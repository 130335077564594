import React from "react";
import _ from "lodash";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import {
    selectPartnersSearch,
    setPartnersSearch,
    PartnersSearch
} from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const partnersSearch = useAppSelector(selectPartnersSearch);
    const [currentPartnersSearch, setCurrentPartnersSearch] = React.useState<PartnersSearch>(partnersSearch);

    const setPartnersSearchDelayed = React.useMemo(() => {
        return _.debounce((partnersSearch: PartnersSearch) => dispatch(setPartnersSearch(partnersSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newPartnersSearch = { ...partnersSearch, name };
        setCurrentPartnersSearch(newPartnersSearch);
        setPartnersSearchDelayed(newPartnersSearch);
    };

    React.useEffect(() => {
        setCurrentPartnersSearch(partnersSearch);
    }, [partnersSearch]);

    return (
        <Box paddingTop={2}>
            <StyledTextField
                placeholder="Search"
                variant="outlined"
                size="small"
                color="secondary"
                value={currentPartnersSearch.name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Search;
