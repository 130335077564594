import types from "./types";

const getSalesPerformanceVsBudgetRequest = () => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_REQUEST
});

const getSalesPerformanceVsBudgetSuccess = (salesFYTD, salesFYTG, budgetFY, FYlabel) => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_SUCCESS,
    payload: {
        salesFYTD,
        salesFYTG,
        budgetFY,
        FYlabel
    }
});

const getSalesPerformanceVsBudgetFailure = () => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_FAILURE
});

const actions = {
    getSalesPerformanceVsBudgetRequest,
    getSalesPerformanceVsBudgetSuccess,
    getSalesPerformanceVsBudgetFailure
};

export default actions;
