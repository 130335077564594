const GET_FORECAST_SALES_WOW_REQUEST = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_WOW_REQUEST";
const GET_FORECAST_SALES_WOW_SUCCESS = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_WOW_SUCCESS";
const GET_FORECAST_SALES_WOW_FAILURE = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_WOW_FAILURE";
const GET_FORECAST_SALES_MOM_REQUEST = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_MOM_REQUEST";
const GET_FORECAST_SALES_MOM_SUCCESS = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_MOM_SUCCESS";
const GET_FORECAST_SALES_MOM_FAILURE = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_MOM_FAILURE";
const GET_FORECAST_SALES_YOY_REQUEST = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_YOY_REQUEST";
const GET_FORECAST_SALES_YOY_SUCCESS = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_YOY_SUCCESS";
const GET_FORECAST_SALES_YOY_FAILURE = "spark/customer/insights/performance/forecast/forecastRevenue/GET_FORECAST_SALES_YOY_FAILURE";
const GET_SALES_HISTORY_FORECAST_REQUEST = "spark/customer/insights/performance/forecast/forecastRevenue/GET_SALES_HISTORY_FORECAST_REQUEST";
const GET_SALES_HISTORY_FORECAST_SUCCESS = "spark/customer/insights/performance/forecast/forecastRevenue/GET_SALES_HISTORY_FORECAST_SUCCESS";
const GET_SALES_HISTORY_FORECAST_FAILURE = "spark/customer/insights/performance/forecast/forecastRevenue/GET_SALES_HISTORY_FORECAST_FAILURE";
const GET_MONTHLY_FORECAST_REQUEST = "spark/customer/insights/performance/forecast/forecastRevenue/GET_MONTHLY_FORECAST_REQUEST";
const GET_MONTHLY_FORECAST_SUCCESS = "spark/customer/insights/performance/forecast/forecastRevenue/GET_MONTHLY_FORECAST_SUCCESS";
const GET_MONTHLY_FORECAST_FAILURE = "spark/customer/insights/performance/forecast/forecastRevenue/GET_MONTHLY_FORECAST_FAILURE";

const types = {
    GET_FORECAST_SALES_WOW_REQUEST,
    GET_FORECAST_SALES_WOW_SUCCESS,
    GET_FORECAST_SALES_WOW_FAILURE,
    GET_FORECAST_SALES_MOM_REQUEST,
    GET_FORECAST_SALES_MOM_SUCCESS,
    GET_FORECAST_SALES_MOM_FAILURE,
    GET_FORECAST_SALES_YOY_REQUEST,
    GET_FORECAST_SALES_YOY_SUCCESS,
    GET_FORECAST_SALES_YOY_FAILURE,
    GET_SALES_HISTORY_FORECAST_REQUEST,
    GET_SALES_HISTORY_FORECAST_SUCCESS,
    GET_SALES_HISTORY_FORECAST_FAILURE,
    GET_MONTHLY_FORECAST_REQUEST,
    GET_MONTHLY_FORECAST_SUCCESS,
    GET_MONTHLY_FORECAST_FAILURE
};

export default types;
