import { operations as openingsAndClosuresOperations } from "./openingsAndClosures";
import { operations as marketCategoryBreakdownOperations } from "./marketCategoryBreakdown";
import { operations as supplyAndDemandOperations } from "./supplyAndDemand";
import { operations as retailDensityOperations } from "./retailDensity";
import { operations as marketCategorySpendOperations } from "./marketCategorySpend";

const getAreaHealthData = () => async (dispatch) => {
    dispatch(openingsAndClosuresOperations.getLifetimeOfStores());
    dispatch(openingsAndClosuresOperations.getNetOpenVsClosuresOverTime());
    dispatch(marketCategoryBreakdownOperations.getMarketCategoryOpeningsVsClosures());
    dispatch(supplyAndDemandOperations.getSupplyAndDemand());
    dispatch(retailDensityOperations.getRetailDensityVis());
    dispatch(marketCategorySpendOperations.getMarketCatSpendPerHead());
};

const operations = {
    getAreaHealthData
};

export default operations;
