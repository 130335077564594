import React from "react";
import { Grid } from "@material-ui/core";

import COGSPastYear from "./COGSPastYear";
import GrossProfitLastYear from "./GrossProfitLastYear";
import GrossProfitMarginLastYear from "./GrossProfitMarginLastYear";
import GrossProfitMarginOverTime from "./GrossProfitMarginOverTime";
import GrossProfitTrend from "./GrossProfitTrend";

const GrossProfitMargin = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <COGSPastYear />
            </Grid>
            <Grid item xs={12} md={4}>
                <GrossProfitLastYear />
            </Grid>
            <Grid item xs={12} md={4}>
                <GrossProfitMarginLastYear />
            </Grid>
            <Grid item xs={12}>
                <GrossProfitMarginOverTime />
            </Grid>
            <Grid item xs={12}>
                <GrossProfitTrend />
            </Grid>
        </Grid>
    );
};

export default GrossProfitMargin;
