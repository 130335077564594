import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const Ethnicity: React.FC = () => {
    const title = "Ethnicity (Diversity)";
    const categories = [
        "Arab",
        "Asian - Other",
        "Bangladeshi",
        "Black - African",
        "Black - Caribbean",
        "Black - Other",
        "Chinese",
        "Indian",
        "Mixed ethnicity",
        "Other",
        "Pakistani",
        "White"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.ethnicity;
    const selectedStoreValues = selectedStore ? [
        selectedStore.arab,
        selectedStore.asianOther,
        selectedStore.bangladeshi,
        selectedStore.blackAfrican,
        selectedStore.blackCaribbean,
        selectedStore.blackOther,
        selectedStore.chinese,
        selectedStore.indian,
        selectedStore.mixed,
        selectedStore.other,
        selectedStore.pakistani,
        selectedStore.white
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.ethnicity;
    const comparatorValues = comparator ? [
        comparator.arab,
        comparator.asianOther,
        comparator.bangladeshi,
        comparator.blackAfrican,
        comparator.blackCaribbean,
        comparator.blackOther,
        comparator.chinese,
        comparator.indian,
        comparator.mixed,
        comparator.other,
        comparator.pakistani,
        comparator.white
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default Ethnicity;
