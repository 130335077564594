import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import dateUtils from "utils/dateUtils";
import numberFormatter from "utils/numberFormatter";

import StreetView from "./StreetView";

const StoreDetails: React.FC = () => {
    const theme = useTheme();
    const store = useAppSelector(selectStore);
    const openingDate = new Date(store?.openingDate.toISOString() ?? "");
    const comparator = useAppSelector(selectComparator);

    return (
        <Box>
            <>
                <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                    How does your {store?.name} store's performance compare to {comparator?.name}?
                    Understand where your {store?.name} store is over and under performing to see where focus your attention.
                </Typography>
                <br />
                <br/>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled }}>
                            Opening date
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {numberFormatter.toOrdinalNumber(openingDate.getDate())} {store !== undefined ? dateUtils.monthName(store.openingDate) : ""} {openingDate.getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled, "paddingTop": theme.spacing(0.5) }}>
                            Store size
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {store !== undefined ? numberFormatter.toDecimalPlaces(parseFloat(String(store.sizeInSquareFeet)), 0, false) : 0}ft<sup>2</sup>
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled }}>
                            Pitch type
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.common.white }}>
                            {store?.retailCentreClassificationName}
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography variant="subtitle1" style={{ "color": theme.palette.text.disabled, "paddingTop": theme.spacing(1) }}>
                            Location
                        </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <StreetView />
                    </Grid>
                </Grid>
            </>
        </Box>
    );
};

export default StoreDetails;
