import React from "react";

import { Grid } from "@material-ui/core";

import LocationAlignment from "./LocationAlignment";
import Overview from "./Overview";

const SelectedLocationOverview: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LocationAlignment />
            </Grid>
            <Grid item xs={12}>
                <Overview />
            </Grid>
        </Grid>
    );
};

export default SelectedLocationOverview;
