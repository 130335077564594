import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { DataWrapper } from "domain/dataWrapper";
import {
    selectComparator,
    selectMonthlySales,
    selectSalesReferenceDate,
    selectStore,
    selectWeeklySales
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import mathUtils from "utils/mathUtils";
import { median } from "mathjs";
import { ClientProductCategory } from "./ClientProductCategory";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RevenueType } from "./RevenueType";

interface LoadRevenueResponse {
    clientProductCategories: ClientProductCategory[]
}

interface RevenueState {
    isLoading: boolean,
    hasErrors: boolean,
    productCategoryMixCategories: ClientProductCategory[],
    productCategoryGrowthCategories: ClientProductCategory[],
    revenueTypes: RevenueType[],
    aggregateRevenueTypes: boolean
}

const initialState: RevenueState = {
    isLoading: false,
    hasErrors: false,
    productCategoryMixCategories: [],
    productCategoryGrowthCategories: [],
    revenueTypes: [new RevenueType("In store", 0, false, true), new RevenueType("Online", 1, true, true)],
    aggregateRevenueTypes: true
};

const revenueSlice = createSlice({
    name: "customer/insights/portfolioNew/revenue",
    initialState,
    reducers: {
        setRevenueProductCategories: (state, action: PayloadAction<string[]>) => {
            const newCategories = action.payload.map(categoryName => new ClientProductCategory(categoryName, true));
            state.productCategoryMixCategories = newCategories;
            state.productCategoryGrowthCategories = newCategories;
        },
        toggleProductCategoryMixCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const productCategoryName = action.payload;
            state.productCategoryMixCategories.find(productCategory => productCategory.name === productCategoryName)?.toggleIsSelected();
        },
        chooseAllProductCategoryMixCategories: (state) => {
            state.productCategoryMixCategories.forEach(productCategory => productCategory.setIsSelected(true));
        },
        deselectAllProductCategoryMixCategories: (state) => {
            state.productCategoryMixCategories.forEach(productCategory => productCategory.setIsSelected(false));
        },
        resetProductCategoryMixCategories: (state) => {
            state.productCategoryMixCategories = initialState.productCategoryMixCategories;
        },
        toggleProductCategoryGrowthCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const productCategoryName = action.payload;
            state.productCategoryGrowthCategories.find(productCategory => productCategory.name === productCategoryName)?.toggleIsSelected();
        },
        chooseAllProductCategoryGrowthCategories: (state) => {
            state.productCategoryGrowthCategories.forEach(productCategory => productCategory.setIsSelected(true));
        },
        deselectAllProductCategoryGrowthCategories: (state) => {
            state.productCategoryGrowthCategories.forEach(productCategory => productCategory.setIsSelected(false));
        },
        resetProductCategoryGrowthCategories: (state) => {
            state.productCategoryGrowthCategories = initialState.productCategoryGrowthCategories;
        },
        toggleRevenueTypeIsSelected: (state, action: PayloadAction<string>) => {
            const revenueTypeName = action.payload;
            state.revenueTypes.find(revenueType => revenueType.name === revenueTypeName)?.toggleIsSelected();
        },
        chooseAllRevenueTypes: (state) => {
            state.revenueTypes.forEach(revenueType => revenueType.setIsSelected(true));
        },
        deselectAllRevenueTypes: (state) => {
            state.revenueTypes.forEach(revenueType => revenueType.setIsSelected(false));
        },
        resetRevenueTypes: (state) => {
            state.revenueTypes = initialState.revenueTypes;
        },
        toggleAggregateRevenueTypes: (state) => {
            state.aggregateRevenueTypes = !state.aggregateRevenueTypes;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadRevenue.pending, (state: RevenueState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadRevenue.rejected, (state: RevenueState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.productCategoryMixCategories = initialState.productCategoryMixCategories;
            state.productCategoryGrowthCategories = initialState.productCategoryGrowthCategories;
        });
        builder.addCase(loadRevenue.fulfilled, (state: RevenueState, action: PayloadAction<LoadRevenueResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.productCategoryMixCategories = action.payload.clientProductCategories;
            state.productCategoryGrowthCategories = action.payload.clientProductCategories;
        });
    }
});

export const {
    setRevenueProductCategories,
    toggleProductCategoryMixCategoryIsSelected,
    chooseAllProductCategoryMixCategories,
    deselectAllProductCategoryMixCategories,
    resetProductCategoryMixCategories,
    toggleProductCategoryGrowthCategoryIsSelected,
    chooseAllProductCategoryGrowthCategories,
    deselectAllProductCategoryGrowthCategories,
    resetProductCategoryGrowthCategories,
    toggleRevenueTypeIsSelected,
    chooseAllRevenueTypes,
    deselectAllRevenueTypes,
    resetRevenueTypes,
    toggleAggregateRevenueTypes
} = revenueSlice.actions;

export const loadRevenue = createAppAsyncThunk(
    "customer/tools/portfolioNew/revenue/loadRevenue",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const monthlySales = selectMonthlySales(state);
            const allProductCategoryNames = monthlySales.data.map(sales => sales.clientProductCategory ?? "Unknown");
            const uniqueProductCategoryNames = Array.from(new Set(allProductCategoryNames));

            const clientProductCategories = uniqueProductCategoryNames
                .map(categoryName => new ClientProductCategory(categoryName, true))
                .sort((a, b) => stringSortExpression(a.name, b.name, SortDirection.ASC));
            const loadRevenueResponse: LoadRevenueResponse = {
                clientProductCategories,
            };
            return loadRevenueResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Revenue.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearRevenue = (): AppThunk => async (dispatch) => {
    dispatch(revenueSlice.actions.resetProductCategoryMixCategories());
    dispatch(revenueSlice.actions.resetProductCategoryGrowthCategories());
};

export const selectProductCategoryMixCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.revenue.productCategoryMixCategories;
};

export const selectProductCategoryGrowthCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.revenue.productCategoryGrowthCategories;
};

// 2.1 Revenue

export const selectRevenueTypes = (state: RootState) => {
    return state.customer.insights.portfolioNew.revenue.revenueTypes;
};

export const selectAggregateRevenueTypes = (state: RootState) => {
    return state.customer.insights.portfolioNew.revenue.aggregateRevenueTypes;
};

export const selectAggregateRevenueTypesSwitchIsDisabled = createSelector(
    selectRevenueTypes,
    (revenueTypes) => {
        return !revenueTypes.every(item => item.isSelected());
    }
);

export const selectSelectedStoreWeeklyRevenue = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectWeeklySales(state),
    selectRevenueTypes,
    selectAggregateRevenueTypes,
    (selectedStore, weeklySales, revenueTypes, aggregateRevenueTypes) => {
        interface WeeklyRevenueSeries {
            seriesName: string,
            seriesID: number,
            values: {
                sales: number,
                forecastFlag: boolean,
                date: DateTime
            }[]
        }

        const selectedStoreWeeklyRevenue: DataWrapper<WeeklyRevenueSeries[]> = {
            isLoading: weeklySales.isLoading,
            hasErrors: weeklySales.hasErrors,
            data: []
        };
        if (!selectedStore || selectedStoreWeeklyRevenue.hasErrors || selectedStoreWeeklyRevenue.isLoading) {
            return selectedStoreWeeklyRevenue;
        }

        const selectedRevenueTypes = revenueTypes.filter(item => item.isSelected());
        const revenueTypeSeries = aggregateRevenueTypes && (selectedRevenueTypes.length === revenueTypes.length)
            ? [{
                name: "Total",
                id: 0,
                onlineFlags: selectedRevenueTypes.map(item => item.isOnline)
            }]
            : selectedRevenueTypes.map(item => ({
                name: item.name,
                id: item.id,
                onlineFlags: [item.isOnline]
            }));

        const selectedStoreRevenue = weeklySales.data.filter(sales => sales.storeID === selectedStore.id);
        selectedStoreWeeklyRevenue.data = revenueTypeSeries.map(seriesName => {
            const values = _(selectedStoreRevenue)
                .filter(item => seriesName.onlineFlags.includes(item.isOnline))
                .groupBy(item => item.weekStartDate.toISO())
                .map((group) => ({
                    sales: group.reduce((total, current) => total + current.totalSales, 0),
                    forecastFlag: group.map(item => item.forecastFlag).some(item => item),
                    date: group[0].weekStartDate
                }))
                .sort((a, b) => numberSortExpression(a.date.toMillis(), b.date.toMillis(), SortDirection.ASC))
                .value();
            return {
                seriesName: seriesName.name,
                seriesID: seriesName.id,
                values
            };
        });

        return selectedStoreWeeklyRevenue;
    }
);

export const selectSelectedStoreYearlyRevenue = createSelector(
    (state: RootState) => selectSalesReferenceDate(state),
    (state: RootState) => selectStore(state),
    (state: RootState) => selectWeeklySales(state),
    selectRevenueTypes,
    (referenceDate, selectedStore, weeklySales, revenueTypes) => {
        interface Revenue {
            label: string,
            currentYear: number,
            previousYear: number,
            nextYear: number
        }

        const selectedStoreYearlyRevenue: DataWrapper<Revenue> = {
            isLoading: weeklySales.isLoading,
            hasErrors: weeklySales.hasErrors,
            data: { label: "", currentYear: 0, previousYear: 0, nextYear: 0 }
        };
        const selectedRevenueTypes = revenueTypes.filter(item => item.isSelected());
        if (!selectedStore || selectedStoreYearlyRevenue.hasErrors || selectedStoreYearlyRevenue.isLoading || selectedRevenueTypes.length === 0) {
            return selectedStoreYearlyRevenue;
        }

        selectedStoreYearlyRevenue.data.label = (selectedRevenueTypes.length === revenueTypes.length)
            ? "total"
            : selectedRevenueTypes[0].name.toLowerCase();
        const selectedRevenueTypesOnlineFlags = selectedRevenueTypes.map(revenueType => revenueType.isOnline);

        const selectedStoreWeeklySales = weeklySales.data
            .filter(sales => sales.storeID === selectedStore.id)
            .filter(sales => selectedRevenueTypesOnlineFlags.includes(sales.isOnline));

        const forecastStartDate = referenceDate.weekday === 6 ? referenceDate.plus({ days: 1 }).startOf("day") : referenceDate.customStartOfWeek();
        selectedStoreYearlyRevenue.data.nextYear = selectedStoreWeeklySales
            .filter(sales => sales.weekStartDate >= forecastStartDate)
            .reduce((total, current) => total + current.totalSales, 0);

        const currentYearStartDate = forecastStartDate.minus({ weeks: 52 });
        selectedStoreYearlyRevenue.data.currentYear = selectedStoreWeeklySales
            .filter(sales => sales.weekStartDate < forecastStartDate && sales.weekStartDate >= currentYearStartDate)
            .reduce((total, current) => total + current.totalSales, 0);

        const previousYearStartDate = currentYearStartDate.minus({ weeks: 52 });
        selectedStoreYearlyRevenue.data.previousYear = selectedStoreWeeklySales
            .filter(sales => sales.weekStartDate < currentYearStartDate && sales.weekStartDate >= previousYearStartDate)
            .reduce((total, current) => total + current.totalSales, 0);

        return selectedStoreYearlyRevenue;
    }
);

export const selectHistoricRevenueGrowth = createSelector(
    (state: RootState) => selectStore(state),
    selectSelectedStoreYearlyRevenue,
    (selectedStore, yearlyRevenue) => {
        const id = "historic-revenue-growth";
        const label = "Historic revenue growth";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = yearlyRevenue;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        const { currentYear, previousYear } = data;
        if (!previousYear || !currentYear) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, label, `This indicator isn’t available because your ${selectedStore.name} store has less than a year’s worth of revenue data uploaded 
            to Dash. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Sales dataset and 
            refresh your company’s Analytics.`);
        }

        const cagr = ((currentYear / previousYear) - 1) * 100;
        if (cagr > 3) {
            status = RagIndicatorStatus.Green;
            value = `Revenue in the last year grew significantly compared to the year prior for your ${selectedStore.name} store.`;
        } else if (cagr < -3) {
            status = RagIndicatorStatus.Red;
            value = `Revenue in the last year declined significantly compared to the year prior for your ${selectedStore.name} store.`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `Revenue in the last year was broadly consistent with the year prior for your  ${selectedStore.name} store.`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectForecastRevenueGrowth = createSelector(
    (state: RootState) => selectStore(state),
    selectSelectedStoreYearlyRevenue,
    (selectedStore, yearlyRevenue) => {
        const id = "forecast-revenue-growth";
        const label = "Forecast revenue growth";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = yearlyRevenue;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }
        const { currentYear, nextYear } = data;
        if (!nextYear || !currentYear) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, label, `This indicator isn’t available because your ${selectedStore.name} store has insufficient revenue data to 
            generate a forecast. To evaluate this insight, someone with permission to upload data from your company will need to 
            edit/upload the Sales dataset and refresh your company’s Analytics.`);
        }

        const cagr = ((nextYear / currentYear) - 1) * 100;
        if (cagr > 3) {
            status = RagIndicatorStatus.Green;
            value = `Revenue in the next year should grow significantly compared to the last year for your ${selectedStore.name} store.`;
        } else if (cagr < -3) {
            status = RagIndicatorStatus.Red;
            value = `Revenue in the next year should decline significantly compared to the last year for your ${selectedStore.name} store.`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `Revenue in the next year should be broadly consistent with the last year for your ${selectedStore.name} store.`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

// 2.2 Ranked Store Growth
interface RankedStoreGrowth {
    storeID: string,
    isSelected: boolean,
    value: number,
    storeName: string,
    rank: number
}

export const selectRankedStoreGrowth = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectWeeklySales(state),
    (state: RootState) => selectSalesReferenceDate(state),
    (selectedStore, comparator, weeklySales, referenceDate) => {
        const rankedStoreGrowth: DataWrapper<RankedStoreGrowth[]> = {
            isLoading: weeklySales.isLoading,
            hasErrors: weeklySales.hasErrors,
            data: []
        };
        if (!selectedStore || !comparator || rankedStoreGrowth.hasErrors || rankedStoreGrowth.isLoading) {
            return rankedStoreGrowth;
        }

        const forecastStartDate = referenceDate.weekday === 6 ? referenceDate.plus({ days: 1 }).startOf("day") : referenceDate.customStartOfWeek();
        const currentYearStartDate = forecastStartDate.minus({ weeks: 52 });
        const previousYearStartDate = currentYearStartDate.minus({ weeks: 52 });

        const selectedAndComparatorStores = [selectedStore].concat(comparator.getStores());

        const storeGrowth = selectedAndComparatorStores.map(store => {
            const storeWeeklySales = weeklySales.data.filter(sales => sales.storeID === store.id);
            const currentYear = storeWeeklySales
                .filter(sales => sales.weekStartDate < forecastStartDate && sales.weekStartDate >= currentYearStartDate)
                .reduce((total, current) => total + current.totalSales, 0);

            const previousYear = storeWeeklySales
                .filter(sales => sales.weekStartDate < currentYearStartDate && sales.weekStartDate >= previousYearStartDate)
                .reduce((total, current) => total + current.totalSales, 0);
            return {
                storeID: store.id,
                isSelected: store.id === selectedStore.id,
                value: mathUtils.safePercentageChange(currentYear, previousYear),
                storeName: store.name,
                rank: 0
            };
        });

        const sortedData = storeGrowth.sort((a, b) => numberSortExpression(a.value, b.value, SortDirection.DESC));
        for (let i in sortedData) {
            sortedData[i].rank = 1 + sortedData.findIndex(item => item.value === sortedData[i].value);
        }

        rankedStoreGrowth.data = sortedData;
        return rankedStoreGrowth;
    }
);

export const selectRankedGrowthClassification = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectRankedStoreGrowth,
    (selectedStore, comparator, rankedGrowth) => {
        const id = "ranked-growth-classification";
        const label = "Ranked growth classification";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = rankedGrowth;
        const selectedStoreGrowth = data.find(storeGrowth => storeGrowth.isSelected);
        if (isLoading || hasErrors || !selectedStoreGrowth || !selectedStore || !comparator) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;
        const selectedPercentile = selectedStoreGrowth.rank / data.length;

        if (selectedPercentile <= topThirdPercentile) {
            status = RagIndicatorStatus.Green;
            value = `${selectedStore.name} is a top performer for 12 month rolling growth relative to ${comparator.name}`;
        } else if (selectedPercentile >= bottomThirdPercentile) {
            status = RagIndicatorStatus.Red;
            value = `${selectedStore.name} is underperforming for 12 month rolling growth relative to ${comparator.name}`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `${selectedStore.name} is average for 12 month rolling growth relative to ${comparator.name}`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

// 2.3 Product Category Mix
interface ProductCategoryMix {
    productCategory: string,
    growth: number,
    mix: number
}

export const selectProductCategoryMixAndGrowth = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectMonthlySales(state),
    (selectedStore, comparator, monthlySales) => {
        const productCategoryMix: DataWrapper<{
            selectedStore: ProductCategoryMix[],
            comparator: ProductCategoryMix[]
        }> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: {
                selectedStore: [],
                comparator: []
            }
        };
        if (!selectedStore || !comparator || productCategoryMix.isLoading || productCategoryMix.hasErrors) {
            return productCategoryMix;
        }

        const selectedAndComparatorStores = [selectedStore].concat(comparator.getStores());

        const storeTotals = selectedAndComparatorStores.map(store => ({
            storeID: store.id,
            currentYear: monthlySales.data
                .filter(sales => (sales.storeID === store.id) && (sales.yearsPrior === 0))
                .reduce((total, sales) => sales.totalSales + total, 0),
            previousYear: monthlySales.data
                .filter(sales => (sales.storeID === store.id) && (sales.yearsPrior === 1))
                .reduce((total, sales) => sales.totalSales + total, 0),
        }));

        const storeCategoryTotals = _(monthlySales.data)
            .groupBy(sales => `${sales.storeID}_${sales.clientProductCategory}`)
            .map((group, key) => {
                const [storeID, clientProductCategory] = key.split("_");
                return {
                    storeID,
                    clientProductCategory,
                    currentYear: group.filter(sales => sales.yearsPrior === 0)
                        .reduce((total, sales) => sales.totalSales + total, 0),
                    previousYear: group.filter(sales => sales.yearsPrior === 1)
                        .reduce((total, sales) => sales.totalSales + total, 0)
                };
            }
            ).value();

        const data = storeCategoryTotals.map(storeCategoryTotal => {
            const storeTotal = storeTotals.find(storeTotal => storeTotal.storeID === storeCategoryTotal.storeID);

            return {
                storeID: storeCategoryTotal.storeID,
                productCategory: storeCategoryTotal.clientProductCategory,
                currentYearSales: storeCategoryTotal.currentYear,
                productCategoryGrowth: mathUtils.safePercentageChange(storeCategoryTotal.currentYear, storeCategoryTotal.previousYear),
                storeYearlySalesDifference: storeTotal ? storeTotal.currentYear - storeTotal.previousYear : 0,
                productCategoryMix: storeTotal ? ((storeTotal.currentYear === 0) ? 0 : 100 * (storeCategoryTotal.currentYear / storeTotal.currentYear)) : 0,
            };
        });

        productCategoryMix.data.selectedStore = data.filter(item => item.storeID === selectedStore.id).map(item => ({
            productCategory: item.productCategory,
            growth: item.productCategoryGrowth,
            mix: item.productCategoryMix
        }));

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);
        productCategoryMix.data.comparator = _(data)
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .groupBy(item => item.productCategory)
            .map((group, key) => ({
                productCategory: key,
                growth: median(group.map(item => item.productCategoryGrowth)),
                mix: median(group.map(item => item.productCategoryMix))
            })
            ).value();

        return productCategoryMix;

    }
);

export const selectProductCategoryMixFiltered = createSelector(
    selectProductCategoryMixAndGrowth,
    selectProductCategoryMixCategories,
    (productCategoryMix, productCategories) => {
        const filteredProductCategoryMix: DataWrapper<{
            selectedStore: ProductCategoryMix[],
            comparator: ProductCategoryMix[]
        }> = {
            isLoading: productCategoryMix.isLoading,
            hasErrors: productCategoryMix.hasErrors,
            data: {
                selectedStore: [],
                comparator: []
            }
        };

        const selectedCategoryNames = productCategories.filter(category => category.isSelected()).map(category => category.name);
        filteredProductCategoryMix.data.selectedStore =
            productCategoryMix.data.selectedStore.filter(categoryMix => selectedCategoryNames.includes(categoryMix.productCategory));
        filteredProductCategoryMix.data.comparator =
            productCategoryMix.data.comparator.filter(categoryMix => selectedCategoryNames.includes(categoryMix.productCategory));

        return filteredProductCategoryMix;
    }
);

export const selectStoreDependencyOnProductCategory = createSelector(
    (state: RootState) => selectStore(state),
    selectProductCategoryMixFiltered,
    selectRankedStoreGrowth,
    (selectedStore, productCategoryMix) => {
        const id = "store-dependency-on-product-or-product-category";
        let label = "Store dependency on product or product category";
        let status = RagIndicatorStatus.Info;
        let value = "";
        const isLoading = productCategoryMix.isLoading;
        const hasErrors = productCategoryMix.hasErrors;
        const selectedStoreSalesMix = productCategoryMix.data.selectedStore;

        if (isLoading || hasErrors || !selectedStore || selectedStoreSalesMix.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const totalMix = selectedStoreSalesMix.reduce((total, current) => total + current.mix, 0);
        const normalisedSalesMix = selectedStoreSalesMix.map(item => item.mix / totalMix);
        const salesGrowth = selectedStoreSalesMix.reduce((total, current) => total + current.growth * (current.mix / totalMix), 0);

        const productCategoryMixSorted = selectedStoreSalesMix.sort((a, b) => numberSortExpression(a.mix, b.mix, SortDirection.DESC));
        const topCategory = productCategoryMixSorted[0].productCategory;

        label = "Store dependency on product category";
        if (salesGrowth >= 0) {
            if (normalisedSalesMix.every(value => value <= 50)) {
                status = RagIndicatorStatus.Green;
                value = "Sales are growing and there's no dependency on a singular product category for growth";
            } else if (normalisedSalesMix.some(value => value > 50 && value < 75)) {
                status = RagIndicatorStatus.Green;
                value = `Sales are growing and there's some dependency on ${topCategory} for growth`;
            } else {
                status = RagIndicatorStatus.Amber;
                value = `Sales are growing and there's a severe dependency on ${topCategory} for sales`;
            }
        } else {
            status = RagIndicatorStatus.Red;
            value = "Sales are decreasing";
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectStoreBestProductCategory = createSelector(
    selectProductCategoryMixFiltered,
    (productCategoryMix) => {
        const id = "store-best-product-category";
        let label = "Store best product category";
        let status = RagIndicatorStatus.Info;
        let value = "";

        const { isLoading, hasErrors, data } = productCategoryMix;
        const selectedStoreSalesMix = data.selectedStore;
        if (isLoading || hasErrors || selectedStoreSalesMix.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const topProductCategoryMix = selectedStoreSalesMix.filter(item => item.mix >= 10);
        const numberOfTopProductCategoryMix = topProductCategoryMix.length;

        const topCategories = numberOfTopProductCategoryMix > 1
            ? topProductCategoryMix
            : topProductCategoryMix
                .sort((a, b) => numberSortExpression(a.mix, b.mix, SortDirection.DESC))
                .slice(0, 3);

        const positiveNoOfTopCategoryForecastGrowth = topCategories.filter(item => item.growth >= 0).length;
        const negativeNoOfTopCategoryForecastGrowth = topCategories.filter(item => item.growth < 0).length;
        const totalNumberOfTopCategories = topCategories.length;

        const positiveCategoryForecastGrowth = (positiveNoOfTopCategoryForecastGrowth / totalNumberOfTopCategories) * 100;
        const negativeCategoryForecastGrowth = (negativeNoOfTopCategoryForecastGrowth / totalNumberOfTopCategories) * 100;

        if (positiveCategoryForecastGrowth >= 75) {
            status = RagIndicatorStatus.Green;
            value = "The majority of the top product categories are growing";
        } else if (positiveCategoryForecastGrowth < 75 && negativeCategoryForecastGrowth < 75) {
            status = RagIndicatorStatus.Amber;
            value = "Mixed growth for the top product categories";
        } else {
            status = RagIndicatorStatus.Red;
            value = "The majority of the top product categories are declining";
        }
        return new RagIndicator(id, status, label, value);
    }
);

// 2.4 Product Category Growth

export const selectProductCategoryGrowthFiltered = createSelector(
    selectProductCategoryMixAndGrowth,
    selectProductCategoryGrowthCategories,
    (productCategoryMix, productCategories) => {
        const filteredProductCategoryMix: DataWrapper<{
            selectedStore: ProductCategoryMix[],
            comparator: ProductCategoryMix[]
        }> = {
            isLoading: productCategoryMix.isLoading,
            hasErrors: productCategoryMix.hasErrors,
            data: {
                selectedStore: [],
                comparator: []
            }
        };

        const selectedCategoryNames = productCategories.filter(category => category.isSelected()).map(category => category.name);
        filteredProductCategoryMix.data.selectedStore =
            productCategoryMix.data.selectedStore.filter(categoryMix => selectedCategoryNames.includes(categoryMix.productCategory));
        filteredProductCategoryMix.data.comparator =
            productCategoryMix.data.comparator.filter(categoryMix => selectedCategoryNames.includes(categoryMix.productCategory));

        return filteredProductCategoryMix;
    }
);

export const selectStoreVsComparatorProductCategoryGrowth = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectProductCategoryGrowthFiltered,
    (selectedStore, comparator, productCategoryMix) => {
        const id = "store-vs-comparator-product-category-growth";
        let label = "Store vs comparator product category growth";
        let status = RagIndicatorStatus.Info;
        let value = "";

        const { isLoading, hasErrors } = productCategoryMix;
        const salesGrowthAndMix = productCategoryMix.data;
        if (isLoading || hasErrors || salesGrowthAndMix.selectedStore.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const values = salesGrowthAndMix.selectedStore.map(item => ({
            ...item,
            comparatorGrowth: salesGrowthAndMix.comparator.find(comparatorItem => comparatorItem.productCategory === item.productCategory)?.growth ?? 0
        }));

        const numberProductCategoryPositiveGrowthVsComparator = values.map(p => p.growth > p.comparatorGrowth).filter(Boolean).length;
        const numberProductCategoryNegativeGrowthVsComparator = values.map(p => p.growth <= p.comparatorGrowth).filter(Boolean).length;
        const totalNumberProductCategory = values.map(p => p.productCategory).length;

        const positiveGrowthCategories = (numberProductCategoryPositiveGrowthVsComparator / totalNumberProductCategory) * 100;
        const negativeGrowthCategories = (numberProductCategoryNegativeGrowthVsComparator / totalNumberProductCategory) * 100;

        if (positiveGrowthCategories >= 75) {
            status = RagIndicatorStatus.Green;
            value = `Majority of product categories in ${selectedStore?.name} have historic growth ahead of ${comparator?.name}`;
        } else if (negativeGrowthCategories >= 75) {
            status = RagIndicatorStatus.Red;
            value = `Majority of product categories in ${selectedStore?.name} have historic growth behind ${comparator?.name}`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `Mixed Performance of product categories in Historic Growth in ${selectedStore?.name} vs ${comparator?.name}`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export default revenueSlice;
