import types from "./types";

const getCategoryForecastSalesGrowthMarketRequest = () => ({
    type: types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_REQUEST
});

const getCategoryForecastSalesGrowthMarketSuccess = (categories, companyGrowth, benchmarkGrowth) => ({
    type: types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_SUCCESS,
    payload: {
        categories,
        companyGrowth,
        benchmarkGrowth
    }
});

const getCategoryForecastSalesGrowthMarketFailure = () => ({
    type: types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_FAILURE
});

const actions = {
    getCategoryForecastSalesGrowthMarketRequest,
    getCategoryForecastSalesGrowthMarketSuccess,
    getCategoryForecastSalesGrowthMarketFailure
};

export default actions;
