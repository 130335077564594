import React from "react";

import { Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const TrustedBy: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const logos = [{
        image: "/content/landing/home/trusted-by/poundland.png",
        alt: "poundland logo"
    }, {
        image: "/content/landing/home/trusted-by/nwaa.jpg",
        alt: "nwaa logo"
    }, {
        image: "/content/landing/home/trusted-by/iceland.png",
        alt: "iceland logo"
    }, {
        image: "/content/landing/home/trusted-by/white-stuff.png",
        alt: "white stuff logo"
    }];

    return (
        // @ts-ignore
        <PageSection
            bgcolor={theme.palette.common.white}
            dataCy="pages-landing-home-trusted-by"
        >
            <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" gutterBottom>
                Trusted by
            </Typography>
            <br />
            <br />
            <Grid container spacing={10} alignItems="center">
                {logos.map((logo, index) =>
                    <Grid key={index} item xs={6} md={3}>
                        <StyledCard square elevation={0}>
                            <CardMedia component="img" image={logo.image} alt={logo.alt} />
                        </StyledCard>
                    </Grid>
                )}
            </Grid>
        </PageSection>
    );
};

export default TrustedBy;
