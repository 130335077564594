import React from "react";

import ChapterAlignment from "pages/customer/tools/location/filters/targets/ChapterAlignment";

import ChangeInOpenStores from "./ChangeInOpenStores";

import {
    selectCandidateTarget,
    selectDefaultTarget,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const AreaHealth: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const defaultTarget = useAppSelector(selectDefaultTarget);
    const resetButtonDisabled = !candidateTarget
        || !defaultTarget
        || candidateTarget.areaHealth === defaultTarget.areaHealth;

    const handleResetButtonClick = () => {
        if (candidateTarget && defaultTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                areaHealth: defaultTarget.areaHealth
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    const handleSwitchChange = () => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                useAreaHealth: !candidateTarget.useAreaHealth
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <ChapterAlignment
            name="Area health"
            disabled={!candidateTarget.useAreaHealth}
            resetButtonDisabled={resetButtonDisabled}
            onResetButtonClick={handleResetButtonClick}
            onSwitchChange={handleSwitchChange}
        >
            <ChangeInOpenStores />
        </ChapterAlignment>
    );
};

export default AreaHealth;
