import React from "react";

import { useAppSelector } from "store";
import { FilterStep, selectActiveStep } from "modules/customer/insights/cost/filters/filtersSlice";

import Stores from "./storeFascias/Stores";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === FilterStep.SelectStore && (<Stores />)}
        </>
    );
};

export default Content;
