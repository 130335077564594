import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface User {
    id: string,
    firstName: string,
    lastName: string,
    isCurrentUser: boolean
}

enum DatasetStatus {
    Unknown = "Unknown",
    Ok = "Ok",
    Processing = "Processing",
    ProcessingIssue = "ProcessingIssue",
    ReadyForRefresh = "ReadyForRefresh",
    RecommendUpdate = "RecommendUpdate"
}

interface Dataset {
    id: string,
    name: string,
    isRequired: boolean,
    status: DatasetStatus
}

interface DashboardState {
    isLoaded: boolean,
    subscriptionPlanName: string,
    users: User[],
    remainingUsersCount: number,
    datasets: Dataset[]
}

const initialState: DashboardState = {
    isLoaded: false,
    subscriptionPlanName: "",
    users: [],
    remainingUsersCount: 0,
    datasets: []
};

const dashboardSlice = createSlice({
    name: "customer/dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getDashboard.pending, (state: DashboardState) => {
            state.isLoaded = false;
        });
        builder.addCase(getDashboard.rejected, (state: DashboardState) => {
            state.isLoaded = true;
        });
        builder.addCase(getDashboard.fulfilled, (state: DashboardState, action: PayloadAction<DashboardState>) => {
            state.isLoaded = true;
            state.subscriptionPlanName = action.payload.subscriptionPlanName;
            state.users = action.payload.users;
            state.remainingUsersCount = action.payload.remainingUsersCount;
            state.datasets = action.payload.datasets;
        });
    }
});

export const getDashboard = createAppAsyncThunk(
    "customer/dashboard/getDashboard",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/customer/dashboard"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data;
        }

        if (response.status === ApiResponseStatus.NotFound) {
            thunkAPI.dispatch(notifyError("Account not found."));
        } else {
            thunkAPI.dispatch(notifyError("Error loading Dashboard."));
        }
        return thunkAPI.rejectWithValue(null);
    }
);

export const selectIsDashboardLoaded = (state: RootState): boolean => {
    return state.customer.dashboard.isLoaded;
};

export const selectSubscriptionPlanName = (state: RootState) => {
    return state.customer.dashboard.subscriptionPlanName;
};

export const selectUsers = (state: RootState) => {
    return state.customer.dashboard.users;
};

export const selectRemainingUsersCount = (state: RootState) => {
    return state.customer.dashboard.remainingUsersCount;
};

export const selectDatasets = (state: RootState) => {
    return state.customer.dashboard.datasets;
};

export default dashboardSlice;
