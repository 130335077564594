import React from "react";
import { Grid } from "@material-ui/core";

import PayrollCostPerSquareFootCategorisation from "./PayrollCostPerSquareFootCategorisation";
import RevenuePerHeadCategorisation from "./RevenuePerHeadCategorisation";
import RevenuePerPoundOfStaffCostCategorisation from "./RevenuePerPoundOfStaffCostCategorisation";
import { useAppSelector } from "store";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectPayrollCostsPerSquareFoot, selectRevenuePerHead, selectRevenuePerPoundOfStaffCosts } from "modules/customer/insights/portfolioNew/drivers/driversSlice";
import RankedGauge from "components/visuals/RankedGauge";

const Staffing: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const revenuePerHead = useAppSelector(selectRevenuePerHead);
    const payrollCostsPerSquareFoot = useAppSelector(selectPayrollCostsPerSquareFoot);
    const revenuePerPoundOfStaffCosts = useAppSelector(selectRevenuePerPoundOfStaffCosts);
    const comparatorName = comparator?.name ?? "";
    const revenuePerHeadStores = revenuePerHead.data;
    const payrollCostsPerSquareFootStores = payrollCostsPerSquareFoot.data;
    const revenuePerPoundOfStaffCostsStores = revenuePerPoundOfStaffCosts.data;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="payroll-costs-per-square-foot"
                    isLoading={payrollCostsPerSquareFoot.isLoading}
                    hasErrors={payrollCostsPerSquareFoot.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Payroll costs per square foot"
                    stores={payrollCostsPerSquareFootStores}
                    lowerIsBetter={true}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="revenue-per-head"
                    isLoading={revenuePerHead.isLoading}
                    hasErrors={revenuePerHead.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Revenue per head"
                    stores={revenuePerHeadStores}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="revenue-per-pound-of-staff-cost"
                    isLoading={revenuePerPoundOfStaffCosts.isLoading}
                    hasErrors={revenuePerPoundOfStaffCosts.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Revenue per £ of staff cost"
                    stores={revenuePerPoundOfStaffCostsStores}
                />
            </Grid>
            <Grid item xs={12}>
                <PayrollCostPerSquareFootCategorisation />
            </Grid>
            <Grid item xs={12}>
                <RevenuePerHeadCategorisation />
            </Grid>
            <Grid item xs={12}>
                <RevenuePerPoundOfStaffCostCategorisation />
            </Grid>
        </Grid>
    );
};

export default Staffing;
