import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import {
    selectYoYRawNetStoreOpenings
} from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";

const YoYRawNetStoreOpenings: React.FC = () => {
    const colourPalette = useColourPalette();
    const comparator = useAppSelector(selectComparator);
    const yearOnYearRawNetStoreOpenings = useAppSelector(selectYoYRawNetStoreOpenings);
    const { data, isLoading, hasErrors } = yearOnYearRawNetStoreOpenings;
    const selectedStoreValue = data.selectedStore;
    const selectedStoreSignal = selectedStoreValue > 0 ? "+" : "";
    const comparatorValue = data.comparator;
    const comparatorSignal = comparatorValue > 0 ? "+" : "";
    const difference = selectedStoreValue - comparatorValue;

    return (
        <Box width="100%" height="100%" data-cy="yoy-raw-net-store-openings">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {selectedStoreSignal}{selectedStoreValue}
                    </Typography>
                }
                targetLabel="Year-on-year change in the number of stores within the local area"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparatorSignal}{comparatorValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparator?.name}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {difference}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default YoYRawNetStoreOpenings;
