import { createSelector } from "reselect";
import { abs } from "mathjs";

const growthBreakdownGrid = (state) => {
    return state.customer.insights.performance.sales.revenueGrowthBreakdown.growthBreakdownGrid;
};

const longTermGrowthVsMarket = createSelector(
    growthBreakdownGrid,
    (growthBreakdownGrid) => {
        const rag = {
            loading: growthBreakdownGrid.loading,
            error: growthBreakdownGrid.error,
            id: "long-term-growth-vs-market",
            label: "Long term growth vs market",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || growthBreakdownGrid.marketCategories.length === 0) {
            return rag;
        }
        
        const clientMainCategory = growthBreakdownGrid.marketCategories[0].categoryName;
        const companyValues = growthBreakdownGrid.marketCategories[0].companyValues.yoyGrowth;
        const benchmarkValues = growthBreakdownGrid.marketCategories[0].benchmarkValues.yoyGrowth;

        const clientYoYGrowthVsMarket = (benchmarkValues === 0) ? 0 : ((companyValues - benchmarkValues) / abs(benchmarkValues));

        if (clientYoYGrowthVsMarket < -0.5) {
            rag.status = "error";
            rag.value = `Long term growth in ${clientMainCategory} significantly behind the market`;
        } else if (clientYoYGrowthVsMarket <= 0.5) {
            rag.status = "warning";
            rag.value= `Long term growth in ${clientMainCategory} is broadly in line with the market `;
        } else {
            rag.status = "success";
            rag.value = `Long term growth in ${clientMainCategory} significantly ahead of the market`;
        }
        return rag;
});

const shortTermGrowthVsMarket = createSelector(
    growthBreakdownGrid,
    (growthBreakdownGrid) => {
        const rag = {
            loading: growthBreakdownGrid.loading,
            error: growthBreakdownGrid.error,
            id: "short-term-growth-vs-market",
            label: "Short term growth vs market",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || growthBreakdownGrid.marketCategories.length === 0) {
            return rag;
        }
        
        
        const clientMainCategory = growthBreakdownGrid.marketCategories[0].categoryName;
        const companyValues = growthBreakdownGrid.marketCategories[0].companyValues.currentMonthVsSMLYGrowth;
        const benchmarkValues = growthBreakdownGrid.marketCategories[0].benchmarkValues.currentMonthVsSMLYGrowth;

        const clientMonthVsSMLYVsMarket = (benchmarkValues === 0) ? 0 :
            ((companyValues - benchmarkValues) / abs(benchmarkValues));
 
        if (clientMonthVsSMLYVsMarket < -0.5) {
            rag.status = "error";
            rag.value = `Short term growth in ${clientMainCategory} significantly behind the market`;
        } else if (clientMonthVsSMLYVsMarket <= 0.5) {
            rag.status = "warning";
            rag.value = `Short term growth in ${clientMainCategory} is broadly in line with the market`;
        } else {
            rag.status = "success";
            rag.value = `Short term growth in ${clientMainCategory} significantly ahead of the market`;
        }
        return rag;

});

const selectors = {
    growthBreakdownGrid,
    longTermGrowthVsMarket,
    shortTermGrowthVsMarket
};

export default selectors;
