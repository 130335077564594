import React from "react";

import { Button } from "@material-ui/core";

import { endSupportSession } from "modules/admin/accounts/supportSessions/supportSessionsSlice";
import { selectUserInfo } from "modules/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store";

const EndSupportSession: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);

    const handleEndSupportSessionClick = () => {
        dispatch(endSupportSession(userInfo.accountId));
    };

    return (
        <Button
            variant="outlined"
            size="large"
            color="default"
            disableElevation
            onClick={handleEndSupportSessionClick}
            data-cy="btn-end-support-session"
        >
            End support session
        </Button>
    );
};

export default EndSupportSession;
