import types from "./types";

const getSalesToProfitRequest = () => ({
    type: types.GET_SALES_TO_PROFIT_REQUEST
});

const getSalesToProfitSuccess = (selectedStore, comparator, netProfitFlag) => ({
    type: types.GET_SALES_TO_PROFIT_SUCCESS,
    payload: {
        selectedStore,
        comparator,
        netProfitFlag
    }
});
const getSalesToProfitFailure = () => ({
    type: types.GET_SALES_TO_PROFIT_FAILURE
});

const actions = {
    getSalesToProfitRequest,
    getSalesToProfitSuccess,
    getSalesToProfitFailure
};

export default actions;
