import React from "react";

import { Card, CardActionArea, CardActions, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import RadarChart from "pages/customer/insights/portfolioNew/filters/RadarChart";

import { StoreWithSimilarityScore } from "modules/customer/insights/portfolioNew/store";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        height: "100%"
    }
}))(Card);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardActions);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%",
        minWidth: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(CardContent);

interface StoreCardProps {
    store: StoreWithSimilarityScore,
    selected: boolean,
    onSelect: (store: StoreWithSimilarityScore) => void,
    onDeselect: (store: StoreWithSimilarityScore) => void,
    showSimilarityScore: boolean
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
    const theme = useTheme();
    const { store, selected, onSelect, onDeselect, showSimilarityScore } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(store);
        } else {
            onSelect(store);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardActions style={{ height: theme.spacing(15) }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {store.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Revenue
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toGBP(store.revenue, 1)}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Gross Profit
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toPercentage(store.grossProfitMargin, false, 0)}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                {"Revenue/ft²"}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toGBP((store.revenue / store.sizeInSquareFeet), 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>
                <StyledCardContent>
                    <RadarChart
                        revenue={store.revenue}
                        revenueScore={store.revenueScore}
                        profit={store.grossProfitMargin}
                        profitScore={store.profitScore}
                        changeInNumberOfStores={store.changeInNumberOfStores}
                        areaHealthScore={store.areaHealthScore}
                        catchmentSize={store.catchmentSize}
                        catchmentScore={store.catchmentScore}
                        numberOfCompetitors={store.numberOfCompetitors}
                        competitionScore={store.competitionScore}
                        footfallLevel={store.footfallLevel}
                        footfallScore={store.footfallScore}
                        totalScore={store.getTotalScore()}
                    />
                </StyledCardContent>
                {showSimilarityScore && <StyledCardActions>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="right"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Similarity:&nbsp;
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                    variant="h6"
                                    component="span"
                                    align="left"
                                    display="inline"
                                    style={{ color: theme.palette.text.primary }}
                            >
                                {store.displaySimilarityScore()}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>}
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default StoreCard;
