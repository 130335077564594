import React from "react";
import Highcharts from "highcharts";

import { useTheme } from "@material-ui/core/styles";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import { RetailCentreWithScores, ScoreField } from "modules/customer/tools/location/retailCentre";
import { Target } from "modules/customer/tools/location/target";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface RadarChartProps {
    retailCentre: RetailCentreWithScores,
    target: Target,
    enableSpend: boolean
}

const RadarChart: React.FC<RadarChartProps> = (props) => {
    const { retailCentre, target, enableSpend } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const demographicsScore = target.useDemographics ? retailCentre.demographicsScore : null;
    const spendScore = target.useSpend ? retailCentre.spendScore : null;
    const areaHealthScore = target.useAreaHealth ? retailCentre.areaHealthScore : null;
    const footfallScore = target.useFootfall ? retailCentre.footfallScore : null;
    const colourGroup = retailCentre.getRagScore(ScoreField.Overall);
    const colour = colourPalette.heatmap[colourGroup];
    //@ts-ignore
    const backgroundColour = theme.palette.quaternary.light;

    const options: Highcharts.Options = React.useMemo(() => {
        const options: Highcharts.Options = {
            chart: {
                height: theme.spacing(48),
                backgroundColor: backgroundColour
            },
            xAxis: {
                type: "category",
                tickmarkPlacement: "on",
                lineWidth: 0,
                labels: {
                    style: {
                        fontSize: `14px`
                    }
                }
            },
            yAxis: {
                gridLineInterpolation: "polygon",
                max: 10
            },
            tooltip: {
                useHTML: true,
                headerFormat: `<table>`,
                footerFormat: `</table>`,
                pointFormatter: function () {
                    const customOptions = this?.options?.custom;
                    const formattedValue = customOptions?.tooltipValue === null
                        ? " - "
                        : numberFormatter.toDecimalPlaces(customOptions?.tooltipValue, 1);
                    const scoreSuffix = "/10";
                    return stringUtils.tooltipHTML([this.options.name?.toString() + ":"], {
                        values: [`${formattedValue}${scoreSuffix}`],
                        header: "Selected location"
                    });
                }
            },
            series: [{
                name: "Score",
                type: "area",
                color: colour,
                data: [{
                    name: "Demographics",
                    y: demographicsScore
                // }, {
                //     ToDo: remove comment and keep score in order once spend feature flag is removed
                //     name: "Spend",
                //     y: spendScore
                }, {
                    name: "Area health",
                    y: areaHealthScore
                }, {
                    name: "Footfall",
                    y: footfallScore
                }],
                pointPlacement: "on"
            }]
        };

        if (enableSpend) {
            // @ts-ignore
            options.series[0].data.splice(1, 0, {
                name: "Spend",
                y: spendScore
            });
        }

        return options;
    }, [theme, demographicsScore, enableSpend, spendScore, areaHealthScore, footfallScore, colour, backgroundColour]);

    return (
        <Radar loading={false} error={false} options={options} dataAdditionPercent={10} />
    );
};

export default RadarChart;
