import React from "react";

import ChapterAlignment from "pages/customer/tools/location/filters/targets/ChapterAlignment";

import FootfallLevel from "./FootfallLevel";

import {
    selectCandidateTarget,
    selectDefaultTarget,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const Footfall: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const defaultTarget = useAppSelector(selectDefaultTarget);
    const resetButtonDisabled = !candidateTarget
        || !defaultTarget
        || candidateTarget.footfall === defaultTarget.footfall;

    const handleResetButtonClick = () => {
        if (candidateTarget && defaultTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                footfall: defaultTarget.footfall
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    const handleSwitchChange = () => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                useFootfall: !candidateTarget.useFootfall
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <ChapterAlignment
            name="Footfall"
            disabled={!candidateTarget.useFootfall}
            resetButtonDisabled={resetButtonDisabled}
            onResetButtonClick={handleResetButtonClick}
            onSwitchChange={handleSwitchChange}
        >
            <FootfallLevel />
        </ChapterAlignment>
    );
};

export default Footfall;
