import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/areaHealth/supplyAndDemand";

const SupplyAndDemandRagIndicator = () => {
    const supplyAndDemandRag = useSelector(state => selectors.supplyAndDemandRag(state));
    const { loading, error, id, label, status, value } = supplyAndDemandRag;

    return (
        <Box data-cy={`${id}-rag-indicator`}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default SupplyAndDemandRagIndicator;
