const GET_SALES_YTD_REQUEST = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_YTD_REQUEST";
const GET_SALES_YTD_SUCCESS = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_YTD_SUCCESS";
const GET_SALES_YTD_FAILURE = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_YTD_FAILURE";
const GET_TOTAL_SALES_LATEST_FULL_MONTH_REQUEST = "spark/customer/insights/performance/sales/revenueOverTime/GET_TOTAL_SALES_LATEST_FULL_MONTH_REQUEST";
const GET_TOTAL_SALES_LATEST_FULL_MONTH_SUCCESS = "spark/customer/insights/performance/sales/revenueOverTime/GET_TOTAL_SALES_LATEST_FULL_MONTH_SUCCESS";
const GET_TOTAL_SALES_LATEST_FULL_MONTH_FAILURE = "spark/customer/insights/performance/sales/revenueOverTime/GET_TOTAL_SALES_LATEST_FULL_MONTH_FAILURE";
const GET_AVG_SALES_LATEST_FULL_MONTH_REQUEST = "spark/customer/insights/performance/sales/revenueOverTime/GET_AVG_SALES_LATEST_FULL_MONTH_REQUEST";
const GET_AVG_SALES_LATEST_FULL_MONTH_SUCCESS = "spark/customer/insights/performance/sales/revenueOverTime/GET_AVG_SALES_LATEST_FULL_MONTH_SUCCESS";
const GET_AVG_SALES_LATEST_FULL_MONTH_FAILURE = "spark/customer/insights/performance/sales/revenueOverTime/GET_AVG_SALES_LATEST_FULL_MONTH_FAILURE";
const GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_REQUEST = "spark/customer/insights/performance/sales/revenueOverTime/GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_REQUEST";
const GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_SUCCESS = "spark/customer/insights/performance/sales/revenueOverTime/GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_SUCCESS";
const GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_FAILURE = "spark/customer/insights/performance/sales/revenueOverTime/GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_FAILURE";
const GET_SALES_HISTORY_REQUEST = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_HISTORY_REQUEST";
const GET_SALES_HISTORY_SUCCESS = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_HISTORY_SUCCESS";
const GET_SALES_HISTORY_FAILURE = "spark/customer/insights/performance/sales/revenueOverTime/GET_SALES_HISTORY_FAILURE";

const types = {
    GET_SALES_YTD_REQUEST,
    GET_SALES_YTD_SUCCESS,
    GET_SALES_YTD_FAILURE,
    GET_TOTAL_SALES_LATEST_FULL_MONTH_REQUEST,
    GET_TOTAL_SALES_LATEST_FULL_MONTH_SUCCESS,
    GET_TOTAL_SALES_LATEST_FULL_MONTH_FAILURE,
    GET_AVG_SALES_LATEST_FULL_MONTH_REQUEST,
    GET_AVG_SALES_LATEST_FULL_MONTH_SUCCESS,
    GET_AVG_SALES_LATEST_FULL_MONTH_FAILURE,
    GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_REQUEST,
    GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_SUCCESS,
    GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_FAILURE,
    GET_SALES_HISTORY_REQUEST,
    GET_SALES_HISTORY_SUCCESS,
    GET_SALES_HISTORY_FAILURE
};

export default types;
