import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { selectFilter, setFilter } from "modules/admin/leads/leadsSlice";
import { useAppDispatch, useAppSelector } from "store";

const FilterLeads: React.FC = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectFilter);

    const handleNameOrEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nameOrEmail = event.target.value;
        const newFilter = { ...filter, nameOrEmail };
        dispatch(setFilter(newFilter));
    };

    return (
        <TextField
            placeholder="Enter name or email"
            variant="outlined"
            size="small"
            color="secondary"
            value={filter.nameOrEmail}
            onChange={handleNameOrEmailChange}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default FilterLeads;
