import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectFootfallPositioning } from "modules/customer/insights/portfolioNew/footfall/footfallSlice";

const FootfallPositioning: React.FC = () => {
    const footfallPositioning = useAppSelector(selectFootfallPositioning);
    return (
        <RAGIndicatorNew ragIndicator={footfallPositioning} />
    );
};

export default FootfallPositioning;
