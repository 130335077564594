import { combineReducers } from "redux";

import api from "./api";
import cube from "./cube";
import logger from "./logger";
import maps from "./maps";
import webAnalytics from "./webAnalytics";

const reducer = combineReducers({
    api,
    cube,
    logger,
    maps,
    webAnalytics
});

export default reducer;
