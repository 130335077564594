import React from "react";

import { Box, Grid } from "@material-ui/core";

import BillingInformation from "./BillingInformation";
import CurrentPlan from "./CurrentPlan";

const Billing: React.FC = () => {
    return (
        <Box data-cy="pages-customer-account-billing">
            <Grid container spacing={4}>
                <Grid item xs={5}>
                    <CurrentPlan />
                </Grid>
                <Grid item xs={5}>
                    <BillingInformation />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Billing;
