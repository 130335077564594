import types from "./types";

const getStoreVsComparatorRevVsCompRequest = () => ({
    type: types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_REQUEST
});

const getStoreVsComparatorRevVsCompSuccess = (store, comparator) => ({
    type: types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getStoreVsComparatorRevVsCompFailure = () => ({
    type: types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_FAILURE
});

const getRevenueVsCompetitionScoreRequest = () => ({
    type: types.GET_REVENUE_VS_COMPETITION_SCORE_REQUEST
});

const getRevenueVsCompetitionScoreSuccess = (status, value) => ({
    type: types.GET_REVENUE_VS_COMPETITION_SCORE_SUCCESS,
    payload: {
        status,
        value
    }
});

const getRevenueVsCompetitionScoreFailure = () => ({
    type: types.GET_REVENUE_VS_COMPETITION_SCORE_FAILURE
});

const actions = {
    getStoreVsComparatorRevVsCompRequest,
    getStoreVsComparatorRevVsCompSuccess,
    getStoreVsComparatorRevVsCompFailure,
    getRevenueVsCompetitionScoreRequest,
    getRevenueVsCompetitionScoreSuccess,
    getRevenueVsCompetitionScoreFailure
};

export default actions;
