import React from "react";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { withStyles } from "@material-ui/core/styles";

import { goToLocation, SavedLocation } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch } from "store";

const StyledGridItem = withStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center"
    }
}))(Grid);

const StyledActionsGridItem = withStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}))(Grid);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Button);

interface GoToLocationProps {
    savedLocation: SavedLocation
}

const GoToLocation: React.FC<GoToLocationProps> = (props) => {
    const { savedLocation } = props;
    const dispatch = useAppDispatch();

    const handleGoToLocationClick = () => {
        dispatch(goToLocation(savedLocation));
    };

    return (
        <Grid container spacing={1}>
            <StyledGridItem item xs={6}>
                <LocationOnOutlinedIcon fontSize="small" />
                <Typography variant="body1" component="div">
                    &nbsp;{savedLocation.address}
                </Typography>
            </StyledGridItem>
            <StyledGridItem item xs={4}>
                <Typography variant="body1" component="div">
                    {`Saved at ${savedLocation.createdAt.toLocaleString()} by ${savedLocation.user.firstName} ${savedLocation.user.lastName}`}
                </Typography>
            </StyledGridItem>
            <StyledActionsGridItem item xs={2}>
                <Box>
                    <StyledButton
                        variant="text"
                        size="small"
                        color="default"
                        endIcon={<ArrowForwardRoundedIcon />}
                        onClick={handleGoToLocationClick}
                        disableElevation
                    >
                        Go to location
                    </StyledButton>
                </Box>
            </StyledActionsGridItem>
        </Grid>
    );
};

export default GoToLocation;
