import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator, selectNumberOfProductCategories } from "modules/customer/insights/portfolio/portfolioSlice";

const SalesSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const numberOfProductCategories = useAppSelector(selectNumberOfProductCategories);
    const productOrProductCategory = numberOfProductCategories > 1 ? "product category" : "product";

    return (
        <>
            Here you can analyse your {store?.name} store's sales history, and anticipate its future sales with our
            weekly forecast. You can see how it has grown historically, and how it will grow in the future, compared
            to {comparator?.name}. You can look in detail at historic and forecast {productOrProductCategory} growth and
            evaluate any differences, to see where you are missing revenue-generating opportunities.
        </>
    );
};

export default SalesSubtitle;
