import React from "react";
import { Grid } from "@material-ui/core";

import DistanceToCoreRetailHub from "./DistanceToCoreRetailHub";
import DirectCompetitors from "./DirectCompetitors";
import Competitors from "./Competitors";
import Map from "./Map";
import DistanceToHotspot from "./DistanceToHotspot";
import SizeRelativeToDirectCompetitors from "./SizeRelativeToDirectCompetitors";

const NearbyCompetition = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <DistanceToCoreRetailHub />
            </Grid>
            <Grid item xs={12} md={4}>
                <DirectCompetitors />
            </Grid>
            <Grid item xs={12} md={4}>
                <Competitors />
            </Grid>
            <Grid item xs={12} md={12}>
                <Map />
            </Grid>
            <Grid item xs={12} md={12}>
                <DistanceToHotspot />
            </Grid>
            <Grid item xs={12} md={12}>
                <SizeRelativeToDirectCompetitors />
            </Grid>
        </Grid>
    );
};

export default NearbyCompetition;
