import React from "react";

import { Box, Card, CardContent, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import { Competitor } from "modules/customer/tools/location/competition/competitors";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(28),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledDivider = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(Divider);

interface LegendProps {
    competitor?: Competitor
}

const Legend: React.FC<LegendProps> = (props) => {
    const { competitor } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const isDirectCompetitor = competitor?.directCompetitor;

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={competitor !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" component="div">
                                {competitor?.fascia}{isDirectCompetitor ? " (direct competitor)" : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div">
                                Distance from selected location
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(competitor?.distanceToProposedStore ?? 0, 1)}km`}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div">
                                Distance from core retail hub
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(competitor?.distanceToHotspot ?? 0, 1)}km`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" component="div">
                                Store size
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(competitor?.size ?? 0, 1, true)}ft`}<sup>2</sup>
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Symbols
                </Typography>
                <Box display="flex">
                    <PlayArrowRoundedIcon fontSize="small" style={{
                        color: colourPalette.categorical[2],
                        transform: "rotate(90deg)"
                    }} />
                    <Typography variant="body1" component="div" gutterBottom>
                        &nbsp;&nbsp;Direct competitor
                    </Typography>
                </Box>
                <Box display="flex">
                    <FiberManualRecordRoundedIcon fontSize="small" style={{ color: colourPalette.categorical[0] }} />
                    <Typography variant="body1" component="div" gutterBottom>
                        &nbsp;&nbsp;Other competitor
                    </Typography>
                </Box>
                <Box display="flex">
                    <RadioButtonCheckedRoundedIcon fontSize="small" style={{ color: theme.palette.common.white }} />
                    <Typography variant="body1" component="div">
                        &nbsp;&nbsp;Core retail hub
                    </Typography>
                </Box>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
