import { combineReducers } from "redux";

import types from "./types";

const storeVsComparatorRankedGrossProfitEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: [],
    rank: 0,
    denominator: 0
};

const storeVsComparatorRankedGrossProfit = (state = storeVsComparatorRankedGrossProfitEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: [],
                rank: 0,
                denominator: 0
            };
        case types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator,
                rank: action.payload.rank,
                denominator: action.payload.denominator
            };
        case types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: [],
                rank: 0,
                denominator: 0
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    storeVsComparatorRankedGrossProfit
});

export default reducer;
