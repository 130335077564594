import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Button, Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const ComprehensiveInsights = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    const handleClick = () => {
        dispatch(trackEvent("Home page", "Comprehensive insights - Find out more click", "Comprehensive insights - Find out more button"));
    };

    return (
        <PageSection
            color={theme.palette.common.white}
            bgcolor={theme.palette.background.defaultDark}
            dataCy="pages-landing-home-comprehensive-insights"
        >
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/home/comprehensive-insights.png" alt="comprehensive insights" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align}>
                        Comprehensive insights
                    </Typography>
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        From customers to products to stores and expansion, Dash reaches every corner of your business.
                        Our suite of insights and tools not only tell you how your business is performing but why.
                        Drilldown into the drivers of your current performance and understand how you can impact the future success of your business.
                    </Typography>
                    <br />
                    <br />
                    <Box align={align}>
                        <Button
                            component={NavLink}
                            to="/insights-and-tools"
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleClick}
                            disableElevation
                            data-cy="btn-find-out-more"
                        >
                            Find out more
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default ComprehensiveInsights;
