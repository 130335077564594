import { createSelector } from "reselect";

const contributionPerformanceVsBudget = (state) => {
    return state.customer.insights.performance.budget.contributionVsBudget.contributionPerformanceVsBudget;
};

const contributionYTDPlusContributionYTGVsBudget = createSelector(
    contributionPerformanceVsBudget,
    (contributionPerformanceVsBudget) => {
        const rag = {
            loading: false,
            error: false,
            id: "contribution-ytd-plus-contribution-ytg-vs-budget",
            label: "Contribution YTD plus contribution YTG vs budget",
            status: "info",
            value: ""
        };

        if (contributionPerformanceVsBudget.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (contributionPerformanceVsBudget.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const contributionFYTD = contributionPerformanceVsBudget.contributionFYTD;
        const contributionFYTG = contributionPerformanceVsBudget.contributionFYTG;
        const budgetFY = contributionPerformanceVsBudget.budgetFY;
        const FYlabel = contributionPerformanceVsBudget.FYlabel;

        if ((budgetFY === 0) && (FYlabel.length === 0)) {
            rag.status = "noData";
            rag.value = "This indicator isn't available because it requires your company's Budget data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Budget dataset and refresh your company's analytics.";
            return rag;
        }

        const x = ((contributionFYTD + contributionFYTG) - budgetFY) / budgetFY * 100;

        if (x > 15) {
            rag.status = "success";
            rag.value = "Based on actual and forecast contribution in this financial year, your company should exceed the contribution budget comfortably.";
        } else if (x < -15) {
            rag.status = "error";
            rag.value = "Based on actual and forecast contribution in this financial year, your company should struggle to reach the contribution budget.";
        } else {
            rag.status = "warning";
            rag.value = "Based on actual and forecast contribution in this financial year, your company is broadly on track to meet the contribution budget.";
        }
        return rag;
    }
);

const selectors = {
    contributionPerformanceVsBudget,
    contributionYTDPlusContributionYTGVsBudget
};

export default selectors;
