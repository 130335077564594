import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { useTheme } from "@material-ui/core/styles";
import Bar from "./Bar";
import useColourPalette from "./useColourPalette";

const ComparisonBar = (props) => {    
    const colourPalette = useColourPalette();
    const theme = useTheme();

    const { loading, error, options: customOptions } = props;

    const staticOptions = {
        chart:{
            height: customOptions?.title?.text ? 250 : 220,
            marginLeft:-60,
            marginTop: 20,
            marginRight:-60
        },
        xAxis: {
            gridLineWidth: 0,
            minorTickLength: 0,
            tickLength: 0,
            visible: false,
            labels: {
                enabled: false
            }
        },
        legend:{
            enabled: true,
        },
        yAxis: [{
            allowDecimals:true,
            plotBands: [{
                label: {
                  text: '',
                  style: {
                    color: theme.palette.common.white,
                  },
                  y:-5
                },
                color: colourPalette.RAGChartZones[0]
              }, {
                label: {
                  text: '',
                  style: {
                    color: theme.palette.common.white
                  },
                  y:-5
                },
                color: colourPalette.RAGChartZones[1]
              }, {
                label: {
                  text: '',
                  style: {
                    color: theme.palette.common.white
                  },
                  y:-5
                },
                color: colourPalette.RAGChartZones[2]
              }],
            tickInterval: 0.5,
            title: {
                text: "",
                style: {
                    color: theme.palette.common.white
                }
            },
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            }
        }, 
        ],
        tooltip:{
            visible:true,
            enabled:true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: theme.palette.common.white, 
                        fontWeight: "600"
                    },
                    inside: true,
                    align: "right",
                    x: -30
                },
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            }
        },
        series: [{
            color: colourPalette.comparators[0]
        },
        {
            name: '',
            color: colourPalette.comparators[1]
        }
        ]
    };

    const options = _.merge({}, staticOptions, customOptions);

    return (
        <Bar loading={loading} error={error} options={options} dataCy="comparison-bar-chart" />
    );
};

ComparisonBar.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

ComparisonBar.defaultProps = {
    loading: false,
    error: false
};

export default ComparisonBar;
