import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const HouseholdTenure: React.FC = () => {
    const title = "Household tenure (Affluence)";
    const categories = [
        "Social rented",
        "Living rent free",
        "Rented private",
        "Shared ownership",
        "Owned with mortgage",
        "Owned outright"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.householdTenure;
    const selectedStoreValues = selectedStore ? [
        selectedStore.socialRent,
        selectedStore.livingRentFree,
        selectedStore.privateRent,
        selectedStore.sharedOwnership,
        selectedStore.ownedWithLoan,
        selectedStore.ownedOutright
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.householdTenure;
    const comparatorValues = comparator ? [
        comparator.socialRent,
        comparator.livingRentFree,
        comparator.privateRent,
        comparator.sharedOwnership,
        comparator.ownedWithLoan,
        comparator.ownedOutright
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default HouseholdTenure;
