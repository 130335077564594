import React from "react";

import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "store";
import { selectAggregateRevenueTypes, selectAggregateRevenueTypesSwitchIsDisabled, toggleAggregateRevenueTypes } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        },
        "&$disabled": {
            color: theme.palette.text.disabled
        },
        "&$disabled + $track": {
            backgroundColor: theme.palette.text.disabled
        }
    },
    checked: {},
    track: {},
    disabled: {}
}))(Switch);

const AggregateRevenueTypesSwitch: React.FC = () => {
    const dispatch = useAppDispatch();
    const aggregateRevenueTypes = useAppSelector(selectAggregateRevenueTypes);
    const disabled = useAppSelector(selectAggregateRevenueTypesSwitchIsDisabled);

    const onChange = () => {
        dispatch(toggleAggregateRevenueTypes());
    };

    const revenueSwitch = (<StyledSwitch
        size="small"
        color="default"
        checked={aggregateRevenueTypes}
        onChange={onChange}
        disabled={disabled}
    />);

    return (
        <FormControlLabel
            control={revenueSwitch}
            label="Aggregate revenue"
            disabled={disabled}
        />
        
    );
};

export default AggregateRevenueTypesSwitch;
