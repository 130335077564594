import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            <p>
                Take a first look at your selected location's competitive landscape. Refine your competitor list
                and identify the areas of highest competition. See where competitors and your proposed store are
                relative to the core retail hub and determine who would have the competitive edge.
            </p>
        </>
    );
};

export default Subtitle;
