import React from "react";

import { Box, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useTheme } from "@material-ui/core/styles";

const PaymentSuccess: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <br />
            <br />
            <br />
            <br />
            {/* @ts-ignore */}
            <Box align="center">
                <CheckCircleOutlineIcon
                    style={{
                        width: theme.spacing(12),
                        height: theme.spacing(12),
                        // @ts-ignore
                        color: theme.palette.tertiary.main
                    }}
                />
            </Box>
            <br />
            <br />
            <Typography variant="h3" component="div" align="center">
                You've successfully
                <br />
                setup a monthly payment
            </Typography>
            <br />
            <br />
            <br />
            <Typography variant="h4" component="div" align="center" color="textSecondary">
                A payment confirmation has been sent via email.
                <br />
                You can now start using Dash!
            </Typography>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default PaymentSuccess;
