import { createSelector } from "reselect";
import { median } from "mathjs";

import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const supplyAndDemand = (state) => {
    return state.customer.insights.portfolio.areaHealth.supplyAndDemand.supplyAndDemand;
};

const supplyAndDemandRag = createSelector(
    supplyAndDemand,
    state => selectStore(state),
    state => selectComparator(state),
    (supplyAndDemand, selectedStore, selectedComparator) => {
        const rag = {
            loading: false,
            error: false,
            id: "supply-and-demand",
            label: "Supply and demand",
            status: "info",
            value: ""
        };

        if (supplyAndDemand.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (supplyAndDemand.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const store = supplyAndDemand.store;
        const comparator = supplyAndDemand.comparator;
        if (Object.keys(store).length === 0 || comparator.length === 0) {
            return rag;
        }

        const comparatorMedianSupply = median(comparator.map(item => item.supply || 0));
        const comparatorMedianDemand = median(comparator.map(item => item.demand || 0));

        const storeSupply = store.supply;
        const storeDemand = store.demand;

        if (storeSupply <= comparatorMedianSupply && storeDemand > comparatorMedianDemand) {
            rag.status = "success";
            rag.value = `${selectedStore.name} is positioned in an area of low supply and high demand relative to the stores in ${selectedComparator.name}`;
        } else if (storeSupply <= comparatorMedianSupply && storeDemand <= comparatorMedianDemand) {
            rag.status = "warning";
            rag.value = `${selectedStore.name} is positioned in an area of low supply and low demand relative to the stores in ${selectedComparator.name}`;
        } else if (storeSupply > comparatorMedianSupply && storeDemand > comparatorMedianDemand) {
            rag.status = "warning";
            rag.value = `${selectedStore.name} is positioned in an area of high supply and high demand relative to the stores in ${selectedComparator.name}`;
        } else {
            rag.status = "error";
            rag.value = `${selectedStore.name} is positioned in an area of high supply and low demand relative to the stores in ${selectedComparator.name}`;
        }
        return rag;
    }
);

const selectors = {
    supplyAndDemand,
    supplyAndDemandRag
};

export default selectors;
