import React from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Container,
    Chip,
    Typography
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import FlareRoundedIcon from "@material-ui/icons/FlareRounded";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";
import Spacer from "components/Spacer";

import { SubchapterProps } from "./Subchapter";

import { LocationChapter } from "modules/customer/tools/location/locationSlice";

const StyledAccordionSummary = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "&$expanded": {
            minHeight: 0
        }
    },
    content: {
        alignItems: "center",
        "&$expanded": {
            margin: "12px 0"
        }
    },
    expanded: {}
}))(AccordionSummary);

const StyledAccordionDetails = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    }
}))(AccordionDetails);

const StyledAvatar = withStyles(theme => ({
    root: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.main
    }
}))(Avatar);

const useStyles = makeStyles(theme => ({
    number: {
        color: theme.palette.text.disabled
    }
}));

export interface ChapterProps {
    number: LocationChapter,
    title: string,
    subtitle: React.ReactElement,
    firstLookTooltip?: React.ReactElement,
    filter?: React.ReactElement,
    isFilterModified?: boolean,
    dataCy: string,
    children: React.ReactElement<SubchapterProps> | React.ReactElement<SubchapterProps>[]
}

const Chapter: React.FC<ChapterProps> = (props) => {
    const { number, title, subtitle, firstLookTooltip, filter, isFilterModified, dataCy } = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box paddingBottom={8}>
            {!!filter && (
                <Box position="sticky" top={0} zIndex={theme.zIndex.drawer}>
                    <Accordion square elevation={0}>
                        <StyledAccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                            <TuneRoundedIcon />
                            &nbsp;&nbsp;
                            <Typography variant="subtitle1" component="div">
                                Customise insights
                            </Typography>
                            <Spacer />
                            {isFilterModified && (
                                <StyledAvatar>
                                    <FilterListRoundedIcon fontSize="inherit" />
                                </StyledAvatar>
                            )}
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            {filter}
                        </StyledAccordionDetails>
                    </Accordion>
                </Box>
            )}
            <Container maxWidth={false} data-cy={dataCy}>
                <Box paddingTop={2} data-cy="header">
                    <Box display="flex" alignItems="center" paddingBottom={1}>
                        <Typography variant="h3" component="div" data-cy="title">
                            <span className={classes.number} data-cy="number">{number}.&nbsp;&nbsp;</span>
                            {title}
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                        {!!firstLookTooltip && (
                            <SimpleTooltip
                                placement={SimpleTooltipPlacement.Right}
                                title={
                                    <Typography variant="body2" component="div">
                                        {firstLookTooltip}
                                    </Typography>
                                }
                            >
                                <Chip
                                    icon={<FlareRoundedIcon />}
                                    label={
                                        <Typography variant="subtitle2" component="div">
                                            First look
                                        </Typography>
                                    }
                                    color="secondary"
                                />
                            </SimpleTooltip>
                        )}
                    </Box>
                    <Typography variant="subtitle1" component="div" data-cy="subtitle">
                        {subtitle}
                    </Typography>
                    <Box paddingTop={2} data-cy="subchapters">
                        {props.children}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Chapter;
