import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "@material-ui/core";

import CompanyDetails from "./CompanyDetails";
import EmailAddressAndPassword from "./EmailAddressAndPassword";
import EmailNotifications from "./EmailNotifications";
import EndSupportSession from "./EndSupportSession";
import Logout from "./Logout";
import PersonalInfo from "./PersonalInfo";
import Privacy from "./Privacy";

import { operations } from "modules/customer/account/settings";
import { selectUserInfo } from "modules/auth/authSlice";

const Settings = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const isAccountAdmin = userInfo.isAccountAdmin;
    const isAppAdmin = userInfo.isAppAdmin;

    React.useEffect(() => {
        dispatch(operations.getSettings());
    }, [dispatch]);

    return (
        <Box data-cy="pages-customer-account-settings">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <PersonalInfo />
                                </Grid>
                                <Grid item xs={12}>
                                    <EmailAddressAndPassword />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <EmailNotifications />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {isAccountAdmin && (
                            <Grid item md={6} xs={12}>
                                <CompanyDetails />
                            </Grid>
                        )}
                        <Grid item md={6} xs={12}>
                            <Privacy />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {isAppAdmin ? (
                        <EndSupportSession />
                    ) : (
                        <Logout />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Settings;
