import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DateTime } from "luxon";
import { DataWrapper } from "domain/dataWrapper";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import _ from "lodash";

import { MonthlyCosts, loadMonthlyCosts } from "./monthlyCosts";
import { MonthlyRevenue, loadMonthlyRevenue } from "./monthlyRevenue";
import {
    selectCostTypes,
    selectStoreGroups,
    selectRegions,
    selectReferenceDate,
    selectCostReductionOpportunityByStore
} from "modules/customer/insights/cost/costSlice";

import mathUtils from "utils/mathUtils";
import { CostTypeMultiSelect } from "modules/customer/insights/cost/costType";
import { StoreGroupMultiSelect } from "modules/customer/insights/cost/storeGroups";
import { RegionMultiSelect } from "modules/customer/insights/cost/regions";

export enum CostTypeMeasure {
    CostType,
    CostTypePercentageOfRevenue
}

export enum TotalCostTrendsOverTimeLineChartGranularity {
    Month,
    Quarter,
    Year
}

interface CostOverviewState {
    isLoading: boolean,
    hasErrors: boolean,
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[],
    costOverTimeCostTypes: CostTypeMultiSelect[],
    costTypeMeasure: CostTypeMeasure,
    costReductionOpportunitiesCostTypes: CostTypeMultiSelect[],
    costReductionOpportunitiesStoreGroups: StoreGroupMultiSelect[],
    costReductionOpportunitiesRegions: RegionMultiSelect[],
    totalCostTrendsOverTimeLineChartGranularity: TotalCostTrendsOverTimeLineChartGranularity
}

const initialState: CostOverviewState = {
    isLoading: false,
    hasErrors: false,
    monthlyCosts: [],
    monthlyRevenue: [],
    costOverTimeCostTypes: [],
    costTypeMeasure: CostTypeMeasure.CostType,
    costReductionOpportunitiesCostTypes: [],
    costReductionOpportunitiesStoreGroups: [],
    costReductionOpportunitiesRegions: [],
    totalCostTrendsOverTimeLineChartGranularity: TotalCostTrendsOverTimeLineChartGranularity.Month
};

interface LoadCostOverviewResponse {
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[],
    costOverTimeCostTypes: CostTypeMultiSelect[],
    costReductionOpportunitiesCostTypes: CostTypeMultiSelect[],
    costReductionOpportunitiesStoreGroups: StoreGroupMultiSelect[],
    costReductionOpportunitiesRegions: RegionMultiSelect[]
}

const costOverviewSlice = createSlice({
    name: "customer/inisghts/cost/costOverview",
    initialState,
    reducers: {
        clearMonthlyCosts: (state) => {
            state.monthlyCosts = initialState.monthlyCosts;
        },
        clearMonthlyRevenue: (state) => {
            state.monthlyRevenue = initialState.monthlyRevenue;
        },
        clearCostOverTimeCostTypes: (state) => {
            state.costOverTimeCostTypes = initialState.costOverTimeCostTypes;
        },
        clearCostReductionOpportunitiesCostTypes: (state) => {
            state.costReductionOpportunitiesCostTypes = initialState.costReductionOpportunitiesCostTypes;
        },
        clearCostReductionOpportunitiesStoreGroups: (state) => {
            state.costReductionOpportunitiesStoreGroups = initialState.costReductionOpportunitiesStoreGroups;
        },
        clearCostReductionOpportunitiesRegions: (state) => {
            state.costReductionOpportunitiesRegions = initialState.costReductionOpportunitiesRegions;
        },
        toggleCostOverTimeCostTypeIsSelected: (state, action: PayloadAction<string>) => {
            const costTypeName = action.payload;
            state.costOverTimeCostTypes.find(costType => costType.name === costTypeName)?.toggleIsSelected();
        },
        toggleCostReductionOpportunitiesCostTypeIsSelected: (state, action: PayloadAction<string>) => {
            const costTypeName = action.payload;
            state.costReductionOpportunitiesCostTypes.find(costType => costType.name === costTypeName)?.toggleIsSelected();
        },
        toggleCostReductionOpportunitiesStoreGroupsIsSelected: (state, action: PayloadAction<string>) => {
            const storeGroup = action.payload;
            state.costReductionOpportunitiesStoreGroups.find(sg => sg.name === storeGroup)?.toggleIsSelected();
        },
        toggleCostReductionOpportunitiesRegionsIsSelected: (state, action: PayloadAction<string>) => {
            const region = action.payload;
            state.costReductionOpportunitiesRegions.find(r => r.name === region)?.toggleIsSelected();
        },
        chooseAllCostOverTimeCostTypes: (state) => {
            state.costOverTimeCostTypes.forEach(costType => costType.setIsSelected(true));
        },
        chooseAllCostReductionOpportunitiesCostTypes: (state) => {
            state.costReductionOpportunitiesCostTypes.forEach(costType => costType.setIsSelected(true));
        },
        chooseAllCostReductionOpportunitiesStoreGroups: (state) => {
            state.costReductionOpportunitiesStoreGroups.forEach(costType => costType.setIsSelected(true));
        },
        chooseAllCostReductionOpportunitiesRegions: (state) => {
            state.costReductionOpportunitiesRegions.forEach(costType => costType.setIsSelected(true));
        },
        deselectAllCostOverTimeCostTypes: (state) => {
            state.costOverTimeCostTypes.forEach(costType => costType.setIsSelected(false));
        },
        deselectAllCostReductionOpportunitiesCostTypes: (state) => {
            state.costReductionOpportunitiesCostTypes.forEach(costType => costType.setIsSelected(false));
        },
        deselectAllCostReductionOpportunitiesStoreGroups: (state) => {
            state.costReductionOpportunitiesStoreGroups.forEach(storeGroup => storeGroup.setIsSelected(false));
        },
        deselectAllCostReductionOpportunitiesRegions: (state) => {
            state.costReductionOpportunitiesRegions.forEach(region => region.setIsSelected(false));
        },
        setCostTypeMeasure: (state, action: PayloadAction<CostTypeMeasure>) => {
            state.costTypeMeasure = action.payload;
        },
        clearCostTypeMeasure: (state) => {
            state.costTypeMeasure = initialState.costTypeMeasure;
        },
        setTotalCostTrendsOverTimeLineChartGranularity: (state, action: PayloadAction<TotalCostTrendsOverTimeLineChartGranularity>) => {
            state.totalCostTrendsOverTimeLineChartGranularity = action.payload;
        },
        clearTotalCostTrendsOverTimeLineChartGranularity: (state) => {
            state.totalCostTrendsOverTimeLineChartGranularity = initialState.totalCostTrendsOverTimeLineChartGranularity;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostOverview.pending, (state: CostOverviewState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
            state.costOverTimeCostTypes = initialState.costOverTimeCostTypes;
            state.costReductionOpportunitiesCostTypes = initialState.costReductionOpportunitiesCostTypes;
            state.costReductionOpportunitiesStoreGroups = initialState.costReductionOpportunitiesStoreGroups;
            state.costReductionOpportunitiesRegions = initialState.costReductionOpportunitiesRegions;
        });
        builder.addCase(loadCostOverview.rejected, (state: CostOverviewState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
            state.costOverTimeCostTypes = initialState.costOverTimeCostTypes;
            state.costReductionOpportunitiesCostTypes = initialState.costReductionOpportunitiesCostTypes;
            state.costReductionOpportunitiesStoreGroups = initialState.costReductionOpportunitiesStoreGroups;
            state.costReductionOpportunitiesRegions = initialState.costReductionOpportunitiesRegions;
        });
        builder.addCase(loadCostOverview.fulfilled, (state: CostOverviewState, action: PayloadAction<LoadCostOverviewResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.monthlyCosts = action.payload.monthlyCosts;
            state.monthlyRevenue = action.payload.monthlyRevenue;
            state.costOverTimeCostTypes = action.payload.costOverTimeCostTypes;
            state.costReductionOpportunitiesCostTypes = action.payload.costReductionOpportunitiesCostTypes;
            state.costReductionOpportunitiesStoreGroups = action.payload.costReductionOpportunitiesStoreGroups;
            state.costReductionOpportunitiesRegions = action.payload.costReductionOpportunitiesRegions;
        });
    }
});

export const {
    toggleCostOverTimeCostTypeIsSelected,
    toggleCostReductionOpportunitiesCostTypeIsSelected,
    toggleCostReductionOpportunitiesStoreGroupsIsSelected,
    toggleCostReductionOpportunitiesRegionsIsSelected,
    chooseAllCostOverTimeCostTypes,
    chooseAllCostReductionOpportunitiesCostTypes,
    chooseAllCostReductionOpportunitiesStoreGroups,
    chooseAllCostReductionOpportunitiesRegions,
    deselectAllCostOverTimeCostTypes,
    setCostTypeMeasure,
    deselectAllCostReductionOpportunitiesCostTypes,
    deselectAllCostReductionOpportunitiesStoreGroups,
    deselectAllCostReductionOpportunitiesRegions,
    setTotalCostTrendsOverTimeLineChartGranularity
} = costOverviewSlice.actions;

export const loadCostOverview = createAppAsyncThunk(
    "customer/insights/cost/costOverview/loadCostOverview",
    async (arg, thunkAPI) => {
        try {
            await thunkAPI.dispatch(setupCube());
            const state = thunkAPI.getState();

            const referenceDate = selectReferenceDate(state);
            const costTypes = selectCostTypes(state);
            const storeGroups = selectStoreGroups(state);
            const regions = selectRegions(state);

            const monthlyCostsPromise = thunkAPI.dispatch(loadMonthlyCosts(referenceDate));
            const monthlyRevenuePromise = thunkAPI.dispatch(loadMonthlyRevenue(referenceDate));
            const results = await Promise.all([
                monthlyCostsPromise,
                monthlyRevenuePromise
            ]);
            const monthlyCosts = results[0];
            const monthlyRevenue = results[1];

            const costOverTimeCostTypes = costTypes.map(costType => {
                return new CostTypeMultiSelect(
                    costType,
                    true
                );
            });

            const costReductionOpportunitiesCostTypes = costTypes.map(costType => {
                return new CostTypeMultiSelect(
                    costType,
                    true
                );
            });

            const costReductionOpportunitiesStoreGroups = storeGroups.map(storeGroup => {
                return new StoreGroupMultiSelect(
                    storeGroup,
                    true
                );
            });

            const costReductionOpportunitiesRegions = regions.map(region => {
                return new RegionMultiSelect(
                    region,
                    true
                );
            });

            const loadCostResponse: LoadCostOverviewResponse = {
                monthlyCosts,
                monthlyRevenue,
                costOverTimeCostTypes,
                costReductionOpportunitiesCostTypes,
                costReductionOpportunitiesStoreGroups,
                costReductionOpportunitiesRegions
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading CostOverview."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearCostOverview = (): AppThunk => (dispatch) => {
    dispatch(costOverviewSlice.actions.clearMonthlyCosts());
    dispatch(costOverviewSlice.actions.clearMonthlyRevenue());
    dispatch(costOverviewSlice.actions.clearCostOverTimeCostTypes());
    dispatch(costOverviewSlice.actions.clearCostTypeMeasure());
    dispatch(costOverviewSlice.actions.clearCostReductionOpportunitiesCostTypes());
    dispatch(costOverviewSlice.actions.clearCostReductionOpportunitiesStoreGroups());
    dispatch(costOverviewSlice.actions.clearCostReductionOpportunitiesRegions());
    dispatch(costOverviewSlice.actions.clearTotalCostTrendsOverTimeLineChartGranularity());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.costOverview.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.costOverview.hasErrors;
};

export const selectMonthlyCosts = (state: RootState) => {
    return state.customer.insights.cost.costOverview.monthlyCosts;
};

export const selectMonthlyRevenue = (state: RootState) => {
    return state.customer.insights.cost.costOverview.monthlyRevenue;
};

export const selectCostOverTimeCostTypes = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costOverTimeCostTypes;
};

export const selectCostTypeMeasure = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costTypeMeasure;
};

export const selectCostReductionOpportunitiesCostTypes = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costReductionOpportunitiesCostTypes;
};

export const selectCostReductionOpportunitiesStoreGroups = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costReductionOpportunitiesStoreGroups;
};

export const selectCostReductionOpportunitiesRegions = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costReductionOpportunitiesRegions;
};

export const selectTotalCostTrendsOverTimeLineChartGranularity = (state: RootState) => {
    return state.customer.insights.cost.costOverview.totalCostTrendsOverTimeLineChartGranularity;
};

export const selectPastTwelveMonthsCosts = createSelector(
    (state: RootState) => selectReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (costReferenceDate, monthlyCosts) => {
        if (!monthlyCosts) {
            return 0;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 });

        return monthlyCosts.filter(costs => costs.date > twelveMonthsPriorReferenceDate)
            .reduce((total, item) => item.costValue + total, 0);
    }
);

export const selectPreviousYearCosts = createSelector(
    (state: RootState) => selectReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (costReferenceDate, monthlyCosts) => {
        if (!monthlyCosts) {
            return 0;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 });
        const twentyFourMonthsPriorReferenceDate = costReferenceDate.minus({ months: 24 });

        return monthlyCosts.filter(costs => (costs.date > twentyFourMonthsPriorReferenceDate)
            && (costs.date <= twelveMonthsPriorReferenceDate))
            .reduce((total, item) => item.costValue + total, 0);
    }
);

export const selectLatestMonthAndPriorYearTotalCosts = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (isLoading, hasErrors, costsReferenceDate, monthlyCosts) => {
        interface LatestMonthAndPriorYearTotalCosts {
            latestMonthDate: DateTime
            latestMonthTotalCosts: number
            yearPriorDate: DateTime
            yearPriorTotalCosts: number
        }

        const latestMonthAndPriorYearTotalCosts: DataWrapper<LatestMonthAndPriorYearTotalCosts> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                latestMonthDate: DateTime.fromMillis(0, { zone: "utc" }),
                latestMonthTotalCosts: 0,
                yearPriorDate: DateTime.fromMillis(0, { zone: "utc" }),
                yearPriorTotalCosts: 0
            }
        };

        if (!costsReferenceDate || !monthlyCosts || latestMonthAndPriorYearTotalCosts.hasErrors || latestMonthAndPriorYearTotalCosts.isLoading) {
            return latestMonthAndPriorYearTotalCosts;
        }

        const latestMonth = costsReferenceDate.startOf("month");
        const latestMonthTotalCosts = monthlyCosts.filter(costs => costs.date.equals(latestMonth))
            .reduce((total, item) => item.costValue + total, 0);

        const priorYearDate = costsReferenceDate.minus({ months: 12 }).startOf("month");
        const priorYearTotalCosts = monthlyCosts.filter(costs => costs.date.equals(priorYearDate))
            .reduce((total, item) => item.costValue + total, 0);

        latestMonthAndPriorYearTotalCosts.data.latestMonthDate = latestMonth;
        latestMonthAndPriorYearTotalCosts.data.latestMonthTotalCosts = latestMonthTotalCosts;
        latestMonthAndPriorYearTotalCosts.data.yearPriorDate = priorYearDate;
        latestMonthAndPriorYearTotalCosts.data.yearPriorTotalCosts = priorYearTotalCosts;

        return latestMonthAndPriorYearTotalCosts;
    }
);

export const selectPastTwelveMonthsPercentageOfRevenue = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectReferenceDate(state),
    (state: RootState) => selectMonthlyRevenue(state),
    (state: RootState) => selectPastTwelveMonthsCosts(state),
    (state: RootState) => selectPreviousYearCosts(state),
    (isLoading, hasErrors, costReferenceDate, monthlyRevenue, pastTwelveMonthsTotalCosts, previousYearTotalCosts) => {
        interface PastTwelveMonthsPercentageOfRevenue {
            pastTwelveMonthsPercentage: number
            previousYearPercentage: number
            difference: number
        }

        const pastTwelveMonthsPercentageOfRevenue: DataWrapper<PastTwelveMonthsPercentageOfRevenue> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                pastTwelveMonthsPercentage: 0,
                previousYearPercentage: 0,
                difference: 0
            }
        };

        if (!monthlyRevenue ||
            !pastTwelveMonthsTotalCosts ||
            !previousYearTotalCosts ||
            pastTwelveMonthsPercentageOfRevenue.hasErrors ||
            pastTwelveMonthsPercentageOfRevenue.isLoading) {
            return pastTwelveMonthsPercentageOfRevenue;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 }).startOf("month");
        const pastTwelveMonthsRevenue = monthlyRevenue.filter(revenue => revenue.date > twelveMonthsPriorReferenceDate)
            .reduce((total, item) => item.revenue + total, 0);

        const twentyFourMonthsPriorReferenceDate = costReferenceDate.minus({ months: 24 }).startOf("month");
        const yearPriorRevenue = monthlyRevenue.filter(revenue => (revenue.date <= twelveMonthsPriorReferenceDate)
            && (revenue.date > twentyFourMonthsPriorReferenceDate))
            .reduce((total, item) => item.revenue + total, 0);

        pastTwelveMonthsPercentageOfRevenue.data.pastTwelveMonthsPercentage = mathUtils.safePercentage(pastTwelveMonthsTotalCosts, pastTwelveMonthsRevenue);
        pastTwelveMonthsPercentageOfRevenue.data.previousYearPercentage = mathUtils.safePercentage(previousYearTotalCosts, yearPriorRevenue);
        pastTwelveMonthsPercentageOfRevenue.data.difference = pastTwelveMonthsPercentageOfRevenue.data.pastTwelveMonthsPercentage - pastTwelveMonthsPercentageOfRevenue.data.previousYearPercentage;

        return pastTwelveMonthsPercentageOfRevenue;
    }
);

export interface TotalCostOverTime {
    date: DateTime,
    group: string,
    costValue: number,
    costPercentageOfRevenue: number
}

export const selectTotalCostTrendsOverTime = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCostOverTimeCostTypes,
    selectTotalCostTrendsOverTimeLineChartGranularity,
    (state: RootState) => selectMonthlyCosts(state),
    (state: RootState) => selectMonthlyRevenue(state),
    (state: RootState) => selectCostTypeMeasure(state),
    (isLoading, hasErrors, costTypes, totalCostTrendsOverTimeLineChartGranularity, monthlyCosts, monthlyRevenue, costTypeMeasure) => {
        const selectedCostTypes = costTypes.filter(costType => costType.isSelected()).map(type => type.name);
        const costs = monthlyCosts.filter(costs => selectedCostTypes.includes(costs.costType));

        const totalCostsOverTime: TotalCostOverTime[] = [];

        costs.forEach(cost => {
            const revenue = monthlyRevenue.find(x => x.date.equals(cost.date));

            totalCostsOverTime.push({
                group: cost.group,
                date: cost.date,
                costValue: cost.costValue,
                costPercentageOfRevenue: mathUtils.safePercentage(cost.costValue, (revenue ? revenue.revenue : 0))
            });
        });

        if (totalCostTrendsOverTimeLineChartGranularity === TotalCostTrendsOverTimeLineChartGranularity.Month) {
            return _(totalCostsOverTime).groupBy(costOverTime => costOverTime.group).value();
        }
        else if (totalCostTrendsOverTimeLineChartGranularity === TotalCostTrendsOverTimeLineChartGranularity.Year) {
            const groupedYearlyTotalCostsOverTime = _(totalCostsOverTime)
                .groupBy(costOverTime => costOverTime.group)
                .map((group, storeGroupKey) => {
                    const yearlyTotalCostsOverTime = _(group).groupBy(costs => costs.date.year)
                        .map((yearlyCosts, yearKey) => {
                            const totalCosts = yearlyCosts.reduce((total, item) => item.costValue + total, 0);
                            const revenue = monthlyRevenue.filter(x => x.date.year === DateTime.fromISO(yearKey).year);
                            const totalRevenue = revenue?.reduce((total, item) => item.revenue + total, 0);
                            return {
                                group: storeGroupKey,
                                date: DateTime.fromISO(yearKey),
                                costValue: totalCosts,
                                costPercentageOfRevenue: mathUtils.safePercentage(totalCosts, totalRevenue)
                            };
                        })
                        .value();

                    return {
                        yearlyTotalCostsOverTime
                    };
                })
                .value();

            const yearlyResult: TotalCostOverTime[] = [];
            groupedYearlyTotalCostsOverTime.forEach(yearlyCost => {
                yearlyCost.yearlyTotalCostsOverTime.forEach(totalCostOverTime => {
                    yearlyResult.push({
                        date: totalCostOverTime.date,
                        group: totalCostOverTime.group,
                        costValue: totalCostOverTime.costValue,
                        costPercentageOfRevenue: totalCostOverTime.costPercentageOfRevenue
                    });
                });
            });

            return _(yearlyResult).groupBy(yearlyCosts => yearlyCosts.group).value();
        }
        else {
            const groupedQuarterlyTotalCostsOverTime = _(totalCostsOverTime)
                .groupBy(costOverTime => costOverTime.group)
                .map((group, storeGroupKey) => {
                    const quarterlyGroupedTotalCostsOverTime = group.reduce<Record<string, TotalCostOverTime[]>>((group, item) => {
                        const date = item.date;
                        const year = date.year;
                        const quarter = Math.ceil(date.month / 3);
                        const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                        const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                        if (!group[key]) {
                            group[key] = [];
                        }
                        group[key].push(item);

                        return group;
                    }, {});

                    const quarterlyTotalCostsOverTime = _(quarterlyGroupedTotalCostsOverTime)
                        .map((quarterGroup, quarterKey) => {
                            const totalCosts = quarterGroup.reduce((total, item) => item.costValue + total, 0);
                            const revenue = monthlyRevenue.filter(x => x.date >= DateTime.fromISO(quarterKey) && x.date < DateTime.fromISO(quarterKey).plus({ months: 3 }));
                            const totalRevenue = revenue?.reduce((total, item) => item.revenue + total, 0);

                            return {
                                group: storeGroupKey,
                                date: DateTime.fromISO(quarterKey),
                                costValue: totalCosts,
                                costPercentageOfRevenue: mathUtils.safePercentage(totalCosts, totalRevenue)
                            };
                        })
                        .value();

                    return {
                        quarterlyTotalCostsOverTime
                    };
                })
                .value();

            const quarterlyResult: TotalCostOverTime[] = [];
            groupedQuarterlyTotalCostsOverTime.forEach(quarterlyCost => {
                quarterlyCost.quarterlyTotalCostsOverTime.forEach(totalCostOverTime => {
                    quarterlyResult.push({
                        date: totalCostOverTime.date,
                        group: totalCostOverTime.group,
                        costValue: totalCostOverTime.costValue,
                        costPercentageOfRevenue: totalCostOverTime.costPercentageOfRevenue
                    });
                });
            });

            return _(quarterlyResult).groupBy(quarterlyCosts => quarterlyCosts.group).value();
        }
    }
);

export const selectCostReductionOpportunity = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCostReductionOpportunitiesCostTypes,
    selectCostReductionOpportunitiesStoreGroups,
    selectCostReductionOpportunitiesRegions,
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (isLoading, hasErrors, costReductionOpportunitiesCostTypes, costReductionOpportunitiesStoreGroups, costReductionOpportunitiesRegions, costReductionOpportunityByStore) => {
        const selectedCostTypes = costReductionOpportunitiesCostTypes.filter(costType => costType.isSelected()).map(type => type.name);
        const selectedStoreGroups = costReductionOpportunitiesStoreGroups.filter(storeGroup => storeGroup.isSelected()).map(type => type.name);
        const selectedRegions = costReductionOpportunitiesRegions.filter(region => region.isSelected()).map(type => type.name);

        return {
            isLoading: costReductionOpportunityByStore.isLoading,
            hasErrors: costReductionOpportunityByStore.hasErrors,
            data: _(costReductionOpportunityByStore.data)
                .filter(x => x.opportunityValue >= 0
                    && x.group !== undefined
                    && x.region !== undefined
                    && selectedCostTypes.includes(x.costName)
                    && selectedStoreGroups.includes(x.group)
                    && selectedRegions.includes(x.region)
                )
                .groupBy(x => x.group).value()
        };
    }
);

export default costOverviewSlice;
