import React from "react";

import { Box } from "@material-ui/core";

import { ChapterProps } from "./Chapter";

interface TabPanelProps {
    hidden: boolean,
    children: React.ReactElement<ChapterProps>
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { hidden, children: chapter } = props;
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        ref?.current?.scrollTo(0, 0);
    }, [hidden]);

    return (
        <Box
            role="tabpanel"
            hidden={hidden}
            maxHeight="calc(100vh - 168px)"
            overflow="auto"
            // @ts-ignore
            ref={ref}
        >
            {!hidden && chapter}
        </Box>
    );
};

export default TabPanel;
