import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Uncover whether your customers come from shared or new origins. Evaluate the trade-off between gaining new
            customers and cannibalising existing ones. Understand overlapping catchment areas and gain insights into
            which stores in your existing portfolio would be most affected.
        </>
    );
};

export default Subtitle;
