import React from "react";
import { Grid } from "@material-ui/core";

import SpendPerHeadCatchmentArea from "./SpendPerHeadCatchmentArea";
import LargestSpendPerHead from "./LargestSpendPerHead";
import MarketCatSpendPerHeadMap from "./MarketCatSpendPerHeadMap";
import MarketCategoriesRagIndicator from "./MarketCategoriesRagIndicator";
import MarketCategoriesFilter from "./MarketCategoriesFilter";

const CatchmentAreaCustomerProfiles = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MarketCategoriesFilter />
            </Grid>
            <Grid item xs={12} md={6}>
                <SpendPerHeadCatchmentArea />
            </Grid>
            <Grid item xs={12} md={6}>
                <LargestSpendPerHead />
            </Grid>
            <Grid item xs={12}>
                <MarketCatSpendPerHeadMap />
            </Grid>
            <Grid item xs={12}>
                <MarketCategoriesRagIndicator />
            </Grid>
        </Grid>
    );
};

export default CatchmentAreaCustomerProfiles;
