import React from "react";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <Box display="flex" alignItems="center">
            <InfoOutlinedIcon />
            &nbsp;&nbsp;
            <Typography variant="h5" component="div">
                Location information
            </Typography>
        </Box>
    );
};

export default Title;
