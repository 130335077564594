import React from "react";

import { Box, Typography } from "@material-ui/core";


const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Cost setup
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    To begin analysing your costs, select the store and cost type you'd like to explore.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
