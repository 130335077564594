import React from "react";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import highchartsHeatmap from "highcharts/modules/heatmap";

import ChartBase from "./ChartBase";

highchartsHeatmap(Highcharts);

interface HeatmapProps {
    dataCy?: string,
    loading: boolean,
    error: boolean,
    options: Highcharts.Options
}

const Heatmap: React.FC<HeatmapProps> = (props: HeatmapProps) => {
    const theme = useTheme();
    const { dataCy, loading, error, options: customOptions } = props;

    const setColorAxisWidth = (chart: Highcharts.Chart): void => {
    //Aligns colour axis with the plot width
        if (chart.legend.options.symbolWidth !== chart.plotWidth) {
            chart.legend.update({
                symbolWidth: chart.plotWidth
            });
        }
    };

    const staticOptions: Highcharts.Options = {
        chart: {
            events: {
                load: function () {
                    setColorAxisWidth(this);
                },
                redraw: function () {
                    setColorAxisWidth(this);
                }
            }
        },
        colorAxis: {
            min: 0,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.palette.common.white,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "14px"
                }
            }
        },
        yAxis: {
            reversed: true,
            title: {
                text: undefined
            },
            gridLineWidth: 0
        },
        xAxis: {
            lineWidth: 0,
            tickWidth: 0
        },
        legend: {
            align: "right",
            layout: "horizontal",
            verticalAlign: "bottom",
            x: 5
        },
        series: [{
            type: "heatmap",
            borderWidth: 1,
            borderRadius: 0,
            marker: {
                // @ts-ignore
                lineColor: theme.palette.background.insight
            },
            dataLabels: {
                enabled: true,
                style: {
                    color: theme.palette.common.white,
                    fontFamily: theme.typography.body2.fontFamily,
                    fontSize: "12px"
                }
            }
        }]
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};


export default Heatmap;
