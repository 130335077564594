import React from "react";
import { Box, Container, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const PageSection = (props) => {
    const { dataCy, color, bgcolor } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            color={color}
            bgcolor={bgcolor}
            data-cy={dataCy}
            position="relative"
            maxWidth="100vw"
            paddingRight="16"
        >
            <Container maxWidth={false} disableGutters={isMobile}>
                {props.children}
            </Container>
        </Box>
    );
};

PageSection.propTypes = {
    dataCy: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string,
    maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    disablePaddingTop: PropTypes.bool,
    disablePaddingBottom: PropTypes.bool
};

PageSection.defaultProps = {
    color: "inherit",
    bgcolor: "inherit",
    maxWidth: "lg",
    top: false,
    bottom: false,
    disablePaddingTop: false,
    disablePaddingBottom: false
};

export default PageSection;
