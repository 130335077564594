import React from "react";

import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearAdmin,
    deleteAdmin,
    getAdmin,
    hideDeleteAdmin,
    selectDeleteAdminVisibility,
    selectAdmin,
    showDeleteAdmin
} from "modules/admin/admins/adminsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteAdminProps {
    adminId: string;
}

const DeleteAdmin: React.FC<DeleteAdminProps> = (props) => {
    const { adminId } = props;
    const dispatch = useAppDispatch();
    const admin = useAppSelector(selectAdmin);
    const deleteAdminVisibility = useAppSelector(selectDeleteAdminVisibility);
    const show = (deleteAdminVisibility.isVisible) && (adminId === deleteAdminVisibility.adminId);

    React.useEffect(() => {
        if (show) {
            dispatch(getAdmin(adminId));
        }
    }, [dispatch, show, adminId]);

    const handleDeleteAdminClick = () => {
        dispatch(showDeleteAdmin(adminId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteAdmin());
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteAdmin());
        dispatch(clearAdmin());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteAdminClick}>
                    <DeleteRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <ConfirmationDialog
                open={show}
                title="Are you sure you want to delete this admin?"
                subtitle={`${admin.firstName} ${admin.lastName} (${admin.email})`}
                type={ConfirmationDialogType.Error}
                confirmLabel="Yes, delete"
                cancelLabel="No, cancel"
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
            />
        </>
    );
};

export default DeleteAdmin;
