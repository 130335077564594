import React from "react";
import { Grid } from "@material-ui/core";

import StoreVsComparatorRankedLastTwelveMonthsGrowth from "./StoreVsComparatorRankedLastTwelveMonthsGrowth";
import RankedGrowthClassification from "./RankedGrowthClassification";

const RankedRevenueGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreVsComparatorRankedLastTwelveMonthsGrowth />
            </Grid>
            <Grid item xs={12}>
                <RankedGrowthClassification />
            </Grid>
        </Grid>
    );
};

export default RankedRevenueGrowth;
