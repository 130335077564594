import React from "react";
import { Box, Typography } from "@material-ui/core";
import numberFormatter from "utils/numberFormatter";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectYearlyGrossProfit } from "modules/customer/insights/portfolioNew/profit/profitSlice";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import mathUtils from "utils/mathUtils";

const GrossProfitLastYear = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const yearlyGrossProfit = useAppSelector(selectYearlyGrossProfit);
    const { isLoading, hasErrors, data } = yearlyGrossProfit;
    const valueFormatted = numberFormatter.toGBP(data.selectedStoreYearlyProfit, 1);
    const comparisonValue = numberFormatter.toGBP(data.selectedComparatorYearlyProfit, 1);
    const differenceValue = mathUtils.safePercentageChange(data.selectedStoreYearlyProfit, data.selectedComparatorYearlyProfit);
    const differencePositiveSign = differenceValue > 0 ? "+" : "";
    const label = `${selectedStore?.name} Gross Profit over past 12 months`;

    return (
        <Box width="100%" height="100%" data-cy="gross-profit-last-year">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {valueFormatted}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparisonValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${selectedComparator?.name} `}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {differencePositiveSign}{numberFormatter.toPercentage(differenceValue, true, 1)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default GrossProfitLastYear;
