const GET_STORE_YOY_GROWTH_VS_COMPARATOR_REQUEST = "spark/customer/insights/portfolio/sales/growth/GET_STORE_YOY_GROWTH_VS_COMPARATOR_REQUEST";
const GET_STORE_YOY_GROWTH_VS_COMPARATOR_SUCCESS = "spark/customer/insights/portfolio/sales/growth/GET_STORE_YOY_GROWTH_VS_COMPARATOR_SUCCESS";
const GET_STORE_YOY_GROWTH_VS_COMPARATOR_FAILURE = "spark/customer/insights/portfolio/sales/growth/GET_STORE_YOY_GROWTH_VS_COMPARATOR_FAILURE";
const GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_REQUEST = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_REQUEST";
const GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_SUCCESS = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_SUCCESS";
const GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_FAILURE = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_FAILURE";
const GET_STORE_VS_COMPARATOR_MOM_GROWTH_REQUEST = "spark/customer/insights/portfolio/sales/growth/GET_STORE_VS_COMPARATOR_MOM_GROWTH_REQUEST";
const GET_STORE_VS_COMPARATOR_MOM_GROWTH_SUCCESS = "spark/customer/insights/portfolio/sales/growth/GET_STORE_VS_COMPARATOR_MOM_GROWTH_SUCCESS";
const GET_STORE_VS_COMPARATOR_MOM_GROWTH_FAILURE = "spark/customer/insights/portfolio/sales/growth/GET_STORE_VS_COMPARATOR_MOM_GROWTH_FAILURE";
const GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_REQUEST = "spark/customer/insights/portfolio/sales/growth/GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_REQUEST";
const GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_SUCCESS = "spark/customer/insights/portfolio/sales/growth/GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_SUCCESS";
const GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_FAILURE = "spark/customer/insights/portfolio/sales/growth/GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_FAILURE";
const GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_REQUEST = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_REQUEST";
const GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_SUCCESS = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_SUCCESS";
const GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_FAILURE = "spark/customer/insights/portfolio/sales/growth/GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_FAILURE";

const types = {
    GET_STORE_YOY_GROWTH_VS_COMPARATOR_REQUEST,
    GET_STORE_YOY_GROWTH_VS_COMPARATOR_SUCCESS,
    GET_STORE_YOY_GROWTH_VS_COMPARATOR_FAILURE,
    GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_REQUEST,
    GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_SUCCESS,
    GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_FAILURE,
    GET_STORE_VS_COMPARATOR_MOM_GROWTH_REQUEST,
    GET_STORE_VS_COMPARATOR_MOM_GROWTH_SUCCESS,
    GET_STORE_VS_COMPARATOR_MOM_GROWTH_FAILURE,
    GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_REQUEST,
    GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_SUCCESS,
    GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_FAILURE,
    GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_REQUEST,
    GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_SUCCESS,
    GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_FAILURE
};

export default types;
