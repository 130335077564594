import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/overview/keyPerformanceIndicators";

const GrossRevenueYTD = () => {
    const dispatch = useDispatch();
    const grossRevenueYTD = useSelector(state => selectors.grossRevenueYTD(state));
    const { loading, error, value, percentageDifference } = grossRevenueYTD;
    const label = "Revenue in the year-to-date vs the same period last year";
    const valueFormatted = numberFormatter.toGBP(value, 1);

    React.useEffect(() => {
        dispatch(operations.getGrossRevenueYTD());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="gross-revenue-ytd">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default GrossRevenueYTD;
