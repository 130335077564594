import React from "react";
import { useDispatch } from "react-redux";

import { AppBar, Box, Container, Link, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Logo from "./Logo";
import Navigation from "./Navigation";

import kpmgLogo from "assets/images/kpmg-logo-white.svg";

import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledAffiliationToolbar = withStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main,
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        [theme.breakpoints.up("xs")]: {
            backgroundImage: "url(/content/landing/header/banner-390.jpg)"
        },
        [theme.breakpoints.up("sm")]: {
            backgroundImage: "url(/content/landing/header/banner-600.jpg)"
        },
        [theme.breakpoints.up("md")]: {
            backgroundImage: "url(/content/landing/header/banner-960.jpg)"
        },
        [theme.breakpoints.up("lg")]: {
            backgroundImage: "url(/content/landing/header/banner-1280.jpg)",
        },
        [theme.breakpoints.up("xl")]: {
            backgroundImage: "url(/content/landing/header/banner-1920.jpg)",
        }
    }
}))(Toolbar);

const StyledAppBar = withStyles(theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.common.white
    }
}))(AppBar);

const StyledNavigationToolbar = withStyles(theme => ({
    root: {
        height: theme.spacing(9),
        [theme.breakpoints.up("md")]: {
            height: theme.spacing(10)
        }
    }
}))(Toolbar);

const Header = () => {
    const appBarRef = React.useRef(null);
    const dispatch = useDispatch();

    const handleLinkClick = () => {
        dispatch(trackEvent("KPMG logo", "KPMG logo click", "KPMG logo link"));
    };

    return (
        <>
            <StyledAffiliationToolbar>
                <Link
                    href="https://home.kpmg/uk/en/home/services/products.html"
                    target="_blank"
                    onClick={handleLinkClick}
                >
                    <Box width={80} height={32}>
                        <img src={kpmgLogo} alt="kpmg logo" />
                    </Box>
                </Link>
            </StyledAffiliationToolbar>
            <StyledAppBar position="sticky" elevation={0} ref={appBarRef}>
                <Container maxWidth="lg">
                    <StyledNavigationToolbar disableGutters>
                        <Logo />
                        <Spacer />
                        <Navigation appBarRef={appBarRef} />
                    </StyledNavigationToolbar>
                </Container>
            </StyledAppBar>
        </>
    );
};

export default Header;
