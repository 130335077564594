import { createSelector } from "reselect";

const avgBasketSizeCMPM = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.avgBasketSizeCMPM;
};

const avgBasketValueCMPM = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.avgBasketValueCMPM;
};

const categoryYTDSalesGrowth = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.categoryYTDSalesGrowth;
};

const categoriesGrowingOrDeclining = createSelector(
    categoryYTDSalesGrowth,
    (categoryYTDSalesGrowth) => {
        const rag = {
            loading: false,
            error: false,
            id: "categories-growing-or-declining",
            label: "Categories growing or declining",
            status: "info",
            value: ""
        };

        if (categoryYTDSalesGrowth.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (categoryYTDSalesGrowth.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const productCategoryGrowth = categoryYTDSalesGrowth.productCategoryGrowth;
        const numberProductCategoryPositiveGrowth = productCategoryGrowth.filter(p => p.y >= 0).length;
        const numberProductCategoryNegativeGrowth = productCategoryGrowth.filter(p => p.y < 0).length;

        const positiveGrowthCategories = (numberProductCategoryPositiveGrowth / productCategoryGrowth.length) * 100;
        const negativeGrowthCategories = (numberProductCategoryNegativeGrowth / productCategoryGrowth.length) * 100;

        if (positiveGrowthCategories >= 75) {
            rag.status = "success";
            rag.value = "Majority of your product categories are in growth";
        } else if (negativeGrowthCategories >= 75) {
            rag.status = "error";
            rag.value = "Majority of your product categories are in decline";
        } else {
            rag.status = "warning";
            rag.value = "Mixed performance of your product categories";
        }
        return rag;
    }
);

const categoryCMSales = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.categoryCMSales;
};

const categoryCMSalesGrowth = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.categoryCMSalesGrowth;
};

const categoryYTDSales = (state) => {
    return state.customer.insights.performance.sales.productCategoryBreakdown.categoryYTDSales;
};

const selectors = {
    avgBasketSizeCMPM,
    avgBasketValueCMPM,
    categoriesGrowingOrDeclining,
    categoryCMSales,
    categoryCMSalesGrowth,
    categoryYTDSales,
    categoryYTDSalesGrowth
};

export default selectors;
