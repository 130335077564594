import React from "react";

import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import SubchapterHeader from "./SubchapterHeader";

export interface SubchapterProps {
    id: string,
    number: number,
    title: string,
    subtitle?: string | React.ReactElement,
    subchapterRef: React.RefObject<HTMLDivElement>,
    dataCy: string,
    children?: React.ReactElement
}

const Subchapter: React.FC<SubchapterProps> = (props) => {
    const { id, number, title, subtitle, subchapterRef, dataCy } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const scrollMarginTop = isMobile ? theme.spacing(18) : theme.spacing(3);

    return (
        // @ts-ignore
        <Box id={id} paddingBottom={8} ref={subchapterRef} data-cy={dataCy} style={{ scrollMarginTop: scrollMarginTop }}>
            <SubchapterHeader number={number} title={title} subtitle={subtitle} />
            {props.children}
        </Box>
    );
};

export default Subchapter;
