import React from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { selectShowSimilarityScores } from "modules/customer/insights/portfolioNew/portfolioSlice";
import {
    selectCustomSelectionStoresSort,
    setCustomSelectionStoresSort,
    CustomSelectionStoreSortField
} from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import { SortDirection } from "utils/sortUtils";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(40)
    }
}))(Menu);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

const Sort: React.FC = () => {
    const dispatch = useAppDispatch();
    const customSelectionStoresSort = useAppSelector(selectCustomSelectionStoresSort);
    const showSimilarityScores = useAppSelector(selectShowSimilarityScores);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSortClick = (field: CustomSelectionStoreSortField, sort: SortDirection) => () => {
        let direction = sort;
        if (customSelectionStoresSort.field === field) {
            direction = customSelectionStoresSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newCustomSelectionStoresSort = { ...customSelectionStoresSort, field, direction };
        dispatch(setCustomSelectionStoresSort(newCustomSelectionStoresSort));
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                color="default"
                disableElevation
                startIcon={<ArrowDownwardIcon />}
                onClick={handleClick}
                data-cy="btn-sort"
            >
                Sort
            </Button>
            <StyledMenu
                open={open}
                onClose={handleClose}
                variant="menu"
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {(showSimilarityScores === true) && (<StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.Similarity, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.Similarity}>
                    <Typography variant="body1" component="div">Similarity</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.Similarity || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>)}
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.RadarColour, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.RadarColour}>
                    <Typography variant="body1" component="div">Radar colour</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.RadarColour || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.StoreName, SortDirection.ASC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.StoreName}>
                    <Typography variant="body1" component="div">
                        Store name
                    </Typography>
                    <Spacer />
                    <Typography variant="body1" component="div">
                        {customSelectionStoresSort.field !== CustomSelectionStoreSortField.StoreName || customSelectionStoresSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                    </Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.OpeningDate, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.OpeningDate}>
                    <Typography variant="body1" component="div">Opening date</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.OpeningDate || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.StoreSize, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.StoreSize}>
                    <Typography variant="body1" component="div">Store size</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.StoreSize || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.Employees, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.Employees}>
                    <Typography variant="body1" component="div">Employees</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.Employees || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.Revenue, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.Revenue}>
                    <Typography variant="body1" component="div">Revenue in the last year</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.Revenue || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.GrossProfitMargin, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.GrossProfitMargin}>
                    <Typography variant="body1" component="div">Gross profit (%)</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.GrossProfitMargin || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.RevenuePerSquareFoot, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.RevenuePerSquareFoot}>
                    <Typography variant="body1" component="div">Revenue per square foot</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.RevenuePerSquareFoot || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.CatchmentSize, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.CatchmentSize}>
                    <Typography variant="body1" component="div">Catchment size (population)</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.CatchmentSize || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.NumberOfCompetitors, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.NumberOfCompetitors}>
                    <Typography variant="body1" component="div">Number of competitors within 5km</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.NumberOfCompetitors || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.ChangeInNumberOfStores, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.ChangeInNumberOfStores}>
                    <Typography variant="body1" component="div">Change in number of stores</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.ChangeInNumberOfStores || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(CustomSelectionStoreSortField.FootfallLevel, SortDirection.DESC)} data-current={customSelectionStoresSort.field === CustomSelectionStoreSortField.FootfallLevel}>
                    <Typography variant="body1" component="div">Footfall level</Typography>
                    <Spacer />
                    {customSelectionStoresSort.field !== CustomSelectionStoreSortField.FootfallLevel || customSelectionStoresSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
};

export default Sort;
