import React from "react";
import { Box } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectLocalDemandAndSupply } from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";

import useColourPalette from "components/visuals/useColourPalette";
import MagicQuadrant from "components/visuals/MagicQuadrant";

import numberFormatter from "utils/numberFormatter";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";

interface ComparatorData {
    name: string,
    x: number,
    y: number,
    custom: { storeName: string }
}

const Chart = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const localDemandAndSupply = useAppSelector(selectLocalDemandAndSupply);

    const selectedComparatorStores = selectedComparator?.getStores();
    const comparatorData: ComparatorData[] = [];
    selectedComparatorStores?.forEach(store => {
        comparatorData.push({
            name: store.name,
            x: localDemandAndSupply.data.find(comparator => comparator.storeId === store.id)?.supply ?? 0,
            y: localDemandAndSupply.data.find(comparator => comparator.storeId === store.id)?.demand ?? 0,
            custom: { storeName: store.name }
        });
    });

    const doQuadrants = (comparatorData.length > 1);

    let xMidPoint = 0;
    let yMidPoint = 0;

    if (doQuadrants) {
        xMidPoint = arrayUtils.median(comparatorData.map(row => row.x));
        yMidPoint = arrayUtils.median(comparatorData.map(row => row.y));
    }

    const options = {
        xAxis: {
            title: {
                text: "Supply (number of competing stores)"
            },
            labels: {
                enabled: true
            },
            reversed: true
        },
        yAxis: {
            title: {
                text: "Demand (£ per year)"
            },
            labels: {
                enabled: true,
                // @ts-ignore
                formatter: function () {
                    // @ts-ignore //ToDo: check type here
                    return numberFormatter.toGBP(this.value, 0);
                }
            }
        },
        series: [{
            name: selectedStore?.name,
            data: [{
                name: selectedStore?.name,
                x: localDemandAndSupply.data.find(comparator => comparator.storeId === selectedStore?.id)?.supply ?? 0,
                y: localDemandAndSupply.data.find(comparator => comparator.storeId === selectedStore?.id)?.demand ?? 0,
                custom: { storeName: selectedStore?.name }
            }],
            color: colourPalette.comparators[0],
            zIndex: 1
        }, {
            name: selectedComparator?.name,
            data: comparatorData,
            color: colourPalette.comparators[1]
        }],

        tooltip: {
            headerFormat: "<table>",
            pointFormatter: function (): any {
                // @ts-ignore
                const series = this.series;
                const seriesName = series.name;
                // @ts-ignore
                const storeName = this.custom.storeName;
                const seriesColor = series.color;
                const xAxisTitle = series.xAxis.options.title.text;
                const yAxisTitle = series.yAxis.options.title.text;

                // @ts-ignore
                const x = this.x;
                // @ts-ignore
                const y = this.y;

                return stringUtils.tooltipHTML([`${xAxisTitle}:`, `${yAxisTitle}:`], {
                    header: (storeName === seriesName) ? `${seriesName}` : `${seriesName}:<br>${storeName}`,
                    values: [numberFormatter.toDecimalPlaces(x, 0), numberFormatter.toGBP(y, 2)],
                    headerFormatting: `color:${seriesColor}`
                });
            },
            footerFormat: "</table>",
            useHTML: true
        }

    };

    return (
        <Box data-cy="supply-and-demand-magic-quadrant">
            <MagicQuadrant
                loading={localDemandAndSupply.isLoading}
                error={localDemandAndSupply.hasErrors}
                options={options}
                topLeftLabel="COMPETITIVE MARKET"
                topRightLabel="UNTAPPED MARKET"
                bottomRightLabel="BARREN MARKET"
                bottomLeftLabel="SATURATED MARKET"
                doQuadrants={doQuadrants}
                xMidPoint={xMidPoint}
                yMidPoint={yMidPoint}
                xAxisReversed={true}
                padBelowZero={true}
            />
        </Box>
    );
};

export default Chart;
