import React from "react";
import { Expression, FillPaint } from "mapbox-gl";
import { Layer, LayerProps, MapEvent, MapRef, Source, SourceProps, ViewportProps } from "react-map-gl";

import useColourPalette from "components/visuals/useColourPalette";

import MapBase from "./MapBase";

const REGIONS_LAYER_ID = "regions";

interface MapRegion {
    code: string,
    colourGroup: number
}

export interface PointerInfo {
    regionCode?: string,
    latitude: number,
    longitude: number
}

export const pointerInfoEmpty: PointerInfo = {
    regionCode: undefined,
    latitude: 0,
    longitude: 0
};

const initialViewport: ViewportProps = {
    latitude: 52.8,
    longitude: -1.4,
    zoom: 5.5
};

interface RegionsMapProps {
    loading: boolean,
    error: boolean,
    title?: string,
    mapboxAccessToken: string,
    mapboxBaseMapStyle: string,
    mapboxRegionsTilesetId: string,
    mapboxRegionsTilesetUrl: string,
    regions: MapRegion[],
    onHover?: (pointerInfo: PointerInfo) => void,
    onClick?: (pointerInfo: PointerInfo) => void,
    downloadData?:object[]
    children?: React.ReactNode
}

const RegionsMap: React.FC<RegionsMapProps> = (props) => {
    const {
        loading, error, title,
        mapboxAccessToken, mapboxBaseMapStyle, mapboxRegionsTilesetId, mapboxRegionsTilesetUrl,
        regions,
        onHover, onClick,
        downloadData
    } = props;
    const colourPalette = useColourPalette();
    const colours = colourPalette.heatmap;
    const mapRef = React.useRef<MapRef>(null);
    const [hoverInfo, setHoverInfo] = React.useState<PointerInfo>(pointerInfoEmpty);
    const [clickInfo, setClickInfo] = React.useState<PointerInfo>(pointerInfoEmpty);

    const regionsSource = React.useMemo((): SourceProps => {
        return {
            type: "vector",
            url: mapboxRegionsTilesetUrl
        };
    }, [mapboxRegionsTilesetUrl]);

    const fillColor = React.useMemo(() => {
        let fillColor: string | Expression = "rgba(0,0,0,0)";
        if (regions.length > 0) {
            fillColor = ["match", ["get", "geo_code"]];
            for (const region of regions) {
                fillColor.push(region.code, colours[region.colourGroup]);
            }
            fillColor.push("rgba(0, 0, 0, 0)");
        }
        return fillColor;
    }, [regions, colours]);

    const fillOpacity = React.useMemo(() => {
        let fillOpacity: number | Expression = 0.4;
        if (!hoverInfo.regionCode && !clickInfo.regionCode) {
            return fillOpacity;
        }
        fillOpacity = ["match", ["get", "geo_code"]];
        if (hoverInfo.regionCode) {
            fillOpacity.push(hoverInfo.regionCode, 0.7);
        }
        if (clickInfo.regionCode && clickInfo.regionCode !== hoverInfo.regionCode) {
            fillOpacity.push(clickInfo.regionCode, 0.7);
        }
        fillOpacity.push(0.4);
        return fillOpacity;
    }, [hoverInfo, clickInfo]);

    const regionsLayer = React.useMemo(() => {
        const paint: FillPaint = {
            "fill-color": fillColor,
            "fill-opacity": fillOpacity
        };
        const layer: LayerProps = {
            id: REGIONS_LAYER_ID,
            type: "fill",
            "source-layer": mapboxRegionsTilesetId,
            paint
        };
        return layer;
    }, [fillColor, fillOpacity, mapboxRegionsTilesetId]);

    const handlePointerEvent = React.useCallback((event: MapEvent): PointerInfo => {
        const { features, lngLat } = event;
        const regionCode = features?.find(f => f.layer.id === REGIONS_LAYER_ID)?.properties["geo_code"];
        if (!regions.some(region => region.code === regionCode)) {
            return pointerInfoEmpty;
        }
        return {
            regionCode,
            latitude: lngLat[1],
            longitude: lngLat[0]
        };
    }, [regions]);

    const handleClick = React.useCallback((event: MapEvent) => {
        const pointerInfo = handlePointerEvent(event);
        const newPointerInfo = pointerInfo.regionCode === clickInfo.regionCode ? pointerInfoEmpty : pointerInfo;
        setClickInfo(newPointerInfo);
        onClick && onClick(newPointerInfo);
    }, [handlePointerEvent, clickInfo, setClickInfo, onClick]);

    const handleHover = React.useCallback((event: MapEvent) => {
        const pointerInfo = handlePointerEvent(event);
        setHoverInfo(pointerInfo);
        onHover && onHover(pointerInfo);
    }, [handlePointerEvent, setHoverInfo, onHover]);

    const handleMouseOut = React.useCallback(() => {
        setHoverInfo(pointerInfoEmpty);
        onHover && onHover(pointerInfoEmpty);
    }, [setHoverInfo, onHover]);

    return (
        <MapBase
            loading={loading}
            error={error}
            title={title}
            mapboxAccessToken={mapboxAccessToken}
            mapboxBaseMapStyle={mapboxBaseMapStyle}
            mapRef={mapRef}
            initialViewport={initialViewport}
            addGeocoder={false}
            addNavigationControl={true}
            addRecenterButton={true}
            addFullscreenButton={true}
            interactiveLayerIds={[REGIONS_LAYER_ID]}
            onHover={handleHover}
            onClick={handleClick}
            onMouseOut={handleMouseOut}
            downloadData={downloadData}
            dataCy="regions-map"
        >
            <Source {...regionsSource}>
                <Layer {...regionsLayer} />
            </Source>
            {props.children}
        </MapBase>
    );
};

export default RegionsMap;
