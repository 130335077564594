import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { selectFrequentlyAskedQuestionsCategoriesWithQuestions } from "modules/customer/help/helpSlice";
import { useAppSelector } from "store";

const useStyles = makeStyles(theme => ({
    accordion: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    },
    expanded: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        backgroundColor: theme.palette.primary.main
    }
}));

const FrequentlyAskedQuestions: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const spacing = isMobile ? 3 : 6;
    const frequentlyAskedQuestionsCategoriesWithQuestions = useAppSelector(selectFrequentlyAskedQuestionsCategoriesWithQuestions);

    if (frequentlyAskedQuestionsCategoriesWithQuestions.length === 0) {
        return null;
    }

    return (
        <Box data-cy="pages-customer-help-faq">
            <br />
            <Typography variant="h4" component="div" gutterBottom>
                Frequently asked questions
            </Typography>
            <br />
            <br />
            <Grid container spacing={spacing}>
                {frequentlyAskedQuestionsCategoriesWithQuestions.map(category =>
                    <Grid key={`category_${category.id}`} item xs={12}>
                        <Grid container spacing={spacing}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h4" component="div">
                                    {category.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                {category.questions.map(question =>
                                    <Box key={`question_${question.id}`} paddingBottom={3}>
                                        <Accordion elevation={0} className={classes.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                classes={{ expanded: classes.expanded }}>
                                                <Typography variant="h5" component="div">
                                                    {question.question}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="subtitle1" component="div">
                                                    {question.answer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default FrequentlyAskedQuestions;
