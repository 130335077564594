import React from "react";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import Actions from "./Actions";
import Content from "./Content";
import Open from "./Open";
import Stepper from "./Stepper";
import Title from "./Title";

import {
    clearCandidateComparatorStores,
    clearCandidateTarget,
    clearCandidateTargetSpendCategories,
    clearCandidateTargetStoreCategory,
    hideFilters,
    selectFiltersVisibility,
    setCandidateComparatorStores,
    setCandidateTarget,
    setCandidateTargetSpendCategories,
    setCandidateTargetStoreCategory
} from "modules/customer/tools/location/filters/filtersSlice";
import {
    selectClientRegistration,
    selectComparatorStores,
    selectStoreCategories,
    selectTarget,
    selectTargetSpendCategories,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { useAppDispatch, useAppSelector } from "store";

const Filters: React.FC = () => {
    const dispatch = useAppDispatch();
    const comparatorStores = useAppSelector(selectComparatorStores);
    const storeCategories = useAppSelector(selectStoreCategories);
    const primaryStoreCategoryId = useAppSelector(selectClientRegistration)?.primaryStoreCategoryId;
    const targetStoreCategory = useAppSelector(selectTargetStoreCategory);
    const target = useAppSelector(selectTarget);
    const targetSpendCategories = useAppSelector(selectTargetSpendCategories);
    const filtersVisibility = useAppSelector(selectFiltersVisibility);
    const isVisible = filtersVisibility.isVisible;

    const handleClose = () => {
        dispatch(hideFilters());
        dispatch(clearCandidateComparatorStores());
        dispatch(clearCandidateTargetStoreCategory());
        dispatch(clearCandidateTarget());
        dispatch(clearCandidateTargetSpendCategories());
    };

    React.useEffect(() => {
        if (isVisible) {
            dispatch(setCandidateComparatorStores(comparatorStores));

            const primaryStoreCategory = storeCategories.find(storeCategory => storeCategory.id === primaryStoreCategoryId);
            const candidateTargetStoreCategory = targetStoreCategory ?? primaryStoreCategory;
            if (candidateTargetStoreCategory) {
                dispatch(setCandidateTargetStoreCategory(candidateTargetStoreCategory));
            }

            if (target) {
                dispatch(setCandidateTarget({ ...target }));
                dispatch(setCandidateTargetSpendCategories([...targetSpendCategories]));
            }
        }
    }, [dispatch, isVisible, comparatorStores, storeCategories, primaryStoreCategoryId, targetStoreCategory, target, targetSpendCategories]);

    return (
        <>
            <Open />
            <TwoPanelDialog
                open={isVisible}
                onClose={handleClose}
                leftPanelTitle={<Title />}
                leftPanelContent={<Stepper />}
                leftPanelActions={<Actions />}
                leftPanelActionsJustifyContent={JustifyContent.SpaceBetween}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default Filters;
