import React from "react";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageCallToAction from "components/landing/PageCallToAction";

import TheDashStory from "./TheDashStory";
import Statistics from "./Statistics";
import ActionableAndAccessibleInsights from "./ActionableAndAccessibleInsights";
import YourDashTeam from "./YourDashTeam";
import OurBackground from "./OurBackground";

const OurStory = () => {
    const theme = useTheme();

    return (
        <Page dataCy="pages-landing-our-story">
            <PageHeader
                title="Our story"
                subtitle="Making advanced analytics accessible"
                dataCy="pages-landing-our-story-header"
            />
            <TheDashStory />
            <Statistics />
            <ActionableAndAccessibleInsights />
            <YourDashTeam />
            <OurBackground />
            <PageCallToAction
                color={theme.palette.common.black}
                bgcolor={theme.palette.tertiary.main}
                title="You've heard our story, what's yours?"
                subtitle="Get in touch and let's see how Dash can make a difference to your business"
                actionLabel="Get in touch"
                actionUrl="/contact-us"
                dataCy="pages-landing-our-story-call-to-action"
            />
        </Page>
    );
};

export default OurStory;
