import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { median } from "mathjs";
import dateUtils from "utils/dateUtils";

import { selectStore, selectComparator, selectReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";
import actions from "./actions";

const getCOGSPastYear = () => async (dispatch, getState) => {
    dispatch(actions.getCOGSPastYearRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const selectedCogsData = resultSet.loadResponses[0].data.map(item => ({
            cogs: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"],
        })).find(item => item.store === selectedStoreID)?.cogs ?? 0;

        const comparatorData = resultSet.loadResponses[0].data.map(item => ({
            cogs: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorCogsData = comparatorData.map(current => {
            const value = comparatorData.find(item => item.store === current.store);
            if (!value) {
                return 0;
            }
            return (value.cogs === 0) ? 0 : value.cogs;
        });

        const sortedData = comparatorCogsData.sort((a, b) => a - b);
        const middleValue = Math.floor(sortedData.length / 2);
        const comparatorMedian = (sortedData.length % 2 !== 0) ? sortedData[middleValue] : (sortedData[middleValue - 1] + sortedData[middleValue]) / 2;
        const percentageDifference = (comparatorMedian === 0) ? 0 : 100 * ((selectedCogsData - comparatorMedian) / comparatorMedian);

        dispatch(actions.getCOGSPastYearSuccess(selectedCogsData, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getCOGSPastYearFailure());
        dispatch(logError("Error loading COGSPastYear.", error));
    }
};

const getGrossProfitLastYear = () => async (dispatch, getState) => {
    dispatch(actions.getGrossProfitLastYearRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));
        const salesValue = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).find(item => item.store === selectedStoreID)?.sales ?? 0;

        const comparatorPriorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorSalesValue = comparatorPriorTwelveMonthsData.map(current => {
            const value = comparatorPriorTwelveMonthsData.find(item => item.store === current.store);
            if (!value) {
                return 0;
            }
            return (value.sales === 0) ? 0 : value.sales;
        });

        const sortedSalesData = comparatorSalesValue.sort((a, b) => a - b);
        const middleSalesValue = Math.floor(sortedSalesData.length / 2);
        const medianSalesComparatorData = (sortedSalesData.length % 2 !== 0) ? sortedSalesData[middleSalesValue] : (sortedSalesData[middleSalesValue - 1] + sortedSalesData[middleSalesValue]) / 2;

        const cogsQuery = {
            measures: ["F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSetCogs = await dispatch(cubeLoadExtended(cogsQuery));

        const cogsValue = resultSetCogs.loadResponses[0].data.map(item => ({
            cost: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"]
        })).find(item => item.store === selectedStoreID)?.cost ?? 0;

        const comparatorCogsData = resultSetCogs.loadResponses[0].data.map(item => ({
            cost: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorCogsValue = comparatorCogsData.map(current => {
            const value = comparatorCogsData.find(item => item.store === current.store);
            if (!value) {
                return 0;
            }
            return (value.cost === 0) ? 0 : value.cost;
        });

        const sortedCogsData = comparatorCogsValue.sort((a, b) => a - b);
        const middleCogsValue = Math.floor(sortedCogsData.length / 2);
        const medianCogsComparatorData = (sortedCogsData.length % 2 !== 0) ? sortedCogsData[middleCogsValue] : (sortedCogsData[middleCogsValue - 1] + sortedCogsData[middleCogsValue]) / 2;

        const grossProfitValue = (cogsValue === 0) ? 0 : salesValue - cogsValue;
        const comparatorGrossProfitValue = (comparatorCogsValue === 0) ? 0 : medianSalesComparatorData - medianCogsComparatorData;
        const percentageDifference = (comparatorGrossProfitValue === 0) ? 0 : 100 * ((grossProfitValue - comparatorGrossProfitValue) / comparatorGrossProfitValue);

        dispatch(actions.getGrossProfitLastYearSuccess(grossProfitValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getGrossProfitLastYearFailure());
        dispatch(logError("Error loading GrossProfitLastYear.", error));
    }
};

const getGrossProfitMarginLastYear = () => async (dispatch, getState) => {
    dispatch(actions.getGrossProfitMarginLastYearRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));
        const salesValue = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).find(item => item.store === selectedStoreID)?.sales ?? 0;

        const comparatorPriorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorSalesValue = comparatorPriorTwelveMonthsData.map(current => {
            const value = comparatorPriorTwelveMonthsData.find(item => item.store === current.store);
            if (!value) {
                return 0;
            }
            return (value.sales === 0) ? 0 : value.sales;
        });

        const sortedSalesData = comparatorSalesValue.sort((a, b) => a - b);
        const middleSalesValue = Math.floor(sortedSalesData.length / 2);
        const medianSalesComparatorData = (sortedSalesData.length % 2 !== 0) ? sortedSalesData[middleSalesValue] : (sortedSalesData[middleSalesValue - 1] + sortedSalesData[middleSalesValue]) / 2;

        const cogsQuery = {
            measures: ["F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSetCogs = await dispatch(cubeLoadExtended(cogsQuery));

        const cogsValue = resultSetCogs.loadResponses[0].data.map(item => ({
            cost: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"],
        })).find(item => item.store === selectedStoreID)?.cost ?? 0;

        const comparatorCogsData = resultSetCogs.loadResponses[0].data.map(item => ({
            cost: item["F_Sales.SumLineCost"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorCogsValue = comparatorCogsData.map(current => {
            const value = comparatorCogsData.find(item => item.store === current.store);
            if (!value) {
                return 0;
            }
            return (value.cost === 0) ? 0 : value.cost;
        });

        const sortedCogsData = comparatorCogsValue.sort((a, b) => a - b);
        const middleCogsValue = Math.floor(sortedCogsData.length / 2);
        const medianCogsComparatorData = (sortedCogsData.length % 2 !== 0) ? sortedCogsData[middleCogsValue] : (sortedCogsData[middleCogsValue - 1] + sortedCogsData[middleCogsValue]) / 2;

        const grossProfitMarginValue = (salesValue === 0) ? 0 : 100 * ((salesValue - cogsValue) / salesValue);
        const comparatorGrossProfitMarginValue = (medianSalesComparatorData === 0) ? 0 : 100 * ((medianSalesComparatorData - medianCogsComparatorData) / medianSalesComparatorData);
        const percentageDifference = (comparatorGrossProfitMarginValue === 0) ? 0 : grossProfitMarginValue - comparatorGrossProfitMarginValue;

        dispatch(actions.getGrossProfitMarginLastYearSuccess(grossProfitMarginValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getGrossProfitMarginLastYearFailure());
        dispatch(logError("Error loading GrossProfitMarginLastYear.", error));
    }
};

const getGrossProfitMarginOverTime = () => async (dispatch, getState) => {
    dispatch(actions.getGrossProfitMarginOverTimeRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue", "F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            order: [
                ["D_Date.Year", "asc"],
                ["D_Date.MonthNo", "asc"]
            ],
            dimensions: ["D_Date.Year", "D_Date.MonthNo", "D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const profitData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            cost: item["F_Sales.SumLineCost"],
            profitMargin: (item["F_Sales.SumLineValue"] === 0) ? 0 :
                100 * ((item["F_Sales.SumLineValue"] - item["F_Sales.SumLineCost"]) / item["F_Sales.SumLineValue"]),
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            date: dateUtils.dateUTC(Date.UTC(item["D_Date.Year"], item["D_Date.MonthNo"] - 1, 1)),
            store: item["D_Store.StoreNaturalID"]
        }));

        const dates = [...new Set(profitData.map(item => item.date.getTime()))];

        const storeProfitData = profitData.filter(item => item.store === selectedStoreID);
        const store = dates.map(function(timestamp) {
            const filteredProfitMargin = storeProfitData.filter(item => item.date.getTime() === timestamp);
            if (filteredProfitMargin.length === 0){
                const date = dateUtils.dateUTC(timestamp);
                return {
                    sales: null,
                    cost: null,
                    profitMargin: null,
                    year: date.getUTCFullYear(),
                    month: date.getUTCMonth(),
                    date: date,
                    store: selectedStoreID
                };
            }

            return filteredProfitMargin[0];
        });

        const comparatorProfitData = profitData.filter(item => comparatorStoresIDs.includes(item.store));        
        const comparator = dates.map(function(timestamp) {
            const filteredProfitMargin = comparatorProfitData.filter(item => item.date.getTime() === timestamp);

            if (filteredProfitMargin.length === 0) {
                const date = dateUtils.dateUTC(timestamp);
                return {
                    date: date,
                    medianComparatorProfitMargin: null,
                    sales: null,
                    cost: null
                };
            }
            
            const profitMarginValue = median(filteredProfitMargin.map(item => item.profitMargin));
            const sales = median(filteredProfitMargin.map(item => item.sales));
            const costs = (((profitMarginValue * sales) / 100) - sales) * -1; //Calculating comparator cost value to display in tooltip
            
            return {
                date: filteredProfitMargin[0].date,
                medianComparatorProfitMargin: profitMarginValue,
                sales: sales,
                cost: costs,
            };
        });

        dispatch(actions.getGrossProfitMarginOverTimeSuccess(store, comparator));
    }
    catch (error) {
        dispatch(actions.getGrossProfitMarginOverTimeFailure());
        dispatch(logError("Error loading GrossProfitMarginOverTime.", error));
    }
};

const getGrossProfitTrend = () => async (dispatch, getState) => {
    dispatch(actions.getGrossProfitTrendRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.Year", "D_Date.MonthNo", "D_Store.StoreNaturalID", "D_Date.Date"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [selectedStoreID]
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));
        const cogsData = resultSet.loadResponses[0].data.map(item => ({
            cost: item["F_Sales.SumLineCost"],
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            store: item["D_Store.StoreNaturalID"]
        })).reduce((arr, i) => {
            if (arr.length !== 0) {
                const month = arr[arr.length - 1].month;
                month === i.month ? arr[arr.length - 1].cost += i.cost : arr.push(i);
            } else {
                arr.push(i);
            }
            return arr;
        }, []);

        const revenueQuery = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.Year", "D_Date.MonthNo", "D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const revenueResultSet = await dispatch(cubeLoadExtended(revenueQuery));
        const revenueData = revenueResultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => item.store === selectedStoreID);

        const profitMargin = cogsData.map(current => {
            const revenue = revenueData.find(item => item.year === current.year && item.month === current.month && item.store === current.store);
            if (!revenue) {
                return {
                    profitMargin: 0,
                    month: current.month,
                    year: current.year,
                    sales: current.sales,
                    cost: current.cost
                };
            }
            return {
                profitMargin: (revenue.sales === 0) ? 0 : 100 * ((revenue.sales - current.cost) / revenue.sales),
                sales: revenue.sales,
                cost: current.cost,
                month: current.month,
                year: current.year
            };
        });

        const store = profitMargin.map(current => {

            return {
                profitMargin: current.profitMargin,
                storeSales: current.sales,
                storeCost: current.cost,
                month: current.month
            };
        });

        //Time period / last element:
        const arrayLength = store.length;
        const numberOfMonths = arrayLength;
        const latestPeriodProfit = store[arrayLength - 1].profitMargin;
        const comparisonPeriodProfit = store[0].profitMargin;

        //Check if comparison period value is 0:
        let cmgrValue = 0;
        if (comparisonPeriodProfit === 0 && latestPeriodProfit > 0) {
            cmgrValue = 0.6;
        } else {
            const cmgrCalculation = ((latestPeriodProfit / comparisonPeriodProfit) ** (1 / numberOfMonths) - 1);
            cmgrValue = cmgrCalculation * 100;
        }

        let ragStatus;
        let ragValue;

        //RAG indicator:
        if (cmgrValue > 0.5) {
            ragStatus = "success";
            ragValue = `Gross profit (%) in the last year trended upward for your ${selectedStoreSelector.name} store.`;
        } else if (cmgrValue >= 0) {
            ragStatus = "warning";
            ragValue = `Gross profit (%) in the last year was stagnant for your ${selectedStoreSelector.name} store.`;
        } else {
            ragStatus = "error";
            ragValue = `Gross profit (%) in the last year trended downward for your ${selectedStoreSelector.name} store.`;
        }

        const status = ragStatus;
        const value = ragValue;
        dispatch(actions.getGrossProfitTrendSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getGrossProfitTrendFailure());
        dispatch(logError("Error loading GrossProfitTrend.", error));
    }
};

const operations = {
    getCOGSPastYear,
    getGrossProfitLastYear,
    getGrossProfitMarginLastYear,
    getGrossProfitMarginOverTime,
    getGrossProfitTrend
};

export default operations;
