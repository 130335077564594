import React from "react";

const StorePerformanceAgainstBudgetSubtitle: React.FC = () => {
    return (
        <>
            How does your performance against budget break down by store? Which stores are excelling, and which need
            your attention?
        </>
    );
};

export default StorePerformanceAgainstBudgetSubtitle;
