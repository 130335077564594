import React from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    ClickAwayListener,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {
    chooseAllSpendCategories,
    deselectAllSpendCategories,
    selectSpendCategories,
    toggleSpendCategoryIsSelected
} from "modules/customer/tools/location/spendNew/spendSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: theme.spacing(43),
        borderRadius: (props: any) => props["data-is-open"] ? "6px 6px 0px 0px" : "6px",
        cursor: "pointer"
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledButton = withStyles(theme => ({
    root: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        color: theme.palette.text.secondary
    }
}))(Button);

const StyledListCard = withStyles(theme => ({
    root: {
        position: "absolute",
        top: theme.spacing(-2),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(43),
        maxHeight: theme.spacing(40),
        overflow: "auto",
        borderRadius: "0px 0px 6px 6px",
        borderTopStyle: "solid",
        zIndex: theme.zIndex.drawer
    }
}))(Card);

const StyledCheckbox = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main
        },
    },
    checked: {}
}))(Checkbox);

const MarketCategoriesFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const spendCategories = useAppSelector(selectSpendCategories);
    const selectedSpendCategoriesCount = spendCategories.filter(spendCategory => spendCategory.isSelected()).length;
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const handleSelectAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        dispatch(chooseAllSpendCategories());
    };

    const handleDeselectAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        dispatch(deselectAllSpendCategories());
    };

    const toggleIsSelected = (spendCategoryName: string) => {
        dispatch(toggleSpendCategoryIsSelected(spendCategoryName));
    };

    return (
        <>
            <StyledCard ref={anchorRef} elevation={0} onClick={handleClick} data-is-open={isOpen}>
                <StyledCardContent>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2" component="div">
                            Market categories
                        </Typography>
                        <Spacer />
                        {isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                    </Box>
                    <Box display="flex" alignItems="center" paddingTop={1} minHeight={theme.spacing(3.5)}>
                        <Typography variant="body2" component="div" color="textSecondary">
                            {`${selectedSpendCategoriesCount} selected`}
                        </Typography>
                        <Spacer />
                        {isOpen && (
                            <>
                                <StyledButton
                                    variant="text"
                                    size="small"
                                    color="default"
                                    disableElevation
                                    onClick={handleSelectAllClick}
                                >
                                    Select all
                                </StyledButton>
                                &nbsp;
                                <StyledButton
                                    variant="text"
                                    size="small"
                                    color="default"
                                    disableElevation
                                    onClick={handleDeselectAllClick}
                                    style={{ marginRight: theme.spacing(-1) }}
                                >
                                    Deselect all
                                </StyledButton>
                            </>
                        )}
                    </Box>
                </StyledCardContent>
            </StyledCard>
            <Box position="relative">
                {isOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <StyledListCard>
                            <List>
                                {spendCategories.map((spendCategory, index) =>
                                    <ListItem
                                        key={index}
                                        dense
                                        divider
                                        button
                                        onClick={() => toggleIsSelected(spendCategory.name)}
                                    >
                                        <ListItemText
                                            primary={spendCategory.name}
                                            primaryTypographyProps={{
                                                variant: "subtitle1",
                                                component: "div"
                                            }}
                                        />
                                        <ListItemSecondaryAction>
                                            <StyledCheckbox
                                                edge="end"
                                                color="default"
                                                checked={spendCategory.isSelected()}
                                                onChange={() => toggleIsSelected(spendCategory.name)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </StyledListCard>
                    </ClickAwayListener>
                )}
            </Box>
        </>
    );
};

export default MarketCategoriesFilter;
