import React from "react";

import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledOuterCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        "&:last-child": {
            paddingBottom: theme.spacing(10)
        }
    }
}))(CardContent);

const StyledInnerCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        maxWidth: theme.spacing(43)
    }
}))(Card);

const StyledInnerCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        "&:last-child": {
            paddingBottom: theme.spacing(12)
        }
    }
}))(CardContent);

const useStyles = makeStyles(() => ({
    backgroundImage: {
        backgroundImage: "url(/content/customer/onboarding/payment/dashes.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
    }
}));

const Payment: React.FC = () => {
    const classes = useStyles();

    return (
        <Card>
            <StyledOuterCardContent>
                {/* @ts-ignore */}
                <Box align="center" className={classes.backgroundImage}>
                    <StyledInnerCard elevation={0}>
                        <StyledInnerCardContent>
                            <Typography variant="h4" component="div" align="center">
                                Setup your monthly payment using our secure payments provider
                            </Typography>
                        </StyledInnerCardContent>
                    </StyledInnerCard>
                </Box>
            </StyledOuterCardContent>
        </Card>
    );
};

export default Payment;
