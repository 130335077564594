import React from "react";
import { Grid } from "@material-ui/core";

import CategoryForecastSalesGrowthMarket from "./CategoryForecastSalesGrowthMarket";
import ForecastGrowthVsMarketRagIndicator from "./ForecastGrowthVsMarketRagIndicator";


const ForecastGrowthVsMarket = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CategoryForecastSalesGrowthMarket />
            </Grid>
            <Grid item xs={12}>
                <ForecastGrowthVsMarketRagIndicator />
            </Grid>
        </Grid>
    );
};

export default ForecastGrowthVsMarket;
