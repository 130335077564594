import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { operations, selectors } from "modules/customer/account/users";

const FilterUsers = () => {
    const dispatch = useDispatch();
    const filter = useSelector(state => selectors.filter(state));

    const handleNameOrEmailChange = (event) => {
        const nameOrEmail = event.target.value;
        const newFilter = { ...filter, nameOrEmail };
        dispatch(operations.setFilter(newFilter));
    };

    return (
        <TextField
            placeholder="Enter name or email"
            variant="outlined"
            size="small"
            color="secondary"
            value={filter.nameOrEmail}
            onChange={handleNameOrEmailChange}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default FilterUsers;
