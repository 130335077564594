import React from "react";
import { useSelector } from "react-redux";

import { selectors } from "modules/customer/insights/performance/forecast/forecastGrowthVsMarket";

const ForecastGrowthVsMarketSubtitle: React.FC = () => {
    const categoryForecastSalesGrowthMarket = useSelector(state => selectors.categoryForecastSalesGrowthMarket(state));
    const topThreeMarketCategories =
        categoryForecastSalesGrowthMarket.categories.slice(0, 3).reduce((phrase: any, word: any) => !phrase ? word : `${phrase}, ${word}`, "")
        || "top three market categories";
    return (
        <>
            How do your growth forecasts compare to {topThreeMarketCategories} and the retail market as a
            whole? We calculate forecast growth for each market category based on the historical performance of brands
            that operate in each market category.
        </>
    );
};

export default ForecastGrowthVsMarketSubtitle;
