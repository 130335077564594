import React from "react";

import { Typography, withStyles } from "@material-ui/core";


const StyledTypography = withStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    }
}))(Typography);

interface MapTitleProps {
    title: string,
}

const MapTitle: React.FC<MapTitleProps> = ({title}) => {
    return <StyledTypography variant="h6">{title}</StyledTypography>;
};

export default MapTitle;
