import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator, selectNumberOfProductCategories } from "modules/customer/insights/portfolio/portfolioSlice";

const ProductCategoryGrowthSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const numberOfProductCategories = useAppSelector(selectNumberOfProductCategories);
    const productOrProductCategory = numberOfProductCategories > 1 ? "product category" : "product";
    const productsOrProductCategories = numberOfProductCategories > 1 ? "product categories" : "products";

    return (
        <>
            Discover which {productsOrProductCategories} are driving your store's growth. How is
            each {productOrProductCategory} performing in your {store?.name} store compared to {comparator?.name}?
            Identify any major differences between the two.
        </>
    );
};

export default ProductCategoryGrowthSubtitle;
