import { createSelector } from "reselect";
import { abs } from "mathjs";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const marketCategoryOpeningsVsClosures = (state) => {
    return state.customer.insights.portfolio.areaHealth.marketCategoryBreakdown.marketCategoryOpeningsVsClosures;
};

const complementaryMarketCat = createSelector(
    marketCategoryOpeningsVsClosures,
    state => selectStore(state),
    state => selectComparator(state),
    (marketCategoryOpeningsVsClosures, selectedStore, selectedComparator) => {
        const rag = {
            loading: false,
            error: false,
            id: "complementary-market-cat",
            label: "Complementary Market Categories",
            status: "info",
            value: ""
        };

        if (marketCategoryOpeningsVsClosures.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }

        if (marketCategoryOpeningsVsClosures.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const compData = marketCategoryOpeningsVsClosures.comparator;
        const storeData = marketCategoryOpeningsVsClosures.store;
        let A = 0;
        let B = 0;
        let compCategoriesObj = [], storeCategoriesObj = [];

        if (storeData) {
            storeCategoriesObj = storeData.filter(i => i.complementaryInd === 1);
            A = storeCategoriesObj.map(item => item.netOpening).reduce((prev, curr) => prev + curr, 0);
        }

        if (compData) {
            compCategoriesObj = compData.filter(i => i.complementaryInd === 1);
            B = compCategoriesObj.map(item => item.netOpening).reduce((prev, curr) => prev + curr, 0);
        }

        let x = ((A - B) / abs(B || 1)) * 100;

        if (storeCategoriesObj.length === 0 || compCategoriesObj.length === 0 ) {
            rag.status = "noData";
            rag.value = `This indicator isn't available because it requires you to set your company's complementary markets. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.`;
            return rag;
        }

        if (x > 50) {
            rag.status = "success";
            rag.value = `Complementary Market Categories net openings vs closures in the last 1 year for ${selectedStore.name} is markedly ahead of ${selectedComparator.name}.`;
        } else if (x <= 50 && x >= -50) {
            rag.status = "warning";
            rag.value = `Complementary Market Categories net openings vs closures in the last 1 year for ${selectedStore.name} is broadly in line with ${selectedComparator.name}.`;
        } else {
            rag.status = "error";
            rag.value = `Complementary Market Categories net openings vs closures in the last 1 year for ${selectedStore.name} is markedly behind ${selectedComparator.name}.`;
        }
        return rag;
    }
);

const nonComplementaryMarketCat = createSelector(
    marketCategoryOpeningsVsClosures,
    state => selectStore(state),
    state => selectComparator(state),
    (marketCategoryOpeningsVsClosures, selectedStore, selectedComparator) => {
        const rag = {
            loading: false,
            error: false,
            id: "non-complementary-market-cat",
            label: "Non-complementary market categories",
            status: "info",
            value: ""
        };

        if (marketCategoryOpeningsVsClosures.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }

        if (marketCategoryOpeningsVsClosures.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const compData = marketCategoryOpeningsVsClosures.comparator;
        const storeData = marketCategoryOpeningsVsClosures.store;
        let A = 0;
        let B = 0;
        
        let compCategoriesObj = [], storeCategoriesObj = [];

        if (storeData) {
            storeCategoriesObj = storeData.filter(i => i.complementaryInd === 0);
            if (storeCategoriesObj) {
                A = storeCategoriesObj.map(item => item.netOpening).reduce((prev, curr) => prev + curr, 0);
            }
        }

        if (compData) {
            compCategoriesObj = compData.filter(i => i.complementaryInd === 0);
            B = compCategoriesObj.map(item => item.netOpening).reduce((prev, curr) => prev + curr, 0);
        }

        let x = ((A - B) / abs(B || 1)) * 100;
        
        if (storeCategoriesObj.length === 0 || compCategoriesObj.length === 0 ) {
            rag.status = "noData";
            rag.value = `This indicator isn't available because it requires you to set your company's non-complementary markets. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.`;
            return rag;
        }

        if (x > 50) {
            rag.status = "error";
            rag.value = `Non-complementary market categories net openings in the last 1 year for ${selectedStore.name} are markedly ahead of ${selectedComparator.name}.`;
        } else if (x <= 50 && x >= -50) {
            rag.status = "warning";
            rag.value = `Non-complementary market categories net openings in the last 1 year for ${selectedStore.name} are broadly in line with ${selectedComparator.name}`;
        } else {
            rag.status = "success";
            rag.value = `Non-complementary market categories net openings in the last 1 year for ${selectedStore.name} are markedly behind ${selectedComparator.name}.`;
        }
        return rag;
    }
);

const selectors = {
    marketCategoryOpeningsVsClosures,
    complementaryMarketCat,
    nonComplementaryMarketCat
};

export default selectors;
