import { combineReducers } from "redux";

import storeSize from "./storeSize";
import staffing from "./staffing";

const reducer = combineReducers({
    storeSize,
    staffing
});

export default reducer;
