import React from "react";
import { Box } from "@material-ui/core";
import {useSelector } from "react-redux";
import RAGIndicator from "components/visuals/RAGIndicator";

import {selectors } from "modules/customer/insights/range/sales/historicSales";

const UnitsSalesTrendRag = () => {
   
    const unitSalesTrend = useSelector((state)=>selectors.unitSalesTrend(state));
    const { loading, error, id, label, value, status } = unitSalesTrend;
   
    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default UnitsSalesTrendRag;

