import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

enum NotificationVariant {
    Default = "default",
    Success = "success",
    Error = "error",
    Info = "info",
    Warning = "warning"
}

interface Notification {
    key: number,
    text: string,
    variant: NotificationVariant
}

const initialState: Notification[] = [];

const createNotification = (text: string, variant: NotificationVariant): Notification => {
    return {
        key: new Date().getTime() + Math.random(),
        text,
        variant
    };
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        notify: (state, action: PayloadAction<string>) => {
            state.push(createNotification(action.payload, NotificationVariant.Default));
        },
        notifySuccess: (state, action: PayloadAction<string>) => {
            state.push(createNotification(action.payload, NotificationVariant.Success));
        },
        notifyError: (state, action: PayloadAction<string>) => {
            state.push(createNotification(action.payload, NotificationVariant.Error));
        },
        notifyInfo: (state, action: PayloadAction<string>) => {
            state.push(createNotification(action.payload, NotificationVariant.Info));
        },
        notifyWarning: (state, action: PayloadAction<string>) => {
            state.push(createNotification(action.payload, NotificationVariant.Warning));
        },
        removeNotification: (state, action: PayloadAction<Notification>) => {
            return state.filter(notification => notification.key !== action.payload.key);
        }
    }
});

export const {
    notify,
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning,
    removeNotification
} = notificationsSlice.actions;

export const selectNotifications = (state: RootState): Notification[] => {
    return state.notifications;
};

export default notificationsSlice;
