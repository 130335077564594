import React from "react";

import { Avatar, Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { selectIsInsightExpanded, toggleIsInsightExpanded } from "modules/customer/tools/location/locationSlice";

const StyledAvatar = withStyles(theme => ({
    root: {
        position: "absolute",
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        color: theme.palette.common.white,
        width: theme.spacing(3),
        height: theme.spacing(6),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        zIndex: theme.zIndex.drawer,
        cursor: "pointer"
    }
}))(Avatar);

const Expand: React.FC = () => {
    const dispatch = useAppDispatch();
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);

    const handleClick = () => {
        dispatch(toggleIsInsightExpanded());
    };

    return (
        <Box position="absolute" height="100vh" width={0} display="flex" alignItems="center" top={0} right={0}>
            <StyledAvatar variant="rounded" onClick={handleClick} data-cy="btn-expand">
                {isInsightExpanded ? (
                    <ChevronLeftIcon fontSize="medium" />
                ) : (
                    <ChevronRightIcon fontSize="medium" />
                )}
            </StyledAvatar>
        </Box>
    );
};

export default Expand;
