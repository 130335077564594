import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const FirstLanguage: React.FC = () => {
    const title = "First language (Diversity)";
    const categories = [
        "English or Welsh",
        "Other"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.firstLanguage;
    const selectedStoreValues = selectedStore ? [
        selectedStore.englishOrWelsh,
        selectedStore.other
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.firstLanguage;
    const comparatorValues = comparator ? [
        comparator.englishOrWelsh,
        comparator.other
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default FirstLanguage;
