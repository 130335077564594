import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const AvgBasketValueCMPM = () => {
    const dispatch = useDispatch();
    const avgBasketValueCMPM = useSelector(state => selectors.avgBasketValueCMPM(state));
    const { loading, error, latestFullMonthAvgBasketValue, percentageDifference, currentMonth, pastMonth } = avgBasketValueCMPM;
    const label = `Average basket value in ${currentMonth[0]} ${currentMonth[1]} vs ${pastMonth[0]} ${pastMonth[1]}`;
    const valueFormatted = numberFormatter.toGBP(latestFullMonthAvgBasketValue);

    React.useEffect(() => {
        dispatch(operations.getAvgBasketValueCMPM());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="avg-basket-value-cm-pm">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default AvgBasketValueCMPM;
