import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class SpendCategory {
    public readonly id: number;
    public readonly name: string;
    public readonly isInPortfolio: boolean;

    constructor(
        id: number,
        name: string,
        isInPortfolio: boolean
    ) {
        this.id = id;
        this.name = name;
        this.isInPortfolio = isInPortfolio;
    }
}

export const loadSpendCategories = (): AppThunk<Promise<SpendCategory[]>> => async (dispatch) => {
    try {
        const clientSpendCategoriesQuery = {
            dimensions: [
                "D_ProductCategory.ProductCategory3",
                "D_ProductCategory.SpendCategory_ID",
            ],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const clientSpendCategoriesPromise = dispatch(cubeLoad(clientSpendCategoriesQuery));

        const allSpendCategoriesQuery = {
            dimensions: [
                "SpendCategory.id",
                "SpendCategory.CategoryName"
            ]
        };
        const allSpendCategoriesPromise = dispatch(cubeLoad(allSpendCategoriesQuery));

        const results = await Promise.all([clientSpendCategoriesPromise, allSpendCategoriesPromise]);
        const clientSpendCategoriesResultSet = results[0] as unknown as ResultSet;
        const allSpendCategoriesResultSet = results[1] as unknown as ResultSet;

        const clientSpendCategoriesRaw = clientSpendCategoriesResultSet.rawData();
        const clientSpendCategoriesIds = clientSpendCategoriesRaw.map(spendCategoryRaw => Number(spendCategoryRaw["D_ProductCategory.SpendCategory_ID"]));

        const allSpendCategoriesRaw = allSpendCategoriesResultSet.rawData();
        return allSpendCategoriesRaw.map(spendCategoryRaw => {
            const id = Number(spendCategoryRaw["SpendCategory.id"]);
            const name = String(spendCategoryRaw["SpendCategory.CategoryName"]);
            const isInPortfolio = clientSpendCategoriesIds.includes(id);
            return new SpendCategory(id, name, isInPortfolio);
        });
    } catch (error) {
        dispatch(logError("Error loading SpendCategories.", error));
        throw error;
    }
};
