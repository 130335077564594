import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { Comparator, ComparatorType } from "modules/customer/insights/portfolio/comparator";
import { Store } from "modules/customer/insights/portfolio/store";
import { selectStores as selectPortfolioStores } from "modules/customer/insights/portfolio/portfolioSlice";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

interface FiltersVisibility {
    isVisible: boolean
}

export enum FilterStep {
    SelectStore,
    SelectComparator,
    CustomSelection
}

export interface StoresSearch {
    name: string
}

const storesSearchEmpty: StoresSearch = {
    name: ""
};

export interface StoresFilter {
    region: string,
    totalScore: number[],
    salesScore: number[],
    driversScore: number[],
    profitScore: number[],
    areaHealthScore: number[],
    competitionScore: number[],
    catchmentScore: number[]
}

const storesFilterEmpty: StoresFilter = {
    region: "",
    totalScore: [0, 30],
    salesScore: [0, 5],
    driversScore: [0, 5],
    profitScore: [0, 5],
    areaHealthScore: [0, 5],
    competitionScore: [0, 5],
    catchmentScore: [0, 5]
};

export enum StoreSortField {
    Name,
    TotalScore,
    SalesScore,
    DriversScore,
    ProfitScore,
    AreaHealthScore,
    CompetitionScore,
    CatchmentScore
}

interface StoresSort {
    field: StoreSortField,
    direction: SortDirection
}

const storesSortEmpty: StoresSort = {
    field: StoreSortField.Name,
    direction: SortDirection.ASC
};

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateStore?: Store,
    candidateComparator?: Comparator,
    storesSearch: StoresSearch,
    storesFilter: StoresFilter,
    storesSort: StoresSort,
    customSelectionStoresSearch: StoresSearch,
    customSelectionStoresFilter: StoresFilter,
    customSelectionStoresSort: StoresSort
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: false
    },
    activeStep: FilterStep.SelectStore,
    storesSearch: storesSearchEmpty,
    storesFilter: storesFilterEmpty,
    storesSort: storesSortEmpty,
    customSelectionStoresSearch: storesSearchEmpty,
    customSelectionStoresFilter: storesFilterEmpty,
    customSelectionStoresSort: storesSortEmpty
};

const filtersSlice = createSlice({
    name: "customer/insights/portfolio/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectStore;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        setCandidateStore: (state, action: PayloadAction<Store>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = undefined;
        },
        setCandidateComparator: (state, action: PayloadAction<Comparator>) => {
            state.candidateComparator = action.payload;
        },
        clearCandidateComparator: (state) => {
            state.candidateComparator = undefined;
        },
        addStoreToCandidateComparator: (state, action: PayloadAction<Store>) => {
            if (!state.candidateComparator || state.candidateComparator.type !== ComparatorType.Custom) {
                return;
            }
            state.candidateComparator.addStore(action.payload);
        },
        removeStoreFromCandidateComparator: (state, action: PayloadAction<Store>) => {
            if (!state.candidateComparator || state.candidateComparator.type !== ComparatorType.Custom) {
                return;
            }
            state.candidateComparator.removeStore(action.payload);
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = storesSearchEmpty;
        },
        setStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.storesFilter = action.payload;
        },
        clearStoresFilter: (state) => {
            state.storesFilter = storesFilterEmpty;
        },
        setStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.storesSort = action.payload;
        },
        clearStoresSort: (state) => {
            state.storesSort = storesSortEmpty;
        },
        setCustomSelectionStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.customSelectionStoresSearch = action.payload;
        },
        clearCustomSelectionStoresSearch: (state) => {
            state.customSelectionStoresSearch = storesSearchEmpty;
        },
        setCustomSelectionStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.customSelectionStoresFilter = action.payload;
        },
        clearCustomSelectionStoresFilter: (state) => {
            state.customSelectionStoresFilter = storesFilterEmpty;
        },
        setCustomSelectionStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.customSelectionStoresSort = action.payload;
        },
        clearCustomSelectionStoresSort: (state) => {
            state.customSelectionStoresSort = storesSortEmpty;
        }
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    setCandidateStore,
    clearCandidateStore,
    setCandidateComparator,
    clearCandidateComparator,
    addStoreToCandidateComparator,
    removeStoreFromCandidateComparator,
    setStoresSearch,
    clearStoresSearch,
    setStoresFilter,
    clearStoresFilter,
    setStoresSort,
    clearStoresSort,
    setCustomSelectionStoresSearch,
    clearCustomSelectionStoresSearch,
    setCustomSelectionStoresFilter,
    clearCustomSelectionStoresFilter,
    setCustomSelectionStoresSort,
    clearCustomSelectionStoresSort
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearCandidateStore());
    dispatch(filtersSlice.actions.clearCandidateComparator());
    dispatch(filtersSlice.actions.clearStoresSearch());
    dispatch(filtersSlice.actions.clearStoresFilter());
    dispatch(filtersSlice.actions.clearStoresSort());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresSearch());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresFilter());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresSort());
    dispatch(filtersSlice.actions.setActiveStep(FilterStep.SelectStore));
};

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.insights.portfolio.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.insights.portfolio.filters.activeStep;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.insights.portfolio.filters.candidateStore;
};

export const selectCandidateComparator = (state: RootState) => {
    return state.customer.insights.portfolio.filters.candidateComparator;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.insights.portfolio.filters.storesSearch;
};

export const selectStoresFilter = (state: RootState) => {
    return state.customer.insights.portfolio.filters.storesFilter;
};

export const selectStoresSort = (state: RootState) => {
    return state.customer.insights.portfolio.filters.storesSort;
};

export const selectCustomSelectionStoresSearch = (state: RootState) => {
    return state.customer.insights.portfolio.filters.customSelectionStoresSearch;
};

export const selectCustomSelectionStoresFilter = (state: RootState) => {
    return state.customer.insights.portfolio.filters.customSelectionStoresFilter;
};

export const selectCustomSelectionStoresSort = (state: RootState) => {
    return state.customer.insights.portfolio.filters.customSelectionStoresSort;
};

export const selectIsStoresFilterModified = createSelector(
    selectStoresFilter,
    (storesFilter) => {
        return storesFilter.region !== storesFilterEmpty.region
            || storesFilter.totalScore[0] !== storesFilterEmpty.totalScore[0]
            || storesFilter.totalScore[1] !== storesFilterEmpty.totalScore[1]
            || storesFilter.salesScore[0] !== storesFilterEmpty.salesScore[0]
            || storesFilter.salesScore[1] !== storesFilterEmpty.salesScore[1]
            || storesFilter.driversScore[0] !== storesFilterEmpty.driversScore[0]
            || storesFilter.driversScore[1] !== storesFilterEmpty.driversScore[1]
            || storesFilter.profitScore[0] !== storesFilterEmpty.profitScore[0]
            || storesFilter.profitScore[1] !== storesFilterEmpty.profitScore[1]
            || storesFilter.areaHealthScore[0] !== storesFilterEmpty.areaHealthScore[0]
            || storesFilter.areaHealthScore[1] !== storesFilterEmpty.areaHealthScore[1]
            || storesFilter.competitionScore[0] !== storesFilterEmpty.competitionScore[0]
            || storesFilter.competitionScore[1] !== storesFilterEmpty.competitionScore[1]
            || storesFilter.catchmentScore[0] !== storesFilterEmpty.catchmentScore[0]
            || storesFilter.catchmentScore[1] !== storesFilterEmpty.catchmentScore[1];
    }
);

export const selectIsCustomSelectionStoresFilterModified = createSelector(
    selectCustomSelectionStoresFilter,
    (storesFilter) => {
        return storesFilter.region !== storesFilterEmpty.region
            || storesFilter.totalScore[0] !== storesFilterEmpty.totalScore[0]
            || storesFilter.totalScore[1] !== storesFilterEmpty.totalScore[1]
            || storesFilter.salesScore[0] !== storesFilterEmpty.salesScore[0]
            || storesFilter.salesScore[1] !== storesFilterEmpty.salesScore[1]
            || storesFilter.driversScore[0] !== storesFilterEmpty.driversScore[0]
            || storesFilter.driversScore[1] !== storesFilterEmpty.driversScore[1]
            || storesFilter.profitScore[0] !== storesFilterEmpty.profitScore[0]
            || storesFilter.profitScore[1] !== storesFilterEmpty.profitScore[1]
            || storesFilter.areaHealthScore[0] !== storesFilterEmpty.areaHealthScore[0]
            || storesFilter.areaHealthScore[1] !== storesFilterEmpty.areaHealthScore[1]
            || storesFilter.competitionScore[0] !== storesFilterEmpty.competitionScore[0]
            || storesFilter.competitionScore[1] !== storesFilterEmpty.competitionScore[1]
            || storesFilter.catchmentScore[0] !== storesFilterEmpty.catchmentScore[0]
            || storesFilter.catchmentScore[1] !== storesFilterEmpty.catchmentScore[1];
    }
);

export const selectStores = createSelector(
    selectPortfolioStores,
    selectStoresSearch,
    selectStoresFilter,
    selectStoresSort,
    (stores, search, filter, sort) => {
        const name = search.name.toLowerCase();
        const filteredStores = stores.filter(store =>
            (!name || store.name.toLowerCase().includes(name))
            && (!filter.region || store.region === filter.region)
            && store.getTotalScore() >= filter.totalScore[0]
            && store.getTotalScore() <= filter.totalScore[1]
            && store.salesScore >= filter.salesScore[0]
            && store.salesScore <= filter.salesScore[1]
            && store.driversScore >= filter.driversScore[0]
            && store.driversScore <= filter.driversScore[1]
            && store.profitScore >= filter.profitScore[0]
            && store.profitScore <= filter.profitScore[1]
            && store.areaHealthScore >= filter.areaHealthScore[0]
            && store.areaHealthScore <= filter.areaHealthScore[1]
            && store.competitionScore >= filter.competitionScore[0]
            && store.competitionScore <= filter.competitionScore[1]
            && store.catchmentScore >= filter.catchmentScore[0]
            && store.catchmentScore <= filter.catchmentScore[1]
        );

        return filteredStores.sort((storeA, storeB) => {
            switch (sort.field) {
                case StoreSortField.TotalScore:
                    return numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), sort.direction);
                case StoreSortField.SalesScore:
                    return numberSortExpression(storeA.salesScore, storeB.salesScore, sort.direction);
                case StoreSortField.DriversScore:
                    return numberSortExpression(storeA.driversScore, storeB.driversScore, sort.direction);
                case StoreSortField.ProfitScore:
                    return numberSortExpression(storeA.profitScore, storeB.profitScore, sort.direction);
                case StoreSortField.AreaHealthScore:
                    return numberSortExpression(storeA.areaHealthScore, storeB.areaHealthScore, sort.direction);
                case StoreSortField.CompetitionScore:
                    return numberSortExpression(storeA.competitionScore, storeB.competitionScore, sort.direction);
                case StoreSortField.CatchmentScore:
                    return numberSortExpression(storeA.catchmentScore, storeB.catchmentScore, sort.direction);
                case StoreSortField.Name:
                default:
                    return stringSortExpression(storeA.name, storeB.name, sort.direction);
            }
        });
    }
);

export const selectComparators = createSelector(
    selectPortfolioStores,
    selectCandidateStore,
    (stores, candidateStore) => {
        let comparators: Comparator[] = [];

        const allOtherStores = stores.filter(s => s.id !== candidateStore?.id);
        if (allOtherStores.length > 0) {
            const allStoresComparator = new Comparator(ComparatorType.AllStores, "All stores", allOtherStores);
            comparators.push(allStoresComparator);
        }

        const storesInRegion = allOtherStores.filter(s => s.region === candidateStore?.region);
        if (storesInRegion.length > 0) {
            const allStoresInRegionComparator = new Comparator(ComparatorType.AllStoresInRegion, `All stores in ${candidateStore?.region}`, storesInRegion);
            comparators.push(allStoresInRegionComparator);

            const sortedStores = storesInRegion.sort((storeA, storeB) => numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), SortDirection.DESC));
            const topFiveStores = sortedStores.slice(0, 5);
            const topFiveStoresInRegionComparator = new Comparator(ComparatorType.TopFiveStoresInRegion, `Top 5 stores in ${candidateStore?.region}`, topFiveStores);
            comparators.push(topFiveStoresInRegionComparator);
        }

        const customComparator = new Comparator(ComparatorType.Custom, "Custom selection", []);
        comparators.push(customComparator);

        return comparators;
    }
);

export const selectCustomSelectionStores = createSelector(
    selectPortfolioStores,
    selectCandidateStore,
    selectCustomSelectionStoresSearch,
    selectCustomSelectionStoresFilter,
    selectCustomSelectionStoresSort,
    (stores, candidateStore, search, filter, sort) => {
        const name = search.name.toLowerCase();
        const filteredStores = stores.filter(store =>
            store.id !== candidateStore?.id
            && (!name || store.name.toLowerCase().includes(name))
            && (!filter.region || store.region === filter.region)
            && store.getTotalScore() >= filter.totalScore[0]
            && store.getTotalScore() <= filter.totalScore[1]
            && store.salesScore >= filter.salesScore[0]
            && store.salesScore <= filter.salesScore[1]
            && store.driversScore >= filter.driversScore[0]
            && store.driversScore <= filter.driversScore[1]
            && store.profitScore >= filter.profitScore[0]
            && store.profitScore <= filter.profitScore[1]
            && store.areaHealthScore >= filter.areaHealthScore[0]
            && store.areaHealthScore <= filter.areaHealthScore[1]
            && store.competitionScore >= filter.competitionScore[0]
            && store.competitionScore <= filter.competitionScore[1]
            && store.catchmentScore >= filter.catchmentScore[0]
            && store.catchmentScore <= filter.catchmentScore[1]
        );

        return filteredStores.sort((storeA, storeB) => {
            switch (sort.field) {
                case StoreSortField.TotalScore:
                    return numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), sort.direction);
                case StoreSortField.SalesScore:
                    return numberSortExpression(storeA.salesScore, storeB.salesScore, sort.direction);
                case StoreSortField.DriversScore:
                    return numberSortExpression(storeA.driversScore, storeB.driversScore, sort.direction);
                case StoreSortField.ProfitScore:
                    return numberSortExpression(storeA.profitScore, storeB.profitScore, sort.direction);
                case StoreSortField.AreaHealthScore:
                    return numberSortExpression(storeA.areaHealthScore, storeB.areaHealthScore, sort.direction);
                case StoreSortField.CompetitionScore:
                    return numberSortExpression(storeA.competitionScore, storeB.competitionScore, sort.direction);
                case StoreSortField.CatchmentScore:
                    return numberSortExpression(storeA.catchmentScore, storeB.catchmentScore, sort.direction);
                case StoreSortField.Name:
                default:
                    return stringSortExpression(storeA.name, storeB.name, sort.direction);
            }
        });
    }
);

export default filtersSlice;
