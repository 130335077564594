import React from "react";

import { Box, Container, Hidden, Toolbar } from "@material-ui/core";

interface PageProps {
    dataCy: string,
    children: React.ReactElement
}

const Page: React.FC<PageProps> = (props) => {
    const { dataCy } = props;

    return (
        <Box data-cy={dataCy}>
            <Container maxWidth={false}>
                {props.children}
            </Container>
            {/* @ts-ignore */}
            <Hidden mdUp>
                <br />
                <Toolbar />
            </Hidden>
        </Box>
    );
};

export default Page;
