import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectStoresTotalSales } from "modules/customer/tools/product/productSlice";

import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

const StoresTotalSales = () => {
    const partner = useAppSelector(selectSelectedPartner);
    const { isLoading, hasErrors, data } = useAppSelector(selectStoresTotalSales);
    const label = `${partner?.name} headroom in the past 12 months`;

    return (
        <Box width="100%" height="100%" data-cy="partner-past-twelve-months-headroom">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalSalesHeadroom, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalOptimisedSales, 1)}
                    </Typography>
                }
                comparisonLabel="Optimised sales in the past 12 months"
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalEstimatedSales, 1)}
                    </Typography>
                }
                differenceLabel="Estimated sales in the past 12 months"
            />
        </Box>
    );
};

export default StoresTotalSales;
