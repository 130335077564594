import React from "react";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import {
    selectStoreSearch,
    setStoreSearch,
    StoreSearch
} from "modules/customer/insights/cost/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const storeSearch = useAppSelector(selectStoreSearch);
    const [currentStoreSearch, setCurrentStoreSearch] = React.useState<StoreSearch>(storeSearch);

    const setStoreSearchDelayed = React.useMemo(() => {
        return _.debounce((storeSearch: StoreSearch) => dispatch(setStoreSearch(storeSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newStoreSearch = { ...storeSearch, name };
        setCurrentStoreSearch(newStoreSearch);
        setStoreSearchDelayed(newStoreSearch);
    };

    React.useEffect(() => {
        setCurrentStoreSearch(storeSearch);
    }, [storeSearch]);

    return (
        <Box paddingTop={2}>
            <StyledTextField
                placeholder="Search"
                variant="outlined"
                size="small"
                color="secondary"
                value={currentStoreSearch.name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Search;
