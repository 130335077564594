const competitionScoreVis = (state) => {
    return state.customer.insights.portfolio.competition.competitionScore.competitionScoreVis;
};

const competitionScoreRag = (state) => {
    return state.customer.insights.portfolio.competition.competitionScore.competitionScoreRag;
};

const selectors = {
    competitionScoreVis,
    competitionScoreRag
};

export default selectors;
