import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import PageSection from "./PageSection";

import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.background.paper
        }
    }
}));

const PageCallToAction = (props) => {
    const { title, subtitle, actionLabel, actionUrl, dataCy, color, bgcolor, buttonVariant } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClick = () => {
        dispatch(trackEvent(actionLabel, `${actionLabel} CTA clicked`, `${actionLabel} CTA`));
    };

    return (
        <PageSection
            color={color}
            bgcolor={bgcolor}
            dataCy={dataCy}
        >
            <Typography variant={isMobile ? "h5" : "h3"} align="center" data-cy="call-to-action-title">
                {title}
            </Typography>
            <br />
            <Typography variant={isMobile ? "subtitle1" : "h4"} align="center" data-cy="call-to-action-subtitle">
                {subtitle}
            </Typography>
            <br />
            <br />
            <Box align="center">
                <Button
                    component={NavLink}
                    to={actionUrl}
                    variant={buttonVariant}
                    size="large"
                    disableElevation
                    className={classes.button}
                    onClick={handleClick}
                    data-cy="call-to-action-btn"
                >
                    {actionLabel}
                </Button>
            </Box>
        </PageSection>
    );
};

PageCallToAction.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    actionLabel: PropTypes.string.isRequired,
    actionUrl: PropTypes.string.isRequired,
    dataCy: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string,
    buttonVariant: PropTypes.oneOf(["contained", "outlined"])
};

PageCallToAction.defaultProps = {
    color: "inherit",
    bgcolor: "inherit",
    buttonVariant: "contained"
};

export default PageCallToAction;
