import { median } from "mathjs";

import actions from "./actions";
import dateUtils from "utils/dateUtils";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectNumberOfProductCategories, selectReferenceDate, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getHistoricProdGrowthStoreVsComp = () => async (dispatch, getState) => {
    dispatch(actions.getHistoricProdGrowthStoreVsCompRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);
        const numberOfProductCategories = selectNumberOfProductCategories(state);

        let productOnly = false;
        const dimensions = ["D_Store.StoreNaturalID"];
        if (numberOfProductCategories <= 1) {
            dimensions.push("D_Product.ProductName");
            productOnly = true;
        } else {
            dimensions.push("D_ProductCategory.ProductCategory1");
        }

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate]
                ]
            }],
            dimensions,
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const priorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: productOnly ? item["D_Product.ProductName"] : item["D_ProductCategory.ProductCategory1"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => item.store === selectedStoreID);

        const twelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: productOnly ? item["D_Product.ProductName"] : item["D_ProductCategory.ProductCategory1"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => item.store === selectedStoreID);

        const comparatorPriorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: productOnly ? item["D_Product.ProductName"] : item["D_ProductCategory.ProductCategory1"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorTwelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: productOnly ? item["D_Product.ProductName"] : item["D_ProductCategory.ProductCategory1"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => comparatorStoresIDs.includes(item.store));


        const selectedStoreGrowth = priorTwelveMonthsData.map(current => {
            const previous = twelveMonthsBeforePriorTwelveMonthsData.find(item => (item.store === current.store) && (item.productCategory === current.productCategory));
            if (!previous) {
                return {
                    growth: 0,
                    productCategory: current.productCategory
                };
            }
            return {
                growth: (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales),
                productCategory: current.productCategory
            };
        });

        const comparatorStoreGrowth = comparatorPriorTwelveMonthsData.map(current => {
            const previous = comparatorTwelveMonthsBeforePriorTwelveMonthsData.find(item => (item.store === current.store) && (item.productCategory === current.productCategory));
            if (!previous) {
                return {
                    comparatorStore: current.store,
                    comparatorGrowth: 0,
                    comparatorProductCategory: current.productCategory
                };
            }
            return {
                comparatorStore: current.store,
                comparatorGrowth: (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales),
                comparatorProductCategory: current.productCategory
            };
        });

        const comparatorCategories = comparatorStoreGrowth.map(i => i.comparatorProductCategory);
        const storeCategories = selectedStoreGrowth.map(i => i.productCategory);
        const categories = [...new Set(storeCategories.concat(comparatorCategories))];
        const values = categories.map(category => {
            const selected = selectedStoreGrowth.find(item => item.productCategory === category);
            const filtered = comparatorStoreGrowth.filter(item => item.comparatorProductCategory === category);
            const growthValues = filtered.map(item => item.comparatorGrowth);
            const sortedData = growthValues.sort((a, b) => a - b);
            const noNullSortedData = sortedData.map(x => x ?? 0);
            const medianComparatorData = noNullSortedData.length !== 0 ? median(noNullSortedData) : 0;

            return {
                productCategory: category,
                selectedStoreGrowth: selected ? selected.growth : 0,
                medianComparatorGrowth: medianComparatorData
            };
        });
        dispatch(actions.getHistoricProdGrowthStoreVsCompSuccess(values));
    }
    catch (error) {
        dispatch(actions.getHistoricProdGrowthStoreVsCompFailure());
        dispatch(logError("Error loading HistoricProdGrowthStoreVsComp.", error));
    }
};

const operations = {
    getHistoricProdGrowthStoreVsComp
};

export default operations;
