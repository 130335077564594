import React from "react";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Delve into the past, present, and future revenue performance of your {store?.name} store.
            From understanding revenue trends and growth comparisons to a detailed analysis of customer buying habits,
            Dash provides insights to optimise your strategy for your {store?.name} store.
            Explore what makes this store stand out and uncover growth opportunities.
        </>
    );
};

export default Subtitle;
