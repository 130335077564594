const GET_COMPETITION_SCORE_VIS_REQUEST = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_VIS_REQUEST";
const GET_COMPETITION_SCORE_VIS_SUCCESS = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_VIS_SUCCESS";
const GET_COMPETITION_SCORE_VIS_FAILURE = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_VIS_FAILURE";
const GET_COMPETITION_SCORE_RAG_REQUEST = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_RAG_REQUEST";
const GET_COMPETITION_SCORE_RAG_SUCCESS = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_RAG_SUCCESS";
const GET_COMPETITION_SCORE_RAG_FAILURE = "spark/customer/insights/portfolio/competition/competitionScore/GET_COMPETITION_SCORE_RAG_FAILURE";

const types = {
    GET_COMPETITION_SCORE_VIS_REQUEST,
    GET_COMPETITION_SCORE_VIS_SUCCESS,
    GET_COMPETITION_SCORE_VIS_FAILURE,
    GET_COMPETITION_SCORE_RAG_REQUEST,
    GET_COMPETITION_SCORE_RAG_SUCCESS,
    GET_COMPETITION_SCORE_RAG_FAILURE
};

export default types;
