import React from "react";

import Centile from "pages/customer/tools/location/filters/targets/Centile";
import CentileMetric from "pages/customer/tools/location/filters/targets/CentileMetric";

import {
    selectCandidateComparatorStores,
    selectCandidateTarget,
    selectAggregatedDirectCompetitors,
    selectRetailCentresCentiles,
    selectStoreCategoryCentilesMetrics,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const Age: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const retailCentresCentiles = useAppSelector(selectRetailCentresCentiles);
    const comparatorStoresCentiles = candidateComparatorStores.map(store => {
        const retailCentreCentile = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id)?.age ?? 0;
        return {
            storeName: store.name,
            centileValue: retailCentreCentile
        };
    });
    const aggregatedDirectCompetitors = useAppSelector(selectAggregatedDirectCompetitors);
    const aggregatedDirectCompetitorCentiles = aggregatedDirectCompetitors.map(item => ({
        storeName: item.fascia,
        centileValue: item.ageCentile
    }));
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const storeCategoryCentilesMetrics = useAppSelector(selectStoreCategoryCentilesMetrics);
    const centileMetric = storeCategoryCentilesMetrics.find(cm => cm.centile === candidateTarget?.age);
    const name = "Age";
    const description = "The age score looks at the catchment area population and evaluates the average age.";
    const ageCentileMetrics = [{
        name: "Aged 17-35",
        tooltip: "The percentage of the catchment area's adult population aged 17 to 35.",
        value: numberFormatter.toPercentage(centileMetric?.age17To35Percentage ?? 0, false, 0)
    }, {
        name: "Aged 36-55",
        tooltip: "The percentage of the catchment area's adult population aged 36 to 55.",
        value: numberFormatter.toPercentage(centileMetric?.age36To55Percentage ?? 0, false, 0)
    }, {
        name: "Aged 56+",
        tooltip: "The percentage of the catchment area's adult population aged 56 or over.",
        value: numberFormatter.toPercentage(centileMetric?.age56PlusPercentage ?? 0, false, 0)
    }];

    const handleSliderChange = (value: number) => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                age: value
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <Centile
            name={name}
            description={description}
            disabled={!candidateTarget.useDemographics}
            sliderValue={candidateTarget.age}
            onSliderChange={handleSliderChange}
            comparatorStoresCentiles={comparatorStoresCentiles}
            aggregatedDirectCompetitorCentiles={aggregatedDirectCompetitorCentiles}
            chartLowLabel="Young population"
            chartHighLabel="Old population"
        >
            {ageCentileMetrics.map((metric, index) =>
                <CentileMetric
                    key={index}
                    name={metric.name}
                    tooltip={metric.tooltip}
                    value={metric.value}
                    disabled={!candidateTarget.useDemographics}
                />
            )}
        </Centile>
    );
};

export default Age;
