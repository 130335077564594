import { createSelector } from "reselect";
import { median } from "mathjs";

import arrayUtils from "utils/arrayUtils";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const payrollCostPerSqft = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.staffing.payrollCostPerSqft;
};

const payrollCostPerSqftGauge = createSelector(
    payrollCostPerSqft,
    (payrollCostPerSqft) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0
        };
        if (payrollCostPerSqft.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (payrollCostPerSqft.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = payrollCostPerSqft.selectedStore;
        const overallData = payrollCostPerSqft.overallData;
        const comparatorStores = payrollCostPerSqft.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.payrollCostPerSqft;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.payrollCostPerSqft));
        out.min = Math.min(...overallData.map(item => item.payrollCostPerSqft));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.payrollCostPerSqft));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.payrollCostPerSqft), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.payrollCostPerSqft), 2 / 3);

        return out;
    }
);

const payrollCostsPerSqftVariance = createSelector(
    payrollCostPerSqft,
    (payrollCostPerSqft) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (payrollCostPerSqft.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (payrollCostPerSqft.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = payrollCostPerSqft.selectedStore;
        const comparatorStores = payrollCostPerSqft.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.payrollCostPerSqft));
        //selected store value
        const selectedStoreValue = selectedStore.payrollCostPerSqft;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const payrollCostsPerSqftRank = createSelector(
    payrollCostPerSqft,
    (payrollCostPerSqft) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0,
            status: "info"
        };
        if (payrollCostPerSqft.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (payrollCostPerSqft.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = payrollCostPerSqft.selectedStore;
        const overallData = payrollCostPerSqft.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => a.payrollCostPerSqft - b.payrollCostPerSqft);

        const selectedStoreValue = selectedStore.payrollCostPerSqft;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.payrollCostPerSqft === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = payrollCostPerSqft.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.payrollCostPerSqft), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.payrollCostPerSqft), 2 / 3);

        out.status = (() => {
            switch (true) {  
                case selectedStoreValue > secondTertile:
                    return 'error';
                case selectedStoreValue >= firstTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const salesValuePerHead = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.staffing.salesValuePerHead;
};

const salesValuePerHeadGauge = createSelector(
    salesValuePerHead,
    (salesValuePerHead) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0
        };
        if (salesValuePerHead.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesValuePerHead.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesValuePerHead.selectedStore;
        const overallData = salesValuePerHead.overallData;
        const comparatorStores = salesValuePerHead.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.salesPerHead;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.salesPerHead));
        out.min = Math.min(...overallData.map(item => item.salesPerHead));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.salesPerHead));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerHead), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerHead), 2 / 3);

        return out;
    }
);

const salesValuePerHeadVariance = createSelector(
    salesValuePerHead,
    (salesValuePerHead) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (salesValuePerHead.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesValuePerHead.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesValuePerHead.selectedStore;
        const comparatorStores = salesValuePerHead.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.salesPerHead));
        //selected store value
        const selectedStoreValue = selectedStore.salesPerHead;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const salesValuePerHeadRank = createSelector(
    salesValuePerHead,
    (salesValuePerHead) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0,
            status: "info"
        };
        if (salesValuePerHead.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesValuePerHead.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesValuePerHead.selectedStore;
        const overallData = salesValuePerHead.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => b.salesPerHead - a.salesPerHead);

        const selectedStoreValue = selectedStore.salesPerHead;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.salesPerHead === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = salesValuePerHead.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerHead), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerHead), 2 / 3);
        
        out.status = (() => {
            switch (true) {  
                case selectedStoreValue < firstTertile:
                    return 'error';
                case selectedStoreValue <= secondTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const salesPerPoundOfStaffCost = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.staffing.salesPerPoundOfStaffCost;
};

const salesPerPoundOfStaffCostGauge = createSelector(
    salesPerPoundOfStaffCost,
    (salesPerPoundOfStaffCost) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0
        };
        if (salesPerPoundOfStaffCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfStaffCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfStaffCost.selectedStore;
        const overallData = salesPerPoundOfStaffCost.overallData;
        const comparatorStores = salesPerPoundOfStaffCost.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.salesPerPoundOfStaffCost;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.salesPerPoundOfStaffCost));
        out.min = Math.min(...overallData.map(item => item.salesPerPoundOfStaffCost));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.salesPerPoundOfStaffCost));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfStaffCost), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfStaffCost), 2 / 3);

        return out;
    }
);

const salesPerPoundOfStaffCostVariance = createSelector(
    salesPerPoundOfStaffCost,
    (salesPerPoundOfStaffCost) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (salesPerPoundOfStaffCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfStaffCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfStaffCost.selectedStore;
        const comparatorStores = salesPerPoundOfStaffCost.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.salesPerPoundOfStaffCost));
        //selected store value
        const selectedStoreValue = selectedStore.salesPerPoundOfStaffCost;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const salesPerPoundOfStaffCostRank = createSelector(
    salesPerPoundOfStaffCost,
    (salesPerPoundOfStaffCost) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0,
            status: "info"
        };
        if (salesPerPoundOfStaffCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfStaffCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfStaffCost.selectedStore;
        const overallData = salesPerPoundOfStaffCost.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => b.salesPerPoundOfStaffCost - a.salesPerPoundOfStaffCost);

        const selectedStoreValue = selectedStore.salesPerPoundOfStaffCost;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.salesPerPoundOfStaffCost === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = salesPerPoundOfStaffCost.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfStaffCost), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfStaffCost), 2 / 3);
        
        out.status = (() => {
            switch (true) {  
                case selectedStoreValue < firstTertile:
                    return 'error';
                case selectedStoreValue <= secondTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const payrollCostsPerSqftCategorisation = createSelector(
    payrollCostPerSqft,
    (state) => selectStore(state),
    (payrollCostPerSqft, selectedStoreSelector) => {
        const out = {
            loading: false,
            error: false,
            id: "payroll-costs-per-sqft-categorisation",
            label: "Payroll costs per square foot categorisation",
            status: "info",
            value: ""
        };
        if (payrollCostPerSqft.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (payrollCostPerSqft.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const data = payrollCostPerSqft.overallData;
        const sortedData = data.sort((a, b) => a.payrollCostPerSqft - b.payrollCostPerSqft); 

        const selectedStore = payrollCostPerSqft.selectedStore;
        const selectedStoreValue = selectedStore.payrollCostPerSqft;

        if (data.length === 0 || sortedData.length === 0 || selectedStoreValue === 0) {
            out.status = "noData";
            out.value = `This indicator isn't available because it requires your company's payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            return out;
        }

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.payrollCostPerSqft === selectedStoreValue);
        const denominator = data.length;

        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            out.status = "success";
            out.value = `${selectedStoreSelector.name} is a top performer with respect to payroll costs per square foot`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            out.status = "error";
            out.value = `${selectedStoreSelector.name} is underperforming with respect to payroll costs per square foot`;
        } else { // equal to or between the 2 options
            out.status = "warning";
            out.value = `${selectedStoreSelector.name} is average with respect to payroll costs per square foot`;
        }

        return out;
    }
);

const salesPerHeadCategorisation = createSelector(
    salesValuePerHead,
    (state) => selectStore(state),
    (salesValuePerHead, selectedStoreSelector) => {
        const out = {
            loading: false,
            error: false,
            id: "sales-per-head-categorisation",
            label: "Sales per head categorisation",
            status: "info",
            value: ""
        };
        if (salesValuePerHead.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesValuePerHead.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const data = salesValuePerHead.overallData;
        const sortedData = data.sort((a, b) => b.salesPerHead - a.salesPerHead); //descending order - higher value gets lower rank

        const selectedStore = salesValuePerHead.selectedStore;
        const selectedStoreValue = selectedStore.salesPerHead;
        
        if (data.length === 0 || sortedData.length === 0 || selectedStoreValue === 0) {
            out.status = "noData";
            out.value = `This indicator isn't available because it requires your store's employee FTE count. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Store dataset and refresh your company's Analytics.`;
            return out;
        }

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.salesPerHead === selectedStoreValue);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            out.status = "success";
            out.value = `${selectedStoreSelector.name} is a top performer with respect to Sales Revenue Per Head`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            out.status = "error";
            out.value = `${selectedStoreSelector.name} is underperforming with respect to Sales Revenue Per Head`;
        } else { // equal to or between the 2 options
            out.status = "warning";
            out.value = `${selectedStoreSelector.name} is average with respect to Sales Revenue Per Head`;
        }

        return out;
    }
);

const salesPerPoundStaffCostCategorisation = createSelector(
    salesPerPoundOfStaffCost,
    (state) => selectStore(state),
    (salesPerPoundOfStaffCost, selectedStoreSelector) => {
        const out = {
            loading: false,
            error: false,
            id: "sales-per-pound-staff-cost-categorisation",
            label: "Sales per pound staff cost categorisation",
            status: "info",
            value: ""
        };
        if (salesPerPoundOfStaffCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfStaffCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const data = salesPerPoundOfStaffCost.overallData;
        const sortedData = data.sort((a, b) => b.salesPerPoundOfStaffCost - a.salesPerPoundOfStaffCost); //descending order - higher value gets lower rank
        if (data.length === 0 || sortedData.length === 0 || sortedData[0]?.salesPerPoundOfStaffCost === 0) {
            out.status = "noData";
            out.value = `This indicator isn't available because it requires your company's payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            return out;
        }

        const selectedStore = salesPerPoundOfStaffCost.selectedStore;
        const selectedStoreValue = selectedStore.salesPerPoundOfStaffCost;

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.salesPerPoundOfStaffCost === selectedStoreValue);
        const denominator = data.length;

        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            out.status = "success";
            out.value = `${selectedStoreSelector.name} is a top performer with respect to Revenue per £ of Staff Cost`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            out.status = "error";
            out.value = `${selectedStoreSelector.name} is underperforming with respect to Revenue per £ of Staff Cost`;
        } else { // equal to or between the 2 options
            out.status = "warning";
            out.value = `${selectedStoreSelector.name} is average with respect to Revenue per £ of Staff Cost`;
        }

        return out;
    }
);

const selectors = {
    payrollCostPerSqft,
    payrollCostPerSqftGauge,
    payrollCostsPerSqftVariance,
    payrollCostsPerSqftRank,
    salesValuePerHead,
    salesValuePerHeadGauge,
    salesValuePerHeadVariance,
    salesValuePerHeadRank,
    salesPerPoundOfStaffCost,
    salesPerPoundOfStaffCostGauge,
    salesPerPoundOfStaffCostVariance,
    salesPerPoundOfStaffCostRank,
    payrollCostsPerSqftCategorisation,
    salesPerHeadCategorisation,
    salesPerPoundStaffCostCategorisation
};

export default selectors;
