const GET_LOCAL_AREA_COMPETITION_REQUEST = "spark/customer/insights/portfolio/competition/localAreaCompetition/GET_LOCAL_AREA_COMPETITION_REQUEST";
const GET_LOCAL_AREA_COMPETITION_SUCCESS = "spark/customer/insights/portfolio/competition/localAreaCompetition/GET_LOCAL_AREA_COMPETITION_SUCCESS";
const GET_LOCAL_AREA_COMPETITION_FAILURE = "spark/customer/insights/portfolio/competition/localAreaCompetition/GET_LOCAL_AREA_COMPETITION_FAILURE";

const types = {
    GET_LOCAL_AREA_COMPETITION_REQUEST,
    GET_LOCAL_AREA_COMPETITION_SUCCESS,
    GET_LOCAL_AREA_COMPETITION_FAILURE,
};

export default types;
