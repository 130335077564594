import React from "react";
import { Box } from "@material-ui/core";
import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectRankedGrowthClassification } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const RankedGrowthClassification = () => {
    const rankedGrowthClassification = useAppSelector(selectRankedGrowthClassification);
    return (
        <Box>
            <RAGIndicatorNew ragIndicator={rankedGrowthClassification} />
        </Box>
    );
};

export default RankedGrowthClassification;
