import { createSelector } from "reselect";
import { median, abs } from "mathjs";
import mathUtils from "utils/mathUtils";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const footfall = (state) => {
    return state.customer.insights.portfolio.catchment.footfall.footfall;
};

const footfallLevelInOutputArea = createSelector( //KPI Selector Template
    footfall,
    state => selectStore(state),
    (footfall, selectedStoreSelector) => {
        const kpi = {
            loading: footfall.loading,
            error: footfall.error,
            value: 0,
            percentageDifference: 0
        };

        if (kpi.loading || kpi.error || footfall.storeValues.length === 0) {
            return kpi;
        }

        const selectedData = footfall.storeValues;
        const comparatorData = footfall.comparatorValues;

        const selectedStoreOAID = selectedStoreSelector.outputAreaCode;

        //normalised footfal value selected lastyear
        const selectedFootFallValue = selectedData.find(item => item.OAID === selectedStoreOAID)?.normalisedLastYear ?? 0;

        //normalised footfall values comparator lastyear
        const comparatorFootfallValues = comparatorData.map(item => item.normalisedLastYear);

        // median of comparator footfall values lastyear
        const medianComparator = comparatorFootfallValues.length !== 0 ? median(comparatorFootfallValues) : 0;

        const percentageDifference = (medianComparator === 0) ? 100 : 100 * ((selectedFootFallValue - medianComparator) / medianComparator);

        kpi.value = selectedFootFallValue;
        kpi.percentageDifference = percentageDifference;
        return kpi;
    }
);

const changeInFootfallLevel = createSelector(
    footfall,
    state => selectStore(state),
    (footfall, selectedStoreSelector) => {
        const kpi = {
            loading: footfall.loading,
            error: footfall.error,
            value: 0,
            percentageDifference: 0
        };

        if (kpi.loading || kpi.error || footfall.storeValues.length === 0) {
            return kpi;
        }

        const selectedData = footfall.storeValues;
        const selectedStoreOAID = selectedStoreSelector.outputAreaCode;
        const selectedOAData = selectedData.find(item => item.OAID === selectedStoreOAID);

        // selected percentage variance last vs lastlast
        const variance = selectedOAData?.percentageChangeYOY ?? 0;

        const comparatorData = footfall.comparatorValues;
        const comparatorPercentageChange = comparatorData.map(item => item.percentageChangeYOY);

        // median of comparator variance values
        const medianVariance = comparatorPercentageChange.length === 0 ? 0 : median(comparatorPercentageChange);

        //comparator display
        const comparatorDisplay = medianVariance - variance;

        kpi.value = variance;
        kpi.percentageDifference = comparatorDisplay;
        return kpi;
    }
);


const footfallByOutputArea = createSelector(
    footfall,
    (footfall) => {
        const footfallMap = {
            loading: footfall.loading,
            error: footfall.error,
            outputAreaData: [],
            mapCenter: footfall.mapCenter
        };

        if (footfallMap.loading || footfallMap.error || footfall.storeValues === []) {
            return footfallMap;
        }
        const footfallValues = footfall.storeValues.map(item => item.normalisedLastYear);

        const percentileThresholds = mathUtils.percentileThresholds(footfallValues, 6);
        const outputAreaData = footfall.storeValues;

        const outputAreaDataWithPercentile = outputAreaData.map(footfallData => {
            let percentile = 0;
            for (let i = 0; i < percentileThresholds.length; i++) {

                if (footfallData.normalisedLastYear >= percentileThresholds[i]) {
                    percentile = i;
                }
            }
            return {
                ...footfallData,
                percentile
            };
        });

        footfallMap.outputAreaData = outputAreaDataWithPercentile;
        return footfallMap;
    }
);

const footfallAnalysis = createSelector(
    footfall,
    state => selectStore(state),
    state => selectComparator(state),
    (footfall, selectedStore, selectedComparator) => {
        const rag = {
            loading: footfall.loading,
            error: footfall.error,
            id: "footfall-analysis",
            label: "Footfall vs comparator",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || footfall.storeValues.length === 0) {
            return rag;
        }
        const selectedData = footfall.storeValues;
        const comparatorData = footfall.comparatorValues;

        const selectedStoreOAID = selectedStore.outputAreaCode;

        const selectedOAData = selectedData.find(item => item.OAID === selectedStoreOAID);
        //normalised footfal value selected lastyear
        const selectedFootFallValue = selectedOAData?.normalisedLastYear ?? 0;

        //normalised footfall values comparator lastyear
        const comparatorFootfallValues = comparatorData.map(item => item.normalisedLastYear);
        const mediancomparatorFootfallValues = median(comparatorFootfallValues);

        // variance for selected and comparator
        const variance = selectedOAData?.percentageChangeYOY ?? 0;
        const comparatorPercentageChange = comparatorData.map(item => item.percentageChangeYOY);

        // median of comparator variance values
        const medianVariance = median(comparatorPercentageChange);

        const X = ((selectedFootFallValue - mediancomparatorFootfallValues) / mediancomparatorFootfallValues) * 100;
        const Y = ((variance - medianVariance) / abs(medianVariance)) * 100;

        const storeName = selectedStore.name;
        const comparatorName = selectedComparator.name;

        const inRange = function (num, start, end) { // gets the range between two values start and end inclusive.
            return num >= start && num <= end;
        };

        //logic green
        if ((X > 25) && (Y > 50)) {
            rag.status = "success";
            rag.value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are markedly ahead of ${comparatorName}`;
        } else if ((X > 25) && inRange(Y, -50, 50)) {
            rag.status = "success";
            rag.value = `The level of footfall for your ${storeName} store is markedly ahead of ${comparatorName}, and the change in year-on-year footfall is broadly in line with ${comparatorName}`;
        } else if (inRange(X, -25, 25) && (Y > 50)) {
            rag.status = "success";
            rag.value = `The level of footfall for your ${storeName} store is broadly in line with ${comparatorName}, and the change in year-on-year footfall is markedly ahead of ${comparatorName}`;
            //logic amber
        } else if ((X > 25) && (Y < -50)) {
            rag.status = "warning";
            rag.value = `The level of footfall for your ${storeName} store is markedly ahead of ${comparatorName}, but its change in year-on-year footfall is markedly behind`;
        } else if (inRange(X, -25, 25) && inRange(Y, -50, 50)) {
            rag.status = "warning";
            rag.value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are broadly in line with ${comparatorName}`;
        } else if ((X < -25) && (Y > 50)) {
            rag.status = "warning";
            rag.value = `The level of footfall for your ${storeName} store is markedly behind ${comparatorName}, but its change in year-on-year footfall is markedly ahead`;
            //logic red
        } else if (inRange(X, -25, 25) && (Y < -50)) {
            rag.status = "error";
            rag.value = `The level of footfall for your ${storeName} store is broadly in line with ${comparatorName}, and the change in year-on-year footfall is markedly behind ${comparatorName}`;
        } else if ((X < -25) && inRange(Y, -50, 50)) {
            rag.status = "error";
            rag.value = `The level of footfall for your ${storeName} store is markedly behind ${comparatorName}, and the change in year-on-year footfall is broadly in line with ${comparatorName}`;
        } else if ((X < -25) && (Y < -50)) {
            rag.status = "error";
            rag.value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are markedly behind ${comparatorName}`;
        }
        return rag;
    }
);

const footfallPositioning = createSelector(
    footfall,
    state => selectStore(state),
    (footfall, selectedStore) => {
        const rag = {
            loading: footfall.loading,
            error: footfall.error,
            id: "footfall-positioning",
            label: "Store positioning",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || footfall.storeValues.length === 0) {
            return rag;
        }

        const inRange = function (num, start, end) { // gets the range between two values start and end inclusive.
            return num >= start && num <= end;
        };

        const selectedStoreOAID = selectedStore.outputAreaCode;

        const selectedData = footfall.storeValues;
        const N = selectedData.length;  //N = number of output areas in the selected store's catchment area.
        const selectedOAData = selectedData.find(item => item.OAID === selectedStoreOAID);
        const selectedOAValue = selectedOAData?.normalisedLastYear ?? 0;

        // rank = Rank each output area in the selected store's catchment area in terms of the level of normalised footfall in the last year.
        const selectedDataNormalised = selectedData.map(item => item.normalisedLastYear);
        const ordered = selectedDataNormalised.sort((a, b) => a - b);
        const rank = ordered.indexOf(selectedOAValue, 1);

        const T1 = (1 * (N + 1)) / 3;
        const T2 = (2 * (N + 1)) / 3;

        const storeName = selectedStore.name;

        if (rank > T2) {
            rag.status = "success";
            rag.value = `Your ${storeName} store's output area has high footfall relative to the rest of its catchment area`;
        } else if (T1 > rank) {
            rag.status = "error";
            rag.value = `Your ${storeName} store's output area has low footfall relatively to the rest of its catchment area`;
        } else if (inRange(rank, T1, T2)) {
            rag.status = "warning";
            rag.value = `Your ${storeName} store's output area has average footfall relative to the rest of its catchment area`;
        }
        return rag;
    }
);

const selectors = {
    footfallLevelInOutputArea,
    changeInFootfallLevel,
    footfallByOutputArea,
    footfallAnalysis,
    footfallPositioning
};

export default selectors;
