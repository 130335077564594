import React from "react";
import { NavLink } from "react-router-dom";

import {
    Box,
    Collapse,
    Divider,
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import CloudRoundedIcon from "@material-ui/icons/CloudRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";
import { useInsights } from "pages/customer/insights/Insights";
import { useTools } from "pages/customer/tools/Tools";

import Logo from "./Logo";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const StyledDrawer = withStyles(theme => ({
    root: {
        width: theme.spacing(25),
        flexShrink: 0
    },
    paper: {
        width: theme.spacing(25),
        // @ts-ignore
        backgroundColor: theme.palette.background.drawer
    }
}))(MuiDrawer);

const StyledListItem = withStyles(theme => ({
    root: {
        "&.active": {
            backgroundColor: theme.palette.primary.main
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.paper
        },
        "&.Mui-disabled": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.light
        }
    }
}))(ListItem);


const StyledCollapse = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Collapse);

const Drawer: React.FC = () => {
    const theme = useTheme();
    const [insightsOpen, setInsightsOpen] = React.useState(false);
    const insights = useInsights();
    const [toolsOpen, setToolsOpen] = React.useState(false);
    const tools = useTools();
    const userInfo = useAppSelector(selectUserInfo);
    const isAccountEditor = userInfo.isAccountEditor;
    const isDataProvided = userInfo.isDataProvided;

    const handleInsightsClick = () => {
        setInsightsOpen(previousOpen => !previousOpen);
    };

    const handleToolsClick = () => {
        setToolsOpen(previousOpen => !previousOpen);
    };

    return (
        <StyledDrawer
            variant="permanent"
            elevation={0}
            anchor="left"
        >
            <Box height={theme.spacing(7.5)} display="flex" alignItems="center" justifyContent="center">
                <Logo />
            </Box>
            <List disablePadding>
                <Divider />
                <StyledListItem
                    button
                    divider
                    // @ts-ignore
                    component={NavLink}
                    to="/dashboard"
                >
                    <ListItemIcon>
                        <DashboardRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </StyledListItem>

                <StyledListItem
                    button
                    divider
                    onClick={handleInsightsClick}
                    selected={insightsOpen}
                >
                    <ListItemIcon>
                        <BarChartRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Insights" />
                    {insightsOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                </StyledListItem>
                <StyledCollapse
                    in={insightsOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List disablePadding>
                        {insights.map((insight, index) =>
                            <StyledListItem
                                key={`insight_${index}`}
                                button
                                // @ts-ignore
                                component={NavLink}
                                to={insight.url}
                                disabled={!isDataProvided || !insight.isAvailable}
                            >
                                <ListItemIcon>
                                    {insight.icon}
                                </ListItemIcon>
                                <ListItemText primary={insight.name} />
                                {(!isDataProvided || !insight.isAvailable) && (
                                    <LockRoundedIcon color="disabled" fontSize="small" />
                                )}
                            </StyledListItem>
                        )}
                    </List>
                </StyledCollapse>
                <StyledListItem
                    button
                    divider
                    onClick={handleToolsClick}
                    selected={toolsOpen}
                >
                    <ListItemIcon>
                        <BuildRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tools" />
                    {toolsOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                </StyledListItem>
                <StyledCollapse
                    in={toolsOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <List disablePadding>
                        {tools.map((tool, index) =>
                            <StyledListItem
                                key={`tool_${index}`}
                                button
                                // @ts-ignore
                                component={NavLink}
                                to={tool.url}
                                disabled={!isDataProvided || !tool.isAvailable}
                            >
                                <ListItemIcon>
                                    {tool.icon}
                                </ListItemIcon>
                                <ListItemText primary={tool.name} />
                                {(!isDataProvided || !tool.isAvailable) && (
                                    <LockRoundedIcon color="disabled" fontSize="small" />
                                )}
                            </StyledListItem>
                        )}
                    </List>
                </StyledCollapse>
            </List>
            <Spacer />
            <List disablePadding>
                <Divider />
                {isAccountEditor && (
                    <StyledListItem
                        button
                        divider
                        // @ts-ignore
                        component={NavLink}
                        to="/data"
                    >
                        <ListItemIcon>
                            <CloudRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Data" />
                    </StyledListItem>
                )}
                <StyledListItem
                    button
                    divider
                    // @ts-ignore
                    component={NavLink}
                    to="/account"
                >
                    <ListItemIcon>
                        <PersonRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                </StyledListItem>
                <StyledListItem
                    button
                    divider
                    // @ts-ignore
                    component={NavLink}
                    to="/help"
                >
                    <ListItemIcon>
                        <HelpRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </StyledListItem>
            </List>
        </StyledDrawer>
    );
};

export default Drawer;
