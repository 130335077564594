import types from "./types";

const getSupplyAndDemandRequest = () => ({
    type: types.GET_SUPPLY_AND_DEMAND_REQUEST
});

const getSupplyAndDemandSuccess = (store, comparator) => ({
    type: types.GET_SUPPLY_AND_DEMAND_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getSupplyAndDemandFailure = () => ({
    type: types.GET_SUPPLY_AND_DEMAND_FAILURE
});

const actions = {
    getSupplyAndDemandRequest,
    getSupplyAndDemandSuccess,
    getSupplyAndDemandFailure
};

export default actions;
