import React from "react";

import { PartnerFilterStep, selectActiveStep } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { useAppSelector } from "store";
import PartnerFascias from "./partnerFascias/PartnerFascias";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === PartnerFilterStep.SelectPartner && (<PartnerFascias />)}
            {/* {activeStep === PartnerFilterStep.SelectPartnerGroup && (<p>Select retail partner group content</p>)} */}
        </>
    );
};

export default Content;
