import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import { selectPrices, selectSubscriptions, selectTaxRates } from "modules/admin/accounts/subscriptions/subscriptionsSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const SubscriptionsTable = () => {
    const theme = useTheme();
    const prices = useAppSelector(selectPrices);
    const taxRates = useAppSelector(selectTaxRates);
    const subscriptions = useAppSelector(selectSubscriptions);

    if (subscriptions.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Plan
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Use Stripe
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Stripe subscription id
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Price
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Tax rate
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Created at
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Active
                            </Box>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscriptions.map((subscription) => (
                        <TableRow key={subscription.id}>
                            <StyledTableCell>
                                {subscription.subscriptionPlan}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {subscription.usePaymentsProvider ? "Yes" : "No"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {subscription.paymentsProviderSubscriptionId}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {(() => {
                                    const price = prices.find(price => price.id === subscription.priceId);
                                    if (!price) {
                                        return "";
                                    }
                                    return `${price.name} - ${numberFormatter.toGBP(price.priceInPence / 100, 2, false)} / month`;
                                })()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {(() => {
                                    const taxRate = taxRates.find(taxRate => taxRate.id === subscription.taxRateId);
                                    if (!taxRate) {
                                        return "";
                                    }
                                    return `${taxRate.name} (${taxRate.percentage}% ${taxRate.isInclusive ? "inclusive" : "exclusive"})`;
                                })()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {subscription.createdAt.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {subscription.isActive ? (
                                    <CheckCircleRoundedIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                    ""
                                )}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default SubscriptionsTable;
