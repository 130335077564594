import React from "react";

import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    number: {
        color: theme.palette.text.disabled
    }
}));

interface ChapterHeaderProps {
    number: number,
    title: string,
    subtitle: string | React.ReactElement
}

const ChapterHeader: React.FC<ChapterHeaderProps> = (props) => {
    const { number, title, subtitle } = props;
    const classes = useStyles();

    return (
        <Box paddingBottom={2} data-cy="header">
            <Container>
                <Box paddingBottom={3}>
                    <Typography variant="h3" component="div" data-cy="title">
                        <span className={classes.number} data-cy="number">{number}.&nbsp;&nbsp;</span>
                        {title}
                    </Typography>
                </Box>
                <Box paddingBottom={3}>
                    <Typography variant="subtitle1" component="div" data-cy="subtitle">
                        {subtitle}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default ChapterHeader;
