import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectReferenceDateNew } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";
import { DateTime } from "luxon"; 

const getCategoryForecastSalesGrowthMarket = () => async (dispatch, getState) => {
    dispatch(actions.getCategoryForecastSalesGrowthMarketRequest());
    try {
        const state = getState();

        const referenceDateNew = selectReferenceDateNew(state);
        const clientLatestFullWeekEndDate =
            (referenceDateNew.weekday === 6) ? referenceDateNew : referenceDateNew.customStartOfWeek().minus({ days: 1 });

        let latestFullWeekEndDate = clientLatestFullWeekEndDate;
        // If client date is more recent than latest bench date then use bench date
        const queryBenchmarkReferenceDate = {
            dimensions: ["F_BenchmarkSalesWithOverall.Date"],
            order: [["F_BenchmarkSalesWithOverall.Date", "desc"]],
            filters: [{
                "member": "F_BenchmarkSalesWithOverall.Sales",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSetBenchmarkReferenceDate = await dispatch(cubeLoad(queryBenchmarkReferenceDate));

        if (resultSetBenchmarkReferenceDate.loadResponses[0].data.length !== 0) {
            const benchDateString = resultSetBenchmarkReferenceDate.loadResponses[0].data[0]["F_BenchmarkSalesWithOverall.Date"];
            const benchDateUtc = dateUtils.dateUTC(benchDateString);
            const benchmarkReferenceDate = DateTime.fromJSDate(benchDateUtc, { zone: "utc" });
            const benchLatestFullWeekEndDate =
                (benchmarkReferenceDate.weekday === 6) ? benchmarkReferenceDate : benchmarkReferenceDate.customStartOfWeek().minus({ days: 1 });
        
            //Reference Date
            latestFullWeekEndDate =
                clientLatestFullWeekEndDate < benchLatestFullWeekEndDate ? clientLatestFullWeekEndDate.endOf("day") : benchLatestFullWeekEndDate.endOf("day");
        }
        
        //Previous Year / Current Year
        const startOfCY = latestFullWeekEndDate.minus({ weeks: 52 }).plus({ days: 1 }).startOf("day"); //Should be a Sunday
        //Next Year
        const startOfNY = latestFullWeekEndDate.plus({ days: 1 }).startOf("day"); //Sunday after latest Saturday
        const endOfNY = startOfNY.plus({ weeks: 52 }).minus({ days: 1 }).endOf("day"); //Saturday 52 weeks after startOfNY

        //Client Forecast Growth
        const queryClientForecast = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [startOfCY, latestFullWeekEndDate],
                    [startOfNY, endOfNY]
                ]
            }],
            dimensions: ["F_SalesForecast.KPMGSpendCategory"],
            order: [
                [
                    "F_SalesForecast.SumLineValue",
                    "desc"
                ]
            ]
        }; //Overall for benchmark denoted by market category of "Retail"
        const resultSetClientForecast = await dispatch(cubeLoad(queryClientForecast));
        
        const growthData = [];

        const companyTotals = {
            nextYear: 0,
            prevYear: 0
        };

        for (let i in resultSetClientForecast.loadResponses[0].data) {
            const prevYear = resultSetClientForecast.loadResponses[0].data[i];
            const currentCategory = prevYear["F_SalesForecast.KPMGSpendCategory"];

            const nextYear = resultSetClientForecast.loadResponses[1].data.find(item => item["F_SalesForecast.KPMGSpendCategory"] === currentCategory);
            if (i < 3) {
                let categoryGrowth = 0;
                const prevYearSales = prevYear["F_SalesForecast.SumLineValue"];
                const nextYearSales = nextYear ? nextYear["F_SalesForecast.SumLineValue"] : null;
                if (prevYearSales !== nextYearSales) {
                    categoryGrowth = prevYearSales === 0 ? 100 : 100 * ((nextYearSales - prevYearSales)/ prevYearSales);
                }
                growthData.push({
                        clientGrowth: categoryGrowth,
                        benchgrowth: null,
                        category: currentCategory
                });
            }

            companyTotals.nextYear += nextYear ? nextYear["F_SalesForecast.SumLineValue"] : 0;
            companyTotals.prevYear += prevYear["F_SalesForecast.SumLineValue"] || 0;
        }

        growthData.push({
            clientGrowth: companyTotals.prevYear === 0 ? 100 : 100 * ((companyTotals.nextYear - companyTotals.prevYear)/ companyTotals.prevYear),
            benchGrowth: null,
            category: "Retail"
        });

        const clientGrowthFiltered = growthData.map(item => item.clientGrowth);
        const categories = growthData.map(item => item.category);

        const queryBenchmarkYoY = {
            measures: ["F_BenchmarkForecastSalesWithOverall.SumSales"],
            timeDimensions: [{
                dimension: "F_BenchmarkForecastSalesWithOverall.Date",
                compareDateRange: [
                    [startOfCY, latestFullWeekEndDate],
                    [startOfNY, endOfNY]
                ]
            }],
            dimensions: ["F_BenchmarkForecastSalesWithOverall.MarketCategory"],
            filters: [
                {
                    member: "F_BenchmarkForecastSalesWithOverall.MarketCategory",
                    operator: "equals",
                    values: categories
                }
            ]
        }; //Overall for benchmark denoted by market category of "Retail"

        const resultSetBenchmarkYoY = await dispatch(cubeLoad(queryBenchmarkYoY));

        for (let i in growthData) {
            const currentCategory = growthData[i].category;

            const benchmarkCurrentYear =
                resultSetBenchmarkYoY.loadResponses[0].data.find(item => item["F_BenchmarkForecastSalesWithOverall.MarketCategory"] === currentCategory);
            
            const benchmarkNextYear =
                resultSetBenchmarkYoY.loadResponses[1].data.find(item => item["F_BenchmarkForecastSalesWithOverall.MarketCategory"] === currentCategory);

            if (benchmarkCurrentYear && benchmarkNextYear) {
                const currentSales = benchmarkCurrentYear["F_BenchmarkForecastSalesWithOverall.SumSales"];
                const nextSales = benchmarkNextYear["F_BenchmarkForecastSalesWithOverall.SumSales"];

                growthData[i].benchGrowth = (currentSales === 0) ? 100 : 100 * ((nextSales - currentSales) / currentSales);
            }
        }

        const benchmarkGrowth = growthData.map(item => item.benchGrowth);

        dispatch(actions.getCategoryForecastSalesGrowthMarketSuccess(categories, clientGrowthFiltered, benchmarkGrowth));
    }
    catch (error) {
        dispatch(actions.getCategoryForecastSalesGrowthMarketFailure());
        dispatch(logError("Error loading CategoryForecastSalesGrowthMarket.", error));
    }
};

const operations = {
    getCategoryForecastSalesGrowthMarket
};

export default operations;
