import React from "react";
import { Grid } from "@material-ui/core";

import DistanceToHotspotKpi from "./DistanceToHotspotKpi";
import NumberOfDirectComp from "./NumberOfDirectComp";
import NumberOfComp from "./NumberOfComp";
import StorePositioningHotspotMap from "./StorePositioningHotspotMap";
import DistanceToHotspotRagIndicator from "./DistanceToHotspotRagIndicator";
import SizeRelativeToDirectCompetitors from "./SizeRelativeToDirectCompetitors";

const LocalAreaCompetition = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <DistanceToHotspotKpi />
            </Grid>
            <Grid item xs={12} md={4}>
                <NumberOfDirectComp />
            </Grid>
            <Grid item xs={12} md={4}>
                <NumberOfComp />
            </Grid>
            <Grid item xs={12}>
                <StorePositioningHotspotMap />
            </Grid>
            <Grid item xs={12}>
                <DistanceToHotspotRagIndicator />
            </Grid>
            <Grid item xs={12}>
                <SizeRelativeToDirectCompetitors />
            </Grid>
        </Grid>
    );
};

export default LocalAreaCompetition;
