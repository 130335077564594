import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";

import actions from "./actions";

const getStoreActualVsBudgetRanked = () => async (dispatch) => {
    dispatch(actions.getStoreActualVsBudgetRankedRequest());
    try {
        const query = {
            measures: [],
            order: [
                ["F_StorePerformanceRanking.CombinedRank", "asc"]
            ],
            dimensions: [
                "F_StorePerformanceRanking.LatestStoreName",
                "F_StorePerformanceRanking.Sales",
                "F_StorePerformanceRanking.Contribution",
                "F_StorePerformanceRanking.BudgetSales",
                "F_StorePerformanceRanking.BudgetContribution",
                "F_StorePerformanceRanking.SalesPercentDifference",
                "F_StorePerformanceRanking.ContributionPercentDifference",
                "F_StorePerformanceRanking.SalesPercentRank",
                "F_StorePerformanceRanking.ContributionPercentRank",
                "F_StorePerformanceRanking.CombinedRank"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const storePerformanceRankings = resultSet.loadResponses[0].data.map(item => ({
            store: item["F_StorePerformanceRanking.LatestStoreName"],
            salesVsBudget: item["F_StorePerformanceRanking.SalesPercentDifference"],
            contributionVsBudget: item["F_StorePerformanceRanking.ContributionPercentDifference"],
            combinedRank: item["F_StorePerformanceRanking.CombinedRank"]
        }));

        dispatch(actions.getStoreActualVsBudgetRankedSuccess(storePerformanceRankings));
    }
    catch (error) {
        dispatch(actions.getStoreActualVsBudgetRankedFailure());
        dispatch(logError("Error loading StoreActualVsBudgetRanked.", error));
    }
};

const operations = {
    getStoreActualVsBudgetRanked
};

export default operations;
