import { immerable } from "immer";
import mathUtils from "utils/mathUtils";

import { StoreWithSimilarityScore } from "./store";

export enum ComparatorType {
    AllStores,
    AllStoresInClientRegion,
    TopFiveStoresInClientRegion,
    AllStoresInRegion,
    TopFiveStoresInRegion,
    AllStoresInGroup,
    AllStoresInCategory,
    AllStoresInPitchType,
    AllStoresInSegment,
    AllStoresInFormat,
    Custom,
    SmartStoreSelection
}

export class Comparator {
    [immerable] = true;

    public readonly type: ComparatorType;
    public readonly name: string;
    private stores: StoreWithSimilarityScore[];

    constructor(type: ComparatorType, name: string, stores: StoreWithSimilarityScore[]) {
        this.type = type;
        this.name = name;
        this.stores = stores;
    }

    getStores() {
        return [...this.stores];
    }

    getRevenueScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.revenueScore));
    }

    getProfitScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.profitScore));
    }

    getAreaHealthScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.areaHealthScore));
    }

    getCatchmentScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.catchmentScore));
    }

    getCompetitionScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.competitionScore));
    }

    getFootfallScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.footfallScore));
    }

    getTotalScore() {
        return mathUtils.safeMedian(this.stores.map(store => store.getTotalScore()));
    }

    addStore(store: StoreWithSimilarityScore) {
        if (!this.stores.some(s => s.id === store.id)) {
            this.stores.push(store);
        }
    }

    removeStore(store: StoreWithSimilarityScore) {
        this.stores = this.stores.filter(s => s.id !== store.id);
    }
}
