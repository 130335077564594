import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import StockBar from "components/visuals/StockBar";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const CategoryYTDSalesGrowth = () => {
    const dispatch = useDispatch();
    const categoryYTDSalesGrowth = useSelector(state => selectors.categoryYTDSalesGrowth(state));
    const { loading, error, productCategoryGrowth, drilldownData } = categoryYTDSalesGrowth;
    productCategoryGrowth.sort((a,b) => {
        return b.y - a.y;
    });

    const options = {
        chart: {
            marginRight: 50
        },
        title: {
            text: "Revenue in the year-to-date vs the same period last year by category"
        },
        tooltip: {
            formatter() {
                const percentageSign = this.y > 0 ? " +" : " ";
                return '<b>' + this.key + '</b><br/>Growth (%)' + percentageSign + numberFormatter.toPercentage(this.y, true, 0);
            }
        },
        series: [
            {
                name: "Product Categories",
                data: productCategoryGrowth
            }],
        drilldown: {
            series: drilldownData
        },
        yAxis: {
            labels: {
                formatter: function() {return numberFormatter.toPercentage(this.value, true, 0);}
            }
        }
    };

    React.useEffect(() => {
        dispatch(operations.getCategoryYTDSalesGrowth());
    }, [dispatch]);

    return (
        <Box data-cy="category-ytd-sales-growth">
            <StockBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CategoryYTDSalesGrowth;
