import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import RetailCentresMap, { MapRetailCentre } from "components/visuals/maps/RetailCentresMap";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";
import StreetView from "./StreetView";

import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const theme = useTheme();
    const fillColours = [theme.palette.common.white];
    const borderColour = theme.palette.common.white;
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const mapRetailCentres: MapRetailCentre[] = [];
    if (retailCentre) {
        mapRetailCentres.push({
            code: retailCentre.code,
            colourGroup: 0
        });
    }

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="overview-map">
            <RetailCentresMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxRetailCentresTilesetId={mapboxConfiguration.retailCentresTilesetId}
                mapboxRetailCentresTilesetUrl={mapboxConfiguration.retailCentresTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                retailCentres={mapRetailCentres}
                fillColours={fillColours}
                borderColour={borderColour}
                enableHover={false}
                enableClick={false}
                displayPOIs={true}
            >
                <Explore />
                <PinnedLocation />
                <Legend />
                <StreetView />
            </RetailCentresMap>
        </Box>
    );
};

export default Map;
