import React from "react";

import { Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import SimpleDialog from "components/SimpleDialog";

import { useAppDispatch } from "store";
import { cancelUpload } from "modules/customer/data/dataSlice";

const CancelUpload: React.FC = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);

    const handleCancelUploadClick = () => {
        setOpen(true);
    };

    const handleYesClick = () => {
        dispatch(cancelUpload());
    };

    const handleNoClick = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton size="small" onClick={handleCancelUploadClick} color="inherit">
                <CloseIcon fontSize="inherit" />
            </IconButton>
            <SimpleDialog
                open={open}
                onClose={handleNoClick}
                title={
                    <>
                        <Typography variant="h1" component="div" color="error" gutterBottom>
                            <ErrorOutlineIcon fontSize="inherit" color="inherit" />
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                            Are you sure you want to cancel the upload?
                        </Typography>
                    </>
                }
                titleAlign="center"
                content={
                    <>
                        <Typography variant="subtitle1" component="div">
                            You will lose all progress.
                        </Typography>
                    </>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            color="default"
                            disableElevation
                            onClick={handleYesClick}
                        >
                            Cancel upload
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disableElevation
                            onClick={handleNoClick}
                        >
                            Continue upload
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default CancelUpload;
