import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export enum ComplementaryIndicator {
    NonComplementary,
    Complementary
}

export class ComplementaryCategory {
    public readonly name: string;
    public readonly indicator: ComplementaryIndicator;

    constructor(
        name: string,
        indicator: ComplementaryIndicator
    ) {
        this.name = name;
        this.indicator = indicator;
    }
}

export const loadComplementaryCategories = (): AppThunk<Promise<ComplementaryCategory[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "KpmgCategoryCompInd.kpmgCategory",
                "KpmgCategoryCompInd.complementaryIndicator"
            ],
            order: {
                "KpmgCategoryCompInd.kpmgCategory": "asc"
            },
            filters: [{
                member: "KpmgCategoryCompInd.complementaryIndicator",
                operator: "equals",
                values: ["0", "1"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => {
            const name = row["KpmgCategoryCompInd.kpmgCategory"];
            const indicator = Number(row["KpmgCategoryCompInd.complementaryIndicator"]) === 1
                ? ComplementaryIndicator.Complementary
                : ComplementaryIndicator.NonComplementary;
            return new ComplementaryCategory(name, indicator);
        });
    } catch (error) {
        dispatch(logError("Error loading ComplementaryCategories.", error));
        throw error;
    }
};
