import React from "react";

import { Box, Container, Paper, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { SubchapterProps } from "./Subchapter";
import ChapterHeader from "./ChapterHeader";

const useStyles = makeStyles(theme => ({
    paper: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}));

export interface ChapterProps {
    id: string,
    number: number,
    title: string,
    subtitle: string | React.ReactElement
    chapterRef: React.RefObject<HTMLDivElement>,
    dataCy: string,
    children: React.ReactElement<SubchapterProps>[]
}

const Chapter: React.FC<ChapterProps> = (props) => {
    const { id, number, title, subtitle, chapterRef, dataCy } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const scrollMarginTop = isMobile ? theme.spacing(12) : theme.spacing(3);

    return (
        // @ts-ignore
        <Box id={id} paddingBottom={8} ref={chapterRef} data-cy={dataCy} style={{ scrollMarginTop: scrollMarginTop }}>
            <ChapterHeader number={number} title={title} subtitle={subtitle} />
            <Paper elevation={0} className={classes.paper} data-cy="subchapters">
                <Container>
                    <Box paddingTop={4}>
                        {props.children}
                    </Box>
                </Container>
            </Paper>
        </Box>
    );
};

export default Chapter;
