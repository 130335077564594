import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ChartBase from "./ChartBase";

const NetworkGraph = (props) => {
    const { loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: "networkgraph"
        },
        plotOptions: {
            networkgraph: {
                layoutAlgorithm: {
                    friction: -0.9
                }
            }
        },

        tooltip: {
            style: {
                color: "#FFFFFF",
              textOutline: false,
              fontWeight: 'normal'
            },
            backgroundColor: '#3A384F',
            borderRadius: 6,
            borderWidth: 0,
            shadow: false,
            padding: 12            
          }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="network-graph-chart" />
    );
};

NetworkGraph.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

NetworkGraph.defaultProps = {
    loading: false,
    error: false
};

export default NetworkGraph;
