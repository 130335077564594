import { combineReducers } from "redux";

import types from "./types";

const salesPerformanceVsBudgetEmpty = {
    loading: false,
    error: false,
    salesFYTD: 0,
    salesFYTG: 0,
    budgetFY: 0,
    FYlabel: ""
};

const salesPerformanceVsBudget = (state = salesPerformanceVsBudgetEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                salesFYTD: 0,
                salesFYTG: 0,
                budgetFY: 0,
                FYlabel: ""
            };
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                salesFYTD: action.payload.salesFYTD,
                salesFYTG: action.payload.salesFYTG,
                budgetFY: action.payload.budgetFY,
                FYlabel: action.payload.FYlabel
            };
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                salesFYTD: 0,
                salesFYTG: 0,
                budgetFY: 0,
                FYlabel: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    salesPerformanceVsBudget
});

export default reducer;
