import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Dissect the shifts within your local retail landscape for your {store?.name} store.
            Reveal the net changes in store categories over time, offering a lens into the evolving retail composition.
            Understand which market sectors are experiencing growth or decline and how these trends could complement or compete with your brand.
            Capitalise on the dynamic retail environment to maintain relevance, exploit footfall, and secure your competitive edge.
        </>
    );
};

export default Subtitle;
