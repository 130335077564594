import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Evaluate the level of competition you would face both directly and indirectly. Analyse
            competitors positioning and size and assess whether your proposed store would be able to compete.
        </>
    );
};

export default Subtitle;
