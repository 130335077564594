import React from "react";

import { Button } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { withStyles } from "@material-ui/core/styles";

import {
    showFilters
} from "modules/customer/insights/cost/filters/filtersSlice";
import { useAppDispatch } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const Open: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(showFilters());
    };

    return (
        <>
            <StyledButton
                variant="outlined"
                size="small"
                color="default"
                disableElevation
                onClick={handleClick}
                data-cy="btn-setup"
            >
                <SettingsIcon />
            </StyledButton>
        </>
    );
};

export default Open;
