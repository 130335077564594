import React from "react";

import { Card, CardActionArea, CardMedia, Dialog } from "@material-ui/core";

import VideoPlayer from "components/VideoPlayer";
import { selectAzureCdnConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Welcome: React.FC = () => {
    const [isVideoVisible, setIsVideoVisible] = React.useState(false);
    const azureCdnConfiguration = useAppSelector(selectAzureCdnConfiguration);
    const onboardingVideoUrl = azureCdnConfiguration.onboardingVideoUrl;
    const videoJsOptions = { //Reference https://docs.videojs.com/tutorial-options.html for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
            pictureInPictureToggle: false
        },
        sources: [{
            src: onboardingVideoUrl,
            type: "video/mp4"
        }],
        tracks: [{
            kind: "captions",
            src: "/content/customer/onboarding/welcome/captions.txt",
            srclang: "en",
            label: "English"
        }],
        poster: "/content/customer/onboarding/welcome/video-poster.png",
        crossorigin: "anonymous"
    };

    const handleOpenClick = () => {
        setIsVideoVisible(true);
    };

    const handleCloseClick = () => {
        setIsVideoVisible(false);
    };

    return (
        <>
            <Card elevation={0}>
                <CardActionArea onClick={handleOpenClick} disableRipple disableTouchRipple>
                    <CardMedia component="img" image="/content/customer/onboarding/welcome/video-poster-with-play-button.png" alt="video poster" />
                </CardActionArea>
            </Card>
            <Dialog open={isVideoVisible} onClose={handleCloseClick} maxWidth="md" fullWidth>
                {/* @ts-ignore */}
                <VideoPlayer options={videoJsOptions} onClose={handleCloseClick} />
            </Dialog>
        </>
    );
};

export default Welcome;
