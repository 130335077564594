import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectRankedStoreGrowth } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import RankedStoresBeeswarm from "components/visuals/RankedStoresBeeswarm";

const StoreVsComparatorRankedLastTwelveMonthsGrowth = () => {
    const selectedStoreName = useAppSelector(selectStore)?.name ?? "";
    const comparatorName = useAppSelector(selectComparator)?.name ?? "";
    const storeVsComparatorRankedLastTwelveMonthsGrowth = useAppSelector(selectRankedStoreGrowth);
    const { isLoading, hasErrors, data } = storeVsComparatorRankedLastTwelveMonthsGrowth;

    return (
        <RankedStoresBeeswarm
            isLoading={isLoading}
            hasErrors={hasErrors}
            dataCy="store-vs-comparator-ranked-last-twelve-months-growth"
            title={`Ranked year-on-year growth (%) for ${comparatorName} and ${selectedStoreName}`}
            selectedStoreName={selectedStoreName}
            comparatorName={comparatorName}
            valueName="12 month growth"
            storesWithValues={data}
        />
    );
};

export default StoreVsComparatorRankedLastTwelveMonthsGrowth;
