import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import { withStyles } from "@material-ui/core/styles";

import Payment from "./Payment";
import PaymentError from "./PaymentError";
import PaymentSuccess from "./PaymentSuccess";
import PlanDetails from "./PlanDetails";
import TermsOfUse from "./TermsOfUse";
import Welcome from "./Welcome";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import {
    acceptTermsOfUse,
    completeOnboarding,
    downloadTermsOfUse,
    getCheckoutSession,
    OnboardingStep,
    selectActiveStep,
    selectCanAcceptTermsOfUse,
    selectCheckoutSession,
    setActiveStep,
    setCanAcceptTermsOfUse,
    setupPayment
} from "modules/customer/onboarding/onboardingSlice";

const StyledDialog = withStyles(theme => ({
    paper: {
        padding: theme.spacing(7),
        width: theme.spacing(97),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(2)
    }
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    }
}))(DialogContent);

const StyledDialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(1),
        justifyContent: "center"
    }
}))(DialogActions);

const StyledGridItemLeft = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    }
}))(Grid);

const StyledGridItemCenter = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}))(Grid);

const StyledGridItemRight = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}))(Grid);

const StyledDownloadButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Button);

const Onboarding: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const acceptedTermsOfUse = userInfo.acceptedTermsOfUse;
    const isAccountSetupComplete = userInfo.isAccountSetupComplete;
    const activeStep = useAppSelector(selectActiveStep);
    const checkoutSession = useAppSelector(selectCheckoutSession);
    const canAcceptTermsOfUse = useAppSelector(selectCanAcceptTermsOfUse);
    const canMoveNext = activeStep !== OnboardingStep.TermsOfUse || canAcceptTermsOfUse;
    const dialogContentRef = React.useRef<HTMLDivElement>(null);
    const { title, subtitle, component, legend, actionLabel } = (() => {
        switch (activeStep) {
            case OnboardingStep.Welcome:
                return {
                    title: "Welcome to Dash",
                    subtitle: "",
                    component: <Welcome />,
                    legend: "Activate your account to use Dash",
                    actionLabel: "Activate account"
                };
            case OnboardingStep.PlanDetails:
                return {
                    title: "Activate account",
                    subtitle: "Review Plan Details",
                    component: <PlanDetails />,
                    legend: "A copy of your Plan Details will be available for download in your Account area",
                    actionLabel: "Proceed with plan"
                };
            case OnboardingStep.TermsOfUse:
                return {
                    title: "Activate account",
                    subtitle: "Accept Terms of Use",
                    component: <TermsOfUse />,
                    legend: "Read all Terms to continue",
                    actionLabel: "Accept Terms"
                };
            case OnboardingStep.Payment:
                return {
                    title: "Activate account",
                    subtitle: "Setup monthly payment",
                    component: <Payment />,
                    legend: "",
                    actionLabel: "Setup payment"
                };
            case OnboardingStep.PaymentResult:
                return {
                    title: "",
                    subtitle: "",
                    component: checkoutSession.isComplete ? <PaymentSuccess /> : <PaymentError />,
                    legend: "",
                    actionLabel: checkoutSession.isComplete ? "Go to Dash" : "Setup payment"
                };
        }
    })();

    const handleDownloadClick = () => {
        if (activeStep === OnboardingStep.TermsOfUse) {
            dispatch(downloadTermsOfUse());
        }
    };

    const handleBackClick = () => {
        switch (activeStep) {
            case OnboardingStep.PlanDetails:
                dispatch(setActiveStep(OnboardingStep.Welcome));
                return;
            case OnboardingStep.TermsOfUse:
                dispatch(setActiveStep(OnboardingStep.PlanDetails));
                return;
            case OnboardingStep.Payment:
                dispatch(setActiveStep(OnboardingStep.TermsOfUse));
                return;
            case OnboardingStep.PaymentResult:
                dispatch(setActiveStep(OnboardingStep.Payment));
                return;
            default:
                return;
        }
    };

    const handleNextClick = () => {
        switch (activeStep) {
            case OnboardingStep.Welcome:
                dispatch(setActiveStep(OnboardingStep.PlanDetails));
                return;
            case OnboardingStep.PlanDetails:
                dispatch(setActiveStep(OnboardingStep.TermsOfUse));
                return;
            case OnboardingStep.TermsOfUse:
                dispatch(acceptTermsOfUse());
                return;
            case OnboardingStep.Payment:
                dispatch(setupPayment());
                return;
            case OnboardingStep.PaymentResult:
                checkoutSession.isComplete
                    ? dispatch(completeOnboarding())
                    : dispatch(setupPayment());
                return;
            default:
                return;
        }
    };

    const handleScroll = () => {
        if (activeStep === OnboardingStep.TermsOfUse && dialogContentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;
            if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 1) {
                dispatch(setCanAcceptTermsOfUse(true));
            }
        }
    };

    React.useEffect(() => {
        if (!isAccountSetupComplete) {
            dispatch(getCheckoutSession());
        }
    }, [dispatch, isAccountSetupComplete]);

    React.useEffect(() => {
        if (checkoutSession.id) {
            dispatch(setActiveStep(OnboardingStep.PaymentResult));
            return;
        }
        if (acceptedTermsOfUse) {
            dispatch(setActiveStep(OnboardingStep.Payment));
        }
    }, [dispatch, checkoutSession, acceptedTermsOfUse]);

    return (
        <StyledDialog open={!isAccountSetupComplete} maxWidth={false} scroll="paper">
            <StyledDialogTitle>
                <Typography variant="h2" component="div" align="center" gutterBottom>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="h5" component="div" align="center" gutterBottom>
                        {subtitle}
                    </Typography>
                )}
            </StyledDialogTitle>
            <StyledDialogContent ref={dialogContentRef} onScroll={handleScroll}>
                {component}
            </StyledDialogContent>
            <StyledDialogActions>
                <Grid container spacing={2} justifyContent="center">
                    <StyledGridItemRight item xs={12}>
                        {activeStep === OnboardingStep.TermsOfUse && (
                            <StyledDownloadButton
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                startIcon={<SaveAltRoundedIcon />}
                                onClick={handleDownloadClick}
                            >
                                Download
                            </StyledDownloadButton>
                        )}
                    </StyledGridItemRight>
                    <StyledGridItemCenter item xs={12}>
                        <Typography variant="body1" component="div" align="center" gutterBottom>
                            {legend}
                        </Typography>
                    </StyledGridItemCenter>
                    <StyledGridItemLeft item xs={3}>
                        {activeStep !== OnboardingStep.Welcome && (activeStep !== OnboardingStep.PaymentResult || !checkoutSession.isComplete) && (
                            <Button
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                onClick={handleBackClick}
                            >
                                Back
                            </Button>
                        )}
                    </StyledGridItemLeft>
                    <StyledGridItemCenter item xs={6}>
                        <Button
                            variant="contained"
                            size="large"
                            color="default"
                            disableElevation
                            disabled={!canMoveNext}
                            onClick={handleNextClick}
                        >
                            {actionLabel}
                        </Button>
                    </StyledGridItemCenter>
                    <StyledGridItemRight item xs={3}>
                        {(activeStep !== OnboardingStep.PaymentResult || !checkoutSession.isComplete) && (
                            <Button
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                href="mailto:dashsupport@kpmg.co.uk"
                            >
                                Contact us
                            </Button>
                        )}
                    </StyledGridItemRight>
                </Grid>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default Onboarding;
