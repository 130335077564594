import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import Spacer from "components/Spacer";
import PercentageIndicator from "components/visuals/PercentageIndicator";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/staffing";

const SalesValuePerHeadVariance = () => {
    const salesValuePerHeadVariance = useSelector(state => selectors.salesValuePerHeadVariance(state));
    const { loading, error, value } = salesValuePerHeadVariance;
    const label = "% variance from median";

    return (
        <Box display="flex" data-cy="sales-value-per-head-variance">
            <Typography variant="body1">{label}</Typography>
            <Spacer />
            <PercentageIndicator loading={loading} error={error} align="right" showIndicator={false} value={value} />
        </Box>
    );
};

export default SalesValuePerHeadVariance;
