import React from "react";

import { Box, Grid, IconButton, Typography } from "@material-ui/core";

import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip from "components/SimpleTooltip";

import DeleteSavedLocation from "./DeleteSavedLocation";

import { selectUserInfo } from "modules/auth/authSlice";
import {
    SavedLocation,
    showEditSavedLocation
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledGridItem = withStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center"
    }
}))(Grid);

const StyledActionsGridItem = withStyles(() => ({
    root: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end"
    }
}))(Grid);

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DisplaySavedLocationProps {
    savedLocation: SavedLocation
}

const DisplaySavedLocation: React.FC<DisplaySavedLocationProps> = (props) => {
    const { savedLocation } = props;
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);

    const handleEditClick = () => {
        dispatch(showEditSavedLocation(savedLocation.id));
    };

    return (
        <Grid container spacing={1}>
            <StyledGridItem item xs={8}>
                <Typography variant="h6" component="div">
                    {savedLocation.name}
                </Typography>
            </StyledGridItem>
            <StyledActionsGridItem item xs={4}>
                <Typography variant="h6" component="div">
                    {savedLocation.isPublic ? "Public" : "Private"}&nbsp;&nbsp;
                </Typography>
                {savedLocation.user.id === userInfo.id && (
                    <Box>
                        <SimpleTooltip title="Edit">
                            <StyledIconButton size="small" onClick={handleEditClick}>
                                <EditRoundedIcon fontSize="inherit" />
                            </StyledIconButton>
                        </SimpleTooltip>
                        <DeleteSavedLocation savedLocationId={savedLocation.id} />
                    </Box>
                )}
            </StyledActionsGridItem>
            <StyledGridItem item xs={12}>
                <Typography variant="subtitle1" component="div">
                    {savedLocation.notes}
                </Typography>
            </StyledGridItem>
        </Grid>
    );
};

export default DisplaySavedLocation;
