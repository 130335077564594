import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Get a feel for the customers in your proposed location's catchment area and understand how much of the
            population they make up. Drill down from customer profile supergroups to groups and subgroups to pinpoint
            the types of customers your new store can tap into. Explore their profiles and evaluate whether these
            customers align well with your target demography.
        </>
    );
};

export default Subtitle;
