import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import { selectCatchmentDemographicIndicators } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const AgeStructureNI: React.FC = () => {
    const title = "Age structure (Age)";
    const categories = [
        "=<15",
        "16-24",
        "25-44",
        "45-64",
        "65+"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.ageStructureNI;
    const selectedStoreValues = selectedStore ? [
        selectedStore.age0to15,
        selectedStore.age16to24,
        selectedStore.age25to44,
        selectedStore.age45to64,
        selectedStore.age65Plus
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.ageStructureNI;
    const comparatorValues = comparator ? [
        comparator.age0to15,
        comparator.age16to24,
        comparator.age25to44,
        comparator.age45to64,
        comparator.age65Plus
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default AgeStructureNI;
