import React from "react";

import { Box, Card, CardContent, IconButton, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

const StyledCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quaternary.main
    }
}))(Card);

const useStyles = makeStyles(theme => ({
    checkIcon: {
        color: theme.palette.tertiary.main
    }
}));

const RolesInfo = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleInfoClick = () => {
        setOpen(true);
    };

    const handleCloseClick = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton size="small" color="inherit" onClick={handleInfoClick}>
                <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
            <SimpleDialog
                open={open}
                onClose={handleCloseClick}
                title={
                    <Typography variant="h5" component="div" gutterBottom>
                        User roles
                    </Typography>
                }
                content={
                    <>
                        <Typography variant="subtitle1" gutterBottom>Owner</Typography>
                        <StyledCard elevation={0}>
                            <CardContent>
                                <Box display="flex" alignItems="center">
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        Company account owner
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" paddingTop={1}>
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        View tools and insights
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" paddingTop={1}>
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        Can manage data, users and billing
                                    </Typography>
                                </Box>
                            </CardContent>
                        </StyledCard>
                        <br />
                        <Typography variant="subtitle1" gutterBottom>Admin</Typography>
                        <StyledCard elevation={0}>
                            <CardContent>
                                <Box display="flex" alignItems="center">
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        View tools and insights
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" paddingTop={1}>
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        Can manage data, users and billing
                                    </Typography>
                                </Box>
                            </CardContent>
                        </StyledCard>
                        <br />
                        <Typography variant="subtitle1" gutterBottom>Editor</Typography>
                        <StyledCard elevation={0}>
                            <CardContent>
                                <Box display="flex" alignItems="center">
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        View tools and insights
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" paddingTop={1}>
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        Can manage data
                                    </Typography>
                                </Box>
                            </CardContent>
                        </StyledCard>
                        <br />
                        <Typography variant="subtitle1" gutterBottom>Viewer</Typography>
                        <StyledCard elevation={0}>
                            <CardContent>
                                <Box display="flex" alignItems="center">
                                    <CheckIcon fontSize="small" className={classes.checkIcon} />
                                    &nbsp;&nbsp;
                                    <Typography variant="subtitle1" component="div">
                                        View tools and insights
                                    </Typography>
                                </Box>
                            </CardContent>
                        </StyledCard>
                        <br />
                    </>
                }
            />
        </>
    );
};

export default RolesInfo;
