import React from "react";

const OpeningsAndClosuresSubtitle = () => {
    return (
        <>
            Are more stores opening near your store, causing the local area to thrive as footfall drivers increase? Or
            is the area seeing a high rate of closures, meaning reduced footfall? And most importantly, how is this
            changing over time?
        </>
    );
};

export default OpeningsAndClosuresSubtitle;
