import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/growth";

const StoreYoYGrowthVsComparator = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeYoYGrowthVsComparator = useSelector(state => selectors.storeYoYGrowthVsComparator(state));
    const { loading, error, value, percentageDifference } = storeYoYGrowthVsComparator;
    const label = `${selectedStore.name} YOY growth vs ${selectedComparator.name}`;
    const valueFormatted = numberFormatter.toPercentage(value, 1);

    return (
        <Box data-cy="store-yoy-growth-vs-comparator">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default StoreYoYGrowthVsComparator;
