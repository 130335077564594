import types from "./types";

const getStoreRevenueVsPYRequest = () => ({
    type: types.GET_STORE_REVENUE_VS_PY_REQUEST
});

const getStoreRevenueVsPYSuccess = (value, percentageDifference) => ({
    type: types.GET_STORE_REVENUE_VS_PY_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getStoreRevenueVsPYFailure = () => ({
    type: types.GET_STORE_REVENUE_VS_PY_FAILURE
});

const getStoreForecastRevenueVsPYRequest = () => ({
    type: types.GET_STORE_FORECAST_REVENUE_VS_PY_REQUEST
});

const getStoreForecastRevenueVsPYSuccess = (value, percentageDifference) => ({
    type: types.GET_STORE_FORECAST_REVENUE_VS_PY_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getStoreForecastRevenueVsPYFailure = () => ({
    type: types.GET_STORE_FORECAST_REVENUE_VS_PY_FAILURE
});

const getSalesHistoryRequest = () => ({
    type: types.GET_SALES_HISTORY_REQUEST
});

const getSalesHistorySuccess = (values) => ({
    type: types.GET_SALES_HISTORY_SUCCESS,
    payload: {
        values
    }
});

const getSalesHistoryFailure = () => ({
    type: types.GET_SALES_HISTORY_FAILURE
});

const getStoreHistoricSalesTrendRequest = () => ({
    type: types.GET_STORE_HISTORIC_SALES_TREND_REQUEST
});

const getStoreHistoricSalesTrendSuccess = (status, value) => ({
    type: types.GET_STORE_HISTORIC_SALES_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getStoreHistoricSalesTrendFailure = () => ({
    type: types.GET_STORE_HISTORIC_SALES_TREND_FAILURE
});

const getStoreFutureSalesTrendRequest = () => ({
    type: types.GET_STORE_FUTURE_SALES_TREND_REQUEST
});

const getStoreFutureSalesTrendSuccess = (status, value) => ({
    type: types.GET_STORE_FUTURE_SALES_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getStoreFutureSalesTrendFailure = () => ({
    type: types.GET_STORE_FUTURE_SALES_TREND_FAILURE
});

const actions = {
    getStoreForecastRevenueVsPYRequest,
    getStoreForecastRevenueVsPYSuccess,
    getStoreForecastRevenueVsPYFailure,
    getStoreRevenueVsPYRequest,
    getStoreRevenueVsPYSuccess,
    getStoreRevenueVsPYFailure,
    getSalesHistoryRequest,
    getSalesHistorySuccess,
    getSalesHistoryFailure,
    getStoreHistoricSalesTrendRequest,
    getStoreHistoricSalesTrendSuccess,
    getStoreHistoricSalesTrendFailure,
    getStoreFutureSalesTrendRequest,
    getStoreFutureSalesTrendSuccess,
    getStoreFutureSalesTrendFailure
};

export default actions;
