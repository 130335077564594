import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import actions from "./actions";

const getTypesOfContributionGrowth = () => async (dispatch) => {
    dispatch(actions.getTypesOfContributionGrowthRequest());
    try {
        const query = {
            measures: ["F_ContributionGrowth.SumGrowthValue"],
            order: [
                ["F_ContributionGrowth.OrderRank", "asc"],
            ],
            dimensions: ["F_ContributionGrowth.GrowthCategory", "F_ContributionGrowth.SegmentDescription", "F_ContributionGrowth.OrderRank"]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const data = resultSet.loadResponses[0].data.map(item => ({
            growthValue: item["F_ContributionGrowth.SumGrowthValue"],
            growthCategory: item["F_ContributionGrowth.GrowthCategory"],
            segmentDescription: item["F_ContributionGrowth.SegmentDescription"],
            orderRank: item["F_ContributionGrowth.OrderRank"]
        }));
        dispatch(actions.getTypesOfContributionGrowthSuccess(data));
    }
    catch (error) {
        dispatch(actions.getTypesOfContributionGrowthFailure());
        dispatch(logError("Error loading TypesOfContributionGrowth.", error));
    }
};

const operations = {
    getTypesOfContributionGrowth
};

export default operations;
