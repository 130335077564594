import React from "react";
import { NavLink } from "react-router-dom";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import SyncProblemRoundedIcon from "@material-ui/icons/SyncProblemRounded";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import PauseCircleOutlineOutlinedIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import {
    clearDatasetFileIssues,
    DataRefreshStatus,
    Dataset,
    DatasetStatus as DatasetStatusEnum,
    downloadDataGuidance,
    getDatasetFileIssues,
    selectDataRefresh,
    selectDatasetFileIssues
} from "modules/customer/data/dataSlice";

const StyledAlert = withStyles(theme => ({
    root: {
        borderLeftWidth: theme.spacing(1.5),
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        color: (props: any) => props["data-color"],
        borderColor: (props: any) => props["data-color"],
        cursor: "pointer"
    }
}))(Alert);

const StyledAccordion = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        borderColor: (props: any) => props["data-color"],
        borderStyle: "solid",
        "&$rounded": {
            borderRadius: theme.spacing(0.75)
        },
        "&$expanded": {
            marginBottom: theme.spacing(3)
        }
    },
    expanded: {},
    rounded: {}
}))(Accordion);

const StyledAccordionSummary = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: (props: any) => props["data-color"],
        color: theme.palette.background.default,
        "&$expanded": {
            minHeight: 0
        }
    },
    content: {
        "&$expanded": {
            margin: 0
        }
    },
    expanded: {}
}))(AccordionSummary);

const StyleAccordionDetails = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))(AccordionDetails);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(5)
    }
}))(Button);

const StyledDisabledButton = withStyles(theme => ({
    root: {
        "&$disabled": {
            color: theme.palette.common.white,
            padding: theme.spacing(0)
        }
    },
    disabled: {}
}))(Button);

const StyledDialog = withStyles(theme => ({
    paper: {
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(0),
        paddingTop: theme.spacing(5)
    }
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(3)
    }
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(0)
    }
}))(DialogContent);

const StyledDialogActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        padding: theme.spacing(5),
        paddingTop: theme.spacing(3),
        justifyContent: "flex-start"
    }
}))(DialogActions);

interface DatasetStatusProps {
    dataset: Dataset
}

const DatasetStatus: React.FC<DatasetStatusProps> = (props) => {
    const { dataset } = props;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const datasetFileIssues = useAppSelector(selectDatasetFileIssues);
    const dataRefresh = useAppSelector(selectDataRefresh);
    const [open, setOpen] = React.useState(false);
    const { color, text, icon, title, message } = (() => {
        switch (dataset.status) {
            case DatasetStatusEnum.Ok:
                return {
                    color: theme.palette.success.main,
                    text: "Up to date",
                    icon: <CheckCircleOutlineIcon />,
                    title: "Up to date",
                    message: "Up to date"
                };
            case DatasetStatusEnum.Submitted:
                return {
                    color: theme.palette.text.disabled,
                    text: "Submitted",
                    icon: <SyncRoundedIcon />,
                    title: "Submitted",
                    message: "Your new data has been submitted to Dash and is now being scanned for viruses. Scanning your data can take a while so check back later to see the status of this dataset."
                };
            case DatasetStatusEnum.Processing:
                return {
                    color: theme.palette.text.disabled,
                    text: "New data processing",
                    icon: <SyncRoundedIcon />,
                    title: "New data is processing",
                    message: "Your new data has been successfully uploaded to Dash and is now being processed. Processing allows us to check your data for any issues before you refresh your Analytics. Processing your data can take a while so check back later to see the status of this dataset."
                };
            case DatasetStatusEnum.ProcessingIssue:
                return {
                    color: theme.palette.error.main,
                    text: "Processing issue",
                    icon: <SyncProblemRoundedIcon />,
                    title: "Processing issue",
                    message: "Processing issue"
                };
            case DatasetStatusEnum.ReadyForRefresh:
                if (dataRefresh.status === DataRefreshStatus.InProgress || dataRefresh.status === DataRefreshStatus.Paused) {
                    return {
                        color: theme.palette.text.disabled,
                        text: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused",
                        icon: (dataRefresh.status === DataRefreshStatus.InProgress)
                            ? <RefreshRoundedIcon />
                            : <PauseCircleOutlineOutlinedIcon />,
                        title: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused",
                        message: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused"
                    };
                }
                return {
                    color: theme.palette.success.main,
                    text: "Ready for refresh",
                    icon: <CheckCircleOutlineRoundedIcon />,
                    title: "Dataset ready for refresh",
                    message: "This dataset has been processed successfully and is ready to be included in your next refresh. Make sure all of your updated datasets are ready before you start your refresh."
                };
            case DatasetStatusEnum.RecommendUpdate:
                if ((dataRefresh.status === DataRefreshStatus.InProgress || dataRefresh.status === DataRefreshStatus.Paused) && dataset.newData) {
                    return {
                        color: theme.palette.text.disabled,
                        text: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused",
                        icon: (dataRefresh.status === DataRefreshStatus.InProgress)
                            ? <RefreshRoundedIcon />
                            : <PauseCircleOutlineOutlinedIcon />,
                        title: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused",
                        message: (dataRefresh.status === DataRefreshStatus.InProgress) ? "Refresh in progress" : "Refresh paused"
                    };
                }
                return {
                    color: theme.palette.warning.main,
                    text: "Recommend update",
                    icon: <UpdateRoundedIcon />,
                    title: "Recommend update",
                    message: "Recommend update"
                };
            case DatasetStatusEnum.Unknown:
            default:
                return {
                    color: dataset.isRequired ? theme.palette.secondary.main : theme.palette.text.secondary,
                    text: dataset.isRequired ? "Upload required" : "Upload recommended",
                    icon: <NoteAddOutlinedIcon />,
                    title: dataset.isRequired ? "Upload required for this dataset" : "Upload recommended for this dataset",
                    message: dataset.isRequired
                        ? "To start using Dash you will need to provide your data for this dataset. The data will be used throughout your Analytics so it's crucial you provide this on your first upload. After that you're free to update your data whenever suits you."
                        : "You don't have to provide your data for this dataset to start using Dash but it is recommended as some elements won't be available without it. Key performance indicators, charts and recommendations may be empty without this data available."
                };
        }
    })();

    const handleStatusClick = () => {
        if (dataset.status === DatasetStatusEnum.ProcessingIssue || dataset.status === DatasetStatusEnum.RecommendUpdate) {
            dispatch(getDatasetFileIssues(dataset.id));
        } else {
            dispatch(clearDatasetFileIssues());
        }
        setOpen(true);
    };

    const handleCloseClick = () => {
        setOpen(false);
        dispatch(clearDatasetFileIssues());
    };

    const handleReadDataGuidanceClick = () => {
        dispatch(downloadDataGuidance());
    };

    if (dataset.status === DatasetStatusEnum.Ok) {
        return null;
    }

    if ((dataRefresh.status === DataRefreshStatus.InProgress || dataRefresh.status === DataRefreshStatus.Paused)
        && (dataset.status !== DatasetStatusEnum.ReadyForRefresh && dataset.status !== DatasetStatusEnum.RecommendUpdate)) {
        return null;
    }

    return (
        <Box paddingX={2} flexGrow={1}>
            <StyledAlert
                data-color={color}
                variant="outlined"
                onClick={handleStatusClick}
                icon={
                    React.cloneElement(icon, {
                        fontSize: "inherit",
                        style: {
                            color: color
                        }
                    })
                }
                action={
                    <IconButton size="small" color="inherit">
                        <OpenInNewRoundedIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {text}
            </StyledAlert>
            <StyledDialog
                open={open}
                onClose={handleCloseClick}
                maxWidth="md"
                fullWidth
            >
                <Box position="absolute" top={0} right={0}>
                    <IconButton size="medium" onClick={handleCloseClick}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                {/* @ts-ignore */}
                <StyledDialogTitle align="left">
                    <Typography variant="h5" component="div">
                        {dataset.name}
                    </Typography>
                </StyledDialogTitle>
                <StyledDialogContent>
                    {(dataset.status === DatasetStatusEnum.ProcessingIssue || dataset.status === DatasetStatusEnum.RecommendUpdate) && datasetFileIssues.map((datasetFileIssue, index) =>
                        <StyledAccordion key={index} expanded data-color={color}>
                            <StyledAccordionSummary data-color={color}>
                                <Box display="flex" alignItems="center">
                                    {React.cloneElement(icon)}
                                    &nbsp;&nbsp;
                                    <Typography variant="h6" component="div">
                                        {datasetFileIssue.issueType}
                                    </Typography>
                                </Box>
                            </StyledAccordionSummary>
                            <StyleAccordionDetails>
                                <Typography variant="subtitle1" component="div">
                                    {datasetFileIssue.issueDetail}
                                </Typography>
                            </StyleAccordionDetails>
                        </StyledAccordion>
                    )}
                    {((dataset.status !== DatasetStatusEnum.ProcessingIssue && dataset.status !== DatasetStatusEnum.RecommendUpdate) || datasetFileIssues.length === 0) && (
                        <StyledAccordion expanded data-color={color}>
                            <StyledAccordionSummary data-color={color}>
                                <Box display="flex" alignItems="center">
                                    {React.cloneElement(icon)}
                                    &nbsp;&nbsp;
                                    <Typography variant="h6" component="div">
                                        {title}
                                    </Typography>
                                </Box>
                            </StyledAccordionSummary>
                            <StyleAccordionDetails>
                                <Typography variant="subtitle1" component="div">
                                    {message}
                                </Typography>
                            </StyleAccordionDetails>
                        </StyledAccordion>
                    )}
                </StyledDialogContent>
                {(dataset.status === DatasetStatusEnum.ProcessingIssue || dataset.status === DatasetStatusEnum.Unknown) && (
                    <StyledDialogActions>
                        <Box display="flex" alignItems="left">
                            <StyledDisabledButton
                                variant="text"
                                size="medium"
                                color="default"
                                disabled
                            >
                                <Typography variant="subtitle1" component="div">
                                    Need help?
                                </Typography>
                            </StyledDisabledButton>

                            <StyledButton
                                variant="text"
                                size="medium"
                                color="default"
                                startIcon={<OpenInNewRoundedIcon />}
                                onClick={handleReadDataGuidanceClick}
                            >
                                <Typography variant="subtitle1" component="div">
                                    Read Dash's data guidance
                                </Typography>
                            </StyledButton>
                            {/* @ts-ignore */}
                            <StyledButton component={NavLink}
                                variant="text"
                                size="medium"
                                color="default"
                                startIcon={<OpenInNewRoundedIcon />}
                                to="/help"
                                target="_blank"
                            >
                                <Typography variant="subtitle1" component="div">
                                    Contact our support team
                                </Typography>
                            </StyledButton>
                        </Box>
                    </StyledDialogActions>
                )}
            </StyledDialog>
        </Box>
    );
};

export default DatasetStatus;
