import { createSelector } from "reselect";

import { selectSubchaptersIds } from "modules/customer/insights/performance/performanceSlice";

import { selectors as revenueOverTimeSelectors } from "modules/customer/insights/performance/sales/revenueOverTime";
import { selectors as revenueGrowthBreakdownSelectors } from "modules/customer/insights/performance/sales/revenueGrowthBreakdown";
import { selectors as revenueGrowthSelectors } from "modules/customer/insights/performance/sales/revenueGrowth";
import { selectors as contributionGrowthSelectors } from "modules/customer/insights/performance/sales/contributionGrowth";
import { selectors as regionalGrowthBreakdownSelectors } from "modules/customer/insights/performance/sales/regionalGrowthBreakdown";
import { selectors as productCategoryBreakdownSelectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

import { selectors as forecastRevenueSelectors } from "modules/customer/insights/performance/forecast/forecastRevenue";
import { selectors as forecastGrowthVsMarketSelectors } from "modules/customer/insights/performance/forecast/forecastGrowthVsMarket";
import { selectors as regionalBreakdownSelectors } from "modules/customer/insights/performance/forecast/regionalBreakdown";

import { selectors as revenueVsBudgetSelectors } from "modules/customer/insights/performance/budget/revenueVsBudget";
import { selectors as revenueVsBudgetOverTimeSelectors } from "modules/customer/insights/performance/budget/revenueVsBudgetOverTime";
import { selectors as contributionVsBudgetSelectors } from "modules/customer/insights/performance/budget/contributionVsBudget";
import { selectors as storePerformanceAgainstBudgetSelectors } from "modules/customer/insights/performance/budget/storePerformanceAgainstBudget";
//import { selectors as budgetVsForecastRevenueSelectors } from "modules/customer/insights/performance/budget/budgetVsForecastRevenue";
//import { selectors as budgetedVsForecastContributionSelectors } from "modules/customer/insights/performance/budget/budgetedVsForecastContribution";

import { scrollIntoViewById } from "utils/scrollUtils";

const allRags = createSelector(
    (state) => selectSubchaptersIds(state),
    revenueOverTimeSelectors.historicOverallCompanySalesTrend,
    revenueOverTimeSelectors.historicShortTermCompanySalesTrend,
    revenueGrowthBreakdownSelectors.longTermGrowthVsMarket,
    revenueGrowthBreakdownSelectors.shortTermGrowthVsMarket,
    revenueGrowthSelectors.organicVsInorganicSalesGrowth,
    contributionGrowthSelectors.organicVsInorganicContributionGrowth,
    regionalGrowthBreakdownSelectors.dependencyOnSingularRegionOrStore,
    productCategoryBreakdownSelectors.categoriesGrowingOrDeclining,
    forecastRevenueSelectors.forecastCompanySalesTrend,
    forecastGrowthVsMarketSelectors.forecastGrowthVsMarket,
    regionalBreakdownSelectors.forecastDependencyOnSingularRegionOrStore,
    revenueVsBudgetSelectors.salesYTDPlusSalesYTGVsBudget,
    revenueVsBudgetOverTimeSelectors.trackingToBudget,
    contributionVsBudgetSelectors.contributionYTDPlusContributionYTGVsBudget,
    storePerformanceAgainstBudgetSelectors.storesVsBudget,
    (subchaptersIds,
     historicOverallCompanySalesTrend,
     historicShortTermCompanySalesTrend,
     longTermGrowthVsMarket,
     shortTermGrowthVsMarket,
     organicVsInorganicSalesGrowth,
     organicVsInorganicContributionGrowth,
     dependencyOnSingularRegionOrStore,
     categoriesGrowingOrDeclining,
     forecastCompanySalesTrend,
     forecastGrowthVsMarket,
     forecastDependencyOnSingularRegionOrStore,
     salesYTDPlusSalesYTGVsBudget,
     trackingToBudget,
     contributionYTDPlusContributionYTGVsBudget,
     storesVsBudget) => {
        return [{
            ...historicOverallCompanySalesTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenueOverTime)
        }, {
            ...historicShortTermCompanySalesTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenueOverTime)
        }, {
            ...longTermGrowthVsMarket,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenueGrowthBreakdown)
        }, {
            ...shortTermGrowthVsMarket,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenueGrowthBreakdown)
        }, {
            ...organicVsInorganicSalesGrowth,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenueGrowth)
        }, {
            ...organicVsInorganicContributionGrowth,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.contributionGrowth)
        }, {
            ...dependencyOnSingularRegionOrStore,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.regionalGrowthBreakdown)
        }, {
            ...categoriesGrowingOrDeclining,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.productCategoryBreakdown)
        }, {
            ...forecastCompanySalesTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.forecast.forecastRevenue)
        }, {
            ...forecastGrowthVsMarket,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.forecast.forecastGrowthVsMarket)
        }, {
            ...forecastDependencyOnSingularRegionOrStore,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.forecast.regionalBreakdown)
        }, {
            ...salesYTDPlusSalesYTGVsBudget,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.budget.revenueVsBudget)
        }, {
            ...trackingToBudget,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.budget.revenueVsBudgetOverTime)
        }, {
            ...contributionYTDPlusContributionYTGVsBudget,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.budget.contributionVsBudget)
        }, {
            ...storesVsBudget,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.budget.storePerformanceAgainstBudget)
        }];
    }
);

const selectors = {
    allRags
};

export default selectors;
