import React from "react";

import { Typography } from "@material-ui/core";


const Subtitle: React.FC = () => {

    return (
        <Typography variant="body1" component="div" color="textSecondary">
            Changes will only be applied to sub-chapters 3.2 onwards.
            They will not affect your alignment score in sub-chapter 3.1.
        </Typography>
    );
};

export default Subtitle;
