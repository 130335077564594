import { AppThunk } from "appThunk";
import { RetailCentre } from "modules/customer/tools/location/retailCentre";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CatchmentArea {
    public readonly retailCentre: RetailCentre;
    public readonly outputAreas: OutputArea[];

    constructor(
        retailCentre: RetailCentre,
        outputAreas: OutputArea[]
    ) {
        this.retailCentre = retailCentre;
        this.outputAreas = outputAreas;
    }
}

interface OutputArea {
    code: string,
    numberOfCustomers: number,
    likelihoodOfVisting: number,
    totalPopulation: number,
    customerProfile: string
}

interface CatchmentCustomerProfilesDimensions {
    "CatchmentCustomerProfiles.RetailCentreID": number,
    "CatchmentCustomerProfiles.StoreCategory_ID": number,
    "CatchmentCustomerProfiles.OAID": string,
    "CatchmentCustomerProfiles.Population": number,
    "CatchmentCustomerProfiles.Probability": number,
    "CatchmentCustomerProfiles.SupergroupName": string
}

export const loadCatchmentAreas = (retailCentres: RetailCentre[], isScenario: boolean, catchmentAccountId: string): AppThunk<Promise<CatchmentArea[]>> => async (dispatch) => {
    try {
        if (retailCentres.length === 0) {
            return [];
        }

        const retailCentreIdsByStoreCategoryId = new Map<number, number[]>();
        retailCentres.forEach(retailCentre => {
            const retailCentreIds = retailCentreIdsByStoreCategoryId.get(retailCentre.storeCategoryId) ?? [];
            retailCentreIds.push(retailCentre.id);
            retailCentreIdsByStoreCategoryId.set(retailCentre.storeCategoryId, retailCentreIds);
        });

        const orFilterClause = { or: [] };
        retailCentreIdsByStoreCategoryId.forEach((retailCentreIds, storeCategoryId) => {
            const andFilterClause = {
                and: [{
                    member: "CatchmentCustomerProfiles.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryId)]
                }, {
                    member: "CatchmentCustomerProfiles.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIds.map(String)
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });
        const segmentName = isScenario ? "Scenario" : "Baseline";

        const query = {
            dimensions: [
                "CatchmentCustomerProfiles.RetailCentreID",
                "CatchmentCustomerProfiles.StoreCategory_ID",
                "CatchmentCustomerProfiles.OAID",
                "CatchmentCustomerProfiles.Population",
                "CatchmentCustomerProfiles.Probability",
                "CatchmentCustomerProfiles.SupergroupName"
            ],
            filters: [orFilterClause],
            segments: ["CatchmentCustomerProfiles." + segmentName]
        };

        const resultSet = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentCustomerProfiles")) as unknown as ExtendedResultSet<CatchmentCustomerProfilesDimensions>;
        const rawData = resultSet.loadResponses[0].data;

        const catchmentAreas = retailCentres.map(retailCentre => {
            const relevantRecords = rawData.filter(row => row["CatchmentCustomerProfiles.RetailCentreID"] === retailCentre.id
                && Number(row["CatchmentCustomerProfiles.StoreCategory_ID"]) === retailCentre.storeCategoryId);

            const outputAreas: OutputArea[] = relevantRecords.map(row => ({
                code: row["CatchmentCustomerProfiles.OAID"],
                numberOfCustomers: row["CatchmentCustomerProfiles.Probability"] * row["CatchmentCustomerProfiles.Population"],
                likelihoodOfVisting: row["CatchmentCustomerProfiles.Probability"],
                totalPopulation: row["CatchmentCustomerProfiles.Population"],
                customerProfile: row["CatchmentCustomerProfiles.SupergroupName"]
            }));

            return new CatchmentArea(
                retailCentre,
                outputAreas
            );
        });

        return catchmentAreas;
    } catch (error) {
        dispatch(logError("Error loading CatchmentAreas.", error));
        throw error;
    }
};
