import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AbortController } from "@azure/abort-controller";
import { BlockBlobClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

export enum DatasetType {
    Csv = "Csv",
    Xlsx = "Xlsx"
}

export enum DatasetStatus {
    Unknown = "Unknown",
    Submitted = "Submitted",
    Ok = "Ok",
    Processing = "Processing",
    ProcessingIssue = "ProcessingIssue",
    ReadyForRefresh = "ReadyForRefresh",
    RecommendUpdate = "RecommendUpdate"
}

export interface DatasetFile {
    id: string,
    fileName: string,
    fileSizeInBytes: number,
    uploadedBy: string,
    uploadedAt: DateTime
}

export interface Dataset {
    id: string,
    name: string,
    type: DatasetType,
    isRequired: boolean,
    status: DatasetStatus,
    newData?: DatasetFile,
    currentData?: DatasetFile
}

export enum DataRefreshStatus {
    PendingDatasets = "PendingDatasets",
    Ready = "Ready",
    InProgress = "InProgress",
    Paused = "Paused",
    UnavailableDataProcessing = "UnavailableDataProcessing",
    UnavailableNoAllowance = "UnavailableNoAllowance"
}

interface DataRefresh {
    status: DataRefreshStatus,
    nextRefreshAvailableAt: DateTime
}

interface DataRefreshVisibility {
    isVisible: boolean
}

interface DatasetFileIssue {
    issueType: string,
    issueDetail: string
}

interface UploadVisibility {
    isVisible: boolean,
    datasetId: string
}

interface UploadInfo {
    datasetId: string,
    fileId: string,
    fileName: string,
    fileSize: number,
    progress: number,
    isComplete: boolean,
    isSuccess: boolean,
    abortController?: AbortController
}

interface CloseUploadConfirmationVisibility {
    isVisible: boolean,
    datasetId: string
}

interface GuidanceVisibility {
    isVisible: boolean
}

export enum GuidanceStep {
    DashUsesDatasets,
    DownloadTemplates,
    UploadData,
    ProcessData,
    RefreshAnalytics,
    ReadDataGuidance
}

interface DataState {
    datasets: Dataset[],
    dataRefresh: DataRefresh,
    dataRefreshVisibility: DataRefreshVisibility,
    datasetFileIssues: DatasetFileIssue[],
    uploadVisibility: UploadVisibility,
    uploadInfo: UploadInfo,
    closeUploadConfirmationVisibility: CloseUploadConfirmationVisibility,
    dontShowGuidanceAgain: boolean,
    guidanceVisibility: GuidanceVisibility,
    guidanceActiveStep: GuidanceStep
}

const dataRefreshEmpty: DataRefresh = {
    status: DataRefreshStatus.PendingDatasets,
    nextRefreshAvailableAt: DateTime.fromMillis(0, { zone: "utc" })
};

const dataRefreshVisibilityEmpty: DataRefreshVisibility = {
    isVisible: false
};

const uploadVisibilityEmpty: UploadVisibility = {
    isVisible: false,
    datasetId: ""
};

const uploadInfoEmpty: UploadInfo = {
    datasetId: "",
    fileId: "",
    fileName: "",
    fileSize: 0,
    progress: 0,
    isComplete: false,
    isSuccess: false,
    abortController: undefined
};

const closeUploadConfirmationVisibilityEmpty: CloseUploadConfirmationVisibility = {
    isVisible: false,
    datasetId: ""
};

const guidanceVisibilityEmpty: GuidanceVisibility = {
    isVisible: false
};

const initialState: DataState = {
    datasets: [],
    dataRefresh: dataRefreshEmpty,
    dataRefreshVisibility: dataRefreshVisibilityEmpty,
    datasetFileIssues: [],
    uploadVisibility: uploadVisibilityEmpty,
    uploadInfo: uploadInfoEmpty,
    closeUploadConfirmationVisibility: closeUploadConfirmationVisibilityEmpty,
    dontShowGuidanceAgain: false,
    guidanceVisibility: guidanceVisibilityEmpty,
    guidanceActiveStep: GuidanceStep.DashUsesDatasets
};

const dataSlice = createSlice({
    name: "customer/data",
    initialState,
    reducers: {
        setDatasets: (state, action: PayloadAction<Dataset[]>) => {
            state.datasets = action.payload;
        },
        clearDatasets: (state) => {
            state.datasets = [];
        },
        setDataRefresh: (state, action: PayloadAction<DataRefresh>) => {
            state.dataRefresh = action.payload;
        },
        clearDataRefresh: (state) => {
            state.dataRefresh = dataRefreshEmpty;
        },
        showDataRefresh: (state) => {
            state.dataRefreshVisibility.isVisible = true;
        },
        hideDataRefresh: (state) => {
            state.dataRefreshVisibility.isVisible = false;
        },
        setDatasetFileIssues: (state, action: PayloadAction<DatasetFileIssue[]>) => {
            state.datasetFileIssues = action.payload;
        },
        clearDatasetFileIssues: (state) => {
            state.datasetFileIssues = [];
        },
        showUpload: (state, action: PayloadAction<string>) => {
            state.uploadVisibility.datasetId = action.payload;
            state.uploadVisibility.isVisible = true;
        },
        hideUpload: (state) => {
            state.uploadVisibility = uploadVisibilityEmpty;
        },
        setUploadInfo: (state, action: PayloadAction<UploadInfo>) => {
            state.uploadInfo = action.payload;
        },
        clearUploadInfo: (state) => {
            state.uploadInfo = uploadInfoEmpty;
        },
        showCloseUploadConfirmation: (state, action: PayloadAction<string>) => {
            state.closeUploadConfirmationVisibility.datasetId = action.payload;
            state.closeUploadConfirmationVisibility.isVisible = true;
        },
        hideCloseUploadConfirmation: (state) => {
            state.closeUploadConfirmationVisibility = closeUploadConfirmationVisibilityEmpty;
        },
        setDontShowGuidanceAgain: (state, action: PayloadAction<boolean>) => {
            state.dontShowGuidanceAgain = action.payload;
        },
        showGuidance: (state) => {
            state.guidanceVisibility.isVisible = true;
        },
        hideGuidance: (state) => {
            state.guidanceVisibility = guidanceVisibilityEmpty;
        },
        setGuidanceActiveStep: (state, action: PayloadAction<GuidanceStep>) => {
            state.guidanceActiveStep = action.payload;
        }
    }
});

export const {
    showDataRefresh,
    hideDataRefresh,
    clearDatasetFileIssues,
    showUpload,
    hideUpload,
    showCloseUploadConfirmation,
    hideCloseUploadConfirmation,
    showGuidance,
    hideGuidance,
    setGuidanceActiveStep
} = dataSlice.actions;

export const getData = (showBackdrop: boolean): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/data", showBackdrop));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const datasetsRaw = response.data.datasets;
            const datasets = datasetsRaw.map((datasetRaw: any) => ({
                ...datasetRaw,
                currentData: datasetRaw.currentData
                    ? {
                        ...datasetRaw.currentData,
                        uploadedAt: DateTime.fromISO(datasetRaw.currentData.uploadedAt, { zone: "utc" })
                    }
                    : undefined,
                newData: datasetRaw.newData
                    ? {
                        ...datasetRaw.newData,
                        uploadedAt: DateTime.fromISO(datasetRaw.newData.uploadedAt, { zone: "utc" })
                    }
                    : undefined

            }));
            dispatch(dataSlice.actions.setDatasets(datasets));

            const dataRefreshRaw = response.data.dataRefresh;
            const dataRefresh = {
                ...dataRefreshRaw,
                nextRefreshAvailableAt: DateTime.fromISO(dataRefreshRaw.nextRefreshAvailableAt, { zone: "utc" })
            };
            dispatch(dataSlice.actions.setDataRefresh(dataRefresh));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(dataSlice.actions.clearDatasets());
            dispatch(dataSlice.actions.clearDataRefresh());
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const getDatasetFileIssues = (datasetId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const datasets = selectDatasets(state);
    const dataset = datasets.find(dataset => dataset.id === datasetId);
    const fileId = dataset?.newData?.id ?? dataset?.currentData?.id;
    const response = await dispatch(apiGet(`/customer/data/${datasetId}/files/${fileId}/issues`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const datasetFileIssues = response.data.datasetFileIssues;
            dispatch(dataSlice.actions.setDatasetFileIssues(datasetFileIssues));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(dataSlice.actions.clearDatasetFileIssues());
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const downloadTemplate = (datasetId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/customer/data/${datasetId}/template`));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const downloadDataGuidance = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/data/guidance"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const verifyDontShowGuidanceAgain = (): AppThunk => (dispatch) => {
    const value = localStorage.getItem("dash.customer.data.dont_show_guidance_again");
    const dontShowGuidanceAgain = value === "true"; //hack bc localstorage only deals with strings
    dispatch(dataSlice.actions.setDontShowGuidanceAgain(dontShowGuidanceAgain));
    if (dontShowGuidanceAgain) {
        dispatch(dataSlice.actions.hideGuidance());
    } else {
        dispatch(dataSlice.actions.showGuidance());
    }
};

export const setDontShowGuidanceAgain = (dontShowGuidanceAgain: boolean): AppThunk => (dispatch) => {
    localStorage.setItem("dash.customer.data.dont_show_guidance_again", dontShowGuidanceAgain.toString());
    dispatch(dataSlice.actions.setDontShowGuidanceAgain(dontShowGuidanceAgain));
};

export const upload = (file: File): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const datasetId = selectUploadVisibility(state).datasetId;
    const uploadInfo = selectUploadInfo(state);
    dispatch(dataSlice.actions.setUploadInfo({
        ...uploadInfo,
        datasetId,
        fileName: file.name,
        fileSize: file.size
    }));
    await dispatch(startUpload(file));
};

const startUpload = (file: File): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    const body = {
        fileName: file.name,
        fileSize: file.size
    };
    const response = await dispatch(apiPost(`/customer/data/${uploadInfo.datasetId}/files`, body));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(dataSlice.actions.setUploadInfo({
                ...uploadInfo,
                fileId: response.data.fileId
            }));
            dispatch(executeUpload(file, response.data.uploadUrl));
            break;
        }
        default: {
            dispatch(dataSlice.actions.setUploadInfo({
                ...uploadInfo,
                isComplete: true,
                isSuccess: false
            }));
        }
    }
};

const executeUpload = (file: File, uploadUrl: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    const abortController = new AbortController();
    dispatch(dataSlice.actions.setUploadInfo({
        ...uploadInfo,
        abortController
    }));

    const abortSignal = abortController.signal;
    const oneMegabyte = 1024 * 1024;
    const bufferSize = 50 * oneMegabyte;

    try {
        const blockBlobClient = new BlockBlobClient(uploadUrl);
        const options: BlockBlobParallelUploadOptions = {
            abortSignal,
            maxSingleShotSize: bufferSize,
            onProgress: progress => dispatch(uploadProgress(progress.loadedBytes))
        };
        await blockBlobClient.uploadData(file, options);
        await dispatch(completeUpload());
    } catch (error) {
        dispatch(logError("Error uploading file.", error));
        dispatch(failUpload());
    }
};

const uploadProgress = (progress: number): AppThunk => (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    dispatch(dataSlice.actions.setUploadInfo({
        ...uploadInfo,
        progress
    }));
};

const completeUpload = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    await dispatch(apiPut(`/customer/data/${uploadInfo.datasetId}/files/${uploadInfo.fileId}/complete`, {}));
    dispatch(dataSlice.actions.setUploadInfo({
        ...uploadInfo,
        isComplete: true,
        isSuccess: true
    }));
};

const failUpload = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    await dispatch(apiPut(`/customer/data/${uploadInfo.datasetId}/files/${uploadInfo.fileId}/fail`, {}));
    dispatch(dataSlice.actions.setUploadInfo({
        ...uploadInfo,
        isComplete: true,
        isSuccess: false
    }));
};

export const cancelUpload = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    const abortController = uploadInfo.abortController;
    if (abortController) {
        abortController.abort();
    }
    await dispatch(apiPut(`/customer/data/${uploadInfo.datasetId}/files/${uploadInfo.fileId}/fail`, {}));
    dispatch(dataSlice.actions.clearUploadInfo());
};

export const submitForProcessing = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const uploadInfo = selectUploadInfo(state);
    const response = await dispatch(apiPut(`/customer/data/${uploadInfo.datasetId}/files/${uploadInfo.fileId}/submit`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideUpload());
            dispatch(dataSlice.actions.clearUploadInfo());
            dispatch(getData(true));
            dispatch(notifySuccess("Data submitted. Dataset status will be updated shortly."));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("File not found."));
            break;
        }
    }
};

export const proceedWithRefresh = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost("/customer/data/refresh", {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideDataRefresh());
            dispatch(getData(true));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const selectDatasets = (state: RootState) => {
    return state.customer.data.datasets;
};

export const selectDataRefresh = (state: RootState) => {
    return state.customer.data.dataRefresh;
};

export const selectDataRefreshVisibility = (state: RootState) => {
    return state.customer.data.dataRefreshVisibility;
};

export const selectDatasetFileIssues = (state: RootState) => {
    return state.customer.data.datasetFileIssues;
};

export const selectUploadVisibility = (state: RootState) => {
    return state.customer.data.uploadVisibility;
};

export const selectUploadInfo = (state: RootState) => {
    return state.customer.data.uploadInfo;
};

export const selectCloseUploadConfirmationVisibility = (state: RootState) => {
    return state.customer.data.closeUploadConfirmationVisibility;
};

export const selectDontShowGuidanceAgain = (state: RootState) => {
    return state.customer.data.dontShowGuidanceAgain;
};

export const selectGuidanceVisibility = (state: RootState) => {
    return state.customer.data.guidanceVisibility;
};

export const selectGuidanceActiveStep = (state: RootState) => {
    return state.customer.data.guidanceActiveStep;
};

export default dataSlice;
