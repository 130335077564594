import React from "react";

import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

import { LeadStatus as LeadStatusEnum } from "modules/admin/leads/leadsSlice";

const StyledAlert = withStyles(theme => ({
    root: {
        color: "inherit",
        borderLeftWidth: theme.spacing(1)
    }
}))(Alert);

interface LeadStatusProps {
    status: LeadStatusEnum;
}

const LeadStatus: React.FC<LeadStatusProps> = (props) => {
    const { status } = props;
    const { severity, text } = (() => {
        switch (status) {
            case LeadStatusEnum.NewLead:
                return {
                    severity: "error",
                    text: "New lead"
                };
            case LeadStatusEnum.DemoBooked:
                return {
                    severity: "warning",
                    text: "Demo booked"
                };
            case LeadStatusEnum.QuoteSent:
                return {
                    severity: "success",
                    text: "Quote sent"
                };
            default:
                return {
                    severity: "info",
                    text: "Unknown"
                };
        }
    })();

    return (
        // @ts-ignore
        <StyledAlert variant="outlined" severity={severity} icon={false}>
            {text}
        </StyledAlert>
    );
};

export default LeadStatus;
