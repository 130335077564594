import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import dateUtils from "utils/dateUtils";

import { loadRetailCentreClassification } from "./retailCentreClassification";
import { loadRagMetrics } from "./ragMetrics";
import { KPMGSpendCategory } from "./kpmgSpendCategory";

export class Store {
    public readonly id: string;
    public readonly outputAreaCode: string;
    public readonly retailCentreID: number;
    public readonly storeCategoryID: number;
    public readonly name: string;
    public readonly group: string;
    public readonly kpmgStoreCategory: string;
    public readonly clientRegion: string;
    public readonly region: string;
    public readonly sizeInSquareFeet: number;
    public readonly format: string;
    public readonly segment: string;
    public readonly openingDate: Date;
    public readonly numberOfEmployees: number;
    public readonly weeklyRevenue: number;
    public readonly yearlyRevenue: number;
    public readonly revenuePerSquareFoot: number;
    public readonly revenuePerHead: number;
    public readonly grossProfit: number;
    public readonly grossProfitMargin: number;
    public retailCentreClassificationName: string;
    public kpmgSpendCategories: KPMGSpendCategory[];
    public changeInNumberOfStores: number;
    public catchmentSize: number;
    public numberOfCompetitors: number;
    public footfallLevel: number;

    constructor(
        id: string,
        outputAreaCode: string,
        retailCentreID: number,
        storeCategoryID: number,
        name: string,
        group: string,
        kpmgStoreCategory: string,
        clientRegion: string,
        region: string,
        sizeInSquareFeet: number,
        format: string,
        segment: string,
        openingDate: Date,
        numberOfEmployees: number,
        weeklyRevenue: number,
        yearlyRevenue: number,
        revenuePerSquareFoot: number,
        grossProfit: number,
        grossProfitMargin: number,
        retailCentreClassificationName: string,
        kpmgSpendCategories: KPMGSpendCategory[],
        changeInNumberOfStores: number,
        catchmentSize: number,
        numberOfCompetitors: number,
        footfallLevel: number,
    ) {
        this.id = id;
        this.outputAreaCode = outputAreaCode;
        this.retailCentreID = retailCentreID;
        this.storeCategoryID = storeCategoryID;
        this.name = name;
        this.group = group;
        this.kpmgStoreCategory = kpmgStoreCategory;
        this.clientRegion = clientRegion;
        this.region = region;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.format = format;
        this.segment = segment;
        this.openingDate = openingDate;
        this.numberOfEmployees = numberOfEmployees;
        this.weeklyRevenue = weeklyRevenue;
        this.yearlyRevenue = yearlyRevenue;
        this.revenuePerSquareFoot = revenuePerSquareFoot;
        this.revenuePerHead = numberOfEmployees === 0 ? 0 : yearlyRevenue / numberOfEmployees;
        this.grossProfit = grossProfit;
        this.grossProfitMargin = grossProfitMargin;
        this.retailCentreClassificationName = retailCentreClassificationName;
        this.kpmgSpendCategories = kpmgSpendCategories;
        this.changeInNumberOfStores = changeInNumberOfStores;
        this.catchmentSize = catchmentSize;
        this.numberOfCompetitors = numberOfCompetitors;
        this.footfallLevel = footfallLevel;
    }
}

export const loadStores = (kpmgSpendCategories: KPMGSpendCategory[], clientId: string): AppThunk<Promise<Store[]>> => async (dispatch) => {
    try {
        const storesQuery = {
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.k_Region",
                "D_Store.OutputAreaID",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.EmployeeCount",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                "D_Store.StoreCategory_ID",
                "D_Store.Segment",
                "D_Store.Format",
                "D_Store.Group",
                "D_Store.ClientRegion",
                "Store_RAGMetrics.Sales",
                "Store_RAGMetrics.Profit",
            ]
        };
        const resultSetStores = await dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const rawStores = resultSetStores.rawData();
        const stores = rawStores.map(rawStore => {
            const yearlyRevenue = rawStore["Store_RAGMetrics.Sales"] ?? 0;
            const grossProfitMargin = rawStore["Store_RAGMetrics.Profit"] ?? 0;
            return new Store(
                rawStore["D_Store.StoreNaturalID"],
                rawStore["D_Store.OutputAreaID"],
                Number(rawStore["D_Store.RetailCentreID"]),
                Number(rawStore["D_Store.StoreCategory_ID"]),
                rawStore["D_Store.StoreName"],
                rawStore["D_Store.Group"],
                rawStore["D_Store.KPMGStoreCategory"],
                rawStore["D_Store.ClientRegion"],
                rawStore["D_Store.k_Region"],
                rawStore["D_Store.Sqft"],
                rawStore["D_Store.Format"],
                rawStore["D_Store.Segment"],
                dateUtils.dateUTC(rawStore["D_Store.OpeningDate"]),
                rawStore["D_Store.EmployeeCount"],
                yearlyRevenue / 52,
                yearlyRevenue,
                yearlyRevenue / rawStore["D_Store.Sqft"],
                grossProfitMargin * yearlyRevenue,
                grossProfitMargin,
                "",
                [],
                0,
                0,
                0,
                0
            );
        });

        const retailCentreClassificationsPromise = dispatch(loadRetailCentreClassification(stores));
        const ragMetricsPromise = dispatch(loadRagMetrics(clientId));

        const results = await Promise.all([retailCentreClassificationsPromise, ragMetricsPromise]);
        const retailCentreClassifications = results[0];
        const ragMetrics = results[1];

        const query = {
            measures: ["F_Sales.SumLineValue"],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_ProductCategory.SpendCategory_ID",
                "D_ProductCategory.ProductCategory3"],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "set"
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawStoreProductCategories = resultSet.rawData();

        const allClientKPMGSpendCategories = [...kpmgSpendCategories];

        for (const i in stores) {
            const retailCentreClassification = retailCentreClassifications.find(item => item.retailCentreId.toString() === stores[i].retailCentreID.toString());
            const ragMetric = ragMetrics.find(item => item.storeId === stores[i].id);

            const relevantSpendCategories = rawStoreProductCategories
                .filter(item => item["D_Store.StoreNaturalID"] === stores[i].id)
                .map(item => new KPMGSpendCategory(
                    Number(item["D_ProductCategory.SpendCategory_ID"]),
                    String(item["D_ProductCategory.ProductCategory3"])
                ));

            stores[i].kpmgSpendCategories = relevantSpendCategories.length === 0 ? allClientKPMGSpendCategories : relevantSpendCategories;

            if (retailCentreClassification) {
                stores[i].retailCentreClassificationName = retailCentreClassification.retailCentreClassificationName;
            }

            if (ragMetric) {
                stores[i].changeInNumberOfStores = ragMetric.areaHealth;
                stores[i].catchmentSize = ragMetric.catchment;
                stores[i].numberOfCompetitors = ragMetric.competition;
                stores[i].footfallLevel = ragMetric.footfall;
            }
        }

        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
