import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/overview/relativePerformance";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const StoreVsComparatorPerformance = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorPerformance = useSelector(state => selectors.storeVsComparatorPerformance(state));
    const { loading, error, store, comparator } = storeVsComparatorPerformance;

    const options = {
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        colors: colourPalette.comparators,
        legend: {
            enabled: true
        },
        tooltip: {
            headerFormat: ``,
            footerFormat: ``,
            useHTML: true,
            formatter: function () {
                const points = this.points;
                const series = points[0].series;
                const seriesName = series.name;
                const seriesColor = series.color;

                const otherSeries = points[1].series;
                const otherSeriesName = otherSeries?.name?.replace("(%)", "");
                const otherSeriesColor = otherSeries?.color;

                const categoryArr = [seriesName, otherSeriesName];
                const categoryFormatArr = [`color:${seriesColor};font-weight:bold`,`color:${otherSeriesColor};font-weight:bold`];
                const valueArr = [numberFormatter.toDecimalPlaces(points[0]?.point?.custom?.tooltipValue, 1),
                    numberFormatter.toDecimalPlaces(points[1]?.point?.custom?.tooltipValue, 1)
                ];

                const headerRow = points[0].key;

                return `<table>${stringUtils.tooltipHTML(categoryArr, { 
                    values: valueArr, 
                    header: headerRow, 
                    categoryFormattingArr: categoryFormatArr 
                })}</table>`;
            }
        },
        series: [{
            name: selectedStore.name,
            data: [
                { name: "Sales", y: store.sales },
                { name: "Drivers", y: store.drivers },
                { name: "Profit", y: store.profit },
                { name: "Area health", y: store.areaHealth },
                { name: "Competition", y: store.competition },
                { name: "Catchment", y: store.catchment }
            ],
            pointPlacement: "on",
            zIndex: 1
        }, {
            name: selectedComparator.name,
            data: [
                { name: "Sales", y: comparator.sales },
                { name: "Drivers", y: comparator.drivers },
                { name: "Profit", y: comparator.profit },
                { name: "Area health", y: comparator.areaHealth },
                { name: "Competition", y: comparator.competition },
                { name: "Catchment", y: comparator.catchment }
            ],
            pointPlacement: "on"
        }]
    };

    return (
        <Box data-cy="store-vs-comparator-performance">
            <Radar loading={loading} error={error} options={options} dataAdditionPercent={10} />
        </Box>
    );
};

export default StoreVsComparatorPerformance;
