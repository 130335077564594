import React from "react";

import { IconButton } from "@material-ui/core";
import HeadsetMicRoundedIcon from "@material-ui/icons/HeadsetMicRounded";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";
import SimpleTooltip from "components/SimpleTooltip";

import { Account, AccountStatus } from "modules/admin/accounts/accounts/accountsSlice";
import {
    hideStartSupportSession,
    selectStartSupportSessionVisibility,
    showStartSupportSession,
    startSupportSession
} from "modules/admin/accounts/supportSessions/supportSessionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface StartSupportSessionProps {
    account: Account
}

const StartSupportSession: React.FC<StartSupportSessionProps> = (props) => {
    const { account } = props;
    const dispatch = useAppDispatch();
    const disabled = account.status === AccountStatus.NoSubscription;
    const startSupportSessionVisibility = useAppSelector(selectStartSupportSessionVisibility);
    const show = startSupportSessionVisibility.isVisible && (startSupportSessionVisibility.accountId === account.id);
    const companyName = account.companyDisplayName
        ? `${account.companyDisplayName} (${account.companyName})`
        : account.companyName;

    const handleStartSupportSessionClick = () => {
        dispatch(showStartSupportSession(account.id));
    };

    const handleConfirmClick = () => {
        dispatch(startSupportSession(account.id));
    };

    const handleCancelClick = () => {
        dispatch(hideStartSupportSession());
    };

    return (
        <>
            <SimpleTooltip title="Start support session">
                <span>
                    <StyledIconButton size="small" onClick={handleStartSupportSessionClick} disabled={disabled}>
                        <HeadsetMicRoundedIcon />
                    </StyledIconButton>
                </span>
            </SimpleTooltip>
            <ConfirmationDialog
                open={show}
                title="Start support session?"
                subtitle={`Support session for ${companyName}`}
                type={ConfirmationDialogType.Warning}
                confirmLabel="Yes, start session"
                cancelLabel="No, cancel"
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
            />
        </>
    );
};

export default StartSupportSession;
