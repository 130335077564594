import { combineReducers } from "redux";

import types from "./types";

const totalSalesGrowthByRegionEmpty = {
    loading: false,
    error: false,
    regionsGrowth: []
};

const totalSalesGrowthByRegion = (state = totalSalesGrowthByRegionEmpty, action) => {
    switch (action.type) {
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                regionsGrowth: []
            };
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                regionsGrowth: action.payload.regionsGrowth
            };
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                regionsGrowth: []
            };
        default:
            return state;
    }
};

const salesByRegionGrowthEmpty = {
    loading: false,
    error: false,
    storesGrowth: []
};

const salesByRegionGrowth = (state = salesByRegionGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_BY_REGION_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                storesGrowth: []
            };
        case types.GET_SALES_BY_REGION_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                storesGrowth: action.payload.storesGrowth
            };
        case types.GET_SALES_BY_REGION_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                storesGrowth: []
            };
        default:
            return state;
    }
};

const dependencyOnSingularRegionOrStoreEmpty = {
    loading: false,
    error: false,
    id: "dependency-on-singular-region-or-store",
    label: "Dependency on singular region or store",
    status: "info",
    value: ""
};

const dependencyOnSingularRegionOrStore = (state = dependencyOnSingularRegionOrStoreEmpty, action) => {
    switch (action.type) {
        case types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                label: "Dependency on singular region or store",
                status: "info",
                value: ""
            };
        case types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                label: action.payload.label,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                label: "Dependency on singular region or store",
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const selectedRegion = (state = "", action) => {
    switch (action.type) {
        case types.SET_SELECTED_REGION:
            return action.payload.selectedRegion;
        default:
            return state;
    }
};

const reducer = combineReducers({
    totalSalesGrowthByRegion,
    salesByRegionGrowth,
    dependencyOnSingularRegionOrStore,
    selectedRegion
});

export default reducer;
