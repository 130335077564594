import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectStoreVsComparatorProductCategoryGrowth } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import { useAppSelector } from "store";

const StoreVsComparatorProductCategoryGrowth = () => {
    const storeVsComparatorProductCategoryGrowth = useAppSelector(selectStoreVsComparatorProductCategoryGrowth);
    return (
        <RAGIndicatorNew ragIndicator={storeVsComparatorProductCategoryGrowth} />
    );
};

export default StoreVsComparatorProductCategoryGrowth;
