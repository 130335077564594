import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import { selectFilter, setFilter } from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        width: theme.spacing(45)
    }
}))(TextField);

const FilterAccounts: React.FC = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectFilter);

    const handleCompanyNameOrCompanyDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyNameOrCompanyDisplayName = event.target.value;
        const newFilter = { ...filter, companyNameOrCompanyDisplayName };
        dispatch(setFilter(newFilter));
    };

    return (
        <StyledTextField
            placeholder="Enter name or display name"
            variant="outlined"
            size="small"
            color="secondary"
            value={filter.companyNameOrCompanyDisplayName}
            onChange={handleCompanyNameOrCompanyDisplayNameChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default FilterAccounts;
