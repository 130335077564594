import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Utilise cutting-edge smartphone location data to analyse footfall patterns around your {store?.name} store.
            Understand the ebb and flow of customer traffic to refine marketing strategies, optimise operations, and bolster lease negotiations.
            Track temporal footfall shifts to inform staffing, pricing, and promotional efforts.
            Armed with these insights, make informed decisions that enhance efficiency, elevate the customer experience, and drive your store's profitability.
        </>
    );
};

export default Subtitle;
