import React from "react";

import { Card, CardMedia, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const ActionableAndAccessibleInsights = () => {
    return (
        <PageSection dataCy="pages-landing-our-story-actionable-and-accessible-insights">
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/our-story/actionable-and-accessible-insights.png" alt="actionable and accessible insights" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" component="div">
                        Actionable and accessible insights
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        Launched in 2022, Dash equips businesses with access to advanced data and analytics capabilities, no matter the business size or ambition.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        Dash brings together KPMG's vast experience in retail, data, and technology and packages it into a powerful insight platform
                        with no long lead times, no development or implementation, and no upfront fees.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        Deep, actionable business insight is now available to everyone. It's time to move from guesswork to data-driven decisions.
                    </Typography>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default ActionableAndAccessibleInsights;
