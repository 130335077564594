import React from "react";
import { Grid } from "@material-ui/core";

import AvgBasketSizeCMPM from "./AvgBasketSizeCMPM";
import AvgBasketValueCMPM from "./AvgBasketValueCMPM";
import CategoriesGrowingOrDeclining from "./CategoriesGrowingOrDeclining";
import CategoryCMSales from "./CategoryCMSales";
import CategoryCMSalesGrowth from "./CategoryCMSalesGrowth";
import CategoryYTDSales from "./CategoryYTDSales";
import CategoryYTDSalesGrowth from "./CategoryYTDSalesGrowth";

const ProductCategoryBreakdown = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <AvgBasketValueCMPM />
            </Grid>
            <Grid item xs={12} md={6}>
                <AvgBasketSizeCMPM />
            </Grid>
            <Grid item xs={12} md={6}>
                <CategoryYTDSales />
            </Grid>
            <Grid item xs={12} md={6}>
                <CategoryCMSales />
            </Grid>
            <Grid item xs={12} md={6}>
                <CategoryYTDSalesGrowth />
            </Grid>
            <Grid item xs={12} md={6}>
                <CategoryCMSalesGrowth />
            </Grid>
            <Grid item xs={12}>
                <CategoriesGrowingOrDeclining />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryBreakdown;
