import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import { getUsers, selectUsers } from "modules/admin/users/usersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const UsersTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectUsers);

    React.useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    if (users.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Name
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Email
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Role
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Company name
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Company display name
                            </Box>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <StyledTableCell>
                                {user.firstName} {user.lastName}
                            </StyledTableCell>
                            <StyledTableCell>
                                {user.email}
                            </StyledTableCell>
                            <StyledTableCell>
                                {user.role}
                            </StyledTableCell>
                            <StyledTableCell>
                                {user.companyName}
                            </StyledTableCell>
                            <StyledTableCell>
                                {user.companyDisplayName}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default UsersTable;
