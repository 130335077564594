import React from "react";

import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";
import Error from "components/visuals/Error";
import Progress from "components/visuals/Progress";

import Actions from "./Actions";
import Content from "./Content";
import LeftPanelTitle from "./LeftPanelTitle";
import RightPanelTitle from "./RightPanelTitle";
import Stepper from "./Stepper";

import {
    hideGenerateRevenuePrediction,
    selectPredictionIsLoading,
    selectGenerateRevenuePredictionVisibility,
    selectHasErrors,
    selectIsLoading,
    selectNumberOfOpenStoresWithSalesInTheLastTwelveMonths,
    showGenerateRevenuePrediction
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(() => ({
    root: {
        width: "100%"
    }
}))(Button);

const GenerateRevenuePrediction: React.FC = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const numberOfOpenStoresWithSalesInTheLastTwelveMonths = useAppSelector(selectNumberOfOpenStoresWithSalesInTheLastTwelveMonths);
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const generateRevenuePredictionVisibility = useAppSelector(selectGenerateRevenuePredictionVisibility);
    const minNumberOfStoresToGeneratePrediction = 25;

    const handleClick = () => {
        dispatch(showGenerateRevenuePrediction());
    };

    const handleCloseClick = () => {
        dispatch(hideGenerateRevenuePrediction());
    };

    if (isLoading) {
        return (<Progress />);
    }

    if (hasErrors) {
        return (<Error />);
    }

    return (
        <>
            {numberOfOpenStoresWithSalesInTheLastTwelveMonths < minNumberOfStoresToGeneratePrediction && (
                <Typography variant="body1" component="div" align="center" gutterBottom>
                    {`Dash can't generate a revenue prediction for the pinned location as you currently have less than
                    ${minNumberOfStoresToGeneratePrediction} stores within your estate. When Dash has less than
                    ${minNumberOfStoresToGeneratePrediction} stores for training the revenue prediction, the accuracy of
                    the prediction drops considerably.`}
                </Typography>
            )}
            <StyledButton
                variant="contained"
                size="large"
                color="secondary"
                disableElevation
                disabled={predictionIsLoading || numberOfOpenStoresWithSalesInTheLastTwelveMonths < minNumberOfStoresToGeneratePrediction}
                onClick={handleClick}
            >
                Generate revenue prediction
            </StyledButton>
            <TwoPanelDialog
                open={generateRevenuePredictionVisibility.isVisible}
                onClose={handleCloseClick}
                leftPanelTitle={<LeftPanelTitle />}
                leftPanelContent={<Stepper />}
                leftPanelActions={<Actions />}
                leftPanelActionsJustifyContent={JustifyContent.SpaceBetween}
                rightPanelTitle={<RightPanelTitle />}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default GenerateRevenuePrediction;
