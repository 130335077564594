import React from "react";

import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import { selectSubscriptionPlanName } from "modules/customer/dashboard/dashboardSlice";
import { NavLink } from "react-router-dom";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const SubscriptionPlan: React.FC = () => {
    const subscriptionPlanName = useAppSelector(selectSubscriptionPlanName);

    return (
        <>
            <Typography variant="subtitle1" component="div" color="textSecondary" gutterBottom>
                Account
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    {subscriptionPlanName}
                </Typography>
            </Box>
            <Box paddingTop={1}>
                <StyledButton
                    variant="text"
                    size="medium"
                    color="default"
                    disableElevation
                    // @ts-ignore
                    component={NavLink}
                    to="/account"
                >
                    View account
                </StyledButton>
            </Box>
        </>
    );
};

export default SubscriptionPlan;
