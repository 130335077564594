import React from "react";
import { Grid } from "@material-ui/core";

import GrowthBreakdownGrid from "./GrowthBreakdownGrid";
import LongTermGrowthVsMarket from "./LongTermGrowthVsMarket";
import ShortTermGrowthVsMarket from "./ShortTermGrowthVsMarket";

const RevenueGrowthBreakdown = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <GrowthBreakdownGrid />
            </Grid>
            <Grid item xs={12}>
                <LongTermGrowthVsMarket />
            </Grid>
            <Grid item xs={12}>
                <ShortTermGrowthVsMarket />
            </Grid>
        </Grid>
    );
};

export default RevenueGrowthBreakdown;
