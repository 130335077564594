import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectFiscalYearStartDate, selectReferenceDate, selectReferenceDateNew } from "modules/customer/insights/performance/performanceSlice";
import actions from "./actions";
import dateUtils from "utils/dateUtils";

const getForecastSalesWOW = () => async (dispatch, getState) => {
    dispatch(actions.getForecastSalesWOWRequest());
    try {
        const state = getState();
        const referenceDateNew = selectReferenceDateNew(state);
        const nextWeekStartDate =
            (referenceDateNew.weekday === 6) ?
                referenceDateNew.plus({ days: 1 }).startOf("day") :
                referenceDateNew.plus({ weeks: 1 }).customStartOfWeek().startOf("day");

        const nextWeekEndDate = nextWeekStartDate.plus({ days: 6 }).endOf("day");

        const swlyStartDate = nextWeekStartDate.minus({ weeks: 52 }).startOf("day");
        const swlyEndDate = swlyStartDate.plus({ days: 6 }).endOf("day");

        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [nextWeekStartDate, nextWeekEndDate],
                    [swlyStartDate, swlyEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const nextWeekValue = resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];
        const previousWeekValue = resultSet.loadResponses[1].data[0]["F_SalesForecast.SumLineValue"];
        const percentageDifference = (previousWeekValue === 0) ? 0 : 100 * ((nextWeekValue - previousWeekValue) / previousWeekValue);

        dispatch(actions.getForecastSalesWOWSuccess(nextWeekValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getForecastSalesWOWFailure());
        dispatch(logError("Error loading ForecastSalesWoW.", error));
    }
};

const getForecastSalesMOM = () => async (dispatch, getState) => {
    dispatch(actions.getForecastSalesMOMRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDateNew(state);

        const nextFullMonthStartDate = currentDate.plus({months: 1 }).startOf("month"); 
        const nextFullMonthEndDate = nextFullMonthStartDate.endOf("month");

        const nextFullMonthPreviousYearStartDate = nextFullMonthStartDate.minus({years: 1});
        const nextFullMonthPreviousYearEndDate = nextFullMonthPreviousYearStartDate.endOf("month");

        const query = {
            measures: ["F_StoreMonthlyForecast.SumSalesValue"],
            timeDimensions: [{
                dimension: "F_StoreMonthlyForecast.MonthStartDate",
                compareDateRange: [
                    [nextFullMonthStartDate, nextFullMonthEndDate],
                    [nextFullMonthPreviousYearStartDate, nextFullMonthPreviousYearEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const nextMonthValue = resultSet.loadResponses[0].data[0]["F_StoreMonthlyForecast.SumSalesValue"];
        const SMLYValue = resultSet.loadResponses[1].data[0]["F_StoreMonthlyForecast.SumSalesValue"];
        const percentageDifference = (SMLYValue === 0) ? 0 : 100 * ((nextMonthValue - SMLYValue) / SMLYValue);
        const nextMonth = [dateUtils.monthName(nextFullMonthStartDate), dateUtils.dateUTC(nextFullMonthStartDate).getFullYear()];
        const sameMonthLastYear = [dateUtils.monthName(nextFullMonthPreviousYearStartDate), dateUtils.dateUTC(nextFullMonthPreviousYearStartDate).getFullYear()];

        dispatch(actions.getForecastSalesMOMSuccess(nextMonthValue, percentageDifference, nextMonth, sameMonthLastYear));
    }
    catch (error) {
        dispatch(actions.getForecastSalesMOMFailure());
        dispatch(logError("Error loading ForecastSalesMOM.", error));
    }
};

const getForecastSalesYOY = () => async (dispatch, getState) => {
    dispatch(actions.getForecastSalesYOYRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYTGStartDate = dateUtils.nextDay(currentDate);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);
        const previousFinancialYTGStartDate = dateUtils.previousFinancialYTGStartDate(currentDate);
        const previousFinancialYearEndDate = dateUtils.previousFinancialYearEndDate(currentDate, fyStart);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [financialYTGStartDate, financialYearEndDate],
                    [previousFinancialYTGStartDate, previousFinancialYearEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const financialYearToGoValue = resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];
        const previousfinancialYearToGoValue = resultSet.loadResponses[1].data[0]["F_SalesForecast.SumLineValue"];
        const percentageDifference = (previousfinancialYearToGoValue === 0) ? 0 : 100 * ((financialYearToGoValue - previousfinancialYearToGoValue) / previousfinancialYearToGoValue);

        dispatch(actions.getForecastSalesYOYSuccess(financialYearToGoValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getForecastSalesYOYFailure());
        dispatch(logError("Error loading ForecastSalesYOY.", error));
    }
};

const getSalesHistoryForecast = () => async (dispatch, getState) => {
    dispatch(actions.getSalesHistoryForecastRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(currentDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue", "F_SalesForecast.MaxForecastFlag"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, next12MonthsEndDate]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.WeekNo", "D_Date.Date"]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const values = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            forecastFlag: item["F_SalesForecast.MaxForecastFlag"],
            week: item["D_Date.WeekNo"],
            date: item["D_Date.Date"]
        }));
        dispatch(actions.getSalesHistoryForecastSuccess(values));
    }
    catch (error) {
        dispatch(actions.getSalesHistoryForecastFailure());
        dispatch(logError("Error loading SalesHistoryForecast.", error));
    }
};

const getMonthlyForecast = () => async (dispatch, getState) => {
    dispatch(actions.getMonthlyForecastRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDateNew(state).endOf('month');
        const next12MonthsEndDate = currentDate.plus({ years: 1 });

        const query = {
            measures: ["F_StoreMonthlyForecast.SumSalesValue"],
            timeDimensions: [{
                dimension: "F_StoreMonthlyForecast.MonthStartDate",
                dateRange: [currentDate, next12MonthsEndDate]
            }],
            order: [
                ["F_StoreMonthlyForecast.MonthStartDate", "asc"]
            ],
            dimensions: ["F_StoreMonthlyForecast.MonthStartDate"],
            filters: [{
                member: "F_StoreMonthlyForecast.ForecastFlag",
                operator: "equals",
                values: ["1"]
            }]
        };
        const resultSetMonthly = await dispatch(cubeLoad(query));
        const values = resultSetMonthly.loadResponses[0].data.map(item => ({
            sales: item["F_StoreMonthlyForecast.SumSalesValue"]
        }));
        dispatch(actions.getMonthlyForecastSuccess(values));
    }
    catch (error) {
        dispatch(actions.getMonthlyForecastFailure());
        dispatch(logError("Error loading monthlyForecast.", error));
    }
};

const operations = {
    getForecastSalesWOW,
    getForecastSalesMOM,
    getForecastSalesYOY,
    getSalesHistoryForecast,
    getMonthlyForecast
};

export default operations;
