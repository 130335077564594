import React from "react";
import PropTypes from "prop-types";
import mathUtils from "utils/mathUtils";

import { Box, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RemoveIcon from "@material-ui/icons/Remove";
import { useTheme } from "@material-ui/core/styles";

import Progress from "./Progress";
import Error from "./Error";

const PercentageIndicator = (props) => {
    const { loading, error, variant, align, showIndicator, value, switchColor, noDataMessage } = props;
    const theme = useTheme();
    const optionalComparator = (noDataMessage.length !== 0);
    const { color, icon, symbol } = (() => {

        if ((value === null) && (!optionalComparator)) return {
            color: theme.palette.text.secondary,
            icon: <RemoveIcon />,
            symbol: ""
        };
        if ((value === null) && (optionalComparator)) return {
            color: theme.palette.text.secondary,
            symbol: ""
        };
        if (mathUtils.round(value, 1) < 0) return {
            color: switchColor ? theme.palette.success.main : theme.palette.error.main,
            icon: <ArrowDropDownIcon />,
            symbol: "-"
        };
        if (mathUtils.round(value, 1) > 0) return {
            color: switchColor ? theme.palette.error.main : theme.palette.success.main,
            icon: <ArrowDropUpIcon />,
            symbol: "+"
        };
        return {
            color: theme.palette.warning.main,
            icon: <RemoveIcon />,
            symbol: "="
        };
    })();
    
    const displayValue = (((optionalComparator) && value === null) ? noDataMessage : `${symbol} ${(mathUtils.round((value < 0) ? (value * -1) : value, 1))}%`);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Box display="flex" justifyContent={align} style={{ color: color }} data-cy="percentage-indicator">
            {(
                <Typography variant={variant} component="div">
                    &nbsp;&nbsp;&nbsp;{displayValue}
                </Typography>
            )}
            {showIndicator && icon}
        </Box>
    );
};

PercentageIndicator.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf(["h5", "h6", "subtitle1", "subtitle2", "body1", "body2"]).isRequired,
    align: PropTypes.oneOf(["left", "center", "right"]).isRequired,
    showIndicator: PropTypes.bool.isRequired,
    value: PropTypes.number,
    switchColor: PropTypes.bool,
    noDataMessage: PropTypes.string
};

PercentageIndicator.defaultProps = {
    loading: false,
    error: false,
    variant: "subtitle1",
    align: "center",
    showIndicator: true,
    value: null,
    switchColor: false,
    noDataMessage: ""
};

export default PercentageIndicator;
