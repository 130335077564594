import { createSelector } from "reselect";
import { median } from "mathjs";

const totalSalesGrowthByRegion = (state) => {
    return state.customer.insights.performance.sales.regionalGrowthBreakdown.totalSalesGrowthByRegion;
};

const salesByRegionGrowth = (state) => {
    return state.customer.insights.performance.sales.regionalGrowthBreakdown.salesByRegionGrowth;
};

const selectedRegion = (state) => {
    return state.customer.insights.performance.sales.regionalGrowthBreakdown.selectedRegion;
};

const salesByRegionGrowthTopBottom = createSelector(
    selectedRegion,
    salesByRegionGrowth,
    (selectedRegion, salesByRegionGrowth) => {
        const storesGrowth = salesByRegionGrowth.storesGrowth;
        const stores = storesGrowth
            .filter(store => !selectedRegion || store.regionId === selectedRegion)
            .sort((a, b) => a.growth - b.growth);

        let top = [];
        let bottom = [];
        const length = stores.length;
        if (length >= 6) {
            top = stores.slice(-3);
            bottom = stores.slice(0, 3);
        } else {
            if (length % 2 === 0) {
                top = stores.slice(-length / 2);
                bottom = stores.slice(0, length / 2);
            } else {
                const values = stores.map(store => store.growth);
                const medianValue = median(values);
                stores.forEach(store => {
                    store.growth >= medianValue
                        ? top.push(store)
                        : bottom.push(store);
                });
            }
        }

        return {
            loading: salesByRegionGrowth.loading,
            error: salesByRegionGrowth.error,
            top,
            bottom
        };
    }
);

const dependencyOnSingularRegionOrStore = (state) => {
    return state.customer.insights.performance.sales.regionalGrowthBreakdown.dependencyOnSingularRegionOrStore;
};

const selectors = {
    totalSalesGrowthByRegion,
    salesByRegionGrowth,
    salesByRegionGrowthTopBottom,
    dependencyOnSingularRegionOrStore,
    selectedRegion
};

export default selectors;
