import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import EditQuestion from "./EditQuestion";
import DeleteQuestion from "./DeleteQuestion";
import MoveQuestionUp from "./MoveQuestionUp";
import MoveQuestionDown from "./MoveQuestionDown";

import { selectQuestions } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

interface QuestionsTableProps {
    categoryId: string
}

const QuestionsTable: React.FC<QuestionsTableProps> = (props) => {
    const { categoryId } = props;
    const theme = useTheme();
    const questions = useAppSelector(selectQuestions);

    if (questions.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Question
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Answer
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Show on landing area
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Show on customer area
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions.map((question) => (
                        <TableRow key={question.id}>
                            <StyledTableCell
                                style={{
                                    width: "30%",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    textAlign: "justify"
                                }}
                            >
                                {question.question}
                            </StyledTableCell>
                            <StyledTableCell
                                style={{
                                    width: "30%",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    textAlign: "justify"
                                }}
                            >
                                {question.answer}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {question.showOnLandingArea ? (
                                    <CheckCircleRoundedIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                    <CancelRoundedIcon style={{ color: theme.palette.error.main }} />
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {question.showOnCustomerArea ? (
                                    <CheckCircleRoundedIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                    <CancelRoundedIcon style={{ color: theme.palette.error.main }} />
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <MoveQuestionUp categoryId={categoryId} questionId={question.id} />
                                <MoveQuestionDown categoryId={categoryId} questionId={question.id} />
                                <EditQuestion categoryId={categoryId} questionId={question.id} />
                                <DeleteQuestion categoryId={categoryId} questionId={question.id} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default QuestionsTable;
