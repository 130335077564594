import { createTheme } from "@material-ui/core/styles";

const muiButtonOverrides = {
    root: {
        textTransform: "none",
        fontWeight: "600",
        borderRadius: "2em"
    },
    containedSizeSmall: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "8px 30px",
        minWidth: "96px"
    },
    contained: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "12px 30px",
        minWidth: "152px"
    },
    containedSizeLarge: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "16px 56px",
        minWidth: "200px"
    },
    outlinedSizeSmall: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "7px 29px",
        minWidth: "96px"
    },
    outlined: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "11px 29px",
        minWidth: "152px"
    },
    outlinedSizeLarge: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "15px 55px",
        minWidth: "200px"
    },
    textSizeSmall: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px",
        padding: "0px 8px",
        minWidth: "0px"
    },
    text: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "8px 12px",
        minWidth: "0px"
    },
    textSizeLarge: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "8px 12px",
        minWidth: "0px"
    }
};

const muiTypographyOverrides = {
    h1: {
        fontWeight: "700",
        fontSize: "48px",
        lineHeight: "56px"
    },
    h2: {
        fontWeight: "300",
        fontSize: "40px",
        lineHeight: "52px"
    },
    h3: {
        fontWeight: "600",
        fontSize: "32px",
        lineHeight: "38px"
    },
    h4: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "28px"
    },
    h5: {
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px"
    },
    h6: {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px"
    },
    subtitle1: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px"
    },
    subtitle2: {
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "18px"
    },
    body1: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px"
    },
    body2: {
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px"
    },
    caption: {
        fontWeight: "400",
        fontSize: "11px",
        lineHeight: "12px"
    }
};

const typography = {
    fontFamily: [
        "Open Sans",
        "sans-serif",
    ].join(",")
};

const light = createTheme({
    palette: {
        primary: {
            main: "#4832CB"
        },
        secondary: {
            main: "#ED4E7A"
        },
        tertiary: {
            main: "#42E8E0"
        },
        quaternary: {
            light: "#454159",
            main: "#39354D",
            dark: "#27233E"
        },
        background: {
            default: "#F9F9F9",
            paper: "#F3F4F5",
            defaultDark: "#070221",
        },
        error: {
            main: "#FF333F"
        },
        info: {
            main: "#1C76FD"
        },
        success: {
            main: "#1DE67A"
        },
        warning: {
            main: "#F5D423"
        },
        text: {
            secondary: "#CDCCD3",
            disabled: "#9C9AA6"
        }
    },
    overrides: {
        MuiButton: muiButtonOverrides,
        MuiTypography: muiTypographyOverrides
    },
    typography: typography
});

const dark = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#4832CB"
        },
        secondary: {
            main: "#ED4E7A"
        },
        tertiary: {
            main: "#42E8E0"
        },
        quaternary: {
            light: "#454159",
            main: "#39354D",
            dark: "#27233E"
        },
        background: {
            default: "#070221",
            paper: "#191833",
            drawer: "#040113",
            insight: "#110E2B"
        },
        error: {
            main: "#FF333F"
        },
        info: {
            main: "#1C76FD"
        },
        success: {
            main: "#1DE67A"
        },
        warning: {
            main: "#F5D423"
        },
        text: {
            secondary: "#CDCCD3",
            disabled: "#9C9AA6"
        },
    },
    overrides: {
        MuiButton: muiButtonOverrides,
        MuiTypography: muiTypographyOverrides
    },
    typography: typography
});

const themes = {
    light,
    dark
};

export default themes;
