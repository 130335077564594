import React from "react";
import { Box } from "@material-ui/core";
import useColourPalette from "components/visuals/useColourPalette";
import { useDispatch, useSelector } from "react-redux";

import Waterfall from "components/visuals/Waterfall";

import { operations, selectors } from "modules/customer/insights/performance/sales/contributionGrowth";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const TypesOfContributionGrowth = () => {
    const dispatch = useDispatch();
    const colourPalette = useColourPalette();
    const typesOfContributionGrowth = useSelector(state => selectors.typesOfContributionGrowth(state));
    const { loading, error, data } = typesOfContributionGrowth;
    const categories = data.map(item => item.growthCategory)
                           .map(item => item.replace("PY cont.", "Year prior cont."))
                           .map(item => item.replace("CY cont.", "Last year cont."));

    //ToDo: Check whether we can update the segment description rather than replacing it on the front-end
    const categoryDescription = data.map(item => item.segmentDescription)
        .map(item => item.replace("Total contribution value in the 12 months prior to the last 12 months", "Contribution in the 12 months prior to the last 12 months"))
        .map(item => item.replace("Total contribution value in the last 12 months", "Contribution in the last 12 months"));

    const series = data.map(item => item.growthValue);
    const growthOrDecline = series[series.length - 1] > series[0] ? 'growth' : 'decline';

    const options = {
        title: {
            text: `Breakdown of contribution ${growthOrDecline} between the last year and the year prior`
        },
        chart: {
            marginTop: 30
        },
        xAxis: {
            categories: categories
        },
        tooltip: {
            enabled: true,
            formatter: function () {
                const category = this.x;
                const categoryIndex = categories.indexOf(category);
                const tooltipHeader = category === "Year prior cont." ? "Prior year contribution" :
                                      category === "Last year cont." ? "Last year contribution" : category;
                const description = categoryDescription[categoryIndex];
                const revenue = this.y;
                return `<table>
                            ${stringUtils.tooltipHTML([`${description}`, `Contribution <b style="color: ${this.color}">${numberFormatter.toGBP(revenue, 2)}</b>`], {
                                header: tooltipHeader
                            })}
                        </table>`;
            },
            useHTML: true
        },
        series: [{
            name: "Contribution",
            data: series.map((point, index) => {
                let dataOptions = { y: point };

                if (index === 0) {
                    dataOptions.color = colourPalette.waterfall.neutral;
                } else if (index === series.length - 1) {
                    dataOptions.color = colourPalette.waterfall.neutral;
                    dataOptions.isSum = true;
                } else if (point < 0) {
                    dataOptions.color = colourPalette.waterfall.negative;
                } else {
                    dataOptions.color = colourPalette.waterfall.positive;
                }

                return dataOptions;
            })
        }]
    };

    React.useEffect(() => {
        dispatch(operations.getTypesOfContributionGrowth());
    }, [dispatch]);

    return (
        <Box data-cy="types-of-contribution-growth">
            <Waterfall loading={loading} error={error} options={options} />
        </Box>
    );
};

export default TypesOfContributionGrowth;
