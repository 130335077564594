import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Assess your {store?.name} store's location against the local competitive backdrop to determine its high street prominence.
            Identify who you're competing with and where they're situated to get an understanding of customer choice and identify pockets of competitive intensity.
            Make strategic decisions about advertising placement to win future customers and stand out in a crowded marketplace.
        </>
    );
};

export default Subtitle;
