import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Box, Button, IconButton, List, ListItem, ListItemText, Popover } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import AboutMobile from "./AboutMobile";
import PlatformMobile from "./PlatformMobile";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(3)
    }
}))(Button);

const NavigationMobile = (props) => {
    const { appBarRef } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [affiliationBarHeight, setAffiliationBarHeight] = React.useState(appBarRef.current.getBoundingClientRect().top);
    const navigationBarHeight = 72;

    const handleClick = () => {
        setAffiliationBarHeight(appBarRef.current.getBoundingClientRect().top);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLinkClick = (label) => {
        dispatch(trackEvent("Header", `Header - ${label} click`, `Header - ${label} button`));
        setOpen(false);
    };

    const handleBookADemoClick = () => {
        dispatch(trackEvent("Header", "Header - Book a demo click", "Header - Book a demo button"));
        setOpen(false);
    };

    return (
        <>
            <StyledButton
                component={NavLink}
                to="/contact-us?subject=book-a-demo"
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleBookADemoClick}
                disableElevation
                data-cy="btn-book-a-demo"
            >
                Book a demo
            </StyledButton>
            <IconButton
                size={"small"}
                onClick={handleClick}
                data-cy="btn-menu"
            >
                {open ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
            <Popover
                anchorEl={appBarRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "100%",
                        height: `calc(100% - ${affiliationBarHeight}px - ${navigationBarHeight}px)`,
                        backgroundColor: theme.palette.common.white
                    }
                }}
                marginThreshold={0}
                elevation={0}
                open={open}
                onClose={handleClose}
            >
                <Box height="100%" display="flex" flexDirection="column">
                    <List>
                        <PlatformMobile onLinkClick={() => handleLinkClick("Platform")} />
                        <ListItem
                            divider
                            button
                            component={NavLink}
                            to="/features"
                            onClick={() => handleLinkClick("Features")}
                        >
                            <ListItemText
                                primary="Features"
                                primaryTypographyProps={{
                                    variant: "h6",
                                    component: "div"
                                }}
                            />
                        </ListItem>
                        <AboutMobile onLinkClick={() => handleLinkClick("About")} />
                        <ListItem
                            button
                            component={NavLink}
                            to="/contact-us"
                            onClick={() => handleLinkClick("Contact")}
                        >
                            <ListItemText
                                primary="Contact"
                                primaryTypographyProps={{
                                    variant: "h6",
                                    component: "div"
                                }}
                            />
                        </ListItem>
                    </List>
                    <Spacer />
                    <Box padding={2}>
                        <Button
                            component={NavLink}
                            to="/login"
                            variant="outlined"
                            size="large"
                            color="default"
                            disableElevation
                            fullWidth
                            onClick={() => handleLinkClick("Login")}
                            data-cy="btn-login"
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

NavigationMobile.propTypes = {
    appBarRef: PropTypes.object.isRequired
};

export default NavigationMobile;
