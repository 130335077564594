import React from "react";

import { useAppSelector } from "store";
import { StoreProductFilterstep, selectActiveStep } from "modules/customer/tools/product/storeProductFilters/storeProductFiltersSlice";
import Stores from "./stores/Stores";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === StoreProductFilterstep.SelectStore && (<Stores />)}
            {/* {activeStep === StoreProductFilterstep.SelectProduct && (<Products />)} */}
        </>
    );
};

export default Content;
