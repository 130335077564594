import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Container, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const PageHeader = (props) => {
    const { title, subtitle, actionLabel, onAction, dataCy } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const paddingTopValue = 10;
    const paddingBottomValue = 10;
    const paddingTop = isMobile ? (paddingTopValue / 2) : paddingTopValue;
    const paddingBottom = isMobile ? (paddingBottomValue / 2) : paddingBottomValue;

    return (
        <Box
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            bgcolor={theme.palette.common.white}
            data-cy={dataCy}
        >
            <Container maxWidth="sm">
                <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" data-cy="page-header-title">
                    {title}
                </Typography>
                {!!subtitle && (
                    <>
                        <br />
                        <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align="center" data-cy="page-header-subtitle">
                            {subtitle}
                        </Typography>
                    </>
                )}
                {!!actionLabel && (
                    <Box align="center" paddingTop={4}>
                        <Button
                            variant="outlined"
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={onAction}
                            data-cy="btn-download"
                        >
                            {actionLabel}
                        </Button>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    actionLabel: PropTypes.string,
    onAction: PropTypes.func,
    dataCy: PropTypes.string
};

PageHeader.defaultProps = {
    subtitle: "",
    onAction: () => {},
    dataCy: ""
};

export default PageHeader;
