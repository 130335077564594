import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectShortTermNetOpenings } from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";

const ShortTermNetOpenings: React.FC = () => {
    const shortTermOpenings = useAppSelector(selectShortTermNetOpenings);
    return (
        <RAGIndicatorNew ragIndicator={shortTermOpenings} />
    );
};

export default ShortTermNetOpenings;
