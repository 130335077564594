const GET_STORE_REVENUE_VS_PY_REQUEST = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_REVENUE_VS_PY_REQUEST";
const GET_STORE_REVENUE_VS_PY_SUCCESS = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_REVENUE_VS_PY_SUCCESS";
const GET_STORE_REVENUE_VS_PY_FAILURE = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_REVENUE_VS_PY_FAILURE";
const GET_STORE_FORECAST_REVENUE_VS_PY_REQUEST = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FORECAST_REVENUE_VS_PY_REQUEST";
const GET_STORE_FORECAST_REVENUE_VS_PY_SUCCESS = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FORECAST_REVENUE_VS_PY_SUCCESS";
const GET_STORE_FORECAST_REVENUE_VS_PY_FAILURE = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FORECAST_REVENUE_VS_PY_FAILURE";
const GET_SALES_HISTORY_REQUEST = "spark/customer/insights/portfolio/sales/revenue/GET_SALES_HISTORY_REQUEST";
const GET_SALES_HISTORY_SUCCESS = "spark/customer/insights/portfolio/sales/revenue/GET_SALES_HISTORY_SUCCESS";
const GET_SALES_HISTORY_FAILURE = "spark/customer/insights/portfolio/sales/revenue/GET_SALES_HISTORY_FAILURE";
const GET_STORE_HISTORIC_SALES_TREND_REQUEST = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_HISTORIC_SALES_TREND_REQUEST";
const GET_STORE_HISTORIC_SALES_TREND_SUCCESS = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_HISTORIC_SALES_TREND_SUCCESS";
const GET_STORE_HISTORIC_SALES_TREND_FAILURE = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_HISTORIC_SALES_TREND_FAILURE";
const GET_STORE_FUTURE_SALES_TREND_REQUEST = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FUTURE_SALES_TREND_REQUEST";
const GET_STORE_FUTURE_SALES_TREND_SUCCESS = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FUTURE_SALES_TREND_SUCCESS";
const GET_STORE_FUTURE_SALES_TREND_FAILURE = "spark/customer/insights/portfolio/sales/revenue/GET_STORE_FUTURE_SALES_TREND_FAILURE";

const types = {
    GET_STORE_REVENUE_VS_PY_REQUEST,
    GET_STORE_REVENUE_VS_PY_SUCCESS,
    GET_STORE_REVENUE_VS_PY_FAILURE,
    GET_STORE_FORECAST_REVENUE_VS_PY_REQUEST,
    GET_STORE_FORECAST_REVENUE_VS_PY_SUCCESS,
    GET_STORE_FORECAST_REVENUE_VS_PY_FAILURE,
    GET_SALES_HISTORY_REQUEST,
    GET_SALES_HISTORY_SUCCESS,
    GET_SALES_HISTORY_FAILURE,
    GET_STORE_HISTORIC_SALES_TREND_REQUEST,
    GET_STORE_HISTORIC_SALES_TREND_SUCCESS,
    GET_STORE_HISTORIC_SALES_TREND_FAILURE,
    GET_STORE_FUTURE_SALES_TREND_REQUEST,
    GET_STORE_FUTURE_SALES_TREND_SUCCESS,
    GET_STORE_FUTURE_SALES_TREND_FAILURE
};

export default types;
