import React from "react";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageSection from "components/landing/PageSection";
import TermsOfUseContents from "pages/TermsOfUseContents";

import { useAppDispatch } from "store";
import { downloadTermsOfUse } from "modules/landing/termsOfUse/termsOfUseSlice";

const TermsOfUse: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const handleDownloadClick = () => {
        dispatch(downloadTermsOfUse());
    };

    return (
        // @ts-ignore
        <Page data-cy="pages-landing-terms-of-use">
            <PageHeader
                title="Terms of use"
                subtitle="Last updated: 17 July 2024"
                actionLabel="Download PDF"
                onAction={handleDownloadClick}
                dataCy="pages-landing-terms-of-use-header"
            />
            {/* @ts-ignore */}
            <PageSection maxWidth="lg" dataCy="pages-landing-terms-of-use-contents">
                {/* @ts-ignore */}
                <TermsOfUseContents linkColor={theme.palette.primary.main} />
            </PageSection>
        </Page>
    );
};

export default TermsOfUse;
