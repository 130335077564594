import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectForecastRevenueGrowth } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const ForecastRevenueGrowth: React.FC = () => {
    const forecastRevenueGrowth = useAppSelector(selectForecastRevenueGrowth);
    return (
        <RAGIndicatorNew ragIndicator={forecastRevenueGrowth} />
    );
};

export default ForecastRevenueGrowth;
