import { combineReducers } from "redux";

import types from "./types";

const storeRevenueVsPYEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const storeRevenueVsPY = (state = storeRevenueVsPYEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_REVENUE_VS_PY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_STORE_REVENUE_VS_PY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_STORE_REVENUE_VS_PY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const storeForecastRevenueVsPYEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const storeForecastRevenueVsPY = (state = storeForecastRevenueVsPYEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_FORECAST_REVENUE_VS_PY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_STORE_FORECAST_REVENUE_VS_PY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_STORE_FORECAST_REVENUE_VS_PY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const salesHistoryEmpty = {
    loading: false,
    error: false,
    values: []
};

const salesHistory = (state = salesHistoryEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_SALES_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values
            };
        case types.GET_SALES_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const storeHistoricSalesTrendEmpty = {
    loading: false,
    error: false,
    id: "store-historic-sales-trend",
    label: "Historic revenue growth",
    status: "info",
    value: ""
};

const storeHistoricSalesTrend = (state = storeHistoricSalesTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_HISTORIC_SALES_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_STORE_HISTORIC_SALES_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_STORE_HISTORIC_SALES_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const storeFutureSalesTrendEmpty = {
    loading: false,
    error: false,
    id: "store-future-sales-trend",
    label: "Forecast revenue growth",
    status: "info",
    value: ""
};

const storeFutureSalesTrend = (state = storeFutureSalesTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_FUTURE_SALES_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_STORE_FUTURE_SALES_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_STORE_FUTURE_SALES_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    storeRevenueVsPY,
    storeForecastRevenueVsPY,
    salesHistory,
    storeHistoricSalesTrend,
    storeFutureSalesTrend
});

export default reducer;
