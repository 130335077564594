import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/forecast/forecastRevenue";

const ForecastSalesMOM = () => {
    const dispatch = useDispatch();
    const forecastSalesMOM = useSelector(state => selectors.forecastSalesMOM(state));
    const { loading, error, nextMonthValue, percentageDifference, nextMonth, sameMonthLastYear } = forecastSalesMOM;
    const nextMonthDate = nextMonth ? `${nextMonth[0]} ${nextMonth[1]}` : 'next month';
    const sameMonthLastYearDate = sameMonthLastYear ? `${sameMonthLastYear[0]} ${sameMonthLastYear[1]}` : 'next month last year';
    const label = `Forecast revenue for ${nextMonthDate} vs historic revenue for ${sameMonthLastYearDate}`;
    const valueFormatted = numberFormatter.toGBP(nextMonthValue, 1);

    React.useEffect(() => {
        dispatch(operations.getForecastSalesMOM());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="forecast-sales-mom">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default ForecastSalesMOM;
