import React from "react";

import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { ChapterProps } from "./Chapter";
import Navigation from "./Navigation";

interface PageProps {
    companyName: string,
    title: string,
    filters?: React.ReactElement,
    isSetupComplete: boolean,
    dataCy: string,
    children: React.ReactElement<ChapterProps>[]
}

const Page: React.FC<PageProps> = (props) => {
    const { companyName, title, filters, isSetupComplete, dataCy } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box maxWidth="100vw" data-cy={dataCy}>
            <Container maxWidth={false} disableGutters={isMobile}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} style={{ minHeight: theme.spacing(11) }}>
                        <Navigation
                            companyName={companyName}
                            title={title}
                            filters={filters}
                            isSetupComplete={isSetupComplete}
                            chapters={props.children}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <br />
                        {isSetupComplete && props.children}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Page;
