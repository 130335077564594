import React from "react";
import { Box } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StopIcon from "@material-ui/icons/Stop";
import { Marker } from "react-map-gl";

import useColourPalette from "components/visuals/useColourPalette";

const StoreMarker = (props) => {
    const { store, onMarkerClick } = props;
    const colourPalette = useColourPalette();

    const onClick = () => {
        onMarkerClick(store);
    };

    return (
        <Marker
            latitude={store.latitude}
            longitude={store.longitude}
            offsetTop={-20}
            offsetLeft={-10}
            onClick={onClick}>
            <Box style={{ cursor: "pointer" }}>
                {store.type === "Selected store" && (
                    <LocationOnOutlinedIcon style={{ color: colourPalette.comparators[0] }} />
                )}
                {store.type === "Your store" && (
                    <LocationOnOutlinedIcon />
                )}
                {store.type === "Direct competitor" && (
                    <StopIcon fontSize="small" style={{ color: colourPalette.categorical[2], transform: "rotate(45deg)" }} />
                )}
                {store.type === "Competitor" && (
                    <FiberManualRecordIcon fontSize="small" style={{ color: colourPalette.categorical[0] }} />
                )}
            </Box>
        </Marker>
    );
};

export default StoreMarker;
