import React from "react";

import { Button } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

import { showFilters } from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch } from "store";

const OpenMobile: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(showFilters());
    };

    return (
        <Button
            variant="outlined"
            size="small"
            color="default"
            disableElevation
            onClick={handleClick}
            startIcon={<SettingsIcon />}
        >
            Setup
        </Button>
    );
};

export default OpenMobile;
