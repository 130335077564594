import React from "react";
import { useLocation } from "react-router-dom";

import Page from "components/landing/Page";

import Checking from "./Checking";
import Error from "./Error";
import Success from "./Success";

import {
    completeRegistration,
    selectIsRegistrationComplete,
    selectIsRegistrationTokenChecked
} from "modules/landing/registration/registrationSlice";
import { useAppDispatch, useAppSelector } from "store";

const Registration: React.FC = () => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const registrationToken = searchParams.get("token") ?? "";
    const isRegistrationTokenChecked = useAppSelector(selectIsRegistrationTokenChecked);
    const isRegistrationComplete = useAppSelector(selectIsRegistrationComplete);

    React.useEffect(() => {
        dispatch(completeRegistration(registrationToken));
    }, [dispatch, registrationToken]);

    return (
        // @ts-ignore
        <Page dataCy="pages-landing-registration">
            {!isRegistrationTokenChecked && (
                <Checking />
            )}
            {isRegistrationTokenChecked && !isRegistrationComplete && (
                <Error />
            )}
            {isRegistrationTokenChecked && isRegistrationComplete && (
                <Success />
            )}
        </Page>
    );
};

export default Registration;
