import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Explore your {store?.name} store's revenue streams with a comprehensive view of in store and online sales.
            Assess historical performance and project future trends with Dash's advanced forecasting, comparing both physical
            and digital revenue against {comparator?.name}. Determine the overall growth trajectory and prepare for the
            year ahead by understanding how each channel contributes to your store's financial landscape.
        </>
    );
};

export default Subtitle;
