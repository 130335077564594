import { Query, ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class RetailCentre {
    public readonly id: number;
    public readonly storeCategoryId: number;
    public readonly affluenceCentile: number;
    public readonly ageCentile: number;
    public readonly childrenCentile: number;
    public readonly diversityCentile: number;
    public readonly urbanicityCentile: number;
    public readonly footfallCentile: number;

    constructor(
        id: number,
        storeCategoryId: number,
        affluenceCentile: number,
        ageCentile: number,
        childrenCentile: number,
        diversityCentile: number,
        urbanicityCentile: number,
        footfallCentile: number
    ) {
        this.id = id;
        this.storeCategoryId = storeCategoryId;
        this.affluenceCentile = affluenceCentile;
        this.ageCentile = ageCentile;
        this.childrenCentile = childrenCentile;
        this.diversityCentile = diversityCentile;
        this.urbanicityCentile = urbanicityCentile;
        this.footfallCentile = footfallCentile;
    }
}

export const loadRetailCentres = (accountId: string): AppThunk<Promise<RetailCentre[]>> => async (dispatch) => {
    try {
        const query: Query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.StoreCategory_ID",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.YoYNetOpeningsPercentage",
                "LocationBenchmarkMetrics.FootfallCentile"
            ],
            filters: [{
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [accountId]
            }],
            segments: [
                "LocationBenchmarkMetrics.Baseline"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;

        const retailCentres = resultSet.rawData().map(row => new RetailCentre(
            row["LocationBenchmarkMetrics.RetailCentreID"],
            row["LocationBenchmarkMetrics.StoreCategory_ID"],
            row["LocationBenchmarkMetrics.AffluenceCentile"],
            row["LocationBenchmarkMetrics.AgeCentile"],
            row["LocationBenchmarkMetrics.ChildrenCentile"],
            row["LocationBenchmarkMetrics.DiversityCentile"],
            row["LocationBenchmarkMetrics.UrbanicityCentile"],
            row["LocationBenchmarkMetrics.FootfallCentile"]
        ));

        return retailCentres;
    } catch (error) {
        dispatch(logError("Error loading RetailCentres.", error));
        throw error;
    }
};
