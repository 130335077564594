import React from "react";
import { Box, Typography } from "@material-ui/core";

const SalesPerPoundOfPropertyCostTitle = () => {
    const label = "Sales per pound of property cost";

    return (
        <Box display="flex" data-cy="sales-per-pound-of-property-cost-title" pb={1}>
            <Typography variant="h6">{label}</Typography>
        </Box>

    );
};

export default SalesPerPoundOfPropertyCostTitle;
