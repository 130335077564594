import React from "react";

import { Box, Button } from "@material-ui/core";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { openStreetView } from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        backgroundColor: theme.palette.background.default,
        "&:hover": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const StreetView: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const handleClick = () => {
        dispatch(openStreetView());
    };

    return (
        <Box position="absolute" bottom={theme.spacing(5)} right={theme.spacing(7)} zIndex={theme.zIndex.drawer}>
            <StyledButton
                variant="contained"
                size="small"
                color="secondary"
                disableElevation
                startIcon={<LaunchRoundedIcon />}
                onClick={handleClick}
                data-cy="btn-explore"
            >
                Open street view
            </StyledButton>
        </Box>
    );
};

export default StreetView;
