import React from "react";

import { Box, Card, CardContent, Grid } from "@material-ui/core";

import ComparatorCard from "./ComparatorCard";

import { useAppDispatch, useAppSelector } from "store";
import { Comparator } from "modules/customer/insights/portfolio/comparator";
import {
    selectComparators,
    selectCandidateComparator,
    setCandidateComparator,
    clearCandidateComparator
} from "modules/customer/insights/portfolio/filters/filtersSlice";
import { withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const Comparators: React.FC = () => {
    const dispatch = useAppDispatch();
    const comparators = useAppSelector(selectComparators);
    const candidateComparator = useAppSelector(selectCandidateComparator);

    const handleSelect = (comparator: Comparator) => {
        dispatch(setCandidateComparator(comparator));
    };

    const handleDeselect = () => {
        dispatch(clearCandidateComparator());
    };

    return (
        <Box marginTop={10} marginBottom={4}>
            {comparators.length > 0 && (
                <StyledCard elevation={0}>
                    <CardContent>
                        <Grid container spacing={2}>
                            {comparators.map(comparator =>
                                <Grid key={comparator.type} item xs={4}>
                                    <ComparatorCard
                                        comparator={comparator}
                                        selected={candidateComparator?.type === comparator.type}
                                        onSelect={handleSelect}
                                        onDeselect={handleDeselect}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </StyledCard>
            )}
        </Box>
    );
};

export default Comparators;
