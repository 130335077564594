import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import numberFormatter from 'utils/numberFormatter';

import ChartBase from "./ChartBase";


const Scatter = (props) => {
    const { dataCy, loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'Open Sans'
            },

        },
        xAxis: {
            title: {
                style: {
                    color: 'white'
                }
            },
            labels: {
                style: {
                    color: 'white'
                }
            },
            showLastLabel: true,
            gridLineWidth: 0,
            minorGridLineWidth: 0
        },
        yAxis: {
            title: {
                style: {
                    color: 'white'
                }
            },
            labels: {
                style: {
                    color: 'white'
                }
            },
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: '#FFFFFF',
            lineWidth: 1
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    },
                    symbol: 'circle'
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                // tooltip: {
                //     headerFormat: '<b>{series.name}</b><br>'
                // },
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                    color: 'white'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:13px">{point.key}</span><table>',
            pointFormatter: function () {
                let pointName = this.name;
                let series = this.series;
                let seriesName = series.name;
                let seriesIndex = series.index;
                let seriesColor = series.color;
                let xAxisTitle = series.xAxis.options.title.text;
                let yAxisTitle = series.yAxis.options.title.text;

                let otherSeriesIndex = -1;

                if (seriesIndex === 0) {
                    otherSeriesIndex = 1;
                } else {
                    otherSeriesIndex = 0;
                }

                let otherSeries = series.chart.series[otherSeriesIndex];
                let otherSeriesName = otherSeries?.name;
                let otherSeriesColor = otherSeries?.color;

                let x = this.x;
                let otherPoint = _.find(otherSeries?.points, function (obj) {
                    return obj?.name === pointName;
                });
                let otherX = otherPoint?.x;

                let y = this.y;
                let otherY = otherPoint?.y;

                return '<tr><td style="color:' + seriesColor + ';padding:0"><b>' + seriesName + ':</b> </td></tr>' +
                    '<tr><td>' + xAxisTitle + ':</td><td style="padding:0"><b> ' + numberFormatter.toGBP(x, 2) + '</b></td></tr>' +
                    '<tr><td>' + yAxisTitle + ':</td><td style="padding:0"><b> ' + numberFormatter.toGBP(y, 2) + '</b></td></tr>' +
                    '<tr><td style="color:' + otherSeriesColor + ';padding:0"><b>' + otherSeriesName + ':</b> </td></tr>' +
                    '<tr><td>' + xAxisTitle + ':</td><td style="padding:0"><b> ' + numberFormatter.toGBP(otherX, 2) + '</b></td></tr>' +
                    '<tr><td>' + yAxisTitle + ':</td><td style="padding:0"><b> ' + numberFormatter.toGBP(otherY, 2) + '</b></td></tr>';
            },
            footerFormat: '</table>',
            useHTML: true
        }
    };

    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

Scatter.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Scatter.defaultProps = {
    dataCy: "scatter-chart",
    loading: false,
    error: false
};

export default Scatter;
