import React from "react";
import { useLocation } from "react-router-dom";
import { scrollIntoViewByRef } from "utils/scrollUtils";

import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageCallToAction from "components/landing/PageCallToAction";
import PageSection from "components/landing/PageSection";

import Section from "./Section";

const InsightsAndTools: React.FC = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const section = searchParams.get("section");
    const theme = useTheme();
    const evenBgcolor = theme.palette.background.default;
    const oddBgcolor = theme.palette.common.white;
    const performanceRef = React.useRef(null);
    const portfolioRef = React.useRef(null);
    const locationRef = React.useRef(null);
    const rangeRef = React.useRef(null);
    const sections = React.useMemo(() => {
        return [{
            id: "performance",
            title: "Performance",
            subtitle: "Track your business' past and future performance, and compare it to the market. Identify your strengths and weaknesses, and get early sight of problems as they emerge.",
            icon: <TrendingUpRoundedIcon />,
            image: "/content/landing/insights-and-tools/performance.png",
            alt: "performance",
            bulletPoints: [
                "Business health check: understand your business' past performance and what is driving it.",
                "Actionable insights: indicators show you what is and is not going well in your business so you can prioritise your next steps.",
                "Future performance forecasts: clear business performance forecasts allow you to identify where changes are needed.",
                "Market trends analysis: find out whether you are ahead or behind the markets you operate in and anticipate future opportunities.",
                "Budget tracking: learn whether you will meet or miss your budget and identify the main drivers for this."
            ],
            ref: performanceRef,
            isAvailable: true
        }, {
            id: "portfolio",
            title: "Portfolio",
            subtitle: "Manage your store with confidence. See how well each store serves its current and potential customers, and replicate what your best stores are doing across your portfolio.",
            icon: <StoreRoundedIcon />,
            image: "/content/landing/insights-and-tools/portfolio.png",
            alt: "portfolio",
            bulletPoints: [
                "Portfolio analysis: understand how each store's location, competition, and customers affect store and product performance.",
                "Benchmarking: compare each store's performance to others in your portfolio and identify your best and worst stores.",
                "Customer analysis: see the demographics of customers local to each of your stores and tailor your product offering to meet their needs.",
                "Forecast financials: evaluate the trajectory of each store to help with lease decisions.",
                "Industry-standard indicators: learn how store size and staffing decisions drive each store's financial performance."
            ],
            ref: portfolioRef,
            isAvailable: true
        }, {
            id: "location",
            title: "Location",
            subtitle: "Understand where you should open your next store using rich external data such as demographics, health of the high street, footfall, market size and local competition.",
            icon: <PinDropRoundedIcon />,
            image: "/content/landing/insights-and-tools/location.png",
            alt: "location",
            bulletPoints: [
                "Pinpoint locations: Dash analyses and ranks every location in the UK, helping you take your search from national to street level.",
                "Improve your winning formula: compare potential locations against your current best stores and find the best places to expand.",
                "Competitive edge: evaluate potential locations against those your competitors operate within and identify where you would have the competitive edge.",
                "Target customers: Dash allows you to define your target customer and shows you the locations that best align with this.",
                "Tailored search: input what factors matter most when opening new stores and Dash ranks every location based on your business' needs."
            ],
            ref: locationRef,
            isAvailable: true
        }, {
            id: "range",
            title: "Range",
            subtitle: "Understand what your customers want and evolve your product offering to meet their needs, reduce wastage and improve cash flow with better buying decisions",
            icon: <ShoppingBasketRoundedIcon />,
            image: "/content/landing/insights-and-tools/range.png",
            alt: "range",
            bulletPoints: [
                "Sales, behaviour, and demographic analysis: understand how your customers' buying habits vary and tailor each store's offering to their needs.",
                "Basket analysis: see which products your customers frequently buy together to help target offers and optimise each store's layout.",
                "Range refinement: learn which products to cut from your product range to maximise your business' revenue and profit.",
                "Procurement optimisation: evaluate future demand for your products to ensure you buy better, avoid slow-moving stock and improve cash flow.",
                "Product segmentation: determine which products are driving your performance, which are forecast growth, and which to remove from your range."
            ],
            ref: rangeRef,
            isAvailable: false
        }];
    }, [performanceRef, portfolioRef, locationRef, rangeRef]);

    const isEven = (value: number) => {
        return value % 2 === 0;
    };

    React.useEffect(() => {
        const ref = sections.find(s => s.id === section)?.ref;
        setTimeout(() => {
            scrollIntoViewByRef(ref);
        }, 250);
    }, [section, sections]);

    return (
        // @ts-ignore
        <Page dataCy="pages-landing-insights-and-tools">
            <PageHeader
                title="Insights and tools"
                subtitle="Powerful analytics to unlock your business potential"
                dataCy="pages-landing-insights-and-tools-header"
            />
            {sections.map((section, index) =>
                // @ts-ignore
                <PageSection
                    key={index}
                    ref={section.ref}
                    bgcolor={isEven(index) ? evenBgcolor : oddBgcolor}
                    dataCy={`pages-landing-insights-and-tools-section-${index + 1}`}
                >
                    <Section
                        direction={isEven(index) ? "row-reverse" : "row"}
                        title={section.title}
                        subtitle={section.subtitle}
                        icon={section.icon}
                        image={section.image}
                        alt={section.alt}
                        bulletPoints={section.bulletPoints}
                        isAvailable={section.isAvailable}
                    />
                </PageSection>
            )}
            <PageCallToAction
                color={theme.palette.common.white}
                bgcolor={theme.palette.primary.main}
                title="Quickly check our FAQs"
                subtitle="Learn more through our Frequently Asked Questions"
                actionLabel="View FAQs"
                actionUrl="/frequently-asked-questions"
                dataCy="pages-landing-insights-and-tools-call-to-action"
            />
        </Page>
    );
};

export default InsightsAndTools;
