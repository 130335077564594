import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectClientRegistration,
    selectComparatorsByChapter,
    selectHasErrors as selectLocationHasErrors,
    selectIsLoading as selectLocationIsLoading,
    selectPinnedLocation,
    selectTarget
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { loadComplementaryCategories } from "./complementaryCategory";
import { loadOpeningsAndClosures, OpeningsAndClosures } from "./openingsAndClosures";
import { OutputArea } from "./outputArea";
import { createStoreCategories, StoreCategory } from "./storeCategory";
import { loadStoreCountsNetOpenings, StoreCountsNetOpenings } from "./storeCountsNetOpenings";
import { Store } from "./store";

//ToDo: adjust this interface
interface netOpenVsClosuresOverTimeObject {
    timeframe: string;
    percentageChange: number;
    netOpenings: number;
}

export enum TimelineField {
    storeCountCurrent = "storeCountCurrent",
    storeCount6MonthsAgo = "storeCount6MonthsAgo",
    storeCount12MonthsAgo = "storeCount12MonthsAgo",
    storeCount24MonthsAgo = "storeCount24MonthsAgo",
    storeCount60MonthsAgo = "storeCount60MonthsAgo"
}

interface StoresVisibility {
    showStoreOpenings: boolean,
    showStoreClosures: boolean
}

interface LoadAreaHealthResponse {
    storeCountsNetOpenings: StoreCountsNetOpenings[],
    openingsAndClosures: OpeningsAndClosures[],
    storeCategories: StoreCategory[]
}

interface AreaHealthState {
    isLoading: boolean,
    hasErrors: boolean,
    timelineStartField: TimelineField,
    timelineEndField: TimelineField,
    storeCountsNetOpenings: StoreCountsNetOpenings[],
    openingsAndClosures: OpeningsAndClosures[],
    storeCategories: StoreCategory[],
    storesVisibility: StoresVisibility
}

const initialState: AreaHealthState = {
    isLoading: false,
    hasErrors: false,
    timelineStartField: TimelineField.storeCount12MonthsAgo,
    timelineEndField: TimelineField.storeCountCurrent,
    storeCountsNetOpenings: [],
    openingsAndClosures: [],
    storeCategories: [],
    storesVisibility: {
        showStoreOpenings: true,
        showStoreClosures: true
    }
};

const areaHealthSlice = createSlice({
    name: "customer/tools/location/areaHealth",
    initialState,
    reducers: {
        setTimelineStartField: (state, action: PayloadAction<TimelineField>) => {
            state.timelineStartField = action.payload;
        },
        setTimelineEndField: (state, action: PayloadAction<TimelineField>) => {
            state.timelineEndField = action.payload;
        },
        clearStoreCountsNetOpenings: (state) => {
            state.storeCountsNetOpenings = [];
        },
        clearOpeningsAndClosures: (state) => {
            state.openingsAndClosures = [];
        },
        clearStoreCategories: (state) => {
            state.storeCategories = [];
        },
        toggleStoreCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const storeCategoryName = action.payload;
            state.storeCategories.find(storeCategory => storeCategory.name === storeCategoryName)?.toggleIsSelected();
        },
        chooseAllStoreCategories: (state) => {
            state.storeCategories.forEach(storeCategory => storeCategory.setIsSelected(true));
        },
        deselectAllStoreCategories: (state) => {
            state.storeCategories.forEach(storeCategory => storeCategory.setIsSelected(false));
        },
        toggleShowStoreOpenings: (state) => {
            state.storesVisibility.showStoreOpenings = !state.storesVisibility.showStoreOpenings;
        },
        toggleShowStoreClosures: (state) => {
            state.storesVisibility.showStoreClosures = !state.storesVisibility.showStoreClosures;
        },
        clearStoresVisibility: (state) => {
            state.storesVisibility = initialState.storesVisibility;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadAreaHealth.pending, (state: AreaHealthState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadAreaHealth.rejected, (state: AreaHealthState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.storeCountsNetOpenings = initialState.storeCountsNetOpenings;
            state.openingsAndClosures = initialState.openingsAndClosures;
            state.storeCategories = initialState.storeCategories;
        });
        builder.addCase(loadAreaHealth.fulfilled, (state: AreaHealthState, action: PayloadAction<LoadAreaHealthResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.storeCountsNetOpenings = action.payload.storeCountsNetOpenings;
            state.openingsAndClosures = action.payload.openingsAndClosures;
            state.storeCategories = action.payload.storeCategories;
        });
    }
});

export const {
    setTimelineStartField,
    setTimelineEndField,
    chooseAllStoreCategories,
    deselectAllStoreCategories,
    toggleStoreCategoryIsSelected,
    toggleShowStoreOpenings,
    toggleShowStoreClosures
} = areaHealthSlice.actions;

export const loadAreaHealth = createAppAsyncThunk(
    "customer/tools/location/areaHealth/loadAreaHealth",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const primaryStoreCategoryName = selectClientRegistration(state)?.primaryStoreCategoryName ?? "";
            const retailCentreId = selectPinnedLocation(state)?.retailCentre.id;
            const comparatorRetailCentreId = selectComparatorsByChapter(state)?.areaHealth?.retailCentreId;

            const retailCentreIds = [];
            if (retailCentreId) {
                retailCentreIds.push(retailCentreId);
            }
            if (comparatorRetailCentreId) {
                retailCentreIds.push(comparatorRetailCentreId);
            }

            const storeCountsNetOpeningsPromise = thunkAPI.dispatch(loadStoreCountsNetOpenings(retailCentreIds));
            const openingsAndClosuresPromise = thunkAPI.dispatch(loadOpeningsAndClosures(retailCentreId));
            const complementaryCategoriesPromise = thunkAPI.dispatch(loadComplementaryCategories());
            const result = await Promise.all([storeCountsNetOpeningsPromise, openingsAndClosuresPromise, complementaryCategoriesPromise]);
            const storeCountsNetOpenings = result[0];
            const openingsAndClosures = result[1];
            const complementaryCategories = result[2];
            const storeCategories = createStoreCategories(primaryStoreCategoryName, complementaryCategories, storeCountsNetOpenings);
            const loadAreaHealthResponse: LoadAreaHealthResponse = {
                storeCountsNetOpenings,
                openingsAndClosures,
                storeCategories
            };
            return loadAreaHealthResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading AreaHealth.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const clearAreaHealth = (): AppThunk => (dispatch) => {
    dispatch(areaHealthSlice.actions.clearStoreCountsNetOpenings());
    dispatch(areaHealthSlice.actions.clearOpeningsAndClosures());
    dispatch(areaHealthSlice.actions.clearStoreCategories());
    dispatch(areaHealthSlice.actions.clearStoresVisibility());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.areaHealth.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.areaHealth.hasErrors;
};

export const selectTimelineStartField = (state: RootState) => {
    return state.customer.tools.location.areaHealth.timelineStartField;
};

export const selectTimelineEndField = (state: RootState) => {
    return state.customer.tools.location.areaHealth.timelineEndField;
};

export const selectStoreCountsNetOpenings = (state: RootState) => {
    return state.customer.tools.location.areaHealth.storeCountsNetOpenings;
};

export const selectOpeningsAndClosures = (state: RootState) => {
    return state.customer.tools.location.areaHealth.openingsAndClosures;
};

export const selectStoreCategories = (state: RootState) => {
    return state.customer.tools.location.areaHealth.storeCategories;
};

export const selectStoresVisibility = (state: RootState) => {
    return state.customer.tools.location.areaHealth.storesVisibility;
};

export const selectNumberOfOpenStores = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectTarget(state),
    (isLoading, hasErrors, pinnedLocation, target) => {
        const id = "number-of-open-stores";
        let label = "Year-on-year (%) change in number of open stores for the selected location";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        if (!pinnedLocation) {
            return new RagIndicator(id, status, label, "No location selected.");
        }
        if (!target?.useAreaHealth) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, "No target set for Area Health", "");
        }
        const score = pinnedLocation.retailCentre.getRagScore(ScoreField.AreaHealth);
        switch (score) {
            case 5:
            case 4:
                status = RagIndicatorStatus.Green;
                label = "The year-on-year (%) change in the number of open stores for the selected location aligns strongly with your target level.";
                break;
            case 3:
            case 2:
                status = RagIndicatorStatus.Amber;
                label = "The year-on-year (%) change in the number of open stores for the selected location aligns averagely with your target level.";
                break;
            default:
                status = RagIndicatorStatus.Red;
                label = "The year-on-year (%) change in the number of open stores for the selected location aligns weakly with your target level.";
        }
        return new RagIndicator(id, status, label, "");
    }
);

export const selectAreaHealthAlignmentScore = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (pinnedLocation) => {
        return pinnedLocation?.retailCentre.areaHealthScore ?? 0;
    }
);

export const selectGroupedStoreCountsOverTime = createSelector(
    selectStoreCountsNetOpenings,
    (storeCountsNetOpenings) => {
        return _(storeCountsNetOpenings)
            .filter(storeCounts => storeCounts.isRetail)
            .groupBy(openings => openings.retailCentreId)
            .map((group, key) => ({
                retailCentreId: key,
                retailCentreValues: group
                    .reduce((accum: any, openings) => {
                        accum.storeCountCurrent += openings.storeCountCurrent;
                        accum.storeCount6MonthsAgo += openings.storeCount6MonthsAgo;
                        accum.storeCount12MonthsAgo += openings.storeCount12MonthsAgo;
                        accum.storeCount24MonthsAgo += openings.storeCount24MonthsAgo;
                        accum.storeCount60MonthsAgo += openings.storeCount60MonthsAgo;
                        return accum;
                    }, {
                        storeCountCurrent: 0,
                        storeCount6MonthsAgo: 0,
                        storeCount12MonthsAgo: 0,
                        storeCount24MonthsAgo: 0,
                        storeCount60MonthsAgo: 0,
                    })
            }))
            .map(retailCentre => ({
                retailCentreId: Number(retailCentre.retailCentreId),
                storeCountCurrent: retailCentre.retailCentreValues.storeCountCurrent,
                storeCount6MonthsAgo: retailCentre.retailCentreValues.storeCount6MonthsAgo,
                storeCount12MonthsAgo: retailCentre.retailCentreValues.storeCount12MonthsAgo,
                storeCount24MonthsAgo: retailCentre.retailCentreValues.storeCount24MonthsAgo,
                storeCount60MonthsAgo: retailCentre.retailCentreValues.storeCount60MonthsAgo,
            }))
            .value();
    }
);

export const selectNetOpenVsClosuresOverTime = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, pinnedLocation, comparatorsByChapter) => {
        const netOpenVsClosuresOverTimeData = {
            location: [] as netOpenVsClosuresOverTimeObject[],
            comparators: [] as netOpenVsClosuresOverTimeObject[],
        };

        if (isLoading || hasErrors) {
            return netOpenVsClosuresOverTimeData;
        }

        const selectedRetailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.areaHealth.retailCentreId;

        const netOpeningsVsClosuresOverTime = groupedStoreCountsOverTime.map(openings => ({
            retailCentreId: openings.retailCentreId,
            retailCentreValues: [{
                timeframe: "25 - 60 months ago",
                percentageChange: mathUtils.safePercentageChange(openings.storeCount24MonthsAgo, openings.storeCount60MonthsAgo),
                netOpenings: openings.storeCount24MonthsAgo - openings.storeCount60MonthsAgo
            }, {
                timeframe: "13 - 24 months ago",
                percentageChange: mathUtils.safePercentageChange(openings.storeCount12MonthsAgo, openings.storeCount24MonthsAgo),
                netOpenings: openings.storeCount12MonthsAgo - openings.storeCount24MonthsAgo
            }, {
                timeframe: "7 - 12 months ago",
                percentageChange: mathUtils.safePercentageChange(openings.storeCount6MonthsAgo, openings.storeCount12MonthsAgo),
                netOpenings: openings.storeCount6MonthsAgo - openings.storeCount12MonthsAgo
            }, {
                timeframe: "0 - 6 months ago",
                percentageChange: mathUtils.safePercentageChange(openings.storeCountCurrent, openings.storeCount6MonthsAgo),
                netOpenings: openings.storeCountCurrent - openings.storeCount6MonthsAgo
            }] as netOpenVsClosuresOverTimeObject[]
        }));

        const selectedOpenings = netOpeningsVsClosuresOverTime.find(openings => openings.retailCentreId === selectedRetailCentreId);
        netOpenVsClosuresOverTimeData.location = selectedOpenings?.retailCentreValues ?? netOpenVsClosuresOverTimeData.location;

        const targetOpenings = netOpeningsVsClosuresOverTime.find(openings => openings.retailCentreId === comparatorRetailCentreId);
        netOpenVsClosuresOverTimeData.comparators = targetOpenings?.retailCentreValues ?? netOpenVsClosuresOverTimeData.comparators;

        return netOpenVsClosuresOverTimeData;
    }
);

export const selectYoYRawNetStoreOpenings = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, pinnedLocation, comparatorsByChapter) => {
        const netStoreOpenings = {
            retailCentre: 0,
            comparator: 0
        };

        if (isLoading || hasErrors) {
            return netStoreOpenings;
        }

        const selectedRetailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.areaHealth.retailCentreId;

        const selectedOpenings = groupedStoreCountsOverTime.find(openings => openings.retailCentreId === selectedRetailCentreId);
        const selectedStoreCount12MonthsAgo = selectedOpenings?.storeCount12MonthsAgo ?? 0;
        const selectedStoreCountCurrent = selectedOpenings?.storeCountCurrent ?? 0;
        const selectedTwelveMonthOpenings = selectedStoreCountCurrent - selectedStoreCount12MonthsAgo;

        const targetOpenings = groupedStoreCountsOverTime.find(openings => openings.retailCentreId === comparatorRetailCentreId);
        const targetStoreCount12MonthsAgo = targetOpenings?.storeCount12MonthsAgo ?? 0;
        const targetStoreCountCurrent = targetOpenings?.storeCountCurrent ?? 0;
        const targetTwelveMonthOpenings = targetStoreCountCurrent - targetStoreCount12MonthsAgo;

        netStoreOpenings.retailCentre = selectedTwelveMonthOpenings;
        netStoreOpenings.comparator = targetTwelveMonthOpenings;
        return netStoreOpenings;
    }
);

export const selectNumberOfStoresInLocalArea = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, pinnedLocation, comparatorsByChapter) => {
        const numberOfStoresInLocalArea = {
            retailCentre: 0,
            comparator: 0
        };

        if (isLoading || hasErrors) {
            return numberOfStoresInLocalArea;
        }

        const selectedRetailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.areaHealth.retailCentreId;

        const selectedOpenings = groupedStoreCountsOverTime.find(openings => openings.retailCentreId === selectedRetailCentreId);
        const selectedStoreCount = selectedOpenings?.storeCountCurrent ?? 0;

        const targetOpenings = groupedStoreCountsOverTime.find(openings => openings.retailCentreId === comparatorRetailCentreId);
        const targetStoreCount = targetOpenings?.storeCountCurrent ?? 0;

        numberOfStoresInLocalArea.retailCentre = selectedStoreCount;
        numberOfStoresInLocalArea.comparator = targetStoreCount;
        return numberOfStoresInLocalArea;
    }
);

export const selectStoreCategoryOpeningsVsClosures = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    selectStoreCountsNetOpenings,
    selectStoreCategories,
    selectTimelineStartField,
    selectTimelineEndField,
    (pinnedLocation, comparatorsByChapter, storeCounts, storeCategories, timelineStartField, timelineEndField) => {
        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.areaHealth.retailCentreId;
        const selectedStoreCategories = storeCategories.filter(storeCategory => storeCategory.isSelected());
        const selectedStoreCategoriesNames = selectedStoreCategories.map(storeCategory => storeCategory.name);
        const filteredStoreCounts = storeCounts.filter(counts => selectedStoreCategoriesNames.includes(counts.storeCategory));

        const storeCountsByCategory = _(filteredStoreCounts)
            .groupBy(storeCategoryCounts => storeCategoryCounts.storeCategory)
            .map((storeCountsByCategory, storeCategory) => ({
                storeCategory,
                storeCountsByRetailCentre: _(storeCountsByCategory)
                    .groupBy(retailCentreCounts => retailCentreCounts.retailCentreId)
                    .map((storeCountsByRetailCentre, retailCentreId) => ({
                        retailCentreId,
                        groupedCounts: storeCountsByRetailCentre.reduce((accumulator, counts) => {
                            accumulator.storeCountCurrent += counts.storeCountCurrent;
                            accumulator.storeCount6MonthsAgo += counts.storeCount6MonthsAgo;
                            accumulator.storeCount12MonthsAgo += counts.storeCount12MonthsAgo;
                            accumulator.storeCount24MonthsAgo += counts.storeCount24MonthsAgo;
                            accumulator.storeCount60MonthsAgo += counts.storeCount60MonthsAgo;
                            return accumulator;
                        }, {
                            storeCountCurrent: 0,
                            storeCount6MonthsAgo: 0,
                            storeCount12MonthsAgo: 0,
                            storeCount24MonthsAgo: 0,
                            storeCount60MonthsAgo: 0
                        })
                    }))
                    .map(storeCountsByRetailCentre => ({
                        retailCentreId: Number(storeCountsByRetailCentre.retailCentreId),
                        storeCountCurrent: storeCountsByRetailCentre.groupedCounts.storeCountCurrent,
                        storeCount6MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount6MonthsAgo,
                        storeCount12MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount12MonthsAgo,
                        storeCount24MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount24MonthsAgo,
                        storeCount60MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount60MonthsAgo
                    }))
                    .value()
            }))
            .value();

        return selectedStoreCategoriesNames.map(storeCategory => {
            const openingsVsClosures = {
                storeCategory,
                retailCentre: {
                    percentageChange: 0,
                    netOpenings: 0
                },
                comparator: {
                    percentageChange: 0,
                    netOpenings: 0
                }
            };
            const storeCounts = storeCountsByCategory.find(counts => counts.storeCategory === storeCategory);
            if (!storeCounts) {
                return openingsVsClosures;
            }

            const retailCentreStoreCounts = storeCounts.storeCountsByRetailCentre.find(counts => counts.retailCentreId === retailCentreId);
            const retailCentreTimelineEndCount = retailCentreStoreCounts?.[timelineEndField] ?? 0;
            const retailCentreTimelineStartCount = retailCentreStoreCounts?.[timelineStartField] ?? 0;
            openingsVsClosures.retailCentre.percentageChange = mathUtils.safePercentageChange(retailCentreTimelineEndCount, retailCentreTimelineStartCount);
            openingsVsClosures.retailCentre.netOpenings = retailCentreTimelineEndCount - retailCentreTimelineStartCount;

            const benchmarkStoreCounts = storeCounts.storeCountsByRetailCentre.find(counts => counts.retailCentreId === comparatorRetailCentreId);
            const benchmarkTimelineEndCount = benchmarkStoreCounts?.[timelineEndField] ?? 0;
            const benchmarkTimelineStartCount = benchmarkStoreCounts?.[timelineStartField] ?? 0;
            openingsVsClosures.comparator.percentageChange = mathUtils.safePercentageChange(benchmarkTimelineEndCount, benchmarkTimelineStartCount);
            openingsVsClosures.comparator.netOpenings = benchmarkTimelineEndCount - benchmarkTimelineStartCount;

            return openingsVsClosures;
        });
    }
);

export const selectOutputAreas = createSelector(
    selectStoreCountsNetOpenings,
    (state: RootState) => selectPinnedLocation(state),
    (storeCountsNetOpenings, pinnedLocation) => {
        const retailCentreId = pinnedLocation?.retailCentre.id;
        const filteredStoreCountsNetOpenings = storeCountsNetOpenings.filter(counts => counts.retailCentreId === retailCentreId);
        return _(filteredStoreCountsNetOpenings)
            .groupBy(counts => counts.outputAreaCode)
            .map((group, key) => {
                const netOpenings = group.reduce((accumulator, counts) => accumulator + counts.netOpenings0to6MonthsAgo + counts.netOpenings7to12MonthsAgo, 0);
                return new OutputArea(key, netOpenings);
            })
            .value();
    }
);

export const selectStores = createSelector(
    selectOpeningsAndClosures,
    selectStoresVisibility,
    (openingsAndClosures, storesVisibility) => {
        return openingsAndClosures
            .filter(oc => (oc.hasOpened && storesVisibility.showStoreOpenings)
                || (!oc.hasOpened && storesVisibility.showStoreClosures))
            .map(oc => new Store(oc.storeName, oc.latitude, oc.longitude, oc.hasOpened));
    }
);

export const selectIstStoresVisibilityModified = createSelector(
    selectStoresVisibility,
    (storesVisibility) => {
        return storesVisibility.showStoreOpenings !== initialState.storesVisibility.showStoreOpenings
            || storesVisibility.showStoreClosures !== initialState.storesVisibility.showStoreClosures;
    }
);

export default areaHealthSlice;
