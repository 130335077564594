import React from "react";

import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    CostMeasure as CostMeasureEnum,
    selectCostMeasure,
    setCostMeasure
} from "modules/customer/insights/cost/storeCosts/storeCostsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButtonGroup = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        marginBottom: theme.spacing(2),
        borderRadius: "6px"
    },
}))(ButtonGroup);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
        color: theme.palette.common.white,
        borderRadius: "6px",
        "&:hover": {
            // @ts-ignore
            backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
            color: theme.palette.common.white,
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:firs-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:first-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        }
    }
}))(Button);

const CostMeasure: React.FC = () => {
    const dispatch = useAppDispatch();
    const costMeasure = useAppSelector(selectCostMeasure);

    const handleCostValueClick = () => {
        dispatch(setCostMeasure(CostMeasureEnum.CostValue));
    };

    const handlePercentageClick = () => {
        dispatch(setCostMeasure(CostMeasureEnum.CostAsPercentageOfRevenue));
    };

    return (
        <StyledButtonGroup
            variant="contained"
            size="medium"
            color="default"
            disableElevation
        >
            <StyledButton
                data-is-selected={costMeasure === CostMeasureEnum.CostValue}
                onClick={handleCostValueClick}
            >
                <Typography variant="subtitle2" component="div">
                    Cost value
                </Typography>
            </StyledButton>
            <StyledButton
                data-is-selected={costMeasure === CostMeasureEnum.CostAsPercentageOfRevenue}
                onClick={handlePercentageClick}
            >
                <Typography variant="subtitle2" component="div">
                    Cost as a % of revenue
                </Typography>
            </StyledButton>
        </StyledButtonGroup>
    );
};

export default CostMeasure;
