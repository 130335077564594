import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import { clearStoreCosts, loadStoreCosts } from "modules/customer/insights/cost/storeCosts/storeCostsSlice";

import CostOverTime from "./costOverTime/CostOverTime";
import StoreClustering from "./storeClustering/StoreClustering";
import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

const CostOverview: React.FC = () => {
    const dispatch = useAppDispatch();
    const store = useAppSelector(selectSelectedStoreByCostType);

    React.useEffect(() => {
        if (store) {
            dispatch(loadStoreCosts());
        }
        return () => {
            dispatch(clearStoreCosts());
        };
    }, [dispatch, store]);

    return (
        <Chapter
            title="Store costs"
            subtitle={`Understand how your ${store?.storeName} store's ${store?.costName} costs compare within the cluster. Identify savings potential, efficiency gaps, and key factors driving costs to target areas with the most impact.`}
            dataCy="store-costs-chapter"
        >
            <Subchapter
                title={`${store?.storeName} store clustering`}
                subtitle={`See the key factors driving ${store?.costName} costs, comparing this your ${store?.storeName} store against similar ones in the cluster. Identify where this it stands out, understand its alignment with the cluster average, and explore how closely it matches other stores.`}
                dataCy="store-clustering-subchapter"
            >
                <StoreClustering />
            </Subchapter>
            <Subchapter
                title={`${store?.storeName} costs over time compared to cluster`}
                subtitle={`Quickly assess how your ${store?.storeName} store's ${store?.costName} costs compare to similar locations. Identify savings potential, gauge cost efficiency relative to revenue, and uncover areas where cost control efforts could yield the most impact.`}
                dataCy="cost-over-time-subchapter"
            >
                <CostOverTime />
            </Subchapter>
        </Chapter>
    );
};

export default CostOverview;
