import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import Treemap from "components/visuals/Treemap";
import useColourPalette from "components/visuals/useColourPalette";

import { selectors } from "modules/customer/insights/portfolio/catchment/customerProfiles";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const PenPortraitTreemap = () => {
    const penPortraitTreemap = useSelector(state => selectors.penPortraitTreemap(state));
    const colourPalette = useColourPalette();
    const { loading, error, penPortraitGroups } = penPortraitTreemap;

    const chartData = penPortraitGroups.map(row => {
        let colour = undefined;
        if (row.type === "Supergroup") {
            colour = colourPalette.categorical[row.classification - 1];
        }
        return {
            id: String(row.id),
            name: row.name,
            parent: String(row.parent),
            value: row.value,
            color: colour,
            custom: {
                numberOfVisitors: row.numberOfVisitors,
                percentageOverallVisitors: row.value,
                type: String(row.type)
            }
        };
    });
    const theme = useTheme();

    const options = {
        chart: {
        },
        tooltip: {
            useHTML: true,
            headerFormat: "",
            footerFormat: "",
            pointFormatter: function () {
                const customOptions = this.custom;
                return `<table><tr><td colspan="2" style="">${customOptions?.type}:</td></tr>
                ${stringUtils.tooltipHTML([" ", "Number of visitors", "Percentage of total<br>visitors"], {
                        header: `${this.name}`,
                        values: [" ",
                            numberFormatter.toTruncatedInteger(customOptions?.numberOfVisitors, 1),
                            numberFormatter.toPercentage(customOptions?.percentageOverallVisitors, true, 1)
                        ],
                        valueFormatting: `color:${this.color}`
                    }
                )}</table>`;
            }
        },
        series: [{
            type: "treemap",
            data: chartData,
            layoutAlgorithm: "squarified",
            levels: [{
                dataLabels: {
                    style: {
                        textOverflow: "clip",
                        fontFamily: theme.typography.fontFamily
                    },
                    crop: true,
                    overflow: "justify",
                    formatter: function () {
                        const pointPercentage = this.point.value ?? 0;                        
                        const formattedPointName = this.point.name;

                        return `<table>${stringUtils.tooltipHTML([`${numberFormatter.toPercentage(pointPercentage, true, 1)}`], {
                            header: formattedPointName,
                            headerFormatting: `color: ${theme.palette.common.black}; fontWeight: 400; fontSize: 14px`,
                            categoryFormatting: `color: ${theme.palette.common.black}; fontWeight: 600; fontSize: 18px`,
                            overrideDefaultHeaderFormatting: true,
                            overrideDefaultCategoryFormatting: true
                        })}</table>`;
                    }
                }
            }]
        }]
    };

    return (
        <Box data-cy="pen-portrait-treemap">
            <Treemap loading={loading} error={error} options={options} />
        </Box>
    );
};

export default PenPortraitTreemap;
