import React from "react";

import { Hidden } from "@material-ui/core";

import OpenDesktop from "./OpenDesktop";
import OpenMobile from "./OpenMobile";

const Open: React.FC = () => {
    return (
        <>
            {/* @ts-ignore */}
            <Hidden smDown>
                <OpenDesktop />
            </Hidden>
            {/* @ts-ignore */}
            <Hidden mdUp>
                <OpenMobile />
            </Hidden>
        </>
    );
};

export default Open;
