import React from "react";

import { Step, StepContent, StepLabel, Stepper as MuiStepper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import StepIcon from "./StepIcon";

import { ComparatorType } from "modules/customer/insights/portfolioNew/comparator";
import {
    selectActiveStep,
    selectCandidateComparator,
    selectCandidateStore
} from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppSelector } from "store";

const StyledStepper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingLeft: theme.spacing(0)
    }
}))(MuiStepper);

const Stepper: React.FC = () => {
    const colourPalette = useColourPalette();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateStore = useAppSelector(selectCandidateStore);
    const candidateComparator = useAppSelector(selectCandidateComparator);

    return (
        <StyledStepper activeStep={activeStep} orientation="vertical">
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Select a store
                    </Typography>
                </StepLabel>
                <StepContent style={{ color: colourPalette.comparators[0] }}>
                    <Typography variant="h6" component="div">
                        {candidateStore?.name}
                    </Typography>
                </StepContent>
            </Step>
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Select a comparator
                    </Typography>
                </StepLabel>
                <StepContent style={{ color: colourPalette.comparators[1] }}>
                    <Typography variant="h6" component="div">
                        {candidateComparator?.name}
                    </Typography>
                </StepContent>
            </Step>
            {candidateComparator?.type === ComparatorType.Custom && (
                <Step expanded>
                    <StepLabel StepIconComponent={StepIcon}>
                        <Typography variant="subtitle1" component="div">
                            Make a custom selection
                        </Typography>
                    </StepLabel>
                    <StepContent style={{ color: colourPalette.comparators[1] }}>
                        <Typography variant="h6" component="div">
                            Custom selection ({candidateComparator?.getStores().length ?? 0})
                        </Typography>
                    </StepContent>
                </Step>
            )}
        </StyledStepper>
    );
};

export default Stepper;
