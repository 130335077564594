const dateUTC = (date: Date | string | number): Date => {
  /*
  Default function for making dates with our data.

  The primary motivation for this is that all of our data tools work with UTC dates except JavaScript, 
  which often assumes the user wants dates in their local timezone.

  Whilst a JavaScript date object is essentially a UTC based epoch timestamp (a la Unix timestamp, but 
  in milliseconds instead of seconds), when converting strings into JS date objects, unless 
  specifically stated, it will assume that it's a local timezone date string (except strings  in the 
  format yyyy-mm-dd for some reason).

  For this reason, this function exists as a default to create dates when the data type cannot reliably 
  be assumed, and to simplify even where it is. 

  * If a string is fed in, the function will check if the last character is a 'Z'. A 'Z' would signify 
  that it is a UTC date, and JS will treat it as such. If it has a 'Z', then a new Date is made from 
  it, if not, a 'Z' is appended to force JS to bring it in as a UTC date.

  * If the function is used as a number, then it is assumed that it's a JS timestamp, and a new date 
  is created.

  * If the 'date' argument is already a Date object, then it's just returned.

  Example of erroneous default (new Date) date handling with a user in the BST timezone (UTC+1):
    `
    //Date without timezone information (this is how UTC dates are returned from Cube)
    const dateString = '2022-09-07T00:00:00';

    //Using default date constructor
    const testDate = new Date(dateString);
    console.log(testDate.toUTCString()); 
    `
    >> "Tue, 06 Sep 2022 23:00:00 GMT"

  This is wrong because the date was already in UTC, so it should have returned the same date and time 
  as originally given. Instead, JS assumed the date string was local time (British Summer Time), and
  so the logged output of the date's UTC time is an hour out (and also a day out!).

  Same example using this function:
    `
    const fixedDate = dateUTC(dateString);
    console.log(fixedDate.toUTCString()); 
    `
    >> ""Wed, 07 Sep 2022 00:00:00 GMT"
  
  This is the correct handling.

  Further reading: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date 
  */
    if (typeof date === "string") {
        if (date.slice(-1) === "Z") {
            return new Date(date);
        } else {
            return new Date(date + "Z");
        }
    } else if (typeof date === "number") {
        return new Date(date);
    }

    return new Date(date);
};


const addDays = (date: Date, numberOfDays: number): Date => {
  const modifiedDate = new Date(date);
  return dateUTC(modifiedDate.setUTCDate(modifiedDate.getUTCDate() + numberOfDays));
};

const addMonths = (date: Date, numberOfMonths: number): Date => {
  const modifiedDate = new Date(date);
  return dateUTC(modifiedDate.setUTCMonth(modifiedDate.getUTCMonth() + numberOfMonths));
};

const addYears = (date: Date, numberOfYears: number): Date => {
  const modifiedDate = new Date(date);
  return dateUTC(modifiedDate.setUTCFullYear(modifiedDate.getUTCFullYear() + numberOfYears));
};

const firstDayOfMonth = (date: Date): Date => {
  const lastDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
  return startTime(lastDay);
};

const lastDayOfMonth = (date: Date): Date => {
  const lastDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));
  return endTime(lastDay);
};

const startTime = (date: Date): Date => {
  const modifiedDate = new Date(date);
  return dateUTC(modifiedDate.setUTCHours(0, 0, 0, 0));
};

const endTime = (date: Date): Date => {
  const modifiedDate = new Date(date);
  return dateUTC(modifiedDate.setUTCHours(23, 59, 59, 999));
};

const weekToDateStartDate = (date: Date | string | number) => {
  let weekToDateStartDate = dateUTC(date);
  weekToDateStartDate.setUTCDate(
    weekToDateStartDate.getUTCDate() - (weekToDateStartDate.getUTCDay())
  );
  return startTime(weekToDateStartDate);
};

const previousWeekToDateStartDate = (date: Date | string | number) => {
  let previousWeekToDateStartDate = dateUTC(date);
  previousWeekToDateStartDate.setUTCDate(
    previousWeekToDateStartDate.getUTCDate() -
      ((previousWeekToDateStartDate.getUTCDay())) - 7
  );
  return startTime(previousWeekToDateStartDate);
};

const monthToDateStartDate = (date: Date | string | number) => {
  const startDate = dateUTC(date);
  const monthStartDate = new Date(Date.UTC(
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(),
    1
  ));
  return monthStartDate;
};

const financialYearStartDate = (date: Date | string | number, financialYearStartDate: Date | string | number) => {
  //date = reference date/current date
  //financialYearStartDate = when the client's financial year starts. Assume start of month.
  //Provided date may not be for the current year.

  const startDate = dateUTC(date);
  const fyDate = dateUTC(financialYearStartDate);

  const timeDifference = startDate.getTime() - fyDate.getTime();  // get the difference in milliseconds
  const differenceInYears = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));  // convert milliseconds to years and round down
  const updatedFyDate = startTime(addYears(fyDate, differenceInYears));  // add the rounded down difference to fyDate
  return dateUTC(updatedFyDate);
};

const financialYearEndDate = (date: Date | string | number, financialYearStartDate: Date | string | number) => {
  //date = reference date/current date
  //financialYearStartDate = when the client's financial year starts. Assume start of month.
  //Provided date may not be for the current year.

  const endDate = dateUTC(date);
  const fyStartDate = dateUTC(financialYearStartDate);
  
  let fyDate = endTime(new Date(Date.UTC(
        fyStartDate.getUTCFullYear() + 1,
        fyStartDate.getUTCMonth(),
        0
  )));
  
  if (fyStartDate.getUTCDate() !== 1) {
      fyDate.setUTCDate(fyStartDate.getUTCDate() - 1);
      fyDate.setUTCMonth(fyStartDate.getUTCMonth());
  }

  let yearEndDate: Date;

  //User may have provided the financial year dates in a previous year so the year may not be "current", this step deals with that
  if ((endDate.getUTCMonth() > fyDate.getUTCMonth()) ||
  (endDate.getUTCMonth() === fyDate.getUTCMonth() && 
  endDate.getUTCDate() > fyDate.getUTCDate())) {
    yearEndDate = new Date(Date.UTC(
      endDate.getUTCFullYear() + 1,
      fyDate.getUTCMonth(),
      fyDate.getUTCDate()
    ));
  } else {
    yearEndDate = new Date(Date.UTC(
      endDate.getUTCFullYear(),
      fyDate.getUTCMonth(),
      fyDate.getUTCDate()
    ));
  }

  //Deal with the case where the client picked 28th/29th Feb as their FY end date.
  if (fyDate.getUTCMonth() === 1) {
    yearEndDate = new Date(Date.UTC(yearEndDate.getUTCFullYear(), 2, 1));
    yearEndDate.setUTCDate(yearEndDate.getUTCDate() - 1);
  }

  return endTime(yearEndDate);
};

const previousFinancialYearEndDate = (date: Date | string | number, financialYearStartDate: Date | string | number) => {
  //date = reference date/current date
  //financialYearStartDate = when the client's financial year starts. Assume start of month.
  //Provided date may not be for the current year.

  const endDate = dateUTC(date);
  const fyStartDate = dateUTC(financialYearStartDate);
  
  //Calculate fy end date based off start date
  let fyDate = endTime(new Date(Date.UTC(
    fyStartDate.getUTCFullYear() + 1,
    fyStartDate.getUTCMonth(),
    0
  )));

  if (fyStartDate.getUTCDate() !== 1) {
    fyDate.setUTCDate(fyStartDate.getUTCDate() - 1);
    fyDate.setUTCMonth(fyStartDate.getUTCMonth());
  }

  let yearEndDate: Date;

  //User may have provided the financial year dates in a previous year so the year may not be "current", this step deals with that
  if ((endDate.getUTCMonth() > fyDate.getUTCMonth()) ||
  (endDate.getUTCMonth() === fyDate.getUTCMonth() && 
  endDate.getUTCDate() > fyDate.getUTCDate())) {
    yearEndDate = new Date(Date.UTC(
      endDate.getUTCFullYear(),
      fyDate.getUTCMonth(),
      fyDate.getUTCDate()
    ));
  } else {
    yearEndDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      fyDate.getUTCMonth(),
      fyDate.getUTCDate()
    ));
  }

  //Deal with the case where the client picked 28th/29th Feb as their FY end date.
  if (fyDate.getUTCMonth() === 1) {
    yearEndDate = new Date(Date.UTC(yearEndDate.getUTCFullYear(), 2, 1));
    yearEndDate.setUTCDate(yearEndDate.getUTCDate() - 1);
  }

  return endTime(yearEndDate);
};

const previousFinancialYearStartDate = (date: Date | string | number, financialYearStartDate: Date | string | number) => {
  //date = reference date/current date
  //financialYearStartDate = when the client's financial year starts. Assume start of month.
  //Provided date may not be for the current year.

  const startDate = dateUTC(date);
  const fyDate = dateUTC(financialYearStartDate);

  const timeDifference = startDate.getTime() - fyDate.getTime();  // get the difference in milliseconds
  const differenceInYears = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));  // convert milliseconds to years and round down
  const updatedFyDate = addYears(fyDate, differenceInYears);  // add the rounded down difference to fyDate

  const previousFinancialYearStartDate = addYears(updatedFyDate, -1); // subtract 1 year from the current financial year start date to get the previous financial year start date
  return dateUTC(previousFinancialYearStartDate);
};

const previousFinancialYearToDateEndDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  const yearEndDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth(),
    endDate.getUTCDate()
  ));
  //Deal with February 29th/28th
  if (yearEndDate.getUTCMonth() > endDate.getUTCMonth()) {
    yearEndDate.setUTCDate(yearEndDate.getUTCDate() - 1);
  }
  return endTime(yearEndDate);
};

const latestFullMonthStartDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));
  
  //Mid month avoids issues around end of Feb
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return firstDayOfMonth(midRefMonth);
  } else {
    return firstDayOfMonth(addMonths(midRefMonth, -1));
  }
};

const latestFullMonthEndDate = (date: Date | string | number) => {
  const referenceDate = endTime(dateUTC(date));
  const lastDay = endTime(lastDayOfMonth(referenceDate));
  
  //Mid month avoids issues around end of Feb
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return lastDayOfMonth(midRefMonth);
  } else {
    return lastDayOfMonth(addMonths(midRefMonth, -1));
  }
};

const monthBeforeLatestFullMonthStartDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));

   //if day is last day of month - give 1st of last month, otherwise, 1st of 2 months ago
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return firstDayOfMonth(addMonths(midRefMonth, -1));
  } else {
    return firstDayOfMonth(addMonths(midRefMonth, -2));
  }
};

const monthBeforeLatestFullMonthEndDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));

   //if day is last day of month - give last of last month, otherwise, last of 2 months ago
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return lastDayOfMonth(addMonths(midRefMonth, -1));
  } else {
    return lastDayOfMonth(addMonths(midRefMonth, -2));
  }
};

const threeYearsAgoStartDate = (date: Date | string | number): Date => {
  const endDate = dateUTC(date);
  const nextDay = addDays(endDate, 1);
  const startTimeNextDay = startTime(nextDay);
  const startDate = addYears(startTimeNextDay, -3);
  return startDate;
};

const twelveMonthsBeforePriorTwelveMonthsEndDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let yearEndDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth(),
    endDate.getUTCDate()
  ));
  //Deal with February 29th (month 1 = Feb)
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    yearEndDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      endDate.getUTCMonth(),
      endDate.getUTCDate() - 1
    ));
  }
  return endTime(yearEndDate);
};

const twelveMonthsBeforePriorTwelveMonthsStartDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let yearStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 2,
    endDate.getUTCMonth(),
    endDate.getUTCDate() + 1
  ));
  //Deal with February 29th (month 1 = Feb)
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    yearStartDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 2,
      endDate.getUTCMonth(),
      endDate.getUTCDate()
    ));
  }
  return yearStartDate;
};

const priorTwelveMonthsFromCurrentMonthStartDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let yearStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth(),
    1
  ));
  //Deal with February 29th (month 1 = Feb)
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    yearStartDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      endDate.getUTCMonth(),
      1
    ));
  }
  return yearStartDate;
};

const priorTwelveMonthsStartDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let yearStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth(),
    endDate.getUTCDate() + 1
  ));
  //Deal with February 29th (month 1 = Feb)
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    yearStartDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      endDate.getUTCMonth(),
      endDate.getUTCDate()
    ));
  }
  return yearStartDate;
};

const priorTwelveMonthsFromPreviousMonthStartDate = (date: Date | string | number) => {
  //FIXME in some cases this returns strange results eg 31st July returns 2nd July. 
  //potential to fix with luxon
  const endDate = dateUTC(date);
  let yearStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth() - 1,
    endDate.getUTCDate() + 1
  ));
  //Deal with dates at end of March (month = 2) to deal with behaviour of end of Feb dates.
  if (endDate.getUTCMonth() === 2 && endDate.getUTCDate() >= 29) {
    yearStartDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      endDate.getUTCMonth(),
      1
    ));
  }
  return yearStartDate;
};

const nextDay = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  const dayStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear(),
    endDate.getUTCMonth(),
    endDate.getUTCDate() + 1
  ));
  return startTime(dayStartDate);
};

const previousFinancialYTGStartDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let prevYTGStartDate = new Date(Date.UTC(
    endDate.getUTCFullYear() - 1,
    endDate.getUTCMonth(),
    endDate.getUTCDate() + 1
  ));
  //deal with Feb 29th
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    prevYTGStartDate = new Date(Date.UTC(
      endDate.getUTCFullYear() - 1,
      2,
      1
    ));
  }
  return prevYTGStartDate;
};

const nextWeekEndDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  const next7DaysEndDate = new Date(Date.UTC(
    endDate.getUTCFullYear(),
    endDate.getUTCMonth(),
    endDate.getUTCDate() + 6
  ));
  return endTime(next7DaysEndDate);
};

const nextFullMonthStartDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));

  //if date is last day of the month, give the 1st day of the next month otherwise give the first of this month 
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return firstDayOfMonth(addMonths(midRefMonth, +1));
  } else {
    return firstDayOfMonth(midRefMonth);
  }
};

const nextFullMonthEndDate = (date: Date | string | number) => {
  const referenceDate = endTime(dateUTC(date));
  const lastDay = endTime(lastDayOfMonth(referenceDate));
  
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

   //if day is last day of the month, get end date of next mnth, else get end of this mnth
  if (referenceDate.getTime() === lastDay.getTime()) {
    return endTime(lastDayOfMonth(addMonths(midRefMonth, +1)));
  } else {
    return endTime(lastDayOfMonth(midRefMonth));
  }

};

const nextFullMonthPreviousYearStartDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));

  //if date is last day of the month, give the 1st day of the next month
  //otherwise give the first of this month 

  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  if (referenceDate.getTime() === lastDay.getTime()) {
    return firstDayOfMonth(addYears(addMonths(midRefMonth, +1), -1));
  } else {
    return firstDayOfMonth(addYears(midRefMonth, -1));
  }
};

const nextFullMonthPreviousYearEndDate = (date: Date | string | number) => {
  const referenceDate = endTime(dateUTC(date));
  const lastDay = endTime(lastDayOfMonth(referenceDate));

   //if day is last day of the month, get end date of next mnth, else get end of this mnth
  if (referenceDate.getTime() === lastDay.getTime()) {
    return new Date(Date.UTC(lastDay.getUTCFullYear() - 1, lastDay.getUTCMonth() + 2, 0));
  } else {
    return new Date(Date.UTC(lastDay.getUTCFullYear() - 1, lastDay.getUTCMonth() + 1, 0));
  }
};

const next12MonthsEndDate = (date: Date | string | number) => {
  const endDate = dateUTC(date);
  let yearEndDate = new Date(Date.UTC(
    endDate.getUTCFullYear() + 1,
    endDate.getUTCMonth(),
    endDate.getUTCDate()
  ));
  //Deal with February 29th (month 1 = Feb)
  if (endDate.getUTCMonth() === 1 && endDate.getUTCDate() === 29) {
    yearEndDate = new Date(endDate.getUTCFullYear() + 1, endDate.getUTCMonth(), 28);
  }
  return endTime(yearEndDate);
};

const smlyLatestFullMonthStartDate = (date: Date | string | number) => {
  const referenceDate = startTime(dateUTC(date));
  const lastDay = startTime(lastDayOfMonth(referenceDate));

  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  //Use previous month if we are part-way through the current month. If we're on the final day of the current month, use the end of the current month
  if (referenceDate.getTime() === lastDay.getTime()) {
    return firstDayOfMonth(addYears(midRefMonth, -1));
  } else {
    return firstDayOfMonth(addMonths(addYears(midRefMonth, -1), -1));
  }
};

const smlyLatestFullMonthEndDate = (date: Date | string | number) => {
  const referenceDate = endTime(dateUTC(date));
  const lastDay = endTime(lastDayOfMonth(referenceDate));
  
  const midRefMonth = new Date(Date.UTC(referenceDate.getUTCFullYear(), referenceDate.getUTCMonth(), 15));

  //Use previous month if we are part-way through the current month. If we're on the final day of the current month, use the end of the current month
  if (referenceDate.getTime() === lastDay.getTime()) {
    return lastDayOfMonth(addYears(midRefMonth, -1));
  } else {
    return lastDayOfMonth(addMonths(addYears(midRefMonth, -1), -1));
  }
};

const monthName = (date: Date | string | number, shortName: boolean = false) => {
  const endDate = dateUTC(date);
  const monthLength = (shortName ? "short" : "long");
  
  return endDate.toLocaleString("en-gb", { month: monthLength, timeZone: 'UTC' });
};

const datesToTime = (datesArray: Date[] | string[] | number[]): number[] => {
  try {
    return datesArray.map((d: Date | string | number) => dateUTC(d).getTime());
  } catch (err) {
    return [0];
  }
};

const dateUtils = {
  dateUTC,
  addDays,
  addMonths,
  addYears,
  firstDayOfMonth,
  lastDayOfMonth,
  startTime,
  endTime,
  weekToDateStartDate,
  previousWeekToDateStartDate,
  monthToDateStartDate,
  financialYearStartDate,
  financialYearEndDate,
  previousFinancialYearEndDate,
  previousFinancialYearStartDate,
  previousFinancialYearToDateEndDate,
  latestFullMonthStartDate,
  latestFullMonthEndDate,
  monthBeforeLatestFullMonthStartDate,
  monthBeforeLatestFullMonthEndDate,
  threeYearsAgoStartDate,
  twelveMonthsBeforePriorTwelveMonthsEndDate,
  twelveMonthsBeforePriorTwelveMonthsStartDate,
  priorTwelveMonthsFromCurrentMonthStartDate,
  priorTwelveMonthsStartDate,
  priorTwelveMonthsFromPreviousMonthStartDate,
  nextDay,
  previousFinancialYTGStartDate,
  nextWeekEndDate,
  nextFullMonthStartDate,
  nextFullMonthEndDate,
  nextFullMonthPreviousYearStartDate,
  nextFullMonthPreviousYearEndDate,
  next12MonthsEndDate,
  smlyLatestFullMonthStartDate,
  smlyLatestFullMonthEndDate,
  monthName,
  datesToTime,
};

export default dateUtils;
