import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const StoreSizeSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            How is your {store?.name} store performing relative to its size - and how does this compare
            to {comparator?.name}? Discover where any changes need to be made.
        </>
    );
};

export default StoreSizeSubtitle;
