import { combineReducers } from "redux";

import types from "./types";

const storeVsComparatorRankedLastTwelveMonthsGrowthEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: [],
    rank: 0,
    denominator: 0
};

const storeVsComparatorRankedLastTwelveMonthsGrowth = (state = storeVsComparatorRankedLastTwelveMonthsGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: [],
                rank: 0,
                denominator: 0
            };
        case types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator,
                rank: action.payload.rank,
                denominator: action.payload.denominator
            };
        case types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: [],
                rank: 0,
                denominator: 0
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    storeVsComparatorRankedLastTwelveMonthsGrowth
});

export default reducer;
