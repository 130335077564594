import React from "react";
import { Grid } from "@material-ui/core";

import SalesPerformanceVsBudget from "./SalesPerformanceVsBudget";
import SalesYTDPlusSalesYTGVsBudget from "./SalesYTDPlusSalesYTGVsBudget";

const RevenueVsBudget = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SalesPerformanceVsBudget />
            </Grid>
            <Grid item xs={12}>
                <SalesYTDPlusSalesYTGVsBudget />
            </Grid>
        </Grid>
    );
};

export default RevenueVsBudget;
