import React from "react";
import { NavLink } from "react-router-dom";

import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography
} from "@material-ui/core";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { withStyles } from "@material-ui/core/styles";

import { useInsights } from "pages/customer/insights/Insights";
import { useTools } from "pages/customer/tools/Tools";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";

const StyledAppBar = withStyles(() => ({
    root: {
        top: "auto",
        bottom: 0
    }
}))(AppBar);

const StyledPopover = withStyles(theme => ({
    paper: {
        maxWidth: "100%",
        width: "100%",
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Popover);

const StyledListItem = withStyles(theme => ({
    root: {
        "&.active": {
            backgroundColor: theme.palette.primary.main
        },
        "&.Mui-disabled": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.light
        }
    }
}))(ListItem);

const StyledBottomNavigation = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.drawer
    }
}))(BottomNavigation);

const StyledBottomNavigationAction = withStyles(theme => ({
    root: {
        "&.active": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    }
}))(BottomNavigationAction);

const MobileNavigation: React.FC = () => {
    const appBarRef = React.useRef(null);
    const [insightsOpen, setInsightsOpen] = React.useState(false);
    const insights = useInsights();
    const [toolsOpen, setToolsOpen] = React.useState(false);
    const tools = useTools();
    const userInfo = useAppSelector(selectUserInfo);
    const isDataProvided = userInfo.isDataProvided;

    const handleInsightsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setInsightsOpen(true);
    };

    const handleInsightsClose = () => {
        setInsightsOpen(false);
    };

    const handleToolsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setToolsOpen(true);
    };

    const handleToolsClose = () => {
        setToolsOpen(false);
    };

    const getUnavailableMessage = (isAvailable: boolean, isAvailableOnMobile: boolean) => {
        if (!isAvailable) {
            return "Coming soon";
        }
        if (!isAvailableOnMobile) {
            return "Coming soon on mobile";
        }
        if (!isDataProvided) {
            return "Upload data to access";
        }
        return "";
    };

    return (
        <StyledAppBar component="nav" ref={appBarRef}>
            <StyledPopover
                anchorEl={appBarRef.current}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                marginThreshold={0}
                elevation={0}
                open={insightsOpen}
                onClose={handleInsightsClose}
            >
                <List disablePadding>
                    {insights.map((insight, index) =>
                        <StyledListItem
                            key={`insight_${index}`}
                            button
                            // @ts-ignore
                            component={NavLink}
                            to={insight.url}
                            onClick={handleInsightsClose}
                            disabled={!isDataProvided || !insight.isAvailable || !insight.isAvailableOnMobile}
                        >
                            <ListItemIcon>
                                {insight.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={insight.name}
                                primaryTypographyProps={{
                                    variant: "body1",
                                    component: "div"
                                }}
                            />
                            {(!isDataProvided || !insight.isAvailable || !insight.isAvailableOnMobile) && (
                                <>
                                    <LockRoundedIcon fontSize="small" />
                                    &nbsp;
                                    <Typography variant="body2" component="div">
                                        {getUnavailableMessage(insight.isAvailable, insight.isAvailableOnMobile)}
                                    </Typography>
                                </>
                            )}
                        </StyledListItem>
                    )}
                </List>
            </StyledPopover>
            <StyledPopover
                anchorEl={appBarRef.current}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                marginThreshold={0}
                elevation={0}
                open={toolsOpen}
                onClose={handleToolsClose}
            >
                <List disablePadding>
                    {tools.map((tool, index) =>
                        <StyledListItem
                            key={`tool_${index}`}
                            button
                            // @ts-ignore
                            component={NavLink}
                            to={tool.url}
                            onClick={handleToolsClose}
                            disabled={!isDataProvided || !tool.isAvailable || !tool.isAvailableOnMobile}
                        >
                            <ListItemIcon>
                                {tool.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={tool.name}
                                primaryTypographyProps={{
                                    variant: "body1",
                                    component: "div"
                                }}
                            />
                            {(!isDataProvided || !tool.isAvailable || !tool.isAvailableOnMobile) && (
                                <>
                                    <LockRoundedIcon fontSize="small" />
                                    &nbsp;
                                    <Typography variant="body2" component="div">
                                        {getUnavailableMessage(tool.isAvailable, tool.isAvailableOnMobile)}
                                    </Typography>
                                </>
                            )}
                        </StyledListItem>
                    )}
                </List>
            </StyledPopover>
            <StyledBottomNavigation showLabels>
                <StyledBottomNavigationAction
                    label="Dashboard"
                    icon={<DashboardRoundedIcon />}
                    // @ts-ignore
                    component={NavLink}
                    to="/dashboard"
                />
                <StyledBottomNavigationAction
                    label="Insights"
                    icon={<BarChartRoundedIcon />}
                    onClick={handleInsightsClick}
                    // @ts-ignore
                    component={NavLink}
                    to="/insights"
                />
                <StyledBottomNavigationAction
                    label="Tools"
                    icon={<BuildRoundedIcon />}
                    onClick={handleToolsClick}
                    // @ts-ignore
                    component={NavLink}
                    to="/tools"
                />
                <StyledBottomNavigationAction
                    label="Account"
                    icon={<PersonRoundedIcon />}
                    // @ts-ignore
                    component={NavLink}
                    to="/account"
                />
                <StyledBottomNavigationAction
                    label="Help"
                    icon={<HelpRoundedIcon />}
                    // @ts-ignore
                    component={NavLink}
                    to="/help"
                />
            </StyledBottomNavigation>
        </StyledAppBar>
    );
};

export default MobileNavigation;
