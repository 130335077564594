import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const Ethnicity: React.FC = () => {
    const title = "Ethnicity (Diversity)";
    const categories = [
        "Arab",
        "Asian - Other",
        "Bangladeshi",
        "Black - African",
        "Black - Caribbean",
        "Black - Other",
        "Chinese",
        "Indian",
        "Mixed ethnicity",
        "Other",
        "Pakistani",
        "White"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.ethnicity;
    const retailCentreValues = retailCentre ? [
        retailCentre.arab,
        retailCentre.asianOther,
        retailCentre.bangladeshi,
        retailCentre.blackAfrican,
        retailCentre.blackCaribbean,
        retailCentre.blackOther,
        retailCentre.chinese,
        retailCentre.indian,
        retailCentre.mixed,
        retailCentre.other,
        retailCentre.pakistani,
        retailCentre.white
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.ethnicity;
    const benchmarkValues = benchmark ? [
        benchmark.arab,
        benchmark.asianOther,
        benchmark.bangladeshi,
        benchmark.blackAfrican,
        benchmark.blackCaribbean,
        benchmark.blackOther,
        benchmark.chinese,
        benchmark.indian,
        benchmark.mixed,
        benchmark.other,
        benchmark.pakistani,
        benchmark.white
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default Ethnicity;
