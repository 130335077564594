import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Category {
    id: string,
    name: string,
    questions: Question[]
}

interface Question {
    id: string,
    question: string,
    answer: string
}

interface FrequentlyAskedQuestionsState {
    categories: Category[]
}

const initialState: FrequentlyAskedQuestionsState = {
    categories: []
};

const frequentlyAskedQuestionsSlice = createSlice({
    name: "landing/frequentlyAskedQuestions",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getCategoriesWithQuestions.pending, (state: FrequentlyAskedQuestionsState) => {
            state.categories = initialState.categories;
        });
        builder.addCase(getCategoriesWithQuestions.rejected, (state: FrequentlyAskedQuestionsState) => {
            state.categories = initialState.categories;
        });
        builder.addCase(getCategoriesWithQuestions.fulfilled, (state: FrequentlyAskedQuestionsState, action: PayloadAction<Category[]>) => {
            state.categories = action.payload;
        });
    }
});

export const getCategoriesWithQuestions = createAppAsyncThunk(
    "landing/frequentlyAskedQuestions/getCategoriesWithQuestions",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/landing/frequently-asked-questions"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.categories;
        }

        thunkAPI.dispatch(notifyError("Error loading frequently asked questions."));
        return thunkAPI.rejectWithValue(null);
    }
);

export const selectCategoriesWithQuestions = (state: RootState) => {
    return state.landing.frequentlyAskedQuestions.categories;
};

export default frequentlyAskedQuestionsSlice;
