import { createSelector } from "reselect";

const forecastSalesWOW = (state) => {
    return state.customer.insights.performance.forecast.forecastRevenue.forecastSalesWoW;
};

const forecastSalesMOM = (state) => {
    return state.customer.insights.performance.forecast.forecastRevenue.forecastSalesMOM;
};

const forecastSalesYOY = (state) => {
    return state.customer.insights.performance.forecast.forecastRevenue.forecastSalesYOY;
};

const salesHistoryForecast = (state) => {
    return state.customer.insights.performance.forecast.forecastRevenue.salesHistoryForecast;
};

const monthlyForecast = (state) => {
    return state.customer.insights.performance.forecast.forecastRevenue.monthlyForecast;
};

const forecastCompanySalesTrend = createSelector(
    monthlyForecast,
    (monthlyForecast) => {
        const rag = {
            loading: false,
            error: false,
            id: "forecast-company-sales-trend",
            label: "Forecast company sales trend",
            status: "info",
            value: ""
        };
        if (monthlyForecast.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (monthlyForecast.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const forecastSalesData = monthlyForecast.values;
        if (forecastSalesData.length === 0) {
            return rag;
        }

        const lastValue = forecastSalesData[forecastSalesData.length - 1].sales;
        const firstValue = forecastSalesData[0].sales;
        const valuesRatio = lastValue / firstValue;
        const monthsInRange = forecastSalesData.length - 1;

        let cmgr = 0;
        if (firstValue === 0 && lastValue > 0) {
            cmgr = 0.6;
        } else {
            //CMGR calculation:
            cmgr = (Math.pow(valuesRatio, (1 / monthsInRange)) - 1) * 100;
        }

        //RAG indicator:
        if (cmgr >= 0.5) {
            rag.status = "success";
            rag.value = "Overall forecast sales are trending upwards";
        } else if (cmgr >= 0) {
            rag.status = "warning";
            rag.value = "Overall forecast sales is stagnant";
        } else {
            rag.status = "error";
            rag.value = "Overall forecast sales are trending downwards";
        }
        return rag;
    }
);

const selectors = {
    forecastSalesWOW,
    forecastSalesMOM,
    forecastSalesYOY,
    salesHistoryForecast,
    forecastCompanySalesTrend
};

export default selectors;
