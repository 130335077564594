import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { mean } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import {
    selectCatchmentAccountIds,
    selectComparatorsByChapter,
    selectPinnedLocation,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { RootState } from "store";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import { loadSpendCategories, SpendCategory } from "./spendCategory";
import { loadSpendLevels, SpendLevel } from "./spendLevel";

interface LoadSpendResponse {
    spendLevels: SpendLevel[],
    spendCategories: SpendCategory[]
}

export enum SpendByMarketCategorySortField {
    MarketCategory,
    WeightedSpend,
    SpendPerHead
}

interface SpendByMarketCategorySort {
    field: SpendByMarketCategorySortField,
    direction: SortDirection
}

export enum SpendByCustomerProfileSortField {
    CustomerProfile,
    WeightedSpend,
    SpendPerHead
}

interface SpendByCustomerProfileSort {
    field: SpendByCustomerProfileSortField,
    direction: SortDirection
}

interface SpendState {
    isLoading: boolean,
    hasErrors: boolean,
    spendLevels: SpendLevel[],
    spendCategories: SpendCategory[],
    spendByMarketCategorySort: SpendByMarketCategorySort,
    spendByCustomerProfileSort: SpendByCustomerProfileSort
}

const initialState: SpendState = {
    isLoading: false,
    hasErrors: false,
    spendLevels: [],
    spendCategories: [],
    spendByMarketCategorySort: {
        field: SpendByMarketCategorySortField.MarketCategory,
        direction: SortDirection.ASC
    },
    spendByCustomerProfileSort: {
        field: SpendByCustomerProfileSortField.CustomerProfile,
        direction: SortDirection.ASC
    }
};

const spendSlice = createSlice({
    name: "customer/tools/location/spend",
    initialState,
    reducers: {
        clearSpendLevels: (state) => {
            state.spendLevels = initialState.spendLevels;
        },
        clearSpendCategories: (state) => {
            state.spendCategories = initialState.spendCategories;
        },
        toggleSpendCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const spendCategoryName = action.payload;
            state.spendCategories.find(spendCategory => spendCategory.name === spendCategoryName)?.toggleIsSelected();
        },
        chooseAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(true));
        },
        deselectAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(false));
        },
        setSpendByMarketCategorySort: (state, action: PayloadAction<SpendByMarketCategorySort>) => {
            state.spendByMarketCategorySort = action.payload;
        },
        clearSpendByMarketCategorySort: (state) => {
            state.spendByMarketCategorySort = initialState.spendByMarketCategorySort;
        },
        setSpendByCustomerProfileSort: (state, action: PayloadAction<SpendByCustomerProfileSort>) => {
            state.spendByCustomerProfileSort = action.payload;
        },
        clearSpendByCustomerProfileSort: (state) => {
            state.spendByCustomerProfileSort = initialState.spendByCustomerProfileSort;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadSpend.pending, (state: SpendState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadSpend.rejected, (state: SpendState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.spendLevels = initialState.spendLevels;
            state.spendCategories = initialState.spendCategories;
        });
        builder.addCase(loadSpend.fulfilled, (state: SpendState, action: PayloadAction<LoadSpendResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.spendLevels = action.payload.spendLevels;
            state.spendCategories = action.payload.spendCategories;
        });
    }
});

export const {
    chooseAllSpendCategories,
    deselectAllSpendCategories,
    toggleSpendCategoryIsSelected,
    setSpendByMarketCategorySort,
    setSpendByCustomerProfileSort
} = spendSlice.actions;

export const loadSpend = createAppAsyncThunk(
    "customer/tools/location/spend/loadSpend",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const retailCentreId = selectPinnedLocation(state)?.retailCentre.id;
            const comparatorRetailCentreId = selectComparatorsByChapter(state)?.areaHealth?.retailCentreId;
            const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;
            const catchmentAccountIds = selectCatchmentAccountIds(state);

            const retailCentreIds = [];
            if (retailCentreId) {
                retailCentreIds.push(retailCentreId);
            }
            if (comparatorRetailCentreId) {
                retailCentreIds.push(comparatorRetailCentreId);
            }

            const spendCategories = await thunkAPI.dispatch(loadSpendCategories());
            const spendLevels = await thunkAPI.dispatch(loadSpendLevels(retailCentreId, targetStoreCategoryId, spendCategories, catchmentAccountIds.scenario));
            const loadSpendResponse: LoadSpendResponse = {
                spendLevels,
                spendCategories
            };
            return loadSpendResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Spend.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearSpend = (): AppThunk => (dispatch) => {
    dispatch(spendSlice.actions.clearSpendLevels());
    dispatch(spendSlice.actions.clearSpendCategories());
    dispatch(spendSlice.actions.clearSpendByMarketCategorySort());
    dispatch(spendSlice.actions.clearSpendByCustomerProfileSort());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.spend.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.spend.hasErrors;
};

export const selectSpendLevels = (state: RootState) => {
    return state.customer.tools.location.spend.spendLevels;
};

export const selectSpendCategories = (state: RootState) => {
    return state.customer.tools.location.spend.spendCategories;
};

export const selectSpendByMarketCategorySort = (state: RootState) => {
    return state.customer.tools.location.spend.spendByMarketCategorySort;
};

export const selectSpendByCustomerProfileSort = (state: RootState) => {
    return state.customer.tools.location.spend.spendByCustomerProfileSort;
};

export const selectIsFilterModified = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendCategories,
    (isLoading, hasErrors, spendCategories) => {
        if (isLoading || hasErrors) {
            return false;
        }
        return spendCategories.some(spendCategory => !spendCategory.isSelected());
    }
);

export const selectWeightedSpend = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendLevels,
    selectSpendCategories,
    (isLoading, hasErrors, spendLevels, spendCategories) => {
        if (isLoading || hasErrors) {
            return 0;
        }

        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);

        return spendLevels
            .filter(spendLevel => selectedSpendCategoriesNames.includes(spendLevel.categoryName))
            .map(spendLevel => spendLevel.weightedSpend)
            .reduce((accumulator: number, weightedSpend) => {
                accumulator += weightedSpend;
                return accumulator;
            }, 0);
    }
);

export const selectSpendPerHead = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendLevels,
    selectSpendCategories,
    (isLoading, hasErrors, spendLevels, spendCategories) => {
        if (isLoading || hasErrors) {
            return 0;
        }

        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);

        const spendPerHead = selectedSpendCategories.length === 0 ? 0 : _(spendLevels)
            .filter(spendLevel => selectedSpendCategoriesNames.includes(spendLevel.categoryName))
            .groupBy(spendLevel => spendLevel.outputAreaCode)
            .map(group => (
                group.reduce((accumulator, spendLevel) => accumulator + spendLevel.spendPerHead, 0)
            ))
            .mean();

        return spendPerHead;
    }
);

export const selectSpendByMarketCategory = createSelector(
    selectSpendLevels,
    selectSpendCategories,
    selectSpendByMarketCategorySort,
    (spendLevels, spendCategories, sort) => {
        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);
        return _(spendLevels)
            .filter(spendLevel => selectedSpendCategoriesNames.includes(spendLevel.categoryName))
            .groupBy(spendLevel => spendLevel.categoryName)
            .map((group, key) => ({
                categoryName: key,
                weightedSpend: group.reduce((accumulator, spendLevel) => accumulator + spendLevel.weightedSpend, 0),
                spendPerHead: group.length !== 0 ? mean(group.map(spendLevel => spendLevel.spendPerHead)) as number : 0
            }))
            .sort((spendA, spendB) => {
                switch (sort.field) {
                    case SpendByMarketCategorySortField.MarketCategory:
                        return stringSortExpression(spendA.categoryName, spendB.categoryName, sort.direction);
                    case SpendByMarketCategorySortField.WeightedSpend:
                        return numberSortExpression(spendA.weightedSpend, spendB.weightedSpend, sort.direction);
                    case SpendByMarketCategorySortField.SpendPerHead:
                        return numberSortExpression(spendA.spendPerHead, spendB.spendPerHead, sort.direction);
                    default:
                        return stringSortExpression(spendA.categoryName, spendB.categoryName, SortDirection.ASC);
                }
            })
            .value();
    }
);

export const selectSpendByCustomerProfile = createSelector(
    selectSpendLevels,
    selectSpendCategories,
    selectSpendByCustomerProfileSort,
    (spendLevels, spendCategories, sort) => {
        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);
        return _(spendLevels)
            .filter(spendLevel => selectedSpendCategoriesNames.includes(spendLevel.categoryName))
            .groupBy(spendLevel => spendLevel.customerProfile)
            .map((group, key) => ({
                customerProfile: key,
                weightedSpend: group.reduce((accumulator, spendLevel) => accumulator + spendLevel.weightedSpend, 0),
                spendPerHead: group.length !== 0 ? mean(group.map(spendLevel => spendLevel.spendPerHead)) as number : 0
            }))
            .sort((spendA, spendB) => {
                switch (sort.field) {
                    case SpendByCustomerProfileSortField.CustomerProfile:
                        return stringSortExpression(spendA.customerProfile, spendB.customerProfile, sort.direction);
                    case SpendByCustomerProfileSortField.WeightedSpend:
                        return numberSortExpression(spendA.weightedSpend, spendB.weightedSpend, sort.direction);
                    case SpendByCustomerProfileSortField.SpendPerHead:
                        return numberSortExpression(spendA.spendPerHead, spendB.spendPerHead, sort.direction);
                    default:
                        return stringSortExpression(spendA.customerProfile, spendB.customerProfile, SortDirection.ASC);
                }
            })
            .value();
    }
);

export const selectSpendByOutputArea = createSelector(
    selectSpendLevels,
    selectSpendCategories,
    (spendLevels, spendCategories) => {
        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);
        return _(spendLevels)
            .filter(spendLevel => selectedSpendCategoriesNames.includes(spendLevel.categoryName))
            .groupBy(spendLevel => spendLevel.outputAreaCode)
            .map((group, key) => ({
                outputAreaCode: key,
                values: group
                    .reduce((accumulator: { weightedSpend: number, spendPerHead: number }, spendLevel) => {
                        accumulator.weightedSpend += spendLevel.weightedSpend;
                        accumulator.spendPerHead += spendLevel.spendPerHead;
                        return accumulator;
                    }, {
                        weightedSpend: 0,
                        spendPerHead: 0
                    })
            }))
            .map(spendByCategory => ({
                outputAreaCode: spendByCategory.outputAreaCode,
                weightedSpend: spendByCategory.values.weightedSpend,
                spendPerHead: spendByCategory.values.spendPerHead
            }))
            .value();
    }
);

export default spendSlice;
