import React from "react";
import _ from "lodash";

import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography
} from "@material-ui/core";
import LayersOutlinedIcon from "@material-ui/icons/LayersOutlined";
import { withStyles } from "@material-ui/core/styles";

import {
    selectIsRetailCentresVisibilityModified,
    selectRetailCentresVisibility,
    selectTarget,
    toggleShowRetailCentres,
    toggleShowNonRetailCentres,
    selectShowExistingStores,
    selectShowExistingStoresModified,
    toggleShowExistingStores
} from "modules/customer/tools/location/locationSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledAvatar = withStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        color: (props: any) => props["data-disabled"]
            ? theme.palette.text.disabled
            // @ts-ignore
            : props["data-is-modified"] ? theme.palette.tertiary.main : theme.palette.common.white,
        width: theme.spacing(3.7),
        height: theme.spacing(3.6),
        right: 0,
        bottom: theme.spacing(15.5),
        margin: theme.spacing(2),
        borderTopLeftRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        borderTopRightRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        zIndex: theme.zIndex.drawer,
        cursor: "pointer"
    }
}))(Avatar);

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        width: theme.spacing(23),
        right: 0,
        bottom: theme.spacing(19.1),
        margin: theme.spacing(2),
        borderBottomRightRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        zIndex: theme.zIndex.drawer
    }
}))(Card);

const StyledCardHeader = withStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:first-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))(CardHeader);

const StyledCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))(CardContent);

const StyledFormControlLabel = withStyles(theme => ({
    root: {
        marginRight: 0
    }
}))(FormControlLabel);

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

const RetailCentresVisibilityFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const target = useAppSelector(selectTarget);
    const retailCentresVisibility = useAppSelector(selectRetailCentresVisibility);
    const isRetailCentresVisibilityModified = useAppSelector(selectIsRetailCentresVisibilityModified);
    const showExistingStores = useAppSelector(selectShowExistingStores);
    const isShowExistingStoresModified = useAppSelector(selectShowExistingStoresModified);
    const [show, setShow] = React.useState(false);
    const [currentShowRetailCentres, setCurrentShowRetailCentres] = React.useState(retailCentresVisibility.showRetailCentres);
    const [currentShowNonRetailCentres, setCurrentShowNonRetailCentres] = React.useState(retailCentresVisibility.showNonRetailCentres);
    const [currentShowExistingStores, setCurrentShowExistingStores] = React.useState(showExistingStores);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (target) {
            setShow((prevShow) => !prevShow);
        }
    };

    const toggleShowRetailCentresDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(toggleShowRetailCentres()), 100);
    }, [dispatch]);

    const toggleShowNonRetailCentresDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(toggleShowNonRetailCentres()), 100);
    }, [dispatch]);

    const toggleShowExistingStoresDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(toggleShowExistingStores()), 100);
    }, [dispatch]);

    const handleRetailCentresChange = () => {
        setCurrentShowRetailCentres(prev => !prev);
        toggleShowRetailCentresDelayed();
    };

    const handleNonRetailCentresChange = () => {
        setCurrentShowNonRetailCentres(prev => !prev);
        toggleShowNonRetailCentresDelayed();
    };

    const handleShowExistingStoresChange = () => {
        setCurrentShowExistingStores(prev => !prev);
        toggleShowExistingStoresDelayed();
    };

    const filterIsModified = isRetailCentresVisibilityModified || isShowExistingStoresModified;

    return (
        <>
            <StyledAvatar
                variant="rounded"
                onClick={handleClick}
                data-show={show}
                data-is-modified={filterIsModified}
                data-disabled={!target}
                data-cy="btn-filter"
            >
                <LayersOutlinedIcon fontSize="small" />
            </StyledAvatar>
            {show && (
                <StyledCard elevation={0} data-show={show}>
                <StyledCardHeader title="Pins" titleTypographyProps={{ variant: "body2" }} />
                    <StyledCardContent>
                        <FormGroup>
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={currentShowExistingStores}
                                        onChange={handleShowExistingStoresChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle2" component="div">
                                        Existing stores
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </StyledCardContent>
                    <StyledCardHeader title="Area type" titleTypographyProps={{ variant: "body2" }} />
                    <StyledCardContent>
                        <FormGroup>
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={currentShowRetailCentres}
                                        onChange={handleRetailCentresChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle2" component="div">
                                        Retail centres
                                    </Typography>
                                }
                            />
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={currentShowNonRetailCentres}
                                        onChange={handleNonRetailCentresChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle2" component="div">
                                        Non-retail centres
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </StyledCardContent>
                </StyledCard>
            )}
        </>
    );
};

export default RetailCentresVisibilityFilter;
