import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";
import { selectCostReductionOpportunityByStore } from "modules/customer/insights/cost/costSlice";

interface FiltersVisibility {
    isVisible: boolean
}

export enum FilterStep {
    SelectStore
}

export interface StoreSearch {
    name: string
}

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateStore?: CostReductionOpportunityByStore,
    storeSearch: StoreSearch
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: false
    },
    activeStep: FilterStep.SelectStore,
    candidateStore: undefined,
    storeSearch: {
        name: ""
    }
};

const filtersSlice = createSlice({
    name: "customer/insights/portfolio/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectStore;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setCandidateStore: (state, action: PayloadAction<CostReductionOpportunityByStore>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        },
        setStoreSearch: (state, action: PayloadAction<StoreSearch>) => {
            state.storeSearch = action.payload;
        },
        clearStoreSearch: (state) => {
            state.storeSearch = initialState.storeSearch;
        }
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    clearActiveStep,
    setCandidateStore,
    clearCandidateStore,
    setStoreSearch,
    clearStoreSearch
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearActiveStep());
    dispatch(filtersSlice.actions.clearCandidateStore());
    dispatch(filtersSlice.actions.clearStoreSearch());
};

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.insights.cost.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.insights.cost.filters.activeStep;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.insights.cost.filters.candidateStore;
};

export const selectStoreSearch = (state: RootState) => {
    return state.customer.insights.cost.filters.storeSearch;
};

export const selectFilteredStores = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    selectStoreSearch,
    (stores, search) => {
        const searchString = search.name.toLowerCase();
        const filteredStores = stores.data.filter(store =>
            (!searchString || store?.storeName?.toLowerCase().includes(searchString)));
        return filteredStores;
    }
);

export default filtersSlice;
