import React from "react";

import { Grid, Typography } from "@material-ui/core";

import Page from "components/admin/Page";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const Home: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);

    return (
        <Page dataCy="pages-admin-home">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        KPMG Admin - {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Home
                    </Typography>
                </Grid>
            </Grid>
        </Page>
    );
};

export default Home;
