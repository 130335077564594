import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useColourPalette from "components/visuals/useColourPalette";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";
import { abs } from "mathjs";

import Waterfall from "components/visuals/Waterfall";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/profit/netProfit";

const SalesToProfit = () => {
    const colourPalette = useColourPalette();
    const selectedStoreName = useAppSelector(selectStore).name;
    const selectedComparatorName = useAppSelector(selectComparator).name;
    const salesToProfit = useSelector(state => selectors.salesToProfit(state));
    const { loading, error, selectedStore, comparator, netProfitFlag } = salesToProfit;

    const upDownColour = (value) => {
        return (value < 0) ? colourPalette.waterfall.negative : colourPalette.waterfall.positive;
    };

    const upDownNeutralColour = (value) => {
        if (value !== 0) {
            return upDownColour(value);
        } else {
            return "";
        }
    };

    const netOrGrossString = netProfitFlag ? "Net" : "Gross";

    const waterfallData = [
        {
            name: "Total revenue ",
            y: selectedStore.sales,
            color: colourPalette.waterfall.neutral,
            custom: {
                comparatorValue: comparator.sales
            }
        },
        {
            name: "COGS",
            y: -selectedStore.cogs,
            color: upDownColour(-selectedStore.cogs),
            custom: {
                comparatorValue: -comparator.cogs
            }
        },
        {
            name: "Payroll costs",
            y: -selectedStore.staffCost,
            color: upDownColour(-selectedStore.staffCost),
            custom: {
                comparatorValue: -comparator.staffCost
            }
        },
        {
            name: "Property costs",
            y: -selectedStore.propertyCost,
            color: upDownColour(-selectedStore.propertyCost),
            custom: {
                comparatorValue: -comparator.propertyCost
            }
        },
        {
            name: "Other costs",
            y: -selectedStore.otherCost,
            color: upDownColour(-selectedStore.otherCost),
            custom: {
                comparatorValue: -comparator.otherCost
            }
        },
        {
            name: `${netOrGrossString} profit`,
            y: selectedStore.netProfit,
            color: colourPalette.waterfall.neutral,
            isSum: true,
            custom: {
                comparatorValue: comparator.netProfit
            }
        }
    ];

    const waterfallDataFiltered = waterfallData.filter(item => item.y !== 0);

    const options = {
        xAxis: {
            type: "category"
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:13px;font-weight:bold;font-family:Open Sans\">{point.key}</span><table>",
            pointFormatter: function () {
                const point = this;
                let categoryFormatting = ["color:" + colourPalette.comparators[0], "color:" + colourPalette.comparators[1]];
                const comparatorValue = point.custom.comparatorValue;
                const variance = 100 * ((point.y - point.custom.comparatorValue) / abs(point.custom.comparatorValue));
                let valueFormattingArr = ["color:" + upDownNeutralColour(point.y), "color:" + upDownNeutralColour(comparatorValue), "color:" + upDownNeutralColour(variance)];
                return stringUtils.tooltipHTMLOld([selectedStoreName, selectedComparatorName, "Variance"], [numberFormatter.toGBP(point.y), numberFormatter.toGBP(comparatorValue), numberFormatter.toPercentage(variance)], categoryFormatting, valueFormattingArr);
            },
            footerFormat: "</table>",
            useHTML: true
        },
        series: [{
            name: "Sales to profit",
            data: waterfallDataFiltered
        }]
    };

    return (
        <Box data-cy="sales-to-profit">
            <Waterfall loading={loading} error={error} options={options} />
        </Box>
    );
};

export default SalesToProfit;
    
