import React from "react";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipBackgroundColour } from "components/SimpleTooltip";
import Spacer from "components/Spacer";

import { DatasetFile as DatasetFileObject } from "modules/customer/data/dataSlice";
import numberFormatter from "utils/numberFormatter";

interface DatasetFileProps {
    datasetFile?: DatasetFileObject
}

const DatasetFile: React.FC<DatasetFileProps> = (props) => {
    const { datasetFile } = props;
    const theme = useTheme();
    const fileSizeInBytes = datasetFile?.fileSizeInBytes ?? 0;
    const { fileSize, unit } = (() => {
        if (fileSizeInBytes >= 1024 * 1024) {
            return {
                fileSize: fileSizeInBytes / 1024 / 1024,
                unit: "MB"
            };
        }
        if (fileSizeInBytes >= 1024) {
            return {
                fileSize: fileSizeInBytes / 1024,
                unit: "KB"
            };
        }
        return {
            fileSize: fileSizeInBytes,
            unit: "Bytes"
        };
    })();

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            {datasetFile ? (
                // @ts-ignore
                <SimpleTooltip
                    backgroundColour={SimpleTooltipBackgroundColour.Quaternary}
                    noWrap
                    title={
                        <Box>
                            <Typography variant="subtitle2" component="div" align="center">
                                {datasetFile.fileName}
                            </Typography>
                            <Box display="flex" alignItems="center" paddingTop={2}>
                                <Typography variant="body2" component="div">
                                    File size&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Spacer />
                                <Typography variant="subtitle2" component="div">
                                    {numberFormatter.toDecimalPlaces(fileSize, 2, false)} {unit}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" paddingTop={1}>
                                <Typography variant="body2" component="div">
                                    Input&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Spacer />
                                <Typography variant="subtitle2" component="div">
                                    {datasetFile.uploadedAt.toLocaleString()}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" paddingTop={1}>
                                <Typography variant="body2" component="div">
                                    Input by&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Spacer />
                                <Typography variant="subtitle2" component="div">
                                    {datasetFile.uploadedBy}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" paddingTop={1}>
                                <Typography variant="body2" component="div">
                                    Id&nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>
                                <Spacer />
                                <Typography variant="subtitle2" component="div">
                                    {datasetFile.id}
                                </Typography>
                            </Box>
                        </Box>
                    }
                >
                    <Box>
                        <Typography variant="body1" component="div" noWrap gutterBottom>
                            Input {datasetFile.uploadedAt.toLocaleString()} by {datasetFile.uploadedBy}
                        </Typography>
                        <Box display="flex">
                            <Typography variant="body1" component="div" color="textSecondary" noWrap>
                                {datasetFile.fileName}
                            </Typography>
                            <Typography variant="body1" component="div" color="textSecondary">
                                &nbsp;&nbsp;{numberFormatter.toDecimalPlaces(fileSize, 2, false)}{unit}
                            </Typography>
                        </Box>
                    </Box>
                </SimpleTooltip>
            ) : (
                <Typography variant="subtitle1" component="div" noWrap style={{ color: theme.palette.text.disabled }}>
                    No data
                </Typography>
            )}
        </Box>
    );
};

export default DatasetFile;
