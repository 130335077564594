import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import EditCategory from "./EditCategory";
import DeleteCategory from "./DeleteCategory";
import MoveCategoryUp from "./MoveCategoryUp";
import MoveCategoryDown from "./MoveCategoryDown";
import Questions from "./Questions";

import { selectCategories } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const CategoriesTable = () => {
    const theme = useTheme();
    const categories = useAppSelector(selectCategories);

    if (categories.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Category
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Show on landing area
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Show on customer area
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((category) => (
                        <TableRow key={category.id}>
                            <StyledTableCell>
                                {category.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {category.showOnLandingArea ? (
                                    <CheckCircleRoundedIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                    <CancelRoundedIcon style={{ color: theme.palette.error.main }} />
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {category.showOnCustomerArea ? (
                                    <CheckCircleRoundedIcon style={{ color: theme.palette.success.main }} />
                                ) : (
                                    <CancelRoundedIcon style={{ color: theme.palette.error.main }} />
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <MoveCategoryUp categoryId={category.id} />
                                <MoveCategoryDown categoryId={category.id} />
                                <EditCategory categoryId={category.id} />
                                <Questions categoryId={category.id} />
                                <DeleteCategory categoryId={category.id} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default CategoriesTable;
