import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/catchment/customerProfiles";

const CatchmentAreaReach = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const catchmentAreaReach = useSelector(state => selectors.catchmentAreaReach(state));
    const { loading, error, value, percentageDifference } = catchmentAreaReach;
    const valueFormatted = value.toFixed(1);
    const label = `${selectedStore.name} catchment area reach (Km) vs ${selectedComparator.name}`;

    return (
        <Box width="100%" height="100%" data-cy="catchment-area-reach">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default CatchmentAreaReach;
