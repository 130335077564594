import { AppThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";

export class AffluenceMetrics {
    public readonly incomeBracket: string;
    public readonly homeOwnershipPercentage: number;
    public readonly occupancyRating: string;

    constructor(
        incomeBracket: string,
        homeOwnershipPercentage: number,
        occupancyRating: string,
    ) {
        this.incomeBracket = incomeBracket;
        this.homeOwnershipPercentage = homeOwnershipPercentage;
        this.occupancyRating = occupancyRating;
    }
}

export class AgeMetrics {
    public readonly age17To35Percentage: number;
    public readonly age36To55Percentage: number;
    public readonly age56PlusPercentage: number;

    constructor(
        age17To35Percentage: number,
        age36To55Percentage: number,
        age56PlusPercentage: number,
    ) {
        this.age17To35Percentage = age17To35Percentage;
        this.age36To55Percentage = age36To55Percentage;
        this.age56PlusPercentage = age56PlusPercentage;
    }
}

export class ChildrenMetrics {
    public readonly children0To5Percentage: number;
    public readonly children6To10Percentage: number;
    public readonly children11To16PlusPercentage: number;

    constructor(
        children0To5Percentage: number,
        children6To10Percentage: number,
        children11To16PlusPercentage: number,
    ) {
        this.children0To5Percentage = children0To5Percentage;
        this.children6To10Percentage = children6To10Percentage;
        this.children11To16PlusPercentage = children11To16PlusPercentage;
    }
}

export class DiversityMetrics {
    public readonly minorityEthnicPercentage: number;
    public readonly languageNotEnglishOrWelshPercentage: number;
    public readonly notBornInUkPercentage: number;

    constructor(
        minorityEthnicPercentage: number,
        languageNotEnglishOrWelshPercentage: number,
        notBornInUkPercentage: number,
    ) {
        this.minorityEthnicPercentage = minorityEthnicPercentage;
        this.languageNotEnglishOrWelshPercentage = languageNotEnglishOrWelshPercentage;
        this.notBornInUkPercentage = notBornInUkPercentage;
    }
}

export class UrbanicityMetrics {
    public readonly populationDensityBracket: string;
    public readonly livingInFlatPercentage: number;
    public readonly numberOfCars: string;

    constructor(
        populationDensityBracket: string,
        livingInFlatPercentage: number,
        numberOfCars: string,
    ) {
        this.populationDensityBracket = populationDensityBracket;
        this.livingInFlatPercentage = livingInFlatPercentage;
        this.numberOfCars = numberOfCars;
    }
}

export class DemographicsComponentMetrics {
    public readonly affluence: AffluenceMetrics;
    public readonly age: AgeMetrics;
    public readonly children: ChildrenMetrics;
    public readonly diversity: DiversityMetrics;
    public readonly urbanicity: UrbanicityMetrics;

    constructor(
        affluence: AffluenceMetrics,
        age: AgeMetrics,
        children: ChildrenMetrics,
        diversity: DiversityMetrics,
        urbanicty: UrbanicityMetrics,
    ) {
        this.affluence = affluence;
        this.age = age;
        this.children = children;
        this.diversity = diversity;
        this.urbanicity = urbanicty;
    }
}

export class AreaHealthComponentMetrics {
    public readonly netOpenings: number;

    constructor(
        netOpenings: number,
    ) {
        this.netOpenings = netOpenings;
    }
}

export class FootfallComponentMetrics {
    public readonly footfallLevel: number;

    constructor(
        footfallLevel: number,
    ) {
        this.footfallLevel = footfallLevel;
    }
}

export class ComponentMetricCentiles {
    public readonly storeCategoryId: number;
    public readonly centile: number;
    public readonly affluence: AffluenceMetrics;
    public readonly age: AgeMetrics;
    public readonly children: ChildrenMetrics;
    public readonly diversity: DiversityMetrics;
    public readonly urbanicity: UrbanicityMetrics;
    public readonly areaHealth: AreaHealthComponentMetrics;
    public readonly footfall: FootfallComponentMetrics;

    constructor(
        storeCategoryId: number,
        centile: number,
        affluence: AffluenceMetrics,
        age: AgeMetrics,
        children: ChildrenMetrics,
        diversity: DiversityMetrics,
        urbanicty: UrbanicityMetrics,
        areaHealth: AreaHealthComponentMetrics,
        footfall: FootfallComponentMetrics
    ) {
        this.storeCategoryId = storeCategoryId;
        this.centile = centile;
        this.affluence = affluence;
        this.age = age;
        this.children = children;
        this.diversity = diversity;
        this.urbanicity = urbanicty;
        this.areaHealth = areaHealth;
        this.footfall = footfall;
    }
}

export const loadComponentMetricCentiles = (targetStoreCategoryId: number | undefined, accountId: string, useMLCatchments: boolean): AppThunk<Promise<ComponentMetricCentiles[]>> => async (dispatch) => {
    if (!targetStoreCategoryId) {
        return [];
    }
    try {
        const catchmentAccountId = useMLCatchments ? accountId : "00000000-0000-0000-0000-000000000000";
        const query = {
            dimensions: [
                "LocationBenchmarkCentileMetrics.Centile",
                "LocationBenchmarkCentileMetrics.MedianIncomeBracket",
                "LocationBenchmarkCentileMetrics.MedianHomeOwnership_Percent",
                "LocationBenchmarkCentileMetrics.MedianOccupancyRating",
                "LocationBenchmarkCentileMetrics.Age17to35_Percent",
                "LocationBenchmarkCentileMetrics.Age36to55_Percent",
                "LocationBenchmarkCentileMetrics.Age56Plus_Percent",
                "LocationBenchmarkCentileMetrics.Children0to5_Percent",
                "LocationBenchmarkCentileMetrics.Children6to10_Percent",
                "LocationBenchmarkCentileMetrics.Children11to16_Percent",
                "LocationBenchmarkCentileMetrics.MedianMinorityEthnic_Percent",
                "LocationBenchmarkCentileMetrics.MedianLangNotEnglishOrWelsh_Percent",
                "LocationBenchmarkCentileMetrics.MedianNotBornInUK_Percent",
                "LocationBenchmarkCentileMetrics.MedianPopulationDensityBracket",
                "LocationBenchmarkCentileMetrics.MedianLivingInFlat_Percent",
                "LocationBenchmarkCentileMetrics.MedianNumberOfCars",
                "LocationBenchmarkCentileMetrics.MedianNetOpenings",
                "LocationBenchmarkCentileMetrics.MedianFootfallDensity"
            ],
            filters: [{
                member: "LocationBenchmarkCentileMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "LocationBenchmarkCentileMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => new ComponentMetricCentiles(
            targetStoreCategoryId,
            row["LocationBenchmarkCentileMetrics.Centile"],
            new AffluenceMetrics(
                row["LocationBenchmarkCentileMetrics.MedianIncomeBracket"],
                row["LocationBenchmarkCentileMetrics.MedianHomeOwnership_Percent"],
                row["LocationBenchmarkCentileMetrics.MedianOccupancyRating"]
            ),
            new AgeMetrics(
                row["LocationBenchmarkCentileMetrics.Age17to35_Percent"],
                row["LocationBenchmarkCentileMetrics.Age36to55_Percent"],
                row["LocationBenchmarkCentileMetrics.Age56Plus_Percent"]
            ),
            new ChildrenMetrics(
                row["LocationBenchmarkCentileMetrics.Children0to5_Percent"],
                row["LocationBenchmarkCentileMetrics.Children6to10_Percent"],
                row["LocationBenchmarkCentileMetrics.Children11to16_Percent"]
            ),
            new DiversityMetrics(
                row["LocationBenchmarkCentileMetrics.MedianMinorityEthnic_Percent"],
                row["LocationBenchmarkCentileMetrics.MedianLangNotEnglishOrWelsh_Percent"],
                row["LocationBenchmarkCentileMetrics.MedianNotBornInUK_Percent"]
            ),
            new UrbanicityMetrics(
                row["LocationBenchmarkCentileMetrics.MedianPopulationDensityBracket"],
                row["LocationBenchmarkCentileMetrics.MedianLivingInFlat_Percent"],
                row["LocationBenchmarkCentileMetrics.MedianNumberOfCars"]
            ),
            new AreaHealthComponentMetrics(
                row["LocationBenchmarkCentileMetrics.MedianNetOpenings"]
            ),
            new FootfallComponentMetrics(
                row["LocationBenchmarkCentileMetrics.MedianFootfallDensity"]
            )
        ));
    } catch (error) {
        dispatch(logError("Error loading ComponentMetricCentiles.", error));
        throw error;
    }
};
