import React from "react";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import {
    SharedPopulationByStoreSortField,
    selectSharedPopulationByStore,
    selectSharedPopulationByStoreSort,
    setSharedPopulationByStoreSort
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { SortDirection } from "utils/sortUtils";

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const SharedPopulationByStore: React.FC = () => {
    const sharedPopulationByStore = useAppSelector(selectSharedPopulationByStore);
    const dispatch = useAppDispatch();
    const sort = useAppSelector(selectSharedPopulationByStoreSort);

    const handleSortClick = (field: SharedPopulationByStoreSortField) => () => {
        let direction = SortDirection.ASC;
        if (sort.field === field) {
            direction = sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSort = { ...sort, field, direction };
        dispatch(setSharedPopulationByStoreSort(newSort));
    };

    return (
        <Box paddingTop={2} data-cy="shared-population-by-store">
            <Typography variant="h6" component="div">
                Shared population by store
            </Typography>
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sort.field === SharedPopulationByStoreSortField.Store}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedPopulationByStoreSortField.Store)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Store
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SharedPopulationByStoreSortField.StraightLineDistance}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedPopulationByStoreSortField.StraightLineDistance)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Straight line distance
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SharedPopulationByStoreSortField.SharedCustomers}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedPopulationByStoreSortField.SharedCustomers)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Shared customers
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sharedPopulationByStore.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {item.store}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.straightLineDistance, 1) + " Km"}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.sharedCustomers, 1)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default SharedPopulationByStore;
