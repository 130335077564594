import types from "./types";

const getStoreVsComparatorRankedGrossProfitRequest = () => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_REQUEST
});

const getStoreVsComparatorRankedGrossProfitSuccess = (store, comparator, rank, denominator) => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_SUCCESS,
    payload: {
        store,
        comparator,
        rank,
        denominator
    }
});

const getStoreVsComparatorRankedGrossProfitFailure = () => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_FAILURE
});

const actions = {
    getStoreVsComparatorRankedGrossProfitRequest,
    getStoreVsComparatorRankedGrossProfitSuccess,
    getStoreVsComparatorRankedGrossProfitFailure
};

export default actions;
