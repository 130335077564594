import React from "react";

import { Box, Button, Container, MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import { withStyles } from "@material-ui/core/styles";

import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import {
    addSavedLocation,
    clearSavedLocationForm,
    hideAddSavedLocation,
    selectSavedLocationForm,
    setSavedLocationForm
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledPaper = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(Paper);

const StyledCancelButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2)
    }
}))(Button);

const StyledSaveButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2)
    }
}))(Button);

const SaveLocation: React.FC = () => {
    const dispatch = useAppDispatch();
    const [hasRendered, setHasRendered] = React.useState(false);
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const form = useAppSelector(selectSavedLocationForm);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newForm = { ...form, name, errors: { ...form.errors, name: "" } };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notes = event.target.value;
        const newForm = { ...form, notes, errors: { ...form.errors, notes: "" } };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleIsPublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isPublic = Boolean(Number(event.target.value));
        const newForm = { ...form, isPublic };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleCancelClick = () => {
        dispatch(hideAddSavedLocation());
        dispatch(clearSavedLocationForm());
    };

    const handleSaveClick = () => {
        dispatch(addSavedLocation());
    };

    React.useEffect(() => {
        if (hasRendered) {
            return;
        }
        if (pinnedLocation) {
            setHasRendered(true);
            const newForm = {
                ...form,
                name: pinnedLocation.address.split(",", 1)[0],
                latitude: pinnedLocation.latitude,
                longitude: pinnedLocation.longitude,
                address: pinnedLocation.address,
                retailCentreId: pinnedLocation.retailCentre.id
            };
            dispatch(setSavedLocationForm(newForm));
        }
    }, [dispatch, hasRendered, pinnedLocation, form]);

    if (!pinnedLocation) {
        return null;
    }

    return (
        <StyledPaper elevation={0}>
            <Container maxWidth={false}>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <BookmarkRoundedIcon />
                    &nbsp;&nbsp;
                    <Typography variant="h6" component="div">
                        Save location
                    </Typography>
                </Box>
                <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    value={form.name}
                    onChange={handleNameChange}
                    error={!!form.errors.name}
                    helperText={form.errors.name}
                    required
                    fullWidth
                />
                <TextField
                    label="Notes"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={form.notes}
                    onChange={handleNotesChange}
                    error={!!form.errors.notes}
                    helperText={form.errors.notes}
                    fullWidth
                />
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <TextField
                        variant="outlined"
                        size="small"
                        color="secondary"
                        margin="normal"
                        select
                        value={Number(form.isPublic)}
                        onChange={handleIsPublicChange}
                        required
                        fullWidth
                    >
                        <MenuItem value={0}>Private</MenuItem>
                        <MenuItem value={1}>Public</MenuItem>
                    </TextField>
                    <StyledCancelButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={handleCancelClick}
                    >
                        Cancel
                    </StyledCancelButton>
                    <StyledSaveButton
                        variant="contained"
                        size="small"
                        color="secondary"
                        disableElevation
                        onClick={handleSaveClick}
                    >
                        Save
                    </StyledSaveButton>
                </Box>
            </Container>
        </StyledPaper>
    );
};

export default SaveLocation;
