import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { cubeLoad, setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import dateUtils from "utils/dateUtils";
import { DateTime } from "luxon";

interface OverviewSubchaptersIds {
    companyHealth: string,
    KeyPerformanceIndicators: string,
    recommendations: string
}

const overviewSubchaptersIdsEmpty: OverviewSubchaptersIds = {
    companyHealth: "",
    KeyPerformanceIndicators: "",
    recommendations: ""
};

interface SalesSubchaptersIds {
    revenueOverTime: string,
    revenueGrowthBreakdown: string,
    revenueGrowth: string,
    contributionGrowth: string,
    regionalGrowthBreakdown: string,
    productCategoryBreakdown: string
}

const salesSubchaptersIdsEmpty: SalesSubchaptersIds = {
    revenueOverTime: "",
    revenueGrowthBreakdown: "",
    revenueGrowth: "",
    contributionGrowth: "",
    regionalGrowthBreakdown: "",
    productCategoryBreakdown: ""
};

interface ForecastSubchaptersIds {
    forecastRevenue: string,
    forecastGrowthVsMarket: string,
    regionalBreakdown: string
}

const forecastSubchaptersIdsEmpty: ForecastSubchaptersIds = {
    forecastRevenue: "",
    forecastGrowthVsMarket: "",
    regionalBreakdown: ""
};

interface BudgetSubchaptersIds {
    revenueVsBudget: string,
    revenueVsBudgetOverTime: string,
    contributionVsBudget: string,
    storePerformanceAgainstBudget: string,
    budgetVsForecastRevenue: string,
    budgetedVsForecastContribution: string
}

const budgetSubchaptersIdsEmpty: BudgetSubchaptersIds = {
    revenueVsBudget: "",
    revenueVsBudgetOverTime: "",
    contributionVsBudget: "",
    storePerformanceAgainstBudget: "",
    budgetVsForecastRevenue: "",
    budgetedVsForecastContribution: ""
};

interface SubchaptersIds {
    overview: OverviewSubchaptersIds,
    sales: SalesSubchaptersIds,
    forecast: ForecastSubchaptersIds,
    budget: BudgetSubchaptersIds
}

const subchaptersIdsEmpty: SubchaptersIds = {
    overview: overviewSubchaptersIdsEmpty,
    sales: salesSubchaptersIdsEmpty,
    forecast: forecastSubchaptersIdsEmpty,
    budget: budgetSubchaptersIdsEmpty
};

interface PerformanceState {
    subchaptersIds: SubchaptersIds,
    referenceDate?: Date,
    fiscalYearStartDate?: Date
}

const initialState: PerformanceState = {
    subchaptersIds: subchaptersIdsEmpty
};

const performanceSlice = createSlice({
    name: "customer/insights/performance",
    initialState,
    reducers: {
        setSubchaptersIds: (state, action: PayloadAction<SubchaptersIds>) => {
            state.subchaptersIds = action.payload;
        },
        clearSubchaptersIds: (state) => {
            state.subchaptersIds = subchaptersIdsEmpty;
        },
        setReferenceDate: (state, action: PayloadAction<Date>) => {
            state.referenceDate = action.payload;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = undefined;
        },
        setFiscalYearStartDate: (state, action: PayloadAction<Date>) => {
            state.fiscalYearStartDate = action.payload;
        },
        clearFiscalYearStartDate: (state) => {
            state.fiscalYearStartDate = undefined;
        }
    }
});

export const { setSubchaptersIds } = performanceSlice.actions;

export const getPerformanceData = (): AppThunk => async (dispatch) => {
    dispatch(backdropOn());
    try {
        await dispatch(setupCube());
        const referenceDatePromise = dispatch(getReferenceDate());
        const fiscalYearStartDatePromise = dispatch(getFiscalYearStartDate());
        await Promise.all([referenceDatePromise, fiscalYearStartDatePromise]);
    } catch (error) {
        dispatch(notifyError("Error loading Performance."));
    } finally {
        dispatch(backdropOff());
    }
};

export const clearPerformanceData = (): AppThunk => async (dispatch) => {
    dispatch(performanceSlice.actions.clearReferenceDate());
    dispatch(performanceSlice.actions.clearFiscalYearStartDate());
};

const getReferenceDate = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Sales.SumLineValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const dateString = resultSet.rawData()[0]["D_Date.Date"];
        const dateUtc = dateUtils.dateUTC(dateString);
        const referenceDate = dateUtils.endTime(dateUtc);
        dispatch(performanceSlice.actions.setReferenceDate(referenceDate));
    } catch (error) {
        dispatch(logError("Error loading ReferenceDate.", error));
        throw error;
    }
};

const getFiscalYearStartDate = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            measures: [],
            dimensions: ["ClientRegistration.FYStart"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const dateString = resultSet.rawData()[0]["ClientRegistration.FYStart"];
        const dateUtc = dateUtils.dateUTC(dateString);
        const fiscalYearStartDate = dateUtils.endTime(dateUtc);
        dispatch(performanceSlice.actions.setFiscalYearStartDate(fiscalYearStartDate));
    } catch (error) {
        dispatch(logError("Error loading FiscalYearStartDate.", error));
        throw error;
    }
};

export const selectSubchaptersIds = (state: RootState) => {
    return state.customer.insights.performance.root.subchaptersIds;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.insights.performance.root.referenceDate;
};

export const selectReferenceDateNew = (state: RootState) => {
    const referenceDate = state.customer.insights.performance.root.referenceDate;
    if (!referenceDate) {
        return DateTime.fromMillis(0, { zone: "utc" });
    }
    return DateTime.fromJSDate(referenceDate, { zone: "utc" });
};

export const selectFiscalYearStartDate = (state: RootState) => {
    return state.customer.insights.performance.root.fiscalYearStartDate;
};

export const selectIsSetupComplete = createSelector(
    selectReferenceDate,
    selectFiscalYearStartDate,
    (referenceDate, fiscalYearStartDate) => {
        return !!referenceDate && !!fiscalYearStartDate;
    }
);

export default performanceSlice;
