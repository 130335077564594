import React from "react";

import { Avatar, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import DownloadIconSvg from "assets/images/download-icon.svg";

const StyledAvatar = withStyles(theme => ({
  root: {
      position: "absolute",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.common.white,
      width: theme.spacing(3.7),
      height: theme.spacing(3.6),
      right: theme.spacing(4.5),
      top: 0,
      margin: theme.spacing(2),
      zIndex: theme.zIndex.drawer,
      cursor: "pointer",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }
}))(Avatar);

interface DownloadButtonProps {
  data: object[];
  fileName?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ data, fileName }) => {
  const convertToCSV = (dataArray: Object[]) => {
    const header = Object.keys(dataArray[0]).join(',') + '\n';
    const csv = dataArray.map((row) => Object.values(row).join(',')).join('\n');
    return header + csv;
  };

  const downloadAsCSV = () => {
    const csvFileName = fileName ? fileName + '.csv' : 'map_data.csv';
    const csvContent = convertToCSV(data);
    const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const a = document.createElement('a');
    a.href = csvURL;
    a.setAttribute('download', csvFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(csvURL);
  };

  return (
      <StyledAvatar variant="rounded" onClick={downloadAsCSV} data-cy="btn-download">
        <Icon style={{textAlign: 'center'}}>
            <img src={DownloadIconSvg} alt="download map data" />
        </Icon>
      </StyledAvatar>
  );
};

export default DownloadButton;
