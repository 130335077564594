import { RetailCentre } from "modules/customer/tools/location/retailCentre";
import { Store } from "modules/customer/tools/location/store";

export class AnalogueStore {
    public readonly store: Store;
    public readonly retailCentre: RetailCentre;
    public readonly scenarioCatchment: boolean;
    public readonly similarityScore: number;
    public readonly affluenceDifference: number;
    public readonly ageDifference: number;
    public readonly childrenDifference: number;
    public readonly diversityDifference: number;
    public readonly urbanicityDifference: number;
    public readonly areaHealthDifference: number;
    public readonly footfallDifference: number;
    public readonly spendDifference?: number;

    constructor(
        store: Store,
        retailCentre: RetailCentre,
        scenarioCatchment: boolean,
        similarityScore: number,
        affluenceDifference: number,
        ageDifference: number,
        childrenDifference: number,
        diversityDifference: number,
        urbanicityDifference: number,
        areaHealthDifference: number,
        footfallDifference: number,
        spendDifference?: number,
    ) {
        this.store = store;
        this.retailCentre = retailCentre;
        this.scenarioCatchment = scenarioCatchment;
        this.similarityScore = similarityScore;
        this.affluenceDifference = affluenceDifference;
        this.ageDifference = ageDifference;
        this.childrenDifference = childrenDifference;
        this.diversityDifference = diversityDifference;
        this.urbanicityDifference = urbanicityDifference;
        this.areaHealthDifference = areaHealthDifference;
        this.footfallDifference = footfallDifference;
        this.spendDifference = spendDifference;
    }
}
