import React from "react";
import { useSelector } from "react-redux";

import { Box, Card, CardContent, Link, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { selectors } from "modules/customer/account/settings";

const StyledCard = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(Card);

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.tertiary.main
    }
}));

const EmailAddressAndPassword = () => {
    const classes = useStyles();
    const personalInfo = useSelector(state => selectors.personalInfo(state));

    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-account-settings-email-address-and-password">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Email address and password
                </Typography>
            </Box>
            <StyledCard>
                <CardContent>
                    <Box color="text.disabled">
                        <Typography variant="subtitle1" component="div" gutterBottom>
                            {personalInfo.email}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Visit KPMG Gateway to manage email address, account password and MFA
                    </Typography>
                    <Link
                        href="https://gateway.kpmg.co.uk/"
                        target="_blank"
                        underline="none"
                        variant="subtitle1"
                        className={classes.link}
                    >
                        KPMG Gateway
                    </Link>
                </CardContent>
            </StyledCard>
        </Box>
    );
};

export default EmailAddressAndPassword;
