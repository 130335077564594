import React from "react";
import Highcharts from "highcharts";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Dumbbell from "components/visuals/Dumbbell";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

highchartsDumbbell(Highcharts);

interface ChartValues {
    lowCategory: string,
    highCategory: string,
    label: string,
    selectedValue: number,
    targetValue: number,
}
interface DumbbellAlignmentProps {
    chartValues: ChartValues[],
    targetIsSet: boolean,
    dataCy?: string
}

const DumbbellAlignment: React.FC<DumbbellAlignmentProps> = (props: DumbbellAlignmentProps) => {
    const { chartValues, targetIsSet, dataCy } = props;
    const colourPalette = useColourPalette();
    const targetColour = '#FFFFFF';

    const seriesNames = {
        retailCentre: "Selected location",
        target: `Target`
    };

    const lowCategories = chartValues.map(value => value.lowCategory);
    const highCategories = chartValues.map(value => value.highCategory);

    const createDumbbellPointOptions = (
        x: number,
        tooltip: string,
        retailCentreValue: number,
        targetValue: number
    ): Highcharts.PointOptionsObject => {
        const low = Math.min(retailCentreValue, targetValue);
        const high = Math.max(retailCentreValue, targetValue);
        return {
            x,
            low,
            high,
            lowColor: (low === retailCentreValue) ? colourPalette.comparators[0] : targetColour,
            color: (high === targetValue) ? targetColour : colourPalette.comparators[0],
            custom: {
                tooltip,
                lowSeries: (low === retailCentreValue) ? seriesNames.retailCentre : seriesNames.target,
                highSeries: (high === targetValue) ? seriesNames.target : seriesNames.retailCentre
            }
        };
    };

    const dumbbellData = targetIsSet ? chartValues.map((metric, index) => 
        createDumbbellPointOptions(index, metric.label, metric.selectedValue, metric.targetValue)
    ) : [];

    const scatterData = targetIsSet ? [] : chartValues.map(metric => ({
        y: metric.selectedValue,
        custom: {
            tooltip: metric.label
        }
    }));

    const chartHeight = 110 + (chartValues.length - 1) * 70;

    const options: Highcharts.Options = {
        chart: {
            height: chartHeight                   
        },
        xAxis: [{
            categories: lowCategories
        }, {
            categories: highCategories
        }],
        yAxis: {
            labels: {
                enabled: false
            },
            max: 100,
            min: 0
        },
        tooltip: {
            useHTML: true,
            headerFormat: `<table>`,
            footerFormat: `</table>`,
            pointFormatter: function () {
                const customOptions = this?.options?.custom;
                const scoreSuffix = `percentile`;
                const header = customOptions?.tooltip + " score";
                if (targetIsSet) {
                    return stringUtils.tooltipHTML([customOptions?.lowSeries, customOptions?.highSeries], {
                        values: [`${numberFormatter.toOrdinalNumber(this.options?.low)} ${scoreSuffix}`,
                            `${numberFormatter.toOrdinalNumber(this.options?.high)} ${scoreSuffix}`],
                        header,
                        valueFormattingArr: [`color:${this.options?.lowColor}`, `color:${this.options?.color}`]
                    });
                } else {
                    return stringUtils.tooltipHTML([seriesNames.retailCentre], {
                        values: [`${numberFormatter.toOrdinalNumber(this.options?.y)} ${scoreSuffix}`],
                        header,
                        valueFormattingArr: [`color:${colourPalette.comparators[0]}`]
                    });
                }
            }
        },
        series: [{
            type: "dumbbell",
            showInLegend: false,
            data: dumbbellData
        }, {
            name: seriesNames.retailCentre,
            type: "scatter",
            data: scatterData,
            showInLegend: true,
            color: colourPalette.comparators[0]
        }, {
            name: seriesNames.target,
            type: "scatter",
            data: [],
            showInLegend: true,
            color: targetColour
        }],
    };

    return (
        <Box data-cy={dataCy}>
            <Dumbbell loading={false} error={false} options={options} />
        </Box>
    );
};

export default DumbbellAlignment;
