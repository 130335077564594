import React from "react";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import SelectedStoreMarker from "components/visuals/maps/SelectedStoreMarker";

import Legend from "./Legend";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";
import { selectAggregatedSpendByOutputArea, selectHasErrors, selectIsLoading } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import mathUtils from "utils/mathUtils";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const selectedStore = useAppSelector(selectStore);
    const spendByOutputArea = useAppSelector(selectAggregatedSpendByOutputArea);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredOutputArea = spendByOutputArea.find(spend => spend.outputAreaCode === hoverInfo.outputAreaCode);

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: selectedStore?.latitude ?? 0,
        longitude: selectedStore?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const thresholds = React.useMemo(() => {
        const values = spendByOutputArea.map(spendByOA => spendByOA.spend);
        return mathUtils.percentileThresholds(values, 5);
    }, [spendByOutputArea]);

    const mapOutputAreas = React.useMemo(() => {
        return spendByOutputArea.map(spendByOA => {
            let colourGroup = 0;
            for (let i = 0; i < thresholds.length; i++) {
                if (spendByOA.spend >= thresholds[i]) {
                    colourGroup = (thresholds.length - 1) - i;
                }
            }
            return {
                code: spendByOA.outputAreaCode,
                colourGroup
            };
        });
    }, [spendByOutputArea, thresholds]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: selectedStore?.latitude ?? 0,
                longitude: selectedStore?.longitude ?? 0
            };
        });
    }, [selectedStore]);

    return (
        <Box data-cy="catchment-area-pen-portrait-classification">
            <OutputAreasMap
                loading={isLoading}
                error={hasErrors}
                title={`${selectedStore?.name} store's catchment area spend`}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colourPalette.sequential}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                downloadData={spendByOutputArea}
            >
                <SelectedStoreMarker latitude={selectedStore?.latitude ?? 0} longitude={selectedStore?.longitude ?? 0} />
                <Legend outputArea={hoveredOutputArea} thresholds={thresholds} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
