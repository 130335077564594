import React from "react";
import { Grid } from "@material-ui/core";

import SpendCategoriesFilter from "./SpendCategoriesFilter";
import Map from "./Map";
import WeightSpendByProbabilitySwitch from "./WeightSpendByProbabilitySwitch";
import CatchmentTotalYearlySpend from "./CatchmentTotalYearlySpend";
import CatchmentSpendPerHead from "./CatchmentSpendPerHead";
import WeightedSpendOrSpendPerHead from "./WeightedSpendOrSpendPerHead";

const MarketCategorySpend = () => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={'auto'}>
                <SpendCategoriesFilter />
            </Grid>
            <Grid item xs={12} md={6}>
                <WeightSpendByProbabilitySwitch />
            </Grid>
            <Grid item xs={12} md={6}>
                <CatchmentTotalYearlySpend />
            </Grid>
            <Grid item xs={12} md={6}>
                <CatchmentSpendPerHead />
            </Grid>
            <Grid item xs={12}>
                <Map />
            </Grid>
            <Grid item xs={12}>
                <WeightedSpendOrSpendPerHead />
            </Grid>
        </Grid>
    );
};

export default MarketCategorySpend;
