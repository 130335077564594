import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import {
    selectHasErrors,
    selectIsLoading,
    selectCatchmentAreaSpendPerHead
} from "modules/customer/tools/location/spendNew/spendSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

import numberFormatter from "utils/numberFormatter";

const CatchmentAreaSpendPerHead: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const catchmentAreaSpendPerHead = useAppSelector(selectCatchmentAreaSpendPerHead);
    const comparatorName = useAppSelector(selectComparatorsByChapter)?.spend?.storeName;

    const targetValue = catchmentAreaSpendPerHead.selectedLocationValue;
    const comparisonValue = catchmentAreaSpendPerHead.comparatorValue;
    const differenceFormatted = numberFormatter.toGBP((targetValue - comparisonValue), 1);

    return (
        <Box width="100%" height="100%" data-cy="total-spend-catchment-area-yearly-spend">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {numberFormatter.toGBP(targetValue, 1)}
                    </Typography>
                }
                targetLabel="Selected location catchment area spend per head"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {numberFormatter.toGBP(comparisonValue, 1)}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparatorName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {differenceFormatted}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default CatchmentAreaSpendPerHead;
