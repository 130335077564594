import { createSelector } from "reselect";

import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const retailDensityVis = (state) => {
    return state.customer.insights.portfolio.areaHealth.retailDensity.retailDensityVis;
};

const retailDensityRag = createSelector(
    retailDensityVis,
    state => selectStore(state),
    state => selectComparator(state),
    (retailDensityVis, selectedStore, selectedComparator) => {
        const rag = {
            loading: false,
            error: false,
            id: "retail-density-rag-indicator",
            label: "Retail density",
            status: "info",
            value: ""
        };

        if (retailDensityVis.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (retailDensityVis.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const store = retailDensityVis.store; //the retail density of the selected store
        const retailDensityData = retailDensityVis.retailDensityData; //overall set of data

        if (Object.keys(retailDensityData).length === 0) {
            return rag;
        }

        const sortedData = retailDensityData.sort((a, b) => {
            return b.retailDensity - a.retailDensity; //descending order - higher value gets lower rank
        });

        const rank = 1 + sortedData.findIndex(item => {
            if (item.retailDensity === store) {
                return true;
            } else {
                return false;
            }
        });

        const denominator = sortedData.length;

        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = rank / denominator;

        if (selectedPercentile < topThirdPercentile) {
            rag.status = "success";
            rag.value = `${selectedStore.name} is positioned in a busy area relative to the other stores in ${selectedComparator.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            rag.status = "error";
            rag.value = `${selectedStore.name} is positioned in a quiet area relative to the other stores in ${selectedComparator.name}`;
        } else {
            rag.status = "warning";
            rag.value = `${selectedStore.name} is positioned in an average area for busyness relative to the other stores in ${selectedComparator.name}`;
        }

        return rag;
    }
);

const selectors = {
    retailDensityVis,
    retailDensityRag
};

export default selectors;
