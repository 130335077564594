import React from "react";

import KPIFact from "./KPIFact";
import numberFormatter from "utils/numberFormatter";

interface KPIAlignmentProps {
    isLoading: boolean,
    hasErrors: boolean,
    score: number,
    label: string,
    disabled?: boolean
}

const KPIAlignment: React.FC<KPIAlignmentProps> = (props) => {
    const {
        isLoading, hasErrors,
        score, label,
        disabled
    } = props;

    const roundedScore = numberFormatter.toDecimalPlaces(score, 0);
    const valueFormatted = `${roundedScore}/10`;

    const displayValue = disabled ? undefined : valueFormatted;
    const displayLabel = disabled ? 'No target set' : label;

    return (
        <KPIFact
                loading={isLoading}
                error={hasErrors}
                label={displayLabel}
                value={displayValue}
                disabled={disabled}
        />
    );
};

export default KPIAlignment;
