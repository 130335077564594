import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const RevenueVsCompetitionScoreSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Here, we divide your {store?.name} store's last 12-month's revenue by its Competition Score - to
            assess the store's sales performance relative to its ability to compete. Contextualise its performance in
            this metric against {comparator?.name}. Determine whether your {store?.name} store is a
            bottom, average, or top performer and where it ranks among {comparator?.name}.
        </>
    );
};

export default RevenueVsCompetitionScoreSubtitle;
