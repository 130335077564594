import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Uncover who your {store?.name} store's customers truly are and understand where they come from.
            Delve into customer profiles, spending trends, and detailed demographics to align your range, store layout,
            and promotional efforts to the needs of your local demography, ensuring sustainable growth for your {store?.name} store.
        </>
    );
};

export default Subtitle;
