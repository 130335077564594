import React from "react";

import { Typography } from "@material-ui/core";

const TotalCostTrendsOverTimeSubtitle: React.FC = () => {
    return (
        <Typography variant="h6">
            Total cost trends over time
        </Typography>
    );
};

export default TotalCostTrendsOverTimeSubtitle;
