import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class ModelFeature {
    public readonly model: string;
    public readonly featureId: number;
    public readonly defaultName: string;
    public readonly displayName: string;
    public readonly featureValueId: number;
    public readonly featureValue: string;

    constructor(
        model: string,
        featureId: number,
        defaultName: string,
        displayName: string,
        featureValueId: number,
        featureValue: string
    ) {
        this.model = model;
        this.featureId = featureId;
        this.defaultName = defaultName;
        this.displayName = displayName;
        this.featureValueId = featureValueId;
        this.featureValue = featureValue;
    }
}

export class RelevantModelFeature {
    public readonly id: number;
    public readonly type: RelevantModelFeatureType;
    public readonly name: string;
    public readonly displayName: string;
    public readonly values: string[];

    constructor(
        id: number,
        type: RelevantModelFeatureType,
        name: string,
        displayName: string,
        values: string[],
    ) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.displayName = displayName;
        this.values = values;
    }
}

export enum RelevantModelFeatureType {
    Numerical,
    Categorical
}

export const loadModelFeatures = (): AppThunk<Promise<ModelFeature[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "ModelStoreFeatures.GroupName",
                "ModelStoreFeatures.FeatureID",
                "ModelStoreFeatures.FeatureName",
                "ModelStoreFeatures.ClientFeatureName",
                "ModelStoreFeatures.FeatureValueID",
                "ModelStoreFeatures.FeatureValue"
            ]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return rawData.map(row => new ModelFeature(
            row["ModelStoreFeatures.GroupName"],
            row["ModelStoreFeatures.FeatureID"],
            row["ModelStoreFeatures.FeatureName"],
            row["ModelStoreFeatures.ClientFeatureName"] ?? row["ModelStoreFeatures.FeatureName"],
            Number(row["ModelStoreFeatures.FeatureValueID"] ?? 0),
            row["ModelStoreFeatures.FeatureValue"] ?? "",
        ));
    } catch (error) {
        dispatch(logError("Error loading ModelFeatures.", error));
        throw error;
    }
};
