const GET_SELECTED_STORE_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_SELECTED_STORE_REQUEST";
const GET_SELECTED_STORE_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_SELECTED_STORE_FAILURE";
const GET_SELECTED_STORE_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_SELECTED_STORE_SUCCESS";

const GET_REVENUE_UNIT_SALES_OVERTIME_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_UNIT_SALES_OVERTIME_REQUEST";
const GET_REVENUE_UNIT_SALES_OVERTIME_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_UNIT_SALES_OVERTIME_FAILURE";
const GET_REVENUE_UNIT_SALES_OVERTIME_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_UNIT_SALES_OVERTIME_SUCCESS";

const GET_REVENUE_LAST_YEAR_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_LAST_YEAR_REQUEST";
const GET_REVENUE_LAST_YEAR_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_LAST_YEAR_FAILURE";
const GET_REVENUE_LAST_YEAR_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_LAST_YEAR_SUCCESS";

const GET_UNIT_SALES_LAST_YEAR_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_LAST_YEAR_REQUEST";
const GET_UNIT_SALES_LAST_YEAR_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_LAST_YEAR_FAILURE";
const GET_UNIT_SALES_LAST_YEAR_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_LAST_YEAR_SUCCESS";


const GET_MEDIAN_WEEKLY_REVENUE_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_REVENUE_REQUEST";
const GET_MEDIAN_WEEKLY_REVENUE_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_REVENUE_FAILURE";
const GET_MEDIAN_WEEKLY_REVENUE_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_REVENUE_SUCCESS";

const GET_MEDIAN_WEEKLY_UNIT_SALES_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_UNIT_SALES_REQUEST";
const GET_MEDIAN_WEEKLY_UNIT_SALES_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_UNIT_SALES_FAILURE";
const GET_MEDIAN_WEEKLY_UNIT_SALES_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_MEDIAN_WEEKLY_UNIT_SALES_SUCCESS";

const GET_REVENUE_TREND_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_TREND_REQUEST";
const GET_REVENUE_TREND_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_TREND_FAILURE";
const GET_REVENUE_TREND_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_REVENUE_TREND_SUCCESS";

const GET_UNIT_SALES_TREND_REQUEST = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_TREND_REQUEST";
const GET_UNIT_SALES_TREND_FAILURE = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_TREND_FAILURE";
const GET_UNIT_SALES_TREND_SUCCESS = "spark/customer/insights/range/sales/historicSales/GET_UNIT_SALES_TREND_SUCCESS";

const types = {
    GET_SELECTED_STORE_REQUEST,
    GET_SELECTED_STORE_FAILURE,
    GET_SELECTED_STORE_SUCCESS,

    GET_REVENUE_UNIT_SALES_OVERTIME_REQUEST,
    GET_REVENUE_UNIT_SALES_OVERTIME_FAILURE,
    GET_REVENUE_UNIT_SALES_OVERTIME_SUCCESS,

    GET_REVENUE_LAST_YEAR_REQUEST,
    GET_REVENUE_LAST_YEAR_FAILURE,
    GET_REVENUE_LAST_YEAR_SUCCESS,

    GET_UNIT_SALES_LAST_YEAR_REQUEST,
    GET_UNIT_SALES_LAST_YEAR_FAILURE,
    GET_UNIT_SALES_LAST_YEAR_SUCCESS,

    GET_MEDIAN_WEEKLY_REVENUE_REQUEST,
    GET_MEDIAN_WEEKLY_REVENUE_FAILURE,
    GET_MEDIAN_WEEKLY_REVENUE_SUCCESS,

    GET_MEDIAN_WEEKLY_UNIT_SALES_REQUEST,
    GET_MEDIAN_WEEKLY_UNIT_SALES_FAILURE,
    GET_MEDIAN_WEEKLY_UNIT_SALES_SUCCESS,

    GET_REVENUE_TREND_REQUEST,
    GET_REVENUE_TREND_FAILURE,
    GET_REVENUE_TREND_SUCCESS,

    GET_UNIT_SALES_TREND_REQUEST,
    GET_UNIT_SALES_TREND_FAILURE,
    GET_UNIT_SALES_TREND_SUCCESS,

};

export default types;
