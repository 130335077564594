import React from "react";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageCallToAction from "components/landing/PageCallToAction";

import CategoriesWithQuestions from "./CategoriesWithQuestions";

import { getCategoriesWithQuestions } from "modules/landing/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch } from "store";

const FrequentlyAskedQuestions: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    React.useEffect(() => {
        dispatch(getCategoriesWithQuestions());
    }, [dispatch]);

    return (
        // @ts-ignore
        <Page dataCy="pages-landing-frequently-asked-questions">
            <PageHeader
                title="Frequently asked questions"
                dataCy="pages-landing-frequently-asked-questions-header"
            />
            <CategoriesWithQuestions />
            <PageCallToAction
                color={theme.palette.common.white}
                bgcolor={theme.palette.primary.main}
                title="Still have a question?"
                subtitle="Contact our team of specialists"
                actionLabel="Contact us"
                actionUrl="/contact-us"
                dataCy="pages-landing-frequently-asked-questions-call-to-action"
            />
        </Page>
    );
};

export default FrequentlyAskedQuestions;
