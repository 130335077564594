import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Gauge from "components/visuals/Gauge";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/staffing";


const PayrollCostPerSqftGauge = () => {
    const payrollCostPerSqftGauge = useSelector(state => selectors.payrollCostPerSqftGauge(state));
    const { loading, error, min, max, data, med, firstTertile, secondTertile } = payrollCostPerSqftGauge;

    const noGaugeData = (data === 0 || max === 0);

    const options = {
    };
    
    return (
        <Box data-cy="payroll-cost-per-sqft-gauge">
            <Gauge loading={loading} error={error} options={options} data={data} min={min} max={max} 
                median={med} firstTertile={firstTertile} secondTertile={secondTertile} noData={noGaugeData} lowerIsBetter={true} 
                reverseYAxis={true} />
        </Box>
    );
};

export default PayrollCostPerSqftGauge;
