import types from "./types";

const getRetailDensityVisRequest = () => ({
    type: types.GET_RETAIL_DENSITY_VIS_REQUEST
});

const getRetailDensityVisSuccess = (store, comparator, retailDensityData) => ({
    type: types.GET_RETAIL_DENSITY_VIS_SUCCESS,
    payload: {
        store,
        comparator,
        retailDensityData
    }
});

const getRetailDensityVisFailure = () => ({
    type: types.GET_RETAIL_DENSITY_VIS_FAILURE
});

const actions = {
    getRetailDensityVisRequest,
    getRetailDensityVisSuccess,
    getRetailDensityVisFailure
};

export default actions;
