import React from "react";

import { Avatar, Button, Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

import { login } from "modules/auth/authSlice";
import { useAppDispatch } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.background.paper
        }
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    primaryCard: {
        backgroundColor: theme.palette.primary.main
    },
    normalCard: {
        backgroundColor: theme.palette.background.default
    },
    primaryAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        fontSize: "32px",
        fontWeight: 600,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderStyle: "solid",
        borderColor: theme.palette.common.white
    },
    normalAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        fontSize: "32px",
        fontWeight: 600,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main
    },
    primaryTypography: {
        color: theme.palette.common.white
    }
}));

const Success: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const handleCreateAccountClick = () => {
        dispatch(login());
    };

    return (
        // @ts-ignore
        <PageSection dataCy="pages-landing-registration-success">
            <Typography variant="h2" component="div" align="center" gutterBottom>
                Get started with Dash in three steps
            </Typography>
            <br />
            <br />
            <br />
            <Grid container spacing={8}>
                <Grid item xs={12} md={4}>
                    <Card elevation={0} className={classes.primaryCard}>
                        {/* @ts-ignore */}
                        <CardContent align="center">
                            <br />
                            <Avatar className={classes.primaryAvatar}>1</Avatar>
                            <br />
                            <Typography variant="h5" component="div" gutterBottom className={classes.primaryTypography}>
                                Create KPMG Gateway account
                            </Typography>
                            <Typography variant="subtitle2" component="div" gutterBottom className={classes.primaryTypography}>
                                Verify your email address and setup a password on our secure server
                            </Typography>
                        </CardContent>
                        <br />
                        <CardActions style={{ justifyContent: "center" }}>
                            <StyledButton
                                variant="contained"
                                size="large"
                                color="default"
                                disableElevation
                                onClick={handleCreateAccountClick}
                            >
                                Create account
                            </StyledButton>
                        </CardActions>
                        <br />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card elevation={0} className={classes.normalCard}>
                        {/* @ts-ignore */}
                        <CardContent align="center">
                            <br />
                            <Avatar className={classes.normalAvatar}>2</Avatar>
                            <br />
                            <Typography variant="h5" component="div" gutterBottom>
                                Get setup
                            </Typography>
                            <Typography variant="subtitle2" component="div" gutterBottom>
                                Complete registration and upload your data to Dash
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card elevation={0} className={classes.normalCard}>
                        {/* @ts-ignore */}
                        <CardContent align="center">
                            <br />
                            <Avatar className={classes.normalAvatar}>3</Avatar>
                            <br />
                            <Typography variant="h5" component="div" gutterBottom>
                                Start using Dash
                            </Typography>
                            <Typography variant="subtitle2" component="div" gutterBottom>
                                Upload your data to start accelerating the growth of your business
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default Success;
