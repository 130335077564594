import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const GrossProfitMarginSubtitle = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Understand how the gross profit margin of your {store?.name} store is changing over time, and
            identify its most and least profitable months. See how this compares to {comparator?.name}.
        </>
    );
};

export default GrossProfitMarginSubtitle;
