import React from "react";

import { Card, CardActionArea, CardMedia, Dialog, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import VideoPlayer from "components/VideoPlayer";

import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";
import { selectAzureCdnConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const SayGoodbyeToGutFeel = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const azureCdnConfiguration = useAppSelector(selectAzureCdnConfiguration);
    const sayGoodbyeToGutFeelVideoUrl = azureCdnConfiguration.sayGoodbyeToGutFeelVideoUrl;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showVideo, setShowVideo] = React.useState(false);
    const videoJsOptions = { //Reference https://docs.videojs.com/tutorial-options.html for more options
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
            pictureInPictureToggle: false
        },
        sources: [{
            src: sayGoodbyeToGutFeelVideoUrl,
            type: "video/mp4"
        }],
        tracks: [{
            kind: "captions",
            src: "/content/landing/home/say-goodbye-to-gut-feel/captions.txt",
            srclang: "en",
            label: "English"
        }],
        poster: "/content/landing/home/say-goodbye-to-gut-feel/video-poster.png",
        crossorigin: "anonymous"
    };

    const handleShowVideoClick = () => {
        dispatch(trackEvent("Home page", "Home - Dash video click", "Dash in action video"));
        setShowVideo(true);
    };

    const handleCloseVideoClick = () => {
        setShowVideo(false);
    };

    return (
        <PageSection
            color={theme.palette.common.white}
            bgcolor={theme.palette.primary.main}
            maxWidth="md"
            disablePaddingBottom
            dataCy="pages-landing-home-say-goodbye-to-gut-feel"
        >
            <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" gutterBottom>
                Say goodbye to gut feel. See Dash in action and learn how it can help your business
            </Typography>
            <br />
            <br />
            <StyledCard elevation={0}>
                <CardActionArea onClick={handleShowVideoClick}>
                    <CardMedia component="img" image="/content/landing/home/say-goodbye-to-gut-feel/video-poster-with-play-button.png" alt="say goodbye to gut feel" />
                </CardActionArea>
            </StyledCard>
            <Dialog
                open={showVideo}
                onClose={handleCloseVideoClick}
                maxWidth="lg"
                fullWidth
            >
                <VideoPlayer options={videoJsOptions} onClose={handleCloseVideoClick} />
            </Dialog>
        </PageSection>
    );
};

export default SayGoodbyeToGutFeel;
