import React from "react";
import { Grid } from "@material-ui/core";

import MarketCategoryOpeningsVsClosures from "./MarketCategoryOpeningsVsClosures";
import ComplementaryMarketCat from "./ComplementaryMarketCat";
import NonComplementaryMarketCat from "./NonComplementaryMarketCat";

const MarketCategoryBreakdown = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MarketCategoryOpeningsVsClosures />
            </Grid>
            <Grid item xs={12}>
                <ComplementaryMarketCat />
            </Grid>
            <Grid item xs={12}>
                <NonComplementaryMarketCat />
            </Grid>
        </Grid>
    );
};

export default MarketCategoryBreakdown;
