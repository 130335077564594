import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectPredictionIsLoading,
    selectPredictionHasErrors,
    selectPeakWeekRevenuePrediction
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const PeakWeekRevenuePrediction: React.FC = () => {
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const peakWeekRevenuePrediction = useAppSelector(selectPeakWeekRevenuePrediction);
    const valueFormatted = numberFormatter.toGBP(peakWeekRevenuePrediction, 1);
    const label = "Pinned location peak week revenue prediction";

    return (
        <Box width="100%" height="100%" data-cy="peak-week-revenue-prediction">
            <KPIFact loading={predictionIsLoading} error={predictionHasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default PeakWeekRevenuePrediction;
