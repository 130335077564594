import { logError } from "modules/helpers/logger/loggerSlice";
import { selectStore, selectComparator, selectProductCategories, cubeLoadStoreCatchments } from "modules/customer/insights/portfolio/portfolioSlice";
import actions from "./actions";

const getSupplyAndDemand = () => async (dispatch, getState) => {
    dispatch(actions.getSupplyAndDemandRequest());
    try {
        const state = getState();
        const spendProductCategories = selectProductCategories(state);

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const comparatorStoresSelector = selectComparator(state);
        const storesData = [selectedStoreSelector].concat(comparatorStoresSelector.getStores());

        const querySupply = {
            dimensions: [
                "F_Supply.StoreCount",
                "F_Supply.LocalArea",
                "F_Supply.KPMGStoreCategory"
            ],
            filters: [{
                    member: "F_Supply.LocalArea",
                    operator: "equals",
                }, {
                    member: "F_Supply.KPMGStoreCategory",
                    operator: "equals",
            }],
            order: {
                "F_Supply.LocalArea": "asc"
            }
        };
        const resultSupply = await dispatch(cubeLoadStoreCatchments(querySupply, storesData, "KPMGStoreCategory", "LocalArea"));
        const supplyData = resultSupply.loadResponses[0].data;

        const queryDemand = {
            measures: [
                "CatchmentMarketCategorySpend.sumBaselineWeightedSpend"
            ],
            dimensions: [
                "CatchmentMarketCategorySpend.RetailCentreID",
                "CatchmentMarketCategorySpend.KpmgStoreCategory",
                "CatchmentMarketCategorySpend.SpendKpmgCategory"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.SpendKpmgCategory",
                operator: "equals",
                values: spendProductCategories
            }, {
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals",
            }, {
                member: "CatchmentMarketCategorySpend.KpmgStoreCategory",
                operator: "equals",
            }],
            segments: [
                "CatchmentMarketCategorySpend.Baseline"
            ]
        };
        const resultDemand = await dispatch(cubeLoadStoreCatchments(queryDemand, storesData, "KpmgStoreCategory", "RetailCentreID"));
        const demandData = resultDemand.loadResponses[0].data;

        let store = {};
        const comparator = [];

        for (let i in storesData) {
            const relevantSupplyData = supplyData.find(item =>
                (item["F_Supply.LocalArea"] === storesData[i].outputAreaCode) && (item["F_Supply.KPMGStoreCategory"] === storesData[i].kpmgStoreCategory));
                
            storesData[i].supply = relevantSupplyData ? parseInt(relevantSupplyData["F_Supply.StoreCount"]) : 0;
            const relevantSpendCategories = storesData[i].kpmgSpendCategories.map(item => item.toLowerCase());

            const relevantDemandData = demandData.filter(item =>
                (item["CatchmentMarketCategorySpend.RetailCentreID"] === storesData[i].retailCentreID)
                && (item["CatchmentMarketCategorySpend.KpmgStoreCategory"] === storesData[i].kpmgStoreCategory)
                && relevantSpendCategories.includes(item["CatchmentMarketCategorySpend.SpendKpmgCategory"].toLowerCase()));

            storesData[i].demand = relevantDemandData
                ? relevantDemandData.map(item => item["CatchmentMarketCategorySpend.sumBaselineWeightedSpend"]).reduce((a, b) => a + b, 0) : 0;

            if (storesData[i].id === selectedStoreID) {
                store = storesData[i];
            } else {
                comparator.push(storesData[i]);
            }
        }

        dispatch(actions.getSupplyAndDemandSuccess(store, comparator));
    }

    catch (error) {
        dispatch(actions.getSupplyAndDemandFailure());
        dispatch(logError("Error loading SupplyAndDemand.", error));
    }
};

const operations = {
    getSupplyAndDemand
};

export default operations;
