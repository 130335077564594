import { combineReducers } from "redux";

import types from "./types";

const marketCategoryOpeningsVsClosuresEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const marketCategoryOpeningsVsClosures = (state = marketCategoryOpeningsVsClosuresEmpty, action) => {
    switch (action.type) {
        case types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_SUCCESS:
            return {
                ...state,   
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    marketCategoryOpeningsVsClosures
});

export default reducer;
