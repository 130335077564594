import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Explore which existing stores closely mirror your selected location. Utilise filtering to tailor results and analyse alignment.
            Gain critical insights to assist with range planning, store segmentation, and layout optimisation for your new store.
        </>
    );
};

export default Subtitle;
