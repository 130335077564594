import React from "react";
import _ from "lodash";

import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { StoresSearch, selectStoresSearch, setStoresSearch } from "modules/customer/tools/product/storeProductFilters/storeProductFiltersSlice";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const storesSearch = useAppSelector(selectStoresSearch);
    const [currentStoresSearch, setCurrentStoresSearch] = React.useState<StoresSearch>(storesSearch);

    const setStoresSearchDelayed = React.useMemo(() => {
        return _.debounce((storesSearch: StoresSearch) => dispatch(setStoresSearch(storesSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newStoresSearch = { ...storesSearch, name };
        setCurrentStoresSearch(newStoresSearch);
        setStoresSearchDelayed(newStoresSearch);
    };

    React.useEffect(() => {
        setCurrentStoresSearch(storesSearch);
    }, [storesSearch]);

    return (
        <Box paddingTop={2}>
            <StyledTextField
                placeholder="Search"
                variant="outlined"
                size="small"
                color="secondary"
                value={currentStoresSearch.name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default Search;
