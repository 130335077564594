import React from "react";
import Highcharts from "highcharts";
import { DateTime } from "luxon";

import { Box, Typography } from "@material-ui/core";

import StockLine from "components/visuals/StockLine";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import {
    selectFootfallLevelOverTime,
    selectHasErrors,
    selectIsLoading
} from "modules/customer/tools/location/footfall/footfallSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface FootfallLevelOverTime {
    footfall: number,
    date: number
}

const FootfallLevelOverTimeChart: React.FC = () => {
    const colourPalette = useColourPalette();
    const comparator = useAppSelector(selectComparatorsByChapter)?.footfall;
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const footfallLevelOverTime = useAppSelector(selectFootfallLevelOverTime);

    const locationSeries: FootfallLevelOverTime[] = [];
    const benchmarkSeries: FootfallLevelOverTime[] = [];

    footfallLevelOverTime.forEach(footfall => {
        locationSeries.push({
            footfall: footfall.retailCentre,
            date: DateTime.fromISO(footfall.date, { zone: "utc" }).toMillis()
        });
        benchmarkSeries.push({
            footfall: footfall.benchmark,
            date: DateTime.fromISO(footfall.date, { zone: "utc" }).toMillis()
        });
    });
    const comparatorName = comparator?.storeName ?? "Unavailable";

    const options: Highcharts.Options = {
        yAxis: {
            title: {
                text: "Footfall"
            },
        },
        legend: {
            enabled: true
        },
        navigator: {
            series: [{
                type: "areaspline",
                data: locationSeries.map(item => [item.date, item.footfall]),
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, new Highcharts.Color(colourPalette.comparators[0]).setOpacity(0.4).get("rgba") as string],
                        [1, "transparent"]
                    ]
                }
            }, {
                type: "areaspline",
                data: benchmarkSeries.map(item => [item.date, item.footfall]),
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, new Highcharts.Color(colourPalette.comparators[1]).setOpacity(0.4).get("rgba") as string],
                        [1, "transparent"]
                    ]
                }
            }]
        },
        rangeSelector: {
            selected: 1,
            buttons: [{
                type: "week",
                count: 26,
                text: "Week",
                dataGrouping: {
                    forced: true,
                    units: [["week", [1]]],
                    approximation: "average",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 12,
                text: "Month",
                dataGrouping: {
                    forced: true,
                    units: [["month", [1]]],
                    approximation: "average",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 24,
                text: "Quarter",
                dataGrouping: {
                    forced: true,
                    units: [["month", [3]]],
                    approximation: "average",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 3,
                text: "Year",
                dataGrouping: {
                    forced: true,
                    units: [["year", [1]]],
                    approximation: "average",
                    groupAll: true
                },
                preserveDataGrouping: true
            }]
        },
        tooltip: {
            formatter: function () {
                const points = this.points ?? [];

                const options = points[0].series.chart.options;
                const selectedButton = options.rangeSelector?.selected ?? 0;
                const buttons = options.rangeSelector?.buttons ?? [];
                const buttonType = buttons[selectedButton].type ?? "month";
                const dateFormats = points[0].series.chart.tooltip.options.dateTimeLabelFormats;
                const dateFormatString = (dateFormats !== undefined) ? dateFormats[buttonType] : "%B %Y";

                let categoryArr: string[] = [];
                let valueFormattingArr: string[] = [];
                let valuesArr: string[] = [];
                let point: Highcharts.TooltipFormatterContextObject;

                const x = points[0].x as number;
                let header = (typeof x === "number") ? Highcharts.dateFormat(dateFormatString, x) : ``;

                if (buttons[selectedButton]?.text === "Quarter") {
                    header = `Q${(Math.floor(Number(Highcharts.dateFormat("%m", x)) / 3) + 1)} ${Highcharts.dateFormat(" %Y", x)}`;
                }

                for (let i = 0; i < points.length; i++) {
                    point = points[i];

                    categoryArr.push(point.series.name);
                    valuesArr.push(numberFormatter.toDecimalPlaces(point.y, 2));
                    valueFormattingArr.push(`color:${point.series.color}`);
                }

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    header: header,
                    values: valuesArr,
                    valueFormattingArr: valueFormattingArr
                })}</table>`;
            },
            dateTimeLabelFormats: {
                month: "%B %Y",
                week: "Week from %A, %b %e, %Y",
                year: "%Y"
            }
        },
        series: [{
            name: "Location",
            type: "spline",
            data: locationSeries.map(item => [item.date, item.footfall]),
            gapSize: 1,
            color: colourPalette.comparators[0]

        }, {
            name: `Comparator: ${comparatorName}`,
            type: "spline",
            data: benchmarkSeries.map(item => [item.date, item.footfall]),
            gapSize: 1,
            color: colourPalette.comparators[1]
        }]
    };

    return (
        <Box data-cy="footfall-density">
            <Typography variant="h6" component="div" gutterBottom>
                Footfall level over time
            </Typography>
            <StockLine loading={isLoading} error={hasErrors} options={options} globalOptions={{}} />
        </Box>
    );
};

export default FootfallLevelOverTimeChart;
