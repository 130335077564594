import types from "./types";

const getTypesOfContributionGrowthRequest = () => ({
    type: types.GET_TYPES_OF_CONTRIBUTION_GROWTH_REQUEST
});

const getTypesOfContributionGrowthSuccess = (data) => ({
    type: types.GET_TYPES_OF_CONTRIBUTION_GROWTH_SUCCESS,
    payload: {
        data
    }
});

const getTypesOfContributionGrowthFailure = () => ({
    type: types.GET_TYPES_OF_CONTRIBUTION_GROWTH_FAILURE
});

const actions = {
    getTypesOfContributionGrowthRequest,
    getTypesOfContributionGrowthSuccess,
    getTypesOfContributionGrowthFailure
};

export default actions;
