export enum RagIndicatorStatus {
    Info,
    NoData,
    Red,
    Amber,
    Green
}

export class RagIndicator {
    public readonly id: string;
    public readonly status: RagIndicatorStatus;
    public readonly label: string;
    public readonly value: string;
    public readonly isLoading: boolean;
    public readonly hasErrors: boolean;
    public readonly isClickEnabled: boolean;
    public readonly onClick: () => void;

    constructor(
        id: string,
        status: RagIndicatorStatus,
        label: string,
        value: string,
        isLoading: boolean = false,
        hasErrors: boolean = false,
        isClickEnabled: boolean = false,
        onClick: () => void = () => {}
    ) {
        this.id = id;
        this.status = status;
        this.label = label;
        this.value = value;
        this.isLoading = isLoading;
        this.hasErrors = hasErrors;
        this.isClickEnabled = isClickEnabled;
        this.onClick = onClick;
    }
}
