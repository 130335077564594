const GET_SALES_PER_SQUARE_FOOT_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_SQUARE_FOOT_REQUEST";
const GET_SALES_PER_SQUARE_FOOT_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_SQUARE_FOOT_SUCCESS";
const GET_SALES_PER_SQUARE_FOOT_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_SQUARE_FOOT_FAILURE";
const GET_PROPERTY_COSTS_PER_SQUARE_FOOT_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_PROPERTY_COSTS_PER_SQUARE_FOOT_REQUEST";
const GET_PROPERTY_COSTS_PER_SQUARE_FOOT_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_PROPERTY_COSTS_PER_SQUARE_FOOT_SUCCESS";
const GET_PROPERTY_COSTS_PER_SQUARE_FOOT_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_PROPERTY_COSTS_PER_SQUARE_FOOT_FAILURE";
const GET_SALES_PER_POUND_OF_PROPERTY_COST_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_POUND_OF_PROPERTY_COST_REQUEST";
const GET_SALES_PER_POUND_OF_PROPERTY_COST_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_POUND_OF_PROPERTY_COST_SUCCESS";
const GET_SALES_PER_POUND_OF_PROPERTY_COST_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/storeSize/GET_SALES_PER_POUND_OF_PROPERTY_COST_FAILURE";

const types = {
    GET_SALES_PER_SQUARE_FOOT_REQUEST,
    GET_SALES_PER_SQUARE_FOOT_SUCCESS,
    GET_SALES_PER_SQUARE_FOOT_FAILURE,
    GET_PROPERTY_COSTS_PER_SQUARE_FOOT_REQUEST,
    GET_PROPERTY_COSTS_PER_SQUARE_FOOT_SUCCESS,
    GET_PROPERTY_COSTS_PER_SQUARE_FOOT_FAILURE,
    GET_SALES_PER_POUND_OF_PROPERTY_COST_REQUEST,
    GET_SALES_PER_POUND_OF_PROPERTY_COST_SUCCESS,
    GET_SALES_PER_POUND_OF_PROPERTY_COST_FAILURE
};

export default types;
