import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import Column from "components/visuals/Column";
import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/areaHealth/marketCategoryBreakdown";
import useColourPalette from "components/visuals/useColourPalette";
import stringUtils from "utils/stringUtils";

const MarketCategoryOpeningsVsClosures = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const marketCategoryOpeningsVsClosures = useSelector(state => selectors.marketCategoryOpeningsVsClosures(state));
    const { loading, error, store, comparator } = marketCategoryOpeningsVsClosures;
    const colourPalette = useColourPalette();

    const selectedStoreName = selectedStore.name;
    const selectedComparatorName = selectedComparator.name;

    const chartData = (rawData) => {
        return rawData?.map(row => {
            return {
                y: row.netOpening,
                name: row.primaryProductCategory,
                custom: {
                    closingCount: row.closingCount,
                    openingCount: row.openingCount
                }
            };
        });
    };

    const storeData = chartData(store);
    const comparatorData = chartData(comparator);

    const options = {
        xAxis: {
            type: "category"
        },
        series: [{
            name: selectedStoreName,
            data: storeData,
            color: colourPalette.comparators[0]
        }, {
            name: selectedComparatorName,
            data: comparatorData,
            color: colourPalette.comparators[1]
        }],

        legend: {
            enabled: true,
        },
        tooltip: {
            headerFormat: "<span style=\"font-size:13px;font-weight:bold;font-family:Open Sans\">{point.key}</span><table>",
            pointFormatter: function () {
                const point = this;
                const pointPrimaryProductCategory = point.name;

                const tooltipTableRows = (colour, data, selectedName) => {
                    const categoryFormatting = ["font-weight:bold;font-family:Open Sans;color:" + colour];
                    let pointData = data.filter(row => row["name"] === pointPrimaryProductCategory)[0];

                    if (pointData !== undefined) {
                        return stringUtils.tooltipHTMLOld([selectedName, "Net:", "Openings:", "Closures:"], ["", pointData.y, pointData.custom.openingCount, pointData.custom.closingCount], categoryFormatting);
                    } else {
                        return "";
                    }
                };

                const storeTable = tooltipTableRows(colourPalette.comparators[0], storeData, selectedStoreName);
                const comparatorTable = tooltipTableRows(colourPalette.comparators[1], comparatorData, selectedComparatorName);

                if (point.series.index === 0) {
                    return storeTable + comparatorTable;
                } else {
                    return comparatorTable + storeTable;
                }
            },
            footerFormat: "</table>",
            useHTML: true
        }
    };

    return (
        <Box data-cy="market-category-openings-vs-closures">
            <Column loading={loading} error={error} options={options} />
        </Box>
    );
};

export default MarketCategoryOpeningsVsClosures;
