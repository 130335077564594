import { combineReducers } from "redux";

import types from "./types";

const avgBasketSizeCMPMEmpty = {
    loading: false,
    error: false,
    latestFullMonthAvgProductsInBasket: 0,
    percentageDifference: 0,
    currentMonth: "CurrentMonth",
    pastMonth: "PastMonth"
};

const avgBasketSizeCMPM = (state = avgBasketSizeCMPMEmpty, action) => {
    switch (action.type) {
        case types.GET_AVG_BASKET_SIZE_CM_PM_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                latestFullMonthAvgProductsInBasket: 0,
                percentageDifference: 0,
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        case types.GET_AVG_BASKET_SIZE_CM_PM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                latestFullMonthAvgProductsInBasket: action.payload.latestFullMonthAvgProductsInBasket,
                percentageDifference: action.payload.percentageDifference,
                currentMonth: action.payload.currentMonth,
                pastMonth: action.payload.pastMonth
            };
        case types.GET_AVG_BASKET_SIZE_CM_PM_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                latestFullMonthAvgProductsInBasket: 0,
                percentageDifference: 0,
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        default:
            return state;
    }
};

const avgBasketValueCMPMEmpty = {
    loading: false,
    error: false,
    latestFullMonthAvgBasketValue: 0,
    percentageDifference: 0,
    currentMonth: "CurrentMonth",
    pastMonth: "PastMonth"
};

const avgBasketValueCMPM = (state = avgBasketValueCMPMEmpty, action) => {
    switch (action.type) {
        case types.GET_AVG_BASKET_VALUE_CM_PM_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                latestFullMonthAvgBasketValue: 0,
                percentageDifference: 0,
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        case types.GET_AVG_BASKET_VALUE_CM_PM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                latestFullMonthAvgBasketValue: action.payload.latestFullMonthAvgBasketValue,
                percentageDifference: action.payload.percentageDifference,
                currentMonth: action.payload.currentMonth,
                pastMonth: action.payload.pastMonth
            };
        case types.GET_AVG_BASKET_VALUE_CM_PM_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                latestFullMonthAvgBasketValue: 0,
                percentageDifference: 0,
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        default:
            return state;
    }
};

const categoryCMSalesEmpty = {
    loading: false,
    error: false,
    categorySales: [],
    drilldownData: []
};

const categoryCMSales = (state = categoryCMSalesEmpty, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_CM_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                categorySales: [],
                drilldownData: [],
                currentMonth: ["currentMonth", "currentYear"]
            };
        case types.GET_CATEGORY_CM_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                categorySales: action.payload.categorySales,
                drilldownData: action.payload.drilldownData,
                currentMonth: action.payload.currentMonth
            };
        case types.GET_CATEGORY_CM_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                categorySales: [],
                drilldownData: [],
                currentMonth: ["currentMonth", "currentYear"]
            };
        default:
            return state;
    }
};

const categoryCMSalesGrowthEmpty = {
    loading: false,
    error: false,
    productCategoryGrowth: [],
    drilldownData: []

};

const categoryCMSalesGrowth = (state = categoryCMSalesGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_CM_SALES_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                productCategoryGrowth: [],
                drilldownData: [],
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        case types.GET_CATEGORY_CM_SALES_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                productCategoryGrowth: action.payload.productCategoryGrowth,
                drilldownData: action.payload.drilldownData,
                currentMonth: action.payload.currentMonth,
                pastMonth: action.payload.pastMonth
            };
        case types.GET_CATEGORY_CM_SALES_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                productCategoryGrowth: [],
                drilldownData: [],
                currentMonth: ["CurrentMonth", "CurrentYear"],
                pastMonth: ["PastMonth", "PastYear"]
            };
        default:
            return state;
    }
};

const categoryYTDSalesEmpty = {
    loading: false,
    error: false,
    categorySales: [],
    drilldownData: []
};

const categoryYTDSales = (state = categoryYTDSalesEmpty, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_YTD_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                categorySales: [],
                drilldownData: []
            };
        case types.GET_CATEGORY_YTD_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                categorySales: action.payload.categorySales,
                drilldownData: action.payload.drilldownData,
            };
        case types.GET_CATEGORY_YTD_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                categorySales: [],
                drilldownData: []
            };
        default:
            return state;
    }
};

const categoryYTDSalesGrowthEmpty = {
    loading: false,
    error: false,
    productCategoryGrowth: [],
    drilldownData: []
};

const categoryYTDSalesGrowth = (state = categoryYTDSalesGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_YTD_SALES_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                productCategoryGrowth: [],
                drilldownData: []
            };
        case types.GET_CATEGORY_YTD_SALES_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                productCategoryGrowth: action.payload.productCategoryGrowth,
                drilldownData: action.payload.drilldownData
            };
        case types.GET_CATEGORY_YTD_SALES_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                productCategoryGrowth: [],
                drilldownData: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    avgBasketSizeCMPM,
    avgBasketValueCMPM,
    categoryCMSales,
    categoryCMSalesGrowth,
    categoryYTDSales,
    categoryYTDSalesGrowth
});

export default reducer;
