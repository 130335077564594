import React from "react";
import { Grid } from "@material-ui/core";

import TypesOfContributionGrowth from "./TypesOfContributionGrowth";
import OrganicVsInorganicContributionGrowth from "./OrganicVsInorganicContributionGrowth";

const ContributionGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TypesOfContributionGrowth />
            </Grid>
            <Grid item xs={12}>
                <OrganicVsInorganicContributionGrowth />
            </Grid>
        </Grid>
    );
};

export default ContributionGrowth;
