import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import mathUtils from "utils/mathUtils";

import Error from "./Error";
import PercentageIndicator from "./PercentageIndicator";
import Progress from "./Progress";

const useStyles = makeStyles(() => ({
    card: {
        width: "100%",
        height: "100%"
    }
}));

const KPIComparator = (props) => {
    const classes = useStyles();
    const { loading, error, label, value, percentage, switchColor, noDataMessage } = props;
    const theme = useTheme();
    const color = (() => { //ToDo: maybe expose status to be taken as prop instead of calculating based on percentage
        if (mathUtils.round(percentage, 1) < 0) return switchColor ? theme.palette.success.main : theme.palette.error.main;
        if (mathUtils.round(percentage, 1) > 0) return switchColor ? theme.palette.error.main : theme.palette.success.main;
        if (percentage === null) return theme.palette.text.primary;
        return theme.palette.warning.main;
    })();

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Card elevation={0} className={classes.card} data-cy="kpi-card-comparator">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <PercentageIndicator loading={false} error={false} value={percentage} switchColor={switchColor} noDataMessage={noDataMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="div" align="center" style={{ color: color }}>{value}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div" align="center">{label}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

KPIComparator.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    switchColor: PropTypes.bool,
    noDataMessage: PropTypes.string
};

KPIComparator.defaultProps = {
    loading: false,
    error: false,
    label: "",
    value: "",
    percentage: 0,
    switchColor: false,
    noDataMessage: ""
};

export default KPIComparator;
