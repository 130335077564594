import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Understand shopper spending for your selected location. Analyse the market size and where spending comes from in your location's catchment area. Dash breaks down
            spending across your market categories, determines which customers make up the majority of spend, and determines whether there's enough spending to support your store.
        </>
    );
};

export default Subtitle;
