import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import BarPositiveAndNegative from "components/visuals/BarPositiveAndNegative";

import { selectors } from "modules/customer/insights/portfolio/catchment/demographicBreakdown";

const AccommodationTypeIndex = () => {
    const accommodationTypeIndex = useSelector(state => selectors.accommodationTypeIndex(state));
    const { loading, error, categories, values } = accommodationTypeIndex;

    const options = {
        title: {
            text: "Accommodation type"
        },
        xAxis: [
            { categories: categories },
            {}
        ],
        series: [{
            data: values
        }]
    };

    return (
        <Box data-cy="accommodation-type-index">
            <BarPositiveAndNegative loading={loading} error={error} options={options} />
        </Box>
    );
};

export default AccommodationTypeIndex;
