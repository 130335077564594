import React from "react";

import { Box, Typography } from "@material-ui/core";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Filter from "./Filter";
import Search from "./Search";
import Sort from "./Sort";


import { selectSavedLocations } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppSelector } from "store";

const Title: React.FC = () => {
    const theme = useTheme();
    const savedLocations = useAppSelector(selectSavedLocations);
    const savedLocationsLabel = `${savedLocations.length} ${savedLocations.length === 1 ? "store" : "stores"}`;

    return (
        // @ts-ignore
        <Box position="sticky" top={0} bgcolor={theme.palette.quaternary.dark} zIndex={theme.zIndex.drawer}>
            <Box display="flex" alignItems="center" paddingBottom={2}>
                <BookmarkRoundedIcon />
                <Typography variant="h5" component="div">
                    &nbsp;&nbsp;Saved locations
                </Typography>
            </Box>
            <Search />
            <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                <Typography variant="body1" component="div">
                    &nbsp;&nbsp;{savedLocationsLabel}
                </Typography>
                <Spacer />
                <Filter />
                &nbsp;&nbsp;
                <Sort />
            </Box>
        </Box>
    );
};

export default Title;
