import React from "react";

import { Box, Typography } from "@material-ui/core";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { useTheme } from "@material-ui/core/styles";

const PaymentError: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <br />
            <br />
            <br />
            <br />
            {/* @ts-ignore */}
            <Box align="center">
                <ErrorOutlineRoundedIcon
                    style={{
                        width: theme.spacing(12),
                        height: theme.spacing(12),
                        color: theme.palette.error.main
                    }}
                />
            </Box>
            <br />
            <br />
            <Typography variant="h3" component="div" align="center">
                There's been an issue
                <br />
                setting up your payment
            </Typography>
            <br />
            <br />
            <br />
            <Typography variant="h4" component="div" align="center" color="textSecondary">
                Your payment setup was interrupted.
                <br />
                Please try again, or contact us if you're experiencing issues.
            </Typography>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default PaymentError;
