import React from "react";

import { Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";

const CostOverTimeLineChartTitle: React.FC = () => {
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);

    return <Typography variant="h6">{selectedStore?.costName} costs over time</Typography>;
};

export default CostOverTimeLineChartTitle;
