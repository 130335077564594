import { RootState } from "store";
import { AppThunk, createAppAsyncThunk } from "appThunk";
import {
    PayloadAction,
    createSelector,
    createSlice
} from "@reduxjs/toolkit";

import { MonthlyCosts, loadMonthlyCosts } from "./monthlyCosts";
import { MonthlyRevenue, loadMonthlyRevenue } from "./monthlyRevenue";
import { DataWrapper } from "domain/dataWrapper";
import {
    selectCostReductionOpportunityByStore,
    selectCostsByStores,
    selectReferenceDate,
    selectRetailCentres,
    selectSelectedStoreByCostType,
    selectStores
} from "modules/customer/insights/cost/costSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { openStreetView as mapOpenStreetView } from "modules/helpers/maps/mapsSlice";
import { notifyError } from "modules/notifications/notificationsSlice";

import _ from "lodash";
import { DateTime } from "luxon";
import { median } from "mathjs";
import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression } from "utils/sortUtils";

export enum CostMeasure {
    CostValue,
    CostAsPercentageOfRevenue
}

export enum CostsOverTimeLineChartGranularity {
    Month,
    Quarter,
    Year
}

interface StoreCostsState {
    isLoading: boolean,
    hasErrors: boolean,
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[],
    costMeasure: CostMeasure,
    costsOverTimeLineChartGranularity: CostsOverTimeLineChartGranularity
}

const initialState: StoreCostsState = {
    isLoading: false,
    hasErrors: false,
    monthlyCosts: [],
    monthlyRevenue: [],
    costMeasure: CostMeasure.CostValue,
    costsOverTimeLineChartGranularity: CostsOverTimeLineChartGranularity.Month
};

interface LoadStoreCostsResponse {
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[]
}

const storeCostsSlice = createSlice({
    name: "customer/inisghts/cost/storeCosts",
    initialState,
    reducers: {
        clearMonthlyCosts: (state) => {
            state.monthlyCosts = initialState.monthlyCosts;
        },
        clearMonthlyRevenue: (state) => {
            state.monthlyRevenue = initialState.monthlyRevenue;
        },
        setCostMeasure: (state, action: PayloadAction<CostMeasure>) => {
            state.costMeasure = action.payload;
        },
        clearCostMeasure: (state) => {
            state.costMeasure = initialState.costMeasure;
        },
        setCostsOverTimeLineChartGranularity: (state, action: PayloadAction<CostsOverTimeLineChartGranularity>) => {
            state.costsOverTimeLineChartGranularity = action.payload;
        },
        clearCostsOverTimeLineChartGranularity: (state) => {
            state.costsOverTimeLineChartGranularity = initialState.costsOverTimeLineChartGranularity;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadStoreCosts.pending, (state: StoreCostsState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadStoreCosts.rejected, (state: StoreCostsState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadStoreCosts.fulfilled, (state: StoreCostsState, action: PayloadAction<LoadStoreCostsResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.monthlyCosts = action.payload.monthlyCosts;
            state.monthlyRevenue = action.payload.monthlyRevenue;
        });
    }
});

export const {
    setCostMeasure,
    clearCostMeasure,
    setCostsOverTimeLineChartGranularity,
    clearCostsOverTimeLineChartGranularity
} = storeCostsSlice.actions;

export const loadStoreCosts = createAppAsyncThunk(
    "customer/insights/cost/storeCosts/loadStoreCosts",
    async (arg, thunkAPI) => {
        try {
            await thunkAPI.dispatch(setupCube());
            const state = thunkAPI.getState();

            const referenceDate = selectReferenceDate(state);
            const store = selectSelectedStoreByCostType(state);
            const costsByStore = selectCostsByStores(state);
            const similarStoresIds = costsByStore.data
                .find(item => item.storeId === store?.storeId
                    && item.costName === store.costName
                )?.similarStores
                .map(similarStore => similarStore.id);

            const monthlyCostsPromise = thunkAPI.dispatch(
                loadMonthlyCosts(
                    referenceDate,
                    store?.storeId,
                    similarStoresIds,
                    store?.costName
                )
            );

            const monthlyRevenuePromise = thunkAPI.dispatch(
                loadMonthlyRevenue(
                    referenceDate,
                    store?.storeId,
                    similarStoresIds,
                )
            );

            const results = await Promise.all([
                monthlyCostsPromise,
                monthlyRevenuePromise
            ]);
            const monthlyCosts = results[0];
            const monthlyRevenue = results[1];

            const loadCostResponse: LoadStoreCostsResponse = {
                monthlyCosts,
                monthlyRevenue
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading StoreCosts."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearStoreCosts = (): AppThunk => (dispatch) => {
    dispatch(storeCostsSlice.actions.clearMonthlyCosts());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.hasErrors;
};

export const selectMonthlyCosts = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.monthlyCosts;
};

export const selectMonthlyRevenue = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.monthlyRevenue;
};

export const selectCostMeasure = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.costMeasure;
};

export const selectCostsOverTimeLineChartGranularity = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.costsOverTimeLineChartGranularity;
};

export const openStreetView = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const selectedStore = selectSelectedStoreByCostType(state);
    const latitude = selectedStore?.latitude ?? 0;
    const longitude = selectedStore?.longitude ?? 0;
    dispatch(mapOpenStreetView(latitude, longitude));
};

export const selectClusterStoresCosts = createSelector(
    selectSelectedStoreByCostType,
    selectCostsByStores,
    selectCostReductionOpportunityByStore,
    (selectedStoreCostReductionOpportunity, costsByStores, costReductionOpportunityByStore) => {

        const selectedStoreCosts = costsByStores.data.find(item =>
            item.storeId === selectedStoreCostReductionOpportunity?.storeId
            && item.costName === selectedStoreCostReductionOpportunity.costName
        );

        if (!selectedStoreCostReductionOpportunity || !selectedStoreCosts) {
            return [];
        }

        const clusterStoreIDs = selectedStoreCosts.similarStores.map(item => item.id);

        return costReductionOpportunityByStore.data
            .filter(item => clusterStoreIDs.includes(item.storeId ?? "")
                && (item.costName === selectedStoreCostReductionOpportunity?.costName))
            .map(clusterStore => {
                const similarityScore =
                    selectedStoreCosts.similarStores.find(similarStore => similarStore.id === clusterStore.storeId)?.similarityScore ?? 0;
                return {
                    storeName: clusterStore.storeName ?? "",
                    costAsPercentageOfRevenue: clusterStore.costAsPercentageOfRevenue,
                    costValue: clusterStore.costValue,
                    variance: selectedStoreCostReductionOpportunity?.costValue - clusterStore.costValue,
                    similarityScore
                };
            })
            .sort((a, b) => numberSortExpression(a.similarityScore, b.similarityScore, SortDirection.DESC));
    }
);

export const selectStoreVsComparatorPerformance = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectSelectedStoreByCostType(state),
    (state: RootState) => selectStores(state),
    (state: RootState) => selectCostsByStores(state),
    (state: RootState) => selectRetailCentres(state),
    (isLoading, hasErrors, store, stores, costsByStores, retailCentres) => {
        interface StoreVsComparatorPerformanceMetrics {
            affluence: number,
            age: number,
            children: number,
            diversity: number,
            footfall: number,
            storeAge: number,
            storeSize: number
        }

        interface StoreVsComparatorPerformance {
            store: StoreVsComparatorPerformanceMetrics,
            clusterAverage: StoreVsComparatorPerformanceMetrics
        }

        const storeVsComparatorPerformance: DataWrapper<StoreVsComparatorPerformance> = {
            isLoading: stores.isLoading || costsByStores.isLoading || isLoading,
            hasErrors: stores.hasErrors || costsByStores.hasErrors || hasErrors,
            data: {
                store: {
                    affluence: 0,
                    age: 0,
                    children: 0,
                    diversity: 0,
                    footfall: 0,
                    storeAge: 0,
                    storeSize: 0
                },
                clusterAverage: {
                    affluence: 0,
                    age: 0,
                    children: 0,
                    diversity: 0,
                    footfall: 0,
                    storeAge: 0,
                    storeSize: 0
                }
            }
        };

        if (!store || !retailCentres || storeVsComparatorPerformance.isLoading || storeVsComparatorPerformance.hasErrors) {
            return storeVsComparatorPerformance;
        }

        const storeRetailCentre = retailCentres.find(retailCentre => retailCentre.id === store.retailCentreId);
        if (storeRetailCentre) {
            storeVsComparatorPerformance.data.store = {
                affluence: storeRetailCentre.affluenceCentile ?? 0,
                age: storeRetailCentre.ageCentile ?? 0,
                children: storeRetailCentre.childrenCentile ?? 0,
                diversity: storeRetailCentre.diversityCentile ?? 0,
                footfall: storeRetailCentre.footfallCentile ?? 0,
                storeAge: store.openingDate ? DateTime.fromISO(store.openingDate.toLocaleString(), { zone: 'utc' }).diffNow("days").negate().days : 0,
                storeSize: store.sizeInSquareFeet ?? 0
            };
        }
        else {
            return storeVsComparatorPerformance;
        }

        const storeSimilarStores = costsByStores.data.find(item =>
            item.storeId === store.storeId
            && item.costName === store.costName
        )?.similarStores;

        if (storeSimilarStores && storeSimilarStores.length > 0) {
            const similarStoresIds = storeSimilarStores.map(similarStore => similarStore.id);
            const similarStores = stores.data.filter(store => similarStoresIds.includes(store.id));
            const similarStoresRetailCentreIds = similarStores.map(s => s.retailCentreId);
            const similarStoresRetailCentres = retailCentres.filter(retailCentre => similarStoresRetailCentreIds.includes(retailCentre.id));

            let storesAgeInDays: number[] = [];
            similarStores.forEach(store => {
                if (store.openingDate) {
                    storesAgeInDays.push(
                        DateTime.fromISO(store.openingDate.toLocaleString(), { zone: 'utc' }).diffNow("days").negate().days
                    );
                }
            });

            if (similarStoresRetailCentres && similarStoresRetailCentres.length > 0) {
                storeVsComparatorPerformance.data.clusterAverage = {
                    affluence: median(similarStoresRetailCentres.map(metric => metric.affluenceCentile)),
                    age: median(similarStoresRetailCentres.map(metric => metric.ageCentile)),
                    children: median(similarStoresRetailCentres.map(metric => metric.childrenCentile)),
                    diversity: median(similarStoresRetailCentres.map(metric => metric.diversityCentile)),
                    footfall: median(similarStoresRetailCentres.map(metric => metric.footfallCentile)),
                    storeAge: median(storesAgeInDays),
                    storeSize: median(similarStores.map(metric => metric.sizeInSquareFeet))
                };
            }
            else {
                return storeVsComparatorPerformance;
            }
        }

        return storeVsComparatorPerformance;
    }
);

export const selectCostsOverTime = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSelectedStoreByCostType,
    selectMonthlyCosts,
    selectMonthlyRevenue,
    selectCostsOverTimeLineChartGranularity,
    (
        isLoading,
        hasErrors,
        store,
        monthlyCosts,
        monthlyRevenue,
        costsOverTimeLineChartGranularity
    ) => {
        interface LabourCost {
            date: DateTime,
            costValue: number,
            costAsPercentageOfRevenue: number
        }

        interface LabourCostOverTime {
            store: LabourCost[]
            clusterAverage: LabourCost[]
        }

        const labourCostsOverTime: DataWrapper<LabourCostOverTime> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                store: [],
                clusterAverage: []
            }
        };

        if (!store || !monthlyCosts || !monthlyRevenue) {
            return labourCostsOverTime;
        }

        if (costsOverTimeLineChartGranularity === CostsOverTimeLineChartGranularity.Month) {
            // Selected store values
            const storeMonthlyCosts = monthlyCosts.filter(costs => costs.storeId === store.storeId);
            const storeMonthlyRevenue = monthlyRevenue.filter(revenue => revenue.storeId === store.storeId);
            storeMonthlyCosts.forEach(cost => {
                const revenue = storeMonthlyRevenue.find(revenue => revenue.date.equals(cost.date));
                labourCostsOverTime.data.store.push({
                    date: cost.date,
                    costValue: cost.costValue,
                    costAsPercentageOfRevenue: mathUtils.safePercentage(cost.costValue, (revenue ? revenue.revenue : 0))
                });
            });

            // Cluster average values
            const clusterMonthlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId !== store.storeId)
                .groupBy(monthlyCosts => monthlyCosts.date)
                .value();
            const clusterMonthlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId !== store.storeId)
                .groupBy(monthlyRevenue => monthlyRevenue.date)
                .value();
            _(clusterMonthlyCosts).forEach(monthlyCost => {
                const monthlyRevenue: any[] = [];
                _(clusterMonthlyRevenue).forEach(revenue => {
                    revenue.forEach(r => {
                        if (r.date.equals(monthlyCost[0].date)) {
                            monthlyRevenue.push(r);
                        }
                    });
                });
                labourCostsOverTime.data.clusterAverage.push({
                    date: monthlyCost[0].date,
                    costValue: median(monthlyCost.map(cost => cost.costValue)),
                    costAsPercentageOfRevenue: mathUtils.safePercentage(
                        median(monthlyCost.map(cost => cost.costValue)),
                        median(monthlyRevenue.map(revenue => revenue.revenue))
                    )
                });
            });
        }
        else if (costsOverTimeLineChartGranularity === CostsOverTimeLineChartGranularity.Year) {
            // Selected store values
            const storeYearlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId === store.storeId)
                .groupBy(costs => costs.date.year)
                .value();
            const storeYearlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId === store.storeId)
                .groupBy(revenue => revenue.date.year)
                .value();
            _(storeYearlyCosts).forEach(yearlyCost => {
                const yearlyRevenue: MonthlyRevenue[] = [];
                _(storeYearlyRevenue).forEach(revenue => {
                    revenue.forEach(r => {
                        if (r.date.year === yearlyCost[0].date.year) {
                            yearlyRevenue.push(r);
                        }
                    });
                });
                labourCostsOverTime.data.store.push({
                    date: yearlyCost[0].date,
                    costValue: yearlyCost.reduce((total, item) => item.costValue + total, 0),
                    costAsPercentageOfRevenue: mathUtils.safePercentage(
                        yearlyCost.reduce((total, item) => item.costValue + total, 0),
                        yearlyRevenue.reduce((total, item) => item.revenue + total, 0)
                    )
                });
            });

            // Cluster average values
            const clusterYearlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId !== store.storeId)
                .groupBy(costs => costs.date.year)
                .value();
            const clusterYearlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId !== store.storeId)
                .groupBy(revenue => revenue.date.year)
                .value();
            _(clusterYearlyCosts).forEach(yearlyCosts => {
                const yearlyRevenue: any[] = [];
                _(clusterYearlyRevenue).forEach(revenue => {
                    revenue.forEach(r => {
                        if (r.date.year === yearlyCosts[0].date.year) {
                            yearlyRevenue.push(r);
                        }
                    });
                });

                const groupedYearlyCosts = _(yearlyCosts).groupBy(costs => costs.storeId).value();
                const totalCosts: number[] = [];
                _(groupedYearlyCosts).forEach(cost => {
                    totalCosts.push(cost.reduce((total, item) => item.costValue + total, 0));
                });

                const groupedYearlyRevenue = _(yearlyRevenue).groupBy(revenue => revenue.storeId).value();
                const totalRevenue: number[] = [];
                _(groupedYearlyRevenue).forEach(revenue => {
                    totalRevenue.push(revenue.reduce((total, item) => item.revenue + total, 0));
                });

                labourCostsOverTime.data.clusterAverage.push({
                    date: yearlyCosts[0].date,
                    costValue: median(totalCosts),
                    costAsPercentageOfRevenue: mathUtils.safePercentage(median(totalCosts), median(totalRevenue))
                });
            });
        }
        else {
            // Selected store values
            const storeMonthlyCosts = monthlyCosts.filter(costs => costs.storeId === store.storeId);
            const storeMonthlyRevenue = monthlyRevenue.filter(costs => costs.storeId === store.storeId);
            const groupedStoreMonthlyCosts: Record<string, { costs: MonthlyCosts[], revenue: MonthlyRevenue[] }> = {};

            storeMonthlyCosts.forEach(cost => {
                const date = cost.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedStoreMonthlyCosts[key]) {
                    groupedStoreMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedStoreMonthlyCosts[key].costs.push(cost);
            });

            storeMonthlyRevenue.forEach(revenue => {
                const date = revenue.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedStoreMonthlyCosts[key]) {
                    groupedStoreMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedStoreMonthlyCosts[key].revenue.push(revenue);
            });

            const storeQuarterData = _(groupedStoreMonthlyCosts)
                .map((group, key) => {
                    return {
                        date: DateTime.fromISO(key),
                        costValue: group.costs.reduce((total, item) => item.costValue, 0),
                        costAsPercentageOfRevenue: mathUtils.safePercentage(
                            group.costs.reduce((total, item) => item.costValue + total, 0),
                            group.revenue.reduce((total, item) => item.revenue + total, 0)
                        )
                    };
                })
                .value();

            labourCostsOverTime.data.store = storeQuarterData;

            // Cluster average values
            const clusterMonthlyCosts = monthlyCosts.filter(costs => costs.storeId !== store.storeId);
            const clusterMonthlyRevenue = monthlyRevenue.filter(costs => costs.storeId !== store.storeId);
            const groupedClusterMonthlyCosts: Record<string, { costs: MonthlyCosts[], revenue: MonthlyRevenue[] }> = {};

            clusterMonthlyCosts.forEach(cost => {
                const date = cost.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedClusterMonthlyCosts[key]) {
                    groupedClusterMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedClusterMonthlyCosts[key].costs.push(cost);
            });

            clusterMonthlyRevenue.forEach(revenue => {
                const date = revenue.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedClusterMonthlyCosts[key]) {
                    groupedClusterMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedClusterMonthlyCosts[key].revenue.push(revenue);
            });

            const clusterQuarterData = _(groupedClusterMonthlyCosts)
                .map((group, key) => {
                    const groupedCostsByStoreId = _(group.costs).groupBy(g => g.storeId).value();
                    const groupedRevenueByStoreId = _(group.revenue).groupBy(g => g.storeId).value();

                    const totalCosts: number[] = [];
                    _(groupedCostsByStoreId).forEach(store => {
                        totalCosts.push(store.reduce((total, item) => item.costValue + total, 0));
                    });

                    const totalRevenue: number[] = [];
                    _(groupedRevenueByStoreId).forEach(store => {
                        totalRevenue.push(store.reduce((total, item) => item.revenue + total, 0));
                    });

                    return {
                        date: DateTime.fromISO(key),
                        costValue: median(totalCosts),
                        costAsPercentageOfRevenue: mathUtils.safePercentage(median(totalCosts), median(totalRevenue))
                    };
                })
                .value();

            labourCostsOverTime.data.clusterAverage = clusterQuarterData;
        }

        return labourCostsOverTime;
    }
);

export default storeCostsSlice;
