import { combineReducers } from "redux";

import types from "./types";

const contributionPerformanceVsBudgetEmpty = {
    loading: false,
    error: false,
    contributionFYTD: 0,
    contributionFYTG: 0,
    budgetFY: 0,
    FYlabel: ""
};

const contributionPerformanceVsBudget = (state = contributionPerformanceVsBudgetEmpty, action) => {
    switch (action.type) {
        case types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                contributionFYTD: 0,
                contributionFYTG: 0,
                budgetFY: 0,
                FYlabel: ""
            };
        case types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                contributionFYTD: action.payload.contributionFYTD,
                contributionFYTG: action.payload.contributionFYTG,
                budgetFY: action.payload.budgetFY,
                FYlabel: action.payload.FYlabel
            };
        case types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                contributionFYTD: 0,
                contributionFYTG: 0,
                budgetFY: 0,
                FYlabel: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    contributionPerformanceVsBudget
});

export default reducer;
