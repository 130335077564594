import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const MarketShareSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Understand how much of the local market your {store?.name} store attracts, compared
            to {comparator?.name}. See how this changes over time, and how the opening and closures of
            competitors' stores has affected it. We calculate market share by taking the overall sales demand for your
            company's main market category in the local area, and dividing this by your store's sales.
        </>
    );
};

export default MarketShareSubtitle;
