import React from "react";

import { Button, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import {
    addLead,
    clearLead,
    hideAddLead,
    selectAddLeadVisibility,
    selectLead,
    setLead,
    showAddLead
} from "modules/admin/leads/leadsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

const AddLead: React.FC = () => {
    const dispatch = useAppDispatch();
    const lead = useAppSelector(selectLead);
    const addLeadVisibility = useAppSelector(selectAddLeadVisibility);
    const show = addLeadVisibility.isVisible;

    const handleAddLeadClick = () => {
        dispatch(showAddLead());
    };

    const handleSubmitClick = () => {
        dispatch(addLead());
    };

    const handleCancelClick = () => {
        dispatch(hideAddLead());
        dispatch(clearLead());
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = event.target.value;
        const newLead = { ...lead, firstName, errors: { ...lead.errors, firstName: "" } };
        dispatch(setLead(newLead));
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = event.target.value;
        const newLead = { ...lead, lastName, errors: { ...lead.errors, lastName: "" } };
        dispatch(setLead(newLead));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const newLead = { ...lead, email, errors: { ...lead.errors, email: "" } };
        dispatch(setLead(newLead));
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;
        const newLead = { ...lead, phoneNumber, errors: { ...lead.errors, phoneNumber: "" } };
        dispatch(setLead(newLead));
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyName = event.target.value;
        const newLead = { ...lead, companyName, errors: { ...lead.errors, companyName: "" } };
        dispatch(setLead(newLead));
    };

    const handleCompanyRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyRegistrationNumber = event.target.value;
        const newLead = {
            ...lead,
            companyRegistrationNumber,
            errors: { ...lead.errors, companyRegistrationNumber: "" }
        };
        dispatch(setLead(newLead));
    };

    const handleJobRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const jobRole = event.target.value;
        const newLead = { ...lead, jobRole, errors: { ...lead.errors, jobRole: "" } };
        dispatch(setLead(newLead));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddIcon />}
                disableElevation
                fullWidth
                onClick={handleAddLeadClick}
            >
                Add lead
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Add lead</Typography>
                }
                content={
                    <>
                        <StyledTextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.firstName}
                            onChange={handleFirstNameChange}
                            error={!!lead.errors.firstName}
                            helperText={lead.errors.firstName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.lastName}
                            onChange={handleLastNameChange}
                            error={!!lead.errors.lastName}
                            helperText={lead.errors.lastName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.email}
                            onChange={handleEmailChange}
                            error={!!lead.errors.email}
                            helperText={lead.errors.email}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Phone number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={!!lead.errors.phoneNumber}
                            helperText={lead.errors.phoneNumber}
                            fullWidth
                        />
                        <StyledTextField
                            label="Company name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.companyName}
                            onChange={handleCompanyNameChange}
                            error={!!lead.errors.companyName}
                            helperText={lead.errors.companyName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Company registration number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.companyRegistrationNumber}
                            onChange={handleCompanyRegistrationNumberChange}
                            error={!!lead.errors.companyRegistrationNumber}
                            helperText={lead.errors.companyRegistrationNumber}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Job role"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.jobRole}
                            onChange={handleJobRoleChange}
                            error={!!lead.errors.jobRole}
                            helperText={lead.errors.jobRole}
                            required
                            fullWidth
                        />
                    </>
                }
                actions={
                    <>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                            Add lead
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default AddLead;
