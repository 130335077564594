import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Grid, Typography, Button, Card, CardMedia, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled,
        width: theme.spacing(6)
    }
}))(Typography);

const YourDashTeam = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const teams = [{
        name: "Retail experts",
        totalMembers: 6
    }, {
        name: "Data engineers",
        totalMembers: 13
    }, {
        name: "Web developers",
        totalMembers: 9,
    }, {
        name: "Data scientists",
        totalMembers: 7
    }, {
        name: "QA testers",
        totalMembers: 5,
    }, {
        name: "Product designers",
        totalMembers: 7,
    }, {
        name: "Cloud engineers",
        totalMembers: 4,
    }];

    const handleClick = () => {
        dispatch(trackEvent("Our story", "Meet the team click", "Meet the team button"));
    };

    return (
        <PageSection
            color={theme.palette.common.white}
            bgcolor={theme.palette.background.defaultDark}
            dataCy="pages-landing-our-story-who-we-are"
        >
            <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" gutterBottom>
                Your Dash team
            </Typography>
            <br />
            <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align="center" gutterBottom>
                We're a team of retail experts, data scientists and engineers, product designers, visualisation
                <br />
                experts and web developers. That's the wealth of knowledge and experience you'll
                <br />
                benefit from when using Dash.
            </Typography>
            <br />
            <br />
            <br />
            <Grid container spacing={6} direction="row-reverse" alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/our-story/your-dash-team.png" alt="your dash team" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    {teams.map((team, index) =>
                        <Box key={index} display="flex" paddingTop={1} paddingBottom={1}>
                            <StyledTypography variant="h2" component="div" align="right">
                                {team.totalMembers}
                            </StyledTypography>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography variant="h2" component="div" align="left">
                                {team.name}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Box align="center">
                <Button
                    component={NavLink}
                    to="/meet-the-team"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleClick}
                    disableElevation
                    data-cy="meet-the-team-btn"
                >
                    Meet the team
                </Button>
            </Box>
        </PageSection>
    );
};

export default YourDashTeam;
