import React from "react";
import PropTypes from "prop-types";

import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const Page = (props) => {
    const { dataCy } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const headerHeight = isMobile ? "112px" : "128px";
    const footerHeight = isMobile ? "777px" : "610px";
    const minHeight = `calc(100vh - ${headerHeight} - ${footerHeight})`;

    return (
        <Box minHeight={minHeight} data-cy={dataCy}>
            {props.children}
        </Box>
    );
};

Page.propTypes = {
    dataCy: PropTypes.string
};

export default Page;
