import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/catchment/customerProfiles";

const TotalPeopleInCatchmentArea = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const totalPeopleInCatchmentArea = useSelector(state => selectors.totalPeopleInCatchmentArea(state));
    const { loading, error, value, percentageDifference } = totalPeopleInCatchmentArea;
    const valueFormatted = numberFormatter.toDecimalPlaces(value, 0);
    const label = `${selectedStore.name} total people in catchment area vs ${selectedComparator.name}`;

    return (
        <Box width="100%" height="100%" data-cy="total-people-in-catchment-area">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default TotalPeopleInCatchmentArea;
