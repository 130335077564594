import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class OutputAreaFootfall {
    public readonly outputAreaCode: string;
    public readonly footfallDensity: number;

    constructor(
        outputAreaCode: string,
        footfallDensity: number
    ) {
        this.outputAreaCode = outputAreaCode;
        this.footfallDensity = footfallDensity;
    }
}

export const loadOutputAreaFootfall = (retailCentreId: number | undefined): AppThunk<Promise<OutputAreaFootfall[]>> => async (dispatch) => {
    try {
        if (!retailCentreId) {
            return [];
        }
        const retailCentreIdString = retailCentreId.toString();
        const queryOaFootfall = {
            dimensions: [
                "F_YearlyFootfall.OAID",
                "F_YearlyFootfall.Prev12MonthsFootfall"
            ],
            filters: [{
                member: "F_YearlyFootfall.RetailCentreID",
                operator: "equals",
                values: [retailCentreIdString]
            }]
        };
        const resultSetOaFootfall = await dispatch(cubeLoad(queryOaFootfall)) as unknown as ResultSet;
        const rawOaFootfall = resultSetOaFootfall.rawData();
        return rawOaFootfall.map(rawRow => new OutputAreaFootfall(
            rawRow["F_YearlyFootfall.OAID"],
            rawRow["F_YearlyFootfall.Prev12MonthsFootfall"] ?? 0,
        ));
    } catch (error) {
        dispatch(logError("Error loading OutputAreaFootfall.", error));
        throw error;
    }
};
