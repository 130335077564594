import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { median, sign } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectClientRegistration,
    selectComparator,
    selectComplementaryCategories,
    selectStore
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { AverageStoreLifetime, loadAverageStoreLifetimes } from "./averageStoreLifetime";
import { StoreCategory, createStoreCategories } from "./storeCategory";
import { StoreCountsNetOpenings, loadStoreCountsNetOpenings } from "./storeCountsNetOpenings";

interface NetOpenVsClosuresOverTime {
    timeframe: string;
    percentageChange: number;
    netOpenings: number;
}

export enum TimelineField {
    storeCountCurrent = "storeCountCurrent",
    storeCount6MonthsAgo = "storeCount6MonthsAgo",
    storeCount12MonthsAgo = "storeCount12MonthsAgo",
    storeCount24MonthsAgo = "storeCount24MonthsAgo",
    storeCount60MonthsAgo = "storeCount60MonthsAgo"
}

interface StoresVisibility {
    showStoreOpenings: boolean,
    showStoreClosures: boolean
}

interface LoadAreaHealthResponse {
    storeCountsNetOpenings: StoreCountsNetOpenings[],
    averageStoreLifetime: AverageStoreLifetime[],
    storeCategories: StoreCategory[]
}

interface AreaHealthState {
    isLoading: boolean,
    hasErrors: boolean,
    timelineStartField: TimelineField,
    timelineEndField: TimelineField,
    storeCountsNetOpenings: StoreCountsNetOpenings[],
    averageStoreLifespan: AverageStoreLifetime[],
    storeCategories: StoreCategory[],
    storesVisibility: StoresVisibility,
}

const initialState: AreaHealthState = {
    isLoading: false,
    hasErrors: false,
    timelineStartField: TimelineField.storeCount12MonthsAgo,
    timelineEndField: TimelineField.storeCountCurrent,
    storeCountsNetOpenings: [],
    averageStoreLifespan: [],
    storeCategories: [],
    storesVisibility: {
        showStoreOpenings: true,
        showStoreClosures: true
    }
};

const areaHealthSlice = createSlice({
    name: "customer/insights/portfolioNew/areaHealth",
    initialState,
    reducers: {
        setTimelineStartField: (state, action: PayloadAction<TimelineField>) => {
            state.timelineStartField = action.payload;
        },
        setTimelineEndField: (state, action: PayloadAction<TimelineField>) => {
            state.timelineEndField = action.payload;
        },
        clearStoreCountsNetOpenings: (state) => {
            state.storeCountsNetOpenings = initialState.storeCountsNetOpenings;
        },
        clearAverageStoreLifetime: (state) => {
            state.averageStoreLifespan = initialState.averageStoreLifespan;
        },
        clearStoreCategories: (state) => {
            state.storeCategories = initialState.storeCategories;
        },
        toggleStoreCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const storeCategoryName = action.payload;
            state.storeCategories.find(storeCategory => storeCategory.name === storeCategoryName)?.toggleIsSelected();
        },
        chooseAllStoreCategories: (state) => {
            state.storeCategories.forEach(storeCategory => storeCategory.setIsSelected(true));
        },
        deselectAllStoreCategories: (state) => {
            state.storeCategories.forEach(storeCategory => storeCategory.setIsSelected(false));
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadAreaHealth.pending, (state: AreaHealthState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadAreaHealth.rejected, (state: AreaHealthState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.storeCountsNetOpenings = initialState.storeCountsNetOpenings;
            state.storeCategories = initialState.storeCategories;
        });
        builder.addCase(loadAreaHealth.fulfilled, (state: AreaHealthState, action: PayloadAction<LoadAreaHealthResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.storeCountsNetOpenings = action.payload.storeCountsNetOpenings;
            state.averageStoreLifespan = action.payload.averageStoreLifetime;
            state.storeCategories = action.payload.storeCategories;
        });
    }
});

export const {
    setTimelineStartField,
    setTimelineEndField,
    chooseAllStoreCategories,
    deselectAllStoreCategories,
    toggleStoreCategoryIsSelected,
} = areaHealthSlice.actions;

export const loadAreaHealth = createAppAsyncThunk(
    "customer/insights/portfolioNew/areaHealth/loadAreaHealth",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const selectedStore = selectStore(state);
            const comparator = selectComparator(state);
            const clientRegistration = selectClientRegistration(state);
            const primaryStoreCategory = clientRegistration?.primaryStoreCategory ?? "";
            const complementaryCategories = selectComplementaryCategories(state);
            const selectedStoreCategory = selectedStore?.kpmgStoreCategory ?? "";

            const comparatorStores = comparator?.getStores() ?? [];
            const selectedAndComparatorStores = selectedStore ? [selectedStore].concat(comparatorStores) : [];

            const storeCountsNetOpeningsPromise = thunkAPI.dispatch(loadStoreCountsNetOpenings(selectedAndComparatorStores));
            const averageStoreLifetimePromise = thunkAPI.dispatch(loadAverageStoreLifetimes(selectedAndComparatorStores));
            const results = await Promise.all([storeCountsNetOpeningsPromise, averageStoreLifetimePromise]);
            const storeCountsNetOpenings = results[0];
            const averageStoreLifetime = results[1];
            const storeCategories = createStoreCategories(primaryStoreCategory, selectedStoreCategory, complementaryCategories, storeCountsNetOpenings);
            const loadAreaHealthResponse: LoadAreaHealthResponse = {
                storeCountsNetOpenings,
                averageStoreLifetime,
                storeCategories
            };

            return loadAreaHealthResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading AreaHealth.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const clearAreaHealth = (): AppThunk => (dispatch) => {
    dispatch(areaHealthSlice.actions.clearStoreCountsNetOpenings());
    dispatch(areaHealthSlice.actions.clearAverageStoreLifetime());
    dispatch(areaHealthSlice.actions.clearStoreCategories());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.hasErrors;
};

export const selectTimelineStartField = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.timelineStartField;
};

export const selectTimelineEndField = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.timelineEndField;
};

export const selectStoreCountsNetOpenings = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.storeCountsNetOpenings;
};

export const selectAverageStoreLifetime = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.averageStoreLifespan;
};

export const selectStoreCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.storeCategories;
};

export const selectStoresVisibility = (state: RootState) => {
    return state.customer.insights.portfolioNew.areaHealth.storesVisibility;
};

export const selectGroupedStoreCountsOverTime = createSelector(
    selectStoreCountsNetOpenings,
    (storeCountsNetOpenings) => {
        return _(storeCountsNetOpenings)
            .filter(storeCounts => storeCounts.isRetail)
            .groupBy(openings => openings.storeID)
            .map((group, key) => ({
                storeID: key,
                storeValues: group
                    .reduce((accum: any, openings) => {
                        accum.storeCountCurrent += openings.storeCountCurrent;
                        accum.storeCount6MonthsAgo += openings.storeCount6MonthsAgo;
                        accum.storeCount12MonthsAgo += openings.storeCount12MonthsAgo;
                        accum.storeCount24MonthsAgo += openings.storeCount24MonthsAgo;
                        accum.storeCount60MonthsAgo += openings.storeCount60MonthsAgo;
                        return accum;
                    }, {
                        storeCountCurrent: 0,
                        storeCount6MonthsAgo: 0,
                        storeCount12MonthsAgo: 0,
                        storeCount24MonthsAgo: 0,
                        storeCount60MonthsAgo: 0,
                    })
            }))
            .map(store => ({
                storeID: store.storeID,
                storeCountCurrent: store.storeValues.storeCountCurrent,
                storeCount6MonthsAgo: store.storeValues.storeCount6MonthsAgo,
                storeCount12MonthsAgo: store.storeValues.storeCount12MonthsAgo,
                storeCount24MonthsAgo: store.storeValues.storeCount24MonthsAgo,
                storeCount60MonthsAgo: store.storeValues.storeCount60MonthsAgo,
            }))
            .value();
    }
);

export const selectNetOpenVsClosuresOverTime = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, selectedStore, comparator) => {
        interface NetOpeningsData {
            selectedStore: NetOpenVsClosuresOverTime[],
            comparator: NetOpenVsClosuresOverTime[]
        }

        const netOpenVsClosuresOverTime: DataWrapper<NetOpeningsData> = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: [],
                comparator: []
            }
        };

        if (isLoading || hasErrors || groupedStoreCountsOverTime.length === 0) {
            return netOpenVsClosuresOverTime;
        }

        const selectedStoreStoreCounts = groupedStoreCountsOverTime?.find(storeCounts => storeCounts.storeID === selectedStore?.id);
        netOpenVsClosuresOverTime.data.selectedStore = selectedStoreStoreCounts ? [{
            timeframe: "25 - 60 months ago",
            percentageChange: mathUtils.safePercentageChange(selectedStoreStoreCounts.storeCount24MonthsAgo, selectedStoreStoreCounts.storeCount60MonthsAgo),
            netOpenings: selectedStoreStoreCounts.storeCount24MonthsAgo - selectedStoreStoreCounts.storeCount60MonthsAgo
        }, {
            timeframe: "13 - 24 months ago",
            percentageChange: mathUtils.safePercentageChange(selectedStoreStoreCounts.storeCount12MonthsAgo, selectedStoreStoreCounts.storeCount24MonthsAgo),
            netOpenings: selectedStoreStoreCounts.storeCount12MonthsAgo - selectedStoreStoreCounts.storeCount24MonthsAgo
        }, {
            timeframe: "7 - 12 months ago",
            percentageChange: mathUtils.safePercentageChange(selectedStoreStoreCounts.storeCount6MonthsAgo, selectedStoreStoreCounts.storeCount12MonthsAgo),
            netOpenings: selectedStoreStoreCounts.storeCount6MonthsAgo - selectedStoreStoreCounts.storeCount12MonthsAgo
        }, {
            timeframe: "0 - 6 months ago",
            percentageChange: mathUtils.safePercentageChange(selectedStoreStoreCounts.storeCountCurrent, selectedStoreStoreCounts.storeCount6MonthsAgo),
            netOpenings: selectedStoreStoreCounts.storeCountCurrent - selectedStoreStoreCounts.storeCount6MonthsAgo
        }] : [];

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorStoreCounts = groupedStoreCountsOverTime.filter(storeCounts => comparatorStoreIDs?.includes(storeCounts.storeID));

        const comparatorOpenings = comparatorStoreCounts.map(storeCounts => ({
            storeID: storeCounts.storeID,
            zeroToSixMonths: {
                percentageChange: mathUtils.safePercentageChange(storeCounts.storeCountCurrent, storeCounts.storeCount6MonthsAgo),
                netOpenings: storeCounts.storeCountCurrent - storeCounts.storeCount6MonthsAgo
            },
            sevenToTwelveMonths: {
                percentageChange: mathUtils.safePercentageChange(storeCounts.storeCount6MonthsAgo, storeCounts.storeCount12MonthsAgo),
                netOpenings: storeCounts.storeCount6MonthsAgo - storeCounts.storeCount12MonthsAgo
            },
            thirteenToTwentyFourMonths: {
                percentageChange: mathUtils.safePercentageChange(storeCounts.storeCount12MonthsAgo, storeCounts.storeCount24MonthsAgo),
                netOpenings: storeCounts.storeCount12MonthsAgo - storeCounts.storeCount24MonthsAgo
            },
            twentyFiveToSixtyMonths: {
                percentageChange: mathUtils.safePercentageChange(storeCounts.storeCount24MonthsAgo, storeCounts.storeCount60MonthsAgo),
                netOpenings: storeCounts.storeCount24MonthsAgo - storeCounts.storeCount60MonthsAgo
            }
        }));
        if (comparatorOpenings.length !== 0) {
            netOpenVsClosuresOverTime.data.comparator = [{
                timeframe: "25 - 60 months ago",
                percentageChange: median(comparatorOpenings.map(openings => openings.twentyFiveToSixtyMonths.percentageChange)),
                netOpenings: median(comparatorOpenings.map(openings => openings.twentyFiveToSixtyMonths.netOpenings))
            }, {
                timeframe: "13 - 24 months ago",
                percentageChange: median(comparatorOpenings.map(openings => openings.thirteenToTwentyFourMonths.percentageChange)),
                netOpenings: median(comparatorOpenings.map(openings => openings.thirteenToTwentyFourMonths.netOpenings))
            }, {
                timeframe: "7 - 12 months ago",
                percentageChange: median(comparatorOpenings.map(openings => openings.sevenToTwelveMonths.percentageChange)),
                netOpenings: median(comparatorOpenings.map(openings => openings.sevenToTwelveMonths.netOpenings))
            }, {
                timeframe: "0 - 6 months ago",
                percentageChange: median(comparatorOpenings.map(openings => openings.zeroToSixMonths.percentageChange)),
                netOpenings: median(comparatorOpenings.map(openings => openings.zeroToSixMonths.netOpenings))
            }];
        }
        return netOpenVsClosuresOverTime;
    }
);

export const selectYoYRawNetStoreOpenings = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, selectedStore, comparator) => {
        const netStoreOpenings = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };

        if (isLoading || hasErrors || groupedStoreCountsOverTime.length === 0) {
            return netStoreOpenings;
        }

        const selectedOpenings = groupedStoreCountsOverTime.find(openings => openings.storeID === selectedStore?.id);
        const selectedStoreCount12MonthsAgo = selectedOpenings?.storeCount12MonthsAgo ?? 0;
        const selectedStoreCountCurrent = selectedOpenings?.storeCountCurrent ?? 0;
        netStoreOpenings.data.selectedStore = selectedStoreCountCurrent - selectedStoreCount12MonthsAgo;

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorOpenings = groupedStoreCountsOverTime
            .filter(openings => comparatorStoreIDs?.includes(openings.storeID))
            .map(storeCounts => storeCounts.storeCountCurrent - storeCounts.storeCount12MonthsAgo);

        netStoreOpenings.data.comparator = comparatorOpenings.length !== 0 ? median(comparatorOpenings) : 0;
        return netStoreOpenings;
    }
);

export const selectNumberOfStoresInLocalArea = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectGroupedStoreCountsOverTime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, groupedStoreCountsOverTime, selectedStore, comparator) => {
        const netStoreOpenings = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };

        if (isLoading || hasErrors || groupedStoreCountsOverTime.length === 0) {
            return netStoreOpenings;
        }

        const selectedStoreStoreCount = groupedStoreCountsOverTime.find(storeCount => storeCount.storeID === selectedStore?.id);
        netStoreOpenings.data.selectedStore = selectedStoreStoreCount?.storeCountCurrent ?? 0;

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorStoreCounts = groupedStoreCountsOverTime
            .filter(openings => comparatorStoreIDs?.includes(openings.storeID))
            .map(storeCounts => storeCounts.storeCountCurrent);

        netStoreOpenings.data.comparator = comparatorStoreCounts.length !== 0 ? median(comparatorStoreCounts) : 0;
        return netStoreOpenings;
    }
);

export const selectAverageStoreLifetimeInYears = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectAverageStoreLifetime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, averageStoreLifetime, selectedStore, comparator) => {
        const averageStoreLifetimes = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };

        if (isLoading || hasErrors || averageStoreLifetime.length === 0) {
            return averageStoreLifetimes;
        }

        const selectedStoreAverageStoreLifetime = averageStoreLifetime.find(storeLifetime => storeLifetime.storeID === selectedStore?.id);
        averageStoreLifetimes.data.selectedStore = (selectedStoreAverageStoreLifetime?.averageStoreLifetime ?? 0) / 12;

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorStoreAverageLifetimes = averageStoreLifetime
            .filter(storeLifetime => comparatorStoreIDs?.includes(storeLifetime.storeID))
            .map(storeLifetime => storeLifetime.averageStoreLifetime / 12);

        averageStoreLifetimes.data.comparator = comparatorStoreAverageLifetimes.length !== 0 ? median(comparatorStoreAverageLifetimes) : 0;
        return averageStoreLifetimes;
    }
);

export const selectShortTermNetOpenings = createSelector(
    selectNetOpenVsClosuresOverTime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (netOpenVsClosuresOverTime, selectedStore, comparator) => {
        const id = "short-term-net-openings";
        const label = "Short term net openings";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = netOpenVsClosuresOverTime;
        if (isLoading || hasErrors || netOpenVsClosuresOverTime.data.selectedStore.length === 0 || netOpenVsClosuresOverTime.data.comparator.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const storeData = data.selectedStore;
        const comparatorData = data.comparator;
        const selectedStoreOpenings = storeData[3].netOpenings + storeData[2].netOpenings;
        const comparatorOpenings = comparatorData[3].netOpenings + comparatorData[2].netOpenings;

        const percentageDifference = mathUtils.safePercentageChange(selectedStoreOpenings, comparatorOpenings) * sign(comparatorOpenings);

        if (percentageDifference > 50) {
            status = RagIndicatorStatus.Green;
            value = `Short term net openings in the local area of ${selectedStore?.name} are markedly ahead of the ${comparator?.name} median`;
        } else if (percentageDifference <= 50 && percentageDifference >= -50) {
            status = RagIndicatorStatus.Amber;
            value = `Short term net openings in the local area of ${selectedStore?.name} are broadly in line with the ${comparator?.name} median`;
        } else {
            status = RagIndicatorStatus.Red;
            value = `Short term net openings in the local area of ${selectedStore?.name} are markedly behind the ${comparator?.name} median`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectLongTermNetOpenings = createSelector(
    selectNetOpenVsClosuresOverTime,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (netOpenVsClosuresOverTime, selectedStore, comparator) => {
        const id = "long-term-net-openings";
        const label = "Long term net openings";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = netOpenVsClosuresOverTime;
        if (isLoading || hasErrors || netOpenVsClosuresOverTime.data.selectedStore.length === 0 || netOpenVsClosuresOverTime.data.comparator.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const storeData = data.selectedStore;
        const comparatorData = data.comparator;
        const selectedStoreOpenings = storeData[0].netOpenings + storeData[1].netOpenings + storeData[2].netOpenings + storeData[3].netOpenings;
        const comparatorOpenings = comparatorData[0].netOpenings + comparatorData[1].netOpenings + comparatorData[2].netOpenings + comparatorData[3].netOpenings;

        const percentageDifference = mathUtils.safePercentageChange(selectedStoreOpenings, comparatorOpenings) * sign(comparatorOpenings);

        if (percentageDifference > 50) {
            status = RagIndicatorStatus.Green;
            value = `Long term net openings in the local area of ${selectedStore?.name} are markedly ahead of the ${comparator?.name} median`;
        } else if (percentageDifference <= 50 && percentageDifference >= -50) {
            status = RagIndicatorStatus.Amber;
            value = `Long term net openings in the local area of ${selectedStore?.name} are broadly in line with the ${comparator?.name} median`;
        } else {
            status = RagIndicatorStatus.Red;
            value = `Long term net openings in the local area of ${selectedStore?.name} are markedly behind the ${comparator?.name} median`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

// 7.2

export const selectStoreCategoryOpeningsVsClosures = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectStoreCountsNetOpenings,
    selectStoreCategories,
    selectTimelineStartField,
    selectTimelineEndField,
    (selectedStore, comparator, storeCounts, storeCategories, timelineStartField, timelineEndField) => {
        const comparatorStoreIDs = comparator?.getStores().map(store => store.id) ?? [];
        const selectedStoreCategories = storeCategories.filter(storeCategory => storeCategory.isSelected());
        const selectedStoreCategoriesNames = selectedStoreCategories.map(storeCategory => storeCategory.name);
        const filteredStoreCounts = storeCounts.filter(counts => selectedStoreCategoriesNames.includes(counts.storeCategory));

        const storeCountsByCategory = _(filteredStoreCounts)
            .groupBy(storeCategoryCounts => storeCategoryCounts.storeCategory)
            .map((storeCountsByCategory, storeCategory) => ({
                storeCategory,
                storeCountsByClientStore: _(storeCountsByCategory)
                    .groupBy(retailCentreCounts => retailCentreCounts.storeID)
                    .map((storeCountsByRetailCentre, storeID) => ({
                        storeID,
                        groupedCounts: storeCountsByRetailCentre.reduce((accumulator, counts) => {
                            accumulator.storeCountCurrent += counts.storeCountCurrent;
                            accumulator.storeCount6MonthsAgo += counts.storeCount6MonthsAgo;
                            accumulator.storeCount12MonthsAgo += counts.storeCount12MonthsAgo;
                            accumulator.storeCount24MonthsAgo += counts.storeCount24MonthsAgo;
                            accumulator.storeCount60MonthsAgo += counts.storeCount60MonthsAgo;
                            return accumulator;
                        }, {
                            storeCountCurrent: 0,
                            storeCount6MonthsAgo: 0,
                            storeCount12MonthsAgo: 0,
                            storeCount24MonthsAgo: 0,
                            storeCount60MonthsAgo: 0
                        })
                    }))
                    .map(storeCountsByRetailCentre => ({
                        storeID: storeCountsByRetailCentre.storeID,
                        storeCountCurrent: storeCountsByRetailCentre.groupedCounts.storeCountCurrent,
                        storeCount6MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount6MonthsAgo,
                        storeCount12MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount12MonthsAgo,
                        storeCount24MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount24MonthsAgo,
                        storeCount60MonthsAgo: storeCountsByRetailCentre.groupedCounts.storeCount60MonthsAgo
                    }))
                    .value()
            }))
            .value();

        return selectedStoreCategoriesNames.map(storeCategory => {
            const openingsVsClosures = {
                storeCategory,
                selectedStore: {
                    percentageChange: 0,
                    netOpenings: 0
                },
                comparator: {
                    percentageChange: 0,
                    netOpenings: 0
                }
            };
            const storeCounts = storeCountsByCategory.find(counts => counts.storeCategory === storeCategory);
            if (!storeCounts) {
                return openingsVsClosures;
            }

            const selectedStoreStoreCounts = storeCounts.storeCountsByClientStore.find(counts => counts.storeID === selectedStore?.id);
            const selectedStoreTimelineEndCount = selectedStoreStoreCounts?.[timelineEndField] ?? 0;
            const selectedStoreTimelineStartCount = selectedStoreStoreCounts?.[timelineStartField] ?? 0;
            openingsVsClosures.selectedStore.percentageChange = mathUtils.safePercentageChange(selectedStoreTimelineEndCount, selectedStoreTimelineStartCount);
            openingsVsClosures.selectedStore.netOpenings = selectedStoreTimelineEndCount - selectedStoreTimelineStartCount;

            const comparatorStoreCounts = storeCounts.storeCountsByClientStore.filter(counts => comparatorStoreIDs.includes(counts.storeID));
            const comparatorOpenings = comparatorStoreCounts.map(storeCounts => {
                const benchmarkTimelineEndCount = storeCounts?.[timelineEndField] ?? 0;
                const benchmarkTimelineStartCount = storeCounts?.[timelineStartField] ?? 0;

                return {
                    percentageChange: mathUtils.safePercentageChange(benchmarkTimelineEndCount, benchmarkTimelineStartCount),
                    netOpenings: benchmarkTimelineEndCount - benchmarkTimelineStartCount
                };

            });

            openingsVsClosures.comparator.percentageChange =
                median(comparatorOpenings.length !== 0 ? comparatorOpenings.map(item => item.percentageChange) : 0);
            openingsVsClosures.comparator.netOpenings =
                median(comparatorOpenings.length !== 0 ? comparatorOpenings.map(item => item.netOpenings) : 0);

            return openingsVsClosures;
        });
    }
);

export const selectComplementaryMarketCategories = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectStoreCountsNetOpenings,
    selectStoreCategories,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, storeCountsNetOpenings, storeCategories, selectedStore, comparator) => {
        const id = "complementary-market-categories";
        const label = "Complementary market categories";
        let value = "";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors || !selectedStore || !comparator || storeCountsNetOpenings.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const complementaryCategories = storeCategories.filter(category => category.flags.includes("complementary")).map(category => category.name);

        if (complementaryCategories.length === 0) {
            value = "This indicator isn't available because it requires you to set your company's complementary markets. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.";
            status = RagIndicatorStatus.NoData;
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const complementaryCategoryNetOpenings = _(storeCountsNetOpenings)
            .filter(storeCounts => complementaryCategories.includes(storeCounts.storeCategory))
            .groupBy(storeCounts => storeCounts.storeID)
            .map((group, storeID) => {
                const totalStoreCountCurrent = group.reduce((accumulator, storeCounts) => accumulator + storeCounts.storeCountCurrent, 0);
                const totalStoreCount60MonthsAgo = group.reduce((accumulator, storeCounts) => accumulator + storeCounts.storeCount60MonthsAgo, 0);
                return {
                    storeID,
                    netOpenings: totalStoreCountCurrent - totalStoreCount60MonthsAgo
                };
            })
            .value();

        const selectedStoreOpenings = complementaryCategoryNetOpenings.find(item => item.storeID === selectedStore.id)?.netOpenings ?? 0;

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);
        const comparatorOpenings = complementaryCategoryNetOpenings
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .map(item => item.netOpenings);

        const comparatorMedianOpenings = comparatorOpenings.length !== 0 ? median(comparatorOpenings) : 0;
        const percentageDifference = mathUtils.safePercentageChange(selectedStoreOpenings, comparatorMedianOpenings) * sign(comparatorMedianOpenings);

        if (percentageDifference > 50) {
            status = RagIndicatorStatus.Green;
            value = `The net openings of complementary stores in the last 5 years for ${selectedStore?.name} are markedly ahead of the ${comparator?.name} median`;
        } else if (percentageDifference <= 50 && percentageDifference >= -50) {
            status = RagIndicatorStatus.Amber;
            value = `The net openings of complementary stores in the last 5 years for ${selectedStore?.name} are broadly in line with the ${comparator?.name} median`;
        } else {
            status = RagIndicatorStatus.Red;
            value = `The net openings of complementary stores in the last 5 years for ${selectedStore?.name} are markedly behind the ${comparator?.name} median`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectNonComplementaryMarketCategories = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectStoreCountsNetOpenings,
    selectStoreCategories,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, storeCountsNetOpenings, storeCategories, selectedStore, comparator) => {
        const id = "non-complementary-market-categories";
        const label = "Non-complementary market categories";
        let value = "";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors || !selectedStore || !comparator || storeCountsNetOpenings.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const nonComplementaryCategories = storeCategories.filter(category => category.flags.includes("non-complementary")).map(category => category.name);

        if (nonComplementaryCategories.length === 0) {
            value = "This indicator isn't available because it requires you to set your company's non-complementary markets. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.";
            status = RagIndicatorStatus.NoData;
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const nonComplementaryCategoryNetOpenings = _(storeCountsNetOpenings)
            .filter(storeCounts => nonComplementaryCategories.includes(storeCounts.storeCategory))
            .groupBy(storeCounts => storeCounts.storeID)
            .map((group, storeID) => {
                const totalStoreCountCurrent = group.reduce((accumulator, storeCounts) => accumulator + storeCounts.storeCountCurrent, 0);
                const totalStoreCount60MonthsAgo = group.reduce((accumulator, storeCounts) => accumulator + storeCounts.storeCount60MonthsAgo, 0);
                return {
                    storeID,
                    netOpenings: totalStoreCountCurrent - totalStoreCount60MonthsAgo
                };
            })
            .value();

        const selectedStoreOpenings = nonComplementaryCategoryNetOpenings.find(item => item.storeID === selectedStore.id)?.netOpenings ?? 0;

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);
        const comparatorOpenings = nonComplementaryCategoryNetOpenings
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .map(item => item.netOpenings);

        const comparatorMedianOpenings = comparatorOpenings.length !== 0 ? median(comparatorOpenings) : 0;
        const percentageDifference = mathUtils.safePercentageChange(selectedStoreOpenings, comparatorMedianOpenings) * sign(comparatorMedianOpenings);

        if (percentageDifference > 50) {
            status = RagIndicatorStatus.Red;
            value = `The net openings of non-complementary stores in the last 5 years for ${selectedStore?.name} are markedly ahead of the ${comparator?.name} median`;
        } else if (percentageDifference <= 50 && percentageDifference >= -50) {
            status = RagIndicatorStatus.Amber;
            value = `The net openings of non-complementary stores in the last 5 years for ${selectedStore?.name} are broadly in line with the ${comparator?.name} median`;
        } else {
            status = RagIndicatorStatus.Green;
            value = `The net openings of non-complementary stores in the last 5 years for ${selectedStore?.name} are markedly behind the ${comparator?.name} median`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export default areaHealthSlice;
