import { immerable } from "immer";

export class ExistingStore {
    [immerable] = true;

    public readonly name: string;
    public readonly storeCategoryId: number;
    public readonly retailCentreId: number;
    private _isSelected: boolean;

    constructor(
        name: string,
        storeCategoryId: number,
        retailCentreId: number,
        isSelected: boolean
    ) {
        this.name = name;
        this.storeCategoryId = storeCategoryId;
        this.retailCentreId = retailCentreId;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}
