import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const MarketShareSubtitle = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Understand the retail density of the area surrounding your {store?.name} store, compared
            to {comparator?.name}. Is your store positioned near others that will drive footfall? Or is it
            relying solely on its own presence to attract customers?
        </>
    );
};

export default MarketShareSubtitle;
