import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class StoreCategoryCentileMetrics {
    public readonly storeCategoryId: number;
    public readonly centile: number;
    public readonly incomeBracket: string;
    public readonly homeOwnershipPercentage: number;
    public readonly occupancyRating: string;
    public readonly age17To35Percentage: number;
    public readonly age36To55Percentage: number;
    public readonly age56PlusPercentage: number;
    public readonly children0To5Percentage: number;
    public readonly children6To10Percentage: number;
    public readonly children11To16PlusPercentage: number;
    public readonly minorityEthnicPercentage: number;
    public readonly languageNotEnglishOrWelshPercentage: number;
    public readonly notBornInUkPercentage: number;
    public readonly populationDensityBracket: string;
    public readonly livingInFlatPercentage: number;
    public readonly numberOfCars: string;
    public readonly netOpenings: number;
    public readonly footfallLevel: number;

    constructor(
        storeCategoryId: number,
        centile: number,
        incomeBracket: string,
        homeOwnershipPercentage: number,
        occupancyRating: string,
        age17To35Percentage: number,
        age36To55Percentage: number,
        age56PlusPercentage: number,
        children0To5Percentage: number,
        children6To10Percentage: number,
        children11To16PlusPercentage: number,
        minorityEthnicPercentage: number,
        languageNotEnglishOrWelshPercentage: number,
        notBornInUkPercentage: number,
        populationDensityBracket: string,
        livingInFlatPercentage: number,
        numberOfCars: string,
        netOpenings: number,
        footfallLevel: number
    ) {
        this.storeCategoryId = storeCategoryId;
        this.centile = centile;
        this.incomeBracket = incomeBracket;
        this.homeOwnershipPercentage = homeOwnershipPercentage;
        this.occupancyRating = occupancyRating;
        this.age17To35Percentage = age17To35Percentage;
        this.age36To55Percentage = age36To55Percentage;
        this.age56PlusPercentage = age56PlusPercentage;
        this.children0To5Percentage = children0To5Percentage;
        this.children6To10Percentage = children6To10Percentage;
        this.children11To16PlusPercentage = children11To16PlusPercentage;
        this.minorityEthnicPercentage = minorityEthnicPercentage;
        this.languageNotEnglishOrWelshPercentage = languageNotEnglishOrWelshPercentage;
        this.notBornInUkPercentage = notBornInUkPercentage;
        this.populationDensityBracket = populationDensityBracket;
        this.livingInFlatPercentage = livingInFlatPercentage;
        this.numberOfCars = numberOfCars;
        this.netOpenings = netOpenings;
        this.footfallLevel = footfallLevel;
    }
}

export const loadStoreCategoryCentilesMetrics = (targetStoreCategoryId: number | undefined, accountId: string, useMLCatchments: boolean): AppThunk<Promise<StoreCategoryCentileMetrics[]>> => async (dispatch) => {
    if (!targetStoreCategoryId) {
        return [];
    }
    try {
        const catchmentAccountId = useMLCatchments ? accountId : "00000000-0000-0000-0000-000000000000";
        const query = {
            dimensions: [
                "LocationBenchmarkCentileMetrics.StoreCategory_ID",
                "LocationBenchmarkCentileMetrics.Centile",
                "LocationBenchmarkCentileMetrics.MedianIncomeBracket",
                "LocationBenchmarkCentileMetrics.MedianHomeOwnership_Percent",
                "LocationBenchmarkCentileMetrics.MedianOccupancyRating",
                "LocationBenchmarkCentileMetrics.Age17to35_Percent",
                "LocationBenchmarkCentileMetrics.Age36to55_Percent",
                "LocationBenchmarkCentileMetrics.Age56Plus_Percent",
                "LocationBenchmarkCentileMetrics.Children0to5_Percent",
                "LocationBenchmarkCentileMetrics.Children6to10_Percent",
                "LocationBenchmarkCentileMetrics.Children11to16_Percent",
                "LocationBenchmarkCentileMetrics.MedianMinorityEthnic_Percent",
                "LocationBenchmarkCentileMetrics.MedianLangNotEnglishOrWelsh_Percent",
                "LocationBenchmarkCentileMetrics.MedianNotBornInUK_Percent",
                "LocationBenchmarkCentileMetrics.MedianPopulationDensityBracket",
                "LocationBenchmarkCentileMetrics.MedianLivingInFlat_Percent",
                "LocationBenchmarkCentileMetrics.MedianNumberOfCars",
                "LocationBenchmarkCentileMetrics.MedianNetOpenings",
                "LocationBenchmarkCentileMetrics.MedianFootfallDensity"
            ],
            filters: [{
                member: "LocationBenchmarkCentileMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "LocationBenchmarkCentileMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => new StoreCategoryCentileMetrics(
            targetStoreCategoryId,
            Number(row["LocationBenchmarkCentileMetrics.Centile"]),
            String(row["LocationBenchmarkCentileMetrics.MedianIncomeBracket"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianHomeOwnership_Percent"]),
            String(row["LocationBenchmarkCentileMetrics.MedianOccupancyRating"]),
            Number(row["LocationBenchmarkCentileMetrics.Age17to35_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.Age36to55_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.Age56Plus_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.Children0to5_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.Children6to10_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.Children11to16_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianMinorityEthnic_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianLangNotEnglishOrWelsh_Percent"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianNotBornInUK_Percent"]),
            String(row["LocationBenchmarkCentileMetrics.MedianPopulationDensityBracket"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianLivingInFlat_Percent"]),
            String(row["LocationBenchmarkCentileMetrics.MedianNumberOfCars"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianNetOpenings"]),
            Number(row["LocationBenchmarkCentileMetrics.MedianFootfallDensity"])
        ));
    } catch (error) {
        dispatch(logError("Error loading StoreCategoryCentilesMetrics.", error));
        throw error;
    }
};
