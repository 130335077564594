import React from "react";

import { Grid } from "@material-ui/core";

import StoreCategoryOpeningsVsClosures from "./StoreCategoryOpeningsVsClosures";
import ComplementaryMarketCategories from "./ComplementaryMarketCategories";
import NonComplementaryMarketCategories from "./NonComplementaryMarketCategories";

const StoreCategoryBreakdown: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreCategoryOpeningsVsClosures />
            </Grid>
            <Grid item xs={12}>
                <ComplementaryMarketCategories />
            </Grid>
            <Grid item xs={12}>
                <NonComplementaryMarketCategories />
            </Grid>
        </Grid>

    );
};

export default StoreCategoryBreakdown;
