import React from "react";

import { Box, Card, CardContent, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.tertiary.main
    }
}));

const Privacy = () => {
    const classes = useStyles();

    return (
        <Box data-cy="pages-customer-account-settings-privacy">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Privacy
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Dash's privacy policy is available to view on the KPMG website.
                    </Typography>
                    <Link
                        href="https://home.kpmg/uk/en/home/misc/privacy.html"
                        target="_blank"
                        underline="none"
                        variant="subtitle1"
                        className={classes.link}
                    >
                        Privacy policy
                    </Link>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Privacy;
