import React from "react";

const SalesSubtitle: React.FC = () => {
    return (
        <>
            Understand both the past and future sales performance of $[selected granularity]. Dig down into growth
            periods and identify which $[selected granularity] $[selected granularity data points] are driving
            $[selected granularity]'s performance.
        </>
    );
};

export default SalesSubtitle;
