import { operations as revenueOperations } from "./revenue";
import { operations as growthOperations } from "./growth";
import { operations as rankedGrowthOperations } from "./rankedGrowth";
import { operations as productCategoryMixOperations } from "./productCategoryMix";
import { operations as productCategoryGrowthOperations } from "./productCategoryGrowth";

const getSalesData = () => async (dispatch) => {
    dispatch(revenueOperations.getStoreRevenueVsPY());
    dispatch(revenueOperations.getStoreForecastRevenueVsPY());
    dispatch(revenueOperations.getSalesHistory());
    dispatch(revenueOperations.getStoreHistoricSalesTrend());
    dispatch(revenueOperations.getStoreFutureSalesTrend());
    dispatch(growthOperations.getStoreYoYGrowthVsComparator());
    dispatch(growthOperations.getStoreForecastYoYGrowthVsComparator());
    dispatch(growthOperations.getStoreVsComparatorMoMGrowth());
    dispatch(growthOperations.getStoreHistoricGrowthVsComparator());
    dispatch(growthOperations.getStoreForecastGrowthVsComparator());
    dispatch(rankedGrowthOperations.getStoreVsComparatorRankedLastTwelveMonthsGrowth());
    dispatch(productCategoryMixOperations.getProductCategoryMix());
    dispatch(productCategoryGrowthOperations.getHistoricProdGrowthStoreVsComp());
};

const operations = {
    getSalesData
};

export default operations;
