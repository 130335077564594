import { median } from "mathjs";

import actions from "./actions";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getLifetimeOfStores = () => async (dispatch, getState) => {
    dispatch(actions.getLifetimeOfStoresRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedOAID = selectedStoreSelector.outputAreaCode;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);

        const relevantOAs = comparatorOAIDs.concat(selectedOAID);

        const query = {
            measures: [
                "OpeningsAndClosures.AvgStoreLifespan"
            ],
            dimensions: [
                "OpeningsAndClosures.MainOAID"
            ],
            filters: [{
                member: "OpeningsAndClosures.MainOAID",
                operator: "equals",
                values: relevantOAs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const selectedData = resultSet.loadResponses[0].data.find(item => item["OpeningsAndClosures.MainOAID"] === selectedOAID);
        const value = selectedData["OpeningsAndClosures.AvgStoreLifespan"].toFixed(2);

        const comparatorData = resultSet.loadResponses[0].data.filter(item => comparatorOAIDs.includes(item["OpeningsAndClosures.MainOAID"]));
        const comparatorValues = comparatorData.map(item => item["OpeningsAndClosures.AvgStoreLifespan"]);

        const comparatorMedian = median(comparatorValues);

        const percentageDifference = 100 * ((value - comparatorMedian) / comparatorMedian);

        dispatch(actions.getLifetimeOfStoresSuccess(value, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getLifetimeOfStoresFailure());
        dispatch(logError("Error loading LifetimeOfStores.", error));
    }
};

const getNetOpenVsClosuresOverTime = () => async (dispatch, getState) => {
    dispatch(actions.getNetOpenVsClosuresOverTimeRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedOAID = selectedStoreSelector.outputAreaCode;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);

        const relevantOAs = comparatorOAIDs.concat(selectedOAID);
        //ToDo bring through separate openings/closures figures for tooltip
        const query = {
            measures: [
                "OpeningsAndClosures.SumZeroToSixMonths",
                "OpeningsAndClosures.SumSevenToTwelveMonths",
                "OpeningsAndClosures.SumThirteenToTwentyFourMonths",
                "OpeningsAndClosures.SumTwentyFiveToSixtyMonths",
            ],
            dimensions: [
                "OpeningsAndClosures.MainOAID"
            ],
            filters: [
                {
                    "member": "OpeningsAndClosures.MainOAID",
                    "operator": "equals",
                    "values": relevantOAs
                }
            ]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const selectedData = resultSet.loadResponses[0].data.find(item => item["OpeningsAndClosures.MainOAID"] === selectedOAID);

        const store = [];
        store[0] = selectedData["OpeningsAndClosures.SumTwentyFiveToSixtyMonths"] ?? 0;
        store[1] = selectedData["OpeningsAndClosures.SumThirteenToTwentyFourMonths"] ?? 0;
        store[2] = selectedData["OpeningsAndClosures.SumSevenToTwelveMonths"] ?? 0;
        store[3] = selectedData["OpeningsAndClosures.SumZeroToSixMonths"] ?? 0;

        const comparatorData = resultSet.loadResponses[0].data.filter(item => comparatorOAIDs.includes(item["OpeningsAndClosures.MainOAID"]));

        const comparator = comparatorData.map(item => ({
            MainOAID: item["OpeningsAndClosures.MainOAID"],
            SumZeroToSixMonths: item["OpeningsAndClosures.SumZeroToSixMonths"] ?? 0,
            SumSevenToTwelveMonths: item["OpeningsAndClosures.SumSevenToTwelveMonths"] ?? 0,
            SumThirteenToTwentyFourMonths: item["OpeningsAndClosures.SumThirteenToTwentyFourMonths"] ?? 0,
            SumTwentyFiveToSixtyMonths: item["OpeningsAndClosures.SumTwentyFiveToSixtyMonths"] ?? 0
        }));

        dispatch(actions.getNetOpenVsClosuresOverTimeSuccess(store, comparator));
    }
    catch (error) {
        dispatch(actions.getNetOpenVsClosuresOverTimeFailure());
        dispatch(logError("Error loading NetOpenVsClosuresOverTime.", error));
    }
};


const operations = {
    getLifetimeOfStores,
    getNetOpenVsClosuresOverTime,
};

export default operations;
