import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";

import useColourPalette from "components/visuals/useColourPalette";
import Column from "components/visuals/Column";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/areaHealth/openingsAndClosures";

const NetOpenVsClosuresOverTime = () => {
    const colourPalette = useColourPalette();
    const theme = useTheme();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const netOpenVsClosuresOverTime = useSelector(state => selectors.netOpenVsClosuresOverTime(state));
    const { loading, error, store, comparator } = netOpenVsClosuresOverTime;

    const quadrantAnnotation = (annotation, useXMax, useYMax) => {
        const chart = annotation.chart;

        if (chart === undefined) {
            return;
        }

        const xAxis = chart.xAxis[0], yAxis = chart.yAxis[0];

        const annotationSpacingFractionX = 0.005;
        const annotationSpacingFractionY = chart.plotWidth/chart.plotHeight * annotationSpacingFractionX;

        const axisVal = (axis, useMax, spacingFraction) => {
            const max = axis.max, min = axis.min;
            const range = max - min;
            const spacer = range * spacingFraction;
    
            return useMax ? max - spacer : min + spacer;
        };

        return {
            x: axisVal(xAxis, useXMax, annotationSpacingFractionX),
            xAxis: 0,
            y: axisVal(yAxis, useYMax, annotationSpacingFractionY),
            yAxis: 0
        };
    };

    const options = {
        title: {
            text: `Net store openings within the local area of ${selectedStore.name} vs ${selectedComparator.name}`,
            enabled: true
        },
        xAxis: {
            categories: ["25 – 60 Months Ago", "13 – 24 Months Ago", "7 – 12 Months ago", "0 – 6 Months Ago"],
            plotBands: [{
                from: 1.5,
                to: 10,
                color: colourPalette.RAGChartZones[2]
            }]
        },
        yAxis: {
            title: {
                text: "Openings/closures within local area"
            }
        },
        annotations: [{
            draggable: '',
            labelOptions: {
                shape: 'rect',
                borderRadius: 6,
                borderWidth: 0,
                overflow: 'justify',
                y: 0,
                verticalAlign: 'bottom',
                style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '13px',
                    color: 'white'
                }
            },
            labels: [{
                point: function(annotation) {
                    return quadrantAnnotation(annotation, true, true);
                },
                x: 100,
                align: 'right',
                text: "Short-term",
                backgroundColor: "#4D4860"
            }, {
                point: function (annotation) {
                    return quadrantAnnotation(annotation, false, true);
                },
                x: -100,
                align: 'right',
                text: "Long-term",
                backgroundColor: "#292542"
            }]
        }],
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                },
                custom: {
                    printMe: true
                }
            }
        },
        tooltip: {
            enabled: true,
            shared: true,
      
            formatter: function () {
              const points = this.points;
              const storePoint = points[0];
              const comparatorPoint = points[1];
      
              const categoryArr = [storePoint.series.name, comparatorPoint.series.name];
              const categoryFormatArr = [`color:${storePoint.series.color};font-weight:bold`,``,`color:${comparatorPoint.series.color};font-weight:bold`,``];
              const valuesArr = [``,numberFormatter.toDecimalPlaces(storePoint.y, 0), ``,numberFormatter.toDecimalPlaces(comparatorPoint.y, 0)];
      
              return `<table>${stringUtils.tooltipHTML(categoryArr, { header: storePoint.key, values: (valuesArr), categoryFormattingArr: categoryFormatArr})}</table>`;
            },
            useHTML: true
        },
        series: [{
            name: selectedStore.name,
            data: store,
            color: colourPalette.comparators[0]
        }, {
            name: selectedComparator.name,
            data: comparator,
            color: colourPalette.comparators[1]
        }]
    };

    return (
        <Box data-cy="net-open-vs-closures-over-time">
            <Column loading={loading} error={error} options={options} />
        </Box>
    );
};

export default NetOpenVsClosuresOverTime;
