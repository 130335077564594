const GET_AVG_BASKET_SIZE_CM_PM_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_SIZE_CM_PM_REQUEST";
const GET_AVG_BASKET_SIZE_CM_PM_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_SIZE_CM_PM_SUCCESS";
const GET_AVG_BASKET_SIZE_CM_PM_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_SIZE_CM_PM_FAILURE";
const GET_AVG_BASKET_VALUE_CM_PM_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_VALUE_CM_PM_REQUEST";
const GET_AVG_BASKET_VALUE_CM_PM_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_VALUE_CM_PM_SUCCESS";
const GET_AVG_BASKET_VALUE_CM_PM_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_AVG_BASKET_VALUE_CM_PM_FAILURE";
const GET_CATEGORY_CM_SALES_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_REQUEST";
const GET_CATEGORY_CM_SALES_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_SUCCESS";
const GET_CATEGORY_CM_SALES_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_FAILURE";
const GET_CATEGORY_CM_SALES_GROWTH_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_GROWTH_REQUEST";
const GET_CATEGORY_CM_SALES_GROWTH_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_GROWTH_SUCCESS";
const GET_CATEGORY_CM_SALES_GROWTH_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_CM_SALES_GROWTH_FAILURE";
const GET_CATEGORY_YTD_SALES_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_REQUEST";
const GET_CATEGORY_YTD_SALES_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_SUCCESS";
const GET_CATEGORY_YTD_SALES_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_FAILURE";
const GET_CATEGORY_YTD_SALES_GROWTH_REQUEST = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_GROWTH_REQUEST";
const GET_CATEGORY_YTD_SALES_GROWTH_SUCCESS = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_GROWTH_SUCCESS";
const GET_CATEGORY_YTD_SALES_GROWTH_FAILURE = "spark/customer/insights/performance/sales/productCategoryBreakdown/GET_CATEGORY_YTD_SALES_GROWTH_FAILURE";

const types = {
    GET_AVG_BASKET_SIZE_CM_PM_REQUEST,
    GET_AVG_BASKET_SIZE_CM_PM_SUCCESS,
    GET_AVG_BASKET_SIZE_CM_PM_FAILURE,
    GET_AVG_BASKET_VALUE_CM_PM_REQUEST,
    GET_AVG_BASKET_VALUE_CM_PM_SUCCESS,
    GET_AVG_BASKET_VALUE_CM_PM_FAILURE,
    GET_CATEGORY_CM_SALES_REQUEST,
    GET_CATEGORY_CM_SALES_SUCCESS,
    GET_CATEGORY_CM_SALES_FAILURE,
    GET_CATEGORY_CM_SALES_GROWTH_REQUEST,
    GET_CATEGORY_CM_SALES_GROWTH_SUCCESS,
    GET_CATEGORY_CM_SALES_GROWTH_FAILURE,
    GET_CATEGORY_YTD_SALES_REQUEST,
    GET_CATEGORY_YTD_SALES_SUCCESS,
    GET_CATEGORY_YTD_SALES_FAILURE,
    GET_CATEGORY_YTD_SALES_GROWTH_REQUEST,
    GET_CATEGORY_YTD_SALES_GROWTH_SUCCESS,
    GET_CATEGORY_YTD_SALES_GROWTH_FAILURE
};

export default types;
