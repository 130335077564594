import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { BinaryFilter, Query, ResultSet } from "@cubejs-client/core";

export class Competitor {
    public readonly storeId: string;
    public readonly fascia: string;
    public readonly directCompetitor: boolean;
    public readonly dateOpened: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly size: number;

    constructor(
        storeId: string,
        fascia: string,
        directCompetitor: boolean,
        dateOpened: string,
        latitude: number,
        longitude: number,
        size: number,
    ) {
        this.storeId = storeId;
        this.fascia = fascia;
        this.directCompetitor = directCompetitor;
        this.dateOpened = dateOpened;
        this.latitude = latitude;
        this.longitude = longitude;
        this.size = size;
    }
}

export const loadCompetitors = (stores: Store[], directCompetitorNames?: string[]): AppThunk<Promise<Competitor[]>> => async (dispatch) => {
    try {
        if (!stores || !directCompetitorNames) {
            return [];
        }

        const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
        stores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
        });

        const queriesByStoreCategory: Query[] = [];
        retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
            const orClause: BinaryFilter[] = [{
                member: "LocalCompetitors.StoreCategory_ID",
                operator: "equals",
                values: [String(storeCategoryID)]
            }];
            if (directCompetitorNames.length !== 0) {
                orClause.push({
                    member: "FasciaMapping.RevisedFascia",
                    operator: "equals",
                    values: directCompetitorNames
                });
            }
            const query: Query = {
                dimensions: [
                    "LocalCompetitors.Fascia",
                    "LocalCompetitors.StoreCategory_ID",
                    "LocalCompetitors.RetailCentreID",
                    "LocalCompetitors.OpeningDate",
                    "FasciaMapping.RevisedFascia",
                    "LocalCompetitors.LAT",
                    "LocalCompetitors.LNG",
                    "LocalCompetitors.NetSalesAreaSqFt"
                ],
                filters: [{
                    member: "LocalCompetitors.Distance",
                    operator: "lt",
                    values: ["7"]
                }, {
                    member: "LocalCompetitors.DateDeleted",
                    operator: "notSet"
                }, {
                    member: "LocalCompetitors.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                }, {
                    or: orClause
                }]
            };
            queriesByStoreCategory.push(query);
        });

        const promises: Promise<ResultSet>[] = [];
        queriesByStoreCategory.forEach(query => {
            const promise = dispatch(cubeLoad(query)) as unknown as Promise<ResultSet>;
            promises.push(promise);
        });

        const results = await Promise.all(promises);

        const rawData: any[] = [];
        results.forEach(resultSet => {
            rawData.push(...resultSet.rawData());
        });

        const competitors: Competitor[] = [];

        stores.forEach(store => {
            const results = rawData.filter(row => (Number(row["LocalCompetitors.StoreCategory_ID"]) === store.storeCategoryID
                || directCompetitorNames.includes(row["FasciaMapping.RevisedFascia"]))
                && Number(row["LocalCompetitors.RetailCentreID"]) === store.retailCentreID);

            const storeCompetitor = results.map(row =>
                new Competitor(
                    store.id,
                    row["LocalCompetitors.Fascia"],
                    directCompetitorNames.includes(row["FasciaMapping.RevisedFascia"]),
                    (DateTime.fromISO(row["LocalCompetitors.OpeningDate"], { zone: "utc" })).toISODate(),
                    row["LocalCompetitors.LAT"],
                    row["LocalCompetitors.LNG"],
                    row["LocalCompetitors.NetSalesAreaSqFt"]
                )
            );

            competitors.push(...storeCompetitor);
        });

        return competitors;
    } catch (error) {
        dispatch(logError("Error loading Competitors.", error));
        throw error;
    }
};
