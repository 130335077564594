import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Dash has evaluated how your selected location's change in number of stores compares to your target level and how strongly they align.
        </>
    );
};

export default Subtitle;
