const GET_STORE_VS_COMPARATOR_REV_VS_COMP_REQUEST = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_STORE_VS_COMPARATOR_REV_VS_COMP_REQUEST";
const GET_STORE_VS_COMPARATOR_REV_VS_COMP_SUCCESS = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_STORE_VS_COMPARATOR_REV_VS_COMP_SUCCESS";
const GET_STORE_VS_COMPARATOR_REV_VS_COMP_FAILURE = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_STORE_VS_COMPARATOR_REV_VS_COMP_FAILURE";
const GET_REVENUE_VS_COMPETITION_SCORE_REQUEST = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_REVENUE_VS_COMPETITION_SCORE_REQUEST";
const GET_REVENUE_VS_COMPETITION_SCORE_SUCCESS = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_REVENUE_VS_COMPETITION_SCORE_SUCCESS";
const GET_REVENUE_VS_COMPETITION_SCORE_FAILURE = "spark/customer/insights/portfolio/competition/revenueVsCompetitionScore/GET_REVENUE_VS_COMPETITION_SCORE_FAILURE";

const types = {
    GET_STORE_VS_COMPARATOR_REV_VS_COMP_REQUEST,
    GET_STORE_VS_COMPARATOR_REV_VS_COMP_SUCCESS,
    GET_STORE_VS_COMPARATOR_REV_VS_COMP_FAILURE,
    GET_REVENUE_VS_COMPETITION_SCORE_REQUEST,
    GET_REVENUE_VS_COMPETITION_SCORE_SUCCESS,
    GET_REVENUE_VS_COMPETITION_SCORE_FAILURE

};

export default types;
