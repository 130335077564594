import { combineReducers } from "redux";

import contactUs from "./contactUs";
import frequentlyAskedQuestions from "./frequentlyAskedQuestions";
import registration from "./registration";
import termsOfUse from "./termsOfUse";

const reducer = combineReducers({
    contactUs,
    frequentlyAskedQuestions,
    registration,
    termsOfUse
});

export default reducer;
