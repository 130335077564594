import { createSelector } from "reselect";
import { abs } from "mathjs";

const salesPerformanceVsBudgetOverTime = (state) => {
    return state.customer.insights.performance.budget.revenueVsBudgetOverTime.salesPerformanceVsBudgetOverTime;
};

const trackingToBudget = createSelector(
    salesPerformanceVsBudgetOverTime,
    (salesPerformanceVsBudgetOverTime) => {
        const rag = {
            loading: false,
            error: false,
            id: "tracking-to-budget",
            label: "Tracking to Budget",
            status: "info",
            value: ""
        };

        if (salesPerformanceVsBudgetOverTime.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (salesPerformanceVsBudgetOverTime.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const quarterlySales = salesPerformanceVsBudgetOverTime.quarterlySales;
        const quarterlyBudget = salesPerformanceVsBudgetOverTime.quarterlyBudget;

        if (quarterlyBudget.length === 0) {
            rag.status = "noData";
            rag.value = "This indicator isn't available because it requires your company's Budget data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Budget dataset and refresh your company's analytics.";
            return rag;
        }

        const sales = quarterlySales.reduce((accum, item) => accum + item.sales, 0);

        const salesQ1 = quarterlySales[0]?.sales ?? 0;
        const salesQ2 = quarterlySales[1]?.sales ?? 0;
        const salesQ3 = quarterlySales[2]?.sales ?? 0;
        const salesQ4 = quarterlySales[3]?.sales ?? 0;

        const budgetQ1 = quarterlyBudget[0]?.budget ?? 0;
        const budgetQ2 = quarterlyBudget[1]?.budget ?? 0;
        const budgetQ3 = quarterlyBudget[2]?.budget ?? 0;
        const budgetQ4 = quarterlyBudget[3]?.budget ?? 0;

        const budgetFY = budgetQ1 + budgetQ2 + budgetQ3 + budgetQ4;

        const YSalesBudgetDifference = sales - budgetFY;
        const h1 = salesQ1 + salesQ2 - (budgetQ1 + budgetQ2);
        const h2 = salesQ3 + salesQ4 - (budgetQ3 + budgetQ4);
        const HYSalesBudgetDifference = ((h2 - h1) / abs(h1)) * 100;

        if (YSalesBudgetDifference >= 0) {
            if (HYSalesBudgetDifference > 50) {
                rag.value = "Based on actual and forecast revenue in this financial year, your company should exceed the revenue budget, and the performance against budget is improving over time.";
                rag.status = "success";
            } else if (HYSalesBudgetDifference >= -50) {
                rag.value = "Based on actual and forecast revenue in this financial year, your company should exceed the revenue budget, and the performance against budget will stay broadly consistent over time.";
                rag.status = "success";
            } else { //HYSalesBudgetDifference < -50
                rag.value = "Based on actual and forecast revenue in this financial year, your company should exceed the revenue budget, and the performance against budget is declining over time.";
                rag.status = "warning";
            }
        } else { //YSalesBudgetDifference < 0
            rag.value = "Based on actual and forecast revenue in this financial year, your company should struggle to reach the revenue budget.";
            rag.status = "error";
        }
        return rag;
    }
);

const selectors = {
    salesPerformanceVsBudgetOverTime,
    trackingToBudget
};

export default selectors;
