import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Button, Card, CardMedia, Fade, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledCard = withStyles(() => ({
    root: {
        position: "absolute",
        top: 0,
        backgroundColor: "transparent",
        zIndex: (props) => props["data-selected"] ? 200 : 100
    }
}))(Card);

const StyledReferenceCard = withStyles(() => ({
    root: {
        opacity: 0
    }
}))(Card);

const Hero = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";
    const [selectedImage, setSelectedImage] = React.useState(0);
    const images = [{
        url: "/content/landing/home/hero/performance.png",
        alt: "performance"
    }, {
        url: "/content/landing/home/hero/portfolio.png",
        alt: "portfolio"
    }, {
        url: "/content/landing/home/hero/location.png",
        alt: "location"
    }, {
        url: "/content/landing/home/hero/range.png",
        alt: "range"
    }];

    const handleClick = (label) => {
        dispatch(trackEvent("Home page", `Hero Images - ${label} click`, `Hero Images - ${label} button`));
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImage(prevSelectedImage => (prevSelectedImage + 1) % 4);
        }, 3750);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <PageSection
            color={theme.palette.common.black}
            bgcolor={theme.palette.common.white}
            disablePaddingBottom
            dataCy="pages-landing-home-hero"
        >
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant={isMobile ? "h3" : "h1"} align={align}>
                        Transforming your data into insights
                    </Typography>
                    <br />
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} align={align}>
                        Understand, grow and take your business to the next level with Dash intelligent insights
                    </Typography>
                    <br />
                    <br />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={5} align={align}>
                            <Button
                                component={NavLink}
                                to="/contact-us?subject=book-a-demo"
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={() => handleClick("Book a demo")}
                                disableElevation
                            >
                                Book a demo
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={5} align={align}>
                            <Button
                                component={NavLink}
                                to="/features"
                                variant="text"
                                size="large"
                                color="default"
                                onClick={() => handleClick("View features")}
                                disableElevation
                            >
                                View features
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box position="relative" align="center">
                        <StyledReferenceCard square elevation={0}>
                            <CardMedia component="img" image={images[0].url} alt={images[0].alt} />
                        </StyledReferenceCard>
                        {images.map((image, index) =>
                            <Fade
                                key={index}
                                in={selectedImage === index}
                                timeout={{
                                    enter: 750,
                                    exit: 3000
                                }}
                            >
                                <StyledCard square elevation={0} data-selected={selectedImage === index}>
                                    <CardMedia component="img" image={image.url} alt={image.alt} />
                                </StyledCard>
                            </Fade>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default Hero;
