import React from "react";

const RegionalBreakdownSubtitle: React.FC = () => {
    return (
        <>
            How does your forecast growth break down across your regions? Which are expected to excel, and which will
            need your attention?
        </>
    );
};

export default RegionalBreakdownSubtitle;
