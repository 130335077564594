import React from "react";
import { Expression, FillPaint, LinePaint } from "mapbox-gl";
import { Layer, LayerProps, MapEvent, MapRef, Source, SourceProps, ViewportProps } from "react-map-gl";
import _ from "lodash";

import MapBase from "./MapBase";

const RETAIL_CENTRES_FILL_LAYER_ID = "retail-centres-fill";
const RETAIL_CENTRES_LINE_LAYER_ID = "retail-centres-line";

export interface MapRetailCentre {
    code: string,
    colourGroup: number,
    opacity?: number
}

interface PointerInfo {
    retailCentreCode?: string,
    latitude: number,
    longitude: number
}

export const pointerInfoEmpty: PointerInfo = {
    retailCentreCode: undefined,
    latitude: 0,
    longitude: 0
};

interface RetailCentresMapProps {
    loading: boolean,
    error: boolean,
    mapboxAccessToken: string,
    mapboxBaseMapStyle: string,
    mapboxRetailCentresTilesetId: string,
    mapboxRetailCentresTilesetUrl: string,
    height?: string | number,
    initialViewport: ViewportProps,
    retailCentres: MapRetailCentre[],
    fillColours: string[],
    borderColour: string,
    enableHover: boolean,
    onHover?: (pointerInfo: PointerInfo) => void,
    enableClick: boolean,
    onClick?: (pointerInfo: PointerInfo) => void,
    addGeocoder: boolean,
    geocoderContainerRef?: React.RefObject<HTMLDivElement>,
    children?: React.ReactNode,
    displayPOIs?: boolean
}

const RetailCentresMap: React.FC<RetailCentresMapProps> = (props) => {
    const {
        loading, error,
        mapboxAccessToken, mapboxBaseMapStyle, mapboxRetailCentresTilesetId, mapboxRetailCentresTilesetUrl,
        height,
        initialViewport,
        retailCentres, fillColours, borderColour,
        enableHover, onHover, enableClick, onClick,
        addGeocoder, geocoderContainerRef,
        displayPOIs
    } = props;
    const mapRef = React.useRef<MapRef>(null);
    const [hoverInfo, setHoverInfo] = React.useState<PointerInfo>(pointerInfoEmpty);
    const [clickInfo, setClickInfo] = React.useState<PointerInfo>(pointerInfoEmpty);

    const retailCentresSource = React.useMemo((): SourceProps => {
        return {
            type: "vector",
            url: mapboxRetailCentresTilesetUrl
        };
    }, [mapboxRetailCentresTilesetUrl]);

    const fillColor = React.useMemo(() => {
        let fillColor: string | Expression = "rgba(0,0,0,0)";
        if (retailCentres.length > 0) {
            fillColor = ["match", ["get", "geo_code"]];
            for (const retailCentre of retailCentres) {
                fillColor.push(retailCentre.code, fillColours[retailCentre.colourGroup]);
            }
            fillColor.push("rgba(0, 0, 0, 0)");
        }
        return fillColor;
    }, [retailCentres, fillColours]);

    const fillOpacity = React.useMemo(() => {
        let fillOpacity: number | Expression = 0.4;
        const minOpacity = 0.1;
        const maxOpacity = 0.1;
        const highlightOpacity = 0.7;

        if (retailCentres.length > 0) {
            fillOpacity = ["match", ["get", "geo_code"]];
            for (const retailCentre of retailCentres) {
                if ((retailCentre.code === hoverInfo.retailCentreCode && enableHover)
                    || (retailCentre.code === clickInfo.retailCentreCode && enableClick)) {
                    fillOpacity.push(retailCentre.code, highlightOpacity);
                } else {
                    fillOpacity.push(retailCentre.code, (((retailCentre.opacity || 1) * (maxOpacity - minOpacity)) + minOpacity));
                }
            }
            fillOpacity.push(0.4);
        }
        return fillOpacity;
    }, [retailCentres, hoverInfo, enableHover, clickInfo, enableClick]);

    const retailCentresFillLayer = React.useMemo(() => {
        const paint: FillPaint = {
            "fill-color": fillColor,
            "fill-opacity": fillOpacity
        };
        const layer: LayerProps = {
            id: RETAIL_CENTRES_FILL_LAYER_ID,
            type: "fill",
            "source-layer": mapboxRetailCentresTilesetId,
            beforeId: 'tunnel-simple',
            paint
        };
        return layer;
    }, [fillColor, fillOpacity, mapboxRetailCentresTilesetId]);

    const lineColor = React.useMemo(() => {
        let lineColor: string | Expression = "rgba(0,0,0,0)";
        if (retailCentres.length > 0) {
            lineColor = ["match", ["get", "geo_code"]];
            for (const retailCentre of retailCentres) {
                lineColor.push(retailCentre.code, borderColour);
            }
            lineColor.push("rgba(0, 0, 0, 0)");
        }
        return lineColor;
    }, [retailCentres, borderColour]);

    const retailCentresLineLayer = React.useMemo(() => {
        const paint: LinePaint = {
            "line-color": lineColor,
            "line-width": 1
        };
        const layer: LayerProps = {
            id: RETAIL_CENTRES_LINE_LAYER_ID,
            type: "line",
            "source-layer": mapboxRetailCentresTilesetId,
            beforeId: 'road-label-simple',
            paint
        };
        return layer;
    }, [lineColor, mapboxRetailCentresTilesetId]);

    const handlePointerEvent = React.useCallback((event: MapEvent): PointerInfo => {
        const { features, lngLat } = event;
        const retailCentreCode = features?.find(f => f.layer.id === RETAIL_CENTRES_FILL_LAYER_ID)?.properties["geo_code"];
        if (!retailCentres.some(retailCentre => retailCentre.code === retailCentreCode)) {
            return pointerInfoEmpty;
        }
        return {
            retailCentreCode,
            latitude: lngLat[1],
            longitude: lngLat[0]
        };
    }, [retailCentres]);

    const handleClick = React.useCallback((event: MapEvent) => {
        const pointerInfo = handlePointerEvent(event);
        const newPointerInfo = pointerInfo.retailCentreCode === clickInfo.retailCentreCode ? pointerInfoEmpty : pointerInfo;
        setClickInfo(newPointerInfo);
        onClick && onClick(newPointerInfo);
    }, [handlePointerEvent, clickInfo, setClickInfo, onClick]);

    const handleHover = React.useCallback((event: MapEvent) => {
        const pointerInfo = handlePointerEvent(event);
        setHoverInfo(pointerInfo);
        onHover && onHover(pointerInfo);
    }, [handlePointerEvent, setHoverInfo, onHover]);

    const handleHoverDelayed = React.useMemo(() => {
        return _.debounce((event: MapEvent) => handleHover(event), 400);
    }, [handleHover]);

    const handleMouseOut = React.useCallback(() => {
        handleHoverDelayed.cancel();
        setHoverInfo(pointerInfoEmpty);
        onHover && onHover(pointerInfoEmpty);
    }, [handleHoverDelayed, setHoverInfo, onHover]);

    return (
        <MapBase
            loading={loading}
            error={error}
            mapboxAccessToken={mapboxAccessToken}
            mapboxBaseMapStyle={mapboxBaseMapStyle}
            height={height}
            mapRef={mapRef}
            initialViewport={initialViewport}
            addGeocoder={addGeocoder}
            geocoderContainerRef={geocoderContainerRef}
            addNavigationControl={true}
            addRecenterButton={true}
            addFullscreenButton={true}
            interactiveLayerIds={[RETAIL_CENTRES_FILL_LAYER_ID]}
            onHover={handleHoverDelayed}
            onClick={handleClick}
            onMouseOut={handleMouseOut}
            dataCy="output-areas-map"
            displayPOIs={displayPOIs}
        >
            <Source {...retailCentresSource}>
                <Layer {...retailCentresFillLayer} />
                <Layer {...retailCentresLineLayer} />
            </Source>
            {props.children}
        </MapBase>
    );
};

export default RetailCentresMap;
