import { combineReducers } from "redux";

import slice from "./portfolioSlice";

import areaHealth from "./areaHealth";
import catchment from "./catchment";
import competition from "./competition";
import filters from "./filters";
import performanceDrivers from "./performanceDrivers";
import profit from "./profit";
import sales from "./sales";

const reducer = combineReducers({
    root: slice.reducer,
    areaHealth,
    catchment,
    competition,
    filters,
    performanceDrivers,
    profit,
    sales
});

export default reducer;
