import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/profit/grossProfitMargin";

const GrossProfitMarginLastYear = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const grossProfitMarginLastYear = useSelector(state => selectors.grossProfitMarginLastYear(state));
    const { loading, error, value, percentageDifference } = grossProfitMarginLastYear;
    const label = `${selectedStore.name} Gross Profit Margin % over past 12 months vs the ${selectedComparator.name}`;
    const valueFormatted = numberFormatter.toPercentage(value, 1);

    return (
        <Box width="100%" height="100%" data-cy="gross-profit-margin-last-year">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default GrossProfitMarginLastYear;
