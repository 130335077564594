import React from "react";

import { IconButton } from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip from "components/SimpleTooltip";

import { moveCategoryDown } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface MoveCategoryDownProps {
    categoryId: string
}

const MoveCategoryDown: React.FC<MoveCategoryDownProps> = (props) => {
    const { categoryId } = props;
    const dispatch = useAppDispatch();

    const handleMoveCategoryDownClick = () => {
        dispatch(moveCategoryDown(categoryId));
    };

    return (
        // @ts-ignore 
        <SimpleTooltip title="Move down">
            <StyledIconButton size="small" onClick={handleMoveCategoryDownClick}>
                <ArrowDownwardRoundedIcon />
            </StyledIconButton>
        </SimpleTooltip>
    );
};

export default MoveCategoryDown;
