import React from "react";
import { Box, Typography } from "@material-ui/core";

const SalesValuePerHeadTitle = () => {
    const label = "Sales value per head";

    return (
        <Box display="flex" data-cy="Sales-value-per-head-title" pb={1}>
            <Typography variant="h6">{label}</Typography>
        </Box>

    );
};

export default SalesValuePerHeadTitle; 
