import { createSelector } from "reselect";

const totalSalesGrowthByRegionForecast = (state) => {
    return state.customer.insights.performance.forecast.regionalBreakdown.totalSalesGrowthByRegionForecast;
};

const salesByRegionGrowthForecast = (state) => {
    return state.customer.insights.performance.forecast.regionalBreakdown.salesByRegionGrowthForecast;
};

const selectedRegion = (state) => {
    return state.customer.insights.performance.forecast.regionalBreakdown.selectedRegion;
};

const salesByRegionGrowthForecastTopBottom = createSelector(
    selectedRegion,
    salesByRegionGrowthForecast,
    (selectedRegion, salesByRegionGrowthForecast) => {
        const storesGrowthForecast = salesByRegionGrowthForecast.storesGrowthForecast;
        const stores = storesGrowthForecast
            .filter(store => !selectedRegion || store.regionId === selectedRegion)
            .sort((a, b) => a.growth - b.growth);

        let top = [];
        let bottom = [];
        const length = stores.length;
        if (length >= 6) {
            top = stores.slice(-3);
            bottom = stores.slice(0, 3);
        } else {
            if (length % 2 === 0) {
                top = stores.slice(-length / 2);
                bottom = stores.slice(0, length / 2);
            } else {
                const total = stores.reduce((total, store) => store.growth + total, 0);
                const mean = total / length;
                stores.forEach(store => {
                    store.growth >= mean
                        ? top.push(store)
                        : bottom.push(store);
                });
            }
        }

        return {
            loading: salesByRegionGrowthForecast.loading,
            error: salesByRegionGrowthForecast.error,
            top,
            bottom
        };
    }
);

const forecastDependencyOnSingularRegionOrStore = (state) => {
    return state.customer.insights.performance.forecast.regionalBreakdown.forecastDependencyOnSingularRegionOrStore;
};

const selectors = {
    totalSalesGrowthByRegionForecast,
    salesByRegionGrowthForecast,
    salesByRegionGrowthForecastTopBottom,
    forecastDependencyOnSingularRegionOrStore,
    selectedRegion
};

export default selectors;
