import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const HouseholdOccupancy: React.FC = () => {
    const title = "Household occupancy (Affluence)";
    const categories = [
        "2+ less rooms than required",
        "1 less room than required",
        "Optimal amount of rooms",
        "1 more room than required",
        "2+ more rooms than required"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.householdOccupancy;
    const selectedStoreValues = selectedStore ? [
        selectedStore.occupancyRatingMinus2orLess,
        selectedStore.occupancyRatingMinus1,
        selectedStore.occupancyRating0,
        selectedStore.occupancyRating1,
        selectedStore.occupancyRating2Plus
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.householdOccupancy;
    const comparatorValues = comparator ? [
        comparator.occupancyRatingMinus2orLess,
        comparator.occupancyRatingMinus1,
        comparator.occupancyRating0,
        comparator.occupancyRating1,
        comparator.occupancyRating2Plus
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default HouseholdOccupancy;
