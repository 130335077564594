export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly region: string;
    public readonly outputAreaCode: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly salesScore: number;
    public readonly driversScore: number;
    public readonly profitScore: number;
    public areaHealthScore: number;
    public readonly competitionScore: number;
    public catchmentScore: number;
    public readonly openingDate: Date;
    public readonly sizeInSquareFeet: number;
    public readonly numberOfEmployees: number;
    public readonly retailCentreID: number;
    public readonly kpmgStoreCategory: string;
    public kpmgSpendCategories: string[];
    
    constructor(
        id: string,
        name: string,
        region: string,
        outputAreaCode: string,
        latitude: number,
        longitude: number,
        salesScore: number,
        driversScore: number,
        profitScore: number,
        areaHealthScore: number,
        competitionScore: number,
        catchmentScore: number,
        openingDate: Date,
        sizeInSquareFeet: number,
        numberOfEmployees: number,
        retailCentreID: number,
        kpmgStoreCategory: string,
        kpmgSpendCategories: string[],

    ) {
        this.id = id;
        this.name = name;
        this.region = region;
        this.outputAreaCode = outputAreaCode;
        this.latitude = latitude;
        this.longitude = longitude;
        this.salesScore = salesScore;
        this.driversScore = driversScore;
        this.profitScore = profitScore;
        this.areaHealthScore = areaHealthScore;
        this.competitionScore = competitionScore;
        this.catchmentScore = catchmentScore;
        this.openingDate = openingDate;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.numberOfEmployees = numberOfEmployees;
        this.retailCentreID = retailCentreID;
        this.kpmgStoreCategory = kpmgStoreCategory;
        this.kpmgSpendCategories = kpmgSpendCategories;
    }

    getTotalScore() {
        return this.salesScore + this.driversScore + this.profitScore + this.areaHealthScore + this.competitionScore + this.catchmentScore;
    }
}
