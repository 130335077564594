import React from "react";

import { Box } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectAreaHealthAlignmentScore } from "modules/customer/tools/location/areaHealth/areaHealthSlice";
import { selectIsLoading, selectHasErrors, selectTarget } from "modules/customer/tools/location/locationSlice";
import KPIAlignment from "components/visuals/KPIAlignment";

const AreaHealthAlignmentScore: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const areaHealthAlignmentScore = useAppSelector(selectAreaHealthAlignmentScore);
    const target = useAppSelector(selectTarget);

    const disabled = !(target?.useAreaHealth);
    const label = "Area health alignment score";
    
    return (
       <Box width="100%" height="100%" data-cy="area-health-alignment-score">
            <KPIAlignment
                isLoading={isLoading}
                hasErrors={hasErrors}
                label={label}
                score={areaHealthAlignmentScore}
                disabled={disabled}
            />
        </Box>
    );
};

export default AreaHealthAlignmentScore;
