import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { operations, selectors } from "modules/customer/insights/performance/sales/regionalGrowthBreakdown";

const DependencyOnSingularRegionOrStore = () => {
    const dispatch = useDispatch();
    const dependencyOnSingularRegionOrStore = useSelector(state => selectors.dependencyOnSingularRegionOrStore(state));
    const { loading, error, id, label, status, value } = dependencyOnSingularRegionOrStore;

    React.useEffect(() => {
        dispatch(operations.getDependencyOnSingularRegionOrStore());
    }, [dispatch]);

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default DependencyOnSingularRegionOrStore;
