import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { distance, mean, median } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { Comparator, ComparatorsByChapter } from "modules/customer/tools/location/comparator";
import {
    selectCatchmentAccountIds,
    selectClientRegistration,
    selectDirectCompetitorNames,
    selectSpendCategories as selectLocationSpendCategories,
    selectStoreCategories as selectLocationStoreCategories,
    selectStores as selectLocationStores,
    selectUseMLCatchments
} from "modules/customer/tools/location/locationSlice";
import { Store } from "modules/customer/tools/location/store";
import { SpendCategory } from "modules/customer/tools/location/spendCategory";
import { StoreCategory } from "modules/customer/tools/location/storeCategory";
import { Target } from "modules/customer/tools/location/target";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import {
    ComparatorComponentMetrics,
    loadComparatorComponentMetrics
} from "./comparatorComponentMetrics";
import { DirectCompetitorMetrics, loadDirectCompeitorCentiles } from "./directCompetitorComponentMetrics";
import { loadRetailCentresCentiles, RetailCentreCentiles } from "./retailCentreCentiles";
import { loadRetailCentresSpendCentiles, RetailCentreSpendCentiles } from "./retailCentreSpendCentiles";
import { loadStoreCategoryCentilesMetrics, StoreCategoryCentileMetrics } from "./storeCategoryCentileMetrics";
import { loadStoreCategorySpendCentileMetrics, StoreCategorySpendCentileMetric } from "./storeCategorySpendCentileMetric";

interface LoadCentilesResponse {
    retailCentresCentiles: RetailCentreCentiles[],
    retailCentresSpendCentiles: RetailCentreSpendCentiles[],
    storeCategoryCentilesMetrics: StoreCategoryCentileMetrics[],
    storeCategorySpendCentileMetrics: StoreCategorySpendCentileMetric[],
    directCompetitorCentiles: DirectCompetitorMetrics[]
}

interface FiltersVisibility {
    isVisible: boolean
}

export enum FilterStep {
    SelectComparator,
    SetTargets
}

export interface ComparatorWithMetrics {
    storeName: string,
    metrics: ComparatorComponentMetrics
}

export interface StoresSearch {
    name: string
}

export interface StoresFilter {
    region: string,
    category: string,
    minRevenue: number,
    maxRevenue: number
}

export enum StoreSortField {
    Name,
    Revenue,
    Age,
    Urbanicity,
    Affluence,
    Diversity,
    Children,
    Spend,
    Footfall,
    AreaHealth
}

interface StoresSort {
    field: StoreSortField,
    direction: SortDirection
}

interface SetupWarningVisibility {
    isVisible: boolean
}

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateComparatorStores: Store[],
    candidateTargetStoreCategory?: StoreCategory,
    candidateTargetSpendCategories: SpendCategory[],
    candidateTarget?: Target,
    storesSearch: StoresSearch,
    storesFilter: StoresFilter,
    storesSort: StoresSort,
    centilesIsLoading: boolean,
    centilesHasErrors: boolean,
    retailCentresCentiles: RetailCentreCentiles[],
    retailCentresSpendCentiles: RetailCentreSpendCentiles[],
    storeCategoryCentilesMetrics: StoreCategoryCentileMetrics[],
    storeCategorySpendCentileMetrics: StoreCategorySpendCentileMetric[],
    targetIsLoading: boolean,
    beeswarmTooltipIsLoading: boolean,
    comparatorComponentMetricsHasErrors: boolean,
    targetHasErrors: boolean,
    comparatorComponentMetrics: ComparatorComponentMetrics[],
    comparatorMetricCentiles: ComparatorComponentMetrics[],
    directCompetitorCentiles: DirectCompetitorMetrics[],
    setupWarningVisibility: SetupWarningVisibility
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: true
    },
    candidateComparatorStores: [],
    candidateTargetStoreCategory: undefined,
    candidateTargetSpendCategories: [],
    candidateTarget: undefined,
    activeStep: FilterStep.SelectComparator,
    storesSearch: {
        name: ""
    },
    storesFilter: {
        region: "",
        category: "",
        minRevenue: Number.NEGATIVE_INFINITY,
        maxRevenue: Number.POSITIVE_INFINITY
    },
    storesSort: {
        field: StoreSortField.Revenue,
        direction: SortDirection.DESC
    },
    centilesIsLoading: false,
    centilesHasErrors: false,
    retailCentresCentiles: [],
    retailCentresSpendCentiles: [],
    storeCategoryCentilesMetrics: [],
    storeCategorySpendCentileMetrics: [],
    targetIsLoading: false,
    targetHasErrors: false,
    beeswarmTooltipIsLoading: false,
    comparatorComponentMetricsHasErrors: false,
    comparatorComponentMetrics: [],
    comparatorMetricCentiles: [],
    directCompetitorCentiles: [],
    setupWarningVisibility: {
        isVisible: false
    }
};

const filtersSlice = createSlice({
    name: "customer/tools/location/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectComparator;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setCandidateComparatorStores: (state, action: PayloadAction<Store[]>) => {
            state.candidateComparatorStores = [...action.payload];
        },
        addStoreToCandidateComparatorStores: (state, action: PayloadAction<Store>) => {
            const store = action.payload;
            const stores = [...state.candidateComparatorStores];
            if (stores.length < 5 && !stores.some(s => s.id === store.id)) {
                stores.push(store);
            }
            state.candidateComparatorStores = stores;
        },
        removeStoreFromCandidateComparatorStores: (state, action: PayloadAction<Store>) => {
            const store = action.payload;
            state.candidateComparatorStores = state.candidateComparatorStores.filter(s => s.id !== store.id);
        },
        clearCandidateComparatorStores: (state) => {
            state.candidateComparatorStores = initialState.candidateComparatorStores;
        },
        setCandidateTargetStoreCategory: (state, action: PayloadAction<StoreCategory>) => {
            state.candidateTargetStoreCategory = action.payload;
        },
        clearCandidateTargetStoreCategory: (state) => {
            state.candidateTargetStoreCategory = initialState.candidateTargetStoreCategory;
        },
        setCandidateTargetSpendCategories: (state, action: PayloadAction<SpendCategory[]>) => {
            state.candidateTargetSpendCategories = action.payload;
        },
        clearCandidateTargetSpendCategories: (state) => {
            state.candidateTargetSpendCategories = initialState.candidateTargetSpendCategories;
        },
        setCandidateTarget: (state, action: PayloadAction<Target>) => {
            state.candidateTarget = action.payload;
        },
        clearCandidateTarget: (state) => {
            state.candidateTarget = initialState.candidateTarget;
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = initialState.storesSearch;
        },
        setStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.storesFilter = action.payload;
        },
        clearStoresFilter: (state) => {
            state.storesFilter = initialState.storesFilter;
        },
        setStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.storesSort = action.payload;
        },
        clearStoresSort: (state) => {
            state.storesSort = initialState.storesSort;
        },
        clearCentiles: (state) => {
            state.retailCentresCentiles = initialState.retailCentresCentiles;
            state.retailCentresSpendCentiles = initialState.retailCentresSpendCentiles;
            state.storeCategoryCentilesMetrics = initialState.storeCategoryCentilesMetrics;
            state.storeCategorySpendCentileMetrics = initialState.storeCategorySpendCentileMetrics;
            state.directCompetitorCentiles = initialState.directCompetitorCentiles;
        },
        clearComparatorComponentMetrics: (state) => {
            state.comparatorComponentMetrics = initialState.comparatorComponentMetrics;
        },
        showSetupWarning: (state) => {
            state.setupWarningVisibility.isVisible = true;
        },
        hideSetupWarning: (state) => {
            state.setupWarningVisibility.isVisible = false;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCentiles.pending, (state: FiltersState) => {
            state.centilesIsLoading = true;
            state.centilesHasErrors = false;
            state.retailCentresCentiles = initialState.retailCentresCentiles;
            state.retailCentresSpendCentiles = initialState.retailCentresSpendCentiles;
            state.storeCategoryCentilesMetrics = initialState.storeCategoryCentilesMetrics;
            state.storeCategorySpendCentileMetrics = initialState.storeCategorySpendCentileMetrics;
            state.directCompetitorCentiles = initialState.directCompetitorCentiles;
        });
        builder.addCase(loadCentiles.rejected, (state: FiltersState) => {
            state.centilesIsLoading = false;
            state.centilesHasErrors = true;
            state.retailCentresCentiles = initialState.retailCentresCentiles;
            state.retailCentresSpendCentiles = initialState.retailCentresSpendCentiles;
            state.storeCategoryCentilesMetrics = initialState.storeCategoryCentilesMetrics;
            state.storeCategorySpendCentileMetrics = initialState.storeCategorySpendCentileMetrics;
            state.directCompetitorCentiles = initialState.directCompetitorCentiles;
        });
        builder.addCase(loadCentiles.fulfilled, (state: FiltersState, action: PayloadAction<LoadCentilesResponse>) => {
            state.centilesIsLoading = false;
            state.centilesHasErrors = false;
            state.retailCentresCentiles = action.payload.retailCentresCentiles;
            state.retailCentresSpendCentiles = action.payload.retailCentresSpendCentiles;
            state.storeCategoryCentilesMetrics = action.payload.storeCategoryCentilesMetrics;
            state.storeCategorySpendCentileMetrics = action.payload.storeCategorySpendCentileMetrics;
            state.directCompetitorCentiles = action.payload.directCompetitorCentiles;
        });
        builder.addCase(loadBeeswarmTooltips.pending, (state: FiltersState) => {
            state.beeswarmTooltipIsLoading = true;
            state.comparatorComponentMetricsHasErrors = false;
        });
        builder.addCase(loadBeeswarmTooltips.rejected, (state: FiltersState) => {
            state.beeswarmTooltipIsLoading = false;
            state.comparatorComponentMetricsHasErrors = true;
            state.comparatorComponentMetrics = initialState.comparatorComponentMetrics;
        });
        builder.addCase(loadBeeswarmTooltips.fulfilled, (state: FiltersState, action: PayloadAction<ComparatorComponentMetrics[]>) => {
            state.beeswarmTooltipIsLoading = false;
            state.comparatorComponentMetricsHasErrors = false;
            state.comparatorComponentMetrics = action.payload;
        });
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    setCandidateComparatorStores,
    addStoreToCandidateComparatorStores,
    removeStoreFromCandidateComparatorStores,
    clearCandidateComparatorStores,
    setCandidateTargetStoreCategory,
    clearCandidateTargetStoreCategory,
    setCandidateTargetSpendCategories,
    clearCandidateTargetSpendCategories,
    setCandidateTarget,
    clearCandidateTarget,
    setStoresSearch,
    clearStoresSearch,
    setStoresFilter,
    clearStoresFilter,
    setStoresSort,
    clearStoresSort,
    clearCentiles,
    showSetupWarning,
    hideSetupWarning
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearActiveStep());
    dispatch(filtersSlice.actions.clearCandidateComparatorStores());
    dispatch(filtersSlice.actions.clearCandidateTargetStoreCategory());
    dispatch(filtersSlice.actions.clearCandidateTarget());
    dispatch(filtersSlice.actions.clearStoresSearch());
    dispatch(filtersSlice.actions.clearStoresFilter());
    dispatch(filtersSlice.actions.clearStoresSort());
    dispatch(filtersSlice.actions.clearCentiles());
    dispatch(filtersSlice.actions.hideSetupWarning());
};

export const loadCentiles = createAppAsyncThunk(
    "customer/tools/location/filters/loadCentiles",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const candidateComparatorStores = selectCandidateComparatorStores(state);
            const candidateTargetStoreCategoryId = selectCandidateTargetStoreCategory(state)?.id;
            const directCompetitorNames = selectDirectCompetitorNames(state);
            const catchmentAccountIds = selectCatchmentAccountIds(state);
            const useMLCatchments = selectUseMLCatchments(state);
            const accountId = selectClientRegistration(state)?.accountId ?? "";

            const directCompetitorCentilesPromise = thunkAPI.dispatch(loadDirectCompeitorCentiles(directCompetitorNames, candidateTargetStoreCategoryId, accountId, useMLCatchments));
            const retailCentresCentilesPromise = thunkAPI.dispatch(loadRetailCentresCentiles(candidateTargetStoreCategoryId, candidateComparatorStores, catchmentAccountIds));
            const retailCentresSpendCentilesPromise = thunkAPI.dispatch(loadRetailCentresSpendCentiles(candidateTargetStoreCategoryId, candidateComparatorStores, catchmentAccountIds));
            const storeCategoryCentilesMetricsPromise = thunkAPI.dispatch(loadStoreCategoryCentilesMetrics(candidateTargetStoreCategoryId, accountId, useMLCatchments));
            const storeCategorySpendCentileMetricsPromise = thunkAPI.dispatch(loadStoreCategorySpendCentileMetrics(candidateTargetStoreCategoryId, accountId, useMLCatchments));
            const result = await Promise.all([
                retailCentresCentilesPromise,
                retailCentresSpendCentilesPromise,
                storeCategoryCentilesMetricsPromise,
                storeCategorySpendCentileMetricsPromise,
                directCompetitorCentilesPromise
            ]);
            const retailCentresCentiles = result[0];
            const retailCentresSpendCentiles = result[1];
            const storeCategoryCentilesMetrics = result[2];
            const storeCategorySpendCentileMetrics = result[3];
            const directCompetitorCentiles = result[4];
            const loadCentilesResponse: LoadCentilesResponse = {
                retailCentresCentiles,
                retailCentresSpendCentiles,
                storeCategoryCentilesMetrics,
                storeCategorySpendCentileMetrics,
                directCompetitorCentiles
            };
            return loadCentilesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Centiles.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const loadBeeswarmTooltips = createAppAsyncThunk(
    "customer/tools/location/filters/loadBeeswarmTooltips",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const candidateComparatorStores = selectCandidateComparatorStores(state);
            const candidateTargetStoreCategoryId = selectCandidateTargetStoreCategory(state)?.id;
            const catchmentAccountIds = selectCatchmentAccountIds(state);
            const baselineRetailCentres = candidateComparatorStores
                .filter(store => store.categoryId === candidateTargetStoreCategoryId)
                .map(store => store.retailCentre.id);
            const scenarioRetailCentres = candidateComparatorStores
                .filter(store => store.categoryId !== candidateTargetStoreCategoryId)
                .map(store => store.retailCentre.id);
            const baselinePromise = thunkAPI.dispatch(loadComparatorComponentMetrics(false, catchmentAccountIds.baseline, candidateTargetStoreCategoryId, baselineRetailCentres));
            const scenarioPromise = thunkAPI.dispatch(loadComparatorComponentMetrics(true, catchmentAccountIds.scenario, candidateTargetStoreCategoryId, scenarioRetailCentres));
            const results = await Promise.all([baselinePromise, scenarioPromise]);
            const comparatorComponentMetrics = results[0].concat(results[1]);
            return comparatorComponentMetrics;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Beeswarm Tooltips.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.location.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.location.filters.activeStep;
};

export const selectCandidateComparatorStores = (state: RootState) => {
    return state.customer.tools.location.filters.candidateComparatorStores;
};

export const selectCandidateTargetStoreCategory = (state: RootState) => {
    return state.customer.tools.location.filters.candidateTargetStoreCategory;
};

export const selectCandidateTargetSpendCategories = (state: RootState) => {
    return state.customer.tools.location.filters.candidateTargetSpendCategories;
};

export const selectCandidateTarget = (state: RootState) => {
    return state.customer.tools.location.filters.candidateTarget;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.tools.location.filters.storesSearch;
};

export const selectStoresFilter = (state: RootState) => {
    return state.customer.tools.location.filters.storesFilter;
};

export const selectStoresSort = (state: RootState) => {
    return state.customer.tools.location.filters.storesSort;
};

export const selectCentilesIsLoading = (state: RootState) => {
    return state.customer.tools.location.filters.centilesIsLoading;
};

export const selectCentilesHasErrors = (state: RootState) => {
    return state.customer.tools.location.filters.centilesHasErrors;
};

export const selectRetailCentresCentiles = (state: RootState) => {
    return state.customer.tools.location.filters.retailCentresCentiles;
};

export const selectRetailCentresSpendCentiles = (state: RootState) => {
    return state.customer.tools.location.filters.retailCentresSpendCentiles;
};

export const selectStoreCategoryCentilesMetrics = (state: RootState) => {
    return state.customer.tools.location.filters.storeCategoryCentilesMetrics;
};

export const selectStoreCategorySpendCentileMetrics = (state: RootState) => {
    return state.customer.tools.location.filters.storeCategorySpendCentileMetrics;
};

export const selectDirectCompetitorCentiles = (state: RootState) => {
    return state.customer.tools.location.filters.directCompetitorCentiles;
};

export const selectBeeswarmTooltipIsLoading = (state: RootState) => {
    return state.customer.tools.location.filters.beeswarmTooltipIsLoading;
};

export const selectTargetIsLoading = (state: RootState) => {
    return state.customer.tools.location.filters.targetIsLoading;
};

export const selectComparatorMetricCentiles = (state: RootState) => {
    return state.customer.tools.location.filters.comparatorMetricCentiles;
};

export const selectComparatorComponentMetrics = (state: RootState) => {
    return state.customer.tools.location.filters.comparatorComponentMetrics;
};

export const selectSetupWarningVisibility = (state: RootState) => {
    return state.customer.tools.location.filters.setupWarningVisibility;
};

export const selectIsStoresFilterModified = createSelector(
    selectStoresFilter,
    (storesFilter) => {
        return storesFilter.region !== initialState.storesFilter.region
            || storesFilter.category !== initialState.storesFilter.category
            || storesFilter.minRevenue !== initialState.storesFilter.minRevenue
            || storesFilter.maxRevenue !== initialState.storesFilter.maxRevenue;
    }
);

export const selectRegions = createSelector(
    (state: RootState) => selectLocationStores(state),
    (stores) => {
        const regions = stores
            .map(store => store.retailCentre.regionName)
            .sort((regionA, regionB) => stringSortExpression(regionA, regionB, SortDirection.ASC));
        return Array.from(new Set(regions));
    }
);

export const selectCurrentStoresCategories = createSelector(
    (state: RootState) => selectLocationStores(state),
    (stores) => {
        const categories = stores
            .map(store => store.categoryName)
            .sort((categoryA, categoryB) => stringSortExpression(categoryA, categoryB, SortDirection.ASC));
        return Array.from(new Set(categories));
    }
);

export const selectMinRevenue = createSelector(
    (state: RootState) => selectLocationStores(state),
    (stores) => {
        const revenues = stores.map(store => store.revenue);
        if (revenues.length === 0) {
            return Number.NEGATIVE_INFINITY;
        }
        return Math.min(...revenues);
    }
);

export const selectMaxRevenue = createSelector(
    (state: RootState) => selectLocationStores(state),
    (stores) => {
        const revenues = stores.map(store => store.revenue);
        if (revenues.length === 0) {
            return Number.POSITIVE_INFINITY;
        }
        return Math.max(...revenues);
    }
);

export const selectStores = createSelector(
    (state: RootState) => selectLocationStores(state),
    selectStoresSearch,
    selectStoresFilter,
    selectStoresSort,
    (stores, search, filter, sort) => {
        const name = search.name.toLowerCase();
        const filteredStores = stores.filter(store =>
            (!name || store.name.toLowerCase().includes(name))
            && (!filter.region || store.retailCentre.regionName === filter.region)
            && (!filter.category || store.categoryName === filter.category)
            && store.revenue >= filter.minRevenue
            && store.revenue <= filter.maxRevenue
        );

        return filteredStores.sort((storeA, storeB) => {
            switch (sort.field) {
                case StoreSortField.Revenue:
                    return numberSortExpression(storeA.revenue, storeB.revenue, sort.direction);
                case StoreSortField.Age:
                    return numberSortExpression(storeA.retailCentre.ageCentile, storeB.retailCentre.ageCentile, sort.direction);
                case StoreSortField.Urbanicity:
                    return numberSortExpression(storeA.retailCentre.urbanicityCentile, storeB.retailCentre.urbanicityCentile, sort.direction);
                case StoreSortField.Affluence:
                    return numberSortExpression(storeA.retailCentre.affluenceCentile, storeB.retailCentre.affluenceCentile, sort.direction);
                case StoreSortField.Diversity:
                    return numberSortExpression(storeA.retailCentre.diversityCentile, storeB.retailCentre.diversityCentile, sort.direction);
                case StoreSortField.Children:
                    return numberSortExpression(storeA.retailCentre.childrenCentile, storeB.retailCentre.childrenCentile, sort.direction);
                case StoreSortField.Spend:
                    return numberSortExpression(storeA.retailCentre.spendCentile, storeB.retailCentre.spendCentile, sort.direction);
                case StoreSortField.Footfall:
                    return numberSortExpression(storeA.retailCentre.footfallCentile, storeB.retailCentre.footfallCentile, sort.direction);
                case StoreSortField.AreaHealth:
                    return numberSortExpression(storeA.retailCentre.areaHealthCentile, storeB.retailCentre.areaHealthCentile, sort.direction);
                case StoreSortField.Name:
                default:
                    return stringSortExpression(storeA.name, storeB.name, sort.direction);
            }
        });
    }
);

export const selectStoreCategories = createSelector(
    (state: RootState) => selectLocationStoreCategories(state),
    (categories) => {
        return [...categories]
            .sort((categoryA, categoryB) => stringSortExpression(categoryA.name, categoryB.name, SortDirection.ASC))
            .sort((categoryA, categoryB) => numberSortExpression(Number(categoryA.isPrimary), Number(categoryB.isPrimary), SortDirection.DESC))
            .sort((categoryA, categoryB) => numberSortExpression(Number(categoryA.isInPortfolio), Number(categoryB.isInPortfolio), SortDirection.DESC));
    }
);

export const selectDefaultTargetSpendCategories = createSelector(
    (state: RootState) => selectLocationSpendCategories(state),
    (spendCategories) => {
        return spendCategories.filter(spendCategory => spendCategory.isInPortfolio);
    }
);

export const selectCandidateComparatorStoresSpendMedianCentile = createSelector(
    selectCandidateComparatorStores,
    selectRetailCentresSpendCentiles,
    selectCandidateTargetSpendCategories,
    (comparatorStores, retailCentresSpendCentiles, targetSpendCategories) => {
        if (comparatorStores.length === 0 || retailCentresSpendCentiles.length === 0 || targetSpendCategories.length === 0) {
            return 0;
        }

        const targetSpendCategoriesIds = targetSpendCategories.map(spendCategory => spendCategory.id);

        const spendCentiles: number[] = [];
        comparatorStores.forEach(store => {
            const retailCentreSpendCentiles = retailCentresSpendCentiles.filter(spendCentile => spendCentile.retailCentreId === store.retailCentre.id
                && targetSpendCategoriesIds.includes(spendCentile.spendCategoryId));
            const meanSpendCentile = retailCentreSpendCentiles.length > 0 ? mean(retailCentreSpendCentiles.map(sc => sc.spend)) as number : 0;
            spendCentiles.push(meanSpendCentile);
        });

        return mathUtils.centilesStandardisedMedian(spendCentiles);
    }
);

const selectCandidateComparatorStoresMedianCentilesWithoutSpend = createSelector(
    selectCandidateComparatorStores,
    selectRetailCentresCentiles,
    (comparatorStores, retailCentresCentiles) => {
        if (comparatorStores.length === 0 || retailCentresCentiles.length === 0) {
            return undefined;
        }

        const affluenceCentiles: number[] = [];
        const ageCentiles: number[] = [];
        const childrenCentiles: number[] = [];
        const diversityCentiles: number[] = [];
        const urbanicityCentiles: number[] = [];
        const areaHealthCentiles: number[] = [];
        const footfallCentiles: number[] = [];
        comparatorStores.forEach(store => {
            const retailCentreCentiles = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id);
            if (retailCentreCentiles) {
                affluenceCentiles.push(retailCentreCentiles.affluence);
                ageCentiles.push(retailCentreCentiles.age);
                childrenCentiles.push(retailCentreCentiles.children);
                diversityCentiles.push(retailCentreCentiles.diversity);
                urbanicityCentiles.push(retailCentreCentiles.urbanicity);
                areaHealthCentiles.push(retailCentreCentiles.areaHealth);
                footfallCentiles.push(retailCentreCentiles.footfall);
            }
        });

        return {
            affluence: mathUtils.centilesStandardisedMedian(affluenceCentiles),
            age: mathUtils.centilesStandardisedMedian(ageCentiles),
            children: mathUtils.centilesStandardisedMedian(childrenCentiles),
            diversity: mathUtils.centilesStandardisedMedian(diversityCentiles),
            urbanicity: mathUtils.centilesStandardisedMedian(urbanicityCentiles),
            areaHealth: mathUtils.centilesStandardisedMedian(areaHealthCentiles),
            footfall: mathUtils.centilesStandardisedMedian(footfallCentiles)
        };
    }
);

const selectCandidateComparatorStoresMedianCentiles = createSelector(
    selectCandidateComparatorStoresSpendMedianCentile,
    selectCandidateComparatorStoresMedianCentilesWithoutSpend,
    (spendMedianCentile, medianCentilesWithoutSpend) => {
        if (!medianCentilesWithoutSpend) {
            return undefined;
        }

        return {
            affluence: medianCentilesWithoutSpend.affluence,
            age: medianCentilesWithoutSpend.age,
            children: medianCentilesWithoutSpend.children,
            diversity: medianCentilesWithoutSpend.diversity,
            urbanicity: medianCentilesWithoutSpend.urbanicity,
            spend: spendMedianCentile,
            areaHealth: medianCentilesWithoutSpend.areaHealth,
            footfall: medianCentilesWithoutSpend.footfall
        };
    }
);

export const selectDefaultTarget = createSelector(
    selectCandidateComparatorStoresMedianCentiles,
    selectFeatureFlags,
    (medianCentiles, featureFlags) => {
        if (!medianCentiles) {
            return undefined;
        }

        const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;

        const defaultTarget: Target = {
            useDemographics: true,
            useSpend: enableSpendNew,
            useAreaHealth: true,
            useFootfall: true,
            affluence: medianCentiles.affluence,
            age: medianCentiles.age,
            children: medianCentiles.children,
            diversity: medianCentiles.diversity,
            urbanicity: medianCentiles.urbanicity,
            spend: medianCentiles.spend,
            areaHealth: medianCentiles.areaHealth,
            footfall: medianCentiles.footfall
        };
        return defaultTarget;
    }
);

const selectCandidateComparatorForSpendChapter = createSelector(
    selectCandidateComparatorStores,
    selectCandidateTargetSpendCategories,
    selectRetailCentresSpendCentiles,
    selectCandidateComparatorStoresSpendMedianCentile,
    (comparatorStores, targetSpendCategories, retailCentresSpendCentiles, spendMedianCentile) => {
        if (comparatorStores.length === 0 || targetSpendCategories.length === 0 || retailCentresSpendCentiles.length === 0) {
            return undefined;
        }

        let spendComparatorStore: Store | undefined = undefined;

        const findStoresWithMinDistanceToCentile = (targetCentile: number) => {
            let storesWithMinDistance: Store[] = [];
            let minDistance = Number.POSITIVE_INFINITY;
            for (const store of comparatorStores) {
                const retailCentreSpendCentiles = retailCentresSpendCentiles.filter(spendCentile => spendCentile.retailCentreId === store.retailCentre.id
                    && targetSpendCategoriesIds.includes(spendCentile.spendCategoryId));
                const meanSpendCentile = retailCentreSpendCentiles.length > 0 ? mean(retailCentreSpendCentiles.map(sc => sc.spend)) as number : 0;
                const distanceToTarget = Number(distance([meanSpendCentile], [targetCentile]));
                if (distanceToTarget < minDistance) {
                    storesWithMinDistance = [store];
                    minDistance = distanceToTarget;
                    continue;
                }
                if (distanceToTarget === minDistance) {
                    storesWithMinDistance.push(store);
                }
            }
            return storesWithMinDistance;
        };

        const targetSpendCategoriesIds = targetSpendCategories.map(spendCategory => spendCategory.id);

        if (comparatorStores.length % 2 !== 0) {
            const storesWithCentile = [];
            for (const store of comparatorStores) {
                const retailCentreSpendCentiles = retailCentresSpendCentiles.filter(spendCentile => spendCentile.retailCentreId === store.retailCentre.id
                    && targetSpendCategoriesIds.includes(spendCentile.spendCategoryId));
                const meanSpendCentile = retailCentreSpendCentiles.length > 0 ? mean(retailCentreSpendCentiles.map(sc => sc.spend)) as number : 0;
                storesWithCentile.push({
                    store,
                    centile: meanSpendCentile
                });
            }
            const sortedStoresWithCentile = storesWithCentile.sort((storeA, storeB) => numberSortExpression(storeA.centile, storeB.centile, SortDirection.ASC));
            if (sortedStoresWithCentile.length !== 0) {
                spendComparatorStore = sortedStoresWithCentile[Math.floor(sortedStoresWithCentile.length / 2)].store;
            }
        } else {
            const storesWithMinDistanceToMedianCentile = findStoresWithMinDistanceToCentile(spendMedianCentile);
            if (storesWithMinDistanceToMedianCentile.length === 1) {
                spendComparatorStore = storesWithMinDistanceToMedianCentile[0];
            } else {
                const storesWithMinDistanceToStandardCentile = findStoresWithMinDistanceToCentile(50);
                spendComparatorStore = storesWithMinDistanceToStandardCentile[0];
            }
        }

        if (!spendComparatorStore) {
            return undefined;
        }

        return new Comparator(
            spendComparatorStore.id,
            spendComparatorStore.name,
            spendComparatorStore,
            spendComparatorStore.retailCentre.id,
            retailCentresSpendCentiles.find(spendCentiles => spendCentiles.retailCentreId === spendComparatorStore?.retailCentre.id)?.isScenario ?? false
        );
    }
);

const selectCandidateComparatorsForChaptersWithoutSpend = createSelector(
    selectCandidateComparatorStores,
    selectRetailCentresCentiles,
    selectCandidateComparatorStoresMedianCentilesWithoutSpend,
    (comparatorStores, retailCentresCentiles, medianCentiles) => {
        if (comparatorStores.length === 0 || retailCentresCentiles.length === 0 || !medianCentiles) {
            return undefined;
        }

        const findStoresWithMinDistanceToCentiles = (
            stores: Store[],
            getCentiles: (retailCentreCentiles: RetailCentreCentiles) => number[],
            targetCentiles: number[]
        ) => {
            let storesWithMinDistance: Store[] = [];
            let minDistance = Number.POSITIVE_INFINITY;
            for (const store of stores) {
                const retailCentreCentiles = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id);
                if (retailCentreCentiles) {
                    const centiles = getCentiles(retailCentreCentiles);
                    if (centiles.length !== targetCentiles.length) {
                        continue;
                    }
                    const distanceToTarget = Number(distance(centiles, targetCentiles));
                    if (distanceToTarget < minDistance) {
                        storesWithMinDistance = [store];
                        minDistance = distanceToTarget;
                        continue;
                    }
                    if (distanceToTarget === minDistance) {
                        storesWithMinDistance.push(store);
                    }
                }
            }
            return storesWithMinDistance;
        };

        const findComparatorStore = (
            stores: Store[],
            getCentiles: (retailCentreCentiles: RetailCentreCentiles) => number[],
            targetMedianCentiles: number[]
        ) => {
            if ((stores.length % 2 !== 0) && (getCentiles(retailCentresCentiles[0]).length === 1)) {
                const storesWithCentiles = [];
                for (const store of stores) {
                    const retailCentreCentiles = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id);
                    if (retailCentreCentiles) {
                        storesWithCentiles.push({
                            store,
                            centile: getCentiles(retailCentreCentiles)[0]
                        });
                    }
                }
                const sortedStoresWithCentiles = storesWithCentiles
                    .sort((storeA, storeB) => numberSortExpression(storeA.centile, storeB.centile, SortDirection.ASC));
                if (sortedStoresWithCentiles.length !== 0) {
                    return sortedStoresWithCentiles[Math.floor(sortedStoresWithCentiles.length / 2)].store;
                }
            }
            const storesWithMinDistanceToMedianCentiles = findStoresWithMinDistanceToCentiles(stores, getCentiles, targetMedianCentiles);
            if (storesWithMinDistanceToMedianCentiles.length === 1) {
                return storesWithMinDistanceToMedianCentiles[0];
            }
            const standardMedianCentiles = targetMedianCentiles.map(_ => 50);
            const storesWithMinDistanceToStandardCentiles = findStoresWithMinDistanceToCentiles(stores, getCentiles, standardMedianCentiles);
            return storesWithMinDistanceToStandardCentiles[0];
        };

        const demographicsComparatorStore = findComparatorStore(
            comparatorStores,
            (retailCentreCentiles) => [retailCentreCentiles.affluence, retailCentreCentiles.age, retailCentreCentiles.children, retailCentreCentiles.diversity, retailCentreCentiles.urbanicity],
            [medianCentiles.affluence, medianCentiles.age, medianCentiles.children, medianCentiles.diversity, medianCentiles.urbanicity]
        );
        const areaHealthComparatorStore = findComparatorStore(
            comparatorStores,
            (retailCentreCentiles) => [retailCentreCentiles.areaHealth],
            [medianCentiles.areaHealth]
        );
        const footfallComparatorStore = findComparatorStore(
            comparatorStores,
            (retailCentreCentiles) => [retailCentreCentiles.footfall],
            [medianCentiles.footfall]
        );

        const demographicsComparator = new Comparator(
            demographicsComparatorStore.id,
            demographicsComparatorStore.name,
            demographicsComparatorStore,
            demographicsComparatorStore.retailCentre.id,
            retailCentresCentiles.find(centiles => centiles.retailCentreId === demographicsComparatorStore.retailCentre.id)?.isScenario ?? false
        );
        const areaHealthComparator = new Comparator(
            areaHealthComparatorStore.id,
            areaHealthComparatorStore.name,
            areaHealthComparatorStore,
            areaHealthComparatorStore.retailCentre.id,
            retailCentresCentiles.find(centiles => centiles.retailCentreId === areaHealthComparatorStore.retailCentre.id)?.isScenario ?? false
        );
        const footfallComparator = new Comparator(
            footfallComparatorStore.id,
            footfallComparatorStore.name,
            footfallComparatorStore,
            footfallComparatorStore.retailCentre.id,
            retailCentresCentiles.find(centiles => centiles.retailCentreId === footfallComparatorStore.retailCentre.id)?.isScenario ?? false
        );

        return {
            demographics: demographicsComparator,
            areaHealth: areaHealthComparator,
            footfall: footfallComparator
        };
    }
);

export const selectCandidateComparatorsByChapter = createSelector(
    selectCandidateComparatorForSpendChapter,
    selectCandidateComparatorsForChaptersWithoutSpend,
    (spendComparator, chaptersComparators) => {
        if (!chaptersComparators) {
            return undefined;
        }

        const demographicsComparator = chaptersComparators.demographics;
        const areaHealthComparator = chaptersComparators.areaHealth;
        const footfallComparator = chaptersComparators.footfall;
        return new ComparatorsByChapter(demographicsComparator, spendComparator, areaHealthComparator, footfallComparator);
    }
);

export const selectAggregatedDirectCompetitors = createSelector(
    selectDirectCompetitorCentiles,
    (directCompetitorCentiles) => {
        if (directCompetitorCentiles.length === 0) {
            return [];
        }

        const groupedCompetitors = _(directCompetitorCentiles)
            .groupBy(competitor => competitor.fascia)
            .map((group, key) => new DirectCompetitorMetrics(
                key,
                median(group.map(competitor => competitor.areaHealthCentile ?? 0)),
                median(group.map(competitor => competitor.footfallCentile ?? 0)),
                median(group.map(competitor => competitor.affluenceCentile ?? 0)),
                median(group.map(competitor => competitor.ageCentile ?? 0)),
                median(group.map(competitor => competitor.childrenCentile ?? 0)),
                median(group.map(competitor => competitor.diversityCentile ?? 0)),
                median(group.map(competitor => competitor.urbanicityCentile ?? 0))
            ))
            .value();

        return groupedCompetitors;
    }
);

export const selectComparatorWithMetrics = createSelector(
    selectCandidateComparatorStores,
    (state: RootState) => selectComparatorMetricCentiles(state),
    (state: RootState) => selectComparatorComponentMetrics(state),
    selectBeeswarmTooltipIsLoading,
    (comparatorStores, comparatorCentiles, comparatorMetrics, tooltipIsLoading) => {
        if (comparatorStores.length === 0 || comparatorCentiles.length === 0) {
            return [];
        }

        const relevantSeries = tooltipIsLoading ? comparatorCentiles : comparatorMetrics;
        const comparatorWithCentiles: ComparatorWithMetrics[] = [];

        for (const i in comparatorStores) {
            const currentStore = comparatorStores[i];
            const currentCentiles =
                relevantSeries.filter(centiles => centiles.retailCentreId === currentStore.retailCentre.id);

            if (currentCentiles.length !== 0) {
                const isScenario = currentStore.categoryId !== currentCentiles[0].storeCategoryId;
                const relevantCentiles = currentCentiles.find(centiles => centiles.isScenario === isScenario);

                if (relevantCentiles) {
                    comparatorWithCentiles.push({
                        storeName: currentStore.name,
                        metrics: relevantCentiles
                    });
                }
            }
        }

        return comparatorWithCentiles;
    }
);

export default filtersSlice;
