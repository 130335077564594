import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectSupplyAndDemandCategorisation } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

const SupplyAndDemandCategorisation = () => {
    const supplyAndDemandCategorisation = useAppSelector(selectSupplyAndDemandCategorisation);

    return (
        <RAGIndicatorNew ragIndicator={supplyAndDemandCategorisation} />
    );
};

export default SupplyAndDemandCategorisation;
