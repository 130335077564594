import React from "react";

import { Tooltip } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: (props: any) => props["data-colour"],
        maxWidth: (props: any) => props["data-no-wrap"] ? "none" : 300
    },
    arrow: {
        color: (props: any) => props["data-colour"]
    }
}))(Tooltip);

export enum SimpleTooltipPlacement {
    BottomEnd = "bottom-end",
    BottomStart = "bottom-start",
    Bottom = "bottom",
    LeftEnd = "left-end",
    LeftStart = "left-start",
    Left = "left",
    RightEnd = "right-end",
    RightStart = "right-start",
    Right = "right",
    TopEnd = "top-end",
    TopStart = "top-start",
    Top = "top"
}

export enum SimpleTooltipBackgroundColour {
    Primary,
    Quaternary
}

interface SimpleTooltipProps {
    title: NonNullable<React.ReactNode>,
    placement?: SimpleTooltipPlacement,
    backgroundColour?: SimpleTooltipBackgroundColour,
    noWrap?: boolean,
    open?: boolean,
    onOpen?: () => void,
    onClose?: () => void,
    disableFocusListener?: boolean,
    disableHoverListener?: boolean,
    disableTouchListener?: boolean
    children: React.ReactElement
}

const SimpleTooltip: React.FC<SimpleTooltipProps> = (props) => {
    const {
        title, placement, backgroundColour, noWrap,
        open, onOpen, onClose, disableFocusListener, disableHoverListener, disableTouchListener,
        children
    } = props;
    const theme = useTheme();

    const colour = (() => {
        switch (backgroundColour) {
            case SimpleTooltipBackgroundColour.Quaternary:
                // @ts-ignore
                return theme.palette.quaternary.main;
            case SimpleTooltipBackgroundColour.Primary:
            default:
                return theme.palette.primary.main;
        }
    })();

    const handleOpen = () => {
        onOpen && onOpen();
    };

    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <StyledTooltip
            title={title}
            placement={placement}
            open={open ?? undefined}
            onOpen={handleOpen}
            onClose={handleClose}
            disableFocusListener={disableFocusListener ?? false}
            disableHoverListener={disableHoverListener ?? false}
            disableTouchListener={disableTouchListener ?? false}
            arrow
            interactive
            data-colour={colour}
            data-no-wrap={noWrap}>
            {children}
        </StyledTooltip>
    );
};

SimpleTooltip.defaultProps = {
    placement: SimpleTooltipPlacement.Bottom,
    backgroundColour: SimpleTooltipBackgroundColour.Primary,
    noWrap: false
};

export default SimpleTooltip;
