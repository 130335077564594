import { median } from "mathjs";

import actions from "./actions";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getRetailDensityVis = () => async (dispatch, getState) => {
    dispatch(actions.getRetailDensityVisRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedOAID = selectedStoreSelector.outputAreaCode;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);

        const relevantOAs = comparatorOAIDs.concat([selectedOAID]);

        const query = {
            dimensions: [
                "RetailDensity.MainOAID",
                "RetailDensity.StoreDensity"
            ],
            filters: [
                {
                    "member": "RetailDensity.MainOAID",
                    "operator": "equals",
                    "values": relevantOAs
                }
            ]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const retailDensityData = resultSet.loadResponses[0].data.map(item => ({
            LocalArea: item["RetailDensity.MainOAID"],
            retailDensity: item["RetailDensity.StoreDensity"]//Stores per km2
        }));

        const storeData = retailDensityData.find(item => item.LocalArea === selectedOAID);
        const store = storeData.retailDensity;
        const comparatorData = retailDensityData.filter(item => comparatorOAIDs.includes(item.LocalArea));
        const comparator = median(comparatorData.map(item => item.retailDensity));

        dispatch(actions.getRetailDensityVisSuccess(store, comparator, retailDensityData));
    }
    catch (error) {
        dispatch(actions.getRetailDensityVisFailure());
        dispatch(logError("Error loading RetailDensityVis.", error));
    }
};

const operations = {
    getRetailDensityVis
};

export default operations;
