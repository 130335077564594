import { createSelector } from "reselect";
import { median } from "mathjs";

import arrayUtils from "utils/arrayUtils";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const salesPerSquareFoot = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.storeSize.salesPerSquareFoot;
};

const salesPerSquareFootGauge = createSelector(
    salesPerSquareFoot,
    (salesPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0
        };
        if (salesPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerSquareFoot.selectedStore;
        const overallData = salesPerSquareFoot.overallData;
        const comparatorStores = salesPerSquareFoot.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.salesPerSqft;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.salesPerSqft));
        out.min = Math.min(...overallData.map(item => item.salesPerSqft));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.salesPerSqft));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerSqft), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerSqft), 2 / 3);

        return out;
    }
);

const salesPerSquareFootVariance = createSelector(
    salesPerSquareFoot,
    (salesPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (salesPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerSquareFoot.selectedStore;
        const comparatorStores = salesPerSquareFoot.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.salesPerSqft));
        //selected store value
        const selectedStoreValue = selectedStore.salesPerSqft;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const salesPerSquareFootRank = createSelector(
    salesPerSquareFoot,
    (salesPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0
        };
        if (salesPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerSquareFoot.selectedStore;
        const overallData = salesPerSquareFoot.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => b.salesPerSqft - a.salesPerSqft);

        const selectedStoreValue = selectedStore.salesPerSqft;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.salesPerSqft === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = salesPerSquareFoot.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerSqft), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerSqft), 2 / 3);
        
        out.status = (() => {
            switch (true) {  
                case selectedStoreValue < firstTertile:
                    return 'error';
                case selectedStoreValue <= secondTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const propertyCostsPerSquareFoot = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.storeSize.propertyCostsPerSquareFoot;
};

const propertyCostsPerSquareFootGauge = createSelector(
    propertyCostsPerSquareFoot,
    (propertyCostsPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0

        };
        if (propertyCostsPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (propertyCostsPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = propertyCostsPerSquareFoot.selectedStore;
        const overallData = propertyCostsPerSquareFoot.overallData;
        const comparatorStores = propertyCostsPerSquareFoot.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.propertyCostPerSqft;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.propertyCostPerSqft));
        out.min = Math.min(...overallData.map(item => item.propertyCostPerSqft));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.propertyCostPerSqft));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.propertyCostPerSqft), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.propertyCostPerSqft), 2 / 3);

        return out;
    }
);

const propertyCostsPerSquareFootVariance = createSelector(
    propertyCostsPerSquareFoot,
    (propertyCostsPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (propertyCostsPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (propertyCostsPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = propertyCostsPerSquareFoot.selectedStore;
        const comparatorStores = propertyCostsPerSquareFoot.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.propertyCostPerSqft));
        //selected store value
        const selectedStoreValue = selectedStore.propertyCostPerSqft;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const propertyCostsPerSquareFootRank = createSelector(
    propertyCostsPerSquareFoot,
    (propertyCostsPerSquareFoot) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0
        };
        if (propertyCostsPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (propertyCostsPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = propertyCostsPerSquareFoot.selectedStore;
        const overallData = propertyCostsPerSquareFoot.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => a.propertyCostPerSqft - b.propertyCostPerSqft);

        const selectedStoreValue = selectedStore.propertyCostPerSqft;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.propertyCostPerSqft === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = propertyCostsPerSquareFoot.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.propertyCostPerSqft), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.propertyCostPerSqft), 2 / 3);

        out.status = (() => {
            switch (true) {  
                case selectedStoreValue > secondTertile:
                    return 'error';
                case selectedStoreValue >= firstTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const salesPerPoundOfPropertyCost = (state) => {
    return state.customer.insights.portfolio.performanceDrivers.storeSize.salesPerPoundOfPropertyCost;
};

const salesPerPoundOfPropertyCostGauge = createSelector(
    salesPerPoundOfPropertyCost,
    (salesPerPoundOfPropertyCost) => {
        const out = {
            loading: false,
            error: false,
            data: 0,
            min: 0,
            max: 0,
            med: 0,
            firstTertile: 0,
            secondTertile: 0
        };
        if (salesPerPoundOfPropertyCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfPropertyCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfPropertyCost.selectedStore;
        const overallData = salesPerPoundOfPropertyCost.overallData;
        const comparatorStores = salesPerPoundOfPropertyCost.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //selected store value
        out.data = selectedStore.salesPerPoundOfPropertyCost;

        //Max and min from both selected and comparator (for display on each end of the gauge)
        out.max = Math.max(...overallData.map(item => item.salesPerPoundOfPropertyCost));
        out.min = Math.min(...overallData.map(item => item.salesPerPoundOfPropertyCost));

        //comparator median
        out.med = median(...comparatorStores.map(item => item.salesPerPoundOfPropertyCost));

        //tertiles
        out.firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfPropertyCost), 1 / 3);
        out.secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfPropertyCost), 2 / 3);

        return out;
    }
);

const salesPerPoundOfPropertyCostVariance = createSelector(
    salesPerPoundOfPropertyCost,
    (salesPerPoundOfPropertyCost) => {
        const out = {
            loading: false,
            error: false,
            value: 0
        };
        if (salesPerPoundOfPropertyCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfPropertyCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfPropertyCost.selectedStore;
        const comparatorStores = salesPerPoundOfPropertyCost.comparatorStores;
        if (Object.keys(selectedStore).length === 0 || comparatorStores.length === 0) {
            return out;
        }

        //comparator median
        const comparatorMedian = median(...comparatorStores.map(item => item.salesPerPoundOfPropertyCost));
        //selected store value
        const selectedStoreValue = selectedStore.salesPerPoundOfPropertyCost;

        //variance from selected store value to median
        out.value = (comparatorMedian === 0) ? 0 : 100 * (selectedStoreValue - comparatorMedian) / comparatorMedian;

        return out;
    }
);

const salesPerPoundOfPropertyCostRank = createSelector(
    salesPerPoundOfPropertyCost,
    (salesPerPoundOfPropertyCost) => {
        const out = {
            loading: false,
            error: false,
            numerator: 0,
            denominator: 0
        };
        if (salesPerPoundOfPropertyCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfPropertyCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const selectedStore = salesPerPoundOfPropertyCost.selectedStore;
        const overallData = salesPerPoundOfPropertyCost.overallData;
        if (Object.keys(selectedStore).length === 0 || overallData.length === 0) {
            return out;
        }

        const sortedData = overallData.sort((a, b) => b.salesPerPoundOfPropertyCost - a.salesPerPoundOfPropertyCost);

        const selectedStoreValue = selectedStore.salesPerPoundOfPropertyCost;

        if (selectedStoreValue === 0) {
            out.numerator = 0;
            out.denominator = 0;
            out.status = "noData";
            return out;
        }

        //Rank of selected store value within store and comparator array
        out.numerator = 1 + sortedData.findIndex(item => item.salesPerPoundOfPropertyCost === selectedStoreValue);
        out.denominator = overallData.length;

        const comparatorStores = salesPerPoundOfPropertyCost.comparatorStores;
        const firstTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfPropertyCost), 1 / 3);
        const secondTertile = arrayUtils.quantile(comparatorStores.map(item => item.salesPerPoundOfPropertyCost), 2 / 3);
        
        out.status = (() => {
            switch (true) {  
                case selectedStoreValue < firstTertile:
                    return 'error';
                case selectedStoreValue <= secondTertile:
                    return 'warning';
                default:
                    return 'success';
            }
        })();

        return out;
    }
);

const storeContributionCategorisation = createSelector(
    salesPerSquareFoot,
    propertyCostsPerSquareFoot,
    (state) => selectStore(state),
    (salesPerSquareFoot, propertyCostsPerSquareFoot, selectedStore) => {
        const out = {
            loading: false,
            error: false,
            id: "store-contribution-categorisation",
            label: "Store contribution categorisation ",
            status: "info",
            value: ""
        };
        if (salesPerSquareFoot.error || propertyCostsPerSquareFoot.error) {
            out.loading = false;
            out.error = true;
            return out;
        }
        if (salesPerSquareFoot.loading || propertyCostsPerSquareFoot.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }

        const salesData = salesPerSquareFoot.overallData;
        const propertyCostData = propertyCostsPerSquareFoot.overallData;
        if (salesData.length === 0 || propertyCostData.length === 0) {
            out.status = "noData";
            out.value = `This indicator isn't available because it requires your company's property cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            return out;
        }

        const data = salesData.map(sps => {
            const pc = propertyCostData.find(item => item.store === sps.store);
            if (!pc) {
                return {
                    store: sps.store,
                    contribution: sps.salesPerSqft,
                };
            }
            return {
                store: sps.store,
                contribution: sps.salesPerSqft - pc.propertyCostPerSqft,
            };
        });

        const sortedData = data.sort((a, b) => b.contribution - a.contribution); //descending order - higher value gets lower rank

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.store === selectedStore);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            out.status = "success";
            out.value = `${selectedStore.name} is a top performer with respect to sales less property costs per square foot`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            out.status = "error";
            out.value = `${selectedStore.name} is underperforming with respect to sales less property costs per square foot`;
        } else { // equal to or between the 2 options
            out.status = "warning";
            out.value = `${selectedStore.name} is average with respect to sales less property costs per square foot`;
        }

        return out;
    }
);

const salesPerPoundPropCostCategorisation = createSelector(
    salesPerPoundOfPropertyCost,
    (state) => selectStore(state),
    (salesPerPoundOfPropertyCost, selectedStoreSelector) => {
        const out = {
            loading: false,
            error: false,
            id: "sales-per-pound-prop-cost-categorisation",
            label: "Sales per pound property cost categorisation",
            status: "info",
            value: ""
        };
        if (salesPerPoundOfPropertyCost.loading) {
            out.loading = true;
            out.error = false;
            return out;
        }
        if (salesPerPoundOfPropertyCost.error) {
            out.loading = false;
            out.error = true;
            return out;
        }

        const data = salesPerPoundOfPropertyCost.overallData;
        const sortedData = data.sort((a, b) => b.salesPerPoundOfPropertyCost - a.salesPerPoundOfPropertyCost); //descending order - higher value gets lower rank
        if (data.length === 0 || sortedData.length === 0 || sortedData[0]?.salesPerPoundOfPropertyCost === 0) {
            out.status = "noData";
            out.value = `This indicator isn't available because it requires your company's property cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            return out;
        }

        const selectedStore = salesPerPoundOfPropertyCost.selectedStore;
        const selectedStoreValue = selectedStore.salesPerPoundOfPropertyCost;

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.salesPerPoundOfPropertyCost === selectedStoreValue);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        const selectedStoreName = selectedStoreSelector.name;
        if (selectedPercentile < topThirdPercentile) {
            out.status = "success";
            out.value = `${selectedStoreName} is a top performer with respect to revenue per £ of property cost`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            out.status = "error";
            out.value = `${selectedStoreName} is underperforming with respect to revenue per £ of property cost`;
        } else { // equal to or between the 2 options
            out.status = "warning";
            out.value = `${selectedStoreName} is average with respect to revenue per £ of property cost`;
        }

        return out;
    }
);

const selectors = {
    salesPerSquareFoot,
    salesPerSquareFootGauge,
    salesPerSquareFootVariance,
    salesPerSquareFootRank,
    propertyCostsPerSquareFoot,
    propertyCostsPerSquareFootGauge,
    propertyCostsPerSquareFootVariance,
    propertyCostsPerSquareFootRank,
    salesPerPoundOfPropertyCost,
    salesPerPoundOfPropertyCostGauge,
    salesPerPoundOfPropertyCostVariance,
    salesPerPoundOfPropertyCostRank,
    storeContributionCategorisation,
    salesPerPoundPropCostCategorisation
};

export default selectors;
