const GET_BUDGET_VS_FORECAST_SALES_REQUEST = "spark/customer/insights/performance/budget/budgetVsForecastRevenue/GET_BUDGET_VS_FORECAST_SALES_REQUEST";
const GET_BUDGET_VS_FORECAST_SALES_SUCCESS = "spark/customer/insights/performance/budget/budgetVsForecastRevenue/GET_BUDGET_VS_FORECAST_SALES_SUCCESS";
const GET_BUDGET_VS_FORECAST_SALES_FAILURE = "spark/customer/insights/performance/budget/budgetVsForecastRevenue/GET_BUDGET_VS_FORECAST_SALES_FAILURE";

const types = {
    GET_BUDGET_VS_FORECAST_SALES_REQUEST,
    GET_BUDGET_VS_FORECAST_SALES_SUCCESS,
    GET_BUDGET_VS_FORECAST_SALES_FAILURE
};

export default types;
