import React from "react";
import { Grid } from "@material-ui/core";

import ProductCategoryMixMagicQuadrant from "./ProductCategoryMixMagicQuadrant";
import StoreDependencyOnProductCategory from "./StoreDependencyOnProductCategory";
import StoreBestProductCategory from "./StoreBestProductCategory";
import ProductCategoryMixFilter from "./ProductCategoryMixFilter";

const ProductCategoryMix = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProductCategoryMixFilter />
            </Grid>
            <Grid item xs={12}>
                <ProductCategoryMixMagicQuadrant />
            </Grid>
            <Grid item xs={12}>
                <StoreDependencyOnProductCategory />
            </Grid>
            <Grid item xs={12}>
                <StoreBestProductCategory />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryMix;
