import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class LocalSupply {
    public readonly storeId: string;
    public readonly storeCount: number;
    public readonly localArea: string;
    public readonly storeCategoryId: number;

    constructor(
        storeId: string,
        storeCount: number,
        localArea: string,
        storeCategoryId: number
    ) {
        this.storeId = storeId;
        this.storeCount = storeCount;
        this.localArea = localArea;
        this.storeCategoryId = storeCategoryId;
    }
}

export const loadLocalSupply = (stores: Store[]): AppThunk<Promise<LocalSupply[] | undefined>> => async (dispatch) => {
    try {
        if (!stores) {
            return undefined;
        }

        const localAreaByStoreCategoryID = new Map<number, string[]>();
        stores.forEach(store => {
            const localAreas = localAreaByStoreCategoryID.get(store.storeCategoryID) ?? [];
            localAreas.push(store.outputAreaCode);
            localAreaByStoreCategoryID.set(store.storeCategoryID, localAreas);
        });

        const orFilterClause = { or: [] };
        localAreaByStoreCategoryID.forEach((localAreas, storeCategoryID) => {
            const andFilterClause = {
                and: [{
                    member: "F_Supply.LocalArea",
                    operator: "equals",
                    values: localAreas.map(String)
                }, {
                    member: "F_Supply.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryID)]
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const query = {
            dimensions: [
                "F_Supply.StoreCount",
                "F_Supply.LocalArea",
                "F_Supply.StoreCategory_ID"
            ],
            filters: [orFilterClause],
            order: {
                "F_Supply.LocalArea": "asc"
            }
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        const localSupplies: LocalSupply[] = [];

        stores.forEach(store => {
            const relevantResults = rawData.filter(row =>
                row["F_Supply.LocalArea"] === store.outputAreaCode
                && Number(row["F_Supply.StoreCategory_ID"]) === store.storeCategoryID);

            const localSupply = relevantResults.map(row => new LocalSupply(
                store.id,
                Number(row["F_Supply.StoreCount"]) ?? 0,
                row["F_Supply.LocalArea"] ?? "",
                Number(row["F_Supply.StoreCategory_ID"]) ?? 0
            ));
            localSupplies.push(...localSupply);
        });

        return localSupplies;
    } catch (error) {
        dispatch(logError("Error loading Local Supply.", error));
        throw error;
    }
};
