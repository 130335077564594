import React from "react";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Analyse the intricacies of customer buying habits in your {store?.name} store.
            Assess the performance of each product category from both a growth and sales mix perspective, identifying your best, growing, steady, and worst performers to refine your sales and marketing strategies.
            Investigate performance discrepancies against {comparator?.name} to comprehend the nuances in local customer buying habits.
        </>
    );
};

export default Subtitle;
