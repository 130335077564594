import { useTheme } from "@material-ui/core/styles";

const useColourPalette = () => {
    const theme = useTheme();

    const background = [
        theme.palette.secondary.main,
        theme.palette.background.paper
    ];

    const RAGIndicators = [
        theme.palette.error.main,
        theme.palette.warning.main,
        theme.palette.success.main,
        "#4d4a60"
    ];

    const heatmap = [
        "#ff0516",
        "#fc7035",
        "#fab338",
        "#f9ef39",
        "#b6cf4a",
        "#05ff37"
    ];

    const categorical = [
        "#42e8e0",
        "#1c76fd",
        "#f755c9",
        "#e9730c",
        "#ff5c67",
        "#8bb340",
        "#f5d423",
        "#786eb6"
    ];

    const waterfall = {
        neutral: theme.palette.info.main,
        positive: theme.palette.success.main,
        negative: theme.palette.error.main
    };

    const comparators = [
        "#e99e0c",
        "#b27aff",
        "#775016",
        "#5C3E90"
    ];

    const sequential = [
        "#2BFFF5",
        "#03BEDD",
        "#0573BD",
        "#0A4385",
        "#05255D"
    ];

    const concentricCircles = [
        "#21e291",
        "#c2ec68",
        "#ff333f"
    ];

    const RAGChartZones = [
        "#1F1B37",
        "#2C2842",
        "#39354D"
    ];

    const cannibalisation = [
        "#070221",
        "#cdccd3",
        "#fec5ca",
        "#ff9f96",
        "#ff5442",
        "#ff0516"
    ];

    return {
        background,
        RAGIndicators,
        heatmap,
        categorical,
        waterfall,
        comparators,
        sequential,
        concentricCircles,
        RAGChartZones,
        cannibalisation
    };
};

export default useColourPalette;
