import React from "react";

const ForecastRevenueSubtitle: React.FC = () => {
    return (
        <>
            What does the future hold for your business? Understand your forecast revenue and growth performance for the
            next 12 months. We calculate our forecasts based on: your historical performance and trends in the markets
            you trade within.
        </>
    );
};

export default ForecastRevenueSubtitle;
