import types from "./types";

const getSalesYTDRequest = () => ({
    type: types.GET_SALES_YTD_REQUEST
});

const getSalesYTDSuccess = (value, label) => ({
    type: types.GET_SALES_YTD_SUCCESS,
    payload: {
        value,
        label
    }
});

const getSalesYTDFailure = () => ({
    type: types.GET_SALES_YTD_FAILURE
});

const getTotalSalesLatestFullMonthRequest = () => ({
    type: types.GET_TOTAL_SALES_LATEST_FULL_MONTH_REQUEST
});

const getTotalSalesLatestFullMonthSuccess = (value, currentMonth, currentYear) => ({
    type: types.GET_TOTAL_SALES_LATEST_FULL_MONTH_SUCCESS,
    payload: {
        value,
        currentMonth,
        currentYear
    }
});

const getTotalSalesLatestFullMonthFailure = () => ({
    type: types.GET_TOTAL_SALES_LATEST_FULL_MONTH_FAILURE
});

const getAvgSalesLatestFullMonthRequest = () => ({
    type: types.GET_AVG_SALES_LATEST_FULL_MONTH_REQUEST
});

const getAvgSalesLatestFullMonthSuccess = (value, currentMonth, currentYear) => ({
    type: types.GET_AVG_SALES_LATEST_FULL_MONTH_SUCCESS,
    payload: {
        value,
        currentMonth,
        currentYear
    }
});

const getAvgSalesLatestFullMonthFailure = () => ({
    type: types.GET_AVG_SALES_LATEST_FULL_MONTH_FAILURE
});

const getHistoricShortTermCompanySalesTrendRequest = () => ({
    type: types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_REQUEST
});

const getHistoricShortTermCompanySalesTrendSuccess = (status, value) => ({
    type: types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getHistoricShortTermCompanySalesTrendFailure = () => ({
    type: types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_FAILURE
});

const getSalesHistoryRequest = () => ({
    type: types.GET_SALES_HISTORY_REQUEST
});

const getSalesHistorySuccess = (values) => ({
    type: types.GET_SALES_HISTORY_SUCCESS,
    payload: {
        values
    }
});

const getSalesHistoryFailure = () => ({
    type: types.GET_SALES_HISTORY_FAILURE
});

const actions = {
    getSalesYTDRequest,
    getSalesYTDSuccess,
    getSalesYTDFailure,
    getTotalSalesLatestFullMonthRequest,
    getTotalSalesLatestFullMonthSuccess,
    getTotalSalesLatestFullMonthFailure,
    getAvgSalesLatestFullMonthRequest,
    getAvgSalesLatestFullMonthSuccess,
    getAvgSalesLatestFullMonthFailure,
    getHistoricShortTermCompanySalesTrendRequest,
    getHistoricShortTermCompanySalesTrendSuccess,
    getHistoricShortTermCompanySalesTrendFailure,
    getSalesHistoryRequest,
    getSalesHistorySuccess,
    getSalesHistoryFailure
};

export default actions;
