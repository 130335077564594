import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";
import Marker from "./Marker";
import StoresVisibilityFilter from "./StoresVisibilityFilter";

import { useAppSelector } from "store";
import { selectOutputAreas, selectStores } from "modules/customer/tools/location/areaHealth/areaHealthSlice";
import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";

enum ColourGroup {
    NetOpenings,
    NoChange,
    NetClosures
}

const Map: React.FC = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    // @ts-ignore
    const colours = [theme.palette.tertiary.main, theme.palette.text.disabled, colourPalette.categorical[4]];
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const outputAreas = useAppSelector(selectOutputAreas);
    const stores = useAppSelector(selectStores);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredOutputArea = outputAreas.find(outputArea => outputArea.code === hoverInfo.outputAreaCode);
    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const mapOutputAreas = React.useMemo(() => {
        return outputAreas.map(outputArea => ({
            code: outputArea.code,
            colourGroup: outputArea.netOpenings > 0
                ? ColourGroup.NetOpenings
                : outputArea.netOpenings < 0 ? ColourGroup.NetClosures : ColourGroup.NoChange
        }));
    }, [outputAreas]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="area-health-map">
            <OutputAreasMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colours}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                downloadData={stores}
            >
                <Explore />
                <PinnedLocation />
                {stores.map((store, index) =>
                    <Marker key={index} store={store} />
                )}
                <Legend outputArea={hoveredOutputArea} />
                <StoresVisibilityFilter />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
