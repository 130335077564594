import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { useAppDispatch, useAppSelector } from "store";
import { selectRegions } from "modules/customer/insights/portfolio/portfolioSlice";
import {
    selectCustomSelectionStoresFilter,
    selectIsCustomSelectionStoresFilterModified,
    setCustomSelectionStoresFilter,
    clearCustomSelectionStoresFilter,
    StoresFilter
} from "modules/customer/insights/portfolio/filters/filtersSlice";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(35)
    }
}))(Menu);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectRegions);
    const customSelectionStoresFilter = useAppSelector(selectCustomSelectionStoresFilter);
    const isCustomSelectionStoresFilterModified = useAppSelector(selectIsCustomSelectionStoresFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentCustomSelectionStoresFilter, setCurrentCustomSelectionStoresFilter] = React.useState<StoresFilter>(customSelectionStoresFilter);
    const marks5 = [{
        value: 0, label: "0"
    }, {
        value: 1, label: "1"
    }, {
        value: 2, label: "2"
    }, {
        value: 3, label: "3"
    }, {
        value: 4, label: "4"
    }, {
        value: 5, label: "5"
    }];
    const marks30 = [{
        value: 0, label: "0"
    }, {
        value: 5, label: "5"
    }, {
        value: 10, label: "10"
    }, {
        value: 15, label: "15"
    }, {
        value: 20, label: "20"
    }, {
        value: 25, label: "25"
    }, {
        value: 30, label: "30"
    }];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearCustomSelectionStoresFilter());
    };

    const setCustomSelectionStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((customSelectionStoresFilter: StoresFilter) => dispatch(setCustomSelectionStoresFilter(customSelectionStoresFilter)), 400);
    }, [dispatch]);

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const region = event.target.value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, region };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleTotalScoreChange = (event: React.ChangeEvent<{}>, value: any) => {
        const totalScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, totalScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    };

    const handleSalesScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const salesScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, salesScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    const handleDriversScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const driversScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, driversScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    const handleProfitScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const profitScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, profitScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    const handleAreaHealthScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const areaHealthScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, areaHealthScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    const handleCompetitionScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const competitionScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, competitionScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    const handleCatchmentScoreChange = ((event: React.ChangeEvent<{}>, value: any) => {
        const catchmentScore = value;
        const newCustomSelectionStoresFilter = { ...customSelectionStoresFilter, catchmentScore };
        setCurrentCustomSelectionStoresFilter(newCustomSelectionStoresFilter);
        setCustomSelectionStoresFilterDelayed(newCustomSelectionStoresFilter);
    });

    React.useEffect(() => {
        setCurrentCustomSelectionStoresFilter(customSelectionStoresFilter);
    }, [customSelectionStoresFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isCustomSelectionStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box>
                        <Typography variant="body1" component="div" gutterBottom>
                            Region
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentCustomSelectionStoresFilter.region}
                            onChange={handleRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {regions.map((region, index) =>
                                <MenuItem key={index} value={region}>
                                    {region}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Total score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.totalScore]}
                            onChange={handleTotalScoreChange}
                            step={5}
                            min={0}
                            max={30}
                            marks={marks30}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Sales score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.salesScore]}
                            onChange={handleSalesScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Drivers score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.driversScore]}
                            onChange={handleDriversScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Profit score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.profitScore]}
                            onChange={handleProfitScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Area health score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.areaHealthScore]}
                            onChange={handleAreaHealthScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Competition score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.competitionScore]}
                            onChange={handleCompetitionScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">Catchment score</Typography>
                        <Slider
                            value={[...currentCustomSelectionStoresFilter.catchmentScore]}
                            onChange={handleCatchmentScoreChange}
                            step={1}
                            min={0}
                            max={5}
                            marks={marks5}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
