import types from "./types";

const getStoreActualVsBudgetRankedRequest = () => ({
    type: types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_REQUEST
});

const getStoreActualVsBudgetRankedSuccess = (storePerformanceRankings) => ({
    type: types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_SUCCESS,
    payload: {
        storePerformanceRankings
    }
});

const getStoreActualVsBudgetRankedFailure = () => ({
    type: types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_FAILURE
});

const actions = {
    getStoreActualVsBudgetRankedRequest,
    getStoreActualVsBudgetRankedSuccess,
    getStoreActualVsBudgetRankedFailure
};

export default actions;
