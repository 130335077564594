import React from "react";

import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { SubchapterProps } from "components/customer/insights/Subchapter";
import SubchapterHeader from "components/customer/insights/SubchapterHeader";

import OverallStorePerformance from "./OverallStorePerformance";
import StoreVsComparatorPerformance from "./StoreVsComparatorPerformance";

const RelativePerformanceSubchapter: React.FC<SubchapterProps> = (props) => {
    const { id, number, title, subtitle, subchapterRef, dataCy } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const scrollMarginTop = isMobile ? theme.spacing(18) : theme.spacing(3);

    return (
        // @ts-ignore
        <Box id={id} paddingBottom={8} ref={subchapterRef} data-cy={dataCy} style={{ scrollMarginTop: scrollMarginTop }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <SubchapterHeader number={number} title={title} subtitle={subtitle} />
                    <OverallStorePerformance />
                </Grid>
                <Grid item md={6} xs={12}>
                    <StoreVsComparatorPerformance />
                </Grid>
            </Grid>
        </Box>
    );
};

export default RelativePerformanceSubchapter;
