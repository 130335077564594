import types from "./types";

const getPayrollCostPerSqftRequest = () => ({
    type: types.GET_PAYROLL_COST_PER_SQFT_REQUEST
});

const getPayrollCostPerSqftSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_PAYROLL_COST_PER_SQFT_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getPayrollCostPerSqftFailure = () => ({
    type: types.GET_PAYROLL_COST_PER_SQFT_FAILURE
});

const getSalesValuePerHeadRequest = () => ({
    type: types.GET_SALES_VALUE_PER_HEAD_REQUEST
});

const getSalesValuePerHeadSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_SALES_VALUE_PER_HEAD_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getSalesValuePerHeadFailure = () => ({
    type: types.GET_SALES_VALUE_PER_HEAD_FAILURE
});

const getSalesPerPoundOfStaffCostRequest = () => ({
    type: types.GET_SALES_PER_POUND_OF_STAFF_COST_REQUEST
});

const getSalesPerPoundOfStaffCostSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_SALES_PER_POUND_OF_STAFF_COST_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getSalesPerPoundOfStaffCostFailure = () => ({
    type: types.GET_SALES_PER_POUND_OF_STAFF_COST_FAILURE
});

const actions = {
    getPayrollCostPerSqftRequest,
    getPayrollCostPerSqftSuccess,
    getPayrollCostPerSqftFailure,
    getSalesValuePerHeadRequest,
    getSalesValuePerHeadSuccess,
    getSalesValuePerHeadFailure,
    getSalesPerPoundOfStaffCostRequest,
    getSalesPerPoundOfStaffCostSuccess,
    getSalesPerPoundOfStaffCostFailure
};

export default actions;
