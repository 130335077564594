import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Link,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";
import {
    selectCanSubmit,
    selectContact,
    selectSubjects,
    sendMessage,
    setContact
} from "modules/landing/contactUs/contactUsSlice";
import { selectGoogleRecaptchaConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppDispatch, useAppSelector } from "store";

const useStyles = makeStyles(theme => ({
    dropdown: {
        backgroundColor: theme.palette.common.white
    },
    row: {
        paddingTop: theme.spacing(2)
    },
    checkbox: {
        marginLeft: "10px"
    }
}));

const CustomTextField = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        "& label": {
            // @ts-ignore
            color: theme.palette.quaternary.light,
        },
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
            }
        }
    }
}))(TextField);

const Form: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const googleRecaptchaConfiguration = useAppSelector(selectGoogleRecaptchaConfiguration);
    const googleRecaptchaSiteKey = googleRecaptchaConfiguration.siteKey;
    const contact = useAppSelector(selectContact);
    const subjects = useAppSelector(selectSubjects);
    const canSubmit = useAppSelector(selectCanSubmit);
    const bookADemo = (contact.subject === "book-a-demo");

    const handleTrackEvent = (category: string, action: string, label: string) => {
        dispatch(trackEvent(category, action, label));
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = event.target.value;
        const newContact = { ...contact, firstName, errors: { ...contact.errors, firstName: "" } };
        dispatch(setContact(newContact));
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = event.target.value;
        const newContact = { ...contact, lastName, errors: { ...contact.errors, lastName: "" } };
        dispatch(setContact(newContact));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const newContact = { ...contact, email, errors: { ...contact.errors, email: "" } };
        dispatch(setContact(newContact));
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phone = event.target.value;
        const newContact = { ...contact, phone, errors: { ...contact.errors, phone: "" } };
        dispatch(setContact(newContact));
    };

    const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const company = event.target.value;
        const newContact = { ...contact, company, errors: { ...contact.errors, company: "" } };
        dispatch(setContact(newContact));
    };

    const handleJobRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const jobRole = event.target.value;
        const newContact = { ...contact, jobRole, errors: { ...contact.errors, jobRole: "" } };
        dispatch(setContact(newContact));
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subject = event.target.value;
        const newContact = { ...contact, subject, errors: { ...contact.errors, subject: "" } };
        dispatch(setContact(newContact));
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message = event.target.value;
        const newContact = { ...contact, message, errors: { ...contact.errors, message: "" } };
        dispatch(setContact(newContact));
    };

    const handleAcceptPrivacyPolicyChange = () => {
        const acceptPrivacyPolicy = !contact.acceptPrivacyPolicy;
        const newContact = { ...contact, acceptPrivacyPolicy };
        if (newContact.acceptPrivacyPolicy) {
            bookADemo
                ? handleTrackEvent("Book a demo form", "Demo policy checked", "Book a demo privacy policy checkbox")
                : handleTrackEvent("Contact us form", "Contact policy checked", "Contact us privacy policy checkbox");
        } else {
            bookADemo
                ? handleTrackEvent("Book a demo form", "Demo policy unchecked", "Book a demo privacy policy checkbox")
                : handleTrackEvent("Contact us form", "Contact policy unchecked", "Contact us privacy policy checkbox");
        }
        dispatch(setContact(newContact));
    };

    const handleCaptchaChange = (token: string | null) => {
        const captchaToken = token ?? "";
        const newContact = { ...contact, captchaToken, errors: { ...contact.errors, captchaToken: "" } };
        if (newContact.captchaToken) {
            bookADemo
                ? handleTrackEvent("Book a demo form", "Demo captcha checked", "Book a demo reCaptcha checkbox")
                : handleTrackEvent("Contact us form", "Contact captcha checked", "Contact us reCaptcha checkbox");
        }
        dispatch(setContact(newContact));
    };

    const handleSubmitClick = () => {
        bookADemo
            ? handleTrackEvent("Book a demo form", "Demo submit button click", "Book a demo submit form button")
            : handleTrackEvent("Contact us form", "Contact submit button click", "Contact us submit form button");
        dispatch(sendMessage());
    };

    const handleBlurEvent = (trackingValue: string, label: string) => {
        if (trackingValue.length > 0) {
            bookADemo
                ? handleTrackEvent(label, `Demo - ${label} completed`, `Book a demo ${label} field`)
                : handleTrackEvent(label, `Contact -${label} completed`, `Contact us ${label} field`);
        } else {
            bookADemo
                ? handleTrackEvent(label, `Demo - ${label} skipped`, `Book a demo ${label} field`)
                : handleTrackEvent(label, `Contact -${label} skipped`, `Contact ${label} field`);
        }
    };

    const handleLinkClick = () => {
        bookADemo
            ? handleTrackEvent("Book a demo form", "Demo privacy policy click", "Book a demo privacy policy link")
            : handleTrackEvent("Contact us form", "Contact privacy policy click", "Contact us privacy policy link");
    };

    return (
        // @ts-ignore
        <PageSection maxWidth="sm" dataCy="pages-landing-contact-us-form">
            <CustomTextField
                label="First Name"
                variant="outlined"
                value={contact.firstName}
                onChange={handleFirstNameChange}
                onBlur={() => handleBlurEvent(contact.firstName, "First Name")}
                error={!!contact.errors.firstName}
                helperText={contact.errors.firstName}
                margin="normal"
                required
                fullWidth
            />
            <CustomTextField
                label="Last Name"
                variant="outlined"
                value={contact.lastName}
                onChange={handleLastNameChange}
                onBlur={() => handleBlurEvent(contact.lastName, "Last Name")}
                error={!!contact.errors.lastName}
                helperText={contact.errors.lastName}
                margin="normal"
                required
                fullWidth
            />
            <CustomTextField
                label="Email Address"
                variant="outlined"
                type="email"
                value={contact.email}
                onChange={handleEmailChange}
                onBlur={() => handleBlurEvent(contact.email, "Email Address")}
                error={!!contact.errors.email}
                helperText={contact.errors.email}
                margin="normal"
                required
                fullWidth
            />
            <CustomTextField
                label="Phone Number"
                variant="outlined"
                value={contact.phone}
                onChange={handlePhoneChange}
                onBlur={() => handleBlurEvent(contact.phone, "Phone Number")}
                error={!!contact.errors.phone}
                helperText={contact.errors.phone}
                margin="normal"
                fullWidth
            />
            <CustomTextField
                label="Company Name"
                variant="outlined"
                value={contact.company}
                onChange={handleCompanyChange}
                onBlur={() => handleBlurEvent(contact.company, "Company Name")}
                error={!!contact.errors.company}
                helperText={contact.errors.company}
                margin="normal"
                required
                fullWidth
            />
            <CustomTextField
                label="Job Role"
                variant="outlined"
                value={contact.jobRole}
                onChange={handleJobRoleChange}
                onBlur={() => handleBlurEvent(contact.jobRole, "Job Role")}
                error={!!contact.errors.jobRole}
                helperText={contact.errors.jobRole}
                margin="normal"
                required
                fullWidth
            />
            <CustomTextField
                label="Subject"
                variant="outlined"
                select
                value={contact.subject}
                onChange={handleSubjectChange}
                onBlur={() => handleBlurEvent(contact.subject, "Subject")}
                error={!!contact.errors.subject}
                helperText={contact.errors.subject}
                margin="normal"
                SelectProps={{
                    MenuProps: {
                        classes: { paper: classes.dropdown },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        }
                    }
                }}
                required
                fullWidth>
                {subjects.map(subject =>
                    <MenuItem key={subject.id} value={subject.value}>
                        {subject.value}
                    </MenuItem>
                )}
                <MenuItem value="book-a-demo">Book a demo</MenuItem>
                <MenuItem value="other">Other</MenuItem>
            </CustomTextField>
            <CustomTextField
                label="Message"
                variant="outlined"
                multiline
                minRows={6}
                value={contact.message}
                onChange={handleMessageChange}
                onBlur={() => handleBlurEvent(contact.message, "Message")}
                error={!!contact.errors.message}
                helperText={contact.errors.message}
                margin="normal"
                required
                fullWidth
            />
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 38 }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 38 }} />}
                        checked={contact.acceptPrivacyPolicy}
                        onChange={handleAcceptPrivacyPolicyChange}
                        className={classes.checkbox} />
                }
                label={
                    <Typography variant="body2">
                        By submitting this form, I accept Dash's&nbsp;
                        <Link href="https://home.kpmg/uk/en/home/misc/privacy.html" target="_blank" onClick={handleLinkClick}>privacy
                            policy</Link>
                    </Typography>
                }
            />
            <FormControl className={classes.row}>
                <ReCAPTCHA
                    sitekey={googleRecaptchaSiteKey}
                    onChange={handleCaptchaChange}
                />
                {contact.errors.captchaToken && <FormHelperText error>{contact.errors.captchaToken}</FormHelperText>}
            </FormControl>
            <Box className={classes.row}>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disableElevation
                    disabled={!canSubmit}
                    onClick={handleSubmitClick}
                    data-cy="btn-submit"
                >
                    Submit form
                </Button>
            </Box>
        </PageSection>
    );
};

export default Form;
