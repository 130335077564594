import React from "react";

import { Box, Typography } from "@material-ui/core";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledRefreshRoundedIcon = withStyles(theme => ({
    root: {
        fontSize: theme.spacing(10),
        animation: "$rotate 2s linear infinite"
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    }
}))(RefreshRoundedIcon);

const RefreshInProgress: React.FC = () => {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            {/* @ts-ignore */}
            <Box align="center">
                <StyledRefreshRoundedIcon />
            </Box>
            <br />
            <Typography variant="h3" component="div" align="center">
                Analytics refresh in progress
            </Typography>
            <br />
            <Typography variant="h4" component="div" align="center">
                You will be notified via email once all Analytics have been refreshed.
                <br />
                You can still access current Insights and Tools.
            </Typography>
        </Box>
    );
};

export default RefreshInProgress;
