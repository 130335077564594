import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const ChildrenAgeStructure: React.FC = () => {
    const title = "Children's age structure (Children)";
    const categories = [
        "<1",
        "1-2",
        "3-5",
        "6-10",
        "11-16"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.childrenAgeStructure;
    const retailCentreValues = retailCentre ? [
        retailCentre.childrenAgeLessThan1,
        retailCentre.childrenAge1to2,
        retailCentre.childrenAge3to5,
        retailCentre.childrenAge6to10,
        retailCentre.childrenAge11to16
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.childrenAgeStructure;
    const benchmarkValues = benchmark ? [
        benchmark.childrenAgeLessThan1,
        benchmark.childrenAge1to2,
        benchmark.childrenAge3to5,
        benchmark.childrenAge6to10,
        benchmark.childrenAge11to16
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default ChildrenAgeStructure;
