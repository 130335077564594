import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Popup } from "react-map-gl";
import PropTypes from "prop-types";
import numberFormatter from "utils/numberFormatter";

import PercentageIndicator from "components/visuals/PercentageIndicator";

const FootfallByOutputAreaPopup = (props) => {
    const { outputAreas, hoverInfo, clickInfo } = props;
    const theme = useTheme();
    const popup = React.useMemo(() => {
        const outputAreaCode = clickInfo.outputAreaCode ?? hoverInfo.outputAreaCode;
        const outputArea = outputAreas.find(item => item.code === outputAreaCode);
        if (!outputArea) {
            return null;
        }
        const latitude = clickInfo.outputAreaCode ? clickInfo.latitude : hoverInfo.latitude;
        const longitude = clickInfo.outputAreaCode ? clickInfo.longitude : hoverInfo.longitude;
        return {
            latitude,
            longitude,
            outputArea
        };
    }, [hoverInfo, clickInfo, outputAreas]);

    if (!popup) {
        return null;
    }
    const valueFormatted = parseFloat(numberFormatter.toDecimalPlaces(popup.outputArea.percentageChangeYOY, 1));

    return (
        <Popup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={false}
            anchor="bottom"
        >
            <Box minWidth={theme.spacing(18)}>
                <Box display="flex" alignItems="center">
                    <Typography variant="body2" component="div">
                        Footfall:&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {numberFormatter.toDecimalPlaces(popup.outputArea.value, 1)}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" paddingTop={1}>
                    <Typography variant="body2" component="div">
                        YoY (%) change:
                    </Typography>
                    <PercentageIndicator variant="subtitle2" showIndicator={false} value={valueFormatted} />
                </Box>
            </Box>
        </Popup>
    );
};

FootfallByOutputAreaPopup.propTypes = {
    outputAreas: PropTypes.array.isRequired,
    hoverInfo: PropTypes.object,
    clickInfo: PropTypes.object
};

export default FootfallByOutputAreaPopup;
