import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class StoreCountsNetOpenings {
    public readonly outputAreaCode: string;
    public readonly retailCentreId: number;
    public readonly storeCategory: string;
    public readonly isRetail: boolean;
    public readonly storeCountCurrent: number;
    public readonly storeCount6MonthsAgo: number;
    public readonly netOpenings0to6MonthsAgo: number;
    public readonly storeCount12MonthsAgo: number;
    public readonly netOpenings7to12MonthsAgo: number;
    public readonly storeCount24MonthsAgo: number;
    public readonly netOpenings13to24MonthsAgo: number;
    public readonly storeCount60MonthsAgo: number;
    public readonly netOpenings25to60MonthsAgo: number;

    constructor(
        outputAreaCode: string,
        retailCentreId: number,
        storeCategory: string,
        isRetail: boolean,
        storeCountCurrent: number,
        storeCount6MonthsAgo: number,
        netOpenings0to6MonthsAgo: number,
        storeCount12MonthsAgo: number,
        netOpenings7to12MonthsAgo: number,
        storeCount24MonthsAgo: number,
        netOpenings13to24MonthsAgo: number,
        storeCount60MonthsAgo: number,
        netOpenings25to60MonthsAgo: number
    ) {
        this.outputAreaCode = outputAreaCode;
        this.retailCentreId = retailCentreId;
        this.storeCategory = storeCategory;
        this.isRetail = isRetail;
        this.storeCountCurrent = storeCountCurrent;
        this.storeCount6MonthsAgo = storeCount6MonthsAgo;
        this.netOpenings0to6MonthsAgo = netOpenings0to6MonthsAgo;
        this.storeCount12MonthsAgo = storeCount12MonthsAgo;
        this.netOpenings7to12MonthsAgo = netOpenings7to12MonthsAgo;
        this.storeCount24MonthsAgo = storeCount24MonthsAgo;
        this.netOpenings13to24MonthsAgo = netOpenings13to24MonthsAgo;
        this.storeCount60MonthsAgo = storeCount60MonthsAgo;
        this.netOpenings25to60MonthsAgo = netOpenings25to60MonthsAgo;
    }
}

export const loadStoreCountsNetOpenings = (retailCentreIds: number[]): AppThunk<Promise<StoreCountsNetOpenings[]>> => async (dispatch) => {
    try {
        const retailCentreIdStrings = retailCentreIds.map(retailCentreId => retailCentreId.toString());
        const storeCountsNetOpeningsQuery = {
            dimensions: [
                "view_LocationAreaHealth.OAID",
                "view_LocationAreaHealth.RetailCentreID",
                "view_LocationAreaHealth.KPMGStoreCategory",
                "view_LocationAreaHealth.IsRetail",
                "view_LocationAreaHealth.StoreCount_Current",
                "view_LocationAreaHealth.StoreCount_6MonthsAgo",
                "view_LocationAreaHealth.NetOpenings_0to6MonthsAgo",
                "view_LocationAreaHealth.StoreCount_12MonthsAgo",
                "view_LocationAreaHealth.NetOpenings_7to12MonthsAgo",
                "view_LocationAreaHealth.StoreCount_24MonthsAgo",
                "view_LocationAreaHealth.NetOpenings_13to24MonthsAgo",
                "view_LocationAreaHealth.StoreCount_60MonthsAgo",
                "view_LocationAreaHealth.NetOpenings_25to60MonthsAgo"
            ],
            filters: [{
                member: "view_LocationAreaHealth.OAID",
                operator: "notEquals",
                values: ["Unknown", "NULL", "Null", "null"]
            }, {
                member: "view_LocationAreaHealth.OAID",
                operator: "set"
            }, {
                member: "view_LocationAreaHealth.RetailCentreID",
                operator: "equals",
                values: retailCentreIdStrings
            }]
        };
        const resultSetStoreCountsNetOpenings = await dispatch(cubeLoad(storeCountsNetOpeningsQuery)) as unknown as ResultSet;
        const rawSetStoreCountsNetOpenings = resultSetStoreCountsNetOpenings.rawData();
        return rawSetStoreCountsNetOpenings.map(rawRow => new StoreCountsNetOpenings(
            rawRow["view_LocationAreaHealth.OAID"],
            rawRow["view_LocationAreaHealth.RetailCentreID"],
            rawRow["view_LocationAreaHealth.KPMGStoreCategory"] ?? "Unknown",
            Boolean(rawRow["view_LocationAreaHealth.IsRetail"]),
            rawRow["view_LocationAreaHealth.StoreCount_Current"] ?? 0,
            rawRow["view_LocationAreaHealth.StoreCount_6MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.NetOpenings_0to6MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.StoreCount_12MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.NetOpenings_7to12MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.StoreCount_24MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.NetOpenings_13to24MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.StoreCount_60MonthsAgo"] ?? 0,
            rawRow["view_LocationAreaHealth.NetOpenings_25to60MonthsAgo"] ?? 0
        ));
    } catch (error) {
        dispatch(logError("Error loading StoreCountsNetOpenings.", error));
        throw error;
    }
};
