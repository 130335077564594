import React from "react";

import { Box } from "@material-ui/core";

import { selectFootfallAlignmentScore } from "modules/customer/tools/location/footfall/footfallSlice";
import { selectIsLoading, selectHasErrors, selectTarget } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";
import KPIAlignment from "components/visuals/KPIAlignment";

const FootfallAlignmentScore: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const footfallAlignmentScore = useAppSelector(selectFootfallAlignmentScore);
    const label = "Footfall alignment score";
    const target = useAppSelector(selectTarget);

    const disabled = !(target?.useFootfall);

    return (
        <Box width="100%" height="100%" data-cy="footfall-alignment-score">
            <KPIAlignment
                isLoading={isLoading}
                hasErrors={hasErrors}
                label={label}
                score={footfallAlignmentScore}
                disabled={disabled}
            />
        </Box>
    );
};

export default FootfallAlignmentScore;
