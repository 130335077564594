import React from "react";

import { Box, Typography } from "@material-ui/core";
import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import { selectAverageStoreLifetimeInYears } from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import numberFormatter from "utils/numberFormatter";

const AverageStoreLifetime: React.FC = () => {
    const colourPalette = useColourPalette();
    const comparator = useAppSelector(selectComparator);
    const comparatorName = comparator?.name;
    const averageStoreLifetime = useAppSelector(selectAverageStoreLifetimeInYears);
    const { data, isLoading, hasErrors } = averageStoreLifetime;
    const selectedStoreValue = numberFormatter.toDecimalPlaces(data.selectedStore, 1) + " years";
    const comparatorValue = numberFormatter.toDecimalPlaces(data.comparator, 1) + " years";
    const difference = numberFormatter.toDecimalPlaces(data.selectedStore - data.comparator, 1) + " years";

    return (
        <Box width="100%" height="100%" data-cy="average-lifetime-of-stores-in-local-area">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {selectedStoreValue}
                    </Typography>
                }
                targetLabel="Average liftime of shops in the local area"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparatorValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparatorName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {difference}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default AverageStoreLifetime;
