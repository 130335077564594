import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const DwellingType: React.FC = () => {
    const title = "Dwelling type (Urbanicity)";
    const categories = [
        "Detached",
        "Other",
        "Semi-detached",
        "Terrace",
        "Flats"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.dwellingType;
    const retailCentreValues = retailCentre ? [
        retailCentre.detached,
        retailCentre.otherAccommodation,
        retailCentre.semiDetached,
        retailCentre.terrace,
        retailCentre.flat
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.dwellingType;
    const benchmarkValues = benchmark ? [
        benchmark.detached,
        benchmark.otherAccommodation,
        benchmark.semiDetached,
        benchmark.terrace,
        benchmark.flat
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default DwellingType;
