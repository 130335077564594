const cogsPastYear = (state) => {
    return state.customer.insights.portfolio.profit.grossProfitMargin.cogsPastYear;
};

const grossProfitLastYear = (state) => {
    return state.customer.insights.portfolio.profit.grossProfitMargin.grossProfitLastYear;
};

const grossProfitMarginLastYear = (state) => {
    return state.customer.insights.portfolio.profit.grossProfitMargin.grossProfitMarginLastYear;
};

const grossProfitMarginOverTime = (state) => {
    return state.customer.insights.portfolio.profit.grossProfitMargin.grossProfitMarginOverTime;
};

const grossProfitTrend = (state) => {
    return state.customer.insights.portfolio.profit.grossProfitMargin.grossProfitTrend;
};

const selectors = {
    cogsPastYear,
    grossProfitLastYear,
    grossProfitMarginLastYear,
    grossProfitMarginOverTime,
    grossProfitTrend
};

export default selectors;
