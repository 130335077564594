import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class AverageDailyFootfall {
    public readonly retailCentreId: number;
    public readonly dayIndex: number;
    public readonly timeBucket: string;
    public readonly timeBucketIndex: number;
    public readonly averageFootfall: number;

    constructor(
        retailCentreId: number,
        dayIndex: number,
        timeBucket: string,
        timeBucketIndex: number,
        averageFootfall: number
    ) {
        this.retailCentreId = retailCentreId;
        this.dayIndex = dayIndex;
        this.timeBucket = timeBucket;
        this.timeBucketIndex = timeBucketIndex;
        this.averageFootfall = averageFootfall;
    }
}

export const loadAverageDailyFootfall = (retailCentreId: number | undefined): AppThunk<Promise<AverageDailyFootfall[]>> => async (dispatch) => {
    if (!retailCentreId) {
        return [];
    }
    try {
        const retailCentreIdString = retailCentreId.toString();
        const query = {
            dimensions: [
                "F_DayTimeFootfall.RetailCentreID",
                "F_DayTimeFootfall.Day",
                "F_DayTimeFootfall.Time",
                "F_DayTimeFootfall.TimeBucketIndex",
                "F_DayTimeFootfall.Prev12MonthsAvgFootfall",
            ],
            filters: [{
                member: "F_DayTimeFootfall.RetailCentreID",
                operator: "equals",
                values: [retailCentreIdString]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new AverageDailyFootfall(
            row["F_DayTimeFootfall.RetailCentreID"],
            row["F_DayTimeFootfall.Day"],
            row["F_DayTimeFootfall.Time"],
            row["F_DayTimeFootfall.TimeBucketIndex"],
            row["F_DayTimeFootfall.Prev12MonthsAvgFootfall"]
        ));
    } catch (error) {
        dispatch(logError("Error loading AverageDailyFootfall.", error));
        throw error;
    }
};
