import React from "react";

import DumbbellAlignment from "components/visuals/DumbellAlignment";

import { useAppSelector } from "store";
import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";

const AreaHealthAlignmentDumbbell: React.FC = () => {

    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const target = useAppSelector(selectTarget);
    const targetIsSet = Boolean(target?.useAreaHealth);

    const chartValues = [{
        lowCategory: "Decreasing number of stores",
        highCategory: "Increasing number of stores",
        label: "Area health",
        selectedValue: pinnedLocation?.retailCentre.areaHealthCentile ?? 0,
        targetValue: target?.areaHealth ?? 0
    }];

    return (
        <DumbbellAlignment chartValues={chartValues} targetIsSet={targetIsSet} dataCy="area-health-alignment" />
    );
};

export default AreaHealthAlignmentDumbbell;
