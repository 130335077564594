const GET_GROWTH_BREAKDOWN_GRID_REQUEST = "spark/customer/insights/performance/sales/revenueGrowthBreakdown/GET_GROWTH_BREAKDOWN_GRID_REQUEST";
const GET_GROWTH_BREAKDOWN_GRID_SUCCESS = "spark/customer/insights/performance/sales/revenueGrowthBreakdown/GET_GROWTH_BREAKDOWN_GRID_SUCCESS";
const GET_GROWTH_BREAKDOWN_GRID_FAILURE = "spark/customer/insights/performance/sales/revenueGrowthBreakdown/GET_GROWTH_BREAKDOWN_GRID_FAILURE";

const types = {
    GET_GROWTH_BREAKDOWN_GRID_REQUEST,
    GET_GROWTH_BREAKDOWN_GRID_SUCCESS,
    GET_GROWTH_BREAKDOWN_GRID_FAILURE,
};

export default types;
