import types from "./types";

const getSalesPerformanceVsBudgetOverTimeRequest = () => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_REQUEST
});

const getSalesPerformanceVsBudgetOverTimeSuccess = (quarterlySales, quarterlyBudget) => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_SUCCESS,
    payload: {
        quarterlySales,
        quarterlyBudget
    }
});

const getSalesPerformanceVsBudgetOverTimeFailure = () => ({
    type: types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_FAILURE
});

const actions = {
    getSalesPerformanceVsBudgetOverTimeRequest,
    getSalesPerformanceVsBudgetOverTimeSuccess,
    getSalesPerformanceVsBudgetOverTimeFailure
};

export default actions;
