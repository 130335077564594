import React from "react";

import { Card, CardMedia, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const TheDashStory = () => {
    return (
        <PageSection dataCy="pages-landing-our-story-the-dash-story">
            <Grid container spacing={6} direction="row-reverse" alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/our-story/the-dash-story.png" alt="the dash story" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" component="div">
                        The Dash Story
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        We have spent years creating bespoke tools for businesses, helping them one by one unlock growth and solve problems using data.
                        In this time, we have developed a passion for delivering analytical solutions that help businesses make the right decisions.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        We wanted to bring the rich insights that these tools unlock to as many businesses as possible.
                        So, we set out to develop a widely accessible product that would enable all brands to better serve their customers.
                        We combined our rich understanding of sector pain points with cutting-edge technology and techniques to develop a truly comprehensive solution.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        A few years later, Dash was born.
                    </Typography>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default TheDashStory;
