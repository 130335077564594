const GET_COGS_PAST_YEAR_REQUEST = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_COGS_PAST_YEAR_REQUEST";
const GET_COGS_PAST_YEAR_SUCCESS = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_COGS_PAST_YEAR_SUCCESS";
const GET_COGS_PAST_YEAR_FAILURE = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_COGS_PAST_YEAR_FAILURE";
const GET_GROSS_PROFIT_LAST_YEAR_REQUEST = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_LAST_YEAR_REQUEST";
const GET_GROSS_PROFIT_LAST_YEAR_SUCCESS = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_LAST_YEAR_SUCCESS";
const GET_GROSS_PROFIT_LAST_YEAR_FAILURE = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_LAST_YEAR_FAILURE";
const GET_GROSS_PROFIT_MARGIN_LAST_YEAR_REQUEST = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_LAST_YEAR_REQUEST";
const GET_GROSS_PROFIT_MARGIN_LAST_YEAR_SUCCESS = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_LAST_YEAR_SUCCESS";
const GET_GROSS_PROFIT_MARGIN_LAST_YEAR_FAILURE = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_LAST_YEAR_FAILURE";
const GET_GROSS_PROFIT_MARGIN_OVER_TIME_REQUEST = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_OVER_TIME_REQUEST";
const GET_GROSS_PROFIT_MARGIN_OVER_TIME_SUCCESS = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_OVER_TIME_SUCCESS";
const GET_GROSS_PROFIT_MARGIN_OVER_TIME_FAILURE = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_MARGIN_OVER_TIME_FAILURE";
const GET_GROSS_PROFIT_TREND_REQUEST = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_TREND_REQUEST";
const GET_GROSS_PROFIT_TREND_SUCCESS = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_TREND_SUCCESS";
const GET_GROSS_PROFIT_TREND_FAILURE = "spark/customer/insights/portfolio/profit/grossProfitMargin/GET_GROSS_PROFIT_TREND_FAILURE";

const types = {
    GET_COGS_PAST_YEAR_REQUEST,
    GET_COGS_PAST_YEAR_SUCCESS,
    GET_COGS_PAST_YEAR_FAILURE,
    GET_GROSS_PROFIT_LAST_YEAR_REQUEST,
    GET_GROSS_PROFIT_LAST_YEAR_SUCCESS,
    GET_GROSS_PROFIT_LAST_YEAR_FAILURE,
    GET_GROSS_PROFIT_MARGIN_LAST_YEAR_REQUEST,
    GET_GROSS_PROFIT_MARGIN_LAST_YEAR_SUCCESS,
    GET_GROSS_PROFIT_MARGIN_LAST_YEAR_FAILURE,
    GET_GROSS_PROFIT_MARGIN_OVER_TIME_REQUEST,
    GET_GROSS_PROFIT_MARGIN_OVER_TIME_SUCCESS,
    GET_GROSS_PROFIT_MARGIN_OVER_TIME_FAILURE,
    GET_GROSS_PROFIT_TREND_REQUEST,
    GET_GROSS_PROFIT_TREND_SUCCESS,
    GET_GROSS_PROFIT_TREND_FAILURE
};

export default types;
