import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectSizeRelativeToDirectCompetitors } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

const SizeRelativeToDirectCompetitors = () => {
    const sizeRelativeToDirectCompetitors = useAppSelector(selectSizeRelativeToDirectCompetitors);

    return (
        <RAGIndicatorNew ragIndicator={sizeRelativeToDirectCompetitors} />
    );
};

export default SizeRelativeToDirectCompetitors;
