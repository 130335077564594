import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const HistoricOverallCompanySalesTrend = () => {
    const historicOverallCompanySalesTrend = useSelector(state => selectors.historicOverallCompanySalesTrend(state));
    const { loading, error, id, label, status, value } = historicOverallCompanySalesTrend;

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default HistoricOverallCompanySalesTrend;
