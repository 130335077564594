import React from "react";
import Highcharts from "highcharts";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import BeeSwarm from "components/visuals/BeeSwarm";
import useColourPalette from "components/visuals/useColourPalette";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

export interface StoreCentile {
    storeName: string,
    centileValue: number
}

const comparatorStoresMarkers = ["circle", "triangle", "square", "diamond", "triangle-down"];

interface CentileChartProps {
    comparatorStoresCentiles: StoreCentile[],
    aggregatedDirectCompetitorCentiles: StoreCentile[],
    lowLabel: string,
    highLabel: string,
    disabled: boolean
}

const CentileChart: React.FC<CentileChartProps> = (props) => {
    const { comparatorStoresCentiles, aggregatedDirectCompetitorCentiles, lowLabel, highLabel, disabled } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    const createPointOptions = (storeCentile: StoreCentile, marker?: string): Highcharts.PointOptionsObject => {
        return {
            name: storeCentile.storeName,
            x: storeCentile.centileValue,
            y: 0,
            marker: {
                symbol: marker
            }
        };
    };

    const comparatorStoresPoints = comparatorStoresCentiles.map((storeCentile, storeCentileIndex) => {
        const marker = comparatorStoresMarkers[storeCentileIndex] ?? "";
        return createPointOptions(storeCentile, marker);
    });

    const aggregatedDirectCompetitorPoints = aggregatedDirectCompetitorCentiles.map(dcCentile => {
        return createPointOptions(dcCentile);
    });

    const options: Highcharts.Options = {
        chart: {
            height: "78px",
            // @ts-ignore
            plotBackgroundColor: theme.palette.background.insight,
            backgroundColor: "transparent",
            spacingTop: 0,
            spacingBottom: 0,
            marginTop: 0,
            zooming: {
                type: undefined
            }
        },
        legend: {
            enabled: false
        },
        xAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            startOnTick: true,
            endOnTick: true,
            tickLength: 0,
            tickWidth: 0,
            tickPositions: [0, 100],
            labels: {
                formatter: function (): string {
                    return this.value === 0 ? lowLabel : highLabel;
                }
            }
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 6
                }
            }
        },
        tooltip: {
            enabled: !disabled,
            useHTML: true,
            headerFormat: `<table>`,
            pointFormatter: function () {
                const scoreSuffix = `percentile`;
                const header = String(this.options?.name ?? "");
                const percentileValueString = numberFormatter.toOrdinalNumber(Math.round(this.options?.x ?? 0));
                return stringUtils.tooltipHTML([`${percentileValueString} ${scoreSuffix}`], {
                    values: [],
                    header
                });
            }
        },
        series: [{
            type: "scatter",
            name: "Aggregated direct competitors",
            color: disabled ? theme.palette.text.secondary : "#FFFFFF",
            marker: {
                symbol: "triangle-down",
                lineWidth: 2.5,
                lineColor: disabled ? theme.palette.text.secondary : "#FFFFFF",
                fillColor: disabled ? theme.palette.text.secondary : "#FFFFFF"
            },
            data: aggregatedDirectCompetitorPoints
        },{
            type: "scatter",
            name: "Comparator stores",
            color: "transparent",
            marker: {
                lineColor: disabled ? theme.palette.text.secondary : colourPalette.comparators[1],
                lineWidth: 2.5
            },
            data: comparatorStoresPoints
        }],
        exporting: {
            enabled: false
        }
    };

    return (
        <Box data-cy="centile-chart">
            <BeeSwarm loading={false} error={false} options={options} useDefaultPlotBands={false} make2D={false} />
        </Box>
    );
};

export default CentileChart;
