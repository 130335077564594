import { immerable } from "immer";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import { ComplementaryCategory, ComplementaryIndicator } from "./complementaryCategory";
import { StoreCountsNetOpenings } from "./storeCountsNetOpenings";

export class StoreCategory {
    [immerable] = true;

    public readonly name: string;
    public readonly flags: string[];
    private _isSelected: boolean;

    constructor(
        name: string,
        flags: string[],
        isSelected: boolean
    ) {
        this.name = name;
        this.flags = flags;
        this._isSelected = isSelected;
    }

    displayValue() {
        const displayFlags = this.flags.reduce((phrase, word) => !phrase ? word : `${phrase}, ${word}`, "");
        if (displayFlags) {
            return `${this.name} (${displayFlags})`;
        }
        return this.name;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const createStoreCategories = (primaryStoreCategoryName: string, complementaryCategories: ComplementaryCategory[], storeCounts: StoreCountsNetOpenings[]) => {
    const complementaryCategoriesNames = complementaryCategories
        .sort((categoryA, categoryB) => stringSortExpression(categoryA.name, categoryB.name, SortDirection.ASC))
        .sort((categoryA, categoryB) => numberSortExpression(categoryA.indicator, categoryB.indicator, SortDirection.DESC))
        .map(category => category.name);

    const storeCountsCategories = storeCounts
        .sort((countsA, countsB) => stringSortExpression(countsA.storeCategory, countsB.storeCategory, SortDirection.ASC))
        .map(counts => counts.storeCategory);

    const categoriesNames = [primaryStoreCategoryName].concat(complementaryCategoriesNames).concat(storeCountsCategories);
    const uniqueCategoriesNames = Array.from(new Set(categoriesNames));
    return uniqueCategoriesNames.map(categoryName => {
        const flags: string[] = [];
        let isSelected = false;

        if (categoryName === primaryStoreCategoryName) {
            flags.push("your category");
            isSelected = true;
        }

        const complementaryCategory = complementaryCategories.find(category => category.name === categoryName);
        if (complementaryCategory) {
            const flag = complementaryCategory.indicator === ComplementaryIndicator.Complementary
                ? "complementary"
                : "non-complementary";
            flags.push(flag);
            isSelected ||= complementaryCategory.indicator === ComplementaryIndicator.Complementary;
        }

        return new StoreCategory(categoryName, flags, isSelected);
    });
};
