import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import BeeSwarm from "components/visuals/BeeSwarm";
import mathUtils from "utils/mathUtils";
import { useAppSelector } from "store";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";
import useColourPalette from "components/visuals/useColourPalette";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/rankedGrowth";

const StoreVsComparatorRankedLastTwelveMonthsGrowth = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorRankedLastTwelveMonthsGrowth = useSelector(state => selectors.storeVsComparatorRankedLastTwelveMonthsGrowth(state));
    const { loading, error, store, comparator } = storeVsComparatorRankedLastTwelveMonthsGrowth;

    const valuesArr = (data) => {
        const arr = (Array.isArray(data)) ? data : [data];
        return arr.map(row => {
            return Number(row.Growth);
        });
    };

    const allDataPoints = valuesArr(store).concat(valuesArr(comparator));
    const firstTertile = arrayUtils.quantile(allDataPoints, 1 / 3);
    const secondTertile = arrayUtils.quantile(allDataPoints, 2 / 3);

    const stores = store.map(function (current) {
        return {
            x: mathUtils.round(current.Growth, 2),
            custom: {
                store: current.storeName,
                rank: current.rank
            }
        };
    });

    const comparators = comparator.map(function (current) {
        return {
            x: mathUtils.round(current.Growth, 2),
            custom: {
                store: current.storeName,
                rank: current.rank
            }
        };
    });

    const options = {
        chart: {
            height: 230
        },
        title: {
            text: `Ranked year-on-year growth (%) for ${selectedComparator.name} and ${selectedStore.name}`,
            margin: 30
        },
        xAxis: {
            plotBands: [
                {
                    from: -1e6, to: firstTertile, color: colourPalette.RAGChartZones[0],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Underperforming", x: 0, y: -5
                    }
                },
                {
                    from: firstTertile, to: secondTertile, color: colourPalette.RAGChartZones[1],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Average", x: 0, y: -5
                    }
                },
                {
                    from: secondTertile, to:  1e6, color: colourPalette.RAGChartZones[2],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Top performer", x: 0, y: -5
                    }
                }
            ],
            labels: {
                format: "{text}%"
            }
        },
        series: [{
            name: selectedStore.name,
            color: colourPalette.comparators[0],
            data: stores
        },
            {
                name: `Store in ${selectedComparator.name}`,
                color: colourPalette.comparators[1],
                data: comparators
            }],
        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            pointFormatter: function () {
                const series = this.series;

                const rankNumerator = this.custom.rank;
                const rankDenominator = allDataPoints.length;
                const rankText = `${rankNumerator}/${rankDenominator}`;

                return stringUtils.tooltipHTML(["12 month growth: ", "Rank: "], {
                    header: `Store: <span style="color:${series.color}">${this.custom.store}</span>`,
                    values: [numberFormatter.toPercentage(this.x, true, 2), rankText]
                });
            },
            footerFormat: '</table>'
        },
    };

    return (
        <Box data-cy="store-vs-comparator-ranked-last-twelve-months-growth">
            <BeeSwarm loading={loading} error={error} options={options} />
        </Box>
    );
};

export default StoreVsComparatorRankedLastTwelveMonthsGrowth;
