import { logError } from "modules/helpers/logger/loggerSlice";
import actions from "./actions";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { selectStore, selectComparator, selectProductCategories, cubeLoadStoreCatchments } from "modules/customer/insights/portfolio/portfolioSlice";

const getMarketCatSpendPerHead = () => async (dispatch, getState) => {
    dispatch(actions.getMarketCatSpendPerHeadRequest());
    try {
        const state = getState();
        const productCategories = selectProductCategories(state);
        const selectedStoreSelector = selectStore(state);
        const comparatorStoresSelector = selectComparator(state);
        const selectedRetailCentreId = selectedStoreSelector.retailCentreID.toString();
        const selectedStoreCategory = selectedStoreSelector.kpmgStoreCategory;
        const selectedStoreName = selectedStoreSelector.name;
        const comparatorStores = comparatorStoresSelector.getStores();
        const comparatorName = comparatorStoresSelector.name;

        const relevantStores = [selectedStoreSelector].concat(...comparatorStores);

        const mapCenter = {
            latitude: selectedStoreSelector.latitude,
            longitude: selectedStoreSelector.longitude
        };

        // Selected Store
        const spendQuery = {
            dimensions: [
                "CatchmentMarketCategorySpend.RetailCentreID",
                "CatchmentMarketCategorySpend.OAID",
                "CatchmentMarketCategorySpend.SpendKpmgCategory",
                "CatchmentMarketCategorySpend.CategorySpendPerHead",
                "CatchmentMarketCategorySpend.KpmgStoreCategory",
                "CatchmentMarketCategorySpend.BaselineWeightedSpend"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals",
                values: [selectedRetailCentreId]
            },{
                member: "CatchmentMarketCategorySpend.KpmgStoreCategory",
                operator: "equals",
                values: [selectedStoreCategory]
            },{
                member: "CatchmentMarketCategorySpend.SpendKpmgCategory",
                operator: "equals",
                values: productCategories
            }],
            segments: [
                "CatchmentMarketCategorySpend.Baseline"
            ],
            order: [
                ["CatchmentMarketCategorySpend.OAID", "asc"]
            ]
        };
        const resultSetSpend = await dispatch(cubeLoadExtended(spendQuery));
        
        const selectedSpendData = resultSetSpend.loadResponses[0].data.map(item => ({
            name: selectedStoreName,
            retailCentreID: item["CatchmentMarketCategorySpend.RetailCentreID"],
            category: item["CatchmentMarketCategorySpend.SpendKpmgCategory"],
            oaid: item["CatchmentMarketCategorySpend.OAID"],
            spend: item["CatchmentMarketCategorySpend.CategorySpendPerHead"],
            weightedSpend: item["CatchmentMarketCategorySpend.BaselineWeightedSpend"],
        }));

        // Comparator Stores
        const queryCompMaxSpend = {
            dimensions: [
                "CatchmentMarketCatSpendAgg.RetailCentreID",
                "CatchmentMarketCatSpendAgg.KpmgStoreCategory",
                "CatchmentMarketCatSpendAgg.SpendKpmgCategory",
                "CatchmentMarketCatSpendAgg.TotalWeightedSpend",
                "CatchmentMarketCatSpendAgg.MedianSpendPerHead",
            ],
            filters: [{
                member: "CatchmentMarketCatSpendAgg.RetailCentreID",
                operator: "equals",
            },{
                member: "CatchmentMarketCatSpendAgg.KpmgStoreCategory",
                operator: "equals",
            },{
                member: "CatchmentMarketCatSpendAgg.SpendKpmgCategory",
                operator: "equals",
                values: productCategories
            }],
            segments: [
                "CatchmentMarketCatSpendAgg.Baseline"
            ]
        };
        
        const resultSetComparator = await dispatch(cubeLoadStoreCatchments(queryCompMaxSpend, relevantStores, "KpmgStoreCategory", "RetailCentreID"));
        
        const comparatorData = resultSetComparator.loadResponses[0].data.map(item => ({ 
            retailCentreID: item["CatchmentMarketCatSpendAgg.RetailCentreID"],
            storeCategory: item["CatchmentMarketCatSpendAgg.KpmgStoreCategory"],
            category: item["CatchmentMarketCatSpendAgg.SpendKpmgCategory"],
            totalSpend: item["CatchmentMarketCatSpendAgg.TotalWeightedSpend"],
            medianSpend: item["CatchmentMarketCatSpendAgg.MedianSpendPerHead"],
            name: comparatorName
        }));

        selectedStoreSelector.kpmgSpendCategories.forEach(productCategory => {
            dispatch(actions.selectMarketCategory(productCategory));
        });

        dispatch(actions.getMarketCatSpendPerHeadSuccess(mapCenter, selectedSpendData, comparatorData));
    }
    catch (error) {
        dispatch(actions.getMarketCatSpendPerHeadFailure());
        dispatch(logError("Error loading MarketCatSpendPerHead.", error));
    }
};

const selectMarketCategory = (marketCategory) => (dispatch) => {
    dispatch(actions.selectMarketCategory(marketCategory));
};

const deselectMarketCategory = (marketCategory) => (dispatch) => {
    dispatch(actions.deselectMarketCategory(marketCategory));
};

const clearSelectedMarketCategories = () => (dispatch) => {
    dispatch(actions.clearSelectedMarketCategories());
};

const operations = {
    getMarketCatSpendPerHead,
    selectMarketCategory,
    deselectMarketCategory,
    clearSelectedMarketCategories
};

export default operations;
