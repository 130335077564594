import React from "react";
import { Box, Typography } from "@material-ui/core";
import numberFormatter from "utils/numberFormatter";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectSelectedStoreYearlyRevenue } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import mathUtils from "utils/mathUtils";

const StoreRevenueVsPY = () => {
    const selectedStore = useAppSelector(selectStore);
    const storeForecastRevenueVsPY = useAppSelector(selectSelectedStoreYearlyRevenue);
    const { isLoading, hasErrors, data } = storeForecastRevenueVsPY;
    const valueFormatted = numberFormatter.toGBP(data.currentYear);
    const comparisonValue = numberFormatter.toGBP(data.previousYear);
    const differenceValue = mathUtils.safePercentageChange(data.currentYear, data.previousYear);
    const label = `${selectedStore?.name} past 12 months ${data.label} revenue`;

    return (
        <Box width="100%" height="100%" data-cy="store-revenue-vs-py">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {valueFormatted}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <PercentageIndicator loading={false} error={false} value={differenceValue} switchColor={false} noDataMessage={""} />
                }
                comparisonLabel={`% change from previous year`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {comparisonValue}
                    </Typography>
                }
                differenceLabel="Previous year's revenue"
            />
        </Box>
    );
};

export default StoreRevenueVsPY;
