import { combineReducers } from "redux";

import slice from "./portfolioSlice";

import filters from "./filters";
import overview from "./overview";
import revenue from "./revenue";
import profit from "./profit";
import drivers from "./drivers";
import catchment from "./catchment";
import competition from "./competition";
import areaHealth from "./areaHealth";
import footfall from "./footfall";

const reducer = combineReducers({
    root: slice.reducer,
    filters,
    overview,
    revenue,
    profit,
    drivers,
    catchment,
    competition,
    areaHealth,
    footfall
});

export default reducer;
