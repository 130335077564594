import React from "react";

import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledSlider = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled,
        "&.Mui-disabled ": {
            // @ts-ignore
            color: theme.palette.quaternary.light
        },
        width: "80%"
    },
    rail: {
        opacity: 1
    },
    mark: {
        opacity: 1,
        height: theme.spacing(0.75),
        width: theme.spacing(0.75),
        marginTop: theme.spacing(-0.25),
        borderRadius: theme.spacing(0.75),
        "&.MuiSlider-markActive": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.light
        }
    },
    thumb: {
        height: theme.spacing(1.75),
        width: theme.spacing(1.75),
        color: theme.palette.primary.main,
        border: "2px solid",
        borderColor: theme.palette.common.white,
        "&:focus, &:hover": {
            boxShadow: "none"
        },
        "&.Mui-disabled ": {
            display: "none"
        }
    }
}))(Slider);

interface CentileSliderProps {
    value: number,
    disabled: boolean,
    onSliderChange: (value: number) => void
}

const CentileSlider: React.FC<CentileSliderProps> = (props) => {
    const { value, disabled, onSliderChange } = props;

    const handleSliderChange = (event: React.ChangeEvent<{}>, value: (number | number[])) => {
        onSliderChange(value as number);
    };

    return (
        <StyledSlider
            value={value}
            onChange={handleSliderChange}
            step={10}
            marks
            min={5}
            max={95}
            track={false}
            disabled={disabled}
        />
    );
};

export default CentileSlider;
