import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import numberFormatter from 'utils/numberFormatter';
import stringUtils from 'utils/stringUtils';
import ChartBase from "./ChartBase";
import { useTheme } from "@material-ui/core";

const Waterfall = (props) => {
    const theme = useTheme();
    const { loading, error, options: customOptions } = props;
    let data = props.options.series[0].data;
    let sum = 0;
    let isNegative = false;
    let offsetValue = 0;

    data.forEach(element => {
        sum+=element.y;
    });

    if(sum<0){
        isNegative = true;
        offsetValue = 20;
    }

    const staticOptions = {
        chart: {
            type: "waterfall",
        },
        xAxis: {
            type: "category",
            opposite: isNegative,
            offset: offsetValue,
            lineWidth: 2,
            labels: {
                style: {
                    fontWeight: "normal",
                    fontSize: "14px",
                    textShadow: false
                }
            }
        },
        yAxis: {
            title: false,
            visible: false,
            labels: {
                enabled: false
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<table>',
            pointFormatter: function () {
                const tooltipProps = {
                        values: [numberFormatter.toGBP(this.y)], 
                        valueFormatting: `color:${this.color}`,
                        header: this.name || this.category
                };
                
                return stringUtils.tooltipHTML([this.series.name], tooltipProps);
            },
            footerFormat: '</table>',
            useHTML: true
        },
        plotOptions: {
            waterfall: {
                dataLabels: {
                    formatter: function () {
                       return numberFormatter.toGBP(this.y, 1);
                    },
                    enabled: true,
                    verticalAlign: "top",
                    crop: false,
                    overflow: "none",
                    y: -25,
                    style: {
                        fontWeight: "normal",
                        fontSize: "14px",
                        textShadow: false,
                        color: theme.palette.common.white                   
                    }
                }
            },
            series: {
                stacking: "normal",
                borderRadius: 7,
                dashStyle: "solid",
                lineColor: "white",
                minPointLength: 3
               
            }
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="waterfall-chart" />
    );
};

Waterfall.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Waterfall.defaultProps = {
    loading: false,
    error: false
};

export default Waterfall;
