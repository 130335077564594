import React from "react";
import { Grid } from "@material-ui/core";

import ContributionPerformanceVsBudget from "./ContributionPerformanceVsBudget";
import ContributionYTDPlusContributionYTGVsBudget from "./ContributionYTDPlusContributionYTGVsBudget";

const ContributionVsBudget = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContributionPerformanceVsBudget />
            </Grid>
            <Grid item xs={12}>
                <ContributionYTDPlusContributionYTGVsBudget />
            </Grid>
        </Grid>
    );
};

export default ContributionVsBudget;
