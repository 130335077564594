import React from "react";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";

import {
    hideAddAccountConfirmation,
    selectAddAccountConfirmationVisibility,
    showAddAccount
} from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const AddAccountConfirmation: React.FC = () => {
    const dispatch = useAppDispatch();
    const addAccountConfirmationVisibility = useAppSelector(selectAddAccountConfirmationVisibility);
    const show = (addAccountConfirmationVisibility.isVisible);

    const handleConfirmClick = () => {
        dispatch(showAddAccount());
        dispatch(hideAddAccountConfirmation());
    };

    const handleCancelClick = () => {
        dispatch(hideAddAccountConfirmation());
    };

    return (
        <ConfirmationDialog
            open={show}
            title="Are you sure you want to create an account?"
            subtitle={"There are high infrastructure costs associated with creating a new account."}
            type={ConfirmationDialogType.Warning}
            confirmLabel="Yes, create account"
            cancelLabel="No, cancel"
            onConfirm={handleConfirmClick}
            onCancel={handleCancelClick}
        />
    );
};

export default AddAccountConfirmation;
