import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Dash has evaluated your target demography based on five dimensions: affluence, age, children, diversity, and urbanicity.
            Dash compares the demography of your selected location's catchment area to your target demography and determines how strongly they align.
        </>
    );
};

export default Subtitle;
