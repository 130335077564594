import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectNonComplementaryMarketCategories } from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";

const NonComplementaryMarketCategories: React.FC = () => {
    const nonComplementaryMarketCategories = useAppSelector(selectNonComplementaryMarketCategories);
    return (
        <RAGIndicatorNew ragIndicator={nonComplementaryMarketCategories} />
    );
};

export default NonComplementaryMarketCategories;
