import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Admin {
    id: string,
    firstName: string,
    lastName: string,
    email: string
}

interface AdminsFilter {
    nameOrEmail: string
}

interface AdminForm {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    errors: AdminFormErrors
}

interface AdminFormErrors {
    firstName: string,
    lastName: string,
    email: string
}

interface AddAdminVisibility {
    isVisible: boolean
}

interface EditAdminVisibility {
    isVisible: boolean,
    adminId: string
}

interface DeleteAdminVisibility {
    isVisible: boolean,
    adminId: string
}

interface AdminsState {
    admins: Admin[],
    filter: AdminsFilter,
    admin: AdminForm,
    addAdminVisibility: AddAdminVisibility,
    editAdminVisibility: EditAdminVisibility,
    deleteAdminVisibility: DeleteAdminVisibility
}

const initialState: AdminsState = {
    admins: [],
    filter: {
        nameOrEmail: ""
    },
    admin: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        errors: {
            firstName: "",
            lastName: "",
            email: ""
        }
    },
    addAdminVisibility: {
        isVisible: false
    },
    editAdminVisibility: {
        isVisible: false,
        adminId: ""
    },
    deleteAdminVisibility: {
        isVisible: false,
        adminId: ""
    }
};

const adminsSlice = createSlice({
    name: "admin/admins",
    initialState,
    reducers: {
        setAdmins: (state, action: PayloadAction<Admin[]>) => {
            state.admins = action.payload;
        },
        clearAdmins: (state) => {
            state.admins = initialState.admins;
        },
        setFilter: (state, action: PayloadAction<AdminsFilter>) => {
            state.filter = action.payload;
        },
        setAdmin: (state, action: PayloadAction<AdminForm>) => {
            state.admin = action.payload;
        },
        clearAdmin: (state) => {
            state.admin = initialState.admin;
        },
        showAddAdmin: (state) => {
            state.addAdminVisibility.isVisible = true;
        },
        hideAddAdmin: (state) => {
            state.addAdminVisibility = initialState.addAdminVisibility;
        },
        showEditAdmin: (state, action: PayloadAction<string>) => {
            state.editAdminVisibility.isVisible = true;
            state.editAdminVisibility.adminId = action.payload;
        },
        hideEditAdmin: (state) => {
            state.editAdminVisibility = initialState.editAdminVisibility;
        },
        showDeleteAdmin: (state, action: PayloadAction<string>) => {
            state.deleteAdminVisibility.isVisible = true;
            state.deleteAdminVisibility.adminId = action.payload;
        },
        hideDeleteAdmin: (state) => {
            state.deleteAdminVisibility = initialState.deleteAdminVisibility;
        }
    }
});

export const {
    setFilter,
    setAdmin,
    clearAdmin,
    showAddAdmin,
    hideAddAdmin,
    showEditAdmin,
    hideEditAdmin,
    showDeleteAdmin,
    hideDeleteAdmin,
} = adminsSlice.actions;

export const getAdmins = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/admins"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const admins = response.data.admins;
            dispatch(adminsSlice.actions.setAdmins(admins));
            break;
        }
        default: {
            dispatch(adminsSlice.actions.clearAdmins());
            break;
        }
    }
};

export const getAdmin = (adminId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/admins/${adminId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const admin = response.data.admin;
            dispatch(adminsSlice.actions.setAdmin({ ...admin, errors: initialState.admin.errors }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(adminsSlice.actions.hideEditAdmin());
            dispatch(adminsSlice.actions.hideDeleteAdmin());
            dispatch(notifyError("Admin not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const addAdmin = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const admin = selectAdmin(state);
    const response = await dispatch(apiPost("/admin/admins", admin));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(adminsSlice.actions.hideAddAdmin());
            dispatch(adminsSlice.actions.clearAdmin());
            dispatch(notifySuccess("Admin added."));
            dispatch(getAdmins());
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0]
            };
            dispatch(adminsSlice.actions.setAdmin({ ...admin, errors }));
            break;
        }
        default:
            break;
    }
};

export const editAdmin = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const admin = selectAdmin(state);
    const response = await dispatch(apiPut(`/admin/admins/${admin.id}`, admin));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(adminsSlice.actions.hideEditAdmin());
            dispatch(adminsSlice.actions.clearAdmin());
            dispatch(notifySuccess("Admin edited."));
            dispatch(getAdmins());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(adminsSlice.actions.hideEditAdmin());
            dispatch(notifyError("Admin not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0]
            };
            dispatch(adminsSlice.actions.setAdmin({ ...admin, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const deleteAdmin = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const admin = selectAdmin(state);
    const response = await dispatch(apiDelete(`/admin/admins/${admin.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(adminsSlice.actions.hideDeleteAdmin());
            dispatch(adminsSlice.actions.clearAdmin());
            dispatch(notifySuccess("Admin deleted."));
            dispatch(getAdmins());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Admin not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const selectFilter = (state: RootState) => {
    return state.admin.admins.filter;
};

export const selectAdmins = createSelector(
    (state: RootState): Admin[] => state.admin.admins.admins,
    selectFilter,
    (admins, filter) => {
        const nameOrEmail = filter.nameOrEmail.toLowerCase();
        return admins.filter(admin =>
            admin.firstName.toLowerCase().includes(nameOrEmail)
            || admin.lastName.toLowerCase().includes(nameOrEmail)
            || admin.email.toLowerCase().includes(nameOrEmail));
    }
);

export const selectAdmin = (state: RootState) => {
    return state.admin.admins.admin;
};

export const selectAddAdminVisibility = (state: RootState) => {
    return state.admin.admins.addAdminVisibility;
};

export const selectEditAdminVisibility = (state: RootState) => {
    return state.admin.admins.editAdminVisibility;
};

export const selectDeleteAdminVisibility = (state: RootState) => {
    return state.admin.admins.deleteAdminVisibility;
};

export default adminsSlice;
