import { createSelector } from "reselect";

const categoryForecastSalesGrowthMarket = (state) => {
    return state.customer.insights.performance.forecast.forecastGrowthVsMarket.categoryForecastSalesGrowthMarket;
};

const forecastGrowthVsMarket = createSelector(
    categoryForecastSalesGrowthMarket,
    (categoryForecastSalesGrowthMarket) => {
        const rag = {
            loading: categoryForecastSalesGrowthMarket.loading,
            error: categoryForecastSalesGrowthMarket.error,
            id: "forecast-growth-vs-market",
            label: "Forecast growth vs market",
            status: "info",
            value: ""
        };

        if (rag.error || rag.loading || categoryForecastSalesGrowthMarket.categories.length === 0) {
            return rag;
        }
        
        const clientMainCategory = categoryForecastSalesGrowthMarket.categories[0];
        const clientForecastGrowth = categoryForecastSalesGrowthMarket.companyGrowth[0];
        const marketForecastGrowth = categoryForecastSalesGrowthMarket.benchmarkGrowth[0];

        const forecastGrowthVsMarket = (marketForecastGrowth === 0) ? 0 : ((clientForecastGrowth - marketForecastGrowth) / Math.abs(marketForecastGrowth));

        if (forecastGrowthVsMarket < -0.5) {
            rag.status = "error";
            rag.value = `Forecast growth in ${clientMainCategory} significantly behind the market`;
        } else if (forecastGrowthVsMarket <= 0.5) {
            rag.status = "warning";
            rag.value = `Forecast growth in ${clientMainCategory} is broadly in line with the market`;
        } else {
            rag.status = "success";
            rag.value = `Forecast growth in ${clientMainCategory} significantly ahead of the market`;
        }

        return rag;
    }
);

const selectors = {
    categoryForecastSalesGrowthMarket,
    forecastGrowthVsMarket
};

export default selectors;
