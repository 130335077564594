import { median } from "mathjs";

import actions from "./actions";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectCostReferenceDate, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getSalesToProfit = () => async (dispatch, getState) => {
    dispatch(actions.getSalesToProfitRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const referenceDate = selectCostReferenceDate(state).endOf("day");
        const priorTwelveMonthsToReferenceDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

        const salesQuery = {
            measures: ["F_Sales.SumLineValue", "F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, referenceDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const salesResultSet = await dispatch(cubeLoadExtended(salesQuery));

        const sales = salesResultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            sales: item["F_Sales.SumLineValue"],
            cogs: item["F_Sales.SumLineCost"]
        }));

        const costQuery = {
            measures: ["F_Cost.SumPropertyCost", "F_Cost.SumPayrollCost", "F_Cost.SumOtherCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, referenceDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const costResultSet = await dispatch(cubeLoadExtended(costQuery));

        const cost = costResultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            propertyCost: item["F_Cost.SumPropertyCost"],
            staffCost: item["F_Cost.SumPayrollCost"],
            otherCost: item["F_Cost.SumOtherCost"]
        }));

        const isNetProfit = (cost.length !== 0);

        const data = sales.map(s => {
            const costs = cost.find(c => c.store === s.store);
            if (!costs) {
                return {
                    store: s.store,
                    sales: s.sales,
                    cogs: s.cogs,
                    propertyCost: 0,
                    staffCost: 0,
                    otherCost: 0,
                    netProfit: s.sales - s.cogs
                };
            }
            return {
                store: s.store,
                sales: s.sales,
                cogs: s.cogs,
                propertyCost: costs.propertyCost,
                staffCost: costs.staffCost,
                otherCost: costs.otherCost,
                netProfit: s.sales - s.cogs - costs.propertyCost - costs.staffCost - costs.otherCost
            };
        });

        const selectedStore = data.find(item => item.store === selectedStoreID) || {};
        const comparatorStores = data.filter(item => comparatorStoresIDs.includes(item.store));

        const comparator = {
            sales: median(...comparatorStores.map(item => item.sales)),
            cogs: median(...comparatorStores.map(item => item.cogs)),
            propertyCost: median(...comparatorStores.map(item => item.propertyCost)),
            staffCost: median(...comparatorStores.map(item => item.staffCost)),
            otherCost: median(...comparatorStores.map(item => item.otherCost)),
            netProfit: median(...comparatorStores.map(item => item.netProfit))
        };

        dispatch(actions.getSalesToProfitSuccess(selectedStore, comparator, isNetProfit));
    }
    catch (error) {
        dispatch(actions.getSalesToProfitFailure());
        dispatch(logError("Error loading SalesToProfit.", error));
    }
};

const operations = {
    getSalesToProfit
};

export default operations;
