const GET_SETTINGS_SUCCESS = "spark/customer/account/setting/GET_SETTINGS_SUCCESS";
const GET_SETTINGS_FAILURE = "spark/customer/account/setting/GET_SETTINGS_FAILURE";
const SET_PERSONAL_INFO = "spark/customer/account/setting/SET_PERSONAL_INFO";
const SHOW_EDIT_PERSONAL_INFO = "spark/customer/account/setting/SHOW_EDIT_PERSONAL_INFO";
const HIDE_EDIT_PERSONAL_INFO = "spark/customer/account/setting/HIDE_EDIT_PERSONAL_INFO";
const SET_COMPANY_DETAILS = "spark/customer/account/setting/SET_COMPANY_DETAILS";
const SHOW_EDIT_COMPANY_DETAILS = "spark/customer/account/setting/SHOW_EDIT_COMPANY_DETAILS";
const HIDE_EDIT_COMPANY_DETAILS = "spark/customer/account/setting/HIDE_EDIT_COMPANY_DETAILS";

const types = {
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
    SET_PERSONAL_INFO,
    SHOW_EDIT_PERSONAL_INFO,
    HIDE_EDIT_PERSONAL_INFO,
    SET_COMPANY_DETAILS,
    SHOW_EDIT_COMPANY_DETAILS,
    HIDE_EDIT_COMPANY_DETAILS
};

export default types;
