import React from "react";

import { Box, Button, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import {
    clearLead,
    editLead,
    getLead,
    getPrices,
    getProducts,
    getTaxRates,
    hideEditLead,
    selectEditLeadVisibility,
    selectLead,
    selectPrices,
    selectProducts,
    selectTaxRates,
    setLead,
    showEditLead
} from "modules/admin/leads/leadsSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

const StyledKeyboardDatePicker = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(KeyboardDatePicker);

interface EditLeadProps {
    leadId: string;
}

const EditLead: React.FC<EditLeadProps> = (props) => {
    const { leadId } = props;
    const dispatch = useAppDispatch();
    const lead = useAppSelector(selectLead);
    const products = useAppSelector(selectProducts);
    const prices = useAppSelector(selectPrices);
    const taxRates = useAppSelector(selectTaxRates);
    const editLeadVisibility = useAppSelector(selectEditLeadVisibility);
    const show = (editLeadVisibility.isVisible) && (leadId === editLeadVisibility.leadId);

    React.useEffect(() => {
        if (show) {
            dispatch(getLead(leadId));
            dispatch(getProducts());
            dispatch(getPrices());
            dispatch(getTaxRates());
        }
    }, [dispatch, show, leadId]);

    const handleEditLeadClick = () => {
        dispatch(showEditLead(leadId));
    };

    const handleSubmitClick = () => {
        dispatch(editLead());
    };

    const handleCancelClick = () => {
        dispatch(hideEditLead());
        dispatch(clearLead());
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = event.target.value;
        const newLead = { ...lead, firstName, errors: { ...lead.errors, firstName: "" } };
        dispatch(setLead(newLead));
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = event.target.value;
        const newLead = { ...lead, lastName, errors: { ...lead.errors, lastName: "" } };
        dispatch(setLead(newLead));
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;
        const newLead = { ...lead, phoneNumber, errors: { ...lead.errors, phoneNumber: "" } };
        dispatch(setLead(newLead));
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyName = event.target.value;
        const newLead = { ...lead, companyName, errors: { ...lead.errors, companyName: "" } };
        dispatch(setLead(newLead));
    };

    const handleCompanyRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyRegistrationNumber = event.target.value;
        const newLead = {
            ...lead,
            companyRegistrationNumber,
            errors: { ...lead.errors, companyRegistrationNumber: "" }
        };
        dispatch(setLead(newLead));
    };

    const handleJobRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const jobRole = event.target.value;
        const newLead = { ...lead, jobRole, errors: { ...lead.errors, jobRole: "" } };
        dispatch(setLead(newLead));
    };

    const handleDemoCallChange = (date: any) => {
        const demoCall = date;
        const newLead = { ...lead, demoCall, errors: { ...lead.errors, demoCall: "" } };
        dispatch(setLead(newLead));
    };

    const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productId = event.target.value;
        const newLead = { ...lead, productId, priceId: "", errors: { ...lead.errors, productId: "", priceId: "" } };
        dispatch(setLead(newLead));
    };

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const priceId = event.target.value;
        const newLead = { ...lead, priceId, errors: { ...lead.errors, priceId: "" } };
        dispatch(setLead(newLead));
    };

    const handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const taxRateId = event.target.value;
        const newLead = { ...lead, taxRateId, errors: { ...lead.errors, taxRateId: "" } };
        dispatch(setLead(newLead));
    };

    const handleInitialTermInYearsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const initialTermInYears = Number(event.target.value);
        const newLead = { ...lead, initialTermInYears, errors: { ...lead.errors, initialTermInYears: "" } };
        dispatch(setLead(newLead));
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Edit">
                <StyledIconButton size="small" onClick={handleEditLeadClick}>
                    <EditRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>Edit lead</Typography>
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" component="div">
                                {lead.email}
                            </Typography>
                            &nbsp;&nbsp;
                            {/* @ts-ignore */}
                            <SimpleTooltip
                                placement={SimpleTooltipPlacement.Right}
                                title={
                                    <Typography variant="body2" component="div">
                                        The lead's email cannot be edited.
                                        If the email is incorrect, please delete the lead and create a new one.
                                    </Typography>
                                }
                            >
                                <InfoOutlinedIcon fontSize="small" cursor="pointer" />
                            </SimpleTooltip>
                        </Box>
                    </>
                }
                content={
                    <>
                        <StyledTextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.firstName}
                            onChange={handleFirstNameChange}
                            error={!!lead.errors.firstName}
                            helperText={lead.errors.firstName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.lastName}
                            onChange={handleLastNameChange}
                            error={!!lead.errors.lastName}
                            helperText={lead.errors.lastName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Phone number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={!!lead.errors.phoneNumber}
                            helperText={lead.errors.phoneNumber}
                            fullWidth
                        />
                        <StyledTextField
                            label="Company name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.companyName}
                            onChange={handleCompanyNameChange}
                            error={!!lead.errors.companyName}
                            helperText={lead.errors.companyName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Company registration number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.companyRegistrationNumber}
                            onChange={handleCompanyRegistrationNumberChange}
                            error={!!lead.errors.companyRegistrationNumber}
                            helperText={lead.errors.companyRegistrationNumber}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Job role"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.jobRole}
                            onChange={handleJobRoleChange}
                            error={!!lead.errors.jobRole}
                            helperText={lead.errors.jobRole}
                            required
                            fullWidth
                        />
                        <StyledKeyboardDatePicker
                            label="Demo call"
                            inputVariant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={lead.demoCall}
                            onChange={handleDemoCallChange}
                            error={!!lead.errors.demoCall}
                            helperText={lead.errors.demoCall}
                            fullWidth
                            disableToolbar
                            format="dd/MM/yyyy"
                            autoOk
                        />
                        <StyledTextField
                            label="Product"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={lead.productId}
                            onChange={handleProductChange}
                            error={!!lead.errors.productId}
                            helperText={lead.errors.productId}
                            fullWidth
                        >
                            {products.length === 0 && (
                                <MenuItem value="" disabled>No products found</MenuItem>
                            )}
                            {products.map(product =>
                                <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </MenuItem>
                            )}
                        </StyledTextField>
                        <StyledTextField
                            label="Price"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={lead.priceId}
                            onChange={handlePriceChange}
                            error={!!lead.errors.priceId}
                            helperText={lead.errors.priceId}
                            fullWidth
                        >
                            {!lead.productId && (
                                <MenuItem value="" disabled>Select a product first</MenuItem>
                            )}
                            {lead.productId && prices.filter(price => price.productId === lead.productId)?.length === 0 && (
                                <MenuItem value="" disabled>No prices found</MenuItem>
                            )}
                            {prices.filter(price => price.productId === lead.productId).map(price =>
                                <MenuItem key={price.id} value={price.id}>
                                    {price.name}
                                    &nbsp;&nbsp;-&nbsp;&nbsp;
                                    {numberFormatter.toGBP(price.priceInPence / 100, 2, false)} / month
                                </MenuItem>
                            )}
                        </StyledTextField>
                        <StyledTextField
                            label="Tax rate"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={lead.taxRateId}
                            onChange={handleTaxRateChange}
                            error={!!lead.errors.taxRateId}
                            helperText={lead.errors.taxRateId}
                            fullWidth
                        >
                            {taxRates.length === 0 && (
                                <MenuItem value="" disabled>No tax rates found</MenuItem>
                            )}
                            {taxRates.map(taxRate =>
                                <MenuItem key={taxRate.id} value={taxRate.id}>
                                    {taxRate.name} ({taxRate.percentage}% {taxRate.isInclusive ? "inclusive" : "exclusive"})
                                </MenuItem>
                            )}
                        </StyledTextField>
                        <StyledTextField
                            label="Initial term"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={lead.initialTermInYears}
                            onChange={handleInitialTermInYearsChange}
                            error={!!lead.errors.initialTermInYears}
                            helperText={lead.errors.initialTermInYears}
                            fullWidth
                        >
                            <MenuItem value={1}>1 year</MenuItem>
                            <MenuItem value={2}>2 years</MenuItem>
                            <MenuItem value={3}>3 years</MenuItem>
                            <MenuItem value={4}>4 years</MenuItem>
                            <MenuItem value={5}>5 years</MenuItem>
                        </StyledTextField>
                    </>
                }
                actions={
                    <>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                            Save
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default EditLead;
