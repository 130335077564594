import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import BarPositiveAndNegative from "components/visuals/BarPositiveAndNegative";

import { selectors } from "modules/customer/insights/portfolio/catchment/demographicBreakdown";

const HouseholdIncomeIndex = () => {
    const householdIncomeIndex = useSelector(state => selectors.householdIncomeIndex(state));
    const { loading, error, categories, values } = householdIncomeIndex;
    
    const options = {
        title: {
            text: "Household income"
        },
        xAxis: [
            { categories: categories },
            {}
        ],
        series: [{
            data: values
        }]
    };

    return (
        <Box data-cy="household-income-index">
            <BarPositiveAndNegative loading={loading} error={error} options={options} />
        </Box>
    );
};

export default HouseholdIncomeIndex;
