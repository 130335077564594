import React from "react";

import { Box, Card, CardContent, Link, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { getSubscription, selectSubscription } from "modules/customer/onboarding/onboardingSlice";
import numberFormatter from "utils/numberFormatter";

const StyledCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        "&:last-child": {
            paddingBottom: theme.spacing(4)
        }
    }
}))(CardContent);

const StyledCheckCircleIcon = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(CheckCircleIcon);

const StyledLink = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Link);

const useStyles = makeStyles(theme => ({
    hr: {
        borderColor: theme.palette.text.disabled
    }
}));

const PlanDetails: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const subscription = useAppSelector(selectSubscription);
    const year = (new Date()).getFullYear();

    React.useEffect(() => {
        dispatch(getSubscription());
    }, [dispatch]);

    return (
        <Card elevation={0}>
            <StyledCardContent>
                <Typography variant="h4" component="div" align="center" color="textSecondary" gutterBottom>
                    Your plan
                </Typography>
                <Typography variant="h3" component="div" align="center">
                    Dash {subscription.planName}
                </Typography>
                <Typography variant="h3" component="div" align="center">
                    {numberFormatter.toGBP(subscription.priceInPence / 100, 2, false)}
                </Typography>
                <Typography variant="body1" component="div" align="center" color="textSecondary">
                    per month (plus VAT)
                </Typography>
                <br />
                <Typography variant="body1" component="div" align="center" color="textSecondary" gutterBottom>
                    Initial Term: {`${subscription.initialTermInYears} ${subscription.initialTermInYears === 1 ? "year" : "years"}`}
                </Typography>
                <br />
                <Typography variant="body1" component="div" align="center" color="textSecondary" gutterBottom>
                    Licensee: {subscription.companyName} (Company Registration
                    No. {subscription.companyRegistrationNumber})
                </Typography>
                <br />
                <Typography variant="h5" component="div" align="left">
                    What's included in your plan
                </Typography>
                <br />
                {subscription.features.map((feature, index) =>
                    <Box key={index} display="flex" paddingBottom={1}>
                        <StyledCheckCircleIcon fontSize="small" />
                        &nbsp;&nbsp;
                        <Typography variant="subtitle1" component="div">
                            {feature}
                        </Typography>
                    </Box>
                )}
                <br />
                {subscription.footnotes.map((footnote, index) =>
                    <Typography key={index} variant="body2" component="div" color="textSecondary" gutterBottom>
                        {footnote}
                    </Typography>
                )}
                <br />
                <hr className={classes.hr} />
                <Typography variant="body2" component="div" color="textSecondary" gutterBottom>
                    Any term not defined in these Plan Details shall have the same meaning given to them in the Terms of
                    Use entered into between KPMG and the Licensee on the Commencement Date.
                </Typography>
                <Typography variant="body2" component="div" color="textSecondary" gutterBottom>
                    The Fees are payable via Dash's payments provider, Stripe and are paid in accordance with Dash's&nbsp;
                    <StyledLink
                        href="https://kpmgdash.co.uk/#/terms-of-use"
                        target="_blank"
                        underline="always"
                        variant="body2"
                    >
                        Terms of use
                    </StyledLink>
                </Typography>
                <Typography variant="body2" component="div" color="textSecondary" gutterBottom>
                    © {year} KPMG LLP a UK limited liability partnership and a member firm of the KPMG global organisation
                    of independent member firms affiliated with KPMG International Limited, a private English company
                    limited by guarantee. All rights reserved.
                </Typography>
                <Typography variant="body2" component="div" color="textSecondary" gutterBottom>
                    For more detail about the structure of the KPMG global organisation please visit:&nbsp;
                    <StyledLink
                        href="https://home.kpmg/governance"
                        target="_blank"
                        underline="always"
                        variant="body2"
                    >
                        https://home.kpmg/governance
                    </StyledLink>
                </Typography>
            </StyledCardContent>
        </Card>
    );
};

export default PlanDetails;
