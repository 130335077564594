import React from "react";

import { Box, Button, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch } from "store";
import {
    downloadDataGuidance,
    downloadExternalDataSourceLicenses,
    downloadOpenSourceSoftware
} from "modules/customer/help/helpSlice";

const StyledCard = withStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(7),
        flexDirection: "column",
        alignItems: "flex-start"
    }
}))(CardActions);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const About: React.FC = () => {
    const dispatch = useAppDispatch();

    const handleExternalDataSourceLicensesClick = () => {
        dispatch(downloadExternalDataSourceLicenses());
    };

    const handleOpenSourceSoftwareClick = () => {
        dispatch(downloadOpenSourceSoftware());
    };

    const handleReadDataGuidanceClick = () => {
        dispatch(downloadDataGuidance());
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-help-about">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    About
                </Typography>
            </Box>
            <StyledCard>
                <StyledCardContent>
                    <Box height="100%" display="flex">
                        <FileCopyRoundedIcon />
                        <Box height="100%" paddingLeft={2} display="flex" flexDirection="column">
                            <Typography variant="subtitle1" component="div">
                                Find out more about Dash.
                            </Typography>
                        </Box>
                    </Box>
                </StyledCardContent>
                <StyledCardActions disableSpacing>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        startIcon={<LaunchRoundedIcon />}
                        onClick={handleExternalDataSourceLicensesClick}
                    >
                        External data source licenses
                    </StyledButton>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        startIcon={<LaunchRoundedIcon />}
                        onClick={handleOpenSourceSoftwareClick}
                    >
                        Open source software (OSS)
                    </StyledButton>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        startIcon={<LaunchRoundedIcon />}
                        onClick={handleReadDataGuidanceClick}
                    >
                        Read data guidance
                    </StyledButton>
                </StyledCardActions>
            </StyledCard>
        </Box>
    );
};

export default About;
