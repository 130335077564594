import React from "react";
import { mean } from "mathjs";

import Centile from "pages/customer/tools/location/filters/targets/Centile";
import CentileMetric from "pages/customer/tools/location/filters/targets/CentileMetric";

import {
    selectCandidateComparatorStores,
    selectCandidateTarget,
    selectCandidateTargetSpendCategories,
    selectRetailCentresSpendCentiles,
    selectStoreCategorySpendCentileMetrics,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const CatchmentSpend: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const candidateTargetSpendCategories = useAppSelector(selectCandidateTargetSpendCategories);
    const candidateTargetSpendCategoriesIds = candidateTargetSpendCategories.map(spendCategory => spendCategory.id);
    const retailCentresSpendCentiles = useAppSelector(selectRetailCentresSpendCentiles);
    const comparatorStoresCentiles = candidateComparatorStores.map(store => {
        const retailCentreSpendCentiles = retailCentresSpendCentiles.filter(spendCentile => spendCentile.retailCentreId === store.retailCentre.id
            && candidateTargetSpendCategoriesIds.includes(spendCentile.spendCategoryId));
        const meanSpendCentile = retailCentreSpendCentiles.length > 0 ? mean(retailCentreSpendCentiles.map(sc => sc.spend)) as number : 0;
        return {
            storeName: store.name,
            centileValue: meanSpendCentile
        };
    });
    // const aggregatedDirectCompetitors = useAppSelector(selectAggregatedDirectCompetitors);
    // const aggregatedDirectCompetitorCentiles = aggregatedDirectCompetitors.map(item => ({
    //     storeName: item.fascia,
    //     centileValue: item.spendCentile
    // }));
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const storeCategorySpendCentileMetrics = useAppSelector(selectStoreCategorySpendCentileMetrics);
    const spendCentileMetric = storeCategorySpendCentileMetrics
        .filter(cm => cm.centile === candidateTarget?.spend && candidateTargetSpendCategoriesIds.includes(cm.spendCategoryId))
        .map(cm => cm.medianWeightedSpend)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const name = "Catchment spend";
    const description = "The catchment area's annual weighted spend within the selected market categories.";
    const spendCentileMetrics = [{
        name: "Catchment spend",
        tooltip: "The total weighted spend in the catchment area.",
        value: numberFormatter.toGBP(spendCentileMetric, 1)
    }];

    const handleSliderChange = (value: number) => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                spend: value
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <Centile
            name={name}
            description={description}
            disabled={!candidateTarget.useSpend}
            sliderValue={candidateTarget.spend}
            onSliderChange={handleSliderChange}
            comparatorStoresCentiles={comparatorStoresCentiles}
            aggregatedDirectCompetitorCentiles={[]}
            chartLowLabel="Low catchment spend"
            chartHighLabel="High catchment spend"
        >
            {spendCentileMetrics.map((metric, index) =>
                <CentileMetric
                    key={index}
                    name={metric.name}
                    tooltip={metric.tooltip}
                    value={metric.value}
                    disabled={!candidateTarget.useSpend}
                />
            )}
        </Centile>
    );
};

export default CatchmentSpend;
