import { combineReducers } from "redux";

import types from "./types";

const grossRevenueYTDEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const grossRevenueYTD = (state = grossRevenueYTDEmpty, action) => {
    switch (action.type) {
        case types.GET_GROSS_REVENUE_YTD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_GROSS_REVENUE_YTD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_GROSS_REVENUE_YTD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0

            };
        default:
            return state;
    }
};

const contributionYTDEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const contributionYTD = (state = contributionYTDEmpty, action) => {
    switch (action.type) {
        case types.GET_CONTRIBUTION_YTD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_CONTRIBUTION_YTD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_CONTRIBUTION_YTD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const forecastSalesYTGEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const forecastSalesYTG = (state = forecastSalesYTGEmpty, action) => {
    switch (action.type) {
        case types.GET_FORECAST_SALES_YTG_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_FORECAST_SALES_YTG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_FORECAST_SALES_YTG_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const ytdSalesPlusYTGSalesVsBudgetedSalesEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const ytdSalesPlusYTGSalesVsBudgetedSales = (state = ytdSalesPlusYTGSalesVsBudgetedSalesEmpty, action) => {
    switch (action.type) {
        case types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    grossRevenueYTD,
    contributionYTD,
    forecastSalesYTG,
    ytdSalesPlusYTGSalesVsBudgetedSales
});

export default reducer;
