import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <Box paddingBottom={2}>
            <Typography variant="h5" component="div">
                Targets
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    Tell Dash what matters most to your business when looking for a new store location by setting
                    targets. Set a target for each of the Location tool's key metrics then Dash will find the locations
                    in the UK that best align with what you're looking for. If any of the metrics are unimportant to
                    your business, you can toggle them off. Dash will then exclude these metrics as a factor when
                    recommending a location.
                </Typography>
            </Box>
        </Box>
    );
};

export default Title;
