import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Examine the year-on-year growth across your {store?.name} store's product categories to inform and enhance your range selection process.
            Gauge performance against {comparator?.name} to identify unique customer trends in your {store?.name} store.
            Leverage these insights to optimise your product mix, ensuring your {store?.name} store's offerings resonate with local market demands and drive continued growth.
        </>
    );
};

export default Subtitle;
