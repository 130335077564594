import React from "react";

import { Button, withStyles } from "@material-ui/core";
import { resetSpendFilters, selectIsFilterModified } from "modules/customer/tools/location/spendNew/spendSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: (props) => props["data-disabled"] ? theme.palette.text.disabled : theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1)
    }
}))(Button);


const ResetFiltersButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const isFilterModified = useAppSelector(selectIsFilterModified);

    const disabled = !isFilterModified;
    const handleResetButtonClick = () => {
        dispatch(resetSpendFilters());
    };

    return (
        <StyledButton
            variant="text"
            size="small"
            color="default"
            disableElevation
            disabled={disabled}
            onClick={handleResetButtonClick}
        >
            Reset
        </StyledButton>
    );
};

export default ResetFiltersButton;
