import React from "react";
import { Grid } from "@material-ui/core";

import ProductCategoryMixMagicQuadrant from "./ProductCategoryMixMagicQuadrant";
import StoreDependencyOnProductOrProductCategory from "./StoreDependencyOnProductOrProductCategory";
import StoreBestProductsOrProductCategoriesForecast from "./StoreBestProductsOrProductCategoriesForecast";

const ProductCategoryMix = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProductCategoryMixMagicQuadrant />
            </Grid>
            <Grid item xs={12}>
                <StoreDependencyOnProductOrProductCategory />
            </Grid>
            <Grid item xs={12}>
                <StoreBestProductsOrProductCategoriesForecast />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryMix;
