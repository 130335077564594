import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";

import Error from "./Error";
import Progress from "./Progress";

const StyledCard = withStyles(theme => ({
    root: {
        width: "100%",
        height: theme.spacing(25)
    }
}))(Card);

const StyledGridContainer = withStyles(theme => ({
    root: {
        height: "100%"
    }
}))(Grid);

const StyledGridItem = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    }
}))(Grid);

const StyledTargetGridItem = withStyles(theme => ({
    root: {
        textAlign: "center",
        justifyContent: "center"
    }
}))(StyledGridItem);

const StyledComparisonGridItem = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        textAlign: (props: any) => props["data-show-difference"] ? "left" : "center",
        justifyContent: (props: any) => props["data-show-difference"] ? "flex-start" : "center"
    }
}))(StyledGridItem);

const StyledDifferenceGridItem = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        textAlign: "left",
        justifyContent: "flex-start"
    }
}))(StyledGridItem);

interface KPIComparisonProps {
    isLoading: boolean,
    hasErrors: boolean,
    targetValue: React.ReactElement,
    targetLabel: string,
    comparisonValue?: React.ReactElement,
    comparisonLabel: string,
    differenceValue?: React.ReactElement,
    differenceLabel?: string
}

const KPIComparison: React.FC<KPIComparisonProps> = (props) => {
    const {
        isLoading, hasErrors,
        targetValue, targetLabel,
        comparisonValue, comparisonLabel,
        differenceValue, differenceLabel
    } = props;
    const theme = useTheme();
    const [hover, setHover] = React.useState(false);
    const hasComparisonValue = !!comparisonValue;
    const comparisonColour = hasComparisonValue ? theme.palette.text.primary : theme.palette.text.disabled;

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    if (isLoading) {
        return (<Progress />);
    }

    if (hasErrors) {
        return (<Error />);
    }

    return (
        <StyledCard elevation={0} data-cy="kpi-card-comparison">
            <StyledGridContainer container>
                <StyledTargetGridItem item xs={6}>
                    <CardContent>
                        <Box paddingBottom={1}>
                            {targetValue}
                        </Box>
                        <Typography variant="body1" component="div">
                            {targetLabel}
                        </Typography>
                    </CardContent>
                </StyledTargetGridItem>
                <Grid item xs={6} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-cy="kpi-hover-grid">
                    <StyledGridContainer container>
                        <StyledComparisonGridItem item xs={12} data-show-difference={hasComparisonValue && hover}>
                            <CardContent>
                                {hasComparisonValue && (
                                    <Box paddingBottom={1}>
                                        {comparisonValue}
                                    </Box>
                                )}
                                <Typography variant="body1" component="div" style={{ color: comparisonColour }}>
                                    {comparisonLabel}
                                </Typography>
                            </CardContent>
                        </StyledComparisonGridItem>
                        {hasComparisonValue && hover && (
                            <StyledDifferenceGridItem item xs={12}>
                                <CardContent>
                                    <Box paddingBottom={1}>
                                        {differenceValue}
                                    </Box>
                                    <Typography variant="body1" component="div">
                                        {differenceLabel}
                                    </Typography>
                                </CardContent>
                            </StyledDifferenceGridItem>
                        )}
                    </StyledGridContainer>
                </Grid>
            </StyledGridContainer>
        </StyledCard>
    );
};

export default KPIComparison;
