import types from "./types";

const getAvgBasketSizeCMPMRequest = () => ({
    type: types.GET_AVG_BASKET_SIZE_CM_PM_REQUEST
});

const getAvgBasketSizeCMPMSuccess = (latestFullMonthAvgProductsInBasket, percentageDifference, currentMonth, pastMonth) => ({
    type: types.GET_AVG_BASKET_SIZE_CM_PM_SUCCESS,
    payload: {
        latestFullMonthAvgProductsInBasket,
        percentageDifference,
        currentMonth,
        pastMonth
    }
});

const getAvgBasketSizeCMPMFailure = () => ({
    type: types.GET_AVG_BASKET_SIZE_CM_PM_FAILURE
});

const getAvgBasketValueCMPMRequest = () => ({
    type: types.GET_AVG_BASKET_VALUE_CM_PM_REQUEST
});

const getAvgBasketValueCMPMSuccess = (latestFullMonthAvgBasketValue, percentageDifference, currentMonth, pastMonth) => ({
    type: types.GET_AVG_BASKET_VALUE_CM_PM_SUCCESS,
    payload: {
        latestFullMonthAvgBasketValue,
        percentageDifference,
        currentMonth,
        pastMonth
    }
});

const getAvgBasketValueCMPMFailure = () => ({
    type: types.GET_AVG_BASKET_VALUE_CM_PM_FAILURE
});

const getCategoryCMSalesRequest = () => ({
    type: types.GET_CATEGORY_CM_SALES_REQUEST
});

const getCategoryCMSalesSuccess = (categorySales, drilldownData, currentMonth) => ({
    type: types.GET_CATEGORY_CM_SALES_SUCCESS,
    payload: {
        categorySales,
        drilldownData,
        currentMonth
    }
});

const getCategoryCMSalesFailure = () => ({
    type: types.GET_CATEGORY_CM_SALES_FAILURE
});

const getCategoryCMSalesGrowthRequest = () => ({
    type: types.GET_CATEGORY_CM_SALES_GROWTH_REQUEST
});

const getCategoryCMSalesGrowthSuccess = (productCategoryGrowth, drilldownData, currentMonth, pastMonth) => ({
    type: types.GET_CATEGORY_CM_SALES_GROWTH_SUCCESS,
    payload: {
        productCategoryGrowth,
        drilldownData,
        currentMonth,
        pastMonth
    }
});

const getCategoryCMSalesGrowthFailure = () => ({
    type: types.GET_CATEGORY_CM_SALES_GROWTH_FAILURE
});

const getCategoryYTDSalesRequest = () => ({
    type: types.GET_CATEGORY_YTD_SALES_REQUEST
});

const getCategoryYTDSalesSuccess = (categorySales, drilldownData) => ({
    type: types.GET_CATEGORY_YTD_SALES_SUCCESS,
    payload: {
        categorySales,
        drilldownData
    }
});

const getCategoryYTDSalesFailure = () => ({
    type: types.GET_CATEGORY_YTD_SALES_FAILURE
});

const getCategoryYTDSalesGrowthRequest = () => ({
    type: types.GET_CATEGORY_YTD_SALES_GROWTH_REQUEST
});

const getCategoryYTDSalesGrowthSuccess = (productCategoryGrowth, drilldownData) => ({
    type: types.GET_CATEGORY_YTD_SALES_GROWTH_SUCCESS,
    payload: {
        productCategoryGrowth,
        drilldownData
    }
});

const getCategoryYTDSalesGrowthFailure = () => ({
    type: types.GET_CATEGORY_YTD_SALES_GROWTH_FAILURE
});

const actions = {
    getAvgBasketSizeCMPMRequest,
    getAvgBasketSizeCMPMSuccess,
    getAvgBasketSizeCMPMFailure,
    getAvgBasketValueCMPMRequest,
    getAvgBasketValueCMPMSuccess,
    getAvgBasketValueCMPMFailure,
    getCategoryCMSalesRequest,
    getCategoryCMSalesSuccess,
    getCategoryCMSalesFailure,
    getCategoryCMSalesGrowthRequest,
    getCategoryCMSalesGrowthSuccess,
    getCategoryCMSalesGrowthFailure,
    getCategoryYTDSalesRequest,
    getCategoryYTDSalesSuccess,
    getCategoryYTDSalesFailure,
    getCategoryYTDSalesGrowthRequest,
    getCategoryYTDSalesGrowthSuccess,
    getCategoryYTDSalesGrowthFailure
};

export default actions;
