import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectStore, selectComparator, selectReferenceDate, selectCostReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";
import actions from "./actions";

const getPayrollCostPerSqft = () => async (dispatch, getState) => {
    dispatch(actions.getPayrollCostPerSqftRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const query = {
            measures: ["F_Cost.SumPayrollCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.Sqft"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const payrollCost = resultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            payrollCost: item["F_Cost.SumPayrollCost"],
            payrollCostPerSqft: (item["D_Store.Sqft"] === 0) ? 0 : item["F_Cost.SumPayrollCost"] / item["D_Store.Sqft"]
        }));

        const selectedStore = payrollCost.find(item => item.store === selectedStoreID) || {};
        const comparatorStores = payrollCost.filter(item => comparatorStoresIDs.includes(item.store));

        dispatch(actions.getPayrollCostPerSqftSuccess(payrollCost, selectedStore, comparatorStores));
    }
    catch (error) {
        dispatch(actions.getPayrollCostPerSqftFailure());
        dispatch(logError("Error loading PayrollCostPerSqft.", error));
    }
};

const getSalesValuePerHead = () => async (dispatch, getState) => {
    dispatch(actions.getSalesValuePerHeadRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.EmployeeCount"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const sales = resultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            sales: item["F_Sales.SumLineValue"],
            salesPerHead: (item["D_Store.EmployeeCount"] === 0) ? 0 : item["F_Sales.SumLineValue"] / item["D_Store.EmployeeCount"]
        }));

        const selectedStore = sales.find(item => item.store === selectedStoreID) || {};
        const comparatorStores = sales.filter(item => comparatorStoresIDs.includes(item.store));

        dispatch(actions.getSalesValuePerHeadSuccess(sales, selectedStore, comparatorStores));
    }
    catch (error) {
        dispatch(actions.getSalesValuePerHeadFailure());
        dispatch(logError("Error loading SalesValuePerHead.", error));
    }
};

const getSalesPerPoundOfStaffCost = () => async (dispatch, getState) => {
    dispatch(actions.getSalesPerPoundOfStaffCostRequest());
    try {
        const state = getState();
        const referenceDate = selectCostReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];
        const priorTwelveMonthsToReferenceDate = referenceDate.minus({ months: 12 }).plus({ days: 1 });

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, referenceDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const sales = resultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            sales: item["F_Sales.SumLineValue"]
        }));

        const payrollCostQuery = {
            measures: ["F_Cost.SumPayrollCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, referenceDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const payrollCostResultSet = await dispatch(cubeLoadExtended(payrollCostQuery));

        const payrollCost = payrollCostResultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            payrollCost: item["F_Cost.SumPayrollCost"]
        }));

        const overallData = sales.map(current => {
            const cost = payrollCost.find(item => item.store === current.store);
            if (!cost) {
                return {
                    store: current.store,
                    sales: current.sales,
                    salesPerPoundOfStaffCost: 0,
                };
            }
            return {
                store: current.store,
                sales: current.sales,
                salesPerPoundOfStaffCost: (cost.payrollCost === 0) ? 0 : current.sales / cost.payrollCost,
            };
        });

        const selectedStore = overallData.find(item => item.store === selectedStoreID) || {};
        const comparatorStores = overallData.filter(item => comparatorStoresIDs.includes(item.store));

        dispatch(actions.getSalesPerPoundOfStaffCostSuccess(overallData, selectedStore, comparatorStores));
    }
    catch (error) {
        dispatch(actions.getSalesPerPoundOfStaffCostFailure());
        dispatch(logError("Error loading SalesPerPoundOfStaffCost.", error));
    }
};

const operations = {
    getPayrollCostPerSqft,
    getSalesValuePerHead,
    getSalesPerPoundOfStaffCost
};

export default operations;
