import React from "react";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageSection from "components/landing/PageSection";
import PageCallToAction from "components/landing/PageCallToAction";

import Section from "./Section";

const Features = () => {
    const theme = useTheme();
    const evenBgcolor = theme.palette.background.default;
    const oddBgcolor = theme.palette.background.defaultDark;
    const evenColor = "inherit";
    const oddColor = theme.palette.common.white;
    const sections = [{
        title: "Get the big picture and the small details",
        paragraph1: "As a one-stop-shop for retail analytics, Dash gives you a macro and micro view of your business. We have brought together experts in retail, data and technology to give you access to cutting edge insights covering the breadth and depth of your business.",
        paragraph2: "Its analysis informs your everyday tactical decisions and shows their effect on performance at every level: product category, store and the business as a whole.",
        image: "/content/landing/features/get-the-big-picture-and-the-small-details.png",
        alt: "get the big picture and the small details"
    }, {
        title: "See more, see further",
        paragraph1: "Dash contains powerful AI algorithms that allow it to generate highly accurate predictions and models that power many of the decisions within Dash including: revenue predictions, store catchment areas, basket analysis, and overall demand for your products and services.",
        paragraph2: "These algorithms have been tried and tested over many years. They learn from your past performance, external data about the market, your customers, and your product categories.",
        image: "/content/landing/features/see-more-see-further.png",
        alt: "see more see further"
    }, {
        title: "A world of data for every location",
        paragraph1: "Dash contains a vast array of data sources to help make better decisions, all of which is regularly updated to ensure you have access to the latest information. This includes a rich variety of customer and demographic data, disposable spend surveys, competitors, places of interest, footfall and much more.",
        paragraph2: "Using all this data, we build a picture of your business from the outside in, giving you fresh new insights.",
        image: "/content/landing/features/a-world-of-data-for-every-location.png",
        alt: "a world of data for every location"
    }, {
        title: "Why wait?",
        paragraph1: "Dash does not have long lead times, nor does it require significant development and integration. We have done the hard work up front so that you can focus on making better decisions immediately.",
        paragraph2: "Upload your data in the required format and get access to insights as soon as your data has run through our algorithms.",
        image: "/content/landing/features/why-wait.png",
        alt: "why wait"
    }, {
        title: "Level up your estate",
        paragraph1: "Understand what makes your existing stores tick and more importantly, learn what you need to do to improve your underperforming stores. Are you making best use of your stores' catchment area? Are you meeting the local customers' needs?",
        paragraph2: "You can also use Dash to open new locations with confidence. Our proprietary algorithms help find new locations that replicate your best performers. Through our Location Finder tool, you can be confident that you are targeting the right customer without cannibalising your existing stores sales.",
        image: "/content/landing/features/level-up-your-estate.png",
        alt: "level up your estate"
    }, {
        title: "Find products for your customers",
        paragraph1: "Understand which customers are most likely to buy certain products within your range, ensuring each store's product range meets the local customers' needs.",
        paragraph2: "Identify underperforming categories to determine what new products need to be sold to maximise customer lifetime value.",
        image: "/content/landing/features/find-products-for-your-customers.png",
        alt: "find products for your customers"
    }, {
        title: "Insights on a platter",
        paragraph1: "Dash's data rich insights present your business results in a simple, visual form. But not everybody is comfortable interpreting charts and graphs, and understanding what they really mean.",
        paragraph2: "To help with this, our AI engine interprets your results into plain English. Highlighting what is going well, what needs immediate attention and what to keep an eye on, allowing you to strengthen your decision-making power.",
        image: "/content/landing/features/insights-on-a-platter.png",
        alt: "insights on a platter"
    }];

    const isEven = (value) => {
        return value % 2 === 0;
    };

    return (
        <Page dataCy="pages-landing-features">
            <PageHeader
                title="Features"
                subtitle="Insightful features to power business growth"
                dataCy="pages-landing-features-header"
            />
            {sections.map((section, index) =>
                <PageSection
                    key={index}
                    bgcolor={isEven(index) ? evenBgcolor : oddBgcolor}
                    color={isEven(index) ? evenColor : oddColor}
                    dataCy={`pages-landing-features-section-${index + 1}`}
                >
                    <Section
                        direction={isEven(index) ? "row-reverse" : "row"}
                        title={section.title}
                        paragraph1={section.paragraph1}
                        paragraph2={section.paragraph2}
                        image={section.image}
                        alt={section.alt}
                    />
                </PageSection>
            )}
            <PageCallToAction
                color={theme.palette.common.white}
                bgcolor={theme.palette.primary.main}
                title="Still have a question?"
                subtitle="Contact our team of specialists"
                actionLabel="Contact us"
                actionUrl="/contact-us"
                dataCy="pages-landing-features-call-to-action"
            />
        </Page>
    );
};

export default Features;
