import React from "react";
import { Box } from "@material-ui/core";

import Boxplot from "components/visuals/Boxplot";
  
/*Example Cube query data result.
name is the category
low is absolute low (lowest store)
high is absolute high (highest store)
lowQuartile is the top of the bottom quartile
highQuartile is the bottom of the top quartile
*/
let fullData = [{
        name: 'Butter',
        low: 19,
        high: 62,
        lowStore: 'Birmingham',
        highStore: 'Truro',
        median: 72,
        lowQuartile: 30,
        highQuartile: 55
    },
    {
        name: 'Milk',
        low: 40,
        high: 51,
        lowStore: 'Walsall',
        highStore: 'Southampton',
        median: 43,
        lowQuartile: 42,
        highQuartile: 49
    },
    {
        name: 'Cheese',
        low: 49,
        high: 75,
        lowStore: 'Redditch',
        highStore: 'Llandudno',
        median: 72.5,
        lowQuartile: 61,
        highQuartile: 72
    },
    {
        name: 'Custard',
        low: 65,
        high: 90,
        lowStore: 'Coventry',
        highStore: 'Glasgow',
        median: 78,
        lowQuartile: 74,
        highQuartile: 80
}];

//Requirement to sort data by range
fullData = fullData.sort((a, b) => 
    (b.high - b.low) - (a.high - a.low)
);

const BoxPlot = () => {

    /*Example of how to transform Cube data for boxplot*/
    function quartilesObject(median, low, high) {
        this.median = median;
        this.low = low;
        this.high = high;
    }

    function extremesObject(extreme, store) {
        this.y = extreme;
        this.store = store;
    }

    const categories = fullData.map(row => row.name);
    const fullRange = fullData.map(row => [row.low, row.high]);  //from top to bottom
    const middleQuartiles = fullData.map(row => new quartilesObject(row.median, row.lowQuartile, row.highQuartile));  //from top of bottom quartile to bottom of top quartile

    const lows = fullData.map(row => new extremesObject(row.low, row.lowStore));
    const highs = fullData.map(row => new extremesObject(row.high, row.highStore));

    const options = {
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'Sales mix (%)'
            }
        }
    };

    return (
        <Box>
            <Boxplot categories={categories} fullRange={fullRange} middleQuartiles={middleQuartiles} lows={lows} highs={highs} options={options} />
        </Box>
    );
};

export default BoxPlot;
