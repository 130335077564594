import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";


export const loadDirectCompetitorNames = (): AppThunk<Promise<string[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "F_DirectCompetitors.CompetitorName"
            ]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData(); 

        if (rawData.length === 0) {
            return [];
        }

        const directCompetitorNames = rawData.map(item => item["F_DirectCompetitors.CompetitorName"].toUpperCase());
        return directCompetitorNames;
    } catch (error) {
        dispatch(logError("Error loading DirectCompetitorComponentMetrics.", error));
        throw error;
    }
};

