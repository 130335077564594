import { getUserInfo } from "modules/auth/authSlice";
import { apiGet, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifySuccess, notifyError } from "modules/notifications/notificationsSlice";

import actions from "./actions";
import selectors from "./selectors";

const getSettings = () => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/account/settings"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const personalInfo = response.data.personalInfo;
            const companyDetails = response.data.companyDetails;
            const emailNotifications = response.data.emailNotifications;
            dispatch(actions.getSettingsSuccess(personalInfo, companyDetails, emailNotifications));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            dispatch(actions.getSettingsFailure());
            break;
        }
        default: {
            dispatch(actions.getSettingsFailure());
            break;
        }
    }
};

const editPersonalInfo = () => async (dispatch, getState) => {
    const state = getState();
    const personalInfo = selectors.personalInfo(state);
    const response = await dispatch(apiPut("/customer/account/settings/personal-info", personalInfo));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideEditPersonalInfo());
            dispatch(notifySuccess("Personal information edited."));
            dispatch(getSettings());
            dispatch(getUserInfo());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(hideEditPersonalInfo());
            dispatch(notifyError("User not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                phoneNumber: response.errorData?.errors?.phoneNumber?.[0]
            };
            dispatch(actions.setPersonalInfo({ ...personalInfo, errors }));
            break;
        }
        default:
            break;
    }
};

const editCompanyDetails = () => async (dispatch, getState) => {
    const state = getState();
    const companyDetails = selectors.companyDetails(state);
    const response = await dispatch(apiPut("/customer/account/settings/company-details", companyDetails));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideEditCompanyDetails());
            dispatch(notifySuccess("Company details edited."));
            dispatch(getSettings());
            dispatch(getUserInfo());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(hideEditCompanyDetails());
            dispatch(notifyError("Account not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                displayName: response.errorData?.errors?.displayName?.[0]
            };
            dispatch(actions.setCompanyDetails({ ...companyDetails, errors }));
            break;
        }
        default:
            break;
    }
};

const setPersonalInfo = (personalInfo) => (dispatch) => {
    dispatch(actions.setPersonalInfo(personalInfo));
};

const showEditPersonalInfo = () => (dispatch) => {
    dispatch(actions.showEditPersonalInfo());
};

const hideEditPersonalInfo = () => (dispatch) => {
    dispatch(getSettings());
    dispatch(actions.hideEditPersonalInfo());
};

const setCompanyDetails = (companyDetails) => (dispatch) => {
    dispatch(actions.setCompanyDetails(companyDetails));
};

const showEditCompanyDetails = () => (dispatch) => {
    dispatch(actions.showEditCompanyDetails());
};

const hideEditCompanyDetails = () => (dispatch) => {
    dispatch(getSettings());
    dispatch(actions.hideEditCompanyDetails());
};

const operations = {
    getSettings,
    editPersonalInfo,
    editCompanyDetails,
    setPersonalInfo,
    showEditPersonalInfo,
    hideEditPersonalInfo,
    setCompanyDetails,
    showEditCompanyDetails,
    hideEditCompanyDetails
};

export default operations;
