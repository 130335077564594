import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Explore the customer profiles shaping your {store?.name} store's catchment area to refine your retail strategy.
            Understand who your customers are and where they originate, assessing if they align with your company's target demography.
            See at a glance areas of strong appeal and those where competition hinders your pull on the local population.
            With insights into the demographic mix, consider if your product range meets the local needs.
            Evaluate the need for strategic adjustments to your {store?.name} store's layout and inventory to enhance pull and maximise revenue.
        </>
    );
};

export default Subtitle;
