import React from "react";

const CatchmentSubtitle: React.FC = () => {
    return (
        <>
            We calculate your store's catchment area based on many factors such as: what it sells, the facilities it
            offers, its reputation, the demographics it serves, and the level of competition in the area. Let's analyse
            your store's catchment area to identify who are your customers, how they are different, and whether you
            attract enough of your best customers.
        </>
    );
};

export default CatchmentSubtitle;
