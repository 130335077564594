import React from "react";
import { Box } from "@material-ui/core";
import Highcharts from "highcharts";

import Column from "components/visuals/Column";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectProductCategoryGrowthFiltered } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";
import { SortDirection, numberSortExpression } from "utils/sortUtils";

const ProductCategoryGrowthVsComparator = () => {
  const colourPalette = useColourPalette();
  const selectedStore = useAppSelector(selectStore);
  const selectedComparator = useAppSelector(selectComparator);
  const historicProdGrowthStoreVsComp = useAppSelector(selectProductCategoryGrowthFiltered);
  const { isLoading, hasErrors, data } = historicProdGrowthStoreVsComp;

  const options: Highcharts.Options = {
    xAxis: {
      type: 'category'
  },
    title: {
      text: `Product category year-on-year growth (%) in ${selectedStore?.name} vs ${selectedComparator?.name}`
    },
    yAxis: {
      title: {
        text: "Growth"
      },
      labels: {
        format: '{text}%'
      }
    },
    plotOptions: {
      series: {
          dataLabels: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: true
  },
    tooltip: {
      enabled: true,
      shared: true,
      
      formatter: function (): string | undefined {
        const point = this.points;
        if (point) {
          const categoryArr = [point[0].series.name,point[1].series.name];
          const categoryFormatArr = [`color:${point[0].series.color};font-weight:bold`,`color:${point[1].series.color};font-weight:bold`];
          const valuesArr = [numberFormatter.toPercentage(point[0].y),numberFormatter.toPercentage(point[1].y)];
          
          return `<table>${
              stringUtils.tooltipHTML(categoryArr, {
                  header: point[0].key,
                  values: valuesArr,
                  categoryFormattingArr: categoryFormatArr
              })
          }</table>`;
        }
      },
      useHTML: true
    },
    series: [{
      name: selectedStore?.name,
      data: data.selectedStore.map(row => {
        return {
          name: row.productCategory, 
          y: row.growth
        };
      }).sort((a, b) => numberSortExpression(a.y, b.y, SortDirection.DESC)),
      color : colourPalette.comparators[0],
      type: "column"

  }, {
      name: selectedComparator?.name,
      data: data.comparator.map(row => { 
        return {
          name: row.productCategory,
          y: row.growth
        };
      }),
      color: colourPalette.comparators[1],
      type: "column"
  }]
  };

  return (
    <Box data-cy="historic-prod-growth-store-vs-comp">
      <Column loading={isLoading} error={hasErrors} options={options} />
    </Box>
  );
};

export default ProductCategoryGrowthVsComparator;
