import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import _ from "lodash";
import dateUtils from "utils/dateUtils";
import mathUtils from "utils/mathUtils";

import { selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";

const getTotalSalesGrowthByRegionForecast = () => async (dispatch, getState) => {
    dispatch(actions.getTotalSalesGrowthByRegionForecastRequest());
    try {
        const state = getState();
        const referenceDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(referenceDate);
        const nextDay = dateUtils.nextDay(referenceDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(referenceDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [nextDay, next12MonthsEndDate],
                    [priorTwelveMonthsStartDate, referenceDate]
                ]
            }],
            dimensions: ["D_Store.k_Region", "D_Store.RegionCode"],
            filters: [{
                member: "D_Store.OnlineFlag",
                operator: "equals",
                values: ["0"]
            }]
        }; //filters to just physical stores
        const resultSet = await dispatch(cubeLoad(query));
        const nextTwelveMonths = resultSet.loadResponses[0].data.map(item => ({
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));
        const priorTwelveMonths = resultSet.loadResponses[1].data.map(item => ({
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));

        const growthValues = [];
        const regionsGrowthForecast = nextTwelveMonths.map(current => {
            const previous = priorTwelveMonths.find(item => item.regionId === current.regionId);
            let growth = 0;
            if (previous && previous.sales !== 0) {
                growth = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            growthValues.push(growth);
            return {
                ...current,
                growth
            };
        });

        const percentileThresholds = mathUtils.percentileThresholds(growthValues, 6);
        const regionsGrowthForecastWithGroup = regionsGrowthForecast.map(region => {
            let group = 0;
            for (let i = 0; i < percentileThresholds.length; i++) {
                if (region.growth >= percentileThresholds[i]) {
                    group = i;
                }
            }
            return {
                ...region,
                group
            };
        });

        dispatch(actions.getTotalSalesGrowthByRegionForecastSuccess(regionsGrowthForecastWithGroup));
    }
    catch (error) {
        dispatch(actions.getTotalSalesGrowthByRegionForecastFailure());
        dispatch(logError("Error loading TotalSalesGrowthByRegionForecast.", error));
    }
};

const getSalesByRegionGrowthForecast = () => async (dispatch, getState) => {
    dispatch(actions.getSalesByRegionGrowthForecastRequest());
    try {
        const state = getState();
        const referenceDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(referenceDate);
        const nextDay = dateUtils.nextDay(referenceDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(referenceDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [nextDay, next12MonthsEndDate],
                    [priorTwelveMonthsStartDate, referenceDate]
                ]
            }],
            dimensions: ["D_Store.k_Region", "D_Store.RegionCode", "D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.OnlineFlag",
                operator: "equals",
                values: ["0"]
            }, {
                member: "F_SalesForecast.SumLineValue",
                operator: "gt",
                values: ["0"]
            }]
        }; //filters to just physical stores, stores with sales (open stores)
        const resultSet = await dispatch(cubeLoad(query));
        const nextTwelveMonths = resultSet.loadResponses[0].data.map(item => ({
            storeId: item["D_Store.StoreNaturalID"],
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));
        const priorTwelveMonths = resultSet.loadResponses[1].data.map(item => ({
            storeId: item["D_Store.StoreNaturalID"],
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));

        //Only stores that had sales in both years
        const nextTwelveMonthsLikeForLikeStores = _.intersectionBy(nextTwelveMonths, priorTwelveMonths, "storeId");
        const priorTwelveMonthsLikeForLikeStores = _.intersectionBy(priorTwelveMonths, nextTwelveMonths, "storeId");

        const storesGrowthForecast = nextTwelveMonthsLikeForLikeStores.map(current => {
            const previous = priorTwelveMonthsLikeForLikeStores.find(item => item.storeId === current.storeId);
            let growth = 0;
            if (previous && previous.sales !== 0) {
                growth = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                growth
            };
        });

        const storeNamesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName"
            ],
            filters: [{
                member: "D_Store.OnlineFlag",
                operator: "equals",
                values: ["0"]
            }]
        }; //filters to just physical stores
        const storeNamesResultSet = await dispatch(cubeLoad(storeNamesQuery));
        const stores = storeNamesResultSet.loadResponses[0].data.map(item => ({
            storeId: item["D_Store.StoreNaturalID"],
            storeName: item["D_Store.StoreName"]
        }));

        const storesGrowthForecastWithStoreName = storesGrowthForecast.map(storeGrowth => {
            const storeName = stores.find(store => store.storeId === storeGrowth.storeId)?.storeName ?? storeGrowth.storeId;
            return {
                ...storeGrowth,
                storeName
            };
        });

        dispatch(actions.getSalesByRegionGrowthForecastSuccess(storesGrowthForecastWithStoreName));
    }
    catch (error) {
        dispatch(actions.getSalesByRegionGrowthForecastFailure());
        dispatch(logError("Error loading SalesByRegionGrowthForecast.", error));
    }
};

const getForecastDependencyOnSingularRegionOrStore = () => async (dispatch, getState) => {
    dispatch(actions.getForecastDependencyOnSingularRegionOrStoreRequest());
    try {
        const state = getState();
        const referenceDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(referenceDate);
        const nextDay = dateUtils.nextDay(referenceDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(referenceDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [nextDay, next12MonthsEndDate],
                    [priorTwelveMonthsStartDate, referenceDate]
                ]
            }],
            dimensions: ["D_Store.k_Region", "D_Store.RegionCode", "D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.OnlineFlag",
                operator: "equals",
                values: ["0"]
            }, {
                member: "F_SalesForecast.SumLineValue",
                operator: "gt",
                values: ["0"]
            }]
        }; //filters to just physical stores, stores with sales (open stores)
        const resultSet = await dispatch(cubeLoad(query));
        const nextTwelveMonths = resultSet.loadResponses[0].data.map(item => ({
            storeId: item["D_Store.StoreNaturalID"],
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));
        const priorTwelveMonths = resultSet.loadResponses[1].data.map(item => ({
            storeId: item["D_Store.StoreNaturalID"],
            regionId: item["D_Store.RegionCode"],
            regionName: item["D_Store.k_Region"],
            sales: item["F_SalesForecast.SumLineValue"]
        }));

        //Only stores that had sales in both years
        const nextTwelveMonthsLikeForLikeStores = _.intersectionBy(nextTwelveMonths, priorTwelveMonths, "storeId");
        const priorTwelveMonthsLikeForLikeStores = _.intersectionBy(priorTwelveMonths, nextTwelveMonths, "storeId");

        const storesGrowthForecast = nextTwelveMonthsLikeForLikeStores.map(current => {
            const previous = priorTwelveMonthsLikeForLikeStores.find(item => item.storeId === current.storeId);
            let growth = 0;
            let rawGrowth = 0;
            let previousSales = 0;
            if (previous && previous.sales !== 0) {
                previousSales = previous.sales;
                growth = ((current.sales - previous.sales) / previous.sales) * 100;
                rawGrowth = current.sales - previous.sales;
            }

            return {
                ...current,
                previousSales,
                growth,
                rawGrowth
            };
        });

        //Get all regionIds:
        const allRegionIds = storesGrowthForecast.map(function (el) { return el.regionId; });

        //Get distinct regionIds:
        const currentDistinctRegionIds = [...new Set(allRegionIds)];

        //Get each regions total sales:
        const regionForecastSales = [];
        for (let i = 0; i < currentDistinctRegionIds.length; i++) { //Each region
            const currentRegionId = currentDistinctRegionIds[i];
            let currentRegionForecastSales = 0;
            let previousRegionForecastSales = 0;
            let regionForecastGrowth = 0;
            for (let j = 0; j < storesGrowthForecast.length; j++) { //Each store
                if (storesGrowthForecast[j].regionId === currentRegionId) {
                    currentRegionForecastSales = currentRegionForecastSales + storesGrowthForecast[j].sales;
                    previousRegionForecastSales = previousRegionForecastSales + storesGrowthForecast[j].previousSales;
                    regionForecastGrowth = (currentRegionForecastSales) - (previousRegionForecastSales);
                }
            }
            regionForecastSales.push({
                regionId: currentRegionId,
                currentRegionForecastSales: currentRegionForecastSales,
                previousRegionForecastSales: previousRegionForecastSales,
                regionForecastGrowth: regionForecastGrowth
            });
        }

        const distinctRegionForecastSales = regionForecastSales;
        let ragLabel;
        let ragStatus;
        let ragValue;
        let y;

        //Next 12 months forecast sales:
        const nextTwelveMonthsForecastSales = nextTwelveMonths.reduce((total, item) => item.sales + total, 0);
        //Last 12 months sales:
        const priorTwelveMonthsSales = priorTwelveMonths.reduce((total, item) => item.sales + total, 0);

        //Overall company forecast sales:
        const overallCompanyForecastSales = (nextTwelveMonthsForecastSales) - (priorTwelveMonthsSales);

        //Get number of distinct regions
        const numberDistinctRegions = currentDistinctRegionIds.length;

        //Check the number of regions:
        if (numberDistinctRegions === 1) { //only one region
            //check for dependency on singular store

            //Set label value:
            ragLabel = "Dependency on singular store";

            //Get number of stores:
            const numberOfStores = storesGrowthForecast.length;

            //No Dependency on a single store for growth:
            let checkStoreNoDependency = 0;
            //Some Dependency on a single store for growth:
            let checkStoreSomeDependency = 0;
            //Severe dependency on a single store for growth:
            let checkStoreSevereDependency = 0;

            //Check each store:
            for (let m = 0; m < storesGrowthForecast.length; m++) {
                //Get raw growth value:
                const storeForecastYoYSalesIncrease = storesGrowthForecast[m].rawGrowth;
                //Y calculation:
                y = ((storeForecastYoYSalesIncrease / overallCompanyForecastSales) * 100);
                if (y <= 50) {
                    checkStoreNoDependency = checkStoreNoDependency + 1;
                } else if (y < 75) {
                    checkStoreSomeDependency = checkStoreSomeDependency + 1;
                } else { //y >= 75
                    checkStoreSevereDependency = checkStoreSevereDependency + 1;
                }
            }

            //Red text - sales are declining:
            if (overallCompanyForecastSales < 0) {
                ragStatus = "error";
                ragValue = "Forecast sales are decreasing";
            }
            //Amber text - severe dependency on at least one store:
            else if (checkStoreSevereDependency >= 1) {
                ragStatus = "warning";
                ragValue = "Forecast sales are growing and there's a severe dependency on a singular store for growth";
            }
            //Green text - no dependency on any store:
            else if (checkStoreNoDependency === numberOfStores) {
                ragStatus = "success";
                ragValue = "Forecast sales are growing and there's no dependency on a singular store for growth";
            }
            //Green text -  some dependency on at least one store:
            else { //checkStoreSomeDependency >= 1
                ragStatus = "success";
                ragValue = "Forecast sales are growing and there's some dependency on a singular store for growth";
            }
        } else {
            //check for dependency on singular region

            //Set label value:
            ragLabel = "Dependency on singular region";

            //Get number of stores:
            const numberOfStores = storesGrowthForecast.length;

            //No Dependency on a single store for growth:
            let checkRegionNoDependency = 0;
            //Some Dependency on a single store for growth:
            let checkRegionSomeDependency = 0;
            //Severe dependency on a single store for growth:
            let checkRegionSevereDependency = 0;

            //Check each region:
            for (let n = 0; n < distinctRegionForecastSales.length; n++) {
                //Get raw growth value:
                const regionForecastYoYSalesIncrease = distinctRegionForecastSales[n].regionForecastGrowth;
                //Y calculation:
                y = ((regionForecastYoYSalesIncrease / overallCompanyForecastSales) * 100);
                if (y <= 50) {
                    checkRegionNoDependency = checkRegionNoDependency + 1;
                } else if (y < 75) {
                    checkRegionSomeDependency = checkRegionSomeDependency + 1;
                } else { // y >= 75
                    checkRegionSevereDependency = checkRegionSevereDependency + 1;
                }
            }

            //Red text - sales are declining:
            if (overallCompanyForecastSales < 0) {
                ragStatus = "error";
                ragValue = "Forecast sales are decreasing";
            }
            //Amber text - severe dependency on at least one store:
            else if (checkRegionSevereDependency >= 1) {
                ragStatus = "warning";
                ragValue = "Forecast sales are growing and there's a severe dependency on a singular region for growth";
            }
            //Green text - no dependency on any store:
            else if (checkRegionNoDependency === numberOfStores) {
                ragStatus = "success";
                ragValue = "Forecast sales are growing and there's no dependency on a singular region for growth";
            }
            //Green text -  some dependency on at least one store:
            else { //checkRegionSomeDependency >= 1
                ragStatus = "success";
                ragValue = "Forecast sales are growing and there's some dependency on a singular region for growth";
            }
        }

        const label = ragLabel;
        const status = ragStatus;
        const value = ragValue;
        dispatch(actions.getForecastDependencyOnSingularRegionOrStoreSuccess(label, status, value));
    }
    catch (error) {
        dispatch(actions.getForecastDependencyOnSingularRegionOrStoreFailure());
        dispatch(logError("Error loading ForecastDependencyOnSingularRegionOrStore.", error));
    }
};

const setSelectedRegion = (selectedRegion) => (dispatch) => {
    dispatch(actions.setSelectedRegion(selectedRegion));
};

const operations = {
    getTotalSalesGrowthByRegionForecast,
    getSalesByRegionGrowthForecast,
    getForecastDependencyOnSingularRegionOrStore,
    setSelectedRegion
};

export default operations;
