import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import mathUtils from "utils/mathUtils";

import { LocalAreaHotspot } from "./localAreaHotspot";

export class Competitor {
    public readonly fascia: string;
    public readonly storeCategoryId: number;
    public readonly storeCategoryName: string;
    public readonly directCompetitor: boolean;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly distanceToProposedStore: number;
    public readonly distanceToHotspot: number;
    public readonly size: number;

    constructor(
        fascia: string,
        storeCategoryId: number,
        storeCategoryName: string,
        directCompetitor: boolean,
        latitude: number,
        longitude: number,
        distanceToProposedStore: number,
        distanceToHotspot: number,
        size: number,
    ) {
        this.fascia = fascia;
        this.storeCategoryId = storeCategoryId;
        this.storeCategoryName = storeCategoryName;
        this.directCompetitor = directCompetitor;
        this.latitude = latitude;
        this.longitude = longitude;
        this.distanceToProposedStore = distanceToProposedStore;
        this.distanceToHotspot = distanceToHotspot;
        this.size = size;
    }
}

export const loadCompetitors = (pinnedLocation?: PinnedLocation, localAreaHotspot?: LocalAreaHotspot, directCompetitorNames?: string[]): AppThunk<Promise<Competitor[]>> => async (dispatch) => {
    try {
        if (!pinnedLocation || !localAreaHotspot || !directCompetitorNames) {
            return [];
        }

        const query = {
            dimensions: [
                "LocalCompetitors.Fascia",
                "LocalCompetitors.StoreCategory_ID",
                "LocalCompetitors.RetailCategory",
                "FasciaMapping.RevisedFascia",
                "LocalCompetitors.LAT",
                "LocalCompetitors.LNG",
                "LocalCompetitors.NetSalesAreaSqFt"
            ],
            filters: [{
                member: "LocalCompetitors.RetailCentreID",
                operator: "equals",
                values: [String(pinnedLocation.retailCentre.id)]
            }, {
                member: "LocalCompetitors.Distance",
                operator: "lt",
                values: ["7"]
            }, {
                member: "LocalCompetitors.DateDeleted",
                operator: "notSet"
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return rawData.map(row => {
            const distanceToProposedStore = mathUtils.haversineDistance(
                pinnedLocation.latitude,
                pinnedLocation.longitude,
                row["LocalCompetitors.LAT"],
                row["LocalCompetitors.LNG"]
            );
            const distanceToHotspot = mathUtils.haversineDistance(
                localAreaHotspot.latitude,
                localAreaHotspot.longitude,
                row["LocalCompetitors.LAT"],
                row["LocalCompetitors.LNG"]
            );
            return new Competitor(
                row["LocalCompetitors.Fascia"],
                Number(row["LocalCompetitors.StoreCategory_ID"]),
                row["LocalCompetitors.RetailCategory"],
                directCompetitorNames.includes(row["FasciaMapping.RevisedFascia"]),
                row["LocalCompetitors.LAT"],
                row["LocalCompetitors.LNG"],
                distanceToProposedStore,
                distanceToHotspot,
                row["LocalCompetitors.NetSalesAreaSqFt"]
            );
        })
            .filter(competitor => competitor.distanceToProposedStore <= 5);
    } catch (error) {
        dispatch(logError("Error loading Competitors.", error));
        throw error;
    }
};
