import types from "./types";

const getCOGSPastYearRequest = () => ({
    type: types.GET_COGS_PAST_YEAR_REQUEST
});

const getCOGSPastYearSuccess = (value, percentageDifference) => ({
    type: types.GET_COGS_PAST_YEAR_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getCOGSPastYearFailure = () => ({
    type: types.GET_COGS_PAST_YEAR_FAILURE
});

const getGrossProfitLastYearRequest = () => ({
    type: types.GET_GROSS_PROFIT_LAST_YEAR_REQUEST
});

const getGrossProfitLastYearSuccess = (value, percentageDifference) => ({
    type: types.GET_GROSS_PROFIT_LAST_YEAR_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getGrossProfitLastYearFailure = () => ({
    type: types.GET_GROSS_PROFIT_LAST_YEAR_FAILURE
});

const getGrossProfitMarginLastYearRequest = () => ({
    type: types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_REQUEST
});

const getGrossProfitMarginLastYearSuccess = (value, percentageDifference) => ({
    type: types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getGrossProfitMarginLastYearFailure = () => ({
    type: types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_FAILURE
});

const getGrossProfitMarginOverTimeRequest = () => ({
    type: types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_REQUEST
});

const getGrossProfitMarginOverTimeSuccess = (store, comparator) => ({
    type: types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_SUCCESS,
    payload: {
        store, comparator
    }
});

const getGrossProfitMarginOverTimeFailure = () => ({
    type: types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_FAILURE
});

const getGrossProfitTrendRequest = () => ({
    type: types.GET_GROSS_PROFIT_TREND_REQUEST
});

const getGrossProfitTrendSuccess = (status, value) => ({
    type: types.GET_GROSS_PROFIT_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getGrossProfitTrendFailure = () => ({
    type: types.GET_GROSS_PROFIT_TREND_FAILURE
});

const actions = {
    getCOGSPastYearRequest,
    getCOGSPastYearSuccess,
    getCOGSPastYearFailure,
    getGrossProfitLastYearRequest,
    getGrossProfitLastYearSuccess,
    getGrossProfitLastYearFailure,
    getGrossProfitMarginLastYearRequest,
    getGrossProfitMarginLastYearSuccess,
    getGrossProfitMarginLastYearFailure,
    getGrossProfitMarginOverTimeRequest,
    getGrossProfitMarginOverTimeSuccess,
    getGrossProfitMarginOverTimeFailure,
    getGrossProfitTrendRequest,
    getGrossProfitTrendSuccess,
    getGrossProfitTrendFailure
};

export default actions;
