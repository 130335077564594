import { combineReducers } from "redux";

import slice from "./productSlice";
import partnerFilters from "./partnerFilters";
import storeProductFilters from "./storeProductFilters";

const reducer = combineReducers({
    root: slice.reducer,
    partnerFilters,
    storeProductFilters
});

export default reducer;
