import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectLongTermNetOpenings } from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";

const LongTermNetOpenings: React.FC = () => {
    const longTermOpenings = useAppSelector(selectLongTermNetOpenings);
    return (
        <RAGIndicatorNew ragIndicator={longTermOpenings} />
    );
};

export default LongTermNetOpenings;
