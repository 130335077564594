import { createSelector } from "reselect";
import { selectStore, selectComparator, selectNumberOfProductCategories } from "modules/customer/insights/portfolio/portfolioSlice";

const historicProdGrowthStoreVsComp = (state) => {
    return state.customer.insights.portfolio.sales.productCategoryGrowth.historicProdGrowthStoreVsComp;
};

const storeVsComparatorProductOrProductCategoryGrowth = createSelector(
    historicProdGrowthStoreVsComp,
    state => selectNumberOfProductCategories(state),
    state => selectStore(state),
    state => selectComparator(state),
    (historicProdGrowthStoreVsComp,
     numberOfProductCategories,
     selectedStoreSelector,
     comparatorStoresSelector) => {
        const rag = {
            loading: historicProdGrowthStoreVsComp.loading,
            error: historicProdGrowthStoreVsComp.error,
            id: "store-vs-comparator-product-or-product-category-growth",
            label: "Store vs comparator product or product category growth",
            status: "info",
            value: ""
        };

        if (rag.error || rag.loading) {
            return rag;
        }

        const values = historicProdGrowthStoreVsComp.values;

        const numberProductCategoryPositiveGrowthVsComparator = values.map(p => p.selectedStoreGrowth > p.medianComparatorGrowth ? p.selectedStoreGrowth : 0).filter(Boolean).length;
        const numberProductCategoryNegativeGrowthVsComparator = values.map(p => p.selectedStoreGrowth <= p.medianComparatorGrowth ? p.selectedStoreGrowth : 0).filter(Boolean).length;
        const totalNumberProductCategory = values.map(p => p.productCategory).length;

        const positiveGrowthCategories = (numberProductCategoryPositiveGrowthVsComparator / totalNumberProductCategory) * 100;
        const negativeGrowthCategories = (numberProductCategoryNegativeGrowthVsComparator / totalNumberProductCategory) * 100;

        const productCategoriesString = ((numberOfProductCategories <= 1) ? "products" : "product categories");

        const ragLabelString = ((numberOfProductCategories <= 1) ? "product" : "product category");
        rag.label = `Store vs comparator ${ragLabelString} growth`;

        if (positiveGrowthCategories >= 75) {
            rag.status = "success";
            rag.value = `Majority of ${productCategoriesString} in ${selectedStoreSelector.name} have historic growth ahead of ${comparatorStoresSelector.name}`;
        } else if (negativeGrowthCategories >= 75) {
            rag.status = "error";
            rag.value = `Majority of ${productCategoriesString} in ${selectedStoreSelector.name} have historic growth behind ${comparatorStoresSelector.name}`;
        } else {
            rag.status = "warning";
            rag.value = `Mixed Performance of ${productCategoriesString} in Historic Growth in ${selectedStoreSelector.name} vs ${comparatorStoresSelector.name}`;
        }

        return rag;
    }
);

const selectors = {
    historicProdGrowthStoreVsComp,
    storeVsComparatorProductOrProductCategoryGrowth
};

export default selectors;
