import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface DataRefresh {
    id: string,
    createdAt: DateTime,
    status: DataRefreshStatus
}

enum DataRefreshStatus {
    Unknown = "Unknown",
    InProgress = "InProgress",
    Paused = "Paused",
    Completed = "Completed"
}

interface DataRefreshesVisibility {
    isVisible: boolean,
    accountId: string
}

interface DeleteDataRefreshVisibility {
    isVisible: boolean,
    dataRefreshId: string
}

interface DataRefreshesState {
    dataRefreshes: DataRefresh[],
    dataRefreshesVisibility: DataRefreshesVisibility,
    deleteDataRefreshVisibility: DeleteDataRefreshVisibility
}

const initialState: DataRefreshesState = {
    dataRefreshes: [],
    dataRefreshesVisibility: {
        isVisible: false,
        accountId: ""
    },
    deleteDataRefreshVisibility: {
        isVisible: false,
        dataRefreshId: ""
    }
};

const dataRefreshesSlice = createSlice({
    name: "admin/accounts/dataRefreshes",
    initialState,
    reducers: {
        setDataRefreshes: (state, action: PayloadAction<DataRefresh[]>) => {
            state.dataRefreshes = action.payload;
        },
        clearDataRefreshes: (state) => {
            state.dataRefreshes = initialState.dataRefreshes;
        },
        showDataRefreshes: (state, action: PayloadAction<string>) => {
            state.dataRefreshesVisibility.isVisible = true;
            state.dataRefreshesVisibility.accountId = action.payload;
        },
        hideDataRefreshes: (state) => {
            state.dataRefreshesVisibility = initialState.dataRefreshesVisibility;
        },
        showDeleteDataRefresh: (state, action: PayloadAction<string>) => {
            state.deleteDataRefreshVisibility.isVisible = true;
            state.deleteDataRefreshVisibility.dataRefreshId = action.payload;
        },
        hideDeleteDataRefresh: (state) => {
            state.deleteDataRefreshVisibility = initialState.deleteDataRefreshVisibility;
        }
    }
});

export const {
    clearDataRefreshes,
    showDataRefreshes,
    hideDataRefreshes,
    showDeleteDataRefresh,
    hideDeleteDataRefresh
} = dataRefreshesSlice.actions;

export const getDataRefreshes = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/data-refreshes`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const dataRefreshesRaw = response.data.dataRefreshes;
            const dataRefreshes = dataRefreshesRaw.map((dataRefreshRaw: any) => ({
                ...dataRefreshRaw,
                createdAt: DateTime.fromISO(dataRefreshRaw.createdAt, { zone: "utc" })
            }));
            dispatch(dataRefreshesSlice.actions.setDataRefreshes(dataRefreshes));
            break;
        }
        default: {
            dispatch(dataRefreshesSlice.actions.clearDataRefreshes());
            break;
        }
    }
};

export const deleteDataRefresh = (accountId: string, dataRefreshId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiDelete(`/admin/accounts/${accountId}/data-refreshes/${dataRefreshId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(dataRefreshesSlice.actions.hideDeleteDataRefresh());
            dispatch(notifySuccess("Data refresh deleted."));
            dispatch(getDataRefreshes(accountId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Data refresh not found."));
            break;
        }
    }
};

export const selectDataRefreshes = (state: RootState) => {
    return state.admin.accounts.dataRefreshes.dataRefreshes;
};

export const selectDataRefreshesVisibility = (state: RootState) => {
    return state.admin.accounts.dataRefreshes.dataRefreshesVisibility;
};

export const selectDeleteDataRefreshVisibility = (state: RootState) => {
    return state.admin.accounts.dataRefreshes.deleteDataRefreshVisibility;
};

export default dataRefreshesSlice;
