import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { dateFormat } from "highcharts";
import Highcharts from "highcharts";

import ChartStockBase from "./ChartStockBase";
import numberFormatter from "utils/numberFormatter";
import useColourPalette from "./useColourPalette";
import { useTheme } from "@material-ui/core/styles";


const StockLine = (props) => {
    const { loading, error, options: customOptions, globalOptions: customGlobalOptions } = props;

    const colourPalette = useColourPalette();
    const theme = useTheme();

    const staticOptions = {
        chart: {
            type: "spline"
        },
        xAxis: {
            tickWidth: 0,
            tickLength: 0,
            dateTimeLabelFormats: {
                millisecond: '%e %b %Y',
                second: '%e %b %Y',
                minute: '%e %b %Y',
                hour: '%e %b %Y',
                day: '%e %b %Y',
                week: '%e %b %Y',
                month: '%b %Y',
                year: '%Y'
            },
            ordinal: false
        },
        yAxis: {
            plotLines: [{
                value: 0,
                color: theme.palette.common.white,
                width: 2
            }]
        },
        navigator: {
            maskFill: String(new Highcharts.Color(theme.palette.common.white).setOpacity(0.1).get("rgba")),
            series: {
                dataLabels: {
                    style: {
                        color: theme.palette.common.white
                    }
                },
                color: colourPalette.categorical[0],
                lineWidth: 2,
                type: "areaspline",
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, String(new Highcharts.Color(colourPalette.categorical[0]).setOpacity(0.4).get("rgba"))],
                        [1, String(new Highcharts.Color(theme.palette.common.white).setOpacity(0).get("rgba"))]
                    ]
                }
            },
            xAxis: {
                ordinal: false, //don't sqaush gaps
                gridLineWidth: 0,
                visible: true,
                offset: 5,
                showFirstLabel: false,
                units: [['year', null]],
                dateTimeLabelFormats: {
                    second: '%Y',
                    minute: '%Y',
                    hour: '%Y',
                    day: '%Y',
                    week: '%Y',
                    month: '%Y',
                    year: '%Y'
                },
                labels: {
                    y: 11,
                    x: -15,
                    style: {
                        color: 'white',
                        fontSize: "12px",
                        opacity: 1
                    }
                }

            },
            handles: {
                symbols: [],
                backgroundColor: '#6A6977',
                borderColor: '#C2C2C2'
            }
        },
        rangeSelector: {
            allButtonsEnabled: true,
            buttonSpacing: 10,
            selected: 4,
            buttonTheme: {
                fill: '#3A384F',
                stroke: 'none',
                'stroke-width': 0,
                fontFamily: 'Open Sans',
                r: 5,
                width: 50,
                style: {
                    color: theme.palette.common.white
                },
                states: {
                    hover: {
                        fill: '#4d4a60',
                        style: {
                            color: 'white',
                            fontFamily: 'Open Sans'
                        }
                    },
                    select: {
                        fill: colourPalette.categorical[0],
                        style: {
                            color: '#3A384F',
                            fontFamily: 'Open Sans'
                        }
                    }
                }
                
            },
            inputBoxBorderColor: '#3A384F',
            inputBoxWidth: 120,
            inputBoxHeight: 18,
            inputStyle: {
                color: 'gray',
                fontWeight: '',
                borderRadius: 6
            },
            labelStyle: {
                color: 'white'
            }
        },

        tooltip: {
            useHTML: true,
            headerFormat: `<p style="font-size: 10;">`,
            footerFormat: `</p>`,
            formatter() {
                let series;
                if (this.points && this.points.length > 0) {
                    series = this?.points[0].series;
                } else {
                    series = this?.series;
                }

                const selected = series?.chart?.options?.rangeSelector?.selected || 0;

                let date = '';
                const timestamp = Number(this.x);
                if(selected === 0){
                    date = dateFormat('%A, %b %e, %Y', timestamp);
                } else if (selected === 1) {
                    date = 'Week from ' + dateFormat('%A, %b %e, %Y', timestamp);
                } else if (selected === 2) {
                    date = dateFormat('%B %Y', timestamp);
                } else if (selected === 3) {
                    date = 'Q' + (Math.floor(parseInt(dateFormat('%m', timestamp)) / 3) + 1) + dateFormat(' %Y', timestamp);
                } else if (selected === 4) {
                    date = dateFormat('%Y', timestamp);
                }

                let s = '<b>' + date + '</b><br/>'+ series?.name + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>' + numberFormatter.toGBP(this.y) + '</b>';

                return s;
            }
        },

        responsive: {
            rules: [{
                chartOptions: {
                    rangeSelector: {
                        enabled: true,
                        inputPosition: {
                            align: 'right',
                            x:15
                        }
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -2
                        },
                        title: {
                            text: ''
                        }
                    }
                },
                condition: {
                    maxWidth: 500
                }
            }]
        }

    };

    const staticGlobalOptions = {

    };
    
    const options = _.merge({}, staticOptions, customOptions);
    const globalOptions = _.merge({}, staticGlobalOptions, customGlobalOptions);

    return (
        <ChartStockBase loading={loading} error={error} options={options} globalOptions={globalOptions} dataCy="stock-line-chart" />
    );   
};

StockLine.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    globalOptions: PropTypes.object.isRequired
};

StockLine.defaultProps = {
    loading: false,
    error: false
};

export default StockLine;
