import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import StockLine from "components/visuals/StockLine";
import dateUtils from "utils/dateUtils";
import arrayUtils from "utils/arrayUtils";

import _ from "lodash";
import { operations, selectors } from "modules/customer/insights/performance/forecast/forecastRevenue";
import numberFormatter from "utils/numberFormatter";
import { dateFormat } from "highcharts";

const SalesHistoryForecast = () => {
    const dispatch = useDispatch();

    const salesHistoryForecast = useSelector(state => selectors.salesHistoryForecast(state));
    const { loading, error, values } = salesHistoryForecast;
    const [actuals, forecast] = arrayUtils.bifurcateCollectionOnFlag(values, "sales", "forecastFlag", 0, 1, values.length, true);
    const dates = dateUtils.datesToTime(values.map(item => item.date));
    const weeks = dateUtils.datesToTime(values.map(item => item.week));
    const actualsDataValues = _.zip(dates, actuals, weeks);
    const forecastDataValues = _.zip(dates, forecast, weeks);

    const globalOptions = {
        lang: {
            rangeSelectorZoom: "Zoom"
        }
    };

    const options = {
        title: {
            text: 'Historic and forecast weekly revenue over time'
        },
        yAxis: {
            title: {
                text: "Revenue"
            },
            labels: {
                formatter: function () { 
                    const noDpTest = numberFormatter.toDecimalPlaces(this.value, 0);
                    const oneDpTest = numberFormatter.toDecimalPlaces(this.value, 1);

                    if(parseFloat(noDpTest) === parseFloat(oneDpTest)) {
                        return numberFormatter.toGBP(this.value, 0);
                    } else {
                        return numberFormatter.toGBP(this.value, 1);
                    }
                }
            }
        },
        series: [{
            name: "Historic",
            data: actualsDataValues,
            gapSize: 1
        }, {
            name: "Forecast",
            data: forecastDataValues,
            dashStyle: "Dash",
            color: "#42E8E0",
            showInNavigator: true,
            navigatorOptions: {
                type: "areaspline",
            },
            gapSize: 1
        }],
        legend:{
            enabled:true
        },

        rangeSelector: {
            allButtonsEnabled: true,
            selected: 3,
            buttons: [{
                type: "month",
                count: 1,
                text: "Month",
                dataGrouping: {
                    forced: false,
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 3,
                text: "Quarter",
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 1,
                text: "Year",
                preserveDataGrouping: true
            }, {
                type: "all",
                text: "All",
                title: "View all",
                preserveDataGrouping: true
            }]
        },

        plotOptions: {
            series: {
                tooltip: {
                    valueDecimals: ",.2f",
                    valuePrefix: "£"
                }
            }
        },

        tooltip: {
            formatter() {
                let date = "Week commencing " + dateFormat("%A, %b %e, %Y", this.x);
                const label = this.points[0].series.name === "Historic" ? "Revenue " : "Forecast revenue ";
                let s = "<b>" + date + "</b><br/>" + label + "<b>" + numberFormatter.toGBP(this.y) + "</b>";

                return s;
            }
        },
    };

    React.useEffect(() => {
        dispatch(operations.getSalesHistoryForecast());
        dispatch(operations.getMonthlyForecast());
    }, [dispatch]);


    return (
        <Box data-cy="sales-history-forecast">
            <StockLine loading={loading} error={error} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default SalesHistoryForecast;
