import React from "react";

import { IconButton } from "@material-ui/core";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip from "components/SimpleTooltip";

import { moveCategoryUp } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface MoveCategoryUpProps {
    categoryId: string
}

const MoveCategoryUp: React.FC<MoveCategoryUpProps> = (props) => {
    const { categoryId } = props;
    const dispatch = useAppDispatch();

    const handleMoveCategoryUpClick = () => {
        dispatch(moveCategoryUp(categoryId));
    };

    return (
        // @ts-ignore 
        <SimpleTooltip title="Move up">
            <StyledIconButton size="small" onClick={handleMoveCategoryUpClick}>
                <ArrowUpwardRoundedIcon />
            </StyledIconButton>
        </SimpleTooltip>
    );
};

export default MoveCategoryUp;
