import React from "react";
import { Marker } from "react-map-gl";

import { Icon } from "@material-ui/core";

import SelectedStoreIconSvg from "assets/images/selected-store-icon.svg";

interface SelectedStoreProps {
    latitude: number,
    longitude: number
}

const SelectedStoreMarker: React.FC<SelectedStoreProps> = (props) => {
    const { latitude, longitude } = props;
    const markerSize = 32;

    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            offsetTop={-24}
            offsetLeft={-12}
        >
            <div style={{
                pointerEvents: 'none',
                width: markerSize,
                height: markerSize,
            }}>
                <Icon>
                    <img src={SelectedStoreIconSvg} height={markerSize} width={markerSize} alt="selected-store-icon" />
                </Icon>
            </div>
        </Marker>
    );
};

export default SelectedStoreMarker;
