import React from "react";

import { Grid, Mark, Slider, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import {
    selectTimelineStartField,
    selectTimelineEndField,
    setTimelineStartField,
    setTimelineEndField,
    TimelineField
} from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledSlider = withStyles(theme => ({
    root: {
        color: theme.palette.common.white
    }
}))(Slider);

enum TimelineValue {
    storeCount60MonthsAgo,
    storeCount24MonthsAgo,
    storeCount12MonthsAgo,
    storeCount6MonthsAgo,
    storeCountCurrent
}

const marks: Mark[] = [{
    value: TimelineValue.storeCount60MonthsAgo
}, {
    value: TimelineValue.storeCount24MonthsAgo
}, {
    value: TimelineValue.storeCount12MonthsAgo
}, {
    value: TimelineValue.storeCount6MonthsAgo
}, {
    value: TimelineValue.storeCountCurrent
}];

const fieldFromValue = (value: TimelineValue): TimelineField => {
    switch (value) {
        case TimelineValue.storeCountCurrent:
            return TimelineField.storeCountCurrent;
        case TimelineValue.storeCount6MonthsAgo:
            return TimelineField.storeCount6MonthsAgo;
        case TimelineValue.storeCount12MonthsAgo:
            return TimelineField.storeCount12MonthsAgo;
        case TimelineValue.storeCount24MonthsAgo:
            return TimelineField.storeCount24MonthsAgo;
        case TimelineValue.storeCount60MonthsAgo:
            return TimelineField.storeCount60MonthsAgo;
    }
};

const valueFromField = (field: TimelineField): TimelineValue => {
    switch (field) {
        case TimelineField.storeCountCurrent:
            return TimelineValue.storeCountCurrent;
        case TimelineField.storeCount6MonthsAgo:
            return TimelineValue.storeCount6MonthsAgo;
        case TimelineField.storeCount12MonthsAgo:
            return TimelineValue.storeCount12MonthsAgo;
        case TimelineField.storeCount24MonthsAgo:
            return TimelineValue.storeCount24MonthsAgo;
        case TimelineField.storeCount60MonthsAgo:
            return TimelineValue.storeCount60MonthsAgo;
    }
};

const TimelineFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const startField = useAppSelector(selectTimelineStartField);
    const startValue = valueFromField(startField);
    const endField = useAppSelector(selectTimelineEndField);
    const endValue = valueFromField(endField);

    const getTypographyStyle = (targetValue: TimelineValue) => {
        return startValue <= targetValue && endValue > targetValue
            ? { color: theme.palette.text.primary, fontWeight: "bold" }
            : { color: theme.palette.text.disabled, fontWeight: "" };
    };

    const handleSliderChange = (event: React.ChangeEvent<{}>, value: (number | number[])) => {
        const values = value as TimelineValue[];
        const startField = fieldFromValue(values[0]);
        dispatch(setTimelineStartField(startField));
        const timelineEndField = fieldFromValue(values[1]);
        dispatch(setTimelineEndField(timelineEndField));
    };

    return (
        <Grid container>
            <Grid item xs={3}>
                <Typography variant="body2" align="center" style={getTypographyStyle(TimelineValue.storeCount60MonthsAgo)}>
                    25-60
                    <br />
                    months ago
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2" align="center" style={getTypographyStyle(TimelineValue.storeCount24MonthsAgo)}>
                    13-24
                    <br />
                    months ago
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2" align="center" style={getTypographyStyle(TimelineValue.storeCount12MonthsAgo)}>
                    7-12
                    <br />
                    months ago
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2" align="center" style={getTypographyStyle(TimelineValue.storeCount6MonthsAgo)}>
                    0-6
                    <br />
                    months ago
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <StyledSlider
                    value={[startValue, endValue]}
                    onChange={handleSliderChange}
                    min={TimelineValue.storeCount60MonthsAgo}
                    max={TimelineValue.storeCountCurrent}
                    marks={marks}
                />
            </Grid>
        </Grid>
    );
};

export default TimelineFilter;
