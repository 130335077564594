import { createSelector } from "reselect";
import _ from "lodash";
import mathUtils from "utils/mathUtils";
import { median } from "mathjs";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const demographics = (state) => {
    return state.customer.insights.portfolio.catchment.demographicBreakdown.demographics;
};

const calculateIndex = (demographicsData, selectedStore, comparatorStores) => {

    let selectedStoreDemographics = [];
    const comparatorDemographics = [];

    //Selected Store
    const selectedData = demographicsData.find(item =>
        (item.RetailCentreID === selectedStore.retailCentreID) && (item.StoreCategory === selectedStore.kpmgStoreCategory));

    for (let value in selectedData) {
        if ((value !== "RetailCentreID") && (value !== "StoreCategory")) {
            selectedStoreDemographics.push(selectedData[value]);
        }
    }
    
    //Comparator
    for (let i in comparatorStores) {
        const currentStoreDemographics = [];
        const currentData = demographicsData.find(item =>
            (item.RetailCentreID === comparatorStores[i].retailCentreID) && (item.StoreCategory === comparatorStores[i].kpmgStoreCategory));
        for (let value in currentData) {
            if ((value !== "RetailCentreID") && (value !== "StoreCategory")) {
                currentStoreDemographics.push(currentData[value]);
            }
        }

        comparatorDemographics.push(currentStoreDemographics);
    }

    // Calculate percentage values for each age group
    const selectedStorePercentages = [];
    for (let value in selectedStoreDemographics) {
        selectedStorePercentages.push(selectedStoreDemographics[value] / _.sum(_.values(selectedStoreDemographics)));
    }

    const percentageValues = [];
    let percentageValuesSum = 0;
    for (let value in comparatorDemographics[0]) {
        const relevantPercentage = median(comparatorDemographics.map(item => (item[value] / _.sum(_.values(item))) || 0));
        percentageValues.push(relevantPercentage);
        percentageValuesSum += median(relevantPercentage);
    }

    const comparatorPercentages = percentageValues.map(item => item / percentageValuesSum);

    // Calculate index for each group
    const values = [];
    for (let i in selectedStorePercentages) {
        let index = 0;
        if (comparatorPercentages[i] === 0 && selectedStorePercentages[i] !== 0) {
            index = 100;
        } else if (selectedStorePercentages[i] !== comparatorPercentages[i]) {
            index = mathUtils.round(100 * ((selectedStorePercentages[i] / comparatorPercentages[i]) - 1), 0);
        }
        values.push(index);
    }
    return values;
};

const ageIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const ageData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            Age0to15: item.Age0to15,
            Age16to24: item.Age16to24,
            Age25to34: item.Age25to34,
            Age35to44: item.Age35to44,
            Age45to54: item.Age45to54,
            Age55to64: item.Age55to64,
            Age65Plus: item.Age65Plus
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["0 to 15", "16 to 24", "25 to 34", "35 to 44", "45 to 54", "55 to 64", "65+"];
        selector.values = calculateIndex(ageData, selectedStore, comparatorStores);

        return selector;
    });

const educationIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const educationData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            NoQualification: item.NoQualification,
            Level1: item.Level1,
            Level2: item.Level2,
            Level3: item.Level3,
            Level4Plus: item.Level4Plus,
            OtherQualification: item.OtherQualification
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["No Qualifications", "Level 1", "Level 2", "Level 3", "Level 4+", "Other"];
        selector.values = calculateIndex(educationData, selectedStore, comparatorStores);

        return selector;
    });

const accommodationTypeIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const accommodationData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            Detached: item.Detached,
            SemiDetached: item.SemiDetached,
            Terrace: item.Terrace,
            Flat: item.Flat,
            OtherAccommodation: item.OtherAccommodation
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["Detached", "Semi-detached", "Terrace", "Flat", "Other"];
        selector.values = calculateIndex(accommodationData, selectedStore, comparatorStores);

        return selector;
    });

const householdCompIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const householdCompData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            OnePersonHousehold: item.OnePersonHousehold,
            MarriedDependentChildren: item.MarriedDependentChildren,
            MarriedNoDependentChildren: item.MarriedNoDependentChildren,
            CohabitingCoupleDependentChildren: item.CohabitingCoupleDependentChildren,
            CohabitingCoupleNoDependentChildren: item.CohabitingCoupleNoDependentChildren,
            LoneParentDependentChildren: item.LoneParentDependentChildren,
            LoneParentNoDependentChildren: item.LoneParentNoDependentChildren,
            MultiPersonHouseholdStudents: item.MultiPersonHouseholdStudents,
            MultiPersonHouseholdOther: item.MultiPersonHouseholdOther
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["Single person",
            "Married with dependent children",
            "Married with no dependent children",
            "Cohabiting couple with dependent children",
            "Cohabiting couple with no dependent children",
            "Lone parent with dependent children",
            "Lone parent with no dependent children",
            "Multi-person household: students",
            "Multi-person household: other"
        ];
        selector.values = calculateIndex(householdCompData, selectedStore, comparatorStores);

        return selector;
    });

const householdIncomeIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const incomeData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            CountIncomeSub20k: item.CountIncomeSub20k,
            CountIncome20to30k: item.CountIncome20to30k,
            CountIncome30to40k: item.CountIncome30to40k,
            CountIncome40to50k: item.CountIncome40to50k,
            CountIncome50to60k: item.CountIncome50to60k,
            CountIncome60to70k: item.CountIncome60to70k,
            CountIncome70to80k: item.CountIncome70to80k,
            CountIncome80to90k: item.CountIncome80to90k,
            CountIncome90to100k: item.CountIncome90to100k,
            CountIncome100kPlus: item.CountIncome100kPlus
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["<£20,000",
            "£20,000 - £29,999",
            "£30,000 - £39,999",
            "£40,000 - £49,999",
            "£50,000 - £59,999",
            "£60,000 - £69,999",
            "£70,000 - £79,999",
            "£80,000 - £89,999",
            "£90,000 - £99,999",
            "£100,000+",
        ];

        selector.values = calculateIndex(incomeData, selectedStore, comparatorStores);

        return selector;
    });

const tenureIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const tenureData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            OwnedOutright: item.OwnedOutright,
            OwnedWithLoan: item.OwnedWithLoan,
            SocialRent: item.SocialRent,
            PrivateRent: item.PrivateRent,
            SharedOwnership: item.SharedOwnership,
            LivingRentFree: item.LivingRentFree
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["Owned outright",
            "Owned with loan",
            "Social rent",
            "Private rent",
            "Shared ownership",
            "Living rent free"
        ];

        selector.values = calculateIndex(tenureData, selectedStore, comparatorStores);

        return selector;
    });

const childrenIndex = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const selector = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            values: [],
            categories: []
        };

        const demographicsData = demographicsSelector.demographicsData;

        if (demographicsSelector.loading || demographicsSelector.error || (demographicsData.length === 0)) {
            return selector;
        }

        const childrenData = demographicsData.map(item => ({
            RetailCentreID: item.RetailCentreID,
            StoreCategory: item.StoreCategory,
            ChildrenAge0to2: item.ChildrenAge0to2,
            ChildrenAge3to5: item.ChildrenAge3to5,
            ChildrenAge6to10: item.ChildrenAge6to10,
            ChildrenAge11to15: item.ChildrenAge11to15,
            ChildrenAge16to18: item.ChildrenAge16to18
        }));

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        selector.categories = ["Children age 0 to 2",
            "Children age 3 to 5",
            "Children age 6 to 10",
            "Children age 11 to 15",
            "Children age 16 to 18",
        ];

        selector.values = calculateIndex(childrenData, selectedStore, comparatorStores);

        return selector;
    });

const representationOfBestDemographics = createSelector(
    demographics,
    state => selectStore(state),
    state => selectComparator(state),
    (demographicsSelector, selectedStoreSelector, comparatorStoresSelector) => {
        const rag = {
            loading: demographicsSelector.loading,
            error: demographicsSelector.error,
            id: "representation-of-best-demographics",
            label: "Representation of best demographics",
            status: "info",
            value: ""
        };

        ///Need actual retail centres in D_Store to do this since we need to join sales to catchment demographics

        if (rag.loading || rag.error || (demographicsSelector.demographicsData.length === 0) || (demographicsSelector.salesData.length === 0)) {
            return rag;
        }

        const originalDemographicsData = demographicsSelector.demographicsData;
        const salesData = demographicsSelector.salesData;
        
        //Demographics Data
        const demographicsArray = [];
        for (let i in originalDemographicsData) {

            //population for oa
            let pop = originalDemographicsData[i].Population;

            //OA ID -- Main OAID of Catchment Area
            let oaid = originalDemographicsData[i].RetailCentreID;
            const kpmgStoreCategory = originalDemographicsData[i].StoreCategory;

            //Pivot the data
            
            // STRUCTURE: oaID, indexGroup, category, oaValue, population
            // oaID is the ID for the output area
            // indexGroup is the index type e.g. children, age, education etc.
            // category is the subgroup within the index type, e.g. education level 1, level 2 etc.
            // oaValue is the number of people falling within a particular category within the OA
            // population is the total population of the output area       
            

            //Children
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Children",
                category: "Children age 0 to 2",
                oaValue: originalDemographicsData[i].ChildrenAge0to2,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Children",
                category: "Children age 3 to 5",
                oaValue: originalDemographicsData[i].ChildrenAge3to5,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Children",
                category: "Children age 6 to 10",
                oaValue: originalDemographicsData[i].ChildrenAge6to10,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Children",
                category: "Children age 11 to 15",
                oaValue: originalDemographicsData[i].ChildrenAge11to15,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Children",
                category: "Children age 16 to 18",
                oaValue: originalDemographicsData[i].ChildrenAge16to18,
                oaPopulation: pop
            });

            //Age
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "0 to 15", oaValue: originalDemographicsData[i].Age0to15, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "16 to 24", oaValue: originalDemographicsData[i].Age16to24, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "25 to 34", oaValue: originalDemographicsData[i].Age25to34, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "35 to 44", oaValue: originalDemographicsData[i].Age35to44, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "45 to 54", oaValue: originalDemographicsData[i].Age45to54, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "55 to 64", oaValue: originalDemographicsData[i].Age55to64, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Age", category: "65+", oaValue: originalDemographicsData[i].Age65Plus, oaPopulation: pop });

            //Education
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Education", category: "No qualifications", oaValue: originalDemographicsData[i].NoQualification, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Education", category: "Level 1", oaValue: originalDemographicsData[i].Level1, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Education", category: "Level 2", oaValue: originalDemographicsData[i].Level2, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Education", category: "Level 3", oaValue: originalDemographicsData[i].Level3, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Education", category: "Level 4+", oaValue: originalDemographicsData[i].Level4Plus, oaPopulation: pop });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Education",
                category: "Other Qualification",
                oaValue: originalDemographicsData[i].OtherQualification,
                oaPopulation: pop
            });

            //Accommodation Type
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Accommodation Type", category: "Detached", oaValue: originalDemographicsData[i].Detached, oaPopulation: pop });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Accommodation Type",
                category: "Semi-detached",
                oaValue: originalDemographicsData[i].SemiDetached,
                oaPopulation: pop
            });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Accommodation Type", category: "Terrace", oaValue: originalDemographicsData[i].Terrace, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Accommodation Type", category: "Flat", oaValue: originalDemographicsData[i].Flat, oaPopulation: pop });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Accommodation Type",
                category: "Other Accommodation",
                oaValue: originalDemographicsData[i].OtherAccommodation,
                oaPopulation: pop
            });

            //Household Composition
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Single person",
                oaValue: originalDemographicsData[i].OnePersonHousehold,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Married with dependent children",
                oaValue: originalDemographicsData[i].MarriedDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Married with no dependent children",
                oaValue: originalDemographicsData[i].MarriedNoDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Cohabiting couple with dependent children",
                oaValue: originalDemographicsData[i].CohabitingCoupleDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Cohabiting couple with no dependent children",
                oaValue: originalDemographicsData[i].CohabitingCoupleNoDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Lone parent with dependent children",
                oaValue: originalDemographicsData[i].LoneParentDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Lone parent with no dependent children",
                oaValue: originalDemographicsData[i].LoneParentNoDependentChildren,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Multi-person household: students",
                oaValue: originalDemographicsData[i].MultiPersonHouseholdStudents,
                oaPopulation: pop
            });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Household Composition",
                category: "Multi-person household: other",
                oaValue: originalDemographicsData[i].MultiPersonHouseholdOther,
                oaPopulation: pop
            });

            //Tenure
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Owned outright", oaValue: originalDemographicsData[i].OwnedOutright, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Owned with loan", oaValue: originalDemographicsData[i].OwnedWithLoan, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Social rent", oaValue: originalDemographicsData[i].SocialRent, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Private rent", oaValue: originalDemographicsData[i].PrivateRent, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Shared ownership", oaValue: originalDemographicsData[i].SharedOwnership, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Tenure", category: "Living rent free", oaValue: originalDemographicsData[i].LivingRentFree, oaPopulation: pop });

            //Income
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "<£20,000", oaValue: originalDemographicsData[i].CountIncomeSub20k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£20,000 - £29,999", oaValue: originalDemographicsData[i].CountIncome20to30k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£30,000 - £39,999", oaValue: originalDemographicsData[i].CountIncome30to40k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£40,000 - £49,999", oaValue: originalDemographicsData[i].CountIncome40to50k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£50,000 - £59,999", oaValue: originalDemographicsData[i].CountIncome50to60k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£60,000 - £69,999", oaValue: originalDemographicsData[i].CountIncome60to70k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£70,000 - £79,999", oaValue: originalDemographicsData[i].CountIncome70to80k, oaPopulation: pop });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£80,000 - £89,999", oaValue: originalDemographicsData[i].CountIncome80to90k, oaPopulation: pop });
            demographicsArray.push({
                oaID: oaid,
                kpmgStoreCategory: kpmgStoreCategory,
                indexGroup: "Income",
                category: "£90,000 - £99,999",
                oaValue: originalDemographicsData[i].CountIncome90to100k,
                oaPopulation: pop
            });
            demographicsArray.push({ oaID: oaid, kpmgStoreCategory: kpmgStoreCategory, indexGroup: "Income", category: "£100,000+", oaValue: originalDemographicsData[i].CountIncome100kPlus, oaPopulation: pop });
        }

        //Find distinct index groups and the distinct categories that sit within them
        const allIndexGroups = demographicsArray.map(item => item.indexGroup);
        const distinctIndexGroups = [...new Set(allIndexGroups)];

        const distinctIndexGroupCategories = [];

        for (let i = 0; i < distinctIndexGroups.length; i++) {
            let currentIndexGroup = distinctIndexGroups[i];
            let filteredArray = demographicsArray.filter(item => item.indexGroup === currentIndexGroup);
            let allIndexGroupCategories = filteredArray.map(item => item.category);
            let distinctIndexCategories = [...new Set(allIndexGroupCategories)];

            distinctIndexGroupCategories.push({
                indexGroup: currentIndexGroup,
                distinctIndexCategories: distinctIndexCategories
            });

        }
        //Adding Sales data to array
        for (let i in demographicsArray) {

            //Find sales data
            const relevantSalesData = salesData.find(item =>
                (item.retailCentreID === demographicsArray[i].oaID) && (item.kpmgStoreCategory === demographicsArray[i].kpmgStoreCategory));
            const relevantSalesValue = relevantSalesData?.salesValue ?? 0;

            demographicsArray[i].oaCategorySales = relevantSalesValue * (demographicsArray[i].oaValue / demographicsArray[i].oaPopulation);
        }

        //Calculate best sales demographic categories
        //For every possible index type (age, education, tenure etc.)
        for (let i = 0; i < distinctIndexGroupCategories.length; i++) {
            let currentIndexGroup = distinctIndexGroupCategories[i].indexGroup;
            let categories = distinctIndexGroupCategories[i].distinctIndexCategories;

            let bestSpendPerHead = 0; //default, will be updated by next for loop
            let bestCategory = categories[0]; //Set as the first one, by default (will be overwritten if it is not best)

            //For every possible category in that particular index type (e.g. detached, semi-detached, flat, etc)
            for (let j = 0; j < categories.length; j++) {
                let currentCategory = categories[j];
                //Filter to relevant records
                let filteredDemographicsArray = demographicsArray.filter(item => (item.indexGroup === currentIndexGroup && item.category === currentCategory));

                //Aggregate relevant values
                //Sum up total spend for the index/category
                let totalIndexCategorySales = filteredDemographicsArray.reduce((acc, curr) => acc + curr.oaCategorySales, 0);
                //Sum up total (weighted) population for the index/category
                let totalIndexCategoryPopulation = filteredDemographicsArray.reduce((acc, curr) => acc + curr.oaValue, 0);
                //find the spend per head for the index/category
                let indexCategorySpendPerHead = totalIndexCategorySales / totalIndexCategoryPopulation;
                //compare to current "best" spend per head and update when applicable
                if (indexCategorySpendPerHead > bestSpendPerHead) {
                    bestSpendPerHead = indexCategorySpendPerHead;
                    bestCategory = currentCategory;
                }

            }

            //Store the final best category for the index group
            distinctIndexGroupCategories[i].bestCategory = bestCategory;

        }

        //Aggregation B: Aggregate the catchment OAs to the main OA/store-level for the store, and sum up all relevant lines for comparator so the values are at comparator-category level or store-category level
        //Calculate the index
        //Make a tally of how many were over-indexed/under-indexed etc.

        //Best categories we need to calculate indexes for. Assumes all categories have distinct names
        const bestCategories = distinctIndexGroupCategories.map(item => item.bestCategory);

        const selectedStore = selectedStoreSelector;
        const comparatorStores = comparatorStoresSelector.getStores();

        //Filter the demographics info to just the "best" categories, and also split out into selected store OAs and comparator OAs only
        const selectedStoreOAFilteredDemographics = demographicsArray.filter(item =>
            (item.oaID === selectedStore.retailCentreID) && (item.kpmgStoreCategory === selectedStore.kpmgStoreCategory) && (bestCategories.includes(item.category)));
        
        const comparatorFilteredDemographics = [];

        for (let i in comparatorStores) {
            const relevantData = demographicsArray.filter(item =>
                (item.oaID === comparatorStores[i].retailCentreID)
                && (item.kpmgStoreCategory === comparatorStores[i].kpmgStoreCategory)
                && (bestCategories.includes(item.category)));

            comparatorFilteredDemographics.push(...relevantData);
        }

        let underRepresentedCount = 0;
        let overRepresentedCount = 0;
        //Calculating indexes 
        for (let i = 0; i < bestCategories.length; i++) {

            const currentCategory = bestCategories[i];

            const selectedStoreFilteredCategory = selectedStoreOAFilteredDemographics.find(item => item.category === currentCategory);
            const comparatorFilteredCategory = comparatorFilteredDemographics.filter(item => item.category === currentCategory);

            const selectedStoreCategoryValue = selectedStoreFilteredCategory?.oaValue ?? 0;//reduce((acc, curr) => acc + curr.oaValue, 0);
            const selectedStoreCategoryPopulation = selectedStoreFilteredCategory?.oaPopulation ?? 0;//reduce((acc, curr) => acc + curr.oaPopulation, 0); //TODO: Shouldn't ever be 0, but should check for it
            const selectedStorePerc = selectedStoreCategoryPopulation !== 0 ? 100 * (selectedStoreCategoryValue / selectedStoreCategoryPopulation) : 0;

            const comparatorPercentages = comparatorFilteredCategory.map(item => item.oaPopulation !== 0 ? 100 * (item.oaValue / item.oaPopulation) : 0);
            const comparatorPerc = comparatorPercentages.length !== 0 ? median(comparatorPercentages) : 0;

            //Work out the index for this category

            let index = 0;

            //special cases where the comparator percentage is 0
            if (comparatorPerc === 0) {

                if (selectedStorePerc === 0) {
                    index = 0;
                } else {
                    index = 100;
                }

            } else { //standard case
                //Calculate index as (store percentage / comparator percentage) - 1, then multiply by 100 to get percentage.
                index = mathUtils.round(100 * ((selectedStorePerc / comparatorPerc) - 1), 0);
            }

            if (index > 0) {
                overRepresentedCount++;
            } else if (index < 0) {
                underRepresentedCount++;
            }
            //Neither count incremented if index = 0

        }

        //Assign red/amber/green depending on how many are overindexed etc.
        const totalIndexes = bestCategories.length;
        const overRep = (overRepresentedCount / totalIndexes) * 100;
        const underRep = (underRepresentedCount / totalIndexes) * 100;

        if (overRep >= 75) {
            rag.status = "success";
            rag.value = `The majority of the best spending demographics are over-represented within your ${selectedStoreSelector.name} catchment area`;
        } else if (underRep >= 75) {
            rag.status = "error";
            rag.value = `The majority of the best spending demographics are under-represented within your ${selectedStoreSelector.name}  catchment area`;
        } else { //overRep < 75 && underRep < 75
            rag.status = "warning";
            rag.value = `Some of your best spending demographics are over-represented within your ${selectedStoreSelector.name}  catchment area`;
        }

        return rag;
    });

const selectors = {
    ageIndex,
    educationIndex,
    accommodationTypeIndex,
    householdCompIndex,
    householdIncomeIndex,
    tenureIndex,
    childrenIndex,
    representationOfBestDemographics
};

export default selectors;
