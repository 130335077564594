import React from "react";

import { Grid, Typography } from "@material-ui/core";

import Page from "components/customer/Page";

import Data from "./Data";
import Insights from "./Insights";
import SubscriptionPlan from "./SubscriptionPlan";
import UploadData from "./UploadData";
import Users from "./Users";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import { getDashboard, selectIsDashboardLoaded } from "modules/customer/dashboard/dashboardSlice";

const Dashboard: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const isDashboardLoaded = useAppSelector(selectIsDashboardLoaded);
    const isDataProvided = userInfo.isDataProvided;

    React.useEffect(() => {
        dispatch(getDashboard());
    }, [dispatch]);

    return (
        <Page dataCy="pages-customer-dashboard">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        {userInfo.companyDisplayName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Grid container spacing={4}>
                        {isDashboardLoaded && !isDataProvided && (
                            <Grid item xs={12}>
                                <UploadData />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Insights />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <SubscriptionPlan />
                        </Grid>
                        <Grid item xs={12}>
                            <Users />
                        </Grid>
                        <Grid item xs={12}>
                            <Data />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
};

export default Dashboard;
