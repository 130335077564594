import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { useTheme } from "@material-ui/core/styles";

import useColourPalette from "./useColourPalette";

const RAGIndicator = (props) => {
    const {
        clickEnabled,
        loading, error,
        status, label, value, onRagClick
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const displayValue = (() => {
        if (loading) return "Loading...";
        if (error) return "Error!";
        return value;
    })();
    const { color, icon } = (() => {
        switch (status) {
            case "error":
                return {
                    color: colourPalette.RAGIndicators[0],
                    icon: <CancelIcon />
                };
            case "success":
                return {
                    color: colourPalette.RAGIndicators[2],
                    icon: <CheckCircleIcon />
                };
            case "warning":
                return {
                    color: colourPalette.RAGIndicators[1],
                    icon: <ErrorIcon />
                };
            case "noData":
                return {
                    color: theme.palette.text.disabled,
                    icon: <InfoIcon />
                };
            case "info":
            default:
                return {
                    color: colourPalette.RAGIndicators[3],
                    icon: <InfoIcon />
                };
        }
    })();

    const handleClick = () => {
        if (clickEnabled) {
            onRagClick();
        }
    };

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            paddingX={1.5}
            paddingY={1.25}
            borderRadius={6}
            border={1}
            borderColor={color}
            style={{ cursor: clickEnabled ? "pointer" : "default" }}
            onClick={handleClick}
            data-cy="rag-indicator"
        >
            <Box display="flex" alignItems="center" color={color} paddingRight={1.5}>
                {icon}
            </Box>
            <Typography variant="h6" component="div">
                <span style={{ color: color }}>
                    {label}&nbsp;&nbsp;
                </span>
                <Typography variant="subtitle1" component="span">
                    {displayValue}
                </Typography>
            </Typography>
        </Box>
    );
};

RAGIndicator.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(["error", "info", "success", "warning", "noData"]).isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onRagClick: PropTypes.func.isRequired,
    clickEnabled: PropTypes.bool.isRequired
};

RAGIndicator.defaultProps = {
    loading: false,
    error: false,
    status: "info",
    clickEnabled: false,
    onRagClick: () => {}
};

export default RAGIndicator;
