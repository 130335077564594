import React from "react";

import { Box, Grid } from "@material-ui/core";

import MarketCategoriesFilter from "./MarketCategoriesFilter";

const Filter: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MarketCategoriesFilter />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filter;
