import React from "react";
import _ from "lodash";

import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ChapterAlignment from "pages/customer/tools/location/filters/targets/ChapterAlignment";

import CatchmentSpend from "./CatchmentSpend";
import SpendCategories from "./SpendCategories";

import {
    selectCandidateTarget,
    selectCandidateTargetSpendCategories,
    selectDefaultTarget,
    selectDefaultTargetSpendCategories,
    setCandidateTarget,
    setCandidateTargetSpendCategories
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledDivider = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Divider);

const Spend: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const defaultTarget = useAppSelector(selectDefaultTarget);
    const candidateTargetSpendCategories = useAppSelector(selectCandidateTargetSpendCategories);
    const candidateTargetSpendCategoriesIds = candidateTargetSpendCategories.map(spendCategory => spendCategory.id);
    const defaultTargetSpendCategories = useAppSelector(selectDefaultTargetSpendCategories);
    const defaultTargetSpendCategoriesIds = defaultTargetSpendCategories.map(spendCategory => spendCategory.id);
    const resetButtonDisabled = !candidateTarget
        || !defaultTarget
        || (_.isEqual(candidateTargetSpendCategoriesIds.sort(), defaultTargetSpendCategoriesIds.sort())
            && candidateTarget.spend === defaultTarget.spend);

    const handleResetButtonClick = () => {
        if (candidateTarget && defaultTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                spend: defaultTarget.spend
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
        dispatch(setCandidateTargetSpendCategories(defaultTargetSpendCategories));
    };

    const handleSwitchChange = () => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                useSpend: !candidateTarget.useSpend
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <ChapterAlignment
            name="Spend"
            disabled={!candidateTarget.useSpend}
            resetButtonDisabled={resetButtonDisabled}
            onResetButtonClick={handleResetButtonClick}
            onSwitchChange={handleSwitchChange}
        >
            <SpendCategories disabled={!candidateTarget.useSpend} />
            <StyledDivider />
            <CatchmentSpend />
        </ChapterAlignment>
    );
};

export default Spend;
