import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Explore yearly spending and spend-per-head figures for your selected location’s catchment area. Determine
            what products your new store should sell by analysing the effect of different market categories on spending.
        </>
    );
};

export default Subtitle;
