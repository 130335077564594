import React from "react";

const ContributionGrowthSubtitle: React.FC = () => {
    return (
        <>
            Find out what has been driving your contribution growth over the last twelve months. Is your company
            dependent on unsustainable sources of growth?
        </>
    );
};

export default ContributionGrowthSubtitle;
