import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartBase from "./ChartBase";
import useColourPalette from "./useColourPalette";

const BeeSwarm = (props) => {
    const { loading, error, options: customOptions, make2D, useDefaultPlotBands } = props;
    const colourPalette = useColourPalette();
    const RAGChartZones = colourPalette.RAGChartZones;

    const set2DData = (chart) => {
        //Creates a beeswarm type chart effect with a 1D scatter series by looping through 
        //points, then on each point loop through all other points looking
        //for collisions. If a collision is found with a point, then move the point that
        //collides with it up or down the equivalent of the diameter of the points
        let points = [];

        let i = 0;
        for (i = 0; i < chart.series.length; i++) {
            points = points.concat((chart.series[i].points));
        }

        //Assign y values and redraw in case not already assigned
        points.forEach(function (point) {
            point.update({y: 0}, false);
        });
        chart.redraw();

        
        const diameter = points[0]?.graphic?.width;  //Assumes same diameter for all points
        let flag = true;

        //Don't do anything if data not properly loaded
        if (points === undefined || diameter === undefined) {
            return;
        }

        //Assign new variables for reference
        points.forEach(function (point) {
            point.translateCount = 0;
            point.translateDirection = undefined;
            point.newPos = {
                yPos: point.plotY,
                yVal: point.y
            };
        });

        const checkOverlap = (point1, point2) => {
            const distanceX = point1.plotX - point2.plotX;
            const distanceY = point1.newPos.yPos - point2.newPos.yPos;
            const distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));
            return distance < diameter;
        };

        //Loop through points and check for overlap between all other points, then move
        //when there is an overlap by changing the y value to one marker radius away
        let j = 0;
        while (flag) {
            flag = false;
            for (i = 0; i < points.length; i++) {
                const point = points[i];
                for (j = 0; j < points.length; j++) {
                    const otherPoint = points[j];
                    if (i !== j && checkOverlap(point, otherPoint)) {
                        flag = true;
                        otherPoint.translateCount++;
                        if (otherPoint.translateCount % 2 === 0) {  //Shift below centre line
                            otherPoint.newPos.yPos -= otherPoint.translateCount * diameter;
                        } else {  //Shift above centre line
                            otherPoint.newPos.yPos += otherPoint.translateCount * diameter;
                        }

                        //Set y value to be equivalent to shifted pixel location
                        otherPoint.newPos.yVal = chart.yAxis[0].toValue(otherPoint.newPos.yPos, true);
                    }
                }
            }
        }

        //Apply new y values
        for (i = 0; i < points.length; i++) {
            points[i].update({ y: points[i].newPos.yVal }, false);
        }

        chart.redraw();

    };

    let calledByRedraw = false;
    let dataLength = 0;

    const staticOptions = {
        chart: {
            zoomType: 'xy',
            height: 200,
            type: 'scatter',
            events: {
                load: function () {
                    if (make2D) {
                        let chart = this;
                        dataLength = chart?.series[0]?.xData?.length;
                        if (dataLength > 0) {
                            calledByRedraw = true;
                            chart.redraw();
                            set2DData(chart);
                            calledByRedraw = false;
                        }
                    }
                },
                redraw: function () {
                    if (make2D && !calledByRedraw) {
                        calledByRedraw = true;

                        set2DData(this);

                        calledByRedraw = false;
                    }
                }
            }
        },

        legend: {
            itemStyle: {
                color: 'white'
            }
        },

        credits: {
            enabled: false
        },

        xAxis: {
            plotBands: [],
            title: {
                enabled: true,
                text: '',
            }
        },

        yAxis: {
            max: 1,
            min: -1,
            visible: false
        },

        plotOptions: {
            scatter: {
                marker: {
                    lineWidth: 0,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    },
                }
            }
        },

        series: [{
            color: colourPalette.comparators[0]

        },
        {
            marker: {
                symbol: 'circle',
                lineWidth: 2,
                lineColor: colourPalette.comparators[1],
                fillColor: 'transparent'
            }
        }
        ]
    };

    if (useDefaultPlotBands) {
        staticOptions.xAxis.plotBands = [
            {
                color: RAGChartZones[0],
                label: {
                    style: {
                        color: '#FFFFFF',
                    },
                    text: '', x: 0, y: -1
                }
            },
            {
                color: RAGChartZones[1],
                label: {
                    style: {
                        color: '#FFFFFF',
                    },
                    text: '', x: 0, y: -1
                }
            },
            {
                color: RAGChartZones[2],
                label: {
                    style: {
                        color: '#FFFFFF',
                    },
                    text: '', x: 0, y: -1
                }
            }
        ];
    }

    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="bee-swarm-chart" />
    );
};

BeeSwarm.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    make2D: PropTypes.bool,
    useDefaultPlotBands: PropTypes.bool
};

BeeSwarm.defaultProps = {
    loading: false,
    error: false,
    make2D: true,
    useDefaultPlotBands: true
};

export default BeeSwarm;
