import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Popup } from "react-map-gl";
import PropTypes from "prop-types";

import Spacer from "components/Spacer";
import useColourPalette from "components/visuals/useColourPalette";

import numberFormatter from "utils/numberFormatter";
import dateUtils from "utils/dateUtils";

const StorePopup = (props) => {
    const { selectedStore, store } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const popupMinWidth = theme.spacing(25);
    const sizeDifference = (store?.id === selectedStore?.id)
        ? null
        : (selectedStore?.sizeInSqft ?? 0) - (store?.sizeInSqft ?? 0);
    const { symbol, colour } = (() => {
        if (sizeDifference === null) return { symbol: null, colour: null };
        if (sizeDifference > 0) return { symbol: "+", colour: colourPalette.RAGIndicators[0] };
        if (sizeDifference < 0) return { symbol: "", colour: colourPalette.RAGIndicators[2] };
        return { symbol: "", colour: colourPalette.RAGIndicators[1] };
    })();

    if (!store || !selectedStore) {
        return null;
    }

    return (
        <Popup
            latitude={store.latitude}
            longitude={store.longitude}
            closeButton={false}
            anchor="bottom">
            <Box minWidth={popupMinWidth}>
                <Box>
                    <Typography variant="body1">{store.name}</Typography>
                    <Typography variant="body2" style={{ opacity: 0.6 }}>{store.type}</Typography>
                </Box>
                <Box paddingTop={1} display="flex">
                    <Typography variant="body2">Opened&nbsp;&nbsp;</Typography>
                    <Spacer />
                    <Typography variant="body1">{dateUtils.dateUTC(store.openedAt).toLocaleDateString()}</Typography>
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">
                    <Typography variant="body2">Size&nbsp;&nbsp;</Typography>
                    <Spacer />
                    <Box>
                        <Typography variant="body1" align="right" style={{ color: colour }}>{numberFormatter.toDecimalPlaces(store.sizeInSqft, 1)} sqft</Typography>
                        {sizeDifference !== null && (
                            <Typography variant="body1" align="right" style={{ color: colour }}>({symbol}{numberFormatter.toDecimalPlaces(sizeDifference, 1)} sqft)</Typography>
                        )}
                    </Box>
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">
                    <Typography variant="body2">Distance to <br /> hotspot</Typography>
                    <Spacer />
                    <Typography variant="body1">{numberFormatter.toDecimalPlaces(store.distanceToHotspot, 1)} km</Typography>
                </Box>
            </Box>
        </Popup>
    );
};

StorePopup.propTypes = {
    selectedStore: PropTypes.object,
    store: PropTypes.object
};

export default StorePopup;
