import React from "react";

import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearQuestion,
    deleteQuestion,
    getQuestion,
    hideDeleteQuestion,
    selectQuestion,
    selectDeleteQuestionVisibility,
    showDeleteQuestion
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteQuestionProps {
    categoryId: string;
    questionId: string;
}

const DeleteQuestion: React.FC<DeleteQuestionProps> = (props) => {
    const { categoryId, questionId } = props;
    const dispatch = useAppDispatch();
    const question = useAppSelector(selectQuestion);
    const deleteQuestionVisibility = useAppSelector(selectDeleteQuestionVisibility);
    const show = (deleteQuestionVisibility.isVisible) && (questionId === deleteQuestionVisibility.questionId);

    React.useEffect(() => {
        if (show) {
            dispatch(getQuestion(categoryId, questionId));
        }
    }, [dispatch, show, categoryId, questionId]);

    const handleDeleteQuestionClick = () => {
        dispatch(showDeleteQuestion(questionId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteQuestion(categoryId));
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteQuestion());
        dispatch(clearQuestion());
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteQuestionClick}>
                    <DeleteRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h1" component="div" color="error" gutterBottom>
                            <ErrorOutlineRoundedIcon fontSize="inherit" color="inherit" />
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                            Are you sure you want to delete this question?
                        </Typography>
                    </>
                }
                titleAlign="center"
                content={
                    <Typography variant="subtitle1" component="div">
                        {question.question}
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button variant="outlined" onClick={handleCancelClick}>
                            No, cancel
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={handleConfirmClick}>
                            Yes, delete
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default DeleteQuestion;
