import React from "react";

import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RadarChart from "./RadarChart";

import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%"
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const Overview: React.FC = () => {
    const target = useAppSelector(selectTarget);
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const retailCentre = pinnedLocation?.retailCentre;
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpend = featureFlags.enableCustomerToolsLocationSpendNew;

    if (!retailCentre || !target) {
        return null;
    }

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <RadarChart retailCentre={retailCentre} target={target} enableSpend={enableSpend} />
            </StyledCardContent>
            <CardActions>
                <Grid container>
                    <Grid item xs={7}>
                        <Typography variant="subtitle1" component="div">
                            Near {pinnedLocation?.address}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        {/* @ts-ignore */}
                        <StyledTypography variant="body1" component="div" align="right">
                            Total alignment score:
                        </StyledTypography>
                        <Typography variant="h5" component="div" align="right">
                            {numberFormatter.toDecimalPlaces(retailCentre.overallScore, 1)}/10.0
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </StyledCard>
    );
};

export default Overview;
