import React from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {
    selectStoresSort,
    setStoresSort,
    StoreSortField
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import { SortDirection } from "utils/sortUtils";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(25)
    }
}))(Menu);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

interface SortProps {
    enableSpend: boolean
}

const Sort: React.FC<SortProps> = (props) => {
    const { enableSpend } = props;
    const dispatch = useAppDispatch();
    const storesSort = useAppSelector(selectStoresSort);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSortClick = (field: StoreSortField) => () => {
        let direction = SortDirection.ASC;
        if (storesSort.field === field) {
            direction = storesSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newStoresSort = { ...storesSort, field, direction };
        dispatch(setStoresSort(newStoresSort));
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                color="default"
                disableElevation
                startIcon={<ArrowDownwardIcon />}
                onClick={handleClick}
                data-cy="btn-sort"
            >
                Sort
            </Button>
            <StyledMenu
                open={open}
                onClose={handleClose}
                variant="menu"
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Revenue)} data-current={storesSort.field === StoreSortField.Revenue}>
                    <Typography variant="body1" component="div">
                        Revenue
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Revenue || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Name)} data-current={storesSort.field === StoreSortField.Name}>
                    <Typography variant="body1" component="div">
                        Store name
                    </Typography>
                    <Spacer />
                    <Typography variant="body1" component="div">
                        {storesSort.field !== StoreSortField.Name || storesSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                    </Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Age)} data-current={storesSort.field === StoreSortField.Age}>
                    <Typography variant="body1" component="div">
                        Age
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Age || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Urbanicity)} data-current={storesSort.field === StoreSortField.Urbanicity}>
                    <Typography variant="body1" component="div">
                        Urbanicity
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Urbanicity || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Affluence)} data-current={storesSort.field === StoreSortField.Affluence}>
                    <Typography variant="body1" component="div">
                        Affluence
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Affluence || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Diversity)} data-current={storesSort.field === StoreSortField.Diversity}>
                    <Typography variant="body1" component="div">
                        Diversity
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Diversity || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Children)} data-current={storesSort.field === StoreSortField.Children}>
                    <Typography variant="body1" component="div">
                        Children
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Children || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                {enableSpend && (
                    <StyledMenuItem onClick={handleSortClick(StoreSortField.Spend)} data-current={storesSort.field === StoreSortField.Spend}>
                        <Typography variant="body1" component="div">
                            Spend
                        </Typography>
                        <Spacer />
                        {storesSort.field !== StoreSortField.Spend || storesSort.direction === SortDirection.ASC ? (
                            <ArrowUpwardIcon fontSize="small" />
                        ) : (
                            <ArrowDownwardIcon fontSize="small" />
                        )}
                    </StyledMenuItem>
                )}
                <StyledMenuItem onClick={handleSortClick(StoreSortField.AreaHealth)} data-current={storesSort.field === StoreSortField.AreaHealth}>
                    <Typography variant="body1" component="div">
                        Area health
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.AreaHealth || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Footfall)} data-current={storesSort.field === StoreSortField.Footfall}>
                    <Typography variant="body1" component="div">
                        Footfall
                    </Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Footfall || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
};

export default Sort;
