import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartStockBase from "./ChartStockBase";

import numberFormatter from "utils/numberFormatter";

const StockBar = (props) => {
    const { dataCy, loading, error, options: customOptions } = props;

    const xAxisSize = (noPoints) => {
        return Math.min(noPoints-1, 5);
    };

    const drillChanges = (chart, e) => {
        let data = e.seriesOptions.data;
        
        chart.xAxis[0].setCategories(data.map(row => row.name), true);
        noPointsGlobal = data.length;
    };
      
    let noPointsPrev = 0;
    let noPointsGlobal = 0;
    let calledByRedraw = false;

    const staticOptions = {
        chart: {
            type: "bar",
            events: {
                load: function() {
                    let chart = this;
                    let xAxis = chart.xAxis[0];

                    xAxis.setCategories(chart.series[0].data.map(row => row.name), false);
                    noPointsGlobal = xAxis.series[0].getValidPoints().length;
                    this.redraw();
                },
                  drilldown: function (e) {
                    drillChanges(this,e);
                },
                  drillup: function (e) {
                    drillChanges(this,e);
                },
                redraw: function () {
                    if (!calledByRedraw && noPointsGlobal !== noPointsPrev) {
                      calledByRedraw = true;
                      let xAxis = this.xAxis[0];
                    if (xAxis.getExtremes().max !== xAxisSize(noPointsGlobal)) {
                        xAxis.setExtremes(0, xAxisSize(noPointsGlobal), true);
                    }
                    noPointsPrev = noPointsGlobal;
                      calledByRedraw = false;
                  }
                }
            }
        },
        xAxis: {
            title: {
                text: null
            },
            type: 'category',
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            endOnTick: true,
            showLastLabel: true,
            scrollbar: {
                enabled: true
            },
            labels: {
                useHTML: true,
                style:{
                    width:'70px',
                    whiteSpace:'normal'
                },
                formatter: function () {
                    let returnStr = "";
                    if (this.value.length <= 30) {
                        returnStr = this.value;
                    } else {
                        returnStr =  String(this.value)?.substring(this[this.length-1]); 
                    }
                    return '<div style="word-wrap: break-word;word-break: break-all;width:70px">' + returnStr + '</div>';
                }
            }
        },
        yAxis: {
            labels: {
            x: 10,
            y: 15
            },
            gridLineWidth: 1,
            minorGridLineWidth: 0,
            lineWidth: 0,
            title: false,
            visible: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                borderRadiusTopLeft: '20%',
                borderRadiusTopRight: '20%'
            },
            bar: {
                maxPointWidth: 34,
                minPointWidth: 16
            },
            column: {
                maxPointWidth: 34,
                minPointWidth: 16
            }
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            formatter() {
                return '<b>' + this.series.name + '</b><br/>' + numberFormatter.toGBP(this.y);
            }
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartStockBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

StockBar.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

StockBar.defaultProps = {
    dataCy: "bar-chart",
    loading: false,
    error: false
};

export default StockBar;
