import { createSelector } from "reselect";

const personalInfo = (state) => {
    return state.customer.account.settings.personalInfo;
};

const editPersonalInfoVisibility = (state) => {
    return state.customer.account.settings.editPersonalInfoVisibility;
};

const canEditPersonalInfo = createSelector(
    personalInfo,
    (personalInfo) => {
        const allFilled = !!personalInfo.firstName
            && !!personalInfo.lastName;

        const hasErrors = !!personalInfo.errors.firstName
            || !!personalInfo.errors.lastName
            || !!personalInfo.errors.phoneNumber;

        return allFilled && !hasErrors;
    }
);

const companyDetails = (state) => {
    return state.customer.account.settings.companyDetails;
};

const editCompanyDetailsVisibility = (state) => {
    return state.customer.account.settings.editCompanyDetailsVisibility;
};

const emailNotifications = (state) => {
    return state.customer.account.settings.emailNotifications;
};

const selectors = {
    personalInfo,
    editPersonalInfoVisibility,
    canEditPersonalInfo,
    companyDetails,
    editCompanyDetailsVisibility,
    emailNotifications
};

export default selectors;
