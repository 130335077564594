import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import StockBar from "components/visuals/StockBar";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const CategoryCMSalesGrowth = () => {
    const dispatch = useDispatch();
    const categoryCMSalesGrowth = useSelector(state => selectors.categoryCMSalesGrowth(state));
    const { loading, error, productCategoryGrowth, drilldownData, currentMonth, pastMonth } = categoryCMSalesGrowth;
    const currentDate = currentMonth ? `${currentMonth[0]} ${currentMonth[1]}` : "latest full month";
    const pastDate = pastMonth ? `${pastMonth[0]} ${pastMonth[1]}` : "past full month";
    productCategoryGrowth.sort((a,b) => {
        return b.y - a.y;
    });

    const options = {
        chart: {
            marginRight: 50
        },
        title: {
            text: `Revenue in ${currentDate} vs ${pastDate}`
        },
        tooltip: {
            formatter() {
                const percentageSign = this.y > 0 ? " +" : " ";
                return '<b>' + this.key + '</b><br/>Growth (%)' + percentageSign + numberFormatter.toPercentage(this.y, true, 0);
            }
        },
        series: [
            {
                name: "Product Categories",
                data: productCategoryGrowth
            }],
        drilldown: {
            series: drilldownData
        },
        yAxis: {
            labels: {
                formatter: function() {return numberFormatter.toPercentage(this.value, true, 0);}
            }
        }
    };

    React.useEffect(() => {
        dispatch(operations.getCategoryCMSalesGrowth());
    }, [dispatch]);

    return (
        <Box data-cy="category-cm-sales-growth">
            <StockBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CategoryCMSalesGrowth;
