import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Button, Card, CardActions, CardContent, CardMedia, Fade, Grid, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import Spacer from "components/Spacer";

import quoteIcon from "assets/images/quote-icon.svg";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledImageCard = withStyles(() => ({
    root: {
        position: "absolute",
        top: 0,
        backgroundColor: "transparent",
        zIndex: (props) => props["data-selected"] ? 200 : 100
    }
}))(Card);

const StyledReferenceCard = withStyles(() => ({
    root: {
        opacity: 0
    }
}))(Card);

const StyledQuoteCard = withStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: theme.spacing(54),
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            minHeight: theme.spacing(45),
            padding: theme.spacing(2)
        }
    }
}))(Card);

const StyledQuoteCardActions = withStyles(theme => ({
    root: {
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    }
}))(CardActions);

const PowerfulAlgorithms = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";
    const [selectedQuote, setSelectedQuote] = React.useState(0);
    const quotes = [
        "Dash gives me all the information needed to meet my existing clients' needs and better target new customers.",
        "I like the simplicity. I can easily see which parts of my business are doing well and which aren't.",
        "Dash gives me valuable insight into current and future product performance and the actions that will improve product category management."
    ];
    const [selectedImage, setSelectedImage] = React.useState(0);
    const images = [{
        url: "/content/landing/home/powerful-algorithms/area-health.png",
        alt: "area health"
    }, {
        url: "/content/landing/home/powerful-algorithms/demand.png",
        alt: "demand"
    }, {
        url: "/content/landing/home/powerful-algorithms/competition.png",
        alt: "competition"
    }];

    const handleClick = () => {
        dispatch(trackEvent("Home page", "Powerful Algorithms - View features button click", "Powerful Algorithms - View features button"));
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImage(prevSelectedImage => (prevSelectedImage + 1) % 3);
        }, 4500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <PageSection
            bgcolor={theme.palette.common.white}
            dataCy="pages-landing-home-powerful-algorithms"
        >
            <Typography variant={isMobile ? "h4" : "h2"} component="div" align="center" gutterBottom>
                Powerful AI algorithms to analyse your stores, customer buying habits, and evaluate future demand for your products
            </Typography>
            <br />
            <br />
            <br />
            <Box position="relative" align="center">
                <StyledReferenceCard square elevation={0}>
                    <CardMedia component="img" image={images[0].url} alt={images[0].alt} />
                </StyledReferenceCard>
                {images.map((image, index) =>
                    <Fade
                        key={index}
                        in={selectedImage === index}
                        timeout={{
                            enter: 1500,
                            exit: 3000
                        }}
                    >
                        <StyledImageCard square elevation={0} data-selected={selectedImage === index}>
                            <CardMedia component="img" image={image.url} alt={image.alt} />
                        </StyledImageCard>
                    </Fade>
                )}
            </Box>
            <br />
            <br />
            <br />
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledQuoteCard elevation={0}>
                        <CardContent align={align}>
                            <Box width={42} height={35}>
                                <img src={quoteIcon} alt="quote icon" />
                            </Box>
                            <br />
                            <Typography variant={isMobile ? "h4" : "h3"} component="div">
                                {quotes[selectedQuote]}
                            </Typography>
                        </CardContent>
                        <Spacer />
                        <StyledQuoteCardActions>
                            <Typography variant={isMobile ? "body2" : "subtitle1"} component="div">
                                Dash launch survey feedback
                            </Typography>
                            <Spacer />
                            <Box>
                                {quotes.map((quote, index) =>
                                    <IconButton key={index} size="small" onClick={() => setSelectedQuote(index)}>
                                        {index === selectedQuote ? (
                                            <FiberManualRecordIcon fontSize={isMobile ? "medium" : "small"} />
                                        ) : (
                                            <FiberManualRecordOutlinedIcon fontSize={isMobile ? "medium" : "small"} />
                                        )}
                                    </IconButton>
                                )}
                            </Box>
                        </StyledQuoteCardActions>
                    </StyledQuoteCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align}>
                        Data driven growth
                    </Typography>
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Whether it's deciding what products to stock, where you should open a new store
                        or anticipating future demand understanding and analysing your data is the key to growth.
                    </Typography>
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Dash is a one-stop-shop for retail analytics that cuts through the noise and gives you
                        the information required to make data-led decisions and grow your business with confidence.
                    </Typography>
                    <br />
                    <br />
                    <Box align={align}>
                        <Button
                            component={NavLink}
                            to="/features"
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleClick}
                            disableElevation
                            data-cy="btn-view-features"
                        >
                            View features
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default PowerfulAlgorithms;
