import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import { operations, selectors } from "modules/customer/account/users";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

const DeleteUser = (props) => {
    const { id } = props;
    const dispatch = useDispatch();
    const user = useSelector(state => selectors.user(state));
    const deleteUserVisibility = useSelector(state => selectors.deleteUserVisibility(state));
    const show = (deleteUserVisibility.isVisible) && (id === deleteUserVisibility.id);

    React.useEffect(() => {
        if (show) {
            dispatch(operations.getUser(id));
        }
    }, [dispatch, show, id]);

    const handleDeleteUserClick = () => {
        dispatch(operations.showDeleteUser(id));
    };

    const handleConfirmClick = () => {
        dispatch(operations.deleteUser());
    };

    const handleCancelClick = () => {
        dispatch(operations.hideDeleteUser());
        dispatch(operations.clearUser());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteUserClick}>
                    <DeleteIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h1" component="div" color="error" gutterBottom>
                            <ErrorOutlineIcon fontSize="inherit" color="inherit" />
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                            Are you sure you want to remove this user?
                        </Typography>
                    </>
                    
                }
                titleAlign="center"
                content={
                    <Typography variant="subtitle1" component="div">
                        {user.firstName} {user.lastName} will no longer have access to Dash
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button
                            variant="outlined"
                            size="large"
                            color="default"
                            disableElevation
                            onClick={handleCancelClick}
                        >
                            No, cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disableElevation
                            onClick={handleConfirmClick}
                        >
                            Yes, remove
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

DeleteUser.propTypes = {
    id: PropTypes.string.isRequired
};

export default DeleteUser;
