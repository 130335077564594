import React from "react";

import { Box, Grid } from "@material-ui/core";
import RAGIndicatorsGroupNew from "components/visuals/RAGIndicatorsGroupNew";

import { useAppSelector } from "store";
import { selectRagIndicators } from "modules/customer/insights/portfolioNew/overview/overviewSlice";

const Recommendations: React.FC = () => {
    const ragIndicators = useAppSelector(selectRagIndicators);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box data-cy="overview-recommendations">
                    <RAGIndicatorsGroupNew ragIndicators={ragIndicators} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Recommendations;
