import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectDemographicAlignment } from "modules/customer/tools/location/demographics/demographicsSlice";

const DemographicAlignment: React.FC = () => {
    const demographicAlignment = useAppSelector(selectDemographicAlignment);
    return (
        <RAGIndicatorNew ragIndicator={demographicAlignment} />
    );
};

export default DemographicAlignment;
