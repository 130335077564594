import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Whilst a change in the number of retailers indicates an area's health, you also need to evaluate whether
            that change is relevant for you or not. Dash has broken down the openings and closures by market category.
            This breakdown in market categories allows you to get a better feel for the current retailers in the local
            area and how they're changing over time.
        </>
    );
};

export default Subtitle;
