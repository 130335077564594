import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { median } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator } from "domain/ragIndicator";
import {
    selectShortTermNetOpenings,
    selectLongTermNetOpenings,
    selectComplementaryMarketCategories,
    selectNonComplementaryMarketCategories
} from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import {
    selectDistanceToHotspot,
    selectSizeRelativeToDirectCompetitors,
    selectSupplyAndDemandCategorisation
} from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import {
    selectRevenueLessPropertyCostsPerSquareFoot,
    selectSalesPerPoundPropCostCategorisation,
    selectPayrollCostsPerSqftCategorisation,
    selectRevenuePerHeadCategorisation,
    selectRevenuePerPoundOfStaffCostCategorisation
} from "modules/customer/insights/portfolioNew/drivers/driversSlice";
import { 
    selectFootfallPositioning,
    selectFootfallVsComparator
} from "modules/customer/insights/portfolioNew/footfall/footfallSlice";
import {
    selectComparator,
    selectStore,
    selectSubchaptersIds
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import {
    selectGrossProfitTrend,
    selectRollingGrossProfit,
    selectProfitabilityEvaluation
} from "modules/customer/insights/portfolioNew/profit/profitSlice";
import {
    selectHistoricRevenueGrowth,
    selectForecastRevenueGrowth,
    selectRankedGrowthClassification,
    selectStoreDependencyOnProductCategory,
    selectStoreBestProductCategory,
    selectStoreVsComparatorProductCategoryGrowth
} from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import { openStreetView as mapOpenStreetView } from "modules/helpers/maps/mapsSlice";
import { RootState } from "store";
import { scrollIntoViewById } from "utils/scrollUtils";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";
import { logError } from "modules/helpers/logger/loggerSlice";


export enum ComparatorStoresSortField {
    StoreName,
    Revenue,
    GrossProfit,
    RevenuePerSqft,
    SimilarityScore
}

interface LoadOverviewResponse {
    defaultComparatorStoresSort: ComparatorStoresSort,
}

interface ComparatorStoresSort {
    field: ComparatorStoresSortField,
    direction: SortDirection
}

interface OverviewState {
    isLoading: boolean,
    hasErrors: boolean,
    comparatorStoresSort: ComparatorStoresSort
}

const initialState: OverviewState = {
    isLoading: false,
    hasErrors: false,
    comparatorStoresSort: {
        field: ComparatorStoresSortField.StoreName,
        direction: SortDirection.ASC
    },
};

const overviewSlice = createSlice({
    name: "customer/insights/portfolioNew/overview",
    initialState,
    reducers: {
        setComparatorStoresSort: (state, action: PayloadAction<ComparatorStoresSort>) => {
            state.comparatorStoresSort = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadOverview.pending, (state: OverviewState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadOverview.rejected, (state: OverviewState) => {
            state.isLoading = false;
            state.hasErrors = true;
        });
        builder.addCase(loadOverview.fulfilled, (state: OverviewState, action: PayloadAction<LoadOverviewResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.comparatorStoresSort = action.payload.defaultComparatorStoresSort;
        });
    }
});

export const {
    setComparatorStoresSort
} = overviewSlice.actions;

export const loadOverview = createAppAsyncThunk(
    "customer/insights/portfolioNew/overview/loadOverview",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const comparator = selectComparator(state);
            const comparatorStores = comparator?.getStores() ?? [];

            const sortBySimilarityScore = comparatorStores.some(store => store.similarityScore);
            const defaultComparatorStoresSort = sortBySimilarityScore ? {
                field: ComparatorStoresSortField.SimilarityScore,
                direction: SortDirection.DESC
            } : { 
                field: ComparatorStoresSortField.StoreName,
                direction: SortDirection.ASC
            };
            
            return {
                defaultComparatorStoresSort
            };
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Overview.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const selectComparatorStoresSort = (state: RootState) => {
    return state.customer.insights.portfolioNew.overview.comparatorStoresSort;
};

export const selectStoreVsComparatorPerformance = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (store, comparator) => {
        if (!store || !comparator) {
            return null;
        }

        const performance = {
            storeValues: {
                revenue: 0,
                profit: 0,
                areaHealth: 0,
                catchment: 0,
                competition: 0,
                footfall: 0
            },
            comparatorValues: {
                revenue: 0,
                profit: 0,
                areaHealth: 0,
                catchment: 0,
                competition: 0,
                footfall: 0
            }
        };

        performance.storeValues.revenue = store.revenueScore;
        performance.storeValues.profit = store.profitScore;
        performance.storeValues.areaHealth = store.areaHealthScore;
        performance.storeValues.catchment = store.catchmentScore;
        performance.storeValues.competition = store.competitionScore;
        performance.storeValues.footfall = store.footfallScore;

        const comparatorStores = comparator?.getStores();
        performance.comparatorValues.revenue = median(comparatorStores.map(item => item.revenueScore));
        performance.comparatorValues.profit = median(comparatorStores.map(item => item.profitScore));
        performance.comparatorValues.areaHealth = median(comparatorStores.map(item => item.areaHealthScore));
        performance.comparatorValues.catchment = median(comparatorStores.map(item => item.catchmentScore));
        performance.comparatorValues.competition = median(comparatorStores.map(item => item.competitionScore));
        performance.comparatorValues.footfall = median(comparatorStores.map(item => item.footfallScore));

        return {
            loading: false,
            error: false,
            store: performance.storeValues,
            comparator: performance.comparatorValues
        };
    }
);

export const openStreetView = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const selectedStore = selectStore(state);
    const latitude = selectedStore?.latitude ?? 0;
    const longitude = selectedStore?.longitude ?? 0;
    dispatch(mapOpenStreetView(latitude, longitude));
};

export const selectRagIndicators = createSelector(
    (state: RootState) => selectSubchaptersIds(state),
    selectHistoricRevenueGrowth,
    selectForecastRevenueGrowth,
    selectRankedGrowthClassification,
    selectStoreDependencyOnProductCategory,
    selectStoreBestProductCategory,
    selectStoreVsComparatorProductCategoryGrowth,
    selectGrossProfitTrend,
    selectRollingGrossProfit,
    selectProfitabilityEvaluation,
    selectRevenueLessPropertyCostsPerSquareFoot,
    selectSalesPerPoundPropCostCategorisation,
    selectPayrollCostsPerSqftCategorisation,
    selectRevenuePerHeadCategorisation,
    selectRevenuePerPoundOfStaffCostCategorisation,
    selectDistanceToHotspot,
    selectSizeRelativeToDirectCompetitors,
    selectSupplyAndDemandCategorisation,
    selectShortTermNetOpenings,
    selectLongTermNetOpenings,
    selectComplementaryMarketCategories,
    selectNonComplementaryMarketCategories,
    selectFootfallVsComparator,
    selectFootfallPositioning,
    (
        subchaptersIds,
        historicRevenueGrowth,
        forecastRevenueGrowth,
        rankedGrowthClassification,
        storeDependencyOnProductCategory,
        storeBestProductCategory,
        storeVsComparatorProductCategoryGrowth,
        grossProfitTrend,
        rollingGrossProfit,
        profitabilityEvaluation,
        revenueLessPropertyCostsPerSquareFoot,
        salesPerPoundPropertyCostCategorisation,
        payrollCostsPerSqftCategorisation,
        revenuePerHeadCategorisation,
        revenuePerPoundOfStaffCostCategorisation,
        distanceToHotpsot,
        sizeRelativeToDirectCompetitors,
        supplyAndDemandCategorisation,
        shortTermNetOpenings,
        longTermNetOpenings,
        complementaryMarketCategories,
        nonComplementaryMarketCategories,
        footfallVsComparator,
        footfallPositioning
    ) => {
        return [
            new RagIndicator(
                historicRevenueGrowth.id,
                historicRevenueGrowth.status,
                historicRevenueGrowth.label,
                historicRevenueGrowth.value,
                historicRevenueGrowth.isLoading,
                historicRevenueGrowth.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.revenue)
            ),
            new RagIndicator(
                forecastRevenueGrowth.id,
                forecastRevenueGrowth.status,
                forecastRevenueGrowth.label,
                forecastRevenueGrowth.value,
                forecastRevenueGrowth.isLoading,
                forecastRevenueGrowth.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.revenue)
            ),
            new RagIndicator(
                rankedGrowthClassification.id,
                rankedGrowthClassification.status,
                rankedGrowthClassification.label,
                rankedGrowthClassification.value,
                rankedGrowthClassification.isLoading,
                rankedGrowthClassification.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.rankedRevenueGrowth)
            ),
            new RagIndicator(
                storeDependencyOnProductCategory.id,
                storeDependencyOnProductCategory.status,
                storeDependencyOnProductCategory.label,
                storeDependencyOnProductCategory.value,
                storeDependencyOnProductCategory.isLoading,
                storeDependencyOnProductCategory.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.productCategoryMix)
            ),
            new RagIndicator(
                storeBestProductCategory.id,
                storeBestProductCategory.status,
                storeBestProductCategory.label,
                storeBestProductCategory.value,
                storeBestProductCategory.isLoading,
                storeBestProductCategory.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.productCategoryMix)
            ),
            new RagIndicator(
                storeVsComparatorProductCategoryGrowth.id,
                storeVsComparatorProductCategoryGrowth.status,
                storeVsComparatorProductCategoryGrowth.label,
                storeVsComparatorProductCategoryGrowth.value,
                storeVsComparatorProductCategoryGrowth.isLoading,
                storeVsComparatorProductCategoryGrowth.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.revenue.productCategoryGrowth)
            ),
            new RagIndicator(
                grossProfitTrend.id,
                grossProfitTrend.status,
                grossProfitTrend.label,
                grossProfitTrend.value,
                grossProfitTrend.isLoading,
                grossProfitTrend.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.profit.grossProfitMargin)
            ),
            new RagIndicator(
                rollingGrossProfit.id,
                rollingGrossProfit.status,
                rollingGrossProfit.label,
                rollingGrossProfit.value,
                rollingGrossProfit.isLoading,
                rollingGrossProfit.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.profit.rankedGrossProfitMargin)
            ),
            new RagIndicator(
                profitabilityEvaluation.id,
                profitabilityEvaluation.status,
                profitabilityEvaluation.label,
                profitabilityEvaluation.value,
                profitabilityEvaluation.isLoading,
                profitabilityEvaluation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.profit.netProfit)
            ),
            new RagIndicator(
                revenueLessPropertyCostsPerSquareFoot.id,
                revenueLessPropertyCostsPerSquareFoot.status,
                revenueLessPropertyCostsPerSquareFoot.label,
                revenueLessPropertyCostsPerSquareFoot.value,
                revenueLessPropertyCostsPerSquareFoot.isLoading,
                revenueLessPropertyCostsPerSquareFoot.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.drivers.storeSize)
            ),
            new RagIndicator(
                salesPerPoundPropertyCostCategorisation.id,
                salesPerPoundPropertyCostCategorisation.status,
                salesPerPoundPropertyCostCategorisation.label,
                salesPerPoundPropertyCostCategorisation.value,
                salesPerPoundPropertyCostCategorisation.isLoading,
                salesPerPoundPropertyCostCategorisation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.drivers.storeSize)
            ),
            new RagIndicator(
                payrollCostsPerSqftCategorisation.id,
                payrollCostsPerSqftCategorisation.status,
                payrollCostsPerSqftCategorisation.label,
                payrollCostsPerSqftCategorisation.value,
                payrollCostsPerSqftCategorisation.isLoading,
                payrollCostsPerSqftCategorisation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.drivers.staffing)
            ),
            new RagIndicator(
                revenuePerHeadCategorisation.id,
                revenuePerHeadCategorisation.status,
                revenuePerHeadCategorisation.label,
                revenuePerHeadCategorisation.value,
                revenuePerHeadCategorisation.isLoading,
                revenuePerHeadCategorisation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.drivers.staffing)
            ),
            new RagIndicator(
                revenuePerPoundOfStaffCostCategorisation.id,
                revenuePerPoundOfStaffCostCategorisation.status,
                revenuePerPoundOfStaffCostCategorisation.label,
                revenuePerPoundOfStaffCostCategorisation.value,
                revenuePerPoundOfStaffCostCategorisation.isLoading,
                revenuePerPoundOfStaffCostCategorisation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.drivers.staffing)
            ),
            new RagIndicator(
                distanceToHotpsot.id,
                distanceToHotpsot.status,
                distanceToHotpsot.label,
                distanceToHotpsot.value,
                distanceToHotpsot.isLoading,
                distanceToHotpsot.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.competition.nearbyCompetition)
            ),
            new RagIndicator(
                sizeRelativeToDirectCompetitors.id,
                sizeRelativeToDirectCompetitors.status,
                sizeRelativeToDirectCompetitors.label,
                sizeRelativeToDirectCompetitors.value,
                sizeRelativeToDirectCompetitors.isLoading,
                sizeRelativeToDirectCompetitors.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.competition.nearbyCompetition)
            ),
            new RagIndicator(
                supplyAndDemandCategorisation.id,
                supplyAndDemandCategorisation.status,
                supplyAndDemandCategorisation.label,
                supplyAndDemandCategorisation.value,
                supplyAndDemandCategorisation.isLoading,
                supplyAndDemandCategorisation.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.competition.supplyAndDemand)
            ),
            new RagIndicator(
                shortTermNetOpenings.id,
                shortTermNetOpenings.status,
                shortTermNetOpenings.label,
                shortTermNetOpenings.value,
                shortTermNetOpenings.isLoading,
                shortTermNetOpenings.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.areaHealth.openingsAndClosures)
            ),
            new RagIndicator(
                longTermNetOpenings.id,
                longTermNetOpenings.status,
                longTermNetOpenings.label,
                longTermNetOpenings.value,
                longTermNetOpenings.isLoading,
                longTermNetOpenings.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.areaHealth.openingsAndClosures)
            ),
            new RagIndicator(
                complementaryMarketCategories.id,
                complementaryMarketCategories.status,
                complementaryMarketCategories.label,
                complementaryMarketCategories.value,
                complementaryMarketCategories.isLoading,
                complementaryMarketCategories.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.areaHealth.storeCategoryBreakdown)
            ),
            new RagIndicator(
                nonComplementaryMarketCategories.id,
                nonComplementaryMarketCategories.status,
                nonComplementaryMarketCategories.label,
                nonComplementaryMarketCategories.value,
                nonComplementaryMarketCategories.isLoading,
                nonComplementaryMarketCategories.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.areaHealth.storeCategoryBreakdown)
            ),
            new RagIndicator(
                footfallVsComparator.id,
                footfallVsComparator.status,
                footfallVsComparator.label,
                footfallVsComparator.value,
                footfallVsComparator.isLoading,
                footfallVsComparator.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.footfall.footfall)
            ),
            new RagIndicator(
                footfallPositioning.id,
                footfallPositioning.status,
                footfallPositioning.label,
                footfallPositioning.value,
                footfallPositioning.isLoading,
                footfallPositioning.hasErrors,
                true,
                () => scrollIntoViewById(subchaptersIds.footfall.footfall)
            ),
        ];
    }
);

export const selectSortedComparatorStores = createSelector(
    (state: RootState) => selectComparator(state),
    selectComparatorStoresSort,
    (comparator, sort) => {
        if (!comparator) {
            return [];
        }

        return comparator.getStores()
            .slice()
            .sort((a, b) => {
                switch (sort.field) {
                    case ComparatorStoresSortField.StoreName:
                        return stringSortExpression(a.name, b.name, sort.direction);
                    case ComparatorStoresSortField.Revenue:
                        return numberSortExpression(a.revenue, b.revenue, sort.direction);
                    case ComparatorStoresSortField.GrossProfit:
                        return numberSortExpression(a.grossProfitMargin, b.grossProfitMargin, sort.direction);
                    case ComparatorStoresSortField.RevenuePerSqft:
                        return numberSortExpression(a.revenuePerSquareFoot, b.revenuePerSquareFoot, sort.direction);
                    case ComparatorStoresSortField.SimilarityScore:
                        return numberSortExpression(a.similarityScore ?? 0, b.similarityScore ?? 0, sort.direction);
                    default:
                        return stringSortExpression(a.name, b.name, SortDirection.ASC);
                }
            });
    }
);

export default overviewSlice;
