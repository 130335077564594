import axios, { Method } from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { updateLatestActivityTime, showLoggedOut } from "modules/auth/authSlice";
import { backdropOn, backdropOff } from "modules/backdrop/backdropSlice";
import { logError, logWarning } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";

export enum ApiResponseStatus {
    Unknown = 0,
    Ok = 200,
    BadRequest = 400,
    Unauthorized = 401,
    NotFound = 404,
    UnprocessableEntity = 422
}

interface ApiResponse {
    status: ApiResponseStatus,
    data: any,
    errorData: any
}

interface ApiState {
}

const initialState: ApiState = {};

const apiSlice = createSlice({
    name: "helpers/api",
    initialState,
    reducers: {}
});

const request = (method: Method, url: string, body: any = {}, showBackdrop: boolean = true): AppThunk<Promise<ApiResponse>> => async (dispatch) => {
    if (showBackdrop) {
        dispatch(backdropOn());
    }
    try {
        const response = await axios.request({ url, method, data: body });
        const data = response?.data;
        dispatch(updateLatestActivityTime());
        return {
            status: ApiResponseStatus.Ok,
            data,
            errorData: undefined
        };
    } catch (error: any) {
        const response = error.response;
        const status = response?.status;
        const errorData = response?.data;
        const apiResponse: ApiResponse = {
            status: ApiResponseStatus.Unknown,
            data: undefined,
            errorData
        };

        const logPayload = {
            method,
            url,
            status,
            errorData
        };

        if (!response || response === "undefined") {
            dispatch(logWarning("Unauthenticated api call.", logPayload));
            dispatch(showLoggedOut());
            apiResponse.status = ApiResponseStatus.Unauthorized;
            return apiResponse;
        }

        dispatch(updateLatestActivityTime());

        if (status === 404) {
            apiResponse.status = ApiResponseStatus.NotFound;
        }

        if (status === 400) {
            apiResponse.status = ApiResponseStatus.BadRequest;
        }

        if (status === 422) {
            apiResponse.status = ApiResponseStatus.UnprocessableEntity;
            const errors = errorData?.errors;
            if (errors) {
                errors.forEach((error: string) => {
                    dispatch(notifyError(error));
                });
            }
        }

        if (apiResponse.status !== ApiResponseStatus.Unknown) {
            dispatch(logWarning("Api call validation error.", logPayload));
        } else {
            dispatch(logError("Api call unexpected error.", logPayload));
            dispatch(notifyError("Something went wrong."));
        }

        return apiResponse;
    } finally {
        if (showBackdrop) {
            dispatch(backdropOff());
        }
    }
};

export const apiGet = (url: string, showBackdrop: boolean = true): AppThunk<Promise<ApiResponse>> => async (dispatch) => {
    return dispatch(request("get", url, {}, showBackdrop));
};

export const apiPost = (url: string, body: any, showBackdrop: boolean = true): AppThunk<Promise<ApiResponse>> => async (dispatch) => {
    return dispatch(request("post", url, body, showBackdrop));
};

export const apiPut = (url: string, body: any, showBackdrop: boolean = true): AppThunk<Promise<ApiResponse>> => async (dispatch) => {
    return dispatch(request("put", url, body, showBackdrop));
};

export const apiDelete = (url: string, body: any = {}, showBackdrop: boolean = true): AppThunk<Promise<ApiResponse>> => async (dispatch) => {
    return dispatch(request("delete", url, body, showBackdrop));
};

export default apiSlice;
