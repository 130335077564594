import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import actions from "./actions";
import dateUtils from "utils/dateUtils";

import { selectStore, selectComparator, selectReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";

const getStoreVsComparatorRevVsComp = () => async (dispatch, getState) => {
    dispatch(actions.getStoreVsComparatorRevVsCompRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.StoreName"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const revenue = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            storeID: item["D_Store.StoreNaturalID"],
            storeName: item["D_Store.StoreName"]
        }));

        const compQuery = {
            dimensions: [
                "F_CompetitionScore.StoreNaturalID",
                "F_CompetitionScore.CompetitionScore"
            ],
            filters: [{
                member: "F_CompetitionScore.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSetComp = await dispatch(cubeLoadExtended(compQuery));

        const compScore = resultSetComp.loadResponses[0].data.map(item => ({
            competitionScore: item["F_CompetitionScore.CompetitionScore"],
            storeID: item["F_CompetitionScore.StoreNaturalID"]
        }));

        const data = revenue.map(current => {
            const compScoreData = compScore.find(item => item.storeID === current.storeID);
            if (!compScoreData) {
                return {
                    store: current.storeID,
                    storeName: current.storeName,
                    sales: current.sales,
                    revenueVsComp: 0,
                    rank: 0
                };
            }
            return {
                store: current.storeID,
                storeName: current.storeName,
                sales: current.sales,
                revenueVsComp: (compScoreData.competitionScore === 0) ? 0 : current.sales / compScoreData.competitionScore,
                rank: 0
            };
        });

        //Rank Each store based on revenueVsComp
        const dataSorted = data.sort((a, b) => (a.revenueVsComp < b.revenueVsComp ? 1 : -1));

        for (let i = 0; i < dataSorted.length; i++) {
            dataSorted[i].rank = i + 1;
        }

        const dataStore = dataSorted.find(item => item.store === selectedStoreID) || {};

        const dataComparator = dataSorted.filter(item => comparatorStoresIDs.includes(item.store));

        dispatch(actions.getStoreVsComparatorRevVsCompSuccess(dataStore, dataComparator));
    }
    catch (error) {
        dispatch(actions.getStoreVsComparatorRevVsCompFailure());
        dispatch(logError("Error loading StoreVsComparatorRevVsComp.", error));
    }
};

const getRevenueVsCompetitionScore = () => async (dispatch, getState) => {
    dispatch(actions.getRevenueVsCompetitionScoreRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const revenue = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            storeID: item["D_Store.StoreNaturalID"]
        }));

        const compQuery = {
            dimensions: [
                "F_CompetitionScore.StoreNaturalID",
                "F_CompetitionScore.CompetitionScore"
            ],
            filters: [{
                member: "F_CompetitionScore.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSetComp = await dispatch(cubeLoadExtended(compQuery));

        const compScore = resultSetComp.loadResponses[0].data.map(item => ({
            competitionScore: item["F_CompetitionScore.CompetitionScore"],
            storeID: item["F_CompetitionScore.StoreNaturalID"]
        }));

        const data = revenue.map(current => {
            const compScoreData = compScore.find(item => item.OAID === current.OAID);
            if (!compScoreData) {
                return {
                    store: current.storeID,
                    sales: current.sales,
                    revenueVsComp: 0,
                    rank: 0
                };
            }
            return {
                store: current.storeID,
                sales: current.sales,
                revenueVsComp: (compScoreData.competitionScore === 0) ? 0 : current.sales / compScoreData.competitionScore,
                rank: 0
            };
        });

        //Rank Each store based on revenueVsComp
        const dataSorted = data.sort((a, b) => (a.revenueVsComp < b.revenueVsComp ? 1 : -1));

        for (let i = 0; i < dataSorted.length; i++) {
            dataSorted[i].rank = i + 1;
        }

        const dataStore = dataSorted.find(item => item.store === selectedStoreID);

        const denominator = data.length;

        let status = "";
        let value = "";

        const topThirdPercentile = 2 / 3;
        const bottomThirdPercentile = 1 / 3;

        const selectedPercentile = dataStore.rank / denominator;

        if (selectedPercentile < topThirdPercentile) {
            status = "success";
            value = `${selectedStoreSelector.name} is a top performer for Revenue vs Competition Score in the latest month`;
        } else if (selectedPercentile < bottomThirdPercentile) {
            status = "error";
            value = `${selectedStoreSelector.name} is under performing for Revenue vs Competition Score in the latest month`;
        } else {
            status = "warning";
            value = `${selectedStoreSelector.name} is a average for Revenue vs Competition Score in the latest month`;
        }
        dispatch(actions.getRevenueVsCompetitionScoreSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getRevenueVsCompetitionScoreFailure());
        dispatch(logError("Error loading RevenueVsCompetitionScore.", error));
    }
};

const operations = {
    getStoreVsComparatorRevVsComp,
    getRevenueVsCompetitionScore
};

export default operations;
