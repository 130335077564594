import { combineReducers } from "redux";

import insights from "./insights";
import tools from "./tools";
import help from "./help";
import data from "./data";
import onboarding from "./onboarding";
import dashboard from "./dashboard";
import account from "./account";

const reducer = combineReducers({
    insights,
    tools,
    help,
    data,
    onboarding,
    dashboard,
    account
});

export default reducer;
