import React from 'react';
import Box from '@material-ui/core/Box';

const Spacer = () => {
    return (
        <Box display="flex" flexGrow="1" />
    );
};

export default Spacer;
