import types from "./types";

const getSalesPerSquareFootRequest = () => ({
    type: types.GET_SALES_PER_SQUARE_FOOT_REQUEST
});

const getSalesPerSquareFootSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_SALES_PER_SQUARE_FOOT_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getSalesPerSquareFootFailure = () => ({
    type: types.GET_SALES_PER_SQUARE_FOOT_FAILURE
});

const getPropertyCostsPerSquareFootRequest = () => ({
    type: types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_REQUEST
});

const getPropertyCostsPerSquareFootSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getPropertyCostsPerSquareFootFailure = () => ({
    type: types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_FAILURE
});

const getSalesPerPoundOfPropertyCostRequest = () => ({
    type: types.GET_SALES_PER_POUND_OF_PROPERTY_COST_REQUEST
});

const getSalesPerPoundOfPropertyCostSuccess = (overallData, selectedStore, comparatorStores) => ({
    type: types.GET_SALES_PER_POUND_OF_PROPERTY_COST_SUCCESS,
    payload: {
        overallData,
        selectedStore,
        comparatorStores
    }
});

const getSalesPerPoundOfPropertyCostFailure = () => ({
    type: types.GET_SALES_PER_POUND_OF_PROPERTY_COST_FAILURE
});

const actions = {
    getSalesPerSquareFootRequest,
    getSalesPerSquareFootSuccess,
    getSalesPerSquareFootFailure,
    getPropertyCostsPerSquareFootRequest,
    getPropertyCostsPerSquareFootSuccess,
    getPropertyCostsPerSquareFootFailure,
    getSalesPerPoundOfPropertyCostRequest,
    getSalesPerPoundOfPropertyCostSuccess,
    getSalesPerPoundOfPropertyCostFailure
};

export default actions;
