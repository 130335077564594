import React from "react";

import { Grid } from "@material-ui/core";

import StoreYoYGrowthVsComparator from "./StoreYoYGrowthVsComparator";
import StoreForecastYoYGrowthVsComparator from "./StoreForecastYoYGrowthVsComparator";
import StoreVsComparatorMoMGrowth from "./StoreVsComparatorMoMGrowth";
import StoreHistoricGrowthVsComparator from "./StoreHistoricGrowthVsComparator";
import StoreForecastGrowthVsComparator from "./StoreForecastGrowthVsComparator";

const Growth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <StoreYoYGrowthVsComparator />
            </Grid>
            <Grid item xs={12} md={6}>
                <StoreForecastYoYGrowthVsComparator />
            </Grid>
            <Grid item xs={12}>
                <StoreVsComparatorMoMGrowth />
            </Grid>
            <Grid item xs={12}>
                <StoreHistoricGrowthVsComparator />
            </Grid>
            <Grid item xs={12}>
                <StoreForecastGrowthVsComparator />
            </Grid>
        </Grid>
    );
};

export default Growth;
