import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const MarketCategorySpendSubtitle = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
                Understand annual spending in the catchment area of your {store?.name} store across different market categories.
                See where market categories are thriving, and analyse spending compared to {comparator?.name}.
            <p>
                To begin, select one or more market categories from the dropdown. If you select more than one category, 
                Dash will total the spend per head across them. The selected categories feed into the key performance indicators 
                and the map.
            </p>
        </>
    );
};

export default MarketCategorySpendSubtitle;
