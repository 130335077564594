import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import Spacer from "components/Spacer";
import FractionIndicator from "components/visuals/FractionIndicator";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/storeSize";

const PropertyCostsPerSquareFootRank = () => {
    const propertyCostsPerSquareFootRank = useSelector(state => selectors.propertyCostsPerSquareFootRank(state));
    const { loading, error, numerator, denominator, status } = propertyCostsPerSquareFootRank;
    const label = "Rank in region";

    return (
        <Box display="flex" data-cy="property-costs-per-square-foot-rank">
            <Typography variant="body1">{label}</Typography>
            <Spacer />
            <FractionIndicator loading={loading} error={error} numerator={numerator} denominator={denominator} status={status}
             ordinalNumerator={true} />
        </Box>
    );
};

export default PropertyCostsPerSquareFootRank;
