import React from "react";

import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    selectCustomerProfilesTreemapSeries,
    setCustomerProfilesTreemapSeries
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import {
    CustomerProfilesTreemapSeries as CustomerProfilesTreemapSeriesEnum
} from "modules/customer/insights/portfolioNew/catchment/customerProfileSummary";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButtonGroup = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: "6px"
    },
}))(ButtonGroup);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
        color: theme.palette.common.white,
        borderRadius: "6px",
        "&:hover": {
            // @ts-ignore
            backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
            color: theme.palette.common.white,
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:firs-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:first-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        }
    }
}))(Button);

const CustomerProfilesTreemapSeries: React.FC = () => {
    const dispatch = useAppDispatch();
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const customerProfilesTreemapSeries = useAppSelector(selectCustomerProfilesTreemapSeries);

    const handleStoreClick = () => {
        dispatch(setCustomerProfilesTreemapSeries(CustomerProfilesTreemapSeriesEnum.Store));
    };

    const handleComparatorClick = () => {
        dispatch(setCustomerProfilesTreemapSeries(CustomerProfilesTreemapSeriesEnum.Comparator));
    };

    return (
        <StyledButtonGroup
            variant="contained"
            size="medium"
            color="default"
            disableElevation
        >
            <StyledButton
                data-is-selected={customerProfilesTreemapSeries === CustomerProfilesTreemapSeriesEnum.Store}
                onClick={handleStoreClick}
            >
                <Typography variant="subtitle2" component="div">
                    {`${store?.name} store`}
                </Typography>
            </StyledButton>
            <StyledButton
                data-is-selected={customerProfilesTreemapSeries === CustomerProfilesTreemapSeriesEnum.Comparator}
                onClick={handleComparatorClick}
            >
                <Typography variant="subtitle2" component="div">
                    {comparator?.name}
                </Typography>
            </StyledButton>
        </StyledButtonGroup>
    );
};

export default CustomerProfilesTreemapSeries;
