import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Assess customer footfall around your {store?.name} store using precise smartphone location data.
            Benchmark this bustling activity against your entire store network to confirm strategic alignment.
            Capitalise on high-traffic areas for marketing campaigns to maximise reach.
            Use these insights to strategically negotiate lease renewals, informed by the density of potential customers frequenting the area.
        </>
    );
};

export default Subtitle;
