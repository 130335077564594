import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import mathUtils from "utils/mathUtils";

export class FootfallPerOutputArea {
    public readonly outputAreaCode: string;
    public readonly currentYearFootfall: number;
    public readonly percentageChangeYoY: number;

    constructor(
        outputAreaCode: string,
        currentYearFootfall: number,
        previousYearFootfall: number,
    ) {
        this.outputAreaCode = outputAreaCode;
        this.currentYearFootfall = currentYearFootfall;
        this.percentageChangeYoY = mathUtils.safePercentageChange(currentYearFootfall, previousYearFootfall);
    }
}

interface CatchmentFootfallDimensions {
    "F_CatchmentYearlyFootfall.OAID": string,
    "F_CatchmentYearlyFootfall.Prev12MonthsFootfall": number,
    "F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall": number
}

export const loadFootfallPerOutputArea = (selectedStore: Store | undefined, catchmentAccountId: string): AppThunk<Promise<FootfallPerOutputArea[]>> => async (dispatch) => {
    try {
        if (!selectedStore) {
            return [];
        }

        const query = {
            dimensions: [
                "F_CatchmentYearlyFootfall.OAID",
                "F_CatchmentYearlyFootfall.Prev12MonthsFootfall",
                "F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall",
            ],
            filters: [{
                member: "F_CatchmentYearlyFootfall.RetailCentreID",
                operator: "equals",
                values: [selectedStore.retailCentreID.toString()]
            },
                {
                    member: "F_CatchmentYearlyFootfall.StoreCategory_ID",
                    operator: "equals",
                    values: [selectedStore.storeCategoryID]
                }],
            order: [
                ["F_CatchmentYearlyFootfall.OAID", "asc"]
            ]
        };

        const resultSet = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "F_CatchmentYearlyFootfall")) as unknown as ExtendedResultSet<CatchmentFootfallDimensions>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => new FootfallPerOutputArea(
                row["F_CatchmentYearlyFootfall.OAID"],
                row["F_CatchmentYearlyFootfall.Prev12MonthsFootfall"],
                row["F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"]
            )
        );
    } catch (error) {
        dispatch(logError("Error loading FootfallPerOutputArea.", error));
        throw error;
    }
};
