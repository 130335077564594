import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import actions from "./actions";
import { selectStore, selectComparator, cubeLoadStoreCatchments } from "modules/customer/insights/portfolio/portfolioSlice";

const getPenPortraitsSearch = () => async (dispatch) => {
    dispatch(actions.getPenPortraitsSearchRequest());
    try {
        const query = {
            dimensions: [
                "PenPortraits.PenPortraitName",
                "PenPortraits.PenPortraitLevel",
                "PenPortraits.PenPortraitDescription"
            ]
        };

        const resultSet = await dispatch(cubeLoad(query));

        const penPortraits = resultSet.loadResponses[0].data.map(item => ({
            penPortraitName: item["PenPortraits.PenPortraitName"],
            penPortraitLevel: item["PenPortraits.PenPortraitLevel"],
            penPortraitDescription: item["PenPortraits.PenPortraitDescription"],
        }));

        dispatch(actions.getPenPortraitsSearchSuccess(penPortraits));
    }
    catch (error) {
        dispatch(actions.getPenPortraitsSearchFailure());
        dispatch(logError("Error loading PenPortraitsSearch.", error));
    }
};

const getCustomerProfiles = () => async (dispatch, getState) => {
    dispatch(actions.getCustomerProfilesRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreRC = selectedStoreSelector.retailCentreID.toString();
        const selectedStoreCategory = selectedStoreSelector.kpmgStoreCategory;

        const queryCustomerProfiles = {
            dimensions: [
                "CatchmentCustomerProfiles.RetailCentreID",
                "CatchmentCustomerProfiles.OAID",
                "CatchmentCustomerProfiles.SupergroupCode",
                "CatchmentCustomerProfiles.SupergroupName",
                "CatchmentCustomerProfiles.GroupName",
                "CatchmentCustomerProfiles.SubgroupName",
                "CatchmentCustomerProfiles.Probability",
                "CatchmentCustomerProfiles.Distance",
                "CatchmentCustomerProfiles.Population"
            ],
            filters: [{
                member: "CatchmentCustomerProfiles.RetailCentreID",
                operator: "equals",
                values: [selectedStoreRC]
            }, {
                member: "CatchmentCustomerProfiles.RetailCategory",
                operator: "equals",
                values: [selectedStoreCategory]
            }],
            segments: [
                "CatchmentCustomerProfiles.Baseline"
            ],
            order: [
                ["CatchmentCustomerProfiles.OAID", "asc"]
            ]
        };

        const resultSetCustomerProfiles = await dispatch(cubeLoadExtended(queryCustomerProfiles));

        const customerProfilesData = resultSetCustomerProfiles.loadResponses[0].data.map(item => ({
            retailCentreID: item["CatchmentCustomerProfiles.RetailCentreID"],
            outputAreaCode: item["CatchmentCustomerProfiles.OAID"],
            percentageLikelihoodOfVisiting: 100 * item["CatchmentCustomerProfiles.Probability"],
            numberOfVisitors: item["CatchmentCustomerProfiles.Population"] * item["CatchmentCustomerProfiles.Probability"],
            distance: item["CatchmentCustomerProfiles.Distance"],
            population: item["CatchmentCustomerProfiles.Population"],
            classification: item["CatchmentCustomerProfiles.SupergroupCode"],
            supergroup: item["CatchmentCustomerProfiles.SupergroupName"],
            group: item["CatchmentCustomerProfiles.GroupName"],
            subgroup: item["CatchmentCustomerProfiles.SubgroupName"]
        }));

        dispatch(actions.getCustomerProfilesSuccess(customerProfilesData));
    }
    catch (error) {
        dispatch(actions.getCustomerProfilesFailure());
        dispatch(logError("Error loading Catchment Customer Profiles.", error));
    }
};

const getPopulation = () => async (dispatch, getState) => {
    dispatch(actions.getPopulationRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);

        const comparatorStoresSelector = selectComparator(state);
        const comparatorStores = comparatorStoresSelector.getStores();

        const relevantStores = [selectedStoreSelector].concat(...comparatorStores);

        const queryCustomerProfiles = {
            dimensions: [
                "CatchmentAreasPopulation.RetailCentreID",
                "CatchmentAreasPopulation.RetailCategory",
                "CatchmentAreasPopulation.BaselinePopulation",
                "CatchmentAreasPopulation.MaxDistance",
            ],
            filters: [{
                member: "CatchmentAreasPopulation.RetailCentreID",
                operator: "equals"
            }, {
                member: "CatchmentAreasPopulation.RetailCategory",
                operator: "equals"
            }],
            segments: [
                "CatchmentAreasPopulation.Baseline"
            ]
        };

        const resultSetCustomerProfiles = await dispatch(cubeLoadStoreCatchments(queryCustomerProfiles, relevantStores, "RetailCategory", "RetailCentreID"));

        const populationData = resultSetCustomerProfiles.loadResponses[0].data.map(item => ({
            retailCentreID: item["CatchmentAreasPopulation.RetailCentreID"],
            storeCategory: item["CatchmentAreasPopulation.RetailCategory"],
            maxDistance: item["CatchmentAreasPopulation.MaxDistance"],
            population: item["CatchmentAreasPopulation.BaselinePopulation"]
        }));

        const relevantSelectedStoreData = populationData.find(item =>
            (item.retailCentreID === selectedStoreSelector.retailCentreID) && (item.storeCategory === selectedStoreSelector.kpmgStoreCategory));

        const selectedStoreValues = relevantSelectedStoreData ?? {
            retailCentreID: selectedStoreSelector.retailCentreID,
            storeCategory: selectedStoreSelector.kpmgStoreCategory,
            maxDistance: 0,
            population: 0
        };

        const comparatorValues = [];

        for (let i in comparatorStores) {
            const relevantData = populationData.find(item =>
                (item.retailCentreID === comparatorStores[i].retailCentreID) && (item.storeCategory === comparatorStores[i].kpmgStoreCategory));

            comparatorValues.push(relevantData ?? {
                retailCentreID: comparatorStores[i].retailCentreID,
                storeCategory: comparatorStores[i].kpmgStoreCategory,
                maxDistance: 0,
                population: 0
            });
        }

        dispatch(actions.getPopulationSuccess(selectedStoreValues, comparatorValues));
    }
    catch (error) {
        dispatch(actions.getPopulationFailure());
        dispatch(logError("Error loading Catchment Population.", error));
    }
};

const getMarketCategorySpend = () => async (dispatch, getState) => {
    dispatch(actions.getMarketCategorySpendRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreRC = selectedStoreSelector.retailCentreID.toString();
        const selectedStoreCategory = selectedStoreSelector.kpmgStoreCategory;

        const productCategories = selectedStoreSelector.kpmgSpendCategories;

        const query = {
            dimensions: [
                "CatchmentMarketCategorySpend.RetailCentreID",
                "CatchmentMarketCategorySpend.OAID",
                "CatchmentMarketCategorySpend.SpendKpmgCategory",
                "CatchmentMarketCategorySpend.CategorySpendPerHead",
                "CatchmentMarketCategorySpend.Distance"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals",
                values: [selectedStoreRC]
            }, {
                member: "CatchmentMarketCategorySpend.KpmgStoreCategory",
                operator: "equals",
                values: [selectedStoreCategory]
            }, {
                member: "CatchmentMarketCategorySpend.SpendKpmgCategory",
                operator: "equals",
                values: productCategories
            }],
            segments: [
                "CatchmentMarketCategorySpend.Baseline"
            ],
            order: [
                ["CatchmentMarketCategorySpend.OAID", "asc"]
            ]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const marketCategorySpendData = resultSet.loadResponses[0].data.map(item => ({
            retailCentreID: item["CatchmentMarketCategorySpend.RetailCentreID"],
            outputAreaCode: item["CatchmentMarketCategorySpend.OAID"],
            KpmgSpendCategory: item["CatchmentMarketCategorySpend.SpendKpmgCategory"],
            spendPerHead: item["CatchmentMarketCategorySpend.CategorySpendPerHead"],
            distance: item["CatchmentMarketCategorySpend.Distance"]
        }));

        dispatch(actions.getMarketCategorySpendSuccess(marketCategorySpendData));
    }
    catch (error) {
        dispatch(actions.getMarketCategorySpendFailure());
        dispatch(logError("Error loading Catchment Market Category Spend.", error));
    }
};

const operations = {
    getPenPortraitsSearch,
    getCustomerProfiles,
    getPopulation,
    getMarketCategorySpend
};

export default operations;
