import { combineReducers } from "redux";

import types from "./types";

const personalInfoEmpty = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    errors: {
        firstName: "",
        lastName: "",
        phoneNumber: ""
    }
};

const personalInfo = (state = personalInfoEmpty, action) => {
    switch (action.type) {
        case types.GET_SETTINGS_SUCCESS:
            return {
                ...personalInfoEmpty,
                ...action.payload.personalInfo
            };
        case types.GET_SETTINGS_FAILURE:
            return personalInfoEmpty;
        case types.SET_PERSONAL_INFO:
            return action.payload.personalInfo;
        default:
            return state;
    }
};

const editPersonalInfoVisibilityEmpty = {
    isVisible: false
};

const editPersonalInfoVisibility = (state = editPersonalInfoVisibilityEmpty, action) => {
    switch (action.type) {
        case types.SHOW_EDIT_PERSONAL_INFO:
            return { ...state, isVisible: true };
        case types.HIDE_EDIT_PERSONAL_INFO:
            return editPersonalInfoVisibilityEmpty;
        default:
            return state;
    }
};

const companyDetailsEmpty = {
    name: "",
    displayName: "",
    errors: {
        displayName: ""
    }
};

const companyDetails = (state = companyDetailsEmpty, action) => {
    switch (action.type) {
        case types.GET_SETTINGS_SUCCESS:
            return {
                ...companyDetailsEmpty,
                ...action.payload.companyDetails
            };
        case types.GET_SETTINGS_FAILURE:
            return companyDetailsEmpty;
        case types.SET_COMPANY_DETAILS:
            return action.payload.companyDetails;
        default:
            return state;
    }
};

const editCompanyDetailsVisibilityEmpty = {
    isVisible: false
};

const editCompanyDetailsVisibility = (state = editCompanyDetailsVisibilityEmpty, action) => {
    switch (action.type) {
        case types.SHOW_EDIT_COMPANY_DETAILS:
            return { ...state, isVisible: true };
        case types.HIDE_EDIT_COMPANY_DETAILS:
            return editCompanyDetailsVisibilityEmpty;
        default:
            return state;
    }
};

const emailNotificationsEmpty = {
    updatesOnDatasets: false,
    updatesOnDataRefresh: false,
    weeklyNewsletter: false,
    featuresUpdates: false
};

const emailNotifications = (state = emailNotificationsEmpty, action) => {
    switch (action.type) {
        case types.GET_SETTINGS_SUCCESS:
            return action.payload.emailNotifications;
        case types.GET_SETTINGS_FAILURE:
            return emailNotificationsEmpty;
        default:
            return state;
    }
};

const reducer = combineReducers({
    personalInfo,
    editPersonalInfoVisibility,
    companyDetails,
    editCompanyDetailsVisibility,
    emailNotifications
});

export default reducer;
