import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Card, CardContent, Grid } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";
import { clearCandidateStore, selectCandidateStore, setCandidateStore, selectFilteredStores } from "modules/customer/insights/cost/filters/filtersSlice";

import StoreCard from "./StoreCard";
import Title from "./Title";
import Search from "./Search";

const NUMBER_OF_VISIBLE_STORES_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const Stores: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const candidateStore = useAppSelector(selectCandidateStore);
    const [numberOfVisibleStores, setNumberOfVisibleStores] = React.useState<number>(0);
    const stores = useAppSelector(selectFilteredStores);

    const visibleStores = React.useMemo(() => {
        return stores.slice(0, numberOfVisibleStores);
    }, [stores, numberOfVisibleStores]);

    const handleSelect = (store: CostReductionOpportunityByStore) => {
        dispatch(setCandidateStore(store));
    };

    const handleDeselect = () => {
        dispatch(clearCandidateStore());
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisibleStores(previousNumberOfVisibleStores => previousNumberOfVisibleStores + NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [setNumberOfVisibleStores]);

    React.useEffect(() => {
        setNumberOfVisibleStores(NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [stores]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisibleStores <= stores.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <br />
                </Box>
                {visibleStores.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visibleStores.map(store =>
                                    <Grid key={store.id} item xs={4}>
                                        <StoreCard
                                            store={store}
                                            selected={candidateStore?.id === store.id}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default Stores;
