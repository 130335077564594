import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import DatasetIcon from "components/DatasetIcon";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectDatasets } from "modules/customer/dashboard/dashboardSlice";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Data: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);
    const datasets = useAppSelector(selectDatasets);

    return (
        <>
            <Typography variant="subtitle1" component="div" color="textSecondary" gutterBottom>
                Data
            </Typography>
            <Box paddingTop={1}>
                {datasets.map(dataset =>
                    <Box key={dataset.id} display="flex" alignItems="center" paddingBottom={1}>
                        <DatasetIcon
                            datasetStatus={dataset.status}
                            isDatasetRequired={dataset.isRequired}
                        />
                        &nbsp;&nbsp;
                        <Typography variant="subtitle1" component="div">
                            {dataset.name}
                        </Typography>
                    </Box>
                )}
            </Box>
            {userInfo.isAccountEditor && (
                <Box paddingTop={1}>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        // @ts-ignore
                        component={NavLink}
                        to="/data"
                    >
                        Manage data
                    </StyledButton>
                </Box>
            )}
        </>
    );
};

export default Data;
