import React from "react";
import { useSelector } from "react-redux";

import { selectors } from "modules/customer/insights/performance/sales/revenueGrowthBreakdown";

const RevenueGrowthBreakdownSubtitle: React.FC = () => {
    const growthBreakdownGrid = useSelector(state => selectors.growthBreakdownGrid(state));
    const topThreeMarketCategories =
        growthBreakdownGrid.marketCategories.reduce((phrase: any, category: any) => !phrase ? category.categoryName : `${phrase}, ${category.categoryName}`, "")
        || "top three market categories";

    return (
        <>
            See how your revenue is growing or declining in key comparison periods. Analyse how this compares
            to {topThreeMarketCategories} and the retail market as a whole.
        </>
    );
};

export default RevenueGrowthBreakdownSubtitle;
