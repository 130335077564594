import React from "react";

import { MenuItem, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { RelevantModelFeature, RelevantModelFeatureType } from "modules/customer/tools/location/overview/modelFeature";
import {
    selectCandidateStoreParametersForm,
    updateCandidateStoreParametersForm
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(TextField);

interface FormFieldProps {
    relevantModelFeature: RelevantModelFeature
}

const FormField: React.FC<FormFieldProps> = (props) => {
    const { relevantModelFeature } = props;
    const dispatch = useAppDispatch();
    const candidateStoreParametersForm = useAppSelector(selectCandidateStoreParametersForm);
    const currentValue = candidateStoreParametersForm.get(relevantModelFeature.name) ?? "";

    const handleNumericalFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = "^([1-9][0-9]*)*$";
        if (!value.match(regex)) {
            return;
        }
        dispatch(updateCandidateStoreParametersForm({ key: relevantModelFeature.name, value }));
    };

    const handleCategoricalFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(updateCandidateStoreParametersForm({ key: relevantModelFeature.name, value }));
    };

    return (
        <>
            {relevantModelFeature.type === RelevantModelFeatureType.Numerical && (
                <StyledTextField
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="none"
                    value={currentValue}
                    onChange={handleNumericalFieldChange}
                    required
                    fullWidth
                />
            )}
            {relevantModelFeature.type === RelevantModelFeatureType.Categorical && (
                <StyledTextField
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="none"
                    select
                    SelectProps={{
                        MenuProps: {
                            variant: "menu"
                        }
                    }}
                    value={currentValue}
                    onChange={handleCategoricalFieldChange}
                    required
                    fullWidth
                >
                    <MenuItem value="">
                        No selection
                    </MenuItem>
                    {relevantModelFeature.values.map((value, index) =>
                        <MenuItem key={index} value={value}>
                            {value}
                        </MenuItem>
                    )}
                </StyledTextField>
            )}
        </>
    );
};

export default FormField;
