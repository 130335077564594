import React from "react";
import { Grid } from "@material-ui/core";

import StoreRevenueVsPY from "./StoreRevenueVsPY";
import StoreForecastRevenueVsPY from "./StoreForecastRevenueVsPY";
import SalesHistory from "./SalesHistory";
import StoreHistoricSalesTrend from "./StoreHistoricSalesTrend";
import StoreFutureSalesTrend from "./StoreFutureSalesTrend";

const Revenue = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <StoreRevenueVsPY />
            </Grid>
            <Grid item xs={12} md={6}>
                <StoreForecastRevenueVsPY />
            </Grid>
            <Grid item xs={12}>
                <SalesHistory />
            </Grid>
            <Grid item xs={12}>
                <StoreHistoricSalesTrend />
            </Grid>
            <Grid item xs={12}>
                <StoreFutureSalesTrend />
            </Grid>
        </Grid>
    );
};

export default Revenue;
