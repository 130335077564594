import React from "react";
import Highcharts from "highcharts";
import { typeOf } from "mathjs";

import { useTheme } from "@material-ui/core/styles";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import { Store } from "modules/customer/tools/location/store";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface RadarChartProps {
    store: Store,
    enableSpend: boolean
}

const RadarChart: React.FC<RadarChartProps> = (props) => {
    const { store, enableSpend } = props;

    const theme = useTheme();
    const colourPalette = useColourPalette();

    const options = React.useMemo((): Highcharts.Options => {
        const options: Highcharts.Options = {
            chart: {
                height: theme.spacing(25),
                backgroundColor: "transparent"
            },
            xAxis: {
                type: "category",
                tickmarkPlacement: "on",
                lineWidth: 0,
                maxPadding: 5,
                labels: {
                    align: "center",
                    distance: 18,
                    formatter: function () {
                        const label = this.axis.defaultLabelFormatter.call(this);
                        if (label === "Diversity") {
                            return "Divers ity";
                        }
                        return label;
                    }
                }
            },
            yAxis: {
                gridLineInterpolation: "polygon",
                max: 100
            },
            tooltip: {
                useHTML: true,
                headerFormat: ``,
                footerFormat: ``,
                pointFormatter: function () {
                    let returnValue = "";
                    let valueDescription = "";
                    const value = this.options.custom?.tooltipValue;
                    if (typeOf(value) === "number") {
                        const returnNumber = parseInt(value?.toFixed(0) as string);
                        returnValue = numberFormatter.toOrdinalNumber(returnNumber);

                        if (returnNumber < 50) {
                            valueDescription = "Below UK average";
                        } else if (returnNumber === 50) {
                            valueDescription = "UK average";
                        } else if (returnNumber <= 100) {
                            valueDescription = "Above UK average";
                        }
                    } else {
                        returnValue = "Unknown";
                    }

                    return `<table>${stringUtils.tooltipHTML([`${returnValue} percentile`, `(${valueDescription})`], {
                        header: this.name,
                        categoryFormattingArr: ["", "font-size: 12px"]
                    })}</table>`;
                }
            },
            series: [{
                name: "Score",
                type: "area",
                color: colourPalette.comparators[1],
                data: [{
                    name: "Affluence",
                    y: store.retailCentre.affluenceCentile
                }, {
                    name: "Age",
                    y: store.retailCentre.ageCentile
                }, {
                    name: "Children",
                    y: store.retailCentre.childrenCentile
                }, {
                    name: "Diversity",
                    y: store.retailCentre.diversityCentile
                }, {
                    name: "Urbanicity",
                    y: store.retailCentre.urbanicityCentile
                // }, {
                //     ToDo: remove comment and keep centile in order once spend feature flag is removed
                //     name: "Spend",
                //     y: store.retailCentre.spendCentile
                }, {
                    name: "Area health",
                    y: store.retailCentre.areaHealthCentile
                }, {
                    name: "Footfall",
                    y: store.retailCentre.footfallCentile
                }],
                pointPlacement: "on"
            }],
            exporting: {
                enabled: false
            }
        };

        if (enableSpend) {
            // @ts-ignore
            options.series[0].data.push({
                name: "Spend",
                y: store.retailCentre.spendCentile
            });
        }
        return options;
    }, [theme, store, colourPalette.comparators, enableSpend]);

    return (
        <Radar loading={false} error={false} options={options} dataAdditionPercent={10} />
    );
};

export default RadarChart;
