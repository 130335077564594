import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import Column from "./Column";
import numberFormatter from 'utils/numberFormatter';

const BarPositiveAndNegative = (props) => {
    const theme = useTheme();
    const { loading, error, options: customOptions } = props;

    const defaultColor = '#123456';

    const xDataLabelCategories = (data) => {

        return data.map(function(item) {
            let prefix = "";
            if (item < 0) {
                prefix = "";
            } else if (item > 0) { 
                prefix = "+"; 
            } else if (item === 0 ) {
                prefix = "=";
            }
            return prefix + item;
        });
    };

    const dataOptions = (data) => {
        let changes = false;

        data.map(function(item) {
            let point = item.y;

            if (item.color === defaultColor) {

                changes = true;

                if (point < 0) {
                    item.color = theme.palette.error.main;
                    
                } else {
                    item.color = theme.palette.success.main;
                }
            }

            return item;
          
        });
            
        return {data, changes};

    };

    const setCategoriesAndData = (chart) => {
        let categoryChanges = false;

        const series = chart.series[0];
        const yData = series.yData;
        
        const categoriesCurrent = chart.xAxis[1].categories; 
        const categoriesNew = xDataLabelCategories(yData);

        if (JSON.stringify(categoriesCurrent) !== JSON.stringify(categoriesNew)) {
            categoryChanges = true;
            chart.xAxis[1].setCategories(xDataLabelCategories(yData), false); 
        }

        const { dataNew, dataChanges } = dataOptions(series.data);

        if (dataChanges) {
            series.setData(dataNew, false);
            chart.redraw();
        } else if (categoryChanges) {
            chart.redraw();
        }      
    };

    let calledByRedraw = false;

    const staticOptions = {
        colors: [defaultColor],
        chart: {
            type: "bar",
            height: "60%",
            events: {
                load: function () {
                    setCategoriesAndData(this);
                },

                redraw: function () {
                    if (calledByRedraw === false) {
                  
                        calledByRedraw = true;
                        setCategoriesAndData(this);
                  }
                  calledByRedraw = false;
                }
            }
        },
        legend:{
            enabled: false
        },
        title: {
            align: "left"
        },
        tooltip: {
             headerFormat: '<table>',
             pointFormatter: function () {
                     
                    return '<tr><td style="padding:0"><b>' + this.series.chart.title.textStr  + '</b> </td></tr>' +
                    '<tr><td>Index </td><td style="padding:0"><b> ' + this.category + '</b></td></tr>' +
                    '<tr><td>Absolute </td><td style="padding:0"><b> ' + numberFormatter.toPercentage(this.y, 1) + '</b></td></tr>'; 
        
             },
             footerFormat: '</table>',
             useHTML: true
            },
        yAxis:{
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            visible: true,
            labels: {
                enabled: false,
                style:{
                    color: theme.palette.common.white
                  }
            },
            plotLines: [{
                color: theme.palette.common.white,
              width: 1,
              value: 0,
              zIndex: 6
            }],
            plotBands: [{
                color: '#39354D',
                from: -500,
                to: 1500
            }]
        },
        xAxis: [{
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineWidth: 0,
            offset: 130,
            labels: {
                x: 5,
                align: "left",
                style:{
                    color: theme.palette.common.white,
                    
                }
            }
        },{
            categories: [],
            opposite: true,
            linkedTo: 0,
            lineWidth: 0,
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            }
        }],
        series:[{
            dataLabels: {
                enabled: false,
                
            } 
        }]
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <Column loading={loading} error={error} options={options} dataCy="positive-negative-bar-chart" />
    );
};

BarPositiveAndNegative.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

BarPositiveAndNegative.defaultProps = {
    loading: false,
    error: false
};

export default BarPositiveAndNegative;
