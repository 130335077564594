import React from "react";

import { Box, Card, CardActionArea, CardContent, MenuItem, Popover, Typography } from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { SavedLocationsSortField, selectSavedLocationsSort, setSavedLocationsSort } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";
import { SortDirection } from "utils/sortUtils";

const StyledButtonCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-open"] ? theme.palette.background.paper : theme.palette.quaternary.dark,
        borderRadius: (props: any) => props["data-is-open"] ? "6px 6px 0px 0px" : "6px"
    }
}))(Card);

const StyledButtonCardActionArea = withStyles(theme => ({
    root: {
        padding: "0px 8px"
    }
}))(CardActionArea);

const StyledMenuCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "6px 0px 6px 6px",
    }
}))(Card);

const StyledMenuCardContent = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(40),
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0)
        }
    }
}))(CardContent);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

const Sort: React.FC = () => {
    const dispatch = useAppDispatch();
    const savedLocationsSort = useAppSelector(selectSavedLocationsSort);
    const [anchorElement, setAnchorElement] = React.useState<HTMLButtonElement | undefined>(undefined);
    const isOpen = Boolean(anchorElement);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(undefined);
    };

    const handleSortClick = (field: SavedLocationsSortField, sort: SortDirection) => () => {
        let direction = sort;
        if (savedLocationsSort.field === field) {
            direction = savedLocationsSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSavedLocationsSort = { ...savedLocationsSort, field, direction };
        dispatch(setSavedLocationsSort(newSavedLocationsSort));
    };

    return (
        <>
            <StyledButtonCard elevation={0} data-is-open={isOpen}>
                <StyledButtonCardActionArea onClick={handleClick}>
                    <Box display="flex" alignItems="center">
                        <ArrowDownwardRoundedIcon fontSize="small" />
                        &nbsp;&nbsp;
                        <Typography variant="body1" component="div">
                            Sort
                        </Typography>
                    </Box>
                </StyledButtonCardActionArea>
            </StyledButtonCard>
            <Popover
                elevation={0}
                open={isOpen}
                anchorEl={anchorElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                PaperProps={{
                    style: { backgroundColor: "transparent", borderRadius: "0px", maxHeight: "70vh" }
                }}
            >
                <StyledMenuCard>
                    <StyledMenuCardContent>
                        <StyledMenuItem onClick={handleSortClick(SavedLocationsSortField.CreatedAt, SortDirection.DESC)} data-current={savedLocationsSort.field === SavedLocationsSortField.CreatedAt}>
                            <Typography variant="body1" component="div">
                                Date saved
                            </Typography>
                            <Spacer />
                            {savedLocationsSort.field !== SavedLocationsSortField.CreatedAt || savedLocationsSort.direction === SortDirection.DESC ? (
                                <ArrowDownwardRoundedIcon fontSize="small" />
                            ) : (
                                <ArrowUpwardRoundedIcon fontSize="small" />
                            )}
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleSortClick(SavedLocationsSortField.Address, SortDirection.ASC)} data-current={savedLocationsSort.field === SavedLocationsSortField.Address}>
                            <Typography variant="body1" component="div">
                                Address
                            </Typography>
                            <Spacer />
                            <Typography variant="body1" component="div">
                                {savedLocationsSort.field !== SavedLocationsSortField.Address || savedLocationsSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                            </Typography>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleSortClick(SavedLocationsSortField.UserName, SortDirection.ASC)} data-current={savedLocationsSort.field === SavedLocationsSortField.UserName}>
                            <Typography variant="body1" component="div">
                                Saved by
                            </Typography>
                            <Spacer />
                            <Typography variant="body1" component="div">
                                {savedLocationsSort.field !== SavedLocationsSortField.UserName || savedLocationsSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                            </Typography>
                        </StyledMenuItem>
                    </StyledMenuCardContent>
                </StyledMenuCard>
            </Popover>
        </>
    );
};

export default Sort;
