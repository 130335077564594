import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartBase from "./ChartBase";
import { useTheme } from "@material-ui/core/styles";
import numberFormatter from "utils/numberFormatter";


const Boxplot = (props) => {
    const theme = useTheme();

    const scatterTooltip = (point, isTop) => {

        const msg = (isTop) ? "Top store sales mix" : "Bottom store sales mix";
        const colour = (isTop) ? theme.palette.success.main : theme.palette.error.main;

        return `<tr><td style="color:${colour}">${msg}</td></tr><tr><td style="padding:0">
                ${point.store}</td><td style="color:${colour}">
                ${numberFormatter.toPercentage(point.y)}</td></tr>`;
    };

    const { dataCy, loading, error, categories, fullRange, middleQuartiles, lows, highs, options: customOptions } = props;
    const staticOptions = {
        chart: {
            inverted: true
        },
        legend: {
            enabled: false
        }, 
        xAxis: {
            type: 'category',
            categories: categories
        },
        yAxis: {
            title: {
                text: ''
            },
            tickAmount: 7
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        tooltip: {
            shared: false,
            useHTML: true,
            headerFormat: `<span style="font-size:13px">{point.key}<br></span><table>`,
            footerFormat: '</table>'
        },
        series: [{
            type: 'dumbbell',
            data: fullRange,
            marker: {
                enabled: false
            },
            enableMouseTracking: false,
            color: theme.palette.common.white

        },      
        {
            type: 'columnrange',
            data: middleQuartiles,
            pointWidth: 16,
            borderRadius: 6,
            color: theme.palette.warning.main,
            borderWidth: 0,
            tooltip: {
                pointFormatter: function() {
                    let point = this;
                    const yellow = theme.palette.warning.main;

                    let returnTable = '<tr><td style="width:150px">25th Percentile &nbsp;&nbsp;&nbsp;</td><td style="color:' + yellow + '">' + numberFormatter.toPercentage(point.low) + '</td></tr>' + 
                    '<tr><td style="">Median &nbsp;&nbsp;&nbsp;</td><td style="color:' + yellow + '">' + numberFormatter.toPercentage(point.median) + '</td></tr>' + 
                    '<tr><td style="">75th Percentile &nbsp;&nbsp;&nbsp;</td><td style="color:' + yellow + '">' + numberFormatter.toPercentage(point.high) + '</td></tr>'; 
                    return returnTable;
                }
            }
        },
        {
            type: 'scatter',
            data: lows,
            marker: {
                symbol: 'circle',
                fillColor: theme.palette.error.main,
                radius: 8
            },
            tooltip: {
                pointFormatter: function() {
                    return scatterTooltip(this, false);
                }
            }
        },
        {
            type: 'scatter',
            data: highs,
            marker: {
                symbol: 'circle',
                fillColor: theme.palette.success.main,
                radius: 8
            },
            tooltip: {
                pointFormatter: function() {
                    return scatterTooltip(this, true);
                }
            }
        }]
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

Boxplot.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired, 
    fullRange: PropTypes.array.isRequired, 
    middleQuartiles: PropTypes.array.isRequired, 
    lows: PropTypes.array.isRequired, 
    highs: PropTypes.array.isRequired,
    options: PropTypes.object
};

Boxplot.defaultProps = {
    dataCy: "boxplot-chart",
    loading: false,
    error: false
};

export default Boxplot;
