import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const HouseholdOccupancy: React.FC = () => {
    const title = "Household occupancy (Affluence)";
    const categories = [
        "2+ less rooms than required",
        "1 less room than required",
        "Optimal amount of rooms",
        "1 more room than required",
        "2+ more rooms than required"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.householdOccupancy;
    const retailCentreValues = retailCentre ? [
        retailCentre.occupancyRatingMinus2orLess,
        retailCentre.occupancyRatingMinus1,
        retailCentre.occupancyRating0,
        retailCentre.occupancyRating1,
        retailCentre.occupancyRating2Plus
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.householdOccupancy;
    const benchmarkValues = benchmark ? [
        benchmark.occupancyRatingMinus2orLess,
        benchmark.occupancyRatingMinus1,
        benchmark.occupancyRating0,
        benchmark.occupancyRating1,
        benchmark.occupancyRating2Plus
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default HouseholdOccupancy;
