import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const HouseholdNumberOfCars: React.FC = () => {
    const title = "Household number of cars (Urbanicity)";
    const categories = [
        "3+ cars",
        "2 cars",
        "1 car",
        "0 cars"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.householdNumberOfCars;
    const retailCentreValues = retailCentre ? [
        retailCentre.car3Plus,
        retailCentre.car2,
        retailCentre.car1,
        retailCentre.car0
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.householdNumberOfCars;
    const benchmarkValues = benchmark ? [
        benchmark.car3Plus,
        benchmark.car2,
        benchmark.car1,
        benchmark.car0
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default HouseholdNumberOfCars;
