import React from "react";

import { Grid } from "@material-ui/core";

import FormField from "./FormField";
import FormLabel from "./FormLabel";

import { RelevantModelFeature } from "modules/customer/tools/location/overview/modelFeature";

interface FormRowProps {
    relevantModelFeature: RelevantModelFeature
}

const FormRow: React.FC<FormRowProps> = (props) => {
    const { relevantModelFeature } = props;
    const { label, tooltip } = (() => {
        switch (relevantModelFeature.name) {
            case "CarPark":
                return {
                    label: "Car park",
                    tooltip: "What type of car parking facilities does your proposed store offer?"
                };
            case "ClickAndCollect":
                return {
                    label: "Click and collect",
                    tooltip: "Does your proposed store offer a Click & Collect service?"
                };
            case "ClientDefinedRegion":
                return {
                    label: "Region",
                    tooltip: "Which geographic region within your company's framework will your proposed store belong to?"
                };
            case "LocationType":
                return {
                    label: "Location type",
                    tooltip: "What type of location best describes where your proposed store is situated?"
                };
            case "NumberOfCarParkSpace":
                return {
                    label: "Number of parking spaces",
                    tooltip: "How many car parking spaces are available at your proposed store?"
                };
            case "NumberOfMannedTills":
                return {
                    label: "Number of manned tills",
                    tooltip: "How many manned tills will your proposed store have?"
                };
            case "NumberOfSelfCheckout":
                return {
                    label: "Number of self-checkouts",
                    tooltip: "How many self-checkout tills will your proposed store have?"
                };
            case "OpenHoursWeek":
                return {
                    label: "Opening hours per week",
                    tooltip: "How many hours will your proposed store be open during a typical week?"
                };
            case "Segment":
                return {
                    label: "Segment",
                    tooltip: "Into which market segment does your proposed store most accurately fit?"
                };
            case "StoreFormat":
                return {
                    label: "Format",
                    tooltip: "What specific format describes your proposed store's layout and style?"
                };
            case "TradingFloors":
                return {
                    label: "Number of trading floors",
                    tooltip: "How many trading floors will your proposed store have?"
                };
            case "Visibility":
                return {
                    label: "Visibility",
                    tooltip: "How visible is your proposed store's frontage from the surrounding environment?"
                };
            case "WheelchairAccessible":
                return {
                    label: "Wheelchair accessible",
                    tooltip: "Is your proposed store equipped with wheelchair accessibility?"
                };
            default:
                return {
                    label: relevantModelFeature.displayName,
                    tooltip: ""
                };
        }
    })();

    return (
        <>
            <Grid item xs={4}>
                <FormLabel text={label} tooltip={tooltip} />
            </Grid>
            <Grid item xs={7}>
                <FormField relevantModelFeature={relevantModelFeature} />
            </Grid>
        </>
    );
};

export default FormRow;
