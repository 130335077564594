import { RetailCentreWithScores } from "./retailCentre";

export class SavedLocationDetails {
    public readonly id: string;
    public readonly name: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly address: string;
    public readonly notes: string;
    public readonly isPublic: boolean;
    public readonly retailCentre: RetailCentreWithScores;

    constructor(
        id: string,
        name: string,
        latitude: number,
        longitude: number,
        address: string,
        notes: string,
        isPublic: boolean,
        retailCentre: RetailCentreWithScores
    ) {
        this.id = id;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.address = address;
        this.notes = notes;
        this.isPublic = isPublic;
        this.retailCentre = retailCentre;
    }
}
