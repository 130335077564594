import React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    number: {
        color: theme.palette.text.disabled
    }
}));

interface SubchapterHeaderProps {
    number: number,
    title: string,
    subtitle?: string | React.ReactElement
}

const SubchapterHeader: React.FC<SubchapterHeaderProps> = (props) => {
    const { number, title, subtitle } = props;
    const classes = useStyles();

    return (
        <Box data-cy="header">
            <Box paddingBottom={3}>
                <Typography variant="h5" component="div" data-cy="title">
                    <span className={classes.number} data-cy="number">{number}&nbsp;&nbsp;</span>
                    {title}
                </Typography>
            </Box>
            <Box paddingBottom={3}>
                <Typography variant="subtitle1" component="div" data-cy="subtitle">
                    {subtitle}
                </Typography>
            </Box>
        </Box>
    );
};

SubchapterHeader.defaultProps = {
    subtitle: ""
};

export default SubchapterHeader;
