import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Button } from "@material-ui/core";

import SimpleDialog from "components/SimpleDialog";

import { login, selectLoggedOutVisibility } from "modules/auth/authSlice";

const LoggedOut = () => {
    const dispatch = useDispatch();
    const loggedOutVisibility = useSelector(selectLoggedOutVisibility);

    const onLoginClick = () => {
        dispatch(login());
    };

    return (
        <SimpleDialog
            open={loggedOutVisibility.isVisible}
            enableClose={false}
            maxWidth="sm"
            title={
                <Typography variant="h3" component="div" gutterBottom>
                    You have been logged out of Dash
                </Typography>
            }
            titleAlign="center"
            content={
                <Typography variant="h4" component="div">
                    For security, your connection times out when you've been inactive for a while
                </Typography>
            }
            contentAlign="center"
            actions={
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    disableElevation
                    data-cy="btn-log-in"
                    onClick={onLoginClick}

                >
                    Log in
                </Button>
            }
            actionsJustifyContent="center"
        />
    );
};

export default LoggedOut;
