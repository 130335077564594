import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledTypography = withStyles(() => ({
    root: {
        fontWeight: "700",
        fontSize: "76px",
        lineHeight: "56px"
    }
}))(Typography);

const Statistics = () => {
    const statistics = [{
        title: "51",
        subtitle: "Strong team of retail data experts"
    }, {
        title: "5,500+",
        subtitle: "Days of technical development and testing"
    }, {
        title: "3.2bn",
        subtitle: "External or proprietary data points"
    }];

    return (
        <PageSection disablePaddingTop disablePaddingBottom dataCy="pages-landing-our-story-statistics">
            <Grid container spacing={6}>
                {statistics.map((stats, index) =>
                    <Grid key={index} item xs={12} md={4}>
                        <StyledTypography component="div" color="primary" align="center">
                            {stats.title}
                        </StyledTypography>
                        <br />
                        <Typography variant="h4" component="div" align="center" gutterBottom>
                            {stats.subtitle}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </PageSection>
    );
};

export default Statistics;
