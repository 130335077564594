import React from "react";

import { Card, CardMedia, Grid, Typography } from "@material-ui/core";

const Content: React.FC = () => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Card elevation={0}>
                    <CardMedia component="img" image="/content/customer/tools/location/image1.svg" alt="location information" />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" component="div">
                    Looking to expand your bricks-and-mortar business but not sure where to start?
                    <br />
                    Dash's Location Finder helps you expand your business with confidence.
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" component="div">
                    Dash has divided the United Kingdom into roughly 220,000 distinct areas.
                    Any of these output areas could be the best location for your company's new store,
                    but to help you identify the best locations we've ranked each area for you.
                    You can also tailor recommendations by adjusting your benchmark settings.
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle1" component="div">
                    We've analysed each output area based on the following chapters:
                    <ul>
                        <li>The demographics in the local area</li>
                        <li>The health of the area</li>
                        <li>The level of demand in the area</li>
                        <li>The level of competition in the area</li>
                        <li>The positioning of the store relative to the rest of your estate</li>
                        <li>The level of footfall</li>
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Content;
