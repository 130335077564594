import React from "react";

const RevenueVsBudgetOverTimeSubtitle: React.FC = () => {
    return (
        <>
            How is your company performing against budget quarter by quarter? Which quarters are ahead of budget, and
            which are behind?
        </>
    );
};

export default RevenueVsBudgetOverTimeSubtitle;
