import React from "react";

import { Card, CardContent, Chip, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import numberFormatter from "utils/numberFormatter";
import { AggregatedSpendPerOutputArea } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { SortDirection, numberSortExpression } from "utils/sortUtils";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(30),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const StyledDivider = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))(Divider);

interface LegendProps {
    outputArea?: AggregatedSpendPerOutputArea,
    thresholds: number[]
}

const Legend: React.FC<LegendProps> = (props) => {
    const { outputArea, thresholds } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    const spendByCategory = outputArea?.spendByCategory
        .sort((a, b) => numberSortExpression(a.spend, b.spend, SortDirection.DESC))
        .slice(0, 5);

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={outputArea !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div">
                                Total spend
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toGBP(outputArea?.spend)}
                            </Typography>
                        </Grid>
                        {spendByCategory && spendByCategory.map(item => {
                            return (
                                <React.Fragment key={item.categoryName}>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" component="div" noWrap>
                                        — &nbsp;{item.categoryName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" component="div" align="right" color='textSecondary'>
                                            {numberFormatter.toGBP(item.spend)}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Spend per head
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toGBP(outputArea?.spendPerHead)}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Likelihood of visit
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toPercentage(outputArea?.likelihoodOfVisiting, false)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div">
                                Supergroup
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div" align="right" noWrap>
                                {outputArea?.supergroupName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Total spend
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[0]} />
                    &nbsp;&nbsp;Very high&nbsp;{"(>" + numberFormatter.toGBP(thresholds[4]) + ")"}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[1]} />
                    &nbsp;&nbsp;High&nbsp;{"("+(numberFormatter.toGBP(thresholds[3]) +  "-" + numberFormatter.toGBP(thresholds[4]))+")"}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[2]} />
                    &nbsp;&nbsp;Medium&nbsp;{"("+(numberFormatter.toGBP(thresholds[2]) +  "-" + numberFormatter.toGBP(thresholds[3]))+")"}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[3]} />
                    &nbsp;&nbsp;Low&nbsp;{"("+(numberFormatter.toGBP(thresholds[1]) +  "-" + numberFormatter.toGBP(thresholds[2]))+")"}
                </Typography>
                <Typography variant="body1" component="div">
                    <StyledChip size="small" data-background-color={colourPalette.sequential[4]} />
                    &nbsp;&nbsp;Very low&nbsp;{"(<" + numberFormatter.toGBP(thresholds[1]) + ")"}
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
