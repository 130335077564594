import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectYearlyGrossProfitMargin } from "modules/customer/insights/portfolioNew/profit/profitSlice";
import numberFormatter from "utils/numberFormatter";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

const GrossProfitMarginLastYear = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const yearlyGrossProfitMarginDataWrapper = useAppSelector(selectYearlyGrossProfitMargin);
    const { isLoading, hasErrors, data: yearlyGrossProfitMargin } = yearlyGrossProfitMarginDataWrapper;
    const differenceValue = yearlyGrossProfitMargin.store - yearlyGrossProfitMargin.comparator;
    const differencePositiveSign = differenceValue > 0 ? "+" : "";
    const label = `${selectedStore?.name} Gross Profit Margin % over past 12 months`;

    return (
        <Box width="100%" height="100%" data-cy="gross-profit-margin-last-year">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {numberFormatter.toPercentage(yearlyGrossProfitMargin.store, true, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {numberFormatter.toPercentage(yearlyGrossProfitMargin.comparator, true, 1)}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${selectedComparator?.name} `}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {differencePositiveSign}{numberFormatter.toPercentage(differenceValue, true, 1)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default GrossProfitMarginLastYear;
