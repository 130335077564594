import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import dateUtils from "utils/dateUtils";
import actions from "./actions";
import { selectStore, selectComparator, selectReferenceDate, cubeLoadStoreCatchments } from "modules/customer/insights/portfolio/portfolioSlice";

const getDemographics = () => async (dispatch, getState) => {
    dispatch(actions.getDemographicsRequest());
    try {
        const state = getState();

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const selectedStoreSelector = selectStore(state);
        const selectedStoreOA = selectedStoreSelector.outputAreaCode;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorOAs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);
        const allStores = [selectedStoreSelector].concat(...comparatorStoresSelector.getStores());

        const querySales = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.RetailCentreID"]
        };

        const resultSetSales = await dispatch(cubeLoadExtended(querySales));

        const salesData = resultSetSales.loadResponses[0].data.map(item => ({
            storeID: item["D_Store.StoreNaturalID"],
            retailCentreID: item["D_Store.RetailCentreID"],
            kpmgStoreCategory: item["D_Store.KPMGStoreCategory"],
            salesValue: item["F_Sales.SumLineValue"]
        }));

        const relevantOAs = salesData.map(item => item.OAID);

        const selectedAndComparatorOAs = [selectedStoreOA].concat(comparatorOAs);

        for (let i in selectedAndComparatorOAs) {
            if (!relevantOAs.includes(selectedAndComparatorOAs[i])) {
                relevantOAs.push(selectedAndComparatorOAs[i]);
            }
        }

        //Find catchment OAs for the selected store OA and use primary category to find this
        const queryDemographics = {
            measures: [],
            dimensions: [
                "CatchmentDemographics.RetailCentreID",
                "CatchmentDemographics.KPMGCategory",
                "CatchmentDemographics.Population",
                "CatchmentDemographics.Age0to15",
                "CatchmentDemographics.Age16to24",
                "CatchmentDemographics.Age25to34",
                "CatchmentDemographics.Age35to44",
                "CatchmentDemographics.Age45to54",
                "CatchmentDemographics.Age55to64",
                "CatchmentDemographics.Age65Plus",
                "CatchmentDemographics.NoQualification",
                "CatchmentDemographics.Level1",
                "CatchmentDemographics.Level2",
                "CatchmentDemographics.Level3",
                "CatchmentDemographics.Level4Plus",
                "CatchmentDemographics.OtherQualification",
                "CatchmentDemographics.Detached",
                "CatchmentDemographics.SemiDetached",
                "CatchmentDemographics.Terrace",
                "CatchmentDemographics.Flat",
                "CatchmentDemographics.OtherAccommodation",
                "CatchmentDemographics.OnePersonHousehold",
                "CatchmentDemographics.MarriedDependentChildren",
                "CatchmentDemographics.MarriedNoDependentChildren",
                "CatchmentDemographics.CohabitingCoupleDependentChildren",
                "CatchmentDemographics.CohabitingCoupleNoDependentChildren",
                "CatchmentDemographics.LoneParentDependentChildren",
                "CatchmentDemographics.LoneParentNoDependentChildren",
                "CatchmentDemographics.MultiPersonHouseholdStudents",
                "CatchmentDemographics.MultiPersonHouseholdOther",
                "CatchmentDemographics.OwnedOutright",
                "CatchmentDemographics.OwnedWithLoan",
                "CatchmentDemographics.SocialRent",
                "CatchmentDemographics.PrivateRent",
                "CatchmentDemographics.SharedOwnership",
                "CatchmentDemographics.LivingRentFree",
                "CatchmentDemographics.ChildrenAge0to2",
                "CatchmentDemographics.ChildrenAge3to5",
                "CatchmentDemographics.ChildrenAge6to10",
                "CatchmentDemographics.ChildrenAge11to15",
                "CatchmentDemographics.ChildrenAge16to18",
                "CatchmentDemographics.CountIncome_Sub20k",
                "CatchmentDemographics.CountIncome_20to30k",
                "CatchmentDemographics.CountIncome_30to40k",
                "CatchmentDemographics.CountIncome_40to50k",
                "CatchmentDemographics.CountIncome_50to60k",
                "CatchmentDemographics.CountIncome_60to70k",
                "CatchmentDemographics.CountIncome_70to80k",
                "CatchmentDemographics.CountIncome_80to90k",
                "CatchmentDemographics.CountIncome_90to100k",
                "CatchmentDemographics.CountIncome_100kPlus"
            ],
            filters: [{
                member: "CatchmentDemographics.RetailCentreID",
                operator: "equals"
            },
            {
                member: "CatchmentDemographics.KPMGCategory",
                operator: "equals"
            }],
            segments: [
                "CatchmentDemographics.Baseline"
            ]
        };
        const resultSetDemographics = await dispatch(cubeLoadStoreCatchments(queryDemographics, allStores, "KPMGCategory", "RetailCentreID"));

        const demographicsData = resultSetDemographics.loadResponses[0].data.map(item => ({
            RetailCentreID: item["CatchmentDemographics.RetailCentreID"],
            StoreCategory: item["CatchmentDemographics.KPMGCategory"],
            Population: item["CatchmentDemographics.Population"],
            Age0to15: item["CatchmentDemographics.Age0to15"],
            Age16to24: item["CatchmentDemographics.Age16to24"],
            Age25to34: item["CatchmentDemographics.Age25to34"],
            Age35to44: item["CatchmentDemographics.Age35to44"],
            Age45to54: item["CatchmentDemographics.Age45to54"],
            Age55to64: item["CatchmentDemographics.Age55to64"],
            Age65Plus: item["CatchmentDemographics.Age65Plus"],
            NoQualification: item["CatchmentDemographics.NoQualification"],
            Level1: item["CatchmentDemographics.Level1"],
            Level2: item["CatchmentDemographics.Level2"],
            Level3: item["CatchmentDemographics.Level3"],
            Level4Plus: item["CatchmentDemographics.Level4Plus"],
            OtherQualification: item["CatchmentDemographics.OtherQualification"],
            Detached: item["CatchmentDemographics.Detached"],
            SemiDetached: item["CatchmentDemographics.SemiDetached"],
            Terrace: item["CatchmentDemographics.Terrace"],
            Flat: item["CatchmentDemographics.Flat"],
            OtherAccommodation: item["CatchmentDemographics.OtherAccommodation"],
            OnePersonHousehold: item["CatchmentDemographics.OnePersonHousehold"],
            MarriedDependentChildren: item["CatchmentDemographics.MarriedDependentChildren"],
            MarriedNoDependentChildren: item["CatchmentDemographics.MarriedNoDependentChildren"],
            CohabitingCoupleDependentChildren: item["CatchmentDemographics.CohabitingCoupleDependentChildren"],
            CohabitingCoupleNoDependentChildren: item["CatchmentDemographics.CohabitingCoupleNoDependentChildren"],
            LoneParentDependentChildren: item["CatchmentDemographics.LoneParentDependentChildren"],
            LoneParentNoDependentChildren: item["CatchmentDemographics.LoneParentNoDependentChildren"],
            MultiPersonHouseholdStudents: item["CatchmentDemographics.MultiPersonHouseholdStudents"],
            MultiPersonHouseholdOther: item["CatchmentDemographics.MultiPersonHouseholdOther"],
            OwnedOutright: item["CatchmentDemographics.OwnedOutright"],
            OwnedWithLoan: item["CatchmentDemographics.OwnedWithLoan"],
            SocialRent: item["CatchmentDemographics.SocialRent"],
            PrivateRent: item["CatchmentDemographics.PrivateRent"],
            SharedOwnership: item["CatchmentDemographics.SharedOwnership"],
            LivingRentFree: item["CatchmentDemographics.LivingRentFree"],
            ChildrenAge0to2: item["CatchmentDemographics.ChildrenAge0to2"],
            ChildrenAge3to5: item["CatchmentDemographics.ChildrenAge3to5"],
            ChildrenAge6to10: item["CatchmentDemographics.ChildrenAge6to10"],
            ChildrenAge11to15: item["CatchmentDemographics.ChildrenAge11to15"],
            ChildrenAge16to18: item["CatchmentDemographics.ChildrenAge16to18"],
            CountIncomeSub20k: item["CatchmentDemographics.CountIncome_Sub20k"],
            CountIncome20to30k: item["CatchmentDemographics.CountIncome_20to30k"],
            CountIncome30to40k: item["CatchmentDemographics.CountIncome_30to40k"],
            CountIncome40to50k: item["CatchmentDemographics.CountIncome_40to50k"],
            CountIncome50to60k: item["CatchmentDemographics.CountIncome_50to60k"],
            CountIncome60to70k: item["CatchmentDemographics.CountIncome_60to70k"],
            CountIncome70to80k: item["CatchmentDemographics.CountIncome_70to80k"],
            CountIncome80to90k: item["CatchmentDemographics.CountIncome_80to90k"],
            CountIncome90to100k: item["CatchmentDemographics.CountIncome_90to100k"],
            CountIncome100kPlus: item["CatchmentDemographics.CountIncome_100kPlus"]
        }));

        dispatch(actions.getDemographicsSuccess(demographicsData, salesData));
    }
    catch (error) {
        dispatch(actions.getDemographicsFailure());
        dispatch(logError("Error loading Demographics.", error));
    }
};

const operations = {
    getDemographics
};

export default operations;
