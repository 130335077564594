import React from "react";
import Highcharts from "highcharts";

import { Box, Typography, useTheme } from "@material-ui/core";

import Treemap from "components/visuals/Treemap";
import useColourPalette from "components/visuals/useColourPalette";

import {
    selectCustomerProfileBreakdown,
    selectCustomerProfilesTreemapSeries,
    selectHasErrors,
    selectIsLoading
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { CustomerProfilesTreemapSeries } from "modules/customer/insights/portfolioNew/catchment/customerProfileSummary";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const CustomerProfilesTreemap: React.FC = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const customerProfilesData = useAppSelector(selectCustomerProfileBreakdown).data;
    const customerProfilesTreemapSeries = useAppSelector(selectCustomerProfilesTreemapSeries);

    const selectedSeriesData = customerProfilesTreemapSeries === CustomerProfilesTreemapSeries.Store
        ? customerProfilesData.selectedStore
        : customerProfilesData.comparator;

    const chartData = selectedSeriesData.map(row => {
        let colour = undefined;
        if (row.type === "Supergroup" && row.supergroupCode !== undefined) {
            colour = colourPalette.categorical[row.supergroupCode - 1];
        }
        return {
            id: row.name,
            name: row.name,
            parent: row.parent,
            value: row.percentageOverallVisitors,
            color: colour,
            custom: {
                numberOfVisitors: row.numberOfVisitors,
                percentageOverallVisitors: row.percentageOverallVisitors,
                type: row.type
            }
        };
    });

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            height: 300,
            inverted: true
        },
        tooltip: {
            useHTML: true,
            headerFormat: "",
            footerFormat: "",
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                const customOptions = this.point?.options?.custom;
                return `<table><tr><td colspan="2" style="">${customOptions?.type}:</td></tr>
                ${stringUtils.tooltipHTML([" ", "Number of visitors", "Percentage of total<br>visitors"], {
                        header: `${this.key}`,
                        values: [" ",
                            numberFormatter.toTruncatedInteger(customOptions?.numberOfVisitors, 1),
                            numberFormatter.toPercentage(customOptions?.percentageOverallVisitors, true, 1)
                        ],
                        valueFormatting: `color:${this.color}`
                    }
                )}</table>`;
            }
        },
        series: [{
            type: "treemap",
            data: chartData,
            layoutAlgorithm: "stripes",
            layoutStartingDirection: "horizontal",
            levels: [{
                dataLabels: {
                    style: {
                        textOverflow: "clip",
                        fontFamily: theme.typography.fontFamily
                    },
                    crop: true,
                    overflow: "justify",
                    formatter: function () {
                        const pointPercentage = this.point.value ?? 0;
                        const pointVisitors = this.point.options?.custom?.numberOfVisitors;
                        const parent = this.point.options.parent;
                        const type = this.point.options.custom?.type;

                        const topLevelPoints = this.series.data.filter(item => item?.options?.custom?.type === "Supergroup");
                        const topLevelValues: number[] = topLevelPoints.map(item => item.options?.custom?.numberOfVisitors) ?? [0];
                        const topLevelTotal = topLevelValues.reduce((accumulator, value) => accumulator + value, 0);

                        const parentVisitors = (type === "Supergroup") ? topLevelTotal : this.series.data.find(item => item.name === parent)?.options.custom?.numberOfVisitors ?? 1e9;

                        let formattedPointName = this.point.name;
                        if (formattedPointName.indexOf(" ") > -1) {
                            formattedPointName = this.point.name.replaceAll(" ", "<br>");
                        }

                        if (100 * pointVisitors / parentVisitors >= 10) {
                            return `<table>${stringUtils.tooltipHTML([`${numberFormatter.toPercentage(pointPercentage, true, 1)}`], {
                                header: formattedPointName,
                                values: ["&nbsp;"],
                                headerFormatting: `color: ${theme.palette.common.black}; fontWeight: 400; fontSize: 14px`,
                                categoryFormatting: `color: ${theme.palette.common.black}; fontWeight: 600; fontSize: 18px`,
                                overrideDefaultHeaderFormatting: true,
                                overrideDefaultCategoryFormatting: true
                            })}</table>`;
                        } else {
                            return "";
                        }

                    }
                }
            }]
        }]
    }), [chartData, theme]);

    return (
        <Box data-cy="customer-profiles-treemap">
            <Typography variant="h6" component="div" gutterBottom>
                (%) of catchment area population by customer profiles
            </Typography>
            <Treemap loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default CustomerProfilesTreemap;
