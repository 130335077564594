import React from "react";

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import {
    selectSpendByMarketCategory,
    selectSpendByMarketCategorySort,
    setSpendByMarketCategorySort,
    SpendByMarketCategorySortField
} from "modules/customer/tools/location/spend/spendSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { SortDirection } from "utils/sortUtils";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const SpendByMarketCategory: React.FC = () => {
    const dispatch = useAppDispatch();
    const spendByMarketCategory = useAppSelector(selectSpendByMarketCategory);
    const sort = useAppSelector(selectSpendByMarketCategorySort);

    const handleSortClick = (field: SpendByMarketCategorySortField) => () => {
        let direction = SortDirection.ASC;
        if (sort.field === field) {
            direction = sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSort = { ...sort, field, direction };
        dispatch(setSpendByMarketCategorySort(newSort));
    };

    return (
        <Box paddingTop={2} data-cy="spend-by-market-category">
            <Typography variant="h6" component="div">
                Spend by market category
            </Typography>
            <br />
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sort.field === SpendByMarketCategorySortField.MarketCategory}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SpendByMarketCategorySortField.MarketCategory)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Market category
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SpendByMarketCategorySortField.WeightedSpend}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SpendByMarketCategorySortField.WeightedSpend)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Weighted spend
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SpendByMarketCategorySortField.SpendPerHead}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SpendByMarketCategorySortField.SpendPerHead)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Average spend per head
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {spendByMarketCategory.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {item.categoryName}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toGBP(item.weightedSpend, 1)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toGBP(item.spendPerHead, 1)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default SpendByMarketCategory;
