import React from "react";
import { DateTime } from "luxon";

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import {
    clearAuditLogs,
    clearCount,
    countAuditLogs,
    getAuditLogs,
    selectAuditLogs,
    selectCount,
    selectPage,
    selectRowsPerPage,
    setPage,
    setRowsPerPage
} from "modules/admin/auditLogs/auditLogsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const AuditLogsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const auditLogs = useAppSelector(selectAuditLogs);
    const count = useAppSelector(selectCount);
    const page = useAppSelector(selectPage);
    const rowsPerPage = useAppSelector(selectRowsPerPage);

    const handlePageChange = (event: unknown, newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = Number(event.target.value);
        dispatch(setRowsPerPage(newRowsPerPage));
    };

    React.useEffect(() => {
        dispatch(countAuditLogs());
        return () => {
            dispatch(clearCount());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (count > 0) {
            dispatch(getAuditLogs());
        }
        return () => {
            dispatch(clearAuditLogs());
        };
    }, [count, dispatch, page, rowsPerPage]);

    if (auditLogs.length === 0) {
        return (<NoData />);
    }

    return (
        <Paper elevation={0}>
            <StyledTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell><Box color="text.disabled">Entity</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">Action</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">Executed by</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">Executed at</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">Keys</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">Old values</Box></StyledTableCell>
                            <StyledTableCell><Box color="text.disabled">New values</Box></StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auditLogs.map((auditLog, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>{auditLog.entityName}</StyledTableCell>
                                <StyledTableCell>{auditLog.action}</StyledTableCell>
                                <StyledTableCell>{auditLog.executedBy}</StyledTableCell>
                                <StyledTableCell>{auditLog.executedAt.toLocaleString(DateTime.DATETIME_SHORT)}</StyledTableCell>
                                <StyledTableCell>{auditLog.entityKeys}</StyledTableCell>
                                <StyledTableCell>{auditLog.oldValues}</StyledTableCell>
                                <StyledTableCell>{auditLog.newValues}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </Paper>
    );
};

export default AuditLogsTable;
