import { ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadSalesReferenceDate = (): AppThunk<Promise<DateTime>> => async (dispatch) => {
    try {
        const query = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Sales.SumLineValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const dateString = resultSet.rawData()[0]["D_Date.Date"];
        return DateTime.fromISO(dateString, { zone: "utc" });
    } catch (error) {
        dispatch(logError("Error loading SalesReferenceDate.", error));
        throw error;
    }
};
