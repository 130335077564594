import React from "react";
import { Grid } from "@material-ui/core";

import CostOpportunity from "./CostsOpportunity";
import CostAsPercentageOfRevenue from "./CostsAsPercentageOfRevenue";
import CostValue from "./CostsValue";
import CostMeasure from "./CostMeasure";
import CostOverTimeLineChartTitle from "./CostOverTimeLineChartTitle";
import CostsOverTimeLineChart from "./CostsOverTimeLineChart";

const CostOverTime: React.FC = () => {

    return (
        <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
                <CostOpportunity />
            </Grid>
            <Grid item md={4} xs={12}>
                <CostAsPercentageOfRevenue />
            </Grid>
            <Grid item md={4} xs={12}>
                <CostValue />
            </Grid>
            <Grid item xs={8}>
                <CostMeasure />
            </Grid>
            <Grid item xs={12}>
                <CostOverTimeLineChartTitle />
            </Grid>
            <Grid item xs={12}>
                <CostsOverTimeLineChart />
            </Grid>
        </Grid>
    );
};

export default CostOverTime;
