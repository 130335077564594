import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/catchment/footfall";

const FootfallAnalysis = () => {
    const footfallAnalysis = useSelector(state => selectors.footfallAnalysis(state));
    const { loading, error, id, label, status, value } = footfallAnalysis;

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default FootfallAnalysis;
