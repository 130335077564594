import React from "react";

import { Box, Typography } from "@material-ui/core";

import Gauge from "components/visuals/Gauge";
import Spacer from "components/Spacer";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import FractionIndicator from "components/visuals/FractionIndicator";
import { abs, max, median, min } from "mathjs";
import arrayUtils from "utils/arrayUtils";
import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression } from "utils/sortUtils";

interface RankedGaugeProps {
    dataCy?: string,
    isLoading: boolean,
    hasErrors: boolean,
    titleText: string,
    comparatorName: string,
    stores: RankedGaugeStore[],
    lowerIsBetter?: boolean
}

export interface RankedGaugeStore {
    storeId: string,
    isSelected: boolean,
    value: number
}

const RankedGauge: React.FC<RankedGaugeProps> = (props) => {
    const { dataCy, isLoading, hasErrors, titleText, comparatorName, stores, lowerIsBetter } = props;

    const selectedStoreValue = stores.find(store => store.isSelected)?.value ?? 0;
    const allValues = stores.map(store => store.value);
    const sortDirection = lowerIsBetter ? SortDirection.ASC : SortDirection.DESC;
    const sortedData = [...allValues].sort((a, b) => numberSortExpression(a, b, sortDirection));

    let minValue, maxValue, medianValue,
        firstTertile, secondTertile, variance,
        numerator, denominator, percentile, status, noData;

    const usePercentile = allValues.length >= 101;
    const rankLabel = usePercentile ? `Percentile in ${comparatorName}` : `Rank across ${comparatorName}`;
    if (usePercentile) {
        const thresholds = mathUtils.percentileThresholds(allValues, 100);
        percentile = thresholds.findIndex(threshold => selectedStoreValue <= threshold) + 1;
        if (percentile === 0) {
            percentile = 100;
        }
        if (lowerIsBetter) {
            percentile = abs(101 - percentile);
        }
    }

    if (allValues.length === 0 || allValues.every(value => !value)) {
        status = "noData";
        noData = true;
    } else {
        minValue = min(allValues);
        maxValue = max(allValues);
        medianValue = median(allValues);
        firstTertile = arrayUtils.quantile(allValues, 1 / 3);
        secondTertile = arrayUtils.quantile(allValues, 2 / 3);
        variance = mathUtils.safePercentageChange(selectedStoreValue, medianValue);

        numerator = 1 + sortedData.findIndex(item => item === selectedStoreValue);
        denominator = allValues.length;
        status = selectedStoreValue < firstTertile ? (lowerIsBetter ? "success" : "error")
            : selectedStoreValue <= secondTertile ? "warning"
                : (lowerIsBetter ? "error" : "success");
    }

    const options = {

    };

    return (
        <>
            <Box data-cy={`${dataCy}-gauge`}>
                <Gauge
                    loading={isLoading} error={hasErrors} options={options} data={selectedStoreValue} min={minValue}
                    max={maxValue} median={medianValue} firstTertile={firstTertile} secondTertile={secondTertile}
                    noData={noData} lowerIsBetter={lowerIsBetter} reverseYAxis={lowerIsBetter}
                />
            </Box>
            <Box display="flex" data-cy={`${dataCy}-title`} pb={1}>
                <Typography variant="h6">{titleText}</Typography>
            </Box>
            <Box display="flex" data-cy={`${dataCy}-variance`}>
                <Typography variant="body1">% variance from median</Typography>
                <Spacer />
                <PercentageIndicator loading={isLoading} error={hasErrors} align="right" showIndicator={false} value={variance} switchColor={lowerIsBetter} noDataMessage={"N/A"} />
            </Box>
            <Box display="flex" data-cy={`${dataCy}-rank`}>
                <Typography variant="body1">{rankLabel}</Typography>
                <Spacer />
                <FractionIndicator
                    loading={isLoading}
                    error={hasErrors}
                    numerator={usePercentile ? percentile : numerator}
                    denominator={denominator}
                    status={status}
                    ordinalNumerator={true}
                    hideDenominator={usePercentile}
                />
            </Box>
        </>
    );
};

export default RankedGauge;
