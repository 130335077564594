import { combineReducers } from "redux";

import types from "./types";

const budgetVsForecastContributionEmpty = {
    loading: false,
    error: false,
    quarterlyContribution: [],
    quarterlyBudget: []
};

const budgetVsForecastContribution = (state = budgetVsForecastContributionEmpty, action) => {
    switch (action.type) {
        case types.GET_BUDGET_VS_FORECAST_CONTRIBUTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                quarterlyContribution: [],
                quarterlyBudget: []
            };
        case types.GET_BUDGET_VS_FORECAST_CONTRIBUTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quarterlyContribution: action.payload.quarterlyContribution,
                quarterlyBudget: action.payload.quarterlyBudget
            };
        case types.GET_BUDGET_VS_FORECAST_CONTRIBUTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                quarterlyContribution: [],
                quarterlyBudget: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    budgetVsForecastContribution
});

export default reducer;
