import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Filter from "./Filter";
import Search from "./Search";
import Sort from "./Sort";
import StoreCard from "./StoreCard";
import Title from "./Title";

import {
    addStoreToCandidateComparatorStores,
    clearCentiles,
    removeStoreFromCandidateComparatorStores,
    selectCandidateComparatorStores,
    selectStores
} from "modules/customer/tools/location/filters/filtersSlice";
import { Store } from "modules/customer/tools/location/store";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const NUMBER_OF_VISIBLE_STORES_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const Comparators: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpend = featureFlags.enableCustomerToolsLocationSpendNew;
    const stores = useAppSelector(selectStores);
    
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const [numberOfVisibleStores, setNumberOfVisibleStores] = React.useState<number>(0);
    const storesLabel = `${stores.length} ${stores.length === 1 ? "store" : "stores"}`;

    const visibleStores = React.useMemo(() => {
        return stores.slice(0, numberOfVisibleStores);
    }, [stores, numberOfVisibleStores]);

    const handleSelect = (store: Store) => {
        dispatch(addStoreToCandidateComparatorStores(store));
    };

    const handleDeselect = (store: Store) => {
        dispatch(removeStoreFromCandidateComparatorStores(store));
    };

    const handleDeselectAllClick = () => {
        stores.forEach(store => {
            dispatch(removeStoreFromCandidateComparatorStores(store));
        });
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisibleStores(previousNumberOfVisibleStores => previousNumberOfVisibleStores + NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [setNumberOfVisibleStores]);

    React.useEffect(() => {
        setNumberOfVisibleStores(NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [stores]);

    React.useEffect(() => {
        dispatch(clearCentiles());
    }, [dispatch, candidateComparatorStores]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisibleStores <= stores.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                        <Typography variant="body1" component="div">
                            &nbsp;&nbsp;{storesLabel}
                        </Typography>
                        &nbsp;&nbsp;
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleDeselectAllClick}
                            data-cy="btn-deselect-all"
                        >
                            Deselect all
                        </Button>
                        <Spacer />
                        <Filter />
                        &nbsp;&nbsp;
                        <Sort enableSpend={enableSpend} />
                    </Box>
                </Box>
                {visibleStores.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visibleStores.map(store =>
                                    <Grid key={store.id} item xs={4}>
                                        <StoreCard
                                            store={store}
                                            selected={candidateComparatorStores.some(s => s.id === store.id)}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                            enableSpend={enableSpend}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default Comparators;
