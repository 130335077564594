import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIFact from "components/visuals/KPIFact";

import { selectors } from "modules/customer/insights/range/sales/historicSales";

const UnitSalesLastYear = () => {
   
    const unitSalesLastYear = useSelector((state) =>selectors.unitSalesLastYear(state));
    const { loading, error, /*placeholder*/ } = unitSalesLastYear;

    const valueFormatted = numberFormatter.toGBP("2000", 1);
    const label = "Unit sales last year KPI Fact placeholder";
   
    return (
        <Box width="100%" height="100%" data-cy="unit-sales-last-year">
            <KPIFact loading={loading} error={error} label={label} value={valueFormatted} />
        </Box>
    );
};

export default UnitSalesLastYear;

