import React from "react";
import { Grid } from "@material-ui/core";

import ProductCategoryGrowthFilter from "./ProductCategoryGrowthFilter";
import ProductCategoryGrowthVsComparator from "./ProductCategoryGrowthVsComparator";
import StoreVsComparatorProductCategoryGrowth from "./StoreVsComparatorProductCategoryGrowth";

const ProductCategoryGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProductCategoryGrowthFilter />
            </Grid>
            <Grid item xs={12}>
                <ProductCategoryGrowthVsComparator />
            </Grid>
            <Grid item xs={12}>
                <StoreVsComparatorProductCategoryGrowth />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryGrowth;
