import React from "react";
import { Box } from "@material-ui/core";
import Highcharts from "highcharts";

import MagicQuadrant from "components/visuals/MagicQuadrant";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectProductCategoryMixAndGrowth, selectProductCategoryMixFiltered } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import _ from "lodash";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const ProductCategoryMixMagicQuadrant = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const productCategoryMix = useAppSelector(selectProductCategoryMixFiltered);
    const unfilteredMix = useAppSelector(selectProductCategoryMixAndGrowth);
    const { isLoading, hasErrors } = productCategoryMix;

    const store = productCategoryMix.data.selectedStore;
    const comparator = productCategoryMix.data.comparator;

    const storeData = store.map(item => ({
        name: item.productCategory,
        x: item.mix,
        y: item.growth
    }));
    const comparatorData = comparator.map(item => ({
        name: item.productCategory,
        x: item.mix,
        y: item.growth
    }));

    const doQuadrants = (unfilteredMix.data.comparator.length > 1);

    let xMidPoint = 0;
    let yMidPoint = 0;

    if (doQuadrants) {
        xMidPoint = arrayUtils.median(unfilteredMix.data.comparator.map(row => row.mix));
        yMidPoint = arrayUtils.median(unfilteredMix.data.comparator.map(row => row.growth));
    }

    const tooltipNumberFormatter = (number: any) => {
        return !isNaN(number) ? numberFormatter.toPercentage(number) : "Unavailable";
    };

    const options: Highcharts.Options = {
        chart: {
            height: 600
        },
        title: {
            text: `Historic sales mix (%) in the last year vs year-on-year growth (%)`,
            margin: 30
        },
        xAxis: {
            title: {
                text: "Mix (%)"
            },
            maxPadding: 0.5,
            minPadding: 0.5,
            labels: {
                enabled: true
            }
        },
        yAxis: {
            title: {
                text: "Year-on-year growth (%)"
            },
            maxPadding: 0.5,
            minPadding: 0.5,
            labels: {
                enabled: true
            }
        },
        tooltip: {
            headerFormat: "<table>",
            pointFormatter: function (): string {
                const pointName = this.name;
                const series = this.series;
                const seriesName = series.name;
                const seriesIndex = series.index;
                const seriesColor = series.color;
                const xAxisTitle = series.xAxis.options.title?.text?.replace("(%)", "");
                const yAxisTitle = "Growth";

                let otherSeriesIndex = -1;

                if (seriesIndex === 0) {
                    otherSeriesIndex = 1;
                } else {
                    otherSeriesIndex = 0;
                }

                const otherSeries = series.chart.series[otherSeriesIndex];
                const otherSeriesName = otherSeries?.name?.replace("(%)", "");
                const otherSeriesColor = otherSeries?.color;

                const x = this.x;
                const otherPoint = _.find(otherSeries?.points, function (obj) {
                    return obj?.name === pointName;
                });
                const otherX = otherPoint?.x;

                const y = this.y;
                const otherY = otherPoint?.y;

                const categoryArr = [seriesName, xAxisTitle, yAxisTitle, otherSeriesName, xAxisTitle, yAxisTitle];
                const categoryFormatArr = [`color:${seriesColor};font-weight:bold`, "", "",
                    `color:${otherSeriesColor};font-weight:bold`, "", ""];
                const valueArr = ["", tooltipNumberFormatter(x), tooltipNumberFormatter(y),
                    "", tooltipNumberFormatter(otherX), tooltipNumberFormatter(otherY)];

                const headerRow = pointName;

                return stringUtils.tooltipHTML(categoryArr, { values: valueArr, header: headerRow, categoryFormattingArr: categoryFormatArr });
            },
            footerFormat: "</table>",
            useHTML: true
        },
        series: [{
            name: selectedStore?.name,
            data: storeData,
            color: colourPalette.comparators[0],
            type: "scatter"
        }, {
            name: selectedComparator?.name,
            data: comparatorData,
            color: colourPalette.comparators[1],
            type: "scatter"
        }]
    };

    return (
        <Box data-cy="product-category-mix-magic-quadrant">
            <MagicQuadrant
                loading={isLoading}
                error={hasErrors}
                options={options}
                topLeftLabel="Growing performers"
                topRightLabel="Best performers"
                bottomRightLabel="Steady performers"
                bottomLeftLabel="Worst performers"
                doQuadrants={doQuadrants}
                xMidPoint={xMidPoint}
                yMidPoint={yMidPoint}
                xAxisReversed={false}
            />
        </Box>
    );
};

export default ProductCategoryMixMagicQuadrant;
