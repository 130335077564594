import { combineReducers } from "redux";

import forecastRevenue from "./forecastRevenue";
import forecastGrowthVsMarket from "./forecastGrowthVsMarket";
import regionalBreakdown from "./regionalBreakdown";

const reducer = combineReducers({
    forecastRevenue,
    forecastGrowthVsMarket,
    regionalBreakdown
});

export default reducer;
