import React from "react";

import { Box, Typography } from "@material-ui/core";
import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectNumberOfStoresInLocalArea
} from "modules/customer/tools/location/areaHealth/areaHealthSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

const NumberOfStoresInLocalArea: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const comparators = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparators?.areaHealth.storeName;
    const numberOfStoresInLocalArea = useAppSelector(selectNumberOfStoresInLocalArea);
    const retailCentreValue = numberOfStoresInLocalArea.retailCentre;
    const comparatorValue = numberOfStoresInLocalArea.comparator;
    const difference = retailCentreValue - comparatorValue;

    return (
        <Box width="100%" height="100%" data-cy="number-of-stores-in-local-area">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {retailCentreValue}
                    </Typography>
                }
                targetLabel="Number of stores within the local area of the selected location"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparatorValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparatorName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {difference}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default NumberOfStoresInLocalArea;
