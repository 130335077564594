import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { hideFilters, selectFiltersVisibility } from "modules/customer/insights/cost/filters/filtersSlice";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import Actions from "./Actions";
import Content from "./Content";
import Open from "./Open";
import Stepper from "./Stepper";
import Title from "./Title";

const Filters: React.FC = () => {
    const dispatch = useAppDispatch();
    const filtersVisibility = useAppSelector(selectFiltersVisibility);
    const isVisible = filtersVisibility.isVisible;

    const handleClose = () => {
        dispatch(hideFilters());
    };

    return (
        <>
            <Open />
            <TwoPanelDialog
                open={isVisible}
                onClose={handleClose}
                leftPanelTitle={<Title />}
                leftPanelContent={<Stepper />}
                leftPanelActions={<Actions />}
                leftPanelActionsJustifyContent={JustifyContent.SpaceBetween}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default Filters;
