import React from "react";

const ForecastSubtitle: React.FC = () => {
    return (
        <>
            Now let's look at the direction of your company's performance, and its future growth prospects. You can also
            compare your forecast to the market as a whole, and see it broken down by region
        </>
    );
};

export default ForecastSubtitle;
