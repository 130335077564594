import React from "react";

import { Box } from "@material-ui/core";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";

import Legend from "./Legend";

import { selectFootfallPerOutputArea, selectIsLoading, selectHasErrors } from "modules/customer/insights/portfolioNew/footfall/footfallSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import SelectedStoreMarker from "components/visuals/maps/SelectedStoreMarker";

enum ColourGroup {
    VeryLow,
    Low,
    Medium,
    High,
    VeryHigh
}

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const colours = [colourPalette.sequential[4], colourPalette.sequential[3], colourPalette.sequential[2], colourPalette.sequential[1], colourPalette.sequential[0]];
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const outputAreas = useAppSelector(selectFootfallPerOutputArea);
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const selectedStore = useAppSelector(selectStore);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredOutputArea = outputAreas.find(outputArea => outputArea.outputAreaCode === hoverInfo.outputAreaCode);

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: selectedStore?.latitude ?? 0,
        longitude: selectedStore?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });
    const mapOutputAreas = React.useMemo(() => {
        return outputAreas.map(outputArea => ({
            code: outputArea.outputAreaCode,
            colourGroup: outputArea.currentYearFootfall >= 151
                ? ColourGroup.VeryHigh
                : outputArea.currentYearFootfall >= 61
                    ? ColourGroup.High
                    : outputArea.currentYearFootfall >= 31
                        ? ColourGroup.Medium
                        : outputArea.currentYearFootfall >= 11 ? ColourGroup.Low : ColourGroup.VeryLow
        }));
    }, [outputAreas]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: selectedStore?.latitude ?? 0,
                longitude: selectedStore?.longitude ?? 0
            };
        });
    }, [selectedStore]);

    return (
        <Box data-cy="footfall-map">
            <OutputAreasMap
                loading={isLoading}
                error={hasErrors}
                title={`${selectedStore?.name} store's catchment area by footfall level in the last year`}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colours}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                displayPOIs={true}
                downloadData={outputAreas}
            >
                <SelectedStoreMarker latitude={selectedStore?.latitude ?? 0} longitude={selectedStore?.longitude ?? 0} />
                <Legend outputArea={hoveredOutputArea} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
