import { combineReducers } from "redux";

import types from "./types";

const historicProdGrowthStoreVsCompEmpty = {
    loading: false,
    error: false,
    values: []
};

const historicProdGrowthStoreVsComp = (state = historicProdGrowthStoreVsCompEmpty, action) => {
    switch (action.type) {
        case types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values
            };
        case types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    historicProdGrowthStoreVsComp
});

export default reducer;
