import React from "react";
import { useDispatch } from "react-redux";

import { Box, Grid } from "@material-ui/core";

import AddUser from "./AddUser";
import FilterUsers from "./FilterUsers";
import Licenses from "./Licenses";
import UsersTable from "./UsersTable";

import { operations } from "modules/customer/account/users";

const Users = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(operations.getUsers());
    }, [dispatch]);

    return (
        <Box data-cy="pages-customer-account-users">
            <Grid container spacing={4}>
                <Grid item xs={2}>
                    <AddUser />
                </Grid>
                <Grid item xs={3}>
                    <FilterUsers />
                </Grid>
                <Grid item xs={7} />
                <Grid item xs={2}>
                    <Licenses />
                </Grid>
                <Grid item xs={10}>
                    <UsersTable />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Users;
