import React from "react";
import { Popup } from "react-map-gl";
import numberFormatter from "utils/numberFormatter";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {useAppSelector} from "store";
import { selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const MarketCatSpendPerHeadMapPopup = (props) => {
    const { spendPerHead, comparatorSpendPerHead, hoverInfo, clickInfo } = props;
    const selectedComparator = useAppSelector(selectComparator);
    const theme = useTheme();
    const popup = React.useMemo(() => {
        const outputAreaCode = clickInfo.outputAreaCode ?? hoverInfo.outputAreaCode;
        const outputArea = spendPerHead.find(item => item.outputAreaCode === outputAreaCode);
        if (!outputArea) {
            return null;
        }
        const latitude = clickInfo.outputAreaCode ? clickInfo.latitude : hoverInfo.latitude;
        const longitude = clickInfo.outputAreaCode ? clickInfo.longitude : hoverInfo.longitude;
        return {
            latitude,
            longitude,
            outputArea
        };
    }, [hoverInfo, clickInfo, spendPerHead]);

    if (!popup) {
        return null;
    }

    return (
        <Popup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={false}
            anchor="bottom"
        >
            <Box minWidth={theme.spacing(18)}>
                <Box paddingTop={1} display="flex">
                    <Typography variant="body2" component="div">
                        OA Spend Per Head&nbsp;&nbsp;
                    </Typography>
                    <Spacer />
                    <Typography variant="subtitle2" component="div">
                        {numberFormatter.toGBP(popup.outputArea.value)}
                    </Typography>
                </Box>
                <Box paddingTop={1} display="flex">
                    <Typography variant="body2" component="div">
                        {selectedComparator.name} median&nbsp;&nbsp;
                    </Typography>
                    <Spacer />
                    <Typography variant="subtitle2" component="div">
                        {numberFormatter.toGBP(comparatorSpendPerHead)}
                    </Typography>
                </Box>
            </Box>
        </Popup>
    );
};

MarketCatSpendPerHeadMapPopup.propTypes = {
    spendPerHead: PropTypes.array.isRequired,
    comparatorSpendPerHead: PropTypes.number.isRequired,
    hoverInfo: PropTypes.object,
    clickInfo: PropTypes.object
};

export default MarketCatSpendPerHeadMapPopup;
