import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Bullet from "components/visuals/Bullet";

import { operations, selectors } from "modules/customer/insights/performance/budget/revenueVsBudget";

const SalesPerformanceVsBudget = () => {
    const dispatch = useDispatch();
    const salesPerformanceVsBudget = useSelector(state => selectors.salesPerformanceVsBudget(state));
    const { loading, error, salesFYTD, salesFYTG, budgetFY } = salesPerformanceVsBudget;

    const noBudgetData = (budgetFY === 0) ? true : false;

    React.useEffect(() => {
        dispatch(operations.getSalesPerformanceVsBudget());
    }, [dispatch]);

    return (
        <Box data-cy="sales-performance-vs-budget">
            <Bullet
                loading={loading}
                error={error}
                actuals={salesFYTD}
                actualsTitle="Historic revenue"
                forecasts={salesFYTG}
                forecastTitle="Forecast revenue"
                title="Historic and forecast revenue vs budgeted revenue"
                budget={budgetFY}
                noData={noBudgetData}
            />
        </Box>
    );
};

export default SalesPerformanceVsBudget;
