import React from "react";
import { Box } from "@material-ui/core";
import HighStock from "highcharts/highstock";
import drilldown from "highcharts/modules/drilldown.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";

import _ from "lodash";

import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

import Progress from "./Progress";
import Error from "./Error";


require ("highcharts/modules/exporting")(HighStock);
require ("highcharts/modules/offline-exporting")(HighStock);
require ("highcharts/modules/export-data")(HighStock);
require ("highcharts/modules/full-screen");

drilldown(HighStock);
DragPanes(HighStock);

//TODO remove post upgrade to Highcharts 11:
(function (factory) {

    if (typeof module === "object" && module.exports) {
        module.exports = factory;
    } else {
        factory(HighStock);
    }
}(function (H) {
    let rel = H.relativeLength;

    H.wrap(H.seriesTypes.column.prototype, "translate", function (proceed) {
        let options = this.options,
            topMargin = options.topMargin || 0,
            bottomMargin = options.bottomMargin || 0;

        proceed.call(this);

        this.points.forEach(function (point) {
            let shapeArgs = point.shapeArgs,
                w = shapeArgs.width,
                h = shapeArgs.height,
                x = shapeArgs.x,
                y = shapeArgs.y;

            // Get the radius
            let rTopLeft, rTopRight, rBottomRight, rBottomLeft;
            if (point.y < 0) {
                rTopLeft = rel(options.borderRadiusBottomLeft || 0, w);
                rTopRight = rel(options.borderRadiusBottomRight || 0, w);
                rBottomRight = rel(options.borderRadiusTopLeft || 0, w);
                rBottomLeft = rel(options.borderRadiusTopRight || 0, w);
            } else {
                rTopLeft = rel(options.borderRadiusTopLeft || 0, w);
                rTopRight = rel(options.borderRadiusTopRight || 0, w);
                rBottomRight = rel(options.borderRadiusBottomRight || 0, w);
                rBottomLeft = rel(options.borderRadiusBottomLeft || 0, w);
            }

            if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
                let maxR = Math.min(w, h) / 2;

                if (rTopLeft > maxR) {
                    rTopLeft = maxR;
                }

                if (rTopRight > maxR) {
                    rTopRight = maxR;
                }

                if (rBottomRight > maxR) {
                    rBottomRight = maxR;
                }

                if (rBottomLeft > maxR) {
                    rBottomLeft = maxR;
                }

                // Preserve the box for data labels
                point.dlBox = point.shapeArgs;

                point.shapeType = "path";
                point.shapeArgs = {
                    d: [
                        ["M", x + rTopLeft, y + topMargin],
                        // top side
                        ["L", x + w - rTopRight, y + topMargin],
                        // top right corner
                        ["C", x + w - rTopRight / 2, y, x + w, y + rTopRight / 2, x + w, y + rTopRight],
                        // right side
                        ["L", x + w, y + h - rBottomRight],
                        // bottom right corner
                        ["C", x + w, y + h - rBottomRight / 2, x + w - rBottomRight / 2, y + h, x + w - rBottomRight, y + h + bottomMargin],
                        // bottom side
                        ["L", x + rBottomLeft, y + h + bottomMargin],
                        // bottom left corner
                        ["C", x + rBottomLeft / 2, y + h, x, y + h - rBottomLeft / 2, x, y + h - rBottomLeft],
                        // left side
                        ["L", x, y + rTopLeft],
                        // top left corner
                        ["C", x, y + rTopLeft / 2, x + rTopLeft / 2, y, x + rTopLeft, y],
                        ["Z"]
                    ]
                };
            }
        });
    });
}));

const ChartStockBase = (props) => {
    const { dataCy, loading, error, options: customOptions, globalOptions: customGlobalOptions } = props;
    const theme = useTheme();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const boxRef = React.useRef(null);
    const chartRef = React.useRef(null);

    const staticGlobalOptions = {
        lang: {
            thousandsSep: ",",
            rangeSelectorZoom: "Granularity",
            numericSymbols: ["k", "m", "bn", "tn"],
        },
    };
    const staticOptions = {
        chart: {
            backgroundColor: theme.palette.background.insight,
            style: {
                fontFamily: "Open Sans"
            }
        },
        exporting: {
            enabled: featureFlags.enableCustomerHighchartsExporting,
            fallbackToServer: false,
            buttons: {
                contextButton: {
                    enabled: true,
                    menuItems: [
                        "viewFullscreen",
                        "separator",
                        "downloadPNG",
                        "downloadJPEG",
                        "separator",
                        'downloadCSV',
                        'downloadXLS',
                        'viewData'
                    ],
                    symbol: '',
                    text: "●●●",
                }
            }
        },
        navigation: {
            menuStyle: {
                background: theme.palette.quaternary.main,
                borderRadius: "6px",
                boxShadow: 'none',
                strokeWidth: '10px'
            },
            menuItemStyle: {
                color: '#FFFFFF'
            },
            menuItemHoverStyle: {
                background: theme.palette.quaternary.light,
                color: '#FFFFFF'
            },
            buttonOptions: {
                height: 24,
                width: 24,
                borderRadius: "6px",
                theme: {
                    fill: theme.palette.background.insight,
                    style: {
                        color: theme.palette.text.disabled,
                    },
                    states: {
                        hover: {
                            fill: theme.palette.quaternary.dark,
                            style: {
                                color: theme.palette.text.disabled,
                            }
                        },
                        select: {
                            fill: theme.palette.background.insight,
                            style: {
                                color: theme.palette.text.disabled,
                            }
                        }
                    }
                }
            }
        },
        plotOptions: {
            column: {
                //TODO remove post upgrade to Highcharts 11:
                borderRadiusTopLeft: "20%",
                borderRadiusTopRight: "20%",
                //TODO uncomment post upgrade to Highcharts 11:
                // maxPointWidth: 34,
                // borderRadius: {
                //     radius: "20%",
                //     scope: "stack",
                //     where: "end"
                // }
            },
            bar: {
                //TODO remove post upgrade to Highcharts 11:
                borderRadiusTopLeft: "20%",
                borderRadiusTopRight: "20%"
                //TODO uncomment post upgrade to Highcharts 11:
                // maxPointWidth: 34,
                // borderRadius: {
                //     radius: "20%",
                //     scope: "stack",
                //     where: "end"
                // }
            },
            spline: {
                lineWidth: 3
            }
        },
        scrollbar: {
            barBackgroundColor: "#3A384F",
            trackBackgroundColor: "#282041",
            buttonBackgroundColor: "#3A384F",
            trackBorderColor: "#3A384F",
            barBorderColor: "#3A384F",
            rifleColor: "gray",

            buttonArrowColor: "white",
            buttonBorderRadius: 4
        },

        title: {
            style: {
                color: theme.palette.common.white
            },
            align: "left",
            text: ""
        },
        subtitle: {
            style: {
                color: theme.palette.common.white
            },
            text: ""
        },
        legend: {
            itemStyle: {
                color: theme.palette.common.white,
                fontSize: "12px"
            }
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            },
            scrollbar: {
                enabled: false,
                barBackgroundColor: "#3A384F",
                trackBackgroundColor: "#282041",
                buttonBackgroundColor: "#3A384F",
                trackBorderColor: "#3A384F",
                barBorderColor: "#3A384F",
                rifleColor: "gray",

                buttonArrowColor: "white",
                buttonBorderRadius: 4
            }
        },
        yAxis: {
            gridLineWidth: 1,
            minorGridLineWidth: 0,
            gridLineColor: "#646174",
            opposite: false,
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            },
            title: {
                style: {
                    color: theme.palette.common.white
                }
            }
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: theme.palette.common.white,
                textDecoration: "none"
            },
            activeDataLabelStyle: {
                color: theme.palette.common.white,
                textDecoration: "none"
            },
            breadcrumbs: {
                format: " < Back",
                floating: true,
                buttonTheme: {
                    fill: "#454159",
                    padding: 8,
                    "stroke-width": 0,
                    style: {
                        color: "white",
                        fontWeight: "bold"
                    },
                    states: {
                        hover: {
                            fill: "#454159"
                        }
                    }
                },
                position: {
                    align: "right",
                    verticalAlign: "bottom"
                },
                showFullPath: false
            },
        },
        tooltip: {
            style: {
                color: "#FFFFFF",
            },
            backgroundColor: "#3A384F",
            borderRadius: 6,
            borderWidth: 0,
            shadow: false,
            padding: 12
        },

        colors: ["#42E8E0", "#1C76FD", "#F755C9", "#E9730C", "#FF5C67", "#F5D423", "#786EB6"]
    };

    const options = _.merge({}, staticOptions, customOptions);
    const globalOptions = _.merge({}, staticGlobalOptions, customGlobalOptions);

    HighStock.setOptions(globalOptions);

    React.useEffect(() => {
        chartRef?.current?.chart?.reflow(false);
    }, [boxRef?.current?.clientHeight, boxRef?.current?.clientWidth, options, chartRef]);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Box ref={boxRef} data-cy={dataCy}>
            <HighchartsReact ref={chartRef} highcharts={HighStock} constructorType={"stockChart"} options={options} />
        </Box>
    );
};

ChartStockBase.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    globalOptions: PropTypes.object
};

ChartStockBase.defaultProps = {
    loading: false,
    error: false
};

export default ChartStockBase;
