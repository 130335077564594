import React from "react";

import { Box, Typography } from "@material-ui/core";


const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Product setup
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    To begin, select a retail partner to explore headroom opportunities and tailor insights to their unique store network. 
                </Typography>
            </Box>
        </>
    );
};

export default Title;
