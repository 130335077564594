import React from "react";

import RAGIndicator from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectRevenuePerPoundOfStaffCostCategorisation } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const RevenuePerPoundOfStaffCostCategorisation = () => {
    const categorisation = useAppSelector(selectRevenuePerPoundOfStaffCostCategorisation);

    return (
        <RAGIndicator ragIndicator={categorisation} />
    );
};

export default RevenuePerPoundOfStaffCostCategorisation;
