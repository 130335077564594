import React from "react";
import { FillPaint } from "mapbox-gl";
import { Layer, LayerProps, MapRef, Source, SourceProps, ViewportProps } from "react-map-gl";
import { default as turfCircle } from "@turf/circle";
import { Feature, Polygon } from "@turf/helpers";

import MapBase from "./MapBase";

const INNER_CIRCLE_SOURCE_ID = "inner-circle-source";
const INNER_CIRCLE_LAYER_ID = "inner-circle";
const MIDDLE_CIRCLE_SOURCE_ID = "middle-circle-source";
const MIDDLE_CIRCLE_LAYER_ID = "middle-circle";
const OUTER_CIRCLE_SOURCE_ID = "outer-circle-source";
const OUTER_CIRCLE_LAYER_ID = "outer-circle";

interface MapCenter {
    latitude: number,
    longitude: number
}

const getCircleData = (mapCenter: MapCenter, radiusInKm: number) => {
    return turfCircle(
        [mapCenter.longitude, mapCenter.latitude],
        radiusInKm,
        { units: "kilometers" });
};

const createMapSource = (id: string, data: Feature<Polygon>): SourceProps => {
    return {
        id,
        type: "geojson",
        data
    };
};

const createMapLayer = (id: string, sourceId: string, colour: string): LayerProps => {
    const paint: FillPaint = {
        "fill-color": colour,
        "fill-opacity": 0.4
    };
    return {
        id: id,
        type: "fill",
        source: sourceId,
        paint
    };
};

interface ConcentricCirclesMapProps {
    loading: boolean,
    error: boolean,
    mapboxAccessToken: string,
    mapboxBaseMapStyle: string,
    mapCenter: MapCenter,
    colours: string[],
    onClick?: () => void,
    downloadData?: object[]
    children: React.ReactNode
}

const ConcentricCirclesMap: React.FC<ConcentricCirclesMapProps> = (props) => {
    const {
        loading, error,
        mapboxAccessToken, mapboxBaseMapStyle,
        mapCenter,
        colours,
        onClick,
        downloadData
    } = props;
    const mapRef = React.useRef<MapRef>(null);
    const initialViewport = React.useMemo((): ViewportProps => {
        return {
            latitude: mapCenter.latitude,
            longitude: mapCenter.longitude,
            zoom: 11
        };
    }, [mapCenter]);

    const innerCircleSource = React.useMemo(() => {
        const data = getCircleData(mapCenter, 1);
        return createMapSource(INNER_CIRCLE_SOURCE_ID, data);
    }, [mapCenter]);

    const innerCircleLayer = React.useMemo(() => {
        return createMapLayer(INNER_CIRCLE_LAYER_ID, INNER_CIRCLE_SOURCE_ID, colours[0]);
    }, [colours]);

    const middleCircleSource = React.useMemo(() => {
        const data = getCircleData(mapCenter, 2);
        return createMapSource(MIDDLE_CIRCLE_SOURCE_ID, data);
    }, [mapCenter]);

    const middleCircleLayer = React.useMemo(() => {
        return createMapLayer(MIDDLE_CIRCLE_LAYER_ID, MIDDLE_CIRCLE_SOURCE_ID, colours[1]);
    }, [colours]);

    const outerCircleSource = React.useMemo(() => {
        const data = getCircleData(mapCenter, 5);
        return createMapSource(OUTER_CIRCLE_SOURCE_ID, data);
    }, [mapCenter]);

    const outerCircleLayer = React.useMemo(() => {
        return createMapLayer(OUTER_CIRCLE_LAYER_ID, OUTER_CIRCLE_SOURCE_ID, colours[2]);
    }, [colours]);

    const handleClick = React.useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    return (
        <MapBase
            loading={loading}
            error={error}
            mapboxAccessToken={mapboxAccessToken}
            mapboxBaseMapStyle={mapboxBaseMapStyle}
            mapRef={mapRef}
            initialViewport={initialViewport}
            addGeocoder={false}
            addNavigationControl={true}
            addRecenterButton={true}
            onClick={handleClick}
            addFullscreenButton={true}
            downloadData={downloadData}
            dataCy="concentric-circles-map"
        >
            <Source {...outerCircleSource}>
                <Layer {...outerCircleLayer} />
            </Source>
            <Source {...middleCircleSource}>
                <Layer {...middleCircleLayer} />
            </Source>
            <Source {...innerCircleSource}>
                <Layer {...innerCircleLayer} />
            </Source>
            {props.children}
        </MapBase>
    );
};

export default ConcentricCirclesMap;
