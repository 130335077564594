import { median } from "mathjs";

import actions from "./actions";
import { cubeLoad, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getCompetitionScoreVis = () => async (dispatch, getState) => {
    dispatch(actions.getCompetitionScoreVisRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const comparatorStoresSelector = selectComparator(state);

        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat(selectedStoreID);

        const query = {
            dimensions: [
                "F_CompetitionScore.StoreNaturalID",
                "F_CompetitionScore.CompetitionScore"
            ],
            filters: [{
                member: "F_CompetitionScore.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const compScoreData = resultSet.loadResponses[0].data.map((item) => ({
            competitionScore: item["F_CompetitionScore.CompetitionScore"],
            storeID: item["F_CompetitionScore.StoreNaturalID"],
        }));

        const selectedData = compScoreData.find(
            (item) => item.storeID === selectedStoreID
        );
        const selectedStoreCompScore = selectedData.competitionScore;

        const comparatorData = compScoreData.filter((item) =>
            comparatorStoresIDs.includes(item.storeID)
        );
        const comparatorMedianCompScore = median(
            ...comparatorData.map((item) => item.competitionScore)
        );

        dispatch(
            actions.getCompetitionScoreVisSuccess(
                selectedStoreCompScore,
                comparatorMedianCompScore
            )
        );
    }
    catch (error) {
        dispatch(actions.getCompetitionScoreVisFailure());
        dispatch(logError("Error loading CompetitionScoreVis.", error));
    }
};

const getCompetitionScoreRag = () => async (dispatch, getState) => {
    dispatch(actions.getCompetitionScoreRagRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const query = {
            dimensions: [
                "F_CompetitionScore.StoreNaturalID",
                "F_CompetitionScore.CompetitionScore"
            ],
            filters: [{
                member: "F_CompetitionScore.StoreNaturalID",
                operator: "equals",
                values: [selectedStoreID]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const compScore = resultSet.loadResponses[0].data[0]["F_CompetitionScore.CompetitionScore"];

        let status = "";
        let value = "";

        if (compScore > 66.6) {
            status = "success";
            value = `Your ${selectedStoreSelector.name} store has clear competitive advantages, which should lead to this 
            store outperforming competitors in the local area`;
        } else if (compScore < 33.33) {
            status = "error";
            value = `Your ${selectedStoreSelector.name} store faces a highly competitive landscape, which should lead 
            to this store struggling to compete in the local area`;
        } else {
            status = "warning";
            value = `Your ${selectedStoreSelector.name} store should be able to compete with the competition in the local area`;
        }

        dispatch(actions.getCompetitionScoreRagSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getCompetitionScoreRagFailure());
        dispatch(logError("Error loading CompetitionScoreRag.", error));
    }
};

const operations = {
    getCompetitionScoreVis,
    getCompetitionScoreRag,
};

export default operations;
