import React from "react";
import { Marker as MapGLMarker } from "react-map-gl";

import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import { useTheme } from "@material-ui/core/styles";

import { selectSelectedStoreCoreRetailHub } from "modules/customer/insights/portfolioNew/competition/competitionSlice";
import { useAppSelector } from "store";

const CoreRetailHub: React.FC = () => {
    const theme = useTheme();
    const selectedStoreCoreRetailHub = useAppSelector(selectSelectedStoreCoreRetailHub);

    if (!selectedStoreCoreRetailHub) {
        return null;
    }

    return (
        <MapGLMarker
            latitude={selectedStoreCoreRetailHub.latitude}
            longitude={selectedStoreCoreRetailHub.longitude}
            offsetTop={-10}
            offsetLeft={-12}
        >
            <RadioButtonCheckedRoundedIcon fontSize="small" style={{ color: theme.palette.common.white }} />
        </MapGLMarker>
    );
};

export default CoreRetailHub;
