import React from "react";

import { Button, MenuItem, TextField, Typography } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import AddAccountConfirmation from "./AddAccountConfirmation";

import {
    addAccount,
    clearAccount,
    hideAddAccount,
    selectAddAccountVisibility,
    selectAccount,
    setAccount,
    showAddAccountConfirmation
} from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(2)
    }
}))(Button);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

const AddAccount: React.FC = () => {
    const dispatch = useAppDispatch();
    const account = useAppSelector(selectAccount);
    const addAccountVisibility = useAppSelector(selectAddAccountVisibility);
    const show = addAccountVisibility.isVisible;

    const handleAddAccountClick = () => {
        dispatch(showAddAccountConfirmation());
    };

    const handleSubmitClick = () => {
        dispatch(addAccount());
    };

    const handleCloseClick = () => {
        dispatch(hideAddAccount());
        dispatch(clearAccount());
    };

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyName = event.target.value;
        const newAccount = { ...account, companyName, errors: { ...account.errors, companyName: "" } };
        dispatch(setAccount(newAccount));
    };

    const handleCompanyRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyRegistrationNumber = event.target.value;
        const newAccount = {
            ...account,
            companyRegistrationNumber,
            errors: { ...account.errors, companyRegistrationNumber: "" }
        };
        dispatch(setAccount(newAccount));
    };

    const handleInitialTermInYearsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const initialTermInYears = Number(event.target.value);
        const newAccount = { ...account, initialTermInYears, errors: { ...account.errors, initialTermInYears: "" } };
        dispatch(setAccount(newAccount));
    };

    return (
        <>
            <StyledButton
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddRoundedIcon />}
                disableElevation
                onClick={handleAddAccountClick}
            >
                Add account
            </StyledButton>
            <SimpleDialog
                open={show}
                onClose={handleCloseClick}
                title={
                    <Typography variant="h5" component="div">Add account</Typography>
                }
                content={
                    <>
                        <StyledTextField
                            label="Company name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={account.companyName}
                            onChange={handleCompanyNameChange}
                            error={!!account.errors.companyName}
                            helperText={account.errors.companyName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Company registration number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={account.companyRegistrationNumber}
                            onChange={handleCompanyRegistrationNumberChange}
                            error={!!account.errors.companyRegistrationNumber}
                            helperText={account.errors.companyRegistrationNumber}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Initial term"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={account.initialTermInYears}
                            onChange={handleInitialTermInYearsChange}
                            error={!!account.errors.initialTermInYears}
                            helperText={account.errors.initialTermInYears}
                            fullWidth
                        >
                            <MenuItem value={1}>1 year</MenuItem>
                            <MenuItem value={2}>2 years</MenuItem>
                            <MenuItem value={3}>3 years</MenuItem>
                            <MenuItem value={4}>4 years</MenuItem>
                            <MenuItem value={5}>5 years</MenuItem>
                        </StyledTextField>
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Add account
                    </Button>
                }
            />
            <AddAccountConfirmation />
        </>
    );
};

export default AddAccount;
