import React from "react";

import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    deleteDataRefresh,
    hideDeleteDataRefresh,
    selectDeleteDataRefreshVisibility,
    showDeleteDataRefresh
} from "modules/admin/accounts/dataRefreshes/dataRefreshesSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteDataRefreshProps {
    accountId: string,
    dataRefreshId: string
}

const DeleteDataRefresh: React.FC<DeleteDataRefreshProps> = (props) => {
    const { accountId, dataRefreshId } = props;
    const dispatch = useAppDispatch();
    const deleteDataRefreshVisibility = useAppSelector(selectDeleteDataRefreshVisibility);
    const show = (deleteDataRefreshVisibility.isVisible) && (dataRefreshId === deleteDataRefreshVisibility.dataRefreshId);

    const handleDeleteDataRefreshClick = () => {
        dispatch(showDeleteDataRefresh(dataRefreshId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteDataRefresh(accountId, dataRefreshId));
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteDataRefresh());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteDataRefreshClick}>
                    <DeleteIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={<></>}
                titleAlign="center"
                content={
                    <Typography variant="h5" component="div">
                        Are you sure you want to delete this data refresh?
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button variant="outlined" onClick={handleCancelClick}>
                            No, cancel
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={handleConfirmClick}>
                            Yes, delete
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default DeleteDataRefresh;
