import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import EditAdmin from "./EditAdmin";
import DeleteAdmin from "./DeleteAdmin";

import { getAdmins, selectAdmins } from "modules/admin/admins/adminsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const AdminsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const admins = useAppSelector(selectAdmins);

    React.useEffect(() => {
        dispatch(getAdmins());
    }, [dispatch]);

    if (admins.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell><Box color="text.disabled">Name</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Email</Box></StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {admins.map((admin) => (
                        <TableRow key={admin.id}>
                            <StyledTableCell>{admin.firstName} {admin.lastName}</StyledTableCell>
                            <StyledTableCell>{admin.email}</StyledTableCell>
                            <StyledTableCell align="right">
                                <EditAdmin adminId={admin.id} />
                                <DeleteAdmin adminId={admin.id} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default AdminsTable;
