import types from "./types";

const getNumberOfHouseholdsRequest = () => ({
    type: types.GET_NUMBER_OF_HOUSEHOLDS_REQUEST
});

const getNumberOfHouseholdsSuccess = ( store, comparator ) => ({
    type: types.GET_NUMBER_OF_HOUSEHOLDS_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getNumberOfHouseholdsFailure = () => ({
    type: types.GET_NUMBER_OF_HOUSEHOLDS_FAILURE
});

const getMarketShareOverTimeRequest = () => ({
    type: types.GET_MARKET_SHARE_OVER_TIME_REQUEST
});

const getMarketShareOverTimeSuccess = (store, comparator) => ({
    type: types.GET_MARKET_SHARE_OVER_TIME_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getMarketShareOverTimeFailure = () => ({
    type: types.GET_MARKET_SHARE_OVER_TIME_FAILURE
});

const getOpeningsAndClosuresRequest = () => ({
    type: types.GET_OPENINGS_AND_CLOSURES_REQUEST
});

const getOpeningsAndClosuresSuccess = (openDirect, closeDirect, openOther, closeOther) => ({
    type: types.GET_OPENINGS_AND_CLOSURES_SUCCESS,
    payload: {
        openDirect,
        closeDirect,
        openOther,
        closeOther
    }
});

const getOpeningsAndClosuresFailure = () => ({
    type: types.GET_OPENINGS_AND_CLOSURES_FAILURE
});

const actions = {
    getNumberOfHouseholdsRequest,
    getNumberOfHouseholdsSuccess,
    getNumberOfHouseholdsFailure,
    getMarketShareOverTimeRequest,
    getMarketShareOverTimeSuccess,
    getMarketShareOverTimeFailure,
    getOpeningsAndClosuresRequest,
    getOpeningsAndClosuresSuccess,
    getOpeningsAndClosuresFailure,
};

export default actions;
