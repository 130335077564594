import React from "react";

import { Box, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import DatasetFilesTable from "./DatasetFilesTable";

import { selectDatasetFiles } from "modules/admin/accounts/datasetFiles/datasetFilesSlice";
import { useAppSelector } from "store";

const StyledTab = withStyles(() => ({
    root: {
        textTransform: "none"
    }
}))(Tab);

interface TabPanelProps {
    value: number,
    index: number,
    children: React.ReactElement
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { value, index, children } = props;
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && children}
        </Box>
    );
};

interface DatasetFilesTabsProps {
    accountId: string
}

const DatasetFilesTabs: React.FC<DatasetFilesTabsProps> = (props) => {
    const { accountId } = props;
    const theme = useTheme();
    const isScrollable = useMediaQuery(theme.breakpoints.down("md"));
    const datasetFiles = useAppSelector(selectDatasetFiles);
    const salesDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "sales");
    const productsDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "products");
    const storesDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "stores");
    const costsDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "costs");
    const budgetDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "budget");
    const companyDatasetFiles = datasetFiles.filter(datasetFile => datasetFile.datasetId === "company");
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event: object, newSelectedTab: number) => {
        setSelectedTab(newSelectedTab);
    };

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                variant={isScrollable ? "scrollable" : "fullWidth"}
            >
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Sales</Typography>}
                    data-cy="tab-sales"
                />
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Products</Typography>}
                    data-cy="tab-products"
                />
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Stores</Typography>}
                    data-cy="tab-stores"
                />
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Costs</Typography>}
                    data-cy="tab-costs"
                />
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Budget</Typography>}
                    data-cy="tab-budget"
                />
                <StyledTab
                    label={<Typography variant="subtitle1" component="div">Company</Typography>}
                    data-cy="tab-company"
                />
            </Tabs>
            <Box paddingTop={2}>
                <TabPanel value={selectedTab} index={0}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={salesDatasetFiles} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={productsDatasetFiles} />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={storesDatasetFiles} />
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={costsDatasetFiles} />
                </TabPanel>
                <TabPanel value={selectedTab} index={4}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={budgetDatasetFiles} />
                </TabPanel>
                <TabPanel value={selectedTab} index={5}>
                    <DatasetFilesTable accountId={accountId} datasetFiles={companyDatasetFiles} />
                </TabPanel>
            </Box>
        </>
    );
};

export default DatasetFilesTabs;
