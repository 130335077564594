import React from "react";
import { Box, Grid } from "@material-ui/core";

import CostTypeFilter from "./CostTypeFilter";
import StoreGroupFilter from "./StoreGroupFilter";
import RegionFilter from "./RegionFilter";
import CostReductionOpportunitiesChart from "./CostReductionOpportunitiesChart";
import CostReductionOpportunitiesByStore from "./CostReductionOpportunityByStore";

const CostReductionOpportunities: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <CostTypeFilter />
            </Grid>
            <Grid item xs={4}>
                <StoreGroupFilter />
            </Grid>
            <Grid item xs={4}>
                <RegionFilter />
            </Grid>
            <Grid item xs={12}>
                <CostReductionOpportunitiesChart />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" >
                    <Box flexGrow={1} width='100%' height={'60vh'} >
                        <CostReductionOpportunitiesByStore />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CostReductionOpportunities;
