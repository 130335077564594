import React from "react";

import { IconButton, Typography } from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import DatasetFilesTabs from "./DatasetFilesTabs";

import { Account, AccountStatus } from "modules/admin/accounts/accounts/accountsSlice";
import { clearDatasetFiles, getDatasetFiles, hideDatasetFiles, selectDatasetFilesVisibility, showDatasetFiles } from "modules/admin/accounts/datasetFiles/datasetFilesSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface DatasetFilesProps {
    account: Account
}

const DatasetFiles: React.FC<DatasetFilesProps> = (props) => {
    const { account } = props;
    const dispatch = useAppDispatch();
    const disabled = account.status === AccountStatus.NoSubscription;
    const datasetFilesVisibility = useAppSelector(selectDatasetFilesVisibility);
    const show = (datasetFilesVisibility.isVisible) && (account.id === datasetFilesVisibility.accountId);

    React.useEffect(() => {
        if (show) {
            dispatch(getDatasetFiles(account.id));
        }
    }, [dispatch, show, account]);

    const handleDatasetFilesClick = () => {
        dispatch(showDatasetFiles(account.id));
    };

    const handleCancelClick = () => {
        dispatch(hideDatasetFiles());
        dispatch(clearDatasetFiles());
    };

    return (
        <>
            <SimpleTooltip title="Dataset files">
                <span>
                    <StyledIconButton size="small" onClick={handleDatasetFilesClick} disabled={disabled}>
                        <InsertDriveFileOutlinedIcon />
                    </StyledIconButton>
                </span>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                maxWidth="lg"
                title={
                    <Typography variant="h5" component="div" gutterBottom>
                        Dataset files
                    </Typography>
                }
                content={<DatasetFilesTabs accountId={account.id} />}
                actions={<></>}
            />
        </>
    );
};

export default DatasetFiles;
