import React from "react";

import { Box, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Header from "pages/customer/tools/location/Header";

import Chapter from "./Chapter";
import Expand from "./Expand";
import Page from "./Page";
import PinnedLocation from "./PinnedLocation";
import SaveLocation from "./SaveLocation";
import Subchapter from "./Subchapter";

import OverviewSubtitle from "./overview/Subtitle";
import SelectedLocationOverview from "./overview/selectedLocationOverview/SelectedLocationOverview";
import RevenuePrediction from "./overview/revenuePrediction/RevenuePrediction";
import RevenuePredictionSubtitle from "./overview/revenuePrediction/Subtitle";
import Analogues from "./overview/analogues/Analogues";
import AnaloguesSubtitle from "./overview/analogues/Subtitle";

import DemographicsSubtitle from "./demographics/Subtitle";
import AlignmentToDemographicsBenchmark
    from "./demographics/alignmentToDemographicsBenchmark/AlignmentToDemographicsBenchmark";
import AlignmentToDemographicsBenchmarkSubtitle from "./demographics/alignmentToDemographicsBenchmark/Subtitle";
import CatchmentAreaDemographics from "./demographics/catchmentAreaDemographics/CatchmentAreaDemographics";
import CatchmentAreaDemographicsSubtitle from "./demographics/catchmentAreaDemographics/Subtitle";
import CustomerProfiles from "./demographics/customerProfiles/CustomerProfiles";
import CustomerProfilesSubtitle from "./demographics/customerProfiles/Subtitle";

import SpendSubtitle from "./spend/Subtitle";
import SpendFirstLookTooltip from "./spend/FirstLookTooltip";
import SpendFilter from "./spend/filter/Filter";
import TotalSpend from "./spend/totalSpend/TotalSpend";
import TotalSpendSubtitle from "./spend/totalSpend/Subtitle";
import CustomerProfileSpend from "./spend/customerProfileSpend/CustomerProfileSpend";
import CustomerProfileSpendSubtitle from "./spend/customerProfileSpend/Subtitle";

import SpendSubtitleNew from "./spendNew/Subtitle";
import SpendFilterNew from "./spendNew/filter/Filter";
import AlignmentToSpendTarget from "./spendNew/alignmentToSpendTarget/AlignmentToSpendTarget";
import AlignmentToSpendTargetSubtitle from "./spendNew/alignmentToSpendTarget/Subtitle";
import TotalSpendNew from "./spendNew/totalSpend/TotalSpend";
import TotalSpendSubtitleNew from "./spendNew/totalSpend/Subtitle";
import CustomerProfileSpendNew from "./spendNew/customerProfileSpend/CustomerProfileSpend";
import CustomerProfileSpendSubtitleNew from "./spendNew/customerProfileSpend/Subtitle";

import CompetitionSubtitle from "./competition/Subtitle";
import CompetitionFirstLookTooltip from "./competition/FirstLookTooltip";
import CompetitionFilter from "./competition/filter/Filter";
import NearbyCompetitorStores from "./competition/nearbyCompetitorStores/NearbyCompetitorStores";
import NearbyCompetitorStoresSubtitle from "./competition/nearbyCompetitorStores/Subtitle";

import AreaHealthSubtitle from "./areaHealth/Subtitle";
import AlignmentToAreaHealthBenchmark from "./areaHealth/alignmentToAreaHealthBenchmark/AlignmentToAreaHealthBenchmark";
import AlignmentToAreaHealthBenchmarkSubtitle from "./areaHealth/alignmentToAreaHealthBenchmark/Subtitle";
import OpeningsAndClosures from "./areaHealth/openingsAndClosures/OpeningsAndClosures";
import OpeningsAndClosuresSubtitle from "./areaHealth/openingsAndClosures/Subtitle";
import StoreCategoryBreakdown from "./areaHealth/storeCategoryBreakdown/StoreCategoryBreakdown";
import StoreCategoryBreakdownSubtitle from "./areaHealth/storeCategoryBreakdown/Subtitle";

import FootfallSubtitle from "./footfall/Subtitle";
import AlignmentToFootfallBenchmark from "./footfall/alignmentToFootfallBenchmark/AlignmentToFootfallBenchmark";
import AlignmentToFootfallBenchmarkSubtitle from "./footfall/alignmentToFootfallBenchmark/Subtitle";
import DailyFootfall from "./footfall/dailyFootfall/DailyFootfall";
import DailyFootfallSubtitle from "./footfall/dailyFootfall/Subtitle";
import FootfallLevelOverTime from "./footfall/footfallLevelOverTime/FootfallLevelOverTime";
import FootfallLevelOverTimeSubtitle from "./footfall/footfallLevelOverTime/Subtitle";

import CannibalisationSubtitle from "./cannibalisation/Subtitle";
import CannibalisationFirstLookTooltip from "./cannibalisation/FirstLookTooltip";
import CannibalisationFilter from "./cannibalisation/filter/Filter";
import SharedVsUniquePopulation from "./cannibalisation/sharedVsUniquePopulation/SharedVsUniquePopulation";
import SharedVsUniquePopulationSubtitle from "./cannibalisation/sharedVsUniquePopulation/Subtitle";
import CustomerProfilesOfSharedAndUniquePopulation
    from "./cannibalisation/customerProfilesOfSharedAndUniquePopulation/CustomerProfilesOfSharedAndUniquePopulation";
import CustomerProfilesOfSharedAndUniquePopulationSubtitle
    from "./cannibalisation/customerProfilesOfSharedAndUniquePopulation/Subtitle";

import {
    selectCannibalisationFilterHasChanged
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import {
    selectIsFilterModified as selectIsCompetitionFilterModified
} from "modules/customer/tools/location/competition/competitionSlice";
import {
    clearInsights,
    loadInsights,
    LocationChapter,
    selectPinnedLocation
} from "modules/customer/tools/location/locationSlice";
import { selectAddSavedLocationVisibility } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import {
    selectIsFilterModified as selectIsSpendFilterModified
} from "modules/customer/tools/location/spend/spendSlice";
import {
    selectIsFilterModified as selectIsSpendFilterModifiedNew
} from "modules/customer/tools/location/spendNew/spendSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const Insight: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const addSavedLocationVisibility = useAppSelector(selectAddSavedLocationVisibility);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const isSpendFilterModified = useAppSelector(selectIsSpendFilterModified);
    const isSpendFilterModifiedNew = useAppSelector(selectIsSpendFilterModifiedNew);
    const isCompetitionFilterModified = useAppSelector(selectIsCompetitionFilterModified);
    const isCannibalisationFilterModified = useAppSelector(selectCannibalisationFilterHasChanged);

    React.useEffect(() => {
        if (pinnedLocation) {
            dispatch(loadInsights());
        }
        return () => {
            dispatch(clearInsights());
        };
    }, [dispatch, pinnedLocation]);

    return (
        <Box position="relative">
            {/* @ts-ignore */}
            <Box paddingY={1} bgcolor={theme.palette.background.insight}>
                <Container maxWidth={false}>
                    <Header />
                </Container>
            </Box>
            {addSavedLocationVisibility.isVisible ? (
                <SaveLocation />
            ) : (
                <PinnedLocation />
            )}
            <Page>
                <Chapter
                    number={LocationChapter.Overview}
                    title="Overview"
                    subtitle={<OverviewSubtitle />}
                    dataCy="overview"
                >
                    <Subchapter
                        number={1.1}
                        title="Selected location overview"
                        dataCy="selected-location-overview"
                    >
                        <SelectedLocationOverview />
                    </Subchapter>
                    <Subchapter
                        number={1.2}
                        title="Revenue Prediction"
                        subtitle={<RevenuePredictionSubtitle />}
                        dataCy="revenue-prediction"
                    >
                        <RevenuePrediction />
                    </Subchapter>
                    <Subchapter
                        number={1.3}
                        title="Analogues"
                        subtitle={<AnaloguesSubtitle />}
                        dataCy="analogues"
                    >
                        <Analogues />
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.Demographics}
                    title="Demographics"
                    subtitle={<DemographicsSubtitle />}
                    dataCy="demographics"
                >
                    <Subchapter
                        number={2.1}
                        title="Alignment to demographics target"
                        subtitle={<AlignmentToDemographicsBenchmarkSubtitle />}
                        dataCy="alignment-to-demographics-target"
                    >
                        <AlignmentToDemographicsBenchmark />
                    </Subchapter>
                    <Subchapter
                        number={2.2}
                        title="Catchment area demographics"
                        subtitle={<CatchmentAreaDemographicsSubtitle />}
                        dataCy="catchment-area-demographics"
                    >
                        <CatchmentAreaDemographics />
                    </Subchapter>
                    <Subchapter
                        number={2.3}
                        title="Customer profiles"
                        subtitle={<CustomerProfilesSubtitle />}
                        dataCy="customer-profiles"
                    >
                        <CustomerProfiles />
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.Spend}
                    title="Spend"
                    subtitle={enableSpendNew ? <SpendSubtitleNew /> : <SpendSubtitle />}
                    firstLookTooltip={enableSpendNew ? undefined : <SpendFirstLookTooltip />}
                    filter={enableSpendNew ? <SpendFilterNew /> : <SpendFilter />}
                    isFilterModified={enableSpendNew ? isSpendFilterModifiedNew : isSpendFilterModified}
                    dataCy="spend"
                >
                    <Subchapter
                        number={3.1}
                        title="Alignment to spend target"
                        subtitle={<AlignmentToSpendTargetSubtitle />}
                        hidden={!enableSpendNew}
                        dataCy="alignment-to-spend-target"
                    >
                        <AlignmentToSpendTarget />
                    </Subchapter>
                    <Subchapter
                        number={enableSpendNew ? 3.2 : 3.1}
                        title="Total spend"
                        subtitle={enableSpendNew ? <TotalSpendSubtitleNew /> : <TotalSpendSubtitle />}
                        dataCy="total-spend"
                    >
                        {enableSpendNew ? (<TotalSpendNew />) : (<TotalSpend />)}
                    </Subchapter>
                    <Subchapter
                        number={enableSpendNew ? 3.3 : 3.2}
                        title="Customer profile spend"
                        subtitle={enableSpendNew ? <CustomerProfileSpendSubtitleNew /> :
                            <CustomerProfileSpendSubtitle />}
                        dataCy="customer-profile-spend"
                    >
                        {enableSpendNew ? (<CustomerProfileSpendNew />) : (<CustomerProfileSpend />)}
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.Competition}
                    title="Competition"
                    subtitle={<CompetitionSubtitle />}
                    firstLookTooltip={<CompetitionFirstLookTooltip />}
                    filter={<CompetitionFilter />}
                    isFilterModified={isCompetitionFilterModified}
                    dataCy="competition"
                >
                    <Subchapter
                        number={4.1}
                        title="Nearby competitor stores"
                        subtitle={<NearbyCompetitorStoresSubtitle />}
                        dataCy="nearby-competitor-stores"
                    >
                        <NearbyCompetitorStores />
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.AreaHealth}
                    title="Area health"
                    subtitle={<AreaHealthSubtitle />}
                    dataCy="area-health"
                >
                    <Subchapter
                        number={5.1}
                        title="Alignment to area health target"
                        subtitle={<AlignmentToAreaHealthBenchmarkSubtitle />}
                        dataCy="alignment-to-area-health-target"
                    >
                        <AlignmentToAreaHealthBenchmark />
                    </Subchapter>
                    <Subchapter
                        number={5.2}
                        title="Openings and closures"
                        subtitle={<OpeningsAndClosuresSubtitle />}
                        dataCy="openings-and-closures"
                    >
                        <OpeningsAndClosures />
                    </Subchapter>
                    <Subchapter
                        number={5.3}
                        title="Store category breakdown"
                        subtitle={<StoreCategoryBreakdownSubtitle />}
                        dataCy="store-category-breakdown"
                    >
                        <StoreCategoryBreakdown />
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.Footfall}
                    title="Footfall"
                    subtitle={<FootfallSubtitle />}
                    dataCy="footfall"
                >
                    <Subchapter
                        number={6.1}
                        title="Alignment to footfall target"
                        subtitle={<AlignmentToFootfallBenchmarkSubtitle />}
                        dataCy="alignment-to-footfall-target"
                    >
                        <AlignmentToFootfallBenchmark />
                    </Subchapter>
                    <Subchapter
                        number={6.2}
                        title="Footfall level over time"
                        subtitle={<FootfallLevelOverTimeSubtitle />}
                        dataCy="footfall-level-over-time"
                    >
                        <FootfallLevelOverTime />
                    </Subchapter>
                    <Subchapter
                        number={6.3}
                        title="Daily footfall"
                        subtitle={<DailyFootfallSubtitle />}
                        dataCy="daily-footfall"
                    >
                        <DailyFootfall />
                    </Subchapter>
                </Chapter>
                <Chapter
                    number={LocationChapter.Cannibalisation}
                    title="Cannibalisation"
                    subtitle={<CannibalisationSubtitle />}
                    firstLookTooltip={<CannibalisationFirstLookTooltip />}
                    filter={<CannibalisationFilter />}
                    isFilterModified={isCannibalisationFilterModified}
                    dataCy="cannibalisation"
                >
                    <Subchapter
                        number={7.1}
                        title="Shared vs unique population"
                        subtitle={<SharedVsUniquePopulationSubtitle />}
                        dataCy="shared-vs-unique-population"
                    >
                        <SharedVsUniquePopulation />
                    </Subchapter>
                    <Subchapter
                        number={7.2}
                        title="Customer profiles of shared and unique population"
                        subtitle={<CustomerProfilesOfSharedAndUniquePopulationSubtitle />}
                        dataCy="customer-profiles-of-shared-and-unique-population"
                    >
                        <CustomerProfilesOfSharedAndUniquePopulation />
                    </Subchapter>
                </Chapter>
            </Page>
            <Expand />
        </Box>
    );
};

export default Insight;
