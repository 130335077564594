import React from "react";

import { Box, Grid, GridSize } from "@material-ui/core";

import SpendByMarketCategory from "./SpendByMarketCategory";
import SpendPerHead from "./SpendPerHead";
import WeightedSpend from "./WeightedSpend";

import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const TotalSpend: React.FC = () => {
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const kpiGridSize: GridSize = isInsightExpanded ? 6 : 12;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={kpiGridSize}>
                    <WeightedSpend />
                </Grid>
                <Grid item xs={kpiGridSize}>
                    <SpendPerHead />
                </Grid>
                <Grid item xs={12}>
                    <SpendByMarketCategory />
                </Grid>
            </Grid>
        </Box>
    );
};

export default TotalSpend;
