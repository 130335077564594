import types from "./types";

const getFootfallRequest = () => ({
    type: types.GET_FOOTFALL_REQUEST
});

const getFootfallSuccess = (storeValues, comparatorValues, mapCenter) => ({
    type: types.GET_FOOTFALL_SUCCESS,
    payload: {
        storeValues,
        comparatorValues,
        mapCenter
    }
});

const getFootfallFailure = () => ({
    type: types.GET_FOOTFALL_FAILURE
});

const actions = {
    getFootfallRequest,
    getFootfallSuccess,
    getFootfallFailure
};

export default actions;
