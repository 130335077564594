import React from "react";
import { min } from "mathjs";

import { Box } from "@material-ui/core";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";
import ExistingStore from "pages/customer/tools/location/map/ExistingStore";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";

import {
    selectLevelOfCannibalisation,
    selectSelectedCannibalisedStores
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const cannibalisationOutputAreas = useAppSelector(selectLevelOfCannibalisation);
    const existingStores = useAppSelector(selectSelectedCannibalisedStores);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredOutputArea = cannibalisationOutputAreas.find(oa => oa.code === hoverInfo.outputAreaCode);
    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const downloadData = cannibalisationOutputAreas.map(({ catchmentIDs, ...item }) => item);

    const mapOutputAreas = React.useMemo(() => {
        return cannibalisationOutputAreas.map(oa => ({
            code: oa.code,
            colourGroup: oa.type === "Existing" ? 0 : min(1 + oa.levelOfCannibalisation, 5)
        }));
    }, [cannibalisationOutputAreas]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="cannibalisation-map">
            <OutputAreasMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colourPalette.cannibalisation}
                onHover={setHoverInfo}
                enableHover={true}
                enableClick={false}
                displayPOIs={true}
                downloadData={downloadData}
            >
                <Explore />
                <PinnedLocation />
                {existingStores.map((store, index) =>
                    <ExistingStore
                        key={index}
                        latitude={store.latitude}
                        longitude={store.longitude}
                    />
                )}
                <Legend outputArea={hoveredOutputArea} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
