import React from "react";
import { Scrollspy } from "@makotot/ghostui";

import { Box, Card, CardMedia, Fade, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageSection from "components/landing/PageSection";
import PageCallToAction from "components/landing/PageCallToAction";

import Section from "./Section";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const HowDashWorks = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const sections = [{
        icon: "/content/landing/how-dash-works/upload-your-data-icon.png",
        title: "Upload your data",
        paragraph1: "Dash requires transactional-level sales data, store data, product data, and a few other optional data sets to deliver insights for your business. Collect the data within your organisation and input it into our easy-to-use templates.",
        paragraph2: "When ready, head over to our Data Upload page to submit your files for processing. There is plenty of helpful guidance and support available to make this a breeze.",
        image: "/content/landing/how-dash-works/upload-your-data.png",
        alt: "upload your data",
        ref: React.useRef(null)
    }, {
        icon: "/content/landing/how-dash-works/let-dash-learn-icon.png",
        title: "Let Dash learn",
        paragraph1: "After submitting your data for processing, Dash merges it with large volumes of external datasets and then runs it through our proprietary AI algorithms. Grab a cup of coffee whilst Dash prepares your analytical treats.",
        paragraph2: "The result: detailed analysis of your locations, customers, and products.",
        image: "/content/landing/how-dash-works/let-dash-learn.png",
        alt: "let Dash learn",
        ref: React.useRef(null)
    }, {
        icon: "/content/landing/how-dash-works/see-the-issues-and-spot-opportunities-icon.png",
        title: "See the issues and spot opportunities",
        paragraph1: "Once your data has refreshed you can explore our insights to see what is working well, what things to keep an eye on and what requires more immediate attention.",
        paragraph2: "These insights will help you understand why some of your products or stores are not performing well, identify who are the highest value customers to your business, and much more.",
        image: "/content/landing/how-dash-works/see-the-issues-and-spot-opportunities.png",
        alt: "see the issues and spot opportunities",
        ref: React.useRef(null)
    }, {
        icon: "/content/landing/how-dash-works/prioritise-opportunities-for-growth-icon.png",
        title: "Prioritise opportunities for growth",
        paragraph1: "In addition to insightful charts and visuals, Dash's AI engine provides a plain English interpretation for each result, highlighting issues and opportunities alongside an urgency rating. You can use this to prioritise what you want to tackle first and understand the effects they will have on your business.",
        paragraph2: "You will find Dash presents opportunities that align with your current strategic objectives, but it also helps you develop new strategies for your business.",
        image: "/content/landing/how-dash-works/prioritise-opportunities-for-growth.png",
        alt: "prioritise opportunities for growth",
        ref: React.useRef(null)
    }, {
        icon: "/content/landing/how-dash-works/refresh-your-data-and-keep-learning-icon.png",
        title: "Refresh your data and keep learning",
        paragraph1: "Each time you refresh your data in the platform, Dash learns more about your business, giving you richer insights and providing the most up to date issues and opportunities to sink your teeth into.",
        paragraph2: "For you, this is as simple as going back to the first step and repeating the process.",
        image: "/content/landing/how-dash-works/refresh-your-data-and-keep-learning.png",
        alt: "refresh your data and keep learning",
        ref: React.useRef(null)
    }];
    const sectionRefs = sections.map(section => section.ref);

    return (
        <Page data-cy="pages-landing-how-dash-works">
            <PageHeader
                title="How Dash works"
                subtitle="Supporting your business through better decision making"
                dataCy="pages-landing-how-dash-works-header"
            />
            <Scrollspy sectionRefs={sectionRefs} offset={-400}>
                {({ currentElementIndexInViewport }) => (
                    <Box position="relative">
                        {sections.map((section, index) =>
                            <PageSection
                                key={index}
                                ref={sectionRefs[index]}
                                dataCy={`pages-landing-how-dash-works-section-${index + 1}`}
                            >
                                <Section
                                    icon={section.icon}
                                    title={section.title}
                                    paragraph1={section.paragraph1}
                                    paragraph2={section.paragraph2}
                                    image={section.image}
                                    alt={section.alt}
                                    showText={true}
                                    showImage={isMobile}
                                />
                            </PageSection>
                        )}
                        <Hidden smDown>
                            <Box position="absolute" top={0} bgcolor="transparent" height="100%" width="100%">
                                <PageSection position="sticky" top={80}>
                                    <Grid container spacing={6} alignItems="center" style={{ minHeight: theme.spacing(76) }}>
                                        <Grid item xs={12} md={6} />
                                        <Grid item xs={12} md={6}>
                                            {sections.map((section, index) =>
                                                <React.Fragment key={index}>
                                                    {currentElementIndexInViewport === index && (
                                                        <Fade
                                                            in={currentElementIndexInViewport === index}
                                                            timeout={{
                                                                enter: 1500,
                                                                exit: 0
                                                            }}
                                                        >
                                                            <StyledCard square elevation={0}>
                                                                <CardMedia component="img" image={section.image} alt={section.alt} />
                                                            </StyledCard>
                                                        </Fade>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                </PageSection>
                            </Box>
                        </Hidden>
                    </Box>
                )}
            </Scrollspy>
            <PageCallToAction
                color={theme.palette.common.white}
                bgcolor={theme.palette.primary.main}
                title="Developed by industry leading experts"
                subtitle="Meet the Dash team that are passionate about solving business problems with data"
                actionLabel="Meet the team"
                actionUrl="/meet-the-team"
                dataCy="pages-landing-how-dash-works-call-to-action"
            />
        </Page>
    );
};

export default HowDashWorks;
