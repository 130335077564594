import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import AddQuestion from "./AddQuestion";
import QuestionsTable from "./QuestionsTable";

import {
    clearQuestions,
    getQuestions,
    hideQuestions,
    selectQuestionsVisibility,
    showQuestions
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface QuestionsProp {
    categoryId: string
}

const Questions: React.FC<QuestionsProp> = (props) => {
    const { categoryId } = props;
    const dispatch = useAppDispatch();
    const questionsVisibility = useAppSelector(selectQuestionsVisibility);
    const show = (questionsVisibility.isVisible) && (categoryId === questionsVisibility.categoryId);

    React.useEffect(() => {
        if (show) {
            dispatch(getQuestions(categoryId));
        }
    }, [dispatch, show, categoryId]);

    const handleQuestionsClick = () => {
        dispatch(showQuestions(categoryId));
    };

    const handleCancelClick = () => {
        dispatch(hideQuestions());
        dispatch(clearQuestions());
    };

    return (
        <>
            <SimpleTooltip title="Questions">
                <StyledIconButton size="small" onClick={handleQuestionsClick}>
                    <ListAltRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                maxWidth="xl"
                title={
                    <Typography variant="h5" component="div" gutterBottom>
                        Questions
                    </Typography>
                }
                content={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <AddQuestion categoryId={categoryId} />
                        </Grid>
                        <Grid item xs={10} />
                        <Grid item xs={12}>
                            <QuestionsTable categoryId={categoryId} />
                        </Grid>
                    </Grid>
                }
                actions={<></>}
            />
        </>
    );
};

export default Questions;
