import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class DefaultStoreFeatures {
    public readonly medianSalesAreaInSqft: number;
    public readonly maxSalesAreaInSqft: number;
    public readonly minSalesAreaInSqft: number;
    public readonly numberOfTradingFloors: number;
    public readonly openingHoursPerWeek: number;
    public readonly isCarParkAvailable: boolean;

    constructor(
        medianSalesAreaInSqft: number,
        maxSalesAreaInSqft: number,
        minSalesAreaInSqft: number,
        numberOfTradingFloors: number,
        openingHoursPerWeek: number,
        isCarParkAvailable: boolean
    ) {
        this.medianSalesAreaInSqft = medianSalesAreaInSqft;
        this.maxSalesAreaInSqft = maxSalesAreaInSqft;
        this.minSalesAreaInSqft = minSalesAreaInSqft;
        this.numberOfTradingFloors = numberOfTradingFloors;
        this.openingHoursPerWeek = openingHoursPerWeek;
        this.isCarParkAvailable = isCarParkAvailable;
    }
}

export const loadDefaultStoreFeatures = (): AppThunk<Promise<DefaultStoreFeatures>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "F_DefaultStoreFeatures.MedianStoreSize",
                "F_DefaultStoreFeatures.MaxStoreSize",
                "F_DefaultStoreFeatures.MinStoreSize",
                "F_DefaultStoreFeatures.MedianTradingFloors",
                "F_DefaultStoreFeatures.MedianOpeningHours",
                "F_DefaultStoreFeatures.ModeCarPark"
            ]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return new DefaultStoreFeatures(
            Number(rawData?.[0]?.["F_DefaultStoreFeatures.MedianStoreSize"] ?? 0),
            Number(rawData?.[0]?.["F_DefaultStoreFeatures.MaxStoreSize"] ?? 0),
            Number(rawData?.[0]?.["F_DefaultStoreFeatures.MinStoreSize"] ?? 0),
            Number(rawData?.[0]?.["F_DefaultStoreFeatures.MedianTradingFloors"] ?? 0),
            Number(rawData?.[0]?.["F_DefaultStoreFeatures.MedianOpeningHours"] ?? 0),
            Boolean(Number(rawData?.[0]?.["F_DefaultStoreFeatures.ModeCarPark"] ?? 0) === 1)
        );
    } catch (error) {
        dispatch(logError("Error loading DefaultStoreFeatures.", error));
        throw error;
    }
};
