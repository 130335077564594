import React from "react";
import { Expression, FillPaint /*, LinePaint*/ } from "mapbox-gl";
import { Layer, LayerProps, MapEvent, MapRef, Source, SourceProps, ViewportProps } from "react-map-gl";
import _ from "lodash";

import MapBase from "./MapBase";

const OUTPUT_AREAS_FILL_LAYER_ID = "output-areas-fill";

// const OUTPUT_AREAS_LINE_LAYER_ID = "output-areas-line";

interface MapOutputArea {
    code: string,
    colourGroup: number,
    opacity?: number
}

interface PointerInfo {
    outputAreaCode?: string,
    latitude: number,
    longitude: number
}

export const pointerInfoEmpty: PointerInfo = {
    outputAreaCode: undefined,
    latitude: 0,
    longitude: 0
};

interface OutputAreasMapProps {
    loading: boolean,
    error: boolean,
    title?: string,
    mapboxAccessToken: string,
    mapboxBaseMapStyle: string,
    mapboxOutputAreasTilesetId: string,
    mapboxOutputAreasTilesetUrl: string,
    height?: string | number,
    initialViewport: ViewportProps,
    outputAreas: MapOutputArea[],
    colours: string[]
    enableHover?: boolean,
    onHover?: (pointerInfo: PointerInfo) => void,
    enableClick?: boolean,
    onClick?: (pointerInfo: PointerInfo) => void,
    addGeocoder: boolean,
    geocoderContainerRef?: React.RefObject<HTMLDivElement>,
    children?: React.ReactNode,
    displayPOIs?: boolean,
    downloadData?: object[]
}

const OutputAreasMap: React.FC<OutputAreasMapProps> = (props) => {
    const {
        loading, error,
        title,
        mapboxAccessToken, mapboxBaseMapStyle, mapboxOutputAreasTilesetId, mapboxOutputAreasTilesetUrl,
        height,
        initialViewport,
        outputAreas, colours,
        enableHover, onHover, enableClick, onClick,
        addGeocoder, geocoderContainerRef,
        displayPOIs, downloadData
    } = props;
    const mapRef = React.useRef<MapRef>(null);
    const [hoverInfo, setHoverInfo] = React.useState<PointerInfo>(pointerInfoEmpty);
    const [clickInfo, setClickInfo] = React.useState<PointerInfo>(pointerInfoEmpty);

    const outputAreasSource = React.useMemo((): SourceProps => {
        return {
            type: "vector",
            url: mapboxOutputAreasTilesetUrl
        };
    }, [mapboxOutputAreasTilesetUrl]);

    const fillColor = React.useMemo(() => {
        let fillColor: string | Expression = "rgba(0,0,0,0)";
        if (outputAreas.length > 0) {
            fillColor = ["match", ["get", "geo_code"]];
            for (const outputArea of outputAreas) {
                fillColor.push(outputArea.code, colours[outputArea.colourGroup]);
            }
            fillColor.push("rgba(0, 0, 0, 0)");
        }
        return fillColor;
    }, [outputAreas, colours]);

    const fillOpacity = React.useMemo(() => {
        let fillOpacity: number | Expression = 0.4;
        const minOpacity = 0.1;
        const maxOpacity = 0.6;
        const standardOpacity = 0.4;
        const highlightOpacity = 0.7;

        if (outputAreas.length > 0) {
            fillOpacity = ["match", ["get", "geo_code"]];
            for (const outputArea of outputAreas) {
                if (outputArea.code === hoverInfo.outputAreaCode || outputArea.code === clickInfo.outputAreaCode) {
                    fillOpacity.push(outputArea.code, highlightOpacity);
                } else {
                    fillOpacity.push(
                        outputArea.code,
                        outputArea.opacity ? (((outputArea.opacity) * (maxOpacity - minOpacity)) + minOpacity) : standardOpacity
                    );
                }
            }
            fillOpacity.push(maxOpacity);
        }
        return fillOpacity;
    }, [outputAreas, hoverInfo, clickInfo]);

    const outputAreasFillLayer = React.useMemo(() => {
        const paint: FillPaint = {
            "fill-color": fillColor,
            "fill-opacity": fillOpacity
        };
        const layer: LayerProps = {
            id: OUTPUT_AREAS_FILL_LAYER_ID,
            type: "fill",
            "source-layer": mapboxOutputAreasTilesetId,
            beforeId: "tunnel-simple",
            paint
        };
        return layer;
    }, [fillColor, fillOpacity, mapboxOutputAreasTilesetId]);

    const handlePointerEvent = React.useCallback((event: MapEvent): PointerInfo => {
        const { features, lngLat } = event;
        const outputAreaCode = features?.find(f => f.layer.id === OUTPUT_AREAS_FILL_LAYER_ID)?.properties["geo_code"];
        if (!outputAreas.some(outputArea => outputArea.code === outputAreaCode)) {
            return pointerInfoEmpty;
        }
        return {
            outputAreaCode,
            latitude: lngLat[1],
            longitude: lngLat[0]
        };
    }, [outputAreas]);

    const handleClick = React.useCallback((event: MapEvent) => {
        if (!enableClick) {
            return;
        }
        const pointerInfo = handlePointerEvent(event);
        const newPointerInfo = pointerInfo.outputAreaCode === clickInfo.outputAreaCode ? pointerInfoEmpty : pointerInfo;
        setClickInfo(newPointerInfo);
        onClick && onClick(newPointerInfo);
    }, [enableClick, handlePointerEvent, clickInfo, setClickInfo, onClick]);

    const handleHover = React.useCallback((event: MapEvent) => {
        if (!enableHover) {
            return;
        }
        const pointerInfo = handlePointerEvent(event);
        setHoverInfo(pointerInfo);
        onHover && onHover(pointerInfo);
    }, [enableHover, handlePointerEvent, setHoverInfo, onHover]);

    const handleHoverDelayed = React.useMemo(() => {
        return _.debounce((event: MapEvent) => handleHover(event), 100);
    }, [handleHover]);

    const handleMouseOut = React.useCallback(() => {
        handleHoverDelayed.cancel();
        setHoverInfo(pointerInfoEmpty);
        onHover && onHover(pointerInfoEmpty);
    }, [handleHoverDelayed, setHoverInfo, onHover]);

    return (
        <MapBase
            loading={loading}
            error={error}
            title={title}
            mapboxAccessToken={mapboxAccessToken}
            mapboxBaseMapStyle={mapboxBaseMapStyle}
            height={height}
            mapRef={mapRef}
            initialViewport={initialViewport}
            addGeocoder={addGeocoder}
            geocoderContainerRef={geocoderContainerRef}
            addNavigationControl={true}
            addRecenterButton={true}
            addFullscreenButton={true}
            interactiveLayerIds={[OUTPUT_AREAS_FILL_LAYER_ID]}
            onHover={handleHoverDelayed}
            onClick={handleClick}
            onMouseOut={handleMouseOut}
            dataCy="output-areas-map"
            displayPOIs={displayPOIs}
            downloadData={downloadData}
            
        >
            <Source {...outputAreasSource}>
                <Layer {...outputAreasFillLayer} />
                {/*<Layer {...outputAreasLineLayer} />*/}
            </Source>
            {props.children}
        </MapBase>
    );
};

OutputAreasMap.defaultProps = {
    // ToDo: setting the values for retro-compatibility; bigger refactoring needed
    enableHover: true,
    enableClick: true
};

export default OutputAreasMap;
