import React from "react";

const RevenueGrowthSubtitle: React.FC = () => {
    return (
        <>
            Find out what has been driving your revenue growth over the last twelve months. Is your company
            over-dependent on unsustainable sources of growth?
        </>
    );
};

export default RevenueGrowthSubtitle;
