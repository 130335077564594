import React from "react";
import { Grid } from "@material-ui/core";

import SalesToProfit from "./SalesToProfit";
import ProfitabilityEvaluation from "./ProfitabilityEvaluation";

const NetProfit = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SalesToProfit/>
            </Grid>
            <Grid item xs={12}>
                <ProfitabilityEvaluation/>
            </Grid>
        </Grid>
    );
};

export default NetProfit;
