import React from "react";

import { Box, Card, CardContent, Grid, LinearProgress, Typography } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import CancelUpload from "./CancelUpload";
import DeleteFile from "./DeleteFile";

import { ReactComponent as CsvFileIcon } from "assets/images/csv-file-icon.svg";
import { ReactComponent as XlsFileIcon } from "assets/images/xls-file-icon.svg";

import { useAppSelector } from "store";
import { DatasetType, selectUploadInfo } from "modules/customer/data/dataSlice";
import numberFormatter from "utils/numberFormatter";

interface UploadInfoProps {
    datasetType: DatasetType
}

const UploadInfo: React.FC<UploadInfoProps> = (props) => {
    const { datasetType } = props;
    const theme = useTheme();
    const uploadInfo = useAppSelector(selectUploadInfo);
    const {
        variant,
        backgroundColor
    }: { variant: "elevation" | "outlined", backgroundColor: string } = uploadInfo.isComplete
        // @ts-ignore
        ? { variant: "elevation", backgroundColor: theme.palette.quaternary.dark }
        : { variant: "outlined", backgroundColor: theme.palette.background.paper };
    const { progress, fileSize, unit, percentage } = (() => {
        if (uploadInfo.fileSize >= 1024 * 1024) {
            return {
                progress: uploadInfo.progress / 1024 / 1024,
                fileSize: uploadInfo.fileSize / 1024 / 1024,
                unit: "MB",
                percentage: uploadInfo.progress / uploadInfo.fileSize * 100
            };
        }
        if (uploadInfo.fileSize >= 1024) {
            return {
                progress: uploadInfo.progress / 1024,
                fileSize: uploadInfo.fileSize / 1024,
                unit: "KB",
                percentage: uploadInfo.progress / uploadInfo.fileSize * 100
            };
        }
        return {
            progress: uploadInfo.progress,
            fileSize: uploadInfo.fileSize,
            unit: "Bytes",
            percentage: uploadInfo.progress / uploadInfo.fileSize * 100
        };
    })();

    if (!uploadInfo.fileName) {
        return null;
    }

    return (
        <Card variant={variant} elevation={0} style={{ position: "relative", backgroundColor: backgroundColor }}>
            {!uploadInfo.isComplete && (
                <Box position="absolute" right={0} color="text.disabled">
                    <CancelUpload />
                </Box>
            )}
            <CardContent>
                <Box display="flex">
                    <Box paddingTop={0.5} color="text.disabled">
                        {datasetType === DatasetType.Csv ? <CsvFileIcon /> : <XlsFileIcon />}
                    </Box>
                    <Box marginLeft={1} flexGrow={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={uploadInfo.isComplete ? 9 : 12}>
                                <Typography variant="subtitle1" component="div" gutterBottom>
                                    {uploadInfo.fileName}
                                </Typography>
                                {!uploadInfo.isComplete && (
                                    <LinearProgress variant="determinate" value={percentage} />
                                )}
                                <Box display="flex">
                                    <Typography variant="body1" component="div" style={{ color: theme.palette.text.disabled }}>
                                        {!uploadInfo.isComplete && `${numberFormatter.toDecimalPlaces(progress, 2, false)} out of `}
                                        {numberFormatter.toDecimalPlaces(fileSize, 2, false)} {unit}
                                    </Typography>
                                    <Spacer />
                                    {!uploadInfo.isComplete && (
                                        <Typography variant="body1" component="div" style={{ color: theme.palette.text.disabled }}>
                                            {numberFormatter.toDecimalPlaces(percentage, 0, false)}%
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                            {uploadInfo.isComplete && (
                                <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                                    <Box
                                        display="flex"
                                        flexGrow={1}
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        color={uploadInfo.isSuccess ? theme.palette.success.main : theme.palette.error.main}>
                                        {uploadInfo.isSuccess ? <CheckCircleOutlineRoundedIcon /> :
                                            <CancelOutlinedIcon />}
                                        &nbsp;
                                        <Typography variant="subtitle1" component="div">
                                            {uploadInfo.isSuccess ? "Uploaded" : "Upload failed"}
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <DeleteFile />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default UploadInfo;
