import React from "react";
import { Grid } from "@material-ui/core";

import SalesPerformanceVsBudgetOverTime from "./SalesPerformanceVsBudgetOverTime";
import TrackingToBudget from "./TrackingToBudget";


const RevenueVsBudgetOverTime = () => {
    return (
        <Grid container spacing={2}> 
            <Grid item xs={12}>
                <SalesPerformanceVsBudgetOverTime />
            </Grid>
            <Grid item xs={12}>
                <TrackingToBudget />
            </Grid>
        </Grid>
    );
};

export default RevenueVsBudgetOverTime;
