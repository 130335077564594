import React from "react";
import { Marker } from "react-map-gl";

import { Icon } from "@material-ui/core";

import ExistingStoreIconSvg from "assets/images/existing-store-icon.svg";

interface ExistingStoreProps {
    latitude: number,
    longitude: number,
    size?: number
}

const ExistingStore: React.FC<ExistingStoreProps> = (props) => {
    const { latitude, longitude, size } = props;
    const defaultSize = 24;
    const markerSize = size ?? defaultSize;
    const offsetFactor = (size ?? defaultSize) / defaultSize;

    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            offsetTop={-24 * offsetFactor}
            offsetLeft={-12 * offsetFactor}
        >
            <div style={{
                pointerEvents: "none",
                width: markerSize,
                height: markerSize,
                transform: `scale(${offsetFactor}, ${offsetFactor})`
            }}>
                <Icon>
                    <img src={ExistingStoreIconSvg} height={defaultSize} width={defaultSize} alt="existing store" />
                </Icon>
            </div>
        </Marker>
    );
};

export default ExistingStore;
