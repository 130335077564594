import React from "react";

const FirstLookTooltip: React.FC = () => {
    return (
        <>
            You're viewing our first release of Location's Spend insights. Take a look around and keep an eye out for
            more new features coming soon! If you would like to share any feedback to help shape future versions, please
            email us at contact@kpmgdash.co.uk
        </>
    );
};

export default FirstLookTooltip;
