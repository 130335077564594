import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import { selectIsLoading, selectHasErrors, selectCatchmentAreaSpendByMarketAndProductCategory } from "modules/customer/tools/location/spendNew/spendSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const CatchmentAreaSpendByMarketAndProductCategory: React.FC = () => {
    const colours = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const comparatorName = useAppSelector(selectComparatorsByChapter)?.spend?.storeName;
    const catchmentAreaSpendByMarketAndProductCategory = useAppSelector(selectCatchmentAreaSpendByMarketAndProductCategory);
    const selectedLocationDrilldownSeries: Highcharts.SeriesOptionsType[] = catchmentAreaSpendByMarketAndProductCategory.pinnedLocation.map(spendByCategory => ({
        type: "bar",
        id: `selected-location-${spendByCategory.spendCategory}`,
        name: `Selected location`,
        custom: {
            tooltipName: "Selected location"
        },
        data: spendByCategory.spendByDetailedCategory.map(item => ({
            name: item.detailedCategoryName,
            y: item.spend
        }))
    }));
    const comparatorDrilldownSeries: Highcharts.SeriesOptionsType[] = catchmentAreaSpendByMarketAndProductCategory.comparator.map(spendByCategory => ({
        type: "bar",
        id: `comparator-${spendByCategory.spendCategory}`,
        name: `Comparator: ${comparatorName}`,
        custom: {
            tooltipName: "Comparator"
        },
        data: spendByCategory.spendByDetailedCategory.map(item => ({
            name: item.detailedCategoryName,
            y: item.spend
        }))
    }));

    const selectedLocationData = catchmentAreaSpendByMarketAndProductCategory.pinnedLocation.map((value) => ({
        name: value.spendCategory,
        y: value.totalCategorySpend,
        drilldown: `selected-location-${value.spendCategory}`
    }));
    const comparatorData = catchmentAreaSpendByMarketAndProductCategory.comparator.map((value) => ({
        name: value.spendCategory,
        y: value.totalCategorySpend,
        drilldown: `comparator-${value.spendCategory}`
    }));

    const yAxisTitle = "Catchment area yearly spend (\u00A3m)";

    const options: Highcharts.Options = {
        xAxis: {
            type: 'category'
        },
        yAxis: {
            labels: {
                enabled: true
            },
            title: {
                text: yAxisTitle
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            formatter: function () {
                const points = this.points ?? [];
                const values = points?.map(point => numberFormatter.toGBP(point.y, 1));
                const captions = points?.map(point => point.series.options.custom?.tooltipName ?? point.series.name);
                const colours = points?.map(point => `color:${String(point.color)}`);
                return `<table>${stringUtils.tooltipHTML(captions, {
                    header: `${points.at(0)?.key ?? ""}`,
                    values,
                    categoryFormattingArr: colours
                })}</table>`;
            },
            useHTML: true,
            shared: true
        },
        series: [{
            name: "Selected location",
            custom: {
                tooltipName: "Selected location"
            },
            data: selectedLocationData,
            color: colours.comparators[0],
            type: "bar"
        }, {
            name: `Comparator: ${comparatorName}`,
            custom: {
                tooltipName: "Comparator"
            },
            data: comparatorData,
            color: colours.comparators[1],
            type: "bar"
        }],
        drilldown: {
            allowPointDrilldown: false,
            series: selectedLocationDrilldownSeries.concat(...comparatorDrilldownSeries)
        }

    };

    return (
        <Box data-cy="catchment-area-spend-by-market-and-product-category">
            <Typography variant="h6" component="div">
                Catchment area spend by market and product category
            </Typography>
            <Bar loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default CatchmentAreaSpendByMarketAndProductCategory;
