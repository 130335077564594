import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Take a first look at shopper spending for your selected location. Analyse the market size and where spending
            comes from in your location's catchment area. Dash breaks down spending across your market categories and
            determines which customers make up the majority of spending.
        </>
    );
};

export default Subtitle;
