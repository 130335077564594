import React from "react";

import { Box } from "@material-ui/core";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";

import { selectSpendByOutputArea } from "modules/customer/tools/location/spend/spendSlice";
import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";
import mathUtils from "utils/mathUtils";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const spendByOutputArea = useAppSelector(selectSpendByOutputArea);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredOutputArea = spendByOutputArea.find(spend => spend.outputAreaCode === hoverInfo.outputAreaCode);
    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const mapOutputAreas = React.useMemo(() => {
        const values = spendByOutputArea.map(spend => spend.weightedSpend);
        const thresholds = mathUtils.percentileThresholds(values, 5);
        return spendByOutputArea.map(spend => {
            let colourGroup = 0;
            for (let i = 0; i < thresholds.length; i++) {
                if (spend.weightedSpend >= thresholds[i]) {
                    colourGroup = (thresholds.length - 1) - i;
                }
            }
            return {
                code: spend.outputAreaCode,
                colourGroup
            };
        });
    }, [spendByOutputArea]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="spend-map">
            <OutputAreasMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colourPalette.sequential}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                displayPOIs={true}
                downloadData={spendByOutputArea}
            >
                <Explore />
                <PinnedLocation />
                <Legend outputArea={hoveredOutputArea} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
