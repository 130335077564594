import React from "react";
import { Popup } from "react-map-gl";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import { PointerInfo } from "components/visuals/maps/RegionsMap";

interface TotalSalesGrowthByRegionMapPopupProps {
    hoverInfo: PointerInfo,
    clickInfo: PointerInfo,
    regions: any[] //ToDo: change for Region type from module once migrated to typescript
}

const TotalSalesGrowthByRegionMapPopup: React.FC<TotalSalesGrowthByRegionMapPopupProps> = (props) => {
    const { hoverInfo, clickInfo, regions } = props;
    const theme = useTheme();
    const popup = React.useMemo(() => {
        const regionCode = clickInfo.regionCode ?? hoverInfo.regionCode;
        const region = regions.find(r => r.regionId === regionCode);
        if (!region) {
            return null;
        }
        const latitude = clickInfo.regionCode ? clickInfo.latitude : hoverInfo.latitude;
        const longitude = clickInfo.regionCode ? clickInfo.longitude : hoverInfo.longitude;
        return {
            latitude,
            longitude,
            region
        };
    }, [hoverInfo, clickInfo, regions]);

    if (!popup) {
        return null;
    }

    return (
        <Popup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={false}
            anchor="left"
        >
            <Box minWidth={theme.spacing(18)}>
                <Typography variant="subtitle2" component="div">
                    {stringUtils.capitaliseFirstLetter(popup.region.regionName.toLowerCase())}
                </Typography>
                <Box paddingTop={2} display="flex">
                    <Typography variant="body1" component="div">
                        Revenue
                    </Typography>
                    <Spacer />
                    <Typography variant="subtitle2" component="div">
                        {numberFormatter.toGBP(popup.region.sales)}
                    </Typography>
                </Box>
                <Box paddingTop={1} display="flex">
                    <Typography variant="body1" component="div">
                        Growth
                    </Typography>
                    <Spacer />
                    <PercentageIndicator
                        variant="subtitle2"
                        align="right"
                        showIndicator={false}
                        value={popup.region.growth}
                    />
                </Box>
            </Box>
        </Popup>
    );
};

export default TotalSalesGrowthByRegionMapPopup;
