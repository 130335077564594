import React from "react";

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { selectClosestFiveAnalogueStores } from "modules/customer/tools/location/overview/overviewSlice";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const AnalogueStores: React.FC = () => {
    const analogueStores = useAppSelector(selectClosestFiveAnalogueStores);

    return (
        <Box paddingTop={2} data-cy="analogue-stores">
            <Typography variant="h6" component="div">
                Analogue stores
            </Typography>
            <br />
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Store name
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Group
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Revenue
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Similarity
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Affluence
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center" style={{width: "200px"}}>
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                &nbsp;&nbsp;&nbsp;Age&nbsp;&nbsp;&nbsp;
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Children
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Diversity
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Urbanicity
                                </StyledTypography>
                            </TableCell>
                            {analogueStores.some(store => store.spendDifference) && <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                &nbsp;&nbsp;Spend&nbsp;&nbsp;
                                </StyledTypography>
                            </TableCell>}
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Area health
                                </StyledTypography>
                            </TableCell>
                            <TableCell align="center">
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Footfall
                                </StyledTypography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {analogueStores.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {item.store.name}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {item.store.group}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toGBP(item.store.revenue, 1)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toPercentage(item.similarityScore, false)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.affluenceCentile} (${numberFormatter.displaySign(item.affluenceDifference)})`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.ageCentile} (${numberFormatter.displaySign(item.ageDifference)})`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.childrenCentile} (${numberFormatter.displaySign(item.childrenDifference)})`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.diversityCentile} (${numberFormatter.displaySign(item.diversityDifference)})`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.urbanicityCentile} (${numberFormatter.displaySign(item.urbanicityDifference)})`}
                                    </Typography>
                                </TableCell>
                                {item.spendDifference !== undefined && <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.spendCentile} (${numberFormatter.displaySign(item.spendDifference)})`}
                                    </Typography>
                                </TableCell>}
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.areaHealthCentile} (${numberFormatter.displaySign(item.areaHealthDifference)})`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {`${item.retailCentre.footfallCentile} (${numberFormatter.displaySign(item.footfallDifference)})`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default AnalogueStores;
