import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartBase from "./ChartBase";

function moveLabels() {
    const ticks = this.xAxis[0].ticks;
    const safeDistance = 10;

    Object.keys(ticks).forEach(value => {
        const label = ticks[value].label;
        const bbox = label.getBBox(true);

        if (bbox.y >= 0) {
            if (bbox.x - safeDistance < 0) {
                label.attr({
                    x: label.xy.x + Math.abs(bbox.x - safeDistance)
                });
            }
            else if (bbox.x + bbox.width + safeDistance > this.chartWidth) {
                label.attr({
                    x: label.xy.x - (bbox.x + bbox.width + safeDistance - this.chartWidth)
                });
            }
        }
    });
}

const Radar = (props) => {
    const { loading, error, options: customOptions, dataAdditionPercent } = props;
    let maxValue = 0;
    /*
    Loop through all data points in the options to:
        - Find and appropriate max value for the y axis
        - Add custom.tooltipValue to store original y value in the data

    Data can be in one of three formats:
        1. y
        2. [x,y]
        3. {"y": y, "x": x, other key/values}
    
    Whatever the original data format, it is turned into type 3. 
    */
    for(let i = 0; i < customOptions.series.length; i++) {

        let data = customOptions.series[i].data;

        for (let j = 0; j < data.length; j++) {

          let dataValue = data[j];
          let yValue;
          let provValue = 0;

          if (typeof dataValue === 'number') {
            yValue = (dataValue);
            customOptions.series[i].data[j] = {
                y: Number(dataValue)
            };
          } else if (Array.isArray(dataValue)) {
            yValue = (dataValue[1]);
            customOptions.series[i].data[j] = {
                x: dataValue[0], 
                y: Number(dataValue[0])
            };
          } else if ("y" in dataValue) {
            yValue = (dataValue["y"]);
          }
          const tooltipValue = yValue === null ? yValue : Number(yValue);
          provValue = Number(yValue);

          //Add tooltipValue into "custom" key whether it already exists or not
          if ("custom" in data) {
            customOptions.series[i].data[j].custom = Object.assign(customOptions.series[i].data[j].custom, {tooltipValue});
          } else {
            customOptions.series[i].data[j].custom = {tooltipValue};
          }

          //Max value y axis:
          const customYMax = (typeof customOptions?.yAxis?.max === "number") ? customOptions?.yAxis?.max : -1e6;

          if(! isNaN(provValue)) {
              maxValue = Math.max(maxValue, provValue, customYMax);
          }
        }
    }

    /*
    Add an increment to y axis and data based on dataAdditionPercent (which can be 0 to have no effect)
    in order to show a small circle in the middle if data is all 0. Whilst this is less purely 
    representative, it is easier to understand when multiple data points are 0.
    When dataAdditionPercent <> 0, custom.tooltipValue should be used for tooltips since it holds
    the original data value.
    */
    const dataAddition = maxValue * (dataAdditionPercent/100);
    maxValue = maxValue + dataAddition;

    const yAxisMaxOptions = {
        yAxis: {
            max: maxValue
        }
    };
    
    const seriesOverwriteOptions = {
        series: []
    };
    for(let i = 0; i < customOptions.series.length; i++) {
        let data = customOptions.series[i].data;
        seriesOverwriteOptions.series.push({data: []});
        for (let j = 0; j < data.length; j++) {
            seriesOverwriteOptions.series[i].data.push({
                y: data[j].custom.tooltipValue + dataAddition
            });
        }
    }    

    const staticOptions = {
        chart: {
            polar: true,
            type: "area",
            events: {
                load: moveLabels,
                redraw: moveLabels
            }
        },
        legend: {
            enabled: false
        },
        subtitle: {
            text: null
        },
        credits: {
            enabled: false
        },
        title: {
            text: null 
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: false
                },
                fillOpacity: 0.50
            }
        },
        xAxis: {
            tickmarkPlacement: 'on',
            pointPlacement: 'on',
            gridLineWidth: 1,
            gridLineColor: '#646174',
        },
        yAxis: {
            gridLineInterpolation: 'polygon',
            gridLineWidth: 1,
            gridLineColor: '#646174',
            labels: {
                enabled: false
            },
            tickInterval: maxValue/3,
            plotLines: [{
                color: '#F0F0F0',
                width: 1,
                value: maxValue,
                zIndex: 1
            }],
            min: 0,
            max: maxValue,
            endOnTick: false,
            startOnTick: false
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            //Use custom.tooltipValue in tooltips always because it is the actual data value
            pointFormat: `<span style="color:{series.color}">{series.name}: <b>{point.custom.tooltipValue:,.1f}</b><br/>`
        },
        pane: {
            size: "80%",
            startAngle: 0
        }
        ,borderRadius: 0
        ,plotBorderWidth: 0
    };
    
    const options = _.merge({}, staticOptions, customOptions, yAxisMaxOptions, seriesOverwriteOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="radar-chart" />
    );
};

Radar.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    dataAdditionPercent: PropTypes.number
};

Radar.defaultProps = {
    loading: false,
    error: false,
    dataAdditionPercent: 0
};

export default Radar;
