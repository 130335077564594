import { createSelector } from "reselect";
import { median } from "mathjs";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const storePerformance = createSelector(
    state => selectStore(state),
    (selectedStoreSelector) => {
        const performance = {
            loading: false,
            error: false,
            values: {
                sales: 0,
                drivers: 0,
                profit: 0,
                areaHealth: 0,
                competition: 0,
                catchment: 0
            }
        };

        performance.values.sales = selectedStoreSelector.salesScore;
        performance.values.drivers = selectedStoreSelector.driversScore;
        performance.values.profit = selectedStoreSelector.profitScore;
        performance.values.areaHealth = selectedStoreSelector.areaHealthScore;
        performance.values.competition = selectedStoreSelector.competitionScore;
        performance.values.catchment = selectedStoreSelector.catchmentScore;

        return performance;
    }
);

const comparatorPerformance = createSelector(
    state => selectComparator(state),
    (comparatorStoresSelector) => {
        const performance = {
            loading: false,
            error: false,
            values: {
                sales: 0,
                drivers: 0,
                profit: 0,
                areaHealth: 0,
                competition: 0,
                catchment: 0
            }
        };

        performance.values.sales = median(comparatorStoresSelector.stores.map(item => item.salesScore));
        performance.values.drivers = median(comparatorStoresSelector.stores.map(item => item.driversScore));
        performance.values.profit = median(comparatorStoresSelector.stores.map(item => item.profitScore));
        performance.values.areaHealth = median(comparatorStoresSelector.stores.map(item => item.areaHealthScore));
        performance.values.competition = median(comparatorStoresSelector.stores.map(item => item.competitionScore));
        performance.values.catchment = median(comparatorStoresSelector.stores.map(item => item.catchmentScore));

        return performance;
    }
);

const storeVsComparatorPerformance = createSelector(
    storePerformance,
    comparatorPerformance,
    (storePerformance, comparatorPerformance) => {
        return {
            loading: comparatorPerformance.loading || storePerformance.loading,
            error: comparatorPerformance.error || storePerformance.error,
            store: storePerformance.values,
            comparator: comparatorPerformance.values
        };
    }
);

const overallStorePerformance = createSelector(
    state => selectStore(state),
    (selectedStoreSelector) => {
        const rag = {
            loading: false,
            error: false,
            id: "overall-store-performance",
            label: "Overall store performance",
            status: "info",
            value: ""
        };

        const score = selectedStoreSelector.getTotalScore();

        if (score > 22) {
            rag.status = "success";
            rag.label = `${selectedStoreSelector.name}'s overall performance is strong`;
        } else if (score >= 8 ) {
            rag.status = "warning";
            rag.label = `${selectedStoreSelector.name}'s overall performance is average with room for improvement`;
        } else {
            rag.status = "error";
            rag.label = `${selectedStoreSelector.name}'s overall performance is poor`;
        }

        return rag;
    }
);

const selectors = {
    overallStorePerformance,
    storeVsComparatorPerformance
};

export default selectors;
