import { combineReducers } from "redux";

import types from "./types";

const footfallEmpty = {
    loading: false,
    error: false,
    storeValues: [],
    comparatorValues: [],
    mapCenter:{
        latitude: 0,
        longitude: 0
    }
    
};

const footfall = (state = footfallEmpty, action) => {
    switch (action.type) {
        case types.GET_FOOTFALL_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                storeValues:[] ,
                comparatorValues: [],
                mapCenter:{
                    latitude: 0,
                    longitude: 0
                }
            };
        case types.GET_FOOTFALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                storeValues:action.payload.storeValues,
                comparatorValues: action.payload.comparatorValues,
                mapCenter: action.payload.mapCenter
            };
        case types.GET_FOOTFALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                storeValues: [],
                comparatorValues: [],
                mapCenter:{
                    latitude: 0,
                    longitude: 0
                }
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    footfall
});

export default reducer;
