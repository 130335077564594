import React from "react";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const RevenueSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            See your {store?.name} store's weekly sales history and projected sales for the next year. Find out
            whether its sales are moving in the right direction.
        </>
    );
};

export default RevenueSubtitle;
