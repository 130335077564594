import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { SpendCategory } from "./spendCategory";

export class SpendPerOutputArea {
    public readonly outputAreaCode: string;
    public readonly categoryName: string;
    public readonly supergroupName: string;
    public readonly probability: number;
    public readonly spend: number;
    public readonly spendPerHead: number;

    constructor(
        outputAreaCode: string,
        categoryName: string,
        supergroupName: string,
        probability: number,
        spend: number,
        spendPerHead: number,
    ) {
        this.outputAreaCode = outputAreaCode;
        this.categoryName = categoryName;
        this.supergroupName = supergroupName;
        this.probability = probability;
        this.spend = spend;
        this.spendPerHead = spendPerHead;
    }
}

export const loadSpendPerOutputArea = (retailCentreId: number | undefined, targetStoreCategoryId: number | undefined, spendCategories: SpendCategory[], catchmentAccountId: string): AppThunk<Promise<SpendPerOutputArea[]>> => async (dispatch) => {
    if (!retailCentreId || !targetStoreCategoryId) {
        return [];
    }

    try {
        const spendCategoryIDs = spendCategories.map(category => String(category.id));

        const query = {
            dimensions: [
                "CatchmentMarketCategorySpend.OAID",
                "CatchmentMarketCategorySpend.SpendCategory_ID",
                "CatchmentMarketCategorySpend.Probability",
                "CatchmentMarketCategorySpend.SupergroupName",
                "CatchmentMarketCategorySpend.CategorySpend",
                "CatchmentMarketCategorySpend.CategorySpendPerHead"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals",
                values: [String(retailCentreId)]
            }, {
                member: "CatchmentMarketCategorySpend.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "CatchmentMarketCategorySpend.SpendCategory_ID",
                operator: "equals",
                values: spendCategoryIDs
            }],
            segments: [
                "CatchmentMarketCategorySpend.Scenario"
            ],
            order: [
                ["CatchmentMarketCategorySpend.OAID", "asc"]
            ]
        };
        const resultSet = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentMarketCategorySpend")) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => {
            const outputAreaCode = row["CatchmentMarketCategorySpend.OAID"];
            const categoryID = row["CatchmentMarketCategorySpend.SpendCategory_ID"];
            const categoryName = spendCategories.find(item => item.id === categoryID)?.name ?? "";
            const spend = Number(row["CatchmentMarketCategorySpend.CategorySpend"]);
            const probability = Number(row["CatchmentMarketCategorySpend.Probability"]);
            const spendPerHead = Number(row["CatchmentMarketCategorySpend.CategorySpendPerHead"]);
            const supergroupName = row["CatchmentMarketCategorySpend.SupergroupName"];
            return new SpendPerOutputArea(outputAreaCode, categoryName, supergroupName, probability, spend, spendPerHead);
        });
    } catch (error) {
        dispatch(logError("Error loading SpendPerOutputArea.", error));
        throw error;
    }
};
