import React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface SubchapterProps {
    number: number,
    title: string,
    subtitle?: React.ReactElement,
    hidden?: boolean,
    dataCy: string,
    children?: React.ReactElement
}

const useStyles = makeStyles(theme => ({
    number: {
        color: theme.palette.text.disabled
    }
}));

const Subchapter: React.FC<SubchapterProps> = (props) => {
    const { dataCy, number, title, subtitle, hidden } = props;
    const classes = useStyles();

    return (
        <Box paddingBottom={4} data-cy={dataCy} hidden={hidden}>
            <Box paddingTop={2} paddingBottom={3} data-cy="header">
                <Typography variant="h5" component="div" data-cy="title" gutterBottom>
                    <span className={classes.number} data-cy="number">{number}&nbsp;&nbsp;</span>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="subtitle1" component="div" data-cy="subtitle">
                        {subtitle}
                    </Typography>
                )}
            </Box>
            {props.children}
        </Box>
    );
};

export default Subchapter;
