import types from "./types";

const getCompetitionScoreVisRequest = () => ({
    type: types.GET_COMPETITION_SCORE_VIS_REQUEST
});

const getCompetitionScoreVisSuccess = (selectedStoreCompScore, comparatorMedianCompScore) => ({
    type: types.GET_COMPETITION_SCORE_VIS_SUCCESS,
    payload: {
        selectedStoreCompScore,
        comparatorMedianCompScore
    }
});

const getCompetitionScoreVisFailure = () => ({
    type: types.GET_COMPETITION_SCORE_VIS_FAILURE
});

const getCompetitionScoreRagRequest = () => ({
    type: types.GET_COMPETITION_SCORE_RAG_REQUEST
});

const getCompetitionScoreRagSuccess = (status, value) => ({
    type: types.GET_COMPETITION_SCORE_RAG_SUCCESS,
    payload: {
        status,
        value
    }
});

const getCompetitionScoreRagFailure = () => ({
    type: types.GET_COMPETITION_SCORE_RAG_FAILURE
});

const actions = {
    getCompetitionScoreVisRequest,
    getCompetitionScoreVisSuccess,
    getCompetitionScoreVisFailure,
    getCompetitionScoreRagRequest,
    getCompetitionScoreRagSuccess,
    getCompetitionScoreRagFailure
};

export default actions;
