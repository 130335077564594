import React from "react";

import { Grid } from "@material-ui/core";

import BreakdownOfCatchmentAreaDemographic
    from "./breakdownOfCatchmentAreaDemographic/BreakdownOfCatchmentAreaDemographic";
import TotalPopulationInCatchmentArea from "./TotalPopulationInCatchmentArea";
import WeightedPopulationInCatchmentArea from "./WeightedPopulationInCatchmentArea";

const CatchmentAreaDemographics: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <WeightedPopulationInCatchmentArea />
            </Grid>
            <Grid item xs={12}>
                <TotalPopulationInCatchmentArea />
            </Grid>
            <Grid item xs={12}>
                <BreakdownOfCatchmentAreaDemographic />
            </Grid>
        </Grid>
    );
};

export default CatchmentAreaDemographics;
