import { combineReducers } from "redux";

import performance from "./performance";
import portfolio from "./portfolio";
import portfolioNew from "./portfolioNew";
import cost from "./cost";
import range from "./range";

const reducer = combineReducers({
    performance,
    portfolio,
    portfolioNew,
    cost,
    range
});

export default reducer;
