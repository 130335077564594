import types from "./types";

const getStoreYoYGrowthVsComparatorRequest = () => ({
    type: types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_REQUEST
});

const getStoreYoYGrowthVsComparatorSuccess = (value, percentageDifference) => ({
    type: types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getStoreYoYGrowthVsComparatorFailure = () => ({
    type: types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_FAILURE
});

const getStoreForecastYoYGrowthVsComparatorRequest = () => ({
    type: types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_REQUEST
});

const getStoreForecastYoYGrowthVsComparatorSuccess = (value, percentageDifference) => ({
    type: types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getStoreForecastYoYGrowthVsComparatorFailure = () => ({
    type: types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_FAILURE
});

const getStoreVsComparatorMoMGrowthRequest = () => ({
    type: types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_REQUEST
});

const getStoreVsComparatorMoMGrowthSuccess = (store, comparator) => ({
    type: types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getStoreVsComparatorMoMGrowthFailure = () => ({
    type: types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_FAILURE
});

const getStoreHistoricGrowthVsComparatorRequest = () => ({
    type: types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_REQUEST
});

const getStoreHistoricGrowthVsComparatorSuccess = (status, value) => ({
    type: types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_SUCCESS,
    payload: {
        status,
        value
    }
});

const getStoreHistoricGrowthVsComparatorFailure = () => ({
    type: types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_FAILURE
});

const getStoreForecastGrowthVsComparatorRequest = () => ({
    type: types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_REQUEST
});

const getStoreForecastGrowthVsComparatorSuccess = (status, value) => ({
    type: types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_SUCCESS,
    payload: {
        status,
        value
    }
});

const getStoreForecastGrowthVsComparatorFailure = () => ({
    type: types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_FAILURE
});
const actions = {
    getStoreYoYGrowthVsComparatorRequest,
    getStoreYoYGrowthVsComparatorSuccess,
    getStoreYoYGrowthVsComparatorFailure,
    getStoreForecastYoYGrowthVsComparatorRequest,
    getStoreForecastYoYGrowthVsComparatorSuccess,
    getStoreForecastYoYGrowthVsComparatorFailure,
    getStoreVsComparatorMoMGrowthRequest,
    getStoreVsComparatorMoMGrowthSuccess,
    getStoreVsComparatorMoMGrowthFailure,
    getStoreHistoricGrowthVsComparatorRequest,
    getStoreHistoricGrowthVsComparatorSuccess,
    getStoreHistoricGrowthVsComparatorFailure,
    getStoreForecastGrowthVsComparatorRequest,
    getStoreForecastGrowthVsComparatorSuccess,
    getStoreForecastGrowthVsComparatorFailure
};

export default actions;
