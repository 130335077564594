import React from "react";

import { Box, Button, Checkbox, IconButton, MobileStepper, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LaunchIcon from "@material-ui/icons/Launch";
import { withStyles } from "@material-ui/core/styles";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import GuidanceSteps from "./GuidanceSteps";

import { useAppDispatch, useAppSelector } from "store";
import {
    downloadDataGuidance,
    GuidanceStep,
    hideGuidance,
    selectDontShowGuidanceAgain,
    selectGuidanceActiveStep,
    selectGuidanceVisibility,
    setDontShowGuidanceAgain,
    setGuidanceActiveStep,
    showGuidance
} from "modules/customer/data/dataSlice";

const StyledMobileStepper = withStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    dotActive: {
        backgroundColor: theme.palette.common.white
    }
}))(MobileStepper);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginTop: theme.spacing(1)
    }
}))(Button);

const Guidance: React.FC = () => {
    const dispatch = useAppDispatch();
    const dontShowGuidanceAgain = useAppSelector(selectDontShowGuidanceAgain);
    const guidanceVisibility = useAppSelector(selectGuidanceVisibility);
    const guidanceActiveStep = useAppSelector(selectGuidanceActiveStep);

    const handleOpenClick = () => {
        dispatch(showGuidance());
    };

    const handleCloseClick = () => {
        dispatch(hideGuidance());
        dispatch(setGuidanceActiveStep(GuidanceStep.DashUsesDatasets));
    };

    const handleReadDataGuidanceClick = () => {
        dispatch(downloadDataGuidance());
    };

    const handleDontShowAgainChange = () => {
        dispatch(setDontShowGuidanceAgain(!dontShowGuidanceAgain));
    };

    const handleNextClick = () => {
        const nextStep = (() => {
            switch (guidanceActiveStep) {
                case GuidanceStep.DashUsesDatasets:
                    return GuidanceStep.DownloadTemplates;
                case GuidanceStep.DownloadTemplates:
                    return GuidanceStep.UploadData;
                case GuidanceStep.UploadData:
                    return GuidanceStep.ProcessData;
                case GuidanceStep.ProcessData:
                    return GuidanceStep.RefreshAnalytics;
                case GuidanceStep.RefreshAnalytics:
                    return GuidanceStep.ReadDataGuidance;
                case GuidanceStep.ReadDataGuidance:
                default:
                    return GuidanceStep.ReadDataGuidance;
            }
        })();
        dispatch(setGuidanceActiveStep(nextStep));
    };

    const handleBackClick = () => {
        const previousStep = (() => {
            switch (guidanceActiveStep) {
                case GuidanceStep.ReadDataGuidance:
                    return GuidanceStep.RefreshAnalytics;
                case GuidanceStep.RefreshAnalytics:
                    return GuidanceStep.ProcessData;
                case GuidanceStep.ProcessData:
                    return GuidanceStep.UploadData;
                case GuidanceStep.UploadData:
                    return GuidanceStep.DownloadTemplates;
                case GuidanceStep.DownloadTemplates:
                    return GuidanceStep.DashUsesDatasets;
                case GuidanceStep.DashUsesDatasets:
                default:
                    return GuidanceStep.DashUsesDatasets;
            }
        })();
        dispatch(setGuidanceActiveStep(previousStep));
    };

    return (
        <>
            <IconButton color="inherit" onClick={handleOpenClick}>
                <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
            <TwoPanelDialog
                open={guidanceVisibility.isVisible}
                onClose={handleCloseClick}
                leftPanelTitle={
                    <Box display="flex" alignItems="center" paddingBottom={3}>
                        <InfoOutlinedIcon />
                        &nbsp;&nbsp;
                        <Typography variant="h5" component="div">
                            Manage your data
                        </Typography>
                    </Box>
                }
                leftPanelContent={
                    <>
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            More info
                        </Typography>
                        <StyledButton
                            variant="text"
                            size="medium"
                            color="default"
                            startIcon={<LaunchIcon />}
                            onClick={handleReadDataGuidanceClick}
                        >
                            Read data guidance
                        </StyledButton>
                    </>
                }
                leftPanelActions={
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" component="div">
                            Don't show me this again
                        </Typography>
                        <Checkbox color="default" checked={dontShowGuidanceAgain} onChange={handleDontShowAgainChange} />
                    </Box>
                }
                leftPanelActionsJustifyContent={JustifyContent.FlexStart}
                rightPanelContent={<GuidanceSteps />}
                rightPanelActions={
                    <StyledMobileStepper
                        variant="dots"
                        position="static"
                        steps={6}
                        activeStep={guidanceActiveStep}
                        nextButton={
                            <Button
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                disabled={guidanceActiveStep === 5}
                                onClick={handleNextClick}
                            >
                                Next
                            </Button>
                        }
                        backButton={
                            <Button
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                disabled={guidanceActiveStep === 0}
                                onClick={handleBackClick}
                            >
                                Back
                            </Button>
                        }
                    />
                }
                rightPanelActionsJustifyContent={JustifyContent.SpaceBetween}
            />
        </>
    );
};

export default Guidance;
