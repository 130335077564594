import React from "react";
import { Box } from "@material-ui/core";

import { useAppSelector } from "store";

import numberFormatter from "utils/numberFormatter";
import KPIFact from "components/visuals/KPIFact";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";

const CostsOpportunity = () => {
    const selectedStoreAndCost = useAppSelector(selectSelectedStoreByCostType);
    const opportunityValueString = numberFormatter.toGBP(selectedStoreAndCost?.opportunityValue, 1);
    const opportunityValuePercentageString = numberFormatter.toPercentage(selectedStoreAndCost?.opportunityValueAsPercentageOfRevenue, false);

    const formattedValueString = `${opportunityValueString} (${opportunityValuePercentageString})`;
    const label = `${selectedStoreAndCost?.storeName} ${selectedStoreAndCost?.costName} costs opportunity`;

    return (
        <Box width="100%" height="100%" data-cy="cost-opportunity">
            <KPIFact
                loading={false}
                error={false}
                label={label}
                value={formattedValueString} />
        </Box>
    );
};

export default CostsOpportunity;
