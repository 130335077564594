import React from "react";

import { Box, Typography } from "@material-ui/core";

import { FilterStep, selectActiveStep } from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppSelector } from "store";

const Title: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            <Typography variant="h5" component="div">
                Portfolio setup
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    {activeStep === FilterStep.SelectStore && "To start managing your store portfolio, please select the store you would like to analyse."}
                    {activeStep === FilterStep.SelectComparator && "Select what you want to compare that store to."}
                    {activeStep === FilterStep.CustomSelection && "Now select a store, or several, to compare your chosen store against."}
                </Typography>
            </Box>
        </>
    );
};

export default Title;
