import React from "react";

import { Box } from "@material-ui/core";
import { selectIsLoading, selectHasErrors, selectNumberOfOtherCompetitors } from "modules/customer/tools/location/competition/competitionSlice";
import { useAppSelector } from "store";
import KPIFact from "components/visuals/KPIFact";

const NumberOfOtherCompetitors: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const otherCompetitorsCount = useAppSelector(selectNumberOfOtherCompetitors);
    const kpiValue = String(otherCompetitorsCount);
    const label = "Other competitors within 5km of the selected location";

    return (
        <Box width="100%" height="100%" data-cy="number-of-other-competitors">
            <KPIFact
                loading={isLoading}
                error={hasErrors}
                label={label}
                value={kpiValue}
            />
        </Box>
    );
};

export default NumberOfOtherCompetitors;
