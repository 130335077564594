import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { selectFilter, setFilter } from "modules/admin/users/usersSlice";
import { useAppDispatch, useAppSelector } from "store";

const FilterUsers: React.FC = () => {
    const dispatch = useAppDispatch();
    const filter = useAppSelector(selectFilter);

    const handleNameOrEmailOrCompanyNameOrCompanyDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nameOrEmailOrCompanyNameOrCompanyDisplayName = event.target.value;
        const newFilter = { ...filter, nameOrEmailOrCompanyNameOrCompanyDisplayName };
        dispatch(setFilter(newFilter));
    };

    return (
        <TextField
            placeholder="Enter name, email, company name or company display name"
            variant="outlined"
            size="small"
            color="secondary"
            value={filter.nameOrEmailOrCompanyNameOrCompanyDisplayName}
            onChange={handleNameOrEmailOrCompanyNameOrCompanyDisplayNameChange}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default FilterUsers;
