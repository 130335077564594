const GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_REQUEST = "spark/customer/insights/portfolio/sales/productCategoryGrowth/GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_REQUEST";
const GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_SUCCESS = "spark/customer/insights/portfolio/sales/productCategoryGrowth/GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_SUCCESS";
const GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_FAILURE = "spark/customer/insights/portfolio/sales/productCategoryGrowth/GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_FAILURE";

const types = {
    GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_REQUEST,
    GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_SUCCESS,
    GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_FAILURE
};

export default types;
