import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            One of the best indicators of an area's health is the change in the number of stores. When more stores open
            in a location, the location will typically thrive due to the new shops driving additional footfall.
            Inversely, when more stores close, there is less reason for potential customers to visit the area, meaning
            there's an increased dependency on the existing customer base. Understand how this metric has trended over
            time for your selected location.
        </>
    );
};

export default Subtitle;
