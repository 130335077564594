import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { Store } from "modules/customer/tools/product/store";
import { selectFilterStores } from "modules/customer/tools/product/productSlice";

interface FiltersVisibility {
    isVisible: boolean
}

export enum StoreProductFilterstep {
    SelectStore,
    SelectProduct
}

export interface StoresSearch {
    name: string
}

interface FiltersState {
    storeProductFiltersVisibility: FiltersVisibility,
    activeStep: StoreProductFilterstep,
    storesSearch: StoresSearch,
    candidateStore?: Store
}

const initialState: FiltersState = {
    storeProductFiltersVisibility: {
        isVisible: false
    },
    activeStep: StoreProductFilterstep.SelectStore,
    storesSearch: {
        name: ""
    },
    candidateStore: undefined
};

const storeProductFiltersSlice = createSlice({
    name: "customer/tools/product/storeProductFilters",
    initialState,
    reducers: {
        showStoreProductFilters: (state) => {
            state.activeStep = initialState.activeStep;
            state.storeProductFiltersVisibility.isVisible = true;
        },
        hideStoreProductFilters: (state) => {
            state.storeProductFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<StoreProductFilterstep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = initialState.storesSearch;
        },
        setCandidateStore: (state, action: PayloadAction<Store>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        }
    },
    extraReducers: (builder: any) => {

    }
});

export const {
    showStoreProductFilters,
    hideStoreProductFilters,
    setActiveStep,
    setStoresSearch,
    setCandidateStore,
    clearActiveStep,
    clearCandidateStore,
    clearStoresSearch
} = storeProductFiltersSlice.actions;

export const resetStoreProductFilters = (): AppThunk => async (dispatch) => {
    dispatch(storeProductFiltersSlice.actions.hideStoreProductFilters());
    dispatch(storeProductFiltersSlice.actions.clearActiveStep());
    dispatch(storeProductFiltersSlice.actions.clearStoresSearch());
    dispatch(storeProductFiltersSlice.actions.clearCandidateStore());
};

export const selectStoreProductFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.storeProductFilters.storeProductFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.storeProductFilters.activeStep;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.tools.product.storeProductFilters.storesSearch;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.tools.product.storeProductFilters.candidateStore;
};

export const selectFilteredStores = createSelector(
    (state: RootState) => selectFilterStores(state),
    selectStoresSearch,
    // selectFilter,
    // selectSort,
    (stores, search/*, filter, sort*/) => {
        const searchString = search.name.toLowerCase();
        const filteredStores = stores.data.filter(store =>
            (!searchString || store.name.toLowerCase().includes(searchString)));
        return filteredStores;
    }
);


export default storeProductFiltersSlice;
