import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import { selectCatchmentDemographicIndicators } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const AgeStructureGB: React.FC = () => {
    const title = "Age structure (Age)";
    const categories = [
        "=<16",
        "17-25",
        "26-35",
        "36-45",
        "46-55",
        "56-65",
        "66+"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.ageStructureGB;
    const selectedStoreValues = selectedStore ? [
        selectedStore.age0to16,
        selectedStore.age17to25,
        selectedStore.age26to35,
        selectedStore.age36to45,
        selectedStore.age46to55,
        selectedStore.age56to65,
        selectedStore.age66Plus
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.ageStructureGB;
    const comparatorValues = comparator ? [
        comparator.age0to16,
        comparator.age17to25,
        comparator.age26to35,
        comparator.age36to45,
        comparator.age46to55,
        comparator.age56to65,
        comparator.age66Plus
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default AgeStructureGB;
