import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { Collapse, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledListItemText = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2)
    }
}))(ListItemText);

const AboutMobile = (props) => {
    const { onLinkClick } = props;
    const [openList, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!openList);
    };

    const handleLinkClick = () => {
        onLinkClick();
    };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText
                    primary="About"
                    primaryTypographyProps={{
                        variant: "h6",
                        component: "div"
                    }}
                />
                {openList ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </ListItem>
            <Collapse in={openList} timeout="auto" unmountOnExit>
                <Divider />
                <List component="div" disablePadding>
                    <ListItem
                        divider
                        button
                        component={NavLink}
                        to="/our-story"
                        onClick={handleLinkClick}
                    >
                        <StyledListItemText
                            primary="Our story"
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                component: "div"
                            }}
                        />
                    </ListItem>
                    <ListItem
                        divider
                        button
                        component={NavLink}
                        to="/meet-the-team"
                        onClick={handleLinkClick}
                    >
                        <StyledListItemText
                            primary="Meet the team"
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                component: "div"
                            }}
                        />
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/frequently-asked-questions"
                        onClick={handleLinkClick}
                    >
                        <StyledListItemText
                            primary="FAQs"
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                component: "div"
                            }}
                        />
                    </ListItem>
                </List>
            </Collapse>
            <Divider />
        </>
    );
};

AboutMobile.propTypes = {
    onLinkClick: PropTypes.func.isRequired
};

export default AboutMobile;
