import types from "./types";

const getDemographicsRequest = () => ({
    type: types.GET_DEMOGRAPHICS_REQUEST
});

const getDemographicsSuccess = (demographicsData, salesData) => ({
    type: types.GET_DEMOGRAPHICS_SUCCESS,
    payload: {
        demographicsData,
        salesData
    }
});

const getDemographicsFailure = () => ({
    type: types.GET_DEMOGRAPHICS_FAILURE
});


const actions = {
    getDemographicsRequest,
    getDemographicsSuccess,
    getDemographicsFailure
};

export default actions;
