import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import BeeSwarm from "components/visuals/BeeSwarm";
import useColourPalette from "components/visuals/useColourPalette";
import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";
import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/profit/rankedGrossProfitMargin";

const StoreVsComparatorRankedGrossProfit = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorRankedGrossProfit = useSelector(state => selectors.storeVsComparatorRankedGrossProfit(state));
    const { loading, error, store, comparator } = storeVsComparatorRankedGrossProfit;

    const chartData = (data) => {
        return data.map(row => {
            return {
                custom: {
                    store: row.Store,
                    rank: row.rank
                },
                x: mathUtils.round(Number(row.GrossProfitPercent), 2),
                y: 0
                //month: row.month,  //For post-MVP
                //year: row.year,
            };
        }).sort((a, b) => {
            return a.x - b.x;
        });
    };

    const valuesArr = (data) => {
        const arr = (Array.isArray(data)) ? data : [data];
        return arr.map(row => {
            return Number(row.GrossProfitPercent);
        });
    };

    const allDataPoints = valuesArr(store).concat(valuesArr(comparator));

    const firstTertile = arrayUtils.quantile(allDataPoints, 1 / 3);
    const secondTertile = arrayUtils.quantile(allDataPoints, 2 / 3);

    const plotBands = (allDataPoints.length < 3) ? [] : [{
        from: -1e6, to: firstTertile, color: colourPalette.RAGChartZones[0],
        label: {
            style: {
                fontWeight: "bold"
            },
            text: "Underperforming", x: 0, y: -5
        }
    },
        {
            from: firstTertile, to: secondTertile, color: colourPalette.RAGChartZones[1],
            label: {
                style: {
                    fontWeight: "bold"
                },
                text: "Average", x: 0, y: -5
            }
        },
        {
            from: secondTertile, to: 10e12, color: colourPalette.RAGChartZones[2],
            label: {
                style: {
                    fontWeight: "bold"
                },
                text: "Top performers", x: 0, y: -5
            }
        }];

    const options = {
        chart: {
            height: 230
        },
        title: {
            text: `Ranked rolling 12-month gross profit % of stores in ${selectedComparator.name} vs ${selectedStore.name}`,
            margin: 30
        },
        series: [{
            name: selectedStore.name,
            color: colourPalette.comparators[0],
            data: chartData(store)
        },
            {
                name: selectedComparator.name,
                color: colourPalette.comparators[1],
                data: chartData(comparator)
            }],
        xAxis: {
            maxPadding: 0.1,
            minPadding: 0.1,
            plotBands: plotBands,
            labels: {
                format: "{text}%"
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: "<table>",
            pointFormatter: function () {
                const series = this.series;
                const seriesColor = series.color;

                return stringUtils.tooltipHTML(["Gross profit: ", "Rank: "], {
                    header: `Store: <span style="color:${seriesColor}">${this.custom.store}</span>`,
                    values: [numberFormatter.toPercentage(this.x, true, 2), this.custom.rank]
                });
            },
            footerFormat: "</table>",
            useHTML: true
        }

    };

    return (
        <Box data-cy="store-vs-comparator-ranked-gross-profit">
            <BeeSwarm loading={loading} error={error} options={options} />
        </Box>
    );
};

export default StoreVsComparatorRankedGrossProfit;
