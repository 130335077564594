import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Bullet from "components/visuals/Bullet";

import { operations, selectors } from "modules/customer/insights/performance/budget/contributionVsBudget";

const ContributionPerformanceVsBudget = () => {
    const dispatch = useDispatch();
    const contributionPerformanceVsBudget = useSelector(state => selectors.contributionPerformanceVsBudget(state));
    const { loading, error, contributionFYTD, contributionFYTG, budgetFY } = contributionPerformanceVsBudget;

    const noBudgetData = (budgetFY === 0);

    React.useEffect(() => {
        dispatch(operations.getContributionPerformanceVsBudget());
    }, [dispatch]);

    return (
        <Box data-cy="contribution-performance-vs-budget">
            <Bullet
                loading={loading}
                error={error}
                actuals={contributionFYTD}
                actualsTitle="Historic contribution"
                forecasts={contributionFYTG}
                forecastTitle="Forecast contribution"
                title="Historic and forecast contribution vs budgeted contribution"
                budget={budgetFY}
                noData={noBudgetData}
            />
        </Box>
    );
};

export default ContributionPerformanceVsBudget;
