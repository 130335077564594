import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Monitor the pulse of your {store?.name} store's local retail environment.
            Track the year-on-year shifts in retail presence to discern whether the local market is on an upward trend or facing a decline.
            Such insights are pivotal in assessing the area's recovery from challenges like the pandemic and the resulting fluctuations in footfall tied to the area's appeal.
            Leverage these observations in critical lease negotiations, where a trend of closures might provide leverage for negotiating more favourable terms, and a surge in openings may indicate a thriving market suitable for additional investment.
        </>
    );
};

export default Subtitle;
