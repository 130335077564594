import React, { useEffect, useRef } from 'react';
// @ts-ignore
import ReactDOM from "react-dom";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Point } from "highcharts";

interface HighchartsCustomTooltipProps {
  point: Point;
  event: any;
  content: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    tooltipContainer: {
        position: "fixed",
        pointerEvents: "none",
        zIndex: 9999,
        left: "-9999px",
        top: "-9999px"
    },
    tooltip: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
        // width: "300px",
        width: "100%",
        height: "100%",
        // maxWidth: "200px",
        boxShadow: theme.shadows[3],
    },
    arrow: {
        pointerEvents: "none",
        position: "absolute",
        bottom: "-5px",
        left: "50%",
        transform: "translateX(-50%) rotate(45deg)",
        width: "10px",
        height: "10px",
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
    },
    tooltipChild: {
        pointerEvents: "none"
    }
}));

const HighchartsCustomTooltip: React.FC<HighchartsCustomTooltipProps> = ({ point, event, content }) => {
  const classes = useStyles();
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (tooltipRef.current && point.series !== null) {
            const chart = point.series.chart;
            const chartOffset = chart.container.getBoundingClientRect();
            const plotLeft = chart.plotLeft;
            const plotTop = chart.plotTop;

            const tooltipWidth = tooltipRef.current.offsetWidth;
            const tooltipHeight = tooltipRef.current.offsetHeight;

            const adjustedX = chartOffset.left + event.target.plotX + plotLeft - tooltipWidth / 2;
              const adjustedY = chartOffset.top + event.target.plotY + plotTop - tooltipHeight - 15;

            tooltipRef.current.style.left = `${adjustedX}px`;
            tooltipRef.current.style.top = `${adjustedY}px`;
        }
    }, [point, event]);

  return ReactDOM.createPortal(
    <div ref={tooltipRef} className={classes.tooltipContainer}>
      <Card className={classes.tooltip}>
        <CardContent>
          {content}
        </CardContent>
        <div className={classes.arrow}></div>
      </Card>
    </div>,
    document.body
  );
};

export default HighchartsCustomTooltip;
