import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectPredictionIsLoading,
    selectPredictionHasErrors,
    selectTypicalWeekRevenuePrediction
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const TypicalWeekRevenuePrediction: React.FC = () => {
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const typicalWeekRevenuePrediction = useAppSelector(selectTypicalWeekRevenuePrediction);
    const valueFormatted = numberFormatter.toGBP(typicalWeekRevenuePrediction, 1);
    const label = "Pinned location typical week revenue prediction";

    return (
        <Box width="100%" height="100%" data-cy="typical-week-revenue-prediction">
            <KPIFact loading={predictionIsLoading} error={predictionHasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default TypicalWeekRevenuePrediction;
