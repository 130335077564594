import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Search from "./Search";
import PartnerCard from "./PartnerCard";
import Title from "./Title";
import { Partner } from "modules/customer/tools/product/partner";
import { useAppDispatch, useAppSelector } from "store";
import { clearCandidatePartner, selectCandidatePartner, selectFilteredPartners, setCandidatePartner } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";

const NUMBER_OF_VISIBLE_PARTNERS_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const PartnerFascias: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const candidatePartner = useAppSelector(selectCandidatePartner);
    const [numberOfVisiblePartners, setNumberOfVisiblePartners] = React.useState<number>(0);
    const partners = useAppSelector(selectFilteredPartners);
    const partnersLabel = `${partners.length} ${partners.length === 1 ? "partner" : "partners"}`;

    const visiblePartners = React.useMemo(() => {
        return partners.slice(0, numberOfVisiblePartners);
    }, [partners, numberOfVisiblePartners]);

    const handleSelect = (partner: Partner) => {
        dispatch(setCandidatePartner(partner));
    };

    const handleDeselect = () => {
        dispatch(clearCandidatePartner());
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisiblePartners(previousNumberOfVisiblePartners => previousNumberOfVisiblePartners + NUMBER_OF_VISIBLE_PARTNERS_INCREMENT);
    }, [setNumberOfVisiblePartners]);

    React.useEffect(() => {
        setNumberOfVisiblePartners(NUMBER_OF_VISIBLE_PARTNERS_INCREMENT);
    }, [partners]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisiblePartners <= partners.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                        <Typography variant="body1" component="div">
                            &nbsp;&nbsp;{partnersLabel}
                        </Typography>
                        <Spacer />
                    </Box>
                </Box>
                {visiblePartners.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visiblePartners.map(partner =>
                                    <Grid key={partner.id} item xs={4}>
                                        <PartnerCard
                                            partner={partner}
                                            selected={candidatePartner?.id === partner.id}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default PartnerFascias;
