const GET_TOTAL_SALES_GROWTH_BY_REGION_REQUEST = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_REQUEST";
const GET_TOTAL_SALES_GROWTH_BY_REGION_SUCCESS = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_SUCCESS";
const GET_TOTAL_SALES_GROWTH_BY_REGION_FAILURE = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_FAILURE";
const GET_SALES_BY_REGION_GROWTH_REQUEST = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_SALES_BY_REGION_GROWTH_REQUEST";
const GET_SALES_BY_REGION_GROWTH_SUCCESS = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_SALES_BY_REGION_GROWTH_SUCCESS";
const GET_SALES_BY_REGION_GROWTH_FAILURE = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_SALES_BY_REGION_GROWTH_FAILURE";
const GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST";
const GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS";
const GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE";
const SET_SELECTED_REGION = "spark/customer/insights/performance/sales/regionalGrowthBreakdown/SET_SELECTED_REGION";

const types = {
    GET_TOTAL_SALES_GROWTH_BY_REGION_REQUEST,
    GET_TOTAL_SALES_GROWTH_BY_REGION_SUCCESS,
    GET_TOTAL_SALES_GROWTH_BY_REGION_FAILURE,
    GET_SALES_BY_REGION_GROWTH_REQUEST,
    GET_SALES_BY_REGION_GROWTH_SUCCESS,
    GET_SALES_BY_REGION_GROWTH_FAILURE,
    GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST,
    GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS,
    GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE,
    SET_SELECTED_REGION
};

export default types;
