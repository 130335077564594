import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import { selectClusterStoresCosts } from "modules/customer/insights/cost/storeCosts/storeCostsSlice";

const CustomAccordion = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.paper,
        borderRadius: "6px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgba(255, 255, 255, 0.25)"
    },
    expanded: {}
}))(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
    root: {
        // @ts-ignore
        color: theme.palette.text.primary,
        padding: 0,
        paddingRight: theme.spacing(1.5),
        minHeight: 0,
        "&$expanded": {
            padding: 0,
            paddingRight: theme.spacing(1.5),
            minHeight: 0,
            backgroundColor: theme.palette.primary.main,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    content: {
        paddingLeft: theme.spacing(1.5),
        "&$expanded": {
            margin: 0
        }
    },
    expanded: {}
}))(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        // @ts-ignore
        color: theme.palette.text.primary,
        padding: 0,
        paddingRight: theme.spacing(1.5),
        margin: 0,
    },
    content: {
        paddingLeft: theme.spacing(1.5)
    },
    expanded: {}
}))(AccordionDetails);

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        maxHeight: 210
    }
}))(TableContainer);

const StyledTableCell = withStyles(theme => ({
    root: {
        border: 0,
        padding: theme.spacing(1),
        // @ts-ignore
        backgroundColor: theme.palette.background.paper,
    }
}))(TableCell);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.secondary
    }
}))(Typography);

const StoreClusteringGroup = () => {
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);
    const selectedStoreName = selectedStore?.storeName;
    const selectedCostType = selectedStore?.costName;
    const data = useAppSelector(selectClusterStoresCosts);

    return (
        <CustomAccordion elevation={0} >
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="#FFFFFF" />} >
                <Typography variant="subtitle1" component="div">
                    What are stores that are within the same cluster as your {selectedStoreName} store?
                </Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
                {/* @ts-ignore */}
                <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow style={{ background: 'red' }}>
                                <StyledTableCell align="left">
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="body1" component="div">
                                        Store name
                                    </StyledTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="body1" component="div">
                                        {selectedCostType} costs as a % of revenue
                                    </StyledTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="body1" component="div">
                                        {selectedCostType} cost year-to-date
                                    </StyledTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="body1" component="div">
                                        Variance
                                    </StyledTypography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="body1" component="div">
                                        Similarity score
                                    </StyledTypography>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((store, index) =>
                                <TableRow key={index}>
                                    <StyledTableCell align="left">
                                        <Typography variant="body1" component="div">
                                            {store.storeName}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant="body1" component="div">
                                            {numberFormatter.toPercentage(store.costAsPercentageOfRevenue, false)}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant="body1" component="div">
                                            {numberFormatter.toGBP(store.costValue, 1)}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant="body1" component="div">
                                            {numberFormatter.toGBP(store.variance, 1)}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant="body1" component="div">
                                            {numberFormatter.toPercentage(store.similarityScore, true)}
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </CustomAccordionDetails>
        </CustomAccordion>
    );
};

export default StoreClusteringGroup;
