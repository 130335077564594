import { immerable } from "immer";
import _ from "lodash";

import { Store } from "./store";

export enum ComparatorType {
    AllStores,
    AllStoresInRegion,
    TopFiveStoresInRegion,
    Custom
}

export class Comparator {
    [immerable] = true;

    public readonly type: ComparatorType;
    public readonly name: string;
    private stores: Store[];

    constructor(type: ComparatorType, name: string, stores: Store[]) {
        this.type = type;
        this.name = name;
        this.stores = stores;
    }

    getStores() {
        return [...this.stores];
    }

    getSalesScore() {
        return _.mean(this.stores.map(store => store.salesScore));
    }

    getDriversScore() {
        return _.mean(this.stores.map(store => store.driversScore));
    }

    getProfitScore() {
        return _.mean(this.stores.map(store => store.profitScore));
    }

    getAreaHealthScore() {
        return _.mean(this.stores.map(store => store.areaHealthScore));
    }

    getCompetitionScore() {
        return _.mean(this.stores.map(store => store.competitionScore));
    }

    getCatchmentScore() {
        return _.mean(this.stores.map(store => store.catchmentScore));
    }

    getTotalScore() {
        return _.mean(this.stores.map(store => store.getTotalScore()));
    }

    addStore(store: Store) {
        if (!this.stores.some(s => s.id === store.id)) {
            this.stores.push(store);
        }
    }

    removeStore(store: Store) {
        this.stores = this.stores.filter(s => s.id !== store.id);
    }
}
