import types from "./types";

const getGrossRevenueYTDRequest = () => ({
    type: types.GET_GROSS_REVENUE_YTD_REQUEST
});

const getGrossRevenueYTDSuccess = (value, percentageDifference) => ({
    type: types.GET_GROSS_REVENUE_YTD_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getGrossRevenueYTDFailure = () => ({
    type: types.GET_GROSS_REVENUE_YTD_FAILURE
});

const getContributionYTDRequest = () => ({
    type: types.GET_CONTRIBUTION_YTD_REQUEST
});

const getContributionYTDSuccess = (value, percentageDifference) => ({
    type: types.GET_CONTRIBUTION_YTD_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getContributionYTDFailure = () => ({
    type: types.GET_CONTRIBUTION_YTD_FAILURE
});

const getForecastSalesYTGRequest = () => ({
    type: types.GET_FORECAST_SALES_YTG_REQUEST
});

const getForecastSalesYTGSuccess = (value, percentageDifference) => ({
    type: types.GET_FORECAST_SALES_YTG_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getForecastSalesYTGFailure = () => ({
    type: types.GET_FORECAST_SALES_YTG_FAILURE
});

const getYTDSalesPlusYTGSalesVsBudgetedSalesRequest = () => ({
    type: types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_REQUEST
});

const getYTDSalesPlusYTGSalesVsBudgetedSalesSuccess = (value, percentageDifference) => ({
    type: types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getYTDSalesPlusYTGSalesVsBudgetedSalesFailure = () => ({
    type: types.GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_FAILURE
});

const actions = {
    getGrossRevenueYTDRequest,
    getGrossRevenueYTDSuccess,
    getGrossRevenueYTDFailure,
    getContributionYTDRequest,
    getContributionYTDSuccess,
    getContributionYTDFailure,
    getForecastSalesYTGRequest,
    getForecastSalesYTGSuccess,
    getForecastSalesYTGFailure,
    getYTDSalesPlusYTGSalesVsBudgetedSalesRequest,
    getYTDSalesPlusYTGSalesVsBudgetedSalesSuccess,
    getYTDSalesPlusYTGSalesVsBudgetedSalesFailure
};

export default actions;
