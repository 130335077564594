import types from "./types";

const getRevenueLastYearRequest = () => ({
    type: types.GET_REVENUE_LAST_YEAR_REQUEST
});

const getRevenueLastYearSuccess = (placeholder) => ({
    type: types.GET_REVENUE_LAST_YEAR_SUCCESS,
    payload: {
        placeholder
    }
});

const getRevenueLastYearFailure = () => ({
    type: types.GET_REVENUE_LAST_YEAR_FAILURE
});

const getUnitSalesLastYearRequest = () => ({
    type: types.GET_UNIT_SALES_LAST_YEAR_REQUEST
});

const getUnitSalesLastYearSuccess = (placeholder) => ({
    type: types.GET_UNIT_SALES_LAST_YEAR_SUCCESS,
    payload: {
        placeholder
    }
});

const getUnitSalesLastYearFailure = () => ({
    type: types.GET_UNIT_SALES_LAST_YEAR_FAILURE
});

const getMedianWeeklyRevenueRequest = () => ({
    type: types.GET_MEDIAN_WEEKLY_REVENUE_REQUEST
});

const getMedianWeeklyRevenueSuccess = (placeholder) => ({
    type: types.GET_MEDIAN_WEEKLY_REVENUE_SUCCESS,
    payload: {
        placeholder
    }
});

const getMedianWeeklyRevenueFailure = () => ({
    type: types.GET_MEDIAN_WEEKLY_REVENUE_FAILURE
});

const getMedianWeeklyUnitSalesRequest = () => ({
    type: types.GET_MEDIAN_WEEKLY_UNIT_SALES_REQUEST
});

const getMedianWeeklyUnitSalesSuccess = (placeholder) => ({
    type: types.GET_MEDIAN_WEEKLY_UNIT_SALES_SUCCESS,
    payload: {
        placeholder
    }
});

const getMedianWeeklyUnitSalesFailure = () => ({
    type: types.GET_MEDIAN_WEEKLY_UNIT_SALES_FAILURE
});

const getRevenueUnitSalesOverTimeRequest = () => ({
    type: types.GET_REVENUE_UNIT_SALES_OVERTIME_REQUEST
});

const getRevenueUnitSalesOverTimeSuccess = (placeholder) => ({
    type: types.GET_REVENUE_UNIT_SALES_OVERTIME_SUCCESS,
    payload: {
        placeholder
    }
});

const getRevenueUnitSalesOverTimeFailure = () => ({
    type: types.GET_REVENUE_UNIT_SALES_OVERTIME_FAILURE
});

const getRevenueTrendRequest = () => ({
    type: types.GET_REVENUE_TREND_REQUEST
});

const getRevenueTrendSuccess = (status, value) => ({
    type: types.GET_REVENUE_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getRevenueTrendFailure = () => ({
    type: types.GET_REVENUE_TREND_FAILURE
});

const getUnitSalesTrendRequest = () => ({
    type: types.GET_UNIT_SALES_TREND_REQUEST
});

const getUnitSalesTrendSuccess = (status, value) => ({
    type: types.GET_UNIT_SALES_TREND_SUCCESS,
    payload: {
        status,
        value
    }
});

const getUnitSalesTrendFailure = () => ({
    type: types.GET_UNIT_SALES_TREND_FAILURE
});

const actions = {
    getRevenueLastYearRequest,
    getRevenueLastYearSuccess,
    getRevenueLastYearFailure,
    getUnitSalesLastYearRequest,
    getUnitSalesLastYearSuccess,
    getUnitSalesLastYearFailure,
    getMedianWeeklyRevenueRequest,
    getMedianWeeklyRevenueSuccess,
    getMedianWeeklyRevenueFailure,
    getMedianWeeklyUnitSalesRequest,
    getMedianWeeklyUnitSalesSuccess,
    getMedianWeeklyUnitSalesFailure,
    getRevenueUnitSalesOverTimeRequest,
    getRevenueUnitSalesOverTimeSuccess,
    getRevenueUnitSalesOverTimeFailure,
    getRevenueTrendRequest,
    getRevenueTrendSuccess,
    getRevenueTrendFailure,
    getUnitSalesTrendRequest,
    getUnitSalesTrendSuccess,
    getUnitSalesTrendFailure
};

export default actions;
