import { combineReducers } from "redux";

import types from "./types";

const totalSalesGrowthByRegionForecastEmpty = {
    loading: false,
    error: false,
    regionsGrowthForecast: []
};

const totalSalesGrowthByRegionForecast = (state = totalSalesGrowthByRegionForecastEmpty, action) => {
    switch (action.type) {
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                regionsGrowthForecast: []
            };
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                regionsGrowthForecast: action.payload.regionsGrowthForecast
            };
        case types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                regionsGrowthForecast: []
            };
        default:
            return state;
    }
};

const salesByRegionGrowthForecastEmpty = {
    loading: false,
    error: false,
    storesGrowthForecast: []
};

const salesByRegionGrowthForecast = (state = salesByRegionGrowthForecastEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_BY_REGION_GROWTH_FORECAST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                storesGrowthForecast: []
            };
        case types.GET_SALES_BY_REGION_GROWTH_FORECAST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                storesGrowthForecast: action.payload.storesGrowthForecast
            };
        case types.GET_SALES_BY_REGION_GROWTH_FORECAST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                storesGrowthForecast: []
            };
        default:
            return state;
    }
};

const forecastDependencyOnSingularRegionOrStoreEmpty = {
    loading: false,
    error: false,
    id: "forecast-dependency-on-singular-region-or-store",
    label: "Forecast dependency on singular region or store",
    status: "info",
    value: ""
};

const forecastDependencyOnSingularRegionOrStore = (state = forecastDependencyOnSingularRegionOrStoreEmpty, action) => {
    switch (action.type) {
        case types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                label: "Forecast dependency on singular region or store",
                status: "info",
                value: ""
            };
        case types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                label: action.payload.label,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                label: "Forecast dependency on singular region or store",
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const selectedRegion = (state = "", action) => {
    switch (action.type) {
        case types.SET_SELECTED_REGION:
            return action.payload.selectedRegion;
        default:
            return state;
    }
};

const reducer = combineReducers({
    totalSalesGrowthByRegionForecast,
    salesByRegionGrowthForecast,
    forecastDependencyOnSingularRegionOrStore,
    selectedRegion
});

export default reducer;
