import React from "react";
import PropTypes from "prop-types";

import ChartBase from "./ChartBase";
import numberFormatter from "utils/numberFormatter";
import { useTheme } from "@material-ui/core/styles";

const Bullet = (props) => {
    const { loading, error, actuals, forecasts, budget, 
            actualsTitle, forecastTitle, title, shortfallTitle, budgetErrorBarTitleExcess, 
            budgetErrorBarTitleShortfall, excessErrorBarTitle, shortfallErrorBarTitle,
            noData } = props;

    const theme = useTheme();

    let options = {
        chart: {
            height: 270
        },
        series: [{
            data: [],
            type: "bar",
            name: ""
        }],
        xAxis: {
            title: {
                text: ""
            }
        },
        yAxis: {
            title: {
                text: ""
            }
        }
    };

    if (!noData) {

        const budgetBarTitle = "BudgetBar";
        const excessBarTitle = "ExcessBar";

        const Excess = actuals + forecasts - budget;
        const pxSpacer = 12;
        const pxBorderWidth = 2;
        let Spacer = (actuals + forecasts - Math.min(Excess, 0))/50;
        
        const chartData = function (Chart) {  
            const Spacer = Chart.yAxis[0].toValue(pxSpacer,true);
        
            Chart.series[0].setData([Spacer + (Excess < 0 ? actuals + Spacer + forecasts : budget)], false);
            Chart.series[1].setData((Excess < 0 ? [[Spacer,Spacer + actuals + Spacer + forecasts]]: [[Spacer, Spacer + budget]]), false);
            Chart.series[2].setData([Spacer + Math.abs(Excess)], false);
            Chart.series[3].setData((Excess < 0 ? [[Spacer + actuals + Spacer + forecasts + Spacer, Spacer + actuals + Spacer + forecasts + Spacer - Excess]] : [[budget + Spacer * 2, Excess + budget + Spacer*2]]), false);
            Chart.series[4].setData([Spacer], false);
            Chart.series[6].setData([Spacer], false);
            Chart.series[8].setData((Excess < 0 ? [Spacer] : [0]), false);
            Chart.series[9].setData((Excess < 0 ? [-Excess] : [0]), false);
        };
        
        let calledByRedraw = false;

        options = {
            title: {
                text: title
            },
            chart: {
                type: "bar",
                spacingBottom:-100,
                spacingRight: -30,
                spacingLeft: 0,
                height: 270,
                events: {
                    load: function () {
                        Spacer = this.yAxis[0].toValue(pxSpacer,true);
                        chartData(this);
                        this.redraw();
                },
                redraw: function () {                
                        if (calledByRedraw === false) {
                            calledByRedraw = true;
                            chartData(this);
                            this.redraw();
                        }
                    calledByRedraw = false;
                }
            }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    borderRadius: 5, //TODO remove post 11 upgrade
                    //TODO Options for post 11 upgrade:
                    // borderRadius: {
                    //     radius: 5,
                    //     scope: "point",
                    //     where: "all"
                    // },
                    pointWidth: 180,
                    // groupPadding: 0
                },
                series: {
                    stacking: "normal",
                    stickyTracking: false,
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            enabled: true,
                            opacity: 1
                        }
                    },
                    borderWidth: pxBorderWidth,
                    color: theme.palette.background.paper,
                    dataLabels: {
                        enabled: true,
                        style: {textOutline: "none", fontSize: "16px", fontFamily: "Open Sans"},
                        formatter: function() {
                            const series = this.series;
                            const seriesName = series.name;
                            
                            if (this.series.userOptions.stack === "bottom" && (seriesName === actualsTitle || seriesName === forecastTitle)) {
                                return `${numberFormatter.toGBP(this.y, 1)}<br>${this.series.name}`;
                            } else if (this.series.userOptions.stack === "bottom" && seriesName === "Under") {
                                return `${numberFormatter.toGBP("-"+this.y, 1)}<br>${this.series.name}`;
                            }

                            if (this.series.userOptions.stack === "top" && this.series.userOptions.type === "bar") {
                                let labelText = "";
                                let labelFigure = 0;
                                if (this.series.name === budgetBarTitle) {
                                    labelText = Excess < 0 ? budgetErrorBarTitleShortfall : budgetErrorBarTitleExcess;
                                    labelFigure = Excess < 0 ? actuals + forecasts : budget;
                                }
                                else if (this.series.name === excessBarTitle) {
                                    labelText = Excess < 0 ? shortfallErrorBarTitle : excessErrorBarTitle;
                                    labelFigure = Math.abs(Excess);
                                }
                                
                                return `<span style="font-size:14px; font-weight: normal;font-family:Open Sans">${labelText} ${numberFormatter.toGBP(labelFigure, 1)}</span>`;
                            } 
                            else return null;
                        }
                    }
                }
            },
            tooltip:{
                enabled: true,
                useHTML: true,
                headerFormat: `<table>`,
                footerFormat: "</table>",
                pointFormatter: function () {
                    const series = this.series;
                    const seriesName = series.name;
                    const seriesColor = series.color;

                    if (this.series.userOptions.stack === "bottom" && (seriesName === actualsTitle || seriesName === forecastTitle)) {
                        const shortSeriesName = seriesName === "Historic revenue" || seriesName === "Historic contribution" ? "Historic" : "Forecast";
                        const timePeriod = (seriesName === actualsTitle) ? "year-to-date" : "year-to-go";
                        const y = this.y || 0;
                        const revenueOrContribution = seriesName.includes("revenue") ? "revenue" : "contribution";
                        const label = seriesName === "Historic revenue" ? "Revenue" :
                                           seriesName === "Forecast revenue" ? "Forecast revenue" :
                                           seriesName === "Historic contribution" ? "Contribution" :
                                           seriesName === "Forecast contribution" ? "Forecast contribution" : "";

                        return `<tr><td style="padding:0"><b>${shortSeriesName} ${timePeriod} ${revenueOrContribution}</b> </td></tr><tr><td>${label}</td>
                        <td style="padding:0;text-align: right;color:${seriesColor}"><b> ${numberFormatter.toGBP(y, 1)}</b></td></tr>
                        <tr><td>(%) of ${revenueOrContribution} budget</td><td style="padding:0;text-align: right"><b> ${numberFormatter.toPercentage(y / budget, false)}</b></td></tr>`;
                    } else if (this.series.userOptions.stack === "bottom" && (seriesName === "Under")) {
                        return `<tr><td style=";padding:0"><b>${seriesName} budget</b> </td></tr><tr><td>Revenue</td>
                            <td style="padding:0;text-align: right;color:red"><b> ${numberFormatter.toGBP(Excess, 1)}</b></td></tr>
                            <tr><td>% of revenue budget</td><td style="padding:0;text-align: right"><b> ${numberFormatter.toPercentage(Excess / budget, false)}</b></td></tr>`;
                    }
                    return ``;
                }
            },
            xAxis: {
                visible: false,
                endOnTick: false,
                min: 0.25
            },
            yAxis: {
                visible: false,
                reversedStacks: false,
                endOnTick: false
            },
            series: [{
                type: "bar",
                opacity: 1,
                color: "transparent",
                includeInDataExport: false,
                name: budgetBarTitle,
                stack: "top",
                maxPointWidth: 0.0001,
                borderWidth: 0,
                enableMouseTracking: false,
                dataLabels: {
                    enabled: true,
                    color: theme.palette.common.white,
                    y: -20
                },
                data: [Spacer + budget/2]
            }, {
                type: "errorbar",
                stack: "top",
                name: "",
                color: theme.palette.common.white,
                whiskerLength: 20,
                data: [[Spacer,budget]],
                enableMouseTracking: false
            }, {
                type: "bar",
                opacity: 1,
                color: "transparent",
                maxPointWidth: 0.0001,
                borderWidth: 0,
                name: excessBarTitle,
                dataLabels: {
                    enabled: true,
                    color: (Excess < 0 ? theme.palette.error.main : theme.palette.success.main),
                    y: -20
                },
                includeInDataExport: false,
                stack: "top",
                data: [Spacer + budget + Spacer + Excess/2],
                enableMouseTracking: false
            } 
            , {
                type: "errorbar",
                stack: "top",
                dashStyle: "ShortDash",
                whiskerDashStyle: "Solid",
                pointPlacement: "between",
                whiskerLength: 20,
                name: "",
                color: theme.palette.common.white,
                data: [[budget + Spacer, Excess + budget + Spacer*2]],
                enableMouseTracking: false
            }
            ,{
                type: "bar",
                stack: "bottom",
                name: "",
                borderWidth: 0,
                color: "transparent",
                maxPointWidth: 1,
                data: [Spacer]
            },{
                type: "bar",
                stack: "bottom",
                color: theme.palette.success.main,
                dataLabels: {
                    color: theme.palette.background.paper,
                    align: "left",
                    padding: 30,
                    y: 40
                },
                borderColor: theme.palette.success.main,
                name: actualsTitle,
                id: actualsTitle,
                data: [actuals]
            },{
                type: "bar",
                stack: "bottom",
                name: "",
                borderWidth: 0,
                color: "transparent",
                maxPointWidth: 1,
                data: [Spacer]
            }, {
                type: "bar",
                name: forecastTitle,
                color: theme.palette.warning.main,
                dataLabels: {
                    color: theme.palette.background.paper,
                    align: "left",
                    padding: 30,
                    y: 40,
                    x: -20
                },
                borderColor: theme.palette.warning.main,
                stack: "bottom",
                data: [forecasts]
            },{
                type: "bar",
                stack: "bottom",
                name: "",
                borderWidth: 0,
                color: "transparent",
                maxPointWidth: 1,
                data: (Excess < 0 ? [Spacer] : [0])
            }, {
                type: "bar",
                name: shortfallTitle,
                color: "transparent",
                dataLabels: {
                    color: (Excess < 0) ? theme.palette.error.main : "transparent",
                    align: "left",
                    padding: 30,
                    y: 40
                },
                enableMouseTracking: (Excess < 0),
                borderColor: (Excess < 0) ? theme.palette.error.main : "transparent",
                stack: "bottom",
                data: (Excess < 0 ? [-Excess] : [0])
            }]
        };
    }

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="bullet-chart" />
    );
};

Bullet.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    actuals: PropTypes.number.isRequired, 
    forecasts: PropTypes.number.isRequired, 
    budget: PropTypes.number.isRequired, 
    actualsTitle: PropTypes.string.isRequired, 
    forecastTitle: PropTypes.string.isRequired,
    shortfallTitle: PropTypes.string.isRequired, 
    budgetErrorBarTitleExcess: PropTypes.string.isRequired, 
    budgetErrorBarTitleShortfall: PropTypes.string.isRequired, 
    excessErrorBarTitle: PropTypes.string.isRequired, 
    shortfallErrorBarTitle: PropTypes.string.isRequired,
    noData: PropTypes.bool,
    title: PropTypes.string
};

Bullet.defaultProps = {
    loading: false,
    error: false, 
    options: {},
    shortfallTitle: "Under", 
    budgetErrorBarTitleExcess: "Budget",
    budgetErrorBarTitleShortfall: "Budget",
    excessErrorBarTitle: "Excess of", 
    shortfallErrorBarTitle: "Under by",
    noData: false
};

export default Bullet;
