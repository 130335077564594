import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import KPIFact from "components/visuals/KPIFact";

import numberFormatter from "utils/numberFormatter";

import {selectors } from "modules/customer/insights/range/sales/historicSales";

const MedianWeeklyRevenue = () => {
    
    const medianWeeklyRevenue = useSelector((state) =>selectors.medianWeeklyRevenue(state));
    const { loading, error, /*placeholder*/ } = medianWeeklyRevenue;
    const label = "Median weekly revenue KPI Fact placeholder";
    const valueFormatted = numberFormatter.toGBP("2000", 1);

    return (
        <Box width="100%" height="100%" data-cy="median-weekly-revenue">
            <KPIFact loading={loading} error={error} label={label} value={valueFormatted} />
        </Box>
    );
};

export default MedianWeeklyRevenue;

