import React from "react";

import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { ChapterProps } from "./Chapter";
import TabPanel from "./TabPanel";

import { useAppDispatch, useAppSelector } from "store";
import {
    LocationChapter,
    selectCurrentChapter,
    setCurrentChapter
} from "modules/customer/tools/location/locationSlice";

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: "none",
        minWidth: theme.spacing(12)
    }
}))(Tab);

const useStyles = makeStyles(() => ({
    scrollButtons: {
        "&.Mui-disabled": {
            opacity: 0.3
        }
    }
}));

interface PageProps {
    children: React.ReactElement<ChapterProps>[]
}

const Page: React.FC<PageProps> = (props) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const currentChapter = useAppSelector(selectCurrentChapter);

    const handleTabChange = (event: object, value: LocationChapter) => {
        dispatch(setCurrentChapter(value));
    };

    return (
        //@ts-ignore
        <Box data-cy="location-insight" bgcolor={theme.palette.background.insight}>
            <Tabs
                value={currentChapter}
                onChange={handleTabChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="on"
                classes={{ scrollButtons: classes.scrollButtons }}
            >
                {props.children.map((chapter) =>
                    <StyledTab
                        key={chapter.props.number}
                        value={chapter.props.number}
                        label={
                            <Typography variant="subtitle1" component="div">
                                {chapter.props.title}
                            </Typography>
                        }
                    />
                )}
            </Tabs>
            {props.children.map((chapter) =>
                <TabPanel key={chapter.props.number} hidden={chapter.props.number !== currentChapter}>
                    {chapter}
                </TabPanel>
            )}
        </Box>
    );
};

export default Page;
