import React from "react";

import { Box } from "@material-ui/core";

import KPIAlignment from "components/visuals/KPIAlignment";

import { selectIsLoading, selectHasErrors, selectTarget } from "modules/customer/tools/location/locationSlice";
import { selectSpendAlignmentScore } from "modules/customer/tools/location/spendNew/spendSlice";
import { useAppSelector } from "store";

const SpendAlignmentScore: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const spendAlignmentScore = useAppSelector(selectSpendAlignmentScore);
    const target = useAppSelector(selectTarget);

    const disabled = !(target?.useSpend);
    const label = "Spend alignment score";

    return (
        <Box width="100%" height="100%" data-cy="spend-alignment-score">
            <KPIAlignment
                isLoading={isLoading}
                hasErrors={hasErrors}
                label={label}
                score={spendAlignmentScore}
                disabled={disabled}
            />
        </Box>
    );
};

export default SpendAlignmentScore;
