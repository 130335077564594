import React from "react";
import { PropTypes } from "prop-types";
import { Box } from "@material-ui/core";
import ChartStockBase from "components/visuals/ChartStockBase";
import Highcharts from "highcharts";
import _ from "lodash";
import useColourPalette from "components/visuals/useColourPalette";

const StackedAreaGraph = (props) => {
    const {loading, error, options: customOptions} = props;
    const colourPalette = useColourPalette();
    const staticOptions = {
        chart: {
            type: 'areaspline',
            defaultSeriesType: 'areaspline',
            
        },
      
        xAxis: {
            type: 'datetime',
            tickmarkPlacement: 'on',
            startOnTick: true,
            dateTimeLabelFormats: {
                millisecond: '%e %b %Y',
                second: '%e %b %Y',
                minute: '%e %b %Y',
                hour: '%e %b %Y',
                day: '%e %b %Y',
                week: '%b %Y',
                month: '%b %Y',
                year: '%Y'},
            labels: {
                    formatter: function() {
                      return Highcharts.dateFormat('%b',this.value);
                    }
                  }
        },
        yAxis: {
            min:0,
            max:105,
            tickInterval: 10,
            labels: {
                format: '{value}%'
              },
            gridLineColor: 'transparent'
            
        },
        
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                pointPlacement: 'on',
                stacking: 'percent',
                fillOpacity: 0.5,
                lineWidth: 2,
            }
        },
                
        legend:{
            enabled: true,
            reversed: true
        }
    };
const options = _.merge({}, staticOptions, customOptions);
    return (
        <Box>
            <ChartStockBase
             loading={loading} error={error} options={options} dataCy="stacked-area-chart"
            colourPalette={colourPalette.categorical}
            />
           
        </Box>
    );
};

StackedAreaGraph.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

StackedAreaGraph.defaultProps = {
    loading: false,
    error: false
};

export default StackedAreaGraph;
