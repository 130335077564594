import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";

export class LocalAreaHotspot {
    public readonly localArea: string;
    public readonly outputAreaCode: string;
    public readonly latitude: number;
    public readonly longitude: number;

    constructor(
        localArea: string,
        outputAreaCode: string,
        latitude: number,
        longitude: number,
    ) {
        this.localArea = localArea;
        this.outputAreaCode = outputAreaCode;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export const loadLocalAreaHotspot = (pinnedLocation?: PinnedLocation): AppThunk<Promise<LocalAreaHotspot | undefined>> => async (dispatch) => {
    try {
        if (!pinnedLocation) {
            return undefined;
        }
        const selectedOutputAreaId = pinnedLocation.retailCentre.code.slice(6, undefined);
        const isInRetailCentre = pinnedLocation.retailCentre.code[0] !== "N"; //Should come from static file

        const filters = isInRetailCentre
            ? [{
                member: "Mapping_RC_OA.RetailCentreID",
                operator: "equals",
                values: [String(pinnedLocation.retailCentre.code)]
            }]
            : [{
                member: "LocalAreaHotspot.MainOAID",
                operator: "equals",
                values: [String(selectedOutputAreaId)]
            }];

        const query = {
            dimensions: [
                "LocalAreaHotspot.MainOAID",
                "LocalAreaHotspot.OAID",
                "LocalAreaHotspot.HotSpotLat",
                "LocalAreaHotspot.HotSpotLong",
            ],
            filters,
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length !== 0) {
            return new LocalAreaHotspot(
                rawData[0]["LocalAreaHotspot.MainOAID"],
                rawData[0]["LocalAreaHotspot.OAID"],
                rawData[0]["LocalAreaHotspot.HotSpotLat"],
                rawData[0]["LocalAreaHotspot.HotSpotLong"],
            );
        }

        return undefined;
    } catch (error) {
        dispatch(logError("Error loading LocalAreaHotspot.", error));
        throw error;
    }
};
