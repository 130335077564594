import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";
import Page from "components/landing/Page";
import PageSection from "components/landing/PageSection";

import { login } from "modules/auth/authSlice";

const StyledOuterCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        maxWidth: theme.spacing(43)
    }
}))(Card);

const StyledOuterCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(5),
        "&:last-child": {
            padding: theme.spacing(5)
        }
    }
}))(CardContent);

const StyledInnerCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderWidth: "1px",
        borderStyle: "dashed"
    }
}))(Card);

const StyledInnerCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        "&:last-child": {
            padding: theme.spacing(3)
        }
    }
}))(CardContent);

const useStyles = makeStyles(() => ({
    backgroundImage: {
        backgroundImage: "url(/content/landing/login/dashes.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
    }
}));

const Login = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const intervalInSeconds = searchParams.get("interval") ?? 4;

    React.useEffect(() => {
        const oneSecondInMilliseconds = 1000;
        const timeout = intervalInSeconds * oneSecondInMilliseconds;
        const timer = setTimeout(function () {
            dispatch(login());
        }, timeout);
        return () => clearTimeout(timer);
    }, [dispatch, intervalInSeconds]);

    return (
        <Page dataCy="pages-landing-login">
            <PageSection maxWidth="sm" bgcolor={theme.palette.common.white}>
                <Typography variant="h2" component="div" align="center" gutterBottom>
                    Login to Dash
                </Typography>
                <br />
                <br />
                <br />
                <Box align="center" className={classes.backgroundImage}>
                    <StyledOuterCard elevation={0}>
                        <StyledOuterCardContent>
                            <Typography variant="h5" component="div" align="center" gutterBottom>
                                KPMG gateway login
                            </Typography>
                            <Typography variant="subtitle1" component="div" align="center" gutterBottom>
                                Our secure login gateway
                                <br />
                                keeps your account data safe
                            </Typography>
                            <br />
                            <StyledInnerCard elevation={0}>
                                <StyledInnerCardContent>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="h5" component="div" align="left">
                                            Connecting to
                                            <br />
                                            KPMG Gateway
                                        </Typography>
                                        <Spacer />
                                        <CircularProgress color="inherit" />
                                    </Box>
                                </StyledInnerCardContent>
                            </StyledInnerCard>
                        </StyledOuterCardContent>
                    </StyledOuterCard>
                </Box>
            </PageSection>
        </Page>
    );
};

export default Login;
