const truncate = (value, args) => {
    const number = Number(value);
    const absoluteValue = Math.abs(number);

    if (absoluteValue >= 1.0e+9) {
        return (number / 1.0e+9).toLocaleString(...args) + "B";
    } else if (absoluteValue >= 1.0e+6) {
        return (number / 1.0e+6).toLocaleString(...args) + "M";
    } else if (absoluteValue >= 1.0e+3) {
        return (number / 1.0e+3).toLocaleString(...args) + "K";
    } else {
        return number.toLocaleString(...args);
    }
};

const toGBP = (value, decimalPlaces = 2, shouldTruncate = true) => {
    const args = [
        "lookup",
        {
            style: "currency",
            currency: "GBP",
            maximumFractionDigits: decimalPlaces
        }
    ];
    return shouldTruncate ? truncate(value, args) : Number(value).toLocaleString(...args);
};

const toPercentage = (value, alreadyPercent = true, decimalPlaces = 1) => {
    let number = Number(value);
    if (alreadyPercent) {number = number / 100;}
    const options = {
        style: "percent",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    };
    return number.toLocaleString("lookup", options);
};

const toDecimalPlaces = (value, decimalPlaces = 2, shouldTruncate = true) => {
    const args = [
        "lookup",
        {
            style: "decimal",
            maximumFractionDigits: decimalPlaces,
            minimumFractionDigits: decimalPlaces
        }
    ];
    return shouldTruncate ? truncate(value, args) : Number(value).toLocaleString(...args);
};

const toOrdinalNumber = (number) => {
    const parsedNumber = parseFloat(number);
    if (!Number.isInteger(parsedNumber)) {
        return number;
    }

    const ordinalRules = new Intl.PluralRules("en", { type: "ordinal" });
    const suffixes = {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th"
    };

    const category = ordinalRules.select(parsedNumber);
    return `${toDecimalPlaces(parsedNumber, 0, false)}${suffixes[category]}`;
};

const toTruncatedInteger = (value, maximumFractionDigits = 2, options = {}) => {
    const integerToFormat = parseInt(value);

    const mergedOptions = {
        ...{
            maximumFractionDigits: maximumFractionDigits
        },
        ...options
    };

    const args = [
        "lookup",
        mergedOptions
    ];

    return truncate(integerToFormat, args);
};

const displaySign = (value, includeZero = true) => {
    return value > 0
        ? '+' + value
        : value === 0 && includeZero
            ? '=' + value
            : String(value);
};

const numberFormatter = {
    truncate,
    toGBP,
    toDecimalPlaces,
    toPercentage,
    toOrdinalNumber,
    toTruncatedInteger,
    displaySign
};

export default numberFormatter;
