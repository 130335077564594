import React from "react";
import { Grid } from "@material-ui/core";

import StoreRevenueVsPY from "./StoreRevenueVsPY";
import StoreForecastRevenueVsPY from "./StoreForecastRevenueVsPY";
import SalesHistory from "./SalesHistory";
import HistoricRevenueGrowth from "./HistoricRevenueGrowth";
import ForecastRevenueGrowth from "./ForecastRevenueGrowth";
import RevenueTypesFilter from "./RevenueTypesFilter";
import AggregateRevenueTypesSwitch from "./AggregateRevenueTypesSwitch";
import SalesHistoryTitle from "./SalesHistoryTitle";

const Revenue = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RevenueTypesFilter />
            </Grid>
            <Grid item xs={12} md={6}>
                <StoreRevenueVsPY />
            </Grid>
            <Grid item xs={12} md={6}>
                <StoreForecastRevenueVsPY />
            </Grid>
            <Grid item xs={12}>
                <SalesHistoryTitle />
            </Grid>
            <Grid item xs={12} style={{marginLeft: 5}}>
                <AggregateRevenueTypesSwitch />
            </Grid>
            <Grid item xs={12}>
                <SalesHistory />
            </Grid>
            <Grid item xs={12}>
                <HistoricRevenueGrowth />
            </Grid>
            <Grid item xs={12}>
                <ForecastRevenueGrowth />
            </Grid>
        </Grid>
    );
};

export default Revenue;
