import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Explore the demography of your selected location's catchment area. Dash has determined where you will
            attract customers and summarised what they look like. Understand the size of the customer pool, how this
            breaks down across the different types of customers, and whether or not they align with your target
            demography.
        </>
    );
};

export default Subtitle;
