import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

export interface Account {
    id: string,
    companyName: string,
    companyDisplayName: string,
    companyRegistrationNumber: string,
    termsOfUseAcceptedAt?: DateTime,
    initialTermInYears: number,
    status: AccountStatus
}

export enum AccountStatus {
    NoSubscription = "NoSubscription",
    Demo = "Demo",
    Active = "Active"
}

interface AccountsFilter {
    companyNameOrCompanyDisplayName: string
}

interface AddAccountConfirmationVisibility {
    isVisible: boolean
}

interface AccountForm {
    id: string,
    companyName: string,
    companyRegistrationNumber: string,
    initialTermInYears: number,
    errors: AccountFormErrors
}

interface AccountFormErrors {
    companyName: string,
    companyRegistrationNumber: string,
    initialTermInYears: string
}

interface AddAccountVisibility {
    isVisible: boolean
}

interface DeleteAccountVisibility {
    isVisible: boolean,
    accountId: string
}

interface DeleteAccountConfirmationVisibility {
    isVisible: boolean,
    accountId: string
}

interface DeleteAccountConfirmationForm {
    companyName: string,
    errors: DeleteAccountConfirmationFormErrors
}

interface DeleteAccountConfirmationFormErrors {
    companyName: string
}

interface AccountsState {
    accounts: Account[],
    filter: AccountsFilter,
    account: AccountForm,
    addAccountConfirmationVisibility: AddAccountConfirmationVisibility,
    addAccountVisibility: AddAccountVisibility,
    deleteAccountVisibility: DeleteAccountVisibility,
    deleteAccountConfirmationVisibility: DeleteAccountConfirmationVisibility,
    deleteAccountConfirmation: DeleteAccountConfirmationForm
}

const initialState: AccountsState = {
    accounts: [],
    filter: {
        companyNameOrCompanyDisplayName: ""
    },
    account: {
        id: "",
        companyName: "",
        companyRegistrationNumber: "",
        initialTermInYears: 0,
        errors: {
            companyName: "",
            companyRegistrationNumber: "",
            initialTermInYears: ""
        }
    },
    addAccountConfirmationVisibility: {
        isVisible: false
    },
    addAccountVisibility: {
        isVisible: false
    },
    deleteAccountVisibility: {
        isVisible: false,
        accountId: ""
    },
    deleteAccountConfirmationVisibility: {
        isVisible: false,
        accountId: ""
    },
    deleteAccountConfirmation: {
        companyName: "",
        errors: {
            companyName: ""
        }
    }
};

const accountsSlice = createSlice({
    name: "admin/accounts/accounts",
    initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<Account[]>) => {
            state.accounts = action.payload;
        },
        clearAccounts: (state) => {
            state.accounts = initialState.accounts;
        },
        setFilter: (state, action: PayloadAction<AccountsFilter>) => {
            state.filter = action.payload;
        },
        clearFilter: (state) => {
            state.filter = initialState.filter;
        },
        setAccount: (state, action: PayloadAction<AccountForm>) => {
            state.account = action.payload;
        },
        clearAccount: (state) => {
            state.account = initialState.account;
        },
        showAddAccountConfirmation: (state) => {
            state.addAccountConfirmationVisibility.isVisible = true;
        },
        hideAddAccountConfirmation: (state) => {
            state.addAccountConfirmationVisibility = initialState.addAccountConfirmationVisibility;
        },
        showAddAccount: (state) => {
            state.addAccountVisibility.isVisible = true;
        },
        hideAddAccount: (state) => {
            state.addAccountVisibility = initialState.addAccountVisibility;
        },
        showDeleteAccount: (state, action: PayloadAction<string>) => {
            state.deleteAccountVisibility.isVisible = true;
            state.deleteAccountVisibility.accountId = action.payload;
        },
        hideDeleteAccount: (state) => {
            state.deleteAccountVisibility = initialState.deleteAccountVisibility;
        },
        showDeleteAccountConfirmation: (state, action: PayloadAction<string>) => {
            state.deleteAccountConfirmationVisibility.isVisible = true;
            state.deleteAccountConfirmationVisibility.accountId = action.payload;
        },
        hideDeleteAccountConfirmation: (state) => {
            state.deleteAccountConfirmationVisibility = initialState.deleteAccountConfirmationVisibility;
        },
        setDeleteAccountConfirmation: (state, action: PayloadAction<DeleteAccountConfirmationForm>) => {
            state.deleteAccountConfirmation = action.payload;
        },
        clearDeleteAccountConfirmation: (state) => {
            state.deleteAccountConfirmation = initialState.deleteAccountConfirmation;
        }
    }
});

export const {
    setFilter,
    setAccount,
    clearAccount,
    showAddAccountConfirmation,
    hideAddAccountConfirmation,
    showAddAccount,
    hideAddAccount,
    showDeleteAccount,
    hideDeleteAccount,
    showDeleteAccountConfirmation,
    hideDeleteAccountConfirmation,
    setDeleteAccountConfirmation,
    clearDeleteAccountConfirmation
} = accountsSlice.actions;

export const getAccounts = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/accounts"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const accountsRaw = response.data.accounts;
            const accounts = accountsRaw.map((accountRaw: any) => ({
                ...accountRaw,
                termsOfUseAcceptedAt: accountRaw.termsOfUseAcceptedAt
                    ? DateTime.fromISO(accountRaw.termsOfUseAcceptedAt, { zone: "utc" })
                    : undefined
            }));
            dispatch(accountsSlice.actions.setAccounts(accounts));
            break;
        }
        default: {
            dispatch(accountsSlice.actions.clearAccounts());
            break;
        }
    }
};

export const addAccount = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const account = selectAccount(state);
    const response = await dispatch(apiPost("/admin/accounts", account));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(accountsSlice.actions.hideAddAccount());
            dispatch(accountsSlice.actions.clearAccount());
            dispatch(notifySuccess("Account added."));
            dispatch(getAccounts());
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                ...initialState.account.errors,
                companyName: response.errorData?.errors?.companyName?.[0],
                companyRegistrationNumber: response.errorData?.errors?.companyRegistrationNumber?.[0],
                initialTermInYears: response.errorData?.errors?.initialTermInYears?.[0]
            };
            dispatch(accountsSlice.actions.setAccount({ ...account, errors }));
            break;
        }
        default:
            break;
    }
};

export const deleteAccount = (accountId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const deleteAccountConfirmation = selectDeleteAccountConfirmation(state);
    const response = await dispatch(apiDelete(`/admin/accounts/${accountId}`, deleteAccountConfirmation));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(accountsSlice.actions.hideDeleteAccount());
            dispatch(accountsSlice.actions.hideDeleteAccountConfirmation());
            dispatch(accountsSlice.actions.clearDeleteAccountConfirmation());
            dispatch(notifySuccess("Account deleted."));
            dispatch(getAccounts());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                ...initialState.deleteAccountConfirmation.errors,
                companyName: response.errorData?.errors?.companyName?.[0]
            };
            dispatch(accountsSlice.actions.setDeleteAccountConfirmation({ ...deleteAccountConfirmation, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const selectFilter = (state: RootState): AccountsFilter => {
    return state.admin.accounts.accounts.filter;
};

export const selectAccounts = createSelector(
    (state: RootState): Account[] => state.admin.accounts.accounts.accounts,
    selectFilter,
    (accounts, filter) => {
        const companyNameOrCompanyDisplayName = filter.companyNameOrCompanyDisplayName.toLowerCase();
        return accounts.filter(account =>
            account.companyName.toLowerCase().includes(companyNameOrCompanyDisplayName)
            || account.companyDisplayName.toLowerCase().includes(companyNameOrCompanyDisplayName));
    }
);

export const selectAddAccountConfirmationVisibility = (state: RootState) => {
    return state.admin.accounts.accounts.addAccountConfirmationVisibility;
};

export const selectAccount = (state: RootState) => {
    return state.admin.accounts.accounts.account;
};

export const selectAddAccountVisibility = (state: RootState) => {
    return state.admin.accounts.accounts.addAccountVisibility;
};

export const selectDeleteAccountVisibility = (state: RootState) => {
    return state.admin.accounts.accounts.deleteAccountVisibility;
};

export const selectDeleteAccountConfirmationVisibility = (state: RootState) => {
    return state.admin.accounts.accounts.deleteAccountConfirmationVisibility;
};

export const selectDeleteAccountConfirmation = (state: RootState) => {
    return state.admin.accounts.accounts.deleteAccountConfirmation;
};

export default accountsSlice;
