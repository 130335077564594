import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { Partner } from "modules/customer/tools/product/partner";
import { selectPartners } from "modules/customer/tools/product/productSlice";

interface FiltersVisibility {
    isVisible: boolean
}

export enum PartnerFilterStep {
    SelectPartner,
    // SelectPartnerGroup
}

export interface PartnersSearch {
    name: string
}

interface FiltersState {
    partnerFiltersVisibility: FiltersVisibility,
    activeStep: PartnerFilterStep,
    partnersSearch: PartnersSearch,
    candidatePartner?: Partner,
    candidatePartnerGroup?: string
}

const initialState: FiltersState = {
    partnerFiltersVisibility: {
        isVisible: false
    },
    activeStep: PartnerFilterStep.SelectPartner,
    partnersSearch: {
        name: ""
    },
    candidatePartner: undefined,
    candidatePartnerGroup: undefined
};

const partnerFiltersSlice = createSlice({
    name: "customer/tools/product/partnerFilters",
    initialState,
    reducers: {
        showPartnerFilters: (state) => {
            state.activeStep = initialState.activeStep;
            state.partnerFiltersVisibility.isVisible = true;
        },
        hidePartnerFilters: (state) => {
            state.partnerFiltersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<PartnerFilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setPartnersSearch: (state, action: PayloadAction<PartnersSearch>) => {
            state.partnersSearch = action.payload;
        },
        clearPartnersSearch: (state) => {
            state.partnersSearch = initialState.partnersSearch;
        },
        setCandidatePartner: (state, action: PayloadAction<Partner>) => {
            state.candidatePartner = action.payload;
        },
        clearCandidatePartner: (state) => {
            state.candidatePartner = initialState.candidatePartner;
        },
        setCandidatePartnerGroup: (state, action: PayloadAction<string>) => {
            state.candidatePartnerGroup = action.payload;
        },
        clearCandidatePartnerGroup: (state) => {
            state.candidatePartnerGroup = initialState.candidatePartnerGroup;
        }
    },
    extraReducers: (builder: any) => {

    }
});

export const {
    showPartnerFilters,
    hidePartnerFilters,
    setActiveStep,
    setPartnersSearch,
    setCandidatePartner,
    setCandidatePartnerGroup,
    clearActiveStep,
    clearCandidatePartner,
    clearCandidatePartnerGroup,
    clearPartnersSearch
} = partnerFiltersSlice.actions;

export const clearPartnerFilters = (): AppThunk => async (dispatch) => {
    dispatch(partnerFiltersSlice.actions.hidePartnerFilters());
    dispatch(partnerFiltersSlice.actions.clearActiveStep());
    dispatch(partnerFiltersSlice.actions.clearPartnersSearch());
    dispatch(partnerFiltersSlice.actions.clearCandidatePartner());
    dispatch(partnerFiltersSlice.actions.clearCandidatePartnerGroup());
};

export const selectPartnerFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.tools.product.partnerFilters.partnerFiltersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.activeStep;
};

export const selectPartnersSearch = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.partnersSearch;
};

export const selectCandidatePartner = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.candidatePartner;
};

export const selectCandidatePartnerGroup = (state: RootState) => {
    return state.customer.tools.product.partnerFilters.candidatePartnerGroup;
};

export const selectFilteredPartners = createSelector(
    (state: RootState) => selectPartners(state),
    selectPartnersSearch,
    // selectFilter,
    // selectSort,
    (partners, search/*, filter, sort*/) => {
        const searchString = search.name.toLowerCase();
        const filteredPartners = partners.filter(partner =>
            (!searchString || partner.name.toLowerCase().includes(searchString)));
        return filteredPartners;
    }
);


export default partnerFiltersSlice;
