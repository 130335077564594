import React from "react";

import { Box, Typography } from "@material-ui/core";

import {
    GenerateRevenuePredictionStep,
    selectActiveGenerateRevenuePredictionStep
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";

const LeftPanelTitle: React.FC = () => {
    const activeGenerateRevenuePredictionStep = useAppSelector(selectActiveGenerateRevenuePredictionStep);

    return (
        <>
            <Typography variant="h5" component="div">
                Revenue prediction
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction && (
                        "To generate a revenue prediction, first provide some details about the new store you plan to open."
                    )}
                    {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.InputStoreParameters && (
                        "If you know more details about the store, input them to improve prediction accuracy. Otherwise you can skip this step."
                    )}
                </Typography>
            </Box>
        </>
    );
};

export default LeftPanelTitle;
