import React from "react";
import { Grid } from "@material-ui/core";

import HistoricProdGrowthStoreVsComp from "./HistoricProdGrowthStoreVsComp";
import StoreVsComparatorProductOrProductCategoryGrowth from "./StoreVsComparatorProductOrProductCategoryGrowth";

const ProductCategoryGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HistoricProdGrowthStoreVsComp />
            </Grid>
            <Grid item xs={12}>
                <StoreVsComparatorProductOrProductCategoryGrowth />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryGrowth;
