import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";
import dateUtils from "utils/dateUtils";

import actions from "./actions";

const getSalesYTD = () => async (dispatch, getState) => {
    dispatch(actions.getSalesYTDRequest());
    try {
        const state = getState();
        const financialYearToDateEndDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(financialYearToDateEndDate, fyStart);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearToDateEndDate]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"];

        const label = "Revenue in the year-to-date";

        dispatch(actions.getSalesYTDSuccess(value, label));
    }
    catch (error) {
        dispatch(actions.getSalesYTDFailure());
        dispatch(logError("Error loading SalesYTD.", error));
    }
};

const getTotalSalesLatestFullMonth = () => async (dispatch, getState) => {
    dispatch(actions.getTotalSalesLatestFullMonthRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [latestFullMonthStartDate, latestFullMonthEndDate]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"];

        const currentMonth = dateUtils.monthName(latestFullMonthStartDate);
        const currentMonthDate = dateUtils.dateUTC(latestFullMonthStartDate);
        const currentYear = currentMonthDate.getFullYear();

        dispatch(actions.getTotalSalesLatestFullMonthSuccess(value, currentMonth, currentYear));
    }
    catch (error) {
        dispatch(actions.getTotalSalesLatestFullMonthFailure());
        dispatch(logError("Error loading TotalSalesLatestFullMonth.", error));
    }
};

const getAvgSalesLatestFullMonth = () => async (dispatch, getState) => {
    dispatch(actions.getAvgSalesLatestFullMonthRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [latestFullMonthStartDate, latestFullMonthEndDate]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const salesValue = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"];

        const days = ((latestFullMonthEndDate.getTime() - latestFullMonthStartDate.getTime()) / (1000 * 60 * 60 * 24));

        const value = salesValue / days;

        const currentMonth = dateUtils.monthName(latestFullMonthStartDate);
        const currentMonthDate = dateUtils.dateUTC(latestFullMonthStartDate);
        const currentYear = currentMonthDate.getFullYear();
        dispatch(actions.getAvgSalesLatestFullMonthSuccess(value, currentMonth, currentYear));
    }
    catch (error) {
        dispatch(actions.getAvgSalesLatestFullMonthFailure());
        dispatch(logError("Error loading AvgSalesLatestFullMonth.", error));
    }
};

const getHistoricShortTermCompanySalesTrend = () => async (dispatch, getState) => {
    dispatch(actions.getHistoricShortTermCompanySalesTrendRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const sixMonthsAgoStartDate = dateUtils.startTime(dateUtils.addMonths(currentDate, -6));

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [sixMonthsAgoStartDate, currentDate]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.Year", "D_Date.QuarterName", "D_Date.MonthNo", "D_Date.WeekNo", "D_Date.Date"]
        };

        const resultSet = await dispatch(cubeLoad(query));
        const values = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            year: item["D_Date.Year"],
            quarter: item["D_Date.QuarterName"],
            month: item["D_Date.MonthNo"],
            week: item["D_Date.WeekNo"],
            date: item["D_Date.Date"]
        }));

        //Extract distinct years
        const allMonths = values.map(function (el) { return el.month; });
        const distinctMonths = [...new Set(allMonths)];

        //Get sales for each distinct year
        let monthlySales = [];
        for (let i = 0; i < distinctMonths.length; i++) {
            let monthValue = distinctMonths[i];
            let monthSales = 0;
            for (let j = 0; j < values.length; j++) {
                if (values[j].month === monthValue) {
                    monthSales = monthSales + values[j].sales;
                }
            }
            monthlySales.push({ monthValue: monthValue, monthSales: monthSales });
        }

        //Latest period Sales
        const arrayLength = monthlySales.length;
        const maxIndex = arrayLength - 1;
        const latestPeriodSales = monthlySales[maxIndex].monthSales;

        const comparisonPeriodSales = monthlySales[0].monthSales;

        const numberOfMonths = maxIndex;

        let x = 0;
        //Check numerator & denominator:
        if (comparisonPeriodSales === 0 && latestPeriodSales > 0) {
            x = 0.6;
        } else {
            //CMGR calculation:
            const cmgrCalculation = ((latestPeriodSales / comparisonPeriodSales) ** (1 / numberOfMonths) - 1);
            x = cmgrCalculation * 100;
        }

        let ragStatus;
        let ragValue;
        //Rag indicator
        if (x > 0.5) {
            ragStatus = "success";
            ragValue = "Short term sales are trending upwards";
        } else if (x >= 0) {
            ragStatus = "warning";
            ragValue = "Short term sales trend is stagnant";
        } else {
            ragStatus = "error";
            ragValue = "Short term sales are trending downwards";
        }

        const status = ragStatus;
        const value = ragValue;

        dispatch(actions.getHistoricShortTermCompanySalesTrendSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getHistoricShortTermCompanySalesTrendFailure());
        dispatch(logError("Error loading HistoricShortTermCompanySalesTrend.", error));
    }
};

const getSalesHistory = () => async (dispatch, getState) => {
    dispatch(actions.getSalesHistoryRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const threeYearsAgoStartDate = dateUtils.threeYearsAgoStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [threeYearsAgoStartDate, currentDate]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.Year", "D_Date.WeekNo", "D_Date.Date"]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const values = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            year: item["D_Date.Year"],
            week: item["D_Date.WeekNo"],
            date: item["D_Date.Date"]
        }));

        dispatch(actions.getSalesHistorySuccess(values));
    }
    catch (error) {
        dispatch(actions.getSalesHistoryFailure());
        dispatch(logError("Error loading SalesHistory.", error));
    }
};

const operations = {
    getSalesYTD,
    getTotalSalesLatestFullMonth,
    getAvgSalesLatestFullMonth,
    getHistoricShortTermCompanySalesTrend,
    getSalesHistory
};

export default operations;
