import React from "react";

import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        "&:last-child": {
            padding: theme.spacing(0)
        }
    }
}))(CardContent);

const UnlockThePowerOfRetailAnalytics: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    React.useEffect(() => {
        const script = document.createElement("script");
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
        script.async = true;
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "25858916",
                    formId: "9d67114f-5abc-40cc-8c32-a43acc2c8afb",
                    target: "#hubspotForm"
                });
            }
        });

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        // @ts-ignore
        <PageSection color={theme.palette.common.white} bgcolor={theme.palette.primary.main}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <Typography variant={isMobile ? "h3" : "h1"} component="div" align={align}>
                        Unlock the power of retail analytics
                    </Typography>
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Harnessing over 3.5 billion data points and cutting-edge AI, Dash gives
                        retailers comprehensive insights across their portfolio, store locations, customer behaviour,
                        predictive forecasts, competition dynamics, and footfall patterns.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align} gutterBottom>
                        Optimise your retail strategy
                    </Typography>
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Utilise store performance, market benchmarking, customer demographics, footfall analysis, and
                        cannibalisation insights to make strategic retail decisions across your store portfolio.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align} gutterBottom>
                        Strategic lease management
                    </Typography>
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Strengthen your negotiating power for lease renewals. Utilise a blend of internal performance
                        metrics with external market insights to solidify terms and optimise profitability.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align} gutterBottom>
                        Strategic store openings
                    </Typography>
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Leverage location analytics to get ahead of the competition. Find new retail locations and
                        opportunities for growth with Dash's 90% accurate revenue predictions.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StyledCard elevation={0}>
                        <StyledCardContent>
                            <Typography variant="h4" component="div" align={align} style={{ fontWeight: "600" }}>
                                Book a demo with Dash today
                            </Typography>
                            <br />
                            <Box id="hubspotForm" />
                        </StyledCardContent>
                    </StyledCard>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default UnlockThePowerOfRetailAnalytics;
