import React from "react";

const LocalAreaCompetitionSubtitle: React.FC = () => {
    return (
        <>
            Understand your positioning in the local area compared to your competition. How far is your store from the
            local area hotspot? Are potential customers shopping at closer, more convenient competitor stores?
        </>
    );
};

export default LocalAreaCompetitionSubtitle;
