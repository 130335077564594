import React from "react";
import { Box } from "@material-ui/core";
import Highcharts from "highcharts";
import StockLine from "components/visuals/StockLine";
import { useTheme } from "@material-ui/core/styles";
import { dateFormat } from "highcharts";
import { useAppSelector } from "store";
import { selectSalesReferenceDate } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectSelectedStoreWeeklyRevenue } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";
import numberFormatter from "utils/numberFormatter";
import { min } from "mathjs";

const SalesHistory = () => {
    const theme = useTheme();
    const referenceDate = useAppSelector(selectSalesReferenceDate);
    const salesHistory = useAppSelector(selectSelectedStoreWeeklyRevenue);
    const { isLoading, hasErrors, data } = salesHistory;
    const seriesColours = ["#42E8E0", "#1C76FD"];

    const series: Highcharts.Series[] = [];
    
    data.forEach((weeklyRevenueSeries) => {
        const revenueValues = weeklyRevenueSeries.values;
        const actualsDataValues = revenueValues.map(revenue => [
            revenue.date.toUTC().toMillis(),
            revenue.forecastFlag ? null : revenue.sales
        ]);

        const forecastDataValues = revenueValues.map((revenue, index) => [
            revenue.date.toUTC().toMillis(),
            revenueValues[min(index + 1, revenueValues.length - 1)].forecastFlag ? revenue.sales : null
        ]);

        series.push({
            name: weeklyRevenueSeries.seriesName + " actuals",
            //@ts-ignore
            data: actualsDataValues,
            color: seriesColours[weeklyRevenueSeries.seriesID],
            showInNavigator: true,
            gapSize: 1,
            navigatorOptions: {
                color: seriesColours[weeklyRevenueSeries.seriesID]
            }
        });

        series.push({
            name: weeklyRevenueSeries.seriesName + " forecast",
            //@ts-ignore
            data: forecastDataValues,
            color: seriesColours[weeklyRevenueSeries.seriesID],
            dashStyle: "ShortDash",
            showInNavigator: true,
            gapSize: 1,
            navigatorOptions: {
                color: seriesColours[weeklyRevenueSeries.seriesID]
            }
        });
    });

    const xAxisMin = referenceDate.minus({ weeks: 52 }).toMillis();

    const globalOptions = {
        lang: {
            rangeSelectorZoom: "Zoom"
        }
    };
    const options = {
        yAxis: {
            title: {
                text: "Revenue"
            },
            labels: {
                //@ts-ignore
                formatter: function () {return numberFormatter.toGBP(this.value, 0);}
            }
        },
        series,

        xAxis: {
            labels: {
                useHTML: true,
                style: {
                    color: theme.palette.common.white,
                    fontSize: "12px"
                },
                units: [["month", [1]]],
                dateTimeLabelFormats: {
                    month: "%b"
                }
            },
            min: xAxisMin
        },

        navigator: {
            series: {
                dataLabels: {
                    color: theme.palette.common.white
                },
                type: 'line',
                gapSize: 1
            },
        },

        rangeSelector: {
            allButtonsEnabled: true,
            selected: null,
            buttons: [{
                type: "month",
                count: 1,
                text: "Month",
                dataGrouping: {
                    forced: false,
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 3,
                text: "Quarter",
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 12,
                text: "Year",
                preserveDataGrouping: true
            }, {
                type: "all",
                text: "All",
                title: "View all",
                preserveDataGrouping: true
            }]
        },

        plotOptions: {
            series: {
                tooltip: {
                    valueDecimals: ",.2f",
                    valuePrefix: "£"
                }
            }
        },

        legend: {
            enabled: true
        },

        tooltip: {
            useHTML: true,
            //@ts-ignore
            formatter: function () {
                //@ts-ignore
                const date = "Week from " + dateFormat("%A, %b %e, %Y", this.points[0].x);
                let tooltipString: string = `<b>${date}</b><br/>`;
                //@ts-ignore
                this.points.forEach((point, index) => {
                    //@ts-ignore
                    if (this.points.length === series.length) {
                        if (index % 2 === 0) {
                            const string = `${point.series.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${numberFormatter.toGBP(point.y)}</b><br/>`;
                            tooltipString += string;
                        }
                    } else {
                        const string = `${point.series.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${numberFormatter.toGBP(point.y)}</b><br/>`;
                        tooltipString += string;
                    }

                });

                return tooltipString;
            }
        },

    };

    return (
        <Box data-cy="sales-history">
            <StockLine loading={isLoading} error={hasErrors} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default SalesHistory;
