import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectHasErrors,
    selectIsLoading,
    selectSharedPopulation
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const SharedPopulation: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const sharedPopulation = useAppSelector(selectSharedPopulation);
    const valueFormatted = numberFormatter.toDecimalPlaces(sharedPopulation, 1);
    const label = "Shared population in the catchment area of the selected location";

    return (
        <Box width="100%" height="100%" data-cy="shared-population">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default SharedPopulation;
