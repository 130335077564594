import types from "./types";

const getForecastSalesWOWRequest = () => ({
    type: types.GET_FORECAST_SALES_WOW_REQUEST
});

const getForecastSalesWOWSuccess = (nextWeekValue, percentageDifference) => ({
    type: types.GET_FORECAST_SALES_WOW_SUCCESS,
    payload: {
        nextWeekValue,
        percentageDifference
    }
});

const getForecastSalesWOWFailure = () => ({
    type: types.GET_FORECAST_SALES_WOW_FAILURE
});

const getForecastSalesMOMRequest = () => ({
    type: types.GET_FORECAST_SALES_MOM_REQUEST
});

const getForecastSalesMOMSuccess = (nextMonthValue, percentageDifference, nextMonth, sameMonthLastYear) => ({
    type: types.GET_FORECAST_SALES_MOM_SUCCESS,
    payload: {
        nextMonthValue,
        percentageDifference, 
        nextMonth, 
        sameMonthLastYear
    }
});

const getForecastSalesMOMFailure = () => ({
    type: types.GET_FORECAST_SALES_MOM_FAILURE
});

const getForecastSalesYOYRequest = () => ({
    type: types.GET_FORECAST_SALES_YOY_REQUEST
});

const getForecastSalesYOYSuccess = (financialYearToGoValue, percentageDifference) => ({
    type: types.GET_FORECAST_SALES_YOY_SUCCESS,
    payload: {
        financialYearToGoValue,
        percentageDifference
    }
});

const getForecastSalesYOYFailure = () => ({
    type: types.GET_FORECAST_SALES_YOY_FAILURE
});

const getSalesHistoryForecastRequest = () => ({
    type: types.GET_SALES_HISTORY_FORECAST_REQUEST
});

const getSalesHistoryForecastSuccess = (values) => ({
    type: types.GET_SALES_HISTORY_FORECAST_SUCCESS,
    payload: {
        values
    }
});

const getSalesHistoryForecastFailure = () => ({
    type: types.GET_SALES_HISTORY_FORECAST_FAILURE
});

const getMonthlyForecastRequest = () => ({
    type: types.GET_MONTHLY_FORECAST_REQUEST
});

const getMonthlyForecastSuccess = (values) => ({
    type: types.GET_MONTHLY_FORECAST_SUCCESS,
    payload: {
        values
    }
});

const getMonthlyForecastFailure = () => ({
    type: types.GET_MONTHLY_FORECAST_FAILURE
});

const actions = {
    getForecastSalesWOWRequest,
    getForecastSalesWOWSuccess,
    getForecastSalesWOWFailure,
    getForecastSalesMOMRequest,
    getForecastSalesMOMSuccess,
    getForecastSalesMOMFailure,
    getForecastSalesYOYRequest,
    getForecastSalesYOYSuccess,
    getForecastSalesYOYFailure,
    getSalesHistoryForecastRequest,
    getSalesHistoryForecastSuccess,
    getSalesHistoryForecastFailure,
    getMonthlyForecastRequest,
    getMonthlyForecastSuccess,
    getMonthlyForecastFailure
};

export default actions;
