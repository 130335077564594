import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { withStyles } from "@material-ui/core/styles";

import { selectSavedLocationsSearch, setSavedLocationsSearch } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const Search: React.FC = () => {
    const dispatch = useAppDispatch();
    const savedLocationsSearch = useAppSelector(selectSavedLocationsSearch);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nameOrAddress = event.target.value;
        const newSavedLocationsSearch = { ...savedLocationsSearch, nameOrAddress };
        dispatch(setSavedLocationsSearch(newSavedLocationsSearch));
    };

    return (
        <StyledTextField
            placeholder="Search"
            variant="outlined"
            size="small"
            color="secondary"
            value={savedLocationsSearch.nameOrAddress}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRoundedIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default Search;
