import React from "react";

import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import Slide from "@material-ui/core/Slide";
import { useTheme, withStyles } from "@material-ui/core/styles";

import RefreshReady from "./RefreshReady";
import RefreshInProgress from "./RefreshInProgress";

import { useAppDispatch, useAppSelector } from "store";
import {
    DataRefreshStatus,
    hideDataRefresh,
    proceedWithRefresh,
    selectDataRefresh,
    selectDataRefreshVisibility,
    showDataRefresh
} from "modules/customer/data/dataSlice";

const StyledCard = withStyles(() => ({
    root: {
        color: (props: any) => props["data-text-color"],
        backgroundColor: (props: any) => props["data-background-color"]
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
}))(CardContent);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        cursor: "pointer",
        color: (props: any) => props["data-text-color"],
        backgroundColor: (props: any) => props["data-background-color"],
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))(DialogTitle);

const StyledDialogActions = withStyles(theme => ({
    root: {
        justifyContent: "center",
        // @ts-ignore
        background: theme.palette.quaternary.dark
    }
}))(DialogActions);

const Refresh: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const dataRefresh = useAppSelector(selectDataRefresh);
    const disabled = dataRefresh.status !== DataRefreshStatus.Ready && dataRefresh.status !== DataRefreshStatus.InProgress;
    const dataRefreshVisibility = useAppSelector(selectDataRefreshVisibility);
    const show = dataRefreshVisibility.isVisible;
    const { title, subtitle, textColor, backgroundColor } = (() => {
        const standardColors = {
            textColor: theme.palette.text.primary,
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.dark
        };
        switch (dataRefresh.status) {
            case DataRefreshStatus.Ready:
                return {
                    title: "You're ready to refresh Analytics",
                    subtitle: "",
                    textColor: theme.palette.background.default,
                    // @ts-ignore
                    backgroundColor: theme.palette.tertiary.main
                };
            case DataRefreshStatus.InProgress:
                return {
                    title: "Refreshing Analytics",
                    subtitle: "",
                    textColor: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.main
                };
            case DataRefreshStatus.Paused:
                return {
                    title: "Refresh paused",
                    subtitle: "Our team are looking into the issue and will contact you shortly",
                    textColor: theme.palette.background.default,
                    backgroundColor: theme.palette.warning.main
                };
            case DataRefreshStatus.PendingDatasets:
                return {
                    title: "Upload datasets to refresh Analytics",
                    subtitle: "",
                    ...standardColors
                };
            case DataRefreshStatus.UnavailableDataProcessing:
                return {
                    title: "Refresh unavailable during data processing",
                    subtitle: "",
                    ...standardColors
                };
            case DataRefreshStatus.UnavailableNoAllowance:
                return {
                    title: "No refresh allowance available",
                    subtitle: `Wait until ${dataRefresh.nextRefreshAvailableAt.toLocaleString()} or update your plan`,
                    ...standardColors
                };
            default:
                return {
                    title: "",
                    subtitle: "",
                    ...standardColors
                };
        }
    })();

    const handleRefreshClick = () => {
        if (show) {
            dispatch(hideDataRefresh());
            return;
        }
        dispatch(showDataRefresh());
    };

    const handleProceedClick = () => {
        dispatch(proceedWithRefresh());
    };

    return (
        <>
            {!show && (
                <StyledCard elevation={0} data-text-color={textColor} data-background-color={backgroundColor}>
                    <CardActionArea onClick={handleRefreshClick} disabled={disabled} disableRipple>
                        <StyledCardContent>
                            <Typography variant="h5" component="div" align="center" gutterBottom style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                {title}
                                {(dataRefresh.status === DataRefreshStatus.Ready || dataRefresh.status === DataRefreshStatus.InProgress) && (
                                    <>
                                        &nbsp;&nbsp;
                                        <ArrowUpwardRoundedIcon />
                                    </>
                                )}
                            </Typography>
                            <Box color={dataRefresh.status === DataRefreshStatus.Paused ? "inherit" : "text.disabled"}>
                                <Typography variant="subtitle1" component="div" align="center">
                                    {subtitle}
                                </Typography>
                            </Box>
                        </StyledCardContent>
                    </CardActionArea>
                </StyledCard>
            )}
            <Dialog
                open={show}
                maxWidth="lg"
                fullWidth
                scroll="paper"
                TransitionComponent={Slide}
                TransitionProps={{
                    // @ts-ignore
                    direction: "up"
                }}
                PaperProps={{
                    style: { height: "calc(100% - 64px)" }
                }}
            >
                <StyledDialogTitle data-text-color={textColor} data-background-color={backgroundColor} onClick={handleRefreshClick}>
                    <Typography variant="h5" component="div" align="center" gutterBottom style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        {title}
                        &nbsp;&nbsp;
                        <ArrowDownwardRoundedIcon />
                    </Typography>
                </StyledDialogTitle>
                <DialogContent>
                    {dataRefresh.status === DataRefreshStatus.Ready && <RefreshReady />}
                    {dataRefresh.status === DataRefreshStatus.InProgress && <RefreshInProgress />}
                </DialogContent>
                {dataRefresh.status === DataRefreshStatus.Ready && (
                    <StyledDialogActions>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Box paddingTop={1}>
                                <Typography variant="h5" component="div" align="center" gutterBottom>
                                    Refresh all analytics for all users
                                </Typography>
                            </Box>
                            <Box paddingTop={1}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    disableElevation
                                    onClick={handleProceedClick}
                                >
                                    Yes, proceed with refresh
                                </Button>
                            </Box>
                            <Box paddingTop={1}>
                                <Typography variant="subtitle1" component="div" align="center" gutterBottom>
                                    The refresh can take several hours. This will use your organisation's
                                    <br />
                                    refresh allowance and can't be undone.
                                </Typography>
                            </Box>
                        </Box>
                    </StyledDialogActions>
                )}
            </Dialog>
        </>
    );
};

export default Refresh;
