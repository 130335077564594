import React from "react";

import { Box, Grid } from "@material-ui/core";
import CompetitorCategoriesFilter from "./CompetitorCategoriesFilter";

const Filter: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CompetitorCategoriesFilter />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filter;
