import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectHasErrors,
    selectIsLoading,
    selectUniquePopulation
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const UniquePopulation: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const uniquePopulation = useAppSelector(selectUniquePopulation);
    const valueFormatted = numberFormatter.toDecimalPlaces(uniquePopulation, 1);
    const label = "Unique gained population in the catchment area of the selected location";

    return (
        <Box width="100%" height="100%" data-cy="unique-population">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default UniquePopulation;
