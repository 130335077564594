import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";

const termsOfUseSlice = createSlice({
    name: "landing/termsOfUse",
    initialState: {},
    reducers: {}
});

export const downloadTermsOfUse = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/landing/terms-of-use"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export default termsOfUseSlice;
