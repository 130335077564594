import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/marketShare";
import numberFormatter from "utils/numberFormatter";

const NumberOfHouseholds = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const numberOfHouseholds = useSelector(state => selectors.numberOfHouseholds(state));
    const { loading, error, value, percentageDifference } = numberOfHouseholds;
    const valueFormatted = numberFormatter.toDecimalPlaces(value, 0);
    const label = `${selectedStore.name} Number of households vs ${selectedComparator.name}`;

    return (
        <Box width="100%" height="100%" data-cy="number-of-households">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default NumberOfHouseholds;
