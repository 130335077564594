import React from "react";
import { Scrollspy } from "@makotot/ghostui";

import { Tab, Tabs, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { ChapterProps } from "./Chapter";

import { scrollIntoViewByRef } from "utils/scrollUtils";

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: "none",
        minWidth: theme.spacing(12)
    }
}))(Tab);

interface NavigationMobileProps {
    isSetupComplete: boolean,
    chapters: React.ReactElement<ChapterProps>[]
}

const NavigationMobile: React.FC<NavigationMobileProps> = (props) => {
    const { isSetupComplete, chapters } = props;
    const theme = useTheme();
    const scrollspyOffset = theme.spacing(86);

    const handleTabChange = (event: object, value: number) => {
        const chapter = chapters[value];
        scrollIntoViewByRef(chapter.props.chapterRef);
    };

    if (!isSetupComplete) {
        return null;
    }
    return (
        <Scrollspy sectionRefs={chapters.map(chapter => chapter.props.chapterRef)} offset={scrollspyOffset}>
            {({ elementsStatusInViewport }) => (
                <Tabs
                    value={elementsStatusInViewport.lastIndexOf(true) === -1 ? 0 : elementsStatusInViewport.lastIndexOf(true)}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {chapters.map((chapter, index) =>
                        <StyledTab
                            key={index}
                            label={
                                <Typography variant="subtitle1" component="div">{chapter.props.title}</Typography>
                            } />
                    )}
                </Tabs>
            )}
        </Scrollspy>
    );
};

export default NavigationMobile;
