import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { choosePinnedLocation, selectPinnedLocation, selectRetailCentresWithScoreGroups, setSavedLocationDetails } from "modules/customer/tools/location/locationSlice";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { SavedLocationDetails } from "modules/customer/tools/location/savedLocationDetails";
import { apiDelete, apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import { dateTimeSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

interface SavedLocationsVisibility {
    isVisible: boolean
}

export interface SavedLocation {
    id: string,
    name: string,
    latitude: number,
    longitude: number,
    address: string,
    retailCentreId: number,
    notes: string,
    isPublic: boolean,
    createdAt: DateTime,
    user: User
}

interface User {
    id: string,
    firstName: string,
    lastName: string
}

interface AddSavedLocationVisibility {
    isVisible: boolean
}

interface EditSavedLocationVisibility {
    isVisible: boolean,
    savedLocationId: string
}

interface DeleteSavedLocationVisibility {
    isVisible: boolean,
    savedLocationId: string
}

interface SavedLocationForm {
    id: string,
    name: string,
    latitude: number,
    longitude: number,
    address: string,
    retailCentreId: number,
    notes: string,
    isPublic: boolean,
    errors: SavedLocationFormErrors
}

interface SavedLocationFormErrors {
    name: string,
    latitude: string,
    longitude: string,
    address: string,
    retailCentreId: string,
    notes: string
}

interface SavedLocationsSearch {
    nameOrAddress: string
}

interface SavedLocationsFilter {
    userId: string,
    isPublic?: boolean,
    createdAtFrom?: DateTime,
    createdAtTo?: DateTime
}

interface SavedLocationsSort {
    field: SavedLocationsSortField,
    direction: SortDirection
}

export enum SavedLocationsSortField {
    CreatedAt,
    Address,
    UserName
}

interface SavedLocationsState {
    savedLocationsVisibility: SavedLocationsVisibility,
    savedLocations: SavedLocation[],
    addSavedLocationVisibility: AddSavedLocationVisibility,
    editSavedLocationVisibility: EditSavedLocationVisibility,
    deleteSavedLocationVisibility: DeleteSavedLocationVisibility
    savedLocationForm: SavedLocationForm,
    savedLocationsSearch: SavedLocationsSearch,
    savedLocationsFilter: SavedLocationsFilter,
    savedLocationsSort: SavedLocationsSort
}

const initialState: SavedLocationsState = {
    savedLocationsVisibility: {
        isVisible: false
    },
    savedLocations: [],
    addSavedLocationVisibility: {
        isVisible: false
    },
    editSavedLocationVisibility: {
        isVisible: false,
        savedLocationId: ""
    },
    deleteSavedLocationVisibility: {
        isVisible: false,
        savedLocationId: ""
    },
    savedLocationForm: {
        id: "",
        name: "",
        latitude: 0,
        longitude: 0,
        address: "",
        retailCentreId: 0,
        notes: "",
        isPublic: false,
        errors: {
            name: "",
            latitude: "",
            longitude: "",
            address: "",
            retailCentreId: "",
            notes: "",
        }
    },
    savedLocationsSearch: {
        nameOrAddress: ""
    },
    savedLocationsFilter: {
        userId: "",
        isPublic: undefined,
        createdAtFrom: undefined,
        createdAtTo: undefined
    },
    savedLocationsSort: {
        field: SavedLocationsSortField.CreatedAt,
        direction: SortDirection.DESC
    }
};

const savedLocationsSlice = createSlice({
    name: "customer/tools/location/savedLocations",
    initialState,
    reducers: {
        showSavedLocations: (state) => {
            state.savedLocationsVisibility.isVisible = true;
        },
        hideSavedLocations: (state) => {
            state.savedLocationsVisibility = initialState.savedLocationsVisibility;
        },
        setSavedLocations: (state, action: PayloadAction<SavedLocation[]>) => {
            state.savedLocations = action.payload;
        },
        clearSavedLocations: (state) => {
            state.savedLocations = initialState.savedLocations;
        },
        showAddSavedLocation: (state) => {
            state.addSavedLocationVisibility.isVisible = true;
        },
        hideAddSavedLocation: (state) => {
            state.addSavedLocationVisibility = initialState.addSavedLocationVisibility;
        },
        showEditSavedLocation: (state, action: PayloadAction<string>) => {
            state.editSavedLocationVisibility.isVisible = true;
            state.editSavedLocationVisibility.savedLocationId = action.payload;
        },
        hideEditSavedLocation: (state) => {
            state.editSavedLocationVisibility = initialState.editSavedLocationVisibility;
        },
        showDeleteSavedLocation: (state, action: PayloadAction<string>) => {
            state.deleteSavedLocationVisibility.isVisible = true;
            state.deleteSavedLocationVisibility.savedLocationId = action.payload;
        },
        hideDeleteSavedLocation: (state) => {
            state.deleteSavedLocationVisibility = initialState.deleteSavedLocationVisibility;
        },
        setSavedLocationForm: (state, action: PayloadAction<SavedLocationForm>) => {
            state.savedLocationForm = action.payload;
        },
        clearSavedLocationForm: (state) => {
            state.savedLocationForm = initialState.savedLocationForm;
        },
        setSavedLocationsSearch: (state, action: PayloadAction<SavedLocationsSearch>) => {
            state.savedLocationsSearch = action.payload;
        },
        clearSavedLocationsSearch: (state) => {
            state.savedLocationsSearch = initialState.savedLocationsSearch;
        },
        setSavedLocationsFilter: (state, action: PayloadAction<SavedLocationsFilter>) => {
            state.savedLocationsFilter = action.payload;
        },
        clearSavedLocationsFilter: (state) => {
            state.savedLocationsFilter = initialState.savedLocationsFilter;
        },
        setSavedLocationsSort: (state, action: PayloadAction<SavedLocationsSort>) => {
            state.savedLocationsSort = action.payload;
        },
        clearSavedLocationsSort: (state) => {
            state.savedLocationsSort = initialState.savedLocationsSort;
        }
    }
});

export const {
    showSavedLocations,
    hideSavedLocations,
    showAddSavedLocation,
    hideAddSavedLocation,
    showEditSavedLocation,
    hideEditSavedLocation,
    showDeleteSavedLocation,
    hideDeleteSavedLocation,
    setSavedLocationForm,
    clearSavedLocationForm,
    setSavedLocationsSearch,
    setSavedLocationsFilter,
    clearSavedLocationsFilter,
    setSavedLocationsSort
} = savedLocationsSlice.actions;

export const clearSavedLocations = (): AppThunk => async (dispatch) => {
    dispatch(savedLocationsSlice.actions.hideSavedLocations());
    dispatch(savedLocationsSlice.actions.clearSavedLocations());
    dispatch(savedLocationsSlice.actions.hideAddSavedLocation());
    dispatch(savedLocationsSlice.actions.hideEditSavedLocation());
    dispatch(savedLocationsSlice.actions.hideDeleteSavedLocation());
    dispatch(savedLocationsSlice.actions.clearSavedLocationForm());
    dispatch(savedLocationsSlice.actions.clearSavedLocationsSearch());
    dispatch(savedLocationsSlice.actions.clearSavedLocationsFilter());
    dispatch(savedLocationsSlice.actions.clearSavedLocationsSort());
};

export const getSavedLocations = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/tools/location/saved-locations"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const savedLocationsRaw = response.data.savedLocations;
            const savedLocations = savedLocationsRaw.map((savedLocationRaw: any) => ({
                ...savedLocationRaw,
                createdAt: DateTime.fromISO(savedLocationRaw.createdAt, { zone: "utc" })
            }));
            dispatch(savedLocationsSlice.actions.setSavedLocations(savedLocations));
            break;
        }
        default: {
            dispatch(savedLocationsSlice.actions.clearSavedLocations());
            break;
        }
    }
};

export const getSavedLocation = (savedLocationId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/customer/tools/location/saved-locations/${savedLocationId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const form = response.data.savedLocation;
            dispatch(savedLocationsSlice.actions.setSavedLocationForm({
                ...form,
                errors: initialState.savedLocationForm.errors
            }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(savedLocationsSlice.actions.hideEditSavedLocation());
            dispatch(savedLocationsSlice.actions.hideDeleteSavedLocation());
            dispatch(notifyError("Location not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const addSavedLocation = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const pinnedLocation = selectPinnedLocation(state);
    const form = selectSavedLocationForm(state);
    const response = await dispatch(apiPost("/customer/tools/location/saved-locations", form));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(savedLocationsSlice.actions.hideAddSavedLocation());
            dispatch(savedLocationsSlice.actions.clearSavedLocationForm());
            dispatch(notifySuccess("Location saved."));
            if (pinnedLocation) {
                const savedLocationDetails = new SavedLocationDetails(
                    "", //ToDo: return id from save operation on backend
                    form.name,
                    pinnedLocation.latitude,
                    pinnedLocation.longitude,
                    form.address,
                    form.notes,
                    form.isPublic,
                    pinnedLocation.retailCentre
                );
                dispatch(setSavedLocationDetails(savedLocationDetails));
            }
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0],
                latitude: response.errorData?.errors?.latitude?.[0],
                longitude: response.errorData?.errors?.longitude?.[0],
                address: response.errorData?.errors?.address?.[0],
                retailCentreId: response.errorData?.errors?.retailCentreId?.[0],
                notes: response.errorData?.errors?.notes?.[0]
            };
            dispatch(savedLocationsSlice.actions.setSavedLocationForm({ ...form, errors }));
            break;
        }
        default:
            break;
    }
};

export const editSavedLocation = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const form = selectSavedLocationForm(state);
    const response = await dispatch(apiPut(`/customer/tools/location/saved-locations/${form.id}`, form));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(savedLocationsSlice.actions.hideEditSavedLocation());
            dispatch(savedLocationsSlice.actions.clearSavedLocationForm());
            dispatch(notifySuccess("Location edited."));
            dispatch(getSavedLocations());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(savedLocationsSlice.actions.hideEditSavedLocation());
            dispatch(notifyError("Location not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0],
                latitude: response.errorData?.errors?.latitude?.[0],
                longitude: response.errorData?.errors?.longitude?.[0],
                address: response.errorData?.errors?.address?.[0],
                retailCentreId: response.errorData?.errors?.retailCentreId?.[0],
                notes: response.errorData?.errors?.notes?.[0]
            };
            dispatch(savedLocationsSlice.actions.setSavedLocationForm({ ...form, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const deleteSavedLocation = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const form = selectSavedLocationForm(state);
    const response = await dispatch(apiDelete(`/customer/tools/location/saved-locations/${form.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(savedLocationsSlice.actions.hideDeleteSavedLocation());
            dispatch(savedLocationsSlice.actions.clearSavedLocationForm());
            dispatch(notifySuccess("Location deleted."));
            dispatch(getSavedLocations());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Location not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const goToLocation = (savedLocation: SavedLocation): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const retailCentres = selectRetailCentresWithScoreGroups(state);
    const retailCentre = retailCentres.find(rc => rc.id === savedLocation.retailCentreId);
    if (!retailCentre) {
        dispatch(notifyError("Retail centre not found."));
        return;
    }
    const savedLocationDetails = new SavedLocationDetails(
        savedLocation.id,
        savedLocation.name,
        savedLocation.latitude,
        savedLocation.longitude,
        savedLocation.address,
        savedLocation.notes,
        savedLocation.isPublic,
        retailCentre
    );
    const pinnedLocation = new PinnedLocation(
        savedLocation.latitude,
        savedLocation.longitude,
        savedLocation.address,
        retailCentre
    );

    dispatch(setSavedLocationDetails(savedLocationDetails));
    dispatch(choosePinnedLocation(pinnedLocation));

    dispatch(savedLocationsSlice.actions.hideAddSavedLocation());
    dispatch(savedLocationsSlice.actions.hideEditSavedLocation());
    dispatch(savedLocationsSlice.actions.hideDeleteSavedLocation());
    dispatch(savedLocationsSlice.actions.clearSavedLocationForm());

    dispatch(savedLocationsSlice.actions.hideSavedLocations());
};

export const selectSavedLocationsVisibility = (state: RootState) => {
    return state.customer.tools.location.savedLocations.savedLocationsVisibility;
};

export const selectAddSavedLocationVisibility = (state: RootState) => {
    return state.customer.tools.location.savedLocations.addSavedLocationVisibility;
};

export const selectEditSavedLocationVisibility = (state: RootState) => {
    return state.customer.tools.location.savedLocations.editSavedLocationVisibility;
};

export const selectDeleteSavedLocationVisibility = (state: RootState) => {
    return state.customer.tools.location.savedLocations.deleteSavedLocationVisibility;
};

export const selectSavedLocationForm = (state: RootState) => {
    return state.customer.tools.location.savedLocations.savedLocationForm;
};

export const selectSavedLocationsSearch = (state: RootState) => {
    return state.customer.tools.location.savedLocations.savedLocationsSearch;
};

export const selectSavedLocationsFilter = (state: RootState) => {
    return state.customer.tools.location.savedLocations.savedLocationsFilter;
};

export const selectSavedLocationsSort = (state: RootState) => {
    return state.customer.tools.location.savedLocations.savedLocationsSort;
};

export const selectUsers = createSelector(
    (state: RootState) => state.customer.tools.location.savedLocations.savedLocations,
    (savedLocations) => {
        const allUserIds = savedLocations
            .map(savedLocation => savedLocation.user)
            .sort((userA, userB) => stringSortExpression(userA.firstName, userB.firstName, SortDirection.ASC))
            .sort((userA, userB) => stringSortExpression(userA.lastName, userB.lastName, SortDirection.ASC))
            .map(user => user.id);
        const uniqueUserIds = Array.from(new Set(allUserIds));

        const users: User[] = [];
        uniqueUserIds.forEach(id => {
            const user = savedLocations.find(savedLocation => savedLocation.user.id === id)?.user;
            if (user) {
                users.push(user);
            }
        });

        return users;
    }
);

export const selectCreatedAtRange = createSelector(
    (state: RootState) => state.customer.tools.location.savedLocations.savedLocations,
    (savedLocations) => {
        const range = {
            from: DateTime.fromMillis(0, { zone: "utc" }),
            to: DateTime.fromMillis(0, { zone: "utc" })
        };

        const dates = savedLocations
            .map(savedLocation => savedLocation.createdAt)
            .sort((dateTimeA, dateTimeB) => dateTimeSortExpression(dateTimeA, dateTimeB, SortDirection.ASC));

        if (dates.length > 0) {
            range.from = dates[0];
            range.to = dates[dates.length - 1];
        }

        return range;
    }
);

export const selectIsSavedLocationsFilterModified = createSelector(
    selectSavedLocationsFilter,
    (savedLocationsFilter) => {
        return savedLocationsFilter.userId !== initialState.savedLocationsFilter.userId
            || savedLocationsFilter.isPublic !== initialState.savedLocationsFilter.isPublic
            || savedLocationsFilter.createdAtFrom !== initialState.savedLocationsFilter.createdAtFrom
            || savedLocationsFilter.createdAtTo !== initialState.savedLocationsFilter.createdAtTo;
    }
);

export const selectSavedLocations = createSelector(
    (state: RootState) => state.customer.tools.location.savedLocations.savedLocations,
    selectSavedLocationsSearch,
    selectSavedLocationsFilter,
    selectSavedLocationsSort,
    (savedLocations, search, filter, sort) => {
        const nameOrAddress = search.nameOrAddress.toLowerCase();
        const filteredSavedLocations = savedLocations.filter(savedLocation =>
            (!nameOrAddress || savedLocation.name.toLowerCase().includes(nameOrAddress) || savedLocation.address.toLowerCase().includes(nameOrAddress))
            && (!filter.userId || savedLocation.user.id === filter.userId)
            && (filter.isPublic === undefined || savedLocation.isPublic === filter.isPublic)
            && (!filter.createdAtFrom || savedLocation.createdAt >= filter.createdAtFrom)
            && (!filter.createdAtTo || savedLocation.createdAt <= filter.createdAtTo)
            && true);

        return filteredSavedLocations.sort((savedLocationA, savedLocationB) => {
            switch (sort.field) {
                case SavedLocationsSortField.Address:
                    return stringSortExpression(savedLocationA.address, savedLocationB.address, sort.direction);
                case SavedLocationsSortField.UserName:
                    return stringSortExpression(`${savedLocationA.user.firstName} ${savedLocationA.user.lastName}`, `${savedLocationB.user.firstName} ${savedLocationB.user.lastName}`, sort.direction);
                case SavedLocationsSortField.CreatedAt:
                default:
                    return dateTimeSortExpression(savedLocationA.createdAt, savedLocationB.createdAt, sort.direction);

            }
        });
    }
);

export default savedLocationsSlice;
