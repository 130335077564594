import React from "react";

import InputStoreParameters from "./InputStoreParameters";
import SetupPrediction from "./SetupPrediction";

import {
    GenerateRevenuePredictionStep,
    selectActiveGenerateRevenuePredictionStep,
    selectHasPrediction,
    selectDefaultStoreFeatures,
    selectPredictionForm,
    selectStoreParametersForm,
    setCandidatePredictionForm,
    setCandidateStoreParametersForm,
    selectGroups
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const Content: React.FC = () => {
    const dispatch = useAppDispatch();
    const hasPrediction = useAppSelector(selectHasPrediction);
    const predictionForm = useAppSelector(selectPredictionForm);
    const storeParametersForm = useAppSelector(selectStoreParametersForm);
    const defaultStoreFeatures = useAppSelector(selectDefaultStoreFeatures);
    const groups = useAppSelector(selectGroups);
    const activeGenerateRevenuePredictionStep = useAppSelector(selectActiveGenerateRevenuePredictionStep);

    React.useEffect(() => {
        if (hasPrediction) {
            dispatch(setCandidatePredictionForm({ ...predictionForm }));
        } else {
            dispatch(setCandidatePredictionForm({
                ...predictionForm,
                group: groups.length === 1 ? groups[0] : "",
                model: groups.length === 1 ? "All stores" : "",
                salesAreaInSqft: defaultStoreFeatures?.medianSalesAreaInSqft ? String(defaultStoreFeatures.medianSalesAreaInSqft) : "1",
            }));
        }
        dispatch(setCandidateStoreParametersForm(storeParametersForm));
    }, [dispatch, hasPrediction, predictionForm, groups, storeParametersForm, defaultStoreFeatures]);

    return (
        <>
            {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction && (
                <SetupPrediction />
            )}
            {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.InputStoreParameters && (
                <InputStoreParameters />
            )}
        </>
    );
};

export default Content;
