import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const PopulationDensity: React.FC = () => {
    const title = "Population density (Urbanicity)";
    const categories = [
        "<2,000",
        "2,000-3,999",
        "4,000-5,999",
        "6,000-10,000",
        ">10,000"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.populationDensity;
    const retailCentreValues = retailCentre ? [
        retailCentre.popDensityLess2000,
        retailCentre.popDensity2000to3999,
        retailCentre.popDensity4000to5999,
        retailCentre.popDensity6000to10000,
        retailCentre.popDensity10000Plus
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.populationDensity;
    const benchmarkValues = benchmark ? [
        benchmark.popDensityLess2000,
        benchmark.popDensity2000to3999,
        benchmark.popDensity4000to5999,
        benchmark.popDensity6000to10000,
        benchmark.popDensity10000Plus
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default PopulationDensity;
