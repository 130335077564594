import React from "react";

import { Stepper as MuiStepper, Step, StepContent, StepLabel, Typography, withStyles } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import StepIcon from "./StepIcon";

import { useAppSelector } from "store";
import { selectActiveStep, selectCandidateStore } from "modules/customer/insights/cost/filters/filtersSlice";

const StyledStepper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingLeft: theme.spacing(0)
    }
}))(MuiStepper);

const Stepper: React.FC = () => {
    const colourPalette = useColourPalette();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateStore = useAppSelector(selectCandidateStore);

    return (
        <StyledStepper activeStep={activeStep} orientation="vertical">
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Selection
                    </Typography>
                </StepLabel>
                <StepContent>
                    {candidateStore && (<Typography variant="subtitle2" component="div" noWrap>
                        Store
                    </Typography>)}
                    <Typography variant="subtitle2" component="div" noWrap style={{ color: colourPalette.comparators[0] }}>
                        {candidateStore?.storeName}
                    </Typography>
                    <br/>
                    {candidateStore && (<Typography variant="subtitle2" component="div" noWrap>
                        Cost type
                    </Typography>)}
                    <Typography variant="subtitle2" component="div" noWrap style={{ color: colourPalette.comparators[0] }}>
                        {candidateStore?.costName}
                    </Typography>
                </StepContent>
            </Step>
        </StyledStepper>
    );
};

export default Stepper;
