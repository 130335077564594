import React from "react";

import { Grid } from "@material-ui/core";

import NumberOfOpenStores from "./NumberOfOpenStores";
import AreaHealthAlignmentScore from "./AreaHealthAlignmentScore";
import AreaHealthAlignmentDumbbell from "./AreaHealthAlignmentDumbbell";

const AlignmentToAreaHealthBenchmark: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <NumberOfOpenStores />
            </Grid>
            <Grid item xs={12}>
                <AreaHealthAlignmentScore />
            </Grid>
            <Grid item xs={12}>
                <AreaHealthAlignmentDumbbell />
            </Grid>
        </Grid>
    );
};

export default AlignmentToAreaHealthBenchmark;
