import { createSelector } from "reselect";
import _ from "lodash";
import mathUtils from "utils/mathUtils";
import { median, sum } from "mathjs";
import { selectStore, selectComparator, selectProductCategories } from "modules/customer/insights/portfolio/portfolioSlice";

const selectedMarketCategories = (state) => {
    return state.customer.insights.portfolio.areaHealth.marketCategorySpend.selectedMarketCategories;
};

const marketCatSpendPerHeadSelector = (state) => {
    return state.customer.insights.portfolio.areaHealth.marketCategorySpend.marketCatSpendPerHead;
};

const filteredSpendData = createSelector(
    marketCatSpendPerHeadSelector,
    selectedMarketCategories,
    state => selectComparator(state),
    (marketCatSpendPerHead, selectedMarketCategories, selectedComparator) => {
        if (marketCatSpendPerHead.loading || marketCatSpendPerHead.error || marketCatSpendPerHead.spendPerHead.length === 0) {
            return {
                ...marketCatSpendPerHead,
                totalComparatorSpend: 0,
                medianComparatorSpendPerHead: 0
            };
        }

        const comparatorStores = selectedComparator.getStores();
        const spendPerHead = marketCatSpendPerHead.spendPerHead;
        const comparatorSpendPerHead = marketCatSpendPerHead.comparatorSpendPerHead;
        const comparatorData = [];

          for (const i in comparatorStores) {
            const store = comparatorStores[i];
            const matchingResult = comparatorSpendPerHead.filter(item =>
                item.retailCentreID === store.retailCentreID &&
                item.storeCategory === store.kpmgStoreCategory
              ).map(item => ({...item, id:store.id}));
              comparatorData.push(...matchingResult);
          }

        const categoryList = selectedMarketCategories.map(item => item.toLowerCase());

        const filteredSpendData = spendPerHead.filter(item => categoryList.includes(item.category.toLowerCase()));
        const filteredCompSpendData = comparatorData.filter(item => categoryList.includes(item.category.toLowerCase()));

        const totalComparatorSpendValuesPerStore = filteredCompSpendData.reduce((totals, obj) => {
            if (!totals[obj.id]) {
              totals[obj.id] = 0;
            }
            totals[obj.id] += obj.totalSpend;
            return totals;
        }, {});
        const totalComparatorSpend = (Object.values(totalComparatorSpendValuesPerStore).length === 0) 
            ? 0 
            : median(Object.values(totalComparatorSpendValuesPerStore));

        const medianComparatorSpendValues = filteredCompSpendData.map(item => item.medianSpend);
        const medianComparatorSpend = (medianComparatorSpendValues.length === 0) ? 0 : median(medianComparatorSpendValues);

        return {
            ...marketCatSpendPerHead,
            spendPerHead: filteredSpendData,
            totalComparatorSpend: totalComparatorSpend,
            medianComparatorSpendPerHead: medianComparatorSpend
        };

    }
);

const spendPerHeadCatchmentArea = createSelector(
    filteredSpendData,
    (filteredSpendData) => {
        const kpi = {
            loading: filteredSpendData.loading,
            error: filteredSpendData.error,
            value: 0,
            percentageDifference: 0
        };

        if (filteredSpendData.loading || filteredSpendData.error || filteredSpendData.spendPerHead.length === 0) {
            return kpi;
        }

        const spendPerHead = filteredSpendData.spendPerHead;

        const categorySpend = spendPerHead.map(item => item.spend).filter(item => item !== null);
        const value = categorySpend.length === 0 ? 0 : median(categorySpend);
        const comparatorValue = filteredSpendData.medianComparatorSpendPerHead;
        const percentageDifference = (comparatorValue === 0) ? 0 : 100 * ((value - comparatorValue) / comparatorValue);

        kpi.value = value;
        kpi.percentageDifference = percentageDifference;

        return kpi;

    }
);

const largestSpendPerHead = createSelector(
    filteredSpendData,
    (filteredSpendData) => {
        const kpi = {
            loading: filteredSpendData.loading,
            error: filteredSpendData.error,
            value: 0,
            percentageDifference: 0
        };

        if (filteredSpendData.loading || filteredSpendData.error || filteredSpendData.spendPerHead === 0) {
            return kpi;
        }

        const spendPerHead = filteredSpendData.spendPerHead;

        const weightedSpend = spendPerHead.map(item => item.weightedSpend);
        const value = weightedSpend.length === 0 ? 0 : sum(weightedSpend);
        const comparatorValue = filteredSpendData.totalComparatorSpend;
        const percentageDifference = (comparatorValue === 0) ? 0 : 100 * ((value - comparatorValue) / comparatorValue);

        kpi.value = value;
        kpi.percentageDifference = percentageDifference;

        return kpi;

    }
);

const marketCatSpendPerHeadMap = createSelector(
    filteredSpendData,
    selectedMarketCategories,
    (filteredSpendData) => {
        const mapData = {
            loading: filteredSpendData.loading,
            error: filteredSpendData.error,
            mapCenter: filteredSpendData.mapCenter,
            spendPerHead: filteredSpendData.spendPerHead,
            comparatorSpendPerHead: filteredSpendData.medianComparatorSpendPerHead
        };

        if (filteredSpendData.loading || filteredSpendData.error || filteredSpendData.spendPerHead.length === 0) {
            return mapData;
        }

        const spendPerHead = filteredSpendData.spendPerHead;
        const groupedSpendPerHead = _(spendPerHead)
            .groupBy(item => item.oaid)
            .map((group, key) => ({
                outputAreaCode: key,
                value: _.sum(group.map(item => item.spend))
            }))
            .value();

        const spendValues = groupedSpendPerHead.map(item => item.value);
        const percentileThresholds = mathUtils.percentileThresholds(spendValues, 6);
        const spendPerHeadWithPercentile = groupedSpendPerHead.map(spendPerHead => {
            let percentile = 0;
            for (let i = 0; i < percentileThresholds.length; i++) {
                if (spendPerHead.value >= percentileThresholds[i]) {
                    percentile = i;
                }
            }
            return {
                ...spendPerHead,
                percentile
            };
        });

        mapData.spendPerHead = spendPerHeadWithPercentile;

        return mapData;
    }
);

const marketCategoriesRag = createSelector(
    spendPerHeadCatchmentArea,
    filteredSpendData,
    (state) => selectStore(state),
    (state) => selectComparator(state),
    (spendPerHeadCatchmentArea, filteredSpendData, selectedStore, selectedComparator) => {
        const rag = {
            loading: filteredSpendData.loading,
            error: filteredSpendData.error,
            id: "market-categories",
            label: "Market categories",
            status: "info",
            value: ""
        };

        if (spendPerHeadCatchmentArea.loading || spendPerHeadCatchmentArea.error || filteredSpendData.spendPerHead.length === 0) {
            return rag;
        }

        const storeName = selectedStore.name;
        const comparatorName = selectedComparator.name;

        const spendPercentageDifference = spendPerHeadCatchmentArea.percentageDifference;

        if (spendPercentageDifference > 50) {
            rag.status = "success";
            rag.value = `Spend per head in ${storeName} is markedly larger than that of ${comparatorName}`;
        } else if (spendPercentageDifference < -50) {
            rag.status = "error";
            rag.value = `Spend per head in ${storeName} is markedly smaller than that of ${comparatorName}`;
        } else {
            rag.status = "warning";
            rag.value = `Spend per head in ${storeName} is broadly in line with that of ${comparatorName}`;
        }

        return rag;
    }
);

const marketCategoriesList = createSelector(
    (state) => selectProductCategories(state),
    marketCatSpendPerHeadSelector,
    (productCategories, marketCatSpendPerHeadSelector) => {
        const marketCatList = {
            loading: marketCatSpendPerHeadSelector.loading,
            error: marketCatSpendPerHeadSelector.loading,
            marketCategories: productCategories
        };

        return marketCatList;
    }
);

const selectors = {
    spendPerHeadCatchmentArea,
    largestSpendPerHead,
    marketCatSpendPerHeadMap,
    marketCategoriesRag,
    marketCategoriesList,
    selectedMarketCategories
};

export default selectors;
