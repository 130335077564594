import { combineReducers } from "redux";

import types from "./types";

const lifetimeOfStoresEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const lifetimeOfStores = (state = lifetimeOfStoresEmpty, action) => {
    switch (action.type) {
        case types.GET_LIFETIME_OF_STORES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_LIFETIME_OF_STORES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_LIFETIME_OF_STORES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const netOpenVsClosuresOverTimeEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const netOpenVsClosuresOverTime = (state = netOpenVsClosuresOverTimeEmpty, action) => {
    switch (action.type) {
        case types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    lifetimeOfStores,
    netOpenVsClosuresOverTime,
});

export default reducer;
