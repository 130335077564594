import React from "react";

import { Card, CardContent, CardHeader, Grid, GridSize, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme, withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import {
    selectIsLoading,
    selectHasErrors,
    selectPenPortraits
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { PenPortrait } from "modules/customer/tools/location/demographics/penPortrait";
import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(TextField);

const StyledCardHeader = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.common.black,
        backgroundColor: (props: any) => props["data-background-color"]
    }
}))(CardHeader);

const CustomerProfilesDefinitions: React.FC = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const colours = [theme.palette.text.disabled, ...colourPalette.categorical];
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const penPortraits = useAppSelector(selectPenPortraits);
    const [penPortrait, setPenPortrait] = React.useState<PenPortrait | null>(null);
    const [inputValue, setInputValue] = React.useState("");
    const inputLabel = isLoading
        ? "Loading..."
        : hasErrors ? "Error!" : "Search definition of customer profiles";
    const supergroupId = Number(penPortrait?.id.substr(0, 1) ?? 0);
    const definitionTitleBackgroundColour = colours[supergroupId];
    const searchGridSize: GridSize = isInsightExpanded ? 5 : 12;
    const definitionGridSize: GridSize = isInsightExpanded ? 7 : 12;

    return (
        <Grid container spacing={1}>
            <Grid item xs={searchGridSize}>
                <Autocomplete
                    disabled={isLoading || hasErrors}
                    value={penPortrait}
                    size="small"
                    onChange={(event, newPenPortrait) => {
                        setPenPortrait(newPenPortrait);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={penPortraits}
                    getOptionLabel={(penPortrait) => `${penPortrait.name} (${penPortrait.level})`}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            label={inputLabel}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={definitionGridSize}>
                <Card elevation={0}>
                    <StyledCardHeader
                        disableTypography
                        title={
                            penPortrait ? (
                                <Typography variant="h6" component="div">
                                    {penPortrait.level}&nbsp;&nbsp;<b>{penPortrait.name}</b>
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    Customer profile definition
                                </Typography>
                            )
                        }
                        data-background-color={definitionTitleBackgroundColour}
                    />
                    <CardContent>
                        <Typography variant="subtitle1" component="div">
                            {penPortrait ? penPortrait.description : "Select a customer profile to see its definition"}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CustomerProfilesDefinitions;
