import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Drawer as MuiDrawer, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Logo from "./Logo";

import { logout } from "modules/auth/authSlice";
import { useAppDispatch } from "store";

const StyledDrawer = withStyles(theme => ({
    root: {
        width: theme.spacing(25),
        flexShrink: 0
    },
    paper: {
        width: theme.spacing(25),
        // @ts-ignore
        backgroundColor: theme.palette.background.drawer
    }
}))(MuiDrawer);

const StyledListItem = withStyles(theme => ({
    root: {
        "&.active": {
            backgroundColor: theme.palette.primary.main
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.background.paper
        },
        "&.Mui-disabled": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.light
        }
    }
}))(ListItem);

const Drawer: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const handleLogoutClick = () => {
        dispatch(logout());
    };

    return (
        <StyledDrawer variant="permanent" elevation={0} anchor="left">
            <Box height={theme.spacing(7.5)} display="flex" alignItems="center" justifyContent="center">
                <Logo />
            </Box>
            <List disablePadding>
                <Divider />
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/home">
                    <ListItemIcon>
                        <HomeRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/admins">
                    <ListItemIcon>
                        <SupervisorAccountRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admins" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/leads">
                    <ListItemIcon>
                        <PersonRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Leads" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/accounts">
                    <ListItemIcon>
                        <RadioButtonUncheckedRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/users">
                    <ListItemIcon>
                        <RadioButtonUncheckedRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/frequently-asked-questions">
                    <ListItemIcon>
                        <LiveHelpRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="FAQs" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/audit">
                    <ListItemIcon>
                        <VerifiedUserRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Audit" />
                </StyledListItem>
            </List>
            <Spacer />
            <List disablePadding>
                <Divider />
                {/* @ts-ignore */}
                <StyledListItem button divider component={NavLink} to="/about">
                    <ListItemIcon>
                        <InfoRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="About" />
                </StyledListItem>
                {/* @ts-ignore */}
                <StyledListItem button divider onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <ExitToAppRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </StyledListItem>
            </List>
        </StyledDrawer>
    );
};

export default Drawer;
