import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import BeeSwarm from "components/visuals/BeeSwarm";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/revenueVsCompetitionScore";
import mathUtils from "utils/mathUtils";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";
import useColourPalette from "components/visuals/useColourPalette";
import numberFormatter from "utils/numberFormatter";

const StoreVsComparatorRevVsComp = () => {
    const colourPalette = useColourPalette();
    const selectedStoreSelector = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorRevVsComp = useSelector(state => selectors.storeVsComparatorRevVsComp(state));
    const { loading, error, store, comparator } = storeVsComparatorRevVsComp;

    const selectedStore = [{
        x: mathUtils.round(store.revenueVsComp, 2),
        custom: {
            store: store.storeName,
            rank: store.rank
        }
    }];

    const comparators = comparator.map(item => ({
        x: mathUtils.round(item.revenueVsComp, 2),
        custom: {
            store: item.storeName,
            rank: item.rank
        }
    }));

    const valuesArr = (data) => {
        const arr = (Array.isArray(data)) ? data : [data];
        return arr.map(row => {
            return Number(row.x);
        });
    };
    
    const allDataPoints = valuesArr(selectedStore).concat(valuesArr(comparators));

    const firstTertile = arrayUtils.quantile(allDataPoints, 1 / 3);
    const secondTertile = arrayUtils.quantile(allDataPoints, 2 / 3);

    const options = {
        xAxis: {
            plotBands: [
                {
                    from: 0,
                    to: firstTertile,
                    color: colourPalette.RAGChartZones[0],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Underperforming",
                        x: 0,
                        y: -2
                    }
                },
                {
                    from: firstTertile,
                    to: secondTertile,
                    color: colourPalette.RAGChartZones[1],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Average",
                        x: 0,
                        y: -2
                    }
                },
                {
                    from: secondTertile,
                    to: 10e12,
                    color: colourPalette.RAGChartZones[2],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Top Performer",
                        x: 0,
                        y: -2
                    }
                }
            ],
            labels: {
                format: "£{text}"
            }
        },

        tooltip: {
            enabled: true,
            headerFormat: "<table>",
            pointFormatter: function () {

                const series = this.series;
                const seriesColor = series.color;

                return stringUtils.tooltipHTML(["Sales vs comp score", "Rank"], {
                    header: `Store: <span style="color:${seriesColor}">${this.custom.store}</span>`,
                    values: [numberFormatter.toGBP(this.x, 2), this.custom.rank]
                });
            },
            footerFormat: "</table>",
            useHTML: true
        },

        series: [{ //RevenueVsComp needs to be plotted on x-axis
            name: selectedStoreSelector.name,
            color: colourPalette.comparators[0],
            data: selectedStore
        }, {
            name: `Store in ${selectedComparator.name}`,
            color: colourPalette.comparators[1],
            data: comparators
        }]
    };

    return (
        <Box data-cy="store-vs-comparator-rev-vs-comp">
            <BeeSwarm loading={loading} error={error} options={options} />
        </Box>
    );
};

export default StoreVsComparatorRevVsComp;
