const GET_LOCAL_MARKET_SHARE_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_LOCAL_MARKET_SHARE_REQUEST";
const GET_LOCAL_MARKET_SHARE_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_LOCAL_MARKET_SHARE_SUCCESS";
const GET_LOCAL_MARKET_SHARE_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_LOCAL_MARKET_SHARE_FAILURE";
const GET_NUMBER_OF_HOUSEHOLDS_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_NUMBER_OF_HOUSEHOLDS_REQUEST";
const GET_NUMBER_OF_HOUSEHOLDS_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_NUMBER_OF_HOUSEHOLDS_SUCCESS";
const GET_NUMBER_OF_HOUSEHOLDS_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_NUMBER_OF_HOUSEHOLDS_FAILURE";
const GET_REVENUE_PER_HOUSEHOLD_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_REVENUE_PER_HOUSEHOLD_REQUEST";
const GET_REVENUE_PER_HOUSEHOLD_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_REVENUE_PER_HOUSEHOLD_SUCCESS";
const GET_REVENUE_PER_HOUSEHOLD_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_REVENUE_PER_HOUSEHOLD_FAILURE";
const GET_MARKET_SHARE_OVER_TIME_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_OVER_TIME_REQUEST";
const GET_MARKET_SHARE_OVER_TIME_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_OVER_TIME_SUCCESS";
const GET_MARKET_SHARE_OVER_TIME_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_OVER_TIME_FAILURE";
const GET_OPENINGS_AND_CLOSURES_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_OPENINGS_AND_CLOSURES_REQUEST";
const GET_OPENINGS_AND_CLOSURES_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_OPENINGS_AND_CLOSURES_SUCCESS";
const GET_OPENINGS_AND_CLOSURES_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_OPENINGS_AND_CLOSURES_FAILURE";
const GET_MARKET_SHARE_TREND_REQUEST = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_TREND_REQUEST";
const GET_MARKET_SHARE_TREND_SUCCESS = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_TREND_SUCCESS";
const GET_MARKET_SHARE_TREND_FAILURE = "spark/customer/insights/portfolio/competition/marketShare/GET_MARKET_SHARE_TREND_FAILURE";

const types = {
    GET_LOCAL_MARKET_SHARE_REQUEST,
    GET_LOCAL_MARKET_SHARE_SUCCESS,
    GET_LOCAL_MARKET_SHARE_FAILURE,
    GET_NUMBER_OF_HOUSEHOLDS_REQUEST,
    GET_NUMBER_OF_HOUSEHOLDS_SUCCESS,
    GET_NUMBER_OF_HOUSEHOLDS_FAILURE,
    GET_REVENUE_PER_HOUSEHOLD_REQUEST,
    GET_REVENUE_PER_HOUSEHOLD_SUCCESS,
    GET_REVENUE_PER_HOUSEHOLD_FAILURE,
    GET_MARKET_SHARE_OVER_TIME_REQUEST,
    GET_MARKET_SHARE_OVER_TIME_SUCCESS,
    GET_MARKET_SHARE_OVER_TIME_FAILURE,
    GET_OPENINGS_AND_CLOSURES_REQUEST,
    GET_OPENINGS_AND_CLOSURES_SUCCESS,
    GET_OPENINGS_AND_CLOSURES_FAILURE,
    GET_MARKET_SHARE_TREND_REQUEST,
    GET_MARKET_SHARE_TREND_SUCCESS,
    GET_MARKET_SHARE_TREND_FAILURE
};

export default types;
