import React from "react";

import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearCategory,
    deleteCategory,
    getCategory,
    hideDeleteCategory,
    selectCategory,
    selectDeleteCategoryVisibility,
    showDeleteCategory
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteCategoryProps {
    categoryId: string;
}

const DeleteCategory: React.FC<DeleteCategoryProps> = (props) => {
    const { categoryId } = props;
    const dispatch = useAppDispatch();
    const category = useAppSelector(selectCategory);
    const deleteCategoryVisibility = useAppSelector(selectDeleteCategoryVisibility);
    const show = (deleteCategoryVisibility.isVisible) && (categoryId === deleteCategoryVisibility.categoryId);

    React.useEffect(() => {
        if (show) {
            dispatch(getCategory(categoryId));
        }
    }, [dispatch, show, categoryId]);

    const handleDeleteCategoryClick = () => {
        dispatch(showDeleteCategory(categoryId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteCategory());
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteCategory());
        dispatch(clearCategory());
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteCategoryClick}>
                    <DeleteRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h1" component="div" color="error" gutterBottom>
                            <ErrorOutlineRoundedIcon fontSize="inherit" color="inherit" />
                        </Typography>
                        <Typography variant="h5" component="div" gutterBottom>
                            Are you sure you want to delete this category and all its questions?
                        </Typography>
                    </>
                }
                titleAlign="center"
                content={
                    <Typography variant="subtitle1" component="div">
                        {category.name}
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button variant="outlined" onClick={handleCancelClick}>
                            No, cancel
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={handleConfirmClick}>
                            Yes, delete
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default DeleteCategory;
