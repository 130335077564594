import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

export interface DatasetFile {
    id: string,
    datasetId: string,
    fileName: string,
    uploadedBy: string,
    uploadedAt: DateTime,
    status: DatasetFileStatus
}

export enum DatasetFileStatus {
    Submitted = "Submitted",
    Processing = "Processing",
    Successful = "Successful",
    Failed = "Failed"
}

interface DatasetFilesVisibility {
    isVisible: boolean,
    accountId: string
}

interface DeleteDatasetFileVisibility {
    isVisible: boolean,
    datasetFileId: string
}

interface DatasetFilesState {
    datasetFiles: DatasetFile[],
    datasetFilesVisibility: DatasetFilesVisibility,
    deleteDatasetFileVisibility: DeleteDatasetFileVisibility
}

const initialState: DatasetFilesState = {
    datasetFiles: [],
    datasetFilesVisibility: {
        isVisible: false,
        accountId: ""
    },
    deleteDatasetFileVisibility: {
        isVisible: false,
        datasetFileId: ""
    }
};

const datasetFilesSlice = createSlice({
    name: "admin/accounts/datasetFiles",
    initialState,
    reducers: {
        setDatasetFiles: (state, action: PayloadAction<DatasetFile[]>) => {
            state.datasetFiles = action.payload;
        },
        clearDatasetFiles: (state) => {
            state.datasetFiles = initialState.datasetFiles;
        },
        showDatasetFiles: (state, action: PayloadAction<string>) => {
            state.datasetFilesVisibility.isVisible = true;
            state.datasetFilesVisibility.accountId = action.payload;
        },
        hideDatasetFiles: (state) => {
            state.datasetFilesVisibility = initialState.datasetFilesVisibility;
        },
        showDeleteDatasetFile: (state, action: PayloadAction<string>) => {
            state.deleteDatasetFileVisibility.isVisible = true;
            state.deleteDatasetFileVisibility.datasetFileId = action.payload;
        },
        hideDeleteDatasetFile: (state) => {
            state.deleteDatasetFileVisibility = initialState.deleteDatasetFileVisibility;
        }
    }
});

export const {
    clearDatasetFiles,
    showDatasetFiles,
    hideDatasetFiles,
    showDeleteDatasetFile,
    hideDeleteDatasetFile
} = datasetFilesSlice.actions;

export const getDatasetFiles = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/dataset-files`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const datasetFilesRaw = response.data.datasetFiles;
            const datasetFiles = datasetFilesRaw.map((datasetFileRaw: any) => ({
                ...datasetFileRaw,
                uploadedAt: DateTime.fromISO(datasetFileRaw.uploadedAt, { zone: "utc" })
            }));
            dispatch(datasetFilesSlice.actions.setDatasetFiles(datasetFiles));
            break;
        }
        default: {
            dispatch(datasetFilesSlice.actions.clearDatasetFiles());
            break;
        }
    }
};

export const deleteDatasetFile = (accountId: string, datasetFileId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiDelete(`/admin/accounts/${accountId}/dataset-files/${datasetFileId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(datasetFilesSlice.actions.hideDeleteDatasetFile());
            dispatch(notifySuccess("Dataset file deleted."));
            dispatch(getDatasetFiles(accountId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Dataset file not found."));
            break;
        }
    }
};

export const selectDatasetFiles = (state: RootState) => {
    return state.admin.accounts.datasetFiles.datasetFiles;
};

export const selectDatasetFilesVisibility = (state: RootState) => {
    return state.admin.accounts.datasetFiles.datasetFilesVisibility;
};

export const selectDeleteDatasetFileVisibility = (state: RootState) => {
    return state.admin.accounts.datasetFiles.deleteDatasetFileVisibility;
};

export default datasetFilesSlice;
