import React from "react";

import { Box, Container } from "@material-ui/core";

import Footer from "./Footer";
import Header from "./header/Header";
import Router from "./Router";

const Landing = () => {
    const headerHeight = 112;
    const footerHeight = 515;

    return (
        <Box flexGrow={1} data-cy="pages-landing">
            <Header />
            <Container
                component="main"
                maxWidth={false}
                disableGutters
                style={{ minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)` }}
            >
                <Router />
            </Container>
            <Footer />
        </Box>
    );
};

export default Landing;
