const grossRevenueYTD = (state) => {
    return state.customer.insights.performance.overview.keyPerformanceIndicators.grossRevenueYTD;
};

const contributionYTD = (state) => {
    return state.customer.insights.performance.overview.keyPerformanceIndicators.contributionYTD;
};

const forecastSalesYTG = (state) => {
    return state.customer.insights.performance.overview.keyPerformanceIndicators.forecastSalesYTG;
};

const ytdSalesPlusYTGSalesVsBudgetedSales = (state) => {
    return state.customer.insights.performance.overview.keyPerformanceIndicators.ytdSalesPlusYTGSalesVsBudgetedSales;
};

const selectors = {
    grossRevenueYTD,
    contributionYTD,
    forecastSalesYTG,
    ytdSalesPlusYTGSalesVsBudgetedSales
};

export default selectors;
