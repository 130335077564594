import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const ProductCategoryMixSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            <p>
            Analyse the intricacies of customer buying habits in your {store?.name} store. Assess the performance
            of each product category from both a growth and sales mix perspective, identifying your best,
            growing, steady, and worst performers to refine your sales and marketing strategies. Investigate
            performance discrepancies against {comparator?.name} to comprehend the nuances in local customer
            buying habits. 
            </p>
        </>
    );
};

export default ProductCategoryMixSubtitle;
