import { combineReducers } from "redux";

import types from "./types";

const budgetVsForecastSalesEmpty = {
    loading: false,
    error: false,
    quarterlySales: [],
    quarterlyBudget: []
};

const budgetVsForecastSales = (state = budgetVsForecastSalesEmpty, action) => {
    switch (action.type) {
        case types.GET_BUDGET_VS_FORECAST_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                quarterlySales: [],
                quarterlyBudget: []
            };
        case types.GET_BUDGET_VS_FORECAST_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quarterlySales: action.payload.quarterlySales,
                quarterlyBudget: action.payload.quarterlyBudget
            };
        case types.GET_BUDGET_VS_FORECAST_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                quarterlySales: [],
                quarterlyBudget: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    budgetVsForecastSales
});

export default reducer;
