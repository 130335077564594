import React from "react";

import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import DatasetStatus from "./DatasetStatus";
import Upload from "./Upload";
import DatasetFile from "./DatasetFile";

import { useAppDispatch } from "store";
import {
    Dataset as DatasetObject,
    DatasetStatus as DatasetStatusEnum,
    DatasetType,
    downloadTemplate
} from "modules/customer/data/dataSlice";

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            paddingBottom: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Button);

const useStyles = makeStyles(() => ({
    gridItem: {
        display: "flex",
        alignItems: "center"
    },
    actionGridItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

interface DatasetProps {
    dataset: DatasetObject
}

const Dataset: React.FC<DatasetProps> = (props) => {
    const { dataset } = props;
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const tooltip = (() => {
        switch (dataset.id) {
            case "budget":
                return "Details of your budget so Dash can analyse progress vs. targets.";
            case "company":
                return "Details specific to your business and the market you operate in.";
            case "costs":
                return "All of your overheads that are needed to calculate net profit.";
            case "products":
                return "Details of your product catalogue, categories and subcategories.";
            case "sales":
                return "All of your revenue and direct cost data related to transactions.";
            case "stores":
                return "Details of your existing store locations and sizes.";
            default:
                return "Unknown";
        }
    })();
    const {
        variant,
        backgroundColor
    }: { variant: "outlined" | "elevation", backgroundColor: string } = dataset.status === DatasetStatusEnum.Unknown
        ? { variant: "outlined", backgroundColor: theme.palette.background.default }
        : { variant: "elevation", backgroundColor: theme.palette.background.paper };

    const handleDownloadTemplateClick = (datasetId: string) => () => {
        dispatch(downloadTemplate(datasetId));
    };

    return (
        <Card elevation={0} variant={variant} style={{ backgroundColor: backgroundColor }}>
            <StyledCardContent>
                <Grid container>
                    <Grid container item xs={6}>
                        <Grid item xs={2} className={classes.gridItem}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" component="div">
                                    {dataset.name}
                                </Typography>
                                &nbsp;&nbsp;
                                {/* @ts-ignore */}
                                <SimpleTooltip
                                    placement={SimpleTooltipPlacement.Right}
                                    title={
                                        <Typography variant="body2" component="div">
                                            {tooltip}
                                        </Typography>
                                    }
                                >
                                    <InfoOutlinedIcon fontSize="small" color="disabled" cursor="pointer" />
                                </SimpleTooltip>
                            </Box>
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <DatasetStatus dataset={dataset} />
                        </Grid>
                        <Grid item xs={4}>
                            <DatasetFile datasetFile={dataset.newData} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={1} />
                        <Grid item xs={4}>
                            <DatasetFile datasetFile={dataset.currentData} />
                        </Grid>
                        <Grid item xs={4} className={classes.actionGridItem}>
                            <StyledButton
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                startIcon={<SaveAltRoundedIcon />}
                                onClick={handleDownloadTemplateClick(dataset.id)}
                            >
                                {dataset.type === DatasetType.Csv ? "Data example" : "Template"}
                            </StyledButton>
                        </Grid>
                        <Grid item xs={3} className={classes.actionGridItem} style={{ justifyContent: "flex-start" }}>
                            <Upload dataset={dataset} />
                        </Grid>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );
};

export default Dataset;
