import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Button, Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StayOneStepAhead = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    const handleClick = () => {
        dispatch(trackEvent("Home page", "Stay One Step Ahead - How dash works click", "Stay One Step Ahead - How dash works button"));
    };

    return (
        <PageSection dataCy="pages-landing-home-stay-one-step-ahead">
            <Grid container spacing={6} direction="row-reverse" alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/home/stay-one-step-ahead.png" alt="stay one step ahead" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant={isMobile ? "h5" : "h3"} component="div" align={align}>
                        Stay one step ahead
                    </Typography>
                    <br />
                    <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                        Dash helps you envisage the future performance of your business.
                        We accent your data with the latest market intelligence and our machine learning algorithms
                        provide rich forecasts allowing you to buy better and seize opportunities.
                    </Typography>
                    <br />
                    <br />
                    <Box align={align}>
                        <Button
                            component={NavLink}
                            to="/how-dash-works"
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleClick}
                            disableElevation
                            data-cy="btn-how-dash-works"
                        >
                            How Dash works
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default StayOneStepAhead;
