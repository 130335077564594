import { ResultSet } from "@cubejs-client/core";
import { createAppAsyncThunk } from "appThunk";
import { Comparator } from "modules/customer/tools/location/comparator";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { CatchmentAccountIds } from "./locationSlice";

export class CatchmentPopulation {
    public readonly retailCentreId: number;
    public readonly baselinePopulation: number;
    public readonly maxDistance: number;
    public readonly population: number;
    public readonly isScenario: boolean;

    constructor(
        retailCentreId: number,
        baselinePopulation: number,
        maxDistance: number,
        population: number,
        isScenario: boolean
    ) {
        this.retailCentreId = retailCentreId;
        this.baselinePopulation = baselinePopulation;
        this.maxDistance = maxDistance;
        this.population = population;
        this.isScenario = isScenario;
    }
}

export const loadCatchmentPopulation = createAppAsyncThunk<
    CatchmentPopulation[],
    {
        catchmentAccountIds: CatchmentAccountIds,
        selectedRetailCentreId?: number,
        demographicsComparator?: Comparator,
        spendComparator?: Comparator,
        targetStoreCategoryId?: number
    }
>(
    "customer/tools/location/loadCatchmentPopulation",
    async ({ catchmentAccountIds, selectedRetailCentreId, demographicsComparator, spendComparator, targetStoreCategoryId }, thunkAPI) => {
        if (!selectedRetailCentreId || !demographicsComparator || !spendComparator || !targetStoreCategoryId) {
            return [];
        }
    
        try {
            const scenarioCatchmentsRCIDs = [String(selectedRetailCentreId)];
            const baselineCatchmentsRCIDs: string[] = [];

            if (demographicsComparator.scenarioCatchment) {
                scenarioCatchmentsRCIDs.push(String(demographicsComparator.retailCentreId));
            }
            else {
                baselineCatchmentsRCIDs.push(String(demographicsComparator.retailCentreId));
            }

            if (spendComparator.scenarioCatchment) {
                scenarioCatchmentsRCIDs.push(String(spendComparator.retailCentreId));
            }
            else {
                baselineCatchmentsRCIDs.push(String(spendComparator.retailCentreId));
            }

            const orClause = [{
                and: [{
                    member: "CatchmentAreasPopulation.RetailCentreID",
                    operator: "equals",
                    values: scenarioCatchmentsRCIDs
                }, {
                    member: "CatchmentAreasPopulation.IsScenario",
                    operator: "equals",
                    values: ["1"]
                }, {
                    member: "CatchmentAreasPopulation.Client_ID",
                    operator: "equals",
                    values: [catchmentAccountIds.scenario]
                }]
            }];

            if (baselineCatchmentsRCIDs.length > 0) {
                orClause.push({
                    and: [{
                        member: "CatchmentAreasPopulation.RetailCentreID",
                        operator: "equals",
                        values: baselineCatchmentsRCIDs
                    }, {
                        member: "CatchmentAreasPopulation.IsScenario",
                        operator: "equals",
                        values: ["0"]
                    }, {
                        member: "CatchmentAreasPopulation.Client_ID",
                        operator: "equals",
                        values: [catchmentAccountIds.baseline]
                    }]
                });
            }

            const query = {
                dimensions: [
                    "CatchmentAreasPopulation.RetailCentreID",
                    "CatchmentAreasPopulation.BaselinePopulation", //weighted
                    "CatchmentAreasPopulation.MaxDistance",
                    "CatchmentAreasPopulation.Population",
                    "CatchmentAreasPopulation.IsScenario"
                ],
                filters: [{
                    member: "CatchmentAreasPopulation.StoreCategory_ID",
                    operator: "equals",
                    values: [String(targetStoreCategoryId)]
                }, {
                    or: orClause
                }]
            };

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            const rawData = resultSet.rawData();
            return rawData.map(row => new CatchmentPopulation(
                row["CatchmentAreasPopulation.RetailCentreID"] ?? 0,
                row["CatchmentAreasPopulation.BaselinePopulation"] ?? 0,
                row["CatchmentAreasPopulation.MaxDistance"] ?? 0,
                row["CatchmentAreasPopulation.Population"] ?? 0,
                Boolean(Number(row["CatchmentAreasPopulation.IsScenario"])) ?? false
            ));
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading CatchmentPopulation.", error));
            throw error;
        }
    }
);
