import { combineReducers } from "redux";

import footfall from "./footfall";
import demographicBreakdown from "./demographicBreakdown";
import customerProfiles from "./customerProfiles";

const reducer = combineReducers({
    footfall,
    demographicBreakdown,
    customerProfiles
});

export default reducer;
