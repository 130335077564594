import React from "react";

import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    clearSavedLocationForm,
    editSavedLocation,
    getSavedLocation,
    hideEditSavedLocation,
    selectEditSavedLocationVisibility,
    selectSavedLocationForm,
    setSavedLocationForm
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledActionsGridItem = withStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}))(Grid);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

const StyledSaveButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(Button);

interface EditSavedLocationProps {
    savedLocationId: string
}

const EditSavedLocation: React.FC<EditSavedLocationProps> = (props) => {
    const { savedLocationId } = props;
    const dispatch = useAppDispatch();
    const form = useAppSelector(selectSavedLocationForm);
    const editSavedLocationVisibility = useAppSelector(selectEditSavedLocationVisibility);
    const show = (editSavedLocationVisibility.isVisible) && (savedLocationId === editSavedLocationVisibility.savedLocationId);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newForm = { ...form, name, errors: { ...form.errors, name: "" } };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notes = event.target.value;
        const newForm = { ...form, notes, errors: { ...form.errors, notes: "" } };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleIsPublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isPublic = Boolean(Number(event.target.value));
        const newForm = { ...form, isPublic };
        dispatch(setSavedLocationForm(newForm));
    };

    const handleCancelClick = () => {
        dispatch(hideEditSavedLocation());
        dispatch(clearSavedLocationForm());
    };

    const handleSaveClick = () => {
        dispatch(editSavedLocation());
    };

    React.useEffect(() => {
        if (show) {
            dispatch(getSavedLocation(savedLocationId));
        }
    }, [dispatch, show, savedLocationId]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <StyledTextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    value={form.name}
                    onChange={handleNameChange}
                    error={!!form.errors.name}
                    helperText={form.errors.name}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <StyledTextField
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    select
                    value={Number(form.isPublic)}
                    onChange={handleIsPublicChange}
                    required
                    fullWidth
                >
                    <MenuItem value={0}>Private</MenuItem>
                    <MenuItem value={1}>Public</MenuItem>
                </StyledTextField>
            </Grid>
            <Grid item xs={12}>
                <StyledTextField
                    label="Notes"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    margin="normal"
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={form.notes}
                    onChange={handleNotesChange}
                    error={!!form.errors.notes}
                    helperText={form.errors.notes}
                    fullWidth
                />
            </Grid>
            <StyledActionsGridItem item xs={12}>
                <Button
                    variant="text"
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <StyledSaveButton
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleSaveClick}
                >
                    Save
                </StyledSaveButton>
            </StyledActionsGridItem>
        </Grid>
    );
};

export default EditSavedLocation;
