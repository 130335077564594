const GET_DEMOGRAPHICS_REQUEST = "spark/customer/insights/portfolio/catchment/demographicBreakdown/GET_DEMOGRAPHICS_REQUEST";
const GET_DEMOGRAPHICS_SUCCESS = "spark/customer/insights/portfolio/catchment/demographicBreakdown/GET_DEMOGRAPHICS_SUCCESS";
const GET_DEMOGRAPHICS_FAILURE = "spark/customer/insights/portfolio/catchment/demographicBreakdown/GET_DEMOGRAPHICS_FAILURE";

const types = {
    GET_DEMOGRAPHICS_REQUEST,
    GET_DEMOGRAPHICS_SUCCESS,
    GET_DEMOGRAPHICS_FAILURE
};

export default types;
