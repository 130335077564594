import React from "react";

import { Grid } from "@material-ui/core";

import SpendByCustomerProfile from "./SpendByCustomerProfile";
import SpendPerHead from "./SpendPerHead";

const CustomerProfileSpend: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SpendByCustomerProfile />
            </Grid>
            <Grid item xs={12}>
                <SpendPerHead />
            </Grid>
        </Grid>
    );
};

export default CustomerProfileSpend;
