import React from "react";

import DumbbellAlignment from "components/visuals/DumbellAlignment";

import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const SpendAlignmentDumbbell: React.FC = () => {
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const target = useAppSelector(selectTarget);
    const targetIsSet = Boolean(target?.useSpend);

    const chartValues = [{
        lowCategory: "Low catchment spend",
        highCategory: "High catchment spend",
        label: "Spend",
        selectedValue: pinnedLocation?.retailCentre.spendCentile ?? 0,
        targetValue: target?.spend ?? 0
    }];

    return (
        <DumbbellAlignment chartValues={chartValues} targetIsSet={targetIsSet} dataCy="spend-alignment" />
    );
};

export default SpendAlignmentDumbbell;
