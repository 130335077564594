const GET_STORE_ACTUAL_VS_BUDGET_RANKED_REQUEST = "spark/customer/insights/performance/budget/storePerformanceAgainstBudget/GET_STORE_ACTUAL_VS_BUDGET_RANKED_REQUEST";
const GET_STORE_ACTUAL_VS_BUDGET_RANKED_SUCCESS = "spark/customer/insights/performance/budget/storePerformanceAgainstBudget/GET_STORE_ACTUAL_VS_BUDGET_RANKED_SUCCESS";
const GET_STORE_ACTUAL_VS_BUDGET_RANKED_FAILURE = "spark/customer/insights/performance/budget/storePerformanceAgainstBudget/GET_STORE_ACTUAL_VS_BUDGET_RANKED_FAILURE";

const types = {
    GET_STORE_ACTUAL_VS_BUDGET_RANKED_REQUEST,
    GET_STORE_ACTUAL_VS_BUDGET_RANKED_SUCCESS,
    GET_STORE_ACTUAL_VS_BUDGET_RANKED_FAILURE
};

export default types;
