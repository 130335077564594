import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface User {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole
}

enum UserRole {
    Unknown = "Unknown",
    Owner = "Owner",
    Admin = "Admin",
    Editor = "Editor",
    Viewer = "Viewer"
}

interface UsersVisibility {
    isVisible: boolean,
    accountId: string
}

interface UsersState {
    users: User[],
    usersVisibility: UsersVisibility
}

const initialState: UsersState = {
    users: [],
    usersVisibility: {
        isVisible: false,
        accountId: ""
    }
};

const usersSlice = createSlice({
    name: "admin/accounts/users",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
        },
        clearUsers: (state) => {
            state.users = initialState.users;
        },
        showUsers: (state, action: PayloadAction<string>) => {
            state.usersVisibility.isVisible = true;
            state.usersVisibility.accountId = action.payload;
        },
        hideUsers: (state) => {
            state.usersVisibility = initialState.usersVisibility;
        }
    }
});

export const {
    clearUsers,
    showUsers,
    hideUsers
} = usersSlice.actions;

export const getUsers = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/users`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const users = response.data.users;
            dispatch(usersSlice.actions.setUsers(users));
            break;
        }
        default: {
            dispatch(usersSlice.actions.clearUsers());
            break;
        }
    }
};

export const selectUsers = (state: RootState) => {
    return state.admin.accounts.users.users;
};

export const selectUsersVisibility = (state: RootState) => {
    return state.admin.accounts.users.usersVisibility;
};

export default usersSlice;
