import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartBase from "./ChartBase";


const Bubble = (props) => {
    const { dataCy, loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: "packedbubble",
            height: "100%",
        },
        plotOptions: {
            packedbubble: {
                minSize: "20%",
                maxSize: "100%",
                zMin: 0,
                zMax: 1000,
                layoutAlgorithm: {
                    splitSeries: true,
                    enableSimulation: false
                },
                point: {
                    events: {
                        mouseOut: function () {
                            let data = this.series.data;
                            data.forEach((bubble) => {
                                if (bubble.dataLabel) {
                                    bubble.dataLabel.css({
                                        fontSize: "0px",
                                    });
                                }
                            });
                        },
                        mouseOver: function () {
                            let data = this.series.data;
                            data.forEach((bubble) => {
                                if (bubble.dataLabel) {
                                    bubble.dataLabel.css({
                                        fontSize: "10px",
                                    });
                                }
                            });
                        }
                    }
                }
            },
            series: {
                marker: {
                    lineWidth: 0,
                    fillOpacity: 0.5
                }
            }
        },
    };

    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

Bubble.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Bubble.defaultProps = {
    dataCy: "bubble-chart",
    loading: false,
    error: false
};

export default Bubble;
