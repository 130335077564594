import React from "react";
import { useAppSelector } from "store"; 

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";
import { selectStoreDependencyOnProductCategory } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const StoreDependencyOnProductCategory = () => {
    const storeDependencyOnProductCategory = useAppSelector(selectStoreDependencyOnProductCategory);
    return (
        <RAGIndicatorNew ragIndicator={storeDependencyOnProductCategory} />
    );
};

export default StoreDependencyOnProductCategory;
