import React from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { useAppDispatch, useAppSelector } from "store";
import { selectStoresSort, setStoresSort, StoreSortField } from "modules/customer/insights/portfolio/filters/filtersSlice";
import { SortDirection } from "utils/sortUtils";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(25)
    }
}))(Menu);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

const Sort: React.FC = () => {
    const dispatch = useAppDispatch();
    const storesSort = useAppSelector(selectStoresSort);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSortClick = (field: StoreSortField) => () => {
        let direction = SortDirection.ASC;
        if (storesSort.field === field) {
            direction = storesSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newStoreSort = { ...storesSort, field, direction };
        dispatch(setStoresSort(newStoreSort));
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                color="default"
                disableElevation
                startIcon={<ArrowDownwardIcon />}
                onClick={handleClick}
                data-cy="btn-sort"
            >
                Sort
            </Button>
            <StyledMenu
                open={open}
                onClose={handleClose}
                variant="menu"
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Name)} data-current={storesSort.field === StoreSortField.Name}>
                    <Typography variant="body1" component="div">
                        Store name
                    </Typography>
                    <Spacer />
                    <Typography variant="body1" component="div">
                        {storesSort.field !== StoreSortField.Name || storesSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                    </Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.TotalScore)} data-current={storesSort.field === StoreSortField.TotalScore}>
                    <Typography variant="body1" component="div">Total score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.TotalScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.SalesScore)} data-current={storesSort.field === StoreSortField.SalesScore}>
                    <Typography variant="body1" component="div">Sales score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.SalesScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.DriversScore)} data-current={storesSort.field === StoreSortField.DriversScore}>
                    <Typography variant="body1" component="div">Driver score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.DriversScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.ProfitScore)} data-current={storesSort.field === StoreSortField.ProfitScore}>
                    <Typography variant="body1" component="div">Profit score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.ProfitScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.AreaHealthScore)} data-current={storesSort.field === StoreSortField.AreaHealthScore}>
                    <Typography variant="body1" component="div">Area health score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.AreaHealthScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.CompetitionScore)} data-current={storesSort.field === StoreSortField.CompetitionScore}>
                    <Typography variant="body1" component="div">Competition score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.CompetitionScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.CatchmentScore)} data-current={storesSort.field === StoreSortField.CatchmentScore}>
                    <Typography variant="body1" component="div">Catchment score</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.CatchmentScore || storesSort.direction === SortDirection.ASC ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
};

export default Sort;
