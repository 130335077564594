import { combineReducers } from "redux";

import types from "./types";

const revenueLastYearEmpty = {
    loading: false,
    error: false,
    placeholder: {}
};

const revenueLastYear = (state = revenueLastYearEmpty, action) => {
    switch (action.type) {
        case types.GET_REVENUE_LAST_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                placeholder: {}
            };
        case types.GET_REVENUE_LAST_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                placeholder: action.payload.placeholder
            };
        case types.GET_REVENUE_LAST_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                placeholder: {}
            };
        default:
            return state;
    }
};

const unitSalesLastYearEmpty = {
    loading: false,
    error: false
};

const unitSalesLastYear = (state = unitSalesLastYearEmpty, action) => {
    switch (action.type) {
        case types.GET_UNIT_SALES_LAST_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                placeholder: {}
            };
        case types.GET_UNIT_SALES_LAST_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                placeholder: action.payload.placeholder
            };
        case types.GET_UNIT_SALES_LAST_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                placeholder: {}
            };
        default:
            return state;
    }
};

const medianWeeklyRevenueEmpty = {
    loading: false,
    error: false
};

const medianWeeklyRevenue = (state = medianWeeklyRevenueEmpty, action) => {
    switch (action.type) {
        case types.GET_MEDIAN_WEEKLY_REVENUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                placeholder: {}
            };
        case types.GET_MEDIAN_WEEKLY_REVENUE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                placeholder: action.payload.placeholder
            };
        case types.GET_MEDIAN_WEEKLY_REVENUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                placeholder: {}
            };
        default:
            return state;
    }
};

const medianWeeklyUnitSalesEmpty = {
    loading: false,
    error: false
};

const medianWeeklyUnitSales = (state = medianWeeklyUnitSalesEmpty, action) => {
    switch (action.type) {
        case types.GET_MEDIAN_WEEKLY_UNIT_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                placeholder: {}
            };
        case types.GET_MEDIAN_WEEKLY_UNIT_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                placeholder: action.payload.placeholder
            };
        case types.GET_MEDIAN_WEEKLY_UNIT_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                placeholder: {}
            };
        default:
            return state;
    }
};

const revenueUnitSalesOverTimeEmpty = {
    loading: false,
    error: false,
    placeholder: []
};

const revenueUnitSalesOverTime = (state = revenueUnitSalesOverTimeEmpty, action) => {
    switch (action.type) {
        case types.GET_REVENUE_UNIT_SALES_OVERTIME_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                placeholder: []
            };
        case types.GET_REVENUE_UNIT_SALES_OVERTIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                placeholder: action.payload.placeholder
            };
        case types.GET_REVENUE_UNIT_SALES_OVERTIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                placeholder: []
            };
        default:
            return state;
    }
};

const revenueTrendEmpty = {
    loading: false,
    error: false,
    id: "revenue-trend",
    label: "Revenue trend RAG placeholder",
    status: "info",
    value: ""
};

const revenueTrend = (state = revenueTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_REVENUE_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_REVENUE_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_REVENUE_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const unitSalesTrendEmpty = {
    loading: false,
    error: false,
    id: "unit-sales-trend",
    label: "Unit sales trend RAG placeholder",
    status: "info",
    value: ""
};

const unitSalesTrend = (state = unitSalesTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_UNIT_SALES_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_UNIT_SALES_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_UNIT_SALES_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    revenueLastYear,
    unitSalesLastYear,
    medianWeeklyRevenue,
    medianWeeklyUnitSales,
    revenueUnitSalesOverTime,
    revenueTrend,
    unitSalesTrend
});

export default reducer;
