import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface AuditLog {
    action: AuditAction,
    entityName: string,
    entityKeys: string,
    oldValues: string,
    newValues: string,
    executedBy: string,
    executedAt: DateTime
}

enum AuditAction {
    Unknown = "Unknown",
    Create = "Create",
    Update = "Update",
    Delete = "Delete"
}

interface AuditLogsState {
    auditLogs: AuditLog[],
    count: number,
    page: number,
    rowsPerPage: number
}

const initialState: AuditLogsState = {
    auditLogs: [],
    count: 0,
    page: 0,
    rowsPerPage: 25
};

const auditLogsSlice = createSlice({
    name: "admin/auditLogs",
    initialState,
    reducers: {
        setAuditLogs: (state, action: PayloadAction<AuditLog[]>) => {
            state.auditLogs = action.payload;
        },
        clearAuditLogs: (state) => {
            state.auditLogs = initialState.auditLogs;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        clearCount: (state) => {
            state.count = initialState.count;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        clearPage: (state) => {
            state.page = initialState.page;
        },
        setRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
            state.page = initialState.page;
        },
        clearRowsPerPage: (state) => {
            state.rowsPerPage = initialState.rowsPerPage;
            state.page = initialState.page;
        },
    }
});

export const {
    clearAuditLogs,
    clearCount,
    setPage,
    setRowsPerPage,
} = auditLogsSlice.actions;

export const getAuditLogs = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const page = selectPage(state);
    const rowsPerPage = selectRowsPerPage(state);
    const skip = page * rowsPerPage;
    const response = await dispatch(apiGet(`/admin/audit-logs?skip=${skip}&take=${rowsPerPage}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const auditLogsRaw = response.data.auditLogs;
            const auditLogs = auditLogsRaw.map((auditLogRaw: any) => ({
                ...auditLogRaw,
                executedAt: DateTime.fromISO(auditLogRaw.executedAt, { zone: "utc" })
            }));
            dispatch(auditLogsSlice.actions.setAuditLogs(auditLogs));
            break;
        }
        default: {
            dispatch(auditLogsSlice.actions.clearAuditLogs());
            break;
        }
    }
};

export const countAuditLogs = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/audit-logs/count"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const count = response.data.count;
            dispatch(auditLogsSlice.actions.setCount(count));
            break;
        }
        default: {
            dispatch(auditLogsSlice.actions.clearCount());
            break;
        }
    }
};

export const selectAuditLogs = (state: RootState) => {
    return state.admin.auditLogs.auditLogs;
};

export const selectCount = (state: RootState) => {
    return state.admin.auditLogs.count;
};

export const selectPage = (state: RootState) => {
    return state.admin.auditLogs.page;
};

export const selectRowsPerPage = (state: RootState) => {
    return state.admin.auditLogs.rowsPerPage;
};

export default auditLogsSlice;
