import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";

const getSalesPerformanceVsBudget = () => async (dispatch, getState) => {
    dispatch(actions.getSalesPerformanceVsBudgetRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(currentDate, fyStart);
        const financialYearToGoStartDate = dateUtils.nextDay(currentDate);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);

        /* Actual Sales FYTD */
        const querySalesFYTD = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, currentDate]
            }],
            filters: [{
                member: "F_SalesForecast.ForecastFlag",
                operator: "equals",
                values: ["0"]
            }]
        };

        const resultSetSalesFYTD = await dispatch(cubeLoad(querySalesFYTD));
        const salesFYTD = resultSetSalesFYTD.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];

        /* Forecast Sales FYTG */
        const querySalesFYTG = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearToGoStartDate, financialYearEndDate]
            }],
            filters: [{
                member: "F_SalesForecast.ForecastFlag",
                operator: "equals",
                values: ["1"]
            }]
        };
        const resultSetSalesFYTG = await dispatch(cubeLoad(querySalesFYTG));
        const salesFYTG = resultSetSalesFYTG.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];

        /* Budget sales FY */
        const budgetQuery = {
            measures: ["F_Budget.SumBudgetRevenue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate]
            }],
            dimensions: ["D_Date.FY"]
        };
        const budgetResultSet = await dispatch(cubeLoad(budgetQuery));

        let budgetFY = 0;
        let FYlabel = "";
        if (budgetResultSet.loadResponses[0].data.length !== 0) {
            budgetFY = budgetResultSet.loadResponses[0].data[0]["F_Budget.SumBudgetRevenue"];
            const FY = budgetResultSet.loadResponses[0].data[0]["D_Date.FY"];
            FYlabel = "FY" + FY;
        }

        dispatch(actions.getSalesPerformanceVsBudgetSuccess(salesFYTD, salesFYTG, budgetFY, FYlabel));
    }
    catch (error) {
        dispatch(actions.getSalesPerformanceVsBudgetFailure());
        dispatch(logError("Error loading SalesPerformanceVsBudget.", error));
    }
};

const operations = {
    getSalesPerformanceVsBudget
};

export default operations;
