import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "./store";

export class StoreCategory {
    public readonly id: number;
    public readonly name: string;
    public readonly isPrimary: boolean;
    public readonly isInPortfolio: boolean;

    constructor(
        id: number,
        name: string,
        isPrimary: boolean,
        isInPortfolio: boolean
    ) {
        this.id = id;
        this.name = name;
        this.isPrimary = isPrimary;
        this.isInPortfolio = isInPortfolio;
    }
}

export const loadStoreCategories = (primaryStoreCategoryId: number, stores: Store[]): AppThunk<Promise<StoreCategory[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "StoreCategory.id",
                "StoreCategory.category"
            ],
            filters: [{
                member: "StoreCategory.isRetail",
                operator: "equals",
                values: ["1"]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        const portfolioCategoriesIds = new Set(stores.map(store => store.categoryId));
        return rawData.map(row => {
            const id = row["StoreCategory.id"] as number;
            const name = row["StoreCategory.category"] as string;
            const isPrimary = id === primaryStoreCategoryId;
            const isInPortfolio = portfolioCategoriesIds.has(id);
            return new StoreCategory(id, name, isPrimary, isInPortfolio);
        });
    } catch (error) {
        dispatch(logError("Error loading StoreCategories.", error));
        throw error;
    }
};
