import { Store } from "./store";

export class Comparator {
    public readonly storeId: string;
    public readonly storeName: string;
    public readonly store: Store;
    public readonly retailCentreId: number;
    public readonly scenarioCatchment: boolean;

    constructor(
        storeId: string,
        storeName: string,
        store: Store,
        retailCentreId: number,
        scenarioCatchment: boolean
    ) {
        this.storeId = storeId;
        this.storeName = storeName;
        this.store = store;
        this.retailCentreId = retailCentreId;
        this.scenarioCatchment = scenarioCatchment;
    }
}

export class ComparatorsByChapter {
    public readonly demographics: Comparator;
    public readonly spend: Comparator | undefined;
    public readonly areaHealth: Comparator;
    public readonly footfall: Comparator;

    constructor(
        demographics: Comparator,
        spend: Comparator | undefined,
        areaHealth: Comparator,
        footfall: Comparator
    ) {
        this.demographics = demographics;
        this.spend = spend;
        this.areaHealth = areaHealth;
        this.footfall = footfall;
    }
}
