import { cubeLoad, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import actions from "./actions";
import { median } from "mathjs";
import { SortDirection, numberSortExpression } from "utils/sortUtils";

const getMarketCategoryOpeningsVsClosures = () => async (dispatch, getState) => {
    dispatch(actions.getMarketCategoryOpeningsVsClosuresRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedOAID = selectedStoreSelector.outputAreaCode;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);

        const relevantOAs = comparatorOAIDs.concat(selectedOAID);

        const queryKpmgCompInd = {
            dimensions: [
                "KpmgCategoryCompInd.kpmgCategory",
                "KpmgCategoryCompInd.complementaryIndicator"
            ],
            order: {
                "KpmgCategoryCompInd.kpmgCategory": "asc"
            }
        };

        const resultqueryKpmgCompInd = await dispatch(cubeLoad(queryKpmgCompInd));
        const compIndData = resultqueryKpmgCompInd.loadResponses[0].data.map(item => ({
            kpmgCategory: item["KpmgCategoryCompInd.kpmgCategory"],
            complementaryIndicator: item["KpmgCategoryCompInd.complementaryIndicator"]
        }));

        const complementaryAndNonComplementary = compIndData.filter(item => item.complementaryIndicator !== -1).map(item => item.kpmgCategory);

        const queryOpeningsAndClosures = {
            measures: [
                "OpeningsAndClosures.SumTwelveMonthsOpenings",
                "OpeningsAndClosures.SumTwelveMonthsClosures",
            ],
            dimensions: [
                "OpeningsAndClosures.MainOAID",
                "OpeningsAndClosures.RetailCategory"
            ],
            filters: [
                {
                    "member": "OpeningsAndClosures.MainOAID",
                    "operator": "equals",
                    "values": relevantOAs
                }
            ]
        };

        const resultSetOpeningsAndClosures = await dispatch(cubeLoadExtended(queryOpeningsAndClosures));

        const uniqueCategories = new Set(resultSetOpeningsAndClosures.loadResponses[0].data.map(item => item["OpeningsAndClosures.RetailCategory"]));
        const uniqueCategoriesIterable = new Array(...uniqueCategories);

        const openingsAndClosures = uniqueCategoriesIterable.map(storeCategory => {
            const relevantCompInd = compIndData.find(item => item.kpmgCategory === storeCategory);
            const complementaryIndicator = relevantCompInd?.complementaryIndicator ?? -1;

            const categoryOpenings = {
                primaryProductCategory: storeCategory,
                complementaryInd: complementaryIndicator,
                storeOpeningCount: 0,
                storeClosingCount: 0,
                storeNetOpening: 0,
                comparatorOpeningCount: 0,
                comparatorClosingCount: 0,
                comparatorNetOpening: 0
            };

            const relevantData =
                resultSetOpeningsAndClosures.loadResponses[0].data.filter(item =>
                    storeCategory === item["OpeningsAndClosures.RetailCategory"]);

            if (relevantData) {
                const storeData = relevantData.find(item => item["OpeningsAndClosures.MainOAID"] === selectedOAID);
                if (storeData) {
                    categoryOpenings.storeOpeningCount = storeData["OpeningsAndClosures.SumTwelveMonthsOpenings"];
                    categoryOpenings.storeClosingCount = storeData["OpeningsAndClosures.SumTwelveMonthsClosures"];
                    categoryOpenings.storeNetOpening = categoryOpenings.storeOpeningCount - categoryOpenings.storeClosingCount;
                }
                const compData = relevantData.filter(item => comparatorOAIDs.includes(item["OpeningsAndClosures.MainOAID"]));
                if (compData.length !== 0) {
                    const compOpenings = compData.map(item => item["OpeningsAndClosures.SumTwelveMonthsOpenings"]);
                    const medianOpenings = median(compOpenings);
                    const compClosures = compData.map(item => item["OpeningsAndClosures.SumTwelveMonthsClosures"]);
                    const medianClosures = median(compClosures);
                    categoryOpenings.comparatorOpeningCount = medianOpenings;
                    categoryOpenings.comparatorClosingCount = medianClosures;
                    categoryOpenings.comparatorNetOpening = categoryOpenings.comparatorOpeningCount - categoryOpenings.comparatorClosingCount;
                }
            }

            return categoryOpenings;
        });

        for (const i in compIndData) {
            const relevantData = openingsAndClosures.find(opening => opening.primaryProductCategory === compIndData[i].kpmgCategory);
            if (!relevantData) {
                openingsAndClosures.push({
                    primaryProductCategory: compIndData[i].kpmgCategory,
                    complementaryInd: compIndData[i].complementaryIndicator,
                    storeOpeningCount: 0,
                    storeClosingCount: 0,
                    storeNetOpening: 0,
                    comparatorOpeningCount: 0,
                    comparatorClosingCount: 0,
                    comparatorNetOpening: 0
                });
            }
        }

        openingsAndClosures.sort((a, b) => numberSortExpression(a.complementaryInd, b.complementaryInd, SortDirection.DESC));

        const filteredOpeningsAndClosures = openingsAndClosures.filter(item =>
            complementaryAndNonComplementary.includes(item.primaryProductCategory)
            || (item.comparatorOpeningCount > 0)
            || (item.comparatorClosingCount > 0)
            || (item.storeOpeningCount > 0)
            || (item.storeClosingCount > 0)
        );

        const storeData = filteredOpeningsAndClosures.map(item => ({
            primaryProductCategory: item.primaryProductCategory,
            complementaryInd: item.complementaryInd,
            openingCount: item.storeOpeningCount,
            closingCount: item.storeClosingCount,
            netOpening: item.storeNetOpening
        }));

        const comparatorData = filteredOpeningsAndClosures.map(item => ({
            primaryProductCategory: item.primaryProductCategory,
            complementaryInd: item.complementaryInd,
            openingCount: item.comparatorOpeningCount,
            closingCount: item.comparatorClosingCount,
            netOpening: item.comparatorNetOpening
        }));

        dispatch(actions.getMarketCategoryOpeningsVsClosuresSuccess(storeData, comparatorData));
    }
    catch (error) {
        dispatch(actions.getMarketCategoryOpeningsVsClosuresFailure());
        dispatch(logError("Error loading MarketCategoryOpeningsVsClosures.", error));
    }
};

const operations = {
    getMarketCategoryOpeningsVsClosures
};

export default operations;
