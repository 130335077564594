import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import actions from "./actions";
import dateUtils from "utils/dateUtils";

import { selectStore, selectComparator, selectReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";

const getStoreVsComparatorRankedGrossProfit = () => async (dispatch, getState) => {
    dispatch(actions.getStoreVsComparatorRankedGrossProfitRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = [selectedStoreID, ...comparatorStoresIDs];

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const query = {
            measures: ["F_Sales.SumLineValue", "F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsStartDate, currentDate]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.StoreName"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query));

        const data = resultSet.loadResponses[0].data.map(item => ({
            StoreID: item["D_Store.StoreNaturalID"],
            Store: item["D_Store.StoreName"],
            Sales: item["F_Sales.SumLineValue"] ?? 0,
            COGS: item["F_Sales.SumLineCost"] ?? 0,
            GrossProfitPercent:
                (item["F_Sales.SumLineValue"] === 0) ? 0 : 100 * ((item["F_Sales.SumLineValue"] - item["F_Sales.SumLineCost"]) / item["F_Sales.SumLineValue"])
        }));

        const sortedData = data.sort((a, b) => {
            return b.GrossProfitPercent - a.GrossProfitPercent; //descending order - higher value gets lower rank
        });

        //Find rank for each store. Stores with same gross profit percent should be given same rank
        for (let i in sortedData) {
            sortedData[i].rank = 1 + sortedData.findIndex(item => {
                if (item.GrossProfitPercent === sortedData[i].GrossProfitPercent) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        const store = sortedData.filter(item => item.StoreID === selectedStoreID); //array as post MVP this will have multiple months
        const comparator = sortedData.filter(item => item.StoreID !== selectedStoreID);

        //Rank of selected store value within store and comparator array -- for use with rag. (to be updated as applicable once there are months in the main array)
        const selectedStore = sortedData.find(item => item.StoreID === selectedStoreID);
        const rank = selectedStore.rank;

        const denominator = sortedData.length;

        dispatch(actions.getStoreVsComparatorRankedGrossProfitSuccess(store, comparator, rank, denominator));
    }
    catch (error) {
        dispatch(actions.getStoreVsComparatorRankedGrossProfitFailure());
        dispatch(logError("Error loading StoreVsComparatorRankedGrossProfit.", error));
    }
};

const operations = {
    getStoreVsComparatorRankedGrossProfit
};

export default operations;
