import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import TotalSalesGrowthByRegion from "./TotalSalesGrowthByRegion";
import SalesByRegionGrowthBottom from "./SalesByRegionGrowthBottom";
import SalesByRegionGrowthTop from "./SalesByRegionGrowthTop";
import DependencyOnSingularRegionOrStore from "./DependencyOnSingularRegionOrStore";

import { operations } from "modules/customer/insights/performance/sales/regionalGrowthBreakdown";

const RegionalGrowthBreakdown = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(operations.getSalesByRegionGrowth());
    }, [dispatch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TotalSalesGrowthByRegion />
            </Grid>
            <Grid item xs={12} md={6}>
                <SalesByRegionGrowthTop />
            </Grid>
            <Grid item xs={12} md={6}>
                <SalesByRegionGrowthBottom />
            </Grid>
            <Grid item xs={12}>
                <DependencyOnSingularRegionOrStore />
            </Grid>
        </Grid>
    );
};

export default RegionalGrowthBreakdown;
