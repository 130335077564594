import React from "react";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    addStoreToCandidateComparator,
    selectCandidateStore,
    selectCandidateComparator,
    selectStoresWithSimilarityScores
} from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.common.white,
        colour: theme.palette.common.white
    }
}))(Button);

const DashAIComparatorsButton: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateStore = useAppSelector(selectCandidateStore);
    const candidateComparator = useAppSelector(selectCandidateComparator);
    const storesWithSimilarityScores = useAppSelector(selectStoresWithSimilarityScores);
    const similarStoreIds = (candidateStore?.similarStores ?? [])
        .filter(similarStore => similarStore.includedInDashComparator)
        .map(similarStore => similarStore.id);


    const handleSelectDashAIComparators = () => {
        if (similarStoreIds.length > 0) {
            const similarStores = storesWithSimilarityScores.filter(store => similarStoreIds.includes(store.id));
            similarStores.forEach(store => {
                if (!candidateComparator?.getStores().some(s => s.id === store.id)) {
                    dispatch(addStoreToCandidateComparator(store));
                }
            });
        }
    };

    return (
        <StyledButton
            variant="outlined"
            size="medium"
            disableElevation
            onClick={handleSelectDashAIComparators}
            data-cy="btn-select-dashai-comparators"
        >
            Select DashAI comparators
        </StyledButton>
    );
};

export default DashAIComparatorsButton;
