import React from "react";

import { Button, Card, CardContent, CardHeader, Switch, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        // @ts-ignore
        backgroundColor: (props) => props["data-disabled"] ? theme.palette.background.paper : theme.palette.quaternary.dark,
        borderStyle: "solid",
        borderWidth: "1px",
        // @ts-ignore
        borderColor: (props) => props["data-disabled"] ? theme.palette.quaternary.main : theme.palette.background.paper
    }
}))(Card);

const StyledCardHeader = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(CardHeader);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        "&:last-child": {
            padding: theme.spacing(0)
        }
    }
}))(CardContent);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1)
    }
}))(Button);

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

interface SectionProps {
    name: string,
    disabled: boolean,
    resetButtonDisabled: boolean,
    onResetButtonClick: () => void,
    onSwitchChange: () => void
}

const ChapterAlignment: React.FC<React.PropsWithChildren<SectionProps>> = (props) => {
    const { name, disabled, resetButtonDisabled, onResetButtonClick, onSwitchChange, children } = props;

    return (
        <StyledCard elevation={0} data-disabled={disabled}>
            <StyledCardHeader
                disableTypography
                title={
                    <Typography variant="h6" component="div" gutterBottom>
                        {name}
                    </Typography>
                }
                subheader={
                    <StyledButton
                        variant="text"
                        size="small"
                        color="default"
                        disableElevation
                        disabled={disabled || resetButtonDisabled}
                        onClick={onResetButtonClick}
                    >
                        Reset to comparator median
                    </StyledButton>
                }
                action={
                    <StyledSwitch
                        size="small"
                        color="default"
                        checked={!disabled}
                        onChange={onSwitchChange}
                    />
                }
            />
            <StyledCardContent>
                {children}
            </StyledCardContent>
        </StyledCard>
    );
};

export default ChapterAlignment;
