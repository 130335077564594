import React from "react";

import { Box, Container, IconButton, Paper, Typography } from "@material-ui/core";
import BookmarkBorderRoundedIcon from "@material-ui/icons/BookmarkBorderRounded";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";
import Spacer from "components/Spacer";

import { selectPinnedLocation, selectSavedLocationDetails } from "modules/customer/tools/location/locationSlice";
import { showAddSavedLocation } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledPaper = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(Paper);

const StyledContainer = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    }
}))(Container);

const PinnedLocation: React.FC = () => {
    const dispatch = useAppDispatch();
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const savedLocationDetails = useAppSelector(selectSavedLocationDetails);

    const handleSaveLocationClick = () => {
        dispatch(showAddSavedLocation());
    };

    if (!pinnedLocation) {
        return null;
    }

    return (
        <StyledPaper elevation={0}>
            <StyledContainer maxWidth={false}>
                {savedLocationDetails ? (
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                            <BookmarkRoundedIcon />
                            <SimpleTooltip
                                placement={SimpleTooltipPlacement.Right}
                                title={
                                    <Typography variant="subtitle1" component="div">
                                        {savedLocationDetails.address}
                                    </Typography>
                                }>
                                <Typography variant="subtitle1" component="div" noWrap>
                                    &nbsp;&nbsp;{savedLocationDetails.name}
                                </Typography>
                            </SimpleTooltip>
                            <Typography variant="subtitle1" component="div" color="textSecondary" noWrap>
                                &nbsp;&nbsp;{`(${savedLocationDetails.isPublic ? "Public" : "Private"})`}
                            </Typography>
                        </Box>
                        {savedLocationDetails.notes && (
                            <Box paddingTop={1}>
                                <Typography variant="subtitle1" component="div">
                                    {savedLocationDetails.notes}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <>
                        <LocationOnOutlinedIcon />
                        <Typography variant="subtitle1" component="div" noWrap>
                            &nbsp;&nbsp;Near {pinnedLocation.address}
                        </Typography>
                        <Spacer />
                        &nbsp;&nbsp;
                        <SimpleTooltip title="Save location">
                            <IconButton size="small" onClick={handleSaveLocationClick}>
                                <BookmarkBorderRoundedIcon fontSize="medium" />
                            </IconButton>
                        </SimpleTooltip>
                    </>
                )}
            </StyledContainer>
        </StyledPaper>
    );
};

export default PinnedLocation;
