import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

import { selectCostTypes } from "modules/customer/insights/cost/costSlice";

import { CostType } from "modules/customer/insights/cost/costType";
import { SortDirection, stringSortExpression } from "utils/sortUtils";


export interface CorrelationMetric {
    displayName: string,
    fieldName: string
}

interface CostDriversState {
    isLoading: boolean,
    hasErrors: boolean,
    correlationCostType?: CostType,
    // correlationMetric?: CorrelationMetric
}

const initialState: CostDriversState = {
    isLoading: false,
    hasErrors: false,
    correlationCostType: undefined,
    // correlationMetric: undefined
};

interface LoadCostDriversResponse {
    correlationCostType?: CostType
}

const costDriversSlice = createSlice({
    name: "customer/inisghts/cost/costDrivers",
    initialState,
    reducers: {
        clearCorrelationCostType: (state) => {
            state.correlationCostType = initialState.correlationCostType;
        },
        setCorrelationCostType: (state, action: PayloadAction<CostType>) => {
            state.correlationCostType = action.payload;
        },
        // clearCorrelationMetric: (state) => {
        //     state.correlationMetric = initialState.correlationMetric;
        // },
        // setCorrelationMetric: (state, action: PayloadAction<CorrelationMetric>) => {
        //     state.correlationMetric = action.payload;
        // }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostDrivers.pending, (state: CostDriversState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.correlationCostType = initialState.correlationCostType;
        });
        builder.addCase(loadCostDrivers.rejected, (state: CostDriversState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.correlationCostType = initialState.correlationCostType;
        });
        builder.addCase(loadCostDrivers.fulfilled, (state: CostDriversState, action: PayloadAction<LoadCostDriversResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.correlationCostType = action.payload.correlationCostType;
        });
    }
});

export const {
    clearCorrelationCostType,
    setCorrelationCostType,
    // clearCorrelationMetric,
    // setCorrelationMetric
} = costDriversSlice.actions;

export const loadCostDrivers = createAppAsyncThunk(
    "customer/insights/cost/costDrivers/loadCostDrivers",
    async (arg, thunkAPI) => {
        try {
            await thunkAPI.dispatch(setupCube());
            const state = thunkAPI.getState();
            const costTypes = selectCostTypes(state);

            const sortedCostTypes = [...costTypes].sort((a, b) => stringSortExpression(a.name, b.name, SortDirection.ASC));
            const correlationCostType = sortedCostTypes.length !== 0 ? sortedCostTypes[0] : undefined;

            const response: LoadCostDriversResponse = {
                correlationCostType,
            };
            return response;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading CostDrivers."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearCostDrivers = (): AppThunk => (dispatch) => {
    dispatch(costDriversSlice.actions.clearCorrelationCostType());
    // dispatch(costDriversSlice.actions.clearCorrelationMetric());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.hasErrors;
};

export const selectCorrelationCostType = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.correlationCostType;
};

// export const selectCorrelationMetric = (state: RootState) => {
//     return state.customer.insights.cost.costDrivers.correlationMetric;
// };

export interface CostCorrelationByStore {
    storeName: string,
    costValue: number,
    correlationMetricValue: number
}

// export const selectCostCorrelationsByStore = createSelector(
//     selectIsLoading,
//     selectHasErrors,
//     (state: RootState) => selectCostReductionOpportunityByStore(state),
//     selectCorrelationCostType,
//     selectCorrelationMetric,
//     (costDriversIsLoading, costDriversHasErrors, costReductionOpportunityByStore, costType, correlationMetric) => {

//         const isLoading = costDriversIsLoading || costReductionOpportunityByStore.isLoading;
//         const hasErrors = costDriversHasErrors || costReductionOpportunityByStore.hasErrors;
//         const costCorrelationsByStore: DataWrapper<CostCorrelationByStore[]> = {
//             isLoading: isLoading,
//             hasErrors: hasErrors,
//             data: []
//         };
//         if (isLoading || hasErrors || costReductionOpportunityByStore.data.length === 0 || !costType || !correlationMetric) {
//             return costCorrelationsByStore;
//         }

//         const storesFilteredByCostType = costReductionOpportunityByStore.data.filter(item => item.costName === costType.name);

//         costCorrelationsByStore.data = storesFilteredByCostType.map(item => {
//             console.log(correlationMetric);
//             return {
//                 storeName: item.storeName ?? "",
//                 costValue: item.costValue,
//                 correlationMetricValue: item[correlationMetric.fieldName] ?? 0,
//             };
//         });
//         return costCorrelationsByStore;
//     }
// );

export default costDriversSlice;
