import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Store selection
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Use the tiles below to assess the headroom value for each store.
                    You can use the search field, filters and sort to help identify a
                    store of interest before continuing to the Store opportunities page.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
