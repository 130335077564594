import React from "react";

import { Box, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

interface FormLabelProps {
    text: string,
    tooltip?: string
}

const FormLabel: React.FC<FormLabelProps> = (props) => {
    const { text, tooltip } = props;
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
                {text}
            </Typography>
            &nbsp;&nbsp;
            {tooltip && (
                <SimpleTooltip
                    placement={SimpleTooltipPlacement.Right}
                    title={
                        <Typography variant="body2" component="div">
                            {tooltip}
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon fontSize="small" color="disabled" cursor="pointer" />
                </SimpleTooltip>
            )}
        </Box>
    );
};

export default FormLabel;
