import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import actions from "./actions";
import dateUtils from "utils/dateUtils";

import { selectStore, selectComparator, selectReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";

const getStoreVsComparatorRankedLastTwelveMonthsGrowth = () => async (dispatch, getState) => {
    dispatch(actions.getStoreVsComparatorRankedLastTwelveMonthsGrowthRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate]
                ]
            }],
            order: [
                ["F_Sales.SumLineValue", "asc"]
            ],
            dimensions: ["D_Store.StoreNaturalID", "D_Store.StoreName"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const priorTwelveMonths = resultSet.loadResponses[0].data.map(item => ({
            Store: item["D_Store.StoreNaturalID"],
            StoreName: item["D_Store.StoreName"],
            Sales: item["F_Sales.SumLineValue"]
        }));

        const twelveMonthsBeforePriorTwelveMonths = resultSet.loadResponses[1].data.map(item => ({
            Store: item["D_Store.StoreNaturalID"],
            StoreName: item["D_Store.StoreName"],
            Sales: item["F_Sales.SumLineValue"]
        }));
        
        const data = priorTwelveMonths.map(current => {
            const prior = twelveMonthsBeforePriorTwelveMonths.find(item => item.Store === current.Store);

            if (!prior) {
                return {
                    Store: current.Store,
                    Growth: 100,
                    storeName: current.StoreName
                };
            }
            if (prior.Sales !== current.Sales) { 
              return {
                Store: current.Store,
                Growth: (!!prior.Sales === false) ? 100 : 
                100 * ((current.Sales - prior.Sales) / prior.Sales),
                storeName: current.StoreName
              };
            }
            return {
                Store: current.Store,
                Growth: 0,
                storeName: current.StoreName
            };
        });

        const sortedData = data.sort((a, b) => {
            return b.Growth - a.Growth; //descending order - higher value gets lower rank
        });
      
        //Find rank for each store. Stores with same growth should be given same rank
        for (let i in sortedData) {
            sortedData[i].rank = 1 + sortedData.findIndex(item => {
                if (item.Growth === sortedData[i].Growth) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        const store = sortedData.filter(item => item.Store === selectedStoreID); //array as post MVP this will have multiple months
        const comparator = sortedData.filter(item => comparatorStoresIDs.includes(item.Store));

        //Rank of selected store value within store and comparator array -- for use with rag. (to be updated as applicable once there are months in the main array)
        const selectedStore = sortedData.find(item => item.Store === selectedStoreID);
        const rank = selectedStore.rank;
        
        const denominator = sortedData.length;

        dispatch(actions.getStoreVsComparatorRankedLastTwelveMonthsGrowthSuccess(store, comparator, rank, denominator));
    }
    catch (error) {
        dispatch(actions.getStoreVsComparatorRankedLastTwelveMonthsGrowthFailure());
        dispatch(logError("Error loading StoreVsComparatorRankedLastTwelveMonthsGrowth.", error));
    }
};

const operations = {
    getStoreVsComparatorRankedLastTwelveMonthsGrowth
};

export default operations;
