const GET_SUPPLY_AND_DEMAND_REQUEST = "spark/customer/insights/portfolio/areaHealth/supplyAndDemand/GET_SUPPLY_AND_DEMAND_REQUEST";
const GET_SUPPLY_AND_DEMAND_SUCCESS = "spark/customer/insights/portfolio/areaHealth/supplyAndDemand/GET_SUPPLY_AND_DEMAND_SUCCESS";
const GET_SUPPLY_AND_DEMAND_FAILURE = "spark/customer/insights/portfolio/areaHealth/supplyAndDemand/GET_SUPPLY_AND_DEMAND_FAILURE";

const types = {
    GET_SUPPLY_AND_DEMAND_REQUEST,
    GET_SUPPLY_AND_DEMAND_SUCCESS,
    GET_SUPPLY_AND_DEMAND_FAILURE
};

export default types;
