import React from "react";

import { Box, Typography } from "@material-ui/core";

import {
    GenerateRevenuePredictionStep,
    selectActiveGenerateRevenuePredictionStep
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";

const RightPanelTitle: React.FC = () => {
    const activeGenerateRevenuePredictionStep = useAppSelector(selectActiveGenerateRevenuePredictionStep);

    return (
        <>
            <Typography variant="h5" component="div">
                {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction && (
                    "Setup the prediction"
                )}
                {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.InputStoreParameters && (
                    "Input additional store parameters"
                )}
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction && (
                        `Select a group and model that best matches the anticipated behaviour or brand of this new
                        store. Sales area size is also required to inform the prediction model as it is a significant
                        driver of revenue. If you haven't identified a specific property yet, Dash has pre-populated
                        this with an average value based on your current estate.`
                    )}
                    {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.InputStoreParameters && (
                        `Improve the accuracy of your revenue prediction by providing more details about the store you
                        plan to open. The model you have selected will affect which details you can input below based
                        on what significantly influences that group of stores revenue.`
                    )}
                </Typography>
            </Box>
        </>
    );
};

export default RightPanelTitle;
