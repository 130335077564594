import React from "react";
import { Card, CardContent, Chip, Typography, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import useColourPalette from "components/visuals/useColourPalette";

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        width: theme.spacing(29)
    }
}));

const CatchmentAreaPenPortraitClassificationLegend = () => {
    const classes = useStyles();
    const theme = useTheme();
    const colourPalette = useColourPalette();
    return (
        <Card elevation={0} className={classes.card}>
            <CardContent>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[0]
                        }} />
                    &nbsp;Rural residents
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[1]
                        }} />
                    &nbsp;Cosmopolitans
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[2]
                        }} />
                    &nbsp;Ethnicity central
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[3]
                        }} />
                    &nbsp;Multicultural metropolitan
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[4]
                        }} />
                    &nbsp;Urbanites
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[5]
                        }} />
                    &nbsp;Suburbanites
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[6]
                        }} />
                    &nbsp;Constrained city dwellers
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.categorical[7]
                        }} />
                    &nbsp;Hard-pressed living
                </Typography>
                <Box display="flex">
                    <LocationOnOutlinedIcon fontSize="small" />
                    <Typography variant="body2">&nbsp;&nbsp;Selected store</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default CatchmentAreaPenPortraitClassificationLegend;
