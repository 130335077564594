import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import ConcentricCirclesMap from "components/visuals/maps/ConcentricCirclesMap";
import useColourPalette from "components/visuals/useColourPalette";

import StoreMarker from "./StoreMarker";
import StorePopup from "./StorePopup";
import MapLegend from "./MapLegend";

import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/localAreaCompetition";

const StorePositioningHotspotMap = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useSelector(selectMapboxConfiguration);
    const storePositioningHotspotMap = useSelector(state => selectors.storePositioningHotspotMap(state));
    const { loading, error, hotspot, stores } = storePositioningHotspotMap;
    const selectedStore = stores.find(store => store.type === "Selected store");
    const [storePopup, setStorePopup] = React.useState(null);

    const onMapClick = () => {
        setStorePopup(null);
    };

    return (
        <Box data-cy="store-positioning-hotspot">
            <ConcentricCirclesMap
                loading={loading}
                error={error}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                colours={colourPalette.concentricCircles}
                mapCenter={hotspot}
                onClick={onMapClick}
                downloadData={stores}
            >
                <MapLegend />
                {stores.map((store, index) =>
                    <StoreMarker
                        key={index}
                        store={store}
                        onMarkerClick={setStorePopup}
                    />
                )}
                <StorePopup
                    selectedStore={selectedStore}
                    store={storePopup}
                />
            </ConcentricCirclesMap>
        </Box>
    );
};

export default StorePositioningHotspotMap;
