import { combineReducers } from "redux";

import types from "./types";

const typesOfSalesGrowthEmpty = {
    loading: false,
    error: false,
    data: []
};

const typesOfSalesGrowth = (state = typesOfSalesGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_TYPES_OF_SALES_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                data: []
            };
        case types.GET_TYPES_OF_SALES_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                data: action.payload.data
            };
        case types.GET_TYPES_OF_SALES_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                data: []
            };
        default:
            return state;
    }
};


const reducer = combineReducers({
    typesOfSalesGrowth
});

export default reducer;
