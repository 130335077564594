import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useColourPalette from "components/visuals/useColourPalette";
import ComparisonBar from "components/visuals/ComparisonBar";
import numberFormatter from 'utils/numberFormatter';
import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/areaHealth/retailDensity";
import stringUtils from "utils/stringUtils";

const RetailDensityBulletChart = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const retailDensityVis = useSelector(state => selectors.retailDensityVis(state));
    const { loading, error, store, comparator } = retailDensityVis;

    const options = {
        chart: {
        },
        title: {
            text: `Stores per km&sup2; in the local area of ${selectedStore.name} vs ${selectedComparator.name}`
        },
        yAxis: [{
            min: 0,
            maxPadding: 0.3,
            gridLineWidth: 0,
            tickAmount: 10,
            lineWidth: 1,
            title: {
                text: "Stores per km&sup2;"
            }
        }],
          
        xAxis: {
            categories: [selectedStore.name, selectedComparator.name]
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    inside: false
                }
            },
            series: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: "#FFFFFF", 
                        fontSize: 14,
                        fontWeight: "normal",
                        textShadow: false,
                        textOutline: false
                    },
                    inside: false,
                    x: -0,
                    formatter: function () {
                        return `Stores per km&sup2;: ${numberFormatter.toDecimalPlaces(this.y, 0)}`;
                    },
                    overflow: "justify"
                }
            }
        },
          
        series: [{
            name: selectedStore.name,
            color: colourPalette.comparators[0],
            data: [{y: store}]
        }, {
            name: selectedComparator.name,
            color: colourPalette.comparators[1],
            data: [{y: comparator}]
        }],
        tooltip:{
            enabled: true,
            headerFormat: "<table>",
            pointFormatter: function () {
                const density = numberFormatter.toDecimalPlaces(this.y);
                const seriesName = this.series.name;
                const headerColour = this.series.color;
                return stringUtils.tooltipHTML([`Stores per km&sup2;: <b>${density}</b>`], {
                    header: seriesName,
                    headerFormatting: `color:${headerColour}`
                });
            },
            footerFormat: "</table>",
            useHTML: true
        },
    };

    return (
        <Box data-cy="retail-density-bullet-chart">
            <ComparisonBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default RetailDensityBulletChart;
