import React from "react";

import Centile from "pages/customer/tools/location/filters/targets/Centile";
import CentileMetric from "pages/customer/tools/location/filters/targets/CentileMetric";

import {
    selectAggregatedDirectCompetitors,
    selectCandidateComparatorStores,
    selectCandidateTarget,
    selectRetailCentresCentiles,
    selectStoreCategoryCentilesMetrics,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const ChangeInOpenStores: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const retailCentresCentiles = useAppSelector(selectRetailCentresCentiles);
    const comparatorStoresCentiles = candidateComparatorStores.map(store => {
        const retailCentreCentile = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id)?.areaHealth ?? 0;
        return {
            storeName: store.name,
            centileValue: retailCentreCentile
        };
    });
    const aggregatedDirectCompetitors = useAppSelector(selectAggregatedDirectCompetitors);
    const aggregatedDirectCompetitorCentiles = aggregatedDirectCompetitors.map(item => ({
        storeName: item.fascia,
        centileValue: item.areaHealthCentile
    }));
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const storeCategoryCentilesMetrics = useAppSelector(selectStoreCategoryCentilesMetrics);
    const centileMetric = storeCategoryCentilesMetrics.find(cm => cm.centile === candidateTarget?.areaHealth);
    const name = "Change in open stores";
    const description = "The year-on-year (%) change in the number of open stores.";
    const areaHealthCentileMetrics = [{
        name: "(%) change in the number of open stores",
        tooltip: "The year-on-year (%) change in the number of open stores in the local area.",
        value: numberFormatter.toPercentage(centileMetric?.netOpenings ?? 0, false)
    }];

    const handleSliderChange = (value: number) => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                areaHealth: value
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <Centile
            name={name}
            description={description}
            disabled={!candidateTarget.useAreaHealth}
            sliderValue={candidateTarget.areaHealth}
            onSliderChange={handleSliderChange}
            comparatorStoresCentiles={comparatorStoresCentiles}
            aggregatedDirectCompetitorCentiles={aggregatedDirectCompetitorCentiles}
            chartLowLabel="Decreasing number of stores"
            chartHighLabel="Increasing number of stores"
        >
            {areaHealthCentileMetrics.map((metric, index) =>
                <CentileMetric
                    key={index}
                    name={metric.name}
                    tooltip={metric.tooltip}
                    value={metric.value}
                    disabled={!candidateTarget.useAreaHealth}
                />
            )}
        </Centile>
    );
};

export default ChangeInOpenStores;
