import { combineReducers } from "redux";

import types from "./types";

const cogsPastYearEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const cogsPastYear = (state = cogsPastYearEmpty, action) => {
    switch (action.type) {
        case types.GET_COGS_PAST_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_COGS_PAST_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_COGS_PAST_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const grossProfitLastYearEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const grossProfitLastYear = (state = grossProfitLastYearEmpty, action) => {
    switch (action.type) {
        case types.GET_GROSS_PROFIT_LAST_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_GROSS_PROFIT_LAST_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_GROSS_PROFIT_LAST_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const grossProfitMarginLastYearEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const grossProfitMarginLastYear = (state = grossProfitMarginLastYearEmpty, action) => {
    switch (action.type) {
        case types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_GROSS_PROFIT_MARGIN_LAST_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const grossProfitMarginOverTimeEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const grossProfitMarginOverTime = (state = grossProfitMarginOverTimeEmpty, action) => {
    switch (action.type) {
        case types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_GROSS_PROFIT_MARGIN_OVER_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const grossProfitTrendEmpty = {
    loading: false,
    error: false,
    id: "gross-profit-trend",
    label: "Gross profit trend",
    status: "info",
    value: ""
};

const grossProfitTrend = (state = grossProfitTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_GROSS_PROFIT_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_GROSS_PROFIT_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_GROSS_PROFIT_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    cogsPastYear,
    grossProfitLastYear,
    grossProfitMarginLastYear,
    grossProfitMarginOverTime,
    grossProfitTrend
});

export default reducer;
