import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import About from "./about/About";
import Accounts from "./accounts/Accounts";
import Admins from "./admins/Admins";
import AuditLogs from "./auditLogs/AuditLogs";
import FrequentlyAskedQuestions from "./frequentlyAskedQuestions/FrequentlyAskedQuestions";
import Home from "./home/Home";
import Leads from "./leads/Leads";
import Users from "./users/Users";

import PageNotFound from "./PageNotFound";

const Router: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/accounts">
                <Accounts />
            </Route>
            <Route path="/admins">
                <Admins />
            </Route>
            <Route path="/audit">
                <AuditLogs />
            </Route>
            <Route path="/home">
                <Home />
            </Route>
            <Route path="/frequently-asked-questions">
                <FrequentlyAskedQuestions />
            </Route>
            <Route path="/leads">
                <Leads />
            </Route>
            <Route path="/users">
                <Users />
            </Route>
            <Route>
                <PageNotFound />
            </Route>
        </Switch>
    );
};

export default Router;
