import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Card, CardActionArea, CardActions, CardMedia, Typography, useMediaQuery } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-card-enabled"] ? theme.palette.quaternary.dark : theme.palette.background.insight,
        margin: "2px",
        "&:hover": {
            margin: (props: any) => props["data-is-card-enabled"] ? 0 : "2px",
            borderStyle: (props: any) => props["data-is-card-enabled"] ? "solid" : "none",
            borderWidth: (props: any) => props["data-is-card-enabled"] ? "2px" : 0,
            borderColor: (props: any) => props["data-is-card-enabled"] ? theme.palette.primary.main : "transparent",
            backgroundColor: (props: any) => props["data-is-card-enabled"] ? theme.palette.primary.main : "none"
        }
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        color: (props: any) => props["data-is-card-enabled"] ? theme.palette.common.white : theme.palette.text.disabled,
        "&:hover": {
            "& .MuiCardActionArea-focusHighlight": {
                opacity: 0
            }
        }
    }
}))(CardActionArea);

const StyledCardMedia = withStyles(() => ({
    root: {
        opacity: (props: any) => props["data-is-card-enabled"] ? 1 : 0.5
    }
}))(CardMedia);

const StyledCardActions = withStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1.5)
    }
}))(CardActions);

const StyledBox = withStyles(theme => ({
    root: {
        position: "absolute",
        top: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: (props: any) => !props["data-is-available"] || (props["data-is-mobile"] && !props["data-is-available-on-mobile"])
            ? theme.palette.secondary.main
            // @ts-ignore
            : theme.palette.tertiary.main,
        borderRadius: "0px 6px 6px 0px",
        color: (props: any) => !props["data-is-available"] || (props["data-is-mobile"] && !props["data-is-available-on-mobile"])
            ? theme.palette.common.white
            : theme.palette.common.black,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(3),
    }
}))(Box);

interface InsightCardProps {
    name: string,
    icon: React.ReactElement,
    url: string,
    image: string,
    alt: string,
    isAvailable: boolean,
    isAvailableOnMobile: boolean
}

const InsightCard: React.FC<InsightCardProps> = (props) => {
    const { name, icon, url, image, alt, isAvailable, isAvailableOnMobile } = props;
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const isDataProvided = userInfo.isDataProvided;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isCardEnabled = isAvailable && (isAvailableOnMobile || !isMobile) && isDataProvided;
    const [hover, setHover] = React.useState(false);
    const bannerText = (() => {
        if (!isAvailable) {
            return "Coming soon";
        }
        if (isMobile && !isAvailableOnMobile) {
            return "Coming soon on mobile";
        }
        if (!isDataProvided) {
            return "Upload data to access";
        }
        return "";
    })();

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <StyledCard
            elevation={0}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-is-card-enabled={isCardEnabled}
        >
            <StyledCardActionArea
                disabled={!isCardEnabled}
                // @ts-ignore
                component={NavLink}
                to={url}
                data-is-card-enabled={isCardEnabled}
            >
                <StyledCardMedia component="img" image={image} alt={alt} data-is-card-enabled={isCardEnabled}/>
                {!isCardEnabled && (
                    <StyledBox
                        data-is-mobile={isMobile}
                        data-is-available={isAvailable}
                        data-is-available-on-mobile={isAvailableOnMobile}
                    >
                        <LockRoundedIcon />
                        <Typography variant="subtitle2" style={{ paddingLeft: theme.spacing(1) }}>
                            {bannerText}
                        </Typography>
                    </StyledBox>
                )}
                <StyledCardActions>
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" width={theme.spacing(4)}>
                                {React.cloneElement(icon, {
                                    fontSize: "small",
                                    style: {
                                        color: (hover && isCardEnabled)
                                            ? theme.palette.text.primary
                                            // @ts-ignore
                                            : theme.palette.quaternary.light
                                    }
                                })}
                            </Box>
                            <Typography variant="subtitle1" component="div">
                                {name}
                            </Typography>
                            <Spacer />
                            {hover && isCardEnabled && (
                                <ArrowForwardIcon fontSize="small" />
                            )}
                        </Box>
                        <br />
                    </Box>
                </StyledCardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default InsightCard;
