import React from "react";

const RevenueOverTimeSubtitle: React.FC = () => {
    return (
        <>
            Get a topline view of your revenue performance. What is your total revenue in a given period? How is it
            changing over time?
        </>
    );
};

export default RevenueOverTimeSubtitle;
