import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";
import useColourPalette from "components/visuals/useColourPalette";

import Column from "components/visuals/Column";

import { operations, selectors } from "modules/customer/insights/performance/budget/revenueVsBudgetOverTime";
import stringUtils from "utils/stringUtils";

const SalesPerformanceVsBudgetOverTime = () => {
    const dispatch = useDispatch();
    const colourPalette = useColourPalette();
    const salesPerformanceVsBudgetOverTime = useSelector(state => selectors.salesPerformanceVsBudgetOverTime(state));
    const { loading, error, quarterlySales, quarterlyBudget } = salesPerformanceVsBudgetOverTime;

    let options = {};

    if (quarterlySales.length !== 0 && quarterlyBudget.length !== 0) {

        const salesData = (quarterlySales, forecastFlag) => {
            const salesWithDuplicates = quarterlySales.map(row => {
                return {
                    name: row.quarter,
                    y: (row.forecastFlag === forecastFlag) ? row.sales : null
                };
            });
            
            const salesWithoutDuplicates = Array.from(salesWithDuplicates.reduce((m, {name, y}) => 
                m.set(name, (m.get(name) || 0) + y), new Map()
            ), ([name, y]) => ({name, y})
            );

            const salesZerosAreNulls = salesWithoutDuplicates.map(row => {
                if (row.y === 0) {
                row.y = null;
            }
            return row;
            });

            return salesZerosAreNulls.sort((a, b) => {
            let aName = a.name;
            let bName = b.name;

            if (aName < bName) {
                return -1;
            } else if (aName > bName) {
                return 1;
            } 
            return 0;
            });
        };

        const historicSales = salesData(quarterlySales, 0);
        const forecastSales = salesData(quarterlySales, 1);

        const budget = quarterlyBudget.map(item => {
            return {
                name: item.quarter,
                y: item.budget
        };});
        const fy = quarterlyBudget[0]?.fy;

        const historicSeriesIndex = 2;
        const forecastSeriesIndex = 0;
        const budgetSeriesIndex = 1;

        options = {
            title: {
                text: "Historic and forecast revenue vs budgeted revenue over time",
                enabled: true
            },
            legend: {
                enabled: true
            },
            xAxis: {
                type: 'category',
                title: {
                    text: "FY " + fy
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: "Revenue"
                },
                labels: {
                    formatter: function() {return numberFormatter.toGBP(this.value, 0);}
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    },
                    stacking: 'normal'
                },
                column: {
                    stacking: 'normal'
                }
            },
            tooltip: {
                useHTML: true,
                headerFormat: null,
                footerFormat: null,
                shared: true,
                formatter: function() {
                    const points = this.points;
                    const series = points[0].series.chart.series;
                    const quarter = points[0].key;
                    const tooltipHeader = `FY ${fy} ${quarter}`;
                    const ytdSeries = series[historicSeriesIndex];
                    const ytgSeries = series[forecastSeriesIndex];
                    const budgetSeries = series[budgetSeriesIndex];

                    const tooltipCategories = [];
                    const tooltipCategoryFormatting = [];
                    const tooltipValues = [];

                    //Add actual revenue (if exists)
                    const ytdValue = ytdSeries.data.find(item => item.name === quarter)?.y;
                    if (ytdValue) {
                        tooltipCategories.push(ytdSeries.name);
                        tooltipCategoryFormatting.push(`color:${ytdSeries.color}`);
                        tooltipValues.push(numberFormatter.toGBP(ytdValue));
                    }

                    //Add forecast revenue (if exists)
                    const ytgValue = ytgSeries.data.find(item => item.name === quarter)?.y;
                    if (ytgValue) {
                        tooltipCategories.push(ytgSeries.name);
                        tooltipCategoryFormatting.push(`color:${ytgSeries.color}`);
                        tooltipValues.push(numberFormatter.toGBP(ytgValue));
                    }

                    //Add budget revenue
                    tooltipCategories.push(budgetSeries.name);
                    tooltipCategoryFormatting.push(`color:${budgetSeries.color}`);
                    tooltipValues.push(numberFormatter.toGBP(budgetSeries.data.find(item => item.name === quarter)?.y));

                    return `<table>${stringUtils.tooltipHTML(tooltipCategories, {
                        values: tooltipValues,
                        categoryFormattingArr: tooltipCategoryFormatting,
                        header: tooltipHeader
                    })}</table>`;
                }
            },
            series: [
                {
                    name: "Historic revenue",
                    data: historicSales,
                    stack: "sales",
                    index: historicSeriesIndex,
                    legendIndex: 0,
                    color: colourPalette.categorical[0]
                },
                {
                    name: "Budgeted revenue",
                    data: budget,
                    stack: "budget",
                    legendIndex: 2,
                    index: budgetSeriesIndex,
                    color: colourPalette.categorical[1]
                },
                {
                    name: "Forecast revenue",
                    data: forecastSales,
                    stack: "sales",
                    index: forecastSeriesIndex,
                    legendIndex: 1,
                    color: colourPalette.categorical[2]
                }
            ]
        };
    } else {
        options = {
            legend: {
                enabled: false
            },
            yAxis: {
                title: ""
            },
            xAxis: {
                title: ""
            },
            series: [
                {
                    data: []
                }
            ]
        };
    }

    React.useEffect(() => {
        dispatch(operations.getSalesPerformanceVsBudgetOverTime());
    }, [dispatch]);

    return (
        <Box data-cy="sales-performance-vs-budget-over-time">
            <Column loading={loading} error={error} options={options} />
        </Box>
    );
};

export default SalesPerformanceVsBudgetOverTime;
