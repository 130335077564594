import React from "react";
import { Box } from "@material-ui/core";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectStoreVsComparatorPerformance } from "modules/customer/insights/portfolioNew/overview/overviewSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";
import { median } from "mathjs";

const tooltipText = (pointName: string): string => {
    const text = (() => {
        switch (pointName) {
            case "Area health":
                return "Change in number of stores";
            case "Catchment":
                return "Catchment size (population)";
            case "Competition":
                return "Number of competitors within 5km";
            case "Footfall":
                return "Footfall level";
            case "Profit":
                return "Gross profit margin";
            case "Revenue":
                return "Revenue in the last year";
            default:
                return pointName;
        }
    })();

    return text;
};

const tooltipMetricValue = (pointName: string, revenue: number, profit: number,
    changeInNumberOfStores: number, catchmentSize: number, numberOfCompetitors: number, footfallLevel: number): string => {
    const metricValue = (() => {
        switch (pointName) {
            case "Area health":
                return numberFormatter.toPercentage(changeInNumberOfStores, false, 1);
            case "Catchment":
                return numberFormatter.toDecimalPlaces(catchmentSize, 1);
            case "Competition":
                return numberOfCompetitors.toString();
            case "Footfall":
                return numberFormatter.toDecimalPlaces(footfallLevel, 1);
            case "Profit":
                return numberFormatter.toPercentage(profit, false, 0);
            case "Revenue":
                return numberFormatter.toGBP(revenue, 1);
            default:
                return pointName;
        }
    })();

    return metricValue;
};

const StoreVsComparatorPerformance = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorPerformance = useAppSelector(selectStoreVsComparatorPerformance);

    const comparatorStores = selectedComparator?.getStores();
    const medianRevenue = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.revenue)) : 0;
    const medianProfit = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.grossProfitMargin)) : 0;
    const medianChangeInNumberOfStores = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.changeInNumberOfStores)) : 0;
    const medianCatchmentSize = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.catchmentSize)) : 0;
    const medianNumberOfCompetitors = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.numberOfCompetitors)) : 0;
    const medianFootfallLevel = comparatorStores === undefined ? 0 : comparatorStores.length > 0 ? median(comparatorStores.map(x => x.footfallLevel)) : 0;

    const options = {
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        colors: colourPalette.comparators,
        legend: {
            enabled: true
        },
        tooltip: {
            headerFormat: ``,
            footerFormat: ``,
            useHTML: true,
            formatter: function (): any {
                // @ts-ignore
                const points = this.points;
                const series = points[0].series;
                const seriesName = series.name;
                const seriesColor = series.color;
                const xAxisTitle = tooltipText(points[0].key);
                const yAxisTitle = "Score";

                const otherSeries = points[1].series;
                const otherSeriesName = otherSeries?.name?.replace("(%)", "");
                const otherSeriesColor = otherSeries?.color;

                const categoryArr = [seriesName, xAxisTitle, yAxisTitle, otherSeriesName, xAxisTitle, yAxisTitle];
                const categoryFormatArr = [`color:${seriesColor};font-weight:bold`, "", "", `color:${otherSeriesColor};font-weight:bold`, "", ""];
                const valueArr = ["",
                    selectedStore === undefined ? "0" : tooltipMetricValue(points[0].key, selectedStore.revenue, selectedStore.grossProfitMargin,
                                                                           selectedStore.changeInNumberOfStores, selectedStore.catchmentSize,
                                                                           selectedStore.numberOfCompetitors, selectedStore.footfallLevel),
                    numberFormatter.toDecimalPlaces(points[0]?.point?.custom?.tooltipValue, 1),
                    "",
                    tooltipMetricValue(points[1].key, medianRevenue, medianProfit, medianChangeInNumberOfStores, medianCatchmentSize,
                                       medianNumberOfCompetitors, medianFootfallLevel),
                    numberFormatter.toDecimalPlaces(points[1]?.point?.custom?.tooltipValue, 1)
                ];

                const headerRow = points[0].key;

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    values: valueArr,
                    header: headerRow,
                    categoryFormattingArr: categoryFormatArr
                })}</table>`;
            }
        },
        series: [{
            name: selectedStore?.name,
            data: [
                { name: "Revenue", y: storeVsComparatorPerformance?.store.revenue },
                { name: "Profit", y: storeVsComparatorPerformance?.store.profit },
                { name: "Area health", y: storeVsComparatorPerformance?.store.areaHealth },
                { name: "Catchment", y: storeVsComparatorPerformance?.store.catchment },
                { name: "Competition", y: storeVsComparatorPerformance?.store.competition },     
                { name: "Footfall", y: storeVsComparatorPerformance?.store.footfall },
            ],
            pointPlacement: "on",
            zIndex: 1
        }, {
            name: selectedComparator?.name,
            data: [
                { name: "Revenue", y: storeVsComparatorPerformance?.comparator.revenue },
                { name: "Profit", y: storeVsComparatorPerformance?.comparator.profit },
                { name: "Area health", y: storeVsComparatorPerformance?.comparator.areaHealth },
                { name: "Catchment", y: storeVsComparatorPerformance?.comparator.catchment },
                { name: "Competition", y: storeVsComparatorPerformance?.comparator.competition },
                { name: "Footfall", y: storeVsComparatorPerformance?.comparator.footfall },
            ],
            pointPlacement: "on"
        }]
    };

    return (
        <Box data-cy="store-vs-comparator-performance">
            <Radar loading={storeVsComparatorPerformance?.loading} error={storeVsComparatorPerformance?.error} options={options} dataAdditionPercent={10} />
        </Box>
    );
};

export default StoreVsComparatorPerformance;
