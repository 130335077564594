import { createSelector } from "reselect";

const salesHistory = (state) => {
    return state.customer.insights.performance.sales.revenueOverTime.salesHistory;
};

const historicOverallCompanySalesTrend = createSelector(
    salesHistory,
    (salesHistory) => {
        const rag = {
            loading: false,
            error: false,
            id: "historic-overall-company-sales-trend",
            label: "Historic overall company sales trend",
            status: "info",
            value: ""
        };
        if (salesHistory.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (salesHistory.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const values = salesHistory.values;
        if (values.length === 0) {
            return rag;
        }
        
        const recentDate = new Date(values[values.length -1].date);
        const oneYearDate = new Date(recentDate.getFullYear() - 1, recentDate.getMonth(), recentDate.getDate());
        const twoYearDate = new Date(oneYearDate.getFullYear() - 1, oneYearDate.getMonth(), oneYearDate.getDate());
        const threeYearDate = new Date(twoYearDate.getFullYear() - 1, twoYearDate.getMonth(), twoYearDate.getDate());
        const threeYearDatePlusOneMonth = new Date(threeYearDate.getFullYear(), threeYearDate.getMonth() + 1, threeYearDate.getDate());

        const hasThreeYearsOfData = new Date(values[0].date) < threeYearDatePlusOneMonth ? true : false;
        const initialYearDates = (hasThreeYearsOfData) ? [threeYearDate, twoYearDate] : [twoYearDate, oneYearDate];
        const numberOfYears = hasThreeYearsOfData ? 3 : 2;

        const initialYear = values.reduce((sales, value) => {
            sales = (initialYearDates[0] < new Date(value.date) && new Date(value.date) <= initialYearDates[1]) ? sales + value.sales : sales;
            return sales;
        }, 0);
        const recentYear = values.reduce((sales, value) => {
            sales = (oneYearDate < new Date(value.date) && new Date(value.date) <= recentDate) ? sales + value.sales : sales;
            return sales;
        }, 0);

        let x = 0;
        if (initialYear === 0 && recentYear > 0) {
            x = 3.5;
        } else {
            //CAGR calculation:
            const cagrCalculation = ((recentYear / initialYear) ** (1 / numberOfYears) - 1);
            x = cagrCalculation * 100;
        }

        //Rag indicator:
        if (x > 3) {
            rag.status = "success";
            rag.value = "Overall sales are trending upwards";
        } else if (x >= 0) {
            rag.status = "warning";
            rag.value = "Overall sales are stagnant";
        } else {
            rag.status = "error";
            rag.value = "Overall sales are trending downwards";
        }
        return rag;
    }
);

const salesYTD = (state) => {
    return state.customer.insights.performance.sales.revenueOverTime.salesYTD;
};

const totalSalesLatestFullMonth = (state) => {
    return state.customer.insights.performance.sales.revenueOverTime.totalSalesLatestFullMonth;
};

const avgSalesLatestFullMonth = (state) => {
    return state.customer.insights.performance.sales.revenueOverTime.avgSalesLatestFullMonth;
};

const historicShortTermCompanySalesTrend = (state) => {
    return state.customer.insights.performance.sales.revenueOverTime.historicShortTermCompanySalesTrend;
};

const selectors = {
    salesYTD,
    totalSalesLatestFullMonth,
    avgSalesLatestFullMonth,
    historicOverallCompanySalesTrend,
    historicShortTermCompanySalesTrend,
    salesHistory
};

export default selectors;
