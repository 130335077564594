import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { selectStore, selectReferenceDateNew } from "modules/customer/insights/portfolio/portfolioSlice";
import actions from "./actions";

const getStoreRevenueVsPY = () => async (dispatch, getState) => {
    dispatch(actions.getStoreRevenueVsPYRequest());
    try {
        const state = getState();
        const store = selectStore(state);
        const referenceDate = selectReferenceDateNew(state);
        const lastYearEnd = referenceDate.endOf("day");
        const lastYearStart = lastYearEnd.minus({ years: 1 }).plus({ days: 1 }).startOf("day");
        const previousYearEnd = lastYearStart.minus({ days: 1 }).endOf("day");
        const previousYearStart = previousYearEnd.minus({ years: 1 }).plus({ days: 1 }).startOf("day");

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [lastYearStart, lastYearEnd],
                    [previousYearStart, previousYearEnd]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [store.id]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));

        const priorTwelveMonthsValue = resultSet.loadResponses[0].data[0] ?
            resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"] : 0;
        const twelveMonthsBeforePriorTwelveMonthsValue = resultSet.loadResponses[1].data[0] ?
            resultSet.loadResponses[1].data[0]["F_Sales.SumLineValue"] : 0;
        const percentageDifference = (twelveMonthsBeforePriorTwelveMonthsValue === 0) ? 100 : 100 * ((priorTwelveMonthsValue - twelveMonthsBeforePriorTwelveMonthsValue) / twelveMonthsBeforePriorTwelveMonthsValue);

        dispatch(actions.getStoreRevenueVsPYSuccess(priorTwelveMonthsValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getStoreRevenueVsPYFailure());
        dispatch(logError("Error loading StoreRevenueVsPY.", error));
    }
};

const getStoreForecastRevenueVsPY = () => async (dispatch, getState) => {
    dispatch(actions.getStoreForecastRevenueVsPYRequest());
    try {
        const state = getState();
        const store = selectStore(state);
        const referenceDate = selectReferenceDateNew(state);
        const lastYearEnd = referenceDate.endOf("day");
        const lastYearStart = lastYearEnd.minus({ years: 1 }).plus({ days: 1 }).startOf("day");
        const nextYearEnd = referenceDate.plus({ years: 1 }).endOf("day");
        const nextYearStart = nextYearEnd.minus({ years: 1 }).plus({ days: 1 }).startOf("day");

        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [nextYearStart, nextYearEnd],
                    [lastYearStart, lastYearEnd]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [store.id]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));

        const next12MonthsValue = resultSet.loadResponses[0].data[0] ?
            resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"] : 0;
        const priorTwelveMonthsValue = resultSet.loadResponses[1].data[0] ?
            resultSet.loadResponses[1].data[0]["F_SalesForecast.SumLineValue"] : 0;
        let percentageDifference = 0;
        if (next12MonthsValue !== 0 || priorTwelveMonthsValue !== 0) {
            percentageDifference = (priorTwelveMonthsValue === 0) ? 100 : 100 * ((next12MonthsValue - priorTwelveMonthsValue) / priorTwelveMonthsValue);
        }


        dispatch(actions.getStoreForecastRevenueVsPYSuccess(next12MonthsValue, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getStoreForecastRevenueVsPYFailure());
        dispatch(logError("Error loading StoreForecastRevenueVsPY.", error));
    }
};

const getSalesHistory = () => async (dispatch, getState) => {
    dispatch(actions.getSalesHistoryRequest());
    try {
        const state = getState();
        const store = selectStore(state);
        const referenceDate = selectReferenceDateNew(state);
        const lastYearStart = referenceDate.minus({ years: 1 }).plus({ days: 1 }).startOf("day");
        const nextYearEnd = referenceDate.plus({ years: 1 }).endOf("day");

        const query = {
            measures: ["F_SalesForecast.SumLineValue", "F_SalesForecast.MaxForecastFlag"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [lastYearStart, nextYearEnd]
            }],
            order: [
                ["D_Date.Date", "asc"]
            ],
            dimensions: ["D_Date.WeekNo", "D_Date.Date"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [store.id]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query));
        const values = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            forecastFlag: item["F_SalesForecast.MaxForecastFlag"],
            week: item["D_Date.WeekNo"],
            date: item["D_Date.Date"]
        }));
        dispatch(actions.getSalesHistorySuccess(values));
    }
    catch (error) {
        dispatch(actions.getSalesHistoryFailure());
        dispatch(logError("Error loading SalesHistory.", error));
    }
};

const getStoreHistoricSalesTrend = () => async (dispatch, getState) => {
    dispatch(actions.getStoreHistoricSalesTrendRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const currentDate = selectReferenceDateNew(state);
        const referenceDate = currentDate.minus({ weeks: 1 }).set({ weekday: 6 });
        const priorTwelveMonthsStartDate = referenceDate.minus({ weeks: 52 }).plus({ days: 1 });
        const twelveMonthsBeforePriorTwelveMonthsStartDate = referenceDate.minus({ weeks: 104 }).plus({ days: 1 });
        const twelveMonthsBeforePriorTwelveMonthsEndDate = referenceDate.minus({ weeks: 52 });

        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate],
                    [priorTwelveMonthsStartDate, referenceDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [selectedStoreID]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));

        const twelveMonthsBeforePriorTwelveMonthsValue = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"] ?
            resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"] : 0;
        const priorTwelveMonthsValue = resultSet.loadResponses[1].data[0] ?
            resultSet.loadResponses[1].data[0]["F_Sales.SumLineValue"] : 0;

        let status = "";
        let value = "";

        if (twelveMonthsBeforePriorTwelveMonthsValue) {
            const cagr = ((priorTwelveMonthsValue / twelveMonthsBeforePriorTwelveMonthsValue) - 1) * 100;
            if (cagr > 3) {
                status = "success";
                value = `Revenue in the last year grew significantly compared to the year prior for your ${selectedStoreSelector.name} store.`;
            } else if (cagr < -3) {
                status = "error";
                value = `Revenue in the last year declined significantly compared to the year prior for your ${selectedStoreSelector.name} store.`;
            } else {
                status = "warning";
                value = `Revenue in the last year was broadly consistent with the year prior for your  ${selectedStoreSelector.name} store.`;
            }
        } else {
            status = "noData";
            value = `This indicator isn’t available because your ${selectedStoreSelector.name} store has less than a year’s worth of revenue data uploaded 
            to Dash. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Sales dataset and 
            refresh your company’s Analytics.`;
        }

        dispatch(actions.getStoreHistoricSalesTrendSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getStoreHistoricSalesTrendFailure());
        dispatch(logError("Error loading StoreHistoricSalesTrend.", error));
    }
};

const getStoreFutureSalesTrend = () => async (dispatch, getState) => {
    dispatch(actions.getStoreFutureSalesTrendRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;

        const currentDate = selectReferenceDateNew(state);
        const referenceDate = currentDate.minus({ weeks: 1 }).set({ weekday: 7 });
        const next12MonthsEndDate = referenceDate.plus({ weeks: 52 }).minus({ days: 1 });
        const prior12MonthsStartDate = referenceDate.minus({ weeks: 52 });
        const prior12MonthsEndDate = referenceDate.minus({ days: 1 });

        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [prior12MonthsStartDate, prior12MonthsEndDate],
                    [referenceDate, next12MonthsEndDate]]
            }],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [selectedStoreID]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query));
        const prior12MonthsValue = resultSet.loadResponses[0].data ?
            resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"] : 0;
        const next12MonthsValue = resultSet.loadResponses[1].data ?
            resultSet.loadResponses[1].data[0]["F_SalesForecast.SumLineValue"] : 0;

        const cagr = prior12MonthsValue ? ((next12MonthsValue / prior12MonthsValue) - 1) * 100 : 100;

        let status = "";
        let value = "";

        if (next12MonthsValue) {
            if (cagr > 3) {
                status = "success";
                value = `Revenue in the next year should grow significantly compared to the last year for your ${selectedStoreSelector.name} store.`;
            } else if (cagr < -3) {
                status = "error";
                value = `Revenue in the next year should decline significantly compared to the last year for your ${selectedStoreSelector.name} store.`;
            } else {
                status = "warning";
                value = `Revenue in the next year should be broadly consistent with the last year for your ${selectedStoreSelector.name} store.`;
            }
        } else {
            status = "noData";
            value = `This indicator isn’t available because your ${selectedStoreSelector.name} store has insufficient revenue data to 
            generate a forecast. To evaluate this insight, someone with permission to upload data from your company will need to 
            edit/upload the Sales dataset and refresh your company’s Analytics.`;
        }

        dispatch(actions.getStoreFutureSalesTrendSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getStoreFutureSalesTrendFailure());
        dispatch(logError("Error loading StoreFutureSalesTrend.", error));
    }
};

const operations = {
    getStoreRevenueVsPY,
    getStoreForecastRevenueVsPY,
    getSalesHistory,
    getStoreHistoricSalesTrend,
    getStoreFutureSalesTrend
};

export default operations;
