import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";
import Page from "components/customer/Page";

import Guidance from "./Guidance";
import Dataset from "./Dataset";
import Refresh from "./Refresh";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import {
    getData,
    selectDataRefreshVisibility,
    selectDatasets,
    selectUploadVisibility,
    verifyDontShowGuidanceAgain
} from "modules/customer/data/dataSlice";

const StyledCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        "&:last-child": {
            paddingBottom: theme.spacing(0)
        }
    }
}))(CardContent);

const Data: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const datasets = useAppSelector(selectDatasets);
    const uploadVisibility = useAppSelector(selectUploadVisibility);
    const dataRefreshVisibility = useAppSelector(selectDataRefreshVisibility);
    const preventRefresh = uploadVisibility.isVisible || dataRefreshVisibility.isVisible;

    React.useEffect(() => {
        dispatch(verifyDontShowGuidanceAgain());
        dispatch(getData(true));
    }, [dispatch]);

    React.useEffect(() => {
        if (preventRefresh) {
            return;
        }
        const thirtySecondsInMilliseconds = 30 * 1000;
        const interval = setInterval(() => {
            dispatch(getData(false));
        }, thirtySecondsInMilliseconds);
        return () => {
            clearInterval(interval);
        };
    }, [dispatch, preventRefresh]);

    return (
        <Page dataCy="pages-customer-data">
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            {userInfo.companyDisplayName}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h3" component="div">
                                Data
                            </Typography>
                            <Guidance />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledCard elevation={0}>
                            <StyledCardContent>
                                <Grid container>
                                    <Grid container item xs={6}>
                                        <Grid item xs={2}>
                                            <Box color="text.disabled">
                                                <Typography variant="h6" component="div">
                                                    Dataset
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box paddingX={2} color="text.disabled">
                                                <Typography variant="h6" component="div">
                                                    Status
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box color="text.disabled">
                                                <Typography variant="h6" component="div">
                                                    New data
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Grid item xs={1} />
                                        <Grid item xs={4}>
                                            <Box color="text.disabled">
                                                <Typography variant="h6" component="div">
                                                    Current data
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledCardContent>
                        </StyledCard>
                    </Grid>
                    {datasets.map(dataset =>
                        <Grid key={dataset.id} item xs={12}>
                            <Dataset dataset={dataset} />
                        </Grid>
                    )}
                </Grid>
                <Spacer />
                <br />
                <Refresh />
            </Box>
        </Page>
    );
};

export default Data;
