import { operations as customerProfilesOperations } from "./customerProfiles";
import { operations as footFallOperations } from "./footfall";
import { operations as demographicBreakdownOperations } from "./demographicBreakdown";

const getCatchmentData = () => async (dispatch) => {
    dispatch(customerProfilesOperations.getPenPortraitsSearch());
    dispatch(customerProfilesOperations.getCustomerProfiles());
    dispatch(customerProfilesOperations.getMarketCategorySpend());
    dispatch(customerProfilesOperations.getPopulation());
    dispatch(footFallOperations.getFootfall());
    dispatch(demographicBreakdownOperations.getDemographics());
};

const operations = {
    getCatchmentData
};

export default operations;
