import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import arrayUtils from "utils/arrayUtils";
import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";

const getAvgBasketSizeCMPM = () => async (dispatch, getState) => {
    dispatch(actions.getAvgBasketSizeCMPMRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const monthBeforeLatestFullMonthStartDate = dateUtils.monthBeforeLatestFullMonthStartDate(currentDate);
        const monthBeforeLatestFullMonthEndDate = dateUtils.monthBeforeLatestFullMonthEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineQuantity", "F_Sales.CountDistinctSalesOrderNo"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [latestFullMonthStartDate, latestFullMonthEndDate],
                    [monthBeforeLatestFullMonthStartDate, monthBeforeLatestFullMonthEndDate]
                ]

            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const latestFullMonthProducts = resultSet.loadResponses[0].data[0]["F_Sales.SumLineQuantity"];
        const latestFullMonthBaskets = resultSet.loadResponses[0].data[0]["F_Sales.CountDistinctSalesOrderNo"];
        const latestFullMonthAvgProductsInBasket = (latestFullMonthBaskets === 0)
            ? 0
            : latestFullMonthProducts / latestFullMonthBaskets;
        const monthBeforeLatestFullMonthProducts = resultSet.loadResponses[1].data[0]["F_Sales.SumLineQuantity"];
        const monthBeforeLatestFullMonthBaskets = resultSet.loadResponses[1].data[0]["F_Sales.CountDistinctSalesOrderNo"];
        const monthBeforeLatestFullMonthAvgProductsInBasket = (monthBeforeLatestFullMonthBaskets === 0)
            ? 0
            : monthBeforeLatestFullMonthProducts / monthBeforeLatestFullMonthBaskets;

        const percentageDifference = (monthBeforeLatestFullMonthAvgProductsInBasket === 0)
            ? 0
            : 100 * ((latestFullMonthAvgProductsInBasket - monthBeforeLatestFullMonthAvgProductsInBasket) / monthBeforeLatestFullMonthAvgProductsInBasket);

        const currentMonth = [dateUtils.monthName(latestFullMonthStartDate), dateUtils.dateUTC(latestFullMonthStartDate).getFullYear()];
        const pastMonth = [dateUtils.monthName(monthBeforeLatestFullMonthStartDate), dateUtils.dateUTC(monthBeforeLatestFullMonthStartDate).getFullYear()];

        dispatch(actions.getAvgBasketSizeCMPMSuccess(latestFullMonthAvgProductsInBasket, percentageDifference, currentMonth, pastMonth));
    }
    catch (error) {
        dispatch(actions.getAvgBasketSizeCMPMFailure());
        dispatch(logError("Error loading AvgBasketSizeCMPM.", error));
    }
};

const getAvgBasketValueCMPM = () => async (dispatch, getState) => {
    dispatch(actions.getAvgBasketValueCMPMRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const monthBeforeLatestFullMonthStartDate = dateUtils.monthBeforeLatestFullMonthStartDate(currentDate);
        const monthBeforeLatestFullMonthEndDate = dateUtils.monthBeforeLatestFullMonthEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue", "F_Sales.CountDistinctSalesOrderNo"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [latestFullMonthStartDate, latestFullMonthEndDate],
                    [monthBeforeLatestFullMonthStartDate, monthBeforeLatestFullMonthEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const latestFullMonthSales = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"];
        const latestFullMonthBaskets = resultSet.loadResponses[0].data[0]["F_Sales.CountDistinctSalesOrderNo"];

        const latestFullMonthAvgBasketValue = (latestFullMonthBaskets === 0)
            ? 0
            : latestFullMonthSales / latestFullMonthBaskets;

        const monthBeforeLatestFullMonthSales = resultSet.loadResponses[1].data[0]["F_Sales.SumLineValue"];
        const monthBeforeLatestFullMonthBaskets = resultSet.loadResponses[1].data[0]["F_Sales.CountDistinctSalesOrderNo"];
        const monthBeforeLatestFullMonthAvgBasketValue = (monthBeforeLatestFullMonthBaskets === 0)
            ? 0
            : monthBeforeLatestFullMonthSales / monthBeforeLatestFullMonthBaskets;

        const percentageDifference = (monthBeforeLatestFullMonthAvgBasketValue === 0)
            ? 0
            : 100 * ((latestFullMonthAvgBasketValue - monthBeforeLatestFullMonthAvgBasketValue) / monthBeforeLatestFullMonthAvgBasketValue);

        const currentMonth = [dateUtils.monthName(latestFullMonthStartDate), dateUtils.dateUTC(latestFullMonthStartDate).getFullYear()];
        const pastMonth = [dateUtils.monthName(monthBeforeLatestFullMonthStartDate), dateUtils.dateUTC(monthBeforeLatestFullMonthStartDate).getFullYear()];

        dispatch(actions.getAvgBasketValueCMPMSuccess(latestFullMonthAvgBasketValue, percentageDifference, currentMonth, pastMonth));
    }
    catch (error) {
        dispatch(actions.getAvgBasketValueCMPMFailure());
        dispatch(logError("Error loading AvgBasketValueCMPM.", error));
    }
};

const getCategoryCMSales = () => async (dispatch, getState) => {
    dispatch(actions.getCategoryCMSalesRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);

        const queryProduct = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                granularity: "month",
                dateRange: [latestFullMonthStartDate, latestFullMonthEndDate]
            }],
            dimensions: ["D_ProductCategory.ProductCategory1", "D_ProductCategory.ProductCategory2", "D_Product.ProductNaturalID", "D_Product.ProductName"]
        };
        const resultSetProduct = await dispatch(cubeLoad(queryProduct));
        const productSales = resultSetProduct.loadResponses[0].data.map(item => ({
            y: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"],
            name: item[ "D_Product.ProductName"]
        })).sort((a,b) => {
            return b.y - a.y;
        });

        const categorySalesCalculation = productSales.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                y: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.y += currentObj.y;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const categorySales = Array.from(categorySalesCalculation.values());

        const subCategorySalesCalculation = productSales.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                y: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.y += currentObj.y;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const subCategorySales = Array.from(subCategorySalesCalculation.values());

        const drilldownData = arrayUtils.drilldownDataFromArrayOfSeries([subCategorySales, productSales], "id");
        const currentMonth = [dateUtils.monthName(latestFullMonthStartDate), dateUtils.dateUTC(latestFullMonthStartDate).getFullYear()];

        dispatch(actions.getCategoryCMSalesSuccess(categorySales, drilldownData, currentMonth));

    }
    catch (error) {
        dispatch(actions.getCategoryCMSalesFailure());
        dispatch(logError("Error loading CategoryCMSales.", error));
    }
};

const getCategoryCMSalesGrowth = () => async (dispatch, getState) => {
    dispatch(actions.getCategoryCMSalesGrowthRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const smlyLatestFullMonthStartDate = dateUtils.smlyLatestFullMonthStartDate(currentDate);
        const smlyLatestFullMonthEndDate = dateUtils.smlyLatestFullMonthEndDate(currentDate);

        const queryProduct = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [latestFullMonthStartDate, latestFullMonthEndDate],
                    [smlyLatestFullMonthStartDate, smlyLatestFullMonthEndDate]
                ]
            }],
            dimensions: ["D_ProductCategory.ProductCategory1", "D_ProductCategory.ProductCategory2", "D_Product.ProductNaturalID", "D_Product.ProductName"]
        };
        const resultSetProduct = await dispatch(cubeLoad(queryProduct));
        const latestFullMonthDataProduct = resultSetProduct.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"],
            name: item["D_Product.ProductName"]
        }));
        const smlyDataProduct = resultSetProduct.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"]
        }));
        const productGrowth = latestFullMonthDataProduct.map(current => {
            const previous = smlyDataProduct.find(item => (item.productCategory === current.productCategory && item.id === current.id && item.productId === current.productId));
            let y = 0;
            if (previous && previous.sales !== 0) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        });

        const latestFullMonthDataCalculation = latestFullMonthDataProduct.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                sales: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.sales += currentObj.sales;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const latestFullMonthData = Array.from(latestFullMonthDataCalculation.values());
        const smlyDataCalculation = smlyDataProduct.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                sales: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.sales += currentObj.sales;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const smlyData = Array.from(smlyDataCalculation.values());
        let productCategoryGrowth = latestFullMonthData.map(current => {
            const previous = smlyData.find(item => item.name === current.name);
            let y = 0;
            if (previous && previous.sales !== 0) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        });

        const latestFullMonthDataSubCategoryCalculation = latestFullMonthDataProduct.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                sales: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.sales += currentObj.sales;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const latestFullMonthDataSubCategory = Array.from(latestFullMonthDataSubCategoryCalculation.values());
        const smlyDataSubCategoryCalculation = smlyDataProduct.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                sales: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.sales += currentObj.sales;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const smlyDataSubCategory = Array.from(smlyDataSubCategoryCalculation.values());
        const productSubCategoryGrowth = latestFullMonthDataSubCategory.map(current => {
            const previous = smlyDataSubCategory.find(item => (item.id === current.id && item.name === current.name));
            let y = 0;
            if (previous && previous.sales !== 0) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        }).sort((a,b) => {
            return b.y - a.y;
        });

        const drilldownData = arrayUtils.drilldownDataFromArrayOfSeries([productSubCategoryGrowth, productGrowth], "id");
        const currentMonth = [dateUtils.monthName(latestFullMonthStartDate), dateUtils.dateUTC(latestFullMonthStartDate).getFullYear()];
        const pastMonth = [dateUtils.monthName(smlyLatestFullMonthStartDate), dateUtils.dateUTC(smlyLatestFullMonthStartDate).getFullYear()];

        dispatch(actions.getCategoryCMSalesGrowthSuccess(productCategoryGrowth, drilldownData, currentMonth, pastMonth));
    }
    catch (error) {
        dispatch(actions.getCategoryCMSalesGrowthFailure());
        dispatch(logError("Error loading CategoryCMSalesGrowth.", error));
    }
};

const getCategoryYTDSales = () => async (dispatch, getState) => {
    dispatch(actions.getCategoryYTDSalesRequest());
    try {
        const state = getState();
        const financialYearToDateEndDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(financialYearToDateEndDate, fyStart);

        const queryProduct = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearToDateEndDate]
            }],
            dimensions: ["D_ProductCategory.ProductCategory1", "D_ProductCategory.ProductCategory2", "D_Product.ProductNaturalID", "D_Product.ProductName"]
        };
        const resultSetProduct = await dispatch(cubeLoad(queryProduct));
        const productSales = resultSetProduct.loadResponses[0].data.map(item => ({
            y: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"],
            name: item["D_Product.ProductName"]
        })).sort((a,b) => {
            return b.y - a.y;
        });

        const categorySalesCalculation = productSales.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                y: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.y += currentObj.y;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const categorySales = Array.from(categorySalesCalculation.values());

        const subCategorySalesCalculation = productSales.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                y: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.y += currentObj.y;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const subCategorySales = Array.from(subCategorySalesCalculation.values());

        const drilldownData = arrayUtils.drilldownDataFromArrayOfSeries([subCategorySales, productSales], "id");

        dispatch(actions.getCategoryYTDSalesSuccess(categorySales, drilldownData));

    }
    catch (error) {
        dispatch(actions.getCategoryYTDSalesFailure());
        dispatch(logError("Error loading CategoryYTDSales.", error));
    }
};

const getCategoryYTDSalesGrowth = () => async (dispatch, getState) => {
    dispatch(actions.getCategoryYTDSalesGrowthRequest());
    try {
        const state = getState();
        const financialYearToDateEndDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearStartDate = dateUtils.previousFinancialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearToDateEndDate = dateUtils.previousFinancialYearToDateEndDate(financialYearToDateEndDate);

        const queryProduct = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [financialYearStartDate, financialYearToDateEndDate],
                    [previousFinancialYearStartDate, previousFinancialYearToDateEndDate]
                ]
            }],
            dimensions: ["D_ProductCategory.ProductCategory1", "D_ProductCategory.ProductCategory2", "D_Product.ProductNaturalID", "D_Product.ProductName"]
        };
        const resultSetProduct = await dispatch(cubeLoad(queryProduct));
        const fytdDataProduct = resultSetProduct.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"],
            name: item["D_Product.ProductName"]
        }));
        const pytdDataProduct = resultSetProduct.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            id: item["D_ProductCategory.ProductCategory2"],
            productId: item["D_Product.ProductNaturalID"]
        }));
        const productGrowth = fytdDataProduct.map(current => {
            const previous = pytdDataProduct.find(item => (item.productCategory === current.productCategory && item.id === current.id && item.productId === current.productId));
            let y = 100;
            if (previous?.sales) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        }).sort((a,b) => {
            return b.y - a.y;
        });

        const fytdDataCalculation = fytdDataProduct.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                sales: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.sales += currentObj.sales;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const fytdData = Array.from(fytdDataCalculation.values());
        const pydtCalculation = pytdDataProduct.reduce((map, currentObj) => {
            const productCategory = currentObj.productCategory;
            const currentCat = map.get(productCategory) || {
                sales: 0,
                name: productCategory,
                drilldown: productCategory
            };
            currentCat.sales += currentObj.sales;
            map.set(productCategory, currentCat);
            return map;
        }, new Map());
        const pytdData = Array.from(pydtCalculation.values());
        const productCategoryGrowth = fytdData.map(current => {
            const previous = pytdData.find(item => item.name === current.name);
            let y = 100;
            if (previous?.sales) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        });

        const fytdDataSubCategoryCalculation = fytdDataProduct.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                sales: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.sales += currentObj.sales;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const fytdDataSubCategory = Array.from(fytdDataSubCategoryCalculation.values());
        const pytdDataSubCategoryCalculation = pytdDataProduct.reduce((map, currentObj) => {
            const id = currentObj.id;
            const currentSubCat = map.get(id) || {
                sales: 0,
                id: currentObj.productCategory,
                name: id,
                drilldown: id
            };
            currentSubCat.sales += currentObj.sales;
            map.set(id, currentSubCat);
            return map;
        }, new Map());
        const pytdDataSubCategory = Array.from(pytdDataSubCategoryCalculation.values());
        const productSubCategoryGrowth = fytdDataSubCategory.map(current => {
            const previous = pytdDataSubCategory.find(item => (item.id === current.id && item.name === current.name));
            let y = 100;
            if (previous?.sales) {
                y = ((current.sales - previous.sales) / previous.sales) * 100;
            }

            return {
                ...current,
                y
            };
        });

        const drilldownData = arrayUtils.drilldownDataFromArrayOfSeries([productSubCategoryGrowth, productGrowth], "id");

        dispatch(actions.getCategoryYTDSalesGrowthSuccess(productCategoryGrowth, drilldownData));
    }
    catch (error) {
        dispatch(actions.getCategoryYTDSalesGrowthFailure());
        dispatch(logError("Error loading CategoryYTDSalesGrowth.", error));
    }
};

const operations = {
    getAvgBasketSizeCMPM,
    getAvgBasketValueCMPM,
    getCategoryCMSales,
    getCategoryCMSalesGrowth,
    getCategoryYTDSales,
    getCategoryYTDSalesGrowth
};

export default operations;
