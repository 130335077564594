import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectHasErrors,
    selectIsLoading,
    selectWeightedSpend
} from "modules/customer/tools/location/spend/spendSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const WeightedSpend: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const weightedSpend = useAppSelector(selectWeightedSpend);
    const valueFormatted = numberFormatter.toGBP(weightedSpend, 1);
    const label = "Weighted yearly spend in the catchment area of the selected location";

    return (
        <Box width="100%" height="100%" data-cy="weighted-spend">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default WeightedSpend;
