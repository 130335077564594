const GET_SALES_TO_PROFIT_REQUEST = "spark/customer/insights/portfolio/profit/netProfit/GET_SALES_TO_PROFIT_REQUEST";
const GET_SALES_TO_PROFIT_SUCCESS = "spark/customer/insights/portfolio/profit/netProfit/GET_SALES_TO_PROFIT_SUCCESS";
const GET_SALES_TO_PROFIT_FAILURE = "spark/customer/insights/portfolio/profit/netProfit/GET_SALES_TO_PROFIT_FAILURE";

const types = {
    GET_SALES_TO_PROFIT_REQUEST,
    GET_SALES_TO_PROFIT_SUCCESS,
    GET_SALES_TO_PROFIT_FAILURE
};

export default types;
