import React from "react";
import Highcharts from "highcharts";
import { Info } from "luxon";

import { Box, Typography } from "@material-ui/core";

import Heatmap from "components/visuals/Heatmap";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import {
    selectAverageDailyFootfall,
    selectHasErrors,
    selectIsLoading
} from "modules/customer/insights/portfolioNew/footfall/footfallSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const AverageDailyFootfallLevel: React.FC = () => {
    const colourPalette = useColourPalette();
    const data = useAppSelector(selectAverageDailyFootfall);

    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);

    let weekdays: string[] = Info.weekdays("short", { locale: "en-gb" });
    weekdays = [weekdays.pop() ?? "Sunday"].concat(weekdays);  //Start on Sunday to match SQL's first day of week functionality

    const timeRanges = [
        "01:00 - 03:59",
        "04:00 - 06:59",
        "07:00 - 09:59",
        "10:00 - 12:59",
        "13:00 - 15:59",
        "16:00 - 18:59",
        "19:00 - 21:59",
        "22:00 - 00:59"
    ];

    const options: Highcharts.Options = {
        yAxis: {
            categories: weekdays,
            reversed: true,
            title: {
                text: undefined
            }
        },
        xAxis: {
            categories: timeRanges
        },
        colorAxis: {
            minColor: "#27233E",
            maxColor: colourPalette.comparators[0],
            max: 250,
            tickPositions: [0, 250],
            labels: {
                formatter: function() {
                    return this.value === 0 ? "Low footfall" : "High footfall";
                }
            }
        },
        tooltip: {
            headerFormat: ``,
            footerFormat: ``,
            useHTML: true,
            formatter: function () {
                const y = this.y;
                const tooltipHeader = (typeof (y) === "number") ? `${weekdays[y]} ${timeRanges[this.point.x]}` : ``;
                return `<table>${stringUtils.tooltipHTML(["Average footfall"],
                    {
                        header: tooltipHeader,
                        values: [numberFormatter.toDecimalPlaces(this.point.value, 1)],
                        valueFormatting: `color:${colourPalette.comparators[0]}`
                    }
                )}</table>`;
            }
        },
        series: [{
            type: "heatmap",
            data: data.map(item => {
                return [
                    item.timeRangeIndex,
                    item.weekdayIndex,
                    item.averageFootfall
                ];
            }),
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return numberFormatter.toDecimalPlaces(this.point.value, 0);

                }
            }
        }],
        navigation: {
            buttonOptions: {
                verticalAlign: 'bottom',
                y: -40
            }
        }
    };

    return (
        <Box data-cy="average-daily-footfall-level">
            <Typography variant="h6" component="div" gutterBottom>
                Average footfall level by day and time
            </Typography>
            <Heatmap loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default AverageDailyFootfallLevel;
