import React from "react";
import { Grid } from "@material-ui/core";

import AccommodationTypeIndex from "./AccommodationTypeIndex";
import AgeIndex from "./AgeIndex";
import ChildrenIndex from "./ChildrenIndex";
import EducationIndex from "./EducationIndex";
import HouseholdCompIndex from "./HouseholdCompIndex";
import HouseholdIncomeIndex from "./HouseholdIncomeIndex";
import RepresentationOfBestDemographics from "./RepresentationOfBestDemographics";
import TenureIndex from "./TenureIndex";

const DemographicBreakdown = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <AgeIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <EducationIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <AccommodationTypeIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <HouseholdCompIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <HouseholdIncomeIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <TenureIndex />
            </Grid>
            <Grid item xs={12} md={4}>
                <ChildrenIndex />
            </Grid>
            <Grid item xs={12}>
                <RepresentationOfBestDemographics />
            </Grid>
        </Grid>
    );
};

export default DemographicBreakdown;
