import { RefObject } from "react";

export const scrollIntoViewByRef = (ref?: RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth"
    });
};

export const scrollIntoViewById = (elementId: string) => {
    const element = document.getElementById(elementId);
    element?.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth"
    });
};
