import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const useStyles = makeStyles(theme => ({
    green: {
        color: theme.palette.success.main
    },
    red: {
        color: theme.palette.error.main
    }
}));

const DemographicBreakdownSubtitle: React.FC = () => {
    const classes = useStyles();
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            <p>
                Evaluate how the demography in the catchment area of your {store?.name} store differs from {comparator?.name}.
                Understand who your customers are and what makes them different. This knowledge is vital for understanding how 
                to adapt your product range, target your marketing, and arrange your store layout. Armed with this information, 
                you can make these big decisions with confidence.
            </p>
            <p>
                Each chart focuses on a particular area of your {store?.name} store's demography, for example, age structure. 
                This area is divided into various categories, such as 0 to 15 or 16 to 24. The chart then shows the proportion of 
                each category within your {store?.name} store's catchment area and indexes this value relative to {comparator?.name}.
            </p>
            <ul>
                <li>
                    <p>
                        A positive index value means the category is over-represented in your {store?.name} store's catchment 
                        area compared to {comparator?.name}. This is shown visually by a 
                        <span className={classes.green}> GREEN BAR.</span>
                    </p>
                </li>
                <li>
                    <p>
                        A negative index value means the category is under-represented and is shown visually by a 
                        <span className={classes.red}> RED BAR.</span>
                    </p>
                </li>
            </ul>
        </>
    );
};

export default DemographicBreakdownSubtitle;
