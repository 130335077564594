import { combineReducers } from "redux";

import accounts from "./accounts";
import admins from "./admins";
import auditLogs from "./auditLogs";
import frequentlyAskedQuestions from "./frequentlyAskedQuestions";
import leads from "./leads";
import users from "./users";

const reducer = combineReducers({
    accounts,
    admins,
    auditLogs,
    frequentlyAskedQuestions,
    leads,
    users
});

export default reducer;
