import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectYoYRawNetStoreOpenings
} from "modules/customer/tools/location/areaHealth/areaHealthSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

const YoYRawNetStoreOpenings: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const comparators = useAppSelector(selectComparatorsByChapter);
    const yearOnYearRawNetStoreOpenings = useAppSelector(selectYoYRawNetStoreOpenings);
    const retailCentreValue = yearOnYearRawNetStoreOpenings.retailCentre;
    const retailCentreSignal = retailCentreValue > 0 ? "+" : "";
    const comparatorValue = yearOnYearRawNetStoreOpenings.comparator;
    const comparatorSignal = comparatorValue > 0 ? "+" : "";
    const difference = retailCentreValue - comparatorValue;

    return (
        <Box width="100%" height="100%" data-cy="yoy-raw-net-store-openings">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {retailCentreSignal}{retailCentreValue}
                    </Typography>
                }
                targetLabel="Year-on-year change in the number of stores within the local area of the selected location"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparatorSignal}{comparatorValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparators?.areaHealth.storeName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {difference}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default YoYRawNetStoreOpenings;
