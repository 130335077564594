import { ResultSet } from "@cubejs-client/core";
import { createAppAsyncThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { DateTime } from "luxon";

export class YearlyCosts {
    public readonly storeID: string;
    // public readonly date: DateTime;
    public readonly costName: string;
    public readonly costValue: number;
    public readonly isPropertyCost: boolean;
    public readonly isStaffCost: boolean;

    constructor(
        storeID: string,
        // date: DateTime,
        costName: string,
        costValue: number,
        isPropertyCost: boolean,
        isStaffCost: boolean
    ) {
        this.storeID = storeID;
        // this.date = date;
        this.costName = costName;
        this.costValue = costValue;
        this.isPropertyCost = isPropertyCost;
        this.isStaffCost = isStaffCost;
    }
}


export const loadYearlyCosts = createAppAsyncThunk<
    YearlyCosts[],
    { selectedStore: Store | undefined, comparatorStores: Store[] | undefined, costReferenceDate: DateTime }
>(
    "customer/insights/portfolio/loadYearlyCosts",
    async ({ selectedStore, comparatorStores, costReferenceDate }, thunkAPI) => {
        try {
            if (!selectedStore || !comparatorStores) {
                return [];
            }

            const comparatorStoreIDs = comparatorStores.map(store => store.id);
            const selectedAndComparatorStoreIDs = [selectedStore.id].concat(...comparatorStoreIDs);

            const priorTwelveMonthsToReferenceDate = costReferenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

            const query = {
                measures: ["F_Cost.SumCostValue"],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
                }],
                dimensions: ["D_Store.StoreNaturalID", "D_Account.AccountName"],
                filters: [{
                    member: "D_Store.StoreNaturalID",
                    operator: "equals",
                    values: selectedAndComparatorStoreIDs
                }]
            };

            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;
            return resultSet.rawData().map(row =>
                new YearlyCosts(
                    row["D_Store.StoreNaturalID"],
                    row["D_Account.AccountName"],
                    Number(row["F_Cost.SumCostValue"]),
                    (String(row["D_Account.AccountName"]).includes("Property")),
                    (row["D_Account.AccountName"] === "Labour"),
                )
            );
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading YearlyCosts.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
