import React from "react";

import { Box, Button } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { clearInsights, clearPinnedLocation, clearSavedLocationDetails } from "modules/customer/tools/location/locationSlice";
import { useAppDispatch } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const Explore: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const handleClick = () => {
        dispatch(clearPinnedLocation());
        dispatch(clearSavedLocationDetails());
        dispatch(clearInsights());
    };

    return (
        <Box position="absolute" margin={2} zIndex={theme.zIndex.drawer}>
            <StyledButton
                variant="outlined"
                size="medium"
                color="default"
                disableElevation
                startIcon={<ArrowBackRoundedIcon />}
                onClick={handleClick}
                data-cy="btn-explore"
            >
                Explore
            </StyledButton>
        </Box>
    );
};

export default Explore;
