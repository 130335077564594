import { combineReducers } from "redux";

import types from "./types";

const numberOfHouseholdsEmpty = {
    loading: false,
    error: false,
    store: 0,
    comparator: []
};

const numberOfHouseholds = (state = numberOfHouseholdsEmpty, action) => {
    switch (action.type) {
        case types.GET_NUMBER_OF_HOUSEHOLDS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: 0,
                comparator: []
            };
        case types.GET_NUMBER_OF_HOUSEHOLDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_NUMBER_OF_HOUSEHOLDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: 0,
                comparator: []
            };
        default:
            return state;
    }
};

const marketShareOverTimeEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const marketShareOverTime = (state = marketShareOverTimeEmpty, action) => {
    switch (action.type) {
        case types.GET_MARKET_SHARE_OVER_TIME_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_MARKET_SHARE_OVER_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator,
            };
        case types.GET_MARKET_SHARE_OVER_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const openingsAndClosuresEmpty = {
    loading: false,
    error: false,
    openDirect: [],
    closeDirect: [],
    openOther: [],
    closeOther: []
};

const openingsAndClosures = (state = openingsAndClosuresEmpty, action) => {
    switch (action.type) {
        case types.GET_OPENINGS_AND_CLOSURES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                openDirect: [],
                closeDirect: [],
                openOther: [],
                closeOther: []
            };
        case types.GET_OPENINGS_AND_CLOSURES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                openDirect : action.payload.openDirect,
                closeDirect : action.payload.closeDirect,
                openOther : action.payload.openOther,
                closeOther : action.payload.closeOther,
            };
        case types.GET_OPENINGS_AND_CLOSURES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                openDirect: [],
                closeDirect: [],
                openOther: [],
                closeOther: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    numberOfHouseholds,
    marketShareOverTime,
    openingsAndClosures
});

export default reducer;
