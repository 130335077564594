import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

import { useAppSelector } from "store";
import {
    selectWeightedPopulationInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

const WeightedPopulationInCatchmentArea: React.FC = () => {
    const colourPalette = useColourPalette();
    const comparatorsByChapter = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparatorsByChapter?.demographics.storeName;
    const weightedPopulationInCatchmentArea = useAppSelector(selectWeightedPopulationInCatchmentArea);
    const { isLoading, hasErrors, retailCentre: retailCentreValue, comparator: comparatorValue } = weightedPopulationInCatchmentArea;
    const difference = retailCentreValue - comparatorValue;

    return (
        <Box width="100%" height="100%" data-cy="weighted-population-in-catchment-area">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {numberFormatter.toDecimalPlaces(retailCentreValue, 1)}
                    </Typography>
                }
                targetLabel="Weighted population in catchment area of the selected location"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {numberFormatter.toDecimalPlaces(comparatorValue, 1)}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparatorName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {numberFormatter.toDecimalPlaces(difference, 1)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default WeightedPopulationInCatchmentArea;
