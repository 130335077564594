import React from "react";

import AreaHealthMap from "./areaHealth/Map";
import CannibalisationMap from "./cannibalisation/Map";
import CompetitionMap from "./competition/Map";
import DemographicsMap from "./demographics/Map";
import FootfallMap from "./footfall/Map";
import LandingMap from "./landing/Map";
import Overview from "./overview/Map";
import SpendMap from "./spend/Map";
import SpendNewMap from "./spendNew/Map";

import { useAppSelector } from "store";
import {
    selectIsInsightVisible,
    selectCurrentChapter,
    LocationChapter
} from "modules/customer/tools/location/locationSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";

const Map: React.FC = () => {
    const isInsightVisible = useAppSelector(selectIsInsightVisible);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;
    const currentChapter = useAppSelector(selectCurrentChapter);

    if (!isInsightVisible) {
        return (
            <LandingMap />
        );
    }

    switch (currentChapter) {
        case LocationChapter.Overview:
            return <Overview />;
        case LocationChapter.Demographics:
            return <DemographicsMap />;
        case LocationChapter.Spend:
            return enableSpendNew ? < SpendNewMap /> : <SpendMap />;
        case LocationChapter.Competition:
            return <CompetitionMap />;
        case LocationChapter.AreaHealth:
            return <AreaHealthMap />;
        case LocationChapter.Footfall:
            return <FootfallMap />;
        case LocationChapter.Cannibalisation:
            return <CannibalisationMap />;
        default:
            return <></>;
    }
};

export default Map;
