import { createSelector } from "reselect";

const salesPerformanceVsBudget = (state) => {
    return state.customer.insights.performance.budget.revenueVsBudget.salesPerformanceVsBudget;
};

const salesYTDPlusSalesYTGVsBudget = createSelector(
    salesPerformanceVsBudget,
    (salesPerformanceVsBudget) => {
        const rag = {
            loading: false,
            error: false,
            id: "sales-ytd-plus-sales-ytg-vs-budget",
            label: "Sales YTD plus sales YTG vs budget",
            status: "info",
            value: ""
        };

        if (salesPerformanceVsBudget.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (salesPerformanceVsBudget.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const salesFYTD = salesPerformanceVsBudget.salesFYTD;
        const salesFYTG = salesPerformanceVsBudget.salesFYTG;
        const budgetFY = salesPerformanceVsBudget.budgetFY;
        const FYlabel = salesPerformanceVsBudget.FYlabel;

        if ((budgetFY === 0) && (FYlabel.length === 0)) {
            rag.status = "noData";
            rag.value = "This indicator isn't available because it requires your company's Budget data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Budget dataset and refresh your company's analytics.";
            return rag;
        }

        const budgetTarget = (budgetFY === 0) ? 0 : ((salesFYTD + salesFYTG) - budgetFY) / (budgetFY) * 100;

        if (budgetTarget > 15) {
            rag.value = "Based on actual and forecast revenue in this financial year, your company should exceed the revenue budget comfortably.";
            rag.status = "success";
        } else if (budgetTarget < -15) {
            rag.value = "Based on actual and forecast revenue in this financial year, your company should struggle to reach the revenue budget.";
            rag.status = "error";
        } else {
            rag.value = "Based on actual and forecast revenue in this financial year, your company is broadly on track to meet the revenue budget.";
            rag.status = "warning";
        }
        return rag;
    }
);

const selectors = {
    salesPerformanceVsBudget,
    salesYTDPlusSalesYTGVsBudget
};

export default selectors;
