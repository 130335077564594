import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Assess the competitive landscape around your {store?.name} store, gauging local market prominence and customer choice.
            Evaluate the balance of competing stores against consumer spend to refine your store's market strategy.
            Utilise these insights to enhance advertising efficacy, optimise pricing, and tailor product offerings, fortifying your competitive edge and catalysing growth.
        </>
    );
};

export default Subtitle;
