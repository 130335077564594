import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CostDriversSubchapter from "./costDrivers/CostDrivers";
import Correlations from "./correlations/Correlations";

const CostDrivers: React.FC = () => {
    return (
        <Chapter
            title="Cost drivers"
            subtitle="Understand the factors driving cost fluctuations across your estate, uncovering patterns and outliers to target inefficiencies and optimise spending."
            dataCy="cost-drivers-chapter"
        >
            <Subchapter
                title="Cost drivers"
                subtitle='Uncover the internal and external factors driving your costs. Explore correlations and their relative impact to understand the "why" behind fluctuations, helping you identify areas for more effective cost control.'
                dataCy="cost-drivers-subchapter"
            >
                <CostDriversSubchapter />
            </Subchapter>
            <Subchapter
                title="Correlations"
                subtitle="Spot trends and outliers across your stores. Compare costs at an individual store level to reveal patterns in spending and identify unique behaviours within your estate. Use these insights to optimise costs, target inefficiencies, and drive strategic improvements."
                dataCy="correlations-subchapter"
            >
                <Correlations />
            </Subchapter>
        </Chapter>
    );
};

export default CostDrivers;
