import React from "react";
import PropTypes from "prop-types";

import { Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const Section = (props) => {
    const { direction, title, paragraph1, paragraph2, image, alt } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    return (
        <Grid container spacing={6} direction={direction} alignItems="center">
            <Grid item xs={12} md={6}>
                <StyledCard square elevation={0}>
                    <CardMedia component="img" image={image} alt={alt} />
                </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h3" component="div" align={align}>
                    {title}
                </Typography>
                <br />
                <Typography variant="h4" component="div" align={align}>
                    {paragraph1}
                </Typography>
                <br />
                <Typography variant="h4" component="div" align={align}>
                    {paragraph2}
                </Typography>
            </Grid>
        </Grid>
    );
};

Section.propTypes = {
    direction: PropTypes.oneOf(["row", "row-reverse"]).isRequired,
    title: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

Section.defaultProps = {
    direction: "row"
};

export default Section;
