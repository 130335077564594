import { combineReducers } from "redux";

import types from "./types";

const storeVsComparatorRevVsCompEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const storeVsComparatorRevVsComp = (state = storeVsComparatorRevVsCompEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_STORE_VS_COMPARATOR_REV_VS_COMP_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const revenueVsCompetitionScoreEmpty = {
    loading: false,
    error: false,
    id: "revenue-vs-competition-score",
    label: "Revenue vs competition score",
    status: "info",
    value: ""
};

const revenueVsCompetitionScore = (state = revenueVsCompetitionScoreEmpty, action) => {
    switch (action.type) {
        case types.GET_REVENUE_VS_COMPETITION_SCORE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_REVENUE_VS_COMPETITION_SCORE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_REVENUE_VS_COMPETITION_SCORE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    storeVsComparatorRevVsComp,
    revenueVsCompetitionScore
});

export default reducer;
