import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import bullet from "highcharts/modules/bullet.js";
import dumbbell from "highcharts/modules/dumbbell.js";
import drilldown from "highcharts/modules/drilldown.js";
import _ from "lodash";
import networkgraph from "highcharts/modules/networkgraph.js";
import annotations from "highcharts/modules/annotations.js";
import NoDataToDisplay from "highcharts/modules/no-data-to-display.js";
import accessibility from "highcharts/modules/accessibility";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";
import Progress from "./Progress";
import Error from "./Error";
import useColourPalette from "./useColourPalette";

require ("highcharts/modules/exporting")(Highcharts);
require ("highcharts/modules/offline-exporting")(Highcharts);
require ("highcharts/modules/export-data")(Highcharts);
require ("highcharts/modules/full-screen");

HighchartsMore(Highcharts);
bullet(Highcharts);
drilldown(Highcharts);
networkgraph(Highcharts);
annotations(Highcharts);
dumbbell(Highcharts);
NoDataToDisplay(Highcharts);
accessibility(Highcharts);

(function (factory) {

    if (typeof module === "object" && module.exports) {
        module.exports = factory;
    } else {
        factory(Highcharts);
    }
}(function (H) {
    let rel = H.relativeLength;

    H.wrap(H.seriesTypes.column.prototype, "translate", function (proceed) {
        let options = this.options,
            topMargin = options.topMargin || 0,
            bottomMargin = options.bottomMargin || 0;

        proceed.call(this);

        this.points.forEach(function (point) {
            let shapeArgs = point.shapeArgs,
                w = shapeArgs.width,
                h = shapeArgs.height,
                x = shapeArgs.x,
                y = shapeArgs.y;

            // Get the radius
            let rTopLeft, rTopRight, rBottomRight, rBottomLeft;
            if (point.y < 0) {
                rTopLeft = rel(options.borderRadiusBottomLeft || 0, w);
                rTopRight = rel(options.borderRadiusBottomRight || 0, w);
                rBottomRight = rel(options.borderRadiusTopLeft || 0, w);
                rBottomLeft = rel(options.borderRadiusTopRight || 0, w);
            } else {
                rTopLeft = rel(options.borderRadiusTopLeft || 0, w);
                rTopRight = rel(options.borderRadiusTopRight || 0, w);
                rBottomRight = rel(options.borderRadiusBottomRight || 0, w);
                rBottomLeft = rel(options.borderRadiusBottomLeft || 0, w);
            }

            if (rTopLeft || rTopRight || rBottomRight || rBottomLeft) {
                let maxR = Math.min(w, h) / 2;

                const forceRounding = (options?.custom?.forceRounding === true) ? true : false;

                if (options.stacking === "normal" && !forceRounding) {

                    if (point.stackY === point.stackTotal && point.stackY !== undefined) {
                        if (point.y >= 0 && point.y !== point.stackY) {
                            rBottomRight = 0;
                            rBottomLeft = 0;
                        } else if (point.y < 0 && point.y !== point.stackY) {
                            rTopRight = 0;
                            rTopLeft = 0;
                        }
                    } else if (point.y < 0 && point.y === point.stackY) {
                        rBottomLeft = 0;
                        rBottomRight = 0;
                    } else if (point.y === point.stackY) {
                        rTopRight = 0;
                        rTopLeft = 0;
                    } else {
                        // no radius
                        rBottomRight = 0;
                        rBottomLeft = 0;
                        rTopRight = 0;
                        rTopLeft = 0;
                    }
                } else {
                    if (rTopLeft > maxR) {
                        rTopLeft = maxR;
                    }

                    if (rTopRight > maxR) {
                        rTopRight = maxR;
                    }

                    if (rBottomRight > maxR) {
                        rBottomRight = maxR;
                    }

                    if (rBottomLeft > maxR) {
                        rBottomLeft = maxR;
                    }
                }

                // Preserve the box for data labels
                point.dlBox = point.shapeArgs;

                point.shapeType = "path";
                point.shapeArgs = {
                    d: [
                        ["M", x + rTopLeft, y + topMargin],
                        // top side
                        ["L", x + w - rTopRight, y + topMargin],
                        // top right corner
                        ["C", x + w - rTopRight / 2, y, x + w, y + rTopRight / 2, x + w, y + rTopRight],
                        // right side
                        ["L", x + w, y + h - rBottomRight],
                        // bottom right corner
                        ["C", x + w, y + h - rBottomRight / 2, x + w - rBottomRight / 2, y + h, x + w - rBottomRight, y + h + bottomMargin],
                        // bottom side
                        ["L", x + rBottomLeft, y + h + bottomMargin],
                        // bottom left corner
                        ["C", x + rBottomLeft / 2, y + h, x, y + h - rBottomLeft / 2, x, y + h - rBottomLeft],
                        // left side
                        ["L", x, y + rTopLeft],
                        // top left corner
                        ["C", x, y + rTopLeft / 2, x + rTopLeft / 2, y, x + rTopLeft, y],
                        ["Z"]
                    ]
                };
            }
        });
    });
}));


const ChartBase = (props) => {
    const { dataCy, loading, error, options: customOptions, constructorType, setChart } = props;
    const theme = useTheme();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const colourPalette = useColourPalette();
    const boxRef = React.useRef(null);
    const chartRef = React.useRef(null);

    useEffect(() => {
        if (setChart) {
            setChart(chartRef.current.chart);
        }
    }, [setChart]);

    const globalOptions = {
        lang: {
            thousandsSep: ",",
            numericSymbols: ["k", "m", "bn", "tn"],
            drillUpText: "< Back",
            noData: "No data to display"
        }
    };

    const staticOptions = {
        chart: {
            backgroundColor: theme.palette.background.insight,
            style: {
                fontFamily: "Open Sans"
            }
        },
        lang: {
            drillUpText: "< Back"
        },
        title: {
            style: {
                color: theme.palette.common.white,
            },
            align: "left",
            text: ""
        },
        subtitle: {
            style: {
                color: theme.palette.common.white,
            },
            text: ""
        },
        legend: {
            itemStyle: {
                color: theme.palette.common.white,
                fontSize: "12px",
                fontWeight: "normal"
            },
            itemHoverStyle: {
                color: theme.palette.common.white,
                fontSize: "12px",
                fontWeight: "bold"
            },
            itemHiddenStyle: {
                color: theme.palette.text.disabled,
                fontSize: "12px",
                fontWeight: "normal"
            }
        },
        plotOptions: {},
        credits: {
            enabled: false,
        },
        colors: colourPalette.categorical,
        xAxis: {
            title: {
                style: {
                    color: theme.palette.common.white
                },
            },
            labels: {
                style: {
                    color: theme.palette.common.white,
                }
            },
        },
        yAxis: {
            labels: {
                style: {
                    color: theme.palette.common.white,
                }
            },
            title: {
                style: {
                    color: theme.palette.common.white
                }
            },
            gridLineColor: "#646174",
            gridLineWidth: "1px"
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: theme.palette.common.white,
                textDecoration: "none"
            },
            activeDataLabelStyle: {
                color: theme.palette.common.white,
                textDecoration: "none"
            },
            breadcrumbs: {
                format: "Back",
                floating: true,
                buttonTheme: {
                    fill: theme.palette.background.insight,
                    padding: 8,
                    paddingLeft: 18,
                    paddingRight: 18,
                    "stroke-width": 1,
                    stroke: "white",
                    r: 17,
                    style: {
                        color: "white",
                    },
                    states: {
                        hover: {
                            fill: theme.palette.background.insight,
                        }
                    }
                },
                position: {
                    align: "right",
                    verticalAlign: "bottom"
                },
                showFullPath: false
            }
        },
        exporting: {
            enabled: featureFlags.enableCustomerHighchartsExporting,
            fallbackToServer: false,
            buttons: {
                contextButton: {
                    enabled: true,
                    menuItems: [
                        "viewFullscreen",
                        "separator",
                        "downloadPNG",
                        "downloadJPEG",
                        "separator",
                        'downloadCSV',
                        'downloadXLS',
                        'viewData'
                    ],
                    symbol: '',
                    text: "●●●",
                }
            }
        },
        navigation: {
            menuStyle: {
                background: theme.palette.quaternary.main,
                borderRadius: "6px",
                boxShadow: 'none',
                strokeWidth: '10px'
            },
            menuItemStyle: {
                color: '#FFFFFF'
            },
            menuItemHoverStyle: {
                background: theme.palette.quaternary.light,
                color: '#FFFFFF'
            },
            buttonOptions: {
                height: 24,
                width: 24,
                borderRadius: "6px",
                theme: {
                    fill: theme.palette.background.insight,
                    style: {
                        color: theme.palette.text.disabled,
                    },
                    states: {
                        hover: {
                            fill: theme.palette.quaternary.dark,
                            style: {
                                color: theme.palette.text.disabled,
                            }
                        },
                        select: {
                            fill: theme.palette.background.insight,
                            style: {
                                color: theme.palette.text.disabled,
                            }
                        }
                    }
                }
            }
        },
        tooltip: {
            style: {
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: "14px"
            },
            backgroundColor: "#3A384F",
            borderRadius: 6,
            borderWidth: 0,
            shadow: false,
            padding: 12
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    Highcharts.setOptions(globalOptions);

    React.useEffect(() => {
        chartRef?.current?.chart?.reflow(false);
    }, [boxRef?.current?.clientHeight, boxRef?.current?.clientWidth, options, chartRef]);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Box ref={boxRef} width="100%" data-cy={dataCy}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} constructorType={constructorType} />
        </Box>
    );
};

ChartBase.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    constructorType: PropTypes.oneOf(["chart", "stockChart", "mapChart", "ganttChart"]).isRequired,
    setChart: PropTypes.func
};

ChartBase.defaultProps = {
    loading: false,
    error: false,
    constructorType: "chart"
};

export default ChartBase;
