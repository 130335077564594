import React from "react";

import { Box, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import PhoneCallbackRoundedIcon from "@material-ui/icons/PhoneCallbackRounded";
import { withStyles } from "@material-ui/core/styles";

import CallbackForm from "./CallbackForm";

const StyledCard = withStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(7),
        alignItems: "flex-end",
        justifyContent: "space-between"
    }
}))(CardActions);

const RequestCallback: React.FC = () => {
    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-help-request-a-callback">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Request a callback
                </Typography>
            </Box>
            <StyledCard>
                <StyledCardContent>
                    <Box height="100%" display="flex">
                        <PhoneCallbackRoundedIcon />
                        <Box height="100%" paddingLeft={2} display="flex" flexDirection="column">
                            <Typography variant="subtitle1" component="div">
                                Request a call back and we will aim to call you within 24 hours.
                            </Typography>
                        </Box>
                    </Box>
                    <br />
                </StyledCardContent>
                <StyledCardActions>
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        Callback available
                        <br />
                        Monday - Friday
                        <br />
                        09:00 - 17:00 GMT
                    </Typography>
                    <CallbackForm />
                </StyledCardActions>
            </StyledCard>
        </Box>
    );
};

export default RequestCallback;
