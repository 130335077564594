import React from "react";

import Comparators from "./comparators/Comparators";
import Targets from "./targets/Targets";

import { FilterStep, selectActiveStep } from "modules/customer/tools/location/filters/filtersSlice";
import { useAppSelector } from "store";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === FilterStep.SelectComparator && (<Comparators />)}
            {activeStep === FilterStep.SetTargets && (<Targets />)}
        </>
    );
};

export default Content;
