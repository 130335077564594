import React from "react";
import PropTypes from "prop-types";

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Typography } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import RAGIndicator from "./RAGIndicator";

const CustomAccordion = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.insight
    },
    expanded: {}
}))(Accordion);

const CustomAccordionSummary = withStyles(theme => ({
    root: {
        borderRadius: "6px",
        color: theme.palette.background.insight,
        padding: 0,
        paddingRight: theme.spacing(1.5),
        "&$expanded": {
            minHeight: 0
        }
    },
    content: {
        paddingLeft: theme.spacing(1.5),
        "&$expanded": {
            margin: 0
        }
    },
    expanded: {}
}))(AccordionSummary);

const CustomAccordionDetails = withStyles(theme => ({
    root: {
        padding: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    }
}))(AccordionDetails);

const useStyles = makeStyles(theme => ({
    expandIcon: {
        color: theme.palette.background.insight
    },
    success: {
        backgroundColor: theme.palette.success.main
    },
    successIcon: {
        color: theme.palette.background.insight,
        backgroundColor: theme.palette.success.main
    },
    successCounter: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.background.insight,
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    warning: {
        backgroundColor: theme.palette.warning.main
    },
    warningIcon: {
        color: theme.palette.background.insight,
        backgroundColor: theme.palette.warning.main
    },
    warningCounter: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.background.insight,
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    error: {
        backgroundColor: theme.palette.error.main
    },
    errorIcon: {
        color: theme.palette.background.insight,
        backgroundColor: theme.palette.error.main
    },
    errorCounter: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.background.insight,
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    noData: {
        backgroundColor: theme.palette.text.disabled
    },
    noDataIcon: {
        color: theme.palette.background.insight,
        backgroundColor: theme.palette.text.disabled
    },
    noDataCounter: {
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.background.insight,
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    info: {
        backgroundColor: "#4d4a60"
    },
    infoIcon: {
        color: theme.palette.background.insight,
        backgroundColor: "#4d4a60"
    },
    infoCounter: {
        color: "#4d4a60",
        backgroundColor: theme.palette.background.insight,
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
}));

const RAGIndicatorsGroup = (props) => {
    const classes = useStyles();
    const { rags } = props;
    const keepItUp = rags.filter(rag => rag.status === "success");
    const requiresAttention = rags.filter(rag => rag.status === "warning");
    const criticalIssue = rags.filter(rag => rag.status === "error");
    const loading = rags.filter(rag => rag.status === "info");
    const unavailable = rags.filter(rag => rag.status === "noData");

    return (
        <Grid container spacing={1} data-cy="rag-indicators-group">
            <Grid item xs={12}>
                <CustomAccordion elevation={0} defaultExpanded>
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                        className={classes.success}>
                        <Box display="flex" alignItems="center" paddingRight={1.5}>
                            <CheckCircleOutlineIcon className={classes.successIcon} />
                        </Box>
                        <Typography variant="h6" component="div">
                            Keep it up
                        </Typography>
                        <Spacer />
                        <Avatar className={classes.successCounter}>
                            <Typography variant="subtitle2" component="div">
                                {keepItUp.length}
                            </Typography>
                        </Avatar>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Grid container spacing={1}>
                            {keepItUp.map((rag, index) =>
                                <Grid key={`keepItUp_${index}`} item xs={12}>
                                    <Box data-cy={rag.id}>
                                        <RAGIndicator clickEnabled={true}  {...rag} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </CustomAccordionDetails>
                </CustomAccordion>
            </Grid>
            <Grid item xs={12}>
                <CustomAccordion elevation={0}>
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                        className={classes.warning}>
                        <Box display="flex" alignItems="center" paddingRight={1.5}>
                            <ErrorOutlineIcon className={classes.warningIcon} />
                        </Box>
                        <Typography variant="h6" component="div">
                            Requires attention
                        </Typography>
                        <Spacer />
                        <Avatar className={classes.warningCounter}>
                            <Typography variant="subtitle2" component="div">
                                {requiresAttention.length}
                            </Typography>
                        </Avatar>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Grid container spacing={1}>
                            {requiresAttention.map((rag, index) =>
                                <Grid key={`requiresAttention_${index}`} item xs={12}>
                                    <Box data-cy={rag.id}>
                                        <RAGIndicator clickEnabled={true} {...rag} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </CustomAccordionDetails>
                </CustomAccordion>
            </Grid>
            <Grid item xs={12}>
                <CustomAccordion elevation={0}>
                    <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                        className={classes.error}>
                        <Box display="flex" alignItems="center" paddingRight={1.5}>
                            <CancelOutlinedIcon className={classes.errorIcon} />
                        </Box>
                        <Typography variant="h6" component="div">
                            Critical issue
                        </Typography>
                        <Spacer />
                        <Avatar className={classes.errorCounter}>
                            <Typography variant="subtitle2" component="div">
                                {criticalIssue.length}
                            </Typography>
                        </Avatar>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <Grid container spacing={1}>
                            {criticalIssue.map((rag, index) =>
                                <Grid key={`criticalIssue_${index}`} item xs={12}>
                                    <Box data-cy={rag.id}>
                                        <RAGIndicator clickEnabled={true} {...rag} />
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </CustomAccordionDetails>
                </CustomAccordion>
            </Grid>
            {unavailable.length > 0 && (
                <Grid item xs={12}>
                    <CustomAccordion elevation={0}>
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                            className={classes.noData}>
                            <Box display="flex" alignItems="center" paddingRight={1.5}>
                                <InfoOutlinedIcon className={classes.noDataIcon} />
                            </Box>
                            <Typography variant="h6" component="div">
                                Unavailable
                            </Typography>
                            <Spacer />
                            <Avatar className={classes.noDataCounter}>
                                <Typography variant="subtitle2" component="div">
                                    {unavailable.length}
                                </Typography>
                            </Avatar>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                            <Grid container spacing={1}>
                                {unavailable.map((rag, index) =>
                                    <Grid key={`loading_${index}`} item xs={12}>
                                        <Box data-cy={rag.id}>
                                            <RAGIndicator clickEnabled={true} {...rag} />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                </Grid>
            )}
            {loading.length > 0 && (
                <Grid item xs={12}>
                    <CustomAccordion elevation={0}>
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                            className={classes.info}>
                            <Box display="flex" alignItems="center" paddingRight={1.5}>
                                <InfoOutlinedIcon className={classes.infoIcon} />
                            </Box>
                            <Typography variant="h6" component="div">
                                Loading
                            </Typography>
                            <Spacer />
                            <Avatar className={classes.infoCounter}>
                                <Typography variant="subtitle2" component="div">
                                    {loading.length}
                                </Typography>
                            </Avatar>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                            <Grid container spacing={1}>
                                {loading.map((rag, index) =>
                                    <Grid key={`loading_${index}`} item xs={12}>
                                        <Box data-cy={rag.id}>
                                            <RAGIndicator clickEnabled={true} {...rag} />
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </CustomAccordionDetails>
                    </CustomAccordion>
                </Grid>
            )}
        </Grid>
    );
};

RAGIndicatorsGroup.propTypes = {
    rags: PropTypes.array.isRequired
};

RAGIndicatorsGroup.defaultProps = {
    rags: []
};

export default RAGIndicatorsGroup;
