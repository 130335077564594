import { combineReducers } from "redux";

import types from "./types";

const forecastSalesWoWEmpty = {
    loading: false,
    error: false,
    nextWeekValue: 0,
    percentageDifference: 0,
    nextWeek: "NextWeek",
    previousWeek: "PreviousWeek"
};

const forecastSalesWoW = (state = forecastSalesWoWEmpty, action) => {
    switch (action.type) {
        case types.GET_FORECAST_SALES_WOW_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                nextWeekValue: 0,
                percentageDifference: 0,
                nextWeek: "NextWeek",
                previousWeek: "PreviousWeek"
            };
        case types.GET_FORECAST_SALES_WOW_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                nextWeekValue: action.payload.nextWeekValue,
                percentageDifference: action.payload.percentageDifference,
                nextWeek: "NextWeek",
                previousWeek: "PreviousWeek"
            };
        case types.GET_FORECAST_SALES_WOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                nextWeekValue: 0,
                percentageDifference: 0,
                nextWeek: "NextWeek",
                previousWeek: "PreviousWeek"
            };
        default:
            return state;
    }
};

const forecastSalesMOMEmpty = {
    loading: false,
    error: false,
    nextMonthValue: 0,
    percentageDifference: 0,
    nextMonth: "nextMonth",
    sameMonthLastYear: "SameMonthLastYear"
};

const forecastSalesMOM = (state = forecastSalesMOMEmpty, action) => {
    switch (action.type) {
        case types.GET_FORECAST_SALES_MOM_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                nextMonthValue: 0,
                percentageDifference: 0,
                nextMonth: ["nextMonth", "year"],
                sameMonthLastYear: ["SameMonthLastYear", "year"]
            };
        case types.GET_FORECAST_SALES_MOM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                nextMonthValue: action.payload.nextMonthValue,
                percentageDifference: action.payload.percentageDifference,
                nextMonth: action.payload.nextMonth,
                sameMonthLastYear: action.payload.sameMonthLastYear
            };
        case types.GET_FORECAST_SALES_MOM_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                nextMonthValue: 0,
                percentageDifference: 0,
                nextMonth: ["nextMonth", "year"],
                sameMonthLastYear: ["SameMonthLastYear", "year"]
            };
        default:
            return state;
    }
};

const forecastSalesYOYEmpty = {
    loading: false,
    error: false,
    financialYearToGoValue: 0,
    percentageDifference: 0,
    yearToGo: "yearToGo",
    samePeriodLastYear: "samePeriodLastYear"
};

const forecastSalesYOY = (state = forecastSalesYOYEmpty, action) => {
    switch (action.type) {
        case types.GET_FORECAST_SALES_YOY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                financialYearToGoValue: 0,
                percentageDifference: 0,
                yearToGo: "yearToGo",
                samePeriodLastYear: "samePeriodLastYear"
            };
        case types.GET_FORECAST_SALES_YOY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                financialYearToGoValue: action.payload.financialYearToGoValue,
                percentageDifference: action.payload.percentageDifference,
                yearToGo: "yearToGo",
                samePeriodLastYear: "samePeriodLastYear"
            };
        case types.GET_FORECAST_SALES_YOY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                financialYearToGoValue: 0,
                percentageDifference: 0,
                yearToGo: "yearToGo",
                samePeriodLastYear: "samePeriodLastYear"
            };
        default:
            return state;
    }
};

const salesHistoryForecastEmpty = {
    loading: false,
    error: false,
    values: []
};

const salesHistoryForecast = (state = salesHistoryForecastEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_HISTORY_FORECAST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_SALES_HISTORY_FORECAST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values
            };
        case types.GET_SALES_HISTORY_FORECAST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const monthlyForecastEmpty = {
    loading: false,
    error: false,
    values: []
};

const monthlyForecast = (state = monthlyForecastEmpty, action) => {
    switch (action.type) {
        case types.GET_MONTHLY_FORECAST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_MONTHLY_FORECAST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values
            };
        case types.GET_MONTHLY_FORECAST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    forecastSalesWoW,
    forecastSalesMOM,
    forecastSalesYOY,
    salesHistoryForecast,
    monthlyForecast
});

export default reducer;
