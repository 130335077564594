import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {
    clearStoresFilter,
    selectIsStoresFilterModified,
    selectMaxRevenue,
    selectMinRevenue,
    selectRegions,
    selectCurrentStoresCategories,
    selectStoresFilter,
    setStoresFilter,
    StoresFilter
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    paper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    list: {
        width: theme.spacing(35)
    }
}))(Menu);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectRegions);
    const storeCategories = useAppSelector(selectCurrentStoresCategories);
    const minRevenue = useAppSelector(selectMinRevenue);
    const maxRevenue = useAppSelector(selectMaxRevenue);
    const storesFilter = useAppSelector(selectStoresFilter);
    const isStoresFilterModified = useAppSelector(selectIsStoresFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentStoresFilter, setCurrentStoresFilter] = React.useState<StoresFilter>(storesFilter);
    const sliderMinValue = Math.max(minRevenue, currentStoresFilter.minRevenue);
    const sliderMaxValue = Math.min(maxRevenue, currentStoresFilter.maxRevenue);
    const step = (maxRevenue - minRevenue) / 5;

    const revenueMarks = React.useMemo(() => {
        const marks: Mark[] = [];
        if (step === 0 || step === Infinity || step === -Infinity) {
            return marks;
        }
        for (let i = 0; i <= 5; i++) {
            const value = minRevenue + (i * step);
            const label = numberFormatter.toDecimalPlaces(value, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [minRevenue, step]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearStoresFilter());
    };

    const setStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((storesFilter: StoresFilter) => dispatch(setStoresFilter(storesFilter)), 400);
    }, [dispatch]);

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const region = event.target.value;
        const newStoresFilter = { ...storesFilter, region };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleStoreCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const category = event.target.value;
        const newStoresFilter = { ...storesFilter, category };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleRevenueChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        const revenue = value as number[];
        const newStoresFilter = { ...storesFilter, minRevenue: revenue[0], maxRevenue: revenue[1] };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    React.useEffect(() => {
        setCurrentStoresFilter(storesFilter);
    }, [storesFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box>
                        <Typography variant="body1" component="div" gutterBottom>
                            Region
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.region}
                            onChange={handleRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {regions.map((region, index) =>
                                <MenuItem key={`region_${index}`} value={region}>
                                    {region}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Store category
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.category}
                            onChange={handleStoreCategoryChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select store category
                            </MenuItem>
                            {storeCategories.map((storeCategory, index) =>
                                <MenuItem key={`storeCategory_${index}`} value={storeCategory}>
                                    {storeCategory}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Revenue
                        </Typography>
                        <Slider
                            value={[sliderMinValue, sliderMaxValue]}
                            onChange={handleRevenueChange}
                            step={step}
                            min={minRevenue}
                            max={maxRevenue}
                            marks={revenueMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
