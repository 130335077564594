import { combineReducers } from "redux";

import types from "./types";

const localAreaCompetitionEmpty = {
    loading: false,
    error: false,
    selectedStore: null,
    comparatorStores: [],
    directCompetitorStores: [],
    otherClientStores: [],
    directCompetitorsNotSet: false
};

const localAreaCompetition = (state = localAreaCompetitionEmpty, action) => {
    switch (action.type) {
        case types.GET_LOCAL_AREA_COMPETITION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                selectedStore: null,
                comparatorStores: [],
                directCompetitorStores: [],
                otherClientStores: [],
                directCompetitorsNotSet: false
            };
        case types.GET_LOCAL_AREA_COMPETITION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores,
                directCompetitorStores: action.payload.directCompetitorStores,
                otherClientStores: action.payload.otherClientStores,
                directCompetitorsNotSet: action.payload.directCompetitorsNotSet
            };
        case types.GET_LOCAL_AREA_COMPETITION_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                selectedStore: null,
                comparatorStores: [],
                directCompetitorStores: [],
                otherClientStores: [],
                directCompetitorsNotSet: false
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    localAreaCompetition
});

export default reducer;
