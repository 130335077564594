import React from "react";

import { Grid } from "@material-ui/core";

import YoYRawNetStoreOpenings from "./YoYRawNetStoreOpenings";
import NumberOfStoresInLocalArea from "./NumberOfStoresInLocalArea";
import NetOpenVsClosuresOverTime from "./NetOpenVsClosuresOverTime";

const OpeningsAndClosures: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <YoYRawNetStoreOpenings />
            </Grid>
            <Grid item xs={12}>
                <NumberOfStoresInLocalArea />
            </Grid>
            <Grid item xs={12}>
                <NetOpenVsClosuresOverTime />
            </Grid>
        </Grid>
    );
};

export default OpeningsAndClosures;
