import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectYoYFootfall } from "modules/customer/insights/portfolioNew/footfall/footfallSlice";

const YearOnYearFootfall = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const yoYFootfall = useAppSelector(selectYoYFootfall);
    const { isLoading, hasErrors, data } = yoYFootfall;
    const valueFormatted = numberFormatter.toPercentage(data.selectedStore);
    const comparisonValue = numberFormatter.toPercentage(data.comparator);
    const differenceValue = data.selectedStore - data.comparator;
    const label = `Year-on-year change in footfall through ${selectedStore?.name}'s retail centre`;

    return (
        <Box width="100%" height="100%" data-cy="year-on-year-footfall">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {valueFormatted}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparisonValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparator?.name}`}
                differenceValue={
                    <PercentageIndicator loading={false} error={false} value={differenceValue} switchColor={false} noDataMessage={""} />
                }
                differenceLabel="% difference"
            />
        </Box>
    );
};

export default YearOnYearFootfall;
