import React from "react";
import { Grid } from "@material-ui/core";

import CompetitionScoreBulletChart from "./CompetitionScoreBulletChart";
import CompetitionScoreRagIndicator from "./CompetitionScoreRagIndicator";

const Revenue = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CompetitionScoreBulletChart />
            </Grid>
            <Grid item xs={12}>
                <CompetitionScoreRagIndicator />
            </Grid>
        </Grid>
    );
};

export default Revenue;
