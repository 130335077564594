import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Page from "components/customer/Page";
import TermsOfUseContents from "pages/TermsOfUseContents";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const TermsOfUse: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);

    return (
        // @ts-ignore
        <Page data-cy="pages-customer-terms-of-use">
            <Grid container item spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        {userInfo.companyDisplayName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Terms of use
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* @ts-ignore */}
                    <TermsOfUseContents linkColor={theme.palette.tertiary.main} />
                </Grid>
            </Grid>
        </Page>
    );
};

export default TermsOfUse;
