import React from "react";

const CompanyHealthSubtitle: React.FC = () => {
    return (
        <>
            How healthy is your company? The key performance indicators below summarise your company's overall health,
            giving you a high-level view of its current and likely future performance. Our radar chart shows at a glance
            which areas of your business are strong, and which need your attention.
        </>
    );
};

export default CompanyHealthSubtitle;
