import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectFootfallVsComparator } from "modules/customer/insights/portfolioNew/footfall/footfallSlice";

const FootfallVsComparator: React.FC = () => {
    const footfallVsComparator = useAppSelector(selectFootfallVsComparator);
    return (
        <RAGIndicatorNew ragIndicator={footfallVsComparator} />
    );
};

export default FootfallVsComparator;
