import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Evaluate the interplay between the number of competing stores and market spend across your portfolio and assess where your {store?.name} store lies.
            Identify whether you need strategic marketing to capture untapped demand or should refine pricing strategies to exploit the local supply and demand context.
            Use this insight to refine your store's competitive strategy, ensuring your store format and product assortment are tailored to capitalise on market opportunities and to solidify your position against competitors.
        </>
    );
};

export default Subtitle;
