import React from "react";

import { useAppSelector } from "store";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";

const Subtitle: React.FC = () => {
    const comparatorsByChapter = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparatorsByChapter?.demographics.storeName;

    return (
        <>
            Analyse your selected location's catchment area and evaluate whether the number of customers this location attracts could support your proposed store.
            Explore the available customers' demography and understand what parts are similar to the demography of your {comparatorName} store and what makes them different.
        </>
    );
};

export default Subtitle;
