import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Uncover your {store?.name} store's profitability. Evaluate last year's performance, tracking changes over time to refine your strategy.
            Trace the financial journey from revenue to net profit, identifying improvement areas and gauging operational sustainability.
        </>
    );
};

export default Subtitle;
