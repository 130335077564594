import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";
import Spacer from "components/Spacer";

import { ReactComponent as LogoDesktop } from "assets/images/dash-logo-desktop-white.svg";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const useStyles = makeStyles(theme => ({
    hr: {
        borderColor: theme.palette.text.disabled
    }
}));

const Footer = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const color = theme.palette.common.white;
    const bgcolor = theme.palette.background.defaultDark;
    const year = (new Date()).getFullYear();

    const handleLinkClick = (label) => {
        dispatch(trackEvent("Footer links", `${label} click`, `Footer - ${label} link`));
    };

    const groups = [{
        title: "Platform",
        links: [{
            label: "How Dash works",
            url: "/how-dash-works"
        }, {
            label: "Features",
            url: "/features"
        }]
    }, {
        title: "Insights and tools",
        links: [{
            label: "Performance",
            url: "/insights-and-tools?section=performance"
        }, {
            label: "Portfolio",
            url: "/insights-and-tools?section=portfolio"
        }, {
            label: "Location",
            url: "/insights-and-tools?section=location"
        }, {
            label: "Range",
            url: "/insights-and-tools?section=range"
        }]
    }, {
        title: "About",
        links: [{
            label: "Our Story",
            url: "/our-story"
        }, {
            label: "Meet the team",
            url: "/meet-the-team"
        }, {
            label: "FAQ",
            url: "/frequently-asked-questions"
        }]
    }, {
        title: "Contact",
        links: [{
            label: "Book a demo",
            url: "/contact-us?subject=book-a-demo"
        }, {
            label: "Contact us",
            url: "/contact-us"
        }]
    }];

    return (
        <PageSection color={color} bgcolor={bgcolor} dataCy="pages-landing-footer">
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <NavLink to="/">
                        <LogoDesktop />
                    </NavLink>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={4}>
                        {groups.map((group, groupIndex) =>
                            <Grid key={groupIndex} item xs={6} md={3}>
                                <Box color="text.disabled">
                                    <Typography variant="body1" component="div">
                                        {group.title}
                                    </Typography>
                                </Box>
                                <br />
                                {group.links.map((link, linkIndex) =>
                                    <Typography
                                        key={`${groupIndex}_${linkIndex}`}
                                        variant="body1"
                                        component="div"
                                        gutterBottom
                                    >
                                        <Link
                                            component={NavLink}
                                            to={link.url}
                                            color="inherit"
                                            underline="none"
                                            variant="body1"
                                        >
                                            {link.label}
                                        </Link>
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <hr className={classes.hr} />
                    </Box>
                    <br />
                    <Box display="flex" alignItems="center" color="text.disabled">
                        © {year} KPMG Dash
                        <Spacer />
                        <Link
                            href="https://home.kpmg/uk/en/home/misc/privacy.html"
                            target="_blank"
                            color="inherit"
                            underline="none"
                            variant="body1"
                            onClick={() => handleLinkClick("Privacy notice")}
                        >
                            Privacy Notice
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                            href="https://home.kpmg/uk/en/home/misc/cookies.html"
                            target="_blank"
                            color="inherit"
                            underline="none"
                            variant="body1"
                            onClick={() => handleLinkClick("Cookies")}
                        >
                            Cookies
                        </Link>
                    </Box>
                    <br />
                    <Box>
                        <hr className={classes.hr} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" component="div" color="textSecondary">
                        © {year} KPMG LLP a UK limited liability partnership and a member firm of the KPMG global
                        organisation
                        of independent member firms affiliated with KPMG International Limited, a private English
                        company
                        limited by guarantee. All rights reserved.
                    </Typography>
                    <br />
                    <Typography variant="body2" component="div" color="textSecondary">
                        For more detail about the structure of the KPMG global organisation please visit: &nbsp;
                        <Link
                            href="https://home.kpmg/governance"
                            target="_blank"
                            color="inherit"
                            underline="always"
                            variant="body2"
                            onClick={() => handleLinkClick("KPMG governance")}
                        >
                            https://home.kpmg/governance
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default Footer;
