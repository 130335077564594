import { createAppAsyncThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CatchmentCustomerProfile {
    public readonly retailCentreId: number;
    public readonly outputAreaCode: string;
    public readonly supergroupCode: number;
    public readonly supergroupName: string;
    public readonly groupName: string;
    public readonly subgroupName: string;
    public readonly probability: number;
    public readonly distance: number;
    public readonly population: number;
    public readonly numberOfVisitors: number;

    constructor(
        retailCentreId: number,
        outputAreaCode: string,
        supergroupCode: number,
        supergroupName: string,
        groupName: string,
        subgroupName: string,
        probability: number,
        distance: number,
        population: number,
        numberOfVisitors: number,
    ) {
        this.retailCentreId = retailCentreId;
        this.outputAreaCode = outputAreaCode;
        this.supergroupCode = supergroupCode;
        this.supergroupName = supergroupName;
        this.groupName = groupName;
        this.subgroupName = subgroupName;
        this.probability = probability;
        this.distance = distance;
        this.population = population;
        this.numberOfVisitors = numberOfVisitors;
    }
}

interface CustomerProfilesDimensions {
    "CatchmentCustomerProfiles.RetailCentreID": string,
    "CatchmentCustomerProfiles.OAID": string,
    "CatchmentCustomerProfiles.SupergroupCode": number,
    "CatchmentCustomerProfiles.SupergroupName": string,
    "CatchmentCustomerProfiles.GroupName": string,
    "CatchmentCustomerProfiles.SubgroupName": string,
    "CatchmentCustomerProfiles.Probability": number,
    "CatchmentCustomerProfiles.Distance": number,
    "CatchmentCustomerProfiles.Population": number,
    "CatchmentCustomerProfiles.NumberOfVisitors": number
}

export const loadCatchmentCustomerProfiles = createAppAsyncThunk<
    CatchmentCustomerProfile[],
    {
        scenarioCatchmentAccountId: string,
        selectedRetailCentreId?: number,
        targetStoreCategoryId?: number
    }
>(
    "customer/tools/location/loadCatchmentCustomerProfiles",
    async ({ scenarioCatchmentAccountId, selectedRetailCentreId, targetStoreCategoryId }, thunkAPI) => {
        if (!selectedRetailCentreId || !targetStoreCategoryId) {
            return [];
        }

        try {
            const query = {
                dimensions: [
                    "CatchmentCustomerProfiles.RetailCentreID",
                    "CatchmentCustomerProfiles.OAID",
                    "CatchmentCustomerProfiles.IsScenario",
                    "CatchmentCustomerProfiles.SupergroupCode",
                    "CatchmentCustomerProfiles.SupergroupName",
                    "CatchmentCustomerProfiles.GroupName",
                    "CatchmentCustomerProfiles.SubgroupName",
                    "CatchmentCustomerProfiles.Probability",
                    "CatchmentCustomerProfiles.Distance",
                    "CatchmentCustomerProfiles.Population",
                    "CatchmentCustomerProfiles.NumberOfVisitors"
                ],
                filters: [{
                    member: "CatchmentCustomerProfiles.RetailCentreID",
                    operator: "equals",
                    values: [String(selectedRetailCentreId)]
                }, {
                    member: "CatchmentCustomerProfiles.StoreCategory_ID",
                    operator: "equals",
                    values: [String(targetStoreCategoryId)]
                }],
                segments: [
                    "CatchmentCustomerProfiles.Scenario"
                ],
                order: [
                    ["CatchmentCustomerProfiles.OAID", "asc"]
                ]
            };

            const resultSet = await thunkAPI.dispatch(cubeLoadCatchmentArea(query, scenarioCatchmentAccountId, "CatchmentCustomerProfiles")) as unknown as ExtendedResultSet<CustomerProfilesDimensions>;
            const queryResponse = resultSet.loadResponses[0];

            return queryResponse.data.map(row => new CatchmentCustomerProfile(
                Number(row["CatchmentCustomerProfiles.RetailCentreID"] ?? 0),
                row["CatchmentCustomerProfiles.OAID"] ?? "",
                Number(row["CatchmentCustomerProfiles.SupergroupCode"] ?? 0),
                row["CatchmentCustomerProfiles.SupergroupName"] ?? "",
                row["CatchmentCustomerProfiles.GroupName"] ?? "",
                row["CatchmentCustomerProfiles.SubgroupName"] ?? "",
                Number(row["CatchmentCustomerProfiles.Probability"] ?? 0),
                Number(row["CatchmentCustomerProfiles.Distance"] ?? 0),
                Number(row["CatchmentCustomerProfiles.Population"] ?? 0),
                Number(row["CatchmentCustomerProfiles.NumberOfVisitors"] ?? 0),
            ));
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading CatchmentCustomerProfiles.", error));
            throw error;
        }
    }
);
