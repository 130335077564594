import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectFootfallLevelAlignment } from "modules/customer/tools/location/footfall/footfallSlice";
import { useAppSelector } from "store";

const FootfallLevelAlignment: React.FC = () => {
    const footfallLevelAlignment = useAppSelector(selectFootfallLevelAlignment);
    return (
        <RAGIndicatorNew ragIndicator={footfallLevelAlignment} />
    );
};

export default FootfallLevelAlignment;
