import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Store } from "modules/customer/insights/portfolioNew/store";

export enum CustomerProfilesTreemapSeries {
    Store,
    Comparator
}

export class CustomerProfileSummary {
    public readonly storeID: string;
    public readonly supergroupCode: number;
    public readonly supergroupName: string;
    public readonly groupName: string;
    public readonly subgroupName: string;
    public readonly unweightedPopulation: number;
    public readonly weightedPopulation: number;

    constructor(
        storeID: string,
        supergroupCode: number,
        supergroupName: string,
        groupName: string,
        subgroupName: string,
        unweightedPopulation: number,
        weightedPopulation: number,
    ) {
        this.storeID = storeID;
        this.supergroupCode = supergroupCode;
        this.supergroupName = supergroupName;
        this.groupName = groupName;
        this.subgroupName = subgroupName;
        this.unweightedPopulation = unweightedPopulation;
        this.weightedPopulation = weightedPopulation;
    }
}

export const loadCustomerProfileSummaries = (stores: Store[], catchmentAccountId: string): AppThunk<Promise<CustomerProfileSummary[]>> => async (dispatch) => {
    try {
        const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
        stores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
        });

        const orFilterClause = { or: [] };
        retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
            const andFilterClause = {
                and: [{
                    member: "CatchmentCustomerProfiles.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryID)]
                }, {
                    member: "CatchmentCustomerProfiles.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });
        const query = {
            measures: [
                "CatchmentCustomerProfiles.SumPopulation",
                "CatchmentCustomerProfiles.SumWeightedPopulation",
            ],
            dimensions: [
                "CatchmentCustomerProfiles.RetailCentreID",
                "CatchmentCustomerProfiles.StoreCategory_ID",
                "CatchmentCustomerProfiles.IsScenario",
                "CatchmentCustomerProfiles.SupergroupCode",
                "CatchmentCustomerProfiles.SupergroupName",
                "CatchmentCustomerProfiles.GroupName",
                "CatchmentCustomerProfiles.SubgroupName",
            ],
            filters: [orFilterClause],
            segments: [
                "CatchmentCustomerProfiles.Baseline"
            ]
        };

        const resultSet = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentCustomerProfiles")) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;

        const customerProfileSummaries: CustomerProfileSummary[] = [];
        stores.forEach(store => {
            const relevantResults = rawData.filter(row =>
                Number(row["CatchmentCustomerProfiles.RetailCentreID"]) === store.retailCentreID
                && Number(row["CatchmentCustomerProfiles.StoreCategory_ID"]) === store.storeCategoryID);

            const storeCustomerProfileSummary = relevantResults.map(row => new CustomerProfileSummary(
                store.id,
                Number(row["CatchmentCustomerProfiles.SupergroupCode"] ?? 0),
                row["CatchmentCustomerProfiles.SupergroupName"] ?? "",
                row["CatchmentCustomerProfiles.GroupName"] ?? "",
                row["CatchmentCustomerProfiles.SubgroupName"] ?? "",
                Number(row["CatchmentCustomerProfiles.SumPopulation"] ?? 0),
                Number(row["CatchmentCustomerProfiles.SumWeightedPopulation"] ?? 0)));
            customerProfileSummaries.push(...storeCustomerProfileSummary);
        });

        return customerProfileSummaries;
    } catch (error) {
        dispatch(logError("Error loading CustomerProfiles.", error));
        throw error;
    }
};
