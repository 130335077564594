import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const MarketCategoryBreakdownSubtitle = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Understand which market categories are thriving and declining in the local area of
            your {store?.name} store, compared to {comparator?.name}. Analyse how the area's market
            category mix has changed over the past 12 months.
        </>
    );
};

export default MarketCategoryBreakdownSubtitle;
