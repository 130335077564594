import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class OpeningsAndClosures {
    public readonly storeName: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly hasOpened: boolean;

    constructor(
        storeName: string,
        latitude: number,
        longitude: number,
        hasOpened: boolean
    ) {
        this.storeName = storeName;
        this.latitude = latitude;
        this.longitude = longitude;
        this.hasOpened = hasOpened;
    }
}

export const loadOpeningsAndClosures = (retailCentreId: number | undefined): AppThunk<Promise<OpeningsAndClosures[]>> => async (dispatch) => {
    if (!retailCentreId) {
        return [];
    }
    const retailCentreIdString = retailCentreId.toString();
    try {
        const query = {
            measures: [
                "OpeningsAndClosures.SumTwelveMonthsOpenings",
                "OpeningsAndClosures.SumTwelveMonthsClosures",
            ],
            dimensions: [
                "OpeningsAndClosures.Fascia",
                "OpeningsAndClosures.Lat",
                "OpeningsAndClosures.Lng"
            ],
            filters: [{
                member: "OpeningsAndClosures.RetailCentreID",
                operator: "equals",
                values: [retailCentreIdString]
            }, {
                member: "OpeningsAndClosures.Fascia",
                operator: "set"
            }, {
                or: [{
                    member: "OpeningsAndClosures.SumTwelveMonthsOpenings",
                    operator: "notEquals",
                    values: ["0"]
                }, {
                    member: "OpeningsAndClosures.SumTwelveMonthsClosures",
                    operator: "notEquals",
                    values: ["0"]
                }]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new OpeningsAndClosures(
            row["OpeningsAndClosures.Fascia"],
            Number(row["OpeningsAndClosures.Lat"]),
            Number(row["OpeningsAndClosures.Lng"]),
            !!row["OpeningsAndClosures.SumTwelveMonthsOpenings"]
        ));
    } catch (error) {
        dispatch(logError("Error loading OpeningsAndClosures.", error));
        throw error;
    }
};
