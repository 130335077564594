import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "./store";

export class RetailCentreClassification {
    public readonly retailCentreId: number;
    public readonly retailCentreClassificationName: string;

    constructor(retailCentreId: number, retailCentreClassificationName: string) {
        this.retailCentreId = retailCentreId;
        this.retailCentreClassificationName = retailCentreClassificationName;
    }
}

export const loadRetailCentreClassification = (stores: Store[]): AppThunk<Promise<RetailCentreClassification[]>> => async (dispatch) => {
    const retailCentreClassification: RetailCentreClassification[] = [];

    try {
        const retailCentreIDStrings = stores.map(store => String(store.retailCentreId));

        const query = {
            dimensions: [
                "D_RetailCentres.RetailCentreID",
                "RetailCentreClassification.RetailCentreClassificationName"
            ],
            filters: [{
                member: "D_RetailCentres.RetailCentreID",
                operator: "equals",
                values: retailCentreIDStrings
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        rawData.forEach(row => {
            retailCentreClassification.push({
                retailCentreId: Number(row["D_RetailCentres.RetailCentreID"]),
                retailCentreClassificationName: row["RetailCentreClassification.RetailCentreClassificationName"] ?? ""
            });
        });

        return retailCentreClassification;
    } catch (error) {
        dispatch(logError("Error loading RetailCentreClassification.", error));
        throw error;
    }
};
