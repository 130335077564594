import { createSelector } from "reselect";
import _ from "lodash";
import { median } from "mathjs";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const customerProfiles = (state) => {
    return state.customer.insights.portfolio.catchment.customerProfiles.customerProfiles;
};

const catchmentPopulation = (state) => {
    return state.customer.insights.portfolio.catchment.customerProfiles.population;
};

const marketCategorySpend = (state) => {
    return state.customer.insights.portfolio.catchment.customerProfiles.marketCategorySpend;
};

const penPortraitTreemap = createSelector( //PenPortrait treeMap Selector Template
    customerProfiles,
    (customerProfiles) => {
        const treeMap = {
            loading: customerProfiles.loading,
            error: customerProfiles.error,
            penPortraitGroups: []
        };

        if (treeMap.loading || treeMap.error || customerProfiles.values.length === 0) {
            return treeMap;
        }

        const selectedCustomerProfiles = customerProfiles.values;

        let totalVisitors = 0;

        for (let i in selectedCustomerProfiles) {
            totalVisitors += selectedCustomerProfiles[i].numberOfVisitors; // Sum the total number of visitors; From all OAs in Catchment Area

        }

        const uniqueSupergroups = [...new Set(selectedCustomerProfiles.map(item => item.supergroup))];
        const uniqueGroups = [...new Set(selectedCustomerProfiles.map(item => item.group))];
        const uniqueSubgroups = [...new Set(selectedCustomerProfiles.map(item => item.subgroup))];

        let idVar = 0;
        const supergroupData = uniqueSupergroups.map(item => ({
            name: item,
            type: "Supergroup",
            id: idVar++,
            numberOfVisitors: 0,
            value: 0, // Percentage of Overall Visitors
            isVisibleInLegend: true
        }));

        for (let i in supergroupData) {
            const relevantProfile = selectedCustomerProfiles.filter(item => (item.supergroup === supergroupData[i].name));
            const visitors = relevantProfile.map(item => item.numberOfVisitors).filter(item => item);
            supergroupData[i].numberOfVisitors = visitors.length !== 0 ? visitors.reduce((a, b) => a + b) : 0;
            supergroupData[i].value = (supergroupData[i].numberOfVisitors / totalVisitors) * 100;
            supergroupData[i].classification = relevantProfile[0].classification;
        }

        const groupData = uniqueGroups.map(item => ({
            name: item,
            type: "Group",
            id: idVar++,
            numberOfVisitors: 0,
            value: 0, // Percentage of Overall Visitors
            parent: 0,
            isVisibleInLegend: true
        }));

        for (let i in groupData) {
            const relevantProfile = selectedCustomerProfiles.filter(item => (item.group === groupData[i].name));
            const visitors = relevantProfile.map(item => item.numberOfVisitors).filter(item => item);
            groupData[i].numberOfVisitors = visitors.length !== 0 ? visitors.reduce((a, b) => a + b) : null;
            groupData[i].value = (groupData[i].numberOfVisitors / totalVisitors) * 100;

            const parentSupergroup = selectedCustomerProfiles.find(item => (item.group === groupData[i].name)).supergroup;
            groupData[i].parent = supergroupData.find(item => (item.name === parentSupergroup)).id;
        }

        const subgroupData = uniqueSubgroups.map(item => ({
            name: item,
            type: "Subgroup",
            id: idVar++,
            numberOfVisitors: 0,
            value: 0, // Percentage of Overall Visitors
            parent: 0,
            isVisibleInLegend: true
        }));

        for (let i in subgroupData) {
            const relevantProfile = selectedCustomerProfiles.filter(item => (item.subgroup === subgroupData[i].name));
            const visitors = relevantProfile.map(item => item.numberOfVisitors).filter(item => item);
            subgroupData[i].numberOfVisitors = visitors.length !== 0 ? visitors.reduce((a, b) => a + b) : null;
            subgroupData[i].value = subgroupData[i].numberOfVisitors ? (subgroupData[i].numberOfVisitors / totalVisitors) * 100 : 0;

            const parentGroup = selectedCustomerProfiles.find(item => (item.subgroup === subgroupData[i].name)).group;
            subgroupData[i].parent = groupData.find(item => (item.name === parentGroup)).id;
        }

        const penPortraitGroups = supergroupData.concat(groupData, subgroupData);

        treeMap.penPortraitGroups = penPortraitGroups;

        return treeMap;
    }
);


const catchmentAreaReach = createSelector(
    catchmentPopulation,
    (catchmentPopulation) => {
        const kpi = {
            loading: catchmentPopulation.loading,
            error: catchmentPopulation.error,
            value: 0,
            percentageDifference: 0
        };

        if (kpi.loading || kpi.error || !catchmentPopulation.selectedStore || catchmentPopulation.comparator.length === []) {
            return kpi;
        }

        const selectedStoreData = catchmentPopulation.selectedStore;
        const comparatorData = catchmentPopulation.comparator;

        const selectedMaxDistance = selectedStoreData.maxDistance;

        const comparatorValues = comparatorData.map(item => item.maxDistance);
        const comparatorMedianValue = median(comparatorValues);

        kpi.value = selectedMaxDistance;
        kpi.percentageDifference = (comparatorMedianValue === 0) ? 0 : 100 * ((selectedMaxDistance - comparatorMedianValue) / comparatorMedianValue);

        return kpi;
    }
);

const totalPeopleInCatchmentArea = createSelector(
    catchmentPopulation,
    (catchmentPopulation) => {
        const kpi = {
            loading: catchmentPopulation.loading,
            error: catchmentPopulation.error,
            value: 0,
            percentageDifference: 0
        };

        if (kpi.loading || kpi.error || !catchmentPopulation.selectedStore || catchmentPopulation.comparator.length === []) {
            return kpi;
        }

        const selectedStoreData = catchmentPopulation.selectedStore;
        const comparatorData = catchmentPopulation.comparator;

        const selectedValue = selectedStoreData.population;

        const comparatorValues = comparatorData.map(item => item.population);
        const comparatorMedianValue = median(comparatorValues);

        kpi.value = selectedValue;
        kpi.percentageDifference = (comparatorMedianValue === 0) ? 0 : 100 * ((selectedValue - comparatorMedianValue) / comparatorMedianValue);

        return kpi;
    }
);

const catchmentAreaPenPortraitClassification = createSelector(
    customerProfiles,
    state => selectStore(state),
    (customerProfiles, selectedStoreSelector) => {
        const selector = {
            loading: customerProfiles.loading,
            error: customerProfiles.error,
            mapCenter: {
                latitude: selectedStoreSelector.latitude,
                longitude: selectedStoreSelector.longitude
            },
            penPortraitClassifications: []
        };
        if (selector.loading || selector.error || customerProfiles.values.length === 0) {
            return selector;
        }

        const customerProfilesData = customerProfiles.values;

        selector.penPortraitClassifications = customerProfilesData;

        return selector;
    }
);

const penPortraitsSearch = (state) => {
    return state.customer.insights.portfolio.catchment.customerProfiles.penPortraitsSearch;
};

const amountOfBestCustomers = createSelector(
    customerProfiles,
    marketCategorySpend,
    state => selectStore(state),
    (customerProfiles, marketCategorySpend, selectedStoreSelector) => {
        const rag = {
            loading: customerProfiles.loading || marketCategorySpend.loading,
            error: customerProfiles.error || marketCategorySpend.error,
            id: "amount-of-best-customers",
            label: "Amount of best customers",
            status: "info",
            value: ""
        };

        if (rag.error || rag.loading ||
            customerProfiles.values.length === 0 || marketCategorySpend.values.length === 0) {
            return rag;
        }

        const customerProfilesData = customerProfiles.values;
        const marketCategorySpendData = marketCategorySpend.values;

        const selectedStoreOAID = selectedStoreSelector.outputAreaCode;

        const storeData = {
            mainOAID: selectedStoreOAID,
            spendData: [],
            catchmentPopulation: 0
        };


        storeData.spendData = customerProfilesData.map(item => ({
            OAID: item.outputAreaCode,
            supergroup: item.supergroup,
            spendPerHead: null,
            population: item.numberOfVisitors,
        }));


        for (let i in storeData.spendData) {
            const relevantSpendData = marketCategorySpendData.find(item =>
                item.outputAreaCode === storeData.spendData[i].OAID);

            storeData.spendData[i].spendPerHead = relevantSpendData ? relevantSpendData.spendPerHead : 0;
            storeData.catchmentPopulation += storeData.spendData[i].population;
        }

        storeData.storeGroupData = _.chain(storeData.spendData)
            .groupBy("supergroup")
            .map((element, id) => ({
                supergroup: id,
                medianSpend: _.meanBy(element, "spendPerHead"),
                sumPopulation: _.sumBy(element, "population"),
                oaCount: _.size(_.countBy(element, "OAID"))
            }))
            .value();

        const sortedGroupData = storeData.storeGroupData.sort((a, b) => {
            return b.oaCount - a.oaCount;
        });
        const top5SelectedData = sortedGroupData.slice(0, 5); //top5 most frequent pen portaits

        const sortedTop5GroupData = top5SelectedData.sort((a, b) => {
            return b.medianSpend - a.medianSpend;
        });

        const sumPopulation = sortedTop5GroupData[0]?.sumPopulation ?? 0;
        const catchmentPopulation = storeData.catchmentPopulation ?? 0;
        storeData.amountOfBestCustomers = catchmentPopulation === 0
            ? 0
            : sumPopulation / catchmentPopulation;


        // RAG calculation
        const selectedStoreValue = storeData.amountOfBestCustomers;

        const selectedStoreName = selectedStoreSelector.name;

        if (selectedStoreValue > 0.3) {
            rag.status = "success";
            rag.value = `Your best spending demographic group are a large proportion of your ${selectedStoreName} store's catchment population`;
        } else if (selectedStoreValue >= 0.1) {
            rag.status = "warning";
            rag.value = `Your best spending demographic group are an average proportion of your ${selectedStoreName} store's catchment population`;
        } else {
            rag.status = "error";
            rag.value = ` Your best spending demographic group are a small proportion of your ${selectedStoreName} store's catchment population`;
        }

        return rag;

    }
);

const selectors = {
    penPortraitTreemap,
    catchmentAreaReach,
    totalPeopleInCatchmentArea,
    catchmentAreaPenPortraitClassification,
    penPortraitsSearch,
    amountOfBestCustomers,
    customerProfiles,
    marketCategorySpend
};

export default selectors;
