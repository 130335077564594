import React from "react";

import { useAppSelector } from "store";
import { selectSelectedPartner } from "modules/customer/tools/product/productSlice";

const Subtitle: React.FC = () => {
    const partner = useAppSelector(selectSelectedPartner);

    return (
        <>
            {`Quickly assess store performance and spot opportunities for growth across ${partner?.name}'s estate.`}
        </>
    );
};

export default Subtitle;
