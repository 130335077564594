import { combineReducers } from "redux";

import types from "./types";

const marketCatSpendPerHeadEmpty = {
    loading: false,
    error: false,
    mapCenter: {
        latitude: 0,
        longitude: 0
    },
    spendPerHead: [],
    comparatorSpendPerHead: []
};

const marketCatSpendPerHead = (state = marketCatSpendPerHeadEmpty, action) => {
    switch (action.type) {
        case types.GET_MARKET_CAT_SPEND_PER_HEAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                mapCenter: {
                    latitude: 0,
                    longitude: 0
                },
                spendPerHead: [],
                comparatorSpendPerHead: []
            };
        case types.GET_MARKET_CAT_SPEND_PER_HEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                mapCenter: action.payload.mapCenter,
                spendPerHead: action.payload.spendPerHead,
                comparatorSpendPerHead: action.payload.comparatorSpendPerHead
            };
        case types.GET_MARKET_CAT_SPEND_PER_HEAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                mapCenter: {
                    latitude: 0,
                    longitude: 0
                },
                spendPerHead: [],
                comparatorSpendPerHead: []
            };
        default:
            return state;
    }
};

const marketCategoriesListEmpty = {
    loading: false,
    error: false,
    marketCategories: []
};

const marketCategoriesList = (state = marketCategoriesListEmpty, action) => {
    switch (action.type) {
        case types.GET_MARKET_CATEGORIES_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                marketCategories: []
            };
        case types.GET_MARKET_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                marketCategories: action.payload.marketCategories
            };
        case types.GET_MARKET_CATEGORIES_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                marketCategories: []
            };
        default:
            return state;
    }
};

const selectedMarketCategories = (state = [], action) => {
    switch (action.type) {
        case types.SELECT_MARKET_CATEGORY:
            return [...state, action.payload.marketCategory];
        case types.DESELECT_MARKET_CATEGORY:
            return state.filter(marketCategory => marketCategory !== action.payload.marketCategory);
        case types.CLEAR_SELECTED_MARKET_CATEGORIES:
            return [];
        default:
            return state;
    }
};

const reducer = combineReducers({
    marketCatSpendPerHead,
    marketCategoriesList,
    selectedMarketCategories
});

export default reducer;
