import React from "react";
import PropTypes from "prop-types";
import videojs from "video.js";

import "video.js/dist/video-js.css";

const VideoPlayer = (props) => {
    const { options, onClose } = props;
    const videoRef = React.useRef(null);

    // Reference: https://docs.videojs.com/tutorial-react.html
    // This separate functional component fixes the removal of the videoelement 
    // from the DOM when calling the dispose() method on a player
    const VideoHtml = () => (
        <div>
            <video ref={videoRef} className="video-js vjs-big-play-centered">
                <track kind="captions" />
            </video>
        </div>
    );

    React.useEffect(() => {
        const videoElement = videoRef.current;
        let player;

        if (videoElement) {
            const closeButton = videojs.getComponent("CloseButton");
            videojs.registerComponent("CloseButton", closeButton);

            player = videojs(videoElement, options);
            player.addChild("CloseButton");

            player.getChild("CloseButton").on("close", function () {
                onClose();
            });
        }

        return function cleanup() {
            if (player) {
                player.dispose();
            }
        };

    }, [options, onClose]);

    return (
        <VideoHtml />
    );
};

VideoPlayer.propTypes = {
    options: PropTypes.object.isRequired
};

export default VideoPlayer;
