import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class StoreCategorySpendCentileMetric {
    public readonly storeCategoryId: number;
    public readonly spendCategoryId: number;
    public readonly centile: number;
    public readonly medianWeightedSpend: number;

    constructor(
        storeCategoryId: number,
        spendCategoryId: number,
        centile: number,
        medianWeightedSpend: number
    ) {
        this.storeCategoryId = storeCategoryId;
        this.spendCategoryId = spendCategoryId;
        this.centile = centile;
        this.medianWeightedSpend = medianWeightedSpend;
    }
}

export const loadStoreCategorySpendCentileMetrics = (targetStoreCategoryId: number | undefined, accountId: string, useMLCatchments: boolean): AppThunk<Promise<StoreCategorySpendCentileMetric[]>> => async (dispatch) => {
    if (!targetStoreCategoryId) {
        return [];
    }
    try {
        const catchmentAccountId = useMLCatchments ? accountId : "00000000-0000-0000-0000-000000000000";
        const query = {
            dimensions: [
                "LocationBenchmarkSpendCentileMetrics.StoreCategory_ID",
                "LocationBenchmarkSpendCentileMetrics.SpendCategory_ID",
                "LocationBenchmarkSpendCentileMetrics.Centile",
                "LocationBenchmarkSpendCentileMetrics.MedianWeightedSpend"
            ],
            filters: [{
                member: "LocationBenchmarkSpendCentileMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "LocationBenchmarkSpendCentileMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => new StoreCategorySpendCentileMetric(
            targetStoreCategoryId,
            Number(row["LocationBenchmarkSpendCentileMetrics.SpendCategory_ID"]),
            Number(row["LocationBenchmarkSpendCentileMetrics.Centile"]),
            Number(row["LocationBenchmarkSpendCentileMetrics.MedianWeightedSpend"])
        ));
    } catch (error) {
        dispatch(logError("Error loading StoreCategorySpendCentileMetrics.", error));
        throw error;
    }
};
