import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Progress from "./Progress";
import Error from "./Error";
import useColourPalette from "./useColourPalette";
import numberFormatter from "utils/numberFormatter";

const FractionIndicator = (props) => {
    const { loading, error, variant, numerator, denominator, status, ordinalNumerator, hideDenominator } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const color = (() => {
        switch (status) {
            case "target":
                return colourPalette.comparators[0];
            case "comparator":
                return colourPalette.comparators[1];
            case "error":
                return theme.palette.error.main;
            case "success":
                return theme.palette.success.main;
            case "warning":
                return theme.palette.warning.main;
            case "info":
            default:
                return "#4d4a60";
        }
    })();

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    const formattedNumerator = (ordinalNumerator) ? numberFormatter.toOrdinalNumber(numerator) : numerator;
    const fraction = hideDenominator ? `${formattedNumerator}` : `${formattedNumerator}/${denominator}`;
    const displayedFraction = (denominator === 0) ? "N/A" : fraction;

    return (
        <Box display="flex" style={{ color: color }} data-cy="fraction-indicator">
            <Typography variant={variant}>&nbsp;&nbsp;&nbsp;{displayedFraction}</Typography>
        </Box>
    );
};

FractionIndicator.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf(["subtitle1", "subtitle2", "body1", "body2"]).isRequired,
    numerator: PropTypes.number.isRequired,
    denominator: PropTypes.number.isRequired,
    status: PropTypes.oneOf(["error", "info", "success", "warning", "target", "comparator", "noData"]).isRequired,
    ordinalNumerator: PropTypes.bool,
    hideDenominator: PropTypes.bool
};

FractionIndicator.defaultProps = {
    loading: false,
    error: false,
    variant: "subtitle2",
    numerator: 0,
    denominator: 0,
    status: "info",
    ordinalNumerator: false,
    hideDenominator: false
};

export default FractionIndicator;
