import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Error from "components/visuals/Error";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import Progress from "components/visuals/Progress";

import { operations, selectors } from "modules/customer/insights/performance/budget/storePerformanceAgainstBudget";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "calc(100vw - 32px)"
    },
    container: {
        width: "100%",
        overflow: "auto",
        maxHeight: theme.spacing(60)
    },
    headerCell: {
        backgroundColor: theme.palette.background.paper
    }
}));

const StoreActualVsBudgetRanked = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const storeActualVsBudgetRanked = useSelector(state => selectors.storeActualVsBudgetRanked(state));
    const { loading, error, storePerformanceRankings } = storeActualVsBudgetRanked;

    React.useEffect(() => {
        dispatch(operations.getStoreActualVsBudgetRanked());
    }, [dispatch]);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Card elevation={0} className={classes.card} data-cy="store-actual-vs-budget-ranked">
            <CardContent>
                <Typography variant="h6" component="div">
                    Ranked performance against revenue and contribution budgets by store
                </Typography>
                <TableContainer className={classes.container} data-cy="table">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.headerCell}>
                                    <Typography variant="h6" component="div">
                                        Store
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" className={classes.headerCell}>
                                    <Typography variant="h6" component="div">
                                        Revenue vs budget
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" className={classes.headerCell}>
                                    <Typography variant="h6" component="div">
                                        Contribution vs budget
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" className={classes.headerCell}>
                                    <Typography variant="h6" component="div">
                                        Combined rank
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {storePerformanceRankings.map((row, index) =>
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography variant="h6" component="div">
                                            {row.store}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {row.salesVsBudget && (
                                            <PercentageIndicator value={row.salesVsBudget} variant="h5" align="right" />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {row.contributionVsBudget && (
                                            <PercentageIndicator value={row.contributionVsBudget} variant="h5" align="right" />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="h5" component="div">
                                            {row.combinedRank}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default StoreActualVsBudgetRanked;
