import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectPredictionIsLoading,
    selectPredictionHasErrors,
    selectTypicalYearRevenuePrediction
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const TypicalYearRevenuePrediction: React.FC = () => {
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const typicalYearRevenuePrediction = useAppSelector(selectTypicalYearRevenuePrediction);
    const valueFormatted = numberFormatter.toGBP(typicalYearRevenuePrediction, 1);
    const label = "Pinned location yearly revenue prediction";

    return (
        <Box width="100%" height="100%" data-cy="typical-year-revenue-prediction">
            <KPIFact loading={predictionIsLoading} error={predictionHasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default TypicalYearRevenuePrediction;
