import { AppThunk } from "appThunk";
import { GeoIndicator, Store } from "modules/customer/insights/portfolioNew/store";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export enum DemographicIndicator {
    HouseholdIncome = "householdIncome",
    HouseholdOccupancy = "householdOccupancy",
    HouseholdTenure = "householdTenure",
    AgeStructureGB = "ageStructureGB",
    AgeStructureNI = "ageStructureNI",
    ChildrenAgeStructure = "childrenAgeStructure",
    CountryOfBirth = "countryOfBirth",
    Ethnicity = "ethnicity",
    FirstLanguage = "firstLanguage",
    DwellingType = "dwellingType",
    HouseholdNumberOfCars = "householdNumberOfCars",
    PopulationDensity = "populationDensity",
    EducationLevel = "educationLevel"
}

export enum DemographicIndicatorMeasure {
    PercentageOfPopulation,
    PopulationInThousands
}

export class DwellingType {
    public readonly detached: number;
    public readonly semiDetached: number;
    public readonly terrace: number;
    public readonly flat: number;
    public readonly otherAccommodation: number;

    constructor(
        detached: number,
        semiDetached: number,
        terrace: number,
        flat: number,
        otherAccommodation: number
    ) {
        this.detached = detached;
        this.semiDetached = semiDetached;
        this.terrace = terrace;
        this.flat = flat;
        this.otherAccommodation = otherAccommodation;
    }
}

export class HouseholdTenure {
    public readonly ownedOutright: number;
    public readonly ownedWithLoan: number;
    public readonly socialRent: number;
    public readonly privateRent: number;
    public readonly sharedOwnership: number;
    public readonly livingRentFree: number;

    constructor(
        ownedOutright: number,
        ownedWithLoan: number,
        socialRent: number,
        privateRent: number,
        sharedOwnership: number,
        livingRentFree: number
    ) {
        this.ownedOutright = ownedOutright;
        this.ownedWithLoan = ownedWithLoan;
        this.socialRent = socialRent;
        this.privateRent = privateRent;
        this.sharedOwnership = sharedOwnership;
        this.livingRentFree = livingRentFree;
    }
}

export class ChildrenAgeStructure {
    public readonly childrenAgeLessThan1: number;
    public readonly childrenAge1to2: number;
    public readonly childrenAge3to5: number;
    public readonly childrenAge6to10: number;
    public readonly childrenAge11to16: number;

    constructor(
        childrenAgeLessThan1: number,
        childrenAge1to2: number,
        childrenAge3to5: number,
        childrenAge6to10: number,
        childrenAge11to16: number
    ) {
        this.childrenAgeLessThan1 = childrenAgeLessThan1;
        this.childrenAge1to2 = childrenAge1to2;
        this.childrenAge3to5 = childrenAge3to5;
        this.childrenAge6to10 = childrenAge6to10;
        this.childrenAge11to16 = childrenAge11to16;
    }
}

export class HouseholdOccupancy {
    public readonly occupancyRating2Plus: number;
    public readonly occupancyRating1: number;
    public readonly occupancyRating0: number;
    public readonly occupancyRatingMinus1: number;
    public readonly occupancyRatingMinus2orLess: number;

    constructor(
        occupancyRating2Plus: number,
        occupancyRating1: number,
        occupancyRating0: number,
        occupancyRatingMinus1: number,
        occupancyRatingMinus2orLess: number
    ) {
        this.occupancyRating2Plus = occupancyRating2Plus;
        this.occupancyRating1 = occupancyRating1;
        this.occupancyRating0 = occupancyRating0;
        this.occupancyRatingMinus1 = occupancyRatingMinus1;
        this.occupancyRatingMinus2orLess = occupancyRatingMinus2orLess;
    }
}

export class AgeStructureGB {
    public readonly age0to16: number;
    public readonly age17to25: number;
    public readonly age26to35: number;
    public readonly age36to45: number;
    public readonly age46to55: number;
    public readonly age56to65: number;
    public readonly age66Plus: number;

    constructor(
        age0to16: number,
        age17to25: number,
        age26to35: number,
        age36to45: number,
        age46to55: number,
        age56to65: number,
        age66Plus: number
    ) {
        this.age0to16 = age0to16;
        this.age17to25 = age17to25;
        this.age26to35 = age26to35;
        this.age36to45 = age36to45;
        this.age46to55 = age46to55;
        this.age56to65 = age56to65;
        this.age66Plus = age66Plus;
    }
}

export class AgeStructureNI {
    public readonly age0to15: number;
    public readonly age16to24: number;
    public readonly age25to44: number;
    public readonly age45to64: number;
    public readonly age65Plus: number;

    constructor(
        age0to15: number,
        age16to24: number,
        age25to44: number,
        age45to64: number,
        age65Plus: number
    ) {
        this.age0to15 = age0to15;
        this.age16to24 = age16to24;
        this.age25to44 = age25to44;
        this.age45to64 = age45to64;
        this.age65Plus = age65Plus;
    }
}

export class CountryOfBirth {
    public readonly bornInUK: number;
    public readonly emigratedToUK: number;

    constructor(bornInUK: number, emigratedToUK: number) {
        this.bornInUK = bornInUK;
        this.emigratedToUK = emigratedToUK;
    }
}

export class Ethnicity {
    public readonly arab: number;
    public readonly asianOther: number;
    public readonly bangladeshi: number;
    public readonly blackAfrican: number;
    public readonly blackCaribbean: number;
    public readonly blackOther: number;
    public readonly chinese: number;
    public readonly indian: number;
    public readonly mixed: number;
    public readonly other: number;
    public readonly pakistani: number;
    public readonly white: number;

    constructor(
        arab: number,
        asianOther: number,
        bangladeshi: number,
        blackAfrican: number,
        blackCaribbean: number,
        blackOther: number,
        chinese: number,
        indian: number,
        mixed: number,
        other: number,
        pakistani: number,
        white: number
    ) {
        this.arab = arab;
        this.asianOther = asianOther;
        this.bangladeshi = bangladeshi;
        this.blackAfrican = blackAfrican;
        this.blackCaribbean = blackCaribbean;
        this.blackOther = blackOther;
        this.chinese = chinese;
        this.indian = indian;
        this.mixed = mixed;
        this.other = other;
        this.pakistani = pakistani;
        this.white = white;
    }
}

export class FirstLanguage {
    public readonly englishOrWelsh: number;
    public readonly other: number;

    constructor(englishOrWelsh: number, other: number) {
        this.englishOrWelsh = englishOrWelsh;
        this.other = other;
    }
}

export class HouseholdNumberOfCars {
    public readonly car3Plus: number;
    public readonly car2: number;
    public readonly car1: number;
    public readonly car0: number;

    constructor(
        car3Plus: number,
        car2: number,
        car1: number,
        car0: number
    ) {
        this.car3Plus = car3Plus;
        this.car2 = car2;
        this.car1 = car1;
        this.car0 = car0;
    }
}

export class PopulationDensity {
    public readonly popDensityLess2000: number;
    public readonly popDensity2000to3999: number;
    public readonly popDensity4000to5999: number;
    public readonly popDensity6000to10000: number;
    public readonly popDensity10000Plus: number;

    constructor(
        popDensityLess2000: number,
        popDensity2000to3999: number,
        popDensity4000to5999: number,
        popDensity6000to10000: number,
        popDensity10000Plus: number
    ) {
        this.popDensityLess2000 = popDensityLess2000;
        this.popDensity2000to3999 = popDensity2000to3999;
        this.popDensity4000to5999 = popDensity4000to5999;
        this.popDensity6000to10000 = popDensity6000to10000;
        this.popDensity10000Plus = popDensity10000Plus;
    }
}

export class HouseholdIncome {
    public readonly countIncome100kPlus: number;
    public readonly countIncome90to100k: number;
    public readonly countIncome80to90k: number;
    public readonly countIncome70to80k: number;
    public readonly countIncome60to70k: number;
    public readonly countIncome50to60k: number;
    public readonly countIncome40to50k: number;
    public readonly countIncome30to40k: number;
    public readonly countIncome20to30k: number;
    public readonly countIncomeSub20k: number;

    constructor(
        countIncome100kPlus: number,
        countIncome90to100k: number,
        countIncome80to90k: number,
        countIncome70to80k: number,
        countIncome60to70k: number,
        countIncome50to60k: number,
        countIncome40to50k: number,
        countIncome30to40k: number,
        countIncome20to30k: number,
        countIncomeSub20k: number
    ) {
        this.countIncome100kPlus = countIncome100kPlus;
        this.countIncome90to100k = countIncome90to100k;
        this.countIncome80to90k = countIncome80to90k;
        this.countIncome70to80k = countIncome70to80k;
        this.countIncome60to70k = countIncome60to70k;
        this.countIncome50to60k = countIncome50to60k;
        this.countIncome40to50k = countIncome40to50k;
        this.countIncome30to40k = countIncome30to40k;
        this.countIncome20to30k = countIncome20to30k;
        this.countIncomeSub20k = countIncomeSub20k;
    }
}

export class EducationLevel {
    public readonly noQualification: number;
    public readonly level1: number;
    public readonly level2: number;
    public readonly level3: number;
    public readonly level4Plus: number;
    public readonly otherQualification: number;

    constructor(
        noQualification: number,
        level1: number,
        level2: number,
        level3: number,
        level4Plus: number,
        otherQualification: number
    ) {
        this.noQualification = noQualification;
        this.level1 = level1;
        this.level2 = level2;
        this.level3 = level3;
        this.level4Plus = level4Plus;
        this.otherQualification = otherQualification;
    }
}

export class CatchmentDemographics {
    public readonly storeID: string;
    public readonly dwellingType: DwellingType;
    public readonly householdTenure: HouseholdTenure;
    public readonly childrenAgeStructure: ChildrenAgeStructure;
    public readonly householdOccupancy: HouseholdOccupancy;
    public readonly ageStructureGB: AgeStructureGB;
    public readonly ageStructureNI: AgeStructureNI;
    public readonly countryOfBirth: CountryOfBirth;
    public readonly ethnicity: Ethnicity;
    public readonly firstLanguage: FirstLanguage;
    public readonly householdNumberOfCars: HouseholdNumberOfCars;
    public readonly populationDensity: PopulationDensity;
    public readonly householdIncome: HouseholdIncome;
    public readonly educationLevel: EducationLevel;

    constructor(
        storeID: string,
        dwellingType: DwellingType,
        householdTenure: HouseholdTenure,
        childrenAgeStructure: ChildrenAgeStructure,
        householdOccupancy: HouseholdOccupancy,
        ageStructureGB: AgeStructureGB,
        ageStructureNI: AgeStructureNI,
        countryOfBirth: CountryOfBirth,
        ethnicity: Ethnicity,
        firstLanguage: FirstLanguage,
        householdNumberOfCars: HouseholdNumberOfCars,
        populationDensity: PopulationDensity,
        householdIncome: HouseholdIncome,
        educationLevel: EducationLevel
    ) {
        this.storeID = storeID;
        this.householdIncome = householdIncome;
        this.householdOccupancy = householdOccupancy;
        this.householdTenure = householdTenure;
        this.ageStructureGB = ageStructureGB;
        this.ageStructureNI = ageStructureNI;
        this.childrenAgeStructure = childrenAgeStructure;
        this.countryOfBirth = countryOfBirth;
        this.ethnicity = ethnicity;
        this.firstLanguage = firstLanguage;
        this.dwellingType = dwellingType;
        this.householdNumberOfCars = householdNumberOfCars;
        this.populationDensity = populationDensity;
        this.educationLevel = educationLevel;
    }
}

export const loadCatchmentDemographics = (selectedAndComparatorStores: Store[], catchmentAccountId: string): AppThunk<Promise<CatchmentDemographics[]>> => async (dispatch) => {

    try {

        const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
        selectedAndComparatorStores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
        });

        const orFilterClause = { or: [] };
        retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
            const andFilterClause = {
                and: [{
                    member: "CatchmentDemographics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryID)]
                }, {
                    member: "CatchmentDemographics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const query = {
            dimensions: [
                "CatchmentDemographics.RetailCentreID",
                "CatchmentDemographics.StoreCategory_ID",
                "CatchmentDemographics.Detached",
                "CatchmentDemographics.SemiDetached",
                "CatchmentDemographics.Terrace",
                "CatchmentDemographics.Flat",
                "CatchmentDemographics.OtherAccommodation",
                "CatchmentDemographics.OwnedOutright",
                "CatchmentDemographics.OwnedWithLoan",
                "CatchmentDemographics.SocialRent",
                "CatchmentDemographics.PrivateRent",
                "CatchmentDemographics.SharedOwnership",
                "CatchmentDemographics.LivingRentFree",
                "CatchmentDemographics.ChildrenAgeLessThan1",
                "CatchmentDemographics.ChildrenAge1to2",
                "CatchmentDemographics.ChildrenAge3to5",
                "CatchmentDemographics.ChildrenAge6to10",
                "CatchmentDemographics.ChildrenAge11to16",
                "CatchmentDemographics.OccupancyRating_2Plus",
                "CatchmentDemographics.OccupancyRating_1",
                "CatchmentDemographics.OccupancyRating_0",
                "CatchmentDemographics.OccupancyRating_Minus1",
                "CatchmentDemographics.OccupancyRating_Minus2orLess",
                "CatchmentDemographics.Age0to16",
                "CatchmentDemographics.Age17to25",
                "CatchmentDemographics.Age26to35",
                "CatchmentDemographics.Age36to45",
                "CatchmentDemographics.Age46to55",
                "CatchmentDemographics.Age56to65",
                "CatchmentDemographics.Age66Plus",
                "CatchmentDemographics.Age0to15",
                "CatchmentDemographics.Age16to24",
                "CatchmentDemographics.Age25to44",
                "CatchmentDemographics.Age45to64",
                "CatchmentDemographics.Age65Plus",
                "CatchmentDemographics.NoQualification",
                "CatchmentDemographics.Level1",
                "CatchmentDemographics.Level2",
                "CatchmentDemographics.Level3",
                "CatchmentDemographics.Level4Plus",
                "CatchmentDemographics.OtherQualification",
                "CatchmentDemographics.BornInUk",
                "CatchmentDemographics.EmigratedToUk",
                "CatchmentDemographics.EthArab",
                "CatchmentDemographics.EthAsianOther",
                "CatchmentDemographics.EthBangladeshi",
                "CatchmentDemographics.EthBlackAfrican",
                "CatchmentDemographics.EthBlackCaribbean",
                "CatchmentDemographics.EthBlackOther",
                "CatchmentDemographics.EthChinese",
                "CatchmentDemographics.EthIndian",
                "CatchmentDemographics.EthMixed",
                "CatchmentDemographics.EthOther",
                "CatchmentDemographics.EthPakistani",
                "CatchmentDemographics.EthWhite",
                "CatchmentDemographics.LangEnglishOrWelsh",
                "CatchmentDemographics.LangOther",
                "CatchmentDemographics.Car3Plus",
                "CatchmentDemographics.Car2",
                "CatchmentDemographics.Car1",
                "CatchmentDemographics.Car0",
                "CatchmentDemographics.PopDensityLess2000",
                "CatchmentDemographics.PopDensity2000to3999",
                "CatchmentDemographics.PopDensity4000to5999",
                "CatchmentDemographics.PopDensity6000to10000",
                "CatchmentDemographics.PopDensity10000Plus",
                "CatchmentDemographics.CountIncome_100kPlus",
                "CatchmentDemographics.CountIncome_90to100k",
                "CatchmentDemographics.CountIncome_80to90k",
                "CatchmentDemographics.CountIncome_70to80k",
                "CatchmentDemographics.CountIncome_60to70k",
                "CatchmentDemographics.CountIncome_50to60k",
                "CatchmentDemographics.CountIncome_40to50k",
                "CatchmentDemographics.CountIncome_30to40k",
                "CatchmentDemographics.CountIncome_20to30k",
                "CatchmentDemographics.CountIncome_Sub20k"
            ],
            segments: [
                "CatchmentDemographics.Baseline"
            ],
            filters: [orFilterClause]
        };

        const results = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentDemographics")) as unknown as ExtendedResultSet<any>;
        const rawData = results.loadResponses[0].data;
        const catchmentDemographics: CatchmentDemographics[] = [];
        selectedAndComparatorStores.forEach(store => {
            const isNIStore = store.geoIndicator === GeoIndicator.NI;
            const relevantResult = rawData.find(relevantResult =>
                Number(relevantResult["CatchmentDemographics.RetailCentreID"]) === store.retailCentreID
                && Number(relevantResult["CatchmentDemographics.StoreCategory_ID"]) === store.storeCategoryID);
            if (relevantResult) {
                const catchmentDemographic = new CatchmentDemographics(
                    store.id,
                    new DwellingType(
                        relevantResult["CatchmentDemographics.Detached"],
                        relevantResult["CatchmentDemographics.SemiDetached"],
                        relevantResult["CatchmentDemographics.Terrace"],
                        relevantResult["CatchmentDemographics.Flat"],
                        relevantResult["CatchmentDemographics.OtherAccommodation"]
                    ),
                    new HouseholdTenure(
                        relevantResult["CatchmentDemographics.OwnedOutright"],
                        relevantResult["CatchmentDemographics.OwnedWithLoan"],
                        relevantResult["CatchmentDemographics.SocialRent"],
                        relevantResult["CatchmentDemographics.PrivateRent"],
                        relevantResult["CatchmentDemographics.SharedOwnership"],
                        relevantResult["CatchmentDemographics.LivingRentFree"]
                    ),
                    new ChildrenAgeStructure(
                        isNIStore ? null : relevantResult["CatchmentDemographics.ChildrenAgeLessThan1"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.ChildrenAge1to2"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.ChildrenAge3to5"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.ChildrenAge6to10"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.ChildrenAge11to16"]
                    ),
                    new HouseholdOccupancy(
                        isNIStore ? null : relevantResult["CatchmentDemographics.OccupancyRating_2Plus"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.OccupancyRating_1"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.OccupancyRating_0"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.OccupancyRating_Minus1"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.OccupancyRating_Minus2orLess"]
                    ),
                    new AgeStructureGB(
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age0to16"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age17to25"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age26to35"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age36to45"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age46to55"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age56to65"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Age66Plus"]
                    ),
                    new AgeStructureNI(
                        isNIStore ? relevantResult["CatchmentDemographics.Age0to15"] : null,
                        isNIStore ? relevantResult["CatchmentDemographics.Age16to24"] : null,
                        isNIStore ? relevantResult["CatchmentDemographics.Age25to44"] : null,
                        isNIStore ? relevantResult["CatchmentDemographics.Age45to64"] : null,
                        isNIStore ? relevantResult["CatchmentDemographics.Age65Plus"] : null
                    ),
                    new CountryOfBirth(
                        relevantResult["CatchmentDemographics.BornInUk"],
                        relevantResult["CatchmentDemographics.EmigratedToUk"]
                    ),
                    new Ethnicity(
                        relevantResult["CatchmentDemographics.EthArab"],
                        relevantResult["CatchmentDemographics.EthAsianOther"],
                        relevantResult["CatchmentDemographics.EthBangladeshi"],
                        relevantResult["CatchmentDemographics.EthBlackAfrican"],
                        relevantResult["CatchmentDemographics.EthBlackCaribbean"],
                        relevantResult["CatchmentDemographics.EthBlackOther"],
                        relevantResult["CatchmentDemographics.EthChinese"],
                        relevantResult["CatchmentDemographics.EthIndian"],
                        relevantResult["CatchmentDemographics.EthMixed"],
                        relevantResult["CatchmentDemographics.EthOther"],
                        relevantResult["CatchmentDemographics.EthPakistani"],
                        relevantResult["CatchmentDemographics.EthWhite"]
                    ),
                    new FirstLanguage(
                        relevantResult["CatchmentDemographics.LangEnglishOrWelsh"],
                        relevantResult["CatchmentDemographics.LangOther"]
                    ),
                    new HouseholdNumberOfCars(
                        relevantResult["CatchmentDemographics.Car3Plus"],
                        relevantResult["CatchmentDemographics.Car2"],
                        relevantResult["CatchmentDemographics.Car1"],
                        relevantResult["CatchmentDemographics.Car0"]
                    ),
                    new PopulationDensity(
                        relevantResult["CatchmentDemographics.PopDensityLess2000"],
                        relevantResult["CatchmentDemographics.PopDensity2000to3999"],
                        relevantResult["CatchmentDemographics.PopDensity4000to5999"],
                        relevantResult["CatchmentDemographics.PopDensity6000to10000"],
                        relevantResult["CatchmentDemographics.PopDensity10000Plus"]
                    ),
                    new HouseholdIncome(
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_100kPlus"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_90to100k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_80to90k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_70to80k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_60to70k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_50to60k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_40to50k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_30to40k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_20to30k"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.CountIncome_Sub20k"]
                    ),
                    new EducationLevel(
                        isNIStore ? null : relevantResult["CatchmentDemographics.NoQualification"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Level1"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Level2"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Level3"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.Level4Plus"],
                        isNIStore ? null : relevantResult["CatchmentDemographics.OtherQualification"]
                    )
                );
                catchmentDemographics.push(catchmentDemographic);
            }
        });

        return catchmentDemographics;
    } catch (error) {
        dispatch(logError("Error loading CatchmentDemographics.", error));
        throw error;
    }
};
