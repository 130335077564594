import React from "react";

import { Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RadarChart from "pages/customer/insights/portfolio/filters/RadarChart";

import { Comparator, ComparatorType } from "modules/customer/insights/portfolio/comparator";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent"
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        minHeight: theme.spacing(7)
    }
}))(CardActions);

interface ComparatorCardProps {
    comparator: Comparator,
    selected: boolean,
    onSelect: (comparator: Comparator) => void,
    onDeselect: (comparator: Comparator) => void
}

const ComparatorCard: React.FC<ComparatorCardProps> = (props) => {
    const { comparator, selected, onSelect, onDeselect } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(comparator);
        } else {
            onSelect(comparator);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardContent>
                    <RadarChart
                        salesScore={comparator.getSalesScore()}
                        driversScore={comparator.getDriversScore()}
                        profitScore={comparator.getProfitScore()}
                        areaHealthScore={comparator.getAreaHealthScore()}
                        competitionScore={comparator.getCompetitionScore()}
                        catchmentScore={comparator.getCatchmentScore()}
                        totalScore={comparator.getTotalScore()}
                    />
                </StyledCardContent>
                <StyledCardActions>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div">
                                {comparator.name}
                            </Typography>
                        </Grid>
                        {comparator.type !== ComparatorType.Custom && (
                            <Grid item xs={4}>
                                <Typography variant="body2" component="div" align="right">
                                    Total score:
                                </Typography>
                                <Typography variant="h5" component="div" align="right">
                                    {numberFormatter.toDecimalPlaces(comparator.getTotalScore(), 1)}/30.0
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </StyledCardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default ComparatorCard;
