import React from "react";
import { useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageCallToAction from "components/landing/PageCallToAction";
import PageHeader from "components/landing/PageHeader";

import Form from "./Form";

import { clearContact, getSubjects, selectContact, setContact } from "modules/landing/contactUs/contactUsSlice";
import { useAppDispatch, useAppSelector } from "store";

const ContactUs: React.FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchParamsSubject = searchParams.get("subject");
    const contact = useAppSelector(selectContact);
    const title = contact.subject === "book-a-demo" ? "Book a demo" : "Get in touch";
    const subtitle = contact.subject === "book-a-demo" ? "A 30 minute overview of Dash features" : "Our specialist team will respond within 48 hours";

    React.useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(clearContact());
        dispatch(getSubjects());
    }, [dispatch, searchParamsSubject]);

    React.useEffect(() => {
        if (!searchParamsSubject) {
            return;
        }
        if (contact.subject) {
            return;
        }

        const subject = searchParamsSubject;
        const newContact = { ...contact, subject };
        dispatch(setContact(newContact));
    }, [dispatch, searchParamsSubject, contact]);

    React.useEffect(() => {
        // @ts-ignore
        window.dashLinkedInPixel && window.dashLinkedInPixel();
        const adobePixelEvent = searchParamsSubject === "book-a-demo" ? "ev_bookdemo_start" : "ev_contactus_start";
        // @ts-ignore
        window.dashAdobePixel && window.dashAdobePixel(adobePixelEvent);
    }, [searchParamsSubject]);

    return (
        // @ts-ignore
        <Page dataCy="pages-landing-contact-us">
            <PageHeader
                title={title}
                subtitle={subtitle}
                dataCy="pages-landing-contact-us-header"
            />
            <Form />
            <PageCallToAction
                bgcolor={theme.palette.common.white}
                title="Quickly check our FAQs"
                subtitle="Learn more through our Frequently Asked Questions"
                actionLabel="View FAQs"
                actionUrl="/frequently-asked-questions"
                buttonVariant="outlined"
                dataCy="pages-landing-contact-us-call-to-action"
            />
        </Page>
    );
};

export default ContactUs;
