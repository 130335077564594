import React from "react";
import Highcharts from "highcharts";

import { useTheme } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";
import Bar from "components/visuals/Bar";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface BarChartProps {
    store: CostReductionOpportunityByStore
}

const BarChart: React.FC<BarChartProps> = (props) => {
    const {
        store
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const categories = [`${store.storeName} cost`, "Average cost (cluster)"];
    const values = [store.costValue, store.similarStoresAverageCostValue];

    const options: Highcharts.Options = {
        chart: {
            height: theme.spacing(20),
            backgroundColor: 'transparent'
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories,
            labels: {
                enabled: true
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        plotOptions: {
            bar: {
                pointPadding: 0.01,
                groupPadding: 0.01
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            footerFormat: ``,
            // @ts-ignore
            formatter: function () {
                const header = `${store.costName} cost value`;

                const categoriesArr = [
                    `${store.storeName}`,
                    "Average cost (cluster)",
                    "Variance",
                    `${store.costName} costs (% revenue)`,
                    `${store.storeName}`,
                    "Average cost (cluster)",
                    "Variance"
                ];
                const categoriesFormatArr = [
                    `color:${colourPalette.comparators[0]};font-weight:bold`,
                    `color:${colourPalette.comparators[1]};font-weight:bold`,
                    `color:${theme.palette.text.primary}`,
                    `color:${theme.palette.text.primary};font-weight:bold`,
                    `color:${colourPalette.comparators[0]};font-weight:bold`,
                    `color:${colourPalette.comparators[1]};font-weight:bold`,
                    `color:${theme.palette.text.primary}`
                ];
                
                const valuesArr = [
                    numberFormatter.toGBP(store.costValue, 1),
                    numberFormatter.toGBP(store.similarStoresAverageCostValue, 1),
                    numberFormatter.toPercentage(
                        ((store.costValue / store.similarStoresAverageCostValue) - 1),
                        false,
                        1
                    ),
                    "",
                    numberFormatter.toPercentage((store.costAsPercentageOfRevenue * 100), true, 2),
                    numberFormatter.toPercentage((store.similarStoresAverageCostPercentageOfRevenue * 100), true, 2),
                    numberFormatter.toPercentage(
                        ((store.costAsPercentageOfRevenue * 100) - (store.similarStoresAverageCostPercentageOfRevenue * 100)),
                        true,
                        2
                    )
                ];

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr,
                        valueFormatting: ""
                    }
                )}</table>`;
            },
            useHTML: true,
            outside: true,
            positioner: function (labelWidth, labelHeight, point) {
                const x = point.plotX + this.chart.plotLeft;
                const y = point.plotY + this.chart.plotTop - labelHeight;
                return { x: x, y: Math.max(y, 0) };
            },
            style: {
                zIndex: 10000
            }
        },
        series: [{
            data: values.map((value, index) => ({
                y: value,
                color: colourPalette.comparators[index]
            })),
            type: "bar",
            dataLabels: {
                enabled: false
            }
        }],
        exporting: {
            enabled: false
        }
    };

    return (
        <Bar loading={false} error={false} options={options} />
    );
};

export default BarChart;
