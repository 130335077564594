import { combineReducers } from "redux";

import types from "./types";

const storeYoYGrowthVsComparatorEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const storeYoYGrowthVsComparator = (state = storeYoYGrowthVsComparatorEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_STORE_YOY_GROWTH_VS_COMPARATOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const storeForecastYoYGrowthVsComparatorEmpty = {
    loading: false,
    error: false,
    value: 0,
    percentageDifference: 0
};

const storeForecastYoYGrowthVsComparator = (state = storeForecastYoYGrowthVsComparatorEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                percentageDifference: 0
            };
        case types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                percentageDifference: action.payload.percentageDifference
            };
        case types.GET_STORE_FORECAST_YOY_GROWTH_VS_COMPARATOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                percentageDifference: 0
            };
        default:
            return state;
    }
};

const storeVsComparatorMoMGrowthEmpty = {
    loading: false,
    error: false,
    store: [],
    comparator: []
};

const storeVsComparatorMoMGrowth = (state = storeVsComparatorMoMGrowthEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: [],
                comparator: []
            };
        case types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator,
            };
        case types.GET_STORE_VS_COMPARATOR_MOM_GROWTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: [],
                comparator: []
            };
        default:
            return state;
    }
};

const storeHistoricGrowthVsComparatorEmpty = {
    loading: false,
    error: false,
    id: "store-historic-growth-vs-comparator",
    label: "Store Historic Growth vs comparator",
    status: "info",
    value: ""
};

const storeHistoricGrowthVsComparator = (state = storeHistoricGrowthVsComparatorEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_STORE_HISTORIC_GROWTH_VS_COMPARATOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const storeForecastGrowthVsComparatorEmpty = {
    loading: false,
    error: false,
    id: "store-forecast-growth-vs-comparator",
    label: "Store Forecast Growth vs comparator",
    status: "info",
    value: ""
};

const storeForecastGrowthVsComparator = (state = storeForecastGrowthVsComparatorEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_STORE_FORECAST_GROWTH_VS_COMPARATOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};
const reducer = combineReducers({
    storeYoYGrowthVsComparator,
    storeForecastYoYGrowthVsComparator,
    storeVsComparatorMoMGrowth,
    storeHistoricGrowthVsComparator,
    storeForecastGrowthVsComparator
});

export default reducer;
