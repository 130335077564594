import React from "react";
import { Box } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const Error = () => {
    return (
        <Box align="center">
            <ErrorOutlineIcon color="error" fontSize="large"/>
        </Box>
    );
};

export default Error;
