import React from "react";
import { Grid } from "@material-ui/core";

import RevenueLastYear from "./RevenueLastYear";
import UnitSalesLastYear from "./UnitSalesLastYear";
import MedianWeeklyRevenue from "./MedianWeeklyRevenue";
import MedianWeeklyUnitSales from "./MedianWeeklyUnitSales";
import RevenueUnitSalesOverTime from "./RevenueUnitSalesOverTime";
import UnitsSalesTrendRag from "./UnitsSalesTrendRag";
import RevenueTrendRag from "./RevenueTrendRag";

const HistoricSales = () => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <RevenueLastYear />
            </Grid>
            <Grid item xs={12} md={3}>
                <UnitSalesLastYear />
            </Grid>
            <Grid item xs={12} md={3}>
                <MedianWeeklyRevenue />
            </Grid>
            <Grid item xs={12} md={3}>
                <MedianWeeklyUnitSales />
            </Grid>
            <Grid item xs={12}>
                <RevenueUnitSalesOverTime />
            </Grid>
            <Grid item xs={12}>
                <RevenueTrendRag />
            </Grid>
            <Grid item xs={12}>
                <UnitsSalesTrendRag />
            </Grid>
        </Grid>
    );
};

export default HistoricSales;

