import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/revenue";

const StoreForecastRevenueVsPY = () => {
    const selectedStore = useAppSelector(selectStore);
    const storeForecastRevenueVsPY = useSelector(state => selectors.storeForecastRevenueVsPY(state));
    const { loading, error, value, percentageDifference } = storeForecastRevenueVsPY;
    const valueFormatted = numberFormatter.toGBP(value, 1);
    const label = `${selectedStore.name} next 12 months forecast revenue vs previous year`;

    return (
        <Box width="100%" height="100%" data-cy="store-forecast-revenue-vs-py">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default StoreForecastRevenueVsPY;
