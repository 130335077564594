import React from "react";
import { Box, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicatorsGroup from "components/visuals/RAGIndicatorsGroup";

import { selectors } from "modules/customer/insights/performance/overview/recommendations";

const Recommendations = () => {
    const allRags = useSelector(state => selectors.allRags(state));
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box data-cy="all-rags">
                    <RAGIndicatorsGroup rags={allRags} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Recommendations;
