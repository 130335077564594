import types from "./types";

const getHistoricProdGrowthStoreVsCompRequest = () => ({
    type: types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_REQUEST
});

const getHistoricProdGrowthStoreVsCompSuccess = (values) => ({
    type: types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_SUCCESS,
    payload: {
        values
    }
});

const getHistoricProdGrowthStoreVsCompFailure = () => ({
    type: types.GET_HISTORIC_PROD_GROWTH_STORE_VS_COMP_FAILURE
});

const actions = {
    getHistoricProdGrowthStoreVsCompRequest,
    getHistoricProdGrowthStoreVsCompSuccess,
    getHistoricProdGrowthStoreVsCompFailure
};

export default actions;
