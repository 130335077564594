import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Take a first look at the impact of opening a new store in your selected location. Understand where you win
            new customers and where you're sharing customers with your existing stores. Gain valuable insights to refine
            your expansion strategy and maximize retail success.
        </>
    );
};

export default Subtitle;
