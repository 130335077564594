import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Understand the potential of your pinned location by generating a revenue prediction. Assess what location
            features are positively or negatively impacting the predicted value.
        </>
    );
};

export default Subtitle;
