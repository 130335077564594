import React from "react";
import { Marker as MapGLMarker } from "react-map-gl";

import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import { useTheme } from "@material-ui/core/styles";

import { selectLocalAreaHotspot } from "modules/customer/tools/location/competition/competitionSlice";
import { useAppSelector } from "store";

const Hotspot: React.FC = () => {
    const theme = useTheme();
    const hotspot = useAppSelector(selectLocalAreaHotspot);

    if (!hotspot) {
        return null;
    }

    return (
        <MapGLMarker
            latitude={hotspot.latitude}
            longitude={hotspot.longitude}
            offsetTop={-10}
            offsetLeft={-12}
        >
            <RadioButtonCheckedRoundedIcon fontSize="small" style={{ color: theme.palette.common.white }} />
        </MapGLMarker>
    );
};

export default Hotspot;
