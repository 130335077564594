import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class PredictionFeature {
    public readonly id: number;
    public readonly name: string;
    public readonly description: string;
    public readonly positivePrefix: string;
    public readonly negativePrefix: string;
    public readonly suffix: string;

    constructor(
        id: number,
        name: string,
        description: string,
        positivePrefix: string,
        negativePrefix: string,
        suffix: string
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.positivePrefix = positivePrefix;
        this.negativePrefix = negativePrefix;
        this.suffix = suffix;
    }
}

export const loadPredictionFeatures = (): AppThunk<Promise<PredictionFeature[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "FeatureDescriptions.FeatureID",
                "FeatureDescriptions.FeatureName",
                "FeatureDescriptions.FeatureDescription",
                "FeatureDescriptions.PositivePrefix",
                "FeatureDescriptions.NegativePrefix",
                "FeatureDescriptions.Suffix"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new PredictionFeature(
            row["FeatureDescriptions.FeatureID"] ?? 0,
            row["FeatureDescriptions.FeatureName"] ?? "",
            row["FeatureDescriptions.FeatureDescription"] ?? "",
            row["FeatureDescriptions.PositivePrefix"] ?? "",
            row["FeatureDescriptions.NegativePrefix"] ?? "",
            row["FeatureDescriptions.Suffix"] ?? ""
        ));
    } catch (error) {
        dispatch(logError("Error loading PredictionFeatures.", error));
        throw error;
    }
};
