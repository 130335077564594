import React from "react";

import { Grid } from "@material-ui/core";

import DemographicAlignment from "./DemographicAlignment";
import OverallDemographicAlignmentScore from "./OverallDemographicAlignmentScore";
import CatchmentAreaDemographicCharacteristics from "./CatchmentAreaDemographicCharacteristics";

const AlignmentToDemographicsBenchmark: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DemographicAlignment />
            </Grid>
            <Grid item xs={12}>
                <OverallDemographicAlignmentScore />
            </Grid>
            <Grid item xs={12}>
                <CatchmentAreaDemographicCharacteristics />
            </Grid>
        </Grid>
    );
};

export default AlignmentToDemographicsBenchmark;
