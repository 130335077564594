import { createAppAsyncThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { KPMGSpendCategory } from "./kpmgSpendCategory";
import { ExtendedResultSet, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";

export class CatchmentSpendTotals {
    public readonly storeID: string;
    public readonly kpmgSpendCategory: string;
    public readonly unweightedSpend: number;
    public readonly weightedSpend: number;

    constructor(
        storeID: string,
        kpmgSpendCategory: string,
        unweightedSpend: number,
        weightedSpend: number,
    ) {
        this.storeID = storeID;
        this.kpmgSpendCategory = kpmgSpendCategory;
        this.unweightedSpend = unweightedSpend;
        this.weightedSpend = weightedSpend;
    }
}

export const loadCatchmentSpendTotals =  createAppAsyncThunk<
    CatchmentSpendTotals[],
    {
        selectedStore: Store | undefined,
        comparatorStores: Store[] | undefined,
        spendCategories: KPMGSpendCategory[],
        catchmentAccountId: string
    }
    >(
    "customer/insights/portfolio/loadCatchmentSpendTotals",
    async ({selectedStore, comparatorStores, spendCategories, catchmentAccountId}, thunkAPI) => {
        try {
            if (!selectedStore || !comparatorStores) {
                return [];
            }
            const stores = [selectedStore].concat(comparatorStores);
            const retailCentreIDsByStoreCategoryID = new Map<number, number[]>();
            stores.forEach(store => {
                const retailCentreIDs = retailCentreIDsByStoreCategoryID.get(store.storeCategoryID) ?? [];
                retailCentreIDs.push(store.retailCentreID);
                retailCentreIDsByStoreCategoryID.set(store.storeCategoryID, retailCentreIDs);
            });
    
            const orFilterClause = { or: [] };
            retailCentreIDsByStoreCategoryID.forEach((retailCentreIDs, storeCategoryID) => {
                const andFilterClause = {
                    and: [{
                        member: "CatchmentMarketCategorySpend.StoreCategory_ID",
                        operator: "equals",
                        values: [String(storeCategoryID)]
                    }, {
                        member: "CatchmentMarketCategorySpend.RetailCentreID",
                        operator: "equals",
                        values: retailCentreIDs.map(String)
                    }]
                };
                // @ts-ignore
                orFilterClause.or.push(andFilterClause);
            });

            const spendCategoryIDs = spendCategories.map(category => String(category.id));

            const query = {
                measures: [
                    "CatchmentMarketCategorySpend.sumCategorySpend",
                    "CatchmentMarketCategorySpend.sumBaselineWeightedSpend"
                ],
                dimensions: [
                    "CatchmentMarketCategorySpend.RetailCentreID",
                    "CatchmentMarketCategorySpend.StoreCategory_ID",
                    "CatchmentMarketCategorySpend.SpendCategory_ID",
                ],
                filters: [
                    orFilterClause,
                {
                    member: "CatchmentMarketCategorySpend.SpendCategory_ID",
                    operator: "equals",
                    values: spendCategoryIDs
                }],
                segments: [
                    "CatchmentMarketCategorySpend.Baseline"
                ]
            };
            
            const resultSet =
                await thunkAPI.dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "CatchmentMarketCategorySpend")) as unknown as ExtendedResultSet<any>;
            const rawData = resultSet.loadResponses[0].data;
            const catchmentSpendTotals: CatchmentSpendTotals[] = [];
            stores.forEach(store => {
                const relevantResults = rawData.filter(row =>
                    Number(row["CatchmentMarketCategorySpend.RetailCentreID"]) === store.retailCentreID
                    && Number(row["CatchmentMarketCategorySpend.StoreCategory_ID"]) === store.storeCategoryID);

                const storeCustomerProfileSummary = relevantResults.map(row => {
                    const categoryID = row["CatchmentMarketCategorySpend.SpendCategory_ID"];
                    const categoryName = spendCategories.find(item => item.id === categoryID)?.name ?? "";

                    return new CatchmentSpendTotals(
                        store.id,
                        categoryName,
                        Number(row["CatchmentMarketCategorySpend.sumCategorySpend"]),
                        Number(row["CatchmentMarketCategorySpend.sumBaselineWeightedSpend"])
                    );
                });
                catchmentSpendTotals.push(...storeCustomerProfileSummary);
            });
            
            return catchmentSpendTotals;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading CatchmentSpendTotals.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
