import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { apiGet, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Category {
    id: string,
    name: string,
    questions: Question[]
}

interface Question {
    id: string,
    question: string,
    answer: string
}

interface CallbackVisibility {
    isVisible: boolean
}

interface CallbackForm {
    name: string,
    subject: string,
    description: string,
    phoneNumber: string,
    errors: CallbackFormErrors
}

interface CallbackFormErrors {
    name: string,
    subject: string,
    description: string,
    phoneNumber: string
}

interface HelpState {
    categories: Category[],
    callbackVisibility: CallbackVisibility,
    callbackForm: CallbackForm
}

const initialState: HelpState = {
    categories: [],
    callbackVisibility: {
        isVisible: false
    },
    callbackForm: {
        name: "",
        subject: "",
        description: "",
        phoneNumber: "",
        errors: {
            name: "",
            subject: "",
            description: "",
            phoneNumber: ""
        }
    }
};

const helpSlice = createSlice({
    name: "customer/help",
    initialState,
    reducers: {
        showCallback: (state) => {
            state.callbackVisibility.isVisible = true;
        },
        hideCallback: (state) => {
            state.callbackVisibility = initialState.callbackVisibility;
        },
        setCallbackForm: (state, action: PayloadAction<CallbackForm>) => {
            state.callbackForm = action.payload;
        },
        clearCallbackForm: (state) => {
            state.callbackForm = initialState.callbackForm;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(getFrequentlyAskedQuestions.rejected, (state: HelpState) => {
            state.categories = initialState.categories;
        });
        builder.addCase(getFrequentlyAskedQuestions.fulfilled, (state: HelpState, action: PayloadAction<Category[]>) => {
            state.categories = action.payload;
        });
    }
});

export const {
    showCallback,
    hideCallback,
    setCallbackForm,
    clearCallbackForm
} = helpSlice.actions;

export const getFrequentlyAskedQuestions = createAppAsyncThunk(
    "customer/help/getFrequentlyAskedQuestions",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/customer/help/frequently-asked-questions"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.categories;
        }

        thunkAPI.dispatch(notifyError("Error loading frequently asked questions."));
        return thunkAPI.rejectWithValue(null);
    }
);

export const requestCallback = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const form = selectCallbackForm(state);
    const response = await dispatch(apiPost("/customer/help", form));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(helpSlice.actions.hideCallback());
            dispatch(helpSlice.actions.clearCallbackForm());
            dispatch(notifySuccess("Thank you. You've successfully requested a callback."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0],
                subject: response.errorData?.errors?.subject?.[0],
                description: response.errorData?.errors?.description?.[0],
                phoneNumber: response.errorData?.errors?.phoneNumber?.[0]
            };
            dispatch(helpSlice.actions.setCallbackForm({ ...form, errors }));
            break;
        }
    }
};

export const downloadExternalDataSourceLicenses = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/help/external-data-source-licenses"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const downloadOpenSourceSoftware = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/help/open-source-software"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const downloadDataGuidance = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/help/data-guidance"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

export const selectFrequentlyAskedQuestionsCategoriesWithQuestions = (state: RootState) => {
    return state.customer.help.categories;
};

export const selectCallbackVisibility = (state: RootState) => {
    return state.customer.help.callbackVisibility;
};

export const selectCallbackForm = (state: RootState) => {
    return state.customer.help.callbackForm;
};

export const selectCanRequestCallback = createSelector(
    selectCallbackForm,
    (callbackForm) => {
        const allFilled = !!callbackForm.name
            && !!callbackForm.subject
            && !!callbackForm.description
            && !!callbackForm.phoneNumber;

        const hasErrors = !!callbackForm.errors.name
            || !!callbackForm.errors.subject
            || !!callbackForm.errors.description
            || !!callbackForm.errors.phoneNumber;

        return allFilled && !hasErrors;
    }
);

export default helpSlice;
