import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Button, Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    }
}))(Card);

const StyledTextCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
}))(Card);

const StyledImageCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingTop: theme.spacing(2)
    }
}))(Card);

const UploadData: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    return (
        <StyledCard elevation={0}>
            <Grid container direction="row-reverse">
                <Grid item md={6} xs={12}>
                    <StyledImageCard elevation={0}>
                        <CardMedia
                            component="img"
                            image="/content/customer/dashboard/circles.svg"
                            alt="upload data"
                        />
                    </StyledImageCard>
                </Grid>
                <Grid item md={6} xs={12}>
                    <StyledTextCard elevation={0}>
                        <Typography variant="h3" component="div" align={align}>
                            Welcome to Dash
                        </Typography>
                        <Box paddingTop={2}>
                            <Typography variant="h4" component="div" align={align}>
                                Add your data to view unique recommendations
                            </Typography>
                        </Box>
                        <Box paddingTop={3} display="flex" justifyContent={align}>
                            <Button
                                component={NavLink}
                                to="/data"
                                variant="contained"
                                size="large"
                                color="primary"
                                disableElevation
                                data-cy="btn-upload-data"
                            >
                                Upload data
                            </Button>
                        </Box>
                    </StyledTextCard>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default UploadData;
