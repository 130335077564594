import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import StockBar from "components/visuals/StockBar";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const CategoryYTDSales = () => {
    const dispatch = useDispatch();
    const categoryYTDSales = useSelector(state => selectors.categoryYTDSales(state));
    const { loading, error, categorySales, drilldownData } = categoryYTDSales;
    categorySales.sort((a,b) => {
        return b.y - a.y;
    });

    const options = {
        chart: {
              marginRight: 50
        },
        title: {
            text: "Revenue in the year-to-date by category"
        },
        tooltip: {
            formatter() {
                return '<b>' + this.key + '</b><br/>Revenue ' + numberFormatter.toGBP(this.y);
            }
        },
        series: [
            {
                name: "Product Categories",
                data: categorySales
            }],
        drilldown: {
            series: drilldownData
        },
        yAxis: {
            labels: {
                formatter: function() {return numberFormatter.toGBP(this.value, 0);}
            }
        }
    };

    React.useEffect(() => {
        dispatch(operations.getCategoryYTDSales());
    }, [dispatch]);

    return (
        <Box data-cy="category-ytd-sales">
            <StockBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CategoryYTDSales;
