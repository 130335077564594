import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectCostReferenceDate } from "modules/customer/insights/portfolio/portfolioSlice";

const NetProfitSubtitle = () => {
    const store = useAppSelector(selectStore);

    const referenceDate = useAppSelector(selectCostReferenceDate);
    const priorTwelveMonthsToReferenceDate = referenceDate?.minus({ months: 12 }).plus({ days: 1 });

    return (
        <>
            Revenue to net profit breakdown for {store?.name} between {priorTwelveMonthsToReferenceDate?.toFormat("dd/MM/yyyy")} to {referenceDate?.toFormat("dd/MM/yyyy")}
        </>
    );
};

export default NetProfitSubtitle;
