import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectProfitabilityEvaluation } from "modules/customer/insights/portfolioNew/profit/profitSlice";

const ProfitabilityEvaluation = () => {
    const profitabilityEvaluation = useAppSelector(selectProfitabilityEvaluation);
    return (
        <RAGIndicatorNew ragIndicator={profitabilityEvaluation} />
    );
};

export default ProfitabilityEvaluation;
