import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/forecast/forecastRevenue";

const ForecastSalesWOW = () => {
    const dispatch = useDispatch();
    const forecastSalesWOW = useSelector(state => selectors.forecastSalesWOW(state));
    const { loading, error, nextWeekValue, percentageDifference } = forecastSalesWOW;
    const label = "Forecast revenue next week vs historic revenue in the same week last year";
    const valueFormatted = numberFormatter.toGBP(nextWeekValue, 1);

    React.useEffect(() => {
        dispatch(operations.getForecastSalesWOW());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="forecast-sales-wow">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default ForecastSalesWOW;
