import React from "react";

import { Grid } from "@material-ui/core";

import StoreCategoryOpeningsVsClosures from "./StoreCategoryOpeningsVsClosures";

const StoreCategoryBreakdown: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreCategoryOpeningsVsClosures />
            </Grid>
        </Grid>

    );
};

export default StoreCategoryBreakdown;
