import React from "react";

import { Box, Card, CardContent, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(22),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const Legend: React.FC = () => {
    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <LocationOnOutlinedIcon />
                    <Typography variant="body1" component="div">
                        &nbsp;&nbsp;Pinned location
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <RemoveRoundedIcon />
                    <Typography variant="body1" component="div">
                        &nbsp;&nbsp;Selected location
                    </Typography>
                </Box>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
