import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIFact from "components/visuals/KPIFact";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const SalesYTD = () => {
    const dispatch = useDispatch();
    const salesYTD = useSelector(state => selectors.salesYTD(state));
    const { loading, error, value, label } = salesYTD;
    const valueFormatted = numberFormatter.toGBP(value, 1);

    React.useEffect(() => {
        dispatch(operations.getSalesYTD());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="sales-ytd">
            <KPIFact loading={loading} error={error} label={label} value={valueFormatted} />
        </Box>
    );
};

export default SalesYTD;
