import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const PopulationDensity: React.FC = () => {
    const title = "Population density (Urbanicity)";
    const categories = [
        "<2,000",
        "2,000-3,999",
        "4,000-5,999",
        "6,000-10,000",
        ">10,000"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.populationDensity;
    const selectedStoreValues = selectedStore ? [
        selectedStore.popDensityLess2000,
        selectedStore.popDensity2000to3999,
        selectedStore.popDensity4000to5999,
        selectedStore.popDensity6000to10000,
        selectedStore.popDensity10000Plus
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.populationDensity;
    const comparatorValues = comparator ? [
        comparator.popDensityLess2000,
        comparator.popDensity2000to3999,
        comparator.popDensity4000to5999,
        comparator.popDensity6000to10000,
        comparator.popDensity10000Plus
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default PopulationDensity;
