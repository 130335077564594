const GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_REQUEST = "spark/customer/insights/performance/forecast/forecastGrowthVsMarket/GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_REQUEST";
const GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_SUCCESS = "spark/customer/insights/performance/forecast/forecastGrowthVsMarket/GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_SUCCESS";
const GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_FAILURE = "spark/customer/insights/performance/forecast/forecastGrowthVsMarket/GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_FAILURE";

const types = {
    GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_REQUEST,
    GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_SUCCESS,
    GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_FAILURE
};

export default types;
