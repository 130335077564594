import React from "react";
import { Box } from "@material-ui/core";
import BeeSwarm from "components/visuals/BeeSwarm";
import arrayUtils from "utils/arrayUtils";
import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression } from "utils/sortUtils";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";
import useColourPalette from "components/visuals/useColourPalette";
import { max, round } from "mathjs";

interface RankedStoresBeeswarmProps {
    isLoading: boolean,
    hasErrors: boolean,
    dataCy: string,
    title: string,
    selectedStoreName: string,
    comparatorName: string,
    valueName: string,
    storesWithValues: StoreWithValue[]
}

interface StoreWithValue {
    storeName: string,
    isSelected: boolean,
    value: number,
    rank: number,
    percentile?: number
}

const RankedStoresBeeswarm: React.FC<RankedStoresBeeswarmProps> = (props) => {
    const colourPalette = useColourPalette();
    const { isLoading, hasErrors, dataCy, title, selectedStoreName, comparatorName, valueName, storesWithValues } = props;

    const allDataPoints = storesWithValues.map(item => item.value);
    const firstTertile = arrayUtils.quantile(allDataPoints, 1 / 3);
    const secondTertile = arrayUtils.quantile(allDataPoints, 2 / 3);

    const selectedStore = storesWithValues.filter(store => store.isSelected);
    const comparatorStores = storesWithValues.filter(store => !store.isSelected);

    const useSample = (comparatorStores.length >= 100);
    const sampleComparatorStores = [];
    let thresholds: number[] = [];
    if (useSample) {
        thresholds = mathUtils.percentileThresholds(allDataPoints, 100);

        const sampleSize = 11;
        const sortedStores =
            comparatorStores.sort((a, b) => numberSortExpression(a.value, b.value, SortDirection.ASC));
        const numberOfStores = sortedStores.length;
        for (let i = numberOfStores - 1; sampleComparatorStores.length < sampleSize; i -= numberOfStores / (sampleSize - 1)) {
            const roundedIndex = round(max(i, 0));
            const sampleStore = sortedStores[roundedIndex];
            let percentile = thresholds.findIndex(threshold => sampleStore.value <= threshold) + 1;
            if (percentile === 0) {
                percentile = 100;
            }
            sampleComparatorStores.push({
                ...sampleStore,
                percentile
            });
        }
    }
    
    const selectedStoreData = selectedStore.map(store => {
        let percentile = thresholds.findIndex(threshold => store.value <= threshold) + 1;
        if (percentile === 0) {
            percentile = 100;
        }
        return {
            x: mathUtils.round(store.value, 2),
            custom: {
                storeName: store.storeName,
                rank: store.rank,
                percentile
            }
        };
    });

    const comparatorStoresData = (useSample ? sampleComparatorStores : comparatorStores).map(store => {
        return {
            x: mathUtils.round(store.value, 2),
            custom: {
                storeName: store.storeName,
                rank: store.rank,
                percentile: store.percentile
            }
        };
    });

    const options = {
        chart: {
            height: 230
        },
        title: {
            text: title,
            margin: 30
        },
        xAxis: {
            plotBands: [
                {
                    from: -1e6, to: firstTertile, color: colourPalette.RAGChartZones[0],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Underperforming", x: 0, y: -5
                    }
                },
                {
                    from: firstTertile, to: secondTertile, color: colourPalette.RAGChartZones[1],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Average", x: 0, y: -5
                    }
                },
                {
                    from: secondTertile, to:  1e6, color: colourPalette.RAGChartZones[2],
                    label: {
                        style: {
                            fontWeight: "bold"
                        },
                        text: "Top performer", x: 0, y: -5
                    }
                }
            ],
            labels: {
                format: "{text}%"
            }
        },
        series: [{
            name: selectedStoreName,
            color: colourPalette.comparators[0],
            data: selectedStoreData
        },
            {
                name: `Store in ${comparatorName}`,
                color: colourPalette.comparators[1],
                data: comparatorStoresData
            }],
        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            // @ts-ignore
            pointFormatter: function () {
                // @ts-ignore
                const series = this.series;

                let secondaryMeasureText, secondaryMeasureValue;
                if (useSample) {
                    secondaryMeasureText = "Percentile: ";
                    // @ts-ignore
                    secondaryMeasureValue = numberFormatter.toOrdinalNumber(this.custom.percentile);
                } else {
                    secondaryMeasureText = "Rank: ";
                    // @ts-ignore
                    const rankNumerator = this.custom.rank;
                    const rankDenominator = allDataPoints.length;
                    secondaryMeasureValue = `${rankNumerator}/${rankDenominator}`;
                }


                return stringUtils.tooltipHTML([`${valueName}: `, secondaryMeasureText], {
                    // @ts-ignore
                    header: `Store: <span style="color:${series.color}">${this.custom.storeName}</span>`,
                    // @ts-ignore
                    values: [numberFormatter.toPercentage(this.x, true, 2), secondaryMeasureValue]
                });
            },
            footerFormat: '</table>'
        },
    };

    return (
        <Box data-cy={dataCy}>
            <BeeSwarm loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default RankedStoresBeeswarm;
