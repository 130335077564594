import React, { useEffect, useRef } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";
import Highcharts from "highcharts";

import Treemap from "components/visuals/Treemap";
import { useAppSelector } from "store";
import { CustomerProfileType, SpendByCategory, selectSpendByCustomerProfile } from "modules/customer/tools/location/spendNew/spendSlice";
import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import useColourPalette from "components/visuals/useColourPalette";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const SpendByCustomerProfile: React.FC = () => {
    const spendByCustomerProfile = useAppSelector(selectSpendByCustomerProfile);
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const chartRef = useRef<Highcharts.Chart | null>(null);

    const setChart = (chart: Highcharts.Chart) => {
        chartRef.current = chart;
    };
    
    const chartData = spendByCustomerProfile.map(row => {
        let colour = undefined;
        if (row.type === CustomerProfileType.Supergroup && row.supergroupCode !== undefined) {
            colour = colourPalette.categorical[row.supergroupCode - 1];
        }
        return {
            id: row.name,
            name: row.name,
            parent: row.parent,
            value: row.totalSpend,
            color: colour,
            custom: {
                // ToDo: Bring through tooltip data
                spendByCategory: row.spendByCategory,
                type: row.type
            }
        };
    });

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
        if (!chartData || chartData.length === 0) {
            chart.series[0].setData([]);
        } else {
            chart.series[0].setData(chartData);
        }
        }
    }, [chartData]);

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            height: (isInsightExpanded) ? 300 : 500,
            inverted: true
        },
        tooltip: {
            useHTML: true,
            headerFormat: "",
            footerFormat: "",
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                const spendByCategory: SpendByCategory[] = this.point.options.custom?.spendByCategory.slice(0, 5);
                const categoryNames = spendByCategory.map(item => `- ${item.categoryName}`);
                const formattedSpendValues = spendByCategory.map(item => numberFormatter.toGBP(item.spend, 1));
                const valueFormattingArr = ["", `color:${theme.palette.common.white}`];
                return `<table style='z-index:${theme.zIndex.tooltip}'><tr><td colspan="2" style=""></td></tr>
                ${stringUtils.tooltipHTML([" ", "Total spend", ...categoryNames], {
                        header: `${this.point.name}`,
                        values: [" ",
                            numberFormatter.toGBP(this.point.value, 1),
                            ...formattedSpendValues
                        ],
                        valueFormatting: `color:${theme.palette.text.secondary}`,
                        valueFormattingArr,
                        headerFormatting: `color:${this.point.color}`
                    }
                )}</table>`;
            }
        },
        series: [{
            name: "Total Spend",
            type: "treemap",
            data: chartData,
            layoutAlgorithm: "stripes",
            layoutStartingDirection: (isInsightExpanded) ? "horizontal" : "vertical",
            levels: [{
                dataLabels: {
                    style: {
                        textOverflow: "clip",
                        fontFamily: theme.typography.fontFamily
                    },
                    crop: true,
                    overflow: "justify",
                    formatter: function () {
                        const spendValue = this.point.value ?? 0;
                        const parent = this.point.options.parent;
                        const type: CustomerProfileType = this.point.options.custom?.type;

                        const topLevelPoints = this.series.data.filter(item => item?.options?.custom?.type === CustomerProfileType.Supergroup);
                        const topLevelValues: number[] = topLevelPoints.map(item => item.options?.value ?? 0) ?? [0];
                        const topLevelTotal = topLevelValues.reduce((accumulator, value) => accumulator + value, 0);

                        const parentSpend = (type === CustomerProfileType.Supergroup) ? topLevelTotal : this.series.data.find(item => item.name === parent)?.options.value ?? 1e9;
                        
                        let formattedPointName = this.point.name; 
                        if (formattedPointName.indexOf(" ") > -1 && isInsightExpanded) {
                          formattedPointName = this.point.name.replaceAll(" ", "<br>");
                        }
                        const percentageOfParentSpend = 100 * (spendValue / parentSpend);
                        const largeLabelThreshold = isInsightExpanded ? 9 : 11;
                        const smallLabelThreshold = isInsightExpanded ? largeLabelThreshold : 6;
                        if (percentageOfParentSpend >= largeLabelThreshold) {
                            return `<table>${stringUtils.tooltipHTML([`${numberFormatter.toGBP(spendValue, 1)}`], {
                                header: formattedPointName,
                                values: ["&nbsp;".repeat((isInsightExpanded) ? 1 : 50)],
                                headerFormatting: `color: ${theme.palette.common.black}; fontWeight: 400; fontSize: 14px`,
                                categoryFormatting: `color: ${theme.palette.common.black}; fontWeight: 600; fontSize: 18px`,
                                overrideDefaultHeaderFormatting: true,
                                overrideDefaultCategoryFormatting: true
                            })}</table>`;
                        } else if (percentageOfParentSpend >= smallLabelThreshold) {
                            return `<table>${stringUtils.tooltipHTML([], {
                                header: formattedPointName,
                                headerFormatting: `color: ${theme.palette.common.black}; fontWeight: 400; fontSize: 14px`,
                                overrideDefaultHeaderFormatting: true,
                            })}</table>`;
                        } else {
                            return "";
                        }

                    }
                }
            }]
        }]
    }), [isInsightExpanded, chartData, theme]);
    return (
        <Box data-cy="spend-by-customer-profile-treemap">
            <Typography variant="h6" component="div" gutterBottom>
                Catchment area spend by customer profile
            </Typography>
            <Treemap loading={false} error={false} options={options} setChart={setChart}/>
        </Box>
    );
};

export default SpendByCustomerProfile;
