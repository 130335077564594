import React from "react";

import { Box, Typography } from "@material-ui/core";

import { FilterStep, selectActiveStep } from "modules/customer/tools/location/filters/filtersSlice";
import { useAppSelector } from "store";

const Title: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            <Typography variant="h5" component="div">
                Location setup
            </Typography>
            <Box paddingTop={1}>
                <Typography variant="subtitle1" component="div">
                    {activeStep === FilterStep.SelectComparator && "To begin searching for a new store location, first select what type of store you want to open. Then select any store, or multiple stores, to compare locations against."}
                    {activeStep === FilterStep.SetTargets && "Select what type of store you want to open and then set your targets to get tailored location scores. The more closely aligned a location is to the target, the higher it will score."}
                </Typography>
            </Box>
        </>
    );
};

export default Title;
