import React from "react";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import OverrideRevenuePredictionConfirmation from "./OverrideRevenuePredictionConfirmation";

import {
    generateRevenuePrediction,
    GenerateRevenuePredictionStep,
    hideGenerateRevenuePrediction,
    selectActiveGenerateRevenuePredictionStep,
    selectHasPrediction,
    selectCandidatePredictionForm,
    setActiveGenerateRevenuePredictionStep,
    showOverrideRevenuePredictionConfirmation
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeGenerateRevenuePredictionStep = useAppSelector(selectActiveGenerateRevenuePredictionStep);
    const hasPrediction = useAppSelector(selectHasPrediction);
    const candidatePredictionForm = useAppSelector(selectCandidatePredictionForm);

    const handleBackClick = () => {
        let previousStep: GenerateRevenuePredictionStep;
        switch (activeGenerateRevenuePredictionStep) {
            case GenerateRevenuePredictionStep.InputStoreParameters:
                previousStep = GenerateRevenuePredictionStep.SetupPrediction;
                break;
            case GenerateRevenuePredictionStep.SetupPrediction:
            default:
                previousStep = GenerateRevenuePredictionStep.SetupPrediction;
                break;
        }
        dispatch(setActiveGenerateRevenuePredictionStep(previousStep));
    };

    const handleNextClick = () => {
        let nextStep: GenerateRevenuePredictionStep;
        switch (activeGenerateRevenuePredictionStep) {
            case GenerateRevenuePredictionStep.SetupPrediction:
                nextStep = GenerateRevenuePredictionStep.InputStoreParameters;
                break;
            case GenerateRevenuePredictionStep.InputStoreParameters:
            default:
                nextStep = GenerateRevenuePredictionStep.InputStoreParameters;
                break;
        }
        dispatch(setActiveGenerateRevenuePredictionStep(nextStep));
    };

    const handleFinishClick = () => {
        if (hasPrediction) {
            dispatch(showOverrideRevenuePredictionConfirmation());
        } else {
            dispatch(hideGenerateRevenuePrediction());
            dispatch(generateRevenuePrediction());
        }
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="medium"
                color="default"
                disableElevation
                onClick={handleBackClick}
                disabled={activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction}
                data-cy="btn-back"
            >
                Back
            </StyledButton>
            {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.SetupPrediction && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleNextClick}
                    disabled={!candidatePredictionForm.group || !candidatePredictionForm.model}
                    data-cy="btn-next"
                >
                    Next
                </Button>
            )}
            {activeGenerateRevenuePredictionStep === GenerateRevenuePredictionStep.InputStoreParameters && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleFinishClick}
                    data-cy="btn-finish"
                >
                    Finish
                </Button>
            )}
            <OverrideRevenuePredictionConfirmation />
        </>
    );
};

export default Actions;
