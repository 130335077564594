import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Evaluate how strongly your selected location aligns with your targets.
            Explore the similarities and differences and decide whether the pinned site is a good spot.
        </>
    );
};

export default Subtitle;
