import React from "react";

const ContributionVsBudgetSubtitle: React.FC = () => {
    return (
        <>
            How is your contribution performing against budget? Are you on track, or do you need to rally in the rest of
            the year?
        </>
    );
};

export default ContributionVsBudgetSubtitle;
