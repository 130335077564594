import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectNumberOfOpenStores } from "modules/customer/tools/location/areaHealth/areaHealthSlice";

const NumberOfOpenStores: React.FC = () => {
    const numberOfOpenStores = useAppSelector(selectNumberOfOpenStores);
    return (
        <RAGIndicatorNew ragIndicator={numberOfOpenStores} />
    );
};

export default NumberOfOpenStores;
