import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { selectStoreCategories } from "modules/customer/tools/location/filters/filtersSlice";
import {
    selectDirectCompetitorNames,
    selectPinnedLocation,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import { CompetitorCategory } from "./competitorCategory";
import { Competitor, loadCompetitors } from "./competitors";
import { loadLocalAreaHotspot, LocalAreaHotspot } from "./localAreaHotspot";

interface LoadCompetitionResponse {
    localAreaHotspot: LocalAreaHotspot | undefined,
    competitors: Competitor[],
    competitorCategories: CompetitorCategory[]
}

interface BreakdownOfCompetitorStoresFilter {
    competitorStore: string
}

export enum BreakdownOfCompetitorStoresSortField {
    Fascia,
    Size,
    DistanceToHotspot,
    DistanceToProposedStore
}

interface BreakdownOfCompetitorStoresSort {
    field: BreakdownOfCompetitorStoresSortField,
    direction: SortDirection
}

interface CompetitionState {
    isLoading: boolean,
    hasErrors: boolean,
    localAreaHotspot: LocalAreaHotspot | undefined,
    competitors: Competitor[],
    competitorCategories: CompetitorCategory[],
    breakdownOfCompetitorStoresFilter: BreakdownOfCompetitorStoresFilter,
    breakdownOfCompetitorStoresSort: BreakdownOfCompetitorStoresSort
}

const initialState: CompetitionState = {
    isLoading: false,
    hasErrors: false,
    localAreaHotspot: undefined,
    competitors: [],
    competitorCategories: [],
    breakdownOfCompetitorStoresFilter: {
        competitorStore: ""
    },
    breakdownOfCompetitorStoresSort: {
        field: BreakdownOfCompetitorStoresSortField.DistanceToProposedStore,
        direction: SortDirection.ASC
    }
};

const competitionSlice = createSlice({
    name: "customer/tools/location/competition",
    initialState,
    reducers: {
        clearCompetitors: (state) => {
            state.competitors = initialState.competitors;
        },
        clearStoreCategories: (state) => {
            state.competitorCategories = initialState.competitorCategories;
        },
        toggleCompetitorCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const categoryName = action.payload;
            state.competitorCategories.find(category => category.name === categoryName)?.toggleIsSelected();
        },
        chooseAllCompetitorCategories: (state) => {
            state.competitorCategories.forEach(category => category.setIsSelected(true));
        },
        deselectAllCompetitorCategories: (state) => {
            state.competitorCategories.forEach(category => category.setIsSelected(false));
        },
        setBreakdownOfCompetitorStoresFilter: (state, action: PayloadAction<BreakdownOfCompetitorStoresFilter>) => {
            state.breakdownOfCompetitorStoresFilter = action.payload;
        },
        clearBreakdownOfCompetitorStoresFilter: (state) => {
            state.breakdownOfCompetitorStoresFilter = initialState.breakdownOfCompetitorStoresFilter;
        },
        setBreakdownOfCompetitorStoresSort: (state, action: PayloadAction<BreakdownOfCompetitorStoresSort>) => {
            state.breakdownOfCompetitorStoresSort = action.payload;
        },
        clearBreakdownOfCompetitorStoresSort: (state) => {
            state.breakdownOfCompetitorStoresSort = initialState.breakdownOfCompetitorStoresSort;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCompetition.pending, (state: CompetitionState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadCompetition.rejected, (state: CompetitionState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.localAreaHotspot = initialState.localAreaHotspot;
            state.competitors = initialState.competitors;
            state.competitorCategories = initialState.competitorCategories;
        });
        builder.addCase(loadCompetition.fulfilled, (state: CompetitionState, action: PayloadAction<LoadCompetitionResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.localAreaHotspot = action.payload.localAreaHotspot;
            state.competitors = action.payload.competitors;
            state.competitorCategories = action.payload.competitorCategories;
        });
    }
});

export const {
    clearCompetitors,
    clearStoreCategories,
    chooseAllCompetitorCategories,
    deselectAllCompetitorCategories,
    toggleCompetitorCategoryIsSelected,
    setBreakdownOfCompetitorStoresSort,
    setBreakdownOfCompetitorStoresFilter
} = competitionSlice.actions;

export const loadCompetition = createAppAsyncThunk(
    "customer/tools/location/competition/loadCompetition",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const directCompetitorNames = selectDirectCompetitorNames(state);
            const targetStoreCategoryId = selectTargetStoreCategory(state)?.id;

            const pinnedLocation = selectPinnedLocation(state);
            //ToDo: Dispatch these queries in paralell by moving distance calculation to selector
            const localAreaHotspot = await thunkAPI.dispatch(loadLocalAreaHotspot(pinnedLocation));
            const competitors = await thunkAPI.dispatch(loadCompetitors(pinnedLocation, localAreaHotspot, directCompetitorNames));

            const competitorCategories = selectStoreCategories(state).map(category =>
                new CompetitorCategory(
                    category.id,
                    category.name,
                    targetStoreCategoryId === category.id
                )
            );

            const loadCompetitionResponse: LoadCompetitionResponse = {
                localAreaHotspot,
                competitors,
                competitorCategories
            };
            return loadCompetitionResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Competition.", error));
            return thunkAPI.rejectWithValue([]);
        }
    }
);

export const clearCompetition = (): AppThunk => (dispatch) => {
    dispatch(competitionSlice.actions.clearCompetitors());
    dispatch(competitionSlice.actions.clearStoreCategories());
    dispatch(competitionSlice.actions.clearBreakdownOfCompetitorStoresSort());
    dispatch(competitionSlice.actions.clearBreakdownOfCompetitorStoresFilter());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.competition.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.competition.hasErrors;
};

export const selectLocalAreaHotspot = (state: RootState) => {
    return state.customer.tools.location.competition.localAreaHotspot;
};

export const selectCompetitorCategories = (state: RootState) => {
    return state.customer.tools.location.competition.competitorCategories;
};

export const selectBreakdownOfCompetitorStoresSort = (state: RootState) => {
    return state.customer.tools.location.competition.breakdownOfCompetitorStoresSort;
};

export const selectBreakdownOfCompetitorStoresFilter = (state: RootState) => {
    return state.customer.tools.location.competition.breakdownOfCompetitorStoresFilter;
};

export const selectIsFilterModified = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCompetitorCategories,
    (state: RootState) => selectTargetStoreCategory(state),
    (isLoading, hasErrors, competitorCategories, targetStoreCategory) => {
        if (isLoading || hasErrors || !targetStoreCategory) {
            return false;
        }
        //ToDo: should have been a XOR
        return competitorCategories.some(competitorCategory =>
            (competitorCategory.id === targetStoreCategory.id && !competitorCategory.isSelected())
            || (competitorCategory.id !== targetStoreCategory.id && competitorCategory.isSelected()));
    }
);

export const selectCompetitors = createSelector(
    (state: RootState) => state.customer.tools.location.competition.competitors,
    selectCompetitorCategories,
    (competitors, competitorCategories) => {
        const selectedCompetitionCategoryIds = competitorCategories
            .filter(category => category.isSelected())
            .map(category => category.id);
        return competitors.filter(competitor =>
            selectedCompetitionCategoryIds.includes(competitor.storeCategoryId)
        );
    }
);

export const selectNumberOfDirectCompetitors = createSelector(
    selectCompetitors,
    (competitors) => {
        const directCompetitors = competitors.filter(competitor => competitor.directCompetitor);
        return directCompetitors.length;
    }
);

export const selectNumberOfOtherCompetitors = createSelector(
    selectCompetitors,
    (competitors) => {
        const otherCompetitors = competitors.filter(competitor => !competitor.directCompetitor);
        return otherCompetitors.length;
    }
);

export const selectBreakdownOfCompetitorStores = createSelector(
    selectCompetitors,
    selectBreakdownOfCompetitorStoresSort,
    selectBreakdownOfCompetitorStoresFilter,
    (competitors, sort, filter) => {
        const fascia = filter.competitorStore.toLowerCase();
        const filteredCompetitors = competitors.filter(competitor =>
            (!fascia || competitor.fascia.toLowerCase().includes(fascia))
        );
        return filteredCompetitors.sort((competitorA, competitorB) => {
            switch (sort.field) {
                case BreakdownOfCompetitorStoresSortField.Fascia:
                    return stringSortExpression(competitorA.fascia, competitorB.fascia, sort.direction);
                case BreakdownOfCompetitorStoresSortField.Size:
                    return numberSortExpression(competitorA.size, competitorB.size, sort.direction);
                case BreakdownOfCompetitorStoresSortField.DistanceToHotspot:
                    return numberSortExpression(competitorA.distanceToHotspot, competitorB.distanceToHotspot, sort.direction);
                case BreakdownOfCompetitorStoresSortField.DistanceToProposedStore:
                    return numberSortExpression(competitorA.distanceToProposedStore, competitorB.distanceToProposedStore, sort.direction);
                default:
                    return stringSortExpression(competitorA.fascia, competitorB.fascia, SortDirection.ASC);
            }
        });
    }
);

export default competitionSlice;
