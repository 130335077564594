import { combineReducers } from "redux";

import types from "./types";

const demographicsEmpty = {
    loading: false,
    error: false,
    demographicsData: [],
    salesData: [],
};

const demographics = (state = demographicsEmpty, action) => {
    switch (action.type) {
        case types.GET_DEMOGRAPHICS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                demographicsData: [],
                salesData: [],
            };
        case types.GET_DEMOGRAPHICS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                demographicsData: action.payload.demographicsData,
                salesData: action.payload.salesData
            };
        case types.GET_DEMOGRAPHICS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                demographicsData: [],
                salesData: [],
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    demographics
});

export default reducer;
