import React from "react";

import { Button } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "store";
import { hidePartnerFilters, selectCandidatePartner } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { choosePartner } from "modules/customer/tools/product/productSlice";

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidatePartner = useAppSelector(selectCandidatePartner);

    const handleFinishClick = () => {
        if (!candidatePartner) {
            return;
        }
        dispatch(choosePartner(candidatePartner));
        dispatch(hidePartnerFilters());
    };

    return (
        <Button
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
            onClick={handleFinishClick}
            disabled={!candidatePartner}
            data-cy="btn-finish"
        >
            Finish
        </Button>
    );
};

export default Actions;
