import React from "react";
import { Grid } from "@material-ui/core";

import Chart from "./Chart";
import SupplyAndDemandCategorisation from "./SupplyAndDemandCategorisation";

const SupplyAndDemand = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Chart />
            </Grid>
            <Grid item xs={12} md={12}>
                <SupplyAndDemandCategorisation />
            </Grid>
        </Grid>
    );
};

export default SupplyAndDemand;
