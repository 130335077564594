import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            <p>
                Discover the footfall landscape of your selected location with Dash. Gain a comprehensive 
                understanding of footfall levels, track changes over time, and identify hotspots for customer flow. 
                Evaluate alignment with your business hours and make informed decisions for optimal store placement.
            </p>
            <p>
                Dash's footfall signal is an indicator of how busy a location is, capturing the presence of unique individuals based on a 
                sample population of mobile phone users. This signal includes all people present, including residents, workers, visitors, 
                and passers-by.
            </p>
        </>
    );
};

export default Subtitle;
