import React from "react";

import { Box, Grid, GridSize } from "@material-ui/core";

import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";
import NumberOfDirectCompetitors from "./NumberOfDirectCompetitors";
import NumberOfOtherCompetitors from "./NumberOfOtherCompetitors";
import BreakdownOfCompetitorStores from "./BreakdownOfCompetitorStores";

const NearbyCompetitorStores: React.FC = () => {
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const kpiGridSize: GridSize = isInsightExpanded ? 6 : 12;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={kpiGridSize}>
                    <NumberOfDirectCompetitors />
                </Grid>
                <Grid item xs={kpiGridSize}>
                    <NumberOfOtherCompetitors />
                </Grid>
                <Grid item xs={12}>
                    <BreakdownOfCompetitorStores />
                </Grid>
            </Grid>
        </Box>
    );
};

export default NearbyCompetitorStores;
