import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/localAreaCompetition";

const DistanceToHotspotKpi = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const distanceToHotspotKpi = useSelector(state => selectors.distanceToHotspotKpi(state));
    const { loading, error, value, percentageDifference } = distanceToHotspotKpi;
    const valueFormatted = value.toFixed(2);
    const label = `${selectedStore.name} Store Distance to hotspot (Km) vs ${selectedComparator.name}`;

    return (
        <Box width="100%" height="100%" data-cy="distance-to-hotspot-kpi">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} switchColor={true}/>
        </Box>
    );
};

export default DistanceToHotspotKpi;
