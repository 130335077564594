import { ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadCostReferenceDate = (salesReferenceDate: DateTime): AppThunk<Promise<DateTime>> => async (dispatch) => {
    try {
        const salesEndOfMonth = salesReferenceDate.endOf("day").equals(salesReferenceDate.endOf("month").endOf("day"))
            ? salesReferenceDate
            : salesReferenceDate.minus({ months: 1 }).endOf("month");

        const query = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Cost.SumCostValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawResultSetData = resultSet.rawData();
        const costReferenceDate = rawResultSetData.length !== 0 ?
            DateTime.fromISO(rawResultSetData[0]["D_Date.Date"], { zone: "utc" }) :
            salesEndOfMonth;
        const costEndOfMonth = costReferenceDate.endOf("month");
        const overallReferenceDate = costEndOfMonth < salesEndOfMonth ? costEndOfMonth : salesEndOfMonth;

        return overallReferenceDate;
    } catch (error) {
        dispatch(logError("Error loading CostReferenceDate.", error));
        throw error;
    }
};
