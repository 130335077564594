const GET_FOOTFALL_REQUEST = "spark/customer/insights/portfolio/catchment/footfall/GET_FOOTFALL_REQUEST";
const GET_FOOTFALL_SUCCESS = "spark/customer/insights/portfolio/catchment/footfall/GET_FOOTFALL_SUCCESS";
const GET_FOOTFALL_FAILURE = "spark/customer/insights/portfolio/catchment/footfall/GET_FOOTFALL_FAILURE";

const types = {
    GET_FOOTFALL_REQUEST,
    GET_FOOTFALL_SUCCESS,
    GET_FOOTFALL_FAILURE
};

export default types;
