import types from "./types";

const getTotalSalesGrowthByRegionForecastRequest = () => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_REQUEST
});

const getTotalSalesGrowthByRegionForecastSuccess = (regionsGrowthForecast) => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_SUCCESS,
    payload: {
        regionsGrowthForecast
    }
});

const getTotalSalesGrowthByRegionForecastFailure = () => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_FAILURE
});

const getSalesByRegionGrowthForecastRequest = () => ({
    type: types.GET_SALES_BY_REGION_GROWTH_FORECAST_REQUEST
});

const getSalesByRegionGrowthForecastSuccess = (storesGrowthForecast) => ({
    type: types.GET_SALES_BY_REGION_GROWTH_FORECAST_SUCCESS,
    payload: {
        storesGrowthForecast
    }
});

const getSalesByRegionGrowthForecastFailure = () => ({
    type: types.GET_SALES_BY_REGION_GROWTH_FORECAST_FAILURE
});

const getForecastDependencyOnSingularRegionOrStoreRequest = () => ({
    type: types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST
});

const getForecastDependencyOnSingularRegionOrStoreSuccess = (label, status, value) => ({
    type: types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS,
    payload: {
        label,
        status,
        value
    }
});

const getForecastDependencyOnSingularRegionOrStoreFailure = () => ({
    type: types.GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE
});

const setSelectedRegion = (selectedRegion) => ({
    type: types.SET_SELECTED_REGION,
    payload: {
        selectedRegion
    }
});

const actions = {
    getTotalSalesGrowthByRegionForecastRequest,
    getTotalSalesGrowthByRegionForecastSuccess,
    getTotalSalesGrowthByRegionForecastFailure,
    getSalesByRegionGrowthForecastRequest,
    getSalesByRegionGrowthForecastSuccess,
    getSalesByRegionGrowthForecastFailure,
    getForecastDependencyOnSingularRegionOrStoreRequest,
    getForecastDependencyOnSingularRegionOrStoreSuccess,
    getForecastDependencyOnSingularRegionOrStoreFailure,
    setSelectedRegion
};

export default actions;
