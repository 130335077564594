import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface RegistrationState {
    isRegistrationTokenChecked: boolean,
    isRegistrationComplete: boolean
}

const initialState: RegistrationState = {
    isRegistrationTokenChecked: false,
    isRegistrationComplete: false
};

const registrationSlice = createSlice({
    name: "landing/registration",
    initialState,
    reducers: {
        setIsRegistrationTokenChecked: (state, action: PayloadAction<boolean>) => {
            state.isRegistrationTokenChecked = action.payload;
        },
        setIsRegistrationComplete: (state, action: PayloadAction<boolean>) => {
            state.isRegistrationComplete = action.payload;
        }
    }
});

export const completeRegistration = (registrationToken: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost("/landing/registration", { registrationToken }));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(registrationSlice.actions.setIsRegistrationTokenChecked(true));
            dispatch(registrationSlice.actions.setIsRegistrationComplete(true));
            break;
        }
        default: {
            dispatch(registrationSlice.actions.setIsRegistrationTokenChecked(true));
            dispatch(registrationSlice.actions.setIsRegistrationComplete(false));
            break;
        }
    }
};

export const selectIsRegistrationTokenChecked = (state: RootState) => {
    return state.landing.registration.isRegistrationTokenChecked;
};

export const selectIsRegistrationComplete = (state: RootState) => {
    return state.landing.registration.isRegistrationComplete;
};

export default registrationSlice;
