import React from "react";

import { Grid } from "@material-ui/core";

import FootfallLevel from "./FootfallLevel";
import YearOnYearFootfall from "./YearOnYearFootfall";
import Map from "./Map";
import FootfallVsComparator from "./FootfallVsComparator";
import FootfallPositioning from "./FootfallPositioning";

const Footfall: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FootfallLevel />
            </Grid>
            <Grid item xs={12} md={6}>
                <YearOnYearFootfall />
            </Grid>
            <Grid item xs={12}>
                <Map />
            </Grid>
            <Grid item xs={12}>
                <FootfallVsComparator />
            </Grid>
            <Grid item xs={12}>
                <FootfallPositioning />
            </Grid>
        </Grid>
    );
};

export default Footfall;
