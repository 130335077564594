import React from "react";
import { NavLink } from "react-router-dom";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(3)
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(3)
    },
    paper: {
        backgroundColor: theme.palette.common.white
    },
    menuItem: {
        justifyContent: "center",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3)
    }
}));

const About = (props) => {
    const { onLinkClick } = props;
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const open = Boolean(menuAnchorEl);

    const handleClick = (event) => {
        onLinkClick();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="large"
                color="default"
                disableElevation
                endIcon={<ExpandMoreIcon />}
                onClick={handleClick}
            >
                About
            </StyledButton>
            <Menu
                anchorEl={menuAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                variant="menu"
                open={open}
                onClose={handleClose}
                PopoverClasses={{ paper: classes.paper }}
            >
                <MenuItem
                    component={NavLink}
                    to="/our-story"
                    onClick={handleClose}
                    ListItemClasses={{ root: classes.menuItem }}
                    data-cy="btn-our-story"
                >
                    <Typography variant="h6" component="div" align="center">
                        Our story
                    </Typography>
                </MenuItem>
                <MenuItem
                    component={NavLink}
                    to="/meet-the-team"
                    onClick={handleClose}
                    ListItemClasses={{ root: classes.menuItem }}
                    data-cy="btn-meet-the-team"
                >
                    <Typography variant="h6" component="div" align="center">
                        Meet the team
                    </Typography>
                </MenuItem>
                <MenuItem
                    component={NavLink}
                    to="/frequently-asked-questions"
                    onClick={handleClose}
                    ListItemClasses={{ root: classes.menuItem }}
                    data-cy="btn-faq"
                >
                    <Typography variant="h6" component="div" align="center">
                        FAQs
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default About;
