import React from "react";

import { Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RadarChart from "pages/customer/insights/portfolio/filters/RadarChart";

import { Store } from "modules/customer/insights/portfolio/store";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent"
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

interface StoreCardProps {
    store: Store,
    selected: boolean,
    onSelect: (store: Store) => void,
    onDeselect: (store: Store) => void
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
    const { store, selected, onSelect, onDeselect } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(store);
        } else {
            onSelect(store);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardContent>
                    <RadarChart
                        salesScore={store.salesScore}
                        driversScore={store.driversScore}
                        profitScore={store.profitScore}
                        areaHealthScore={store.areaHealthScore}
                        competitionScore={store.competitionScore}
                        catchmentScore={store.catchmentScore}
                        totalScore={store.getTotalScore()}
                    />
                </StyledCardContent>
                <CardActions>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div">
                                {store.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div" align="right">
                                Total score:
                            </Typography>
                            <Typography variant="h5" component="div" align="right">
                                {numberFormatter.toDecimalPlaces(store.getTotalScore(), 1)}/30.0
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default StoreCard;
