import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectRemainingUsersCount, selectUsers } from "modules/customer/dashboard/dashboardSlice";

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    you: {
        color: theme.palette.text.disabled
    }
}));

const Users: React.FC = () => {
    const classes = useStyles();
    const userInfo = useAppSelector(selectUserInfo);
    const users = useAppSelector(selectUsers);
    const remainingUsersCount = useAppSelector(selectRemainingUsersCount);

    return (
        <>
            <Typography variant="subtitle1" component="div" color="textSecondary" gutterBottom>
                Users
            </Typography>
            <Box paddingTop={1}>
                {users.map(user =>
                    <Typography key={user.id} variant="subtitle1" component="div" gutterBottom>
                        {user.firstName} {user.lastName}
                        {user.isCurrentUser && (<span className={classes.you}>&nbsp;(You)</span>)}
                    </Typography>
                )}
                {remainingUsersCount > 0 && (
                    <Typography variant="body1" component="div" color="textSecondary">
                        and {remainingUsersCount} more user(s)
                    </Typography>
                )}
            </Box>
            {userInfo.isAccountAdmin && (
                <Box paddingTop={1}>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        // @ts-ignore
                        component={NavLink}
                        to="/account"
                    >
                        Update users
                    </StyledButton>
                </Box>
            )}
        </>
    );
};

export default Users;
