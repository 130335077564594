import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledListItemText = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2)
    }
}))(ListItemText);

const StyledListItemAvatar = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2)
    }
}))(ListItemAvatar);

const StyledAvatar = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main
    }
}))(Avatar);

const PlatformMobile = (props) => {
    const { onLinkClick } = props;
    const [open, setOpen] = React.useState(false);
    const [insightsAndToolsOpen, setInsightsAndToolsOpen] = React.useState(false);
    const insightsAndToolsSections = [{
        name: "Performance",
        url: "/insights-and-tools?section=performance",
        icon: <TrendingUpRoundedIcon />
    }, {
        name: "Portfolio",
        url: "/insights-and-tools?section=portfolio",
        icon: <StoreRoundedIcon />
    }, {
        name: "Location",
        url: "/insights-and-tools?section=location",
        icon: <PinDropRoundedIcon />
    }, {
        name: "Range",
        url: "/insights-and-tools?section=range",
        icon: <ShoppingBasketRoundedIcon />
    }];

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleInsightsAndToolsClick = () => {
        setInsightsAndToolsOpen(prevOpen => !prevOpen);
    };

    const handleLinkClick = () => {
        onLinkClick();
    };

    return (
        <>
            <ListItem button onClick={handleClick} divider>
                <ListItemText
                    primary="Platform"
                    primaryTypographyProps={{
                        variant: "h6",
                        component: "div"
                    }}
                />
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </ListItem>
            <Collapse in={open} unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        divider
                        button
                        component={NavLink}
                        to="/how-dash-works"
                        onClick={handleLinkClick}
                    >
                        <StyledListItemText
                            primary="How Dash works"
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                component: "div"
                            }}
                        />
                    </ListItem>
                    <ListItem button onClick={handleInsightsAndToolsClick}>
                        <StyledListItemText
                            primary="Insights and tools"
                            primaryTypographyProps={{
                                variant: "subtitle1",
                                component: "div"
                            }}
                        />
                        {insightsAndToolsOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                    </ListItem>
                    <Collapse in={insightsAndToolsOpen} unmountOnExit>
                        <List component="div" disablePadding>
                            {insightsAndToolsSections.map((section, index) =>
                                <ListItem
                                    key={index}
                                    button
                                    component={NavLink}
                                    to={section.url}
                                    onClick={handleLinkClick}
                                >
                                    <StyledListItemAvatar>
                                        <StyledAvatar>
                                            {section.icon}
                                        </StyledAvatar>
                                    </StyledListItemAvatar>
                                    <StyledListItemText
                                        primary={section.name}
                                        primaryTypographyProps={{
                                            variant: "h6",
                                            component: "div"
                                        }}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                    <Divider />
                </List>
            </Collapse>
        </>
    );
};

PlatformMobile.propTypes = {
    onLinkClick: PropTypes.func.isRequired
};

export default PlatformMobile;
