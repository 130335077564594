import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Gauge from "components/visuals/Gauge";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/storeSize";

const SalesPerPoundOfPropertyCostGauge = () => {
    const salesPerPoundOfPropertyCost = useSelector(state => selectors.salesPerPoundOfPropertyCostGauge(state));
    const { loading, error, min, max, data, med, firstTertile, secondTertile } = salesPerPoundOfPropertyCost;

    const noGaugeData = (data === 0 || max === 0);

    const options = {

    };
    
    return (
        <Box data-cy="sales-per-pound-of-property-cost-gauge">
            <Gauge loading={loading} error={error} options={options} data={data} min={min} max={max} 
                median={med} firstTertile={firstTertile} secondTertile={secondTertile} noData={noGaugeData}/>
        </Box>
    );
};

export default SalesPerPoundOfPropertyCostGauge;
