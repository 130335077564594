import React from "react";
import { Popup } from "react-map-gl";
import PropTypes from "prop-types";
import numberFormatter from "utils/numberFormatter";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

const CatchmentAreaPenPortraitClassificationPopup = (props) => {
    const { penPortraitClassifications, hoverInfo, clickInfo } = props;
    const theme = useTheme();
    const popup = React.useMemo(() => {
        const outputAreaCode = clickInfo.outputAreaCode ?? hoverInfo.outputAreaCode;
        const outputArea = penPortraitClassifications.find(item => item.outputAreaCode === outputAreaCode);
        if (!outputArea) {
            return null;
        }
        const latitude = clickInfo.outputAreaCode ? clickInfo.latitude : hoverInfo.latitude;
        const longitude = clickInfo.outputAreaCode ? clickInfo.longitude : hoverInfo.longitude;
        return {
            latitude,
            longitude,
            outputArea
        };
    }, [hoverInfo, clickInfo, penPortraitClassifications]);

    if (!popup) {
        return null;
    }

    return (
        <Popup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={false}
            anchor="bottom"
        >
            <Box minWidth={theme.spacing(18)}>
                <Box display="flex" alignItems="center">
                    <Typography variant="body2" component="div">
                        % likelihood of visiting&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <Spacer />
                    <Typography variant="subtitle2" component="div">
                        {numberFormatter.toPercentage(popup.outputArea.percentageLikelihoodOfVisiting)}
                    </Typography>
                </Box>
                <Box paddingTop={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                        Supergroup:
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {popup.outputArea.supergroup}
                    </Typography>
                </Box>
                <Box paddingTop={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                        Group:
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {popup.outputArea.group}
                    </Typography>
                </Box>
                <Box paddingTop={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                        Subgroup:
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {popup.outputArea.subgroup}
                    </Typography>
                </Box>
            </Box>
        </Popup>
    );
};

CatchmentAreaPenPortraitClassificationPopup.propTypes = {
    penPortraitClassifications: PropTypes.array.isRequired,
    hoverInfo: PropTypes.object,
    clickInfo: PropTypes.object
};

export default CatchmentAreaPenPortraitClassificationPopup;
