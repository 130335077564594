import { combineReducers } from "redux";

import competitionScore from "./competitionScore";
import localAreaCompetition from "./localAreaCompetition";
import marketShare from "./marketShare";
import revenueVsCompetitionScore from "./revenueVsCompetitionScore";

const reducer = combineReducers({
    competitionScore,
    localAreaCompetition,
    marketShare,
    revenueVsCompetitionScore
});

export default reducer;
