import React from "react";

import { Box } from "@material-ui/core";
import { MapRef } from "react-map-gl";

import MapBase from "components/visuals/maps/MapBase";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Hotspot from "./Hotspot";
import Legend from "./Legend";
import Marker from "./Marker";

import { useAppSelector } from "store";
import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { selectCompetitors } from "modules/customer/tools/location/competition/competitionSlice";
import { Competitor } from "modules/customer/tools/location/competition/competitors";

const Map: React.FC = () => {
    const mapRef = React.useRef<MapRef>(null);
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const competitors = useAppSelector(selectCompetitors);
    const [hoveredCompetitor, setHoveredCompetitor] = React.useState<Competitor | undefined>(undefined);
    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="competition-map">
            <MapBase
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                addNavigationControl={true}
                addRecenterButton={true}
                addFullscreenButton={true}
                dataCy="competitors-map"
                mapRef={mapRef}
                downloadData={competitors}
            >
                <Explore />
                <PinnedLocation />
                {competitors.map((competitor, index) =>
                    <Marker
                        key={index}
                        competitor={competitor}
                        onTooltipOpen={() => setHoveredCompetitor(competitor)}
                        onTooltipClose={() => setHoveredCompetitor(undefined)}
                    />
                )}
                <Hotspot />
                <Legend competitor={hoveredCompetitor} />
            </MapBase>
        </Box>
    );
};

export default Map;
