import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiGet, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifySuccess, notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Subject {
    id: string,
    value: string
}

interface ContactForm {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    company: string,
    jobRole: string,
    subject: string,
    message: string,
    acceptPrivacyPolicy: boolean,
    captchaToken: string,
    errors: ContactFormErrors
}

interface ContactFormErrors {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    company: string,
    jobRole: string,
    subject: string,
    message: string,
    captchaToken: string
}

interface ContactUsState {
    subjects: Subject[],
    contact: ContactForm
}

const initialState: ContactUsState = {
    subjects: [],
    contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        jobRole: "",
        subject: "",
        message: "",
        acceptPrivacyPolicy: false,
        captchaToken: "",
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            jobRole: "",
            subject: "",
            message: "",
            captchaToken: ""
        }
    }
};

const contactUsSlice = createSlice({
    name: "landing/contact-us",
    initialState,
    reducers: {
        setSubjects: (state, action: PayloadAction<Subject[]>) => {
            state.subjects = action.payload;
        },
        clearSubjects: (state) => {
            state.subjects = initialState.subjects;
        },
        setContact: (state, action: PayloadAction<ContactForm>) => {
            state.contact = action.payload;
        },
        clearContact: (state) => {
            state.contact = initialState.contact;
        }
    }
});

export const { setContact, clearContact } = contactUsSlice.actions;

export const getSubjects = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/landing/contact-us/subjects"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const subjects = response.data.subjects;
            dispatch(contactUsSlice.actions.setSubjects(subjects));
            break;
        }
        default: {
            dispatch(contactUsSlice.actions.clearSubjects());
            dispatch(notifyError("Error loading subjects."));
            break;
        }
    }
};

export const sendMessage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const contact = selectContact(state);
    const isBookADemo = contact.subject === "book-a-demo";
    const url = isBookADemo ? "/landing/contact-us/book-a-demo" : "/landing/contact-us/send-message";
    const response = await dispatch(apiPost(url, contact));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(notifySuccess("Thank you. Your submission has been received."));
            dispatch(contactUsSlice.actions.clearContact());
            if (isBookADemo) {
                // @ts-ignore
                window.dashAdobePixel && window.dashAdobePixel("ev_bookdemo_complete");
            } else {
                // @ts-ignore
                window.dashAdobePixel && window.dashAdobePixel("ev_contactus_complete");
            }
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0],
                phone: response.errorData?.errors?.phone?.[0],
                company: response.errorData?.errors?.company?.[0],
                jobRole: response.errorData?.errors?.jobRole?.[0],
                subject: response.errorData?.errors?.subject?.[0],
                message: response.errorData?.errors?.message?.[0],
                captchaToken: response.errorData?.errors?.captchaToken?.[0]
            };
            dispatch(contactUsSlice.actions.setContact({ ...contact, errors }));
            break;
        }
        default:
            break;
    }
};

export const selectSubjects = (state: RootState) => {
    return state.landing.contactUs.subjects;
};

export const selectContact = (state: RootState) => {
    return state.landing.contactUs.contact;
};

export const selectCanSubmit = createSelector(
    selectContact,
    (contact) => {
        const allFilled = !!contact.firstName
            && !!contact.lastName
            && !!contact.email
            && !!contact.company
            && !!contact.jobRole
            && !!contact.subject
            && !!contact.message
            && contact.acceptPrivacyPolicy
            && !!contact.captchaToken;

        const hasErrors = !!contact.errors.firstName
            || !!contact.errors.lastName
            || !!contact.errors.email
            || !!contact.errors.phone
            || !!contact.errors.company
            || !!contact.errors.jobRole
            || !!contact.errors.subject
            || !!contact.errors.message
            || !!contact.errors.captchaToken;

        return allFilled && !hasErrors;
    }
);

export default contactUsSlice;
