import { createAppAsyncThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { DateTime } from "luxon";

export class MonthlySales {
    public readonly storeID: string;
    public readonly clientProductCategory: string;
    public readonly yearsPrior: number;
    public readonly monthStartDate: Date;
    public readonly totalSales: number;
    public readonly totalCostOfGoods: number;

    constructor(
        storeID: string,
        clientProductCategory: string,
        yearsPrior: number,
        monthStartDate: Date,
        totalSales: number,
        totalCostOfGoods: number
    ) {
        this.storeID = storeID;
        this.clientProductCategory = clientProductCategory;
        this.yearsPrior = yearsPrior;
        this.monthStartDate = monthStartDate;
        this.totalSales = totalSales;
        this.totalCostOfGoods = totalCostOfGoods;
    }
}


export const loadMonthlySales =  createAppAsyncThunk<
    MonthlySales[],
    {
        selectedStore: Store | undefined,
        comparatorStores: Store[] | undefined,
        referenceDate: DateTime}
    >(
    "customer/insights/portfolio/loadMonthlySales",
    async ({selectedStore, comparatorStores, referenceDate}, thunkAPI) => {
        try {
            if (!selectedStore || !comparatorStores) {
                return [];
            }
            const twelveMonthsPrior = referenceDate.minus({months: 12});
            const startOfCurrentYear = twelveMonthsPrior.plus({days: 1}).startOf('day');
            const endOfCurrentYear = referenceDate.endOf('day');
            const startOfPreviousYear = startOfCurrentYear.minus({months: 12}).startOf('day');
            const endOfPreviousYear = twelveMonthsPrior.endOf('day');

            const comparatorStoreIDs = comparatorStores.map(store => store.id);
            const selectedAndComparatorStoreIDs = [selectedStore.id].concat(...comparatorStoreIDs);

            const query = {
                measures: [
                    "F_Sales.SumLineValue",
                    "F_Sales.SumLineCost"
                ],
                dimensions: [
                    "D_Store.StoreNaturalID",
                    "D_ProductCategory.ProductCategory1",
                    "D_Date.MonthStartDate"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    compareDateRange: [
                        [startOfCurrentYear, endOfCurrentYear],
                        [startOfPreviousYear, endOfPreviousYear]                
                    ]
                }],
                filters: [{
                    member: "D_Store.StoreNaturalID",
                    operator: "equals",
                    values: selectedAndComparatorStoreIDs
                }],
                limit: 50000
            };

            // ToDo: Is below logic still required?
            // const productGranularity = numberOfProductCategories === 1;
            // productGranularity ? query.dimensions.push("D_Product.ProductName") : query.dimensions.push("D_ProductCategory.ProductCategory1");

            //@ts-ignore
            const resultSet = await thunkAPI.dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet;
            const monthlySales: MonthlySales[] = [];
            
            for (let i = 0; i < resultSet.loadResponses.length; i++) {
                //@ts-ignore
                monthlySales.push(...resultSet.loadResponses[i].data.map(row => 
                    new MonthlySales(
                        row["D_Store.StoreNaturalID"],
                        // productGranularity ? row["D_Product.ProductName"] : row["D_ProductCategory.ProductCategory1"],
                        row["D_ProductCategory.ProductCategory1"],
                        i,
                        row["D_Date.MonthStartDate"],
                        Number(row["F_Sales.SumLineValue"]),
                        Number(row["F_Sales.SumLineCost"])
                    )
                ));
            }

            return monthlySales;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading MonthlySales.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
