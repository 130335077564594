import React from "react";
import { Grid } from "@material-ui/core";

import NetProfitChart from "./NetProfitChart";
import ProfitabilityEvaluation from "./ProfitabilityEvaluation";

const NetProfit: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <NetProfitChart />
            </Grid>
            <Grid item xs={12}>
                <ProfitabilityEvaluation />
            </Grid>
        </Grid>
    );
};

export default NetProfit;
