import { createSelector } from "reselect";
import { abs, median } from "mathjs";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const lifetimeOfStores = (state) => {
    return state.customer.insights.portfolio.areaHealth.openingsAndClosures.lifetimeOfStores;
};

const openVsClosuresSelector = (state) => {
    return state.customer.insights.portfolio.areaHealth.openingsAndClosures.netOpenVsClosuresOverTime;
};

const netOpenVsClosuresOverTime = createSelector(
    openVsClosuresSelector,
    (openVsClosuresSelector) => {
        const selectorData = {
            loading: openVsClosuresSelector.loading,
            error: openVsClosuresSelector.error,
            store: openVsClosuresSelector.store,
            comparator: []
        };

        if (selectorData.loading || selectorData.error || selectorData.store.length === 0) {
            return selectorData;
        }

        const comparator = [];

        comparator.push(
            median(openVsClosuresSelector.comparator.map(item => item.SumTwentyFiveToSixtyMonths)),
            median(openVsClosuresSelector.comparator.map(item => item.SumThirteenToTwentyFourMonths)),
            median(openVsClosuresSelector.comparator.map(item => item.SumSevenToTwelveMonths)),
            median(openVsClosuresSelector.comparator.map(item => item.SumZeroToSixMonths))
        );

        selectorData.comparator = comparator;

        return selectorData;
    }
);

const netOpeningsInPastYear = createSelector(
    openVsClosuresSelector,
    (netOpenVsClosuresOverTime) => {
        const kpi = {
            loading: netOpenVsClosuresOverTime.loading,
            error: netOpenVsClosuresOverTime.error,
            value: 0,
            percentageDifference: 0
        };

        if (kpi.loading || kpi.error || netOpenVsClosuresOverTime.store.length === 0) {
            return kpi;
        }

        const store = netOpenVsClosuresOverTime.store;
        const comparator = netOpenVsClosuresOverTime.comparator;

        //data for 12 months
        let selectedValue = store[3] + store[2];
        let comparatorValue = median(comparator.map(item => item.SumZeroToSixMonths + item.SumSevenToTwelveMonths));

        kpi.value = selectedValue;

        if (selectedValue === comparatorValue) {
            kpi.percentageDifference = 0;
        } else {
            kpi.percentageDifference = (comparatorValue === 0) ? 100 * (abs(selectedValue) / selectedValue) : 100 * (selectedValue - comparatorValue) / abs(comparatorValue);
        }

        return kpi;
    }
);

const shortTermNetOpenings = createSelector(
    openVsClosuresSelector,
    state => selectStore(state),
    state => selectComparator(state),
    (netOpenVsClosuresOverTime, selectedStore, selectedComparator) => {
        const rag = {
            loading: netOpenVsClosuresOverTime.loading,
            error: netOpenVsClosuresOverTime.error,
            id: "short-term-net-openings",
            label: "Short term net openings",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || netOpenVsClosuresOverTime.store.length === 0) {
            return rag;
        }

        const store = netOpenVsClosuresOverTime.store;
        const comparator = netOpenVsClosuresOverTime.comparator;
        let A = store[3] + store[2];
        let B = median((comparator.map(item => item.SumZeroToSixMonths + item.SumSevenToTwelveMonths)));

        let x = ((A - B) / abs(B || 1)) * 100;

        if (x > 50) {
            rag.status = "success";
            rag.value = `Short Term Net Openings in the local area of ${selectedStore.name} are markedly ahead of ${selectedComparator.name}`;
        } else if (x <= 50 && x >= -50) {
            rag.status = "warning";
            rag.value = `Short Term Net Openings in the local area of ${selectedStore.name} are broadly in line with ${selectedComparator.name}`;
        } else {
            rag.status = "error";
            rag.value = `Short Term Net Openings in the local area of ${selectedStore.name} are markedly behind ${selectedComparator.name}`;
        }
        return rag;
    }
);

const longTermNetOpenings = createSelector(
    openVsClosuresSelector,
    state => selectStore(state),
    state => selectComparator(state),
    (netOpenVsClosuresOverTime, selectedStore, selectedComparator) => {
        const rag = {
            loading: netOpenVsClosuresOverTime.loading,
            error: netOpenVsClosuresOverTime.error,
            id: "long-term-net-openings",
            label: "Long term net openings",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || netOpenVsClosuresOverTime.store.length === 0) {
            return rag;
        }

        const store = netOpenVsClosuresOverTime.store;
        const comparator = netOpenVsClosuresOverTime.comparator;


        const storeName = selectedStore.name;
        const comparatorName = selectedComparator.name;

        let A = store[0] + store[1] + store[2] + store[3];
        let B = median((comparator.map(item => item.SumZeroToSixMonths + item.SumSevenToTwelveMonths + item.SumThirteenToTwentyFourMonths + item.SumTwentyFiveToSixtyMonths)));
        let x = ((A - B) / abs(B || 1)) * 100;

        if (x > 50) {
            rag.status = "success";
            rag.value = `Long term net openings in the local area of ${storeName} are markedly ahead of ${comparatorName}`;
        } else if (x <= 50 && x >= -50) {
            rag.status = "warning";
            rag.value = `Long term net openings in the local area of ${storeName} are broadly in line with ${comparatorName}`;
        } else {
            rag.status = "error";
            rag.value = `Long term net openings in the local area of ${storeName} are markedly behind ${comparatorName}`;
        }
        return rag;
    }
);

const selectors = {
    netOpeningsInPastYear,
    lifetimeOfStores,
    netOpenVsClosuresOverTime,
    shortTermNetOpenings,
    longTermNetOpenings
};

export default selectors;
