import _ from "lodash";

const padArray = (arr, desiredLen, padWith = null, padToTheRight = true) => {

    const arrLen = arr.length;

    if (arrLen >= desiredLen) {
        return arr;
    } else if (padToTheRight) {
        return arr.concat(Array(desiredLen).fill(padWith)).slice(0, desiredLen);
    } else {
        return Array(desiredLen).fill(padWith).concat(arr).slice(arrLen);
    }
};

const bifurcateCubeResultsOnFlag = (resultSet, dataField, flagField, flagFirst,
                                    flagSecond, arrLen, overlappingData = true) => {

    let firstArr = resultSet.chartPivot().filter(arr => arr[flagField] === flagFirst).map(item => item[dataField]);

    const firstArrLastDataIndex = firstArr.length - 1;

    let secondArr = resultSet.chartPivot().filter(arr => arr[flagField] === flagSecond).map(item => item[dataField]);

    firstArr = padArray(firstArr, arrLen, null, true);
    secondArr = padArray(secondArr, arrLen, null, false);


    if (overlappingData) {
        secondArr[firstArrLastDataIndex] = firstArr[firstArrLastDataIndex];
    }

    return [firstArr, secondArr];
};

const arrayColumn = (array, columnName) => {
    /* Returns array from the 'column' of an array of collections. 
    
    Eg 
    arrayColumn([
        {col1: 1, col2: 2},
        {col1: 3, col2: 4}
    ], 'col1')

    would return [1,3]
     */
    return array.map(e => e[columnName]);
};

const drilldownDataFromArrayOfSeries = (arrOfDrilldownLevels, groupByField = "id") => {

    let drilldownData = [];

    for (let i = 0; i < arrOfDrilldownLevels.length; i++) {
        const grouped = _.groupBy(arrOfDrilldownLevels[i], groupByField);

        _.forEach(grouped, (value, key) => {
            let dataArray = [];

            _.forEach(value, (value) => {
                dataArray.push(_.omit(value, [groupByField]));
            });
            drilldownData.push({
                    name: key,
                    id: key,
                    data: dataArray
                }
            );
        });
    }

    return drilldownData;
};

const bifurcateCollectionOnFlag = (collection, dataField, flagField, flagFirst, flagSecond, arrLen = collection.length, overlappingData = true) => {
    let firstArr = arrayColumn(_.filter(collection, [flagField, flagFirst]), dataField);

    const firstArrLastDataIndex = firstArr.length - 1;

    let secondArr = arrayColumn(_.filter(collection, [flagField, flagSecond]), dataField);

    firstArr = padArray(firstArr, arrLen, null, true);
    secondArr = padArray(secondArr, arrLen, null, false);

    if (overlappingData) {
        secondArr[firstArrLastDataIndex] = firstArr[firstArrLastDataIndex];
    }

    return [firstArr, secondArr];
};

const make2DFrom1D = (data) => {
    let xydata = [];
    let jspace = 20;
    let l = undefined;
    let c = 1;

    for (let i = 0; i < data.length; i++) {
        let d = data[i];
        if (Math.floor(d) !== l) {
            l = Math.floor(d);
            c = 1;
        } else {
            c += 1;
        }

        let m = (c % 2 === 0) ? 1 : -1;
        let j = (Math.floor(c / 2) * m) / jspace;

        while (j > 1 || j < -1) {
            let n = Math.floor(Math.abs(j)) + 1;
            j = (Math.floor(c / 2) * m) / (jspace * n);
        }

        xydata.push([d, j]);
    }
    return xydata;
};

const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const sortAsc = arr => {
    if (Array.isArray(arr)) return arr.sort((a, b) => a - b);
    return [];
};

const sortDesc = arr => {
    if (Array.isArray(arr)) return arr.sort((a, b) => b - a);
    return [];
};

const quantile = (arr, q) => {
    /*
    Feed an array with q as desired quantile point. q should be given as a decimal
    e.g. quantile(arr, 0.5) would give the median. 
    */
    const sorted = sortAsc(arr); 
    const quantileThresholdPos = q * (sorted.length + 1) - 1;
    const lowerQuantilePos = Math.floor(quantileThresholdPos);
    const upperQuantilePos = Math.ceil(quantileThresholdPos);
    const lowerQuantileVal = sorted[lowerQuantilePos];

    if (lowerQuantilePos === sorted.length) {
        return sorted[lowerQuantilePos - 1];
    } else if (sorted[lowerQuantilePos] === undefined) {
        return sorted[0];
    } else if (sorted[upperQuantilePos] !== undefined) {
        const upperQuantileVal = sorted[upperQuantilePos];
        const rankDistance = quantileThresholdPos - lowerQuantilePos;
        const valDistance = upperQuantileVal - lowerQuantileVal;
        const quantileThresholdVal = lowerQuantileVal + rankDistance * valDistance;

        return quantileThresholdVal;
    }
};

const arrayUtils = {
    padArray,
    bifurcateCubeResultsOnFlag,
    arrayColumn,
    drilldownDataFromArrayOfSeries,
    bifurcateCollectionOnFlag,
    make2DFrom1D,
    median,
    sortAsc,
    sortDesc,
    quantile
};

export default arrayUtils;
