import { immerable } from "immer";

export enum AlignmentCentile {
    Affluence = "affluenceCentile",
    Age = "ageCentile",
    Children = "childrenCentile",
    Diversity = "diversityCentile",
    Urbanicity = "urbanicityCentile",
    Spend = "spendCentile",
    AreaHealth = "areaHealthCentile",
    Footfall = "footfallCentile"
}

export class AnalogueAlignmentMetric {
    [immerable] = true;

    public readonly name: string;
    public readonly centile: AlignmentCentile;
    private _isSelected: boolean;

    constructor(
        name: string,
        centile: AlignmentCentile,
        isSelected: boolean
    ) {
        this.name = name;
        this.centile = centile;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const analogueAlignmentMetrics = [new AnalogueAlignmentMetric(
    "Affluence",
    AlignmentCentile.Affluence,
    true
), new AnalogueAlignmentMetric(
    "Age",
    AlignmentCentile.Age,
    true
), new AnalogueAlignmentMetric(
    "Children",
    AlignmentCentile.Children,
    true
), new AnalogueAlignmentMetric(
    "Diversity",
    AlignmentCentile.Diversity,
    true
), new AnalogueAlignmentMetric(
    "Urbanicity",
    AlignmentCentile.Urbanicity,
    true
), new AnalogueAlignmentMetric(
    "Spend",
    AlignmentCentile.Spend,
    true
), new AnalogueAlignmentMetric(
    "Area health",
    AlignmentCentile.AreaHealth,
    true
), new AnalogueAlignmentMetric(
    "Footfall",
    AlignmentCentile.Footfall,
    true
)];
