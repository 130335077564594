import types from "./types";

const getPenPortraitsSearchRequest = () => ({
    type: types.GET_PEN_PORTRAITS_SEARCH_REQUEST
});

const getPenPortraitsSearchSuccess = (penPortraits) => ({
    type: types.GET_PEN_PORTRAITS_SEARCH_SUCCESS,
    payload: {
        penPortraits
    }
});

const getPenPortraitsSearchFailure = () => ({
    type: types.GET_PEN_PORTRAITS_SEARCH_FAILURE
});

const getCustomerProfilesRequest = () => ({
    type: types.GET_CUSTOMER_PROFILES_REQUEST
});

const getCustomerProfilesSuccess = (values) => ({
    type: types.GET_CUSTOMER_PROFILES_SUCCESS,
    payload: {
        values
    }
});

const getCustomerProfilesFailure = () => ({
    type: types.GET_CUSTOMER_PROFILES_FAILURE
});

const getPopulationRequest = () => ({
    type: types.GET_POPULATION_REQUEST
});

const getPopulationSuccess = (selectedStore, comparator) => ({
    type: types.GET_POPULATION_SUCCESS,
    payload: {
        selectedStore,
        comparator
    }
});

const getPopulationFailure = () => ({
    type: types.GET_POPULATION_FAILURE
});

const getMarketCategorySpendRequest = () => ({
    type: types.GET_MARKET_CATEGORY_SPEND_REQUEST
});

const getMarketCategorySpendSuccess = (values) => ({
    type: types.GET_MARKET_CATEGORY_SPEND_SUCCESS,
    payload: {
        values
    }
});

const getMarketCategorySpendFailure = () => ({
    type: types.GET_MARKET_CATEGORY_SPEND_FAILURE
});

const actions = {
    getPenPortraitsSearchRequest,
    getPenPortraitsSearchSuccess,
    getPenPortraitsSearchFailure,
    getCustomerProfilesRequest,
    getCustomerProfilesSuccess,
    getCustomerProfilesFailure,
    getPopulationRequest,
    getPopulationSuccess,
    getPopulationFailure,
    getMarketCategorySpendRequest,
    getMarketCategorySpendSuccess,
    getMarketCategorySpendFailure
};

export default actions;
