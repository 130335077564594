import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectNumberOfCompetitors } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import numberFormatter from "utils/numberFormatter";

const DirectCompetitors = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const numberOfCompetitors = useAppSelector(selectNumberOfCompetitors);
    const selectedStoreNumberOfDirectCompetitors = numberOfCompetitors.data.selectedStoreNumberOfDirectCompetitors;
    const selectedComparatorNumberOfDirectCompetitors = numberOfCompetitors.data.selectedComparatorNumberOfDirectCompetitors;
    const difference = 100 * ((selectedStoreNumberOfDirectCompetitors - selectedComparatorNumberOfDirectCompetitors) / selectedComparatorNumberOfDirectCompetitors);
    const differencePositiveSign = difference > 0 ? "+" : "";

    const label = `Number of direct competitors within 5kms of your ${selectedStore?.name} store`;

    return (
        <Box width="100%" height="100%" data-cy="direct-competitors-within-five-km">
            <KPIComparison
                isLoading={numberOfCompetitors.isLoading}
                hasErrors={numberOfCompetitors.hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {selectedStoreNumberOfDirectCompetitors}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {selectedComparatorNumberOfDirectCompetitors}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${selectedComparator?.name} `}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {differencePositiveSign}{numberFormatter.toPercentage(difference, true, 2)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default DirectCompetitors;
