import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

export interface Category {
    id: string,
    name: string,
    showOnLandingArea: boolean,
    showOnCustomerArea: boolean
}

interface CategoryForm {
    id: string,
    name: string,
    showOnLandingArea: boolean,
    showOnCustomerArea: boolean
    errors: CategoryFormErrors
}

interface CategoryFormErrors {
    name: string
}

interface AddCategoryVisibility {
    isVisible: boolean
}

interface EditCategoryVisibility {
    isVisible: boolean,
    categoryId: string
}

interface DeleteCategoryVisibility {
    isVisible: boolean,
    categoryId: string
}

export interface Question {
    id: string,
    question: string,
    answer: string,
    showOnLandingArea: boolean,
    showOnCustomerArea: boolean
}

interface QuestionForm {
    id: string,
    question: string,
    answer: string,
    showOnLandingArea: boolean,
    showOnCustomerArea: boolean,
    errors: QuestionFormErrors
}

interface QuestionFormErrors {
    question: string,
    answer: string
}

interface QuestionsVisibility {
    isVisible: boolean,
    categoryId: string
}

interface AddQuestionVisibility {
    isVisible: boolean
}

interface EditQuestionVisibility {
    isVisible: boolean,
    questionId: string
}

interface DeleteQuestionVisibility {
    isVisible: boolean,
    questionId: string,
}

interface FrequentlyAskedQuestionsState {
    categories: Category[],
    category: CategoryForm,
    addCategoryVisibility: AddCategoryVisibility,
    editCategoryVisibility: EditCategoryVisibility,
    deleteCategoryVisibility: DeleteCategoryVisibility,
    questions: Question[],
    question: QuestionForm,
    questionsVisibility: QuestionsVisibility,
    addQuestionVisibility: AddQuestionVisibility,
    editQuestionVisibility: EditQuestionVisibility,
    deleteQuestionVisibility: DeleteQuestionVisibility
}

const initialState: FrequentlyAskedQuestionsState = {
    categories: [],
    category: {
        id: "",
        name: "",
        showOnLandingArea: false,
        showOnCustomerArea: false,
        errors: {
            name: ""
        }
    },
    addCategoryVisibility: {
        isVisible: false
    },
    editCategoryVisibility: {
        isVisible: false,
        categoryId: ""
    },
    deleteCategoryVisibility: {
        isVisible: false,
        categoryId: ""
    },
    questions: [],
    question: {
        id: "",
        question: "",
        answer: "",
        showOnLandingArea: false,
        showOnCustomerArea: false,
        errors: {
            question: "",
            answer: ""
        }
    },
    questionsVisibility: {
        isVisible: false,
        categoryId: ""
    },
    addQuestionVisibility: {
        isVisible: false
    },
    editQuestionVisibility: {
        isVisible: false,
        questionId: ""
    },
    deleteQuestionVisibility: {
        isVisible: false,
        questionId: ""
    }
};

const frequentlyAskedQuestionsSlice = createSlice({
    name: "admin/frequentlyAskedQuestions",
    initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<Category[]>) => {
            state.categories = action.payload;
        },
        clearCategories: (state) => {
            state.categories = initialState.categories;
        },
        setCategory: (state, action: PayloadAction<CategoryForm>) => {
            state.category = action.payload;
        },
        clearCategory: (state) => {
            state.category = initialState.category;
        },
        showAddCategory: (state) => {
            state.addCategoryVisibility.isVisible = true;
        },
        hideAddCategory: (state) => {
            state.addCategoryVisibility = initialState.addCategoryVisibility;
        },
        showEditCategory: (state, action: PayloadAction<string>) => {
            state.editCategoryVisibility.isVisible = true;
            state.editCategoryVisibility.categoryId = action.payload;
        },
        hideEditCategory: (state) => {
            state.editCategoryVisibility = initialState.editCategoryVisibility;
        },
        showDeleteCategory: (state, action: PayloadAction<string>) => {
            state.deleteCategoryVisibility.isVisible = true;
            state.deleteCategoryVisibility.categoryId = action.payload;
        },
        hideDeleteCategory: (state) => {
            state.deleteCategoryVisibility = initialState.deleteCategoryVisibility;
        },
        setQuestions: (state, action: PayloadAction<Question[]>) => {
            state.questions = action.payload;
        },
        clearQuestions: (state) => {
            state.questions = initialState.questions;
        },
        setQuestion: (state, action: PayloadAction<QuestionForm>) => {
            state.question = action.payload;
        },
        clearQuestion: (state) => {
            state.question = initialState.question;
        },
        showQuestions: (state, action: PayloadAction<string>) => {
            state.questionsVisibility.isVisible = true;
            state.questionsVisibility.categoryId = action.payload;
        },
        hideQuestions: (state) => {
            state.questionsVisibility = initialState.questionsVisibility;
        },
        showAddQuestion: (state) => {
            state.addQuestionVisibility.isVisible = true;
        },
        hideAddQuestion: (state) => {
            state.addQuestionVisibility = initialState.addQuestionVisibility;
        },
        showEditQuestion: (state, action: PayloadAction<string>) => {
            state.editQuestionVisibility.isVisible = true;
            state.editQuestionVisibility.questionId = action.payload;
        },
        hideEditQuestion: (state) => {
            state.editQuestionVisibility = initialState.editQuestionVisibility;
        },
        showDeleteQuestion: (state, action: PayloadAction<string>) => {
            state.deleteQuestionVisibility.isVisible = true;
            state.deleteQuestionVisibility.questionId = action.payload;
        },
        hideDeleteQuestion: (state) => {
            state.deleteQuestionVisibility = initialState.deleteQuestionVisibility;
        }
    }
});

export const {
    setCategory,
    clearCategory,
    showAddCategory,
    hideAddCategory,
    showEditCategory,
    hideEditCategory,
    showDeleteCategory,
    hideDeleteCategory,
    clearQuestions,
    setQuestion,
    clearQuestion,
    showQuestions,
    hideQuestions,
    showAddQuestion,
    hideAddQuestion,
    showEditQuestion,
    hideEditQuestion,
    showDeleteQuestion,
    hideDeleteQuestion
} = frequentlyAskedQuestionsSlice.actions;

export const getCategories = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/frequently-asked-questions"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const categories = response.data.categories;
            dispatch(frequentlyAskedQuestionsSlice.actions.setCategories(categories));
            break;
        }
        default: {
            dispatch(frequentlyAskedQuestionsSlice.actions.clearCategories());
            break;
        }
    }
};

export const getCategory = (categoryId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/frequently-asked-questions/${categoryId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const category = response.data.category;
            dispatch(frequentlyAskedQuestionsSlice.actions.setCategory({
                ...category,
                errors: initialState.category.errors
            }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditCategory());
            dispatch(frequentlyAskedQuestionsSlice.actions.hideDeleteCategory());
            dispatch(notifyError("Category not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const addCategory = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const category = selectCategory(state);
    const response = await dispatch(apiPost("/admin/frequently-asked-questions", category));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(frequentlyAskedQuestionsSlice.actions.hideAddCategory());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearCategory());
            dispatch(notifySuccess("Category added."));
            dispatch(getCategories());
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0]
            };
            dispatch(frequentlyAskedQuestionsSlice.actions.setCategory({ ...category, errors }));
            break;
        }
        default:
            break;
    }
};

export const editCategory = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const category = selectCategory(state);
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${category.id}`, category));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditCategory());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearCategory());
            dispatch(notifySuccess("Category edited."));
            dispatch(getCategories());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditCategory());
            dispatch(notifyError("Category not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                name: response.errorData?.errors?.name?.[0]
            };
            dispatch(frequentlyAskedQuestionsSlice.actions.setCategory({ ...category, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const deleteCategory = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const category = selectCategory(state);
    const response = await dispatch(apiDelete(`/admin/frequently-asked-questions/${category.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideDeleteCategory());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearCategory());
            dispatch(notifySuccess("Category deleted."));
            dispatch(getCategories());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Category not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const moveCategoryUp = (categoryId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${categoryId}/move-up`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getCategories());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Category not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const moveCategoryDown = (categoryId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${categoryId}/move-down`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getCategories());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Category not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const getQuestions = (categoryId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/frequently-asked-questions/${categoryId}/questions`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const questions = response.data.questions;
            dispatch(frequentlyAskedQuestionsSlice.actions.setQuestions(questions));
            break;
        }
        default: {
            dispatch(frequentlyAskedQuestionsSlice.actions.clearQuestions());
            break;
        }
    }
};

export const getQuestion = (categoryId: string, questionId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/frequently-asked-questions/${categoryId}/questions/${questionId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const question = response.data.question;
            dispatch(frequentlyAskedQuestionsSlice.actions.setQuestion({
                ...question,
                errors: initialState.question.errors
            }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditQuestion());
            dispatch(frequentlyAskedQuestionsSlice.actions.hideDeleteQuestion());
            dispatch(notifyError("Question not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const addQuestion = (categoryId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const question = selectQuestion(state);
    const response = await dispatch(apiPost(`/admin/frequently-asked-questions/${categoryId}/questions`, question));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(frequentlyAskedQuestionsSlice.actions.hideAddQuestion());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearQuestion());
            dispatch(notifySuccess("Question added."));
            dispatch(getQuestions(categoryId));
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                question: response.errorData?.errors?.question?.[0],
                answer: response.errorData?.errors?.answer?.[0]
            };
            dispatch(frequentlyAskedQuestionsSlice.actions.setQuestion({ ...question, errors }));
            break;
        }
        default:
            break;
    }
};

export const editQuestion = (categoryId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const question = selectQuestion(state);
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${categoryId}/questions/${question.id}`, question));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditQuestion());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearQuestion());
            dispatch(notifySuccess("Question edited."));
            dispatch(getQuestions(categoryId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideEditQuestion());
            dispatch(notifyError("Question not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                question: response.errorData?.errors?.question?.[0],
                answer: response.errorData?.errors?.answer?.[0]
            };
            dispatch(frequentlyAskedQuestionsSlice.actions.setQuestion({ ...question, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const deleteQuestion = (categoryId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const question = selectQuestion(state);
    const response = await dispatch(apiDelete(`/admin/frequently-asked-questions/${categoryId}/questions/${question.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(frequentlyAskedQuestionsSlice.actions.hideDeleteQuestion());
            dispatch(frequentlyAskedQuestionsSlice.actions.clearQuestion());
            dispatch(notifySuccess("Question deleted."));
            dispatch(getQuestions(categoryId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Question not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const moveQuestionUp = (categoryId: string, questionId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${categoryId}/questions/${questionId}/move-up`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getQuestions(categoryId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Question not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const moveQuestionDown = (categoryId: string, questionId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPut(`/admin/frequently-asked-questions/${categoryId}/questions/${questionId}/move-down`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getQuestions(categoryId));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Category not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const selectCategories = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.categories;
};

export const selectCategory = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.category;
};

export const selectAddCategoryVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.addCategoryVisibility;
};

export const selectEditCategoryVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.editCategoryVisibility;
};

export const selectDeleteCategoryVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.deleteCategoryVisibility;
};

export const selectQuestions = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.questions;
};

export const selectQuestion = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.question;
};

export const selectQuestionsVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.questionsVisibility;
};

export const selectAddQuestionVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.addQuestionVisibility;
};

export const selectEditQuestionVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.editQuestionVisibility;
};

export const selectDeleteQuestionVisibility = (state: RootState) => {
    return state.admin.frequentlyAskedQuestions.deleteQuestionVisibility;
};

export default frequentlyAskedQuestionsSlice;
