import React from "react";

import { Grid } from "@material-ui/core";
import AnalogueStores from "./AnalogueStores";
import AnalogueStoreGroupsFilter from "./AnalogueStoreGroupsFilter";
import AnalogueAlignmentMetricsFilter from "./AnalogueAlignmentMetricsFilter";

const Analogues: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs="auto">
                <AnalogueStoreGroupsFilter />
            </Grid>
            <Grid item xs="auto">
                <AnalogueAlignmentMetricsFilter />
            </Grid>
            <Grid item xs={12}>
                <AnalogueStores />
            </Grid>
        </Grid>
    );
};

export default Analogues;
