import React from "react";

import { Box, Grid } from "@material-ui/core";

import SpendByCustomerProfile from "./SpendByCustomerProfile";

const CustomerProfileSpend: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SpendByCustomerProfile />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerProfileSpend;
