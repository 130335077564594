import React from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { dateFormat } from "highcharts";
import { useTheme } from "@material-ui/core/styles";
import useColourPalette from "components/visuals/useColourPalette";
import { useSelector } from "react-redux";
import ChartStockBase from "components/visuals/ChartStockBase";
import dateUtils from "utils/dateUtils";
import numberFormatter from "utils/numberFormatter";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/marketShare";
import stringUtils from "utils/stringUtils";

const MarketShareOverTime = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const marketShareOverTime = useSelector(state => selectors.marketShareOverTime(state));
    const { loadingMarketShare, errorMarketShare, store, comparator } = marketShareOverTime;
    const storeData = store.storeCatchmentArea;
    const comparatorData = comparator.comparatorCatchmentArea;
    const dates = dateUtils.datesToTime(store.dates);
    const storeDataValues = _.zip(dates, storeData);
    const comparatorDataValues = _.zip(dates, comparatorData);

    const openingsAndClosures = useSelector(state => selectors.openingsAndClosures(state));
    const { loadingOpeningsClosures, errorOpeningsClosures, openDirect, closeDirect, openOther, closeOther } = openingsAndClosures;

    const loading = loadingMarketShare || loadingOpeningsClosures;
    const error = errorMarketShare || errorOpeningsClosures;

    const dataFormat = (data) => {
        return data.map(row => {
            return {
                x: dateUtils.dateUTC(row.date).getTime(),
                y: 1,
                custom: {
                    size: row.size,
                    competitorName: row.name
                }
            };
        }).sort((a, b) => {
            return a.x - b.x;
        });
    };

    const yAxisStandardOptions = {
        gridLineWidth: 1,
        minorGridLineWidth: 0,
        gridLineColor: "#646174",
        opposite: false,
        labels: {
            style: {
                color: theme.palette.common.white
            },
            align: "right",
            x: -3
        },
        title: {
            style: {
                color: theme.palette.common.white
            }
        },
        resize: {
            enabled: true,
            lineWidth: 2,
            lineColor: "#F0F0F0"
        }
    };

    const xAxisStandardOptions = {
        type: "datetime",
        labels: {
            style: {
                color: theme.palette.common.white
            }
        },
        lineWidth: "2px",
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
        min: dates[0],
        max: dates[dates.length - 1]
    };

    const globalOptions = {
        lang: {
            rangeSelectorZoom: "Zoom"
        }
    };

    const options = {
        chart: {
            height: "50%"
        },

        xAxis: [

            _.merge({}, xAxisStandardOptions, {
                dateTimeLabelFormats: {
                    second: "%b",
                    minute: "%b",
                    hour: "%b",
                    day: "%b",
                    week: "%b",
                    month: "%b",
                    year: "%b"
                }
            }),

            _.merge({}, xAxisStandardOptions, {
                units: [["year", [1]]],
                dateTimeLabelFormats: {
                    second: "%Y",
                    minute: "%Y",
                    hour: "%Y",
                    day: "%Y",
                    week: "%Y",
                    month: "%Y",
                    year: "%Y"
                },
                labels: {
                    x: 50
                }
            })
        ],

        yAxis: [

            _.merge({}, yAxisStandardOptions, {
                height: "85%",
                allowDecimals: true,
                title: {
                    text: "Market share (%)",
                    style: {
                        color: theme.palette.common.white
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + "%";
                    },
                    style: {
                        color: theme.palette.common.white
                    }
                }
            }),
            _.merge({}, yAxisStandardOptions, {
                top: "85%",
                height: "15%",
                endOnTick: false,
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            })
        ],
        legend: {
            enabled: true,
            align: "center",
            useHTML: true,
            style: {
                color: theme.palette.common.white
            },
            itemHoverStyle: {
                color: theme.palette.common.white
            },
            labelFormatter: function () {
                let seriesIndex = this.userOptions.index;
                if (seriesIndex === 0) {
                    return this.name;
                } else if (seriesIndex === 1) {
                    return this.name;
                } else {

                    return this.name;
                }
            }
        },
        title: {
            text: `${selectedStore.name} vs ${selectedComparator.name} market share over time`
        },
        tooltip: {
            useHTML: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 6
                },
                tooltip: {
                    headerFormat: ``,
                    pointFormatter: function () {
                        const competitor = this.custom.competitorName;
                        const datePrefix = ` ${this.series.userOptions?.custom?.tooltipDateWording}` || ``;
                        const categories = [`Date${datePrefix}:`, `Size:`];
                        const sizeSqft = (this.custom.size == null) ? `Unknown` : `${numberFormatter.toDecimalPlaces(this.custom.size, 0, false)} sqft`;
                        const values = [dateFormat("%B %Y", this.x), sizeSqft];

                        return `<table>${stringUtils.tooltipHTML(categories, {
                            values: values,
                            header: competitor,
                            headerFormatting: `color:${this.series.color}`
                        })}</table>`;
                    }
                }
            },
            spline: {
                tooltip: {
                    enabled: true,
                    pointFormatter: function() {
                        return `<table>${stringUtils.tooltipHTML(["Market share"], {
                            header: this.series.name,
                            values: [numberFormatter.toPercentage(this.y)],
                            headerFormatting: `font-weight:bold;color:${this.series.color}`
                        })}</table>`;
                    }
                }
            }
        },
        series: [
            {
                type: "spline",
                name: selectedStore.name,
                yAxis: 0,
                xAxis: 1,
                id: "Store",
                data: storeDataValues,
                showInLegend: true,
                color: colourPalette.comparators[0]
            },
            {
                type: "spline",
                name: selectedComparator.name,
                id: "Comparator",
                linkedTo: "Store",
                yAxis: 0,
                xAxis: 1,
                color: colourPalette.comparators[1],
                data: comparatorDataValues,
                showInLegend: true
            },

            {
                type: "scatter",
                name: "Opening of direct competitor",
                linkedTo: "Store",
                yAxis: 1,
                xAxis: 0,
                data: dataFormat(openDirect),
                showInLegend: true,
                color: colourPalette.RAGIndicators[0],
                marker: {
                    symbol: "diamond",
                },
                custom: {
                    tooltipDateWording: "opened"
                }
            },
            {
                type: "scatter",
                name: `Closure of direct competitor`,
                linkedTo: "Store",
                yAxis: 1,
                xAxis: 0,
                data: dataFormat(closeDirect),
                showInLegend: true,
                color: colourPalette.RAGIndicators[2],
                marker: {
                    symbol: "diamond",
                },
                custom: {
                    tooltipDateWording: "closed"
                }
            },
            {
                type: "scatter",
                name: "Opening of competitor",
                linkedTo: "Store",
                yAxis: 1,
                xAxis: 1,
                data: dataFormat(openOther),
                showInLegend: true,
                color: colourPalette.RAGIndicators[0],
                marker: {
                    symbol: "circle",
                    fillColor: "transparent",
                    lineWidth: 2,
                    lineColor: colourPalette.RAGIndicators[0]
                },
                custom: {
                    tooltipDateWording: "opened"
                }
            },
            {
                type: "scatter",
                name: "Closure of competitor",
                linkedTo: "Store",
                yAxis: 1,
                xAxis: 0,
                data: dataFormat(closeOther),
                showInLegend: true,
                color: colourPalette.RAGIndicators[2],
                marker: {
                    symbol: "circle",
                    fillColor: "transparent",
                    lineWidth: 2,
                    lineColor: colourPalette.RAGIndicators[2]
                },
                custom: {
                    tooltipDateWording: "closed"
                }
            }
        ],

        responsive: {
            rules: [{
                chartOptions: {
                    chart: {
                        height: "120%"
                    }
                },
                condition: {
                    maxWidth: 500
                }
            }]
        },

        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        }
    };

    return (
        <Box data-cy="market-share-over-time">
            <ChartStockBase loading={loading} error={error} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default MarketShareOverTime;
