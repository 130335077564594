import React from "react";

const OverviewSubtitle: React.FC = () => {
    return (
        <>
            Our Portfolio insight allows you to select a store and compare it to other stores within your portfolio. We
            have designed this insight to evaluate the financial performance of the chosen store and then drill down
            into what is driving this performance. This insight will help you identify your best and worst stores and
            give you the knowledge to replicate best practices across your portfolio.
        </>
    );
};

export default OverviewSubtitle;
