import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/location/store";
import { ExtendedResultSet, cubeLoad, cubeLoadCatchmentArea } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import {
    AffluenceMetrics,
    AgeMetrics,
    AreaHealthComponentMetrics,
    ChildrenMetrics,
    DiversityMetrics,
    FootfallComponentMetrics,
    UrbanicityMetrics
} from "./componentMetricCentiles";

export class ComparatorComponentMetrics {
    public readonly retailCentreId: number;
    public readonly storeCategoryId: number;
    public readonly isScenario: boolean;
    public readonly affluenceCentile: number;
    public readonly affluenceMetrics?: AffluenceMetrics | null;
    public readonly ageCentile: number;
    public readonly ageMetrics?: AgeMetrics;
    public readonly childrenCentile: number;
    public readonly childrenMetrics?: ChildrenMetrics;
    public readonly diversityCentile: number;
    public readonly diversityMetrics?: DiversityMetrics;
    public readonly urbanicityCentile: number;
    public readonly urbanicityMetrics?: UrbanicityMetrics;
    public readonly areaHealthCentile: number;
    public readonly areaHealthMetrics: AreaHealthComponentMetrics;
    public readonly footfallCentile: number;
    public readonly footfallMetrics: FootfallComponentMetrics;

    constructor(
        retailCentreId: number,
        storeCategoryId: number,
        isScenario: boolean,
        areaHealthCentile: number,
        footfallCentile: number,
        affluenceCentile: number,
        ageCentile: number,
        childrenCentile: number,
        diversityCentile: number,
        urbanicityCentile: number,
        areaHealthMetrics: AreaHealthComponentMetrics,
        footfallMetrics: FootfallComponentMetrics,
        affluenceMetrics?: AffluenceMetrics,
        ageMetrics?: AgeMetrics,
        childrenMetrics?: ChildrenMetrics,
        diversityMetrics?: DiversityMetrics,
        urbanicityMetrics?: UrbanicityMetrics,
    ) {
        this.retailCentreId = retailCentreId;
        this.storeCategoryId = storeCategoryId;
        this.isScenario = isScenario;
        this.affluenceCentile = affluenceCentile;
        this.affluenceMetrics = affluenceMetrics;
        this.ageCentile = ageCentile;
        this.ageMetrics = ageMetrics;
        this.childrenCentile = childrenCentile;
        this.childrenMetrics = childrenMetrics;
        this.diversityCentile = diversityCentile;
        this.diversityMetrics = diversityMetrics;
        this.urbanicityCentile = urbanicityCentile;
        this.urbanicityMetrics = urbanicityMetrics;
        this.areaHealthCentile = areaHealthCentile;
        this.areaHealthMetrics = areaHealthMetrics;
        this.footfallCentile = footfallCentile;
        this.footfallMetrics = footfallMetrics;
    }
}

export const loadComparatorComponentMetrics = (isScenario: boolean, catchmentAccountId: string, storeCategoryId?: number, retailCentreIds?: number[]): AppThunk<Promise<ComparatorComponentMetrics[]>> => async (dispatch) => {
    if (!storeCategoryId || !retailCentreIds || retailCentreIds.length === 0) {
        return [];
    }
    try {
        const retailCentreStringIds = retailCentreIds.map(item => String(item));
        const isScenarioString = isScenario ? "1" : "0";
        const query = {
            dimensions: [
                "LocationBenchmarkRetailCentreMetrics.RetailCentre_ID",
                "LocationBenchmarkRetailCentreMetrics.IsScenario",
                "LocationBenchmarkRetailCentreMetrics.AffluenceCentile",
                "LocationBenchmarkRetailCentreMetrics.MedianIncomeBracket",
                "LocationBenchmarkRetailCentreMetrics.HomeOwnership_Percent",
                "LocationBenchmarkRetailCentreMetrics.MedianOccupancyRating",
                "LocationBenchmarkRetailCentreMetrics.AgeCentile",
                "LocationBenchmarkRetailCentreMetrics.Age17to35_Percent",
                "LocationBenchmarkRetailCentreMetrics.Age36to55_Percent",
                "LocationBenchmarkRetailCentreMetrics.Age56Plus_Percent",
                "LocationBenchmarkRetailCentreMetrics.Age16to24_Percent",
                "LocationBenchmarkRetailCentreMetrics.Age25to64_Percent",
                "LocationBenchmarkRetailCentreMetrics.Age65Plus_Percent",
                "LocationBenchmarkRetailCentreMetrics.ChildrenCentile",
                "LocationBenchmarkRetailCentreMetrics.Children0to5_Percent",
                "LocationBenchmarkRetailCentreMetrics.Children6to10_Percent",
                "LocationBenchmarkRetailCentreMetrics.Children11to16_Percent",
                "LocationBenchmarkRetailCentreMetrics.Children_Percent",
                "LocationBenchmarkRetailCentreMetrics.DiversityCentile",
                "LocationBenchmarkRetailCentreMetrics.MinorityEthnic_Percent",
                "LocationBenchmarkRetailCentreMetrics.LangNotEnglishOrWelsh_Percent",
                "LocationBenchmarkRetailCentreMetrics.NotBornInUK_Percent",
                "LocationBenchmarkRetailCentreMetrics.UrbanicityCentile",
                "LocationBenchmarkRetailCentreMetrics.MedianPopulationDensityBracket",
                "LocationBenchmarkRetailCentreMetrics.LivingInFlat_Percent",
                "LocationBenchmarkRetailCentreMetrics.MedianNumberOfCars",
                "LocationBenchmarkRetailCentreMetrics.AreaHealthCentile",
                "LocationBenchmarkRetailCentreMetrics.YoYNetOpenings_Percent",
                "LocationBenchmarkRetailCentreMetrics.FootfallCentile",
                "LocationBenchmarkRetailCentreMetrics.FootfallDensity"
            ],
            filters: [{
                member: "LocationBenchmarkRetailCentreMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(storeCategoryId)]
            }, {
                member: "LocationBenchmarkRetailCentreMetrics.RetailCentre_ID",
                operator: "equals",
                values: retailCentreStringIds
            }, {
                member: "LocationBenchmarkRetailCentreMetrics.IsScenario",
                operator: "equals",
                values: [isScenarioString]
            }]
        };
        const resultSet = await dispatch(cubeLoadCatchmentArea(query, catchmentAccountId, "LocationBenchmarkRetailCentreMetrics")) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => new ComparatorComponentMetrics(
            row["LocationBenchmarkRetailCentreMetrics.RetailCentre_ID"],
            storeCategoryId,
            Boolean(row["LocationBenchmarkRetailCentreMetrics.IsScenario"]),
            row["LocationBenchmarkRetailCentreMetrics.AreaHealthCentile"],
            row["LocationBenchmarkRetailCentreMetrics.FootfallCentile"],
            row["LocationBenchmarkRetailCentreMetrics.AffluenceCentile"],
            row["LocationBenchmarkRetailCentreMetrics.AgeCentile"],
            row["LocationBenchmarkRetailCentreMetrics.ChildrenCentile"],
            row["LocationBenchmarkRetailCentreMetrics.DiversityCentile"],
            row["LocationBenchmarkRetailCentreMetrics.UrbanicityCentile"],
            new AreaHealthComponentMetrics(
                row["LocationBenchmarkRetailCentreMetrics.YoYNetOpenings_Percent"]
            ),
            new FootfallComponentMetrics(
                row["LocationBenchmarkRetailCentreMetrics.FootfallDensity"]
            ),
            new AffluenceMetrics(
                row["LocationBenchmarkRetailCentreMetrics.MedianIncomeBracket"],
                row["LocationBenchmarkRetailCentreMetrics.HomeOwnership_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.MedianOccupancyRating"]
            ),
            new AgeMetrics(
                row["LocationBenchmarkRetailCentreMetrics.Age17to35_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.Age36to55_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.Age56Plus_Percent"]
            ),
            new ChildrenMetrics(
                row["LocationBenchmarkRetailCentreMetrics.Children0to5_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.Children6to10_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.Children11to16_Percent"]
            ),
            new DiversityMetrics(
                row["LocationBenchmarkRetailCentreMetrics.MinorityEthnic_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.LangNotEnglishOrWelsh_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.NotBornInUK_Percent"]
            ),
            new UrbanicityMetrics(
                row["LocationBenchmarkRetailCentreMetrics.MedianPopulationDensityBracket"],
                row["LocationBenchmarkRetailCentreMetrics.LivingInFlat_Percent"],
                row["LocationBenchmarkRetailCentreMetrics.MedianNumberOfCars"]
            ),
        ));
    } catch (error) {
        dispatch(logError("Error loading ComparatorComponentMetrics.", error));
        throw error;
    }
};

export const loadComparatorMetricCentiles = (targetStoreCategoryId?: number, stores?: Store[]): AppThunk<Promise<ComparatorComponentMetrics[]>> => async (dispatch) => {
    if (!targetStoreCategoryId || !stores || stores.length === 0) {
        return [];
    }
    try {
        const retailCentreStringIds = stores.map(store => String(store.retailCentre.id));
        const query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.IsScenario",
                "LocationBenchmarkMetrics.YoYNetOpeningsPercentage",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.FootfallDensity",
                "LocationBenchmarkMetrics.FootfallCentile",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile"
            ],
            filters: [{
                member: "LocationBenchmarkMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "LocationBenchmarkMetrics.RetailCentreID",
                operator: "equals",
                values: retailCentreStringIds
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return stores.map(store => {
            const isScenario = store.categoryId !== targetStoreCategoryId;
            const relevantRow = rawData.find(row =>
                row["LocationBenchmarkMetrics.RetailCentreID"] === store.retailCentre.id
                && Boolean(row["LocationBenchmarkMetrics.IsScenario"]) === isScenario
            );

            return new ComparatorComponentMetrics(
                relevantRow["LocationBenchmarkMetrics.RetailCentreID"],
                targetStoreCategoryId,
                Boolean(relevantRow["LocationBenchmarkMetrics.IsScenario"]),
                relevantRow["LocationBenchmarkMetrics.AreaHealthCentile"],
                relevantRow["LocationBenchmarkMetrics.FootfallCentile"],
                relevantRow["LocationBenchmarkMetrics.AffluenceCentile"],
                relevantRow["LocationBenchmarkMetrics.AgeCentile"],
                relevantRow["LocationBenchmarkMetrics.ChildrenCentile"],
                relevantRow["LocationBenchmarkMetrics.DiversityCentile"],
                relevantRow["LocationBenchmarkMetrics.UrbanicityCentile"],
                new AreaHealthComponentMetrics(relevantRow["LocationBenchmarkMetrics.YoYNetOpeningsPercentage"]),
                new FootfallComponentMetrics(relevantRow["LocationBenchmarkMetrics.FootfallDensity"]),
            );
        });
    } catch (error) {
        dispatch(logError("Error loading ComparatorComponentMetrics.", error));
        throw error;
    }
};
