import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Asses the performance drivers of your {store?.name} store, examining both revenue and cost in relation to its size.
            Benchmark against {comparator?.name} to gauge efficiency and determine whether your store's performance is proportional to its size.
            Use these insights to inform crucial lease renewal decisions with confidence.
        </>
    );
};

export default Subtitle;
