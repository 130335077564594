import types from "./types";

const getGrowthBreakdownGridRequest = () => ({
    type: types.GET_GROWTH_BREAKDOWN_GRID_REQUEST
});

const getGrowthBreakdownGridSuccess = (currentMonth, marketCategories, sector) => ({
    type: types.GET_GROWTH_BREAKDOWN_GRID_SUCCESS,
    payload: {
        currentMonth,
        marketCategories,
        sector
    }
});

const getGrowthBreakdownGridFailure = () => ({
    type: types.GET_GROWTH_BREAKDOWN_GRID_FAILURE
});

const actions = {
    getGrowthBreakdownGridRequest,
    getGrowthBreakdownGridSuccess,
    getGrowthBreakdownGridFailure,
};

export default actions;
