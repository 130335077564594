import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { selectComparator, selectShowSimilarityScores } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { ComparatorStoresSortField, selectComparatorStoresSort, selectSortedComparatorStores, setComparatorStoresSort } from "modules/customer/insights/portfolioNew/overview/overviewSlice";
import { SortDirection } from "utils/sortUtils";

const CustomAccordion = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.paper,
        borderRadius: "6px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgba(255, 255, 255, 0.25)"
    },
    expanded: {}
}))(Accordion);

const CustomAccordionSummary = withStyles((theme) => ({
    root: {
        // @ts-ignore
        color: theme.palette.text.primary,
        padding: 0,
        paddingRight: theme.spacing(1.5),
        minHeight: 0,
        "&$expanded": {
            padding: 0,
            paddingRight: theme.spacing(1.5),
            minHeight: 0,
            backgroundColor: theme.palette.primary.main,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    content: {
        paddingLeft: theme.spacing(1.5),
        "&$expanded": {
            margin: 0
        }
    },
    expanded: {}
}))(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
    root: {
        // @ts-ignore
        color: theme.palette.text.primary,
        padding: 0,
        paddingRight: theme.spacing(1.5),
        margin: 0,
    },
    content: {
        paddingLeft: theme.spacing(1.5)
    },
    expanded: {}
}))(AccordionDetails);

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        maxHeight: 210
    }
}))(TableContainer);

const StyledTableCell = withStyles(theme => ({
    root: {
        border: 0,
        padding: theme.spacing(1),
        // @ts-ignore
        backgroundColor: theme.palette.background.paper,
    }
}))(TableCell);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.secondary
    }
}))(Typography);

const ComparatorStoreDetails = () => {
    const dispatch = useAppDispatch();
    const comparator = useAppSelector(selectComparator);
    const comparatorStores = useAppSelector(selectSortedComparatorStores);
    const comparatorName = comparator?.name;
    const showSimilarityScores = useAppSelector(selectShowSimilarityScores);
    const sort = useAppSelector(selectComparatorStoresSort);

    const handleSortClick = (field: ComparatorStoresSortField) => () => {
        let direction = SortDirection.ASC;
        if (sort.field === field) {
            direction = sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSort = { ...sort, field, direction };
        dispatch(setComparatorStoresSort(newSort));
    };

    return (
        <CustomAccordion elevation={0} >
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="#FFFFFF" />} >
                <Typography variant="subtitle1" component="div">
                    Comparator stores ({comparatorName})
                </Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow style={{background: 'red'}}>
                            <StyledTableCell align="left">
                                <TableSortLabel
                                    active={sort.field === ComparatorStoresSortField.StoreName}
                                    direction={sort.direction}
                                    onClick={handleSortClick(ComparatorStoresSortField.StoreName)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="body1" component="div">
                                    Store name
                                </StyledTypography>
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TableSortLabel
                                    active={sort.field === ComparatorStoresSortField.Revenue}
                                    direction={sort.direction}
                                    onClick={handleSortClick(ComparatorStoresSortField.Revenue)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="body1" component="div">
                                    Revenue
                                </StyledTypography>
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TableSortLabel
                                    active={sort.field === ComparatorStoresSortField.GrossProfit}
                                    direction={sort.direction}
                                    onClick={handleSortClick(ComparatorStoresSortField.GrossProfit)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="body1" component="div">
                                    Gross profit
                                </StyledTypography>
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TableSortLabel
                                    active={sort.field === ComparatorStoresSortField.RevenuePerSqft}
                                    direction={sort.direction}
                                    onClick={handleSortClick(ComparatorStoresSortField.RevenuePerSqft)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="body1" component="div">
                                    Revenue/ft2
                                </StyledTypography>
                                </TableSortLabel>
                            </StyledTableCell>
                            {showSimilarityScores &&<StyledTableCell align="center">
                                <TableSortLabel
                                    active={sort.field === ComparatorStoresSortField.SimilarityScore}
                                    direction={sort.direction}
                                    onClick={handleSortClick(ComparatorStoresSortField.SimilarityScore)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="body1" component="div">
                                    Similarity score
                                </StyledTypography>
                                </TableSortLabel>
                            </StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comparatorStores.map((store, index) =>
                            <TableRow key={index}>
                                <StyledTableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {store.name}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toGBP(store.revenue, 1)}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant="body1" component="div">
                                    {numberFormatter.toPercentage(store.grossProfitMargin, false)}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant="body1" component="div">
                                    {numberFormatter.toGBP(store.revenuePerSquareFoot, 1)}
                                    </Typography>
                                </StyledTableCell>
                                {showSimilarityScores && <StyledTableCell align="center">
                                    <Typography variant="body1" component="div">
                                    {store.displaySimilarityScore()}
                                    </Typography>
                                </StyledTableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            </CustomAccordionDetails>
        </CustomAccordion>
    );
};

export default ComparatorStoreDetails;
