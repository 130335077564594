import React from "react";

import { Button } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "store";
import { chooseStore, clearSelectedStore } from "modules/customer/tools/product/productSlice";
import { hideStoreProductFilters, selectCandidateStore } from "modules/customer/tools/product/storeProductFilters/storeProductFiltersSlice";

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateStore = useAppSelector(selectCandidateStore);

    const handleFinishClick = () => {
        if (candidateStore) {
            dispatch(chooseStore(candidateStore));
        } else {
            dispatch(clearSelectedStore());
        }
        dispatch(hideStoreProductFilters());
    };

    return (
        <Button
            variant="contained"
            size="small"
            color="secondary"
            disableElevation
            onClick={handleFinishClick}
            data-cy="btn-finish"
        >
            Finish
        </Button>
    );
};

export default Actions;
