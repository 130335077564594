import { combineReducers } from "redux";

import billing from "./billing";
import settings from "./settings";
import users from "./users";


const reducer = combineReducers({
    billing,
    settings,
    users
});

export default reducer;
