import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";

import { logout } from "modules/auth/authSlice";

const Logout = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <Button
            variant="outlined"
            size="large"
            color="default"
            disableElevation
            onClick={handleLogout}
            data-cy="btn-logout"
        >
            Logout
        </Button>
    );
};

export default Logout;
