import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadResponseResult } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { backdropOn, backdropOff } from "modules/backdrop/backdropSlice";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";

import { CubeService } from "./cubeService";

interface Credentials {
    apiUrl: string,
    accessToken: string
}

interface CubeState {
    cubeService?: CubeService
}

export interface ExtendedResultSet<T> {
    loadResponses: LoadResponseResult<T>[]
}

const initialState: CubeState = {
    cubeService: undefined
};

const cubeSlice = createSlice({
    name: "helpers/cube",
    initialState,
    reducers: {
        setCubeService: (state, action: PayloadAction<CubeService>) => {
            state.cubeService = action.payload;
        }
    }
});

const getCredentials = (): AppThunk<Promise<Credentials>> => async (dispatch) => {
    dispatch(backdropOn());
    try {
        const response = await dispatch(apiGet("/customer/insights/credentials"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.credentials;
        }
        throw new Error("Error loading credentials.");
    } finally {
        dispatch(backdropOff());
    }
};

export const setupCube = (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(backdropOn());
    try {
        const credentials = await dispatch(getCredentials());
        const cubeService = new CubeService(credentials.apiUrl, credentials.accessToken);
        dispatch(cubeSlice.actions.setCubeService(cubeService));
    } catch (error) {
        dispatch(logError("Error setting up analytics provider.", error));
        throw error;
    } finally {
        dispatch(backdropOff());
    }
};

export const cubeLoad = (query: any): AppThunk => (dispatch, getState) => {
    const state = getState();
    const cubeService = selectCubeService(state);
    return cubeService?.load(query);
};

export const cubeLoadParallel = (query: any, rowsCount: number): AppThunk<Promise<any[]>> => async (dispatch, getState) => {
    const state = getState();
    const cubeService = selectCubeService(state);
    let result = [];
    if (cubeService) {
        result = await cubeService.loadParallel(query, rowsCount);
    }
    return result;
};

export const cubeLoadExtended = (query: any): AppThunk => (dispatch, getState) => {
    const state = getState();
    const cubeService = selectCubeService(state);
    return cubeService?.loadExtended(query);
};

export const cubeLoadCatchmentArea = (query: any, catchmentAccountId: string, catchmentSchemaName: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const cubeService = selectCubeService(state);
    return cubeService?.loadCatchmentArea(query, catchmentAccountId, catchmentSchemaName);
};

export const cubeSQL = (query: any): AppThunk => (dispatch, getState) => {
    const state = getState();
    const cubeService = selectCubeService(state);
    return cubeService?.sql(query);
};

export const selectCubeService = (state: RootState): CubeService | undefined => {
    return state.helpers.cube.cubeService;
};

export default cubeSlice;
