import { createSelector } from "reselect";

const storeActualVsBudgetRanked = (state) => {
    return state.customer.insights.performance.budget.storePerformanceAgainstBudget.storeActualVsBudgetRanked;
};

const storesVsBudget = createSelector(
    storeActualVsBudgetRanked,
    (storeActualVsBudgetRanked) => {
        const rag = {
            loading: false,
            error: false,
            id: "stores-vs-budget",
            label: "Stores vs budget",
            status: "info",
            value: ""
        };

        if (storeActualVsBudgetRanked.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (storeActualVsBudgetRanked.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const storePerformanceRankings = storeActualVsBudgetRanked.storePerformanceRankings;
        const totalStores = storePerformanceRankings.length;
        const storesWithData = storePerformanceRankings.filter(store => store.salesVsBudget).length;
        const storesBeatingSalesAndBudgets = storePerformanceRankings.filter(store => (store.salesVsBudget > 0) && (store.contributionVsBudget > 0)).length;
        const storesBehindSalesAndBudgets = storePerformanceRankings.filter(store => (store.salesVsBudget < 0) && (store.contributionVsBudget < 0)).length;
        const x = (storesBeatingSalesAndBudgets / totalStores) * 100;
        const x2 = (storesBehindSalesAndBudgets / totalStores) * 100;

        if (storesWithData === 0) {
            rag.status = "noData";
            rag.value = "This indicator isn't available because it requires your company's Budget data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Budget dataset and refresh your company's analytics.";
        } else {
            if (x >= 75) {
                rag.status = "success";
                rag.value = "Most of your stores are beating their revenue and contribution budgets.";
            } else if (x2 >= 75) {
                rag.status = "error";
                rag.value = "Most of your stores are behind their revenue and contribution budgets.";
            } else {
                rag.status = "warning";
                rag.value = "Your stores have a mixed performance against their revenue and contribution budgets.";
            }
        }
        return rag;
    }
);

const selectors = {
    storeActualVsBudgetRanked,
    storesVsBudget
};

export default selectors;
