import React from "react";

import { Card, CardMedia, Grid, Typography, useMediaQuery, Box, Avatar } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StyledAvatar = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        }
    }
}))(Avatar);

interface SectionProps {
    direction: "row" | "row-reverse",
    title: string,
    subtitle: string,
    icon: React.ReactElement,
    image: string,
    alt: string,
    bulletPoints: string[],
    isAvailable: boolean
}

const Section: React.FC<SectionProps> = (props) => {
    const { direction, title, subtitle, icon, image, alt, bulletPoints, isAvailable } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    return (
        <Grid container spacing={6} direction={direction} alignItems="center">
            <Grid item xs={12} md={6}>
                <StyledCard square elevation={0}>
                    <CardMedia component="img" image={image} alt={alt} />
                </StyledCard>
            </Grid>
            <Grid item xs={12} md={6}>
                {!isAvailable && (
                    <Box>
                        <Typography variant={isMobile ? "h6" : "h5"} component="div" align={align} style={{ color: theme.palette.secondary.main }}>
                            Coming soon
                        </Typography>
                        <br />
                    </Box>
                )}
                <Box display="flex" alignItems="center" justifyContent={isMobile ? "center" : "flex-start"}>
                    <StyledAvatar>{React.cloneElement(icon, { fontSize: isMobile ? "medium" : "large" })}</StyledAvatar>
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant={isMobile ? "h3" : "h1"} component="div" align={align}>
                        {title}
                    </Typography>
                </Box>
                <br />
                <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align={align}>
                    {subtitle}
                </Typography>
                <br />
                <Typography variant={isMobile ? "subtitle2" : "h6"} component="div" align={align} gutterBottom>
                    {title} includes:
                </Typography>
                {bulletPoints.map((bulletPoint, index) =>
                    <Box key={index} display="flex">
                        <CheckCircleIcon
                            fontSize={isMobile ? "small" : "medium"}
                            style={{
                                // @ts-ignore
                                color: theme.palette.background.defaultDark
                            }}
                        />
                        &nbsp;&nbsp;
                        <Typography variant={isMobile ? "body1" : "subtitle1"} component="div" gutterBottom>
                            {bulletPoint}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default Section;
