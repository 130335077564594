import React from "react";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";

import {
    hideFilters,
    hideSetupWarning,
    selectSetupWarningVisibility,
    showFilters
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const SetupWarning: React.FC = () => {
    const dispatch = useAppDispatch();
    const setupWarningVisibility = useAppSelector(selectSetupWarningVisibility);
    const show = setupWarningVisibility.isVisible;

    const handleConfirmClick = () => {
        dispatch(hideSetupWarning());
        dispatch(showFilters());
    };

    const handleCancelClick = () => {
        dispatch(hideFilters());
        dispatch(hideSetupWarning());
    };

    return (
        <ConfirmationDialog
            open={show}
            title="Making changes in Setup will remove your selected location pin"
            subtitle="This map will stay in place but you will need to drop a new pin."
            type={ConfirmationDialogType.Warning}
            confirmLabel="Continue to Setup"
            cancelLabel="Cancel"
            onConfirm={handleConfirmClick}
            onCancel={handleCancelClick}
        />
    );
};

export default SetupWarning;
