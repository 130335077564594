import React from "react";

import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Error from "components/visuals/Error";
import Progress from "components/visuals/Progress";
import useColourPalette from "components/visuals/useColourPalette";

import {
    selectModelFeatures,
    selectPredictionForm,
    selectPredictionHasErrors,
    selectPredictionIsLoading,
    selectStoreParametersForm
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";

const StyledCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        maxHeight: theme.spacing(28),
        overflow: "auto"
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const ProposedStoreParameters: React.FC = () => {
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const modelFeatures = useAppSelector(selectModelFeatures);
    const predictionForm = useAppSelector(selectPredictionForm);
    const storeParametersForm = useAppSelector(selectStoreParametersForm);
    const filledFeatures = Array.from(storeParametersForm).map(([name, value]) => ({ name, value }));
    const colourPalette = useColourPalette();

    const getDisplayName = (name: string) => {
        switch (name) {
            case "CarPark":
                return "Car park";
            case "ClickAndCollect":
                return "Click and collect";
            case "ClientDefinedRegion":
                return "Region";
            case "LocationType":
                return "Location type";
            case "NumberOfCarParkSpace":
                return "Number of parking spaces";
            case "NumberOfMannedTills":
                return "Number of manned tills";
            case "NumberOfSelfCheckout":
                return "Number of self-checkouts";
            case "OpenHoursWeek":
                return "Opening hours per week";
            case "Segment":
                return "Segment";
            case "StoreFormat":
                return "Format";
            case "TradingFloors":
                return "Number of trading floors";
            case "Visibility":
                return "Visibility";
            case "WheelchairAccessible":
                return "Wheelchair accessible";
            default: {
                const modelFeature = modelFeatures.find(modelFeature => modelFeature.defaultName === name);
                return modelFeature
                    ? modelFeature.displayName
                    : name;
            }
        }
    };

    if (predictionIsLoading) {
        return (<Progress />);
    }

    if (predictionHasErrors) {
        return (<Error />);
    }

    return (
        <StyledCard variant="outlined" data-cy="proposed-store-parameters">
            <StyledCardContent>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <Typography variant="subtitle1" component="div">
                        Group:&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {predictionForm.group}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <Typography variant="subtitle1" component="div">
                        Model:&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {predictionForm.model}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <Typography variant="subtitle1" component="div">
                        Sales area size:&nbsp;&nbsp;
                    </Typography>
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {predictionForm.salesAreaInSqft ?? 0} sqft
                    </Typography>
                </Box>
                {filledFeatures.map(feature =>
                    <Box key={feature.name} display="flex" alignItems="center" paddingBottom={1}>
                        <Typography variant="subtitle1" component="div">
                            {getDisplayName(feature.name)}:&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                            {feature.value}
                        </Typography>
                    </Box>
                )}
            </StyledCardContent>
        </StyledCard>
    );
};

export default ProposedStoreParameters;
