import React from "react";
import PropTypes from "prop-types";

import { Box, Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StyledIconCard = withStyles(theme => ({
    root: {
        backgroundColor: "transparent",
        width: theme.spacing(10),
        height: theme.spacing(10)
    }
}))(Card);

const Section = (props) => {
    const { icon, title, paragraph1, paragraph2, image, alt, showImage } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";

    return (
        <Grid container spacing={6} direction="row-reverse" alignItems="center" style={{ minHeight: isMobile ? 0 : theme.spacing(76) }}>
            <Grid item xs={12} md={6}>
                {showImage && (
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image={image} alt={alt} />
                    </StyledCard>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <StyledIconCard elevation={0}>
                        <CardMedia component="img" image={icon} alt={`${alt} icon`} />
                    </StyledIconCard>
                    <br />
                    <Typography variant="h3" component="div" align={align}>
                        {title}
                    </Typography>
                    <br />
                    <Typography variant="h4" component="div" align={align}>
                        {paragraph1}
                    </Typography>
                    <br />
                    <Typography variant="h4" component="div" align={align}>
                        {paragraph2}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

Section.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    showImage: PropTypes.bool.isRequired
};

export default Section;
