import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

interface InformationVisibility {
    isVisible: boolean
}

interface InformationState {
    informationVisibility: InformationVisibility
}

const initialState: InformationState = {
    informationVisibility: {
        isVisible: false
    }
};

const informationSlice = createSlice({
    name: "customer/tools/location/information",
    initialState,
    reducers: {
        showInformation: (state) => {
            state.informationVisibility.isVisible = true;
        },
        hideInformation: (state) => {
            state.informationVisibility.isVisible = false;
        }
    }
});

export const { showInformation, hideInformation } = informationSlice.actions;

export const selectInformationVisibility = (state: RootState) => {
    return state.customer.tools.location.information.informationVisibility;
};

export default informationSlice;
