import React from "react";

import { Button, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import {
    addAdmin,
    clearAdmin,
    hideAddAdmin,
    selectAddAdminVisibility,
    selectAdmin,
    setAdmin,
    showAddAdmin
} from "modules/admin/admins/adminsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

const AddAdmin: React.FC = () => {
    const dispatch = useAppDispatch();
    const admin = useAppSelector(selectAdmin);
    const addAdminVisibility = useAppSelector(selectAddAdminVisibility);
    const show = addAdminVisibility.isVisible;

    const handleAddAdminClick = () => {
        dispatch(showAddAdmin());
    };

    const handleSubmitClick = () => {
        dispatch(addAdmin());
    };

    const handleCancelClick = () => {
        dispatch(hideAddAdmin());
        dispatch(clearAdmin());
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = event.target.value;
        const newAdmin = { ...admin, firstName, errors: { ...admin.errors, firstName: "" } };
        dispatch(setAdmin(newAdmin));
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = event.target.value;
        const newAdmin = { ...admin, lastName, errors: { ...admin.errors, lastName: "" } };
        dispatch(setAdmin(newAdmin));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const newAdmin = { ...admin, email, errors: { ...admin.errors, email: "" } };
        dispatch(setAdmin(newAdmin));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddIcon />}
                disableElevation
                fullWidth
                onClick={handleAddAdminClick}
            >
                Add admin
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Add admin</Typography>
                }
                content={
                    <>
                        <StyledTextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={admin.firstName}
                            onChange={handleFirstNameChange}
                            error={!!admin.errors.firstName}
                            helperText={admin.errors.firstName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={admin.lastName}
                            onChange={handleLastNameChange}
                            error={!!admin.errors.lastName}
                            helperText={admin.errors.lastName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={admin.email}
                            onChange={handleEmailChange}
                            error={!!admin.errors.email}
                            helperText={admin.errors.email}
                            required
                            fullWidth
                        />
                    </>
                }
                actions={
                    <>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                            Add admin
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default AddAdmin;
