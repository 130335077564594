const GET_SALES_PERFORMANCE_VS_BUDGET_REQUEST = "spark/customer/insights/performance/budget/revenueVsBudget/GET_SALES_PERFORMANCE_VS_BUDGET_REQUEST";
const GET_SALES_PERFORMANCE_VS_BUDGET_SUCCESS = "spark/customer/insights/performance/budget/revenueVsBudget/GET_SALES_PERFORMANCE_VS_BUDGET_SUCCESS";
const GET_SALES_PERFORMANCE_VS_BUDGET_FAILURE = "spark/customer/insights/performance/budget/revenueVsBudget/GET_SALES_PERFORMANCE_VS_BUDGET_FAILURE";

const types = {
    GET_SALES_PERFORMANCE_VS_BUDGET_REQUEST,
    GET_SALES_PERFORMANCE_VS_BUDGET_SUCCESS,
    GET_SALES_PERFORMANCE_VS_BUDGET_FAILURE
};

export default types;
