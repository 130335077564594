import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { getAppDetails, selectIsAppDetailsLoaded } from "modules/appDetails/appDetailsSlice";
import { getUserInfo, selectIsAuthenticationChecked, selectIsAuthenticated } from "modules/auth/authSlice";
import { getFeatureFlags, selectIsFeatureFlagsLoaded } from "modules/featureFlags/featureFlagsSlice";
import { setupLogger } from "modules/helpers/logger/loggerSlice";
import { setupWebAnalytics } from "modules/helpers/webAnalytics/webAnalyticsSlice";
import {
    getSiteConfiguration,
    selectIsSiteConfigurationLoaded
} from "modules/siteConfiguration/siteConfigurationSlice";

const Setup: React.FC = (props) => {
    const dispatch = useAppDispatch();
    const isSiteConfigurationLoaded = useAppSelector(selectIsSiteConfigurationLoaded);
    const isFeatureFlagsLoaded = useAppSelector(selectIsFeatureFlagsLoaded);
    const isAuthenticationChecked = useAppSelector(selectIsAuthenticationChecked);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isAppDetailsLoaded = useAppSelector(selectIsAppDetailsLoaded);

    React.useEffect(() => {
        dispatch(getSiteConfiguration());
        dispatch(getUserInfo());
        dispatch(getFeatureFlags());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(setupLogger());
        dispatch(setupWebAnalytics());
    }, [dispatch, isSiteConfigurationLoaded]);

    React.useEffect(() => {
        if (isAuthenticated) {
            dispatch(getAppDetails());
        }
    }, [dispatch, isAuthenticationChecked, isAuthenticated]);

    if (!isSiteConfigurationLoaded || !isAuthenticationChecked || !isFeatureFlagsLoaded || (isAuthenticated && !isAppDetailsLoaded)) {
        return null;
    }

    return (
        <>{props.children}</>
    );
};

export default Setup;
