import React from "react";

import Chapter from "components/customer/insights/Chapter";
import Page from "components/customer/insights/Page";
import Subchapter from "components/customer/insights/Subchapter";

import Filters from "./filters/Filters";

import OverviewSubtitle from "./overview/OverviewSubtitle";
import RelativePerformanceSubchapter from "./overview/relativePerformance/RelativePerformanceSubchapter";
import RelativePerformanceSubtitle from "./overview/relativePerformance/RelativePerformanceSubtitle";
import Recommendations from "./overview/recommendations/Recommendations";

import SalesSubtitle from "./sales/SalesSubtitle";
import Revenue from "./sales/revenue/Revenue";
import RevenueSubtitle from "./sales/revenue/RevenueSubtitle";
import Growth from "./sales/growth/Growth";
import GrowthSubtitle from "./sales/growth/GrowthSubtitle";
import RankedGrowth from "./sales/rankedGrowth/RankedGrowth";
import RankedGrowthSubtitle from "./sales/rankedGrowth/RankedGrowthSubtitle";
import ProductCategoryGrowth from "./sales/productCategoryGrowth/ProductCategoryGrowth";
import ProductCategoryGrowthSubtitle from "./sales/productCategoryGrowth/ProductCategoryGrowthSubtitle";
import ProductCategoryMix from "./sales/productCategoryMix/ProductCategoryMix";
import ProductCategoryMixSubtitle from "./sales/productCategoryMix/ProductCategoryMixSubtitle";

import PerformanceDriversSubtitle from "./performanceDrivers/PerformanceDriversSubtitle";
import StoreSize from "./performanceDrivers/storeSize/StoreSize";
import StoreSizeSubtitle from "./performanceDrivers/storeSize/StoreSizeSubtitle";
import Staffing from "./performanceDrivers/staffing/Staffing";
import StaffingSubtitle from "./performanceDrivers/staffing/StaffingSubtitle";

import ProfitSubtitle from "./profit/ProfitSubtitle";
import GrossProfitMargin from "./profit/grossProfitMargin/GrossProfitMargin";
import GrossProfitMarginSubtitle from "./profit/grossProfitMargin/GrossProfitMarginSubtitle";
import RankedGrossProfitMargin from "./profit/rankedGrossProfitMargin/RankedGrossProfitMargin";
import RankedGrossProfitMarginSubtitle from "./profit/rankedGrossProfitMargin/RankedGrossProfitMarginSubtitle";
import NetProfit from "./profit/netProfit/NetProfit";
import NetProfitSubtitle from "./profit/netProfit/NetProfitSubtitle";

import AreaHealthSubtitle from "./areaHealth/AreaHealthSubtitle";
import OpeningsAndClosures from "./areaHealth/openingsAndClosures/OpeningsAndClosures";
import OpeningsAndClosuresSubtitle from "./areaHealth/openingsAndClosures/OpeningsAndClosuresSubtitle";
import MarketCategoryBreakdown from "./areaHealth/marketCategoryBreakdown/MarketCategoryBreakdown";
import MarketCategoryBreakdownSubtitle from "./areaHealth/marketCategoryBreakdown/MarketCategoryBreakdownSubtitle";
import SupplyAndDemand from "./areaHealth/supplyAndDemand/SupplyAndDemand";
import SupplyAndDemandSubtitle from "./areaHealth/supplyAndDemand/SupplyAndDemandSubtitle";
import RetailDensity from "./areaHealth/retailDensity/RetailDensity";
import RetailDensitySubtitle from "./areaHealth/retailDensity/RetailDensitySubtitle";
import MarketCategorySpend from "./areaHealth/marketCategorySpend/MarketCategorySpend";
import MarketCategorySpendSubtitle from "./areaHealth/marketCategorySpend/MarketCategorySpendSubtitle";

import CompetitionSubtitle from "./competition/CompetitionSubtitle";
import CompetitionScore from "./competition/competitionScore/CompetitionScore";
import CompetitionScoreSubtitle from "./competition/competitionScore/CompetitionScoreSubtitle";
import MarketShare from "./competition/marketShare/MarketShare";
import MarketShareSubtitle from "./competition/marketShare/MarketShareSubtitle";
import LocalAreaCompetition from "./competition/localAreaCompetition/LocalAreaCompetition";
import LocalAreaCompetitionSubtitle from "./competition/localAreaCompetition/LocalAreaCompetitionSubtitle";
import RevenueVsCompetitionScore from "./competition/revenueVsCompetitionScore/RevenueVsCompetitionScore";
import RevenueVsCompetitionScoreSubtitle
    from "./competition/revenueVsCompetitionScore/RevenueVsCompetitionScoreSubtitle";

import CatchmentSubtitle from "./catchment/CatchmentSubtitle";
import Footfall from "./catchment/footfall/Footfall";
import FootfallSubtitle from "./catchment/footfall/FootfallSubtitle";
import DemographicBreakdown from "./catchment/demographicBreakdown/DemographicBreakdown";
import DemographicBreakdownSubtitle from "./catchment/demographicBreakdown/DemographicBreakdownSubtitle";
import CustomerProfiles from "./catchment/customerProfiles/CustomerProfiles";
import CustomerProfilesSubtitle from "./catchment/customerProfiles/CustomerProfilesSubtitle";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import {
    getPortfolioData,
    clearPortfolioData,
    getInsightData,
    setSubchaptersIds,
    selectIsSetupComplete,
    selectStore,
    selectComparator,
    selectNumberOfProductCategories
} from "modules/customer/insights/portfolio/portfolioSlice";
import { showFilters, clearFilters } from "modules/customer/insights/portfolio/filters/filtersSlice";

const OVERVIEW_CHAPTER_ID = "overview";
const RELATIVE_PERFORMANCE_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_relative-performance`;
const RECOMMENDATIONS_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_recommendations`;

const SALES_CHAPTER_ID = "sales";
const REVENUE_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_revenue`;
const GROWTH_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_growth`;
const RANKED_GROWTH_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_ranked-growth`;
const PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_product-category-mix`;
const PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_product-category-growth`;

const PERFORMANCE_DRIVERS_CHAPTER_ID = "performance-drivers";
const STORE_SIZE_SUBCHAPTER_ID = `${PERFORMANCE_DRIVERS_CHAPTER_ID}_store-size`;
const STAFFING_SUBCHAPTER_ID = `${PERFORMANCE_DRIVERS_CHAPTER_ID}_staffing`;

const PROFIT_CHAPTER_ID = "profit";
const GROSS_PROFIT_MARGIN_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_gross-profit-margin`;
const RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_ranked-gross-profit-margin`;
const NET_PROFIT_SUBCHAPTER_ID = `${PROFIT_CHAPTER_ID}_net-profit`;

const AREA_HEALTH_CHAPTER_ID = "area-health";
const OPENINGS_AND_CLOSURES_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_openings-and-closures`;
const MARKET_CATEGORY_BREAKDOWN_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_market-category-breakdown`;
const SUPPLY_AND_DEMAND_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_supply-and-demand`;
const RETAIL_DENSITY_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_retail-density`;
const MARKET_CATEGORY_SPEND_SUBCHAPTER_ID = `${AREA_HEALTH_CHAPTER_ID}_market-category-spend`;

const COMPETITION_CHAPTER_ID = "competition";
const COMPETITION_SCORE_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_competition-score`;
const REVENUE_VS_COMPETITION_SCORE_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_revenue-vs-competition-score`;
const LOCAL_AREA_COMPETITION_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_local-area-competition`;
const MARKET_SHARE_SUBCHAPTER_ID = `${COMPETITION_CHAPTER_ID}_market-share`;

const CATCHMENT_CHAPTER_ID = "catchment";
const CUSTOMER_PROFILES_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_customer-profiles`;
const FOOTFALL_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_footfall`;
const DEMOGRAPHIC_BREAKDOWN_SUBCHAPTER_ID = `${CATCHMENT_CHAPTER_ID}_demographic-breakdown`;

const Portfolio: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const isSetupComplete = useAppSelector(selectIsSetupComplete);
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const numberOfProductCategories = useAppSelector(selectNumberOfProductCategories);

    const overviewChapterRef = React.useRef<HTMLDivElement>(null);
    const relativePerformanceSubchapterRef = React.useRef<HTMLDivElement>(null);
    const recommendationsSubchapterRef = React.useRef<HTMLDivElement>(null);

    const salesChapterRef = React.useRef<HTMLDivElement>(null);
    const revenueSubchapterRef = React.useRef<HTMLDivElement>(null);
    const growthSubchapterRef = React.useRef<HTMLDivElement>(null);
    const rankedGrowthSubchapterRef = React.useRef<HTMLDivElement>(null);
    const productCategoryMixSubchapterRef = React.useRef<HTMLDivElement>(null);
    const productCategoryGrowthSubchapterRef = React.useRef<HTMLDivElement>(null);

    const performanceDriversChapterRef = React.useRef<HTMLDivElement>(null);
    const storeSizeSubchapterRef = React.useRef<HTMLDivElement>(null);
    const staffingSubchapterRef = React.useRef<HTMLDivElement>(null);

    const profitChapterRef = React.useRef<HTMLDivElement>(null);
    const grossProfitMarginSubchapterRef = React.useRef<HTMLDivElement>(null);
    const rankedGrossProfitMarginSubchapterRef = React.useRef<HTMLDivElement>(null);
    const netProfitSubchapterRef = React.useRef<HTMLDivElement>(null);

    const areaHealthChapterRef = React.useRef<HTMLDivElement>(null);
    const openingsAndClosuresSubchapterRef = React.useRef<HTMLDivElement>(null);
    const marketCategoryBreakdownSubchapterRef = React.useRef<HTMLDivElement>(null);
    const supplyAndDemandSubchapterRef = React.useRef<HTMLDivElement>(null);
    const retailDensitySubchapterRef = React.useRef<HTMLDivElement>(null);
    const marketCategorySpendSubchapterRef = React.useRef<HTMLDivElement>(null);

    const competitionChapterRef = React.useRef<HTMLDivElement>(null);
    const competitionScoreSubchapterRef = React.useRef<HTMLDivElement>(null);
    const revenueVsCompetitionScoreSubchapterRef = React.useRef<HTMLDivElement>(null);
    const localAreaCompetitionSubchapterRef = React.useRef<HTMLDivElement>(null);
    const marketShareSubchapterRef = React.useRef<HTMLDivElement>(null);

    const catchmentChapterRef = React.useRef<HTMLDivElement>(null);
    const customerProfilesSubchapterRef = React.useRef<HTMLDivElement>(null);
    const footfallSubchapterRef = React.useRef<HTMLDivElement>(null);
    const demographicBreakdownSubchapterRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        dispatch(getPortfolioData());
        dispatch(showFilters());
        dispatch(setSubchaptersIds({
            overview: {
                relativePerformance: RELATIVE_PERFORMANCE_SUBCHAPTER_ID,
                recommendations: RECOMMENDATIONS_SUBCHAPTER_ID
            },
            sales: {
                revenue: REVENUE_SUBCHAPTER_ID,
                growth: GROWTH_SUBCHAPTER_ID,
                rankedGrowth: RANKED_GROWTH_SUBCHAPTER_ID,
                productCategoryMix: PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID,
                productCategoryGrowth: PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID
            },
            performanceDrivers: {
                storeSize: STORE_SIZE_SUBCHAPTER_ID,
                staffing: STAFFING_SUBCHAPTER_ID
            },
            profit: {
                grossProfitMargin: GROSS_PROFIT_MARGIN_SUBCHAPTER_ID,
                rankedGrossProfitMargin: RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID,
                netProfit: NET_PROFIT_SUBCHAPTER_ID
            },
            areaHealth: {
                openingsAndClosures: OPENINGS_AND_CLOSURES_SUBCHAPTER_ID,
                marketCategoryBreakdown: MARKET_CATEGORY_BREAKDOWN_SUBCHAPTER_ID,
                supplyAndDemand: SUPPLY_AND_DEMAND_SUBCHAPTER_ID,
                retailDensity: RETAIL_DENSITY_SUBCHAPTER_ID,
                marketCategorySpend: MARKET_CATEGORY_SPEND_SUBCHAPTER_ID
            },
            competition: {
                competitionScore: COMPETITION_SCORE_SUBCHAPTER_ID,
                revenueVsCompetitionScore: REVENUE_VS_COMPETITION_SCORE_SUBCHAPTER_ID,
                localAreaCompetition: LOCAL_AREA_COMPETITION_SUBCHAPTER_ID,
                marketShare: MARKET_SHARE_SUBCHAPTER_ID
            },
            catchment: {
                customerProfiles: CUSTOMER_PROFILES_SUBCHAPTER_ID,
                footfall: FOOTFALL_SUBCHAPTER_ID,
                demographicBreakdown: DEMOGRAPHIC_BREAKDOWN_SUBCHAPTER_ID
            }
        }));
        return () => {
            dispatch(clearPortfolioData());
            dispatch(clearFilters());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (store && comparator) {
            dispatch(getInsightData());
        }
    }, [dispatch, store, comparator]);

    return (
        <Page
            companyName={userInfo.companyDisplayName}
            title="Portfolio"
            filters={<Filters />}
            isSetupComplete={isSetupComplete}
            dataCy="pages-customer-insights-portfolio"
        >
            <Chapter
                id={OVERVIEW_CHAPTER_ID}
                number={1}
                title="Overview"
                subtitle={<OverviewSubtitle />}
                chapterRef={overviewChapterRef}
                dataCy="overview"
            >
                <RelativePerformanceSubchapter
                    id={RELATIVE_PERFORMANCE_SUBCHAPTER_ID}
                    number={1.1}
                    title="Relative performance"
                    subtitle={<RelativePerformanceSubtitle />}
                    subchapterRef={relativePerformanceSubchapterRef}
                    dataCy="relative-performance"
                />
                <Subchapter
                    id={RECOMMENDATIONS_SUBCHAPTER_ID}
                    number={1.2}
                    title="Recommendations"
                    subchapterRef={recommendationsSubchapterRef}
                    dataCy="recommendations"
                >
                    <Recommendations />
                </Subchapter>
            </Chapter>
            <Chapter
                id={SALES_CHAPTER_ID}
                number={2}
                title="Sales"
                subtitle={<SalesSubtitle />}
                chapterRef={salesChapterRef}
                dataCy="sales"
            >
                <Subchapter
                    id={REVENUE_SUBCHAPTER_ID}
                    number={2.1}
                    title="Revenue"
                    subtitle={<RevenueSubtitle />}
                    subchapterRef={revenueSubchapterRef}
                    dataCy="revenue"
                >
                    <Revenue />
                </Subchapter>
                <Subchapter
                    id={GROWTH_SUBCHAPTER_ID}
                    number={2.2}
                    title="Growth"
                    subtitle={<GrowthSubtitle />}
                    subchapterRef={growthSubchapterRef}
                    dataCy="growth"
                >
                    <Growth />
                </Subchapter>
                <Subchapter
                    id={RANKED_GROWTH_SUBCHAPTER_ID}
                    number={2.3}
                    title="Ranked growth"
                    subtitle={<RankedGrowthSubtitle />}
                    subchapterRef={rankedGrowthSubchapterRef}
                    dataCy="ranked-growth"
                >
                    <RankedGrowth />
                </Subchapter>
                <Subchapter
                    id={PRODUCT_CATEGORY_MIX_SUBCHAPTER_ID}
                    number={2.4}
                    title={numberOfProductCategories > 1 ? "Product category mix" : "Product mix"}
                    subtitle={<ProductCategoryMixSubtitle />}
                    subchapterRef={productCategoryMixSubchapterRef}
                    dataCy="product-category-mix"
                >
                    <ProductCategoryMix />
                </Subchapter>
                <Subchapter
                    id={PRODUCT_CATEGORY_GROWTH_SUBCHAPTER_ID}
                    number={2.5}
                    title={numberOfProductCategories > 1 ? "Product category growth" : "Product growth"}
                    subtitle={<ProductCategoryGrowthSubtitle />}
                    subchapterRef={productCategoryGrowthSubchapterRef}
                    dataCy="product-category-growth"
                >
                    <ProductCategoryGrowth />
                </Subchapter>
            </Chapter>
            <Chapter
                id={PERFORMANCE_DRIVERS_CHAPTER_ID}
                number={3}
                title="Performance drivers"
                subtitle={<PerformanceDriversSubtitle />}
                chapterRef={performanceDriversChapterRef}
                dataCy="performance-drivers"
            >
                <Subchapter
                    id={STORE_SIZE_SUBCHAPTER_ID}
                    number={3.1}
                    title="Store size"
                    subtitle={<StoreSizeSubtitle />}
                    subchapterRef={storeSizeSubchapterRef}
                    dataCy="store-size"
                >
                    <StoreSize />
                </Subchapter>
                <Subchapter
                    id={STAFFING_SUBCHAPTER_ID}
                    number={3.2}
                    title="Staffing"
                    subtitle={<StaffingSubtitle />}
                    subchapterRef={staffingSubchapterRef}
                    dataCy="staffing"
                >
                    <Staffing />
                </Subchapter>
            </Chapter>
            <Chapter
                id={PROFIT_CHAPTER_ID}
                number={4}
                title="Profit"
                subtitle={<ProfitSubtitle />}
                chapterRef={profitChapterRef}
                dataCy="profit"
            >
                <Subchapter
                    id={GROSS_PROFIT_MARGIN_SUBCHAPTER_ID}
                    number={4.1}
                    title="Gross profit margin"
                    subtitle={<GrossProfitMarginSubtitle />}
                    subchapterRef={grossProfitMarginSubchapterRef}
                    dataCy="gross-profit-margin"
                >
                    <GrossProfitMargin />
                </Subchapter>
                <Subchapter
                    id={RANKED_GROSS_PROFIT_MARGIN_SUBCHAPTER_ID}
                    number={4.2}
                    title="Ranked gross profit margin"
                    subtitle={<RankedGrossProfitMarginSubtitle />}
                    subchapterRef={rankedGrossProfitMarginSubchapterRef}
                    dataCy="ranked-gross-profit-margin"
                >
                    <RankedGrossProfitMargin />
                </Subchapter>
                <Subchapter
                    id={NET_PROFIT_SUBCHAPTER_ID}
                    number={4.3}
                    title="Net profit"
                    subtitle={<NetProfitSubtitle />}
                    subchapterRef={netProfitSubchapterRef}
                    dataCy="net-profit"
                >
                    <NetProfit />
                </Subchapter>
            </Chapter>
            <Chapter
                id={AREA_HEALTH_CHAPTER_ID}
                number={5}
                title="Area health"
                subtitle={<AreaHealthSubtitle />}
                chapterRef={areaHealthChapterRef}
                dataCy="area-health"
            >
                <Subchapter
                    id={OPENINGS_AND_CLOSURES_SUBCHAPTER_ID}
                    number={5.1}
                    title="Openings and closures"
                    subtitle={<OpeningsAndClosuresSubtitle />}
                    subchapterRef={openingsAndClosuresSubchapterRef}
                    dataCy="openings-and-closures"
                >
                    <OpeningsAndClosures />
                </Subchapter>
                <Subchapter
                    id={MARKET_CATEGORY_BREAKDOWN_SUBCHAPTER_ID}
                    number={5.2}
                    title="Market category breakdown"
                    subtitle={<MarketCategoryBreakdownSubtitle />}
                    subchapterRef={marketCategoryBreakdownSubchapterRef}
                    dataCy="market-category-breakdown"
                >
                    <MarketCategoryBreakdown />
                </Subchapter>
                <Subchapter
                    id={SUPPLY_AND_DEMAND_SUBCHAPTER_ID}
                    number={5.3}
                    title="Supply and demand"
                    subtitle={<SupplyAndDemandSubtitle />}
                    subchapterRef={supplyAndDemandSubchapterRef}
                    dataCy="supply-and-demand"
                >
                    <SupplyAndDemand />
                </Subchapter>
                <Subchapter
                    id={RETAIL_DENSITY_SUBCHAPTER_ID}
                    number={5.4}
                    title="Retail density"
                    subtitle={<RetailDensitySubtitle />}
                    subchapterRef={retailDensitySubchapterRef}
                    dataCy="retail-density"
                >
                    <RetailDensity />
                </Subchapter>
                <Subchapter
                    id={MARKET_CATEGORY_SPEND_SUBCHAPTER_ID}
                    number={5.5}
                    title="Market category spend"
                    subtitle={<MarketCategorySpendSubtitle />}
                    subchapterRef={marketCategorySpendSubchapterRef}
                    dataCy="market-category-spend"
                >
                    <MarketCategorySpend />
                </Subchapter>
            </Chapter>
            <Chapter
                id={COMPETITION_CHAPTER_ID}
                number={6}
                title="Competition"
                subtitle={<CompetitionSubtitle />}
                chapterRef={competitionChapterRef}
                dataCy="competition"
            >
                <Subchapter
                    id={COMPETITION_SCORE_SUBCHAPTER_ID}
                    number={6.1}
                    title="Competition score"
                    subtitle={<CompetitionScoreSubtitle />}
                    subchapterRef={competitionScoreSubchapterRef}
                    dataCy="competition-score"
                >
                    <CompetitionScore />
                </Subchapter>
                <Subchapter
                    id={REVENUE_VS_COMPETITION_SCORE_SUBCHAPTER_ID}
                    number={6.2}
                    title="Revenue vs competition score"
                    subtitle={<RevenueVsCompetitionScoreSubtitle />}
                    subchapterRef={revenueVsCompetitionScoreSubchapterRef}
                    dataCy="revenue-vs-competition-score"
                >
                    <RevenueVsCompetitionScore />
                </Subchapter>
                <Subchapter
                    id={LOCAL_AREA_COMPETITION_SUBCHAPTER_ID}
                    number={6.3}
                    title="Local area competition"
                    subtitle={<LocalAreaCompetitionSubtitle />}
                    subchapterRef={localAreaCompetitionSubchapterRef}
                    dataCy="local-area-competition"
                >
                    <LocalAreaCompetition />
                </Subchapter>
                <Subchapter
                    id={MARKET_SHARE_SUBCHAPTER_ID}
                    number={6.4}
                    title="Market share"
                    subtitle={<MarketShareSubtitle />}
                    subchapterRef={marketShareSubchapterRef}
                    dataCy="market-share"
                >
                    <MarketShare />
                </Subchapter>
            </Chapter>
            <Chapter
                id={CATCHMENT_CHAPTER_ID}
                number={7}
                title="Catchment"
                subtitle={<CatchmentSubtitle />}
                chapterRef={catchmentChapterRef}
                dataCy="catchment"
            >
                <Subchapter
                    id={CUSTOMER_PROFILES_SUBCHAPTER_ID}
                    number={7.1}
                    title="Customer profiles"
                    subtitle={<CustomerProfilesSubtitle />}
                    subchapterRef={customerProfilesSubchapterRef}
                    dataCy="customer-profiles"
                >
                    <CustomerProfiles />
                </Subchapter>
                <Subchapter
                    id={FOOTFALL_SUBCHAPTER_ID}
                    number={7.2}
                    title="Footfall"
                    subtitle={<FootfallSubtitle />}
                    subchapterRef={footfallSubchapterRef}
                    dataCy="footfall"
                >
                    <Footfall />
                </Subchapter>
                <Subchapter
                    id={DEMOGRAPHIC_BREAKDOWN_SUBCHAPTER_ID}
                    number={7.3}
                    title="Demographic breakdown"
                    subtitle={<DemographicBreakdownSubtitle />}
                    subchapterRef={demographicBreakdownSubchapterRef}
                    dataCy="demographic-breakdown"
                >
                    <DemographicBreakdown />
                </Subchapter>
            </Chapter>
        </Page>
    );
};

export default Portfolio;
