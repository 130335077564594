import React from "react";

const OverviewSubtitle: React.FC = () => {
    return (
        <>
            Our Performance insight gives you an in-depth understanding of your company's overall business performance.
            We have designed it to provide you with valuable business insight and show you where to focus to improve
            performance.
        </>
    );
};

export default OverviewSubtitle;
