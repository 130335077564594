import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";
import { selectStoreBestProductCategory } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const StoreBestProductCategory = () => {
    const storeBestProductCategory = useAppSelector(selectStoreBestProductCategory);
    return (
        <RAGIndicatorNew ragIndicator={storeBestProductCategory} />
    );
};

export default StoreBestProductCategory;
