import React from "react";
import _ from "lodash";

import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography
} from "@material-ui/core";
import LayersOutlinedIcon from "@material-ui/icons/LayersOutlined";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import {
    selectIstStoresVisibilityModified,
    selectStoresVisibility,
    toggleShowStoreClosures,
    toggleShowStoreOpenings
} from "modules/customer/tools/location/areaHealth/areaHealthSlice";

const StyledAvatar = withStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        // @ts-ignore
        color: (props: any) => props["data-is-modified"] ? theme.palette.tertiary.main : theme.palette.common.white,
        width: theme.spacing(3.7),
        height: theme.spacing(3.6),
        right: 0,
        bottom: theme.spacing(15.5),
        margin: theme.spacing(2),
        borderTopLeftRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        borderTopRightRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        zIndex: theme.zIndex.drawer,
        cursor: "pointer"
    }
}))(Avatar);

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        width: theme.spacing(23),
        right: 0,
        bottom: theme.spacing(19.1),
        margin: theme.spacing(2),
        borderBottomRightRadius: (props: any) => props["data-show"] ? "0px" : "4px",
        zIndex: theme.zIndex.drawer
    }
}))(Card);

const StyledCardHeader = withStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))(CardHeader);

const StyledCardContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }
}))(CardContent);

const StyledFormControlLabel = withStyles(theme => ({
    root: {
        marginRight: 0
    }
}))(FormControlLabel);

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

const StoresVisibilityFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const storesVisibility = useAppSelector(selectStoresVisibility);
    const istStoresVisibilityModified = useAppSelector(selectIstStoresVisibilityModified);
    const [show, setShow] = React.useState(false);
    const [currentShowStoreOpenings, setCurrentShowStoreOpenings] = React.useState(storesVisibility.showStoreOpenings);
    const [currentShowStoreClosures, setCurrentShowStoreClosures] = React.useState(storesVisibility.showStoreClosures);

    const handleClick = () => {
        setShow((prevShow) => !prevShow);
    };

    const toggleShowStoreOpeningsDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(toggleShowStoreOpenings()), 100);
    }, [dispatch]);

    const toggleShowStoreClosuresDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(toggleShowStoreClosures()), 100);
    }, [dispatch]);

    const handleShowStoreOpeningsChange = () => {
        setCurrentShowStoreOpenings(prev => !prev);
        toggleShowStoreOpeningsDelayed();
    };

    const handleShowStoreClosuresChange = () => {
        setCurrentShowStoreClosures(prev => !prev);
        toggleShowStoreClosuresDelayed();
    };

    return (
        <>
            <StyledAvatar
                variant="rounded"
                onClick={handleClick}
                data-show={show}
                data-is-modified={istStoresVisibilityModified}
                data-cy="btn-filter"
            >
                <LayersOutlinedIcon fontSize="small" />
            </StyledAvatar>
            {show && (
                <StyledCard elevation={0} data-show={show}>
                    <StyledCardHeader title="Pins" titleTypographyProps={{ variant: "body2" }} />
                    <StyledCardContent>
                        <FormGroup>
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={currentShowStoreOpenings}
                                        onChange={handleShowStoreOpeningsChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle2" component="div">
                                        Store openings
                                    </Typography>
                                }
                            />
                            <StyledFormControlLabel
                                control={
                                    <StyledSwitch
                                        size="small"
                                        color="default"
                                        checked={currentShowStoreClosures}
                                        onChange={handleShowStoreClosuresChange}
                                    />
                                }
                                label={
                                    <Typography variant="subtitle2" component="div">
                                        Store closures
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </StyledCardContent>
                </StyledCard>
            )}
        </>
    );
};

export default StoresVisibilityFilter;
