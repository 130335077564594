const GET_PAYROLL_COST_PER_SQFT_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_PAYROLL_COST_PER_SQFT_REQUEST";
const GET_PAYROLL_COST_PER_SQFT_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_PAYROLL_COST_PER_SQFT_SUCCESS";
const GET_PAYROLL_COST_PER_SQFT_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_PAYROLL_COST_PER_SQFT_FAILURE";
const GET_SALES_VALUE_PER_HEAD_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_VALUE_PER_HEAD_REQUEST";
const GET_SALES_VALUE_PER_HEAD_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_VALUE_PER_HEAD_SUCCESS";
const GET_SALES_VALUE_PER_HEAD_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_VALUE_PER_HEAD_FAILURE";
const GET_SALES_PER_POUND_OF_STAFF_COST_REQUEST = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_PER_POUND_OF_STAFF_COST_REQUEST";
const GET_SALES_PER_POUND_OF_STAFF_COST_SUCCESS = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_PER_POUND_OF_STAFF_COST_SUCCESS";
const GET_SALES_PER_POUND_OF_STAFF_COST_FAILURE = "spark/customer/insights/portfolio/performanceDrivers/staffing/GET_SALES_PER_POUND_OF_STAFF_COST_FAILURE";

const types = {
    GET_PAYROLL_COST_PER_SQFT_REQUEST,
    GET_PAYROLL_COST_PER_SQFT_SUCCESS,
    GET_PAYROLL_COST_PER_SQFT_FAILURE,
    GET_SALES_VALUE_PER_HEAD_REQUEST,
    GET_SALES_VALUE_PER_HEAD_SUCCESS,
    GET_SALES_VALUE_PER_HEAD_FAILURE,
    GET_SALES_PER_POUND_OF_STAFF_COST_REQUEST,
    GET_SALES_PER_POUND_OF_STAFF_COST_SUCCESS,
    GET_SALES_PER_POUND_OF_STAFF_COST_FAILURE
};

export default types;
