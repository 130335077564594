import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import RAGIndicator from "components/visuals/RAGIndicator";

import {selectors } from "modules/customer/insights/range/sales/historicSales";


const RevenueTrendRag = () => {
      const revenueTrend = useSelector((state) => selectors.revenueTrend(state));
    const { loading, error, id, label, value, status} = revenueTrend;

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default RevenueTrendRag;

