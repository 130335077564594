import React from "react";

import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearLead,
    deleteLead,
    getLead,
    hideDeleteLead,
    selectDeleteLeadVisibility,
    selectLead,
    showDeleteLead
} from "modules/admin/leads/leadsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteLeadProps {
    leadId: string;
}

const DeleteLead: React.FC<DeleteLeadProps> = (props) => {
    const { leadId } = props;
    const dispatch = useAppDispatch();
    const lead = useAppSelector(selectLead);
    const deleteLeadVisibility = useAppSelector(selectDeleteLeadVisibility);
    const show = (deleteLeadVisibility.isVisible) && (leadId === deleteLeadVisibility.leadId);

    React.useEffect(() => {
        if (show) {
            dispatch(getLead(leadId));
        }
    }, [dispatch, show, leadId]);

    const handleDeleteLeadClick = () => {
        dispatch(showDeleteLead(leadId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteLead());
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteLead());
        dispatch(clearLead());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteLeadClick}>
                    <DeleteRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <ConfirmationDialog
                open={show}
                title="Are you sure you want to delete this lead?"
                subtitle={`${lead.firstName} ${lead.lastName} (${lead.email})`}
                type={ConfirmationDialogType.Error}
                confirmLabel="Yes, delete"
                cancelLabel="No, cancel"
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
            />
        </>
    );
};

export default DeleteLead;
