import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const RelativePerformanceSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            How does your {store?.name} store's performance compare to {comparator?.name}? Our radar
            charts visualise this comparison, and show you where you might need to focus your attention.
        </>
    );
};

export default RelativePerformanceSubtitle;
