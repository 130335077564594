import React from "react";
import { Grid } from "@material-ui/core";

import GrossRevenueYTD from "./GrossRevenueYTD";
import ContributionYTD from "./ContributionYTD";
import ForecastSalesYTG from "./ForecastSalesYTG";
import YTDSalesPlusYTGSalesVsBudgetedSales from "./YTDSalesPlusYTGSalesVsBudgetedSales";

const KeyPerformanceIndicators = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <GrossRevenueYTD />
            </Grid>
            <Grid item xs={12} md={3}>
                <ContributionYTD />
            </Grid>
            <Grid item xs={12} md={3}>
                <ForecastSalesYTG />
            </Grid>
            <Grid item xs={12} md={3}>
                <YTDSalesPlusYTGSalesVsBudgetedSales />
            </Grid>
        </Grid>
    );
};

export default KeyPerformanceIndicators;
