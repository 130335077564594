import React from "react";

import { Box, Stepper as MuiStepper, Step, StepContent, StepLabel, Typography, withStyles } from "@material-ui/core";
import ChangeHistoryRoundedIcon from "@material-ui/icons/ChangeHistoryRounded";
import CropSquareRoundedIcon from "@material-ui/icons/CropSquareRounded";
import DetailsOutlinedIcon from "@material-ui/icons/DetailsOutlined";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";

import useColourPalette from "components/visuals/useColourPalette";

import StepIcon from "./StepIcon";

import {
    selectActiveStep,
    selectCandidateComparatorStores
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppSelector } from "store";

const icons = [
    <FiberManualRecordOutlinedIcon fontSize="small" />,
    <ChangeHistoryRoundedIcon fontSize="small" />,
    <CropSquareRoundedIcon fontSize="small" />,
    <CropSquareRoundedIcon style={{ transform: "rotate(45deg)" }} fontSize="small" />,
    <DetailsOutlinedIcon fontSize="small" />
];

const StyledStepper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingLeft: theme.spacing(0)
    }
}))(MuiStepper);

const Stepper: React.FC = () => {
    const colourPalette = useColourPalette();
    const activeStep = useAppSelector(selectActiveStep);
    const stores = useAppSelector(selectCandidateComparatorStores);

    return (
        <StyledStepper activeStep={activeStep} orientation="vertical">
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Select a comparator
                    </Typography>
                </StepLabel>
                <StepContent style={{ color: colourPalette.comparators[1] }}>
                    {stores.map((store, index) =>
                        <Box key={index} display="flex" alignItems="center" paddingTop={1}>
                            {icons[index]}
                            &nbsp;&nbsp;
                            <Typography variant="h6" component="div" noWrap>
                                {store.name}
                            </Typography>
                        </Box>
                    )}
                </StepContent>
            </Step>
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Set your targets
                    </Typography>
                </StepLabel>
            </Step>
        </StyledStepper>
    );
};

export default Stepper;
