import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectComplementaryMarketCategories } from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";

const ComplementaryMarketCategories: React.FC = () => {
    const complementaryMarketCategories = useAppSelector(selectComplementaryMarketCategories);
    return (
        <RAGIndicatorNew ragIndicator={complementaryMarketCategories} />
    );
};

export default ComplementaryMarketCategories;
