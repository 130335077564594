import { combineReducers } from "redux";

import types from "./types";

const growthBreakdownGridEmpty = {
    loading: false,
    error: false,
    currentMonth: "",
    marketCategories: [],
    sector: null
};

const growthBreakdownGrid = (state = growthBreakdownGridEmpty, action) => {
    switch (action.type) {
        case types.GET_GROWTH_BREAKDOWN_GRID_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                currentMonth: "",
                marketCategories: [],
                sector: null
            };
        case types.GET_GROWTH_BREAKDOWN_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                currentMonth: action.payload.currentMonth,
                marketCategories: action.payload.marketCategories,
                sector: action.payload.sector
            };
        case types.GET_GROWTH_BREAKDOWN_GRID_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                currentMonth: "",
                marketCategories: [],
                sector: null
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    growthBreakdownGrid,
});

export default reducer;
