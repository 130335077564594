import types from "./types";

const getStoreVsComparatorRankedLastTwelveMonthsGrowthRequest = () => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_REQUEST
});

const getStoreVsComparatorRankedLastTwelveMonthsGrowthSuccess = (store, comparator, rank, denominator) => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_SUCCESS,
    payload: {
        store,
        comparator,
        rank,
        denominator
    }
});

const getStoreVsComparatorRankedLastTwelveMonthsGrowthFailure = () => ({
    type: types.GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_FAILURE
});

const actions = {
    getStoreVsComparatorRankedLastTwelveMonthsGrowthRequest,
    getStoreVsComparatorRankedLastTwelveMonthsGrowthSuccess,
    getStoreVsComparatorRankedLastTwelveMonthsGrowthFailure
};

export default actions;
