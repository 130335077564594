import React from "react";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    FilterStep,
    hideFilters,
    selectActiveStep,
    selectCandidateComparatorsByChapter,
    selectCandidateComparatorStores,
    selectCandidateTarget,
    selectCandidateTargetSpendCategories,
    selectCandidateTargetStoreCategory,
    setActiveStep
} from "modules/customer/tools/location/filters/filtersSlice";
import {
    chooseComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter
} from "modules/customer/tools/location/locationSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const candidateTargetStoreCategory = useAppSelector(selectCandidateTargetStoreCategory);
    const candidateTargetSpendCategories = useAppSelector(selectCandidateTargetSpendCategories);
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const candidateComparatorsByChapter = useAppSelector(selectCandidateComparatorsByChapter);
    const finishEnabled =
        (candidateTarget?.useDemographics || candidateTarget?.useSpend || candidateTarget?.useAreaHealth || candidateTarget?.useFootfall) //at least one target set
        && (!candidateTarget?.useSpend || candidateTargetSpendCategories.length > 0); //if using spend, at lest one spend category selected

    const handleBackClick = () => {
        let previousStep: FilterStep;
        switch (activeStep) {
            case FilterStep.SetTargets:
                previousStep = FilterStep.SelectComparator;
                break;
            case FilterStep.SelectComparator:
            default:
                previousStep = FilterStep.SelectComparator;
                break;
        }
        dispatch(setActiveStep(previousStep));
    };

    const handleNextClick = () => {
        let nextStep: FilterStep;
        switch (activeStep) {
            case FilterStep.SelectComparator:
                nextStep = FilterStep.SetTargets;
                break;
            case FilterStep.SetTargets:
            default:
                nextStep = FilterStep.SetTargets;
                break;
        }
        dispatch(setActiveStep(nextStep));
    };

    const handleFinishClick = () => {
        if (!candidateTargetStoreCategory || !candidateTarget || !candidateComparatorsByChapter) {
            return;
        }
        const payload = {
            comparatorStores: candidateComparatorStores,
            targetStoreCategory: candidateTargetStoreCategory,
            targetSpendCategories: candidateTargetSpendCategories,
            target: candidateTarget,
            comparatorsByChapter: candidateComparatorsByChapter
        };
        dispatch(hideFilters());
        dispatch(chooseComparatorStoresTargetStoreCategoryTargetSpendCategoriesTargetAndComparatorsByChapter(payload));
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="medium"
                color="default"
                disableElevation
                onClick={handleBackClick}
                disabled={activeStep === FilterStep.SelectComparator}
                data-cy="btn-back"
            >
                Back
            </StyledButton>
            {activeStep === FilterStep.SelectComparator && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleNextClick}
                    disabled={candidateComparatorStores.length === 0}
                    data-cy="btn-next"
                >
                    Next
                </Button>
            )}
            {activeStep === FilterStep.SetTargets && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleFinishClick}
                    disabled={!finishEnabled}
                    data-cy="btn-finish"
                >
                    Finish
                </Button>
            )}
        </>
    );
};

export default Actions;
