import React from "react";
import { Grid } from "@material-ui/core";

import RetailDensityBulletChart from "./RetailDensityBulletChart";
import RetailDensityRagIndicator from "./RetailDensityRagIndicator";

const Revenue = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RetailDensityBulletChart />
            </Grid>
            <Grid item xs={12}>
                <RetailDensityRagIndicator />
            </Grid>
        </Grid>
    );
};

export default Revenue;
