import React from "react";

const ProductCategoryBreakdownSubtitle: React.FC = () => {
    return (
        <>
            Discover your revenue and growth breakdown by product category, product sub-category and product. Which are
            your star performers, and which need attention?
        </>
    );
};

export default ProductCategoryBreakdownSubtitle;
