import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoad, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class DirectCompetitorMetrics {
    public readonly fascia: string;
    public readonly affluenceCentile: number; 
    public readonly ageCentile: number;
    public readonly childrenCentile: number;
    public readonly diversityCentile: number;
    public readonly urbanicityCentile: number;
    public readonly areaHealthCentile: number;
    public readonly footfallCentile: number;
    // public readonly areaHealthMetrics?: AreaHealthComponentMetrics;
    // public readonly footfallMetrics?: FootfallComponentMetrics;
    // public readonly affluenceMetrics?: object;
    // public readonly ageMetrics?: object;
    // public readonly childrenMetrics?: object;
    // public readonly diversityMetrics?: object;
    // public readonly urbanicityMetrics?: object;

    
    constructor(
        fascia: string,
        areaHealthCentile: number,
        footfallCentile: number,
        affluenceCentile: number,
        ageCentile: number,
        childrenCentile: number,
        diversityCentile: number,
        urbanicityCentile: number,
        //areaHealthMetrics: AreaHealthComponentMetrics,
        //footfallMetrics: FootfallComponentMetrics,
        // affluenceMetrics?: object,
        // ageMetrics?: object,
        // childrenMetrics?: object,
        // diversityMetrics?: object,
        // urbanicityMetrics?: object,
    ) {
        this.fascia = fascia;
        this.affluenceCentile = affluenceCentile;
        this.ageCentile = ageCentile;
        this.childrenCentile = childrenCentile;
        this.diversityCentile = diversityCentile;
        this.urbanicityCentile = urbanicityCentile;
        this.areaHealthCentile = areaHealthCentile;
        this.footfallCentile = footfallCentile;
        //this.areaHealthMetrics = areaHealthMetrics;
        //this.footfallMetrics = footfallMetrics;
        // this.affluenceMetrics = affluenceMetrics;
        // this.ageMetrics = ageMetrics;
        // this.childrenMetrics = childrenMetrics;
        // this.diversityMetrics = diversityMetrics;
        // this.urbanicityMetrics = urbanicityMetrics;
    }
}

export const loadDirectCompetitorComponentMetrics = (directCompetitors: string[], targetStoreCategory: string): AppThunk<Promise<DirectCompetitorMetrics[]>> => async (dispatch) => {
    if (directCompetitors.length === 0 || !targetStoreCategory) {
        return [];
    }
    try {
        // Beeswarm tooltip values

        const query = {
        dimensions: [
            "FasciaMapping.RevisedFascia",
            "StorePointShortened.StorePointsID",
            "StorePointShortened.KPMGStoreCategory",
            "LocationBenchmarkRetailCentreMetrics.RetailCentre_ID",
            "LocationBenchmarkRetailCentreMetrics.KPMGStoreCategory",
            "LocationBenchmarkRetailCentreMetrics.IsScenario",
            "LocationBenchmarkRetailCentreMetrics.AffluenceCentile",
            "LocationBenchmarkRetailCentreMetrics.MedianIncomeBracket",
            "LocationBenchmarkRetailCentreMetrics.HomeOwnership_Percent",
            "LocationBenchmarkRetailCentreMetrics.MedianOccupancyRating",
            "LocationBenchmarkRetailCentreMetrics.AgeCentile",
            "LocationBenchmarkRetailCentreMetrics.Age17to35_Percent",
            "LocationBenchmarkRetailCentreMetrics.Age36to55_Percent",
            "LocationBenchmarkRetailCentreMetrics.Age56Plus_Percent",
            "LocationBenchmarkRetailCentreMetrics.Age16to24_Percent",
            "LocationBenchmarkRetailCentreMetrics.Age25to64_Percent",
            "LocationBenchmarkRetailCentreMetrics.Age65Plus_Percent",
            "LocationBenchmarkRetailCentreMetrics.ChildrenCentile",
            "LocationBenchmarkRetailCentreMetrics.Children0to5_Percent",
            "LocationBenchmarkRetailCentreMetrics.Children6to10_Percent",
            "LocationBenchmarkRetailCentreMetrics.Children11to16_Percent",
            "LocationBenchmarkRetailCentreMetrics.Children_Percent",
            "LocationBenchmarkRetailCentreMetrics.DiversityCentile",
            "LocationBenchmarkRetailCentreMetrics.MinorityEthnic_Percent",
            "LocationBenchmarkRetailCentreMetrics.LangNotEnglishOrWelsh_Percent",
            "LocationBenchmarkRetailCentreMetrics.NotBornInUK_Percent",
            "LocationBenchmarkRetailCentreMetrics.UrbanicityCentile",
            "LocationBenchmarkRetailCentreMetrics.MedianPopulationDensityBracket",
            "LocationBenchmarkRetailCentreMetrics.LivingInFlat_Percent",
            "LocationBenchmarkRetailCentreMetrics.MedianNumberOfCars",
            "LocationBenchmarkRetailCentreMetrics.AreaHealthCentile",
            "LocationBenchmarkRetailCentreMetrics.YoYNetOpenings_Percent",
            "LocationBenchmarkRetailCentreMetrics.FootfallCentile",
            "LocationBenchmarkRetailCentreMetrics.FootfallDensity"
        ],
        filters: [{
                member: "FasciaMapping.RevisedFascia",
                operator: "equals",
                values: directCompetitors
            }, {
                member: "LocationBenchmarkRetailCentreMetrics.KPMGStoreCategory",
                operator: "equals",
                values: [targetStoreCategory]
            }]
        };
        
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length === 0) {
            return [];
        }

        const individualMetrics = rawData.map(row => new DirectCompetitorMetrics(
            row["StorePointShortened.Fascia"],
            row["LocationBenchmarkRetailCentreMetrics.AreaHealthCentile"],
            row["LocationBenchmarkRetailCentreMetrics.FootfallCentile"],
            row["LocationBenchmarkRetailCentreMetrics.AffluenceCentile"],
            row["LocationBenchmarkRetailCentreMetrics.AgeCentile"],
            row["LocationBenchmarkRetailCentreMetrics.ChildrenCentile"],
            row["LocationBenchmarkRetailCentreMetrics.DiversityCentile"],
            row["LocationBenchmarkRetailCentreMetrics.UrbanicityCentile"],
            // new AreaHealthComponentMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.YoYNetOpenings_Percent"]
            // ),
            // new FootfallComponentMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.FootfallDensity"]
            // ),
            // new AffluenceMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.MedianIncomeBracket"],
            //     row["LocationBenchmarkRetailCentreMetrics.HomeOwnership_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.MedianOccupancyRating"]
            // ),
            // new AgeMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.Age17to35_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.Age36to55_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.Age56Plus_Percent"]
            // ),
            // new ChildrenMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.Children0to5_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.Children6to10_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.Children11to16_Percent"]
            // ),
            // new DiversityMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.MedianMinorityEthnic_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.MedianLangNotEnglishOrWelsh_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.MedianNotBornInUK_Percent"]
            // ),
            // new UrbanicityMetrics(
            //     row["LocationBenchmarkRetailCentreMetrics.MedianPopulationDensityBracket"],
            //     row["LocationBenchmarkRetailCentreMetrics.MedianLivingInFlat_Percent"],
            //     row["LocationBenchmarkRetailCentreMetrics.MedianNumberOfCars"]
            // ),
        ));

        return individualMetrics;
    } catch (error) {
        dispatch(logError("Error loading DirectCompetitorComponentMetrics.", error));
        throw error;
    }
};

export const loadDirectCompeitorCentiles = (directCompetitors: string[], targetStoreCategoryId: number | undefined, accountId: string, useMLCatchments: boolean): AppThunk<Promise<DirectCompetitorMetrics[]>> => async (dispatch) => {
    if (directCompetitors.length === 0 || !targetStoreCategoryId) {
        return [];
    }
    try {
        const catchmentAccountId = useMLCatchments ? accountId : "00000000-0000-0000-0000-000000000000";
        const query = {
            dimensions: [
                "FasciaMapping.RevisedFascia",
                "StorePointShortened.StorePointsID",
                "LocationBenchmarkMetrics.StoreCategory_ID",
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.IsScenario",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.FootfallCentile",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile"
            ],
            filters: [{
                member: "FasciaMapping.RevisedFascia",
                operator: "equals",
                values: directCompetitors
            }, {
                member: "StorePointShortened.DateDeleted",
                operator: "notSet"
            }, {
                member: "LocationBenchmarkMetrics.IsScenario",
                operator: "equals",
                values: [String(Number(useMLCatchments))]
            }, {
                member: "LocationBenchmarkMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }, {
                member: "LocationBenchmarkMetrics.AffluenceCentile",
                operator: "set"
            }]
        };

        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
        const rawData = resultSet.loadResponses[0].data;

        return rawData.map(row =>
            new DirectCompetitorMetrics(
                row["FasciaMapping.RevisedFascia"],
                Number(row["LocationBenchmarkMetrics.AreaHealthCentile"]),
                Number(row["LocationBenchmarkMetrics.FootfallCentile"]),
                row["LocationBenchmarkMetrics.AffluenceCentile"],
                row["LocationBenchmarkMetrics.AgeCentile"],
                row["LocationBenchmarkMetrics.ChildrenCentile"],
                row["LocationBenchmarkMetrics.DiversityCentile"],
                row["LocationBenchmarkMetrics.UrbanicityCentile"]
            )
        );
    } catch (error) {
        dispatch(logError("Error loading DirectCompetitorCentiles.", error));
        throw error;
    }
};
