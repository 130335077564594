import { combineReducers } from "redux";

import types from "./types";

const salesPerformanceVsBudgetOverTimeEmpty = {
    loading: false,
    error: false,
    quarterlySales: [],
    quarterlyBudget: []
};

const salesPerformanceVsBudgetOverTime = (state = salesPerformanceVsBudgetOverTimeEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                quarterlySales: [],
                quarterlyBudget: []
            };
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                quarterlySales: action.payload.quarterlySales,
                quarterlyBudget: action.payload.quarterlyBudget
            };
        case types.GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                quarterlySales: [],
                quarterlyBudget: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    salesPerformanceVsBudgetOverTime
});

export default reducer;
