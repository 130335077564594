import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const AvgBasketSizeCMPM = () => {
    const dispatch = useDispatch();
    const avgBasketSizeCMPM = useSelector(state => selectors.avgBasketSizeCMPM(state));
    const { loading, error, latestFullMonthAvgProductsInBasket, percentageDifference, currentMonth, pastMonth } = avgBasketSizeCMPM;
    const label = `Average basket size in ${currentMonth[0]} ${currentMonth[1]} vs ${pastMonth[0]} ${pastMonth[1]}`;
    const valueFormatted = numberFormatter.toDecimalPlaces(latestFullMonthAvgProductsInBasket, 1);

    React.useEffect(() => {
        dispatch(operations.getAvgBasketSizeCMPM());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="avg-basket-size-cm-pm">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default AvgBasketSizeCMPM;
