import React from "react";
import { useSelector } from "react-redux";

import Chapter from "components/customer/insights/Chapter";
import Page from "components/customer/insights/Page";
import Subchapter from "components/customer/insights/Subchapter";

import SalesSubtitle from "./sales/SalesSubtitle";
import HistoricSales from "./sales/historicSales/HistoricSales";
import HistoricSalesSubtitle from "./sales/historicSales/HistoricSalesSubtitle";

import { selectUserInfo } from "modules/auth/authSlice";

const OVERVIEW_CHAPTER_ID = "overview";
const PERFORMANCE_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_performance`;
const RECOMMENDATIONS_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_recommendations`;

const SALES_CHAPTER_ID = "sales";
const HISTORIC_SALES_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_historics-sales`;
const DRILL_DOWN_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_drill-down`;
const FORECAST_SALES_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_forecast-sales`;
const SALES_CATEGORY_ROLES_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_sales-category-roles`;

const PROFITABILITY_CHAPTER_ID = "profitability";
const HISTORIC_PROFIT_SUBCHAPTER_ID = `${PROFITABILITY_CHAPTER_ID}_historic-profit`;
const COST_BREAKDOWN_SUBCHAPTER_ID = `${PROFITABILITY_CHAPTER_ID}_cost-breakdown`;
const PROFITABILITY_CATEGORY_ROLES_SUBCHAPTER_ID = `${PROFITABILITY_CHAPTER_ID}_profitability-category-roles`;
const PROFIT_CHAINS_SUBCHAPTER_ID = `${PROFITABILITY_CHAPTER_ID}_profit-chains`;
const REFINEMENT_SUBCHAPTER_ID = `${PROFITABILITY_CHAPTER_ID}_refinement`;

const BASKET_CHAPTER_ID = "basket";
const BASKET_ANALYSIS_SUBCHAPTER_ID = `${BASKET_CHAPTER_ID}_basket-analysis`;
const BASKET_COMPOSITION_SUBCHAPTER_ID = `${BASKET_CHAPTER_ID}_basket-composition`;
const COMPLEMENTARY_GOODS_SUBCHAPTER_ID = `${BASKET_CHAPTER_ID}_complementary-goods`;

const RANGE_RATIONALISATION_CHAPTER_ID = "range-rationalisation";
const MAXIMISATION_SUBCHAPTER_ID = `${RANGE_RATIONALISATION_CHAPTER_ID}_maximisation`;
const IDENTIFICATION_SUBCHAPTER_ID = `${RANGE_RATIONALISATION_CHAPTER_ID}_identification`;
const UPSHOT_SUBCHAPTER_ID = `${RANGE_RATIONALISATION_CHAPTER_ID}_upshot`;

const DEMOGRAPHICS_CHAPTER_ID = "demographics";
const STORE_VARIANCES_SUBCHAPTER_ID = `${DEMOGRAPHICS_CHAPTER_ID}_store-variances`;
const DEMOGRAPHIC_DRIVERS_SUBCHAPTER_ID = `${DEMOGRAPHICS_CHAPTER_ID}_demographic-drivers`;
const PRODUCT_LOYALTY_SUBCHAPTER_ID = `${DEMOGRAPHICS_CHAPTER_ID}_product-loyalty`;

const Range: React.FC = () => {
    const userInfo = useSelector(selectUserInfo);

    const overviewChapterRef = React.useRef(null);
    const performanceSubchapterRef = React.useRef(null);
    const recommendationsSubchapterRef = React.useRef(null);

    const salesChapterRef = React.useRef(null);
    const historicSalesSubchapterRef = React.useRef(null);
    const drillDownSubchapterRef = React.useRef(null);
    const forecastSalesSubchapterRef = React.useRef(null);
    const salesCategoryRolesSubchapterRef = React.useRef(null);

    const profitabilityChapterRef = React.useRef(null);
    const historicProfitSubchapterRef = React.useRef(null);
    const costBreakdownSubchapterRef = React.useRef(null);
    const profitabilityCategoryRolesSubchapterRef = React.useRef(null);
    const profitChainsSubchapterRef = React.useRef(null);
    const refinementSubchapterRef = React.useRef(null);

    const basketChapterRef = React.useRef(null);
    const basketAnalysisSubchapterRef = React.useRef(null);
    const basketCompositionSubchapterRef = React.useRef(null);
    const complementaryGoodsSubchapterRef = React.useRef(null);

    const rangeRationalisationChapterRef = React.useRef(null);
    const maximisationSubchapterRef = React.useRef(null);
    const identificationSubchapterRef = React.useRef(null);
    const upshotSubchapterRef = React.useRef(null);

    const demographicsChapterRef = React.useRef(null);
    const storeVariancesSubchapterRef = React.useRef(null);
    const demographicDriversSubchapterRef = React.useRef(null);
    const productLoyaltySubchapterRef = React.useRef(null);

    return (
        <Page
            title="Range"
            companyName={userInfo.companyDisplayName}
            isSetupComplete={true}
            dataCy="pages-customer-insights-range"
        >
            <Chapter
                id={OVERVIEW_CHAPTER_ID}
                number={1}
                title="Overview"
                subtitle=""
                chapterRef={overviewChapterRef}
                dataCy="overview"
            >
                <Subchapter
                    id={PERFORMANCE_SUBCHAPTER_ID}
                    number={1.1}
                    title="Performance"
                    subtitle=""
                    subchapterRef={performanceSubchapterRef}
                    dataCy="performance"
                >
                    <>Performance</>
                </Subchapter>
                <Subchapter
                    id={RECOMMENDATIONS_SUBCHAPTER_ID}
                    number={1.2}
                    title="Recommendations"
                    subtitle=""
                    subchapterRef={recommendationsSubchapterRef}
                    dataCy="recommendations"
                >
                    <>Recommendations</>
                </Subchapter>
            </Chapter>
            <Chapter
                id={SALES_CHAPTER_ID}
                number={2}
                title="Sales"
                subtitle={<SalesSubtitle />}
                chapterRef={salesChapterRef}
                dataCy="sales"
            >
                <Subchapter
                    id={HISTORIC_SALES_SUBCHAPTER_ID}
                    number={2.1}
                    title="Historic Sales"
                    subtitle={<HistoricSalesSubtitle />}
                    subchapterRef={historicSalesSubchapterRef}
                    dataCy="historic-sales"
                >
                    <HistoricSales />
                </Subchapter>
                <Subchapter
                    id={DRILL_DOWN_SUBCHAPTER_ID}
                    number={2.2}
                    title="Drilldown"
                    subtitle=""
                    subchapterRef={drillDownSubchapterRef}
                    dataCy="drilldown"
                >
                    <>Drilldown</>
                </Subchapter>
                <Subchapter
                    id={FORECAST_SALES_SUBCHAPTER_ID}
                    number={2.3}
                    title="Forecast Sales"
                    subtitle=""
                    subchapterRef={forecastSalesSubchapterRef}
                    dataCy="forecast-sales"
                >
                    <>Forecast Sales</>
                </Subchapter>
                <Subchapter
                    id={SALES_CATEGORY_ROLES_SUBCHAPTER_ID}
                    number={2.4}
                    title="Category Roles"
                    subtitle=""
                    subchapterRef={salesCategoryRolesSubchapterRef}
                    dataCy="category-roles"
                >
                    <>Category roles</>
                </Subchapter>
            </Chapter>
            <Chapter
                id={PROFITABILITY_CHAPTER_ID}
                number={3}
                title="Profitability"
                subtitle=""
                chapterRef={profitabilityChapterRef}
                dataCy="profitability"
            >
                <Subchapter
                    id={HISTORIC_PROFIT_SUBCHAPTER_ID}
                    number={3.1}
                    title="Historic Profit"
                    subtitle=""
                    subchapterRef={historicProfitSubchapterRef}
                    dataCy="historic-profit"
                >
                    <>Historic profit</>
                </Subchapter>
                <Subchapter
                    id={COST_BREAKDOWN_SUBCHAPTER_ID}
                    number={3.2}
                    title="Cost Breakdown"
                    subtitle=""
                    subchapterRef={costBreakdownSubchapterRef}
                    dataCy="cost-breakdown"
                >
                    <>Cost breakdown</>
                </Subchapter>
                <Subchapter
                    id={PROFITABILITY_CATEGORY_ROLES_SUBCHAPTER_ID}
                    number={3.3}
                    title="Category Roles"
                    subtitle=""
                    subchapterRef={profitabilityCategoryRolesSubchapterRef}
                    dataCy="category-roles"
                >
                    <>Category roles</>
                </Subchapter>
                <Subchapter
                    id={PROFIT_CHAINS_SUBCHAPTER_ID}
                    number={3.4}
                    title="Profit Chains"
                    subtitle=""
                    subchapterRef={profitChainsSubchapterRef}
                    dataCy="profit-chains"
                >
                    <>Profit chains</>
                </Subchapter>
                <Subchapter
                    id={REFINEMENT_SUBCHAPTER_ID}
                    number={3.5}
                    title="Refinement"
                    subtitle=""
                    subchapterRef={refinementSubchapterRef}
                    dataCy="refinement"
                >
                    <>Refinement</>
                </Subchapter>
            </Chapter>
            <Chapter
                id={BASKET_CHAPTER_ID}
                number={4}
                title="Basket"
                subtitle=""
                chapterRef={basketChapterRef}
                dataCy="basket"
            >
                <Subchapter
                    id={BASKET_ANALYSIS_SUBCHAPTER_ID}
                    number={4.1}
                    title="Basket Analysis"
                    subtitle=""
                    subchapterRef={basketAnalysisSubchapterRef}
                    dataCy="basket-analysis"
                >
                    <>Basket analysis</>
                </Subchapter>
                <Subchapter
                    id={BASKET_COMPOSITION_SUBCHAPTER_ID}
                    number={4.2}
                    title="Basket Composition"
                    subtitle=""
                    subchapterRef={basketCompositionSubchapterRef}
                    dataCy="basket-composition"
                >
                    <>Basket composition</>
                </Subchapter>
                <Subchapter
                    id={COMPLEMENTARY_GOODS_SUBCHAPTER_ID}
                    number={4.3}
                    title="Complementary Goods"
                    subtitle=""
                    subchapterRef={complementaryGoodsSubchapterRef}
                    dataCy="complementary-goods"
                >
                    <>Complementary goods</>
                </Subchapter>
            </Chapter>
            <Chapter
                id={RANGE_RATIONALISATION_CHAPTER_ID}
                number={5}
                title="Range Rationalisation"
                subtitle=""
                chapterRef={rangeRationalisationChapterRef}
                dataCy="range-rationalisation"
            >
                <Subchapter
                    id={MAXIMISATION_SUBCHAPTER_ID}
                    number={5.1}
                    title="Maximisation"
                    subtitle=""
                    subchapterRef={maximisationSubchapterRef}
                    dataCy="maximisation"
                >
                    <>Maximisation</>
                </Subchapter>
                <Subchapter
                    id={IDENTIFICATION_SUBCHAPTER_ID}
                    number={5.2}
                    title="Identification"
                    subtitle=""
                    subchapterRef={identificationSubchapterRef}
                    dataCy="identification"
                >
                    <>Identification</>
                </Subchapter>
                <Subchapter
                    id={UPSHOT_SUBCHAPTER_ID}
                    number={5.3}
                    title="Upshot"
                    subtitle=""
                    subchapterRef={upshotSubchapterRef}
                    dataCy="upshot"
                >
                    <>Upshot</>
                </Subchapter>
            </Chapter>
            <Chapter
                id={DEMOGRAPHICS_CHAPTER_ID}
                number={6}
                title="Demographics"
                subtitle=""
                chapterRef={demographicsChapterRef}
                dataCy="demographics"
            >
                <Subchapter
                    id={STORE_VARIANCES_SUBCHAPTER_ID}
                    number={6.1}
                    title="Store Variances"
                    subtitle=""
                    subchapterRef={storeVariancesSubchapterRef}
                    dataCy="store-variances"
                >
                    <>Store variances</>
                </Subchapter>
                <Subchapter
                    id={DEMOGRAPHIC_DRIVERS_SUBCHAPTER_ID}
                    number={6.2}
                    title="Demographics Drivers"
                    subtitle=""
                    subchapterRef={demographicDriversSubchapterRef}
                    dataCy="demographic-drivers"
                >
                    <>Demographic drivers</>
                </Subchapter>
                <Subchapter
                    id={PRODUCT_LOYALTY_SUBCHAPTER_ID}
                    number={6.3}
                    title="Product Loyalty"
                    subtitle=""
                    subchapterRef={productLoyaltySubchapterRef}
                    dataCy="product-loyalty"
                >
                    <>Product loyalty</>
                </Subchapter>
            </Chapter>
        </Page>
    );
};

export default Range;
