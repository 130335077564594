import { combineReducers } from "redux";

import revenueOverTime from "./revenueOverTime";
import revenueGrowthBreakdown from "./revenueGrowthBreakdown";
import revenueGrowth from "./revenueGrowth";
import contributionGrowth from "./contributionGrowth";
import regionalGrowthBreakdown from "./regionalGrowthBreakdown";
import productCategoryBreakdown from "./productCategoryBreakdown";

const reducer = combineReducers({
    revenueOverTime,
    revenueGrowthBreakdown,
    revenueGrowth,
    contributionGrowth,
    regionalGrowthBreakdown,
    productCategoryBreakdown
});

export default reducer;
