import React from "react";

const AreaHealthSubtitle: React.FC = () => {
    return (
        <>
            Understand the health of the surrounding area for your selected location. Dash has tracked the openings and
            closures of all retail businesses in the local area, allowing you to determine if the local area is on the
            rise or heading for demise. Understand who's opening and closing in your selected location and evaluate what
            that means for your potential store.
        </>
    );
};

export default AreaHealthSubtitle;
