import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import AccountStatus from "./AccountStatus";
import DatasetFiles from "./datasetFiles/DatasetFiles";
import DataRefreshes from "./dataRefreshes/DataRefreshes";
import DeleteAccount from "./DeleteAccount";
import StartSupportSession from "./supportSession/StartSupportSession";
import Subscriptions from "./subscriptions/Subscriptions";
import Users from "./users/Users";

import { getAccounts, selectAccounts } from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const AccountsTable = () => {
    const dispatch = useAppDispatch();
    const accounts = useAppSelector(selectAccounts);

    React.useEffect(() => {
        dispatch(getAccounts());
    }, [dispatch]);

    if (accounts.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Name
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Display name
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Id
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Reg. No.
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Terms of use accepted at
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Initial term
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Box color="text.disabled">
                                Status
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts.map((account) => (
                        <TableRow key={account.id}>
                            <StyledTableCell>
                                {account.companyName}
                            </StyledTableCell>
                            <StyledTableCell>
                                {account.companyDisplayName}
                            </StyledTableCell>
                            <StyledTableCell>
                                {account.id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {account.companyRegistrationNumber}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {account.termsOfUseAcceptedAt?.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {account.initialTermInYears} year(s)
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <AccountStatus status={account.status} />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Subscriptions account={account} />
                                <Users account={account} />
                                <DatasetFiles account={account} />
                                <DataRefreshes account={account} />
                                <StartSupportSession account={account} />
                                <DeleteAccount accountId={account.id} companyName={account.companyName} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default AccountsTable;
