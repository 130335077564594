import React from "react";

import { Card, CardContent } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import TermsOfUseContents from "pages/TermsOfUseContents";

const TermsOfUse: React.FC = () => {
    const theme = useTheme();
    return (
        <Card>
            <CardContent>
                {/* @ts-ignore */}
                <TermsOfUseContents linkColor={theme.palette.tertiary.main}/>
            </CardContent>
        </Card>
    );
};

export default TermsOfUse;
