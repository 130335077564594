import actions from "./actions";
import { cubeLoad, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectUserInfo } from "modules/auth/authSlice";

const getLocalAreaCompetition = () => async (dispatch, getState) => {
    dispatch(actions.getLocalAreaCompetitionRequest());
    try {
        const state = getState();
        const userInfo = selectUserInfo(state);
        const companyName = userInfo.companyName;
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const selectedStoreOAID = selectedStoreSelector.outputAreaCode;

        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoreOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);

        const relevantOAIDs = comparatorStoreOAIDs.concat([selectedStoreOAID]);

        const radiansConstant = 0.01746031;
        const earthRadius = 6378.8; // (In Km)

        //Step 1: Get Selected & Comparator Stores
        const selectedAndComparatorSelectors = comparatorStoresSelector.getStores().concat([selectedStoreSelector]);

        const selectedAndComparatorStores = selectedAndComparatorSelectors.map(store => ({
            storeID: store.id,
            retailCentreID: store.retailCentreID,
            storeCategory: store.kpmgStoreCategory,
            name: store.name,
            type: (store.id === selectedStoreID) ? "Selected store" : "Comparator",
            latitude: store.latitude,
            longitude: store.longitude,
            openedAt: store.openingDate,
            sizeInSqft: store.sizeInSquareFeet,
            OAID: store.outputAreaCode,
            hotspot: {
                latitude: 0,
                longitude: 0
            },
            distanceToHotspot: null
        }));

        const queryDirectCompetitorNames = {
            dimensions: [
                "F_DirectCompetitors.CompetitorName"
            ]
        };

        const resultSetDirectCompetitorsNames = await dispatch(cubeLoad(queryDirectCompetitorNames));
        const directCompetitorNames = resultSetDirectCompetitorsNames.loadResponses[0].data.map(item => item["F_DirectCompetitors.CompetitorName"].toUpperCase());
        
        const directCompetitorsNotSet = directCompetitorNames.length === 0;

        //Step 2: Get Local Area Hotspot for Each Store
        const queryHotspotData = {
            dimensions: [
                "LocalAreaHotspot.MainOAID",
                "LocalAreaHotspot.HotSpotLat",
                "LocalAreaHotspot.HotSpotLong",
            ],
            filters: [
                {
                    member: "LocalAreaHotspot.MainOAID",
                    operator: "equals",
                    values: relevantOAIDs
                }
            ]
        };

        const resultSetHotspotData = await dispatch(cubeLoadExtended(queryHotspotData));

        for (let i in selectedAndComparatorStores) {
            const relevantHotspotData =
                resultSetHotspotData.loadResponses[0].data.find(item => item["LocalAreaHotspot.MainOAID"] === selectedAndComparatorStores[i].OAID);
            
            if (relevantHotspotData) {
                selectedAndComparatorStores[i].hotspot.latitude = relevantHotspotData["LocalAreaHotspot.HotSpotLat"];
                selectedAndComparatorStores[i].hotspot.longitude = relevantHotspotData["LocalAreaHotspot.HotSpotLong"];
            }
        }

        //Step 3: Get Other Client Stores in Selected Local Area
        const queryOtherClientStores = {
            dimensions: [
                "D_Store.StoreName",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.OutputAreaID"
            ],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "notEquals",
                values: [selectedStoreID]
            }, {
                member: "D_Store.OutputAreaID",
                operator: "notEquals",
                values: ["Unknown"]
            }, {
                member: "D_Store.ClosingDate", 
                operator: "notSet"
            }]
        };
        const resultSetOtherClientStores = await dispatch(cubeLoadExtended(queryOtherClientStores));

        const otherClientStores = resultSetOtherClientStores.loadResponses[0].data.map(item => ({
            type: "Your store",
            name: item["D_Store.StoreName"],
            latitude: item["D_Store.Lat"],
            longitude: item["D_Store.Long"],
            openedAt: item["D_Store.OpeningDate"],
            sizeInSqft: item["D_Store.Sqft"],
            OAID: item["D_Store.OutputAreaID"],
            distanceToHotspot: 0,
        }));

        let maxCompetitorDistance = "";

        for (let i in selectedAndComparatorStores) {
            const hotspot = selectedAndComparatorStores[i].hotspot;
            if (hotspot.latitude) {
                const storeLat = selectedAndComparatorStores[i].latitude * radiansConstant;
                const storeLong = selectedAndComparatorStores[i].longitude * radiansConstant;
                const diffLat = (hotspot.latitude * radiansConstant) - storeLat;
                const diffLong = (hotspot.longitude * radiansConstant) - storeLong;
                selectedAndComparatorStores[i].distanceToHotspot =
                    earthRadius * (2 * Math.asin(Math.sqrt(Math.abs(Math.pow(Math.sin(diffLat / 2), 2)
                        + Math.cos(storeLat) * Math.cos(hotspot.latitude) * Math.pow(Math.sin(diffLong / 2), 2)))));
                if (selectedStoreID === selectedAndComparatorStores[i].storeID) {
                    const selectedStoreDistanceToHotspot = selectedAndComparatorStores[i].distanceToHotspot;
                    maxCompetitorDistance = String((selectedStoreDistanceToHotspot + 5));
                }
            }
        }
        
        const retailCentreIDsByStoreCategory = new Map();
        selectedAndComparatorStores.forEach(store => {
            const retailCentreIDs = retailCentreIDsByStoreCategory.get(store.storeCategory) ?? [];
            retailCentreIDs.push(store.retailCentreID);
            retailCentreIDsByStoreCategory.set(store.storeCategory, retailCentreIDs);
        });

        const orFilterClause = { or: [] };
        retailCentreIDsByStoreCategory.forEach((retailCentreIDs, storeCategory) => {
            const andFilterClause = {
                and: [{
                    member: "LocalCompetitors.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIDs.map(String)
                },{
                    member: "LocalCompetitors.RetailCategory",
                    operator: "equals",
                    values: [String(storeCategory)]
                }]
            };
            orFilterClause.or.push(andFilterClause);
        });

        //Step 4: Calculate Distance to Hotspot for All Stores
        const queryOtherCompetitors = {
            dimensions: [
                "LocalCompetitors.RetailCentreID",
                "LocalCompetitors.RetailCategory",
                "LocalCompetitors.StorePointsID",
                "LocalCompetitors.LAT",
                "LocalCompetitors.LNG",
                "LocalCompetitors.OpeningDate",
                "LocalCompetitors.NetSalesAreaSqFt",
                "LocalCompetitors.PrimaryProductCategory",
                "LocalCompetitors.Fascia",
                "FasciaMapping.RevisedFascia"
            ],
            filters: [orFilterClause, {
                    member: "LocalCompetitors.Distance",
                    operator: "lte",
                    values: [maxCompetitorDistance]
                },{
                    member: "LocalCompetitors.DateDeleted",
                    operator: "notSet"
                }
            ]
        };

        const resultSetOtherCompetitors = await dispatch(cubeLoadExtended(queryOtherCompetitors));

        const otherCompetitors = resultSetOtherCompetitors.loadResponses[0].data.map(item => ({
            retailCentreID: item["LocalCompetitors.RetailCentreID"],
            storePointsID: item["LocalCompetitors.StorePointsID"],
            storeCategory: item["LocalCompetitors.RetailCategory"],
            type: ((directCompetitorNames.includes(item["LocalCompetitors.Fascia"]) || directCompetitorNames.includes(item["FasciaMapping.RevisedFascia"]))
                ? "Direct competitor" : "Competitor"),
            name: item["LocalCompetitors.Fascia"],
            latitude: item["LocalCompetitors.LAT"],
            longitude: item["LocalCompetitors.LNG"],
            openedAt: (item["LocalCompetitors.OpeningDate"]),
            sizeInSqft: item["LocalCompetitors.NetSalesAreaSqFt"],
            distanceToHotspot: null,
            localArea: true
        })).filter(item => item.name.toLowerCase().trim() !== companyName.toLowerCase().trim());

        for (let i in selectedAndComparatorStores) {
            const relevantCompetitors = otherCompetitors.filter(item =>
                (selectedAndComparatorStores[i].retailCentreID === item.retailCentreID)
                && (selectedAndComparatorStores[i].storeCategory === item.storeCategory || item.type === "Direct competitor"));

            selectedAndComparatorStores[i].competitorCount = relevantCompetitors.length;

            const relevantDirectCompetitors = relevantCompetitors.filter(item => item.type === "Direct competitor");
            selectedAndComparatorStores[i].directCompetitorCount = relevantDirectCompetitors.filter(item => item.localArea).length;

            if (selectedAndComparatorStores[i].storeID === selectedStoreID) {
                selectedAndComparatorStores[i].competitors = relevantCompetitors;
            }
        }

        const selectedStore = selectedAndComparatorStores.find(item => item.storeID === selectedStoreID);
        const comparatorStores = selectedAndComparatorStores.filter(item => item.storeID !== selectedStoreID); //Needs changed

        const competitors = selectedStore.competitors;
        const directCompetitorStores = competitors.filter(item => item.type === "Direct competitor");

        for (let i in selectedStore.competitors) {
            const hotspot = selectedStore.hotspot;
            const storeLat = selectedStore.competitors[i].latitude * radiansConstant;
            const storeLong = selectedStore.competitors[i].longitude * radiansConstant;
            const diffLat = (hotspot.latitude * radiansConstant) - storeLat;
            const diffLong = (hotspot.longitude * radiansConstant) - storeLong;

            selectedStore.competitors[i].distanceToHotspot =
                earthRadius * (2 * Math.asin(Math.sqrt(Math.pow(Math.sin(diffLat / 2), 2) + Math.cos(storeLat) * Math.cos(hotspot.latitude * radiansConstant) * Math.pow(Math.sin(diffLong / 2), 2))));

            //Remove competitor if it is over 5km from hotspot
            if (selectedStore.competitors[i].distanceToHotspot > 5) {
                selectedStore.competitors.splice(i, 1);
            }
        }

        if (selectedStore.hotspot.latitude) {
            for (let i in otherClientStores) {

                const hotspot = selectedStore.hotspot;
                const storeLat = otherClientStores[i].latitude * radiansConstant;
                const storeLong = otherClientStores[i].longitude * radiansConstant;
                const diffLat = (hotspot.latitude * radiansConstant) - storeLat;
                const diffLong = (hotspot.longitude * radiansConstant) - storeLong;

                otherClientStores[i].distanceToHotspot =
                    earthRadius * (2 * Math.asin(Math.sqrt(Math.pow(Math.sin(diffLat / 2), 2) + Math.cos(storeLat) * Math.cos(hotspot.latitude * radiansConstant) * Math.pow(Math.sin(diffLong / 2), 2))));
            }
        }
        const filteredOtherClientStores = otherClientStores.filter(item => (item.distanceToHotspot <= 5));

        dispatch(actions.getLocalAreaCompetitionSuccess(selectedStore, comparatorStores, directCompetitorStores, filteredOtherClientStores, directCompetitorsNotSet));
    }
    catch (error) {
        dispatch(actions.getLocalAreaCompetitionFailure());
        dispatch(logError("Error loading localAreaCompetition.", error));
    }
};

const operations = {
    getLocalAreaCompetition,
};

export default operations;
