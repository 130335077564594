import types from "./types";

const getLifetimeOfStoresRequest = () => ({
    type: types.GET_LIFETIME_OF_STORES_REQUEST
});

const getLifetimeOfStoresSuccess = (value, percentageDifference) => ({
    type: types.GET_LIFETIME_OF_STORES_SUCCESS,
    payload: {
        value,
        percentageDifference
    }
});

const getLifetimeOfStoresFailure = () => ({
    type: types.GET_LIFETIME_OF_STORES_FAILURE
});

const getNetOpenVsClosuresOverTimeRequest = () => ({
    type: types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_REQUEST
});

const getNetOpenVsClosuresOverTimeSuccess = (store, comparator) => ({
    type: types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getNetOpenVsClosuresOverTimeFailure = () => ({
    type: types.GET_NET_OPEN_VS_CLOSURES_OVER_TIME_FAILURE
});

const actions = {
    getLifetimeOfStoresRequest,
    getLifetimeOfStoresSuccess,
    getLifetimeOfStoresFailure,
    getNetOpenVsClosuresOverTimeRequest,
    getNetOpenVsClosuresOverTimeSuccess,
    getNetOpenVsClosuresOverTimeFailure,
};

export default actions;
