import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Navigate the spending terrain within your {store?.name} store's catchment area to unlock strategic retail opportunities.
            Develop a clearer view of market size by examining spending patterns segmented by your product categories.
            These insights guide you in optimally distributing store space among categories and sharpen your marketing tactics.
            Harness this knowledge to pinpoint high-spend areas, ensuring your promotional activities resonate with the local customer base and drive maximum return on investment.
        </>
    );
};

export default Subtitle;
