import React from "react";

import { Box, Button, Checkbox, TextField, Typography } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { makeStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import {
    addQuestion,
    clearQuestion,
    hideAddQuestion,
    selectAddQuestionVisibility,
    selectQuestion,
    setQuestion,
    showAddQuestion
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const useStyles = makeStyles(theme => ({
    input: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

interface AddQuestionProps {
    categoryId: string
}

const AddQuestion: React.FC<AddQuestionProps> = (props) => {
    const { categoryId } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const question = useAppSelector(selectQuestion);
    const addQuestionVisibility = useAppSelector(selectAddQuestionVisibility);
    const show = (addQuestionVisibility.isVisible);

    const handleAddQuestionClick = () => {
        dispatch(showAddQuestion());
    };

    const handleSubmitClick = () => {
        dispatch(addQuestion(categoryId));
    };

    const handleCancelClick = () => {
        dispatch(hideAddQuestion());
        dispatch(clearQuestion());
    };

    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newQuestion = { ...question, question: value, errors: { ...question.errors, question: "" } };
        dispatch(setQuestion(newQuestion));
    };

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const answer = event.target.value;
        const newQuestion = { ...question, answer, errors: { ...question.errors, answer: "" } };
        dispatch(setQuestion(newQuestion));
    };

    const handleShowOnLandingAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnLandingArea = event.target.checked;
        const newQuestion = { ...question, showOnLandingArea };
        dispatch(setQuestion(newQuestion));
    };

    const handleShowOnCustomerAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnCustomerArea = event.target.checked;
        const newQuestion = { ...question, showOnCustomerArea };
        dispatch(setQuestion(newQuestion));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddRoundedIcon />}
                disableElevation
                fullWidth
                onClick={handleAddQuestionClick}
            >
                Add question
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Add question</Typography>
                }
                content={
                    <>
                        <TextField
                            label="Question"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            multiline
                            minRows={4}
                            value={question.question}
                            onChange={handleQuestionChange}
                            error={!!question.errors.question}
                            helperText={question.errors.question}
                            required
                            fullWidth
                            InputProps={{ classes: { multiline: classes.input } }}
                        />
                        <TextField
                            label="Answer"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            multiline
                            minRows={4}
                            value={question.answer}
                            onChange={handleAnswerChange}
                            error={!!question.errors.answer}
                            helperText={question.errors.answer}
                            required
                            fullWidth
                            InputProps={{ classes: { multiline: classes.input } }}
                        />
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={question.showOnLandingArea}
                                onChange={handleShowOnLandingAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on landing area
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={question.showOnCustomerArea}
                                onChange={handleShowOnCustomerAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on customer area
                            </Typography>
                        </Box>
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Add question
                    </Button>
                }
            />
        </>
    );
};

export default AddQuestion;
