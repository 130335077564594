import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Button, Card, CardMedia, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageSection from "components/landing/PageSection";

const StyledImageCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const PageNotFound = () => {
    return (
        <Page data-cy="pages-landing-page-not-found">
            <PageSection maxWidth="sm">
                <StyledImageCard elevation={0}>
                    <CardMedia component="img" image="/content/landing/page-not-found/circles.svg" alt="page not found" />
                </StyledImageCard>
            </PageSection>
            <PageSection maxWidth="sm" disablePaddingTop>
                <Typography variant="h3" component="div" align="center" gutterBottom>
                    Page not found
                </Typography>
                <br />
                <Typography variant="h4" component="div" align="center">
                    The requested URL was not found on the server
                </Typography>
                <br />
                <br />
                <Box align="center">
                    <Button
                        component={NavLink}
                        to="/home"
                        variant="contained"
                        size="large"
                        color="primary"
                        disableElevation
                        data-cy="btn-go-to-home"
                    >
                        Go to Homepage
                    </Button>
                </Box>
            </PageSection>
        </Page>
    );
};

export default PageNotFound;
