import React from "react";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import {
    SharedCustomersByCustomerProfileSortField,
    selectSharedAndNewCustomersByCustomerProfile,
    selectSharedCustomersByCustomerProfileSort,
    setSharedCustomersByCustomerProfileSort
} from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { SortDirection } from "utils/sortUtils";

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const SharedAndNewCustomersByCustomerProfile: React.FC = () => {
    const dispatch = useAppDispatch();
    const sharedAndNewCustomersByCustomerProfile = useAppSelector(selectSharedAndNewCustomersByCustomerProfile);
    const sort = useAppSelector(selectSharedCustomersByCustomerProfileSort);

    const handleSortClick = (field: SharedCustomersByCustomerProfileSortField) => () => {
        let direction = SortDirection.ASC;
        if (sort.field === field) {
            direction = sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSort = { ...sort, field, direction };
        dispatch(setSharedCustomersByCustomerProfileSort(newSort));
    };

    return (
        <Box paddingTop={2} data-cy="shared-and-new-customers-by-customer-profile">
            <Typography variant="h6" component="div">
                Shared and new customers by customer profile
            </Typography>
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sort.field === SharedCustomersByCustomerProfileSortField.CustomerProfile}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedCustomersByCustomerProfileSortField.CustomerProfile)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Customer profile
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SharedCustomersByCustomerProfileSortField.SharedCustomers}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedCustomersByCustomerProfileSortField.SharedCustomers)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Shared population
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === SharedCustomersByCustomerProfileSortField.UniqueGainedPopulation}
                                    direction={sort.direction}
                                    onClick={handleSortClick(SharedCustomersByCustomerProfileSortField.UniqueGainedPopulation)}
                                    IconComponent={ExpandMoreIcon}
                                >
                                {/* @ts-ignore */}
                                <StyledTypography variant="h6" component="div">
                                    Unique gained population
                                </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sharedAndNewCustomersByCustomerProfile.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {item.customerProfile}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.sharedCustomers, 1)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.uniqueGainedPopulation, 1)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default SharedAndNewCustomersByCustomerProfile;
