import { createSelector } from "reselect";

const productCategoryMix = (state) => {
    return state.customer.insights.portfolio.sales.productCategoryMix.productCategoryMix;
};

const storeDependencyOnProductOrProductCategory = createSelector(
    productCategoryMix,
    (productCategoryMix) => {
        const rag = {
            loading: false,
            error: false,
            id: "store-dependency-on-product-or-product-category",
            label: "Store dependency on product or product category",
            status: "info",
            value: ""
        };
        if (productCategoryMix.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (productCategoryMix.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const store = productCategoryMix.store;
        const categories = store.map(item => item.productCategory);
        const distinctCategories = [...new Set(categories)];
        const countCategories = distinctCategories.length;
        const x = store.map(item => item.productCategoryMix);
        const salesGrowth = store.map(item => item.salesDifferencePriorTwoYears)[0];

        if (countCategories > 1) {
            rag.label = "Store dependency on product category";
            if (salesGrowth >= 0) {
                if (x.every(value => value <= 50)) {
                    rag.status = "success";
                    rag.value = "Sales are growing and there's no dependency on a singular product category for growth";
                } else if (x.some(value => value > 50 && value < 75)) {
                    rag.status = "success";
                    rag.value = "Sales are growing and there's some dependency on a singular product category for growth";
                } else {
                    rag.status = "warning";
                    rag.value = "Sales are growing and there's a severe dependency on a singular product category for sales";
                }
            } else {
                rag.status = "error";
                rag.value = "Sales are decreasing";
            }
        } else {
            rag.label = "Store dependency on product";
            if (salesGrowth >= 0) {
                if (x.every(value => value <= 50)) {
                    rag.status = "success";
                    rag.value = "Sales are growing and there's no dependency on a singular product for growth.";
                } else if (x.some(value => value > 50 && value < 75)) {
                    rag.status = "success";
                    rag.value = "Sales are growing and there's some dependency on a singular product for growth";
                } else {
                    rag.status = "warning";
                    rag.value = "Sales are growing and there's a severe dependency on a singular product for sales";
                }
            } else {
                rag.status = "error";
                rag.value = "Sales are decreasing";
            }
        }

        return rag;
    }
);

const storeBestProductsOrProductCategoriesForecast = createSelector(
    productCategoryMix,
    (productCategoryMix) => {
        const rag = {
            loading: false,
            error: false,
            id: "store-best-products-or-product-categories-forecast",
            label: "Store best products or product categories forecast",
            status: "info",
            value: ""
        };
        if (productCategoryMix.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (productCategoryMix.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const store = productCategoryMix.store;
        const categories = store.map(item => item.productCategory);
        const distinctCategories = [...new Set(categories)];
        const countCategories = distinctCategories.length;

        const topProductCategoryMix = store.filter(item => item.productCategoryMix >= 10);
        const numberOfTopProductCategoryMix = topProductCategoryMix.length;

        const topCategories = numberOfTopProductCategoryMix > 1
            ? topProductCategoryMix
            : topProductCategoryMix
                .sort((a, b) => b.productCategoryMix - a.productCategoryMix)
                .slice(0, 3);

        const positiveNoOfTopCategoryForecastGrowth = topCategories.filter(item => item.forecastProductCategoryGrowth >= 0).length;
        const negativeNoOfTopCategoryForecastGrowth = topCategories.filter(item => item.forecastProductCategoryGrowth < 0).length;
        const totalNumberOfTopCategories = topCategories.length;

        const positiveCategoryForecastGrowth = (positiveNoOfTopCategoryForecastGrowth / totalNumberOfTopCategories) * 100;
        const negativeCategoryForecastGrowth = (negativeNoOfTopCategoryForecastGrowth / totalNumberOfTopCategories) * 100;

        if (countCategories > 1) {
            rag.label = "Store best product category";
            if (positiveCategoryForecastGrowth >= 75) {
                rag.status = "success";
                rag.value = "The majority of the top product categories are forecasting growth";
            } else if (positiveCategoryForecastGrowth < 75 && negativeCategoryForecastGrowth < 75) {
                rag.status = "warning";
                rag.value = "Mixed forecast performance for the top product categories";
            } else {
                rag.status = "error";
                rag.value = "The majority of the top product categories are forecasting decline";
            }
        } else {
            rag.label = "Store best product";
            if (positiveCategoryForecastGrowth >= 75) {
                rag.status = "success";
                rag.value = "The majority of the top products are forecasting growth";
            } else if (positiveCategoryForecastGrowth < 75 && negativeCategoryForecastGrowth < 75) {
                rag.status = "warning";
                rag.value = "Mixed forecast performance for the top products";
            } else {
                rag.status = "error";
                rag.value = "The majority of the top products are forecasting decline";
            }
        }

        return rag;
    }
);

const selectors = {
    productCategoryMix,
    storeDependencyOnProductOrProductCategory,
    storeBestProductsOrProductCategoriesForecast
};

export default selectors;
