import React from "react";
import { Box } from "@material-ui/core";
import {useSelector } from "react-redux";

import {selectors } from "modules/customer/insights/range/sales/historicSales";
import StockLine from "components/visuals/StockLine";

const RevenueUnitSalesOverTime = () => {
    const revenueUnitSalesOverTime = useSelector((state) => selectors.revenueUnitSalesOverTime(state));
    const { loading, error, /*placeholder*/} = revenueUnitSalesOverTime;

    const globalOptions = {
        lang: {
            rangeSelectorZoom: 'Zoom'
        }
    };

    const options = {
        yAxis: {
            title: {
                text: 'Revenue unit sales over time stockline chart placeholder'
            }
        },
        series: [{
            name: "Actuals",
        }, {
            name: "Forecast",
            dashStyle: 'Dash',
            color: "#42E8E0",
            showInNavigator: true,
            navigatorOptions: {
                type: 'areaspline',
            },
        }],

        rangeSelector: {
            allButtonsEnabled: true,
            selected: 1,
            buttons: [{
                type: 'month',
                count: 1,
                text: 'Month',
                preserveDataGrouping: true
            }, {
                type: 'month',
                count: 3,
                text: 'Quarter',
                preserveDataGrouping: true
            }, {
                type: 'year',
                count: 1,
                text: 'Year',
                preserveDataGrouping: true
            }]
        },

        plotOptions: {
            series: {
                tooltip: {
                    valueDecimals: ",.2f",
                    valuePrefix: "£"
                }
            }
        }
    };
   
    return (
        <Box data-cy="revenue-unit-sales-over-time">
            <StockLine loading={loading} error={error} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default RevenueUnitSalesOverTime;

