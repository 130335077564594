import React from "react";

import { Box, Card, CardActions, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import DisplaySavedLocation from "./DisplaySavedLocation";
import EditSavedLocation from "./EditSavedLocation";
import GoToLocation from "./GoToLocation";

import { selectEditSavedLocationVisibility, selectSavedLocations } from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppSelector } from "store";

const StyledCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(4),
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.background.default
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        "&:last-child": {
            padding: theme.spacing(2)
        },
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        padding: theme.spacing(2)
    }
}))(CardActions);

const Content: React.FC = () => {
    const savedLocations = useAppSelector(selectSavedLocations);
    const editSavedLocationVisibility = useAppSelector(selectEditSavedLocationVisibility);

    return (
        <Box marginBottom={4}>
            {savedLocations.length === 0 ? (
                <NoData />
            ) : (
                savedLocations.map(savedLocation =>
                    <StyledCard key={savedLocation.id} elevation={0}>
                        <StyledCardContent>
                            {(editSavedLocationVisibility.isVisible) && (savedLocation.id === editSavedLocationVisibility.savedLocationId) ? (
                                <EditSavedLocation savedLocationId={savedLocation.id} />
                            ) : (
                                <DisplaySavedLocation savedLocation={savedLocation} />
                            )}
                        </StyledCardContent>
                        <StyledCardActions>
                            <GoToLocation savedLocation={savedLocation} />
                        </StyledCardActions>
                    </StyledCard>
                )
            )}
        </Box>
    );
};

export default Content;
