import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { DateTime } from "luxon";

export class MonthlyCosts {
    public readonly date: DateTime;
    public readonly costType: string;
    public readonly costValue: number;
    public readonly group: string;

    constructor(
        date: DateTime,
        costType: string,
        costValue: number,
        group: string
    ) {
        this.date = date;
        this.costType = costType;
        this.costValue = costValue;
        this.group = group;
    }
}

export const loadMonthlyCosts = (costReferenceDate: DateTime): AppThunk<Promise<MonthlyCosts[]>> => async (dispatch) => {
    try {
        if (!costReferenceDate) {
            return [];
        }

        const priorSixtyMonthsToReferenceDate = costReferenceDate.minus({ months: 60 }).plus({ days: 1 }).startOf("day");

        const costQuery = {
            measures: ["F_Cost.SumCostValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorSixtyMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            dimensions: ["D_Date.MonthStartDate", "D_Account.AccountName", "D_Store.Group"],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        const costPromise = dispatch(cubeLoad(costQuery));

        const result = await Promise.all([costPromise]) as unknown as ResultSet[];

        const costRawData = result[0].rawData();

        return costRawData.map(costRow => {
            return new MonthlyCosts(
                DateTime.fromISO(costRow["D_Date.MonthStartDate"], { zone: "utc" }),
                costRow["D_Account.AccountName"],
                Number(costRow["F_Cost.SumCostValue"]),
                costRow["D_Store.Group"]
            );
        });
    } catch (error) {
        dispatch(logError("Error loading MonthlyCosts.", error));
        throw error;
    }
};
