import React from "react";

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const StyledGridContainer = withStyles(theme => ({
    root: {
        height: "100%"
    }
}))(Grid);

const StyledGridItem = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    }
}))(Grid);

const StyledLeftGridItem = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(StyledGridItem);

const StyledRightGridItem = withStyles(theme => ({
    root: {}
}))(StyledGridItem);

const StyledLeftDialogTitle = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))(DialogTitle);

const StyledLeftDialogContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))(DialogContent);

const StyledLeftDialogActions = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        justifyContent: (props: any) => props["data-justify-content"]
    }
}))(DialogActions);

const StyledRightDialogTitle = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(7)
    }
}))(DialogTitle);

const StyledRightDialogContent = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(7),
    }
}))(DialogContent);

const StyledRightDialogActions = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(7),
        // @ts-ignore
        justifyContent: (props) => props["data-justify-content"]
    }
}))(DialogActions);

export enum JustifyContent {
    FlexStart = "flex-start",
    FlexEnd = "flex-end",
    Center = "center",
    SpaceBetween = "space-between",
    SpaceAround = "space-around",
    SpaceEvenly = "space-evenly"
}

interface TwoPanelDialogProps {
    open: boolean,
    onClose: () => void,
    leftPanelTitle: string | React.ReactElement,
    leftPanelContent?: React.ReactElement,
    leftPanelActions?: React.ReactElement,
    leftPanelActionsJustifyContent?: JustifyContent
    rightPanelTitle?: string | React.ReactElement,
    rightPanelContent: React.ReactElement,
    rightPanelActions?: React.ReactElement,
    rightPanelActionsJustifyContent?: JustifyContent
}

const TwoPanelDialog: React.FC<TwoPanelDialogProps> = (props) => {
    const {
        open, onClose,
        leftPanelTitle, leftPanelContent, leftPanelActions, leftPanelActionsJustifyContent,
        rightPanelTitle, rightPanelContent, rightPanelActions, rightPanelActionsJustifyContent
    } = props;

    const handleCloseClick = () => {
        onClose();
    };

    return (
        <Dialog open={open} maxWidth="lg" fullWidth scroll="paper" PaperProps={{ style: { height: "calc(100% - 96px)" } }}>
            <Box position="absolute" top={0} right={0}>
                <IconButton size="medium" onClick={handleCloseClick}>
                    <CloseIcon fontSize="medium" />
                </IconButton>
            </Box>
            <StyledGridContainer container>
                <StyledLeftGridItem item xs={3}>
                    <StyledLeftDialogTitle disableTypography>
                        {leftPanelTitle}
                    </StyledLeftDialogTitle>
                    <StyledLeftDialogContent>
                        {leftPanelContent}
                    </StyledLeftDialogContent>
                    <StyledLeftDialogActions data-justify-content={leftPanelActionsJustifyContent}>
                        {leftPanelActions}
                    </StyledLeftDialogActions>
                </StyledLeftGridItem>
                <StyledRightGridItem item xs={9}>
                    <StyledRightDialogTitle disableTypography>
                        {rightPanelTitle}
                    </StyledRightDialogTitle>
                    <StyledRightDialogContent>
                        {rightPanelContent}
                    </StyledRightDialogContent>
                    {rightPanelActions && (
                        <StyledRightDialogActions data-justify-content={rightPanelActionsJustifyContent}>
                            {rightPanelActions}
                        </StyledRightDialogActions>
                    )}
                </StyledRightGridItem>
            </StyledGridContainer>
        </Dialog>
    );
};

TwoPanelDialog.defaultProps = {
    leftPanelActionsJustifyContent: JustifyContent.FlexEnd,
    rightPanelActionsJustifyContent: JustifyContent.FlexEnd
};

export default TwoPanelDialog;
