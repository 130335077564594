import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const DwellingType: React.FC = () => {
    const title = "Dwelling type (Urbanicity)";
    const categories = [
        "Detached",
        "Other",
        "Semi-detached",
        "Terrace",
        "Flats"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.dwellingType;
    const selectedStoreValues = selectedStore ? [
        selectedStore.detached,
        selectedStore.otherAccommodation,
        selectedStore.semiDetached,
        selectedStore.terrace,
        selectedStore.flat
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.dwellingType;
    const comparatorValues = comparator ? [
        comparator.detached,
        comparator.otherAccommodation,
        comparator.semiDetached,
        comparator.terrace,
        comparator.flat
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default DwellingType;
