import React from "react";

import { Box, Grid, GridSize } from "@material-ui/core";

import SharedPopulation from "./SharedPopulation";
import SharedPopulationByStore from "./SharedPopulationByStore";
import UniquePopulation from "./UniquePopulation";

import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const SharedVsUniquePopulation: React.FC = () => {
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const kpiGridSize: GridSize = isInsightExpanded ? 6 : 12;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={kpiGridSize}>
                    <SharedPopulation />
                </Grid>
                <Grid item xs={kpiGridSize}>
                    <UniquePopulation />
                </Grid>
                <Grid item xs={12}>
                    <SharedPopulationByStore />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SharedVsUniquePopulation;
