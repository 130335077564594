import React from "react";
import { NavLink } from "react-router-dom";

import { Avatar, Button, Card, CardActionArea, CardContent, CardMedia, Grid, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";
import ShoppingBasketRoundedIcon from "@material-ui/icons/ShoppingBasketRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledLeftCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const StyledRightCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white
    }
}))(Card);

const StyledImageCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledImageCardMedia = withStyles(theme => ({
    root: {
        maxWidth: theme.spacing(48),
        width: "100%",
        height: "auto"
    }
}))(CardMedia);

const StyledAvatar = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main
    }
}))(Avatar);

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(3)
    }
}))(Button);

const Platform = (props) => {
    const { onLinkClick } = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const open = Boolean(menuAnchorEl);
    const insightsAndToolsSections = [{
        name: "Performance",
        url: "/insights-and-tools?section=performance",
        icon: <TrendingUpRoundedIcon />
    }, {
        name: "Portfolio",
        url: "/insights-and-tools?section=portfolio",
        icon: <StoreRoundedIcon />
    }, {
        name: "Location",
        url: "/insights-and-tools?section=location",
        icon: <PinDropRoundedIcon />
    }, {
        name: "Range",
        url: "/insights-and-tools?section=range",
        icon: <ShoppingBasketRoundedIcon />
    }];

    const handleClick = (event) => {
        onLinkClick();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="large"
                color="default"
                disableElevation
                endIcon={<ExpandMoreIcon />}
                onClick={handleClick}
            >
                Platform
            </StyledButton>
            <Popover
                anchorEl={menuAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <StyledLeftCard square elevation={0}>
                            <CardActionArea component={NavLink} to="/how-dash-works" onClick={handleClose}>
                                <StyledCardContent>
                                    <Typography variant="h6" component="div" gutterBottom>
                                        How Dash works
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        Supporting your business through better decision making
                                    </Typography>
                                    <br />
                                    <StyledImageCard elevation={0}>
                                        <StyledImageCardMedia component="img" image="/content/landing/header/platform/how-dash-works.png" alt="how dash works" />
                                    </StyledImageCard>
                                </StyledCardContent>
                            </CardActionArea>
                        </StyledLeftCard>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledRightCard square elevation={0}>
                            <StyledCardContent>
                                <Typography variant="h6" component="div" gutterBottom>
                                    Insights and tools
                                </Typography>
                                <Typography variant="body1" component="div">
                                    Powerful analytics to unlock your business potential
                                </Typography>
                                <List>
                                    {insightsAndToolsSections.map((section, index) =>
                                        <ListItem
                                            key={index}
                                            button
                                            component={NavLink}
                                            to={section.url}
                                            onClick={handleClose}
                                            disableGutters
                                        >
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    {section.icon}
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={section.name}
                                                primaryTypographyProps={{
                                                    variant: "h6",
                                                    component: "div"
                                                }}
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </StyledCardContent>
                        </StyledRightCard>
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default Platform;
