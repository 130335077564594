import React from "react";
import Highcharts from "highcharts/highcharts.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import numberFormatter from "utils/numberFormatter";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import ChartBase from "./ChartBase";
import useColourPalette from "components/visuals/useColourPalette";
import stringUtils from "utils/stringUtils";

solidGauge(Highcharts);

const Gauge = (props) => {
  const theme = useTheme();
  const colourPalette = useColourPalette();
  const { loading, error, options: customOptions, data, min, max, median, firstTertile, secondTertile, noData, lowerIsBetter, reverseYAxis } = props;
  
  let options = {
    chart: {
      height: noData ? '70%' : 270
    },
    series: {
      name: "",
      data: []
    },
    xAxis: {
      title: ""
    },
    yAxis: {
      title: ""
    }
  };

if (!noData) {

  let gaugeColour = '';
  let gaugeColours = [colourPalette.RAGIndicators[0], colourPalette.RAGIndicators[1], colourPalette.RAGIndicators[2]];

  if (data !== null && (firstTertile !== null || secondTertile !== null)) {
    if(!lowerIsBetter) {
      if(data < firstTertile) {
          gaugeColour = gaugeColours[0]; 
      } else if (data <= secondTertile) {
          gaugeColour = gaugeColours[1]; 
      } else {
          gaugeColour = gaugeColours[2]; 
      }
    } else {
      if(data > secondTertile) {
        gaugeColour = gaugeColours[0]; 
      } else if (data >= firstTertile) {
          gaugeColour = gaugeColours[1]; 
      } else {
          gaugeColour = gaugeColours[2]; 
      }
    }
  }  

  const setupGauge = (chart) => {
    if (!calledByRedraw) {
      calledByRedraw = true;

      let updateOptions = {
        chart: {
        },
        pane: {
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              style: {
              },
            }
          }
        },
        yAxis: {
          labels: {
                  style: {
                  }
              }
        },
        series: [{
          data: [{
            color: gaugeColour
          }],
          colorAxis: false
        }]
      };

      let width = 0;
      width = chart?.chartWidth;

      if (width >= 343) {
        updateOptions.pane.size = "135%";
        updateOptions.chart.height = '70%';
        updateOptions.yAxis.labels.style.fontSize = "14px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "32px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -70;
      } else if (width >= 281 && width < 343) {
        updateOptions.chart.height = '70%';
        updateOptions.pane.size = "130%";
        updateOptions.yAxis.labels.style.fontSize = "14px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "32px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -70;
      } else if (width >= 254 && width <= 290) {
        updateOptions.chart.height = '70%';
        updateOptions.pane.size = "125%";
        updateOptions.yAxis.labels.style.fontSize = "12px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "32px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -70;
      } else if (width >= 220 && width <= 253) {
        updateOptions.chart.height = '70%';
        updateOptions.pane.size = "120%";
        updateOptions.yAxis.labels.style.fontSize = "10px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "25px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -50;
      } else if (width >= 181 && width <= 220) {
        updateOptions.chart.height = '70%';
        updateOptions.pane.size = "115%";
        updateOptions.yAxis.labels.style.fontSize = "10px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "20px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -40;
      } else if (width <= 180) {
        updateOptions.chart.height = '70%';
        updateOptions.pane.size = "115%";
        updateOptions.yAxis.labels.style.fontSize = "8px";
        updateOptions.plotOptions.solidgauge.dataLabels.style.fontSize = "15px";
        updateOptions.plotOptions.solidgauge.dataLabels.y = -30;
      }

      chart.update(updateOptions);

      chart.redraw();
      calledByRedraw = false;
    }
  };

  let calledByRedraw = false;
  const staticOptions = {
      chart: {
          type: "solidgauge",
          style: {"fontFamily": "Open Sans"},
          events: {
            redraw: function() {
              setupGauge(this);
            },
            load: function() {
              setupGauge(this);
            }
          },
          marginBottom: -20,
          marginTop: 20
      },

      pane: {
        center: ['50%', '70%'],
        startAngle: -90,
        endAngle: 90,
        background: {
          borderWidth: 2,
          backgroundColor: 'transparent',
          borderColor: '#6A677A',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      yAxis: {
        
        min: min,
        max: max,
        tickPositions: [min, max],
        gridLineColor: '#6A677A',
        minorGridLineColor: '#6A677A',
        tickWidth: 0,
        plotLines: [{
            value: median,
            zIndex: 5,
            width: 2,
            color: theme.palette.common.white,
            dashStyle: 'dash',
            label: {
                text: (median === null) ? '' : numberFormatter.toGBP(median),
                style: {
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: theme.palette.common.white
                }
            }
        }],
        minorTickInterval: null,
      
        tickPositioner: function() {
            return [this.min, this.max];
        },
        lineWidth: 2,
        lineColor: "#6A677A",
        labels: {
          distance: "80%",
          y: 15,
          formatter: function() {
            return numberFormatter.toGBP(this.value);
          },      
          style:{
              color: theme.palette.common.white,
              fontSize: '14px'
          }
        },
        reversed: reverseYAxis
      },
      title: null,
      tooltip: {
        enabled: false,
        formatter: function() {
          return stringUtils.tooltipHTML(["Selected store", "Lowest in comparator", "Highest in comparator", "Median"],
          { values: [data, min, max, median], 
            categoryFormattingArr: [`color:${colourPalette.comparators[0]}`]
          });
        }

      },
      legend: {
        align: 'center',
        useHTML: true,
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        style:{
            color: theme.palette.common.white
        },
        itemHoverStyle: {
            color: theme.palette.common.white
        }
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled:true,
            pointFormatter: function() {
              return `<span style="font-family:Open Sans; color: ${this.color};">${numberFormatter.toGBP(this.y, 2)}</span>`;
            },
            y: -27,
            borderWidth: 0,
            useHTML: true,
            style: {
              fontWeight:"600",
            },
            allowOverlap: false,
            crop: false,
            overflow: 'none'
          }
        }
      },
    series: [{
      data: [{
        color: gaugeColour,
        y: data
      }],
      colorAxis: false
    }]
  };

  options = _.merge({}, staticOptions, customOptions);
  
  }

  return (
      <ChartBase loading={loading} error={error} options={options} data-cy="gauge-chart"/>
);
};

Gauge.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  median: PropTypes.number,
  firstTertile: PropTypes.number,
  secondTertile: PropTypes.number,
  data: PropTypes.number,
  noData: PropTypes.bool,
  lowerIsBetter: PropTypes.bool,
  reverseYAxis: PropTypes.bool
};

Gauge.defaultProps = {
  loading: false,
  error: false,
  min: 0,
  max: 0,
  median: null,
  firstTertile: null,
  secondTertile: null,
  data: null,
  noData: false,
  lowerIsBetter: false,
  reverseYAxis: false
};

export default Gauge;
