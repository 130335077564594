import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";
import Bar from "components/visuals/Bar";

import { selectors } from "modules/customer/insights/performance/forecast/regionalBreakdown";
import stringUtils from "utils/stringUtils";

const SalesByRegionGrowthForecastTop = () => {
    const theme = useTheme();
    let title = "Top stores for forecast year-on-year revenue growth (%)";
    const selectedRegion = useSelector(state => selectors.selectedRegion(state));
    const totalSalesGrowthByRegion = useSelector(state => selectors.totalSalesGrowthByRegionForecast(state));
    if(selectedRegion) {
        const regionName = totalSalesGrowthByRegion.regionsGrowthForecast.find(r => r.regionId === selectedRegion).regionName;
        title += ` (${regionName})`;
    }
    const salesByRegionGrowthForecastTopBottom = useSelector(state => selectors.salesByRegionGrowthForecastTopBottom(state));
    const { loading, error, top } = salesByRegionGrowthForecastTopBottom;
    top.sort((a, b) => b.growth - a.growth);
    const options = {
        chart:{
            height: "240px"
        },
        title: {
            text: title
        },
        colors: [
            theme.palette.success.main
        ],
        yAxis: {
            labels: {
                formatter: function() {return numberFormatter.toPercentage(this.value, true, 0);}
            },
            gridLineWidth: 0
        },
        xAxis: {
            categories: top.map(store => store.storeName),
            title: {
                text: null
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                        enabled: false
                }
            }
        },
        tooltip: {
            enabled: true,
            formatter: function() {
                const store = this.x;
                const growth = numberFormatter.toPercentage(this.y);
                const sales = numberFormatter.toGBP(this.point.custom.sales);
                return `<table>${stringUtils.tooltipHTML(['Forecast revenue ', 'Forecast growth (%) '], {
                    header: store,
                    headerFormatting: `color:${this.color}`,
                    values: [sales, growth]
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            name: 'High forecast growth',
            data: top.map(store => {
                return {
                    y: store.growth,
                    custom: {
                        sales: store.sales
                    }
                };
            })
        }]
    };

    return (
        <Box data-cy="sales-by-region-growth-forecast-top">
            <Bar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default SalesByRegionGrowthForecastTop;
