import React from "react";
import _ from "lodash";

import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import {
    selectCustomSelectionStoresSearch,
    setCustomSelectionStoresSearch,
    StoresSearch
} from "modules/customer/insights/portfolio/filters/filtersSlice";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(TextField);

const Search = () => {
    const dispatch = useAppDispatch();
    const customSelectionStoresSearch = useAppSelector(selectCustomSelectionStoresSearch);
    const [currentCustomSelectionStoresSearch, setCurrentCustomSelectionStoresSearch] = React.useState<StoresSearch>(customSelectionStoresSearch);

    const setCustomSelectionStoresSearchDelayed = React.useMemo(() => {
        return _.debounce((customSelectionStoresSearch: StoresSearch) => dispatch(setCustomSelectionStoresSearch(customSelectionStoresSearch)), 400);
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newCustomSelectionStoresSearch = { ...customSelectionStoresSearch, name };
        setCurrentCustomSelectionStoresSearch(newCustomSelectionStoresSearch);
        setCustomSelectionStoresSearchDelayed(newCustomSelectionStoresSearch);
    };

    React.useEffect(() => {
        setCurrentCustomSelectionStoresSearch(customSelectionStoresSearch);
    }, [customSelectionStoresSearch]);

    return (
        <StyledTextField
            placeholder="Search"
            variant="outlined"
            size="small"
            color="secondary"
            value={currentCustomSelectionStoresSearch.name}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default Search;
