import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Partner
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Evaluate headroom potential across your current and prospective retail partners.
                    Use this view to identify growth opportunities within existing accounts and explore
                    new partners where expansion could drive additional sales.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
