import React from "react";
import Bubble from 'components/visuals/Bubble';

const BubblePOC = () => {
    const options = {
        dataLabels: {
            enabled: true,
            format: '{point.name}',
            filter: {
                property: 'y',
                operator: '>',
                value: 130
            },
            style: {
                color: 'black',
                textOutline: 'none',
                fontWeight: 'bold',
                fontSize: "0px",
            },
            states: {
                hover: {
                enabled: true,
                }
            },
            x: -15,
            y: -8,
        },
        tooltip: {
            useHTML: true,
            formatter(){
                let output;
                if(this.point.name){
                    output = `<table><tr><h3>${this.point.name}</h3></tr>
                        <tr><td><p>${this.series.options.custom.subHead}</p></td></tr>
                        <tr><th>Value:</th><td>${this.point.value}</td></tr>
                        <tr><th>Revenue Uplift:</th><td>${this.point.revenueUplift} Pounds</td></tr>
                        <tr><th>% of total revenue uplift: </th><td>${this.point.percentRevenue} %</td></tr></table>`;
                }
                else {
                    output = `<table><tr><h3>${this.series.name}</h3></tr>
                        <tr><td><p>${this.series.options.custom.subHead}</p></td></tr>
                        <tr><th>Revenue Uplift:</th><td>${this.series.options.custom.revenueUplift} Pounds</td></tr>
                        <tr><th>% of total revenue uplift:</th><td>${this.series.options.custom.percentRevenue} %</td></tr></table>`;
                }
                return output;
            }
        },
        series: [{
            name: 'Europe',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: 'Germany',
                value: 767.1,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: 'Croatia',
                value: 20.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Belgium",
                value: 97.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Czech Republic",
                value: 111.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Netherlands",
                value: 158.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Spain",
                value: 241.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ukraine",
                value: 249.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Poland",
                value: 298.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "France",
                value: 323.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Romania",
                value: 78.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "UK",
                value: 415.4,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Turkey",
                value: 353.2,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Italy",
                value: 337.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Greece",
                value: 71.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Austria",
                value: 69.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Belarus",
                value: 67.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Serbia",
                value: 59.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Finland",
                value: 54.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Bulgaria",
                value: 51.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Portugal",
                value: 48.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Norway",
                value: 44.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Sweden",
                value: 44.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Hungary",
                value: 43.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Switzerland",
                value: 40.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Denmark",
                value: 40,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Slovakia",
                value: 34.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ireland",
                value: 34.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Croatia",
                value: 20.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Estonia",
                value: 19.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Slovenia",
                value: 16.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Lithuania",
                value: 12.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Luxembourg",
                value: 10.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Macedonia",
                value: 9.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Moldova",
                value: 7.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Latvia",
                value: 7.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Cyprus",
                value: 7.2,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }, {
            name: 'Africa',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: "Senegal",
                value: 8.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Cameroon",
                value: 9.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Zimbabwe",
                value: 13.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ghana",
                value: 14.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Kenya",
                value: 14.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Sudan",
                value: 17.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Tunisia",
                value: 24.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Angola",
                value: 25,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Libya",
                value: 50.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ivory Coast",
                value: 7.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Morocco",
                value: 60.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ethiopia",
                value: 8.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "United Republic of Tanzania",
                value: 9.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Nigeria",
                value: 93.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "South Africa",
                value: 392.7,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Egypt",
                value: 225.1,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Algeria",
                value: 141.5,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }, {
            name: 'Oceania',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: "Australia",
                value: 409.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "New Zealand",
                value: 34.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Papua New Guinea",
                value: 7.1,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }, {
            name: 'North America',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: "Costa Rica",
                value: 7.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Honduras",
                value: 8.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Jamaica",
                value: 8.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Panama",
                value: 10.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Guatemala",
                value: 12,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Dominican Republic",
                value: 23.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Cuba",
                value: 30.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "USA",
                value: 5334.5,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Canada",
                value: 566,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Mexico",
                value: 456.3,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }, {
            name: 'South America',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: "El Salvador",
                value: 7.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Uruguay",
                value: 8.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Bolivia",
                value: 17.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Trinidad and Tobago",
                value: 34,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Ecuador",
                value: 43,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Chile",
                value: 78.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Peru",
                value: 52,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Colombia",
                value: 74.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Brazil",
                value: 501.1,
                revenueUplift: 23,
                percentRevenue: 12
            }, {
                name: "Argentina",
                value: 199,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Venezuela",
                value: 195.2,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }, {
            name: 'Asia',
            custom:{
                subHead: 'Revenue uplift per annum vs homebrand salted butter 250g',
                revenueUplift: 26,
                percentRevenue: 22
            },
            data: [{
                name: "Nepal",
                value: 6.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Georgia",
                value: 6.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Brunei Darussalam",
                value: 7.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Kyrgyzstan",
                value: 7.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Afghanistan",
                value: 7.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Myanmar",
                value: 9.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Mongolia",
                value: 14.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Sri Lanka",
                value: 16.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Bahrain",
                value: 20.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Yemen",
                value: 22.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Jordan",
                value: 22.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Lebanon",
                value: 21.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Azerbaijan",
                value: 31.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Singapore",
                value: 47.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Hong Kong",
                value: 49.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Syria",
                value: 52.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "DPR Korea",
                value: 59.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Israel",
                value: 64.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Turkmenistan",
                value: 70.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Oman",
                value: 74.3,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Qatar",
                value: 88.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Philippines",
                value: 96.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Kuwait",
                value: 98.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Uzbekistan",
                value: 122.6,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Iraq",
                value: 139.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Pakistan",
                value: 158.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Vietnam",
                value: 190.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "UAE",
                value: 201.1,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Malaysia",
                value: 227.5,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Kazakhstan",
                value: 236.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Thailand",
                value: 272,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Taiwan",
                value: 276.7,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Indonesia",
                value: 453,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Saudi Arabia",
                value: 494.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Japan",
                value: 1278.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "China",
                value: 10540.8,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "India",
                value: 2341.9,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Russia",
                value: 1766.4,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Iran",
                value: 618.2,
                revenueUplift: 23,
                percentRevenue: 12
            },
            {
                name: "Korea",
                value: 610.1,
                revenueUplift: 23,
                percentRevenue: 12
            }]
        }],
    };
    return (
        <Bubble options = {options}/>
    );
};

export default BubblePOC;
