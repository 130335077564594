import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import EditLead from "./EditLead";
import DeleteLead from "./DeleteLead";
import SendQuote from "./SendQuote";
import LeadStatus from "./LeadStatus";

import { getLeads, selectLeads } from "modules/admin/leads/leadsSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

const LeadsTable = () => {
    const dispatch = useAppDispatch();
    const leads = useAppSelector(selectLeads);

    React.useEffect(() => {
        dispatch(getLeads());
    }, [dispatch]);

    if (leads.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell><Box color="text.disabled">Name</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Email</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Phone number</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Company name</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Company registration number</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Job role</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Lead generated</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Demo call</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Product</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Price</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Tax rate</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Initial term</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Quote expires at</Box></StyledTableCell>
                        <StyledTableCell><Box color="text.disabled">Status</Box></StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {leads.map((lead) => (
                        <TableRow key={lead.id}>
                            <StyledTableCell>{lead.firstName} {lead.lastName}</StyledTableCell>
                            <StyledTableCell>{lead.email}</StyledTableCell>
                            <StyledTableCell>{lead.phoneNumber}</StyledTableCell>
                            <StyledTableCell>{lead.companyName}</StyledTableCell>
                            <StyledTableCell>{lead.companyRegistrationNumber}</StyledTableCell>
                            <StyledTableCell>{lead.jobRole}</StyledTableCell>
                            <StyledTableCell>{new Date(lead.createdAt).toLocaleDateString()}</StyledTableCell>
                            <StyledTableCell>
                                {lead?.demoCall && new Date(lead.demoCall).toLocaleDateString()}
                            </StyledTableCell>
                            <StyledTableCell>{lead.product?.name}</StyledTableCell>
                            <StyledTableCell>
                                {lead.price && (
                                    <>
                                        {lead.price.name}
                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                        {numberFormatter.toGBP(lead.price.priceInPence / 100, 2, false)} / month
                                    </>
                                )}
                            </StyledTableCell>
                            <StyledTableCell>
                                {lead.taxRate && (
                                    `${lead.taxRate.name} (${lead.taxRate.percentage}% ${lead.taxRate.isInclusive ? "inclusive" : "exclusive"})`
                                )}
                            </StyledTableCell>
                            <StyledTableCell>{lead.initialTermInYears} year(s)</StyledTableCell>
                            <StyledTableCell>
                                {lead?.quoteExpiresAt && new Date(lead.quoteExpiresAt).toLocaleDateString()}
                            </StyledTableCell>
                            <StyledTableCell>
                                <LeadStatus status={lead.status} />
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <SendQuote leadId={lead.id} />
                                <EditLead leadId={lead.id} />
                                <DeleteLead leadId={lead.id} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default LeadsTable;
