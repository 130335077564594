import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import dateUtils from "utils/dateUtils";
import arrayUtils from "utils/arrayUtils";
import StockLine from "components/visuals/StockLine";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import useColourPalette from "components/visuals/useColourPalette";
import { dateFormat, Color } from "highcharts";
import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/revenue";
import numberFormatter from "utils/numberFormatter";

const SalesHistory = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const salesHistory = useSelector(state => selectors.salesHistory(state));
    const { loading, error, values } = salesHistory;
    const [actuals, forecast] = arrayUtils.bifurcateCollectionOnFlag(values, "sales", "forecastFlag", 0, 1, values.length, true);
    const dates = dateUtils.datesToTime(values.map(item => item.date));
    const weeks = dateUtils.datesToTime(values.map(item => item.week));
    const actualsDataValues = _.zip(dates, actuals, weeks);
    const forecastDataValues = _.zip(dates, forecast, weeks);

    console.log(actuals, actualsDataValues);
    console.log(forecast, forecastDataValues);


    const globalOptions = {
        lang: {
            rangeSelectorZoom: "Zoom"
        }
    };
    const options = {
        title: {
            text: `${selectedStore.name} historic weekly revenue & forecast weekly revenue over time`
        },
        yAxis: {
            title: {
                text: "Revenue"
            },
            labels: {
                formatter: function () {return numberFormatter.toGBP(this.value, 0);}
            }
        },
        series: [{
            name: "Historic",
            data: actualsDataValues,
            color: colourPalette.comparators[0],
            gapSize: 1
        }, {
            name: "Forecast",
            data: forecastDataValues,
            color: colourPalette.comparators[0],
            dashStyle: "Dash",
            showInNavigator: true,
            navigatorOptions: {
                type: "areaspline",
            },
            gapSize: 1
        }],

        xAxis: {
            labels: {
                useHTML: true,
                style: {
                    color: theme.palette.common.white,
                    fontSize: "12px"
                },
                units: [["month", [1]]],
                dateTimeLabelFormats: {
                    month: "%b"
                }
            }
        },

        navigator: {
            series: {
                dataLabels: {
                    color: theme.palette.common.white
                },
                color: colourPalette.comparators[0],

                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, String(new Color(colourPalette.comparators[0]).setOpacity(0.4).get("rgba"))],
                        [1, String(new Color(theme.palette.common.white).setOpacity(0).get("rgba"))]
                    ]
                },
                gapSize: 1
            },
        },

        rangeSelector: {
            allButtonsEnabled: true,
            selected: 3,
            buttons: [{
                type: "month",
                count: 1,
                text: "Month",
                dataGrouping: {
                    forced: false,
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 3,
                text: "Quarter",
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 12,
                text: "Year",
                preserveDataGrouping: true
            }, {
                type: "all",
                text: "All",
                title: "View all",
                preserveDataGrouping: true
            }]
        },

        plotOptions: {
            series: {
                tooltip: {
                    valueDecimals: ",.2f",
                    valuePrefix: "£"
                }
            }
        },

        legend: {
            enabled: true
        },

        tooltip: {
            useHTML: true,
            formatter() {
                const date = "Week from " + dateFormat("%A, %b %e, %Y", this.x);

                return `<b>${date}</b><br/>${this.points[0].series.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>${numberFormatter.toGBP(this.y)}</b>`;
            }
        },

    };

    return (
        <Box data-cy="sales-history">
            <StockLine loading={loading} error={error} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default SalesHistory;
