import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { operations, selectors } from "modules/customer/insights/performance/forecast/regionalBreakdown";

const ForecastDependencyOnSingularRegionOrStore = () => {
    const dispatch = useDispatch();
    const forecastDependencyOnSingularRegionOrStore = useSelector(state => selectors.forecastDependencyOnSingularRegionOrStore(state));
    const { loading, error, id, label, status, value } = forecastDependencyOnSingularRegionOrStore;

    React.useEffect(() => {
        dispatch(operations.getForecastDependencyOnSingularRegionOrStore());
    }, [dispatch]);

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default ForecastDependencyOnSingularRegionOrStore;
