import { combineReducers } from "redux";

import revenueVsBudget from "./revenueVsBudget";
import revenueVsBudgetOverTime from "./revenueVsBudgetOverTime";
import contributionVsBudget from "./contributionVsBudget";
import storePerformanceAgainstBudget from "./storePerformanceAgainstBudget";
import budgetVsForecastRevenue from "./budgetVsForecastRevenue";
import budgetedVsForecastContribution from "./budgetedVsForecastContribution";

const reducer = combineReducers({
    revenueVsBudget,
    revenueVsBudgetOverTime,
    contributionVsBudget,
    storePerformanceAgainstBudget,
    budgetVsForecastRevenue,
    budgetedVsForecastContribution
});

export default reducer;
