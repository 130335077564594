import React from "react";

import { Card, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageHeader from "components/landing/PageHeader";
import PageCallToAction from "components/landing/PageCallToAction";
import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const MeetTheTeam = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";
    const teamMembers = [{
        name: "Ben Williams",
        title: "Co-Founder",
        image: "/content/landing/meet-the-team/ben-williams.jpg",
        alt: "ben williams"
    }, {
        name: "Tauqeer Ahmed",
        title: "Co-Founder",
        image: "/content/landing/meet-the-team/tauqeer-ahmed.jpg",
        alt: "tauqeer ahmed"
    }, {
        name: "Hellen Mweti",
        title: "Lead Data Scientist",
        image: "/content/landing/meet-the-team/hellen-mweti.jpg",
        alt: "hellen mweti"
    }, {
        name: "Jessica Blackwell",
        title: "Lead Product Designer",
        image: "/content/landing/meet-the-team/jessica-blackwell.jpg",
        alt: "jessica blackwell"
    }, {
        name: "Dom De Vere",
        title: "Lead Data Engineer",
        image: "/content/landing/meet-the-team/dom-de-vere.jpg",
        alt: "dom de vere"
    }, {
        name: "Heitor Belloni",
        title: "Principal Software Engineer",
        image: "/content/landing/meet-the-team/heitor-belloni.jpg",
        alt: "heitor belloni"
    }, {
        name: "Adrian Ellis",
        title: "Data Product Manager",
        image: "/content/landing/meet-the-team/adrian-ellis.jpg",
        alt: "adrian ellis"
    }, {
        name: "Oliver Tuck",
        title: "Lead Experience Designer",
        image: "/content/landing/meet-the-team/oliver-tuck.jpg",
        alt: ""
    }, {
        name: "Jacob Hodgetts",
        title: "Product Specification Lead",
        image: "/content/landing/meet-the-team/jake-hodgetts.jpg",
        alt: "jacob hodgetts"
    }, {
        name: "Alastair Cowen",
        title: "Data QA Manager",
        image: "/content/landing/meet-the-team/alastair-cowen.jpg",
        alt: "alastair cowen"
    }, {
        name: "Emily Sheppard",
        title: "Client Relationship Manager",
        image: "/content/landing/meet-the-team/emily-sheppard.jpg",
        alt: "emily sheppard"
    }, {
        name: "Farzan Batki",
        title: "Head of Sales",
        image: "/content/landing/meet-the-team/farzan-batki.jpg",
        alt: "farzan batki"
    }];

    return (
        <Page dataCy="pages-landing-meet-the-team">
            <PageHeader
                title="Meet the team"
                subtitle="We are a team of over 50 data, retail and product experts, passionate about solving business problems with data"
                dataCy="pages-landing-meet-the-team-header"
            />
            <PageSection dataCy="pages-landing-meet-the-team-team-members">
                <Grid container spacing={6} justifyContent="center">
                    {teamMembers.map((teamMember, index) =>
                        <Grid key={index} item xs={12} md={4}>
                            <StyledCard elevation={0}>
                                <CardMedia component="img" image={teamMember.image} alt={teamMember.alt} />
                            </StyledCard>
                            <br />
                            <Typography variant="h4" component="div" align={align}>
                                {teamMember.name}
                            </Typography>
                            <Typography variant="h5" component="div" align={align}>
                                {teamMember.title}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </PageSection>
            <PageCallToAction
                color={theme.palette.common.black}
                bgcolor={theme.palette.tertiary.main}
                title="Ready to get started?"
                subtitle="Get Dash to access intelligent insights for your business"
                actionLabel="Book a demo"
                actionUrl="/contact-us?subject=book-a-demo"
                dataCy="pages-landing-meet-the-team-call-to-action"
            />
        </Page>
    );
};

export default MeetTheTeam;
