import types from "./types";

const getProductCategoryMixRequest = () => ({
    type: types.GET_PRODUCT_CATEGORY_MIX_REQUEST
});

const getProductCategoryMixSuccess = (store, comparator) => ({
    type: types.GET_PRODUCT_CATEGORY_MIX_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getProductCategoryMixFailure = () => ({
    type: types.GET_PRODUCT_CATEGORY_MIX_FAILURE
});

const actions = {
    getProductCategoryMixRequest,
    getProductCategoryMixSuccess,
    getProductCategoryMixFailure
};

export default actions;
