import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { getAccounts } from "modules/admin/accounts/accounts/accountsSlice";
import { apiGet, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Subscription {
    id: string,
    isActive: boolean,
    createdAt: DateTime,
    usePaymentsProvider: boolean,
    paymentsProviderSubscriptionId: string,
    productId: string,
    priceId: string,
    taxRateId: string,
    subscriptionPlan: string
}

interface SubscriptionsVisibility {
    isVisible: boolean,
    accountId: string
}

interface SubscriptionForm {
    usePaymentsProvider: boolean,
    productId: string,
    priceId: string,
    taxRateId: string,
    errors: SubscriptionFormErrors
}

interface SubscriptionFormErrors {
    productId: string,
    priceId: string,
    taxRateId: string
}

interface AddSubscriptionVisibility {
    isVisible: boolean,
    accountId: string
}

interface Product {
    id: string,
    name: string
}

interface Price {
    id: string,
    productId: string,
    name: string,
    priceInPence: number
}

interface TaxRate {
    id: string,
    name: string,
    isInclusive: boolean,
    percentage: number
}

interface SubscriptionsState {
    subscriptions: Subscription[],
    subscriptionsVisibility: SubscriptionsVisibility,
    subscription: SubscriptionForm,
    addSubscriptionVisibility: AddSubscriptionVisibility,
    products: Product[],
    prices: Price[],
    taxRates: TaxRate[],
}

const initialState: SubscriptionsState = {
    subscriptions: [],
    subscriptionsVisibility: {
        isVisible: false,
        accountId: ""
    },
    subscription: {
        usePaymentsProvider: false,
        productId: "",
        priceId: "",
        taxRateId: "",
        errors: {
            productId: "",
            priceId: "",
            taxRateId: ""
        }
    },
    addSubscriptionVisibility: {
        isVisible: false,
        accountId: ""
    },
    products: [],
    prices: [],
    taxRates: []
};

const subscriptionsSlice = createSlice({
    name: "admin/accounts/subscriptions",
    initialState,
    reducers: {
        setSubscriptions: (state, action: PayloadAction<Subscription[]>) => {
            state.subscriptions = action.payload;
        },
        clearSubscriptions: (state) => {
            state.subscriptions = initialState.subscriptions;
        },
        showSubscriptions: (state, action: PayloadAction<string>) => {
            state.subscriptionsVisibility.isVisible = true;
            state.subscriptionsVisibility.accountId = action.payload;
        },
        hideSubscriptions: (state) => {
            state.subscriptionsVisibility = initialState.subscriptionsVisibility;
        },
        setSubscription: (state, action: PayloadAction<SubscriptionForm>) => {
            state.subscription = action.payload;
        },
        clearSubscription: (state) => {
            state.subscription = initialState.subscription;
        },
        showAddSubscription: (state, action: PayloadAction<string>) => {
            state.addSubscriptionVisibility.isVisible = true;
            state.addSubscriptionVisibility.accountId = action.payload;
        },
        hideAddSubscription: (state) => {
            state.addSubscriptionVisibility = initialState.addSubscriptionVisibility;
        },
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.products = action.payload;
        },
        clearProducts: (state) => {
            state.products = initialState.products;
        },
        setPrices: (state, action: PayloadAction<Price[]>) => {
            state.prices = action.payload;
        },
        clearPrices: (state) => {
            state.prices = initialState.prices;
        },
        setTaxRates: (state, action: PayloadAction<TaxRate[]>) => {
            state.taxRates = action.payload;
        },
        clearTaxRates: (state) => {
            state.taxRates = initialState.taxRates;
        }
    }
});

export const {
    clearSubscriptions,
    showSubscriptions,
    hideSubscriptions,
    setSubscription,
    clearSubscription,
    showAddSubscription,
    hideAddSubscription
} = subscriptionsSlice.actions;

export const getSubscriptions = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/subscriptions`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const subscriptionsRaw = response.data.subscriptions;
            const subscriptions = subscriptionsRaw.map((subscriptionRaw: any) => ({
                ...subscriptionRaw,
                createdAt: subscriptionRaw.createdAt
                    ? DateTime.fromISO(subscriptionRaw.createdAt, { zone: "utc" })
                    : undefined
            }));
            dispatch(subscriptionsSlice.actions.setSubscriptions(subscriptions));
            break;
        }
        default: {
            dispatch(subscriptionsSlice.actions.clearSubscriptions());
            break;
        }
    }
};

export const addSubscription = (accountId: string): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const subscription = selectSubscription(state);
    const response = await dispatch(apiPost(`/admin/accounts/${accountId}/subscriptions`, subscription));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(subscriptionsSlice.actions.hideAddSubscription());
            dispatch(subscriptionsSlice.actions.clearSubscription());
            dispatch(notifySuccess("Subscription added."));
            dispatch(getSubscriptions(accountId));
            dispatch(getAccounts());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                productId: response.errorData?.errors?.productId?.[0],
                priceId: response.errorData?.errors?.priceId?.[0],
                taxRateId: response.errorData?.errors?.taxRateId?.[0]
            };
            dispatch(subscriptionsSlice.actions.setSubscription({ ...subscription, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const getProducts = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/subscriptions/products`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const products = response.data.products;
            dispatch(subscriptionsSlice.actions.setProducts(products));
            break;
        }
        default: {
            dispatch(subscriptionsSlice.actions.clearProducts());
            break;
        }
    }
};

export const getPrices = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/subscriptions/prices`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const prices = response.data.prices;
            dispatch(subscriptionsSlice.actions.setPrices(prices));
            break;
        }
        default: {
            dispatch(subscriptionsSlice.actions.clearPrices());
            break;
        }
    }
};

export const getTaxRates = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/accounts/${accountId}/subscriptions/tax-rates`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const taxRates = response.data.taxRates;
            dispatch(subscriptionsSlice.actions.setTaxRates(taxRates));
            break;
        }
        default: {
            dispatch(subscriptionsSlice.actions.clearTaxRates());
            break;
        }
    }
};

export const selectSubscriptions = (state: RootState) => {
    return state.admin.accounts.subscriptions.subscriptions;
};

export const selectSubscriptionsVisibility = (state: RootState) => {
    return state.admin.accounts.subscriptions.subscriptionsVisibility;
};

export const selectSubscription = (state: RootState) => {
    return state.admin.accounts.subscriptions.subscription;
};

export const selectAddSubscriptionVisibility = (state: RootState) => {
    return state.admin.accounts.subscriptions.addSubscriptionVisibility;
};

export const selectProducts = (state: RootState) => {
    return state.admin.accounts.subscriptions.products;
};

export const selectPrices = (state: RootState) => {
    return state.admin.accounts.subscriptions.prices;
};

export const selectTaxRates = (state: RootState) => {
    return state.admin.accounts.subscriptions.taxRates;
};

export default subscriptionsSlice;
