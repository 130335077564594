import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/location/store";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { CatchmentAccountIds } from "modules/customer/tools/location/locationSlice";

export class RetailCentreCentiles {
    public readonly retailCentreId: number;
    public readonly storeCategoryId: number;
    public readonly isScenario: boolean;
    public readonly affluence: number;
    public readonly age: number;
    public readonly children: number;
    public readonly diversity: number;
    public readonly urbanicity: number;
    public readonly areaHealth: number;
    public readonly footfall: number;

    constructor(
        retailCentreId: number,
        storeCategoryId: number,
        isScenario: boolean,
        affluence: number,
        age: number,
        children: number,
        diversity: number,
        urbanicity: number,
        areaHealth: number,
        footfall: number
    ) {
        this.retailCentreId = retailCentreId;
        this.storeCategoryId = storeCategoryId;
        this.isScenario = isScenario;
        this.affluence = affluence;
        this.age = age;
        this.children = children;
        this.diversity = diversity;
        this.urbanicity = urbanicity;
        this.areaHealth = areaHealth;
        this.footfall = footfall;
    }
}

export const loadRetailCentresCentiles = (targetStoreCategoryId: number | undefined, comparatorStores: Store[], catchmentAccountIds: CatchmentAccountIds): AppThunk<Promise<RetailCentreCentiles[]>> => async (dispatch) => {
    if (!targetStoreCategoryId || comparatorStores.length === 0) {
        return [];
    }

    try {
        const loadCentilesQuery = (targetStoreCategoryId: number, comparatorStores: Store[], isScenario: boolean): AppThunk<Promise<RetailCentreCentiles[]>> => async (dispatch) => {
            const retailCentreIds = comparatorStores.map(store => store.retailCentre.id);
            const catchmentAccountId = isScenario ? catchmentAccountIds.scenario : catchmentAccountIds.baseline;
            const query = {
                dimensions: [
                    "LocationBenchmarkMetrics.RetailCentreID",
                    "LocationBenchmarkMetrics.AffluenceCentile",
                    "LocationBenchmarkMetrics.AgeCentile",
                    "LocationBenchmarkMetrics.ChildrenCentile",
                    "LocationBenchmarkMetrics.DiversityCentile",
                    "LocationBenchmarkMetrics.UrbanicityCentile",
                    "LocationBenchmarkMetrics.AreaHealthCentile",
                    "LocationBenchmarkMetrics.FootfallCentile"
                ],
                filters: [{
                    member: "LocationBenchmarkMetrics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(targetStoreCategoryId)]
                }, {
                    member: "LocationBenchmarkMetrics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIds.map(String)
                }, {
                    member: "LocationBenchmarkMetrics.IsScenario",
                    operator: "equals",
                    values: [String(Number(isScenario))]
                }, {
                    member: "LocationBenchmarkMetrics.Client_ID",
                    operator: "equals",
                    values: [catchmentAccountId]
                }]
            };
            const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;

            const rawData = resultSet.loadResponses[0].data;
            return rawData.map(row => new RetailCentreCentiles(
                Number(row["LocationBenchmarkMetrics.RetailCentreID"]),
                targetStoreCategoryId,
                isScenario,
                Number(row["LocationBenchmarkMetrics.AffluenceCentile"]),
                Number(row["LocationBenchmarkMetrics.AgeCentile"]),
                Number(row["LocationBenchmarkMetrics.ChildrenCentile"]),
                Number(row["LocationBenchmarkMetrics.DiversityCentile"]),
                Number(row["LocationBenchmarkMetrics.UrbanicityCentile"]),
                Number(row["LocationBenchmarkMetrics.AreaHealthCentile"]),
                Number(row["LocationBenchmarkMetrics.FootfallCentile"])
            ));
        };

        const baselineRetailCentresCentiles = await dispatch(loadCentilesQuery(targetStoreCategoryId, comparatorStores, false));

        const scenarioComparators = [];
        for (const comparator of comparatorStores) {
            const comparatorCentiles = baselineRetailCentresCentiles.find(centiles => centiles.retailCentreId === comparator.retailCentre.id);
            if (!comparatorCentiles) {
                scenarioComparators.push(comparator);
            }
        }

        const scenarioRetailCentresCentiles = scenarioComparators.length !== 0
            ? await dispatch(loadCentilesQuery(targetStoreCategoryId, scenarioComparators, true))
            : [];

        const retailCentresCentiles = baselineRetailCentresCentiles.concat(scenarioRetailCentresCentiles);
        return retailCentresCentiles;

    } catch (error) {
        dispatch(logError("Error loading RetailCentresCentiles.", error));
        throw error;
    }
};
