import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

interface BackdropState {
    displayCount: number
}

const initialState: BackdropState = {
    displayCount: 0
};

const backdropSlice = createSlice({
    name: "backdrop",
    initialState,
    reducers: {
        backdropOn: (state) => {
            state.displayCount += 1;
        },
        backdropOff: (state) => {
            state.displayCount -= 1;
        }
    }
});

export const { backdropOn, backdropOff } = backdropSlice.actions;

export const selectIsBackdropOn = (state: RootState): boolean => {
    return state.backdrop.displayCount > 0;
};

export default backdropSlice;
