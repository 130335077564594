import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";
import mathUtils from "utils/mathUtils";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectCatchmentTotalYearlySpend } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";

const CatchmentTotalYearlySpend = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const catchmentTotalYearlySpend = useAppSelector(selectCatchmentTotalYearlySpend);
    const { isLoading, hasErrors, data } = catchmentTotalYearlySpend;
    const valueFormatted = numberFormatter.toGBP(data.selectedStore);
    const comparisonValue = numberFormatter.toGBP(data.comparator);
    const differenceValue = mathUtils.safePercentageChange(data.selectedStore, data.comparator);
    const label = `${selectedStore?.name} store's total catchment area yearly spend`;

    return (
        <Box width="100%" height="100%" data-cy="catchment-total-yearly-spend">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {valueFormatted}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparisonValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparator?.name}`}
                differenceValue={
                    <PercentageIndicator loading={false} error={false} value={differenceValue} switchColor={false} noDataMessage={""} />
                }
                differenceLabel="% difference"
            />
        </Box>
    );
};

export default CatchmentTotalYearlySpend;
