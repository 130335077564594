import { PivotConfig, Query, ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export interface YearlyCosts {
    storeId: string;
    revenue: number;
    [key: string]: number | string | boolean;
}


export const loadYearlyCosts = (costReferenceDate: DateTime): AppThunk<Promise<YearlyCosts[]>> => async (dispatch) => {
    try {
        const priorTwelveMonthsToReferenceDate = costReferenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

        const costsQuery: Query = {
            measures: ["F_Cost.SumCostValue"],
            dimensions: ["D_Store.StoreNaturalID", "D_Account.AccountName"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                //@ts-ignore
                dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
        };

        const salesQuery = {
            measures: ["F_Sales.SumLineValue", "F_Sales.SumLineCost"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            dimensions: ["D_Store.StoreNaturalID"]
        };

        const costsPromise = dispatch(cubeLoad(costsQuery));
        const salesPromise = dispatch(cubeLoad(salesQuery));
        const results = await Promise.all([costsPromise, salesPromise]) as unknown as ResultSet[];
        const salesRawData = results[1].rawData();

        const pivotConfig: PivotConfig = {
            x: ["D_Store.StoreNaturalID", 'measures'],
            y: ["D_Account.AccountName"]
        };

        return results[0].tablePivot(pivotConfig).map(costsRow => {
            const relevantSalesData = salesRawData.find(salesRow => salesRow["D_Store.StoreNaturalID"] === costsRow["D_Store.StoreNaturalID"]);
            const revenue = relevantSalesData ? relevantSalesData["F_Sales.SumLineValue"] : 0;
            const cogs = relevantSalesData ? relevantSalesData["F_Sales.SumLineCost"] : 0;

            const storeId = String(costsRow["D_Store.StoreNaturalID"]);
            delete costsRow["D_Store.StoreNaturalID"];
            delete costsRow["measures"];

            const costKeys = Object.keys(costsRow);
            let totalCosts = cogs;
            costKeys.forEach(key => {
                totalCosts += Number(costsRow[key]);
            });

            return {
                storeId,
                "Cost of goods": cogs ?? 0,
                "Net profit": revenue - totalCosts,
                revenue,
                ...costsRow
            };
        });
    } catch (error) {
        dispatch(logError("Error loading YearlyCosts.", error));
        throw error;
    }
};
