import React from "react";

const CompetitionScoreSubtitle: React.FC = () => {
    return (
        <>
            <p>
                Exclusive to Dash, the Competition Score evaluates the level of competition in your store's local area,
                by analysing:
            </p>
            <ol>
                <li>
                    The store's distance from the core retail hub in the local area
                </li>
                <li>
                    The size of the store (in square feet)
                </li>
                <li>
                    The number of competitors in the local area, their size, and their distance from the core retail hub
                </li>
            </ol>
            <p>
                We use these metrics to calculate a Competition Score between 0 and 100:
            </p>
            <ul>
                <li>
                    A score close to 0 indicates that your store is struggling to compete.
                </li>
                <li>
                    A score close to 100 indicates that your store has a clear competitive advantage.
                </li>
            </ul>
        </>
    );
};

export default CompetitionScoreSubtitle;
