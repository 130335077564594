import React from "react";

import { Button, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import { useAppDispatch, useAppSelector } from "store";
import { ProductChapter, selectCurrentChapter, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { selectStoreProductFiltersVisibility, showStoreProductFilters } from "modules/customer/tools/product/storeProductFilters/storeProductFiltersSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const OpenPartners: React.FC = () => {
    const dispatch = useAppDispatch();
    const filtersVisibility = useAppSelector(selectStoreProductFiltersVisibility);
    const selectedStore = useAppSelector(selectSelectedStore);
    const currentChapter = useAppSelector(selectCurrentChapter);
    const storeChapters = [ProductChapter.StoreOpportunities, ProductChapter.ProductStoreFit];

    const isTooltipOpen = storeChapters.includes(currentChapter) && !filtersVisibility.isVisible && !selectedStore;

    const handleClick = () => {
        dispatch(showStoreProductFilters());
    };

    return (
        <>
            <SimpleTooltip
                placement={SimpleTooltipPlacement.Bottom}
                title={
                    <Typography variant="subtitle1" component="div">
                        Select a store to view store opportunities
                    </Typography>
                }
                open={isTooltipOpen}
                disableFocusListener={true}
                disableHoverListener={true}
                disableTouchListener={true}
            >
                <StyledButton
                    variant="outlined"
                    size="small"
                    color="default"
                    disableElevation
                    onClick={handleClick}
                    data-cy="btn-setup"
                >
                    <SettingsIcon />
                </StyledButton>
            </SimpleTooltip>
        </>
    );
};

export default OpenPartners;
