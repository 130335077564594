import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const HouseholdNumberOfCars: React.FC = () => {
    const title = "Household number of cars (Urbanicity)";
    const categories = [
        "3+ cars",
        "2 cars",
        "1 car",
        "0 cars"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.householdNumberOfCars;
    const selectedStoreValues = selectedStore ? [
        selectedStore.car3Plus,
        selectedStore.car2,
        selectedStore.car1,
        selectedStore.car0
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.householdNumberOfCars;
    const comparatorValues = comparator ? [
        comparator.car3Plus,
        comparator.car2,
        comparator.car1,
        comparator.car0
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default HouseholdNumberOfCars;
