import React from "react";

import { Card, CardMedia, Grid, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { GuidanceStep, selectGuidanceActiveStep } from "modules/customer/data/dataSlice";

const GuidanceSteps: React.FC = () => {
    const guidanceActiveStep = useAppSelector(selectGuidanceActiveStep);
    const { image, alt, title, text } = (() => {
        switch (guidanceActiveStep) {
            case GuidanceStep.DashUsesDatasets:
                return {
                    image: "/content/customer/data/guidance/dash-uses-datasets-to-drive-your-analytics.png",
                    alt: "dash uses datasets to drive your analytics",
                    title: "Dash uses datasets to drive your Analytics",
                    text: "Input and manage your datasets from the Data dashboard to keep your Insights and Tools up-to-date and relevant to your business. Dash provides statuses against datasets to help you manage your data. Statuses will let you know when data is old, missing, ready to be included in a refresh or has encountered processing issues. Select a status to view more details."
                };
            case GuidanceStep.DownloadTemplates:
                return {
                    image: "/content/customer/data/guidance/download-templates-and-data-examples-before-uploading-data.png",
                    alt: "download templates and data examples before uploading data",
                    title: "Download templates and data examples before uploading data",
                    text: "Numeric datasets have unique templates or data examples to help you upload your data. These provide guidance on exactly what data is required, in what format and how to provide it. Download these before preparing your data to make sure Dash can process your data successfully later on. Once you're ready, select upload to navigate to the upload screen."
                };
            case GuidanceStep.UploadData:
                return {
                    image: "/content/customer/data/guidance/upload-your-data-files.png",
                    alt: "upload your data files",
                    title: "Upload your data files",
                    text: "Upload data files using the dedicated upload screen for the relevant dataset. Once uploaded successfully, you can submit your data for processing."
                };
            case GuidanceStep.ProcessData:
                return {
                    image: "/content/customer/data/guidance/hold-tight-whilst-we-process-your-data.png",
                    alt: "hold tight whilst we process your data",
                    title: "Hold tight whilst we process your data",
                    text: "Dash will process your data to make sure everything looks right before you refresh your Analytics. Depending on the size of your data file, this could take a while. In the meantime you can use your Analytics as normal, just check back later to make sure your data has processed successfully and is ready for refresh."
                };
            case GuidanceStep.RefreshAnalytics:
                return {
                    image: "/content/customer/data/guidance/refresh-analytics-for-all-users.png",
                    alt: "refresh analytics for all users",
                    title: "Refresh Analytics for all users",
                    text: "Once you've updated all the datasets you want to be reflected in your Analytics, go ahead and select the refresh pane. Don't worry, we'll double-check all the details are correct before this happens. You won't be able to refresh if you haven't provided all the required datasets or you don't have a refresh allowance remaining. Refreshes update Analytics for all users in your organisation and take place overnight. They'll be ready to view the next morning."
                };
            case GuidanceStep.ReadDataGuidance:
                return {
                    image: "/content/customer/data/guidance/read-our-full-data-guidance-document-before-you-start.png",
                    alt: "read our full data guidance document before you start",
                    title: "Read our full data guidance document before you start",
                    text: "We know it can be tricky to get started, so we've made a more detailed guidance document for you to review. You can access this at any time using the information icon on the Data dashboard and then selecting Detailed guidance."
                };
            default:
                return {
                    image: "",
                    alt: "",
                    title: "",
                    text: ""
                };
        }
    })();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card elevation={0}>
                    <CardMedia component="img" image={image} alt={alt} />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" component="div">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default GuidanceSteps;
