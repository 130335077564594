import React from "react";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import Page from "components/admin/Page";

import { selectAppDetails } from "modules/appDetails/appDetailsSlice";
import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const About = () => {
    const userInfo = useAppSelector(selectUserInfo);
    const appDetails = useAppSelector(selectAppDetails);

    return (
        <Page dataCy="pages-admin-about">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        KPMG Admin - {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        About
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Version number
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {appDetails.versionNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Environment name
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {appDetails.environmentName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Is development
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {appDetails.isDevelopment ? "Yes" : "No"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Is production
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {appDetails.isProduction ? "Yes" : "No"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
};

export default About;
