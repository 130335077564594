import React from "react";

import { Box, Button, IconButton, TextField, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import {
    clearAdmin,
    editAdmin,
    getAdmin,
    hideEditAdmin,
    selectEditAdminVisibility,
    selectAdmin,
    setAdmin,
    showEditAdmin
} from "modules/admin/admins/adminsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

interface EditAdminProps {
    adminId: string;
}

const EditAdmin: React.FC<EditAdminProps> = (props) => {
    const { adminId } = props;
    const dispatch = useAppDispatch();
    const admin = useAppSelector(selectAdmin);
    const editAdminVisibility = useAppSelector(selectEditAdminVisibility);
    const show = (editAdminVisibility.isVisible) && (adminId === editAdminVisibility.adminId);

    React.useEffect(() => {
        if (show) {
            dispatch(getAdmin(adminId));
        }
    }, [dispatch, show, adminId]);

    const handleEditAdminClick = () => {
        dispatch(showEditAdmin(adminId));
    };

    const handleSubmitClick = () => {
        dispatch(editAdmin());
    };

    const handleCancelClick = () => {
        dispatch(hideEditAdmin());
        dispatch(clearAdmin());
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = event.target.value;
        const newAdmin = { ...admin, firstName, errors: { ...admin.errors, firstName: "" } };
        dispatch(setAdmin(newAdmin));
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = event.target.value;
        const newAdmin = { ...admin, lastName, errors: { ...admin.errors, lastName: "" } };
        dispatch(setAdmin(newAdmin));
    };

    return (
        <>
            <SimpleTooltip title="Edit">
                <StyledIconButton size="small" onClick={handleEditAdminClick}>
                    <EditRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>Edit admin</Typography>
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1" component="div">
                                {admin.email}
                            </Typography>
                            &nbsp;&nbsp;
                            <SimpleTooltip
                                placement={SimpleTooltipPlacement.Right}
                                title={
                                    <Typography variant="body2" component="div">
                                        The admin's email cannot be edited.
                                        If the email is incorrect, please delete the admin and create a new one.
                                    </Typography>
                                }
                            >
                                <InfoOutlinedIcon fontSize="small" cursor="pointer" />
                            </SimpleTooltip>
                        </Box>
                    </>
                }
                content={
                    <>
                        <StyledTextField
                            label="First name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={admin.firstName}
                            onChange={handleFirstNameChange}
                            error={!!admin.errors.firstName}
                            helperText={admin.errors.firstName}
                            required
                            fullWidth
                        />
                        <StyledTextField
                            label="Last name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={admin.lastName}
                            onChange={handleLastNameChange}
                            error={!!admin.errors.lastName}
                            helperText={admin.errors.lastName}
                            required
                            fullWidth
                        />
                    </>
                }
                actions={
                    <>
                        <Button onClick={handleCancelClick}>Cancel</Button>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>Save</Button>
                    </>
                }
            />
        </>
    );
};

export default EditAdmin;
