import React from "react";
import { useSelector } from "react-redux";
import stringUtils from "utils/stringUtils";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Radar from "components/visuals/Radar";

import { selectors } from "modules/customer/insights/performance/overview/companyHealth";
import numberFormatter from "utils/numberFormatter";

const InsightPerformance = () => {
    const { loading, error, isBudgetDataAvailable, values } = useSelector(state => selectors.insightPerformance(state));
    const totalScore = values.budget + values.forecast + values.growth + values.product + values.sales;
    const denominator = isBudgetDataAvailable ? 25 : 20;
    const colourPalette = useColourPalette();
    const colourGroup = Math.floor(colourPalette.heatmap.length * totalScore / denominator);
    const colourIndex = Math.min(colourGroup, colourPalette.heatmap.length - 1);
    const colour = colourPalette.heatmap[colourIndex];

    const categories = Object.keys(values).map(item => stringUtils.capitaliseFirstLetter(item));
    const options = {
        xAxis: {
            categories: categories,
            tickmarkPlacement: "on",
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        colors: [
            colour
        ],
        series: [{
            name: "Relative Performance",
            data: Object.values(values),
            pointPlacement: "on"
        }], 
        tooltip: {
            useHTML: true,
            headerFormat: ``,
            footerFormat: ``,
            pointFormatter: function () {
                return `<table>${stringUtils.tooltipHTML([this.category], {
                    header: "Relative performance",
                    values: [numberFormatter.toDecimalPlaces(this.custom.tooltipValue, 1)],
                    headerFormatting: `color:${this.color}`
                })}</table>`;
            }
        }
    };

    return (
        <Box data-cy="insight-performance">
            <Radar loading={loading} error={error} options={options} dataAdditionPercent={10} />
        </Box>
    );
};

export default InsightPerformance;
