import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiDelete, apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError, notifySuccess } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface Lead {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    companyRegistrationNumber: string,
    jobRole: string,
    createdAt: Date,
    demoCall?: Date,
    product?: Product,
    price?: Price,
    taxRate?: TaxRate,
    initialTermInYears: number,
    quoteExpiresAt?: Date,
    status: LeadStatus
}

interface Product {
    id: string,
    name: string
}

interface Price {
    id: string,
    productId: string,
    name: string,
    priceInPence: number
}

interface TaxRate {
    id: string,
    name: string,
    isInclusive: boolean,
    percentage: number
}

export enum LeadStatus {
    NewLead = "NewLead",
    DemoBooked = "DemoBooked",
    QuoteSent = "QuoteSent"
}

interface LeadsFilter {
    nameOrEmail: string
}

interface LeadForm {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    companyRegistrationNumber: string,
    jobRole: string,
    demoCall?: Date,
    productId: string,
    priceId: string,
    taxRateId: string,
    initialTermInYears: number,
    errors: LeadFormErrors
}

interface LeadFormErrors {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyName: string,
    companyRegistrationNumber: string,
    jobRole: string,
    demoCall: string,
    productId: string,
    priceId: string,
    initialTermInYears: string,
    taxRateId: string
}

interface AddLeadVisibility {
    isVisible: boolean
}

interface EditLeadVisibility {
    isVisible: boolean,
    leadId: string
}

interface DeleteLeadVisibility {
    isVisible: boolean,
    leadId: string
}

interface SendQuoteVisibility {
    isVisible: boolean,
    leadId: string
}

interface LeadsState {
    leads: Lead[],
    filter: LeadsFilter,
    lead: LeadForm,
    addLeadVisibility: AddLeadVisibility,
    editLeadVisibility: EditLeadVisibility,
    deleteLeadVisibility: DeleteLeadVisibility,
    sendQuoteVisibility: SendQuoteVisibility,
    products: Product[],
    prices: Price[],
    taxRates: TaxRate[]
}

const initialState: LeadsState = {
    leads: [],
    lead: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        companyRegistrationNumber: "",
        jobRole: "",
        demoCall: undefined,
        productId: "",
        priceId: "",
        taxRateId: "",
        initialTermInYears: 1,
        errors: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            companyRegistrationNumber: "",
            jobRole: "",
            demoCall: "",
            productId: "",
            priceId: "",
            taxRateId: "",
            initialTermInYears: ""
        }
    },
    addLeadVisibility: {
        isVisible: false
    },
    editLeadVisibility: {
        isVisible: false,
        leadId: ""
    },
    deleteLeadVisibility: {
        isVisible: false,
        leadId: ""
    },
    sendQuoteVisibility: {
        isVisible: false,
        leadId: ""
    },
    filter: {
        nameOrEmail: ""
    },
    products: [],
    prices: [],
    taxRates: []
};

const leadsSlice = createSlice({
    name: "admin/leads",
    initialState,
    reducers: {
        setLeads: (state, action: PayloadAction<Lead[]>) => {
            state.leads = action.payload;
        },
        clearLeads: (state) => {
            state.leads = initialState.leads;
        },
        setFilter: (state, action: PayloadAction<LeadsFilter>) => {
            state.filter = action.payload;
        },
        setLead: (state, action: PayloadAction<LeadForm>) => {
            state.lead = action.payload;
        },
        clearLead: (state) => {
            state.lead = initialState.lead;
        },
        showAddLead: (state) => {
            state.addLeadVisibility.isVisible = true;
        },
        hideAddLead: (state) => {
            state.addLeadVisibility = initialState.addLeadVisibility;
        },
        showEditLead: (state, action: PayloadAction<string>) => {
            state.editLeadVisibility.isVisible = true;
            state.editLeadVisibility.leadId = action.payload;
        },
        hideEditLead: (state) => {
            state.editLeadVisibility = initialState.editLeadVisibility;
        },
        showDeleteLead: (state, action: PayloadAction<string>) => {
            state.deleteLeadVisibility.isVisible = true;
            state.deleteLeadVisibility.leadId = action.payload;
        },
        hideDeleteLead: (state) => {
            state.deleteLeadVisibility = initialState.deleteLeadVisibility;
        },
        showSendQuote: (state, action: PayloadAction<string>) => {
            state.sendQuoteVisibility.isVisible = true;
            state.sendQuoteVisibility.leadId = action.payload;
        },
        hideSendQuote: (state) => {
            state.sendQuoteVisibility = initialState.sendQuoteVisibility;
        },
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.products = action.payload;
        },
        clearProducts: (state) => {
            state.products = initialState.products;
        },
        setPrices: (state, action: PayloadAction<Price[]>) => {
            state.prices = action.payload;
        },
        clearPrices: (state) => {
            state.prices = initialState.prices;
        },
        setTaxRates: (state, action: PayloadAction<TaxRate[]>) => {
            state.taxRates = action.payload;
        },
        clearTaxRates: (state) => {
            state.taxRates = initialState.taxRates;
        }
    }
});

export const {
    setLead,
    clearLead,
    showAddLead,
    hideAddLead,
    showEditLead,
    hideEditLead,
    showDeleteLead,
    hideDeleteLead,
    showSendQuote,
    hideSendQuote,
    setFilter
} = leadsSlice.actions;

export const getLeads = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/leads"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const leads = response.data.leads;
            dispatch(leadsSlice.actions.setLeads(leads));
            break;
        }
        default: {
            dispatch(leadsSlice.actions.clearLeads());
            break;
        }
    }
};

export const getLead = (leadId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet(`/admin/leads/${leadId}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const lead = response.data.lead;
            dispatch(leadsSlice.actions.setLead({ ...lead, errors: initialState.lead.errors }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(leadsSlice.actions.hideEditLead());
            dispatch(leadsSlice.actions.hideDeleteLead());
            dispatch(notifyError("Lead not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const addLead = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const lead = selectLead(state);
    const response = await dispatch(apiPost("/admin/leads", lead));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(leadsSlice.actions.hideAddLead());
            dispatch(leadsSlice.actions.clearLead());
            dispatch(notifySuccess("Lead added."));
            dispatch(getLeads());
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                ...initialState.lead.errors,
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0],
                phoneNumber: response.errorData?.errors?.phoneNumber?.[0],
                companyName: response.errorData?.errors?.companyName?.[0],
                companyRegistrationNumber: response.errorData?.errors?.companyRegistrationNumber?.[0],
                jobRole: response.errorData?.errors?.jobRole?.[0]
            };
            dispatch(leadsSlice.actions.setLead({ ...lead, errors }));
            break;
        }
        default:
            break;
    }
};

export const editLead = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const lead = selectLead(state);
    const response = await dispatch(apiPut(`/admin/leads/${lead.id}`, lead));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(leadsSlice.actions.hideEditLead());
            dispatch(leadsSlice.actions.clearLead());
            dispatch(notifySuccess("Lead edited."));
            dispatch(getLeads());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(leadsSlice.actions.hideEditLead());
            dispatch(notifyError("Lead not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0],
                phoneNumber: response.errorData?.errors?.phoneNumber?.[0],
                companyName: response.errorData?.errors?.companyName?.[0],
                companyRegistrationNumber: response.errorData?.errors?.companyRegistrationNumber?.[0],
                jobRole: response.errorData?.errors?.jobRole?.[0],
                demoCall: response.errorData?.errors?.demoCall?.[0],
                productId: response.errorData?.errors?.productId?.[0],
                priceId: response.errorData?.errors?.priceId?.[0],
                taxRateId: response.errorData?.errors?.taxRateId?.[0],
                initialTermInYears: response.errorData?.errors?.initialTermInYears?.[0]
            };
            dispatch(leadsSlice.actions.setLead({ ...lead, errors }));
            break;
        }
        default: {
            break;
        }
    }
};

export const deleteLead = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const lead = selectLead(state);
    const response = await dispatch(apiDelete(`/admin/leads/${lead.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(leadsSlice.actions.hideDeleteLead());
            dispatch(leadsSlice.actions.clearLead());
            dispatch(notifySuccess("Lead deleted."));
            dispatch(getLeads());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Lead not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const sendQuote = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const lead = selectLead(state);
    const response = await dispatch(apiPut(`/admin/leads/${lead.id}/send-quote`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(leadsSlice.actions.hideSendQuote());
            dispatch(leadsSlice.actions.clearLead());
            dispatch(notifySuccess("Quote sent."));
            dispatch(getLeads());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(leadsSlice.actions.hideSendQuote());
            dispatch(notifyError("Lead not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const getProducts = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/leads/products"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const products = response.data.products;
            dispatch(leadsSlice.actions.setProducts(products));
            break;
        }
        default: {
            dispatch(leadsSlice.actions.clearProducts());
            break;
        }
    }
};

export const getPrices = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/leads/prices"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const prices = response.data.prices;
            dispatch(leadsSlice.actions.setPrices(prices));
            break;
        }
        default: {
            dispatch(leadsSlice.actions.clearPrices());
            break;
        }
    }
};

export const getTaxRates = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/leads/tax-rates"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const taxRates = response.data.taxRates;
            dispatch(leadsSlice.actions.setTaxRates(taxRates));
            break;
        }
        default: {
            dispatch(leadsSlice.actions.clearTaxRates());
            break;
        }
    }
};

export const selectFilter = (state: RootState) => {
    return state.admin.leads.filter;
};

export const selectLeads = createSelector(
    (state: RootState): Lead[] => state.admin.leads.leads,
    selectFilter,
    (leads, filter) => {
        const nameOrEmail = filter.nameOrEmail.toLowerCase();
        return leads.filter(lead =>
            lead.firstName.toLowerCase().includes(nameOrEmail)
            || lead.lastName.toLowerCase().includes(nameOrEmail)
            || lead.email.toLowerCase().includes(nameOrEmail));
    }
);

export const selectLead = (state: RootState) => {
    return state.admin.leads.lead;
};

export const selectAddLeadVisibility = (state: RootState) => {
    return state.admin.leads.addLeadVisibility;
};

export const selectEditLeadVisibility = (state: RootState) => {
    return state.admin.leads.editLeadVisibility;
};

export const selectDeleteLeadVisibility = (state: RootState) => {
    return state.admin.leads.deleteLeadVisibility;
};

export const selectSendQuoteVisibility = (state: RootState) => {
    return state.admin.leads.sendQuoteVisibility;
};

export const selectProducts = (state: RootState) => {
    return state.admin.leads.products;
};

export const selectPrices = (state: RootState) => {
    return state.admin.leads.prices;
};

export const selectTaxRates = (state: RootState) => {
    return state.admin.leads.taxRates;
};

export default leadsSlice;
