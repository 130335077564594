import React from "react";

import { TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";

import {
    clearDeleteAccountConfirmation,
    deleteAccount,
    hideDeleteAccountConfirmation,
    selectDeleteAccountConfirmation,
    selectDeleteAccountConfirmationVisibility,
    setDeleteAccountConfirmation
} from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

interface DeleteAccountConfirmationProps {
    accountId: string,
    companyName: string
}

const DeleteAccountConfirmation: React.FC<DeleteAccountConfirmationProps> = (props) => {
    const { accountId, companyName } = props;
    const dispatch = useAppDispatch();
    const deleteAccountConfirmation = useAppSelector(selectDeleteAccountConfirmation);
    const deleteAccountConfirmationVisibility = useAppSelector(selectDeleteAccountConfirmationVisibility);
    const show = (deleteAccountConfirmationVisibility.isVisible) && (accountId === deleteAccountConfirmationVisibility.accountId);

    const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const companyName = event.target.value;
        const newDeleteAccountConfirmation = {
            ...deleteAccountConfirmation,
            companyName,
            errors: { ...deleteAccountConfirmation.errors, companyName: "" }
        };
        dispatch(setDeleteAccountConfirmation(newDeleteAccountConfirmation));
    };

    const handleCompanyNamePaste = (event: React.ClipboardEvent) => {
        event.preventDefault();
        return false;
    };

    const handleConfirmClick = () => {
        dispatch(deleteAccount(accountId));
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteAccountConfirmation());
        dispatch(clearDeleteAccountConfirmation());
    };

    return (
        <ConfirmationDialog
            open={show}
            title="Are you absolutely sure you want to delete this account?"
            subtitle={
                <>
                    <Typography variant="subtitle1" component="div">
                        This action <b>cannot</b> be undone.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        This will permanently delete
                        the <b>{companyName}</b> account, users, subscriptions, dataset files, and data refreshes.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Please type in the company name to confirm.
                    </Typography>
                    <StyledTextField
                        label="Company name"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        margin="normal"
                        value={deleteAccountConfirmation.companyName}
                        onChange={handleCompanyNameChange}
                        onPaste={handleCompanyNamePaste}
                        error={!!deleteAccountConfirmation.errors.companyName}
                        helperText={deleteAccountConfirmation.errors.companyName}
                        required
                        fullWidth
                    />
                </>
            }
            type={ConfirmationDialogType.Error}
            confirmLabel="Yes, permanently delete it"
            cancelLabel="No, cancel"
            onConfirm={handleConfirmClick}
            onCancel={handleCancelClick}
        />
    );
};

export default DeleteAccountConfirmation;
