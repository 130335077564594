const GET_LIFETIME_OF_STORES_REQUEST = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_LIFETIME_OF_STORES_REQUEST";
const GET_LIFETIME_OF_STORES_SUCCESS = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_LIFETIME_OF_STORES_SUCCESS";
const GET_LIFETIME_OF_STORES_FAILURE = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_LIFETIME_OF_STORES_FAILURE";
const GET_NET_OPEN_VS_CLOSURES_OVER_TIME_REQUEST = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_NET_OPEN_VS_CLOSURES_OVER_TIME_REQUEST";
const GET_NET_OPEN_VS_CLOSURES_OVER_TIME_SUCCESS = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_NET_OPEN_VS_CLOSURES_OVER_TIME_SUCCESS";
const GET_NET_OPEN_VS_CLOSURES_OVER_TIME_FAILURE = "spark/customer/insights/portfolio/areaHealth/openingsAndClosures/GET_NET_OPEN_VS_CLOSURES_OVER_TIME_FAILURE";

const types = {
    GET_LIFETIME_OF_STORES_REQUEST,
    GET_LIFETIME_OF_STORES_SUCCESS,
    GET_LIFETIME_OF_STORES_FAILURE,
    GET_NET_OPEN_VS_CLOSURES_OVER_TIME_REQUEST,
    GET_NET_OPEN_VS_CLOSURES_OVER_TIME_SUCCESS,
    GET_NET_OPEN_VS_CLOSURES_OVER_TIME_FAILURE,
};

export default types;
