import React from "react";

import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
    setAlignmentScoreField,
    selectAlignmentScoreField,
    selectTarget
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderWidth: "1px",
                borderColor: theme.palette.text.disabled
            },
            "&.Mui-focused fieldset": {
                borderWidth: "1px",
                borderColor: theme.palette.common.white
            }
        }
    }
}))(TextField);

const useStyles = makeStyles(theme => ({
    select: {
        "&:focus": {
            backgroundColor: theme.palette.background.default
        }
    },
    list: {
        backgroundColor: theme.palette.background.default
    }
}));

const Alignment: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;
    const alignmentScoreField = useAppSelector(selectAlignmentScoreField);
    const target = useAppSelector(selectTarget);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAlignmentScoreField = event.target.value as ScoreField;
        dispatch(setAlignmentScoreField(newAlignmentScoreField));
    };

    const getAlignmentName = (scoreField: ScoreField) => {
        switch (scoreField) {
            case ScoreField.Overall:
                return "Overall alignment";
            case ScoreField.Demographics:
                return "Demographic alignment";
            case ScoreField.Spend:
                return "Spend alignment";
            case ScoreField.AreaHealth:
                return "Area health alignment";
            case ScoreField.Footfall:
                return "Footfall alignment";
            default:
                return "";
        }
    };

    return (
        <StyledTextField
            select
            margin="dense"
            variant="outlined"
            fullWidth
            SelectProps={{
                classes: { select: classes.select },
                IconComponent: ExpandMoreRoundedIcon,
                MenuProps: {
                    classes: { list: classes.list },
                    variant: "menu"
                },
                renderValue: (scoreField) =>
                    <Box display="flex">
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            Viewing:&nbsp;
                        </Typography>
                        <Typography variant="h6" component="div">
                            {getAlignmentName(scoreField as ScoreField)}
                        </Typography>
                    </Box>
            }}
            value={alignmentScoreField}
            onChange={handleChange}
            disabled={!target}
        >
            <MenuItem value={ScoreField.Overall}>
                {getAlignmentName(ScoreField.Overall)}
            </MenuItem>
            <MenuItem value={ScoreField.Demographics} disabled={!(target?.useDemographics)}>
                {getAlignmentName(ScoreField.Demographics)}
            </MenuItem>
            {enableSpendNew && (
                <MenuItem value={ScoreField.Spend} disabled={!(target?.useSpend)}>
                    {getAlignmentName(ScoreField.Spend)}
                </MenuItem>
            )}
            <MenuItem value={ScoreField.AreaHealth} disabled={!(target?.useAreaHealth)}>
                {getAlignmentName(ScoreField.AreaHealth)}
            </MenuItem>
            <MenuItem value={ScoreField.Footfall} disabled={!(target?.useFootfall)}>
                {getAlignmentName(ScoreField.Footfall)}
            </MenuItem>
        </StyledTextField>
    );
};

export default Alignment;
