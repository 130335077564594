import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";
import mathUtils from "utils/mathUtils";

import { useAppSelector } from "store";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectFootfallLevel } from "modules/customer/insights/portfolioNew/footfall/footfallSlice";

const FootfallLevel = () => {
    const colourPalette = useColourPalette();
    const comparator = useAppSelector(selectComparator);
    const footfallLevel = useAppSelector(selectFootfallLevel);
    const { isLoading, hasErrors, data } = footfallLevel;
    const valueFormatted = numberFormatter.toDecimalPlaces(data.selectedStore, 1);
    const comparisonValue = numberFormatter.toDecimalPlaces(data.comparator, 1);
    const differenceValue = mathUtils.safePercentageChange(data.selectedStore, data.comparator);
    const label = "Footfall level";

    return (
        <Box width="100%" height="100%" data-cy="footfall-level">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {valueFormatted}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparisonValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparator?.name}`}
                differenceValue={
                    <PercentageIndicator loading={false} error={false} value={differenceValue} switchColor={false} noDataMessage={""} />
                }
                differenceLabel="% difference"
            />
        </Box>
    );
};

export default FootfallLevel;
