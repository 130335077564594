import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Progress from "components/visuals/Progress";
import Error from "components/visuals/Error";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import Sparkline from "components/visuals/Sparkline";

import { selectUserInfo } from "modules/auth/authSlice";
import { operations, selectors } from "modules/customer/insights/performance/sales/revenueGrowthBreakdown";

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "calc(100vw - 32px)"
    },
    container: {
        width: "100%",
        overflow: "auto",
        maxHeight: theme.spacing(130)
    },
    sectorRow: {
        backgroundColor: theme.palette.quaternary.main
    },
    noBorderBottom: {
        borderBottom: "none"
    },
    borderRight: {
        borderRight: "1px solid rgba(81, 81, 81, 1)"
    }
}));

const GrowthBreakdownGrid = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const userInfo = useSelector(selectUserInfo);
    const companyName = userInfo.companyDisplayName;
    const growthBreakdownGrid = useSelector(state => selectors.growthBreakdownGrid(state));
    const { loading, error, currentMonth, marketCategories, sector } = growthBreakdownGrid;
    const options = {
        chart: {
            height: theme.spacing(8),
            width: theme.spacing(32)
        }
    };

    React.useEffect(() => {
        dispatch(operations.getGrowthBreakdownGrid());
    }, [dispatch]);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <Card elevation={0} className={classes.card} data-cy="growth-breakdown-grid">
            <CardContent>
                <Typography variant="h6" component="div">
                    Historic growth performance for {companyName} vs the market
                </Typography>
                <TableContainer className={classes.container} data-cy="table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="17.5%" className={classes.borderRight} />
                                <TableCell align="center" width="17.5%" className={classes.borderRight}>
                                    <Typography variant="h6" component="div">
                                        Year-on-year growth (%)
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" width="17.5%" className={classes.borderRight}>
                                    <Typography variant="h6" component="div">
                                        {currentMonth} vs {currentMonth} last year growth (%)
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" width="17.5%" className={classes.borderRight}>
                                    <Typography variant="h6" component="div">
                                        Week-on-week growth (%)
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6" component="div">
                                        Month-on-month growth sparkline
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {marketCategories && marketCategories.length !== 0 && marketCategories.map((marketCategory, index) =>
                                <React.Fragment key={index}>
                                    <TableRow>
                                        <TableCell className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <Typography variant="h6" component="div">
                                                {`${companyName} ${marketCategory.categoryName}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={marketCategory.companyValues.yoyGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={marketCategory.companyValues.currentMonthVsSMLYGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={marketCategory.companyValues.wowGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.noBorderBottom}>
                                            <Sparkline
                                                loading={loading}
                                                error={error}
                                                options={{
                                                    ...options,
                                                    chart: {
                                                        ...options.chart,
                                                        backgroundColor: theme.palette.background.paper
                                                    },
                                                    series: [{ data: marketCategory.companyValues.momGrowthTrend }]
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.borderRight}>
                                            <Typography variant="h6" component="div">
                                                {marketCategory.categoryName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={marketCategory.benchmarkValues.yoyGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={marketCategory.benchmarkValues.currentMonthVsSMLYGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={marketCategory.benchmarkValues.wowGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Sparkline
                                                loading={loading}
                                                error={error}
                                                options={{
                                                    ...options,
                                                    chart: {
                                                        ...options.chart,
                                                        backgroundColor: theme.palette.background.paper
                                                    },
                                                    series: [{}, { data: marketCategory.benchmarkValues.momGrowthTrend }]
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                            {sector && Object.keys(sector).length !== 0 && (
                                <>
                                    <TableRow className={classes.sectorRow}>
                                        <TableCell className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <Typography variant="h6" component="div">
                                                {companyName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={sector.companyValues.yoyGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={sector.companyValues.currentMonthVsSMLYGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={`${classes.noBorderBottom} ${classes.borderRight}`}>
                                            <PercentageIndicator value={sector.companyValues.wowGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.noBorderBottom}>
                                            <Sparkline
                                                loading={loading}
                                                error={error}
                                                options={{
                                                    ...options,
                                                    chart: {
                                                        ...options.chart,
                                                        backgroundColor: theme.palette.quaternary.main
                                                    },
                                                    series: [{ data: sector.companyValues.momGrowthTrend }]
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.sectorRow}>
                                        <TableCell className={classes.borderRight}>
                                            <Typography variant="h6" component="div">
                                                {sector.sectorName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={sector.benchmarkValues.yoyGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={sector.benchmarkValues.currentMonthVsSMLYGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center" className={classes.borderRight}>
                                            <PercentageIndicator value={sector.benchmarkValues.wowGrowth} variant="h5" align="center" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Sparkline
                                                loading={loading}
                                                error={error}
                                                options={{
                                                    ...options,
                                                    chart: {
                                                        ...options.chart,
                                                        backgroundColor: theme.palette.quaternary.main
                                                    },
                                                    series: [{}, { data: sector.benchmarkValues.momGrowthTrend }]
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default GrowthBreakdownGrid;
