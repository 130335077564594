import React from "react";

import { Box, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

export interface CentileMetricProps {
    name: string,
    tooltip: string,
    value: string,
    disabled: boolean
}

const CentileMetric: React.FC<CentileMetricProps> = (props) => {
    const { name, tooltip, value, disabled } = props;

    return (
        <>
            <Box display="flex" alignItems="center">
                <Typography variant="body2" component="div" color={disabled ? "inherit" : "textSecondary"}>
                    {name}
                </Typography>
                &nbsp;&nbsp;
                <SimpleTooltip
                    placement={SimpleTooltipPlacement.Right}
                    title={
                        <Typography variant="body1" component="div" color="textPrimary">
                            {tooltip}
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon fontSize="inherit" color="disabled" cursor="pointer" />
                </SimpleTooltip>
            </Box>
            <Typography variant="subtitle2" component="div" color="textPrimary" gutterBottom>
                {disabled && (<br />)}
                {!disabled && value}
            </Typography>
        </>
    );
};

export default CentileMetric;
