import React from "react";
import { Grid } from "@material-ui/core";

import RevenueLessPropertyCostsPerSquareFoot from "./RevenueLessPropertyCostsPerSquareFoot";
import RevenuePerPoundOfPropertyCostCategorisation from "./RevenuePerPoundOfPropertyCostCategorisation";
import RankedGauge from "components/visuals/RankedGauge";
import { useAppSelector } from "store";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectPropertyCostPerSquareFoot, selectSalesPerPoundOfPropertyCosts, selectSalesPerSquareFoot } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const StoreSize: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const revenuePerSquareFoot = useAppSelector(selectSalesPerSquareFoot);
    const propertyCostsPerSquareFoot = useAppSelector(selectPropertyCostPerSquareFoot);
    const salesPerPoundOfPropertyCosts = useAppSelector(selectSalesPerPoundOfPropertyCosts);
    const comparatorName = comparator?.name ?? "";
    const revenuePerSquareFootfStores = revenuePerSquareFoot.data;
    const propertyCostsPerSquareFootStores = propertyCostsPerSquareFoot.data;
    const salesPerPoundOfPropertyCostsStores = salesPerPoundOfPropertyCosts.data;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="revenue-per-square-foot"
                    isLoading={revenuePerSquareFoot.isLoading}
                    hasErrors={revenuePerSquareFoot.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Revenue per square foot"
                    stores={revenuePerSquareFootfStores}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="property-costs-per-square-foot"
                    isLoading={propertyCostsPerSquareFoot.isLoading}
                    hasErrors={propertyCostsPerSquareFoot.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Property costs per square foot"
                    stores={propertyCostsPerSquareFootStores}
                    lowerIsBetter={true}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <RankedGauge
                    dataCy="revenue-per-pound-of-property-cost"
                    isLoading={salesPerPoundOfPropertyCosts.isLoading}
                    hasErrors={salesPerPoundOfPropertyCosts.hasErrors}
                    comparatorName={comparatorName}
                    titleText="Revenue per £ of property cost"
                    stores={salesPerPoundOfPropertyCostsStores}
                />
            </Grid>
            <Grid item xs={12}>
                <RevenueLessPropertyCostsPerSquareFoot />
            </Grid>
            <Grid item xs={12}>
                <RevenuePerPoundOfPropertyCostCategorisation />
            </Grid>
        </Grid>
    );
};

export default StoreSize;
