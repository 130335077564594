import { operations as storeSizeOperations } from "./storeSize";
import { operations as staffingOperations } from "./staffing";

const getPerformanceDriversData = () => async (dispatch) => {
    dispatch(storeSizeOperations.getSalesPerSquareFoot());
    dispatch(storeSizeOperations.getPropertyCostsPerSquareFoot());
    dispatch(storeSizeOperations.getSalesPerPoundOfPropertyCost());
    dispatch(staffingOperations.getPayrollCostPerSqft());
    dispatch(staffingOperations.getSalesValuePerHead());
    dispatch(staffingOperations.getSalesPerPoundOfStaffCost());
};

const operations = {
    getPerformanceDriversData
};

export default operations;
