import React from "react";

import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import {
    clearCandidateTarget,
    clearCandidateTargetSpendCategories,
    clearCentiles,
    selectCandidateTargetStoreCategory,
    selectCentilesIsLoading,
    selectStoreCategories,
    setCandidateTargetStoreCategory
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const useStyles = makeStyles(theme => ({
    paper: {
        maxHeight: "70vh",
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

const StoreCategory: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const candidateTargetStoreCategory = useAppSelector(selectCandidateTargetStoreCategory);
    const storeCategories = useAppSelector(selectStoreCategories);
    const isLoading = useAppSelector(selectCentilesIsLoading);

    const handleStoreCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCandidateTargetStoreCategoryId = Number(event.target.value);
        const newCandidateTargetStoreCategory = storeCategories.find(storeCategory => storeCategory.id === newCandidateTargetStoreCategoryId);
        if (newCandidateTargetStoreCategory) {
            dispatch(clearCentiles());
            dispatch(clearCandidateTarget());
            dispatch(clearCandidateTargetSpendCategories());
            dispatch(setCandidateTargetStoreCategory(newCandidateTargetStoreCategory));
        }
    };

    return (
        <Box data-cy="store-category" paddingBottom={4}>
            <Box display="flex" alignItems="center" paddingBottom={1}>
                <Typography variant="h5" component="div">
                    What type of store do you want to open?
                </Typography>
                &nbsp;&nbsp;
                <SimpleTooltip
                    placement={SimpleTooltipPlacement.Right}
                    title={
                        <Typography variant="body2" component="div">
                            The type of store underpins several of the metrics within the location tool, as it dictates
                            the size and shape of the catchment area for your proposed location.
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon fontSize="small" color="disabled" cursor="pointer" />
                </SimpleTooltip>
            </Box>
            <StyledTextField
                variant="outlined"
                size="small"
                color="secondary"
                margin="none"
                select
                SelectProps={{
                    MenuProps: {
                        classes: { paper: classes.paper },
                        variant: "menu"
                    }
                }}
                value={candidateTargetStoreCategory?.id ?? ""}
                onChange={handleStoreCategoryChange}
                disabled={isLoading}
            >
                {storeCategories.map(storeCategory =>
                    <MenuItem key={storeCategory.id} value={storeCategory.id}>
                        {`${storeCategory.name}${storeCategory.isPrimary ? " (Primary store category)" : storeCategory.isInPortfolio ? " (Currently in your portfolio)" : ""}`}
                    </MenuItem>
                )}
            </StyledTextField>
        </Box>
    );
};

export default StoreCategory;
