import React from "react";
import _ from "lodash";

import { Typography, Button } from "@material-ui/core";

import SimpleDialog from "components/SimpleDialog";

import Countdown from "./Countdown";

import {
    getUserInfo,
    hideIdle,
    selectIdleVisibility,
    selectLatestActivityTime,
    showIdle
} from "modules/auth/authSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { useAppDispatch, useAppSelector } from "store";

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;
const ONE_HUNDRED_AND_THIRTEEN_MINUTES_IN_MILLISECONDS = 113 * ONE_MINUTE_IN_MILLISECONDS;

const Idle: React.FC = () => {
    const dispatch = useAppDispatch();
    const latestActivityTime = useAppSelector(selectLatestActivityTime);
    const idleVisibility = useAppSelector(selectIdleVisibility);

    const handleStayLoggedInClick = () => {
        dispatch(getUserInfo());
        dispatch(setupCube());
    };

    const showIdleDelayed = React.useMemo(() => {
        return _.debounce(() => dispatch(showIdle()), ONE_HUNDRED_AND_THIRTEEN_MINUTES_IN_MILLISECONDS);
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(hideIdle());
        showIdleDelayed();
        return () => showIdleDelayed.cancel();
    }, [dispatch, latestActivityTime, showIdleDelayed]);

    return (
        <SimpleDialog
            open={idleVisibility.isVisible}
            enableClose={false}
            maxWidth="sm"
            title={
                <>
                    <Typography variant="h3" component="div" gutterBottom>
                        You will be logged out of Dash in
                    </Typography>
                    <Countdown />
                </>
            }
            titleAlign="center"
            content={
                <Typography variant="h4" component="div">
                    For security, your connection times out when you've been inactive for a while
                </Typography>
            }
            contentAlign="center"
            actions={
                <>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        disableElevation
                        data-cy="btn-stay-logged-in"
                        onClick={handleStayLoggedInClick}
                    >
                        Stay logged in
                    </Button>
                </>
            }
            actionsJustifyContent="center"
        />
    );
};

export default Idle;
