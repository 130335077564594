import { createSelector } from "reselect";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { median } from "mathjs";
import mathUtils from "utils/mathUtils";

const householdsSelector = (state) => {
    return state.customer.insights.portfolio.competition.marketShare.numberOfHouseholds;
};

const revenueSelector = (state) => {
    return state.customer.insights.portfolio.competition.marketShare.marketShareOverTime;
};

const marketShareOverTime = createSelector(
    revenueSelector,
    (revenueSelector) => {
        const selector = {
            loading: revenueSelector.loading,
            error: revenueSelector.error,
            store: {},
            comparator: {}
        };
        if (selector.loading || selector.error || revenueSelector.store.length === 0) {
            return selector;
        }

        const selectedStoreRevenueData = revenueSelector.store;
        const comparatorRevenueData = revenueSelector.comparator;

        const selectedStoreMarketShare = selectedStoreRevenueData.map(item => ({
            marketShare: item.monthlyMarketShare * 100,
            date: item.date
        }));

        selector.store = {
            storeCatchmentArea: selectedStoreMarketShare.map(item => item.marketShare),
            dates: selectedStoreMarketShare.map(item => item.date)
        };

        const comparatorMarketShare = [];

        for (let i in selectedStoreMarketShare) {
            const relevantComparatorRevenueData = comparatorRevenueData.filter(item => item.date === selectedStoreMarketShare[i].date);

            const relevantComparatorRevenueValues = relevantComparatorRevenueData.map(item => item.monthlyMarketShare * 100);
            const medianCompMarketShare = median(relevantComparatorRevenueValues);
            comparatorMarketShare.push({
                marketShare: medianCompMarketShare,
                date: selectedStoreMarketShare[i].date
            });
        }

        selector.comparator = {
            comparatorCatchmentArea: comparatorMarketShare.map(item => item.marketShare),
            dates: comparatorMarketShare.map(item => item.date)
        };

        return selector;
    });

const localMarketShare = createSelector(
    revenueSelector,
    (state) => selectComparator(state),
    (revenueSelector, comparatorStoresSelector) => {
        const kpi = {
            loading: revenueSelector.loading,
            error: revenueSelector.error,
            value: 0,
            percentageDifference: 0
        };
        if (kpi.loading || kpi.error || revenueSelector.store.length === 0) {
            return kpi;
        }

        const selectedStoreRevenueData = revenueSelector.store;
        const selectedStoreSales = selectedStoreRevenueData.reduce((total, item) => item.weightedSalesValue + total, 0);
        const marketSpend = selectedStoreRevenueData.reduce((total, item) => item.marketSpend + total, 0);
        const selectedStoreMarketShare = mathUtils.safePercentage(selectedStoreSales, marketSpend);

        kpi.value = selectedStoreMarketShare;

        const comparatorStores = comparatorStoresSelector.getStores();
        const comparatorRevenueData = revenueSelector.comparator;
        let comparatorSales = 0;
        let comparatorSpend = 0;

        for (let i in comparatorStores) {
            const relevantRevenueData = comparatorRevenueData.filter(item =>
                (item.storeID === comparatorStores[i].id));
            comparatorSales += relevantRevenueData.reduce((total, item) => item.weightedSalesValue + total, 0);
            comparatorSpend += relevantRevenueData.reduce((total, item) => item.marketSpend + total, 0);
        }
        const comparatorValue = mathUtils.safePercentage(comparatorSales, comparatorSpend);

        kpi.percentageDifference = mathUtils.safePercentageChange(kpi.value, comparatorValue);

        return kpi;
    });

const numberOfHouseholds = createSelector(
    householdsSelector,
    (householdsSelector) => {
        const kpi = {
            loading: householdsSelector.loading,
            error: householdsSelector.error,
            value: 0,
            percentageDifference: 0
        };
        if (kpi.loading || kpi.error || householdsSelector.store === 0) {
            return kpi;
        }

        const comparatorHouseholds = householdsSelector.comparator.map(item => item.households);
        const comparatorValue = median(comparatorHouseholds);

        kpi.value = householdsSelector.store;
        kpi.percentageDifference = (comparatorValue === 0) ? 100 : 100 * ((kpi.value - comparatorValue) / comparatorValue);

        return kpi;
    });

const revenuePerHousehold = createSelector(
    revenueSelector,
    householdsSelector,
    (state) => selectComparator(state),
    (revenueSelector, householdsSelector, comparatorStoresSelector) => {
        const kpi = {
            loading: revenueSelector.loading || householdsSelector.loading,
            error: revenueSelector.error || householdsSelector.error,
            value: 0,
            percentageDifference: 0
        };
        if (kpi.loading || kpi.error || revenueSelector.store.length === 0 || householdsSelector.comparator.length === 0) {
            return kpi;
        }

        const selectedStoreHouseholds = householdsSelector.store;
        const selectedStoreRevenueData = revenueSelector.store;
        const selectedStoreRevenue = selectedStoreRevenueData.reduce((total, item) => item.salesValue + total, 0);

        kpi.value = (selectedStoreHouseholds === 0) ? 0 : selectedStoreRevenue / selectedStoreHouseholds;

        const comparatorRevenueData = revenueSelector.comparator;
        const comparatorHouseholdsData = householdsSelector.comparator;

        const comparatorStores = comparatorStoresSelector.getStores();
        const comparatorRevenuePerHouseholds = [];

        for (let i in comparatorStores) {
            const relevantRevenueData = comparatorRevenueData.filter(item =>
                (item.storeID === comparatorStores[i].id));
            const totalStoreSales = relevantRevenueData.reduce((total, item) => item.salesValue + total, 0);
            const relevantHouseholdsData = comparatorHouseholdsData.find(item =>
                (item.retailCentreID === comparatorStores[i].retailCentreID) && (item.storeCategory === comparatorStores[i].kpmgStoreCategory));
            const relevantHouseholdsValue = relevantHouseholdsData?.households ?? 0;
            const revenuePerHousehold = (relevantHouseholdsValue === 0) ? totalStoreSales : totalStoreSales / relevantHouseholdsValue;
            comparatorRevenuePerHouseholds.push(revenuePerHousehold);
        }

        const comparatorValue = median(comparatorRevenuePerHouseholds);

        kpi.percentageDifference = comparatorValue === 0 ? 100 : 100 * ((kpi.value - comparatorValue) / comparatorValue);

        return kpi;
    });

const openingsAndClosures = (state) => {
    return state.customer.insights.portfolio.competition.marketShare.openingsAndClosures;
};

const marketShareTrend = createSelector(
    revenueSelector,
    state => selectStore(state),
    (revenueSelector, selectedStoreSelector) => {
        const rag = {
            loading: revenueSelector.loading,
            error: false,
            id: "market-share-trend",
            label: "Store market share",
            status: "info",
            value: ""
        };

        if (rag.loading || rag.error || revenueSelector.store.length === 0) {
            return rag;
        }

        const selectedStoreRevenueData = revenueSelector.store;

        const monthlyMarketShare = selectedStoreRevenueData.map(item => item.monthlyMarketShare);

        //Placeholders
        const arrayLength = monthlyMarketShare.length;
        const maxIndex = arrayLength - 1;

        const latestPeriod = monthlyMarketShare[maxIndex];
        const comparisonPeriod = monthlyMarketShare[0];
        const numberOfMonths = maxIndex;

        let x = 0;
        //Check numerator & denominator:
        if (comparisonPeriod === 0 && latestPeriod > 0) {
            // Avoid div/0 error
            x = 0.6;
        } else if (comparisonPeriod === latestPeriod) {
            // No growth in this case and handles zeroes
            x = 0;
        } else {
            // Normal case CMGR calculation:
            const cmgrCalculation = ((latestPeriod / comparisonPeriod) ** (1 / numberOfMonths) - 1);
            x = cmgrCalculation * 100;
        }

        //RAG indicator:
        if (x > 0.5) {
            rag.status = "success";
            rag.value = `Your ${selectedStoreSelector.name} store's market share (%) trended upward last year.`;
        } else if (x >= 0) {
            rag.status = "warning";
            rag.value = `Your ${selectedStoreSelector.name} store's market share (%) was stagnant last year.`;
        } else {
            rag.status = "error";
            rag.value = `Your ${selectedStoreSelector.name} store's market share (%) trended downward last year.`;
        }

        return rag;
    });


const selectors = {
    localMarketShare,
    numberOfHouseholds,
    revenuePerHousehold,
    marketShareOverTime,
    openingsAndClosures,
    marketShareTrend
};

export default selectors;
