import types from "./types";

const getSettingsSuccess = (personalInfo, companyDetails, emailNotifications) => ({
    type: types.GET_SETTINGS_SUCCESS,
    payload: {
        personalInfo,
        companyDetails,
        emailNotifications
    }
});

const getSettingsFailure = () => ({
    type: types.GET_SETTINGS_FAILURE
});

const setPersonalInfo = (personalInfo) => ({
    type: types.SET_PERSONAL_INFO,
    payload: {
        personalInfo
    }
});

const showEditPersonalInfo = () => ({
    type: types.SHOW_EDIT_PERSONAL_INFO
});

const hideEditPersonalInfo = () => ({
    type: types.HIDE_EDIT_PERSONAL_INFO
});

const setCompanyDetails = (companyDetails) => ({
    type: types.SET_COMPANY_DETAILS,
    payload: {
        companyDetails
    }
});

const showEditCompanyDetails = () => ({
    type: types.SHOW_EDIT_COMPANY_DETAILS
});

const hideEditCompanyDetails = () => ({
    type: types.HIDE_EDIT_COMPANY_DETAILS
});

const actions = {
    getSettingsSuccess,
    getSettingsFailure,
    setPersonalInfo,
    showEditPersonalInfo,
    hideEditPersonalInfo,
    setCompanyDetails,
    showEditCompanyDetails,
    hideEditCompanyDetails
};

export default actions;
