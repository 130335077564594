import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";

import FootfallByOutputAreaPopup from "./FootfallByOutputAreaPopup";
import StoreMarker from "./StoreMarker";

import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { selectors } from "modules/customer/insights/portfolio/catchment/footfall";

const FootfallByOutputArea = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useSelector(selectMapboxConfiguration);
    const footfallByOutputArea = useSelector(state => selectors.footfallByOutputArea(state));
    const { loading, error, mapCenter, outputAreaData } = footfallByOutputArea;
    const outputAreas = outputAreaData.map(item => ({
        code: item.OAID,
        value: item.normalisedLastYear,
        colourGroup: item.percentile,
        percentageChangeYOY: item.percentageChangeYOY
    }));

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: mapCenter.latitude,
        longitude: mapCenter.longitude,
        zoom: 13.5
    });
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const [clickInfo, setClickInfo] = React.useState(pointerInfoEmpty);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: mapCenter.latitude,
                longitude: mapCenter.longitude
            };
        });
    }, [mapCenter]);

    return (
        <Box data-cy="footfall-by-output-area">
            <OutputAreasMap
                loading={loading}
                error={error}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={outputAreas}
                colours={colourPalette.heatmap}
                onHover={setHoverInfo}
                onClick={setClickInfo}
                downloadData={outputAreaData}
            >
                <FootfallByOutputAreaPopup
                    outputAreas={outputAreas}
                    hoverInfo={hoverInfo}
                    clickInfo={clickInfo}
                />
                <StoreMarker mapCenter={mapCenter}/>
            </OutputAreasMap>
        </Box>
    );
};

export default FootfallByOutputArea;
