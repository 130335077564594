import types from "./types";

const getMarketCategoryOpeningsVsClosuresRequest = () => ({
    type: types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_REQUEST
});

const getMarketCategoryOpeningsVsClosuresSuccess = ( store, comparator ) => ({
    type: types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_SUCCESS,
    payload: {
        store,
        comparator
    }
});

const getMarketCategoryOpeningsVsClosuresFailure = () => ({
    type: types.GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_FAILURE
});

const actions = {
    getMarketCategoryOpeningsVsClosuresRequest,
    getMarketCategoryOpeningsVsClosuresSuccess,
    getMarketCategoryOpeningsVsClosuresFailure
};

export default actions;
