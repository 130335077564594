import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";

interface MapsState {
}

const initialState: MapsState = {};

const mapsSlice = createSlice({
    name: "helpers/maps",
    initialState,
    reducers: {}
});

export const openStreetView = (latitude: number, longitude: number): AppThunk => () => {
    const url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
    window.open(url, "_blank");
};

export default mapsSlice;
