import React from "react";
import { Box } from "@material-ui/core";
import NetworkGraph from "components/visuals/NetworkGraph";

import numberFormatter from "utils/numberFormatter";

const parentData = { productName: 'Cathedral city 550G', pc: 12.2, profit: 1.4 };

const nodesData = [
    { productName: 'Homebrand finest cheese selection 600G', pc: 100, profit: 1.5 },
    { productName: 'Castello tickler white extra mature cheddar 200G', pc: 50, profit: 1.9 },
    { productName: 'Castello tickler white extra mature cheddar 350G', pc: 10, profit: 1.1 },
    { productName: 'Castello tickler white extra mature cheddar 400G', pc: 5, profit: 2.9 },
    { productName: 'Creamfields mature white cheddar large', pc: 1, profit: 666 },
];

const Network = () => {
        
    const parentName = parentData.productName;

    const data = nodesData.map(row => { 
        return { from: parentName, to: row.productName, custom: { pc: row.pc, profit: row.profit }};
    });

    const parentNode = [ { 
        id: parentName,
        name: "",
        color: 'transparent',
        marker: {
            fillColor: '#1A1632',
            lineColor: '#FFFFFF',
            lineWidth: 3,
            radius: 10
        }
    } ];

    const nodes = [...parentNode, ...data.map( row => {
        const topRadius = 70;

        const scalingFactor = topRadius / Math.sqrt(100 / Math.PI);
        const radiusArea = scalingFactor * Math.sqrt(row.custom.pc / Math.PI);

        return { id: row.to,
            name: numberFormatter.toPercentage(row.custom.pc),
            color: 'transparent',
            marker: {
                fillColor: '#1A1632',
                lineColor: '#42E8E0',
                lineWidth: 3,
                radius: radiusArea
            }
        };
    })];

    const options = {

        chart: {
            events: {
                load() { 
                    // this.series[0].nodes[0].fixedPosition = true 
                }
            },
            backgroundColor: 'transparent'
        },        
        plotOptions:{
            series:{
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    },
                    inactive: {
                        enabled: false,
                        opacity: 1
                    }
                },
            },
              networkgraph: {
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        },
                        normal: {
                            animation: false
                        }
                    }
                },
                draggable: false
              }
        },
        tooltip: {
            formatter() {
                let nodeName = this.point.id;
                
                if(nodeName === parentName) {
                    let networkInfo = `<span><b> ${nodeName} </b></span><span style="height: 12px; display: block;"></span></br>
                    <span> Bought together </span><span style="width: 200px; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span><b> 30% </b></span></br>
                    <span> Product profit </span><span style="width: 200px; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="color:#1DE67A"><b> +£1.40  </b></span></br>`; 
                    return networkInfo;
                } else {
                    let networkInfo = `<span><b> ${nodeName} </b></span><span style="height: 12px; display: block;"></span></br>
                    <span> Bought together </span><span style="width: 200px; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span><b> 30% </b></span></br>
                    <span> Product profit </span><span style="width: 200px; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="color:#1DE67A"><b> +£1.40  </b></span></br>`;
                    return networkInfo;
                }
                

            }
            
        },
        series: [{
            link:{
                color: "white"
            },
            marker: {
                lineWidth: 5
            },
            data: data,
            
            dataLabels: {
                enabled: true,
                borderRadius: 0,
                backgroundColor: "transparent",
                padding: 0,
                color: 'white',
                linkFormat: '',
                allowOverlap: false,
                style: {
                    fontSize: "14px",
                    color: "white",
                textOutline: false,
                fontWeight: 'normal',
                y: 50
                },
                formatter() {

                    const point = this.point;
                    if (point.id !== parentName) {
                        return numberFormatter.toPercentage(point.linksTo[0].custom.pc);
                    } else {
                        return parentName;
                    }
                }
            },
            events:{
                afterAnimate: function() {
                    const chart = this.chart;

                    const nodes = chart.series[0].nodes;
                    const parentNode = nodes[0];
                    const otherNodes = nodes.slice(1);

                    const parentPlotY = parentNode.plotY;

                    for (let i = 0; i < otherNodes.length; i++) {
                        let node = otherNodes[i];

                        let plotX = node.plotX;
                        let plotY = node.plotY;

                        chart.addAnnotation({
                            id: "AnnotationNode" + node.id,
                            labelOptions: {
                                borderRadius: 5,
                                backgroundColor: "#4d4a60",
                                padding: 10,
                                color: 'white',
                                borderWidth: 0
                            },
                            labels: [{
                                point: {
                                    x: plotX,
                                    y: plotY
                                },
                                y: (plotY > parentPlotY) ? node.radius + 10 : -node.radius - 10,
                                text: node.id
                            }]
                        });
                    }
                }
            },
            layoutAlgorithm: {
                enableSimulation: false
            },            
            nodes: nodes
            
        }
        ]
    };

    return (
        <Box>
            <NetworkGraph options={options} />
        </Box>
    );
};

export default Network;
