import React from "react";

import { Avatar } from "@material-ui/core";
import { Fullscreen } from "@material-ui/icons";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { FullscreenControl } from "react-map-gl";

const StyledAvatar = withStyles(theme => ({
  root: {
      position: "absolute",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.common.white,
      width: theme.spacing(3.7),
      height: theme.spacing(3.6),
      right: 0,
      top: 0,
      margin: theme.spacing(2),
      zIndex: theme.zIndex.drawer,
      cursor: "pointer",
      pointerEvents: 'none'
  }
}))(Avatar);

const FullscreenButton = () => {
  const theme = useTheme();
  const fullscreenControlStyle = {
    width: theme.spacing(3.7),
    height: theme.spacing(3.6),
    right: 0,
    top: 0,
    margin: theme.spacing(2),
  };

  return (
    <div>
        <StyledAvatar variant="rounded" data-cy="btn-map-fullscreen">
            <Fullscreen fontSize="medium" />
        </StyledAvatar>
        <FullscreenControl style={fullscreenControlStyle}>
        </FullscreenControl>
    </div>
  );
};

export default FullscreenButton;
