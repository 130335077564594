import { combineReducers } from "redux";

import types from "./types";

const salesYTDEmpty = {
    loading: false,
    error: false,
    value: 0,
    label: "Revenue in the year-to-date"
};

const salesYTD = (state = salesYTDEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_YTD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                label: "Revenue in the year-to-date"
            };
        case types.GET_SALES_YTD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                label: "Revenue in the year-to-date"
            };
        case types.GET_SALES_YTD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                label: "Revenue in the year-to-date"
            };
        default:
            return state;
    }
};

const totalSalesLatestFullMonthEmpty = {
    loading: false,
    error: false,
    value: 0,
    currentMonth: "",
    currentYear: ""
};

const totalSalesLatestFullMonth = (state = totalSalesLatestFullMonthEmpty, action) => {
    switch (action.type) {
        case types.GET_TOTAL_SALES_LATEST_FULL_MONTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                currentMonth: "",
                currentYear: ""
            };
        case types.GET_TOTAL_SALES_LATEST_FULL_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                currentMonth: action.payload.currentMonth,
                currentYear: action.payload.currentYear
            };
        case types.GET_TOTAL_SALES_LATEST_FULL_MONTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                currentMonth: "",
                currentYear: ""
            };
        default:
            return state;
    }
};

const avgSalesLatestFullMonthEmpty = {
    loading: false,
    error: false,
    value: 0,
    currentMonth: "",
    currentYear: ""
};

const avgSalesLatestFullMonth = (state = avgSalesLatestFullMonthEmpty, action) => {
    switch (action.type) {
        case types.GET_AVG_SALES_LATEST_FULL_MONTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                value: 0,
                currentMonth: "",
                currentYear: ""
            };
        case types.GET_AVG_SALES_LATEST_FULL_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                value: action.payload.value,
                currentMonth: action.payload.currentMonth,
                currentYear: action.payload.currentYear
            };
        case types.GET_AVG_SALES_LATEST_FULL_MONTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                value: 0,
                currentMonth: "",
                currentYear: ""
            };
        default:
            return state;
    }
};

const historicShortTermCompanySalesTrendEmpty = {
    loading: false,
    error: false,
    id: "historic-short-term-company-sales-trend",
    label: "Historic short term company sales trend",
    status: "info",
    value: ""
};

const historicShortTermCompanySalesTrend = (state = historicShortTermCompanySalesTrendEmpty, action) => {
    switch (action.type) {
        case types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_HISTORIC_SHORT_TERM_COMPANY_SALES_TREND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const salesHistoryEmpty = {
    loading: false,
    error: false,
    values: []
};

const salesHistory = (state = salesHistoryEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_SALES_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values
            };
        case types.GET_SALES_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    salesYTD,
    totalSalesLatestFullMonth,
    avgSalesLatestFullMonth,
    historicShortTermCompanySalesTrend,
    salesHistory
});

export default reducer;
