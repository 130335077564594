const GET_MARKET_CAT_SPEND_PER_HEAD_REQUEST = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CAT_SPEND_PER_HEAD_REQUEST";
const GET_MARKET_CAT_SPEND_PER_HEAD_SUCCESS = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CAT_SPEND_PER_HEAD_SUCCESS";
const GET_MARKET_CAT_SPEND_PER_HEAD_FAILURE = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CAT_SPEND_PER_HEAD_FAILURE";
const GET_MARKET_CATEGORIES_LIST_REQUEST = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CATEGORIES_LIST_REQUEST";
const GET_MARKET_CATEGORIES_LIST_SUCCESS = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CATEGORIES_LIST_SUCCESS";
const GET_MARKET_CATEGORIES_LIST_FAILURE = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/GET_MARKET_CATEGORIES_LIST_FAILURE";
const SELECT_MARKET_CATEGORY = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/SELECT_MARKET_CATEGORY";
const DESELECT_MARKET_CATEGORY = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/DESELECT_MARKET_CATEGORY";
const CLEAR_SELECTED_MARKET_CATEGORIES = "spark/customer/insights/portfolio/areaHealth/marketCategorySpend/CLEAR_SELECTED_MARKET_CATEGORIES";

const types = {
    GET_MARKET_CAT_SPEND_PER_HEAD_REQUEST,
    GET_MARKET_CAT_SPEND_PER_HEAD_SUCCESS,
    GET_MARKET_CAT_SPEND_PER_HEAD_FAILURE,
    GET_MARKET_CATEGORIES_LIST_REQUEST,
    GET_MARKET_CATEGORIES_LIST_SUCCESS,
    GET_MARKET_CATEGORIES_LIST_FAILURE,
    SELECT_MARKET_CATEGORY,
    DESELECT_MARKET_CATEGORY,
    CLEAR_SELECTED_MARKET_CATEGORIES
};

export default types;
