import React from "react";

import { Button, Typography } from "@material-ui/core";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import { withStyles } from "@material-ui/core/styles";

import TwoPanelDialog from "components/TwoPanelDialog";

import DragAndDrop from "./DragAndDrop";
import UploadInfo from "./UploadInfo";
import CloseUploadConfirmation from "./CloseUploadConfirmation";

import { useAppDispatch, useAppSelector } from "store";
import {
    Dataset,
    DatasetStatus,
    DatasetType,
    DataRefreshStatus,
    downloadDataGuidance,
    downloadTemplate,
    hideUpload,
    selectDataRefresh,
    selectUploadInfo,
    selectUploadVisibility,
    showUpload,
    showCloseUploadConfirmation,
    submitForProcessing
} from "modules/customer/data/dataSlice";

const StyledUploadButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(Button);

const StyledDownloadButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginTop: theme.spacing(1)
    }
}))(Button);

interface UploadProps {
    dataset: Dataset
}

const Upload: React.FC<UploadProps> = (props) => {
    const { dataset } = props;
    const dispatch = useAppDispatch();
    const uploadVisibility = useAppSelector(selectUploadVisibility);
    const show = (uploadVisibility.isVisible) && (dataset.id === uploadVisibility.datasetId);
    const uploadInfo = useAppSelector(selectUploadInfo);
    const uploadInProgress = uploadInfo.fileName !== "";
    const dataRefresh = useAppSelector(selectDataRefresh);
    const disabled = dataRefresh.status === DataRefreshStatus.InProgress
        || dataRefresh.status === DataRefreshStatus.Paused
        || dataset.status === DatasetStatus.Submitted
        || dataset.status === DatasetStatus.Processing;

    const handleUploadClick = () => {
        dispatch(showUpload(dataset.id));
    };

    const handleClose = () => {
        if (uploadInProgress) {
            dispatch(showCloseUploadConfirmation(dataset.id));
        } else {
            dispatch(hideUpload());
        }
    };

    const handleDownloadTemplateClick = (datasetId: string) => () => {
        dispatch(downloadTemplate(datasetId));
    };

    const handleReadDataGuidanceClick = () => {
        dispatch(downloadDataGuidance());
    };

    const handleSubmitForProcessingClick = () => {
        dispatch(submitForProcessing());
    };

    return (
        <>
            <StyledUploadButton
                variant="contained"
                size="small"
                color="primary"
                disableElevation
                disabled={disabled}
                onClick={handleUploadClick}
            >
                Upload
            </StyledUploadButton>
            <TwoPanelDialog
                open={show}
                onClose={handleClose}
                leftPanelTitle={
                    <Typography variant="h5" component="div" gutterBottom>
                        {dataset.name} data upload
                    </Typography>
                }
                leftPanelContent={
                    <>
                        <Typography variant="subtitle1" component="div">
                            {dataset.type === DatasetType.Csv
                                ? `Provide your ${dataset.name} data in the form of a .csv file following the same format used in our data example.`
                                : `Provide your ${dataset.name} data by populating a ${dataset.name} template file and uploading it here.`
                            }
                        </Typography>
                        <br />
                        <Typography variant="subtitle1" component="div" color="textSecondary">
                            Before you upload
                        </Typography>
                        <StyledDownloadButton
                            variant="text"
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={handleDownloadTemplateClick(dataset.id)}
                            startIcon={<SaveAltRoundedIcon />}
                        >
                            {dataset.type === DatasetType.Csv ? "Download data example" : "Download template"}
                        </StyledDownloadButton>
                        <StyledDownloadButton
                            variant="text"
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={handleReadDataGuidanceClick}
                            startIcon={<OpenInNewRoundedIcon />}
                        >
                            Read data guidance
                        </StyledDownloadButton>
                    </>
                }
                rightPanelContent={
                    <>
                        <DragAndDrop dataset={dataset} />
                        <br />
                        <UploadInfo datasetType={dataset.type} />
                    </>
                }
                rightPanelActions={
                    <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        disableElevation
                        disabled={!uploadInfo.isComplete || !uploadInfo.isSuccess}
                        onClick={handleSubmitForProcessingClick}
                    >
                        Submit for processing
                    </Button>
                }
            />
            <CloseUploadConfirmation dataset={dataset} />
        </>
    );
};

export default Upload;
