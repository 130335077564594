import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/competition/marketShare";

const MarketShareTrend = () => {
    const marketShareTrend = useSelector(state => selectors.marketShareTrend(state));
    const { loading, error, id, label, status, value } = marketShareTrend;

    return (
        <Box data-cy={`${id}-rag-indicator`}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default MarketShareTrend;
