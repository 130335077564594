import React from "react";
import {
    Backdrop as MuiBackdrop,
    CircularProgress
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import { selectIsBackdropOn } from "modules/backdrop/backdropSlice";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: Number.MAX_SAFE_INTEGER,
        color: theme.palette.common.white
    }
}));

const Backdrop = () => {
    const classes = useStyles();
    const isBackdropOn = useSelector(selectIsBackdropOn);
    return (
        <MuiBackdrop className={classes.backdrop} open={isBackdropOn}>
            <CircularProgress color="inherit" />
        </MuiBackdrop>
    );
};

export default Backdrop;
