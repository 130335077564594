import React from "react";
import Highcharts from "highcharts";
import { Typography } from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import numberFormatter from "utils/numberFormatter";
// import stringUtils from "utils/stringUtils";
import HighchartsCustomTooltip from "components/visuals/HighchartsCustomTooltip";
import useTooltipHandler from "components/visuals/useTooltipHandler";

interface RadarChartProps {
    salesScore: number,
    driversScore: number,
    profitScore: number,
    areaHealthScore: number,
    competitionScore: number,
    catchmentScore: number,
    totalScore: number
}

const RadarChart: React.FC<RadarChartProps> = (props) => {
    const {
        salesScore,
        driversScore,
        profitScore,
        areaHealthScore,
        competitionScore,
        catchmentScore,
        totalScore
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const colourGroup = Math.floor(colourPalette.heatmap.length * totalScore / 30);
    const colourIndex = Math.min(colourGroup, colourPalette.heatmap.length - 1);
    const colour = colourPalette.heatmap[colourIndex];
    
    const { tooltipData, handleTooltip, handleMouseOut } = useTooltipHandler();
    const contentRenderer = (point: Highcharts.Point): React.ReactNode => (
        <table>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <Typography variant="subtitle2" style={{ color: colour }}>Relative performance</Typography>
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="body2">{point.name}</Typography></td>
                    <td>
                        <Typography variant="body1" align="right">
                            {numberFormatter.toDecimalPlaces(point.options?.custom?.tooltipValue, 1)}
                        </Typography>
                    </td>
                </tr>
            </tbody>
        </table>
    );
    const content = tooltipData && contentRenderer(tooltipData.point);

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            height: theme.spacing(30),
            backgroundColor: 'transparent'
        },
        // @ts-ignore
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0,
            labels: {
                useHTML: true,
                style: {
                    width: "70px",
                    whiteSpace: "normal"
                },
                formatter: function () {
                    let returnStr = "";
                    const label = String(this.value);
                    if (label.length < 9) {
                        returnStr = label;
                    } else {
                        returnStr = "<div style=\"text-align: center\">" + label?.replace(/(.{5})/, "$1<br>") + "</div>";
                    }
                    return returnStr;
                }
            }
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        plotOptions: {  
            series: {
                point: {
                    events: {
                        mouseOver: handleTooltip,
                        mouseOut: handleMouseOut
                    }
                }
            }
        },
        tooltip: {
            enabled: false
        },
        series: [{
            name: "Score",
            type: "area",
            color: colour,
            data: [{
                name: "Sales",
                y: salesScore
            }, {
                name: "Drivers",
                y: driversScore
            }, {
                name: "Profit",
                y: profitScore
            }, {
                name: "Area health",
                y: areaHealthScore
            }, {
                name: "Competition",
                y: competitionScore
            }, {
                name: "Catchment",
                y: catchmentScore
            }],
            pointPlacement: "on"
        }],
        exporting: {
            enabled: false
        }
    }), [theme, salesScore, driversScore, profitScore, areaHealthScore, competitionScore, catchmentScore, colour, handleTooltip, handleMouseOut]);

    return (
        <>
            {tooltipData && <HighchartsCustomTooltip point={tooltipData.point} content={content} event={tooltipData.event} />}
            <Radar loading={false} error={false} options={options} dataAdditionPercent={10}/>
        </>
    );
};

export default RadarChart;
