import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Get a feel for the customers in your selected location's catchment area and understand how they spend. Drill down from customer profile supergroups to groups and
            subgroups to see how spending breaks down to a more granular level. Investigate whether the people that spend the most on your type of products align with your target
            demography.
        </>
    );
};

export default Subtitle;
