import React from "react";
import { Grid } from "@material-ui/core";

import TypesOfSalesGrowth from "./TypesOfSalesGrowth";
import OrganicVsInorganicSalesGrowth from "./OrganicVsInorganicSalesGrowth";

const RevenueGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TypesOfSalesGrowth />
            </Grid>
            <Grid item xs={12}>
                <OrganicVsInorganicSalesGrowth />
            </Grid>
        </Grid>
    );
};

export default RevenueGrowth;
