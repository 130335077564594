import React from "react";

import { Grid } from "@material-ui/core";

import CustomerProfilesDefinitions from "./CustomerProfilesDefinitions";
import CustomerProfilesTreemap from "./CustomerProfilesTreemap";

const CustomerProfiles: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CustomerProfilesTreemap />
            </Grid>
            <Grid item xs={12}>
                <CustomerProfilesDefinitions />
            </Grid>
        </Grid>
    );
};

export default CustomerProfiles;
