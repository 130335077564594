import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Card, CardActions, CardContent, Divider, Grid, TextField, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleTooltip from "components/SimpleTooltip";

import { operations, selectors } from "modules/customer/account/settings";

const StyledCard = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(() => ({
    root: {
        paddingBottom: 0
    }
}))(CardContent);

const StyledCardActions = withStyles(() => ({
    root: {
        paddingTop: 0,
        justifyContent: "flex-end"
    }
}))(CardActions);

const StyledEditButton = withStyles(theme => ({
    root: {
        color: theme.palette.tertiary.main
    }
}))(Button);

const StyledSaveButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: theme.palette.quaternary.main
    }
}));

const CompanyDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const companyDetails = useSelector(state => selectors.companyDetails(state));
    const editCompanyDetailsVisibility = useSelector(state => selectors.editCompanyDetailsVisibility(state));

    const handleEditClick = () => {
        dispatch(operations.showEditCompanyDetails());
    };

    const handleSaveClick = () => {
        dispatch(operations.editCompanyDetails());
    };

    const handleCancelClick = () => {
        dispatch(operations.hideEditCompanyDetails());
    };

    const handleDisplayNameChange = (event) => {
        const displayName = event.target.value;
        const newCompanyDetails = { ...companyDetails, displayName, errors: { ...displayName.errors, displayName: "" } };
        dispatch(operations.setCompanyDetails(newCompanyDetails));
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-account-settings-company-details">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Company details
                </Typography>
            </Box>
            <StyledCard elevation={0}>
                <StyledCardContent>
                    <Typography variant="h4" component="div">
                        Company name
                    </Typography>
                    <Box color="text.disabled" paddingTop={1}>
                        <Typography variant="subtitle1" component="div">
                            {companyDetails.name}
                        </Typography>
                    </Box>
                    <Box paddingY={2}>
                        <Divider />
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h4" component="div">
                            Display name
                        </Typography>
                        &nbsp;&nbsp;
                        <SimpleTooltip
                            placement="right"
                            title={
                                <Typography variant="body1" component="div">
                                    Changes to the display name will update the company name shown on Insights and Tools for all users
                                </Typography>
                            }
                        >
                            <InfoOutlinedIcon color="disabled" cursor="pointer" />
                        </SimpleTooltip>
                    </Box>
                    <Box color="text.disabled" paddingTop={1}>
                        {editCompanyDetailsVisibility.isVisible ? (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Display name"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        margin="dense"
                                        value={companyDetails.displayName}
                                        onChange={handleDisplayNameChange}
                                        error={!!companyDetails.errors.displayName}
                                        helperText={companyDetails.errors.displayName}
                                        fullWidth
                                        InputProps={{ classes: { input: classes.input } }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography variant="subtitle1" component="div" gutterBottom>
                                {companyDetails.displayName}
                            </Typography>
                        )}
                    </Box>
                </StyledCardContent>
                <StyledCardActions>
                    {editCompanyDetailsVisibility.isVisible ? (
                        <>
                            <Button
                                variant="text"
                                size="medium"
                                color="default"
                                disableElevation
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                            <StyledSaveButton
                                variant="contained"
                                size="small"
                                color="secondary"
                                disableElevation
                                onClick={handleSaveClick}
                            >
                                Save
                            </StyledSaveButton>
                        </>
                    ) : (
                        <StyledEditButton
                            variant="text"
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={handleEditClick}
                        >
                            Edit
                        </StyledEditButton>
                    )}
                </StyledCardActions>
            </StyledCard>
        </Box>
    );
};

export default CompanyDetails;
