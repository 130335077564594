import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import useColourPalette from "components/visuals/useColourPalette";
import ComparisonBar from "components/visuals/ComparisonBar";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/competitionScore";

const CompetitionScoreBulletChart = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const competitionScoreVis = useSelector(state => selectors.competitionScoreVis(state));
    const { loading, error, selectedStoreCompScore, comparatorMedianCompScore } = competitionScoreVis;

    const store = selectedStoreCompScore;
    const colours = useColourPalette().comparators;
    const options = {
        yAxis: [{
            plotBands: [{
                label: {
                    text: "Struggling to compete"
                },
                from: 0,
                to: 100/3
            }, {
                label: {
                    text: "Competing"
                },
                from: 100/3,
                to: 2*100/3
            }, {
                label: {
                    text: "Clear competitive advantage"
                },
                from: 2*100/3,
                to: 100
            }],
            min: -10,
            max: 110,
            tickInterval: 10,
            gridLineWidth: 0,
            labels: {
                formatter: function () {
                    let tickInterval = "" + this.axis.tickInterval;
                    if (tickInterval.indexOf(".") > 0) {
                        let tickIntervalDecimals = tickInterval.length - 1 - tickInterval.indexOf(".");
                        return this.value.toFixed(tickIntervalDecimals);
                    }
                    return this.value;
                }
            }
        }],

        xAxis: {
            categories: [selectedStore.name, selectedComparator.name]
        },

        tooltip: {
            enabled: true,
            pointFormatter: function () {
                return `<table>${stringUtils.tooltipHTML(['Competition score: '], {values: [this.y], header: this.series.name, headerFormatting: `color:${this.color}`})}</table>`;
            },
            useHTML: true
        },

        series: [{
            name: selectedStore.name,
            data: [store],
            color: colours[0]
        }, {
            name: selectedComparator.name,
            data: [comparatorMedianCompScore],
            color: colours[1],
        }],

        plotOptions: {
            series: {
                dataLabels: {
                    formatter: function () {return `Competition score: ${numberFormatter.toDecimalPlaces(this.y, 1)}`;},
                    align: 'left',
                    inside: false,
                    x: 0,
                    style: {
                        textShadow: false,
                        textOutline: false
                    }
                }
            }
        }
    };

    return (
        <Box data-cy="competition-score-bullet-chart">
            <ComparisonBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CompetitionScoreBulletChart;
