import React from "react";

const BudgetSubtitle: React.FC = () => {
    return (
        <>
            Are you on course to meet your revenue and contribution budgets? Evaluate your progress and likely future
            performance.
        </>
    );
};

export default BudgetSubtitle;
