import React from "react";

const RevenueVsBudgetSubtitle: React.FC = () => {
    return (
        <>
            How is your revenue performing against budget? Are you on track, or do you need to rally in the rest of the
            year?
        </>
    );
};

export default RevenueVsBudgetSubtitle;
