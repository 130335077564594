import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/areaHealth/openingsAndClosures";

const LongTermNetOpenings = () => {
    const longTermNetOpenings = useSelector(state => selectors.longTermNetOpenings(state));
    const { loading, error, id, label, status, value } = longTermNetOpenings;

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default LongTermNetOpenings;
