import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Column from "components/visuals/Column";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/productCategoryGrowth";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const HistoricProdGrowthStoreVsComp = () => {
  const colourPalette = useColourPalette();
  const selectedStore = useAppSelector(selectStore);
  const selectedComparator = useAppSelector(selectComparator);
  const historicProdGrowthStoreVsComp = useSelector(state => selectors.historicProdGrowthStoreVsComp(state));
  const { loading, error, values } = historicProdGrowthStoreVsComp;

  const options = {
    xAxis: {
      type: 'category'
  },
    title: {
      text: `Product category year-on-year growth (%) in ${selectedStore.name} vs ${selectedComparator.name}`
    },
    yAxis: {
      title: {
        text: "Growth"
      },
      labels: {
        format: '{text}%'
      }
    },
    plotOptions: {
      series: {
          dataLabels: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: true
  },
    tooltip: {
      enabled: true,
      shared: true,
      
      formatter: function () {
        const point = this.points;
        
        const categoryArr = [point[0].series.name,point[1].series.name];
        const categoryFormatArr = [`color:${point[0].series.color};font-weight:bold`,`color:${point[1].series.color};font-weight:bold`];
        const valuesArr = [numberFormatter.toPercentage(point[0].y),numberFormatter.toPercentage(point[1].y)];
        
        return `<table>${
            stringUtils.tooltipHTML(categoryArr, {
                header: point[0].key,
                values: valuesArr,
                categoryFormattingArr: categoryFormatArr
            })
        }</table>`;
      },
      useHTML: true
    },
    series: [{
      name: selectedStore.name,
      data: values.map(row => {
        return {
          name: row.productCategory, 
          y: row.selectedStoreGrowth
        };
      }),
      color : colourPalette.comparators[0]

  }, {
      name: selectedComparator.name,
      data: values.map(row => { 
        return {
          name: row.productCategory,
          y: row.medianComparatorGrowth
        };
      }),
      color: colourPalette.comparators[1]
  }]
  };

  return (
    <Box data-cy="historic-prod-growth-store-vs-comp">
      <Column loading={loading} error={error} options={options} />
    </Box>
  );
};

export default HistoricProdGrowthStoreVsComp;
