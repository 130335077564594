import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { selectNotifications, removeNotification } from "modules/notifications/notificationsSlice";

let displayed = [];

const Notifications = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const notifications = useSelector(selectNotifications);

    const storeDisplayed = (key) => {
        displayed = [...displayed, key];
    };

    const removeDisplayed = (key) => {
        displayed = displayed.filter(item => item !== key);
    };

    React.useEffect(() => {
        notifications.forEach(notification => {
            if (displayed.includes(notification.key)) {
                return;
            }

            enqueueSnackbar(notification.text, {
                key: notification.key,
                variant: notification.variant,
                onExited: (event, key) => {
                    dispatch(removeNotification(notification));
                    removeDisplayed(key);
                }
            });

            storeDisplayed(notification.key);
        });
    }, [notifications, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifications;
