import React from "react";

import { Grid, GridSize, Slide } from "@material-ui/core";

import Insight from "./insight/Insight";
import Map from "./map/Map";

import { clearFilters, showFilters } from "modules/customer/tools/location/filters/filtersSlice";
import {
    choosePinnedLocation,
    clearInsights,
    clearLocation,
    clearPinnedLocation,
    loadAddress,
    loadAllRetailCentres,
    loadLocation,
    selectIsInsightExpanded,
    selectIsInsightVisible,
    selectRetailCentresWithScoreGroups,
    selectTarget,
    selectTargetSpendCategories,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { useAppDispatch, useAppSelector } from "store";

interface GridXs {
    insight: GridSize,
    map: GridSize
}

const Location: React.FC = () => {
    const dispatch = useAppDispatch();
    const targetStoreCategory = useAppSelector(selectTargetStoreCategory);
    const targetSpendCategories = useAppSelector(selectTargetSpendCategories);
    const target = useAppSelector(selectTarget);
    const retailCentres = useAppSelector(selectRetailCentresWithScoreGroups);
    const isInsightVisible = useAppSelector(selectIsInsightVisible);
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);

    const gridXs: GridXs = isInsightExpanded ? { insight: 8, map: 4 } : { insight: 4, map: 8 };
    if (!isInsightVisible) {
        gridXs.map = 12;
    }

    const handleEndToEndChooseRetailCentreClick = async () => {
        // for end-to-end testing purposes only
        const retailCentre = retailCentres.find(rc => rc.code === "RC_EW_1956");
        if (retailCentre) {
            const address = await dispatch(loadAddress(retailCentre.latitude, retailCentre.longitude));
            const pinnedLocation = new PinnedLocation(retailCentre.latitude, retailCentre.longitude, address, retailCentre);
            dispatch(choosePinnedLocation(pinnedLocation));
        }
    };

    React.useEffect(() => {
        dispatch(loadLocation());
        dispatch(showFilters());
        return () => {
            dispatch(clearLocation());
            dispatch(clearFilters());
        };
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(clearPinnedLocation());
        dispatch(clearInsights());
        if (targetStoreCategory && targetSpendCategories.length > 0 && target) {
            dispatch(loadAllRetailCentres());
        }
    }, [dispatch, targetStoreCategory, targetSpendCategories, target]);

    return (
        <Grid container data-cy="pages-customer-tools-location">
            <Slide direction="right" in={isInsightVisible} mountOnEnter unmountOnExit>
                <Grid item xs={gridXs.insight}>
                    <Insight />
                </Grid>
            </Slide>
            <Grid item xs={gridXs.map}>
                <Map />
            </Grid>
            {/* for end-to-end testing purposes only */}
            <button
                style={{ display: "none" }}
                onClick={handleEndToEndChooseRetailCentreClick}
                data-cy="end-to-end-choose-retail-centre"
            />
        </Grid>
    );
};

export default Location;
