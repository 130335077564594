import React from "react";

import { Box, Checkbox, Grid, ListItemText, MenuItem, TextField, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import FormLabel from "./FormLabel";

import { selectClientRegistration } from "modules/customer/tools/location/locationSlice";
import {
    clearCandidateStoreParametersForm,
    selectCandidatePredictionForm,
    selectDefaultStoreFeatures,
    selectGroups,
    selectModels,
    selectStores,
    setCandidatePredictionForm
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(TextField);

const StyledMultiSelectTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        },
        "& .MuiSelect-root": {
            textAlign: "left"
        }
    },
}))(TextField);

const useStyles = makeStyles(theme => ({
    paper: {
        maxHeight: "70vh",
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

const SetupPrediction: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const clientRegistration = useAppSelector(selectClientRegistration);
    const hasCustomCatchments = clientRegistration?.hasCustomCatchments ?? false;
    const candidatePredictionForm = useAppSelector(selectCandidatePredictionForm);
    const defaultStoreFeatures = useAppSelector(selectDefaultStoreFeatures);
    const groups = useAppSelector(selectGroups);
    const models = useAppSelector(selectModels);
    const stores = useAppSelector(selectStores);

    const handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const model = value ? candidatePredictionForm.model : "";
        const newCandidatePredictionForm = { ...candidatePredictionForm, group: value, model };
        dispatch(setCandidatePredictionForm(newCandidatePredictionForm));
    };

    const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newCandidatePredictionForm = { ...candidatePredictionForm, model: value };
        dispatch(setCandidatePredictionForm(newCandidatePredictionForm));
        dispatch(clearCandidateStoreParametersForm());
    };

    const handleSalesAreaInSqftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = "^([1-9][0-9]*)*$";
        if (!value.match(regex)) {
            return;
        }
        const newCandidatePredictionForm = { ...candidatePredictionForm, salesAreaInSqft: value };
        dispatch(setCandidatePredictionForm(newCandidatePredictionForm));
    };

    const handleSalesAreaInSqftBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value) {
            const newCandidatePredictionForm = { ...candidatePredictionForm, salesAreaInSqft: "1" };
            dispatch(setCandidatePredictionForm(newCandidatePredictionForm));
        }
    };

    const handleClosingStoresChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const ids = event.target.value as string[];
        const newCandidatePredictionForm = { ...candidatePredictionForm, closingStoresIds: ids };
        dispatch(setCandidatePredictionForm(newCandidatePredictionForm));
    };

    return (
        <Box paddingTop={4} paddingBottom={4}>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <FormLabel
                        text="Group"
                        tooltip="Which group of stores will the store you're opening belong to?"
                    />
                </Grid>
                <Grid item xs={7}>
                    <StyledTextField
                        variant="outlined"
                        size="small"
                        color="secondary"
                        margin="none"
                        select
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                variant: "menu"
                            }
                        }}
                        value={candidatePredictionForm.group}
                        onChange={handleGroupChange}
                        fullWidth
                    >
                        <MenuItem value="">
                            Select a group
                        </MenuItem>
                        {groups.map(group =>
                            <MenuItem key={`group_${group}`} value={group}>
                                {group}
                            </MenuItem>
                        )}
                    </StyledTextField>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel
                        text="Model"
                        tooltip="The revenue prediction learns from your existing stores. Which group of stores would you like your revenue prediction based on?"
                    />
                </Grid>
                <Grid item xs={7}>
                    <StyledTextField
                        variant="outlined"
                        size="small"
                        color="secondary"
                        margin="none"
                        select
                        SelectProps={{
                            displayEmpty: true,
                            MenuProps: {
                                variant: "menu"
                            }
                        }}
                        value={candidatePredictionForm.model}
                        onChange={handleModelChange}
                        disabled={!candidatePredictionForm.group}
                        fullWidth
                    >
                        <MenuItem value="">
                            Select a model
                        </MenuItem>
                        <MenuItem value="All stores">
                            All stores
                        </MenuItem>
                        {groups.length > 1 && (models.map(model =>
                            <MenuItem key={`model_${model}`} value={model}>
                                {model}
                            </MenuItem>
                        ))}
                    </StyledTextField>
                </Grid>
                <Grid item xs={4}>
                    <FormLabel
                        text="Sales area size (square feet)"
                        tooltip="How large (square feet) is the sales area for your proposed store?"
                    />
                </Grid>
                <Grid item xs={7}>
                    <StyledTextField
                        variant="outlined"
                        size="small"
                        color="secondary"
                        margin="none"
                        value={candidatePredictionForm.salesAreaInSqft}
                        onChange={handleSalesAreaInSqftChange}
                        onBlur={handleSalesAreaInSqftBlur}
                        required
                        fullWidth
                    />
                    {Number(candidatePredictionForm.salesAreaInSqft) < (defaultStoreFeatures?.minSalesAreaInSqft ?? 0) && (
                        <Box display="flex" paddingTop={1} color="warning.main">
                            <ErrorOutlineIcon fontSize="small" />
                            &nbsp;&nbsp;
                            <Typography variant="subtitle2" component="div">
                                The store size you have input is smaller than your smallest store size. As a result, the
                                revenue prediction may be less accurate as there are no stores from your existing
                                portfolio on which to base this prediction.
                            </Typography>
                        </Box>
                    )}
                    {Number(candidatePredictionForm.salesAreaInSqft) > (defaultStoreFeatures?.maxSalesAreaInSqft ?? 0) && (
                        <Box display="flex" paddingTop={1} color="warning.main">
                            <ErrorOutlineIcon fontSize="small" />
                            &nbsp;&nbsp;
                            <Typography variant="subtitle2" component="div">
                                The store size you have input is bigger than your biggest store size. As a result, the
                                revenue prediction may be less accurate as there are no stores from your existing
                                portfolio on which to base this prediction.
                            </Typography>
                        </Box>
                    )}
                </Grid>
                {hasCustomCatchments && (
                    <>
                        <Grid item xs={4}>
                            <FormLabel
                                text="Closing stores"
                                tooltip="Closing stores, up to 3."
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <StyledMultiSelectTextField
                                variant="outlined"
                                size="small"
                                color="secondary"
                                margin="none"
                                select
                                SelectProps={{
                                    multiple: true,
                                    MenuProps: {
                                        variant: "menu",
                                        classes: { paper: classes.paper }
                                    },
                                    renderValue: (value) => {
                                        const ids = value as string[];
                                        const names = stores
                                            .filter(store => ids.includes(store.id))
                                            .map(store => store.name)
                                            .join(", ");
                                        return `${ids.length} selected (${names})`;
                                    }
                                }}
                                value={candidatePredictionForm.closingStoresIds}
                                onChange={handleClosingStoresChange}
                                fullWidth
                            >
                                {stores.map((store) => (
                                    <MenuItem
                                        key={store.id}
                                        value={store.id}
                                        disabled={candidatePredictionForm.closingStoresIds.length >= 3 && !candidatePredictionForm.closingStoresIds.includes(store.id)}
                                    >
                                        <Checkbox checked={candidatePredictionForm.closingStoresIds.includes(store.id)} />
                                        <ListItemText primary={store.name} />
                                    </MenuItem>
                                ))}
                            </StyledMultiSelectTextField>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default SetupPrediction;
