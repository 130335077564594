import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/storeSize";

const StoreContributionCategorisation = () => {
    const storeContributionCategorisation = useSelector(state => selectors.storeContributionCategorisation(state));
    const { loading, error, id, label, status, value } = storeContributionCategorisation;

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default StoreContributionCategorisation;
