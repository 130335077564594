import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const HouseholdTenure: React.FC = () => {
    const title = "Household tenure (Affluence)";
    const categories = [
        "Social rented",
        "Living rent free",
        "Rented private",
        "Shared ownership",
        "Owned with mortgage",
        "Owned outright"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.householdTenure;
    const retailCentreValues = retailCentre ? [
        retailCentre.socialRent,
        retailCentre.livingRentFree,
        retailCentre.privateRent,
        retailCentre.sharedOwnership,
        retailCentre.ownedWithLoan,
        retailCentre.ownedOutright
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.householdTenure;
    const benchmarkValues = benchmark ? [
        benchmark.socialRent,
        benchmark.livingRentFree,
        benchmark.privateRent,
        benchmark.sharedOwnership,
        benchmark.ownedWithLoan,
        benchmark.ownedOutright
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default HouseholdTenure;
