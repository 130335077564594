import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Progress from "components/visuals/Progress";
import Error from "components/visuals/Error";

import {
    selectHasErrors,
    selectIsLoading,
    selectPredictionHasErrors,
    selectPredictionIsLoading,
    selectRevenuePrediction
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

function calculateOriginalRevenue(percentageChange: number, revisedRevenue: number): number {
    if (percentageChange === 0) {
        return revisedRevenue;
    }

    const originalValue = revisedRevenue / (1 + (percentageChange / 100));

    return originalValue;
}

const Deflections: React.FC = () => {
    const overviewHasErrors = useAppSelector(selectHasErrors);
    const overviewIsLoading = useAppSelector(selectIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const isLoading = overviewIsLoading || predictionIsLoading;
    const hasErrors = overviewHasErrors || predictionHasErrors;
    const revenuePrediction = useAppSelector(selectRevenuePrediction);
    const deflections = revenuePrediction?.deflections ?? [];

    if (isLoading) {
        return (<Progress />);
    }

    if (hasErrors) {
        return (<Error />);
    }

    return (
        <Box data-cy="deflections">
            <Typography variant="h6" component="div">
                Deflections
            </Typography>
            <br />
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Box color="text.disabled">
                                    Store name
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box color="text.disabled">
                                    Original revenue
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box color="text.disabled">
                                    Change in revenue
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deflections.map(deflection =>
                            <TableRow key={deflection.storeId}>
                                <TableCell align="left">
                                    {deflection.storeName}
                                </TableCell>
                                <TableCell align="center">
                                    {numberFormatter.toGBP(
                                        calculateOriginalRevenue(deflection.percentageChange, deflection.revisedRevenue),
                                        1
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {numberFormatter.toGBP(
                                        (deflection.revisedRevenue - calculateOriginalRevenue(deflection.percentageChange, deflection.revisedRevenue)),
                                        1
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default Deflections;
