import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectWeightedSpendOrSpendPerHead } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";

const WeightedSpendOrSpendPerHead: React.FC = () => {
    const weightedSpendOrSpendPerHead = useAppSelector(selectWeightedSpendOrSpendPerHead);
    return (
        <RAGIndicatorNew ragIndicator={weightedSpendOrSpendPerHead} />
    );
};

export default WeightedSpendOrSpendPerHead;
