import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const EducationLevel: React.FC = () => {
    const title = "Education level";
    const categories = [
        "No Qualifications",
        "Level 1",
        "Level 2",
        "Level 3",
        "Level 4+",
        "Other"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.educationLevel;
    const selectedStoreValues = selectedStore ? [
        selectedStore.noQualification,
        selectedStore.level1,
        selectedStore.level2,
        selectedStore.level3,
        selectedStore.otherQualification
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.educationLevel;
    const comparatorValues = comparator ? [
        comparator.noQualification,
        comparator.level1,
        comparator.level2,
        comparator.level3,
        comparator.otherQualification
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default EducationLevel;
