import React from "react";

import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import ChapterAlignment from "pages/customer/tools/location/filters/targets/ChapterAlignment";

import Affluence from "./Affluence";
import Age from "./Age";
import Children from "./Children";
import Diversity from "./Diversity";
import Urbanicity from "./Urbanicity";

import {
    selectCandidateTarget,
    selectDefaultTarget,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledDivider = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Divider);

const Demographics: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const defaultTarget = useAppSelector(selectDefaultTarget);
    const resetButtonDisabled = !candidateTarget
        || !defaultTarget
        || (candidateTarget.affluence === defaultTarget.affluence
            && candidateTarget.age === defaultTarget.age
            && candidateTarget.children === defaultTarget.children
            && candidateTarget.diversity === defaultTarget.diversity
            && candidateTarget.urbanicity === defaultTarget.urbanicity);

    const handleResetButtonClick = () => {
        if (candidateTarget && defaultTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                affluence: defaultTarget.affluence,
                age: defaultTarget.age,
                children: defaultTarget.children,
                diversity: defaultTarget.diversity,
                urbanicity: defaultTarget.urbanicity
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    const handleSwitchChange = () => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                useDemographics: !candidateTarget.useDemographics
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <ChapterAlignment
            name="Demographics"
            disabled={!candidateTarget.useDemographics}
            resetButtonDisabled={resetButtonDisabled}
            onResetButtonClick={handleResetButtonClick}
            onSwitchChange={handleSwitchChange}
        >
            <Affluence />
            <StyledDivider />
            <Age />
            <StyledDivider />
            <Children />
            <StyledDivider />
            <Diversity />
            <StyledDivider />
            <Urbanicity />
        </ChapterAlignment>
    );
};

export default Demographics;
