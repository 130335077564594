import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiDelete, apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

import { getUserInfo } from "modules/auth/authSlice";

interface StartSupportSessionVisibility {
    isVisible: boolean,
    accountId: string
}

interface SupportSessionsState {
    startSupportSessionVisibility: StartSupportSessionVisibility
}

const initialState: SupportSessionsState = {
    startSupportSessionVisibility: {
        isVisible: false,
        accountId: ""
    }
};

const supportSessionsSlice = createSlice({
    name: "admin/accounts/supportSessions",
    initialState,
    reducers: {
        showStartSupportSession: (state, action: PayloadAction<string>) => {
            state.startSupportSessionVisibility.isVisible = true;
            state.startSupportSessionVisibility.accountId = action.payload;
        },
        hideStartSupportSession: (state) => {
            state.startSupportSessionVisibility = initialState.startSupportSessionVisibility;
        }
    }
});

export const {
    showStartSupportSession,
    hideStartSupportSession
} = supportSessionsSlice.actions;

export const startSupportSession = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost(`/admin/accounts/${accountId}/support-session`, {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideStartSupportSession());
            dispatch(getUserInfo());
            window.location.href = "/"; //ToDo: use history instead of forcing a page change
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const endSupportSession = (accountId: string): AppThunk => async (dispatch) => {
    const response = await dispatch(apiDelete(`/admin/accounts/${accountId}/support-session`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getUserInfo());
            window.location.href = "/"; //ToDo: use history instead of forcing a page change
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        default: {
            break;
        }
    }
};

export const selectStartSupportSessionVisibility = (state: RootState) => {
    return state.admin.accounts.supportSessions.startSupportSessionVisibility;
};

export default supportSessionsSlice;
