import ReactGA4 from "react-ga4";

export class WebAnalyticsService {
    constructor(measurementId: string) {
        if (measurementId) {
            ReactGA4.initialize(measurementId);
        }
        // ToDo: Log errors
    }

    trackPageView(pathname: string, search?: string) {
        const fullPath = pathname + search;
        if (ReactGA4.isInitialized) {
            ReactGA4.send({
                hitType: "pageview",
                page: fullPath
            });
        }
    }

    trackEvent(category: string, action: string, label?: string) {
        if (ReactGA4.isInitialized) {
            ReactGA4.event({
                category: category,
                action: action,
                label: label
            });
        }
    }
}

