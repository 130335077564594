import React from "react";

import { Grid } from "@material-ui/core";

import FootfallAlignmentScore from "./FootfallAlignmentScore";
import FootfallLevelAlignment from "./FootfallLevelAlignment";
import FootfallAlignmentDumbbell from "./FootfallAlignmentDumbbell";

const AlignmentToFootfallBenchmark: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FootfallLevelAlignment />
            </Grid>
            <Grid item xs={12}>
                <FootfallAlignmentScore />
            </Grid>
            <Grid item xs={12}>
                <FootfallAlignmentDumbbell />
            </Grid>
        </Grid>
    );
};

export default AlignmentToFootfallBenchmark;
