import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);
    return (
        <>
            <p>
                Assess the detailed demographics of your {store?.name} store's catchment area, encompassing household income and age structure, among other characteristics, and note how they compare to {comparator?.name}.
                Use this analysis to size up the target market in the catchment area, ensuring it has the potential to support your business's growth.
                Armed with these insights, refine your product assortment and marketing strategies to match the local demographic landscape for maximum impact.
            </p>
        </>
    );
};

export default Subtitle;
