const GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_REQUEST = "spark/customer/insights/portfolio/areaHealth/marketCategoryBreakdown/GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_REQUEST";
const GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_SUCCESS = "spark/customer/insights/portfolio/areaHealth/marketCategoryBreakdown/GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_SUCCESS";
const GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_FAILURE = "spark/customer/insights/portfolio/areaHealth/marketCategoryBreakdown/GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_FAILURE";

const types = {
    GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_REQUEST,
    GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_SUCCESS,
    GET_MARKET_CATEGORY_OPENINGS_VS_CLOSURES_FAILURE
};

export default types;
