import React from "react";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import highchartsDumbbell from "highcharts/modules/dumbbell";

import ChartBase from "./ChartBase";

highchartsDumbbell(Highcharts);

interface DumbbellProps {
    dataCy?: string,
    loading: boolean,
    error: boolean,
    options: Highcharts.Options
}

const Dumbbell: React.FC<DumbbellProps> = (props: DumbbellProps) => {
    const theme = useTheme();
    const { dataCy, loading, error, options: customOptions } = props;

    const staticOptions: Highcharts.Options = {
        chart: {
            inverted: true
        },
        xAxis: [{
            type: 'category',
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            }
        },{
            type: 'category',
            labels: {
                style: {
                    color: theme.palette.common.white
                }
            },
            linkedTo: 0,
            opposite: true
        }],
        yAxis: {
            title: {
                text: ``
            }
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function() {return false;}
                },
                marker: {
                    symbol: 'circle',
                    radius: 7
                }
            },
            dumbbell: {
                connectorColor: theme.palette.common.white,
                connectorWidth: 2
            }
        }
                   
    };
    
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

export default Dumbbell;
