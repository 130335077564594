import React from "react";

import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {
    selectStoresSort,
    setStoresSort,
    StoreSortField
} from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import { SortDirection } from "utils/sortUtils";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(40)
    }
}))(Menu);

const StyledMenuItem = withStyles(theme => ({
    root: {
        fontWeight: (props: any) => props["data-current"] ? "bold" : "normal",
        color: (props: any) => props["data-current"] ? theme.palette.text.primary : theme.palette.text.disabled
    }
}))(MenuItem);

const Sort: React.FC = () => {
    const dispatch = useAppDispatch();
    const storesSort = useAppSelector(selectStoresSort);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSortClick = (field: StoreSortField, sort: SortDirection) => () => {
        let direction = sort;
        if (storesSort.field === field) {
            direction = storesSort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newStoreSort = { ...storesSort, field, direction };
        dispatch(setStoresSort(newStoreSort));
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                color="default"
                disableElevation
                startIcon={<ArrowDownwardIcon />}
                onClick={handleClick}
                data-cy="btn-sort"
            >
                Sort
            </Button>
            <StyledMenu
                open={open}
                onClose={handleClose}
                variant="menu"
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <StyledMenuItem onClick={handleSortClick(StoreSortField.RadarColour, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.RadarColour}>
                    <Typography variant="body1" component="div">Radar colour</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.RadarColour || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.StoreName, SortDirection.ASC)} data-current={storesSort.field === StoreSortField.StoreName}>
                    <Typography variant="body1" component="div">
                        Store name
                    </Typography>
                    <Spacer />
                    <Typography variant="body1" component="div">
                        {storesSort.field !== StoreSortField.StoreName || storesSort.direction === SortDirection.ASC ? "A-Z" : "Z-A"}
                    </Typography>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.OpeningDate, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.OpeningDate}>
                    <Typography variant="body1" component="div">Opening date</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.OpeningDate || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.StoreSize, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.StoreSize}>
                    <Typography variant="body1" component="div">Store size</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.StoreSize || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Employees, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.Employees}>
                    <Typography variant="body1" component="div">Employees</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Employees || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.Revenue, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.Revenue}>
                    <Typography variant="body1" component="div">Revenue in the last year</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.Revenue || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.GrossProfitMargin, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.GrossProfitMargin}>
                    <Typography variant="body1" component="div">Gross profit (%)</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.GrossProfitMargin || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.RevenuePerSquareFoot, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.RevenuePerSquareFoot}>
                    <Typography variant="body1" component="div">Revenue per square foot</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.RevenuePerSquareFoot || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.CatchmentSize, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.CatchmentSize}>
                    <Typography variant="body1" component="div">Catchment size (population)</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.CatchmentSize || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.NumberOfCompetitors, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.NumberOfCompetitors}>
                    <Typography variant="body1" component="div">Number of competitors within 5km</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.NumberOfCompetitors || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.ChangeInNumberOfStores, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.ChangeInNumberOfStores}>
                    <Typography variant="body1" component="div">Change in number of stores</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.ChangeInNumberOfStores || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSortClick(StoreSortField.FootfallLevel, SortDirection.DESC)} data-current={storesSort.field === StoreSortField.FootfallLevel}>
                    <Typography variant="body1" component="div">Footfall level</Typography>
                    <Spacer />
                    {storesSort.field !== StoreSortField.FootfallLevel || storesSort.direction === SortDirection.DESC ? (
                        <ArrowDownwardIcon fontSize="small" />
                    ) : (
                        <ArrowUpwardIcon fontSize="small" />
                    )}
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
};

export default Sort;
