import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const FirstLanguage: React.FC = () => {
    const title = "First language (Diversity)";
    const categories = [
        "English or Welsh",
        "Other"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.firstLanguage;
    const retailCentreValues = retailCentre ? [
        retailCentre.englishOrWelsh,
        retailCentre.other
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.firstLanguage;
    const benchmarkValues = benchmark ? [
        benchmark.englishOrWelsh,
        benchmark.other
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default FirstLanguage;
