import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectPastTwelveMonthsCosts,
    selectPreviousYearCosts
} from "modules/customer/insights/cost/costOverview/costOverviewSlice";

import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";
import mathUtils from "utils/mathUtils";

const PastTwelveMonthsTotalCosts = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const pastTwelveMonthsCosts = useAppSelector(selectPastTwelveMonthsCosts);
    const previousYearCosts = useAppSelector(selectPreviousYearCosts);

    const label = `Past 12 months total costs`;
    const difference = mathUtils.safePercentageChange(pastTwelveMonthsCosts, previousYearCosts);

    return (
        <Box width="100%" height="100%" data-cy="past-twelve-months-vs-previous-year-total-costs">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(pastTwelveMonthsCosts, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <PercentageIndicator loading={false} error={false} value={difference} switchColor={true} noDataMessage={""} />
                }
                comparisonLabel={"% change from previous year"}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(previousYearCosts, 1)}
                    </Typography>
                }
                differenceLabel="Previous year"
            />
        </Box>
    );
};

export default PastTwelveMonthsTotalCosts;
