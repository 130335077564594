import React from "react";

import { Box, Container, Hidden, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { ChapterProps } from "./Chapter";
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";

interface NavigationProps {
    companyName: string,
    title: string,
    filters?: React.ReactElement,
    isSetupComplete: boolean,
    chapters: React.ReactElement<ChapterProps>[]
}

const Navigation: React.FC<NavigationProps> = (props) => {
    const { companyName, title, filters, isSetupComplete, chapters } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const bgcolor = theme.palette.background.default;
    const zIndex = theme.zIndex.drawer;
    const position = isMobile ? "fixed" : "sticky";

    return (
        <Box width="100%" position={position} top={0} bgcolor={bgcolor} zIndex={zIndex} data-cy="insight-navigation">
            {/* @ts-ignore */}
            <Hidden smDown>
                <br />
                <Typography variant="subtitle1" component="div" color="textSecondary">
                    {companyName}
                </Typography>
                <Box paddingBottom={1} data-cy="title">
                    <Typography variant="h3">{title}</Typography>
                </Box>
                <Box paddingTop={1} paddingBottom={1} data-cy="filters">
                    {filters}
                </Box>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden mdUp>
                <Container>
                    <Box display="flex" alignItems="center" paddingY={1} data-cy="title-and-filters">
                        <Typography variant="h6">{title}</Typography>
                        <Spacer />
                        {filters}
                    </Box>
                </Container>
            </Hidden>
            <Box data-cy="chapters">
                {/* @ts-ignore */}
                <Hidden smDown>
                    <NavigationDesktop chapters={chapters} isSetupComplete={isSetupComplete} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <NavigationMobile chapters={chapters} isSetupComplete={isSetupComplete} />
                </Hidden>
            </Box>
        </Box>
    );
};

export default Navigation;
