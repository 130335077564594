import { configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers } from "redux";

import admin from "modules/admin";
import appDetails from "modules/appDetails";
import auth from "modules/auth";
import backdrop from "modules/backdrop";
import customer from "modules/customer";
import featureFlags from "modules/featureFlags";
import helpers from "modules/helpers";
import landing from "modules/landing";
import notifications from "modules/notifications";
import siteConfiguration from "modules/siteConfiguration";

enableMapSet();

const rootReducer = combineReducers({
    admin,
    appDetails,
    auth,
    backdrop,
    customer,
    featureFlags,
    helpers,
    landing,
    notifications,
    siteConfiguration
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
