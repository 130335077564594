import React from "react";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Dash's Portfolio module offers a holistic analysis of your {store?.name} store,
            from sales trends and profitability to customer and competitor analysis and footfall dynamics,
            Portfolio equips you with actionable insights for strategic retail decision-making.
        </>
    );
};

export default Subtitle;
