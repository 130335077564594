const storeVsComparatorRevVsComp = (state) => {
    return state.customer.insights.portfolio.competition.revenueVsCompetitionScore.storeVsComparatorRevVsComp;
};

const revenueVsCompetitionScore = (state) => {
    return state.customer.insights.portfolio.competition.revenueVsCompetitionScore.revenueVsCompetitionScore;
};

const selectors = {
    storeVsComparatorRevVsComp,
    revenueVsCompetitionScore
};

export default selectors;
