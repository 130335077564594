import React from "react";

import Centile from "pages/customer/tools/location/filters/targets/Centile";
import CentileMetric from "pages/customer/tools/location/filters/targets/CentileMetric";

import {
    selectCandidateComparatorStores,
    selectCandidateTarget,
    selectAggregatedDirectCompetitors,
    selectRetailCentresCentiles,
    selectStoreCategoryCentilesMetrics,
    setCandidateTarget
} from "modules/customer/tools/location/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const Affluence: React.FC = () => {
    const dispatch = useAppDispatch();
    const candidateComparatorStores = useAppSelector(selectCandidateComparatorStores);
    const retailCentresCentiles = useAppSelector(selectRetailCentresCentiles);
    const comparatorStoresCentiles = candidateComparatorStores.map(store => {
        const retailCentreCentile = retailCentresCentiles.find(rcc => rcc.retailCentreId === store.retailCentre.id)?.affluence ?? 0;
        return {
            storeName: store.name,
            centileValue: retailCentreCentile
        };
    });
    const aggregatedDirectCompetitors = useAppSelector(selectAggregatedDirectCompetitors);
    const aggregatedDirectCompetitorCentiles = aggregatedDirectCompetitors.map(item => ({
        storeName: item.fascia,
        centileValue: item.affluenceCentile
    }));
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const storeCategoryCentilesMetrics = useAppSelector(selectStoreCategoryCentilesMetrics);
    const centileMetric = storeCategoryCentilesMetrics.find(cm => cm.centile === candidateTarget?.affluence);
    const name = "Affluence";
    const description = `The affluence score looks at the catchment area population and evaluates the resident's income,
        homeownership status, and home occupancy rating.`;
    const affluenceCentileMetrics = [{
        name: "Income bracket",
        tooltip: "The median household income bucket in the catchment area.",
        value: centileMetric?.incomeBracket ?? ""
    }, {
        name: "Home owners",
        tooltip: "The percentage of the catchment area's population that are homeowners.",
        value: numberFormatter.toPercentage(centileMetric?.homeOwnershipPercentage ?? 0, false, 0)
    }, {
        name: "Occupancy rating",
        tooltip: `The median household occupancy rating for homes in the catchment area. The occupancy rating is a
            metric that determines whether a household's accommodation is overcrowded, ideally occupied or
            under-occupied. This rating is calculated by comparing the number of bedrooms the household requires to the
            number of available bedrooms.`,
        value: centileMetric?.occupancyRating ?? ""
    }];

    const handleSliderChange = (value: number) => {
        if (candidateTarget) {
            const newCandidateTarget = {
                ...candidateTarget,
                affluence: value
            };
            dispatch(setCandidateTarget(newCandidateTarget));
        }
    };

    if (!candidateTarget) {
        return null;
    }

    return (
        <Centile
            name={name}
            description={description}
            disabled={!candidateTarget.useDemographics}
            sliderValue={candidateTarget.affluence}
            onSliderChange={handleSliderChange}
            comparatorStoresCentiles={comparatorStoresCentiles}
            aggregatedDirectCompetitorCentiles={aggregatedDirectCompetitorCentiles}
            chartLowLabel="Low wealth population"
            chartHighLabel="High wealth population"
        >
            {affluenceCentileMetrics.map((metric, index) =>
                <CentileMetric
                    key={index}
                    name={metric.name}
                    tooltip={metric.tooltip}
                    value={metric.value}
                    disabled={!candidateTarget.useDemographics}
                />
            )}
        </Centile>
    );
};

export default Affluence;
