import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/areaHealth/openingsAndClosures";

const NetOpeningsInPastYear = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const netOpeningsInPastYear = useSelector(state => selectors.netOpeningsInPastYear(state));
    const { loading, error, value, percentageDifference } = netOpeningsInPastYear;
    const valueFormatted = value.toString();
    const label = `Net store openings in the last 12 months within the local area of ${selectedStore.name} vs ${selectedComparator.name}`;

    return (
        <Box width="100%" height="100%" data-cy="net-openings-in-past-two-years">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default NetOpeningsInPastYear;
