import { createSelector } from "reselect";

import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const salesToProfit = (state) => {
    return state.customer.insights.portfolio.profit.netProfit.salesToProfit;
};

const profitabilityEvaluation = createSelector(
    salesToProfit,
    state => selectStore(state),
    state => selectComparator(state),
    (salesToProfit, selectedStoreSelector, comparatorSelector) => {
        const rag = {
            loading: salesToProfit.loading,
            error: salesToProfit.error,
            id: "profitability-evaluation",
            label: "Net profit (%) evaluation",
            status: "info",
            value: ""
        };
        if (rag.loading || rag.error) {
            return rag;
        }

        const selectedStore = salesToProfit.selectedStore;
        const comparator = salesToProfit.comparator;

        const netProfitSelected = (selectedStore.sales === 0) ? 0 : 100 * selectedStore.netProfit / selectedStore.sales;
        const netProfitComparator = (comparator.sales === 0) ? 0 : 100 * comparator.netProfit / comparator.sales;
        const netProfitRelative = (netProfitComparator === 0) ? 0 : ((netProfitSelected - netProfitComparator) / Math.abs(netProfitComparator)) * 100;

        if ((selectedStore.propertyCost === 0 || selectedStore.staffCost === 0) && netProfitComparator === 0) {
            rag.status = "noData";
            rag.value = `This indicator isn't available because it requires your company's property and payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            return rag;
        }

        if (netProfitRelative > 50) {
            rag.status = "success";
            rag.value = `The net profit (%) for ${selectedStoreSelector.name} is high relative to ${comparatorSelector.name}`;
        } else if (netProfitRelative < -50) {
            rag.status = "error";
            rag.value = `The net profit (%) for ${selectedStoreSelector.name} is low relative to ${comparatorSelector.name}`;
        } else { // equal to or between the 2 options
            rag.status = "warning";
            rag.value = `The net profit (%) for ${selectedStoreSelector.name} is broadly in line with ${comparatorSelector.name}`;
        }

        return rag;
    }
);

const selectors = {
    salesToProfit,
    profitabilityEvaluation,
};

export default selectors;
