import React from "react";

import { Card, CardContent, Chip, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(28),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const StyledDivider = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))(Divider);

interface LegendProps {
    outputArea?: { weightedSpend: number, spendPerHead: number }
}

const Legend: React.FC<LegendProps> = (props) => {
    const { outputArea } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={outputArea !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Weighted spend
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toGBP(outputArea?.weightedSpend, 1)}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Spend per head
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toGBP(outputArea?.spendPerHead, 1)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Weighted spend
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[0]} />
                    &nbsp;&nbsp;Very high
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[1]} />
                    &nbsp;&nbsp;High
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[2]} />
                    &nbsp;&nbsp;Medium
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.sequential[3]} />
                    &nbsp;&nbsp;Low
                </Typography>
                <Typography variant="body1" component="div">
                    <StyledChip size="small" data-background-color={colourPalette.sequential[4]} />
                    &nbsp;&nbsp;Very low
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
