import { combineReducers } from "redux";

import types from "./types";

const salesPerSquareFootEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const salesPerSquareFoot = (state = salesPerSquareFootEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_PER_SQUARE_FOOT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_SALES_PER_SQUARE_FOOT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_SALES_PER_SQUARE_FOOT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};


const propertyCostsPerSquareFootEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const propertyCostsPerSquareFoot = (state = propertyCostsPerSquareFootEmpty, action) => {
    switch (action.type) {
        case types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_PROPERTY_COSTS_PER_SQUARE_FOOT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};

const salesPerPoundOfPropertyCostEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const salesPerPoundOfPropertyCost = (state = salesPerPoundOfPropertyCostEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_PER_POUND_OF_PROPERTY_COST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_SALES_PER_POUND_OF_PROPERTY_COST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_SALES_PER_POUND_OF_PROPERTY_COST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    salesPerSquareFoot,
    propertyCostsPerSquareFoot,
    salesPerPoundOfPropertyCost
});

export default reducer;
