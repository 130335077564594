import React from "react";

import Comparators from "./comparators/Comparators";
import CustomSelection from "./customSelection/CustomSelection";
import Stores from "./stores/Stores";

import { FilterStep, selectActiveStep } from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppSelector } from "store";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === FilterStep.SelectStore && (<Stores />)}
            {activeStep === FilterStep.SelectComparator && (<Comparators />)}
            {activeStep === FilterStep.CustomSelection && (<CustomSelection />)}
        </>
    );
};

export default Content;
