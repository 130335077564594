import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/forecast/forecastRevenue";

const ForecastSalesYOY = () => {
    const dispatch = useDispatch();
    const forecastSalesYOY = useSelector(state => selectors.forecastSalesYOY(state));
    const { loading, error, financialYearToGoValue, percentageDifference } = forecastSalesYOY;
    const label = "Forecast revenue in the year to go vs the same period last year";
    const valueFormatted = numberFormatter.toGBP(financialYearToGoValue, 1);

    React.useEffect(() => {
        dispatch(operations.getForecastSalesYOY());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="forecast-sales-yoy">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default ForecastSalesYOY;
