import React from "react";
import { Scrollspy } from "@makotot/ghostui";

import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { ChapterProps } from "./Chapter";

import { scrollIntoViewByRef } from "utils/scrollUtils";

const StyledListItem = withStyles(theme => ({
    root: {
        "&.Mui-selected": {
            borderLeftStyle: "solid",
            borderLeftWidth: theme.spacing(0.5),
            borderLeftColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
            "&:hover": {
                backgroundColor: theme.palette.background.default
            },
            "& > .MuiListItemText-root > .MuiTypography-root": {
                fontWeight: "bold"
            }
        }
    }
}))(ListItem);

const StyledNestedListItem = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(4),
        "&.Mui-selected": {
            paddingLeft: theme.spacing(3.5)
        }
    }
}))(StyledListItem);

interface NavigationDesktopProps {
    isSetupComplete: boolean,
    chapters: React.ReactElement<ChapterProps>[]
}

const NavigationDesktop: React.FC<NavigationDesktopProps> = (props) => {
    const { isSetupComplete, chapters } = props;
    const theme = useTheme();
    const scrollspyOffset = theme.spacing(-15);

    const scrollIntoView = (ref: React.RefObject<HTMLDivElement>) => () => {
        scrollIntoViewByRef(ref);
    };

    return (
        <Scrollspy sectionRefs={chapters.map(chapter => chapter.props.chapterRef)} offset={scrollspyOffset}>
            {(chaptersScrollspy) =>
                <>
                    {chapters.map((chapter, chapterIndex) =>
                        <List key={chapterIndex} dense disablePadding>
                            {isSetupComplete && (
                                <StyledListItem
                                    button
                                    dense
                                    onClick={scrollIntoView(chapter.props.chapterRef)}
                                >
                                    <ListItemText
                                        primary={`${chapter.props.number}. ${chapter.props.title}`}
                                        primaryTypographyProps={{
                                            variant: "subtitle1",
                                            noWrap: true
                                        }}
                                        data-cy="insight-chapter"
                                    />
                                </StyledListItem>
                            )}
                            <Collapse
                                in={isSetupComplete && chaptersScrollspy.currentElementIndexInViewport === chapterIndex}
                                unmountOnExit
                            >
                                <List dense disablePadding>
                                    <Scrollspy
                                        sectionRefs={chapter.props.children.map(subchapter => subchapter.props.subchapterRef)}
                                        offset={scrollspyOffset}
                                    >
                                        {(subchaptersScrollspy) =>
                                            <>
                                                {chapter.props.children.map((subchapter, subchapterIndex) =>
                                                    <StyledNestedListItem
                                                        key={subchapterIndex}
                                                        button
                                                        dense
                                                        selected={isSetupComplete && subchaptersScrollspy.currentElementIndexInViewport === subchapterIndex}
                                                        onClick={scrollIntoView(subchapter.props.subchapterRef)}
                                                    >
                                                        <ListItemText
                                                            primary={`${subchapter.props.number}. ${subchapter.props.title}`}
                                                            primaryTypographyProps={{
                                                                variant: "subtitle1",
                                                                noWrap: true
                                                            }}
                                                            data-cy="insight-subchapter"
                                                        />
                                                    </StyledNestedListItem>
                                                )}
                                            </>
                                        }
                                    </Scrollspy>
                                </List>
                            </Collapse>
                        </List>
                    )}
                </>
            }
        </Scrollspy>
    );
};

export default NavigationDesktop;
