import { median } from "mathjs";

import actions from "./actions";
import dateUtils from "utils/dateUtils";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectReferenceDate, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getProductCategoryMix = () => async (dispatch, getState) => {
    dispatch(actions.getProductCategoryMixRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        // Get current & previous year of sales
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID", "D_ProductCategory.ProductCategory1"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const currentYear = resultSet.loadResponses[0].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            sales: item["F_Sales.SumLineValue"]
        }));

        const previousYear = resultSet.loadResponses[1].data.map(item => ({
            store: item["D_Store.StoreNaturalID"],
            productCategory: item["D_ProductCategory.ProductCategory1"],
            sales: item["F_Sales.SumLineValue"]
        }));

        const storeTotals = selectedAndComparatorIDs.map(storeID => ({
            storeID,
            currentYearTotal: currentYear.filter(sales => sales.store === storeID).reduce((total, sales) => sales.sales + total, 0),
            previousYearTotal: previousYear.filter(sales => sales.store === storeID).reduce((total, sales) => sales.sales + total, 0)
        }));

        //Calculate forecast product category growth for y axis
        const growthData = currentYear.map(current => {
            const previousStore = previousYear.filter(item => item.store === current.store);
            const previous = previousStore.find(item =>  item.productCategory === current.productCategory);
            const overall = storeTotals.find(storeTotal => storeTotal.storeID === current.store);
            
            if (!previous) {
                return {
                    store: current.store,
                    productCategory: current.productCategory,
                    sales: current.sales || 0,
                    forecastProductCategoryGrowth: 0,
                    salesDifferencePriorTwoYears: overall ? overall.currentYearTotal - overall.previousYearTotal : 0,
                    productCategoryMix: overall ? ((overall.currentYearTotal === 0) ? 0 : 100 * (current.sales / overall.currentYearTotal)) : 0,
                };
            }
            return {
                store: current.store,
                productCategory: current.productCategory,
                sales: current.sales || 0,
                forecastProductCategoryGrowth: (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales),
                salesDifferencePriorTwoYears: overall.currentYearTotal - overall.previousYearTotal,
                productCategoryMix: (overall.currentYearTotal === 0) ? 0 : 100 * (current.sales / overall.currentYearTotal),
            };
        });

        //ToDo: Add dropdown selection automatically filtered to 'relevant categories'
        const relevantCategories = growthData.filter(item => item.store === selectedStoreID && item.productCategoryMix > 1).map(item => item.productCategory);
        const filteredGrowthData = growthData.filter(item => relevantCategories.includes(item.productCategory));

        const store = filteredGrowthData.filter(item => item.store === selectedStoreID);
        const comparatorData = filteredGrowthData.filter(item => comparatorStoresIDs.includes(item.store));
        const productCategories = comparatorData.map(item => item.productCategory);
        const distinctProductCategories = [...new Set(productCategories)];

        const comparator = distinctProductCategories.map(productCategory => {
            const filteredComparator = comparatorData.filter(item => item.productCategory === productCategory);
            let productCategoryMix = median(...filteredComparator.map(item => item.productCategoryMix));
            let forecastProductCategoryGrowth = median(...filteredComparator.map(item => item.forecastProductCategoryGrowth));
            return {
                productCategory,
                productCategoryMix,
                forecastProductCategoryGrowth
            };
        });

        dispatch(actions.getProductCategoryMixSuccess(store, comparator));
    }
    catch (error) {
        dispatch(actions.getProductCategoryMixFailure());
        dispatch(logError("Error loading ProductCategoryMix.", error));
    }
};

const operations = {
    getProductCategoryMix
};

export default operations;
