import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Analyse the commercial vitality around your {store?.name} store. Track openings and closures to gauge market trends and recovery, informing lease negotiations and investment decisions.
            Delve into store category changes to understand evolving market dynamics and their implications for your brand.
            Together, these insights sharpen your competitive strategy, helping you adapt to and capitalise on the local retail environment's shifting landscape.
        </>
    );
};

export default Subtitle;
