import React from "react";

import { Grid, Typography } from "@material-ui/core";

import Page from "components/admin/Page";

import AddLead from "./AddLead";
import FilterLeads from "./FilterLeads";
import LeadsTable from "./LeadsTable";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const Leads: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);

    return (
        <Page dataCy="pages-admin-leads">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        KPMG Admin - {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Leads
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2}>
                            <AddLead />
                        </Grid>
                        <Grid item xs={3}>
                            <FilterLeads />
                        </Grid>
                        <Grid item xs={7} />
                        <Grid item xs={12}>
                            <LeadsTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
};

export default Leads;
