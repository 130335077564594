const GET_RETAIL_DENSITY_VIS_REQUEST = "spark/customer/insights/portfolio/areaHealth/retailDensity/GET_RETAIL_DENSITY_VIS_REQUEST";
const GET_RETAIL_DENSITY_VIS_SUCCESS = "spark/customer/insights/portfolio/areaHealth/retailDensity/GET_RETAIL_DENSITY_VIS_SUCCESS";
const GET_RETAIL_DENSITY_VIS_FAILURE = "spark/customer/insights/portfolio/areaHealth/retailDensity/GET_RETAIL_DENSITY_VIS_FAILURE";

const types = {
    GET_RETAIL_DENSITY_VIS_REQUEST,
    GET_RETAIL_DENSITY_VIS_SUCCESS,
    GET_RETAIL_DENSITY_VIS_FAILURE
};

export default types;
