import { ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class DailyFootfall {
    public readonly retailCentreId: number;
    public readonly date: DateTime;
    public readonly footfall: number;

    constructor(
        retailCentreId: number,
        date: DateTime,
        footfall: number
    ) {
        this.retailCentreId = retailCentreId;
        this.date = date;
        this.footfall = footfall;
    }
}

export const loadDailyFootfall = (retailCentreIds: number[]): AppThunk<Promise<DailyFootfall[]>> => async (dispatch) => {
    if (!retailCentreIds || retailCentreIds.length === 0) {
        return [];
    }
    try {
        const retailCentreIdStrings = retailCentreIds.map(retailCentreId => retailCentreId.toString());
        const query = {
            dimensions: [
                "F_DailyFootfall.RetailCentreID",
                "F_DailyFootfall.Date",
                "F_DailyFootfall.Footfall"
            ],
            order: [[
                "F_DailyFootfall.Date",
                "asc"
            ]],
            filters: [{
                member: "F_DailyFootfall.RetailCentreID",
                operator: "equals",
                values: retailCentreIdStrings
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new DailyFootfall(
            row["F_DailyFootfall.RetailCentreID"],
            DateTime.fromISO(row["F_DailyFootfall.Date"], { zone: "utc" }),
            row["F_DailyFootfall.Footfall"]
        ));

    } catch (error) {
        dispatch(logError("Error loading DailyFootfall.", error));
        throw error;
    }
};
