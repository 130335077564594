import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIFact from "components/visuals/KPIFact";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const AvgSalesLatestFullMonth = () => {
    const dispatch = useDispatch();
    const avgSalesLatestFullMonth = useSelector(state => selectors.avgSalesLatestFullMonth(state));
    const { loading, error, value, currentMonth, currentYear } = avgSalesLatestFullMonth;
    const label = `Average daily revenue in ${currentMonth} ${currentYear}`;
    const valueFormatted = numberFormatter.toGBP(value, 1);

    React.useEffect(() => {
        dispatch(operations.getAvgSalesLatestFullMonth());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="avg-sales-latest-full-month">
            <KPIFact loading={loading} error={error} label={label} value={valueFormatted} />
        </Box>
    );
};

export default AvgSalesLatestFullMonth;
