import React from "react";

import { IconButton, Typography } from "@material-ui/core";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import DataRefreshesTable from "./DataRefreshesTable";

import { Account, AccountStatus } from "modules/admin/accounts/accounts/accountsSlice";
import { clearDataRefreshes, getDataRefreshes, hideDataRefreshes, selectDataRefreshesVisibility, showDataRefreshes } from "modules/admin/accounts/dataRefreshes/dataRefreshesSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface DataRefreshesProps {
    account: Account
}

const DataRefreshes: React.FC<DataRefreshesProps> = (props) => {
    const { account } = props;
    const dispatch = useAppDispatch();
    const disabled = account.status === AccountStatus.NoSubscription;
    const dataRefreshesVisibility = useAppSelector(selectDataRefreshesVisibility);
    const show = (dataRefreshesVisibility.isVisible) && (account.id === dataRefreshesVisibility.accountId);

    React.useEffect(() => {
        if (show) {
            dispatch(getDataRefreshes(account.id));
        }
    }, [dispatch, show, account]);

    const handleDataRefreshesClick = () => {
        dispatch(showDataRefreshes(account.id));
    };

    const handleCancelClick = () => {
        dispatch(hideDataRefreshes());
        dispatch(clearDataRefreshes());
    };

    return (
        <>
            <SimpleTooltip title="Data refreshes">
                <span>
                    <StyledIconButton size="small" onClick={handleDataRefreshesClick} disabled={disabled}>
                        <SyncRoundedIcon />
                    </StyledIconButton>
                </span>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                maxWidth="md"
                title={<Typography variant="h5" component="div" gutterBottom>Data refreshes</Typography>}
                content={<DataRefreshesTable accountId={account.id} />}
                actions={<></>}
            />
        </>
    );
};

export default DataRefreshes;
