import React from "react";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

interface MousePosition {
    x: number,
    y: number
}

const Pin: React.FC = () => {
    const target = useAppSelector(selectTarget);
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const [mousePosition, setMousePosition] = React.useState<MousePosition | undefined>(undefined);
    const x = (mousePosition?.x ?? 0) - 12;
    const y = (mousePosition?.y ?? 0) - 24;

    React.useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const { clientX, clientY } = event;
            setMousePosition({ x: clientX, y: clientY });
        };
        document.addEventListener("mousemove", handleMouseMove);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    if (!target || pinnedLocation || !mousePosition) {
        return null;
    }

    return (
        <LocationOnOutlinedIcon style={{ position: "absolute", left: `${x}px`, top: `${y}px` }} />
    );
};

export default Pin;
