import React from "react";

import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparatorsByChapter = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparatorsByChapter?.spend?.storeName;

    return (
        <>
            Explore yearly spending and spend-per-head figures for your selected location's catchment area and see how this compares to your {comparatorName} store.
            Determine what products your new store should sell by analysing the effect of different market categories on spending and drilling down into market subcategories.
            Switch between weighted and unweighted spending to see each market's full size and how much your location attracts from that market.
        </>
    );
};

export default Subtitle;
