import { combineReducers } from "redux";

import types from "./types";

const salesToProfitEmpty = {
    loading: false,
    error: false,
    selectedStore: {},
    comparator: {},
    netProfitFlag: true
};

const salesToProfit = (state = salesToProfitEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_TO_PROFIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                selectedStore: {},
                comparator: {},
                netProfitFlag: true
            };
        case types.GET_SALES_TO_PROFIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                selectedStore: action.payload.selectedStore,
                comparator: action.payload.comparator,
                netProfitFlag: action.payload.netProfitFlag
            };
        case types.GET_SALES_TO_PROFIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                selectedStore: {},
                comparator: {},
                netProfitFlag: true
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    salesToProfit
});

export default reducer;
