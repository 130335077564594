import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Highcharts from "highcharts";
import ChartBase from "./ChartBase";
import addTreemapModule from "highcharts/modules/treemap";
import drilldown from "highcharts/modules/drilldown.js";
import { useTheme } from "@material-ui/core/styles";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

addTreemapModule(Highcharts);
drilldown(Highcharts);

const Treemap = (props) => {
    const theme = useTheme();
    const { loading, error, options: customOptions, setChart } = props;

    const staticOptions = {
        chart: {
            type: "treemap",
            height: '220px'
        },
        legend: {
            enabled: false
        },
        series: [{
            breadcrumbs: {
                format: ' < Back',
                floating: true,
                buttonTheme: {
                    fill: "#F7F7F7",
                    padding: 8,
                    "stroke-width": 0,
                    style: {
                        color: "black",
                        fontWeight: "bold"
                    },
                    states: {
                        hover: {
                            fill: "#E6E6E6"
                        }
                    }
                },
                position: {
                    align: 'right',
                    verticalAlign:'top'
                },
                showFullPath: false
            },
            states: {
            },
            type: "treemap",
            allowTraversingTree: true,
            levelIsConstant: false,
            alternateStartingDirection: false,
            borderColor: "black",
            borderWidth: 0,
            dataLabels: {
                enabled: false
            },
            levels: [{
                level: 1,
                layoutAlgorithm: "stripes",
                borderWidth: 3,
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `<table>${stringUtils.tooltipHTML([this.point.name, numberFormatter.toPercentage(this.point.value, true, 1)], {
                            categoryFormattingArr: ["fontWeight: 400", "fontWeight: 600"],
                            categoryFormatting: `color: ${theme.palette.common.black}`
                        })}</table>`;
                    },
                    inside: true,
                    allowOverlap: false,
                    align: "left",
                    verticalAlign: "bottom"
                }
            }
        ]
        }]
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="treemap-chart" setChart={setChart} />
    );
};

Treemap.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    setChart: PropTypes.func
};

Treemap.defaultProps = {
    loading: false,
    error: false
};

export default Treemap;
