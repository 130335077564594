import React from "react";

import { Box, Button, Card, CardActionArea, CardContent, Checkbox, ListItem, ListItemSecondaryAction, ListItemText, Menu, Typography } from "@material-ui/core";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import Spacer from "components/Spacer";

import { operations, selectors } from "modules/customer/insights/portfolio/areaHealth/marketCategorySpend";

const StyledCheckbox = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        "&$checked": {
            color: theme.palette.common.white
        },
    },
    checked: {}
}))(Checkbox);

const useStyles = makeStyles(theme => ({
    card: {
        width: theme.spacing(35)
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    menu: {
        width: theme.spacing(35)
    },
    checkboxChecked: {
        color: theme.palette.common.white
    }
}));

const MarketCategoriesFilter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketCategoriesList = useSelector(state => selectors.marketCategoriesList(state));
    const selectedMarketCategories = useSelector(state => selectors.selectedMarketCategories(state));
    const { loading, error, marketCategories } = marketCategoriesList;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectAllClick = () => {
        marketCategories.forEach(marketCategory => {
            if (!selectedMarketCategories.includes(marketCategory)) {
                dispatch(operations.selectMarketCategory(marketCategory));
            }
        });
    };

    const handleDeselectAllClick = () => {
        dispatch(operations.clearSelectedMarketCategories());
    };

    const toggleSelect = (marketCategory) => () => {
        if (selectedMarketCategories.includes(marketCategory)) {
            dispatch(operations.deselectMarketCategory(marketCategory));
        } else {
            dispatch(operations.selectMarketCategory(marketCategory));
        }
    };

    return (
        <Box data-cy="market-categories-filter">
            <Card elevation={0} className={classes.card}>
                <CardActionArea onClick={handleClick}>
                    <CardContent className={classes.cardContent}>
                        <Box display="flex">
                            <Typography variant="body1" gutterBottom>Market categories</Typography>
                            <Spacer />
                            {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                        </Box>
                        <Typography variant="body2">
                            {selectedMarketCategories.length === marketCategories.length ? "All" : selectedMarketCategories.length} selected
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                MenuListProps={{
                    className: classes.menu
                }}
                keepMounted
                open={open}
                onClose={handleClose}>
                <ListItem>
                    <ListItemSecondaryAction>
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleSelectAllClick}
                        >
                            Select all
                        </Button>
                        &nbsp;
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleDeselectAllClick}
                        >
                            Deselect all
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                {loading && (
                    <ListItem dense>
                        <ListItemText primary="Loading..." />
                    </ListItem>
                )}
                {error && (
                    <ListItem dense>
                        <ListItemText primary="Error!" />
                    </ListItem>
                )}
                {marketCategories.map((marketCategory, index) =>
                    <ListItem key={index} dense>
                        <ListItemText primary={marketCategory} />
                        <ListItemSecondaryAction>
                            <StyledCheckbox
                                edge="end"
                                onChange={toggleSelect(marketCategory)}
                                checked={selectedMarketCategories.includes(marketCategory)}
                                classes={{ checked: classes.checkboxChecked }} />
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </Menu>
        </Box>
    );
};

export default MarketCategoriesFilter;
