import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Dash has evaluated how the yearly footfall in your selected location compares against the target level and how strong they align.
        </>
    );
};

export default Subtitle;
