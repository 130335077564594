import React from "react";

import { Box, Typography } from "@material-ui/core";

import AgeStructure from "./AgeStructureGB";
import ChildrenAgeStructure from "./ChildrenAgeStructure";
import CountryOfBirth from "./CountryOfBirth";
import DemographicIndicatorDropdown from "./DemographicIndicatorDropdown";
import DemographicIndicatorMeasure from "./DemographicIndicatorMeasure";
import DwellingType from "./DwellingType";
import Ethnicity from "./Ethnicity";
import FirstLanguage from "./FirstLanguage";
import HouseholdIncome from "./HouseholdIncome";
import HouseholdNumberOfCars from "./HouseholdNumberOfCars";
import HouseholdOccupancy from "./HouseholdOccupancy";
import HouseholdTenure from "./HouseholdTenure";
import PopulationDensity from "./PopulationDensity";
import EducationLevel from "./EducationLevel";

import { useAppSelector } from "store";
import { DemographicIndicator } from "modules/customer/insights/portfolioNew/catchment/catchmentDemographics";
import { selectDemographicIndicator } from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import AgeStructureNI from "./AgeStructureNI";

const BreakdownOfCatchmentAreaDemographic: React.FC = () => {
    const selectedStore = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const demographicIndicator = useAppSelector(selectDemographicIndicator);

    return (
        <Box data-cy="breakdown-of-catchment-area-demographic">    
            <Typography variant="h6" component="div" gutterBottom>
                Breakdown of catchment area demography for {selectedStore?.name} vs {comparator?.name}
            </Typography>
            <DemographicIndicatorDropdown />
            <DemographicIndicatorMeasure />
            {(() => {
                switch (demographicIndicator) {
                    case DemographicIndicator.DwellingType:
                        return <DwellingType />;
                    case DemographicIndicator.HouseholdOccupancy:
                        return <HouseholdOccupancy />;
                    case DemographicIndicator.HouseholdIncome:
                        return <HouseholdIncome />;
                    case DemographicIndicator.HouseholdTenure:
                        return <HouseholdTenure />;
                    case DemographicIndicator.AgeStructureGB:
                        return <AgeStructure />;
                    case DemographicIndicator.AgeStructureNI:
                        return <AgeStructureNI />;
                    case DemographicIndicator.ChildrenAgeStructure:
                        return <ChildrenAgeStructure />;
                    case DemographicIndicator.CountryOfBirth:
                        return <CountryOfBirth />;
                    case DemographicIndicator.Ethnicity:
                        return <Ethnicity />;
                    case DemographicIndicator.FirstLanguage:
                        return <FirstLanguage />;
                    case DemographicIndicator.HouseholdNumberOfCars:
                        return <HouseholdNumberOfCars />;
                    case DemographicIndicator.PopulationDensity:
                        return <PopulationDensity />;
                    case DemographicIndicator.EducationLevel:
                        return <EducationLevel />;
                    default:
                        return <></>;
                }
            })()}
        </Box>
    );
};

export default BreakdownOfCatchmentAreaDemographic;
