import React from "react";

import { Card, CardContent, Chip, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import { selectTarget } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(21),
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const Legend: React.FC = () => {
    const target = useAppSelector(selectTarget);
    const theme = useTheme();
    const colourPalette = useColourPalette();

    if (!target) {
        return null;
    }

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Target alignment
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[5]} />
                    &nbsp;&nbsp;Very strong
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[4]} />
                    &nbsp;&nbsp;Strong
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[3]} />
                    &nbsp;&nbsp;Above Average
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[2]} />
                    &nbsp;&nbsp;Below Average
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[1]} />
                    &nbsp;&nbsp;Weak
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.heatmap[0]} />
                    &nbsp;&nbsp;Very weak
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
