import React from "react";

import { Box, Grid } from "@material-ui/core";

import FormRow from "./FormRow";

import { selectRelevantModelFeatures } from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";

const InputStoreParameters: React.FC = () => {
    const relevantModelFeatures = useAppSelector(selectRelevantModelFeatures);

    return (
        <Box paddingTop={4} paddingBottom={4}>
            <Grid container spacing={4}>
                {relevantModelFeatures.map(relevantModelFeature =>
                    <FormRow key={relevantModelFeature.id} relevantModelFeature={relevantModelFeature} />
                )}
            </Grid>
        </Box>
    );
};

export default InputStoreParameters;
