import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/overview/relativePerformance";

const OverallStorePerformance = () => {
    const overallStorePerformance = useSelector(state => selectors.overallStorePerformance(state));
    const { loading, error, label, status, value } = overallStorePerformance;

    return (
        <Box data-cy="overall-store-performance">
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default OverallStorePerformance;
