import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import {
    selectHasErrors,
    selectIsLoading,
    selectSpendPerHead
} from "modules/customer/tools/location/spend/spendSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const SpendPerHead: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const spendPerHead = useAppSelector(selectSpendPerHead);
    const valueFormatted = numberFormatter.toGBP(spendPerHead, 1);
    const label = "Average spend per head in the catchment area of the selected location";

    return (
        <Box width="100%" height="100%" data-cy="spend-per-head">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default SpendPerHead;
