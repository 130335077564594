import { createSelector } from "reselect";

import { selectSubchaptersIds } from "modules/customer/insights/portfolio/portfolioSlice";

import { selectors as revenueSelectors } from "modules/customer/insights/portfolio/sales/revenue";
import { selectors as growthSelectors } from "modules/customer/insights/portfolio/sales/growth";
import { selectors as rankedGrowthSelectors } from "modules/customer/insights/portfolio/sales/rankedGrowth";
import { selectors as productCategoryMixSelectors } from "modules/customer/insights/portfolio/sales/productCategoryMix";
import { selectors as productCategoryGrowthSelectors } from "modules/customer/insights/portfolio/sales/productCategoryGrowth";

import { selectors as storeSizeSelectors } from "modules/customer/insights/portfolio/performanceDrivers/storeSize";
import { selectors as staffingSelectors } from "modules/customer/insights/portfolio/performanceDrivers/staffing";

import { selectors as grossProfitMarginSelectors } from "modules/customer/insights/portfolio/profit/grossProfitMargin";
import { selectors as rankedGrossProfitMarginSelectors } from "modules/customer/insights/portfolio/profit/rankedGrossProfitMargin";
import { selectors as netProfitSelectors } from "modules/customer/insights/portfolio/profit/netProfit";

import { selectors as openingsAndClosuresSelectors } from "modules/customer/insights/portfolio/areaHealth/openingsAndClosures";
import { selectors as marketCategoryBreakdownSelectors } from "modules/customer/insights/portfolio/areaHealth/marketCategoryBreakdown";
import { selectors as supplyAndDemandSelectors } from "modules/customer/insights/portfolio/areaHealth/supplyAndDemand";
import { selectors as retailDensitySelectors } from "modules/customer/insights/portfolio/areaHealth/retailDensity";
import { selectors as marketCategorySpendSelectors } from "modules/customer/insights/portfolio/areaHealth/marketCategorySpend";

import { selectors as competitionScoreSelectors } from "modules/customer/insights/portfolio/competition/competitionScore";
import { selectors as revenueVsCompetitionScoreSelectors } from "modules/customer/insights/portfolio/competition/revenueVsCompetitionScore";
import { selectors as localAreaCompetitionSelectors } from "modules/customer/insights/portfolio/competition/localAreaCompetition";
import { selectors as marketShareSelectors } from "modules/customer/insights/portfolio/competition/marketShare";

import { selectors as customerProfilesSelectors } from "modules/customer/insights/portfolio/catchment/customerProfiles";
import { selectors as footfallSelectors } from "modules/customer/insights/portfolio/catchment/footfall";
import { selectors as demographicBreakdownSelectors } from "modules/customer/insights/portfolio/catchment/demographicBreakdown";

import { scrollIntoViewById } from "utils/scrollUtils";

const allRags = createSelector(
    state => selectSubchaptersIds(state),
    revenueSelectors.storeHistoricSalesTrend,
    revenueSelectors.storeFutureSalesTrend,
    growthSelectors.storeHistoricGrowthVsComparator,
    growthSelectors.storeForecastGrowthVsComparator,
    rankedGrowthSelectors.rollingGrowthClassification,
    productCategoryMixSelectors.storeDependencyOnProductOrProductCategory,
    productCategoryMixSelectors.storeBestProductsOrProductCategoriesForecast,
    productCategoryGrowthSelectors.storeVsComparatorProductOrProductCategoryGrowth,
    storeSizeSelectors.storeContributionCategorisation,
    storeSizeSelectors.salesPerPoundPropCostCategorisation,
    staffingSelectors.payrollCostsPerSqftCategorisation,
    staffingSelectors.salesPerHeadCategorisation,
    staffingSelectors.salesPerPoundStaffCostCategorisation,
    grossProfitMarginSelectors.grossProfitTrend,
    rankedGrossProfitMarginSelectors.rollingGrossProfit,
    netProfitSelectors.profitabilityEvaluation,
    openingsAndClosuresSelectors.shortTermNetOpenings,
    openingsAndClosuresSelectors.longTermNetOpenings,
    marketCategoryBreakdownSelectors.complementaryMarketCat,
    marketCategoryBreakdownSelectors.nonComplementaryMarketCat,
    supplyAndDemandSelectors.supplyAndDemandRag,
    retailDensitySelectors.retailDensityRag,
    marketCategorySpendSelectors.marketCategoriesRag,
    competitionScoreSelectors.competitionScoreRag,
    revenueVsCompetitionScoreSelectors.revenueVsCompetitionScore,
    localAreaCompetitionSelectors.distanceToHotspotRag,
    localAreaCompetitionSelectors.sizeRelativeToDirectCompetitors,
    marketShareSelectors.marketShareTrend,
    customerProfilesSelectors.amountOfBestCustomers,
    footfallSelectors.footfallAnalysis,
    footfallSelectors.footfallPositioning,
    demographicBreakdownSelectors.representationOfBestDemographics,
    (subchaptersIds,
     storeHistoricSalesTrend,
     storeFutureSalesTrend,
     storeHistoricGrowthVsComparator,
     storeForecastGrowthVsComparator,
     rollingGrowthClassification,
     storeDependencyOnProductOrProductCategory,
     storeBestProductsOrProductCategoriesForecast,
     storeVsComparatorProductOrProductCategoryGrowth,
     storeContributionCategorisation,
     salesPerPoundPropCostCategorisation,
     payrollCostsPerSqftCategorisation,
     salesPerHeadCategorisation,
     salesPerPoundStaffCostCategorisation,
     grossProfitTrend,
     rollingGrossProfit,
     profitabilityEvaluation,
     shortTermNetOpenings,
     longTermNetOpenings,
     complementaryMarketCat,
     nonComplementaryMarketCat,
     supplyAndDemandRag,
     retailDensityRag,
     marketCategoriesRag,
     competitionScoreRag,
     revenueVsCompetitionScore,
     distanceToHotspotRag,
     sizeRelativeToDirectCompetitors,
     marketShareTrend,
     amountOfBestCustomers,
     footfallAnalysis,
     footfallPositioning,
     representationOfBestDemographics) => {
        return [{
            ...storeHistoricSalesTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenue)
        }, {
            ...storeFutureSalesTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.revenue)
        }, {
            ...storeHistoricGrowthVsComparator,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.growth)
        }, {
            ...storeForecastGrowthVsComparator,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.growth)
        }, {
            ...rollingGrowthClassification,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.rankedGrowth)
        }, {
            ...storeDependencyOnProductOrProductCategory,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.productCategoryMix)
        }, {
            ...storeBestProductsOrProductCategoriesForecast,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.productCategoryMix)
        }, {
            ...storeVsComparatorProductOrProductCategoryGrowth,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.sales.productCategoryGrowth)
        }, {
            ...storeContributionCategorisation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.performanceDrivers.storeSize)
        }, {
            ...salesPerPoundPropCostCategorisation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.performanceDrivers.storeSize)
        }, {
            ...payrollCostsPerSqftCategorisation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.performanceDrivers.staffing)
        }, {
            ...salesPerHeadCategorisation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.performanceDrivers.staffing)
        }, {
            ...salesPerPoundStaffCostCategorisation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.performanceDrivers.staffing)
        }, {
            ...grossProfitTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.profit.grossProfitMargin)
        }, {
            ...rollingGrossProfit,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.profit.rankedGrossProfitMargin)
        }, {
            ...profitabilityEvaluation,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.profit.netProfit)
        }, {
            ...shortTermNetOpenings,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.openingsAndClosures)
        }, {
            ...longTermNetOpenings,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.openingsAndClosures)
        }, {
            ...complementaryMarketCat,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.marketCategoryBreakdown)
        }, {
            ...nonComplementaryMarketCat,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.marketCategoryBreakdown)
        }, {
            ...supplyAndDemandRag,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.supplyAndDemand)
        }, {
            ...retailDensityRag,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.retailDensity)
        }, {
            ...marketCategoriesRag,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.areaHealth.marketCategorySpend)
        }, {
            ...competitionScoreRag,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.competition.competitionScore)
        }, {
            ...revenueVsCompetitionScore,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.competition.revenueVsCompetitionScore)
        }, {
            ...distanceToHotspotRag,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.competition.localAreaCompetition)
        }, {
            ...sizeRelativeToDirectCompetitors,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.competition.localAreaCompetition)
        }, {
            ...marketShareTrend,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.competition.marketShare)
        }, {
            ...amountOfBestCustomers,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.catchment.customerProfiles)
        }, {
            ...footfallAnalysis,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.catchment.footfall)
        }, {
            ...footfallPositioning,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.catchment.footfall)
        }, {
            ...representationOfBestDemographics,
            onRagClick: () => scrollIntoViewById(subchaptersIds?.catchment.demographicBreakdown)
        }];
    }
);

const selectors = {
    allRags
};

export default selectors;
