import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/profit/grossProfitMargin";

const COGSPastYear = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const cogsPastYear = useSelector(state => selectors.cogsPastYear(state));
    const { loading, error, value, percentageDifference } = cogsPastYear;
    const label = `${selectedStore.name} cost of goods sold in past 12 months vs the ${selectedComparator.name}`;
    const valueFormatted = numberFormatter.toGBP(value, 1);

    return (
        <Box width="100%" height="100%" data-cy="cogs-past-year">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default COGSPastYear;
