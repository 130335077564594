import React from "react";

import { SvgIcon } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";

const useStyles = makeStyles(theme => ({
    variantSuccess: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.background.default,
        fontSize: theme.spacing(2),
        fontWeight: 600
    },
    variantError: {
        backgroundColor: theme.palette.error.main,
        fontSize: theme.spacing(2),
        fontWeight: 600
    },
    variantInfo: {
        backgroundColor: theme.palette.info.main,
        fontSize: theme.spacing(2),
        fontWeight: 600
    },
    variantWarning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.background.default,
        fontSize: theme.spacing(2),
        fontWeight: 600
    },
}));

const StyledSvgIcon = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(1)
    }
}))(SvgIcon);

const oneSecondInMilliseconds = 1000;
const sixSeconds = 6 * oneSecondInMilliseconds;

const SnackbarProvider = (props) => {
    const classes = useStyles();

    return (
        <NotistackSnackbarProvider
            maxSnack={4}
            autoHideDuration={sixSeconds}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            classes={classes}
            iconVariant={{
                success: <StyledSvgIcon><CheckCircleOutlinedIcon /></StyledSvgIcon>,
                error: <StyledSvgIcon><CancelOutlinedIcon /></StyledSvgIcon>,
                warning: <StyledSvgIcon><ReportProblemOutlinedIcon /></StyledSvgIcon>,
                info: <StyledSvgIcon><InfoOutlinedIcon /></StyledSvgIcon>
            }}
        >
            {props.children}
        </NotistackSnackbarProvider>
    );
};

export default SnackbarProvider;
