import { operations as competitionScoreOperations } from "./competitionScore";
import { operations as revenueVsCompetitionScoreOperations } from "./revenueVsCompetitionScore";
import { operations as localAreaCompetitionOperations } from "./localAreaCompetition";
import { operations as marketShareOperations } from "./marketShare";

const getCompetitionData = () => async (dispatch) => {
    dispatch(competitionScoreOperations.getCompetitionScoreVis());
    dispatch(competitionScoreOperations.getCompetitionScoreRag());
    dispatch(revenueVsCompetitionScoreOperations.getStoreVsComparatorRevVsComp());
    dispatch(revenueVsCompetitionScoreOperations.getRevenueVsCompetitionScore());
    dispatch(localAreaCompetitionOperations.getLocalAreaCompetition());
    dispatch(marketShareOperations.getNumberOfHouseholds());
    dispatch(marketShareOperations.getMarketShareOverTime());
    dispatch(marketShareOperations.getOpeningsAndClosures());
};

const operations = {
    getCompetitionData
};

export default operations;
