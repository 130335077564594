import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/revenue";

const StoreRevenueVsPY = () => {
    const selectedStore = useAppSelector(selectStore);
    const storeRevenueVsPY = useSelector(state => selectors.storeRevenueVsPY(state));
    const { loading, error, value, percentageDifference } = storeRevenueVsPY;
    const valueFormatted = numberFormatter.toGBP(value, 1);
    const label = `${selectedStore.name} past 12 months revenue vs previous year`;

    return (
        <Box width="100%" height="100%" data-cy="store-revenue-vs-py">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} />
        </Box>
    );
};

export default StoreRevenueVsPY;
