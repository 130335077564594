export class OutputArea {
    public readonly code: string;
    public readonly netOpenings: number;

    constructor(
        code: string,
        netOpenings: number
    ) {
        this.code = code;
        this.netOpenings = netOpenings;
    }
}
