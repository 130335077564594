import { combineReducers } from "redux";

import types from "./types";

const competitionScoreVisEmpty = {
    loading: false,
    error: false,
    selectedStoreCompScore: 0,
    comparatorMedianCompScore: 0
};

const competitionScoreVis = (state = competitionScoreVisEmpty, action) => {
    switch (action.type) {
        case types.GET_COMPETITION_SCORE_VIS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                selectedStoreCompScore: 0,
                comparatorMedianCompScore: 0
            };
        case types.GET_COMPETITION_SCORE_VIS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                selectedStoreCompScore: action.payload.selectedStoreCompScore,
                comparatorMedianCompScore: action.payload.comparatorMedianCompScore
            };
        case types.GET_COMPETITION_SCORE_VIS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                selectedStoreCompScore: 0,
                comparatorMedianCompScore: 0
            };
        default:
            return state;
    }
};

const competitionScoreRagEmpty = {
    loading: false,
    error: false,
    id: "competition-score",
    label: "Competition score",
    status: "info",
    value: ""
};

const competitionScoreRag = (state = competitionScoreRagEmpty, action) => {
    switch (action.type) {
        case types.GET_COMPETITION_SCORE_RAG_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                status: "info",
                value: ""
            };
        case types.GET_COMPETITION_SCORE_RAG_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                status: action.payload.status,
                value: action.payload.value
            };
        case types.GET_COMPETITION_SCORE_RAG_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: "info",
                value: ""
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    competitionScoreVis,
    competitionScoreRag
});

export default reducer;
