import React from "react";

import { useAppSelector } from "store";
import { CostChapter, selectCurrentChapter, selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";

import CostOverview from "./costOverview/CostOverview";
import CostDrivers from "./costDrivers/CostDrivers";
import StoreCosts from "./storeCosts/StoreCosts";

const Insight: React.FC = () => {
    const currentChapter = useAppSelector(selectCurrentChapter);
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);

    return (
        <>
            {currentChapter === CostChapter.CostOverview && (
                <CostOverview />
            )}
            {currentChapter === CostChapter.CostDrivers && (
                <CostDrivers />
            )}
            {(currentChapter === CostChapter.StoreCosts && selectedStore) && (
                <StoreCosts />
            )}
        </>
    );
};

export default Insight;
