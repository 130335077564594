import React from "react";

import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import StoreDetails from "./StoreDetails";
import StoreVsComparatorPerformance from "./StoreVsComparatorPerformance";
import ComparatorStoreDetails from "./ComparatorStoreDetails";

const StoreSummary: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const scrollMarginTop = isMobile ? theme.spacing(18) : theme.spacing(3);

    return (
        <Box style={{ scrollMarginTop: scrollMarginTop }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <StoreDetails />
                </Grid>
                <Grid item md={6} xs={12}>
                    <StoreVsComparatorPerformance />
                </Grid>
                <Grid item xs={12}>
                    <ComparatorStoreDetails />
                </Grid>
            </Grid>
        </Box>
    );
};

export default StoreSummary;
