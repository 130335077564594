import React from "react";

import { Grid, Typography } from "@material-ui/core";

import Page from "components/admin/Page";

import AddCategory from "./AddCategory";
import CategoriesTable from "./CategoriesTable";

import { selectUserInfo } from "modules/auth/authSlice";
import { getCategories } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const FrequentlyAskedQuestions: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);

    React.useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    return (
        <Page dataCy="pages-admin-frequently-asked-questions">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        KPMG Admin - {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Frequently asked questions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={2}>
                            <AddCategory />
                        </Grid>
                        <Grid item xs={10} />
                        <Grid item xs={12}>
                            <CategoriesTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
};

export default FrequentlyAskedQuestions;
