const storeRevenueVsPY = (state) => {
    return state.customer.insights.portfolio.sales.revenue.storeRevenueVsPY;
};

const storeForecastRevenueVsPY = (state) => {
    return state.customer.insights.portfolio.sales.revenue.storeForecastRevenueVsPY;
};

const salesHistory = (state) => {
    return state.customer.insights.portfolio.sales.revenue.salesHistory;
};

const storeHistoricSalesTrend = (state) => {
    return state.customer.insights.portfolio.sales.revenue.storeHistoricSalesTrend;
};

const storeFutureSalesTrend = (state) => {
    return state.customer.insights.portfolio.sales.revenue.storeFutureSalesTrend;
};

const selectors = {
    storeRevenueVsPY,
    storeForecastRevenueVsPY,
    salesHistory,
    storeHistoricSalesTrend,
    storeFutureSalesTrend
};

export default selectors;
