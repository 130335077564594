import React from "react";

import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { useTheme, withStyles } from "@material-ui/core/styles";

import numberFormatter from "utils/numberFormatter";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import { useAppDispatch, useAppSelector } from "store";
import {
    clearLead,
    getLead,
    getPrices,
    getProducts,
    getTaxRates,
    hideSendQuote,
    selectLead,
    selectPrices,
    selectProducts,
    selectSendQuoteVisibility,
    sendQuote,
    showSendQuote,
    selectTaxRates
} from "modules/admin/leads/leadsSlice";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface SendQuoteProps {
    leadId: string;
}

const SendQuote: React.FC<SendQuoteProps> = (props) => {
    const { leadId } = props;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const lead = useAppSelector(selectLead);
    const products = useAppSelector(selectProducts);
    const product = products?.find(product => product.id === lead.productId);
    const prices = useAppSelector(selectPrices);
    const price = prices?.find(price => price.id === lead.priceId);
    const taxRates = useAppSelector(selectTaxRates);
    const taxRate = taxRates?.find(taxRate => taxRate.id === lead.taxRateId);
    const sendQuoteVisibility = useAppSelector(selectSendQuoteVisibility);
    const show = (sendQuoteVisibility.isVisible) && (leadId === sendQuoteVisibility.leadId);
    const details = [{
        title: "Name",
        value: `${lead.firstName} ${lead.lastName}`
    }, {
        title: "Company",
        value: lead.companyName
    }, {
        title: "Company registration number",
        value: lead.companyRegistrationNumber
    }, {
        title: "Email",
        value: lead.email
    }, {
        title: "Product",
        value: product?.name
    }, {
        title: "Price",
        value: price?.priceInPence
            ? `${numberFormatter.toGBP(price.priceInPence / 100, 2, false)} / month`
            : ""
    }, {
        title: "Tax rate",
        value: taxRate?.name
            ? `${taxRate.name} (${taxRate.percentage}% ${taxRate.isInclusive ? "inclusive" : "exclusive"})`
            : ""
    }, {
        title: "Initial term",
        value: lead.initialTermInYears
            ? `${lead.initialTermInYears} ${lead.initialTermInYears === 1 ? "year" : "years"}`
            : ""
    }];

    React.useEffect(() => {
        if (show) {
            dispatch(getLead(leadId));
            dispatch(getProducts());
            dispatch(getPrices());
            dispatch(getTaxRates());
        }
    }, [dispatch, show, leadId]);

    const handleSendQuoteClick = () => {
        dispatch(showSendQuote(leadId));
    };

    const handleConfirmClick = () => {
        dispatch(sendQuote());
    };

    const handleCancelClick = () => {
        dispatch(hideSendQuote());
        dispatch(clearLead());
    };

    return (
        <>
            {/* @ts-ignore*/}
            <SimpleTooltip title="Send quote">
                <StyledIconButton size="small" onClick={handleSendQuoteClick}>
                    <ReceiptIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                backgroundColor={theme.palette.primary.main}
                title={
                    <Typography variant="h2" component="div" gutterBottom>
                        Are the details correct?
                    </Typography>
                }
                titleAlign="center"
                content={
                    <Box paddingX={6}>
                        <Grid container>
                            {details.map((detail, index) =>
                                <React.Fragment key={index}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {detail.title}:&nbsp;&nbsp;
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {detail.value}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Box>
                }
                actions={
                    <>
                        <Button variant="outlined" onClick={handleCancelClick}>
                            No, cancel
                        </Button>
                        <Button variant="contained" color="default" disableElevation onClick={handleConfirmClick}>
                            Yes, send quote
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default SendQuote;
