import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Column from "./Column";

const Bar = (props) => {
    const { loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: "bar"
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <Column loading={loading} error={error} options={options} dataCy="bar-chart" />
    );
};

Bar.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Bar.defaultProps = {
    loading: false,
    error: false
};

export default Bar;
