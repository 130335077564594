import React from "react";
import { Marker as MapGLMarker } from "react-map-gl";

import { Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useTheme } from "@material-ui/core/styles";

import SimpleTooltip, { SimpleTooltipBackgroundColour, SimpleTooltipPlacement } from "components/SimpleTooltip";
import useColourPalette from "components/visuals/useColourPalette";

import { Store } from "modules/customer/tools/location/areaHealth/store";

interface MarkerProps {
    store: Store
}

const Marker: React.FC<MarkerProps> = (props) => {
    const { store } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    // @ts-ignore
    const colour = store.hasOpened ? theme.palette.tertiary.main : colourPalette.categorical[4];

    return (
        <MapGLMarker
            latitude={store.latitude}
            longitude={store.longitude}
            offsetTop={-10}
            offsetLeft={-12}
        >
            <SimpleTooltip
                placement={SimpleTooltipPlacement.Bottom}
                backgroundColour={SimpleTooltipBackgroundColour.Quaternary}
                title={
                    <Typography variant="body2" component="div" align="center" style={{ fontWeight: "bold" }}>
                        {store.name}
                    </Typography>
                }
            >
                <FiberManualRecordIcon fontSize="small" cursor="pointer" style={{ color: colour }} />
            </SimpleTooltip>
        </MapGLMarker>
    );
};

export default Marker;
