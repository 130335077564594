import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Analyse your {store?.name} store's staff productivity through metrics like revenue per head and revenue per
            £ of staff cost. Evaluate whether your store is bloated, adequately staffed, or running lean. Benchmark
            against {comparator?.name} in these metrics to identify areas for investigation. Utilise these insights to
            make informed decisions during contract renewals and compensation reviews.
        </>
    );
};

export default Subtitle;
