import React from "react";

import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "store";
import { selectWeightSpendByProbability, toggleWeightSpendByProbability } from "modules/customer/tools/location/spendNew/spendSlice";

const StyledSwitch = withStyles(theme => ({
    switchBase: {
        "&$checked": {
            // @ts-ignore
            color: theme.palette.tertiary.main,
        },
        "&$checked + $track": {
            // @ts-ignore
            backgroundColor: theme.palette.tertiary.main
        }
    },
    checked: {},
    track: {}
}))(Switch);

const WeightSpendByProbabilitySwitch: React.FC = () => {
    const dispatch = useAppDispatch();
    const weightSpendByProbability = useAppSelector(selectWeightSpendByProbability);

    const onChange = () => {
        dispatch(toggleWeightSpendByProbability());
    };

    const probabilitySwitch = (<StyledSwitch
        size="small"
        color="default"
        checked={weightSpendByProbability}
        onChange={onChange}
    />);

    return (
        <FormControlLabel
            control={probabilitySwitch}
            label="Weight spend by likelihood of visiting"
        />

    );
};

export default WeightSpendByProbabilitySwitch;
