import { createSelector } from "reselect";

const budgetVsForecastSales = (state) => {
    return state.customer.insights.performance.budget.budgetVsForecastRevenue.budgetVsForecastSales;
};

const budgetedSalesVsForecastSales = createSelector(
    budgetVsForecastSales,
    (budgetVsForecastSales) => {
        const rag = {
            loading: false,
            error: false,
            id: "budgeted-vs-forecast-sales",
            label: "Budgeted vs forecast sales",
            status: "info",
            value: ""
        };

        if (budgetVsForecastSales.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (budgetVsForecastSales.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const quarterlySales = budgetVsForecastSales.quarterlySales;
        const quarterlyBudget = budgetVsForecastSales.quarterlyBudget;

        if (quarterlyBudget.length === 0) {
            rag.status = "noData";
            rag.value = "No Data Available";
            return rag;
        }

        const forecast = quarterlySales.reduce((total, item) => item.sales + total, 0);
        const budget = quarterlyBudget.reduce((total, item) => item.budget + total, 0);

        const x = ((forecast - budget) / forecast) * 100;

        if (x > 50) {
            rag.status = "error";
            rag.value = "Significantly over budgeting sales relative to Dash's Forecast";
        } else if (x < -50) {
            rag.status = "error";
            rag.value = "Significantly under budgeting sales relative to Dash's Forecast";
        } else if (x <= -25) {
            rag.status = "warning";
            rag.value = "Slightly under budgeting sales relative to Dash's Forecast";
        } else if (x >= 25) {
            rag.status = "warning";
            rag.value = "Slightly over budgeting sales relative to Dash's Forecast";
        } else {
            rag.status = "success";
            rag.value = "Sales budget is broadly in line with forecast";
        }
        return rag;
    }
);

const selectors = {
    budgetVsForecastSales,
    budgetedSalesVsForecastSales
};

export default selectors;
