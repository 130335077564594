import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const HistoricShortTermCompanySalesTrend = () => {

    const dispatch = useDispatch();
    const historicShortTermCompanySalesTrend = useSelector(state => selectors.historicShortTermCompanySalesTrend(state));
    const { loading, error, id, label, status, value } = historicShortTermCompanySalesTrend;

    React.useEffect(() => {
        dispatch(operations.getHistoricShortTermCompanySalesTrend());
    }, [dispatch]);

    return (
        <Box data-cy={id}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default HistoricShortTermCompanySalesTrend;
