import React from "react";

import { IconButton } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import Title from "./Title";
import Content from "./Content";

import { useAppDispatch, useAppSelector } from "store";
import {
    selectInformationVisibility,
    showInformation,
    hideInformation
} from "modules/customer/tools/location/information/informationSlice";

const Information = () => {
    const dispatch = useAppDispatch();
    const informationVisibility = useAppSelector(selectInformationVisibility);

    const handleOpenClick = () => {
        dispatch(showInformation());
    };

    const handleCloseClick = () => {
        dispatch(hideInformation());
    };

    return (
        <>
            <IconButton color="inherit" onClick={handleOpenClick}>
                <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>
            <TwoPanelDialog
                open={informationVisibility.isVisible}
                onClose={handleCloseClick}
                leftPanelTitle={<Title />}
                leftPanelActionsJustifyContent={JustifyContent.FlexStart}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default Information;
