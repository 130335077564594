import types from "./types";

const getMarketCatSpendPerHeadRequest = () => ({
    type: types.GET_MARKET_CAT_SPEND_PER_HEAD_REQUEST
});

const getMarketCatSpendPerHeadSuccess = (mapCenter, spendPerHead, comparatorSpendPerHead) => ({
    type: types.GET_MARKET_CAT_SPEND_PER_HEAD_SUCCESS,
    payload: {
        mapCenter,
        spendPerHead,
        comparatorSpendPerHead
    }
});

const getMarketCatSpendPerHeadFailure = () => ({
    type: types.GET_MARKET_CAT_SPEND_PER_HEAD_FAILURE
});

const getMarketCategoriesListRequest = () => ({
    type: types.GET_MARKET_CATEGORIES_LIST_REQUEST
});

const getMarketCategoriesListSuccess = (marketCategories) => ({
    type: types.GET_MARKET_CATEGORIES_LIST_SUCCESS,
    payload: {
        marketCategories
    }
});

const getMarketCategoriesListFailure = () => ({
    type: types.GET_MARKET_CATEGORIES_LIST_FAILURE
});

const selectMarketCategory = (marketCategory) => ({
    type: types.SELECT_MARKET_CATEGORY,
    payload: {
        marketCategory
    }
});

const deselectMarketCategory = (marketCategory) => ({
    type: types.DESELECT_MARKET_CATEGORY,
    payload: {
        marketCategory
    }
});

const clearSelectedMarketCategories = () => ({
    type: types.CLEAR_SELECTED_MARKET_CATEGORIES
});

const actions = {
    getMarketCatSpendPerHeadRequest,
    getMarketCatSpendPerHeadSuccess,
    getMarketCatSpendPerHeadFailure,
    getMarketCategoriesListRequest,
    getMarketCategoriesListSuccess,
    getMarketCategoriesListFailure,
    selectMarketCategory,
    deselectMarketCategory,
    clearSelectedMarketCategories
};

export default actions;
