import React from "react";
import Highcharts, { dateFormat } from "highcharts";
import { DateTime } from "luxon";

import { Box } from "@material-ui/core";

import StockLine from "components/visuals/StockLine";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppDispatch, useAppSelector } from "store";
import {
    CostTypeMeasure,
    TotalCostTrendsOverTimeLineChartGranularity,
    selectHasErrors,
    selectIsLoading,
    selectCostTypeMeasure,
    selectTotalCostTrendsOverTime,
    setTotalCostTrendsOverTimeLineChartGranularity,
    selectTotalCostTrendsOverTimeLineChartGranularity
} from "modules/customer/insights/cost/costOverview/costOverviewSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface CostOverTime {
    date: number,
    group: string, 
    costValue: number,
    costPercentageOfRevenue: number
}

const TotalCostTrendsOverTime: React.FC = () => {
    const dispatch = useAppDispatch();
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const costTypeMeasure = useAppSelector(selectCostTypeMeasure);
    const totalCostsOverTime = useAppSelector(selectTotalCostTrendsOverTime);
    const chartGranularity = useAppSelector(selectTotalCostTrendsOverTimeLineChartGranularity);

    const series: Highcharts.Series[] = [];
    const navigationSeries: any[] = [];

    Object.keys(totalCostsOverTime).forEach(key => {
        series.push({
            name: key,
            // @ts-ignore
            data: totalCostsOverTime[key].map(cost =>
                [DateTime.fromISO(cost.date.toISODate(), { zone: "utc" }).toMillis(), (costTypeMeasure === CostTypeMeasure.CostType ? cost.costValue : cost.costPercentageOfRevenue)]
            ),
            color: colourPalette.categorical[Object.keys(totalCostsOverTime).indexOf(key)]
        });

        navigationSeries.push({
            type: "spline",
            data: totalCostsOverTime[key].map(cost =>
                [DateTime.fromISO(cost.date.toISODate(), { zone: "utc" }).toMillis(), (costTypeMeasure === CostTypeMeasure.CostType ? cost.costValue : cost.costPercentageOfRevenue)]
            )
        });
    });

    const tooltipGetOtherValue = (date: number, group: string): number => {
        if (totalCostsOverTime) {
            const filteredCostOverTimeByGroup = totalCostsOverTime[group];
            const filteredCostOverTimeByDate: CostOverTime[] = [];

            for (let i = 0; i < filteredCostOverTimeByGroup.length; i++) {
                if (DateTime.fromISO(filteredCostOverTimeByGroup[i].date.toISODate(), { zone: "utc" }).toMillis() === date) {
                    filteredCostOverTimeByDate.push({
                        date: DateTime.fromISO(filteredCostOverTimeByGroup[i].date.toISODate(), { zone: "utc" }).toMillis(),
                        group: filteredCostOverTimeByGroup[i].group,
                        costValue: filteredCostOverTimeByGroup[i].costValue,
                        costPercentageOfRevenue: filteredCostOverTimeByGroup[i].costPercentageOfRevenue
                    });
                }
            }

            let result = 0;

            for (let i = 0; i < filteredCostOverTimeByDate.length; i++) {
                result += (costTypeMeasure === CostTypeMeasure.CostType ? filteredCostOverTimeByDate[i].costPercentageOfRevenue : filteredCostOverTimeByDate[i].costValue);
            }

            return result;
        }
        return 0;
    };

    const options = {
        yAxis: {
            title: {
                text: (costTypeMeasure === CostTypeMeasure.CostType ? "Costs (M)" : "Cost as a % of revenue")
            },
        },
        legend: {
            enabled: true
        },
        series,
        navigator: {
            series: navigationSeries,
        },
        rangeSelector: {
            allButtonsEnabled: true,
            selected: 0,
            buttons: [{
                type: "month",
                count: 12,
                text: "Month",
                dataGrouping: {
                    forced: true,
                    units: [["month", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setTotalCostTrendsOverTimeLineChartGranularity(TotalCostTrendsOverTimeLineChartGranularity.Month));
                    }
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 24,
                text: "Quarter",
                dataGrouping: {
                    forced: true,
                    units: [["month", [3]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setTotalCostTrendsOverTimeLineChartGranularity(TotalCostTrendsOverTimeLineChartGranularity.Quarter));
                    }
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 3,
                text: "Year",
                dataGrouping: {
                    forced: true,
                    units: [["year", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                events: {
                    click: function () {
                        dispatch(setTotalCostTrendsOverTimeLineChartGranularity(TotalCostTrendsOverTimeLineChartGranularity.Year));
                    }
                },
                preserveDataGrouping: true
            }]
        },
        tooltip: {
            enabled: true,
            shared: true,
            headerFormat: ``,
            footerFormat: ``,
            // @ts-ignore
            formatter: function () {
                let header = "";
                
                if (chartGranularity === TotalCostTrendsOverTimeLineChartGranularity.Year) {
                    // @ts-ignore
                    header = dateFormat("%Y", this.points[0].x);
                }
                else if (chartGranularity === TotalCostTrendsOverTimeLineChartGranularity.Month) {
                    // @ts-ignore
                    header = dateFormat("%B %Y", this.points[0].x);
                }
                else {
                    // @ts-ignore
                    const month = DateTime.fromMillis(this.points[0].x).month;
                    const quarter = Math.ceil(month / 3);
                    // @ts-ignore
                    header = `Q${quarter} ` + dateFormat("%Y", this.points[0].x);
                }

                const categoriesArr: string[] = [];
                const categoriesFormatArr: string[] = [];
                const valuesArr: string[] = [];

                // @ts-ignore
                this.points.forEach((point, index) => {
                    costTypeMeasure === CostTypeMeasure.CostType ? categoriesArr.push(point.series.name, "Cost value", "Cost as % revenue") : categoriesArr.push(point.series.name, "Cost as % revenue", "Cost value");
                    categoriesFormatArr.push(`color:${point.series.color};font-weight:bold`, "", "");

                    const costValue = costTypeMeasure === CostTypeMeasure.CostType ? numberFormatter.toGBP(point.y) : numberFormatter.toGBP(tooltipGetOtherValue(point.x, point.series.name));
                    const percentageValue = costTypeMeasure === CostTypeMeasure.CostTypePercentageOfRevenue ? numberFormatter.toPercentage(point.y, true, 1) : numberFormatter.toPercentage(tooltipGetOtherValue(point.x, point.series.name), true, 1);
                    costTypeMeasure === CostTypeMeasure.CostType ? valuesArr.push("", costValue, percentageValue) : valuesArr.push("", percentageValue, costValue);
                });

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr
                    }
                )}</table>`;
            },
            useHTML: true
        }
    };

    return (
        <Box data-cy="total-cost-trends-over-time">
            <StockLine loading={isLoading} error={hasErrors} options={options} globalOptions={{}} />
        </Box>
    );
};

export default TotalCostTrendsOverTime;
