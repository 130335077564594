import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import actions from "./actions";

const getTypesOfSalesGrowth = () => async (dispatch) => {
    dispatch(actions.getTypesOfSalesGrowthRequest());
    try {
        const query = {
            measures: ["F_SalesGrowth.SumGrowthValue"],
            order: [
                ["F_SalesGrowth.OrderRank", "asc"],
            ],
            dimensions: ["F_SalesGrowth.GrowthCategory", "F_SalesGrowth.SegmentDescription", "F_SalesGrowth.OrderRank"]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const data = resultSet.loadResponses[0].data.map(item => ({
            growthValue: item["F_SalesGrowth.SumGrowthValue"],
            growthCategory: item["F_SalesGrowth.GrowthCategory"],
            segmentDescription: item["F_SalesGrowth.SegmentDescription"],
            orderRank: item["F_SalesGrowth.OrderRank"]
        }));
        dispatch(actions.getTypesOfSalesGrowthSuccess(data));
    }
    catch (error) {
        dispatch(actions.getTypesOfSalesGrowthFailure());
        dispatch(logError("Error loading TypesOfSalesGrowth.", error));
    }
};

const operations = {
    getTypesOfSalesGrowth
};

export default operations;
