import React from "react";

import { Step, StepLabel, Stepper as MuiStepper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import StepIcon from "./StepIcon";

import { selectActiveGenerateRevenuePredictionStep } from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";


const StyledStepper = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        paddingLeft: theme.spacing(0)
    }
}))(MuiStepper);

const Stepper: React.FC = () => {
    const activeGenerateRevenuePredictionStep = useAppSelector(selectActiveGenerateRevenuePredictionStep);

    return (
        <StyledStepper activeStep={activeGenerateRevenuePredictionStep} orientation="vertical">
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Setup prediction
                    </Typography>
                </StepLabel>
            </Step>
            <Step expanded>
                <StepLabel StepIconComponent={StepIcon}>
                    <Typography variant="subtitle1" component="div">
                        Input store parameters
                    </Typography>
                </StepLabel>
            </Step>
        </StyledStepper>
    );
};

export default Stepper;
