import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Column from "components/visuals/Column";

import StoreCategoriesFilter from "./StoreCategoriesFilter";
import TimelineFilter from "./TimelineFilter";

import {
    selectIsLoading,
    selectHasErrors,
    selectStoreCategoryOpeningsVsClosures
} from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const StoreCategoryOpeningsVsClosures: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading: boolean = useAppSelector(selectIsLoading);
    const hasErrors: boolean = useAppSelector(selectHasErrors);
    const selectedStore = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const storeCategoryOpeningsVsClosures = useAppSelector(selectStoreCategoryOpeningsVsClosures);
    const retailCentreSeries: Highcharts.PointOptionsObject[] = [];
    const comparatorSeries: Highcharts.PointOptionsObject[] = [];
    storeCategoryOpeningsVsClosures.forEach(row => {
        retailCentreSeries.push({
            name: row.storeCategory,
            y: row.selectedStore.percentageChange,
            custom: {
                netOpenings: row.selectedStore.netOpenings
            }
        });
        comparatorSeries.push({
            name: row.storeCategory,
            y: row.comparator.percentageChange,
            custom: {
                netOpenings: row.comparator.netOpenings
            }
        });
    });

    const options: Highcharts.Options = {
        xAxis: {
            type: "category"
        },
        yAxis: {
            labels: {
                format: `{text}%`
            },
            title: {
                text: "% change in the number of stores"
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [
            {
                type: "column",
                name: selectedStore?.name,
                data: retailCentreSeries,
                color: colourPalette.comparators[0]
            }, {
                type: "column",
                name: comparator?.name,
                data: comparatorSeries,
                color: colourPalette.comparators[1]
            }
        ],
        legend: {
            enabled: true
        },
        tooltip: {
            enabled: true,
            shared: false,
            headerFormat: ``,
            footerFormat: ``,
            formatter: function () {
                const point = this;
                const categories: string[] = ["% change in the<br>number of stores ", "Number of net<br>openings"];
                const percentageChange: number = point?.y ?? 0;
                const percentageChangeSign = percentageChange > 0 ? "+" : "";
                const percentageChangeTooltip = `${percentageChangeSign}${numberFormatter.toPercentage(percentageChange, true, 0)}`;
                const netOpeningsTooltip = `${numberFormatter.toDecimalPlaces(point?.point.options.custom?.netOpenings ?? 0, 0)}`;
                const values = [percentageChangeTooltip, netOpeningsTooltip];

                return `<table>${stringUtils.tooltipHTML(categories, {
                    header: point.key,
                    values,
                    valueFormatting: `color:${point.color}`
                })}</table>`;
            },
            useHTML: true
        }
    };

    return (
        <Box data-cy="market-category-openings-vs-closures">
            <Typography variant="h6" component="div">
                (%) change in the number of stores per category within the local area of your {selectedStore?.name} store vs {comparator?.name}
            </Typography>
            <StoreCategoriesFilter />
            <Column error={hasErrors} loading={isLoading} options={options} />
            <TimelineFilter />
        </Box>
    );
};

export default StoreCategoryOpeningsVsClosures;
