import React from "react";

import { Grid } from "@material-ui/core";

import Insight from "./insight/Insight";
import Navigation from "./navigation/Navigation";
import {
    clearInsights,
    clearProduct,
    clearStoreProductFilters,
    loadInsights,
    loadProduct,
    loadStoreFilters,
    selectSelectedPartner,
    selectSelectedStore
} from "modules/customer/tools/product/productSlice";
import { useAppDispatch, useAppSelector } from "store";
import { showPartnerFilters } from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";

const Product: React.FC = () => {
    const dispatch = useAppDispatch();
    const partner = useAppSelector(selectSelectedPartner);
    const store = useAppSelector(selectSelectedStore);
    const isSetupComplete = !!partner;

    React.useEffect(() => {
        dispatch(loadProduct());
        dispatch(showPartnerFilters());
        return () => {
            dispatch(clearProduct());
        };
    }, [dispatch]);

    // If partner changes, reload insights & modal
    React.useEffect(() => {
        if (partner) {
            dispatch(loadInsights());
            dispatch(loadStoreFilters());
        }
        return () => {
            dispatch(clearInsights());
            dispatch(clearStoreProductFilters());
        };
    }, [dispatch, partner]);

    // If store changes, reload insights only
    React.useEffect(() => {
        dispatch(loadInsights());
        return () => {
            dispatch(clearInsights());
        };
    }, [dispatch, store]);
    return (
        <Grid container data-cy="pages-customer-tools-product">
            <Grid item xs={3}>
                <Navigation isSetupComplete={isSetupComplete} />
            </Grid>
            <Grid item xs={9}>
                <Insight isSetupComplete={isSetupComplete} />
            </Grid>
        </Grid>
    );
};

export default Product;
