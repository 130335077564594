import React from "react";
import { Grid } from "@material-ui/core";

import CatchmentAreaReach from "./CatchmentAreaReach";
import TotalPeopleInCatchmentArea from "./TotalPeopleInCatchmentArea";
import CatchmentAreaPenPortraitClassification from "./CatchmentAreaPenPortraitClassification";
import AmountOfBestCustomers from "./AmountOfBestCustomers";
import PenPortraitTreemap from "./PenPortraitTreemap";
import PenPortraitsSearch from "./PenPortraitsSearch";

const CustomerProfiles = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <CatchmentAreaReach />
            </Grid>
            <Grid item xs={12} md={6}>
                <TotalPeopleInCatchmentArea />
            </Grid>
            <Grid item xs={12}>
                <CatchmentAreaPenPortraitClassification />
            </Grid>
            <Grid item xs={12}>
                <PenPortraitTreemap />
            </Grid>
            <Grid item xs={12}>
                <PenPortraitsSearch />
            </Grid>
            <Grid item xs={12}>
                <AmountOfBestCustomers />
            </Grid>
        </Grid>
    );
};

export default CustomerProfiles;
