import React from "react";
import { Box } from "@material-ui/core";
import Highcharts from "highcharts";

import Column from "components/visuals/Column";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectCostReductionOpportunity } from "modules/customer/insights/cost/costOverview/costOverviewSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const CostReductionOpportunitiesChart = () => {
    const colourPalette = useColourPalette();
    const costReductionOpportunity = useAppSelector(selectCostReductionOpportunity);
    const { isLoading, hasErrors, data } = costReductionOpportunity;

    const series: Highcharts.Series[] = [];

    Object.keys(data).forEach(key => {
        const reducedData = data[key].reduce((acc, curr) => {
            const existing = acc.find(item => item[0] === curr.costName);

            if (existing) {
                // @ts-ignore
                existing[1] += curr.opportunityValue;
            } else {
                // @ts-ignore
                acc.push([curr.costName, curr.opportunityValue]);
            }

            return acc;
        }, []);

        // @ts-ignore
        series.push({
            name: key,
            // @ts-ignore
            data: reducedData,
            color: colourPalette.categorical[Object.keys(data).indexOf(key)],
            type: "column"
        });
    });

    const options = {
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: "Cost reduction opportunity"
            },
            labels: {
                // @ts-ignore
                formatter: function () { return numberFormatter.toGBP(this.value, 1); }
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        legend: {
            enabled: true
        },
        series,
        tooltip: {
            enabled: true,
            shared: true,
            useHTML: true,

            formatter: function (): string | undefined {
                const categoriesArr: string[] = [];
                const categoriesFormatArr: string[] = [];
                const valuesArr: string[] = [];

                // @ts-ignore
                this.points.forEach((point, index) => {
                    categoriesArr.push(point.series.name, "Total costs");
                    categoriesFormatArr.push(`color:${point.series.color};font-weight:bold`, "");

                    const opportunityValue = numberFormatter.toGBP(point.y);
                    
                    valuesArr.push(
                        "",
                        opportunityValue
                    );
                });

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: "",
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr
                    }
                )}</table>`;
            }
        }
    };

    return (
        <Box data-cy="historic-prod-growth-store-vs-comp">
            <Column loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default CostReductionOpportunitiesChart;
