import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectDemographicIndicatorsInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";

const HouseholdIncome: React.FC = () => {
    const title = "Household income (Affluence)";
    const categories = [
        "<£20,000",
        "£20,000-£29,999",
        "£30,000-£39,999",
        "£40,000-£49,999",
        "£50,000-£59,999",
        "£60,000-£69,999",
        "£70,000-£79,999",
        "£80,000-£89,999",
        "£90,000-£99,999",
        ">£100,000"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectDemographicIndicatorsInCatchmentArea);

    const retailCentre = demographicIndicatorsInCatchmentArea.retailCentre?.householdIncome;
    const retailCentreValues = retailCentre ? [
        retailCentre.countIncomeSub20k,
        retailCentre.countIncome20to30k,
        retailCentre.countIncome30to40k,
        retailCentre.countIncome40to50k,
        retailCentre.countIncome50to60k,
        retailCentre.countIncome60to70k,
        retailCentre.countIncome70to80k,
        retailCentre.countIncome80to90k,
        retailCentre.countIncome90to100k,
        retailCentre.countIncome100kPlus
    ] : [];
    const retailCentreTotal = retailCentreValues.reduce((sum, val) => sum + val, 0);

    const benchmark = demographicIndicatorsInCatchmentArea.benchmark?.householdIncome;
    const benchmarkValues = benchmark ? [
        benchmark.countIncomeSub20k,
        benchmark.countIncome20to30k,
        benchmark.countIncome30to40k,
        benchmark.countIncome40to50k,
        benchmark.countIncome50to60k,
        benchmark.countIncome60to70k,
        benchmark.countIncome70to80k,
        benchmark.countIncome80to90k,
        benchmark.countIncome90to100k,
        benchmark.countIncome100kPlus
    ] : [];
    const benchmarkTotal = benchmarkValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            retailCentreValues={retailCentreValues}
            benchmarkValues={benchmarkValues}
            retailCentreTotal={retailCentreTotal}
            benchmarkTotal={benchmarkTotal}
        />
    );
};

export default HouseholdIncome;
