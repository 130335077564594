import React from "react";

import { Box, Button, Checkbox, TextField, Typography } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { makeStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import {
    addCategory,
    clearCategory,
    hideAddCategory,
    selectAddCategoryVisibility,
    selectCategory,
    setCategory,
    showAddCategory
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const useStyles = makeStyles(theme => ({
    input: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

const AddCategory: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const category = useAppSelector(selectCategory);
    const addCategoryVisibility = useAppSelector(selectAddCategoryVisibility);
    const show = addCategoryVisibility.isVisible;

    const handleAddCategoryClick = () => {
        dispatch(showAddCategory());
    };

    const handleSubmitClick = () => {
        dispatch(addCategory());
    };

    const handleCancelClick = () => {
        dispatch(hideAddCategory());
        dispatch(clearCategory());
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newCategory = { ...category, name, errors: { ...category.errors, name: "" } };
        dispatch(setCategory(newCategory));
    };

    const handleShowOnLandingAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnLandingArea = event.target.checked;
        const newCategory = { ...category, showOnLandingArea };
        dispatch(setCategory(newCategory));
    };

    const handleShowOnCustomerAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnCustomerArea = event.target.checked;
        const newCategory = { ...category, showOnCustomerArea };
        dispatch(setCategory(newCategory));
    };

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddRoundedIcon />}
                disableElevation
                fullWidth
                onClick={handleAddCategoryClick}
            >
                Add category
            </Button>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Add category</Typography>
                }
                content={
                    <>
                        <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={category.name}
                            onChange={handleNameChange}
                            error={!!category.errors.name}
                            helperText={category.errors.name}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={category.showOnLandingArea}
                                onChange={handleShowOnLandingAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on landing area
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={category.showOnCustomerArea}
                                onChange={handleShowOnCustomerAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on customer area
                            </Typography>
                        </Box>
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Add category
                    </Button>
                }
            />
        </>
    );
};

export default AddCategory;
