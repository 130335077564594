import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import StockLine from "components/visuals/StockLine";

import dateUtils from "utils/dateUtils";
import arrayUtils from "utils/arrayUtils";
import numberFormatter from "utils/numberFormatter";

import _ from "lodash";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const SalesHistory = () => {
    const dispatch = useDispatch();

    const salesHistory = useSelector(state => selectors.salesHistory(state));
    const { loading, error, values } = salesHistory;

    const sales = arrayUtils.arrayColumn(values, "sales");
    const dates = dateUtils.datesToTime(arrayUtils.arrayColumn(values, "date"));
    const weeks = dateUtils.datesToTime(arrayUtils.arrayColumn(values, "week"));
    const dataValues = _.zip(dates, sales, weeks);

    const options = {
        title: {
            text: "Revenue over time"
        },
        yAxis: {
            title: {
                text: "Revenue"
            },
            labels: {
                formatter: function () { 
                    const noDpTest = numberFormatter.toDecimalPlaces(this.value, 0);
                    const oneDpTest = numberFormatter.toDecimalPlaces(this.value, 1);

                    if(parseFloat(noDpTest) === parseFloat(oneDpTest)) {
                        return numberFormatter.toGBP(this.value, 0);
                    } else {
                        return numberFormatter.toGBP(this.value, 1);
                    }
                }
            }
        },
        series: [{
            name: "Revenue",
            data: dataValues,
            gapSize: 1

        }],
        rangeSelector: {
            selected: 1,
            buttons: [{
                type: "month",
                count: 1,
                text: "Day",
                dataGrouping: {
                    forced: true,
                    units: [["day", [1]]]
                },
                preserveDataGrouping: true
            }, {
                type: "month",
                count: 6,
                text: "Week",
                dataGrouping: {
                    forced: true,
                    units: [["week", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 1,
                text: "Month",
                dataGrouping: {
                    forced: true,
                    units: [["month", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: 4,
                text: "Quarter",
                dataGrouping: {
                    forced: true,
                    units: [["month", [3]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }, {
                type: "year",
                count: "all",
                text: "Year",
                dataGrouping: {
                    forced: true,
                    units: [["year", [1]]],
                    approximation: "sum",
                    groupAll: true
                },
                preserveDataGrouping: true
            }]
        },
        plotOptions: {
            series: {
                tooltip: {
                    valueDecimals: ",.2f",
                    valuePrefix: "£"
                },
                dataGrouping: {
                    forced: true,
                    units: [
                        ["week", [1, 2]]
                    ]
                }
            }
        }
    };

    React.useEffect(() => {
        dispatch(operations.getSalesHistory());
    }, [dispatch]);


    return (
        <Box data-cy="sales-history">
            <StockLine loading={loading} error={error} options={options} globalOptions={{}} />
        </Box>
    );
};

export default SalesHistory;
