import { combineReducers } from "redux";

import types from "./types";

const storeActualVsBudgetRankedEmpty = {
    loading: false,
    error: false,
    storePerformanceRankings: []
};

const storeActualVsBudgetRanked = (state = storeActualVsBudgetRankedEmpty, action) => {
    switch (action.type) {
        case types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                storePerformanceRankings: []
            };
        case types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                storePerformanceRankings: action.payload.storePerformanceRankings
            };
        case types.GET_STORE_ACTUAL_VS_BUDGET_RANKED_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                storePerformanceRankings: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    storeActualVsBudgetRanked
});

export default reducer;
