import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import MagicQuadrant from "components/visuals/MagicQuadrant";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/areaHealth/supplyAndDemand";
import useColourPalette from "components/visuals/useColourPalette";
import numberFormatter from "utils/numberFormatter";
import arrayUtils from "utils/arrayUtils";
import stringUtils from "utils/stringUtils";

const SupplyAndDemandMagicQuadrant = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const supplyAndDemand = useSelector(state => selectors.supplyAndDemand(state));
    const { loading, error, store, comparator } = supplyAndDemand;
    const comparatorData = comparator.map(item => ({ name: item.name, x: item.supply, y: item.demand, custom: { storeName: item.name } }));
    const doQuadrants = (comparatorData.length > 1);

    let xMidPoint = 0;
    let yMidPoint = 0;

    if (doQuadrants) {
        xMidPoint = arrayUtils.median(comparatorData.map(row => row.x));
        yMidPoint = arrayUtils.median(comparatorData.map(row => row.y));
    }

    const options = {
        xAxis: {
            title: {
                text: "Supply"
            },
            reversed: true
        },
        yAxis: {
            title: {
                text: "Demand"
            },
            labels: {
                format: "£{text}"
            }
        },
        series: [{
            name: selectedStore.name,
            data: [{
                name: store.name,
                x: store.supply,
                y: store.demand,
                custom: { storeName: store.name }
            }],
            color: colourPalette.comparators[0],
            zIndex: 1
        }, {
            name: selectedComparator.name,
            data: comparatorData,
            color: colourPalette.comparators[1]
        }],

        tooltip: {
            headerFormat: "<table>",
            pointFormatter: function () {
                const series = this.series;
                const seriesName = series.name;
                const storeName = this.custom.storeName;
                const seriesColor = series.color;
                const xAxisTitle = series.xAxis.options.title.text;
                const yAxisTitle = series.yAxis.options.title.text;

                const x = this.x;
                const y = this.y;

                return stringUtils.tooltipHTML([`${xAxisTitle}:`, `${yAxisTitle}:`], {
                    header: (storeName === seriesName) ? `${seriesName}` : `${seriesName}:<br>${storeName}`,
                    values: [numberFormatter.toDecimalPlaces(x, 0), numberFormatter.toGBP(y, 2)],
                    headerFormatting: `color:${seriesColor}`
                });
            },
            footerFormat: "</table>",
            useHTML: true
        }
    };

    return (
        <Box data-cy="supply-and-demand-magic-quadrant">
            <MagicQuadrant
                loading={loading}
                error={error}
                options={options}
                topLeftLabel="High supply, high demand"
                topRightLabel="Low supply, high demand"
                bottomRightLabel="Low supply, low demand"
                bottomLeftLabel="High supply, low demand"
                doQuadrants={doQuadrants}
                xMidPoint={xMidPoint}
                yMidPoint={yMidPoint}
                xAxisReversed={true}
                padBelowZero={true}
            />
        </Box>
    );
};

export default SupplyAndDemandMagicQuadrant;
