import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectGrossProfitTrend } from "modules/customer/insights/portfolioNew/profit/profitSlice";

const GrossProfitTrend = () => {
    const grossProfitTrend = useAppSelector(selectGrossProfitTrend);
    return (
        <RAGIndicatorNew ragIndicator={grossProfitTrend} />
    );
};

export default GrossProfitTrend;
