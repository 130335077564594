import { combineReducers } from "redux";

import types from "./types";

const payrollCostPerSqftEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const payrollCostPerSqft = (state = payrollCostPerSqftEmpty, action) => {
    switch (action.type) {
        case types.GET_PAYROLL_COST_PER_SQFT_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_PAYROLL_COST_PER_SQFT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_PAYROLL_COST_PER_SQFT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};

const salesValuePerHeadEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const salesValuePerHead = (state = salesValuePerHeadEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_VALUE_PER_HEAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_SALES_VALUE_PER_HEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_SALES_VALUE_PER_HEAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};

const salesPerPoundOfStaffCostEmpty = {
    loading: false,
    error: false,
    overallData: [],
    selectedStore: {},
    comparatorStores: []
};

const salesPerPoundOfStaffCost = (state = salesPerPoundOfStaffCostEmpty, action) => {
    switch (action.type) {
        case types.GET_SALES_PER_POUND_OF_STAFF_COST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        case types.GET_SALES_PER_POUND_OF_STAFF_COST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                overallData: action.payload.overallData,
                selectedStore: action.payload.selectedStore,
                comparatorStores: action.payload.comparatorStores
            };
        case types.GET_SALES_PER_POUND_OF_STAFF_COST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                overallData: [],
                selectedStore: {},
                comparatorStores: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    payrollCostPerSqft,
    salesValuePerHead,
    salesPerPoundOfStaffCost
});

export default reducer;
