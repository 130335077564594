import { combineReducers } from "redux";

import slice from "./costSlice";
import costOverview from "./costOverview";
import costDrivers from "./costDrivers";
import storeCosts from "./storeCosts";
import filters from "./filters";

const reducer = combineReducers({
    root: slice.reducer,
    costOverview,
    costDrivers,
    storeCosts,
    filters
});

export default reducer;
