import React from "react";

import { Box, Button, Checkbox, IconButton, TextField, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearCategory,
    editCategory,
    getCategory,
    hideEditCategory,
    selectCategory,
    selectEditCategoryVisibility,
    setCategory,
    showEditCategory
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

const useStyles = makeStyles(theme => ({
    input: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

interface EditCategoryProps {
    categoryId: string;
}

const EditCategory: React.FC<EditCategoryProps> = (props) => {
    const { categoryId } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const category = useAppSelector(selectCategory);
    const editCategoryVisibility = useAppSelector(selectEditCategoryVisibility);
    const show = (editCategoryVisibility.isVisible) && (categoryId === editCategoryVisibility.categoryId);

    React.useEffect(() => {
        if (show) {
            dispatch(getCategory(categoryId));
        }
    }, [dispatch, show, categoryId]);

    const handleEditCategoryClick = () => {
        dispatch(showEditCategory(categoryId));
    };

    const handleSubmitClick = () => {
        dispatch(editCategory());
    };

    const handleCancelClick = () => {
        dispatch(hideEditCategory());
        dispatch(clearCategory());
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newCategory = { ...category, name, errors: { ...category.errors, name: "" } };
        dispatch(setCategory(newCategory));
    };

    const handleShowOnLandingAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnLandingArea = event.target.checked;
        const newCategory = { ...category, showOnLandingArea };
        dispatch(setCategory(newCategory));
    };

    const handleShowOnCustomerAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnCustomerArea = event.target.checked;
        const newCategory = { ...category, showOnCustomerArea };
        dispatch(setCategory(newCategory));
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Edit">
                <StyledIconButton size="small" onClick={handleEditCategoryClick}>
                    <EditRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Edit category</Typography>
                }
                content={
                    <>
                        <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={category.name}
                            onChange={handleNameChange}
                            error={!!category.errors.name}
                            helperText={category.errors.name}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={category.showOnLandingArea}
                                onChange={handleShowOnLandingAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on landing area
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={category.showOnCustomerArea}
                                onChange={handleShowOnCustomerAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on customer area
                            </Typography>
                        </Box>
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Save
                    </Button>
                }
            />
        </>
    );
};

export default EditCategory;
