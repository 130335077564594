import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface User {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    role: Role,
    companyName: string,
    companyDisplayName: string
}

enum Role {
    Unknown = "Unknown",
    Owner = "Owner",
    Admin = "Admin",
    Editor = "Editor",
    Viewer = "Viewer"
}

interface UsersFilter {
    nameOrEmailOrCompanyNameOrCompanyDisplayName: string
}

interface UsersState {
    users: User[],
    filter: UsersFilter
}

const initialState: UsersState = {
    users: [],
    filter: {
        nameOrEmailOrCompanyNameOrCompanyDisplayName: ""
    }
};

const usersSlice = createSlice({
    name: "admin/users",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
        },
        clearUsers: (state) => {
            state.users = initialState.users;
        },
        setFilter: (state, action: PayloadAction<UsersFilter>) => {
            state.filter = action.payload;
        }
    }
});

export const { setFilter } = usersSlice.actions;

export const getUsers = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/admin/users"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const users = response.data.users;
            dispatch(usersSlice.actions.setUsers(users));
            break;
        }
        default: {
            dispatch(usersSlice.actions.clearUsers());
            break;
        }
    }
};

export const selectFilter = (state: RootState) => {
    return state.admin.users.filter;
};

export const selectUsers = createSelector(
    (state: RootState) => state.admin.users.users,
    selectFilter,
    (users, filter) => {
        const nameOrEmailOrCompanyNameOrCompanyDisplayName = filter.nameOrEmailOrCompanyNameOrCompanyDisplayName.toLowerCase();
        return users.filter(user =>
            user.firstName.toLowerCase().includes(nameOrEmailOrCompanyNameOrCompanyDisplayName)
            || user.lastName.toLowerCase().includes(nameOrEmailOrCompanyNameOrCompanyDisplayName)
            || user.email.toLowerCase().includes(nameOrEmailOrCompanyNameOrCompanyDisplayName)
            || user.companyName.toLowerCase().includes(nameOrEmailOrCompanyNameOrCompanyDisplayName)
            || user.companyDisplayName.toLowerCase().includes(nameOrEmailOrCompanyNameOrCompanyDisplayName));
    }
);

export default usersSlice;
