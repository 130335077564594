import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CentileChart, { StoreCentile } from "./CentileChart";
import { CentileMetricProps } from "./CentileMetric";
import CentileSlider from "./CentileSlider";

const StyledGridContainer = withStyles(theme => ({
    root: {
        margin: 0,
        width: "100%",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    }
}))(Grid);

const StyledGridItem = withStyles(theme => ({
    root: {
        color: (props: any) => props["data-disabled"] ? theme.palette.text.disabled : theme.palette.text.secondary
    }
}))(Grid);

interface CentileProps {
    name: string,
    description: string,
    disabled: boolean,
    sliderValue: number,
    onSliderChange: (value: number) => void,
    comparatorStoresCentiles: StoreCentile[],
    aggregatedDirectCompetitorCentiles: StoreCentile[],
    chartLowLabel: string,
    chartHighLabel: string,
    children?: React.ReactElement<CentileMetricProps> | React.ReactElement<CentileMetricProps>[]
}

const Centile: React.FC<CentileProps> = (props) => {
    const {
        name, description, disabled,
        sliderValue, onSliderChange,
        comparatorStoresCentiles, aggregatedDirectCompetitorCentiles,
        chartLowLabel, chartHighLabel,
        children
    } = props;

    return (
        <StyledGridContainer container spacing={4} alignItems="center">
            <StyledGridItem item xs={3} data-disabled={disabled}>
                <Typography variant="subtitle1" color={disabled ? "inherit" : "textPrimary"} gutterBottom>
                    {name}
                </Typography>
                <Typography variant="body2" color={disabled ? "inherit" : "textSecondary"}>
                    {description}
                </Typography>
            </StyledGridItem>
            <StyledGridItem item xs={6} align="center" data-disabled={disabled}>
                <CentileSlider
                    value={sliderValue}
                    disabled={disabled}
                    onSliderChange={onSliderChange}
                />
                <CentileChart
                    comparatorStoresCentiles={comparatorStoresCentiles}
                    aggregatedDirectCompetitorCentiles={aggregatedDirectCompetitorCentiles}
                    lowLabel={chartLowLabel}
                    highLabel={chartHighLabel}
                    disabled={disabled}
                />
            </StyledGridItem>
            <StyledGridItem item xs={3} data-disabled={disabled}>
                {children}
            </StyledGridItem>
        </StyledGridContainer>
    );
};

export default Centile;
