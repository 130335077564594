import React from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';

import ChartBase from "./ChartBase";

const Sparkline = (props) => {
    const { loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: 'spline'
        },
        legend: {
            enabled: false
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: false
                },
                states: {
                    hover: {
                        enabled: false
                    }
                },
                lineWidth: 2
            }
        },
        exporting: {
            enabled: false
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy="sparkline-chart" />
    );
};

Sparkline.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Sparkline.defaultProps = {
    loading: false,
    error: false
};

export default Sparkline;
