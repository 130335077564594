import React from "react";

const SalesSubtitle: React.FC = () => {
    return (
        <>
            This section analyses your sales performance. Is your revenue growing or declining? How does this compare to
            the market as a whole? Which categories, sub-categories and products are the key drivers of your revenue
            performance?
        </>
    );
};

export default SalesSubtitle;
