import React from "react";

import { Box, Grid } from "@material-ui/core";
import MarketCategoriesFilter from "./MarketCategoriesFilter";
import ResetFiltersButton from "./ResetFilters";
import WeightSpendByProbabilitySwitch from "./WeightSpendByProbabilitySwitch";
import Subtitle from "./Subtitle";

const Filter: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} md={11}>
                    <Subtitle />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-end"} height="100%">
                        <ResetFiltersButton />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <MarketCategoriesFilter />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 15, marginLeft: 5 }}>
                    <WeightSpendByProbabilitySwitch />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filter;
