import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectRollingGrossProfit } from "modules/customer/insights/portfolioNew/profit/profitSlice";

const RollingGrossProfitRag = () => {
    const rollingGrossProfit = useAppSelector(selectRollingGrossProfit);
    return (
        <RAGIndicatorNew ragIndicator={rollingGrossProfit} />
    );
};

export default RollingGrossProfitRag;
