import { immerable } from "immer";

export class RevenueType {
    [immerable] = true;

    public readonly name: "Online" | "In store";
    public readonly id: number;
    public readonly isOnline: boolean;
    private _isSelected: boolean;

    constructor(
        name: "Online" | "In store",
        id: number,
        isOnline: boolean,
        isSelected: boolean
    ) {
        this.name = name;
        this.id = id;
        this.isOnline = isOnline;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}
