import React from "react";

import { Grid } from "@material-ui/core";

import SpendAlignmentDumbbell from "./SpendAlignmentDumbbell";
import SpendAlignmentScore from "./SpendAlignmentScore";
import SpendOfWeightedCatchment from "./SpendOfWeightedCatchment";

const AlignmentToSpendTarget: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SpendOfWeightedCatchment />
            </Grid>
            <Grid item xs={12}>
                <SpendAlignmentScore />
            </Grid>
            <Grid item xs={12}>
                <SpendAlignmentDumbbell />
            </Grid>
        </Grid>
    );
};

export default AlignmentToSpendTarget;
