import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import PageCallToAction from "components/landing/PageCallToAction";

import ComprehensiveInsights from "./ComprehensiveInsights";
import Hero from "./Hero";
import PowerfulAlgorithms from "./PowerfulAlgorithms";
import SayGoodbyeToGutFeel from "./SayGoodbyeToGutFeel";
import StayOneStepAhead from "./StayOneStepAhead";
import TrustedBy from "./TrustedBy";

const Home = () => {
    const theme = useTheme();

    React.useEffect(() => {
        window.dashLinkedInPixel && window.dashLinkedInPixel();
        window.dashAdobePixel && window.dashAdobePixel("ev_dash_landingpage");
    });

    return (
        <Box data-cy="pages-landing-home">
            <Hero />
            <TrustedBy />
            <SayGoodbyeToGutFeel />
            <PowerfulAlgorithms />
            <StayOneStepAhead />
            <ComprehensiveInsights />
            <TrustedBy />
            <PageCallToAction
                color={theme.palette.common.white}
                bgcolor={theme.palette.secondary.main}
                title="Take your business to the next level"
                subtitle="Get Dash to access intelligent insights for your business"
                actionLabel="Book a demo"
                actionUrl="/contact-us?subject=book-a-demo"
                dataCy="pages-landing-home-call-to-action"
            />
        </Box>
    );
};

export default Home;
