import React from "react";
import { Grid } from "@material-ui/core";

import StoreActualVsBudgetRanked from "./StoreActualVsBudgetRanked";
import StoresVsBudget from "./StoresVsBudget";

const StorePerformanceAgainstBudget = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreActualVsBudgetRanked />
            </Grid>
            <Grid item xs={12}>
                <StoresVsBudget />
            </Grid>
        </Grid>
    );
};

export default StorePerformanceAgainstBudget;
