import React from "react";

import { Box, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { useTheme } from "@material-ui/core/styles";

import useColourPalette from "./useColourPalette";

import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";

interface RAGIndicatorNewProps {
    ragIndicator: RagIndicator
}

const RAGIndicatorNew: React.FC<RAGIndicatorNewProps> = (props) => {
    const { ragIndicator } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const displayValue = (() => {
        if (ragIndicator.isLoading) return "Loading...";
        if (ragIndicator.hasErrors) return "Error!";
        return ragIndicator.value;
    })();
    const { color, icon } = (() => {
        switch (ragIndicator.status) {
            case RagIndicatorStatus.Red:
                return {
                    color: colourPalette.RAGIndicators[0],
                    icon: <CancelIcon />
                };
            case RagIndicatorStatus.Amber:
                return {
                    color: colourPalette.RAGIndicators[1],
                    icon: <ErrorIcon />
                };
            case RagIndicatorStatus.Green:
                return {
                    color: colourPalette.RAGIndicators[2],
                    icon: <CheckCircleIcon />
                };

            case RagIndicatorStatus.NoData:
                return {
                    color: theme.palette.text.disabled,
                    icon: <InfoIcon />
                };
            case RagIndicatorStatus.Info:
            default:
                return {
                    color: colourPalette.RAGIndicators[3],
                    icon: <InfoIcon />
                };
        }
    })();

    const handleClick = () => {
        if (ragIndicator.isClickEnabled) {
            ragIndicator.onClick();
        }
    };

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            paddingX={1.5}
            paddingY={1.25}
            borderRadius={6}
            border={1}
            borderColor={color}
            style={{ cursor: ragIndicator.isClickEnabled ? "pointer" : "default" }}
            onClick={handleClick}
            data-cy={`${ragIndicator.id}-rag-indicator`}
        >
            <Box display="flex" alignItems="center" color={color} paddingRight={1.5}>
                {icon}
            </Box>
            <Typography variant="h6" component="div">
                <span style={{ color: color }}>
                    {ragIndicator.label}&nbsp;&nbsp;
                </span>
                <Typography variant="subtitle1" component="span">
                    {displayValue}
                </Typography>
            </Typography>
        </Box>
    );
};

export default RAGIndicatorNew;
