import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Explore how footfall changes throughout the week and uncover the busy times for your selected location. 
            Evaluate if these align with your business's typical opening hours. Consider the footfall profile: Are the busiest times 
            during commuting hours when potential customers are transitory? Or do the peak periods occur when potential customers 
            are more likely to stay around?
        </>
    );
};

export default Subtitle;
