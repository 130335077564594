const GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_REQUEST = "spark/customer/insights/portfolio/sales/rankedGrowth/GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_REQUEST";
const GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_SUCCESS = "spark/customer/insights/portfolio/sales/rankedGrowth/GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_SUCCESS";
const GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_FAILURE = "spark/customer/insights/portfolio/sales/rankedGrowth/GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_FAILURE";

const types = {
    GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_REQUEST,
    GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_SUCCESS,
    GET_STORE_VS_COMPARATOR_RANKED_LAST_TWELVE_MONTHS_GROWTH_FAILURE
};

export default types;
