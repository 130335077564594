import React from "react";

import { Box, Button, Checkbox, MenuItem, TextField, Typography } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import {
    addSubscription,
    clearSubscription,
    hideAddSubscription,
    selectAddSubscriptionVisibility,
    selectPrices,
    selectProducts,
    selectSubscription,
    selectTaxRates,
    setSubscription,
    showAddSubscription
} from "modules/admin/accounts/subscriptions/subscriptionsSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(2)
    }
}))(Button);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    },
}))(TextField);

interface AddSubscriptionProps {
    accountId: string
}

const AddSubscription: React.FC<AddSubscriptionProps> = (props) => {
    const { accountId } = props;
    const dispatch = useAppDispatch();
    const subscription = useAppSelector(selectSubscription);
    const products = useAppSelector(selectProducts);
    const prices = useAppSelector(selectPrices);
    const taxRates = useAppSelector(selectTaxRates);
    const addSubscriptionVisibility = useAppSelector(selectAddSubscriptionVisibility);
    const show = (addSubscriptionVisibility.isVisible) && (accountId === addSubscriptionVisibility.accountId);

    const handleAddSubscriptionClick = () => {
        dispatch(showAddSubscription(accountId));
    };

    const handleSubmitClick = () => {
        dispatch(addSubscription(accountId));
    };

    const handleCloseClick = () => {
        dispatch(hideAddSubscription());
        dispatch(clearSubscription());
    };

    const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const productId = event.target.value;
        const newSubscription = { ...subscription, productId, priceId: "", errors: { ...subscription.errors, productId: "", priceId: "" } };
        dispatch(setSubscription(newSubscription));
    };

    const handleUsePaymentsProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const usePaymentsProvider = event.target.checked;
        const newSubscription = { ...subscription, usePaymentsProvider };
        dispatch(setSubscription(newSubscription));
    };

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const priceId = event.target.value;
        const newSubscription = { ...subscription, priceId, errors: { ...subscription.errors, priceId: "" } };
        dispatch(setSubscription(newSubscription));
    };

    const handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const taxRateId = event.target.value;
        const newSubscription = { ...subscription, taxRateId, errors: { ...subscription.errors, taxRateId: "" } };
        dispatch(setSubscription(newSubscription));
    };

    return (
        <>
            <StyledButton
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddRoundedIcon />}
                disableElevation
                onClick={handleAddSubscriptionClick}
            >
                Add subscription
            </StyledButton>
            <SimpleDialog
                open={show}
                onClose={handleCloseClick}
                title={
                    <Typography variant="h5" component="div">Add account</Typography>
                }
                content={
                    <>
                        <StyledTextField
                            label="Product"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={subscription.productId}
                            onChange={handleProductChange}
                            error={!!subscription.errors.productId}
                            helperText={subscription.errors.productId}
                            fullWidth
                        >
                            {products.length === 0 && (
                                <MenuItem value="" disabled>No products found</MenuItem>
                            )}
                            {products.map(product =>
                                <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </MenuItem>
                            )}
                        </StyledTextField>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={subscription.usePaymentsProvider}
                                onChange={handleUsePaymentsProviderChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Use Stripe
                            </Typography>
                        </Box>
                        {subscription.usePaymentsProvider && (
                            <>
                                <StyledTextField
                                    label="Price"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    margin="normal"
                                    select
                                    value={subscription.priceId}
                                    onChange={handlePriceChange}
                                    error={!!subscription.errors.priceId}
                                    helperText={subscription.errors.priceId}
                                    fullWidth
                                >
                                    {!subscription.productId && (
                                        <MenuItem value="" disabled>Select a product first</MenuItem>
                                    )}
                                    {subscription.productId && prices.filter(price => price.productId === subscription.productId)?.length === 0 && (
                                        <MenuItem value="" disabled>No prices found</MenuItem>
                                    )}
                                    {prices.filter(price => price.productId === subscription.productId).map(price =>
                                        <MenuItem key={price.id} value={price.id}>
                                            {price.name}
                                            &nbsp;&nbsp;-&nbsp;&nbsp;
                                            {numberFormatter.toGBP(price.priceInPence / 100, 2, false)} / month
                                        </MenuItem>
                                    )}
                                </StyledTextField>
                                <StyledTextField
                                    label="Tax rate"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    margin="normal"
                                    select
                                    value={subscription.taxRateId}
                                    onChange={handleTaxRateChange}
                                    error={!!subscription.errors.taxRateId}
                                    helperText={subscription.errors.taxRateId}
                                    fullWidth
                                >
                                    {taxRates.length === 0 && (
                                        <MenuItem value="" disabled>No tax rates found</MenuItem>
                                    )}
                                    {taxRates.map(taxRate =>
                                        <MenuItem key={taxRate.id} value={taxRate.id}>
                                            {taxRate.name} ({taxRate.percentage}% {taxRate.isInclusive ? "inclusive" : "exclusive"})
                                        </MenuItem>
                                    )}
                                </StyledTextField>
                            </>
                        )}
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Add account
                    </Button>
                }
            />
        </>
    );
};

export default AddSubscription;
