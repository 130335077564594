import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { DateTime } from "luxon";

export class MonthlyCosts {
    public readonly storeId: number;
    public readonly date: DateTime;
    public readonly costType: string;
    public readonly costValue: number;
    public readonly group: string;

    constructor(
        storeId: number,
        date: DateTime,
        costType: string,
        costValue: number,
        group: string
    ) {
        this.storeId = storeId;
        this.date = date;
        this.costType = costType;
        this.costValue = costValue;
        this.group = group;
    }
}

export const loadMonthlyCosts = (
    costReferenceDate: DateTime,
    storeId?: number,
    similarStoresIds?: number[],
    costType?: string
): AppThunk<Promise<MonthlyCosts[]>> => async (dispatch) => {
    try {
        if (!costReferenceDate || !storeId || !similarStoresIds || !costType) {
            return [];
        }

        const priorSixtyMonthsToReferenceDate = costReferenceDate
            .minus({ months: 60 })
            .plus({ days: 1 })
            .startOf("day");

        const storeAndSimilarStoreIds = [storeId].concat(...similarStoresIds).map(String);

        const costQuery = {
            measures: ["F_Cost.SumCostValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorSixtyMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            filters: [
                {
                    member: "D_Account.AccountName",
                    operator: "equals",
                    values: [String(costType)]
                },
                {
                    member: "D_Store.PK_Store",
                    operator: "equals",
                    values: storeAndSimilarStoreIds
                }
            ],
            dimensions: [
                "D_Store.PK_Store",
                "D_Date.MonthStartDate",
                "D_Account.AccountName",
                "D_Store.Group"
            ],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        const costPromise = dispatch(cubeLoad(costQuery));

        const result = await Promise.all([costPromise]) as unknown as ResultSet[];

        const costRawData = result[0].rawData();

        return costRawData.map(costRow => {
            return new MonthlyCosts(
                costRow["D_Store.PK_Store"],
                DateTime.fromISO(costRow["D_Date.MonthStartDate"], { zone: "utc" }),
                costRow["D_Account.AccountName"],
                Number(costRow["F_Cost.SumCostValue"]),
                costRow["D_Store.Group"]
            );
        });
    } catch (error) {
        dispatch(logError("Error loading MonthlyCosts.", error));
        throw error;
    }
};
