import React from "react";
import { ViewportProps } from "react-map-gl";

import { Avatar } from "@material-ui/core";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledAvatar = withStyles(theme => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        width: theme.spacing(3.7),
        height: theme.spacing(3.6),
        right: 0,
        bottom: theme.spacing(11),
        margin: theme.spacing(2),
        zIndex: theme.zIndex.drawer,
        cursor: "pointer"
    }
}))(Avatar);

interface RecenterProps {
    initialViewport: ViewportProps,
    centerMap: (latitude: number, longitude: number, zoom?: number) => void
}

const Recenter: React.FC<RecenterProps> = (props) => {
    const { initialViewport, centerMap } = props;
    const [viewport, setViewport] = React.useState(initialViewport);

    const handleClick = React.useCallback(() => {
        centerMap(viewport.latitude as number, viewport.longitude as number, viewport.zoom);
    }, [centerMap, viewport]);

    React.useEffect(() => {
        setViewport(initialViewport);
    }, [initialViewport]);

    return (
        <StyledAvatar variant="rounded" onClick={handleClick} data-cy="btn-recenter">
            <NearMeRoundedIcon fontSize="small" />
        </StyledAvatar>
    );
};

export default Recenter;
