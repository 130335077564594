import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import _ from "lodash";
import { DateTime } from "luxon";

import { DataWrapper } from "domain/dataWrapper";
import {
    selectComparator,
    selectMonthlySales,
    selectStore,
    selectYearlyCosts,
    selectCostReferenceDate,
} from "modules/customer/insights/portfolioNew/portfolioSlice";

import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import { RankedGaugeStore } from "components/visuals/RankedGauge";

interface DriversState {
    isLoading: boolean,
    hasErrors: boolean
}

const initialState: DriversState = {
    isLoading: false,
    hasErrors: false
};

const driversSlice = createSlice({
    name: "customer/insights/portfolioNew/drivers",
    initialState,
    reducers: {}
});

interface RevenueByStore {
    storeId: string,
    revenue: number
}

const selectRevenueByStore = createSelector(
    (state: RootState) => selectMonthlySales(state),
    (state: RootState) => selectCostReferenceDate(state),
    (monthlySales, costReferenceDate) => {
        const revenueByStore: DataWrapper<RevenueByStore[]> = {
            isLoading: monthlySales.isLoading,
            hasErrors: monthlySales.hasErrors,
            data: []
        };
        const referenceDate = costReferenceDate.endOf("day");
        const priorTwelveMonthsOfReferenceDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf("day");

        revenueByStore.data = _(monthlySales.data)
            .filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString(), { zone: 'utc' }) >= priorTwelveMonthsOfReferenceDate)
            .filter(sales => DateTime.fromISO(sales.monthStartDate.toLocaleString(), { zone: 'utc' }) <= referenceDate)
            .groupBy(sales => sales.storeID)
            .map((group, key) => {
                const storeId = key;
                const revenue = group.reduce((total, current) => total + current.totalSales, 0);
                return {
                    storeId,
                    revenue
                };
            })
            .value();

        return revenueByStore;
    }
);

// Chapter 4.1
export const selectSalesPerSquareFoot = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectRevenueByStore(state),
    (selectedStore, comparator, revenueByStore) => {

        const salesPerSquareFoot: DataWrapper<RankedGaugeStore[]> = {
            isLoading: revenueByStore.isLoading,
            hasErrors: revenueByStore.hasErrors,
            data: []
        };
        if (!selectedStore || salesPerSquareFoot.hasErrors || salesPerSquareFoot.isLoading) {
            return salesPerSquareFoot;
        }
        const selectedAndComparatorStores = [selectedStore].concat(comparator?.getStores() ?? []);

        salesPerSquareFoot.data = _(revenueByStore.data)
            .map(item => {
                const { storeId, revenue } = item;
                const isSelected = storeId === selectedStore.id;
                const store = selectedAndComparatorStores.find(store => store.id === storeId);
                const storeSizeInSquareFeet = store === undefined ? 0 : store.sizeInSquareFeet;
                const salesPerSquareFoot = storeSizeInSquareFeet === 0 ? 0 : revenue / storeSizeInSquareFeet;
                return {
                    storeId,
                    isSelected,
                    value: salesPerSquareFoot
                };
            })
            .value();
        return salesPerSquareFoot;
    }
);

export const selectPropertyCostPerSquareFoot = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectYearlyCosts(state),
    (selectedStore, comparator, yearlyCosts) => {
        const costsPerSquareFoot: DataWrapper<RankedGaugeStore[]> = {
            isLoading: yearlyCosts.isLoading,
            hasErrors: yearlyCosts.hasErrors,
            data: []
        };
        if (!selectedStore || costsPerSquareFoot.hasErrors || costsPerSquareFoot.isLoading) {
            return costsPerSquareFoot;
        }
        const selectedAndComparatorStores = [selectedStore].concat(comparator?.getStores() ?? []);

        costsPerSquareFoot.data = _(yearlyCosts.data)
            .groupBy(comparator => comparator.storeID)
            .map((group, key) => {
                const storeId = key;
                const isSelected = storeId === selectedStore.id;
                const store = selectedAndComparatorStores.find(store => store.id === key);
                const propertyCosts = group.filter(cost => cost.isPropertyCost).reduce((total, current) => total + current.costValue, 0);
                const storeSizeInSquareFeet = store === undefined ? 0 : store.sizeInSquareFeet;
                const propertyCostsPerSquareFoot = propertyCosts / storeSizeInSquareFeet;
                return {
                    storeId,
                    isSelected,
                    value: propertyCostsPerSquareFoot
                };
            })
            .value();

        return costsPerSquareFoot;
    }
);

export const selectSalesPerPoundOfPropertyCosts = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectRevenueByStore(state),
    (state: RootState) => selectYearlyCosts(state),
    (selectedStore, revenueByStore, yearlyCosts) => {
        const salesPerPoundOfPropertyCosts: DataWrapper<RankedGaugeStore[]> = {
            isLoading: yearlyCosts.isLoading || revenueByStore.isLoading,
            hasErrors: yearlyCosts.hasErrors || revenueByStore.hasErrors,
            data: []
        };
        if (!selectedStore || salesPerPoundOfPropertyCosts.hasErrors || salesPerPoundOfPropertyCosts.isLoading) {
            return salesPerPoundOfPropertyCosts;
        }

        salesPerPoundOfPropertyCosts.data = _(yearlyCosts.data)
            .groupBy(costs => costs.storeID)
            .map((group, key) => {
                const storeId = key;
                const isSelected = storeId === selectedStore.id;
                const storeSales = revenueByStore.data.find(item => item.storeId === key);
                const sales = storeSales === undefined ? 0 : storeSales.revenue;
                const propertyCosts = group.filter(cost => cost.isPropertyCost).reduce((total, current) => total + current.costValue, 0);
                const salesPerPoundOfPropertyCosts = propertyCosts === 0 ? 0 : sales / propertyCosts;
                return {
                    storeId,
                    isSelected,
                    value: salesPerPoundOfPropertyCosts
                };
            })
            .value();

        return salesPerPoundOfPropertyCosts;
    }
);

export const selectRevenueLessPropertyCostsPerSquareFoot = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectSalesPerSquareFoot(state),
    (state: RootState) => selectPropertyCostPerSquareFoot(state),
    (selectedStore, selectedComparartor, salesPerSquareFoot, propertyCostsPerSquareFoot) => {
        const id = "revenu-less-property-costs-per-square-foot";
        const label = "Revenue less property costs per square foot";
        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        const { data: salesData, isLoading, hasErrors } = salesPerSquareFoot;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const propertyCostData = propertyCostsPerSquareFoot.data;
        if (salesData.length === 0 || propertyCostData.length === 0 || propertyCostData.every(item => !item.value)) {
            ragValue = "This indicator isn't available because it requires your company's property cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.";
            ragStatus = RagIndicatorStatus.NoData;
            return new RagIndicator(id, ragStatus, label, ragValue, (salesPerSquareFoot.isLoading || propertyCostsPerSquareFoot.isLoading), (salesPerSquareFoot.hasErrors || propertyCostsPerSquareFoot.hasErrors));
        }

        const data = salesData.map(sps => {
            const propertyCost = propertyCostData.find(item => item.storeId === sps.storeId);
            if (!propertyCost) {
                return {
                    storeId: sps.storeId,
                    contribution: sps.value,
                };
            }
            return {
                store: sps.storeId,
                contribution: sps.value - propertyCost.value,
            };
        });

        const sortedData = data.sort((a, b) => b.contribution - a.contribution);

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.storeId === selectedStore?.id);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is a top performer for revenue less property costs per square foot relative to ${selectedComparartor?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is underperforming for revenue less property costs per square foot relative to ${selectedComparartor?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is an average performer for revenue less property costs per square foot relative to ${selectedComparartor?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, (salesPerSquareFoot.isLoading || propertyCostsPerSquareFoot.isLoading), (salesPerSquareFoot.hasErrors || propertyCostsPerSquareFoot.hasErrors));
    }
);

export const selectSalesPerPoundPropCostCategorisation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectSalesPerPoundOfPropertyCosts(state),
    (selectedStore, selectedComparator, salesPerPoundOfPropertyCost) => {
        const id = "sales-per-pound-prop-cost-categorisation";
        const label = "Revenue per £ property cost categorisation";
        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        const { data, isLoading, hasErrors } = salesPerPoundOfPropertyCost;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const sortedData = data.sort((a, b) => b.value - a.value);
        if (data.length === 0 || sortedData.length === 0 || sortedData[0]?.value === 0) {
            ragValue = `This indicator isn't available because it requires your company's property cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.`;
            ragStatus = RagIndicatorStatus.NoData;
            return new RagIndicator(id, ragStatus, label, ragValue, salesPerPoundOfPropertyCost.isLoading, salesPerPoundOfPropertyCost.hasErrors);
        }

        const selectedStoreValue = salesPerPoundOfPropertyCost.data.find(store => store.isSelected)?.value ?? 0;

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.value === selectedStoreValue);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        const selectedStoreName = selectedStore?.name;
        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStoreName} is a top performer store for revenue per £ of property cost relative to ${selectedComparator?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStoreName} is an underperforming store for revenue per £ of property cost relative to ${selectedComparator?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStoreName} is an average store for revenue per £ of property cost relative to ${selectedComparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, salesPerPoundOfPropertyCost.isLoading, salesPerPoundOfPropertyCost.hasErrors);
    }
);

// Chapter 4.2
export const selectPayrollCostsPerSquareFoot = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectYearlyCosts(state),
    (selectedStore, comparator, yearlyCosts) => {

        const payrollCostsPerSquareFoot: DataWrapper<RankedGaugeStore[]> = {
            isLoading: yearlyCosts.isLoading,
            hasErrors: yearlyCosts.hasErrors,
            data: []
        };
        if (!selectedStore || payrollCostsPerSquareFoot.hasErrors || payrollCostsPerSquareFoot.isLoading) {
            return payrollCostsPerSquareFoot;
        }

        const selectedAndComparatorStores = [selectedStore].concat(comparator?.getStores() ?? []);
        payrollCostsPerSquareFoot.data = _(yearlyCosts.data)
            .groupBy(sales => sales.storeID)
            .map((group, storeId) => {
                const store = selectedAndComparatorStores.find(store => store.id === storeId);
                const isSelected = storeId === selectedStore.id;
                const payrollCosts = group.filter(cost => cost.isStaffCost).reduce((total, current) => total + current.costValue, 0);
                const storeSizeInSqft = store === undefined ? 0 : store.sizeInSquareFeet;
                const payrollCostPerSqft = payrollCosts / storeSizeInSqft;

                return {
                    storeId,
                    isSelected,
                    value: payrollCostPerSqft
                };
            })
            .value();

        return payrollCostsPerSquareFoot;
    }
);

export const selectRevenuePerHead = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectRevenueByStore(state),
    (selectedStore, comparator, revenueByStore) => {
        const revenuePerHead: DataWrapper<RankedGaugeStore[]> = {
            isLoading: revenueByStore.isLoading,
            hasErrors: revenueByStore.hasErrors,
            data: []
        };
        if (!selectedStore || revenuePerHead.hasErrors || revenuePerHead.isLoading) {
            return revenuePerHead;
        }

        const selectedAndComparatorStores = [selectedStore].concat(comparator?.getStores() ?? []);

        revenuePerHead.data = selectedAndComparatorStores
            .filter(store => store.numberOfEmployees !== 0)
            .map(store => {
                const isSelected = store.id === selectedStore.id;
                const revenue = revenueByStore.data.find(item => item.storeId === store.id)?.revenue ?? 0;
                const revenuePerHead = revenue / store.numberOfEmployees;
                return {
                    storeId: store.id,
                    isSelected,
                    value: revenuePerHead
                };
            });

        return revenuePerHead;
    }
);

export const selectRevenuePerPoundOfStaffCosts = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectRevenueByStore(state),
    (state: RootState) => selectYearlyCosts(state),
    (selectedStore, revenueByStore, yearlyCosts) => {
        const revenuePerPoundOfStaffCosts: DataWrapper<RankedGaugeStore[]> = {
            isLoading: yearlyCosts.isLoading || revenueByStore.isLoading,
            hasErrors: yearlyCosts.hasErrors || revenueByStore.hasErrors,
            data: []
        };
        if (!selectedStore || revenuePerPoundOfStaffCosts.hasErrors || revenuePerPoundOfStaffCosts.isLoading) {
            return revenuePerPoundOfStaffCosts;
        }

        revenuePerPoundOfStaffCosts.data = _(yearlyCosts.data)
            .groupBy(costs => costs.storeID)
            .map((group, key) => {
                const storeId = key;
                const isSelected = storeId === selectedStore.id;
                const storeRevenue = revenueByStore.data.find(item => item.storeId === key);
                const revenue = storeRevenue === undefined ? 0 : storeRevenue.revenue;
                const payrollCosts = group.filter(cost => cost.isStaffCost).reduce((total, current) => total + current.costValue, 0);
                const revenuePerPoundOfStaffCosts = payrollCosts === 0 ? 0 : revenue / payrollCosts;
                return {
                    storeId,
                    isSelected,
                    value: revenuePerPoundOfStaffCosts
                };
            })
            .value();

        return revenuePerPoundOfStaffCosts;
    }
);

export const selectPayrollCostsPerSqftCategorisation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectPayrollCostsPerSquareFoot(state),
    (selectedStore, selectedComparator, payrollCostPerSqft) => {
        const id = "payroll-costs-per-sqft-categorisation";
        const label = "Payroll costs per square foot";
        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        const { data, isLoading, hasErrors } = payrollCostPerSqft;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const sortedData = data.sort((a, b) => a.value - b.value);

        const selectedStoreValue = data.find(store => store.isSelected)?.value ?? 0;

        if (data.length === 0 || data.every(item => !item.value)) {
            ragValue = "This indicator isn't available because it requires your company's payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.";
            ragStatus = RagIndicatorStatus.NoData;
            return new RagIndicator(id, ragStatus, label, ragValue, payrollCostPerSqft.isLoading, payrollCostPerSqft.hasErrors);
        }

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.value === selectedStoreValue);
        const denominator = data.length;

        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is a top performer for payroll costs per square foot with respect to ${selectedComparator?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is a poor performer for payroll costs per square foot with respect to ${selectedComparator?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is an average performer for payroll costs per square foot with respect to ${selectedComparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, payrollCostPerSqft.isLoading, payrollCostPerSqft.hasErrors);
    }
);

export const selectRevenuePerHeadCategorisation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectRevenuePerHead(state),
    (selectedStore, selectedComparator, revenuePerHead) => {
        const id = "revenue-per-head-categorisation";
        const label = "Revenue per head";
        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        const { data, isLoading, hasErrors } = revenuePerHead;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const sortedData = data.sort((a, b) => b.value - a.value);
        const selectedStoreValue = revenuePerHead.data.find(store => store.isSelected)?.value;

        if (data.length === 0 || data.every(item => !item.value)) {
            ragValue = "This indicator isn't available because it requires your store's employee FTE count. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Store dataset and refresh your company's Analytics.";
            ragStatus = RagIndicatorStatus.NoData;
            return new RagIndicator(id, ragStatus, label, ragValue, revenuePerHead.isLoading, revenuePerHead.hasErrors);
        }

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.value === selectedStoreValue);
        const denominator = data.length;

        //ToDo base the below on tertiles
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is a top performer for revenue per head relative to ${selectedComparator?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is a poor performer for revenue per head relative to ${selectedComparator?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is an average performer for revenue per head relative to ${selectedComparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, revenuePerHead.isLoading, revenuePerHead.hasErrors);
    }
);

export const selectRevenuePerPoundOfStaffCostCategorisation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectRevenuePerPoundOfStaffCosts(state),
    (selectedStore, selectedComparator, revenuePerPoundOfStaffCosts) => {
        const id = "revenue-per-pound-staff-cost-categorisation";
        const label = "Revenue per £ of staff cost";
        let ragStatus = RagIndicatorStatus.Info;
        let ragValue = "";

        const { data, isLoading, hasErrors } = revenuePerPoundOfStaffCosts;
        if (isLoading || hasErrors || !selectedStore) {
            return new RagIndicator(id, ragStatus, label, ragValue, isLoading, hasErrors);
        }

        const sortedData = data.sort((a, b) => b.value - a.value);
        if (data.length === 0 || data.every(item => !item.value)) {
            ragValue = "This indicator isn't available because it requires your company's payroll cost data. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Cost dataset and refresh your company's Analytics.";
            ragStatus = RagIndicatorStatus.NoData;
            return new RagIndicator(id, ragStatus, label, ragValue, revenuePerPoundOfStaffCosts.isLoading, revenuePerPoundOfStaffCosts.hasErrors);
        }

        const selectedStoreValue = revenuePerPoundOfStaffCosts.data.find(store => store.isSelected)?.value ?? 0;

        //Rank of selected store value within store and comparator array
        const numerator = 1 + sortedData.findIndex(item => item.value === selectedStoreValue);
        const denominator = data.length;

        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;

        const selectedPercentile = numerator / denominator;

        if (selectedPercentile < topThirdPercentile) {
            ragStatus = RagIndicatorStatus.Green;
            ragValue = `${selectedStore?.name} is a top performer for revenue per £ of staff cost relative to ${selectedComparator?.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            ragStatus = RagIndicatorStatus.Red;
            ragValue = `${selectedStore?.name} is a poor performer for revenue per £ of staff cost relative to ${selectedComparator?.name}`;
        } else {
            ragStatus = RagIndicatorStatus.Amber;
            ragValue = `${selectedStore?.name} is an average performer for revenue per £ of staff cost relative to ${selectedComparator?.name}`;
        }

        return new RagIndicator(id, ragStatus, label, ragValue, revenuePerPoundOfStaffCosts.isLoading, revenuePerPoundOfStaffCosts.hasErrors);
    }
);

export default driversSlice;
