import React from "react";

import { Box, Grid, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Page from "components/customer/Page";

import Billing from "./billing/Billing";
import Settings from "./settings/Settings";
import Users from "./users/Users";

import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";

const StyledTab = withStyles(() => ({
    root: {
        textTransform: "none"
    }
}))(Tab);

interface TabPanelProps {
    value: number,
    index: number,
    children: React.ReactElement
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { value, index, children } = props;
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && children}
        </Box>
    );
};

const Account: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const isAccountAdmin = userInfo.isAccountAdmin;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event: object, newSelectedTab: number) => {
        setSelectedTab(newSelectedTab);
    };

    return (
        <Page dataCy="pages-customer-account">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item md={2} xs={12}>
                            <Typography variant="subtitle1" component="div" color="textSecondary">
                                {userInfo.companyDisplayName}
                            </Typography>
                            <Typography variant="h3" component="div">
                                Account
                            </Typography>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                variant={isMobile ? "fullWidth" : "standard"}
                                centered
                            >
                                <StyledTab
                                    label={<Typography variant="subtitle1" component="div">Settings</Typography>}
                                />
                                {isAccountAdmin && (
                                    <StyledTab
                                        label={<Typography variant="subtitle1" component="div">Users</Typography>}
                                    />
                                )}
                                {isAccountAdmin && (
                                    <StyledTab
                                        label={<Typography variant="subtitle1" component="div">Billing</Typography>}
                                    />
                                )}
                            </Tabs>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TabPanel value={selectedTab} index={0}>
                        <Settings />
                    </TabPanel>
                    {isAccountAdmin && (
                        <>
                            <TabPanel value={selectedTab} index={1}>
                                <Users />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={2}>
                                <Billing />
                            </TabPanel>
                        </>
                    )}
                </Grid>
            </Grid>
        </Page>
    );
};

export default Account;
