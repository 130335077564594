const GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_REQUEST = "spark/customer/insights/performance/budget/revenueVsBudgetOverTime/GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_REQUEST";
const GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_SUCCESS = "spark/customer/insights/performance/budget/revenueVsBudgetOverTime/GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_SUCCESS";
const GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_FAILURE = "spark/customer/insights/performance/budget/revenueVsBudgetOverTime/GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_FAILURE";

const types = {
    GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_REQUEST,
    GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_SUCCESS,
    GET_SALES_PERFORMANCE_VS_BUDGET_OVER_TIME_FAILURE
};

export default types;
