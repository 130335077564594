import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import RAGIndicator from "components/visuals/RAGIndicator";

import { selectors } from "modules/customer/insights/portfolio/competition/competitionScore";

const CompetitionScoreRagIndicator = () => {
    const competitionScoreRag = useSelector(state => selectors.competitionScoreRag(state));
    const { loading, error, id, label, status, value } = competitionScoreRag;

    return (
        <Box data-cy={`${id}-rag-indicator`}>
            <RAGIndicator loading={loading} error={error} label={label} status={status} value={value} />
        </Box>
    );
};

export default CompetitionScoreRagIndicator;
