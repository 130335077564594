import React from "react";

import { Button, Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import { useAppDispatch, useAppSelector } from "store";
import {
    clearCallbackForm,
    hideCallback,
    requestCallback,
    selectCallbackForm,
    selectCallbackVisibility,
    selectCanRequestCallback,
    selectFrequentlyAskedQuestionsCategoriesWithQuestions,
    setCallbackForm,
    showCallback
} from "modules/customer/help/helpSlice";

const useStyles = makeStyles(theme => ({
    input: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

const CallbackForm: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const categories = useAppSelector(selectFrequentlyAskedQuestionsCategoriesWithQuestions);
    const subjects = categories.map(category => ({ id: category.id, value: category.name }));
    const callbackVisibility = useAppSelector(selectCallbackVisibility);
    const callbackForm = useAppSelector(selectCallbackForm);
    const canRequestCallback = useAppSelector(selectCanRequestCallback);

    const handleRequestCallbackClick = () => {
        dispatch(showCallback());
    };

    const handleSubmitClick = () => {
        dispatch(requestCallback());
    };

    const handleCancelClick = () => {
        dispatch(hideCallback());
        dispatch(clearCallbackForm());
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        const newCallbackForm = { ...callbackForm, name, errors: { ...callbackForm.errors, name: "" } };
        dispatch(setCallbackForm(newCallbackForm));
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subject = event.target.value;
        const newCallbackForm = { ...callbackForm, subject, errors: { ...callbackForm.errors, subject: "" } };
        dispatch(setCallbackForm(newCallbackForm));
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const description = event.target.value;
        const newCallbackForm = { ...callbackForm, description, errors: { ...callbackForm.errors, description: "" } };
        dispatch(setCallbackForm(newCallbackForm));
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value;
        const newCallbackForm = { ...callbackForm, phoneNumber, errors: { ...callbackForm.errors, phoneNumber: "" } };
        dispatch(setCallbackForm(newCallbackForm));
    };

    React.useEffect(() => {
        dispatch(clearCallbackForm());
    }, [dispatch]);

    return (
        <>
            <Button
                variant="contained"
                size="medium"
                color="secondary"
                disableElevation
                onClick={handleRequestCallbackClick}
                data-cy="btn-request-callback"
            >
                Request a callback
            </Button>
            <SimpleDialog
                open={callbackVisibility.isVisible}
                onClose={handleCancelClick}
                dataCy="pages-customer-help-request-callback-form"
                title={
                    <>
                        <Typography variant="h5" component="div" gutterBottom>
                            Request a callback
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            Please provide some basic information about your request
                        </Typography>
                    </>
                }
                content={
                    <>
                        <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={callbackForm.name}
                            onChange={handleNameChange}
                            error={!!callbackForm.errors.name}
                            helperText={callbackForm.errors.name}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Subject"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            select
                            value={callbackForm.subject}
                            onChange={handleSubjectChange}
                            error={!!callbackForm.errors.subject}
                            helperText={callbackForm.errors.subject}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        >
                            {subjects.map(subject =>
                                <MenuItem key={subject.id} value={subject.value}>
                                    {subject.value}
                                </MenuItem>
                            )}
                            <Divider />
                            <MenuItem value="other">Other</MenuItem>
                        </TextField>
                        <TextField
                            label="Description"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            multiline
                            minRows={6}
                            value={callbackForm.description}
                            onChange={handleDescriptionChange}
                            error={!!callbackForm.errors.description}
                            helperText={callbackForm.errors.description}
                            required
                            fullWidth
                            InputProps={{ classes: { multiline: classes.input } }}
                        />
                        <TextField
                            label="Phone number"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            value={callbackForm.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={!!callbackForm.errors.phoneNumber}
                            helperText={callbackForm.errors.phoneNumber}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                    </>
                }
                actions={
                    <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        disableElevation
                        disabled={!canRequestCallback}
                        onClick={handleSubmitClick}
                        data-cy="btn-submit"
                    >
                        Submit
                    </Button>
                }
            />
        </>
    );
};

export default CallbackForm;
