import React from "react";
import { Box } from "@material-ui/core";
import StockLine from "components/visuals/StockLine";
import useColourPalette from "components/visuals/useColourPalette";
import { dateFormat } from "highcharts";
import { useTheme } from "@material-ui/core/styles";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectGrossProfitMarginOverTime } from "modules/customer/insights/portfolioNew/profit/profitSlice";

import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const GrossProfitMarginOverTime = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const grossProfitMarginOverTime = useAppSelector(selectGrossProfitMarginOverTime);
    const { loading, error, data } = grossProfitMarginOverTime;

    const storeProfitMargin = data.store.map(function (current) {
        const storeDates = current.date.getTime();
        return {
            x: storeDates,
            y: current.profit,
            custom: {
                sales: current.sales,
                costs: current.costs
            }
        };
    });

    const comparatorProfitMargin = data.comparator.map(function (current) {
        const comparatorDates = current.date.getTime();
        return {
            x: comparatorDates,
            y: current.profit,
            custom: {
                sales: current.sales,
                costs: current.costs
            }
        };
    });

    const globalOptions = {
        lang: {
            rangeSelectorZoom: "Zoom"
        }
    };

    const options = {
        title: {
            text: `${selectedStore.name} vs ${selectedComparator.name} gross profit margin % per month`
        },
        colors: colourPalette.comparators,
        series: [{
            name: selectedStore.name,
            data: storeProfitMargin,
            grouping: true,
            xAxis: 0
        }, {
            name: selectedComparator.name,
            data: comparatorProfitMargin,
            grouping: true,
            xAxis: 0
        }],
        legend: {
            enabled: true
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            shared: true,
            split: false,
            formatter: function () {
                const point = this.points;
                const selectedStorePoint = point[0];
                const comparatorPoint = point[1];

                const categoryArr = [selectedStorePoint.series.name, 'Sales', 'COGS', comparatorPoint.series.name, 'Sales', 'COGS'];
                const categoryFormatArr = [`color:${selectedStorePoint.color};font-weight:bold`, "", "",
                `color:${comparatorPoint.color};font-weight:bold`, "", ""];

                const header = dateFormat("%B %Y", selectedStorePoint.x);

                const storePercentage = numberFormatter.toPercentage(selectedStorePoint.y);
                const comparatorPercentage = numberFormatter.toPercentage(comparatorPoint.y);
                const selectedSales = numberFormatter.toGBP(selectedStorePoint.point.custom.sales);
                const selectedCOGS = numberFormatter.toGBP(selectedStorePoint.point.custom.costs);
                const comparatorSales = numberFormatter.toGBP(comparatorPoint.point.custom.sales);
                const comparatorCOGS = numberFormatter.toGBP(comparatorPoint.point.custom.costs);

                const valueArr = [storePercentage, selectedSales, selectedCOGS, comparatorPercentage, comparatorSales, comparatorCOGS];

                return `<table>${stringUtils.tooltipHTML(categoryArr, { header: header, values: valueArr, categoryFormattingArr: categoryFormatArr })}</table>`;

            }
        },
        xAxis: [{
            lineWidth: 0,
            type: "datetime",
            labels: {
                useHTML: true,
                style: {
                    color: theme.palette.common.white,
                    fontSize: "12px"
                },
                units: [["month", [1]]],
                dateTimeLabelFormats: {
                    month: "%b"
                }
            }
        }],
        yAxis: [{
            allowDecimals: true,
            opposite: false,
            gridLineColor: "#646174",
            plotLines: [{
                value: 0,
                color: "#FFFFFF",
                width: 2
            }],
            title: {
                text: "Gross profit margin",
                style: {
                    color: theme.palette.common.white
                }
            },
            labels: {
                style: {
                    color: theme.palette.common.white
                },
                format: '{text}%'
            }
        },
        ]
    };

    return (
        <Box data-cy="gross-profit-margin-over-time">
            <StockLine loading={loading} error={error} options={options} globalOptions={globalOptions} />
        </Box>
    );
};

export default GrossProfitMarginOverTime;
