import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { useInsights } from "pages/customer/insights/Insights";
import { useTools } from "pages/customer/tools/Tools";

import InsightCard from "./InsightCard";

const Insights: React.FC = () => {
    const insights = useInsights();
    const tools = useTools();

    return (
        <>
            <Typography variant="subtitle1" component="div" color="textSecondary" gutterBottom>
                All analytics
            </Typography>
            <Grid container spacing={4}>
                {insights.map((insight, index) =>
                    <Grid key={`insight_${index}`} item md={4} xs={12}>
                        <InsightCard
                            name={insight.name}
                            icon={insight.icon}
                            url={insight.url}
                            image={insight.image}
                            alt={insight.alt}
                            isAvailable={insight.isAvailable}
                            isAvailableOnMobile={insight.isAvailableOnMobile}
                        />
                    </Grid>
                )}
                {tools.map((tool, index) =>
                    <Grid key={`tool_${index}`} item md={4} xs={12}>
                        <InsightCard
                            name={tool.name}
                            icon={tool.icon}
                            url={tool.url}
                            image={tool.image}
                            alt={tool.alt}
                            isAvailable={tool.isAvailable}
                            isAvailableOnMobile={tool.isAvailableOnMobile}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Insights;
