import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Discover which customers come from shared and new origins and assess whether opening a new store
            cannibalises high-spending groups for your existing stores or attracts valuable new customers.
        </>
    );
};

export default Subtitle;
