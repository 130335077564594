export class Store {
    public readonly name: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly hasOpened: boolean;

    constructor(
        name: string,
        latitude: number,
        longitude: number,
        hasOpened: boolean
    ) {
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.hasOpened = hasOpened;
    }
}
