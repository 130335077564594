import React from "react";

import { Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import SyncProblemRoundedIcon from "@material-ui/icons/SyncProblemRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { useAppSelector } from "store";
import { DatasetStatus, selectDatasets } from "modules/customer/data/dataSlice";

const StyledOuterCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const StyledInnerCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(Card);

const StyledAlert = withStyles(theme => ({
    root: {
        borderLeftWidth: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        color: (props: any) => props["data-color"],
        borderColor: (props: any) => props["data-color"],
    }
}))(Alert);

const RefreshReady: React.FC = () => {
    const theme = useTheme();
    const datasets = useAppSelector(selectDatasets);
    const newDataInputs = datasets.filter(dataset =>
        dataset.newData !== null
        && (dataset.status === DatasetStatus.ReadyForRefresh || dataset.status === DatasetStatus.RecommendUpdate));
    const newDataInputsIds = newDataInputs.map(dataset => dataset.id);
    const currentDataInputs = datasets.filter(dataset => dataset.currentData && !newDataInputsIds.includes(dataset.id));

    return (
        <>
            <br />
            <Typography variant="h2" component="div" align="center" gutterBottom>
                Are these details correct?
            </Typography>
            <br /><br />
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography variant="h4" component="div">
                            {newDataInputs.length} new data inputs
                        </Typography>
                        <br />
                        <StyledOuterCard elevation={0}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {newDataInputs.map((dataset, index) =>
                                        <Grid key={`new_data_input_${index}`} item xs={12}>
                                            <StyledInnerCard elevation={0}>
                                                <CardContent>
                                                    <Typography variant="h5" component="div" gutterBottom>
                                                        {dataset.name}
                                                    </Typography>
                                                    <br /><br />
                                                    <Typography variant="subtitle1" component="div" noWrap gutterBottom>
                                                        Input {dataset.newData?.uploadedAt.toLocaleString()} by {dataset.newData?.uploadedBy}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" color="textSecondary" noWrap>
                                                        {dataset.newData?.fileName}
                                                    </Typography>
                                                    {dataset.status === DatasetStatus.RecommendUpdate && (
                                                        <>
                                                            <br />
                                                            <StyledAlert
                                                                variant="outlined"
                                                                data-color={theme.palette.warning.main}
                                                                icon={
                                                                    <UpdateRoundedIcon fontSize="inherit" style={{ color: theme.palette.warning.main }} />
                                                                }
                                                            >
                                                                Old data could cause issues in Analytics
                                                            </StyledAlert>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </StyledInnerCard>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </StyledOuterCard>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" component="div">
                            {currentDataInputs.length} current data inputs
                        </Typography>
                        <br />
                        <StyledOuterCard elevation={0}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {currentDataInputs.map((dataset, index) =>
                                        <Grid key={`current_data_input_${index}`} item xs={12}>
                                            <StyledInnerCard elevation={0}>
                                                <CardContent>
                                                    <Typography variant="h5" component="div" gutterBottom>
                                                        {dataset.name}
                                                    </Typography>
                                                    {dataset.status === DatasetStatus.Ok && (
                                                        <><br /><br /></>
                                                    )}
                                                    <Typography variant="subtitle1" component="div" noWrap gutterBottom>
                                                        Input {dataset.currentData?.uploadedAt.toLocaleString()} by {dataset.currentData?.uploadedBy}
                                                    </Typography>
                                                    <Typography variant="body1" component="div" color="textSecondary" noWrap>
                                                        {dataset.currentData?.fileName}
                                                    </Typography>
                                                    {dataset.status === DatasetStatus.ProcessingIssue && (
                                                        <>
                                                            <br />
                                                            <StyledAlert
                                                                variant="outlined"
                                                                data-color={theme.palette.error.main}
                                                                icon={
                                                                    <SyncProblemRoundedIcon fontSize="inherit" style={{ color: theme.palette.error.main }} />
                                                                }
                                                            >
                                                                Current data will be used due to outstanding processing
                                                                issues
                                                            </StyledAlert>
                                                        </>
                                                    )}
                                                    {dataset.status === DatasetStatus.RecommendUpdate && (
                                                        <>
                                                            <br />
                                                            <StyledAlert
                                                                variant="outlined"
                                                                data-color={theme.palette.warning.main}
                                                                icon={
                                                                    <UpdateRoundedIcon fontSize="inherit" style={{ color: theme.palette.warning.main }} />
                                                                }
                                                            >
                                                                Old data could cause issues in Analytics
                                                            </StyledAlert>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </StyledInnerCard>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </StyledOuterCard>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default RefreshReady;

