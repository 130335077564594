import { operations as grossProfitMarginOperations } from "./grossProfitMargin";
import { operations as rankedGrossProfitMarginOperations } from "./rankedGrossProfitMargin";
import { operations as netProfitOperations } from "./netProfit";

const getProfitData = () => async (dispatch) => {
    dispatch(grossProfitMarginOperations.getCOGSPastYear());
    dispatch(grossProfitMarginOperations.getGrossProfitLastYear());
    dispatch(grossProfitMarginOperations.getGrossProfitMarginLastYear());
    dispatch(grossProfitMarginOperations.getGrossProfitMarginOverTime());
    dispatch(grossProfitMarginOperations.getGrossProfitTrend());
    dispatch(rankedGrossProfitMarginOperations.getStoreVsComparatorRankedGrossProfit());
    dispatch(netProfitOperations.getSalesToProfit());
};

const operations = {
    getProfitData
};

export default operations;
