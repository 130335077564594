import React from "react";

import { Card, CardContent, CardHeader, Grid, GridSize, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme, withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import {
    selectIsLoading,
    selectHasErrors,
    selectCustomerProfileDefinitions
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { CustomerProfileDefinition } from "modules/customer/insights/portfolioNew/catchment/customerProfileDefinition";
import { useAppSelector } from "store";

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(TextField);

const StyledCardHeader = withStyles(theme => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.common.black,
        backgroundColor: (props: any) => props["data-background-color"]
    }
}))(CardHeader);

const CustomerProfileDefinitions: React.FC = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const colours = [theme.palette.text.disabled, ...colourPalette.categorical];
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const customerProfileDefinitions = useAppSelector(selectCustomerProfileDefinitions);
    const [customerProfileDefinition, setCustomerProfileDefinition] = React.useState<CustomerProfileDefinition | null>(null);
    const [inputValue, setInputValue] = React.useState("");
    const inputLabel = isLoading
        ? "Loading..."
        : hasErrors ? "Error!" : "Search definition of customer profiles";
    const supergroupId = Number(customerProfileDefinition?.id.substr(0, 1) ?? 0);
    const definitionTitleBackgroundColour = colours[supergroupId];
    const searchGridSize: GridSize = 5;
    const definitionGridSize: GridSize = 7;

    return (
        <Grid container spacing={1}>
            <Grid item xs={searchGridSize}>
                <Autocomplete
                    disabled={isLoading || hasErrors}
                    value={customerProfileDefinition}
                    size="small"
                    onChange={(event, newCustomerProfileDefinition) => {
                        setCustomerProfileDefinition(newCustomerProfileDefinition);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={customerProfileDefinitions}
                    getOptionLabel={(customerProfileDefinition) => `${customerProfileDefinition.name} (${customerProfileDefinition.level})`}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            variant="outlined"
                            size="small"
                            color="secondary"
                            label={inputLabel}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={definitionGridSize}>
                <Card elevation={0}>
                    <StyledCardHeader
                        disableTypography
                        title={
                            customerProfileDefinition ? (
                                <Typography variant="h6" component="div">
                                    {customerProfileDefinition.level}&nbsp;&nbsp;<b>{customerProfileDefinition.name}</b>
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    Customer profile definition
                                </Typography>
                            )
                        }
                        data-background-color={definitionTitleBackgroundColour}
                    />
                    <CardContent>
                        <Typography variant="subtitle1" component="div">
                            {customerProfileDefinition ? customerProfileDefinition.description : "Select a customer profile to see its definition"}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CustomerProfileDefinitions;
