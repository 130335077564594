import React from "react";
import { Grid } from "@material-ui/core";

import LocalMarketShare from "./LocalMarketShare";
import NumberOfHouseholds from "./NumberOfHouseholds";
import RevenuePerHousehold from "./RevenuePerHousehold";
import MarketShareOverTime from "./MarketShareOverTime";
import MarketShareTrend from "./MarketShareTrend";

const MarketShare = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <LocalMarketShare />
            </Grid>
            <Grid item xs={12} md={4}>
                <NumberOfHouseholds />
            </Grid>
            <Grid item xs={12} md={4}>
                <RevenuePerHousehold />
            </Grid>
            <Grid item xs={12}>
                <MarketShareOverTime />
            </Grid>
            <Grid item xs={12}>
                <MarketShareTrend />
            </Grid>
        </Grid>
    );
};

export default MarketShare;
