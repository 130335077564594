import { combineReducers } from "redux";

import slice from "./performanceSlice";

import overview from "./overview";
import sales from "./sales";
import forecast from "./forecast";
import budget from "./budget";

const reducer = combineReducers({
    root: slice.reducer,
    overview,
    sales,
    forecast,
    budget
});

export default reducer;
