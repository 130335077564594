import { combineReducers } from "redux";

import revenue from "./revenue";
import growth from "./growth";
import rankedGrowth from "./rankedGrowth";
import productCategoryGrowth from "./productCategoryGrowth";
import productCategoryMix from "./productCategoryMix";

const reducer = combineReducers({
    revenue,
    growth,
    rankedGrowth,
    productCategoryGrowth,
    productCategoryMix
});

export default reducer;
