const GET_PEN_PORTRAITS_SEARCH_REQUEST = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_PEN_PORTRAITS_SEARCH_REQUEST";
const GET_PEN_PORTRAITS_SEARCH_SUCCESS = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_PEN_PORTRAITS_SEARCH_SUCCESS";
const GET_PEN_PORTRAITS_SEARCH_FAILURE = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_PEN_PORTRAITS_SEARCH_FAILURE";
const GET_CUSTOMER_PROFILES_REQUEST = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_CUSTOMER_PROFILES_REQUEST";
const GET_CUSTOMER_PROFILES_SUCCESS = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_CUSTOMER_PROFILES_SUCCESS";
const GET_CUSTOMER_PROFILES_FAILURE = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_CUSTOMER_PROFILES_FAILURE";
const GET_POPULATION_REQUEST = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_POPULATION_REQUEST";
const GET_POPULATION_SUCCESS = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_POPULATION_SUCCESS";
const GET_POPULATION_FAILURE = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_POPULATION_FAILURE";
const GET_MARKET_CATEGORY_SPEND_REQUEST = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_MARKET_CATEGORY_SPEND_REQUEST";
const GET_MARKET_CATEGORY_SPEND_SUCCESS = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_MARKET_CATEGORY_SPEND_SUCCESS";
const GET_MARKET_CATEGORY_SPEND_FAILURE = "spark/customer/insights/portfolio/catchment/customerProfiles/GET_MARKET_CATEGORY_SPEND_FAILURE";


const types = {
    GET_PEN_PORTRAITS_SEARCH_REQUEST,
    GET_PEN_PORTRAITS_SEARCH_SUCCESS,
    GET_PEN_PORTRAITS_SEARCH_FAILURE,
    GET_CUSTOMER_PROFILES_REQUEST,
    GET_CUSTOMER_PROFILES_SUCCESS,
    GET_CUSTOMER_PROFILES_FAILURE,
    GET_POPULATION_REQUEST,
    GET_POPULATION_SUCCESS,
    GET_POPULATION_FAILURE,
    GET_MARKET_CATEGORY_SPEND_REQUEST,
    GET_MARKET_CATEGORY_SPEND_SUCCESS,
    GET_MARKET_CATEGORY_SPEND_FAILURE
};

export default types;
