import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class AverageStoreLifetime {
    public readonly storeID: string;
    public readonly averageStoreLifetime: number;

    constructor(
        storeID: string,
        averageStoreLifetime: number,
    ) {
        this.storeID = storeID;
        this.averageStoreLifetime = averageStoreLifetime;
    }
}

export const loadAverageStoreLifetimes = (stores: Store[]): AppThunk<Promise<AverageStoreLifetime[]>> => async (dispatch) => {
    try {
        const selectedAndComparatorOAs = stores.map(store => store.outputAreaCode);

        const query = {
            measures: [
                "OpeningsAndClosures.AvgStoreLifespan"
            ],
            dimensions: [
                "OpeningsAndClosures.MainOAID"
            ],
            filters: [{
                member: "OpeningsAndClosures.MainOAID",
                operator: "equals",
                values: selectedAndComparatorOAs
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        const averageStoreLifetimes: AverageStoreLifetime[] = [];
        stores.forEach(store => {
            const relevantResult = rawData.find(row =>
                row["OpeningsAndClosures.MainOAID"] === store.outputAreaCode);

            averageStoreLifetimes.push(
                new AverageStoreLifetime(
                    store.id,
                    relevantResult["OpeningsAndClosures.AvgStoreLifespan"],
                )
            );
        });
        return averageStoreLifetimes;
    } catch (error) {
        dispatch(logError("Error loading AverageStoreLifetimes.", error));
        throw error;
    }
};
