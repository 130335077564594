import React from "react";
import { Grid } from "@material-ui/core";

import SupplyAndDemandMagicQuadrant from "./SupplyAndDemandMagicQuadrant";
import SupplyAndDemandRagIndicator from "./SupplyAndDemandRagIndicator";

const ProductCategoryMix = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SupplyAndDemandMagicQuadrant />
            </Grid>
            <Grid item xs={12}>
                <SupplyAndDemandRagIndicator />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryMix;
