import React from "react";

import { Box } from "@material-ui/core";

import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import useColourPalette from "components/visuals/useColourPalette";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";

import { selectCatchmentCustomerProfiles, selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const colours = ["rgba(0,0,0,0)", ...colourPalette.categorical];
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const catchmentCustomerProfiles = useAppSelector(selectCatchmentCustomerProfiles);
    const customerProfiles = catchmentCustomerProfiles.data;
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredCustomerProfile = customerProfiles.find(customerProfile => customerProfile.outputAreaCode === hoverInfo.outputAreaCode);


    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const mapOutputAreas = React.useMemo(() => {
        return customerProfiles.map(customerProfile => ({
            code: customerProfile.outputAreaCode,
            colourGroup: customerProfile.supergroupCode,
            opacity: customerProfile.probability
        }));
    }, [customerProfiles]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="demographics-map">
            <OutputAreasMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colours}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                downloadData={customerProfiles}
            >
                <Explore />
                <PinnedLocation />
                <Legend customerProfile={hoveredCustomerProfile} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
