import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Trace your {store?.name} store's financial journey from last year's revenue to net profit.
            Pinpoint areas with excess costs and refine your strategy accordingly.
            Compare costs to those of {comparator?.name} to provide context for performance and assess the ongoing operational sustainability of your {store?.name} store.
        </>
    );
};

export default Subtitle;
