import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Gauge from "components/visuals/Gauge";

import { selectors } from "modules/customer/insights/portfolio/performanceDrivers/storeSize";

const PropertyCostsPerSquareFootGauge = () => {
    const propertyCostsPerSquareFoot = useSelector(state => selectors.propertyCostsPerSquareFootGauge(state)); 
    const { loading, error, min, max, data, med, firstTertile, secondTertile } = propertyCostsPerSquareFoot;

    const noGaugeData = (data === 0 || max === 0);

    const options = {

    };
    
    return (
        <Box data-cy="property-costs-per-square-foot-gauge">
            <Gauge loading={loading} error={error} options={options} data={data} min={min} max={max} 
                median={med} firstTertile={firstTertile} secondTertile={secondTertile} noData={noGaugeData} lowerIsBetter={true} 
                reverseYAxis={true} />
        </Box>
    );
};

export default PropertyCostsPerSquareFootGauge;
