import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Uncover the key drivers behind your {store?.name} store's performance by analysing both revenue and cost in relation to its size and staff.
            Benchmark against {comparator?.name} to gauge efficiency and ensure your store's performance aligns with its size and staff.
            From crucial lease renewals to staff contracts, Dash provides insights to inform confident decisions for optimal store performance.
        </>
    );
};

export default Subtitle;
