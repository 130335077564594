import React from "react";

import { Box, Typography } from "@material-ui/core";
import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import {
    selectNumberOfStoresInLocalArea
} from "modules/customer/insights/portfolioNew/areaHealth/areaHealthSlice";
import { selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";

const NumberOfStoresInLocalArea: React.FC = () => {
    const colourPalette = useColourPalette();
    const comparator = useAppSelector(selectComparator);
    const comparatorName = comparator?.name;
    const numberOfStoresInLocalArea = useAppSelector(selectNumberOfStoresInLocalArea);
    const { data, isLoading, hasErrors } = numberOfStoresInLocalArea;
    const selectedStoreValue = data.selectedStore;
    const comparatorValue = data.comparator;
    const difference = selectedStoreValue - comparatorValue;

    return (
        <Box width="100%" height="100%" data-cy="number-of-stores-in-local-area">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {selectedStoreValue}
                    </Typography>
                }
                targetLabel="Number of stores within the local area"
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {comparatorValue}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparatorName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {difference}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default NumberOfStoresInLocalArea;
