import React from "react";

import { Grid } from "@material-ui/core";

import BreakdownOfCatchmentAreaDemographic
    from "./breakdownOfCatchmentAreaDemographic/BreakdownOfCatchmentAreaDemographic";

const CatchmentAreaDemographics: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BreakdownOfCatchmentAreaDemographic />
            </Grid>
        </Grid>
    );
};

export default CatchmentAreaDemographics;
