import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import StoreOverviewSubchapter from "./storeOverview/StoreOverview";
import ChapterSubtitle from "./Subtitle";
import SubchapterSubtitle from "./storeOverview/Subtitle";

const StoreOverview: React.FC = () => {
    return (
        <Chapter
            title="Store overview"
            subtitle={<ChapterSubtitle />}
            dataCy="store-overview-chapter"
        >
            <Subchapter
                title="Store overview"
                subtitle={<SubchapterSubtitle />}
                dataCy="store-overview-subchapter"
            >
                <StoreOverviewSubchapter />
            </Subchapter>
        </Chapter>
    );
};

export default StoreOverview;
