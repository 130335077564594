import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const SupplyAndDemandSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Analyse supply and demand levels in the local area of your {store?.name} store. We have calculated
            the level of spending in the markets you trade within and the number of existing stores in the local area
            that serve these markets. Find out if the local market is saturated or has room for expansion. Compare
            supply and demand levels to those in {comparator?.name}.
        </>
    );
};

export default SupplyAndDemandSubtitle;
