import { max, median } from "mathjs";

import actions from "./actions";
import dateUtils from "utils/dateUtils";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectComparator, selectReferenceDate, selectReferenceDateNew, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const getStoreYoYGrowthVsComparator = () => async (dispatch, getState) => {
    dispatch(actions.getStoreYoYGrowthVsComparatorRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));
        const priorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const twelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);


        const comparatorpriorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatortwelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));


        const selectedStoreGrowth = priorTwelveMonthsData.map(current => {
            const previous = twelveMonthsBeforePriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const comparatorStoreGrowth = comparatorpriorTwelveMonthsData.map(current => {
            const previous = comparatortwelveMonthsBeforePriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const sortedData = comparatorStoreGrowth.sort((a, b) => a - b);
        const comparatorMedian = median(sortedData);
        const percentageDifference = selectedStoreGrowth - comparatorMedian;

        dispatch(actions.getStoreYoYGrowthVsComparatorSuccess(selectedStoreGrowth, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getStoreYoYGrowthVsComparatorFailure());
        dispatch(logError("Error loading StoreYoYGrowthVsComparator.", error));
    }
};

const getStoreForecastYoYGrowthVsComparator = () => async (dispatch, getState) => {
    dispatch(actions.getStoreForecastYoYGrowthVsComparatorRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(currentDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [currentDate, next12MonthsEndDate],
                    [priorTwelveMonthsStartDate, currentDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const next12MonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const priorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const comparatorNext12MonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorPriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const selectedStoreGrowth = next12MonthsData.map(current => {
            const previous = priorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const comparatorStoreGrowth = comparatorNext12MonthsData.map(current => {
            const previous = comparatorPriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const sortedData = comparatorStoreGrowth.sort((a, b) => a - b);
        const comparatorMedian = median(sortedData);
        const percentageDifference = selectedStoreGrowth - comparatorMedian;

        dispatch(actions.getStoreForecastYoYGrowthVsComparatorSuccess(selectedStoreGrowth, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getStoreForecastYoYGrowthVsComparatorFailure());
        dispatch(logError("Error loading StoreForecastYoYGrowthVsComparator.", error));
    }
};

const getStoreVsComparatorMoMGrowth = () => async (dispatch, getState) => {
    dispatch(actions.getStoreVsComparatorMoMGrowthRequest());
    try {
        const state = getState();
        const forecastReferenceDate = selectReferenceDateNew(state).customStartOfWeek();

        const thirteenMonthsAgo = forecastReferenceDate.startOf('month').minus({ months: 13 });
        const twelveMonthsAgo = forecastReferenceDate.startOf('month').minus({ months: 12 });
        const twelveMonthsAfter = forecastReferenceDate.endOf('month').plus({ months: 12 });
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);

        const query = {
            measures: ["F_StoreMonthlyForecast.SumSalesValue", "F_StoreMonthlyForecast.MaxForecastFlag"],
            timeDimensions: [{
                dimension: "F_StoreMonthlyForecast.MonthStartDate",
                compareDateRange: [
                    [thirteenMonthsAgo, twelveMonthsAfter]
                ]
            }],
            order: [
                ["F_StoreMonthlyForecast.MonthStartDate", 'asc'],
            ],
            dimensions: ["F_StoreMonthlyForecast.MonthStartDate", "D_Store.StoreNaturalID", "D_Date.Year", "D_Date.MonthNo"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const StoreData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_StoreMonthlyForecast.SumSalesValue"],
            forecastFlag: item["F_StoreMonthlyForecast.MaxForecastFlag"],
            date: item["F_StoreMonthlyForecast.MonthStartDate"],
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => item.store === selectedStoreID);
        
        let startDate = twelveMonthsAgo;
        const endDate = twelveMonthsAfter;
        const fullTwoYears = [];
        while(startDate <= endDate){
            fullTwoYears.push({
                sales: null, 
                forecastFlag: 0, 
                month: startDate.month,   
                year: startDate.year, 
                store: selectedStoreID 
            });
            startDate = startDate.plus({ months: 1 });
        }

        let prevForecastFlag = 0;
        const StoreDataFullYear = fullTwoYears.map(fullYearItem => {
            const matching = StoreData.find(storeDataItem => fullYearItem.month === storeDataItem.month && fullYearItem.year === storeDataItem.year);
            if (matching) {
                prevForecastFlag = matching.forecastFlag;
                return matching;
            }
            fullYearItem.forecastFlag = prevForecastFlag;
            return fullYearItem;
        });

        const store = StoreDataFullYear.map(current => {
            const previous = StoreData.find(item => (item.store === current.store) && (((item.month === (current.month - 1)) && (item.year === current.year)) || ((item.year === (current.year - 1)) && (item.month === 12) && (current.month === 1))));
            if (!previous || !current.sales) {
                return {
                    selectedStoreGrowth: null,
                    month: current.month,
                    year: current.year,
                    forecastFlag: current.forecastFlag
                };
            }
            return {
                selectedStoreGrowth: (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales),
                month: current.month,
                year: current.year,
                forecastFlag: current.forecastFlag
            };
        });

        if (!store[store.length - 1].selectedStoreGrowth) {
            store.pop();
        }

        const comparatorStoresData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_StoreMonthlyForecast.SumSalesValue"],
            forecastFlag: item["F_StoreMonthlyForecast.MaxForecastFlag"],
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            store: item["D_Store.StoreNaturalID"]
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorStoreGrowth = comparatorStoresData.map(current => {
            const previous = comparatorStoresData.find(item => (item.store === current.store) && (((item.month === (current.month - 1)) && (item.year === current.year)) || ((item.year === (current.year - 1)) && (item.month === 12) && (current.month === 1))));
            if (!previous) {
                return {
                    comparatorGrowth: 0,
                    month: current.month,
                    year: current.year,
                    forecastFlag: current.forecastFlag
                };
            }
            return {
                comparatorGrowth: (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales),
                month: current.month,
                year: current.year,
                forecastFlag: current.forecastFlag
            };
        });
        
        const comparator = store.map(current => {
            const filtered = comparatorStoreGrowth.filter(item => ((item.month === current.month && item.year === current.year)));
            let forecastFlag = current.forecastFlag;
            let medianComparatorData = null;
            if (filtered.length > 0) {
                forecastFlag = max(filtered.map(item => item.forecastFlag));
                const growthValue = filtered.map(item => item.comparatorGrowth);
                const sortedData = growthValue.sort((a, b) => a - b);
                const filteredSortedData = sortedData.filter(item => item != null);
                medianComparatorData = median(filteredSortedData);
            }

            return {
                medianComparatorGrowth: medianComparatorData,
                month: current.month,
                year: current.year,
                forecastFlag: forecastFlag
            };
        });

        dispatch(actions.getStoreVsComparatorMoMGrowthSuccess(store, comparator));

    }
    catch (error) {
        dispatch(actions.getStoreVsComparatorMoMGrowthFailure());
        dispatch(logError("Error loading StoreVsComparatorMoMGrowth.", error));
    }
};

const getStoreHistoricGrowthVsComparator = () => async (dispatch, getState) => {
    dispatch(actions.getStoreHistoricGrowthVsComparatorRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const comparatorStoreName = comparatorStoresSelector.name;
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));
        const priorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const twelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);


        const comparatorpriorTwelveMonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatortwelveMonthsBeforePriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_Sales.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));


        const selectedStoreGrowth = priorTwelveMonthsData.map(current => {
            const previous = twelveMonthsBeforePriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const comparatorStoreGrowth = comparatorpriorTwelveMonthsData.map(current => {
            const previous = comparatortwelveMonthsBeforePriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const sortedData = comparatorStoreGrowth.sort((a, b) => a - b);
        const comparatorMedian = median(sortedData);

        const x = (comparatorMedian === 0) ? 100 : ((selectedStoreGrowth - comparatorMedian) / Math.abs(comparatorMedian)) * 100;

        let status = "";
        let value = "";


        if (x > 50) {
            status = "success";
            value = `Historic Growth markedly ahead of ${comparatorStoreName}`;
        } else if (x < -50) {
            status = "error";
            value = `Historic Growth markedly behind of ${comparatorStoreName}`;
        } else {
            status = "warning";
            value = `Historic Growth Broadly in line with ${comparatorStoreName}`;
        }

        dispatch(actions.getStoreHistoricGrowthVsComparatorSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getStoreHistoricGrowthVsComparatorFailure());
        dispatch(logError("Error loading StoreHistoricGrowthVsComparator.", error));
    }
};

const getStoreForecastGrowthVsComparator = () => async (dispatch, getState) => {
    dispatch(actions.getStoreForecastGrowthVsComparatorRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoresIDs = comparatorStoresSelector.getStores().map(store => store.id);
        const comparatorStoreName = comparatorStoresSelector.name;
        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);

        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const next12MonthsEndDate = dateUtils.next12MonthsEndDate(currentDate);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [currentDate, next12MonthsEndDate],
                    [priorTwelveMonthsStartDate, currentDate]
                ]
            }],
            dimensions: ["D_Store.StoreNaturalID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query));

        const next12MonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const priorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => item.store === selectedStoreID);

        const comparatorNext12MonthsData = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const comparatorPriorTwelveMonthsData = resultSet.loadResponses[1].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            store: item["D_Store.StoreNaturalID"],
        })).filter(item => comparatorStoresIDs.includes(item.store));

        const selectedStoreGrowth = next12MonthsData.map(current => {
            const previous = priorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const comparatorStoreGrowth = comparatorNext12MonthsData.map(current => {
            const previous = comparatorPriorTwelveMonthsData.find(item => item.store === current.store);
            if (!previous) {
                return 0;
            }
            return (previous.sales === 0) ? 0 : 100 * ((current.sales - previous.sales) / previous.sales);
        });

        const sortedData = comparatorStoreGrowth.sort((a, b) => a - b);
        const comparatorMedian = median(sortedData);
        const x = (comparatorMedian === 0) ? 100 : ((selectedStoreGrowth - comparatorMedian) / Math.abs(comparatorMedian)) * 100;

        let status = "";
        let value = "";

        if (x > 50) {
            status = "success";
            value = `Forecast Growth markedly ahead of ${comparatorStoreName}`;
        } else if (x < -50) {
            status = "error";
            value = `Forecast Growth markedly behind of ${comparatorStoreName}`;
        } else {
            status = "warning";
            value = `Forecast Growth Broadly in line with ${comparatorStoreName}`;
        }

        dispatch(actions.getStoreForecastGrowthVsComparatorSuccess(status, value));
    }
    catch (error) {
        dispatch(actions.getStoreForecastGrowthVsComparatorFailure());
        dispatch(logError("Error loading StoreForecastGrowthVsComparator.", error));
    }
};

const operations = {
    getStoreYoYGrowthVsComparator,
    getStoreForecastYoYGrowthVsComparator,
    getStoreVsComparatorMoMGrowth,
    getStoreHistoricGrowthVsComparator,
    getStoreForecastGrowthVsComparator
};

export default operations;
