import React from "react";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import Radar from "components/visuals/Radar";

import { useAppSelector } from "store";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import { selectStoreVsComparatorPerformance } from "modules/customer/insights/cost/storeCosts/storeCostsSlice";

import { DateTime } from "luxon";
import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const storeAgeTooltipValue = (storeAgeInDays: number): string => {
    const today = DateTime.now();
    const openingDate = today.minus({ days: storeAgeInDays });
    const difference = today.diff(openingDate, ["years", "months", "days"]).toObject();
    const years = difference.years ?? 0;
    const months = difference.months ?? 0;
    const days = difference.days ?? 0;

    if (years === 0 && months > 0 && days > 0) {
        return `${months} ${months > 1 ? 'months' : 'month'} ${mathUtils.round(days, 0)} ${days > 1 ? 'days' : 'day'}`;
    }
    else if (years > 0 && months === 0 && days > 0) {
        return `${years} ${years > 1 ? 'years' : 'year'} ${mathUtils.round(days, 0)} ${days > 1 ? 'days' : 'day'}`;
    }
    else if (years > 0 && months > 0 && days === 0) {
        return `${years} ${years > 1 ? 'years' : 'year'} ${months} ${months > 1 ? 'months' : 'month'}`;
    }

    return `${years} ${years > 1 ? 'years' : 'year'} ${months} ${months > 1 ? 'months' : 'month'} ${mathUtils.round(days, 0)} ${days > 1 ? 'days' : 'day'}`;
};

const tooltipMetricValue = (
    pointName: string,
    affluence: number,
    age: number,
    children: number,
    diversity: number,
    footfall: number,
    storeAge: string,
    storeSize: string
): string => {
    const metricValue = (() => {
        switch (pointName) {
            case "Affluence":
                return affluence.toString();
            case "Age":
                return age.toString();
            case "Children":
                return children.toString();
            case "Diversity":
                return diversity.toString();
            case "Footfall":
                return footfall.toString();
            case "Store age":
                return storeAge;
            case "Store size":
                return storeSize;
            default:
                return pointName;
        }
    })();

    return metricValue;
};

const StoreVsComparatorPerformance = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectSelectedStoreByCostType);
    const storeVsComparatorPerformance = useAppSelector(selectStoreVsComparatorPerformance);

    const options = {
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        colors: colourPalette.comparators,
        legend: {
            enabled: true
        },
        tooltip: {
            headerFormat: ``,
            footerFormat: ``,
            useHTML: true,
            formatter: function (): any {
                // @ts-ignore
                const points = this.points;
                const series = points[0].series;
                const seriesName = series.name;
                const seriesColor = series.color;

                const otherSeries = points[1].series;
                const otherSeriesName = otherSeries?.name;
                const otherSeriesColor = otherSeries?.color;

                const categoryArr = [seriesName, otherSeriesName];
                const categoryFormatArr = [`color:${seriesColor};font-weight:bold`, `color:${otherSeriesColor};font-weight:bold`];

                const valueArr = [
                    tooltipMetricValue(
                        points[0].key,
                        storeVsComparatorPerformance?.data.store.affluence,
                        storeVsComparatorPerformance?.data.store.age,
                        storeVsComparatorPerformance?.data.store.children,
                        storeVsComparatorPerformance?.data.store.diversity,
                        storeVsComparatorPerformance?.data.store.footfall,
                        storeAgeTooltipValue(storeVsComparatorPerformance?.data.store.storeAge),
                        `${numberFormatter.toDecimalPlaces(parseFloat(String(storeVsComparatorPerformance?.data.store.storeSize)), 0, false)}ft<sup>2</sup>`
                    ),
                    tooltipMetricValue(
                        points[1].key,
                        storeVsComparatorPerformance?.data.clusterAverage.affluence,
                        storeVsComparatorPerformance?.data.clusterAverage.age,
                        storeVsComparatorPerformance?.data.clusterAverage.children,
                        storeVsComparatorPerformance?.data.clusterAverage.diversity,
                        storeVsComparatorPerformance?.data.clusterAverage.footfall,
                        storeAgeTooltipValue(storeVsComparatorPerformance?.data.clusterAverage.storeAge),
                        `${numberFormatter.toDecimalPlaces(parseFloat(String(storeVsComparatorPerformance?.data.clusterAverage.storeSize)), 0, false)}ft<sup>2</sup>`
                    ),
                ];

                const headerRow = points[0].key;

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    values: valueArr,
                    header: headerRow,
                    categoryFormattingArr: categoryFormatArr
                })}</table>`;
            }
        },
        series: [{
            name: selectedStore?.storeName,
            data: [
                { name: "Affluence", y: storeVsComparatorPerformance?.data.store.affluence },
                { name: "Age", y: storeVsComparatorPerformance?.data.store.age },
                { name: "Children", y: storeVsComparatorPerformance?.data.store.children },
                { name: "Diversity", y: storeVsComparatorPerformance?.data.store.diversity },
                { name: "Footfall", y: storeVsComparatorPerformance?.data.store.footfall },
                { name: "Store age", y: storeVsComparatorPerformance?.data.store.storeAge },
                { name: "Store size", y: storeVsComparatorPerformance?.data.store.storeSize }
            ],
            pointPlacement: "on",
            zIndex: 1
        }, {
            name: "Cluster Average",
            data: [
                { name: "Affluence", y: storeVsComparatorPerformance?.data.clusterAverage.affluence },
                { name: "Age", y: storeVsComparatorPerformance?.data.clusterAverage.age },
                { name: "Children", y: storeVsComparatorPerformance?.data.clusterAverage.children },
                { name: "Diversity", y: storeVsComparatorPerformance?.data.clusterAverage.diversity },
                { name: "Footfall", y: storeVsComparatorPerformance?.data.clusterAverage.footfall },
                { name: "Store age", y: storeVsComparatorPerformance?.data.clusterAverage.storeAge },
                { name: "Store size", y: storeVsComparatorPerformance?.data.clusterAverage.storeSize },
            ],
            pointPlacement: "on"
        }]
    };

    return (
        <Box data-cy="store-vs-comparator-performance">
            <Radar loading={storeVsComparatorPerformance?.isLoading} error={storeVsComparatorPerformance?.hasErrors} options={options} dataAdditionPercent={10} />
        </Box>
    );
};

export default StoreVsComparatorPerformance;
