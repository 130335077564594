import React from "react";

import { Grid, Typography } from "@material-ui/core";

import Page from "components/customer/Page";

import About from "./About";
import EmailUs from "./EmailUs";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import RequestCallback from "./RequestCallback";

import { selectUserInfo } from "modules/auth/authSlice";
import { getFrequentlyAskedQuestions } from "modules/customer/help/helpSlice";
import { useAppDispatch, useAppSelector } from "store";

const Help: React.FC = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);

    React.useEffect(() => {
        dispatch(getFrequentlyAskedQuestions());
    }, [dispatch]);

    return (
        <Page dataCy="pages-customer-help">
            <Grid container item spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        {userInfo.companyDisplayName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Help
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <RequestCallback />
                </Grid>
                <Grid item xs={12} md={4}>
                    <EmailUs />
                </Grid>
                <Grid item xs={12} md={4}>
                    <About />
                </Grid>
                <Grid item xs={12}>
                    <FrequentlyAskedQuestions />
                </Grid>
            </Grid>
        </Page>
    );
};

export default Help;
