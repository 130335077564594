import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { DemographicIndicatorMeasure } from "modules/customer/tools/location/demographics/catchmentDemographics";
import {
    selectIsLoading,
    selectHasErrors,
    selectDemographicIndicatorMeasure
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";
import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface DemographicIndicatorChartProps {
    title: string,
    categories: string[],
    retailCentreValues: number[],
    benchmarkValues: number[],
    retailCentreTotal: number,
    benchmarkTotal: number
}

const DemographicIndicatorChart: React.FC<DemographicIndicatorChartProps> = (props) => {
    const { title, categories, retailCentreValues, benchmarkValues, retailCentreTotal, benchmarkTotal } = props;
    const colours = useColourPalette();
    const comparatorName = useAppSelector(selectComparatorsByChapter)?.demographics.storeName;
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const measure = useAppSelector(selectDemographicIndicatorMeasure);

    const retailCentreSeries = retailCentreValues.map((value) => ({
        y: measure === DemographicIndicatorMeasure.PercentageOfPopulation
            ? mathUtils.safePercentage(value, retailCentreTotal)
            : value / 1000,
        custom: {
            absoluteValue: value,
            percentageValue: mathUtils.safePercentage(value, retailCentreTotal)
        }
    }));
    const comparatorSeries = benchmarkValues.map((value) => ({
        y: measure === DemographicIndicatorMeasure.PercentageOfPopulation
            ? mathUtils.safePercentage(value, benchmarkTotal)
            : value / 1000,
        custom: {
            absoluteValue: value,
            percentageValue: mathUtils.safePercentage(value, benchmarkTotal)
        }
    }));

    const yAxisTitle = measure === DemographicIndicatorMeasure.PercentageOfPopulation
        ? "% of population"
        : "Population (000s)";

    const options: Highcharts.Options = {
        xAxis: {
            categories: categories
        },
        yAxis: {
            labels: {
                enabled: true
            },
            title: {
                text: yAxisTitle
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            formatter() {
                const value = this.point.options.custom?.absoluteValue;
                const percentage = this.point.options.custom?.percentageValue;
                const captions = measure === DemographicIndicatorMeasure.PercentageOfPopulation
                    ? ["% of total population", "Population"]
                    : ["Population", "% of total population"];
                const values = measure === DemographicIndicatorMeasure.PercentageOfPopulation
                    ? [numberFormatter.toPercentage(percentage), numberFormatter.toTruncatedInteger(value, 2)]
                    : [numberFormatter.toTruncatedInteger(value, 2), numberFormatter.toPercentage(percentage)];

                return `<table>${stringUtils.tooltipHTML(captions, {
                    values,
                    header: `${this.x}`,
                    valueFormatting: `color:${this.series.color}`
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            name: "Selected location",
            data: retailCentreSeries,
            color: colours.comparators[0],
            type: "bar"
        }, {
            name: `Comparator: ${comparatorName}`,
            data: comparatorSeries,
            color: colours.comparators[1],
            type: "bar"
        }]
    };

    return (
        <Box data-cy="demographic-indicators-in-catchment-area">
            <Typography variant="h6" component="div">
                {title}
            </Typography>
            <Bar loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default DemographicIndicatorChart;
