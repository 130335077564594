import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import About from "./About";
import Platform from "./Platform";
import { trackEvent } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(3)
    }
}))(Button);

const NavigationDesktop = () => {
    const dispatch = useDispatch();
    const handleClick = (label) => {
        dispatch(trackEvent("Header", `Header - ${label} click`, `Header - ${label} button`));
    };

    return (
        <>
            <Platform onLinkClick={() => handleClick("Platform")} />
            <StyledButton
                component={NavLink}
                to="/features"
                variant="text"
                size="large"
                color="default"
                onClick={() => handleClick("Features")}
                disableElevation
                data-cy="btn-features"
            >
                Features
            </StyledButton>
            <About onLinkClick={() => handleClick("About")} />
            <StyledButton
                component={NavLink}
                to="/contact-us"
                variant="text"
                size="large"
                color="default"
                onClick={() => handleClick("Contact")}
                disableElevation
                data-cy="btn-contact"
            >
                Contact
            </StyledButton>
            <StyledButton
                component={NavLink}
                to="/login"
                variant="outlined"
                size="small"
                color="default"
                onClick={() => handleClick("Login")}
                disableElevation
                data-cy="btn-login"
            >
                Login
            </StyledButton>
            <Button
                component={NavLink}
                to="/contact-us?subject=book-a-demo"
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => handleClick("Book a demo")}
                disableElevation
                data-cy="btn-book-a-demo"
            >
                Book a demo
            </Button>
        </>
    );
};

export default NavigationDesktop;
