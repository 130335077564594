import React from "react";

const HistoricSalesSubtitle: React.FC = () => {

    return (
        <>
            Understand $[Selected store] weekly sales history and analyse its projected sales for the next year.
            Evaluate whether the store's sales are trending in the right direction.
        </>
    );
};

export default HistoricSalesSubtitle;
