import React from "react";

import { Card, CardContent, Chip, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import { OutputArea } from "modules/customer/tools/location/areaHealth/outputArea";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(26),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const StyledDivider = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(Divider);

interface LegendProps {
    outputArea?: OutputArea
}

const Legend: React.FC<LegendProps> = (props) => {
    const { outputArea } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                    <Collapse in={outputArea !== undefined}>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                <Typography variant="body2" component="div">
                                    (%) Change in the number of stores in the last 12 months
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" component="div" align="right">
                                    {numberFormatter.toPercentage(outputArea?.netOpenings ?? 0, true, 0)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <StyledDivider />
                    </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    (%) Change in the number of stores in the last 12 months
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    {/* @ts-ignore */}
                    <StyledChip size="small" data-background-color={theme.palette.tertiary.main} />
                    &nbsp;&nbsp;Net openings
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={theme.palette.text.disabled} />
                    &nbsp;&nbsp;No change
                </Typography>
                <Typography variant="body1" component="div">
                    <StyledChip size="small" data-background-color={colourPalette.categorical[4]} />
                    &nbsp;&nbsp;Net closures
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
