import React from "react";

import RAGIndicator from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectRevenueLessPropertyCostsPerSquareFoot } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const RevenueLessPropertyCostsPerSquareFoot = () => {
    const revenueLessPropertyCostsPerSquareFoot = useAppSelector(selectRevenueLessPropertyCostsPerSquareFoot);

    return (
        <RAGIndicator ragIndicator={revenueLessPropertyCostsPerSquareFoot} />
    );
};

export default RevenueLessPropertyCostsPerSquareFoot;
