import React from "react";
import { Grid } from "@material-ui/core";

import RankedGrossProfitMarginStoreVsComparator from "./RankedGrossProfitMarginStoreVsComparator";
import RollingGrossProfit from "./RollingGrossProfit";

const RankedGrossProfitMargin = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RankedGrossProfitMarginStoreVsComparator />
            </Grid>
            <Grid item xs={12}>
                <RollingGrossProfit />
            </Grid>
        </Grid>
    );
};

export default RankedGrossProfitMargin;
