import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { Store } from "./store";

export class RetailCentreClassification {
    public readonly retailCentreId: string;
    public readonly retailCentreName: string;
    public readonly retailCentreClassificationId: number;
    public readonly retailCentreClassificationName: string;
    public readonly country: string;
    public readonly region: string;

    constructor(retailCentreId: string, retailCentreName: string, retailCentreClassificationId: number, retailCentreClassificationName: string, country: string, region: string) {
        this.retailCentreId = retailCentreId;
        this.retailCentreName = retailCentreName;
        this.retailCentreClassificationId = retailCentreClassificationId;
        this.retailCentreClassificationName = retailCentreClassificationName;
        this.country = country;
        this.region = region;
    }
}

export const loadRetailCentreClassification = (stores: Store[]): AppThunk<Promise<RetailCentreClassification[]>> => async (dispatch) => {
    const retailCentreClassification: RetailCentreClassification[] = [];

    try {
        const retailCentreIDStrings = stores.map(store => String(store.retailCentreID));

        const query = {
            dimensions: [
                "D_RetailCentres.RetailCentreID",
                "D_RetailCentres.RetailCentreName",
                "D_RetailCentres.RetailCentreClassificationID",
                "RetailCentreClassification.RetailCentreClassificationName",
                "D_RetailCentres.Country",
                "D_RetailCentres.Region"
            ],
            filters: [{
                member: "D_RetailCentres.RetailCentreID",
                operator: "equals",
                values: retailCentreIDStrings
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length === 0) {
            return [];
        }

        rawData.forEach(rawRetailCentreClassification => {
            retailCentreClassification.push({
                retailCentreId: rawRetailCentreClassification["D_RetailCentres.RetailCentreID"] ?? "",
                retailCentreName: rawRetailCentreClassification["D_RetailCentres.RetailCentreName"] ?? "",
                retailCentreClassificationId: rawRetailCentreClassification["D_RetailCentres.RetailCentreClassificationID"] ?? 0,
                retailCentreClassificationName: rawRetailCentreClassification["RetailCentreClassification.RetailCentreClassificationName"] ?? "",
                country: rawRetailCentreClassification["D_RetailCentres.Country"] ?? "",
                region: rawRetailCentreClassification["D_RetailCentres.Region"] ?? ""
            });
        });

        return retailCentreClassification;
    } catch (error) {
        dispatch(logError("Error loading RetailCentreClassification.", error));
        throw error;
    }
};
