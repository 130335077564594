import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class KPMGSpendCategory {
    public readonly id: number;
    public readonly name: string;

    constructor(
        id: number,
        name: string
    ) {
        this.id = id;
        this.name = name;
    }
}

export const loadKPMGSpendCategories = (): AppThunk<Promise<KPMGSpendCategory[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "D_ProductCategory.SpendCategory_ID",
                "D_ProductCategory.ProductCategory3"
            ],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const spendCategories = resultSet.rawData().map(item => 
            new KPMGSpendCategory(
                Number(item["D_ProductCategory.SpendCategory_ID"]),
                String(item["D_ProductCategory.ProductCategory3"])
            )
        );
        return spendCategories;
    } catch (error) {
        dispatch(logError("Error loading ProductCategories.", error));
        throw error;
    }
};
