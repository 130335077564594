import React from "react";
import { useSelector } from "react-redux";

import { Box, Card, CardContent, Typography } from "@material-ui/core";

import Spacer from "components/Spacer";

import { selectors } from "modules/customer/account/users";

const Licenses = () => {
    const licenses = useSelector(state => selectors.licenses(state));

    return (
        <>
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Licenses
                </Typography>
            </Box>
            <Card elevation={0}>
                <CardContent>
                    <Box display="flex" color="text.disabled">
                        <Typography variant="subtitle1" component="div" gutterBottom>
                            Active
                        </Typography>
                        <Spacer />
                        <Typography variant="h6" component="div" color="textPrimary" gutterBottom>
                            {licenses.active}
                        </Typography>
                    </Box>
                    <Box display="flex" color="text.disabled">
                        <Typography variant="subtitle1" component="div">
                            Remaining
                        </Typography>
                        <Spacer />
                        <Typography variant="h6" component="div" color="textPrimary">
                            {licenses.remaining}
                        </Typography>
                    </Box>
                    {licenses.remaining === 0 && (
                        <>
                            <br />
                            <Box color="warning.main">
                                <Typography variant="subtitle2" component="div" gutterBottom>
                                    No licenses remaining
                                </Typography>
                            </Box>
                            <Typography variant="body1" component="div" gutterBottom>
                                Remove an active user or upgrade your plan
                            </Typography>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default Licenses;
