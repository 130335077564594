import React from "react";
import { Grid } from "@material-ui/core";

import NetOpeningsInPastYear from "./NetOpeningsInPastYear";
import LifetimeOfStores from "./LifetimeOfStores";
import NetOpenVsClosuresOverTime from "./NetOpenVsClosuresOverTime";
import ShortTermNetOpenings from "./ShortTermNetOpenings";
import LongTermNetOpenings from "./LongTermNetOpenings";

const OpeningsAndClosures = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <NetOpeningsInPastYear />
            </Grid>
            <Grid item xs={12} md={6}>
                <LifetimeOfStores />
            </Grid>
            <Grid item xs={12}>
                <NetOpenVsClosuresOverTime />
            </Grid>
            <Grid item xs={12}>
                <ShortTermNetOpenings />
            </Grid>
            <Grid item xs={12}>
                <LongTermNetOpenings />
            </Grid>
        </Grid>
    );
};

export default OpeningsAndClosures;
