import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import Column from "components/visuals/Column";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/sales/growth";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";
import { DateTime } from "luxon"; 

const StoreVsComparatorMoMGrowth = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const storeVsComparatorMoMGrowth = useSelector(state => selectors.storeVsComparatorMoMGrowth(state));
    const { loading, error, store, comparator } = storeVsComparatorMoMGrowth;
    const colours = useColourPalette().comparators;

    const storeCombined = store.map(row => {
        const isForecast = (row.forecastFlag === 1);
        return {
            x: new Date(row.year, row.month-1, 2).getTime(),
            y: row.selectedStoreGrowth,
            borderColor: colours[0],
            borderWidth: 1,
            dashStyle: (isForecast) ? "ShortDash" : undefined,
            color: (isForecast) ? colours[2] : colours[0]
        };
    });

    const comparatorCombined = comparator.map(row => {
        const isForecast = (row.forecastFlag === 1);
        return {
            x: new Date(row.year, row.month-1, 2).getTime(),
            y: row.medianComparatorGrowth,
            dashStyle: (isForecast) ? "ShortDash" : undefined,
            borderColor: colours[1],
            borderWidth: 1,
            color: (isForecast) ? colours[3] : colours[1]
        };
    });

    const options = {
        chart: {
            type: "column"
        },
        yAxis: {
            title: {
                text: "Monthly growth (%)"
            }
        },
        title: {
            text: `${selectedStore.name} vs ${selectedComparator.name} historic month-on-month growth % and forecast month-on-month growth % over time`
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            labels: {
                format: '{value:%b %Y}'
            }
        },
        legend: {
            enabled: true,
            align: 'center',
            useHTML: true,
            style: {
                color: "white"
            },
            itemHoverStyle: {
                color: 'white'
            },
            labelFormatter: function () {
                if(this.name === selectedStore.name){
                    return selectedStore.name + '<br/> — Actuals <br/>';
                } else {
                    return selectedComparator.name + '<br/> --- Forecast <br/>';
                }
            }
        },
        series: [{
            name: selectedStore.name,
            custom: {
                roundingCheck: true
            },
            data: storeCombined,
            stack: 'store',
            color: colours[0],
        }, {
            name: selectedComparator.name,
            data: comparatorCombined,
            stack: 'comparator',
            color: colours[1]
        }],
        tooltip:{
            xDateFormat: '%B %Y',
            enabled: true,
            shared: true,
            headerFormat:  ``,            
            formatter: function () {
                const points = this.points;
                const date = DateTime.fromMillis(this.x, { zone: "utc" });

                let categories = [points[0]?.series?.name];
                let values = [numberFormatter.toPercentage(points[0]?.y, 1)];

                if (points.length > 1) {
                    categories.push(points[1]?.series?.name);
                    values.push(numberFormatter.toPercentage(points[1]?.y, 1));
                }

                return `<table>${stringUtils.tooltipHTML(categories, {
                    values: values,
                    header: date.toLocaleString({ month: "long", year: "numeric"}),
                    categoryFormattingArr: [`color:${points[0]?.series?.color}`, `color:${points[1]?.series?.color}`]
                })}</table>`;
            },
            footerFormat: ``,
            useHTML: true
        },
        plotOptions: {
            column: {
                dataLabels:{
                    enabled: false
                }
            }
        }
    };

    return (
        <Box data-cy="store-vs-comparator-mom-growth">
            <Column loading={loading} error={error} options={options} />
        </Box>
    );
};

export default StoreVsComparatorMoMGrowth;
