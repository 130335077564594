import React from "react";
import { Grid } from "@material-ui/core";

import SalesPerSquareFootGauge from "./SalesPerSquareFootGauge";
import SalesPerSquareFootTitle from "./SalesPerSquareFootTitle";
import SalesPerSquareFootVariance from "./SalesPerSquareFootVariance";
import SalesPerSquareFootRank from "./SalesPerSquareFootRank";
import PropertyCostsPerSquareFootGauge from "./PropertyCostsPerSquareFootGauge";
import PropertyCostsPerSquareFootTitle from "./PropertyCostsPerSquareFootTitle";
import PropertyCostsPerSquareFootVariance from "./PropertyCostsPerSquareFootVariance";
import PropertyCostsPerSquareFootRank from "./PropertyCostsPerSquareFootRank";
import SalesPerPoundOfPropertyCostGauge from "./SalesPerPoundOfPropertyCostGauge";
import SalesPerPoundOfPropertyCostTitle from "./SalesPerPoundOfPropertyCostTitle";
import SalesPerPoundOfPropertyCostVariance from "./SalesPerPoundOfPropertyCostVariance";
import SalesPerPoundOfPropertyCostRank from "./SalesPerPoundOfPropertyCostRank";
import StoreContributionCategorisation from "./StoreContributionCategorisation";
import SalesPerPoundPropCostCategorisation from "./SalesPerPoundPropCostCategorisation";

const StoreSize = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={4}>
                        <SalesPerSquareFootGauge />
                        <SalesPerSquareFootTitle />
                        <SalesPerSquareFootVariance />
                        <SalesPerSquareFootRank />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PropertyCostsPerSquareFootGauge />
                        <PropertyCostsPerSquareFootTitle />
                        <PropertyCostsPerSquareFootVariance />
                        <PropertyCostsPerSquareFootRank />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SalesPerPoundOfPropertyCostGauge />
                        <SalesPerPoundOfPropertyCostTitle />
                        <SalesPerPoundOfPropertyCostVariance />
                        <SalesPerPoundOfPropertyCostRank />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <StoreContributionCategorisation />
            </Grid>
            <Grid item xs={12}>
                <SalesPerPoundPropCostCategorisation />
            </Grid>
        </Grid>
    );
};

export default StoreSize;
