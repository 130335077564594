import types from "./types";

const getTotalSalesGrowthByRegionRequest = () => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_REQUEST
});

const getTotalSalesGrowthByRegionSuccess = (regionsGrowth) => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_SUCCESS,
    payload: {
        regionsGrowth
    }
});

const getTotalSalesGrowthByRegionFailure = () => ({
    type: types.GET_TOTAL_SALES_GROWTH_BY_REGION_FAILURE
});

const getSalesByRegionGrowthRequest = () => ({
    type: types.GET_SALES_BY_REGION_GROWTH_REQUEST
});

const getSalesByRegionGrowthSuccess = (storesGrowth) => ({
    type: types.GET_SALES_BY_REGION_GROWTH_SUCCESS,
    payload: {
        storesGrowth
    }
});

const getSalesByRegionGrowthFailure = () => ({
    type: types.GET_SALES_BY_REGION_GROWTH_FAILURE
});

const getDependencyOnSingularRegionOrStoreRequest = () => ({
    type: types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST
});

const getDependencyOnSingularRegionOrStoreSuccess = (label, status, value) => ({
    type: types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS,
    payload: {
        label,
        status,
        value
    }
});

const getDependencyOnSingularRegionOrStoreFailure = () => ({
    type: types.GET_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE
});

const setSelectedRegion = (selectedRegion) => ({
    type: types.SET_SELECTED_REGION,
    payload: {
        selectedRegion
    }
});

const actions = {
    getTotalSalesGrowthByRegionRequest,
    getTotalSalesGrowthByRegionSuccess,
    getTotalSalesGrowthByRegionFailure,
    getSalesByRegionGrowthRequest,
    getSalesByRegionGrowthSuccess,
    getSalesByRegionGrowthFailure,
    getDependencyOnSingularRegionOrStoreRequest,
    getDependencyOnSingularRegionOrStoreSuccess,
    getDependencyOnSingularRegionOrStoreFailure,
    setSelectedRegion
};

export default actions;
