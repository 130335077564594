import { combineReducers } from "redux";

import accounts from "./accounts";
import dataRefreshes from "./dataRefreshes";
import datasetFiles from "./datasetFiles";
import subscriptions from "./subscriptions";
import supportSessions from "./supportSessions";
import users from "./users";


const reducer = combineReducers({
    accounts,
    dataRefreshes,
    datasetFiles,
    subscriptions,
    supportSessions,
    users
});

export default reducer;
