import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

import { selectCategoriesWithQuestions } from "modules/landing/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppSelector } from "store";

const useStyles = makeStyles(theme => ({
    accordion: {
        backgroundColor: theme.palette.common.white
    },
    expanded: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    }
}));

const CategoriesWithQuestions: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const bgcolor = theme.palette.background.paper;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const align = isMobile ? "center" : "left";
    const spacing = isMobile ? 3 : 6;
    const categoriesWithQuestions = useAppSelector(selectCategoriesWithQuestions);

    if (categoriesWithQuestions.length === 0) {
        return null;
    }

    return (
        // @ts-ignore
        <PageSection bgcolor={bgcolor} dataCy="pages-landing-frequently-asked-questions-categories-with-questions">
            <Grid container spacing={spacing}>
                {categoriesWithQuestions.map(category =>
                    <Grid key={category.id} item xs={12}>
                        <Grid container spacing={spacing}>
                            {/* @ts-ignore */}
                            <Grid item xs={12} md={5} align={align}>
                                <Typography variant="h4" component="div">
                                    {category.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                {category.questions.map(question =>
                                    <Box key={question.id} paddingBottom={3}>
                                        <Accordion elevation={0} className={classes.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                classes={{ expanded: classes.expanded }}>
                                                <Typography variant="h5" component="div">
                                                    {question.question}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="subtitle1" component="div">
                                                    {question.answer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </PageSection>
    );
};

export default CategoriesWithQuestions;
