import React from "react";
import { Grid } from "@material-ui/core";

import ForecastSalesWOW from "./ForecastSalesWOW";
import ForecastSalesMOM from "./ForecastSalesMOM";
import ForecastSalesYOY from "./ForecastSalesYOY";
import SalesHistoryForecast from "./SalesHistoryForecast";
import ForecastCompanySalesTrend from "./ForecastCompanySalesTrend";

const ForecastRevenue = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <ForecastSalesWOW />
            </Grid>
            <Grid item xs={12} md={4}>
                <ForecastSalesMOM />
            </Grid>
            <Grid item xs={12} md={4}>
                <ForecastSalesYOY />
            </Grid>
            <Grid item xs={12}>
                <SalesHistoryForecast />
            </Grid>
            <Grid item xs={12}>
                <ForecastCompanySalesTrend />
            </Grid>
        </Grid>
    );
};

export default ForecastRevenue;
