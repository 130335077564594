import { useState, useCallback } from "react";
import Highcharts from "highcharts";

interface TooltipData {
    point: Highcharts.Point;
    event: any;
}

const useTooltipHandler = () => {
    const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

    const handleTooltip = useCallback(function(this: Highcharts.Point, event: any) {
        setTooltipData({ point: this, event });
    }, []);

    const handleMouseOut = useCallback(() => {
        setTooltipData(null);
    }, []);

    return { tooltipData, handleTooltip, handleMouseOut };
};

export default useTooltipHandler;
