import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const AreaHealthSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Having compared the financial performance of your {store?.name} store to {comparator?.name},
            you can now discover what is driving it. Let's look at the retail health of the area surrounding
            your {store?.name} store by analysing local retail openings and closures, the current level of supply
            and demand, the number of footfall drivers, and the level of spending. Is the area on the rise or declining?
        </>
    );
};

export default AreaHealthSubtitle;
