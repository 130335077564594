import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { RetailCentre } from "modules/customer/tools/location/retailCentre";
import { Store } from "modules/customer/tools/location/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import mathUtils from "utils/mathUtils";

export class PotentiallyCannibalisedStore {
    public readonly name: string;
    public readonly storeCategoryId: number;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly retailCentre: RetailCentre;
    public readonly distanceToProposedStore: number;

    constructor(
        name: string,
        storeCategoryId: number,
        latitude: number,
        longitude: number,
        retailCentre: RetailCentre,
        distanceToProposedStore: number
    ) {
        this.name = name;
        this.storeCategoryId = storeCategoryId;
        this.latitude = latitude;
        this.longitude = longitude;
        this.retailCentre = retailCentre;
        this.distanceToProposedStore = distanceToProposedStore;
    }
}

export const loadPotentiallyCannibalisedStores = (pinnedLocation?: PinnedLocation, targetStoreCategoryId?: number, stores?: Store[]): AppThunk<Promise<PotentiallyCannibalisedStore[]>> => async (dispatch) => {
    try {
        if (!pinnedLocation || !targetStoreCategoryId || !stores) {
            return [] as PotentiallyCannibalisedStore[];
        }

        const proposedStoreFilters = {
            and: [{
                member: "CatchmentAreasPopulation.IsScenario",
                operator: "equals",
                values: ["1"]
            }, {
                member: "CatchmentAreasPopulation.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "CatchmentAreasPopulation.RetailCentreID",
                operator: "equals",
                values: [String(pinnedLocation.retailCentre.id)]
            }]
        };

        const retailCentreIdsByStoreCategoryId = new Map<number, number[]>();
        stores.forEach(store => {
            const retailCentreIds = retailCentreIdsByStoreCategoryId.get(store.categoryId) ?? [];
            retailCentreIds.push(store.retailCentre.id);
            retailCentreIdsByStoreCategoryId.set(store.categoryId, retailCentreIds);
        });

        const orFilterClause = { or: [proposedStoreFilters] };
        retailCentreIdsByStoreCategoryId.forEach((retailCentreIds, storeCategoryId) => {
            const andFilterClause = {
                and: [{
                    member: "CatchmentAreasPopulation.IsScenario",
                    operator: "equals",
                    values: ["0"]
                }, {
                    member: "CatchmentAreasPopulation.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryId)]
                }, {
                    member: "CatchmentAreasPopulation.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIds.map(String)
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const query = {
            dimensions: [
                "CatchmentAreasPopulation.RetailCentreID",
                "CatchmentAreasPopulation.StoreCategory_ID",
                "CatchmentAreasPopulation.IsScenario",
                "CatchmentAreasPopulation.MaxDistance"
            ],
            filters: [orFilterClause]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        const proposedStoreData = rawData.find(row => Boolean(row["CatchmentAreasPopulation.IsScenario"]));
        const proposedStore = {
            latitude: pinnedLocation.latitude,
            longitude: pinnedLocation.longitude,
            maximumReach: proposedStoreData["CatchmentAreasPopulation.MaxDistance"]
        };

        const potentiallyCannibalisedStores = [];
        const existingStoresCatchmentData = rawData.filter(row => row["CatchmentAreasPopulation.IsScenario"] === 0);

        for (const store of stores) {
            const catchmentData = existingStoresCatchmentData.find(row => store.retailCentre.id === row["CatchmentAreasPopulation.RetailCentreID"]
                && store.categoryId === row["CatchmentAreasPopulation.StoreCategory_ID"]);

            if (catchmentData) {
                const maximumReach = catchmentData["CatchmentAreasPopulation.MaxDistance"];
                const distanceToProposedStore = mathUtils.haversineDistance(
                    pinnedLocation.latitude,
                    pinnedLocation.longitude,
                    store.latitude,
                    store.longitude
                );

                if (distanceToProposedStore <= (proposedStore.maximumReach + maximumReach)) {
                    potentiallyCannibalisedStores.push(new PotentiallyCannibalisedStore(
                        store.name,
                        store.categoryId,
                        store.latitude,
                        store.longitude,
                        store.retailCentre,
                        distanceToProposedStore
                    ));
                }
            }
        }

        return potentiallyCannibalisedStores as PotentiallyCannibalisedStore[];
    } catch (error) {
        dispatch(logError("Error loading CannibalisedStores.", error));
        throw error;
    }
};
