import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Column from "components/visuals/Column";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectNetOpenVsClosuresOverTime
} from "modules/customer/tools/location/areaHealth/areaHealthSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface ExtendedAnnotation extends Highcharts.Annotation {
    chart: Highcharts.Chart
}

const NetOpenVsClosuresOverTime: React.FC = (props) => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const data = useAppSelector(selectNetOpenVsClosuresOverTime);
    const comparators = useAppSelector(selectComparatorsByChapter);
    const comparatorName = comparators?.areaHealth.storeName;
    const colourPalette = useColourPalette();
    const theme = useTheme();

    const quadrantAnnotation = (annotation: ExtendedAnnotation, useXMax: boolean, useYMax: boolean): Highcharts.AnnotationMockPointOptionsObject => {
        const chart = annotation.chart;

        if (chart === undefined) {
            return { x: 0, y: -1e6, xAxis: 0, yAxis: 0 };
        }

        const xAxis = chart.xAxis[0], yAxis = chart.yAxis[0];

        const annotationSpacingFractionX = 0.005;
        const annotationSpacingFractionY = chart.plotWidth / chart.plotHeight * annotationSpacingFractionX;

        const axisVal = (axis: Highcharts.Axis, useMax: boolean, spacingFraction: number) => {
            const max = axis.max ?? 0;
            const min = axis.min ?? 0;
            const range = max - min;
            const spacer = range * spacingFraction;

            return useMax ? max - spacer : min + spacer;
        };

        return {
            x: axisVal(xAxis, useXMax, annotationSpacingFractionX),
            xAxis: 0,
            y: axisVal(yAxis, useYMax, annotationSpacingFractionY),
            yAxis: 0
        };
    };

    const options: Highcharts.Options = {
        xAxis: {
            categories: data.location.map(row => row.timeframe),
            plotBands: [{
                from: 1.5,
                to: 10,
                color: colourPalette.RAGChartZones[2]
            }]
        },
        yAxis: {
            title: {
                text: "% change in number of stores"
            }
        },
        annotations: [{
            draggable: "",
            labelOptions: {
                shape: "rect",
                borderRadius: 6,
                borderWidth: 0,
                overflow: "justify",
                y: 0,
                verticalAlign: "bottom",
                style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "13px",
                    color: "white"
                }
            },
            labels: [{
                point: function (annotation) {
                    return quadrantAnnotation(annotation as ExtendedAnnotation, true, true);
                },
                x: 100,
                align: "right",
                text: "Short-term",
                backgroundColor: "#4D4860"
            }, {
                point: function (annotation) {
                    return quadrantAnnotation(annotation as ExtendedAnnotation, false, true);
                },
                x: -100,
                align: "left",
                text: "Long-term",
                backgroundColor: "#292542",
                crop: false
            }]
        }],
        navigation: {
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                },
                custom: {
                    printMe: true
                }
            }
        },
        tooltip: {
            enabled: true,
            shared: true,
            headerFormat: ``,
            footerFormat: ``,
            formatter: function () {
                const points = this.points ?? [];

                let categoryArr: string[] = [];
                let categoryFormatArr: string[] = [];
                let valueFormatArr: string[] = [];
                let valuesArr: string[] = [];

                let series: Highcharts.Series;
                let point: Highcharts.TooltipFormatterContextObject;
                for (let i = 0; i < points.length; i++) {
                    point = points[i];
                    series = point.series;
                    const pcChange: number = point.y ?? 0;
                    let pcChangeSign: string = "";
                    if (pcChange > 0) {
                        pcChangeSign = "+";
                    }
                    categoryArr.push(series?.name, `Number of net openings: `, `(%) change in the number of stores: `);
                    categoryFormatArr.push(`color:${series?.options?.color};font-weight:bold`, ``, ``);
                    valueFormatArr.push(``, `color:${series?.options?.color};font-weight:bold`, `color:${series?.options?.color};font-weight:bold`);
                    valuesArr.push(``, numberFormatter.toDecimalPlaces(point.point.options.custom?.netOpenings, 0), pcChangeSign + numberFormatter.toPercentage(point.y));
                }

                return `<table>${stringUtils.tooltipHTML(categoryArr, {
                    header: points[0].key,
                    values: (valuesArr),
                    categoryFormattingArr: categoryFormatArr,
                    valueFormattingArr: valueFormatArr
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            type: "column",
            name: "Selected location",  //TODO make dynamic
            data: data.location.map(obj => {
                return { y: obj.percentageChange, category: obj.timeframe, custom: { netOpenings: obj.netOpenings } };
            }),
            color: colourPalette.comparators[0]
        }, {
            type: "column",
            name: `Comparator: ${comparatorName}`, //TODO make dynamic
            data: data.comparators.map(obj => {
                return { y: obj.percentageChange, category: obj.timeframe, custom: { netOpenings: obj.netOpenings } };
            }),
            color: colourPalette.comparators[1]
        }]
    };

    return (
        <Box data-cy="net-open-vs-closures-over-time">
            <Typography variant="h6" component="div" gutterBottom>
                (%) change in the number of stores within the local area of the selected location vs your {comparatorName} store 
            </Typography>
            <Column loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default NetOpenVsClosuresOverTime;
