import React from "react";
import { Grid } from "@material-ui/core";

import StoreVsComparatorRevVsComp from "./StoreVsComparatorRevVsComp";
import RevenueVsCompetitionScoreRAGIndicator from "./RevenueVsCompetitionScoreRagIndicator";

const RevenueVsCompetitionScore = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreVsComparatorRevVsComp />
            </Grid>
            <Grid item xs={12}>
                <RevenueVsCompetitionScoreRAGIndicator />
            </Grid>
        </Grid>
    );
};

export default RevenueVsCompetitionScore;
