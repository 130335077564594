import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createAppAsyncThunk } from "appThunk";
import { apiGet, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { RootState } from "store";

interface AppDetails {
    versionNumber: string,
    environmentName: string,
    isDevelopment: boolean,
    isProduction: boolean
}

interface AppDetailsState {
    isLoaded: boolean,
    appDetails: AppDetails
}

const appDetailsEmpty: AppDetails = {
    versionNumber: "",
    environmentName: "",
    isDevelopment: false,
    isProduction: false
};

const initialState: AppDetailsState = {
    isLoaded: false,
    appDetails: appDetailsEmpty
};

const appDetailsSlice = createSlice({
    name: "appDetails",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(getAppDetails.pending, (state: AppDetailsState) => {
            state.isLoaded = false;
        });
        builder.addCase(getAppDetails.rejected, (state: AppDetailsState) => {
            state.isLoaded = true;
        });
        builder.addCase(getAppDetails.fulfilled, (state: AppDetailsState, action: PayloadAction<AppDetails>) => {
            state.isLoaded = true;
            state.appDetails = action.payload;
        });
    }
});

export const getAppDetails = createAppAsyncThunk(
    "appDetails/getAppDetails",
    async (arg, thunkAPI) => {
        const response = await thunkAPI.dispatch(apiGet("/app-details"));
        if (response.status === ApiResponseStatus.Ok) {
            return response.data.appDetails;
        }
        return thunkAPI.rejectWithValue(null);
    }
);

export const selectIsAppDetailsLoaded = (state: RootState): boolean => {
    return state.appDetails.isLoaded;
};

export const selectAppDetails = (state: RootState) => {
    return state.appDetails.appDetails;
};

export default appDetailsSlice;
