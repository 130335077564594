import React from "react";

import AirplayRoundedIcon from "@material-ui/icons/AirplayRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { AccountStatus as AccountStatusEnum } from "modules/admin/accounts/accounts/accountsSlice";

const StyledAlert = withStyles(theme => ({
    root: {
        borderLeftWidth: theme.spacing(1.5),
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        color: (props: any) => props["data-color"],
        borderColor: (props: any) => props["data-color"]
    }
}))(Alert);

interface AccountStatusProps {
    status: AccountStatusEnum
}

const AccountStatus: React.FC<AccountStatusProps> = (props) => {
    const { status } = props;
    const theme = useTheme();
    const { color, icon, text } = (() => {
        switch (status) {
            case AccountStatusEnum.Demo:
                return {
                    color: theme.palette.secondary.main,
                    icon: <AirplayRoundedIcon />,
                    text: "Demo"
                };
            case AccountStatusEnum.Active:
                return {
                    color: theme.palette.success.main,
                    icon: <CheckCircleOutlineRoundedIcon />,
                    text: "Active"
                };
            case AccountStatusEnum.NoSubscription:
            default:
                return {
                    color: theme.palette.text.disabled,
                    icon: <HighlightOffRoundedIcon />,
                    text: "No subscription"
                };
        }
    })();

    return (
        <StyledAlert
            data-color={color}
            variant="outlined"
            icon={
                React.cloneElement(icon, {
                    fontSize: "inherit",
                    style: {
                        color: color
                    }
                })
            }
        >
            {text}
        </StyledAlert>
    );
};

export default AccountStatus;
