import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectSpendOfWeightedCatchment } from "modules/customer/tools/location/spendNew/spendSlice";
import { useAppSelector } from "store";

const SpendOfWeightedCatchment: React.FC = () => {
    const spendOfWeightedCatchment = useAppSelector(selectSpendOfWeightedCatchment);
    return (
        <RAGIndicatorNew ragIndicator={spendOfWeightedCatchment} />
    );
};

export default SpendOfWeightedCatchment;
