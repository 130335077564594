import React from "react";

import { Typography } from "@material-ui/core";

import PageSection from "components/landing/PageSection";

const Checking: React.FC = () => {
    return (
        // @ts-ignore
        <PageSection dataCy="pages-landing-registration-checking">
            <Typography variant="h2" component="div" align="center">
                We're getting things ready for you...
            </Typography>
        </PageSection>
    );
};

export default Checking;
