import React from "react";

import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearSavedLocationForm,
    deleteSavedLocation,
    getSavedLocation,
    hideDeleteSavedLocation,
    selectDeleteSavedLocationVisibility,
    selectSavedLocationForm,
    showDeleteSavedLocation
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteSavedLocationProps {
    savedLocationId: string
}

const DeleteSavedLocation: React.FC<DeleteSavedLocationProps> = (props) => {
    const { savedLocationId } = props;
    const dispatch = useAppDispatch();
    const form = useAppSelector(selectSavedLocationForm);
    const deleteSavedLocationVisibility = useAppSelector(selectDeleteSavedLocationVisibility);
    const show = (deleteSavedLocationVisibility.isVisible) && (savedLocationId === deleteSavedLocationVisibility.savedLocationId);

    const handleDeleteClick = () => {
        dispatch(showDeleteSavedLocation(savedLocationId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteSavedLocation());
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteSavedLocation());
        dispatch(clearSavedLocationForm());
    };

    React.useEffect(() => {
        if (show) {
            dispatch(getSavedLocation(savedLocationId));
        }
    }, [dispatch, show, savedLocationId]);

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteClick}>
                    <DeleteRoundedIcon fontSize="inherit" />
                </StyledIconButton>
            </SimpleTooltip>
            <ConfirmationDialog
                open={show}
                title={`Are you sure you want to delete the saved location ${form.name}?`}
                subtitle="This location will be deleted for all account members and cannot be undone"
                type={ConfirmationDialogType.Error}
                confirmLabel="Yes, delete"
                cancelLabel="No, cancel"
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
            />
        </>
    );
};

export default DeleteSavedLocation;
