import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { DateTime } from "luxon";

export class MonthlyRevenue {
    public readonly date: DateTime;
    public readonly revenue: number;

    constructor(
        date: DateTime,
        revenue: number
    ) {
        this.date = date;
        this.revenue = revenue;
    }
}

export const loadMonthlyRevenue = (costReferenceDate: DateTime): AppThunk<Promise<MonthlyRevenue[]>> => async (dispatch) => {
    try {
        if (!costReferenceDate) {
            return [];
        }

        const priorSixtyMonthsToReferenceDate = costReferenceDate.minus({ months: 60 }).plus({ days: 1 }).startOf("day");

        const salesQuery = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorSixtyMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            dimensions: ["D_Date.MonthStartDate"],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        const salesPromise = dispatch(cubeLoad(salesQuery));

        const result = await Promise.all([salesPromise]) as unknown as ResultSet[];

        const salesRawData = result[0].rawData();

        return salesRawData.map(salesRow => {
            return new MonthlyRevenue(
                DateTime.fromISO(salesRow["D_Date.MonthStartDate"], { zone: "utc" }),
                salesRow["F_Sales.SumLineValue"]
            );
        });
    } catch (error) {
        dispatch(logError("Error loading MonthlyRevenue.", error));
        throw error;
    }
};
