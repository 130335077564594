import React from "react";

import { Box, Button, Checkbox, IconButton, TextField, Typography } from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import {
    clearQuestion,
    editQuestion,
    getQuestion,
    hideEditQuestion,
    selectQuestion,
    selectEditQuestionVisibility,
    setQuestion,
    showEditQuestion
} from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

const useStyles = makeStyles(theme => ({
    input: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}));

interface EditQuestionProps {
    categoryId: string,
    questionId: string
}

const EditQuestion: React.FC<EditQuestionProps> = (props) => {
    const { categoryId, questionId } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const question = useAppSelector(selectQuestion);
    const editQuestionVisibility = useAppSelector(selectEditQuestionVisibility);
    const show = (editQuestionVisibility.isVisible) && (questionId === editQuestionVisibility.questionId);

    React.useEffect(() => {
        if (show) {
            dispatch(getQuestion(categoryId, questionId));
        }
    }, [dispatch, show, categoryId, questionId]);

    const handleEditQuestionClick = () => {
        dispatch(showEditQuestion(questionId));
    };

    const handleSubmitClick = () => {
        dispatch(editQuestion(categoryId));
    };

    const handleCancelClick = () => {
        dispatch(hideEditQuestion());
        dispatch(clearQuestion());
    };

    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newQuestion = { ...question, question: value, errors: { ...question.errors, question: "" } };
        dispatch(setQuestion(newQuestion));
    };

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const answer = event.target.value;
        const newQuestion = { ...question, answer, errors: { ...question.errors, answer: "" } };
        dispatch(setQuestion(newQuestion));
    };

    const handleShowOnLandingAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnLandingArea = event.target.checked;
        const newQuestion = { ...question, showOnLandingArea };
        dispatch(setQuestion(newQuestion));
    };

    const handleShowOnCustomerAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const showOnCustomerArea = event.target.checked;
        const newQuestion = { ...question, showOnCustomerArea };
        dispatch(setQuestion(newQuestion));
    };

    return (
        <>
            {/* @ts-ignore */}
            <SimpleTooltip title="Edit">
                <StyledIconButton size="small" onClick={handleEditQuestionClick}>
                    <EditRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={
                    <Typography variant="h5" component="div">Edit question</Typography>
                }
                content={
                    <>
                        <TextField
                            label="Question"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            multiline
                            minRows={4}
                            value={question.question}
                            onChange={handleQuestionChange}
                            error={!!question.errors.question}
                            helperText={question.errors.question}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <TextField
                            label="Answer"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            margin="normal"
                            multiline
                            minRows={4}
                            value={question.answer}
                            onChange={handleAnswerChange}
                            error={!!question.errors.answer}
                            helperText={question.errors.answer}
                            required
                            fullWidth
                            InputProps={{ classes: { input: classes.input } }}
                        />
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={question.showOnLandingArea}
                                onChange={handleShowOnLandingAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on landing area
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={question.showOnCustomerArea}
                                onChange={handleShowOnCustomerAreaChange}
                            />
                            <Typography variant="subtitle1" component="div">
                                Show on customer area
                            </Typography>
                        </Box>
                    </>
                }
                actions={
                    <Button variant="contained" color="secondary" disableElevation onClick={handleSubmitClick}>
                        Save
                    </Button>
                }
            />
        </>
    );
};

export default EditQuestion;
