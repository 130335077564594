import { combineReducers } from "redux";

import types from "./types";

const penPortraitsSearchEmpty = {
    loading: false,
    error: false,
    penPortraits: []
};

const penPortraitsSearch = (state = penPortraitsSearchEmpty, action) => {
    switch (action.type) {
        case types.GET_PEN_PORTRAITS_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                penPortraits: []
            };
        case types.GET_PEN_PORTRAITS_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                penPortraits: action.payload.penPortraits
            };
        case types.GET_PEN_PORTRAITS_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                penPortraits: []
            };
        default:
            return state;
    }
};

const customerProfilesEmpty = {
    loading: false,
    error: false,
    values: []
};

const customerProfiles = (state = customerProfilesEmpty, action) => {
    switch (action.type) {
        case types.GET_CUSTOMER_PROFILES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: [],
            };
        case types.GET_CUSTOMER_PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values,
            };
        case types.GET_CUSTOMER_PROFILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: [],
            };
        default:
            return state;
    }
};
const populationEmpty = {
    loading: false,
    error: false,
    selectedStore: null,
    comparator: []
};

const population = (state = populationEmpty, action) => {
    switch (action.type) {
        case types.GET_POPULATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                selectedStore: null,
                comparator: []
            };
        case types.GET_POPULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                selectedStore: action.payload.selectedStore,
                comparator: action.payload.comparator,
            };
        case types.GET_AMOUNT_OF_BEST_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                selectedStore: {},
                comparator: []
            };
        default:
            return state;
    }
};

const marketCategorySpendEmpty = {
    loading: false,
    error: false,
    values: []
};

const marketCategorySpend = (state = marketCategorySpendEmpty, action) => {
    switch (action.type) {
        case types.GET_MARKET_CATEGORY_SPEND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                values: []
            };
        case types.GET_MARKET_CATEGORY_SPEND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                values: action.payload.values,
            };
        case types.GET_MARKET_CATEGORY_SPEND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                values: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    penPortraitsSearch,
    customerProfiles,
    population,
    marketCategorySpend
});

export default reducer;
