import React from "react";
import PropTypes from "prop-types";

import { Box, Container, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const PageSection = React.forwardRef((props, ref) => {
    const { dataCy, color, bgcolor, maxWidth, position, top, disablePaddingTop, disablePaddingBottom } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const paddingTopValue = disablePaddingTop ? 0 : 16;
    const paddingBottomValue = disablePaddingBottom ? 0 : 16;
    const paddingTop = isMobile ? (paddingTopValue / 2) : paddingTopValue;
    const paddingBottom = isMobile ? (paddingBottomValue / 2) : paddingBottomValue;

    return (
        <Box
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            color={color}
            bgcolor={bgcolor}
            position={position}
            top={top}
            ref={ref}
            data-cy={dataCy}
        >
            <Container maxWidth={maxWidth}>
                {props.children}
            </Container>
        </Box>
    );
});

PageSection.propTypes = {
    dataCy: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string,
    maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
    position: PropTypes.oneOf(["static", "relative", "absolute", "sticky"]),
    top: PropTypes.number,
    disablePaddingTop: PropTypes.bool,
    disablePaddingBottom: PropTypes.bool
};

PageSection.defaultProps = {
    color: "inherit",
    bgcolor: "inherit",
    maxWidth: "lg",
    position: "static",
    disablePaddingTop: false,
    disablePaddingBottom: false
};

export default PageSection;
