import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import numberFormatter from "utils/numberFormatter";

import KPIComparator from "components/visuals/KPIComparator";

import { operations, selectors } from "modules/customer/insights/performance/overview/keyPerformanceIndicators";

const YTDSalesPlusYTGSalesVsBudgetedSales = () => {
    const dispatch = useDispatch();
    const ytdSalesPlusYTGSalesVsBudgetedSales = useSelector(state => selectors.ytdSalesPlusYTGSalesVsBudgetedSales(state));
    const { loading, error, value, percentageDifference } = ytdSalesPlusYTGSalesVsBudgetedSales;
    const label = "Historic and forecast revenue vs budgeted revenue";
    const valueFormatted = numberFormatter.toGBP(value, 1);

    React.useEffect(() => {
        dispatch(operations.getYTDSalesPlusYTGSalesVsBudgetedSales());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="ytd-sales-plus-ytg-sales-vs-budgeted-sales">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} noDataMessage={"No budget data available"} />
        </Box>
    );
};

export default YTDSalesPlusYTGSalesVsBudgetedSales;
