import React from "react";
import { NavLink } from "react-router-dom";

import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { ReactComponent as LogoDesktop } from "assets/images/dash-logo-desktop-white-horizontal.svg";
import { ReactComponent as LogoMobile } from "assets/images/dash-logo-mobile-white.svg";

const useStyles = makeStyles(() => ({
    navLink: {
        display: "flex"
    }
}));

const Logo: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <NavLink to="/" className={classes.navLink}>
            {isMobile ? <LogoMobile /> : <LogoDesktop />}
        </NavLink>
    );
};

export default Logo;
