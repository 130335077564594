import React from "react";

import { SvgIcon } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import SyncProblemRoundedIcon from "@material-ui/icons/SyncProblemRounded";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledSvgIcon = withStyles(theme => ({
    root: {
        color: (props: any) => props["data-color"]
    }
}))(SvgIcon);

enum DatasetStatus {
    Unknown = "Unknown",
    Submitted = "Submitted",
    Ok = "Ok",
    Processing = "Processing",
    ProcessingIssue = "ProcessingIssue",
    ReadyForRefresh = "ReadyForRefresh",
    RecommendUpdate = "RecommendUpdate"
}

interface DatasetIconProps {
    datasetStatus: DatasetStatus,
    isDatasetRequired: boolean
}

const DatasetIcon: React.FC<DatasetIconProps> = (props) => {
    const { datasetStatus, isDatasetRequired } = props;
    const theme = useTheme();
    const { color, icon } = (() => {
        switch (datasetStatus) {
            case DatasetStatus.Ok:
                return {
                    color: theme.palette.success.main,
                    icon: <CheckCircleOutlineIcon />
                };
            case DatasetStatus.Submitted:
                return {
                    color: theme.palette.text.disabled,
                    icon: <SyncRoundedIcon />
                };
            case DatasetStatus.Processing:
                return {
                    color: theme.palette.text.disabled,
                    icon: <SyncRoundedIcon />
                };
            case DatasetStatus.ProcessingIssue:
                return {
                    color: theme.palette.error.main,
                    icon: <SyncProblemRoundedIcon />
                };
            case DatasetStatus.ReadyForRefresh:
                return {
                    color: theme.palette.success.main,
                    icon: <CheckCircleOutlineIcon />
                };
            case DatasetStatus.RecommendUpdate:
                return {
                    color: theme.palette.warning.main,
                    icon: <UpdateRoundedIcon />
                };
            case DatasetStatus.Unknown:
            default:
                return {
                    color: isDatasetRequired ? theme.palette.secondary.main : theme.palette.text.secondary,
                    icon: <NoteAddOutlinedIcon />
                };
        }
    })();

    return (
        <StyledSvgIcon size="small" data-color={color}>
            {icon}
        </StyledSvgIcon>
    );
};

export default DatasetIcon;
