import React from "react";

import { Button, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import SimpleDialog from "components/SimpleDialog";

import { useAppDispatch, useAppSelector } from "store";
import {
    cancelUpload,
    Dataset,
    hideCloseUploadConfirmation,
    hideUpload,
    selectCloseUploadConfirmationVisibility
} from "modules/customer/data/dataSlice";

interface CloseUploadConfirmationProps {
    dataset: Dataset
}

const CloseUploadConfirmation: React.FC<CloseUploadConfirmationProps> = (props) => {
    const { dataset } = props;
    const dispatch = useAppDispatch();
    const closeUploadConfirmationVisibility = useAppSelector(selectCloseUploadConfirmationVisibility);
    const show = (closeUploadConfirmationVisibility.isVisible) && (dataset.id === closeUploadConfirmationVisibility.datasetId);

    const handleCancelClick = () => {
        dispatch(cancelUpload());
        dispatch(hideUpload());
        dispatch(hideCloseUploadConfirmation());
    };

    const handleContinueClick = () => {
        dispatch(hideCloseUploadConfirmation());
    };

    return (
        <SimpleDialog
            open={show}
            onClose={handleContinueClick}
            title={
                <>
                    <Typography variant="h1" component="div" color="error" gutterBottom>
                        <ErrorOutlineIcon fontSize="inherit" color="inherit" />
                    </Typography>
                    <Typography variant="h5" component="div" gutterBottom>
                        Closing this window will cancel your file upload
                    </Typography>
                </>
            }
            titleAlign="center"
            content={
                <>
                    <Typography variant="subtitle1" component="div">
                        You will lose all progress
                    </Typography>
                </>
            }
            contentAlign="center"
            actions={
                <>
                    <Button
                        variant="outlined"
                        size="large"
                        color="default"
                        disableElevation
                        onClick={handleCancelClick}
                    >
                        Cancel upload
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        disableElevation
                        onClick={handleContinueClick}
                    >
                        Continue upload
                    </Button>
                </>
            }
            actionsJustifyContent="center"
        />
    );
};

export default CloseUploadConfirmation;
