import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";

import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import dateUtils from "utils/dateUtils";
import actions from "./actions";

const getGrossRevenueYTD = () => async (dispatch, getState) => {
    dispatch(actions.getGrossRevenueYTDRequest());
    try {
        const state = getState();
        const financialYearToDateEndDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearStartDate = dateUtils.previousFinancialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearToDateEndDate = dateUtils.previousFinancialYearToDateEndDate(financialYearToDateEndDate);
        const query = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [financialYearStartDate, financialYearToDateEndDate],
                    [previousFinancialYearStartDate, previousFinancialYearToDateEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_Sales.SumLineValue"];
        const previousfinancialYearToDateValue = resultSet.loadResponses[1].data[0]["F_Sales.SumLineValue"];
        let percentageDifference = 0;
        if(previousfinancialYearToDateValue !== value){
            (!!previousfinancialYearToDateValue === false) ? percentageDifference = 100 : 
            percentageDifference =  100 * ((value - previousfinancialYearToDateValue) / previousfinancialYearToDateValue);
        }
 
        dispatch(actions.getGrossRevenueYTDSuccess(value, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getGrossRevenueYTDFailure());
        dispatch(logError("Error loading GrossRevenueYTD.", error));
    }
};

const getContributionYTD = () => async (dispatch, getState) => {
    dispatch(actions.getContributionYTDRequest());
    try {
        const state = getState();
        const financialYearToDateEndDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearStartDate = dateUtils.previousFinancialYearStartDate(financialYearToDateEndDate, fyStart);
        const previousFinancialYearToDateEndDate = dateUtils.previousFinancialYearToDateEndDate(financialYearToDateEndDate);
        const query = {
            measures: ["F_Sales.SumContribution"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [financialYearStartDate, financialYearToDateEndDate],
                    [previousFinancialYearStartDate, previousFinancialYearToDateEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_Sales.SumContribution"];
        const previousfinancialYearToDateValue = resultSet.loadResponses[1].data[0]["F_Sales.SumContribution"];
        let percentageDifference = 0;
        if(previousfinancialYearToDateValue !== value){
            (!!previousfinancialYearToDateValue === false) ? percentageDifference = 100 : 
            percentageDifference =  100 * ((value - previousfinancialYearToDateValue) / previousfinancialYearToDateValue);
        }
        dispatch(actions.getContributionYTDSuccess(value, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getContributionYTDFailure());
        dispatch(logError("Error loading ContributionYTD.", error));
    }
};

const getForecastSalesYTG = () => async (dispatch, getState) => {
    dispatch(actions.getForecastSalesYTGRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYTGStartDate = dateUtils.nextDay(currentDate);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);
        const previousFinancialYTGStartDate = dateUtils.previousFinancialYTGStartDate(currentDate);
        const previousFinancialYearEndDate = dateUtils.previousFinancialYearEndDate(currentDate, fyStart);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [financialYTGStartDate, financialYearEndDate],
                    [previousFinancialYTGStartDate, previousFinancialYearEndDate]
                ]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];
        const previousfinancialYearToGoValue = resultSet.loadResponses[1].data[0]["F_SalesForecast.SumLineValue"];
        const percentageDifference = (previousfinancialYearToGoValue === 0) ? 0 : 100 * ((value - previousfinancialYearToGoValue) / previousfinancialYearToGoValue);
        dispatch(actions.getForecastSalesYTGSuccess(value, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getForecastSalesYTGFailure());
        dispatch(logError("Error loading ForecastSalesYTG.", error));
    }
};

const getYTDSalesPlusYTGSalesVsBudgetedSales = () => async (dispatch, getState) => {
    dispatch(actions.getYTDSalesPlusYTGSalesVsBudgetedSalesRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(currentDate, fyStart);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query));
        const value = resultSet.loadResponses[0].data[0]["F_SalesForecast.SumLineValue"];

        const budgetQuery = {
            measures: ["F_Budget.SumBudgetRevenue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate],
            }]
        };
        const budgetResultSet = await dispatch(cubeLoad(budgetQuery));
        const budgetFY = budgetResultSet.loadResponses[0].data[0]["F_Budget.SumBudgetRevenue"];

        let percentageDifference = 0;
        
        if (budgetFY === 0) {
            percentageDifference = 100;
        } else if (!budgetFY) {
            percentageDifference = null;
        } else {
            percentageDifference = 100 * ((value - budgetFY) / budgetFY);
        }
        dispatch(actions.getYTDSalesPlusYTGSalesVsBudgetedSalesSuccess(value, percentageDifference));
    }
    catch (error) {
        dispatch(actions.getYTDSalesPlusYTGSalesVsBudgetedSalesFailure());
        dispatch(logError("Error loading YTDSalesPlusYTGSalesVsBudgetedSales.", error));
    }
};
const operations = {
    getGrossRevenueYTD,
    getContributionYTD,
    getForecastSalesYTG,
    getYTDSalesPlusYTGSalesVsBudgetedSales
};

export default operations;
