import { RetailCentreWithScores } from "./retailCentre";

export class PinnedLocation {
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly address: string;
    public readonly retailCentre: RetailCentreWithScores;

    constructor(latitude: number, longitude: number, address: string, retailCentre: RetailCentreWithScores) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.address = address;
        this.retailCentre = retailCentre;
    }
}
