import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const RankedGrowthSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Analyse your {store?.name} store's 12-month growth rate and contextualise its growth
            against {comparator?.name}. Determine whether your {store?.name} store is a bottom, average, or top
            performer and where it ranks among {comparator?.name}.
        </>
    );
};

export default RankedGrowthSubtitle;
