import React from "react";
import { Button, Card, CardMedia, Grid, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/customer/PageSection";

const StyledImageCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const PageNotFound = () => {
    return (
        <PageSection data-cy="pages-admin-page-not-found">
            <Grid container spacing={10} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={5}>
                    <StyledImageCard elevation={0}>
                        <CardMedia component="img" image="/content/admin/page-not-found/circles.svg" alt="page not found" />
                    </StyledImageCard>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h3" gutterBottom>Page not found</Typography>
                    <Typography variant="h4">The requested URL was not found on the server</Typography>
                    <br />
                    <Button
                        component={NavLink}
                        to="/dashboard"
                        variant="contained"
                        size="large"
                        color="primary"
                        disableElevation
                        data-cy="btn-go-to-dashboard">
                        Go to Dashboard
                    </Button>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default PageNotFound;
