import React from "react";

import { Box, Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";
import useColourPalette from "components/visuals/useColourPalette";

import { useAppDispatch, useAppSelector } from "store";
import { selectComparator, selectIsSetupComplete, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { showFilters } from "modules/customer/insights/portfolio/filters/filtersSlice";

const StyledCard = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-is-setup-complete"] ? theme.palette.background.default : theme.palette.secondary.main,
        maxWidth: theme.spacing(41)
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        minHeight: theme.spacing(10)
    }
}))(CardActionArea);

const OpenDesktop: React.FC = () => {
    const dispatch = useAppDispatch();
    const colourPalette = useColourPalette();
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const isSetupComplete = useAppSelector(selectIsSetupComplete);

    const handleClick = () => {
        dispatch(showFilters());
    };

    return (
        <>
            <StyledCard
                elevation={0}
                variant="outlined"
                data-is-setup-complete={isSetupComplete}
                data-cy="btn-filters"
            >
                <StyledCardActionArea onClick={handleClick}>
                    {isSetupComplete ? (
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="div">
                                    Selected store:&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                                    {store?.name}
                                </Typography>
                            </Box>
                            <br />
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="div">
                                    Comparator:&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                                    {comparator?.name}
                                </Typography>
                            </Box>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="div">
                                    Select store(s)
                                </Typography>
                                <Spacer />
                                <ArrowForwardRoundedIcon fontSize="medium" />
                            </Box>
                        </CardContent>
                    )}
                </StyledCardActionArea>
            </StyledCard>
            <br />
            {!isSetupComplete &&
                <Typography variant="subtitle1" component="div">
                    Portfolio requires a store selection before insights are generated
                </Typography>
            }
        </>
    );
};

export default OpenDesktop;
