import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const GrowthSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Understand how your {store?.name} store has grown compared to {comparator?.name}. Our forecast
            helps you understand if its relative performance is likely to improve or decline in the future?
        </>
    );
};

export default GrowthSubtitle;
