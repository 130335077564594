import React from "react";
import { Box } from "@material-ui/core";
import StackedAreaGraph from "components/visuals/StackedArea";
import useColourPalette from "components/visuals/useColourPalette";

const StackedArea = () => {
    const colourPalette = useColourPalette();
    const options = {
     
        title: {
            text: 'Stacked Area Chart Example'
        },

        yAxis: {
            title: {
                text: "XXX"
            }
        },
        tooltip: {
            split: false,
            headerFormat: '',
            pointFormatter: function() {
                return`
                <span><b> ${this.series.name} </b></span></br></br>
                <span style="height: 12px; display: block;"></span>
                <span> % of baskets </span> </br>
                <span>include ${this.series.name}</span>
                <span style="width: 30px; display: inline-block;"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span> ${this.percentage.toFixed(0)}%</span>`;
            }

            
        },
        series: [
      
        {
            name: 'Fondue',
            data: [100, 90, 100, 34, 68, 78, 96, 33, 87, 40, 67, 100]
        },{
            name: 'Kefir',
            data: [95, 83, 31, 67, 90, 39, 78, 79, 50, 82, 77, 90]
        },{
            name: 'Custard',
            data: [81, 73, 57, 67, 88, 78, 36, 53, 68, 34, 69, 83]
        },{
            name: 'Cheese',
            data: [52, 50, 53, 65, 63, 54, 46, 60, 52, 65, 53, 73]
        },{
            name: 'Cream',
            data: [42, 44, 50, 55, 50, 43, 40, 47, 44 ,36, 50, 61]
        },{
            name: 'Milk',
            data: [28, 37, 31, 43, 28, 36, 32, 27, 9 ,27, 41, 39]
        },{
            name: 'Butter',
            data: [10, 12, 27, 39, 14, 25, 12, 13, 30 ,10, 36, 15]
        },{
            name: 'Yoghurt',
            data: [17, 10, 7, 30, 4, 5, 6, 7, 37 ,9, 18, 9]
        }]    
    };

    return (
        <Box>
            <StackedAreaGraph
            options={options} 
            colourPalette={colourPalette.categorical}
            />
           
        </Box>
    );
};

export default StackedArea;


