import React from "react";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectHistoricRevenueGrowth } from "modules/customer/insights/portfolioNew/revenue/revenueSlice";

const HistoricRevenueGrowth: React.FC = () => {
    const historicRevenueGrowth = useAppSelector(selectHistoricRevenueGrowth);
    return (
        <RAGIndicatorNew ragIndicator={historicRevenueGrowth} />
    );
};

export default HistoricRevenueGrowth;
