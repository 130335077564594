import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import CostOverviewSubchapter from "./costOverview/CostOverview";
import CostReductionOpportunities from "./costReductionOpportunities/CostReductionOpportunities";

const CostOverview: React.FC = () => {
    return (
        <Chapter
            title="Cost overview"
            subtitle="Efficiently manage your estate's costs with insights to track spending, focus on key cost types, and uncover targeted savings opportunities."
            dataCy="cost-overview-chapter"
        >
            <Subchapter
                title="Cost overview"
                subtitle="Gain a clear view of your estate's costs. Track total spend and focus on specific cost types across your estate. Identify trends in spending, helping you to manage budgets effectively and optimise cost efficiency."
                dataCy="cost-overview-subchapter"
            >
                <CostOverviewSubchapter />
            </Subchapter>
            <Subchapter
                title="Cost reduction opportunities"
                subtitle="Discover cost reduction opportunities. Compare store costs against similar stores across your estate. Identify areas of excess spending and lean operations, so you can target inefficiencies and make informed, strategic reductions."
                dataCy="cost-reduction-opportunities-subchapter"
            >
                <CostReductionOpportunities />
            </Subchapter>
        </Chapter>
    );
};

export default CostOverview;
