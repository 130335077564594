import { createAppAsyncThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { DateTime } from "luxon";
import { logError } from "modules/helpers/logger/loggerSlice";

export class WeeklySales {
    public readonly storeID: string;
    public readonly forecastFlag: boolean;
    public readonly isOnline: boolean;
    public readonly weekStartDate: DateTime;
    public readonly totalSales: number;
    public readonly totalContribution: number;

    constructor(
        storeID: string,
        forecastFlag: boolean,
        isOnline: boolean,
        weekStartDate: DateTime,
        totalSales: number,
        totalContribution: number
    ) {
        this.storeID = storeID;
        this.forecastFlag = forecastFlag;
        this.isOnline = isOnline;
        this.weekStartDate = weekStartDate;
        this.totalSales = totalSales;
        this.totalContribution = totalContribution;
    }
}

export const loadWeeklySales =  createAppAsyncThunk<
    WeeklySales[],
    {selectedStore: Store | undefined, comparatorStores: Store[] | undefined, referenceDate: DateTime}
    >(
    "customer/insights/portfolio/loadWeeklySales",
    async ({selectedStore, comparatorStores, referenceDate}, thunkAPI) => {
        try {
            if (!selectedStore || !comparatorStores) {
                return [];
            }
            const forecastStartDate = referenceDate.weekday === 7 ? referenceDate.plus({days: 1}) : referenceDate.customStartOfWeek();
            const nextYearEnd = forecastStartDate.plus({ weeks: 52 }).minus({days: 1}).endOf('day');
            const currentYearEnd = forecastStartDate.minus({days: 1}).endOf('day');
            const twoYearsPrior = forecastStartDate.minus({ weeks: 52*2 }).startOf('day');
            const threeYearsPrior = forecastStartDate.minus({ weeks: 52*3 }).startOf('day');
    
            const comparatorStoreIDs = comparatorStores.map(store => store.id);
    
            const query = {
                measures: [
                    "F_SalesForecast.SumLineValue",
                    "F_SalesForecast.SumContribution"
                ],
                dimensions: [
                    "D_Store.StoreNaturalID",
                    "D_Date.Date",
                    "F_SalesForecast.ForecastFlag",
                    "F_SalesForecast.OnlineFlag"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [threeYearsPrior, nextYearEnd]
                }],
                filters: [{
                    or: [{
                        and: [{
                            member: "D_Store.StoreNaturalID",
                            operator: "equals",
                            values: [selectedStore.id]
                        },{
                            member: "D_Date.Date",
                            operator: "inDateRange",
                            values: [threeYearsPrior, nextYearEnd]
                        }]
                    },{
                        and: [{
                            member: "D_Store.StoreNaturalID",
                            operator: "equals",
                            values: comparatorStoreIDs
                        },{
                            member: "D_Date.Date",
                            operator: "inDateRange",
                            values: [twoYearsPrior, currentYearEnd]
                        }]
                    }]
                }],
                limit: 50000
            };
    
            const resultSet = await thunkAPI.dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
            return resultSet.loadResponses[0].data.map(row =>
                new WeeklySales(
                    row["D_Store.StoreNaturalID"],
                    row["F_SalesForecast.ForecastFlag"],
                    Boolean(row["F_SalesForecast.OnlineFlag"]),
                    DateTime.fromISO(row["D_Date.Date"], { zone: "utc" }),
                    row["F_SalesForecast.SumLineValue"],
                    row["F_SalesForecast.SumContribution"]
                )
            );
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading weekly sales."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
