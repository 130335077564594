import React from "react";
import { Marker } from "react-map-gl";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import { selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { useAppSelector } from "store";

const PinnedLocation: React.FC = () => {
    const pinnedLocation = useAppSelector(selectPinnedLocation);

    if (!pinnedLocation) {
        return null;
    }

    return (
        <Marker
            latitude={pinnedLocation.latitude}
            longitude={pinnedLocation.longitude}
            offsetTop={-20}
            offsetLeft={-10}
        >
            <LocationOnOutlinedIcon />
        </Marker>
    );
};

export default PinnedLocation;
