import React from "react";

import { Box, Card, CardContent, Collapse, Divider, Grid, Icon, Typography, useTheme } from "@material-ui/core";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import RadioButtonCheckedRoundedIcon from "@material-ui/icons/RadioButtonCheckedRounded";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";
import OtherStoreIconSvg from "assets/images/other-store-icon.svg";
import SelectedStoreIconSvg from "assets/images/selected-store-icon.svg";

import { Store } from "modules/customer/insights/portfolioNew/store";
import { Competitor } from "modules/customer/insights/portfolioNew/competition/competitors";
import { CoreRetailHub } from "modules/customer/insights/portfolioNew/competition/coreRetailHub";

import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(28),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledDivider = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(Divider);

interface LegendProps {
    store?: Store
    competitor?: Competitor,
    coreRetailHub?: CoreRetailHub;
}

const Legend: React.FC<LegendProps> = (props) => {
    const { store, competitor, coreRetailHub } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const isDirectCompetitor = competitor?.directCompetitor;

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={competitor !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" component="div">
                                {competitor?.fascia}{isDirectCompetitor ? " (direct competitor)" : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div">
                                Distance from selected location
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(
                                        mathUtils.haversineDistance(
                                            store?.latitude ?? 0,
                                            store?.longitude ?? 0,
                                            competitor?.latitude ?? 0,
                                            competitor?.longitude ?? 0
                                        ) ?? 0, 1)
                                    }km`
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div">
                                Distance from core retail hub
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(
                                    mathUtils.haversineDistance(
                                        coreRetailHub?.latitude ?? 0,
                                        coreRetailHub?.longitude ?? 0,
                                        competitor?.latitude ?? 0,
                                        competitor?.longitude ?? 0
                                    ) ?? 0, 1)
                                    }km`
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" component="div" style={{ paddingTop: theme.spacing(0.75) }}>
                                Store size
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {`${numberFormatter.toDecimalPlaces(competitor?.size ?? 0, 1, true)}ft`}<sup>2</sup>
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Box display="flex">
                    <Icon fontSize="small">
                        <img src={SelectedStoreIconSvg} height={24} width={24} alt="portfolio-competition-nearby-competition-legend-selected-store-icon" />
                    </Icon>
                    <Typography variant="body1" component="div" style={{ paddingTop: theme.spacing(0.25), paddingLeft: theme.spacing(0.5) }} gutterBottom>
                        &nbsp;&nbsp;{store?.name} store
                    </Typography>
                </Box>
                <Box display="flex" style={{ paddingTop: theme.spacing(0.25) }}>
                    <Icon fontSize="small">
                        <img src={OtherStoreIconSvg} height={24} width={24} alt="portfolio-competition-nearby-competition-legend-other-store-icon" />
                    </Icon>
                    <Typography variant="body1" component="div" style={{ paddingTop: theme.spacing(0.25), paddingLeft: theme.spacing(0.5) }} gutterBottom>
                        &nbsp;&nbsp;Your other stores
                    </Typography>
                </Box>
                <Box display="flex">
                    <PlayArrowRoundedIcon fontSize="medium" style={{ color: colourPalette.categorical[2], transform: "rotate(90deg)" }} />
                    <Typography variant="body1" component="div" style={{ paddingTop: theme.spacing(0.25) }} gutterBottom>
                        &nbsp;&nbsp;Direct competitor
                    </Typography>
                </Box>
                <Box display="flex">
                    <FiberManualRecordRoundedIcon fontSize="small" style={{ color: colourPalette.categorical[0], paddingLeft: theme.spacing(0.5) }} />
                    <Typography variant="body1" component="div" style={{ paddingTop: theme.spacing(0.25), paddingLeft: theme.spacing(0.5) }} gutterBottom>
                        &nbsp;&nbsp;Other competitor
                    </Typography>
                </Box>
                <Box display="flex">
                    <RadioButtonCheckedRoundedIcon fontSize="small" style={{ color: theme.palette.common.white, paddingLeft: theme.spacing(0.5) }} />
                    <Typography variant="body1" component="div" style={{ paddingTop: theme.spacing(0.25), paddingLeft: theme.spacing(0.5) }}>
                        &nbsp;&nbsp;Core retail hub
                    </Typography>
                </Box>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
