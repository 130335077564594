import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import Waterfall from "components/visuals/Waterfall";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueGrowth";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const TypesOfSalesGrowth = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const typesOfSalesGrowth = useSelector(state => selectors.typesOfSalesGrowth(state));
    const { loading, error, data } = typesOfSalesGrowth;
    const categories = data.map(item => item.growthCategory)
                           .map(item => item.replace("PY sales", "Year prior revenue"))
                           .map(item => item.replace("CY sales", "Last year revenue"));

    //ToDo: Check whether we can update the segment description rather than replacing it on the front-end
    const categoryDescription = data.map(item => item.segmentDescription)
        .map(item => item.replace("Total sales value in the 12 months prior to the last 12 months", "Revenue in the 12 months prior to the last 12 months"))
        .map(item => item.replace("Change in sales from existing products in existing stores", "Change in revenue from existing products in existing stores"))
        .map(item => item.replace("Total sales value in the last 12 months", "Revenue in the last 12 months"));

    const series = data.map(item => item.growthValue);
    const growthOrDecline = (series[series.length - 1] > series[0]) ? 'growth' : 'decline';
    const options = {
        title: {
            text: `Breakdown of revenue ${growthOrDecline} between the last year and the year prior`
        },
        xAxis: {
            categories: categories
        },
        tooltip: {
            enabled: true,
            formatter: function () {
                const category = this.x;
                const categoryIndex = categories.indexOf(category);
                const tooltipHeader = category === "Year prior revenue" ? "Prior year revenue" : category;
                const description = categoryDescription[categoryIndex];
                const revenue = this.y;
                return `<table>
                            ${stringUtils.tooltipHTML([`${description}`, `Revenue <b style="color: ${this.color}">${numberFormatter.toGBP(revenue, 2)}</b>`], {
                                header: tooltipHeader
                            })}
                        </table>`;
            },
            useHTML: true
        },
        series: [{
            name: "Revenue",
            data: series.map((point, index) => {
                let dataOptions = { y: point };

                if (index === 0) {
                    dataOptions.color = theme.palette.info.main;
                } else if (index === series.length - 1) {
                    dataOptions.color = theme.palette.info.main;
                    dataOptions.isSum = true;
                } else if (point < 0) {
                    dataOptions.color = theme.palette.error.main;
                } else {
                    dataOptions.color = theme.palette.success.main;
                }

                return dataOptions;
            })
        }]
    };

    React.useEffect(() => {
        dispatch(operations.getTypesOfSalesGrowth());
    }, [dispatch]);

    return (
        <Box data-cy="types-of-sales-growth">
            <Waterfall loading={loading} error={error} options={options} />
        </Box>
    );
};

export default TypesOfSalesGrowth;
