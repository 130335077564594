import React from "react";

import { Grid, GridSize } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import FootfallLevel from "./FootfallLevel";
import YoYChangeInFootfall from "./YoYChangeInFootfall";
import FootfallLevelOverTimeChart from "./FootfallLevelOverTimeChart";

const FootfallLevelOverTime: React.FC = () => {
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const kpiGridSize: GridSize = isInsightExpanded ? 6 : 12;
    return (
        <Grid container spacing={2}>
            <Grid item xs={kpiGridSize}>
                <FootfallLevel />
            </Grid>
            <Grid item xs={kpiGridSize}>
                <YoYChangeInFootfall />
            </Grid>
            <Grid item xs={12}>
                <FootfallLevelOverTimeChart />
            </Grid>
        </Grid>
    );
};

export default FootfallLevelOverTime;
