import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class AverageDailyFootfall {
    public readonly weekdayIndex: number;
    public readonly timeRangeIndex: number;
    public readonly averageFootfall: number;

    constructor(
        weekdayIndex: number,
        timeRangeIndex: number,
        averageFootfall: number
    ) {
        this.weekdayIndex = weekdayIndex;
        this.timeRangeIndex = timeRangeIndex;
        this.averageFootfall = averageFootfall;
    }
}

export const loadAverageDailyFootfall = (selectedStore: Store | undefined): AppThunk<Promise<AverageDailyFootfall[]>> => async (dispatch) => {
    if (!selectedStore) {
        return [];
    }
    try {
        const retailCentreIdString = selectedStore.retailCentreID.toString();
        const query = {
            dimensions: [
                "F_DayTimeFootfall.Day",
                "F_DayTimeFootfall.TimeBucketIndex",
                "F_DayTimeFootfall.Prev12MonthsAvgFootfall",
            ],
            filters: [{
                member: "F_DayTimeFootfall.RetailCentreID",
                operator: "equals",
                values: [retailCentreIdString]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new AverageDailyFootfall(
            row["F_DayTimeFootfall.Day"] - 1,
            row["F_DayTimeFootfall.TimeBucketIndex"],
            row["F_DayTimeFootfall.Prev12MonthsAvgFootfall"]
        ));
    } catch (error) {
        dispatch(logError("Error loading AverageDailyFootfall.", error));
        throw error;
    }
};
