import { createSelector } from "reselect";

const budgetVsForecastContribution = (state) => {
    return state.customer.insights.performance.budget.budgetedVsForecastContribution.budgetVsForecastContribution;
};

const budgetedContVsForecastCont = createSelector(
    budgetVsForecastContribution,
    (budgetVsForecastContribution) => {
        const rag = {
            loading: false,
            error: false,
            id: "budgeted-contribution-vs-forecast-contribution",
            label: "Budgeted contribution vs forecast contribution",
            status: "info",
            value: ""
        };

        if (budgetVsForecastContribution.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (budgetVsForecastContribution.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const quarterlyContribution = budgetVsForecastContribution.quarterlyContribution;
        const quarterlyBudget = budgetVsForecastContribution.quarterlyBudget;

        if (quarterlyBudget.length === 0) {
            rag.status = "noData";
            rag.value = "No Data Available";
            return rag;
        }

        const contribution = quarterlyContribution.reduce((total, item) => item.contribution + total, 0);
        const budget = quarterlyBudget.reduce((total, item) => item.budget + total, 0);

        const x = ((budget - contribution) / contribution) * 100;

        if (x > 50) {
            rag.status = "error";
            rag.value = "Significantly over budgeting contribution relative to Dash's Forecast";
        } else if (x < -50) {
            rag.status = "error";
            rag.value = "Significantly under budgeting contribution relative to Dash's Forecast ";
        } else if (x <= -25) {
            rag.status = "warning";
            rag.value = "Slightly under budgeting contribution relative to Dash's Forecast";
        } else if (x >= 25) {
            rag.status = "warning";
            rag.value = "Slightly over budgeting contribution relative to Dash's Forecast";
        } else {
            rag.status = "success";
            rag.value = "Contribution budget is broadly in line with forecast";
        }
        return rag;
    }
);

const selectors = {
    budgetVsForecastContribution,
    budgetedContVsForecastCont
};

export default selectors;
