import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import Page from "components/admin/Page";

import AccountsTable from "./AccountsTable";
import AddAccount from "./AddAccount";
import FilterAccounts from "./FilterAccounts";

import { selectUserInfo } from "modules/auth/authSlice";
import { useAppSelector } from "store";

const Accounts: React.FC = () => {
    const userInfo = useAppSelector(selectUserInfo);

    return (
        <Page dataCy="pages-admin-accounts">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <br />
                    <Typography variant="subtitle1" component="div" color="textSecondary">
                        KPMG Admin - {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <Typography variant="h3" component="div">
                        Accounts
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <AddAccount />
                                <FilterAccounts />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <AccountsTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
};

export default Accounts;
