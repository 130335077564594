import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import KPIFact from "components/visuals/KPIFact";

import { operations, selectors } from "modules/customer/insights/performance/sales/revenueOverTime";

const TotalSalesLatestFullMonth = () => {
    const dispatch = useDispatch();
    const totalSalesLatestFullMonth = useSelector(state => selectors.totalSalesLatestFullMonth(state));
    const { loading, error, value, currentMonth, currentYear } = totalSalesLatestFullMonth;
    const label = `Revenue in ${currentMonth} ${currentYear}`;
    const valueFormatted = numberFormatter.toGBP(value, 1);

    React.useEffect(() => {
        dispatch(operations.getTotalSalesLatestFullMonth());
    }, [dispatch]);

    return (
        <Box width="100%" height="100%" data-cy="total-sales-latest-full-month">
            <KPIFact loading={loading} error={error} label={label} value={valueFormatted} />
        </Box>
    );
};

export default TotalSalesLatestFullMonth;
