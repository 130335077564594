const GET_PRODUCT_CATEGORY_MIX_REQUEST = "spark/customer/insights/portfolio/sales/productCategoryMix/GET_PRODUCT_CATEGORY_MIX_REQUEST";
const GET_PRODUCT_CATEGORY_MIX_SUCCESS = "spark/customer/insights/portfolio/sales/productCategoryMix/GET_PRODUCT_CATEGORY_MIX_SUCCESS";
const GET_PRODUCT_CATEGORY_MIX_FAILURE = "spark/customer/insights/portfolio/sales/productCategoryMix/GET_PRODUCT_CATEGORY_MIX_FAILURE";

const types = {
    GET_PRODUCT_CATEGORY_MIX_REQUEST,
    GET_PRODUCT_CATEGORY_MIX_SUCCESS,
    GET_PRODUCT_CATEGORY_MIX_FAILURE
};

export default types;
