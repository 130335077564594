import { combineReducers } from "redux";

import slice from "./locationSlice";

import areaHealth from "./areaHealth";
import cannibalisation from "./cannibalisation";
import competition from "./competition";
import demographics from "./demographics";
import filters from "./filters";
import footfall from "./footfall";
import information from "./information";
import overview from "./overview";
import savedLocations from "./savedLocations";
import spend from "./spend";
import spendNew from "./spendNew";

const reducer = combineReducers({
    root: slice.reducer,
    information,
    filters,
    savedLocations,
    areaHealth,
    demographics,
    footfall,
    overview,
    spend,
    spendNew,
    competition,
    cannibalisation
});

export default reducer;
