import React from "react";

import { Hidden } from "@material-ui/core";

import Drawer from "./Drawer";
import MobileNavigation from "./MobileNavigation";

const Navigation: React.FC = () => {
    return (
        <>
            <Hidden smDown>
                <Drawer />
            </Hidden>
            <Hidden mdUp>
                <MobileNavigation />
            </Hidden>
        </>
    );
};

export default Navigation;
