import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";
import { selectGoogleAnalyticsConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";

import { WebAnalyticsService } from "./webAnalyticsService";

interface WebAnalyticsState {
    webAnalyticsService?: WebAnalyticsService
}

const initialState: WebAnalyticsState = {
    webAnalyticsService: undefined
};

const webAnalyticsSlice = createSlice({
    name: "helpers/webAnalytics",
    initialState,
    reducers: {
        setWebAnalyticsService: (state, action: PayloadAction<WebAnalyticsService>) => {
            state.webAnalyticsService = action.payload;
        }
    }
});

export const setupWebAnalytics = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const webAnalyticsConfiguration = selectGoogleAnalyticsConfiguration(state);
    const webAnalyticsMeasurementId = webAnalyticsConfiguration.measurementId;
    const webAnalyticsService = new WebAnalyticsService(webAnalyticsMeasurementId);
    dispatch(webAnalyticsSlice.actions.setWebAnalyticsService(webAnalyticsService));
};

export const trackPageView = (pathname: string, search?: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const webAnalyticsService = selectWebAnalyticsService(state);
    webAnalyticsService?.trackPageView(pathname, search);
};

export const trackEvent = (category: string, action: string, label?: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const webAnalyticsService = selectWebAnalyticsService(state);
    webAnalyticsService?.trackEvent(category, action, label);
};

const selectWebAnalyticsService = (state: RootState): WebAnalyticsService | undefined => {
    return state.helpers.webAnalytics.webAnalyticsService;
};

export default webAnalyticsSlice;
