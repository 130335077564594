import { combineReducers } from "redux";

import types from "./types";

const categoryForecastSalesGrowthMarketEmpty = {
    loading: false,
    error: false,
    categories: [],
    companyGrowth: [],
    benchmarkGrowth: []
};

const categoryForecastSalesGrowthMarket = (state = categoryForecastSalesGrowthMarketEmpty, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                categories: [],
                companyGrowth: [],
                benchmarkGrowth: []
            };
        case types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                categories: action.payload.categories,
                companyGrowth: action.payload.companyGrowth,
                benchmarkGrowth: action.payload.benchmarkGrowth
            };
        case types.GET_CATEGORY_FORECAST_SALES_GROWTH_MARKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                categories: [],
                companyGrowth: [],
                benchmarkGrowth: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    categoryForecastSalesGrowthMarket
});

export default reducer;
