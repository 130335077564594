import React from "react";
import Highcharts from "highcharts";

import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Radar from "components/visuals/Radar";
import useColourPalette from "components/visuals/useColourPalette";

import HighchartsCustomTooltip from "components/visuals/HighchartsCustomTooltip";
import useTooltipHandler from "components/visuals/useTooltipHandler";

import numberFormatter from "utils/numberFormatter";

interface RadarChartProps {
    revenue: number,
    revenueScore: number | undefined,
    profit: number,
    profitScore: number | undefined,
    changeInNumberOfStores: number,
    areaHealthScore: number | undefined,
    catchmentSize: number,
    catchmentScore: number | undefined,
    numberOfCompetitors: number,
    competitionScore: number | undefined,
    footfallLevel: number,
    footfallScore: number | undefined,
    totalScore: number | undefined
}

const tooltipText = (pointName: string): string => {
    const text = (() => {
        switch (pointName) {
            case "Area health":
                return "Change in number of stores";
            case "Catchment":
                return "Catchment size (population)";
            case "Competition":
                return "Number of competitors within 5km";
            case "Footfall":
                return "Footfall level";
            case "Profit":
                return "Gross profit margin";
            case "Revenue":
                return "Revenue in the last year";
            default:
                return pointName;
        }
    })();

    return text;
};

const tooltipMetricValue = (pointName: string, revenue: number, profit: number,
    changeInNumberOfStores: number, catchmentSize: number, numberOfCompetitors: number, footfallLevel: number): string => {
    const metricValue = (() => {
        switch (pointName) {
            case "Area health":
                return numberFormatter.toPercentage(changeInNumberOfStores, false, 1);
            case "Catchment":
                return numberFormatter.toDecimalPlaces(catchmentSize, 1);
            case "Competition":
                return numberOfCompetitors.toString();
            case "Footfall":
                return numberFormatter.toDecimalPlaces(footfallLevel, 1);
            case "Profit":
                return numberFormatter.toPercentage(profit, false, 0);
            case "Revenue":
                return numberFormatter.toGBP(revenue, 1);
            default:
                return pointName;
        }
    })();

    return metricValue;
};

const RadarChart: React.FC<RadarChartProps> = (props) => {
    const {
        revenue,
        revenueScore,
        profit, 
        profitScore,
        changeInNumberOfStores,
        areaHealthScore,
        catchmentSize,
        catchmentScore,
        numberOfCompetitors,
        competitionScore,
        footfallLevel,
        footfallScore,
        totalScore
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const colourGroup = Math.floor(colourPalette.heatmap.length * (totalScore ?? 0) / 30);
    const colourIndex = Math.min(colourGroup, colourPalette.heatmap.length - 1);
    const colour = colourPalette.heatmap[colourIndex];

    const { tooltipData, handleTooltip, handleMouseOut } = useTooltipHandler();
    const contentRenderer = (point: Highcharts.Point): React.ReactNode => (
        <table>
            <tbody>
            <tr>
                <td colSpan={2}>
                    <Typography variant="subtitle2" style={{ color: colour }}>Relative performance</Typography>
                </td>
            </tr>
            <tr>
                <td style={{ width: "100px" }}><Typography variant="body2">{tooltipText(point.name)}</Typography></td>
                <td>
                        <Typography variant="body1" align="right">
                            {tooltipMetricValue(point.name, revenue, profit, changeInNumberOfStores, catchmentSize,
                                numberOfCompetitors, footfallLevel)}
                    </Typography>
                </td>
            </tr>
            <tr>
                <td style={{ width: "100px" }}><Typography variant="body2">Score</Typography></td>
            <td>
                <Typography variant="body1" align="right">
                    {numberFormatter.toDecimalPlaces(point.options?.custom?.tooltipValue, 1)}
                </Typography>
            </td>
            </tr>
            
            </tbody>
        </table>
    );
    const content = tooltipData && contentRenderer(tooltipData.point);

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            height: theme.spacing(30),
            backgroundColor: 'transparent'
        },
        // @ts-ignore
        xAxis: {
            type: "category",
            tickmarkPlacement: "on",
            lineWidth: 0,
            labels: {
                useHTML: true,
                style: {
                    width: "70px",
                    whiteSpace: "normal"
                },
                formatter: function () {
                    let returnStr = "";
                    const label = String(this.value);
                    if (label.length < 9) {
                        returnStr = label;
                    } else {
                        returnStr = "<div style=\"text-align: center\">" + label?.replace(/(.{5})/, "$1<br>") + "</div>";
                    }
                    return returnStr;
                }
            }
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            max: 5
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        mouseOver: handleTooltip,
                        mouseOut: handleMouseOut
                    }
                }
            }
        },
        tooltip: {
            enabled: false
        },
        series: [{
            name: "Score",
            type: "area",
            color: colour,
            data: [{
                name: "Revenue",
                y: revenueScore
            }, {
                name: "Profit",
                y: profitScore
            }, {
                name: "Catchment",
                y: catchmentScore
            }, {
                name: "Competition",
                y: competitionScore
            }, {
                name: "Area health",
                y: areaHealthScore
            }, {
                name: "Footfall",
                y: footfallScore
            }],
            pointPlacement: "on"
        }],
        exporting: {
            enabled: false
        }
    }), [theme, revenueScore, profitScore, areaHealthScore, catchmentScore, competitionScore, footfallScore, colour, handleTooltip, handleMouseOut]);

    return (
        <>
            {tooltipData &&
                <HighchartsCustomTooltip point={tooltipData.point} content={content} event={tooltipData.event} />}
            <Radar loading={false} error={false} options={options} dataAdditionPercent={10} />
        </>
    );
};

export default RadarChart;
