import { ResultSet } from "@cubejs-client/core";
import { immerable } from "immer";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadStoreGroups = (): AppThunk<Promise<StoreGroup[]>> => async (dispatch) => {
    try {
        const query = {
            filters: [{
                "member": "D_Store.Group",
                "operator": "set",
            }],
            dimensions: ["D_Store.Group"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        return resultSet.rawData().map(row => new StoreGroup(
            row["D_Store.Group"]
        ));
    } catch (error) {
        dispatch(logError("Error loading StoreGroups.", error));
        throw error;
    }
};

export class StoreGroup {
    public readonly name: string;

    constructor(
        name: string
    ) {
        this.name = name;
    }
}


export class StoreGroupMultiSelect extends StoreGroup {
    [immerable] = true;

    private _isSelected: boolean;

    constructor(
        storeGroup: StoreGroup,
        _isSelected: boolean
    ) {
        super(
            storeGroup.name
        );
        this._isSelected = _isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}
