import React from "react";

import { Button } from "@material-ui/core";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";

import Content from "./Content";
import Title from "./Title";

import { selectTarget } from "modules/customer/tools/location/locationSlice";
import {
    clearSavedLocationForm,
    getSavedLocations,
    hideAddSavedLocation,
    hideDeleteSavedLocation,
    hideEditSavedLocation,
    hideSavedLocations,
    selectSavedLocationsVisibility,
    showSavedLocations
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const SavedLocations: React.FC = () => {
    const dispatch = useAppDispatch();
    const savedLocationsVisibility = useAppSelector(selectSavedLocationsVisibility);
    const isVisible = savedLocationsVisibility.isVisible;
    const target = useAppSelector(selectTarget);


    const handleClick = () => {
        dispatch(hideAddSavedLocation());
        dispatch(hideEditSavedLocation());
        dispatch(hideDeleteSavedLocation());
        dispatch(clearSavedLocationForm());

        dispatch(showSavedLocations());
    };

    const handleClose = () => {
        dispatch(hideAddSavedLocation());
        dispatch(hideEditSavedLocation());
        dispatch(hideDeleteSavedLocation());
        dispatch(clearSavedLocationForm());

        dispatch(hideSavedLocations());
    };

    React.useEffect(() => {
        if (isVisible) {
            dispatch(getSavedLocations());
        }
    }, [dispatch, isVisible]);

    return (
        <>
            <StyledButton
                variant="outlined"
                size="small"
                color="default"
                disableElevation
                onClick={handleClick}
                disabled={!target}
                data-cy="btn-saved-locations"
            >
                <BookmarkRoundedIcon />
            </StyledButton>
            <SimpleDialog
                open={isVisible}
                onClose={handleClose}
                maxWidth="lg"
                fullHeight={true}
                title={<Title />}
                content={<Content />}
            />
        </>
    );
};

export default SavedLocations;
