import React from "react";
import Highcharts from "highcharts";

import { Box } from "@material-ui/core";

import Bar from "components/visuals/Bar";

import { selectUserInfo } from "modules/auth/authSlice";
import { operations, selectors } from "modules/customer/insights/performance/forecast/forecastGrowthVsMarket";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const CategoryForecastSalesGrowthMarket = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const companyName = userInfo.companyDisplayName;
    const categoryForecastSalesGrowthMarket = useAppSelector(state => selectors.categoryForecastSalesGrowthMarket(state));
    const { loading, error, categories, companyGrowth, benchmarkGrowth } = categoryForecastSalesGrowthMarket;

    const options: Highcharts.Options = {
        title: {
            text: `Forecast year-on-year revenue growth (%) for ${companyName} vs the market`
        },
        xAxis: {
            categories: categories.map((item: any) => {
                return (item !== null) ? item : "Unknown";
            }),
            labels: {
                enabled: true,
                style: { fontSize: "12px", fontWeight: "normal" },
            }
        },
        yAxis: {
            gridLineWidth: 0,
            labels: {
                enabled: false
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    style: { fontSize: "14px", fontWeight: "normal" },
                    formatter() {
                        return numberFormatter.toPercentage(this.y);
                    }
                }
            }
        },
        tooltip: {
            formatter() {
                const valuesArr = [numberFormatter.toPercentage(this.y)];
                return `<table>${stringUtils.tooltipHTML(["Forecast year-on-year growth (%): "], {
                    values: valuesArr,
                    header: `${this.series.name} - ${this.x}`,
                    headerFormatting: `color:${this.series.color}`
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            name: companyName,
            data: companyGrowth.map((item: any) => {
                return (item !== undefined) ? item : null;
            }),
            type: "bar"
        }, {
            name: "Market",
            data: benchmarkGrowth.map((item: any) => {
                return (item !== undefined) ? item : null;
            }),
            type: "bar"
        }]
    };

    React.useEffect(() => {
        dispatch(operations.getCategoryForecastSalesGrowthMarket());
    }, [dispatch]);

    return (
        <Box data-cy="category-forecast-sales-growth-market">
            <Bar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CategoryForecastSalesGrowthMarket;
