import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { abs, median } from "mathjs";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectClientRegistration,
    selectComparator,
    selectStore
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { AverageDailyFootfall, loadAverageDailyFootfall } from "./averageDailyFootfall";
import { FootfallPerOutputArea, loadFootfallPerOutputArea } from "./footfallPerOutputArea";
import { RetailCentreFootfall, loadRetailCentreFootfall } from "./retailCentreFootfall";

interface LoadFootfallResponse {
    retailCentreFootfall: RetailCentreFootfall[],
    footfallPerOutputArea: FootfallPerOutputArea[],
    averageDailyFootfall: AverageDailyFootfall[],
}

interface FootfallState {
    isLoading: boolean,
    hasErrors: boolean,
    retailCentreFootfall: RetailCentreFootfall[],
    footfallPerOutputArea: FootfallPerOutputArea[],
    averageDailyFootfall: AverageDailyFootfall[]
}

const initialState: FootfallState = {
    isLoading: false,
    hasErrors: false,
    retailCentreFootfall: [],
    footfallPerOutputArea: [],
    averageDailyFootfall: []
};

const footfallSlice = createSlice({
    name: "customer/insights/portfolioNew/footfall",
    initialState,
    reducers: {
        clearRetailCentreFootfall: (state) => {
            state.retailCentreFootfall = [];
        },
        clearAverageDailyFootfall: (state) => {
            state.averageDailyFootfall = [];
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadFootfall.pending, (state: FootfallState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadFootfall.rejected, (state: FootfallState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.retailCentreFootfall = initialState.retailCentreFootfall;
            state.footfallPerOutputArea = initialState.footfallPerOutputArea;
            state.averageDailyFootfall = initialState.averageDailyFootfall;
        });
        builder.addCase(loadFootfall.fulfilled, (state: FootfallState, action: PayloadAction<LoadFootfallResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.retailCentreFootfall = action.payload.retailCentreFootfall;
            state.footfallPerOutputArea = action.payload.footfallPerOutputArea;
            state.averageDailyFootfall = action.payload.averageDailyFootfall;
        });
    }
});

export const loadFootfall = createAppAsyncThunk(
    "customer/insights/portfolioNew/footfall/loadFootfall",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const selectedStore = selectStore(state);
            const comparator = selectComparator(state);
            const catchmentAccountId = selectClientRegistration(state)?.accountId ?? "";

            const comparatorStores = comparator?.getStores() ?? [];
            const selectedAndComparatorStores = selectedStore ? [selectedStore].concat(comparatorStores) : [];

            const retailCentreFootfallPromise = thunkAPI.dispatch(loadRetailCentreFootfall(selectedAndComparatorStores));
            const footfallPerOutputAreaPromise = thunkAPI.dispatch(loadFootfallPerOutputArea(selectedStore, catchmentAccountId));
            const averageDailyFootfallPromise = thunkAPI.dispatch(loadAverageDailyFootfall(selectedStore));
            const result = await Promise.all([retailCentreFootfallPromise, footfallPerOutputAreaPromise, averageDailyFootfallPromise]);
            const retailCentreFootfall = result[0];
            const footfallPerOutputArea = result[1];
            const averageDailyFootfall = result[2];
            const loadFootfallResponse: LoadFootfallResponse = {
                retailCentreFootfall,
                footfallPerOutputArea,
                averageDailyFootfall
            };
            return loadFootfallResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Footfall.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearFootfall = (): AppThunk => (dispatch) => {
    dispatch(footfallSlice.actions.clearRetailCentreFootfall());
    dispatch(footfallSlice.actions.clearAverageDailyFootfall());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.portfolioNew.footfall.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.portfolioNew.footfall.hasErrors;
};

export const selectRetailCentreFootfall = (state: RootState) => {
    return state.customer.insights.portfolioNew.footfall.retailCentreFootfall;
};

export const selectFootfallPerOutputArea = (state: RootState) => {
    return state.customer.insights.portfolioNew.footfall.footfallPerOutputArea;
};

export const selectAverageDailyFootfall = (state: RootState) => {
    return state.customer.insights.portfolioNew.footfall.averageDailyFootfall;
};

export const selectFootfallLevel = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectRetailCentreFootfall,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, retailCentreFootfall, selectedStore, comparator) => {
        const footfallLevel: DataWrapper<{ selectedStore: number, comparator: number }> = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };
        if (retailCentreFootfall.length === 0 || !selectedStore || !comparator) {
            return footfallLevel;
        }

        const selectedStoreFootfall = retailCentreFootfall.find(item => item.storeID === selectedStore.id);
        footfallLevel.data.selectedStore = selectedStoreFootfall?.currentYearFootfall ?? 0;

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);
        const comparatorFootfallValues = retailCentreFootfall
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .map(item => item.currentYearFootfall);

        footfallLevel.data.comparator = comparatorFootfallValues.length !== 0 ? median(comparatorFootfallValues) : 0;

        return footfallLevel;
    }
);

export const selectYoYFootfall = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectRetailCentreFootfall,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, retailCentreFootfall, selectedStore, comparator) => {
        const yearOnYearFootfall: DataWrapper<{ selectedStore: number, comparator: number }> = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };
        if (retailCentreFootfall.length === 0 || !selectedStore || !comparator) {
            return yearOnYearFootfall;
        }

        const selectedStoreFootfall = retailCentreFootfall.find(item => item.storeID === selectedStore.id);
        yearOnYearFootfall.data.selectedStore =
            mathUtils.safePercentageChange(selectedStoreFootfall?.currentYearFootfall ?? 0, selectedStoreFootfall?.previousYearFootfall ?? 0);

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);
        const comparatorFootfallValues = retailCentreFootfall
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .map(item => mathUtils.safePercentageChange(item.currentYearFootfall, item.previousYearFootfall));

        yearOnYearFootfall.data.comparator = comparatorFootfallValues.length !== 0 ? median(comparatorFootfallValues) : 0;

        return yearOnYearFootfall;
    }
);

export const selectFootfallVsComparator = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectFootfallLevel,
    selectYoYFootfall,
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (isLoading, hasErrors, footfallLevel, yoyFootfall, selectedStore, comparator) => {
        const id = "footfall-vs-comparator";
        const label = "Footfall vs comparator";
        let value = "";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors || !selectedStore || !comparator) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const selectedStoreFootfall = footfallLevel.data.selectedStore;
        const comparatorMedianFootfall = footfallLevel.data.comparator;

        const selectedStoreYoyChange = yoyFootfall.data.selectedStore;
        const comparatorMedianYoyChange = yoyFootfall.data.comparator;

        const X = mathUtils.safePercentageChange(selectedStoreFootfall, comparatorMedianFootfall);

        const Y = (comparatorMedianYoyChange !== 0)
            ? ((selectedStoreYoyChange - comparatorMedianYoyChange) / abs(comparatorMedianYoyChange)) * 100
            : (selectedStoreYoyChange === 0 ? 0 : 100);

        const storeName = selectedStore.name;
        const comparatorName = comparator.name;

        const inRange = function (value: number, lowerLimit: number, upperLimit: number) {
            return value >= lowerLimit && value <= upperLimit;
        };

        if ((X > 25) && (Y > 50)) {
            status = RagIndicatorStatus.Green;
            value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are markedly ahead of ${comparatorName}`;
        } else if ((X > 25) && inRange(Y, -50, 50)) {
            status = RagIndicatorStatus.Green;
            value = `The level of footfall for your ${storeName} store is markedly ahead of ${comparatorName}, and the change in year-on-year footfall is broadly in line with ${comparatorName}`;
        } else if ((X > 25) && (Y < -50)) {
            status = RagIndicatorStatus.Amber;
            value = `The level of footfall for your ${storeName} store is markedly ahead of ${comparatorName}, but its change in year-on-year footfall is markedly behind`;
        } else if (inRange(X, -25, 25) && (Y > 50)) {
            status = RagIndicatorStatus.Green;
            value = `The level of footfall for your ${storeName} store is broadly in line with ${comparatorName}, and the change in year-on-year footfall is markedly ahead of ${comparatorName}`;
        } else if (inRange(X, -25, 25) && inRange(Y, -50, 50)) {
            status = RagIndicatorStatus.Amber;
            value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are broadly in line with ${comparatorName}`;
        } else if (inRange(X, -25, 25) && (Y < -50)) {
            status = RagIndicatorStatus.Red;
            value = `The level of footfall for your ${storeName} store is broadly in line with ${comparatorName}, and the change in year-on-year footfall is markedly behind ${comparatorName}`;
        } else if ((X < -25) && (Y > 50)) {
            status = RagIndicatorStatus.Amber;
            value = `The level of footfall for your ${storeName} store is markedly behind ${comparatorName}, but its change in year-on-year footfall is markedly ahead`;
        } else if ((X < -25) && inRange(Y, -50, 50)) {
            status = RagIndicatorStatus.Red;
            value = `The level of footfall for your ${storeName} store is markedly behind ${comparatorName}, and the change in year-on-year footfall is broadly in line with ${comparatorName}`;
        } else if ((X < -25) && (Y < -50)) {
            status = RagIndicatorStatus.Red;
            value = `Both the level of footfall and the change in year-on-year footfall associated with your ${storeName} store are markedly behind ${comparatorName}`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectFootfallPositioning = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectFootfallPerOutputArea,
    (state: RootState) => selectStore(state),
    (isLoading, hasErrors, footfallPerOutputArea, selectedStore) => {
        const id = "footfall-positioning";
        const label = "Footfall positioning";
        let value = "";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors || !selectedStore || footfallPerOutputArea.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }
        const selectedOAData = footfallPerOutputArea.find(item => item.outputAreaCode === selectedStore.outputAreaCode);
        const selectedOAValue = selectedOAData?.currentYearFootfall ?? 0;

        const footfallValues = footfallPerOutputArea.map(item => item.currentYearFootfall);
        const orderedValues = footfallValues.sort((a, b) => a - b);
        const selectedOARank = orderedValues.indexOf(selectedOAValue) + 1;

        const lowerThird = (1 * (footfallPerOutputArea.length)) / 3;
        const upperThird = (2 * (footfallPerOutputArea.length)) / 3;

        const storeName = selectedStore.name;

        if (selectedOARank > upperThird) {
            status = RagIndicatorStatus.Green;
            value = `Your ${storeName} store's output area has high footfall relative to the rest of its catchment area`;
        } else if (selectedOARank < lowerThird) {
            status = RagIndicatorStatus.Red;
            value = `Your ${storeName} store's output area has low footfall relatively to the rest of its catchment area`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `Your ${storeName} store's output area has average footfall relative to the rest of its catchment area`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export default footfallSlice;
