const GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_REQUEST = "spark/customer/insights/portfolio/profit/rankedGrossProfitMargin/GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_REQUEST";
const GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_SUCCESS = "spark/customer/insights/portfolio/profit/rankedGrossProfitMargin/GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_SUCCESS";
const GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_FAILURE = "spark/customer/insights/portfolio/profit/rankedGrossProfitMargin/GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_FAILURE";

const types = {
    GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_REQUEST,
    GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_SUCCESS,
    GET_STORE_VS_COMPARATOR_RANKED_GROSS_PROFIT_FAILURE
};

export default types;
