import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppDispatch, RootState } from "store";

export type AppThunk<TReturnType = void> = ThunkAction<TReturnType, RootState, undefined, AnyAction>

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
}>();
