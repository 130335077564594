import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/location/store";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { CatchmentAccountIds } from "modules/customer/tools/location/locationSlice";

export class RetailCentreSpendCentiles {
    public readonly retailCentreId: number;
    public readonly storeCategoryId: number;
    public readonly isScenario: boolean;
    public readonly spendCategoryId: number;
    public readonly spend: number;

    constructor(
        retailCentreId: number,
        storeCategoryId: number,
        isScenario: boolean,
        spendCategoryId: number,
        spend: number
    ) {
        this.retailCentreId = retailCentreId;
        this.storeCategoryId = storeCategoryId;
        this.isScenario = isScenario;
        this.spendCategoryId = spendCategoryId;
        this.spend = spend;
    }
}

export const loadRetailCentresSpendCentiles = (targetStoreCategoryId: number | undefined, comparatorStores: Store[], catchmentAccountIds: CatchmentAccountIds): AppThunk<Promise<RetailCentreSpendCentiles[]>> => async (dispatch) => {
    if (!targetStoreCategoryId || comparatorStores.length === 0) {
        return [];
    }

    try {
        const loadCentiles = (comparatorStores: Store[], isScenario: boolean): AppThunk<Promise<RetailCentreSpendCentiles[]>> => async (dispatch) => {
            const retailCentreIds = comparatorStores.map(store => store.retailCentre.id);
            const catchmentAccountId = isScenario ? catchmentAccountIds.scenario : catchmentAccountIds.baseline;
            const query = {
                dimensions: [
                    "LocationBenchmarkSpendMetrics.RetailCentreID",
                    "LocationBenchmarkSpendMetrics.StoreCategory_ID",
                    "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                    "LocationBenchmarkSpendMetrics.SpendCentile"
                ],
                filters: [{
                    member: "LocationBenchmarkSpendMetrics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(targetStoreCategoryId)]
                }, {
                    member: "LocationBenchmarkSpendMetrics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIds.map(String)
                }, {
                    member: "LocationBenchmarkSpendMetrics.IsScenario",
                    operator: "equals",
                    values: [String(Number(isScenario))]
                }, {
                    member: "LocationBenchmarkSpendMetrics.Client_ID",
                    operator: "equals",
                    values: [catchmentAccountId]
                }]
            };
            const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
            const rawData = resultSet.loadResponses[0].data;
            return rawData.map(row => new RetailCentreSpendCentiles(
                Number(row["LocationBenchmarkSpendMetrics.RetailCentreID"]),
                targetStoreCategoryId,
                isScenario,
                Number(row["LocationBenchmarkSpendMetrics.SpendCategory_ID"]),
                Number(row["LocationBenchmarkSpendMetrics.SpendCentile"])
            ));
        };

        const baselineCentiles = await dispatch(loadCentiles(comparatorStores, false));

        const scenarioComparatorStores = [];
        for (const comparatorStore of comparatorStores) {
            const comparatorStoreCentiles = baselineCentiles.find(centiles => centiles.retailCentreId === comparatorStore.retailCentre.id);
            if (!comparatorStoreCentiles) {
                scenarioComparatorStores.push(comparatorStore);
            }
        }

        const scenarioCentiles = scenarioComparatorStores.length !== 0
            ? await dispatch(loadCentiles(scenarioComparatorStores, true))
            : [];

        return baselineCentiles.concat(scenarioCentiles);
    } catch (error) {
        dispatch(logError("Error loading RetailCentresSpendCentiles.", error));
        throw error;
    }
};
