import { createSelector } from "reselect";

const typesOfSalesGrowth = (state) => {
    return state.customer.insights.performance.sales.revenueGrowth.typesOfSalesGrowth;
};

const organicVsInorganicSalesGrowth = createSelector(
    typesOfSalesGrowth,
    (typesOfSalesGrowth) => {
        const rag = {
            loading: false,
            error: false,
            id: "organic-vs-inorganic-sales-growth",
            label: "Organic vs inorganic sales growth",
            status: "info",
            value: ""
        };

        if (typesOfSalesGrowth.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (typesOfSalesGrowth.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const data = typesOfSalesGrowth.data;
        const organicGrowth = data.filter(g => g.growthCategory === "Like-for-like");
        const inorganicGrowth = data.filter(g => g.growthCategory !== "Like-for-like" && g.growthCategory !== "PY sales" && g.growthCategory !== "CY sales");
        const starting = data.filter(g => g.growthCategory === "PY sales");
        const ending = data.filter(g => g.growthCategory === "CY sales");

        const organic = organicGrowth.reduce((total, item) => item.growthValue + total, 0);
        const inorganic = inorganicGrowth.reduce((total, item) => item.growthValue + total, 0);
        const startingSales = starting.reduce((total, item) => item.growthValue + total, 0);
        const endingSales = ending.reduce((total, item) => item.growthValue + total, 0);

        const change = endingSales - startingSales; //How much increase/decrease in sales there was
        const organicPerc = (change === 0) ? 0 : 100 * organic / Math.abs(change); //How much of the increase/decrease was organic (in percentage)
        const inorganicPerc = (change === 0) ? 0 : 100 * inorganic / Math.abs(change); //How much of the increase/decrease was inorganic (in percentage)

        const overallGrowth = (startingSales === 0) ? 0 : 100 * ((endingSales - startingSales) / startingSales); //Growth between start point and end point (percentage)

        const x = (inorganicPerc === 0) ? 100 : ((organicPerc - inorganicPerc) / Math.abs(inorganicPerc)) * 100; //Comparing organic and inorganic growth

        if (overallGrowth < 0) {
            rag.status = "error";
            rag.value = "Company sales are in decline";
        } else if (x < -50) {
            rag.status = "warning";
            rag.value = "Company sales are growing, but the type of growth is unsustainable.";
        } else if (x <= 50) {
            rag.status = "success";
            rag.value = "Company Sales are growing, and organic and inorganic types of growth are broadly in line";
        } else {
            rag.status = "success";
            rag.value = "Company Sales are growing, and the type of growth is sustainable";
        }
        return rag;
    }
);

const selectors = {
    typesOfSalesGrowth,
    organicVsInorganicSalesGrowth
};

export default selectors;
