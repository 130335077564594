import types from "./types";

const getTypesOfSalesGrowthRequest = () => ({
    type: types.GET_TYPES_OF_SALES_GROWTH_REQUEST
});

const getTypesOfSalesGrowthSuccess = (data) => ({
    type: types.GET_TYPES_OF_SALES_GROWTH_SUCCESS,
    payload: {
        data
    }
});

const getTypesOfSalesGrowthFailure = () => ({
    type: types.GET_TYPES_OF_SALES_GROWTH_FAILURE
});

const actions = {
    getTypesOfSalesGrowthRequest,
    getTypesOfSalesGrowthSuccess,
    getTypesOfSalesGrowthFailure
};

export default actions;
