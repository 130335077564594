import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import TotalSalesGrowthByRegionForecast from "./TotalSalesGrowthByRegionForecast";
import SalesByRegionGrowthForecastBottom from "./SalesByRegionGrowthForecastBottom";
import SalesByRegionGrowthForecastTop from "./SalesByRegionGrowthForecastTop";
import ForecastDependencyOnSingularRegionOrStore from "./ForecastDependencyOnSingularRegionOrStore";

import { operations } from "modules/customer/insights/performance/forecast/regionalBreakdown";

const RegionalBreakdown = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(operations.getSalesByRegionGrowthForecast());
    }, [dispatch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TotalSalesGrowthByRegionForecast />
            </Grid>
            <Grid item xs={12} md={6}>
                <SalesByRegionGrowthForecastTop />
            </Grid>
            <Grid item xs={12} md={6}>
                <SalesByRegionGrowthForecastBottom />
            </Grid>
            <Grid item xs={12}>
                <ForecastDependencyOnSingularRegionOrStore />
            </Grid>
        </Grid>
    );
};

export default RegionalBreakdown;
