import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StackedArea from './StackedAreaPOC';
import Network from './NetworkPOC';
import Bubble from './BubblePOC';
import BoxPlot from './BoxplotPOC';
import DualAxisStock from './DualAxisStockLinePOC';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4),
    }
}));


const Range = () => {
    const classes = useStyles();
    return (
        <Paper elevation={0} className={classes.paper}>
            <br/>
            <StackedArea/>
            <Network />
            <Bubble />
            <BoxPlot />
            <DualAxisStock />
        </Paper>
    );
};

export default Range;
