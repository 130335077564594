import { createSelector } from "reselect";

const typesOfContributionGrowth = (state) => {
    return state.customer.insights.performance.sales.contributionGrowth.typesOfContributionGrowth;
};

const organicVsInorganicContributionGrowth = createSelector(
    typesOfContributionGrowth,
    (typesOfContributionGrowth) => {
        const rag = {
            loading: false,
            error: false,
            id: "organic-vs-inorganic-contribution-growth",
            label: "Organic vs inorganic contribution growth",
            status: "info",
            value: ""
        };

        if (typesOfContributionGrowth.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (typesOfContributionGrowth.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const data = typesOfContributionGrowth.data;
        const organicGrowth = data.filter(g => g.growthCategory === "Like-for-like");
        const inorganicGrowth = data.filter(g => g.growthCategory !== "Like-for-like" && g.growthCategory !== "PY cont." && g.growthCategory !== "CY cont.");
        const starting = data.filter(g => g.growthCategory === "PY cont.");
        const ending = data.filter(g => g.growthCategory === "CY cont.");

        const organic = organicGrowth.reduce((total, item) => item.growthValue + total, 0);
        const inorganic = inorganicGrowth.reduce((total, item) => item.growthValue + total, 0);
        const startingContribution = starting.reduce((total, item) => item.growthValue + total, 0);
        const endingContribution = ending.reduce((total, item) => item.growthValue + total, 0);

        const change = endingContribution - startingContribution; //How much increase/decrease in contribution there was
        const organicPerc = (change === 0) ? 0 : 100 * organic / Math.abs(change); //How much of the increase/decrease was organic (in percentage)
        const inorganicPerc = (change === 0) ? 0 : 100 * inorganic / Math.abs(change); //How much of the increase/decrease was inorganic (in percentage)

        const overallGrowth = (startingContribution === 0) ? 0 : 100 * ((endingContribution - startingContribution) / startingContribution); //Growth between start point and end point (percentage)

        const x = (inorganicPerc === 0) ? 100 : ((organicPerc - inorganicPerc) / Math.abs(inorganicPerc)) * 100; //Comparing organic and inorganic growth

        if (overallGrowth < 0) {
            rag.status = "error";
            rag.value = "Company contribution is in decline";
        } else if (x < -50) {
            rag.status = "warning";
            rag.value = "Company contribution is growing, but the type of growth is unsustainable.";
        } else if (x <= 50) {
            rag.status = "success";
            rag.value = "Company contribution is growing, and organic and inorganic types of growth are broadly in line";
        } else {
            rag.status = "success";
            rag.value = "Company contribution is growing, and the type of growth is sustainable";
        }
        return rag;
    }
);

const selectors = {
    typesOfContributionGrowth,
    organicVsInorganicContributionGrowth
};

export default selectors;
