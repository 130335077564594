import React from "react";

import LensIcon from "@material-ui/icons/Lens";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

interface StepIconProps {
    active: boolean,
    completed: boolean
}

const StepIcon: React.FC<StepIconProps> = (props) => {
    const { active, completed } = props;

    if (active) {
        return (<LensIcon />);
    }
    if (completed) {
        return (<CheckCircleIcon />);
    }
    return (<RadioButtonUncheckedIcon />);
};

export default StepIcon;
