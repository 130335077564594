import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Store selection
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Compare your store's costs to similar stores in the cluster to identify opportunities for optimisation and understand where it stands in relation to the average.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
