import { DateTime } from "luxon";

export enum SortDirection {
    ASC = "asc",
    DESC = "desc"
}

export const stringSortExpression = (itemA: string, itemB: string, sortDirection: SortDirection) => {
    return sortDirection === SortDirection.ASC
        ? itemA.toLowerCase().localeCompare(itemB.toLowerCase())
        : itemB.toLowerCase().localeCompare(itemA.toLowerCase()); //SortDirection.DESC
};

export const numberSortExpression = (itemA: number, itemB: number, sortDirection: SortDirection) => {
    return sortDirection === SortDirection.ASC ? itemA - itemB : itemB - itemA;
};

export const dateSortExpression = (itemA: Date, itemB: Date, sortDirection: SortDirection) => {
    return sortDirection === SortDirection.ASC ? itemA.getTime() - itemB.getTime() : itemB.getTime() - itemA.getTime();
};

export const dateTimeSortExpression = (itemA: DateTime, itemB: DateTime, sortDirection: SortDirection) => {
    return sortDirection === SortDirection.ASC ? itemA.toMillis() - itemB.toMillis() : itemB.toMillis() - itemA.toMillis();
};
