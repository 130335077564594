import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import DeleteDataRefresh from "./DeleteDataRefresh";

import { selectDataRefreshes } from "modules/admin/accounts/dataRefreshes/dataRefreshesSlice";
import { useAppSelector } from "store";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

interface DataRefreshesTableProps {
    accountId: string
}

const DataRefreshesTable: React.FC<DataRefreshesTableProps> = (props) => {
    const { accountId } = props;
    const dataRefreshes = useAppSelector(selectDataRefreshes);

    if (dataRefreshes.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Created At
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Status
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRefreshes.map((dataRefresh) => (
                        <TableRow key={dataRefresh.id}>
                            <StyledTableCell>
                                {dataRefresh.createdAt.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell>
                                {dataRefresh.status}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <DeleteDataRefresh accountId={accountId} dataRefreshId={dataRefresh.id} />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default DataRefreshesTable;
