import { createSelector } from "reselect";

import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const storeVsComparatorRankedGrossProfit = (state) => {
    return state.customer.insights.portfolio.profit.rankedGrossProfitMargin.storeVsComparatorRankedGrossProfit;
};

const rollingGrossProfit = createSelector(
    storeVsComparatorRankedGrossProfit,
    state => selectStore(state),
    state => selectComparator(state),
    (storeVsComparatorRankedGrossProfit, selectedStore, selectedComparator) => {
        const rag = {
            loading: false,
            error: false,
            id: "rolling-gross-profit",
            label: "Rolling gross profit",
            status: "info",
            value: ""
        };
        if (storeVsComparatorRankedGrossProfit.loading) {
            rag.loading = true;
            rag.error = false;
            return rag;
        }
        if (storeVsComparatorRankedGrossProfit.error) {
            rag.loading = false;
            rag.error = true;
            return rag;
        }

        const rank = storeVsComparatorRankedGrossProfit.rank;
        const denominator = storeVsComparatorRankedGrossProfit.denominator;
        const topThirdPercentile = 1 / 3;
        const bottomThirdPercentile = 2 / 3;
        const selectedPercentile = rank / denominator;

        if (selectedPercentile < topThirdPercentile) {
            rag.status = "success";
            rag.value = `${selectedStore.name} is a top performer for gross profit relative to the ${selectedComparator.name}`;
        } else if (selectedPercentile > bottomThirdPercentile) {
            rag.status = "error";
            rag.value = `${selectedStore.name} is underperforming in terms of gross profit relative to the ${selectedComparator.name}`;
        } else {
            rag.status = "warning";
            rag.value = `${selectedStore.name} is average for gross profit relative to the ${selectedComparator.name}`;
        }

        return rag;
    }
);

const selectors = {
    storeVsComparatorRankedGrossProfit,
    rollingGrossProfit
};

export default selectors;
