import React from "react";

import {
    Box,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { withStyles } from "@material-ui/core/styles";

import {
    BreakdownOfCompetitorStoresSortField,
    selectBreakdownOfCompetitorStores,
    selectBreakdownOfCompetitorStoresFilter,
    selectBreakdownOfCompetitorStoresSort,
    setBreakdownOfCompetitorStoresFilter,
    setBreakdownOfCompetitorStoresSort
} from "modules/customer/tools/location/competition/competitionSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";
import { SortDirection } from "utils/sortUtils";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTextField = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        width: theme.spacing(45)
    }
}))(TextField);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

const BreakdownOfCompetitorStores: React.FC = () => {
    const dispatch = useAppDispatch();
    const breakdownOfCompetitorStores = useAppSelector(selectBreakdownOfCompetitorStores);
    const filter = useAppSelector(selectBreakdownOfCompetitorStoresFilter);
    const sort = useAppSelector(selectBreakdownOfCompetitorStoresSort);

    const handleCompetitorStoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const competitorStore = event.target.value;
        const newFilter = { ...filter, competitorStore };
        dispatch(setBreakdownOfCompetitorStoresFilter(newFilter));
    };

    const handleSortClick = (field: BreakdownOfCompetitorStoresSortField) => () => {
        let direction = SortDirection.ASC;
        if (sort.field === field) {
            direction = sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }
        const newSort = { ...sort, field, direction };
        dispatch(setBreakdownOfCompetitorStoresSort(newSort));
    };

    return (
        <Box paddingTop={2} data-cy="breakdown-of-competitor-stores">
            <Typography variant="h6" component="div">
                Breakdown of competitor stores within 5km
            </Typography>
            <br />
            {/* @ts-ignore */}
            <StyledTableContainer component={Paper} elevation={0} data-cy="table">
                <Box paddingTop={2} paddingBottom={2}>
                    <StyledTextField
                        placeholder="Search for a competitor store"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        value={filter.competitorStore}
                        onChange={handleCompetitorStoreChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchRoundedIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sort.field === BreakdownOfCompetitorStoresSortField.Fascia}
                                    direction={sort.direction}
                                    onClick={handleSortClick(BreakdownOfCompetitorStoresSortField.Fascia)}
                                    IconComponent={ExpandMoreRoundedIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Competitor stores
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === BreakdownOfCompetitorStoresSortField.Size}
                                    direction={sort.direction}
                                    onClick={handleSortClick(BreakdownOfCompetitorStoresSortField.Size)}
                                    IconComponent={ExpandMoreRoundedIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Size
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === BreakdownOfCompetitorStoresSortField.DistanceToProposedStore}
                                    direction={sort.direction}
                                    onClick={handleSortClick(BreakdownOfCompetitorStoresSortField.DistanceToProposedStore)}
                                    IconComponent={ExpandMoreRoundedIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Distance to selected location
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sort.field === BreakdownOfCompetitorStoresSortField.DistanceToHotspot}
                                    direction={sort.direction}
                                    onClick={handleSortClick(BreakdownOfCompetitorStoresSortField.DistanceToHotspot)}
                                    IconComponent={ExpandMoreRoundedIcon}
                                >
                                    {/* @ts-ignore */}
                                    <StyledTypography variant="h6" component="div">
                                        Distance from core retail hub
                                    </StyledTypography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdownOfCompetitorStores.map((item, index) =>
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                        {item.fascia}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.size, 1, true) + " sqft"}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.distanceToProposedStore, 1) + "km"}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body1" component="div">
                                        {numberFormatter.toDecimalPlaces(item.distanceToHotspot, 1) + "km"}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default BreakdownOfCompetitorStores;
