import React from "react";

import { Box, Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Navigation from "./navigation/Navigation";

import Router from "./Router";
import LoggedOut from "./LoggedOut";

const StyledContainer = withStyles(theme => ({
    root: {
        width: "calc(100vw - 200px)",
        [theme.breakpoints.down("md")]: {
            width: "100vw"
        }
    }
}))(Container);

const Admin: React.FC = () => {
    return (
        <Box display="flex" flexGrow={1} data-cy="pages-admin">
            <Navigation />
            {/* @ts-ignore */}
            <StyledContainer component="main" maxWidth={false} disableGutters>
                <Router />
                <LoggedOut />
            </StyledContainer>
        </Box>
    );
};

export default Admin;
