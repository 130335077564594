import React from "react";

import { Box, Typography } from "@material-ui/core";
import { useTheme, } from "@material-ui/core/styles";

const StoreClusteringGroupSubtitle = () => {
    const theme = useTheme();

    return (
        <Box paddingBottom={2}>
            <Typography variant="h6" style={{ "color": theme.palette.common.white }}>
                Store clustering group
            </Typography>
        </Box>
    );
};

export default StoreClusteringGroupSubtitle;
