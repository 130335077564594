import types from "./types";

const getContributionPerformanceVsBudgetRequest = () => ({
    type: types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_REQUEST
});

const getContributionPerformanceVsBudgetSuccess = (contributionFYTD, contributionFYTG, budgetFY, FYlabel) => ({
    type: types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_SUCCESS,
    payload: {
        contributionFYTD,
        contributionFYTG,
        budgetFY,
        FYlabel
    }
});

const getContributionPerformanceVsBudgetFailure = () => ({
    type: types.GET_CONTRIBUTION_PERFORMANCE_VS_BUDGET_FAILURE
});

const actions = {
    getContributionPerformanceVsBudgetRequest,
    getContributionPerformanceVsBudgetSuccess,
    getContributionPerformanceVsBudgetFailure
};

export default actions;
