import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";

import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";
import { selectSelectedStoreByCostType } from "modules/customer/insights/cost/costSlice";
import useColourPalette from "components/visuals/useColourPalette";
import mathUtils from "utils/mathUtils";

const CostValue = () => {
    const colours = useColourPalette();
    const selectedStoreAndCost = useAppSelector(selectSelectedStoreByCostType);

    const difference = selectedStoreAndCost ? mathUtils.safePercentageChange(selectedStoreAndCost.costValue, selectedStoreAndCost.similarStoresAverageCostValue) : 0;
    const label = `${selectedStoreAndCost?.storeName} ${selectedStoreAndCost?.costName} cost`;

    return (
        <Box width="100%" height="100%" data-cy="costs-value">
            <KPIComparison
                isLoading={false}
                hasErrors={false}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colours.comparators[0] }}>
                        {numberFormatter.toGBP(selectedStoreAndCost?.costValue, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h5" component="div" style={{ color: colours.comparators[1] }}>
                        {numberFormatter.toGBP(selectedStoreAndCost?.similarStoresAverageCostValue, 1)}
                    </Typography>
                }
                comparisonLabel={`${selectedStoreAndCost?.costName} cost cluster`}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toPercentage(difference, true)}
                    </Typography>
                }
                differenceLabel="Difference (%)"
            />
        </Box>
    );
};

export default CostValue;
