import React from "react";
import { Grid } from "@material-ui/core";

import StoreVsComparatorRankedLastTwelveMonthsGrowth from "./StoreVsComparatorRankedLastTwelveMonthsGrowth";
import RollingGrowthClassification from "./RollingGrowthClassification";

const ProductCategoryGrowth = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StoreVsComparatorRankedLastTwelveMonthsGrowth />
            </Grid>
            <Grid item xs={12}>
                <RollingGrowthClassification />
            </Grid>
        </Grid>
    );
};

export default ProductCategoryGrowth;
