import React from "react";

import { Card, CardMedia, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledCard = withStyles(() => ({
    root: {
        backgroundColor: "transparent"
    }
}))(Card);

const OurBackground = () => {
    return (
        <PageSection dataCy="pages-landing-our-story-our-background">
            <Grid container spacing={6} direction="row-reverse" alignItems="center">
                <Grid item xs={12} md={6}>
                    <StyledCard square elevation={0}>
                        <CardMedia component="img" image="/content/landing/our-story/our-background.png" alt="our background" />
                    </StyledCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" component="div">
                        Our background
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" component="div">
                        KPMG is a global organisation supporting businesses of all sizes, across all industries.
                        In the UK, our 15,300 strong team support our clients through offering deep business, sector and technology experience.
                        Dash is part of KPMG Products, a range of powerful tools and applications designed to increase the speed and accuracy of your decision-making.
                    </Typography>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default OurBackground;
