import { combineReducers } from "redux";

import types from "./types";

const retailDensityVisEmpty = {
    loading: false,
    error: false,
    store: 0,
    comparator: 0,
    retailDensityData: []
};

const retailDensityVis = (state = retailDensityVisEmpty, action) => {
    switch (action.type) {
        case types.GET_RETAIL_DENSITY_VIS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: 0,
                comparator: 0,
                retailDensityData: []
            };
        case types.GET_RETAIL_DENSITY_VIS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator,
                retailDensityData: action.payload.retailDensityData
            };
        case types.GET_RETAIL_DENSITY_VIS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: 0,
                comparator: 0,
                retailDensityData: []
            };
        default:
            return state;
    }
};


const reducer = combineReducers({
    retailDensityVis
});

export default reducer;
