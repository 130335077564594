import { median } from "mathjs";

const percentileThresholds = (items: number[], numberOfGroups: number) => {
    items.sort((a, b) => a - b);
    const thresholds = [];
    const numberOfItems = items.length;
    const step = 100 / numberOfGroups;
    let percentile = 0;
    for (let i = 0; i < numberOfGroups; i++) {
        if (numberOfItems === 0) {
            thresholds.push(0);
            continue;
        }
        const rank = (percentile / 100) * (numberOfItems - 1);
        const intgr = Math.floor(rank);
        const fract = rank % 1;

        const threshold = items[intgr] + (fract * (items[intgr + 1] - items[intgr]));
        thresholds.push(threshold);

        percentile += step;
    }
    return thresholds;
};

const round = (number: number, decimalPlaces: number) => {
    const isNegative = number < 0;
    if (isNegative) number *= -1;
    const round = Number(Math.round(parseFloat(number + "e" + decimalPlaces)) + "e-" + decimalPlaces);
    if (isNegative) return round * -1;
    return round;
};

const safePercentageChange = (newVal: number, oldVal: number): number => {
    if (oldVal === 0) {
        if (newVal > 0) {
            return 100;
        } else {
            return 0;
        }
    }

    return 100 * ((newVal - oldVal) / oldVal);
};

const safePercentage = (numerator: number, denominator: number): number => {
    if (denominator === 0) {
        if (numerator > 0) {
            return 100;
        } else {
            return 0;
        }
    }

    return 100 * (numerator / denominator);
};

const haversineDistance = (latitudeA: number, longitudeA: number, latitudeB: number, longitudeB: number): number => {
    const radiansConstant = 0.01746031;
    const earthRadius = 6378.8; // (In Km)
    const latitudeARadians = latitudeA * radiansConstant;
    const longitudeARadians = longitudeA * radiansConstant;
    const latitudeBRadians = latitudeB * radiansConstant;
    const longitudeBRadians = longitudeB * radiansConstant;
    const differenceInLatitude = latitudeARadians - latitudeBRadians;
    const differenceInLongitude = longitudeARadians - longitudeBRadians;
    return earthRadius *
        (2 * Math.asin(Math.sqrt(Math.pow(Math.sin(differenceInLatitude / 2), 2) + Math.cos(latitudeBRadians) * Math.cos(latitudeARadians * radiansConstant) * Math.pow(Math.sin(differenceInLongitude / 2), 2))));
};

const safeMedian = (values: number[]): number | undefined => {
    const filteredValues = values.filter(value => value !== null || value !== undefined);
    if (filteredValues.length === 0) {
        return undefined;
    }
    return median(filteredValues);
};

const centilesStandardisedMedian = (centiles: number[]) => {
    if (centiles.length === 0) {
        return 0;
    }
    const centilesMedian = median(centiles);
    let standardisedMedian = 45;
    let difference = 100; // initialise with a number larger than any potential difference
    for (let possibleCentileMedian = 5; possibleCentileMedian <= 95; possibleCentileMedian += 10) {
        const absDiff = Math.abs(centilesMedian - possibleCentileMedian);
        if (absDiff <= difference) {
            difference = absDiff;
            standardisedMedian = possibleCentileMedian;
        }
    }
    return standardisedMedian;
};

const mathUtils = {
    percentileThresholds,
    round,
    safePercentageChange,
    safePercentage,
    haversineDistance,
    safeMedian,
    centilesStandardisedMedian
};

export default mathUtils;
