import React from "react";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const CustomerProfilesSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            <p>
                Understand who your customers are and where they travel from. Using data from the Office of National
                Statistics, we categorise the occupants of each output area* surrounding your {store?.name} store
                into eight possible customer profile supergroups. Each profile is then split into groups and sub-groups.
                Customer profiles describe the demographics of an area, based on characteristics such as race, age,
                gender and number of children. We combine these with our catchment area analysis to determine your
                customer base. To learn more about the customer profiles, get their definitions in the dropdown below.
            </p>
            <p>
                *An output area is the smallest geographical unit used for Census purposes. Most output areas contain
                between 110 and 139 households.
            </p>
        </>
    );
};

export default CustomerProfilesSubtitle;
