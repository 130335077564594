import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { useTheme, withStyles } from "@material-ui/core/styles";

const StyledDialog = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.quaternary.dark
    },
    paper: {
        padding: theme.spacing(5)
    }
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    }
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4)
    }
}))(DialogContent);

const StyledDialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        justifyContent: "space-evenly"
    }
}))(DialogActions);

export enum ConfirmationDialogType {
    Error,
    Warning
}

interface ConfirmationDialogProps {
    open: boolean,
    title: string,
    subtitle: string | React.ReactElement,
    type: ConfirmationDialogType,
    confirmLabel: string,
    cancelLabel: string,
    onConfirm: () => void,
    onCancel: () => void
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    const { open, title, subtitle, type, confirmLabel, cancelLabel, onConfirm, onCancel } = props;
    const theme = useTheme();
    const colour = type === ConfirmationDialogType.Error ? theme.palette.error.main : theme.palette.warning.main;

    return (
        <StyledDialog
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <StyledDialogTitle>
                <Typography variant="h1" component="div" align="center" style={{ color: colour }}>
                    <ErrorOutlineRoundedIcon fontSize="inherit" color="inherit" />
                </Typography>
            </StyledDialogTitle>
            <StyledDialogContent>
                <Typography variant="h5" component="div" align="center">
                    {title}
                </Typography>
                <br />
                <Typography variant="subtitle1" component="div" align="center">
                    {subtitle}
                </Typography>
            </StyledDialogContent>
            <StyledDialogActions disableSpacing>
                <Button
                    variant="outlined"
                    size="large"
                    color="default"
                    disableElevation
                    onClick={onCancel}
                >
                    {cancelLabel}
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disableElevation
                    onClick={onConfirm}
                >
                    {confirmLabel}
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default ConfirmationDialog;
