import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/insights/portfolioNew/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CoreRetailHub {
    public readonly localArea: string;
    public readonly latitude: number;
    public readonly longitude: number;

    constructor(
        localArea: string,
        latitude: number,
        longitude: number,
    ) {
        this.localArea = localArea;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export const loadCoreRetailHubs = (stores: Store[]): AppThunk<Promise<CoreRetailHub[] | undefined>> => async (dispatch) => {
    try {
        if (!stores) {
            return undefined;
        }

        let storesOutputAreaCode: string[] = [];
        stores.forEach((store) => {
            storesOutputAreaCode.push(
                store.outputAreaCode
            );
        });


        const filters = [{
            member: "LocalAreaHotspot.MainOAID",
            operator: "equals",
            values: storesOutputAreaCode
        }];

        const query = {
            dimensions: [
                "LocalAreaHotspot.MainOAID",
                "LocalAreaHotspot.HotSpotLat",
                "LocalAreaHotspot.HotSpotLong",
            ],
            filters
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;

        let coreRetailHubs: CoreRetailHub[] = [];

        for (let i = 0; i < resultSet.rawData().length; i++) {
            coreRetailHubs.push(...resultSet.rawData().map(row =>
                new CoreRetailHub(
                    row["LocalAreaHotspot.MainOAID"],
                    row["LocalAreaHotspot.HotSpotLat"],
                    row["LocalAreaHotspot.HotSpotLong"]
                )
            ));
        }

        return coreRetailHubs;
    } catch (error) {
        dispatch(logError("Error loading Core Retail Hub.", error));
        throw error;
    }
};
