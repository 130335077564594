import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class WeeklyRevenueShare {
    public readonly peakWeek: number;
    public readonly medianWeek: number;

    constructor(
        peakWeek: number,
        medianWeek: number
    ) {
        this.peakWeek = peakWeek;
        this.medianWeek = medianWeek;
    }
}

export const loadWeeklyRevenueShare = (): AppThunk<Promise<WeeklyRevenueShare>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "WeeklyRevenueShare.SalesShare_PeakWeek",
                "WeeklyRevenueShare.SalesShare_MedianWeek"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        let peakWeek = 0;
        let medianWeek = 0;
        if (rawData.length > 0) {
            peakWeek = rawData[0]["WeeklyRevenueShare.SalesShare_PeakWeek"] ?? 0;
            medianWeek = rawData[0]["WeeklyRevenueShare.SalesShare_MedianWeek"] ?? 0;
        }
        return new WeeklyRevenueShare(peakWeek, medianWeek);
    } catch (error) {
        dispatch(logError("Error loading WeeklyRevenueShare.", error));
        throw error;
    }
};
