import React from "react";
import { useSelector } from "react-redux";

import { Box, Card, CardContent, FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { selectors } from "modules/customer/account/settings";

const StyledCard = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(Card);

const EmailNotifications = () => {
    const emailNotifications = useSelector(state => selectors.emailNotifications(state));

    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-account-settings-email-notifications">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Email notifications (coming soon)
                </Typography>
            </Box>
            <StyledCard>
                <CardContent>
                    <Typography variant="h4" component="div">
                        Data notifications
                    </Typography>
                    <br />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    color="default"
                                    checked={emailNotifications.updatesOnDatasets}
                                    disabled
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Updates on datasets
                                </Typography>
                            }
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    color="default"
                                    checked={emailNotifications.updatesOnDataRefresh}
                                    disabled
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Updates on data refresh
                                </Typography>
                            }
                        />
                    </FormGroup>
                    <br />
                    <br />
                    <Typography variant="h4" component="div">
                        Newsletter
                    </Typography>
                    <br />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    color="default"
                                    checked={emailNotifications.weeklyNewsletter}
                                    disabled
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Weekly newsletter
                                </Typography>
                            }
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    color="default"
                                    checked={emailNotifications.featuresUpdates}
                                    disabled
                                />
                            }
                            label={
                                <Typography variant="subtitle1" component="div">
                                    Features updates
                                </Typography>
                            }
                        />
                    </FormGroup>
                </CardContent>
            </StyledCard>
        </Box>
    );
};

export default EmailNotifications;
