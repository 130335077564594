import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import numberFormatter from "utils/numberFormatter";
import StockBar from "components/visuals/StockBar";

import { operations, selectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

const CategoryCMSales = () => {
    const dispatch = useDispatch();
    const categoryCMSales = useSelector(state => selectors.categoryCMSales(state));
    const { loading, error, categorySales, drilldownData, currentMonth } = categoryCMSales; 
    const date = currentMonth ? `${currentMonth[0]} ${currentMonth[1]}` : "latest month";
    categorySales.sort((a,b) => {
        return b.y - a.y;
    });

    const options = {
        chart: {
            marginRight: 50
        },
        title: {
            text: `Revenue in ${date} by category`
        },
        tooltip: {
            formatter() {
                return '<b>' + this.key + '</b><br/>Revenue ' + numberFormatter.toGBP(this.y);
            }
        },
        series: [
            {
                name: "Product Categories",
                data: categorySales
            }],
        drilldown: {
            series: drilldownData
        },
        yAxis: {
            labels: {
                formatter: function() {return numberFormatter.toGBP(this.value, 0);}
            }
        }
    };

    React.useEffect(() => {
        dispatch(operations.getCategoryCMSales());
    }, [dispatch]);

    return (
        <Box data-cy="category-cm-sales">
            <StockBar loading={loading} error={error} options={options} />
        </Box>
    );
};

export default CategoryCMSales;
