import React from "react";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { hideIdle, showLoggedOut } from "modules/auth/authSlice";
import { useAppDispatch } from "store";

const StyledTypography = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Typography);

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;
const FIVE_MINUTES_IN_MILLISECONDS = 5 * ONE_MINUTE_IN_MILLISECONDS;

const Countdown: React.FC = () => {
    const dispatch = useAppDispatch();
    const [millisecondsLeft, setMillisecondsLeft] = React.useState(FIVE_MINUTES_IN_MILLISECONDS);
    const minutes = Math.floor((millisecondsLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((millisecondsLeft % (1000 * 60)) / 1000);
    const minutesLabel = minutes > 1 ? "minutes" : "minute";
    const secondsLabel = seconds === 1 ? "second" : "seconds";

    React.useEffect(() => {
        const interval = setInterval(() => {
            setMillisecondsLeft(prev => prev - ONE_SECOND_IN_MILLISECONDS);
        }, ONE_SECOND_IN_MILLISECONDS);
        return () => clearInterval(interval);
    }, [dispatch]);

    React.useEffect(() => {
        if (millisecondsLeft <= 0) {
            dispatch(showLoggedOut());
            dispatch(hideIdle());
        }
    }, [dispatch, millisecondsLeft]);

    return (
        // @ts-ignore
        <StyledTypography variant="h3" component="div" gutterBottom>
            {minutes > 0 && `${minutes} ${minutesLabel} and `}
            {seconds} {secondsLabel}
        </StyledTypography>
    );
};

export default Countdown;
