import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const ChildrenAgeStructure: React.FC = () => {
    const title = "Children's age structure (Children)";
    const categories = [
        "<1",
        "1-2",
        "3-5",
        "6-10",
        "11-16"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.childrenAgeStructure;
    const selectedStoreValues = selectedStore ? [
        selectedStore.childrenAgeLessThan1,
        selectedStore.childrenAge1to2,
        selectedStore.childrenAge3to5,
        selectedStore.childrenAge6to10,
        selectedStore.childrenAge11to16
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.childrenAgeStructure;
    const comparatorValues = comparator ? [
        comparator.childrenAgeLessThan1,
        comparator.childrenAge1to2,
        comparator.childrenAge3to5,
        comparator.childrenAge6to10,
        comparator.childrenAge11to16
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default ChildrenAgeStructure;
