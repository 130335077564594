import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const StyledDialog = withStyles(theme => ({
    root: {
        color: theme.palette.quaternary.dark
    },
    paper: {
        padding: theme.spacing(5)
    }
}))(Dialog);

const StyledDialogTitle = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
    }
}))(DialogTitle);

const StyledDialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(1)
    }
}))(DialogContent);

const StyledDialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(4)
    }
}))(DialogActions);

const SimpleDialog = (props) => {
    const {
        open, enableClose, onClose, maxWidth, fullHeight, backgroundColor,
        title, titleAlign,
        content, contentAlign,
        actions, actionsJustifyContent,
        dataCy
    } = props;
    const theme = useTheme();

    const handleCloseClick = () => {
        onClose();
    };

    return (
        <StyledDialog
            open={open}
            maxWidth={maxWidth}
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: backgroundColor || theme.palette.quaternary.dark,
                    height: fullHeight ? "calc(100% - 64px)" : "auto"
                },
                "data-cy": dataCy
            }}
        >
            {enableClose && (
                <Box position="absolute" top={0} right={0}>
                    <IconButton size="medium" onClick={handleCloseClick}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            )}
            {title && (
                <StyledDialogTitle align={titleAlign}>
                    {title}
                </StyledDialogTitle>
            )}
            <StyledDialogContent align={contentAlign}>
                {content}
            </StyledDialogContent>
            {actions && (
                <StyledDialogActions style={{ justifyContent: actionsJustifyContent }}>
                    {actions}
                </StyledDialogActions>
            )}
        </StyledDialog>
    );
};

SimpleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
    fullHeight: PropTypes.bool.isRequired,
    backgroundColor: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleAlign: PropTypes.oneOf(["left", "center"]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    contentAlign: PropTypes.oneOf(["left", "center"]),
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actionsJustifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
    enableClose: PropTypes.bool,
    dataCy: PropTypes.string
};

SimpleDialog.defaultProps = {
    enableClose: true,
    onClose: () => {},
    maxWidth: "sm",
    fullHeight: false,
    titleAlign: "left",
    contentAlign: "left",
    actionsJustifyContent: "flex-end",
    dataCy: ""
};

export default SimpleDialog;
