import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTheme } from "@material-ui/core";

import Scatter from "./Scatter";
import useColourPalette from "./useColourPalette";

const MagicQuadrant = (props) => {
    const {
        loading, error, options: customOptions, topLeftLabel, topRightLabel,
        bottomRightLabel, bottomLeftLabel, padPercentage, padBelowZero, 
        doQuadrants, xMidPoint, yMidPoint, xAxisReversed, disableLegendClick
    } = props;

    const theme = useTheme();
    const colourPalette = useColourPalette();
    const RAGChartZones = colourPalette.RAGChartZones;

    let calledByRedraw = false;

    const rendererElementDestroyer = (chart, elementName) => {
        if (chart[elementName]) {
            chart[elementName].destroy();
            chart[elementName] = undefined;
        }
    };
    const quadrantRectRender = (chart, x, y, width, height, r=1, fillColour, elementName) => {
        rendererElementDestroyer(chart, elementName);

        chart[elementName] = chart.renderer.rect(x, y, width, height, r).attr({ fill: fillColour }).add();
    };
    
    const setupMagicQuadrant = (chart) => {
        const yAxis = chart.yAxis[0];

        if (padBelowZero) {
            const currentExtremes = yAxis.getExtremes();
            const dataMax = currentExtremes?.dataMax;
            const dataMin = currentExtremes?.dataMin;
            const dataRange = dataMax - dataMin;

            const yMin = dataMin - dataRange * padPercentage/100;
            const yMax = dataMax + dataRange * padPercentage/100;

            let prevCalledByRedraw = calledByRedraw;
            calledByRedraw = true;
            chart.yAxis[0].setExtremes(yMin, yMax, true, false);
            calledByRedraw = prevCalledByRedraw;
        }

        if (doQuadrants) {
            const yMin = yAxis.min;
            const yMax = yAxis.max;

            const xAxis = chart.xAxis[0];
            const xMin = xAxis.min;
            const xMax = xAxis.max;

            const x0 = chart.xAxis[0].toPixels(xMin, false);
            const x1 = chart.xAxis[0].toPixels(xMidPoint, false);
            const x2 = chart.xAxis[0].toPixels(xMax, false);
        
            const y1 = chart.yAxis[0].toPixels(yMidPoint, false);
            const y0 = chart.yAxis[0].toPixels(yMin, false);
            const y2 = chart.yAxis[0].toPixels(yMax, false);

            if (!isNaN(x0) && !isNaN(x1) && !isNaN(x2) && !isNaN(y0) && !isNaN(y1) && !isNaN(y2)) {   
                if (!xAxisReversed) {
                    quadrantRectRender(chart, x0, y2, x1 - x0, y1 - y2, 1, RAGChartZones[1], "topLeftRect");
                    quadrantRectRender(chart, x1, y2, x2 - x1, y1 - y2, 1, RAGChartZones[2], "topRightRect");
                    quadrantRectRender(chart, x0, y1, x1 - x0, y0 - y1, 1, RAGChartZones[0], "bottomLeftRect");
                    quadrantRectRender(chart, x1, y1, x2 - x1, y0 - y1, 1, RAGChartZones[1], "bottomRightRect");
                } else {
                    quadrantRectRender(chart, x2, y2, x2 + x1, y1 - y2, 1, RAGChartZones[1], "topLeftRect");
                    quadrantRectRender(chart, x1, y2, x2 + x0, y1 - y2, 1, RAGChartZones[2], "topRightRect");
                    quadrantRectRender(chart, x1, y1, x2 + x1, y0 - y1, 1, RAGChartZones[0], "bottomLeftRect");
                    quadrantRectRender(chart, x1, y1, x2 + x0, y0 - y1, 1, RAGChartZones[1], "bottomRightRect");
                }
            }
        }

        chart.redraw();
    };

    const quadrantAnnotation = (annotation, useXMax, useYMax) => {
        const chart = annotation.chart;

        if (chart === undefined) {
            return;
        }

        const xAxis = chart.xAxis[0], yAxis = chart.yAxis[0];

        const annotationSpacingFractionX = 0.015;
        const annotationSpacingFractionY = chart.plotWidth/chart.plotHeight * annotationSpacingFractionX;

        const axisVal = (axis, useMax, spacingFraction) => {
            const max = axis.max, min = axis.min;
            const range = max - min;
            const spacer = range * spacingFraction;
    
            return useMax ? max - spacer : min + spacer;
        };

        return {
            x: axisVal(xAxis, useXMax, annotationSpacingFractionX),
            xAxis: 0,
            y: axisVal(yAxis, useYMax, annotationSpacingFractionY),
            yAxis: 0
        };
    };

    const preliminaryOptions = {
        chart: {
            alignTicks: false,
            height: '40%',
            events: {
                load: function () {
                    setupMagicQuadrant(this);
                },

                redraw: function () {
                    if (calledByRedraw === false) { 
                        calledByRedraw = true;
                        setupMagicQuadrant(this);
                  }
                  
                  calledByRedraw = false;
                }
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            startOnTick: false,
            endOnTick: false,
            minPadding: padPercentage/100,
            maxPadding: padPercentage/100
        },
        xAxis: {
            minorTickLength: 0,
            tickLength: 0,
            labels: {
                enabled: false
            },
            maxPadding: 0.1
        },
        plotOptions: {
            scatter: {
                dataLabels: {
                    enabled: false
                },
                events: {
                    legendItemClick: (disableLegendClick) ? function() { return false; } : undefined
                }
            }
        },
    };

    const annotationOptions = {
        annotations: [{
            draggable: '',
            labelOptions: {
                shape: 'rect',
                borderRadius: 6,
                borderWidth: 0,
                overflow: 'justify',
                backgroundColor: '#423E55',
                y: 0,
                verticalAlign: 'bottom',
                style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '11px',
                    color: 'white'
                }
            },
            labels: [{
                point: function(annotation) {
                    return quadrantAnnotation(annotation, true, true);
                },
                align: 'right',
                text: !xAxisReversed ? topRightLabel : topLeftLabel,
            }, {
                point: function (annotation) {
                    return quadrantAnnotation(annotation, false, true);
                },
                align: 'right',
                text: !xAxisReversed ? topLeftLabel : topRightLabel
            }, {
                point: function (annotation) {
                    return quadrantAnnotation(annotation, true, false);
                },
                align: 'left',
                text: !xAxisReversed ? bottomRightLabel : bottomLeftLabel
            }, {
                point: function (annotation) {
                    return quadrantAnnotation(annotation, false, false);
                },
                align: 'left',
                text: !xAxisReversed ? bottomLeftLabel : bottomRightLabel
            }]
        }]
    };

    let staticOptions = preliminaryOptions;

    if (doQuadrants) {
        staticOptions = _.merge({}, annotationOptions, staticOptions);
    }

    const options = _.merge({}, staticOptions, customOptions);
    
    return (
        <Scatter loading={loading} error={error} options={options} dataCy="magic-quadrant-chart" />
    );
};

MagicQuadrant.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired,
    topLeftLabel: PropTypes.string,
    topRightLabel: PropTypes.string,
    bottomRightLabel: PropTypes.string,
    bottomLeftLabel: PropTypes.string,
    padPercentage: PropTypes.number,
    padBelowZero: PropTypes.bool,
    doQuadrants: PropTypes.bool,
    xMidPoint: PropTypes.number,
    yMidPoint: PropTypes.number,
    xAxisReversed: PropTypes.bool.isRequired,
    disableLegendClick: PropTypes.bool
};

MagicQuadrant.defaultProps = {
    loading: false,
    error: false,
    topLeftLabel: "",
    topRightLabel: "",
    bottomRightLabel: "",
    bottomLeftLabel: "",
    padPercentage: 30,
    padBelowZero: false,
    doQuadrants: false,
    xMidPoint: 0,
    yMidPoint: 0,
    xAxisReversed: false,
    disableLegendClick: false
};

export default MagicQuadrant;
