import React from "react";

import { Slider, withStyles } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "store";
import { selectLikelihoodOfVisitingThresholds, setLikelihoodOfVisitingThresholds } from "modules/customer/tools/location/cannibalisation/cannibalisationSlice";

const StyledSlider = withStyles(theme => ({
  root: {
      width: "100%"
  },
  rail: {
      opacity: 1,
      color: theme.palette.text.disabled
  },
  thumb: {
      height: theme.spacing(1.75),
      width: theme.spacing(1.75),
      color: theme.palette.primary.main,
      border: "2px solid",
      borderColor: theme.palette.common.white,
      "&:focus, &:hover": {
          boxShadow: "none"
      },
      "&.Mui-disabled ": {
          display: "none"
      }
  }
}))(Slider);

const LikelihoodOfVisitingSlider: React.FC = () => {
    const filterValue = useAppSelector(selectLikelihoodOfVisitingThresholds);
    const [value, setValue] = React.useState(filterValue);
    const dispatch = useAppDispatch();
    
    const handleSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        setValue(value as number[]);
    };

    const handleSliderChangeCommmitted = (event: React.ChangeEvent<{}>, value: number | number[]) => {
        const newValue = value as number[];
        dispatch(setLikelihoodOfVisitingThresholds(newValue));
        setValue(newValue);
    };

    return (
        <StyledSlider
            value={value}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommmitted}
            valueLabelDisplay="auto"
        />
    );
};

export default LikelihoodOfVisitingSlider;
