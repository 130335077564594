import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import Error from "components/visuals/Error";
import Progress from "components/visuals/Progress";

import AreaHealth from "./areaHealth/AreaHealth";
import Demographics from "./demographics/Demographics";
import Footfall from "./footfall/Footfall";
import Spend from "./spend/Spend";
import StoreCategory from "./StoreCategory";
import Title from "./Title";

import {
    loadCentiles,
    selectCandidateTarget,
    selectCandidateTargetStoreCategory,
    selectCentilesHasErrors,
    selectCentilesIsLoading,
    selectDefaultTarget,
    selectDefaultTargetSpendCategories,
    setCandidateTarget,
    setCandidateTargetSpendCategories
} from "modules/customer/tools/location/filters/filtersSlice";
import { selectTarget } from "modules/customer/tools/location/locationSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const Targets: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const enableSpendNew = featureFlags.enableCustomerToolsLocationSpendNew;
    const target = useAppSelector(selectTarget);
    const [skipOverrideSpend, setSkipOverrideSpend] = React.useState(!!target);
    const candidateTargetStoreCategory = useAppSelector(selectCandidateTargetStoreCategory);
    const isLoading = useAppSelector(selectCentilesIsLoading);
    const hasErrors = useAppSelector(selectCentilesHasErrors);
    const candidateTarget = useAppSelector(selectCandidateTarget);
    const defaultTarget = useAppSelector(selectDefaultTarget);
    const defaultTargetSpendCategories = useAppSelector(selectDefaultTargetSpendCategories);

    React.useEffect(() => {
        if (!defaultTarget) {
            return;
        }
        if (!candidateTarget) {
            dispatch(setCandidateTarget({ ...defaultTarget }));
            dispatch(setCandidateTargetSpendCategories([...defaultTargetSpendCategories]));
        }
    }, [dispatch, candidateTarget, defaultTarget, defaultTargetSpendCategories]);

    React.useEffect(() => {
        if (defaultTarget && candidateTarget) {
            if (skipOverrideSpend) {
                setSkipOverrideSpend(false);
                return;
            }
            dispatch(setCandidateTarget({ ...candidateTarget, spend: defaultTarget.spend }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTarget]);

    React.useEffect(() => {
        dispatch(loadCentiles());
    }, [dispatch, candidateTargetStoreCategory]);

    return (
        <Box marginBottom={4}>
            <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                <StoreCategory />
                <Title />
            </Box>
            {isLoading && !hasErrors && (
                <Progress />
            )}
            {!isLoading && hasErrors && (
                <Error />
            )}
            {!isLoading && !hasErrors && (
                <>
                    <Demographics />
                    {enableSpendNew && <Spend />}
                    <AreaHealth />
                    <Footfall />
                </>
            )}
        </Box>
    );
};

export default Targets;
