import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import KPIComparator from "components/visuals/KPIComparator";

import { useAppSelector } from "store";
import { selectComparator, selectStore } from "modules/customer/insights/portfolio/portfolioSlice";
import { selectors } from "modules/customer/insights/portfolio/competition/localAreaCompetition";

const NumberOfDirectComp = () => {
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const numberOfDirectComp = useSelector(state => selectors.numberOfDirectComp(state));
    const { loading, error, value, percentageDifference } = numberOfDirectComp;
    const label = `${selectedStore.name} Store Number of Direct Competitors vs ${selectedComparator.name}`;
    const valueFormatted = value.toString();

    return (
        <Box width="100%" height="100%" data-cy="number-of-direct-comp">
            <KPIComparator loading={loading} error={error} label={label} value={valueFormatted} percentage={percentageDifference} switchColor={true} />
        </Box>
    );
};

export default NumberOfDirectComp;
