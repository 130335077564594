import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectDistanceToCoreRetailHub } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

const DistanceToCoreRetailHub = () => {
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const selectedComparator = useAppSelector(selectComparator);
    const distanceToCoreRetailHub = useAppSelector(selectDistanceToCoreRetailHub);

    const label = `${selectedStore?.name} store's distance to core retail hub`;
    const selectedStoreDistance = distanceToCoreRetailHub.data.selectedStoreDistance;
    const selectedComparatorDistance = distanceToCoreRetailHub.data.selectedComparatorDistance;
    const difference = 100 * ((selectedStoreDistance - selectedComparatorDistance) / selectedComparatorDistance);
    const differencePositiveSign = difference > 0 ? "+" : "";

    return (
        <Box width="100%" height="100%" data-cy="distance-to-core-retail-hub">
            <KPIComparison
                isLoading={distanceToCoreRetailHub.isLoading}
                hasErrors={distanceToCoreRetailHub.hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {numberFormatter.toDecimalPlaces(selectedStoreDistance, 1)}km
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {numberFormatter.toDecimalPlaces(selectedComparatorDistance, 1)}km
                    </Typography>
                }
                comparisonLabel={`Comparator: ${selectedComparator?.name}`}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {differencePositiveSign}{numberFormatter.toPercentage(difference, true, 2)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default DistanceToCoreRetailHub;
