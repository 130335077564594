import React from "react";

import DumbbellAlignment from "components/visuals/DumbellAlignment";

import { useAppSelector } from "store";
import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";

const FootfallAlignmentDumbbell: React.FC = () => {

    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const target = useAppSelector(selectTarget);
    const targetIsSet = Boolean(target?.useFootfall);

    const chartValues = [{
        lowCategory: "Very quiet area",
        highCategory: "Very busy area",
        label: "Footfall",
        selectedValue: pinnedLocation?.retailCentre.footfallCentile ?? 0,
        targetValue: target?.footfall ?? 0
    }];

    return (
        <DumbbellAlignment chartValues={chartValues} targetIsSet={targetIsSet} dataCy="footfall-alignment" />
    );
};

export default FootfallAlignmentDumbbell;
