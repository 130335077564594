const storeYoYGrowthVsComparator = (state) => {
    return state.customer.insights.portfolio.sales.growth.storeYoYGrowthVsComparator;
};

const storeForecastYoYGrowthVsComparator = (state) => {
    return state.customer.insights.portfolio.sales.growth.storeForecastYoYGrowthVsComparator;
};

const storeVsComparatorMoMGrowth = (state) => {
    return state.customer.insights.portfolio.sales.growth.storeVsComparatorMoMGrowth;
};

const storeHistoricGrowthVsComparator = (state) => {
    return state.customer.insights.portfolio.sales.growth.storeHistoricGrowthVsComparator;
};

const storeForecastGrowthVsComparator = (state) => {
    return state.customer.insights.portfolio.sales.growth.storeForecastGrowthVsComparator;
};

const selectors = {
    storeYoYGrowthVsComparator,
    storeForecastYoYGrowthVsComparator,
    storeVsComparatorMoMGrowth,
    storeHistoricGrowthVsComparator,
    storeForecastGrowthVsComparator
};

export default selectors;
