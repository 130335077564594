import React from "react";

import RankedStoresBeeswarm from "components/visuals/RankedStoresBeeswarm";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectRankedGrossProfit } from "modules/customer/insights/portfolioNew/profit/profitSlice";

const RankedGrossProfitMarginStoreVsComparator: React.FC = () => {
    const selectedStoreName = useAppSelector(selectStore)?.name ?? "";
    const comparatorName = useAppSelector(selectComparator)?.name ?? "";
    const rankedGrossProfit = useAppSelector(selectRankedGrossProfit);
    const { isLoading, hasErrors, data } = rankedGrossProfit;

    return (
        <RankedStoresBeeswarm
            isLoading={isLoading}
            hasErrors={hasErrors}
            dataCy="store-vs-comparator-ranked-gross-profit"
            title={`Ranked gross profit margin (%) for ${selectedStoreName} and ${comparatorName}`}
            selectedStoreName={selectedStoreName}
            comparatorName={comparatorName}
            valueName="Gross profit"
            storesWithValues={data.rankedStores}
        />
    );
};

export default RankedGrossProfitMarginStoreVsComparator;
