import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Query, ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { cubeLoad, setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import dateUtils from "utils/dateUtils";
import { SortDirection, stringSortExpression } from "utils/sortUtils";

import { Store } from "./store";
import { Comparator } from "./comparator";

import { operations as salesOperations } from "./sales";
import { operations as performanceDriversOperations } from "./performanceDrivers";
import { operations as profitOperations } from "./profit";
import { operations as areaHealthOperations } from "./areaHealth";
import { operations as competitionOperations } from "./competition";
import { operations as catchmentOperations } from "./catchment";

interface OverviewSubchaptersIds {
    relativePerformance: string,
    recommendations: string
}

const overviewSubchaptersIdsEmpty: OverviewSubchaptersIds = {
    relativePerformance: "",
    recommendations: ""
};

interface SalesSubchaptersIds {
    revenue: string,
    growth: string,
    rankedGrowth: string,
    productCategoryMix: string,
    productCategoryGrowth: string
}

const salesSubchaptersIdsEmpty: SalesSubchaptersIds = {
    revenue: "",
    growth: "",
    rankedGrowth: "",
    productCategoryMix: "",
    productCategoryGrowth: ""
};

interface PerformanceDriversSubchaptersIds {
    storeSize: string,
    staffing: string
}

const performanceDriversSubchaptersIdsEmpty: PerformanceDriversSubchaptersIds = {
    storeSize: "",
    staffing: ""
};

interface ProfitSubchaptersIds {
    grossProfitMargin: string,
    rankedGrossProfitMargin: string,
    netProfit: string
}

const profitSubchaptersIdsEmpty: ProfitSubchaptersIds = {
    grossProfitMargin: "",
    rankedGrossProfitMargin: "",
    netProfit: ""
};

interface AreaHealthSubchaptersIds {
    openingsAndClosures: string,
    marketCategoryBreakdown: string,
    supplyAndDemand: string,
    retailDensity: string,
    marketCategorySpend: string,
}

const areaHealthSubchaptersIdsEmpty: AreaHealthSubchaptersIds = {
    openingsAndClosures: "",
    marketCategoryBreakdown: "",
    supplyAndDemand: "",
    retailDensity: "",
    marketCategorySpend: ""
};

interface CompetitionSubchaptersIds {
    competitionScore: string,
    revenueVsCompetitionScore: string,
    localAreaCompetition: string,
    marketShare: string,
}

const competitionSubchaptersIdsEmpty: CompetitionSubchaptersIds = {
    competitionScore: "",
    revenueVsCompetitionScore: "",
    localAreaCompetition: "",
    marketShare: ""
};

interface CatchmentSubchaptersIds {
    customerProfiles: string,
    footfall: string,
    demographicBreakdown: string,
}

const catchmentSubchaptersIdsEmpty: CatchmentSubchaptersIds = {
    customerProfiles: "",
    footfall: "",
    demographicBreakdown: ""
};

interface SubchaptersIds {
    overview: OverviewSubchaptersIds,
    sales: SalesSubchaptersIds,
    performanceDrivers: PerformanceDriversSubchaptersIds,
    profit: ProfitSubchaptersIds,
    areaHealth: AreaHealthSubchaptersIds,
    competition: CompetitionSubchaptersIds,
    catchment: CatchmentSubchaptersIds
}

const subchaptersIdsEmpty: SubchaptersIds = {
    overview: overviewSubchaptersIdsEmpty,
    sales: salesSubchaptersIdsEmpty,
    performanceDrivers: performanceDriversSubchaptersIdsEmpty,
    profit: profitSubchaptersIdsEmpty,
    areaHealth: areaHealthSubchaptersIdsEmpty,
    competition: competitionSubchaptersIdsEmpty,
    catchment: catchmentSubchaptersIdsEmpty
};

interface PortfolioState {
    subchaptersIds: SubchaptersIds,
    stores: Store[],
    referenceDate?: Date,
    referenceDateUtcIsoString: string,
    costReferenceDate: DateTime,
    primaryCategory?: string,
    productCategories: string [],
    numberOfProductCategories: number,
    store?: Store,
    comparator?: Comparator
}

const initialState: PortfolioState = {
    subchaptersIds: subchaptersIdsEmpty,
    stores: [],
    referenceDateUtcIsoString: "",
    costReferenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    productCategories: [],
    numberOfProductCategories: 0
};

const portfolioSlice = createSlice({
    name: "customer/insights/portfolio",
    initialState,
    reducers: {
        setSubchaptersIds: (state, action: PayloadAction<SubchaptersIds>) => {
            state.subchaptersIds = action.payload;
        },
        clearSubchaptersIds: (state) => {
            state.subchaptersIds = subchaptersIdsEmpty;
        },
        setStores: (state, action: PayloadAction<Store[]>) => {
            state.stores = action.payload;
        },
        clearStores: (state) => {
            state.stores = [];
        },
        setReferenceDate: (state, action: PayloadAction<Date>) => {
            state.referenceDate = action.payload;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = undefined;
        },
        setReferenceDateUtcIsoString: (state, action: PayloadAction<string>) => {
            state.referenceDateUtcIsoString = action.payload;
        },
        setCostReferenceDate: (state, action: PayloadAction<DateTime>) => {
            state.costReferenceDate = action.payload;
        },
        clearReferenceDateUtcIsoString: (state) => {
            state.referenceDateUtcIsoString = "";
        },
        setPrimaryCategory: (state, action: PayloadAction<string>) => {
            state.primaryCategory = action.payload;
        },
        clearPrimaryCategory: (state) => {
            state.primaryCategory = undefined;
        },
        setProductCategories: (state, action: PayloadAction<string[]>) => {
            state.productCategories = action.payload;
        },
        clearProductCategories: (state) => {
            state.productCategories = [];
        },
        setNumberOfProductCategories: (state, action: PayloadAction<number>) => {
            state.numberOfProductCategories = action.payload;
        },
        clearNumberOfProductCategories: (state) => {
            state.numberOfProductCategories = 0;
        },
        chooseStoreAndComparator: (state, action: PayloadAction<{ store: Store, comparator: Comparator }>) => {
            state.store = action.payload.store;
            state.comparator = action.payload.comparator;
        },
        clearStoreAndComparator: (state) => {
            state.store = undefined;
            state.comparator = undefined;
        }
    }
});

export const {
    setSubchaptersIds,
    chooseStoreAndComparator
} = portfolioSlice.actions;

export const getPortfolioData = (): AppThunk => async (dispatch) => {
    dispatch(backdropOn());
    try {
        await dispatch(setupCube());
        const referenceDatePromise = dispatch(getReferenceDate());
        const primaryCategoryPromise = dispatch(getPrimaryCategory());
        const productCategoriesPromise = dispatch(getProductCategories());
        const numberOfProductCategoriesPromise = dispatch(getNumberOfProductCategories());
        await Promise.all([referenceDatePromise, primaryCategoryPromise, productCategoriesPromise, numberOfProductCategoriesPromise]);
        await dispatch(getCostReferenceDate());
        await dispatch(getStores());
    } catch (error) {
        dispatch(notifyError("Error loading Portfolio."));
    } finally {
        dispatch(backdropOff());
    }
};

export const clearPortfolioData = (): AppThunk => async (dispatch) => {
    dispatch(portfolioSlice.actions.clearStores());
    dispatch(portfolioSlice.actions.clearReferenceDate());
    dispatch(portfolioSlice.actions.clearReferenceDateUtcIsoString());
    dispatch(portfolioSlice.actions.clearPrimaryCategory());
    dispatch(portfolioSlice.actions.clearProductCategories());
    dispatch(portfolioSlice.actions.clearNumberOfProductCategories());
    dispatch(portfolioSlice.actions.clearStoreAndComparator());
};

const getStores = (): AppThunk => async (dispatch, getState) => {
    try {
        const storesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.k_Region",
                "D_Store.OutputAreaID",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store_RagMetrics.Competition",
                "D_Store_RagMetrics.Drivers",
                "D_Store_RagMetrics.Profit",
                "D_Store_RagMetrics.Sales",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.EmployeeCount",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                
            ],
            filters: [{
                member: "D_Store.OutputAreaID",
                operator: "notEquals",
                values: ["Unknown", "NULL", "Null", "null"]
            }, {
                member: "D_Store.OutputAreaID",
                operator: "set"
            }, {
                member: "D_Store.ClosingDate", 
                operator: "notSet"
            }]
        };
        const resultSetStores = await dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const rawStores = resultSetStores.rawData();
        const stores = rawStores.map(rawStore => new Store(
            rawStore["D_Store.StoreNaturalID"],
            rawStore["D_Store.StoreName"],
            rawStore["D_Store.k_Region"],
            rawStore["D_Store.OutputAreaID"],
            rawStore["D_Store.Lat"],
            rawStore["D_Store.Long"],
            rawStore["D_Store_RagMetrics.Sales"] ?? 0,
            rawStore["D_Store_RagMetrics.Drivers"] ?? 0,
            rawStore["D_Store_RagMetrics.Profit"] ?? 0,
            0,
            rawStore["D_Store_RagMetrics.Competition"] ?? 0,
            0,
            dateUtils.dateUTC(rawStore["D_Store.OpeningDate"]),
            rawStore["D_Store.Sqft"],
            rawStore["D_Store.EmployeeCount"],
            rawStore["D_Store.RetailCentreID"],
            rawStore["D_Store.KPMGStoreCategory"],
            []
        ));
        
        const outputAreasScores = await dispatch(getOutputAreasScores(stores));
        
        const query = {
            measures: ["F_Sales.SumLineValue"],
            dimensions: ["D_Store.StoreNaturalID", "D_ProductCategory.ProductCategory3"],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "set"
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawStoreProductCategories = resultSet.rawData();

        const state = getState();
        const clientSpendCategories = selectProductCategories(state);
        
        for (const i in stores) {
            const outputAreaScore = outputAreasScores.find(item =>
                (item.kpmgStoreCategory === stores[i].kpmgStoreCategory) && (item.outputAreaCode === stores[i].outputAreaCode));
            
            if (outputAreaScore) {
                stores[i].areaHealthScore = outputAreaScore?.areaHealthScore;
                stores[i].catchmentScore = outputAreaScore?.catchmentScore;
            }
            const relevantSpendCategories = rawStoreProductCategories.filter(item => item["D_Store.StoreNaturalID"] === stores[i].id).map(item => item["D_ProductCategory.ProductCategory3"]);

            stores[i].kpmgSpendCategories = relevantSpendCategories.length === 0 ? clientSpendCategories : relevantSpendCategories;
        }

        dispatch(portfolioSlice.actions.setStores(stores));
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};


interface OutputAreaScores {
    outputAreaCode: string,
    kpmgStoreCategory: string,
    areaHealthScore: number,
    catchmentScore: number
}

const getOutputAreasScores = (stores: Store[]): AppThunk<Promise<OutputAreaScores[]>> => async (dispatch) => {
    const outputAreaScores: OutputAreaScores[] = [];

    try {
        const query = {
            dimensions: [
                "OA_RagMetrics.DestinationOA",
                "OA_RagMetrics.KPMGStoreCategory",
                "OA_RagMetrics.AreaHealth",
                "OA_RagMetrics.Catchment",
            ],
            filters: [{
                member: "OA_RagMetrics.DestinationOA",
                operator: "equals",
            }, {
                member: "OA_RagMetrics.KPMGStoreCategory",
                operator: "equals",
            }]
        };
        
        // @ts-ignore
        const resultSet = await dispatch(cubeLoadStoreCatchments(query, stores, "KPMGStoreCategory", "DestinationOA")) as unknown as ResultSet;
        resultSet.rawData().forEach(rawOutputAreaScore => {
            outputAreaScores.push({
                outputAreaCode: rawOutputAreaScore["OA_RagMetrics.DestinationOA"] ?? "",
                kpmgStoreCategory: rawOutputAreaScore["OA_RagMetrics.KPMGStoreCategory"] ?? "",
                areaHealthScore: rawOutputAreaScore["OA_RagMetrics.AreaHealth"] ?? 0,
                catchmentScore: rawOutputAreaScore["OA_RagMetrics.Catchment"] ?? 0,
            });
        });
    } catch (error) {
        stores.forEach(store => {
            outputAreaScores.push({
                outputAreaCode: store.outputAreaCode,
                kpmgStoreCategory: store.kpmgStoreCategory,
                areaHealthScore: 0,
                catchmentScore: 0
            });
        });
        dispatch(logError("Error loading OutputAreasScores.", error));
    }
    return outputAreaScores;
};

const getReferenceDate = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Sales.SumLineValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const dateString = resultSet.rawData()[0]["D_Date.Date"];
        dispatch(portfolioSlice.actions.setReferenceDateUtcIsoString(dateString));
        const dateUtc = dateUtils.dateUTC(dateString);
        const referenceDate = dateUtils.endTime(dateUtc);
        dispatch(portfolioSlice.actions.setReferenceDate(referenceDate));
    } catch (error) {
        dispatch(logError("Error loading ReferenceDate.", error));
        throw error;
    }
};

const getCostReferenceDate = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const salesReferenceDate = selectReferenceDateNew(state);

        const salesEndOfMonth = salesReferenceDate.endOf("day").equals(salesReferenceDate.endOf("month").endOf("day"))
            ? salesReferenceDate
            : salesReferenceDate.minus({months: 1}).endOf("month");

        const query = {
            dimensions: ["D_Date.Date"],
            order: [["D_Date.Date", "desc"]],
            filters: [{
                "member": "F_Cost.SumCostValue",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawResultSetData = resultSet.rawData();
        const costReferenceDate = rawResultSetData.length !== 0 ?
            DateTime.fromISO(rawResultSetData[0]["D_Date.Date"], { zone: "utc" }) :
            salesEndOfMonth;
        const costEndOfMonth = costReferenceDate.endOf("month");

        const overallReferenceDate = costEndOfMonth < salesEndOfMonth ? costEndOfMonth : salesEndOfMonth;
        dispatch(portfolioSlice.actions.setCostReferenceDate(overallReferenceDate));
    } catch (error) {
        dispatch(logError("Error loading CostReferenceDate.", error));
        throw error;
    }
};

const getPrimaryCategory = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            dimensions: ["ClientRegistration.PrimaryCategory"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const primaryCategory = resultSet.rawData()[0]["ClientRegistration.PrimaryCategory"];
        dispatch(portfolioSlice.actions.setPrimaryCategory(primaryCategory));
    } catch (error) {
        dispatch(logError("Error loading PrimaryCategory.", error));
        throw error;
    }
};

const getProductCategories = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            dimensions: ["D_ProductCategory.ProductCategory3"],
            filters: [{
                member: "D_ProductCategory.PK_ProductCategory",
                operator: "notEquals",
                values: ["-1"]
            }, {
                member: "D_Product.CurrentRecord",
                operator: "equals",
                values: ["Y"]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const productCategories = resultSet.rawData().map(item => item["D_ProductCategory.ProductCategory3"]);
        dispatch(portfolioSlice.actions.setProductCategories(productCategories));
    } catch (error) {
        dispatch(logError("Error loading ProductCategories.", error));
        throw error;
    }
};

const getNumberOfProductCategories = (): AppThunk => async (dispatch) => {
    try {
        const query = {
            measures: ["D_ProductCategory.CountProductCategories"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const numberOfProductCategories = resultSet.rawData()[0]["D_ProductCategory.CountProductCategories"];
        dispatch(portfolioSlice.actions.setNumberOfProductCategories(numberOfProductCategories));
    } catch (error) {
        dispatch(logError("Error loading NumberOfProductCategories.", error));
        throw error;
    }
};

export const cubeLoadStoreCatchments = (query: Query, stores: Store[], storeCategoryDimension: string, retailCentreDimension: string): AppThunk<Promise<ResultSet>> => async (dispatch) => {
    //Group Store OAs by category
    // @ts-ignore
    const allCategories = stores.map(item => item.kpmgStoreCategory);
    // @ts-ignore
    const uniqueCategories = [...new Set(allCategories)];

    const groupedRCs = [];
    for (let i in uniqueCategories) {
        const category = uniqueCategories[i];
        groupedRCs.push({});
        // @ts-ignore
        groupedRCs[i][storeCategoryDimension] = [uniqueCategories[i]];
        if (retailCentreDimension.includes("RetailCentre")) {
            // @ts-ignore
            groupedRCs[i][retailCentreDimension] = stores.filter(store => store.kpmgStoreCategory === category).map(store => store.retailCentreID.toString());
        } else {
            // @ts-ignore
            groupedRCs[i][retailCentreDimension] = stores.filter(store => store.kpmgStoreCategory === category).map(store => store.outputAreaCode);
        }
    }

    // @ts-ignore
    const relevantQueryFilters = query.filters?.filter(item => item.member.includes(storeCategoryDimension) || item.member.includes(retailCentreDimension));
    // @ts-ignore
    query.filters = query.filters?.filter(item => !item.member.includes(storeCategoryDimension) && !item.member.includes(retailCentreDimension));

    //const filterQuery = query;
    const queryFilters = relevantQueryFilters;
    // @ts-ignore

    query.filters.push({
        // @ts-ignore
        or: []
    });

    for (let i in groupedRCs) {
        //For each group of OAs add a new set of filters
        const newFilters = [];

        for (let value in groupedRCs[i]) {
            // @ts-ignore
            const relevantFilter = queryFilters?.find(item => item.member.includes(value));
            const filterValue = groupedRCs[i][value];
            if (relevantFilter) {
            const newFilter = {
                // @ts-ignore
                member: relevantFilter.member,
                // @ts-ignore
                operator: relevantFilter.operator,
                values: filterValue
            };
            newFilters.push(newFilter);
            }
        }

        // @ts-ignore
        query.filters[query.filters.length - 1].or.push({
            and: newFilters
        });
    }

    const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
    return resultSet;
};

export const getInsightData = (): AppThunk => async (dispatch) => {
    try {
        await dispatch(setupCube());
        dispatch(salesOperations.getSalesData());
        dispatch(performanceDriversOperations.getPerformanceDriversData());
        dispatch(profitOperations.getProfitData());
        dispatch(areaHealthOperations.getAreaHealthData());
        dispatch(competitionOperations.getCompetitionData());
        dispatch(catchmentOperations.getCatchmentData());
    } catch (error) {
        dispatch(logError("Error loading insight data.", error));
    }
};

export const selectSubchaptersIds = (state: RootState) => {
    return state.customer.insights.portfolio.root.subchaptersIds;
};

export const selectStores = (state: RootState): Store[] => {
    return state.customer.insights.portfolio.root.stores;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.insights.portfolio.root.referenceDate;
};

export const selectCostReferenceDate = (state: RootState) => {
    return state.customer.insights.portfolio.root.costReferenceDate;
};

export const selectReferenceDateNew = (state: RootState) => {
    const referenceDateUtcIsoString = state.customer.insights.portfolio.root.referenceDateUtcIsoString;
    if (!referenceDateUtcIsoString) {
        return DateTime.fromMillis(0, { zone: "utc" });
    }
    return DateTime.fromISO(referenceDateUtcIsoString, { zone: "utc" });
};

export const selectPrimaryCategory = (state: RootState) => {
    return state.customer.insights.portfolio.root.primaryCategory;
};

export const selectProductCategories = (state: RootState) => {
    return state.customer.insights.portfolio.root.productCategories;
};

export const selectNumberOfProductCategories = (state: RootState) => {
    return state.customer.insights.portfolio.root.numberOfProductCategories;
};

export const selectRegions = createSelector(
    selectStores,
    (stores) => {
        const regions = stores
            .map(store => store.region)
            .sort((regionA, regionB) => stringSortExpression(regionA, regionB, SortDirection.ASC));
        return Array.from(new Set(regions));
    }
);

export const selectStore = (state: RootState) => {
    return state.customer.insights.portfolio.root.store;
};

export const selectComparator = (state: RootState) => {
    return state.customer.insights.portfolio.root.comparator;
};

export const selectIsSetupComplete = createSelector(
    selectStore,
    selectComparator,
    (store, comparator) => {
        return !!store && !!comparator;
    }
);

export default portfolioSlice;
