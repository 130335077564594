import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectApplicationInsightsConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { RootState } from "store";

import { LoggerService } from "./loggerService";

enum Severity {
    Debug,
    Information,
    Warning,
    Error,
    Critical
}

interface LoggerState {
    loggerService?: LoggerService
}

const initialState: LoggerState = {
    loggerService: undefined
};

const loggerSlice = createSlice({
    name: "helpers/logger",
    initialState,
    reducers: {
        setLoggerService: (state, action: PayloadAction<LoggerService>) => {
            state.loggerService = action.payload;
        }
    }
});

export const setupLogger = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const applicationInsightsConfiguration = selectApplicationInsightsConfiguration(state);
    const applicationInsightsInstrumentationKey = applicationInsightsConfiguration.instrumentationKey;
    const loggerService = new LoggerService(applicationInsightsInstrumentationKey);
    dispatch(loggerSlice.actions.setLoggerService(loggerService));
};

const log = (severity: Severity, message: string, payload: any = {}): AppThunk => (dispatch, getState) => {
    const state = getState();
    const userInfo = selectUserInfo(state);
    const customDimensions = {
        userId: userInfo.id,
        accountId: userInfo.accountId,
        payload
    };
    const loggerService = selectLoggerService(state);
    switch (severity) {
        case Severity.Critical:
            loggerService?.critical(message, customDimensions);
            break;
        case Severity.Error:
            loggerService?.error(message, customDimensions);
            break;
        case Severity.Warning:
            loggerService?.warning(message, customDimensions);
            break;
        case Severity.Information:
            loggerService?.information(message, customDimensions);
            break;
        case Severity.Debug:
        default:
            loggerService?.debug(message, customDimensions);

    }
};

export const logDebug = (message: string, payload: any = {}): AppThunk => (dispatch) => {
    dispatch(log(Severity.Debug, message, payload));
};

export const logInformation = (message: string, payload: any = {}): AppThunk => (dispatch) => {
    dispatch(log(Severity.Information, message, payload));
};

export const logWarning = (message: string, payload: any = {}): AppThunk => (dispatch) => {
    dispatch(log(Severity.Warning, message, payload));
};

export const logError = (message: string, payload: any = {}): AppThunk => (dispatch) => {
    dispatch(log(Severity.Error, message, payload));
};

export const logCritical = (message: string, payload: any = {}): AppThunk => (dispatch) => {
    dispatch(log(Severity.Critical, message, payload));
};

const selectLoggerService = (state: RootState): LoggerService | undefined => {
    return state.helpers.logger.loggerService;
};

export default loggerSlice;
