import { createSelector } from "reselect";
import { median } from "mathjs";

const localAreaCompetition = (state) => {
    return state.customer.insights.portfolio.competition.localAreaCompetition.localAreaCompetition;
};

const distanceToHotspotKpi = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const kpi = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            value: 0,
            percentageDifference: 0
        };

        const selectedStoreData = localAreaCompetition.selectedStore;
        if (kpi.loading || kpi.error || !selectedStoreData) {
            return kpi;
        }

        const comparatorStoresData = localAreaCompetition.comparatorStores;

        //Distance to Hotspot
        const selectedHotspotDistance = selectedStoreData?.distanceToHotspot;
        const compHotspotDistance = (comparatorStoresData.length === 0) ? 0 : median(comparatorStoresData.map(item => item.distanceToHotspot));
        const percentageDifference = (compHotspotDistance === 0) ? 100 : (100 * (selectedHotspotDistance - compHotspotDistance) / compHotspotDistance);

        kpi.value = selectedHotspotDistance ?? 0;
        kpi.percentageDifference = percentageDifference ?? 0;

        return kpi;
    }
);

const numberOfDirectComp = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const kpi = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            value: 0,
            percentageDifference: 0
        };

        const selectedStoreData = localAreaCompetition.selectedStore;
        if (kpi.loading || kpi.error || !selectedStoreData) {
            return kpi;
        }

        const comparatorStoresData = localAreaCompetition.comparatorStores;

        //Distance to Hotspot
        const selectedDirectCompetitorCount = selectedStoreData?.directCompetitorCount;
        const comparatorDirectCompetitorCount = (comparatorStoresData.length === 0) ? 0 : median(comparatorStoresData.map(item => item.directCompetitorCount));
        const percentageDifference =
            (comparatorDirectCompetitorCount === 0) ? 100 : (100 * (selectedDirectCompetitorCount - comparatorDirectCompetitorCount) / comparatorDirectCompetitorCount);

        kpi.value = selectedDirectCompetitorCount ?? 0;
        kpi.percentageDifference = percentageDifference ?? 0;

        return kpi;
    }
);

const numberOfComp = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const kpi = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            value: 0,
            percentageDifference: 0
        };

        const selectedStoreData = localAreaCompetition.selectedStore;
        if (kpi.loading || kpi.error || !selectedStoreData) {
            return kpi;
        }

        const comparatorStoresData = localAreaCompetition.comparatorStores;

        //Distance to Hotspot
        const selectedCompetitorCount = selectedStoreData?.competitorCount;
        const comparatorCompetitorCount = (comparatorStoresData.length === 0) ? 0 : median(comparatorStoresData.map(item => item.competitorCount));
        const percentageDifference = (comparatorCompetitorCount === 0) ? 100 : (100 * (selectedCompetitorCount - comparatorCompetitorCount) / comparatorCompetitorCount);

        kpi.value = selectedCompetitorCount ?? 0;
        kpi.percentageDifference = percentageDifference ?? 0;

        return kpi;
    }
);

const storePositioningHotspotMap = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const values = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            stores: [],
            hotspot: {
                latitude: 0,
                longitude: 0
            }
        };

        const selectedStore = localAreaCompetition.selectedStore;
        if (values.loading || values.error || !selectedStore) {
            return values;
        }

        const otherClientStores = localAreaCompetition.otherClientStores;
        const competitorStores = selectedStore.competitors ?? [];

        values.stores = [selectedStore].concat(otherClientStores, competitorStores);

        for (let i in values.stores) {
            values.stores[i].id = i;
        }

        values.hotspot = selectedStore.hotspot ?? { latitude: 0, longitude: 0 };

        return values;
    }
);

const distanceToHotspotRag = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const rag = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            id: "distance-to-hotspot",
            label: "Distance to hotspot",
            status: "info",
            value: ""
        };

        const selectedStore = localAreaCompetition.selectedStore;
        if (rag.loading || rag.error || !selectedStore) {
            return rag;
        }

        const selectedDistanceToHotspot = selectedStore?.distanceToHotspot;
        const competitorStores = localAreaCompetition.directCompetitorStores;

        //Error RAG statuses:
        if (localAreaCompetition.directCompetitorsNotSet) {
            rag.status = "noData";
            rag.value = `This indicator isn't available because it requires you to set your company's direct competitors. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.`;
            return rag;
        } else if (competitorStores.length === 0) {
            // AMBER
            rag.status = "warning";
            rag.value = `No direct competitors in the local area of ${selectedStore.name}`;
            return rag;
        }

        //Comparison values are median of all Direct Competitors within Local Area (5k Radius)
        const competitorDistance = competitorStores.map(item => item.distanceToHotspot);
        let closerCompetitors = 0, furtherCompetitors = 0;

        for (let i in competitorDistance) {
            if (competitorDistance[i] < selectedDistanceToHotspot) {
                closerCompetitors++;
            } else if (competitorDistance[i] > selectedDistanceToHotspot) {
                furtherCompetitors++;
            }
        }

        const percentCloser = 100 * (closerCompetitors / competitorDistance.length);
        const percentFurther = 100 * (furtherCompetitors / competitorDistance.length);

        if (percentFurther >= 75) {
            // GREEN
            rag.value = `Majority of direct competitors are further from the hotspot of the local area than ${selectedStore.name}`;
            rag.status = "success";
        } else if (percentCloser >= 75) {
            // RED
            rag.value = `Majority of direct competitors are closer to the hotspot of the local area than ${selectedStore.name}`;
            rag.status = "error";
        } else {
            // AMBER
            rag.value = `${selectedStore.name} is broadly in line with the distance from the hotspot as other direct competitors in the local area `;
            rag.status = "warning";
        }

        return rag;
    }
);

const sizeRelativeToDirectCompetitors = createSelector(
    localAreaCompetition,
    (localAreaCompetition) => {
        const rag = {
            loading: localAreaCompetition.loading,
            error: localAreaCompetition.error,
            id: "size-relative-to-direct-competitors",
            label: "Size relative to direct competitors",
            status: "info",
            value: ""
        };

        const selectedStore = localAreaCompetition.selectedStore;
        if (rag.loading || rag.error || !selectedStore) {
            return rag;
        }

        const selectedStoreSqft = selectedStore.sizeInSqft;

        const competitorStores = localAreaCompetition.directCompetitorStores;

        //Error RAG statuses:
        if (localAreaCompetition.directCompetitorsNotSet) {
            rag.status = "noData";
            rag.value = `This indicator isn't available because it requires you to set your company's direct competitors. To evaluate this insight, someone with permission to upload data from your company will need to edit/upload the Company dataset and refresh your company's Analytics.`;
            return rag;
        } else if (competitorStores.length === 0) {
            // AMBER
            rag.status = "warning";
            rag.value = `No direct competitors in the local area of ${selectedStore.name}`;
            return rag;
        }

        //Comparison values are median of all Direct Competitors within Local Area (5k Radius)
        const competitorSqft = competitorStores.map(item => item.sizeInSqft).filter(item => item);

        let smallerCompetitors = 0, largerCompetitors = 0;

        for (let i in competitorSqft) {
            if (competitorSqft[i] < selectedStoreSqft) {
                smallerCompetitors++;
            } else if (competitorSqft[i] > selectedStoreSqft) {
                largerCompetitors++;
            }
        }

        const percentSmaller = 100 * (smallerCompetitors / competitorSqft.length);
        const percentLarger = 100 * (largerCompetitors / competitorSqft.length);

        if (percentSmaller >= 75) {
            // GREEN
            rag.value = `Majority of direct competitors are smaller in size than ${selectedStore.name}`;
            rag.status = "success";
        } else if (percentLarger >= 75) {
            // RED
            rag.value = `Majority of direct competitors are larger in size than ${selectedStore.name}`;
            rag.status = "error";
        } else {
            // AMBER
            rag.value = `Majority of direct competitors are broadly the same size as ${selectedStore.name}`;
            rag.status = "warning";
        }

        return rag;
    }
);

const selectors = {
    localAreaCompetition,
    distanceToHotspotKpi,
    numberOfDirectComp,
    numberOfComp,
    storePositioningHotspotMap,
    distanceToHotspotRag,
    sizeRelativeToDirectCompetitors
};

export default selectors;
