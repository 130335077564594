import React from "react";
import Carousel from "react-material-ui-carousel";

import { Box, Card, CardActions, CardContent, CardMedia, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

import quoteIcon from "assets/images/quote-icon.svg";

const StyledQuoteCard = withStyles(theme => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2)
        }
    }
}))(Card);

const DashProvidedUsWithDetailedInsight: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const slides = [{
        title: "Location analytics",
        subtitle: "Find where to open your next store with granular geospatial analysis",
        image: "/content/landing/bookRetailAnalyticsDemo/location.png",
        alt: "performance"
    }, {
        title: "Portfolio Optimisation",
        subtitle: "Analyse benchmark, forecast site performance to strategically optimise your portfolio",
        image: "/content/landing/bookRetailAnalyticsDemo/portfolio.png",
        alt: "portfolio"
    }, {
        title: "Revenue forecasting",
        subtitle: "Predict annual revenue utilising Dash's advanced 90% accuracy projections",
        image: "/content/landing/bookRetailAnalyticsDemo/performance.png",
        alt: "location"
    }];

    return (
        // @ts-ignore
        <PageSection>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12}>
                    <Carousel
                        autoPlay={true}
                        stopAutoPlayOnHover={false}
                        interval={3750}
                        animation="slide"
                        navButtonsAlwaysInvisible={true}
                    >
                        {slides.map((slide, index) =>
                            // @ts-ignore
                            <Box key={index} align="center">
                                <Typography variant={isMobile ? "h5" : "h3"} component="div" align="center">
                                    {slide.title}
                                </Typography>
                                <br />
                                <Typography variant={isMobile ? "subtitle1" : "h4"} component="div" align="center">
                                    {slide.subtitle}
                                </Typography>
                                <br />
                                <br />
                                <Card elevation={0}>
                                    <CardMedia component="img" image={slide.image} alt={slide.alt} />
                                </Card>
                            </Box>
                        )}
                    </Carousel>
                </Grid>
                <Grid item xs={12}>
                    <StyledQuoteCard elevation={0}>
                        <CardContent>
                            <Box width={42} height={35}>
                                <img src={quoteIcon} alt="quote icon" />
                            </Box>
                            <br />
                            <Typography variant={isMobile ? "h4" : "h3"} component="div">
                                Dash provided us with detailed insight into the viability of each store at speed... to
                                gather that level of detail ourselves would likely have resulted in a missed
                                opportunity.
                            </Typography>
                            <br />
                        </CardContent>
                        <CardActions>
                            <Typography variant={isMobile ? "body2" : "subtitle1"} component="div">
                                Property Director, Discount Retailer
                            </Typography>
                        </CardActions>
                    </StyledQuoteCard>
                </Grid>
            </Grid>
        </PageSection>
    );
};

export default DashProvidedUsWithDetailedInsight;
