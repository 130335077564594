import React from "react";

import { Box, Typography } from "@material-ui/core";

const NoData: React.FC = () => {
    return (
        // @ts-ignore
        <Box align="center" color="text.disabled">
            <Typography variant="overline">No data to display</Typography>
        </Box>
    );
};

export default NoData;
