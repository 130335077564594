import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import PageNotFound from "./PageNotFound";

import BookRetailAnalyticsDemo from "./bookRetailAnalyticsDemo/BookRetailAnalyticsDemo";
import ContactUs from "./contactUs/ContactUs";
import Features from "./features/Features";
import FrequentlyAskedQuestions from "./frequentlyAskedQuestions/FrequentlyAskedQuestions";
import Home from "./home/Home";
import HowDashWorks from "./howDashWorks/HowDashWorks";
import InsightsAndTools from "./insightsAndTools/InsightsAndTools";
import Login from "./login/Login";
import MeetTheTeam from "./meetTheTeam/MeetTheTeam";
import OurStory from "./ourStory/OurStory";
import Registration from "./registration/Registration";
import TermsOfUse from "./termsOfUse/TermsOfUse";

import { useAppDispatch } from "store";
import { trackPageView } from "modules/helpers/webAnalytics/webAnalyticsSlice";

const Router: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    React.useEffect(() => {
        dispatch(trackPageView(location.pathname, location.search));
    }, [location, dispatch]);

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>
            <Route path="/book-retail-analytics-demo">
                <BookRetailAnalyticsDemo />
            </Route>
            <Route path="/contact-us">
                <ContactUs />
            </Route>
            <Route path="/features">
                <Features />
            </Route>
            <Route path="/frequently-asked-questions">
                <FrequentlyAskedQuestions />
            </Route>
            <Route path="/home">
                <Home />
            </Route>
            <Route path="/how-dash-works">
                <HowDashWorks />
            </Route>
            <Route path="/insights-and-tools">
                <InsightsAndTools />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/meet-the-team">
                <MeetTheTeam />
            </Route>
            <Route path="/our-story">
                <OurStory />
            </Route>
            <Route path="/registration">
                <Registration />
            </Route>
            <Route path="/terms-of-use">
                <TermsOfUse />
            </Route>
            <Route>
                <PageNotFound />
            </Route>
        </Switch>
    );
};

export default Router;
