const GET_GROSS_REVENUE_YTD_REQUEST = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_GROSS_REVENUE_YTD_REQUEST";
const GET_GROSS_REVENUE_YTD_SUCCESS = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_GROSS_REVENUE_YTD_SUCCESS";
const GET_GROSS_REVENUE_YTD_FAILURE = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_GROSS_REVENUE_YTD_FAILURE";
const GET_CONTRIBUTION_YTD_REQUEST = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_CONTRIBUTION_YTD_REQUEST";
const GET_CONTRIBUTION_YTD_SUCCESS = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_CONTRIBUTION_YTD_SUCCESS";
const GET_CONTRIBUTION_YTD_FAILURE = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_CONTRIBUTION_YTD_FAILURE";
const GET_FORECAST_SALES_YTG_REQUEST = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_FORECAST_SALES_YTG_REQUEST";
const GET_FORECAST_SALES_YTG_SUCCESS = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_FORECAST_SALES_YTG_SUCCESS";
const GET_FORECAST_SALES_YTG_FAILURE = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_FORECAST_SALES_YTG_FAILURE";
const GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_REQUEST = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_REQUEST";
const GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_SUCCESS = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_SUCCESS";
const GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_FAILURE = "spark/customer/insights/performance/overview/keyPerformanceIndicators/GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_FAILURE";

const types = {
    GET_GROSS_REVENUE_YTD_REQUEST,
    GET_GROSS_REVENUE_YTD_SUCCESS,
    GET_GROSS_REVENUE_YTD_FAILURE,
    GET_CONTRIBUTION_YTD_REQUEST,
    GET_CONTRIBUTION_YTD_SUCCESS,
    GET_CONTRIBUTION_YTD_FAILURE,
    GET_FORECAST_SALES_YTG_REQUEST,
    GET_FORECAST_SALES_YTG_SUCCESS,
    GET_FORECAST_SALES_YTG_FAILURE,
    GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_REQUEST,
    GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_SUCCESS,
    GET_YTD_SALES_PLUS_YTG_SALES_VS_BUDGETED_SALES_FAILURE
};

export default types;
