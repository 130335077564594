import React from "react";

import { useAppSelector } from "store";
import { selectStore } from "modules/customer/insights/portfolio/portfolioSlice";

const FootfallSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Understand the level of footfall in your {store?.name} store's catchment area and see how it has
            changed over time. Assess the best spot for footfall and your {store?.name} store's positioning
            relative to it. The footfall values used throughout Dash do not show the exact number of people passing
            through an area. Instead, these values represent a relative level of footfall, with each output area having
            a footfall value between 0 and 100. A score close to 0 indicates a poor level of footfall, whereas a score
            close to 100 indicates a very high level of footfall.
        </>
    );
};

export default FootfallSubtitle;
