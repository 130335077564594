import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const ProfitSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Evaluate the profitability of your {store?.name} store compared to {comparator?.name}, and any
            discrepancies between the two. Using the cost data you have provided, this section shows you where
            your {store?.name} store is lean and where it is bloated.
        </>
    );
};

export default ProfitSubtitle;
