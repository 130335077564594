import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { DemographicIndicatorMeasure } from "modules/customer/insights/portfolioNew/catchment/catchmentDemographics";
import {
    selectIsLoading,
    selectHasErrors,
    selectDemographicIndicatorMeasure
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";
import mathUtils from "utils/mathUtils";
import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface DemographicIndicatorChartProps {
    title: string,
    categories: string[],
    selectedStoreValues: number[],
    comparatorValues: number[],
    selectedStoreTotal: number,
    comparatorTotal: number
}

const DemographicIndicatorChart: React.FC<DemographicIndicatorChartProps> = (props) => {
    const { title, categories, selectedStoreValues, comparatorValues, selectedStoreTotal, comparatorTotal } = props;
    const colours = useColourPalette();
    const selectedStore = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const measure = useAppSelector(selectDemographicIndicatorMeasure);

    const selectedStoreSeries = selectedStoreValues.map((value) => ({
        y: measure === DemographicIndicatorMeasure.PercentageOfPopulation
            ? mathUtils.safePercentage(value, selectedStoreTotal)
            : value / 1000,
        custom: {
            absoluteValue: value,
            percentageValue: mathUtils.safePercentage(value, selectedStoreTotal)
        }
    }));
    const comparatorSeries = comparatorValues.map((value) => ({
        y: measure === DemographicIndicatorMeasure.PercentageOfPopulation
            ? mathUtils.safePercentage(value, comparatorTotal)
            : value / 1000,
        custom: {
            absoluteValue: value,
            percentageValue: mathUtils.safePercentage(value, comparatorTotal)
        }
    }));

    const yAxisTitle = measure === DemographicIndicatorMeasure.PercentageOfPopulation
        ? "% of population"
        : "Population (000s)";

    const options: Highcharts.Options = {
        xAxis: {
            categories: categories
        },
        yAxis: {
            labels: {
                enabled: true
            },
            title: {
                text: yAxisTitle
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                }
            }
        },
        tooltip: {
            formatter() {
                const value = this.point.options.custom?.absoluteValue;
                const percentage = this.point.options.custom?.percentageValue;
                const captions = measure === DemographicIndicatorMeasure.PercentageOfPopulation
                    ? ["% of total population", "Population"]
                    : ["Population", "% of total population"];
                const values = measure === DemographicIndicatorMeasure.PercentageOfPopulation
                    ? [numberFormatter.toPercentage(percentage), numberFormatter.toTruncatedInteger(value, 2)]
                    : [numberFormatter.toTruncatedInteger(value, 2), numberFormatter.toPercentage(percentage)];

                return `<table>${stringUtils.tooltipHTML(captions, {
                    values,
                    header: `${this.x}`,
                    valueFormatting: `color:${this.series.color}`
                })}</table>`;
            },
            useHTML: true
        },
        series: [{
            name: selectedStore?.name,
            data: selectedStoreSeries,
            color: colours.comparators[0],
            type: "bar"
        }, {
            name: comparator?.name,
            data: comparatorSeries,
            color: colours.comparators[1],
            type: "bar"
        }]
    };

    return (
        <Box data-cy="demographic-indicators-in-catchment-area">
            <Typography variant="h6" component="div">
                {title}
            </Typography>
            <Bar loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default DemographicIndicatorChart;
