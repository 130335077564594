import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";

import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";

const getContributionPerformanceVsBudget = () => async (dispatch, getState) => {
    dispatch(actions.getContributionPerformanceVsBudgetRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(currentDate, fyStart);
        const financialYearToGoStartDate = dateUtils.nextDay(currentDate);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);

        /* Actual Contribution FYTD */
        const queryContributionFYTD = {
            measures: ["F_SalesForecast.SumContribution"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, currentDate]
            }],
            filters: [{
                member: "F_SalesForecast.ForecastFlag",
                operator: "equals",
                values: ["0"]
            }]
        };
        const resultSetContributionFYTD = await dispatch(cubeLoad(queryContributionFYTD));
        const contributionFYTD = resultSetContributionFYTD.loadResponses[0].data[0]["F_SalesForecast.SumContribution"];

        /* Forecast Contribution FYTG */
        const queryContributionFYTG = {
            measures: ["F_SalesForecast.SumContribution"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearToGoStartDate, financialYearEndDate]
            }],
            filters: [{
                member: "F_SalesForecast.ForecastFlag",
                operator: "equals",
                values: ["1"]
            }]
        };
        const resultSetContributionFYTG = await dispatch(cubeLoad(queryContributionFYTG));
        const contributionFYTG = resultSetContributionFYTG.loadResponses[0].data[0]["F_SalesForecast.SumContribution"];

        /* Budget contribution FY */
        const budgetContributionQuery = {
            measures: ["F_Budget.SumBudgetContribution"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate]
            }],
            dimensions: ["D_Date.FY"]
        };
        const budgetResultSet = await dispatch(cubeLoad(budgetContributionQuery));

        let budgetFY = 0;
        let FYlabel = "";
        if (budgetResultSet.loadResponses[0].data.length !== 0) {
            budgetFY = budgetResultSet.loadResponses[0].data[0]["F_Budget.SumBudgetContribution"];
            const FY = budgetResultSet.loadResponses[0].data[0]["D_Date.FY"];
            FYlabel = "FY" + FY;
        }

        dispatch(actions.getContributionPerformanceVsBudgetSuccess(contributionFYTD, contributionFYTG, budgetFY, FYlabel));
    }
    catch (error) {
        dispatch(actions.getContributionPerformanceVsBudgetFailure());
        dispatch(logError("Error loading ContributionPerformanceVsBudget.", error));
    }
};

const operations = {
    getContributionPerformanceVsBudget
};

export default operations;
