import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import ChartBase from "./ChartBase";

const Column = (props) => {
    const { dataCy, loading, error, options: customOptions } = props;
    const staticOptions = {
        chart: {
            type: "column"
        },
        xAxis: {
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            endOnTick: true,
            showLastLabel: true,
            lineWidth: 0
        },
        yAxis: {
            gridLineWidth: 1,
            minorGridLineWidth: 0,
            title: false,
            visible: true,
            plotLines: [{
                value: 0,
                color: '#FFFFFF',
                width: 2
            }]
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    size: 10
                },
                borderWidth: 0,
                borderRadiusTopLeft: '20%',
                borderRadiusTopRight: '20%'
            },
            bar: {
                maxPointWidth: 34,
                minPointWidth: 16
            },
            column: {
                maxPointWidth: 34,
                minPointWidth: 16
            }
        },
        legend: {
            enabled: true
        }
    };
    const options = _.merge({}, staticOptions, customOptions);

    return (
        <ChartBase loading={loading} error={error} options={options} dataCy={dataCy} />
    );
};

Column.propTypes = {
    dataCy: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    options: PropTypes.object.isRequired
};

Column.defaultProps = {
    dataCy: "column-chart",
    loading: false,
    error: false
};

export default Column;
