import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import NoData from "components/NoData";

import DeleteDatasetFile from "./DeleteDatasetFile";

import { DatasetFile, DatasetFileStatus } from "modules/admin/accounts/datasetFiles/datasetFilesSlice";

const StyledTableContainer = withStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}))(TableContainer);

const StyledTableCell = withStyles(() => ({
    root: {
        whiteSpace: "nowrap"
    }
}))(TableCell);

interface DatasetFilesTableProps {
    accountId: string,
    datasetFiles: DatasetFile[]
}

const DatasetFilesTable: React.FC<DatasetFilesTableProps> = (props) => {
    const { accountId, datasetFiles } = props;

    if (datasetFiles.length === 0) {
        return (<NoData />);
    }

    return (
        // @ts-ignore
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Id
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Name
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Uploaded by
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Uploaded at
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box color="text.disabled">
                                Status
                            </Box>
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datasetFiles.map((datasetFile) => (
                        <TableRow key={datasetFile.id}>
                            <StyledTableCell>
                                {datasetFile.id}
                            </StyledTableCell>
                            <StyledTableCell>
                                {datasetFile.fileName}
                            </StyledTableCell>
                            <StyledTableCell>
                                {datasetFile.uploadedBy}
                            </StyledTableCell>
                            <StyledTableCell>
                                {datasetFile.uploadedAt.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell>
                                {datasetFile.status}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {datasetFile.status === DatasetFileStatus.Submitted && (
                                    <DeleteDatasetFile accountId={accountId} datasetFileId={datasetFile.id} />
                                )}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default DatasetFilesTable;
