import { combineReducers } from "redux";

import grossProfitMargin from "./grossProfitMargin";
import rankedGrossProfitMargin from "./rankedGrossProfitMargin";
import netProfit from "./netProfit";

const reducer = combineReducers({
    grossProfitMargin,
    rankedGrossProfitMargin,
    netProfit
});

export default reducer;
