import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import { DateTime } from "luxon";

export class MonthlyRevenue {
    public readonly storeId: number;
    public readonly date: DateTime;
    public readonly revenue: number;

    constructor(
        storeId: number,
        date: DateTime,
        revenue: number
    ) {
        this.storeId = storeId;
        this.date = date;
        this.revenue = revenue;
    }
}

export const loadMonthlyRevenue = (
    costReferenceDate: DateTime,
    storeId?: number,
    similarStoresIds?: number[]
): AppThunk<Promise<MonthlyRevenue[]>> => async (dispatch) => {
    try {
        if (!costReferenceDate || !storeId || !similarStoresIds) {
            return [];
        }

        const priorSixtyMonthsToReferenceDate = costReferenceDate
            .minus({ months: 60 })
            .plus({ days: 1 })
            .startOf("day");

        const storeAndSimilarStoreIds = [storeId].concat(...similarStoresIds).map(String);

        const salesQuery = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorSixtyMonthsToReferenceDate, costReferenceDate.endOf('day')]
            }],
            filters: [{
                member: "D_Store.PK_Store",
                operator: "equals",
                values: storeAndSimilarStoreIds
            }],
            dimensions: [
                "D_Store.PK_Store",
                "D_Date.MonthStartDate"
            ],
            order: [["D_Date.MonthStartDate", "asc"]]
        };

        const salesPromise = dispatch(cubeLoad(salesQuery));

        const result = await Promise.all([salesPromise]) as unknown as ResultSet[];

        const salesRawData = result[0].rawData();

        return salesRawData.map(salesRow => {
            return new MonthlyRevenue(
                salesRow["D_Store.PK_Store"],
                DateTime.fromISO(salesRow["D_Date.MonthStartDate"], { zone: "utc" }),
                salesRow["F_Sales.SumLineValue"]
            );
        });
    } catch (error) {
        dispatch(logError("Error loading MonthlyRevenue.", error));
        throw error;
    }
};
