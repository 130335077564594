import React from "react";
import { Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import useColourPalette from "components/visuals/useColourPalette";

import { selectors } from "modules/customer/insights/portfolio/catchment/customerProfiles";

const PenPortraitsSearch = () => {
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const penPortraitsSearch = useSelector(state => selectors.penPortraitsSearch(state));
    const { penPortraits } = penPortraitsSearch;
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
                <Autocomplete
                    value={value}
                    size="small"
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={penPortraits}
                    getOptionLabel={(option) => `${option.penPortraitName} (${option.penPortraitLevel})`}
                    renderInput={(params) => (
                        <TextField {...params} label="Search definition of customer profiles" variant="outlined" />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={7}>
                <Card elevation={0}>
                    <CardHeader
                        disableTypography
                        style={{
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            color: theme.palette.common.black,
                            backgroundColor: colourPalette.categorical[7]
                        }}
                        title={
                            value ? (
                                <Typography variant="h6" component="div">
                                    {value.penPortraitLevel}&nbsp;&nbsp;<b>{value.penPortraitName}</b>
                                </Typography>
                            ) : (
                                <Typography variant="h6" component="div">
                                    Supergroup, group or subgroup
                                </Typography>
                            )
                        }
                    />
                    <CardContent>
                        <Typography variant="subtitle1" component="div">
                            {value ? value.penPortraitDescription : "Select a definition..."}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PenPortraitsSearch;
