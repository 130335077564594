import types from "./types";

const getLocalAreaCompetitionRequest = () => ({
    type: types.GET_LOCAL_AREA_COMPETITION_REQUEST
});

const getLocalAreaCompetitionSuccess = (selectedStore, comparatorStores, directCompetitorStores, otherClientStores, directCompetitorsNotSet) => ({
    type: types.GET_LOCAL_AREA_COMPETITION_SUCCESS,
    payload: {
        selectedStore,
        comparatorStores,
        directCompetitorStores,
        otherClientStores,
        directCompetitorsNotSet
    }
});

const getLocalAreaCompetitionFailure = () => ({
    type: types.GET_LOCAL_AREA_COMPETITION_FAILURE
});


const actions = {
    getLocalAreaCompetitionRequest,
    getLocalAreaCompetitionSuccess,
    getLocalAreaCompetitionFailure
};

export default actions;
