import React from "react";

import { Grid } from "@material-ui/core";

import YoYRawNetStoreOpenings from "./YoYRawNetStoreOpenings";
import NumberOfStoresInLocalArea from "./NumberOfStoresInLocalArea";
import NetOpenVsClosuresOverTime from "./NetOpenVsClosuresOverTime";
import AverageStoreLifetime from "./AverageStoreLifespan";
import ShortTermNetOpenings from "./ShortTermNetOpenings";
import LongTermNetOpenings from "./LongTermNetOpenings";

const OpeningsAndClosures: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <NumberOfStoresInLocalArea />
            </Grid>
            <Grid item xs={12} md={4}>
                <YoYRawNetStoreOpenings />
            </Grid>
            <Grid item xs={12} md={4}>
                <AverageStoreLifetime />
            </Grid>
            <Grid item xs={12}>
                <NetOpenVsClosuresOverTime />
            </Grid>
            <Grid item xs={12}>
                <ShortTermNetOpenings />
            </Grid>
            <Grid item xs={12}>
                <LongTermNetOpenings />
            </Grid>
        </Grid>
    );
};

export default OpeningsAndClosures;
