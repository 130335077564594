import React from "react";
import { Grid } from "@material-ui/core";

import SalesYTD from "./SalesYTD";
import TotalSalesLatestFullMonth from "./TotalSalesLatestFullMonth";
import AvgSalesLatestFullMonth from "./AvgSalesLatestFullMonth";
import SalesHistory from "./SalesHistory";
import HistoricOverallCompanySalesTrend from "./HistoricOverallCompanySalesTrend";
import HistoricShortTermCompanySalesTrend from "./HistoricShortTermCompanySalesTrend";

const RevenueOverTime = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <SalesYTD />
            </Grid>
            <Grid item xs={12} md={4}>
                <TotalSalesLatestFullMonth />
            </Grid>
            <Grid item xs={12} md={4}>
                <AvgSalesLatestFullMonth />
            </Grid>
            <Grid item xs={12}>
                <SalesHistory />
            </Grid>
            <Grid item xs={12}>
                <HistoricOverallCompanySalesTrend />
            </Grid>
            <Grid item xs={12}>
                <HistoricShortTermCompanySalesTrend />
            </Grid>
        </Grid>
    );
};

export default RevenueOverTime;
