import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Analyse your {store?.name} store's revenue growth last year and assess its position compared to {comparator?.name}.
            Understand whether the growth was behind, in line with, or ahead of the pack to inform your strategy for your {store?.name} store.
        </>
    );
};

export default Subtitle;
