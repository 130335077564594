import React from "react";

import { Box } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import OutputAreasMap, { pointerInfoEmpty } from "components/visuals/maps/OutputAreasMap";
import SelectedStoreMarker from "components/visuals/maps/SelectedStoreMarker";

import Legend from "./Legend";

import { selectStore, selectCatchmentCustomerProfiles } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const colours = ["rgba(0,0,0,0)", ...colourPalette.categorical];
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const selectedStore = useAppSelector(selectStore);
    const customerProfiles = useAppSelector(selectCatchmentCustomerProfiles);
    const [hoverInfo, setHoverInfo] = React.useState(pointerInfoEmpty);
    const hoveredCustomerProfile = customerProfiles.data.find(customerProfile => customerProfile.outputAreaCode === hoverInfo.outputAreaCode);

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: selectedStore?.latitude ?? 0,
        longitude: selectedStore?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const mapOutputAreas = React.useMemo(() => {
        return customerProfiles.data.map(customerProfile => ({
            code: customerProfile.outputAreaCode,
            colourGroup: customerProfile.supergroupCode,
            opacity: customerProfile.probability
        }));
    }, [customerProfiles]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: selectedStore?.latitude ?? 0,
                longitude: selectedStore?.longitude ?? 0
            };
        });
    }, [selectedStore]);

    return (
        <Box data-cy="catchment-area-pen-portrait-classification">
            <OutputAreasMap
                loading={customerProfiles.isLoading}
                error={customerProfiles.hasErrors}
                title={"Catchment area customer profiles with percentage likelihood of visiting"}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas}
                colours={colours}
                enableHover={true}
                onHover={setHoverInfo}
                enableClick={false}
                downloadData={customerProfiles.data}
            >
                <SelectedStoreMarker latitude={selectedStore?.latitude ?? 0} longitude={selectedStore?.longitude ?? 0} />
                <Legend catchmentCustomerProfile={hoveredCustomerProfile} />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
