import { createSelector } from "reselect";

import { selectors as revenueOverTimeSelectors } from "modules/customer/insights/performance/sales/revenueOverTime";
import { selectors as revenueGrowthBreakdownSelectors } from "modules/customer/insights/performance/sales/revenueGrowthBreakdown";
import { selectors as revenueGrowthSelectors } from "modules/customer/insights/performance/sales/revenueGrowth";
import { selectors as contributionGrowthSelectors } from "modules/customer/insights/performance/sales/contributionGrowth";
import { selectors as regionalGrowthBreakdownSelectors } from "modules/customer/insights/performance/sales/regionalGrowthBreakdown";
import { selectors as productCategoryBreakdownSelectors } from "modules/customer/insights/performance/sales/productCategoryBreakdown";

import { selectors as forecastRevenueSelectorsforecastRevenueSelectors } from "modules/customer/insights/performance/forecast/forecastRevenue";
import { selectors as forecastGrowthVsMarketSelectors } from "modules/customer/insights/performance/forecast/forecastGrowthVsMarket";
import { selectors as regionalBreakdownSelectors } from "modules/customer/insights/performance/forecast/regionalBreakdown";

import { selectors as revenueVsBudgetSelectors } from "modules/customer/insights/performance/budget/revenueVsBudget";
import { selectors as revenueVsBudgetOverTimeSelectors } from "modules/customer/insights/performance/budget/revenueVsBudgetOverTime";
import { selectors as contributionVsBudgetSelectors } from "modules/customer/insights/performance/budget/contributionVsBudget";
import { selectors as storePerformanceAgainstBudgetSelectors } from "modules/customer/insights/performance/budget/storePerformanceAgainstBudget";
//import { selectors as budgetVsForecastRevenueSelectors } from "modules/customer/insights/performance/budget/budgetVsForecastRevenue";
//import { selectors as budgetedVsForecastContributionSelectors } from "modules/customer/insights/performance/budget/budgetedVsForecastContribution";

const insightPerformance = createSelector(
    revenueOverTimeSelectors.historicOverallCompanySalesTrend,
    revenueOverTimeSelectors.historicShortTermCompanySalesTrend,
    revenueGrowthBreakdownSelectors.longTermGrowthVsMarket,
    revenueGrowthBreakdownSelectors.shortTermGrowthVsMarket,
    revenueGrowthSelectors.organicVsInorganicSalesGrowth,
    contributionGrowthSelectors.organicVsInorganicContributionGrowth,
    regionalGrowthBreakdownSelectors.dependencyOnSingularRegionOrStore,
    productCategoryBreakdownSelectors.categoriesGrowingOrDeclining,
    forecastRevenueSelectorsforecastRevenueSelectors.forecastCompanySalesTrend,
    forecastGrowthVsMarketSelectors.forecastGrowthVsMarket,
    regionalBreakdownSelectors.forecastDependencyOnSingularRegionOrStore,
    revenueVsBudgetSelectors.salesYTDPlusSalesYTGVsBudget,
    revenueVsBudgetOverTimeSelectors.trackingToBudget,
    contributionVsBudgetSelectors.contributionYTDPlusContributionYTGVsBudget,
    storePerformanceAgainstBudgetSelectors.storesVsBudget,
    //budgetVsForecastRevenueSelectors.budgetedSalesVsForecastSales,
    //budgetedVsForecastContributionSelectors.budgetedContVsForecastCont,
    (historicOverallCompanySalesTrend,
     historicShortTermCompanySalesTrend,
     longTermGrowthVsMarket,
     shortTermGrowthVsMarket,
     organicVsInorganicSalesGrowth,
     organicVsInorganicContributionGrowth,
     dependencyOnSingularRegionOrStore,
     categoriesGrowingOrDeclining,
     forecastCompanySalesTrend,
     forecastGrowthVsMarket,
     forecastDependencyOnSingularRegionOrStore,
     salesYTDPlusSalesYTGVsBudget,
     trackingToBudget,
     contributionYTDPlusContributionYTGVsBudget,
     storesVsBudget) => {
        const performance = {
            loading: false,
            error: false,
            isBudgetDataAvailable: false,
            values: {
                sales: 0,
                growth: 0,
                product: 0,
                forecast: 0,
                budget: 0
            }
        };

        const sales = [
            historicOverallCompanySalesTrend,
            historicShortTermCompanySalesTrend
        ];
        const growth = [
            longTermGrowthVsMarket,
            shortTermGrowthVsMarket,
            organicVsInorganicSalesGrowth,
            organicVsInorganicContributionGrowth,
            dependencyOnSingularRegionOrStore
        ];
        const product = [
            categoriesGrowingOrDeclining
        ];
        const forecast = [
            forecastCompanySalesTrend,
            forecastGrowthVsMarket,
            forecastDependencyOnSingularRegionOrStore
        ];
        const budget = [
            salesYTDPlusSalesYTGVsBudget,
            trackingToBudget,
            contributionYTDPlusContributionYTGVsBudget,
            storesVsBudget,
            //budgetedSalesVsForecastSales,
            //budgetedContVsForecastCont
        ];

        if (sales.some(rag => rag.loading)
            || growth.some(rag => rag.loading)
            || product.some(rag => rag.loading)
            || forecast.some(rag => rag.loading)
            || budget.some(rag => rag.loading)) {
            performance.loading = true;
            return performance;
        }

        const reducer = (total, rag) => {
            const value = (() => {
                switch (rag.status) {
                    case "success":
                        return 2;
                    case "warning":
                        return 1;
                    case "error":
                    case "info":
                    default:
                        return 0;
                }
            })();
            return total + value;
        };

        const score = (rags) => {
            const points = rags.reduce(reducer, 0);
            const score = (points / (2 * rags.length)) * 5;
            return score;
        };

        performance.isBudgetDataAvailable = !budget.some(rag => rag.status === "noData");
        performance.values.sales = score(sales);
        performance.values.growth = score(growth);
        performance.values.product = score(product);
        performance.values.forecast = score(forecast);
        performance.values.budget = score(budget);
        return performance;
    }
);

const selectors = {
    insightPerformance
};

export default selectors;
