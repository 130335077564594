import React from "react";

import { Grid, IconButton, Typography } from "@material-ui/core";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import AddSubscription from "./AddSubscription";
import SubscriptionsTable from "./SubscriptionsTable";

import { Account } from "modules/admin/accounts/accounts/accountsSlice";
import {
    clearSubscriptions,
    getPrices,
    getProducts,
    getSubscriptions,
    getTaxRates,
    hideSubscriptions,
    selectSubscriptionsVisibility,
    showSubscriptions
} from "modules/admin/accounts/subscriptions/subscriptionsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface UsersProps {
    account: Account
}

const Subscriptions: React.FC<UsersProps> = (props) => {
    const { account } = props;
    const dispatch = useAppDispatch();
    const subscriptionsVisibility = useAppSelector(selectSubscriptionsVisibility);
    const show = (subscriptionsVisibility.isVisible) && (account.id === subscriptionsVisibility.accountId);

    React.useEffect(() => {
        if (show) {
            dispatch(getProducts(account.id));
            dispatch(getPrices(account.id));
            dispatch(getTaxRates(account.id));
            dispatch(getSubscriptions(account.id));
        }
    }, [dispatch, show, account]);

    const handleSubscriptionsClick = () => {
        dispatch(showSubscriptions(account.id));
    };

    const handleCancelClick = () => {
        dispatch(hideSubscriptions());
        dispatch(clearSubscriptions());
    };

    return (
        <>
            <SimpleTooltip title="Subscriptions">
                <StyledIconButton size="small" onClick={handleSubscriptionsClick}>
                    <CreditCardRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                maxWidth="lg"
                title={
                    <Typography variant="h5" component="div" gutterBottom>
                        Subscriptions
                    </Typography>
                }
                content={
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <AddSubscription accountId={account.id} />
                        </Grid>
                        <Grid item xs={12}>
                            <SubscriptionsTable />
                        </Grid>
                    </Grid>
                }
                actions={<></>}
            />
        </>
    );
};

export default Subscriptions;
