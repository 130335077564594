import React from "react";

import { Box, Button, Card, CardMedia, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PageSection from "components/landing/PageSection";

const StyledImageCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    }
}))(Card);

const Error: React.FC = () => {
    return (
        <Box data-cy="pages-landing-registration-error">
            {/* @ts-ignore */}
            <PageSection maxWidth="sm">
                <StyledImageCard elevation={0}>
                    <CardMedia component="img" image="/content/landing/registration/error/circles.svg" alt="registration error" />
                </StyledImageCard>
            </PageSection>
            {/* @ts-ignore */}
            <PageSection maxWidth="md" disablePaddingTop>
                <Typography variant="h3" component="div" align="center" gutterBottom>
                    This link isn't working
                </Typography>
                <br />
                <Typography variant="h4" component="div" align="center">
                    This could be because it's expired or you're not using the latest registration
                    <br />
                    link provided. To get a new link, please contact our support team.
                </Typography>
                <br />
                <br />
                {/* @ts-ignore */}
                <Box align="center">
                    <Button
                        href="mailto:dashsupport@kpmg.co.uk?subject=KPMG Dash Support"
                        variant="contained"
                        size="large"
                        color="secondary"
                        disableElevation
                        data-cy="btn-contact-support"
                    >
                        Contact support
                    </Button>
                </Box>
            </PageSection>
        </Box>
    );
};

export default Error;
