import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";
import { Typography } from "@material-ui/core";

const SalesHistoryTitle: React.FC = () => {
    const selectedStore = useAppSelector(selectStore);

    return <Typography variant="h6">{selectedStore?.name} historic weekly revenue & forecast weekly revenue over time</Typography>;
};

export default SalesHistoryTitle;
