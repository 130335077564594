// very dangerous! run away from here!!

import { DateTime } from "luxon";

declare module "luxon/src/datetime" {
    export interface DateTime {
        customStartOfWeek(): DateTime;
    }
}

DateTime.prototype.customStartOfWeek = function (): DateTime {
    const _self = this as DateTime;
    if (_self.weekday === 7) {
        //already sunday
        return _self;
    }
    return _self
        .startOf("week") //monday
        .minus({ days: 1 }); //sunday
};

// you shouldn't be adding anything to this file
