import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import { logError } from "modules/helpers/logger/loggerSlice";
import {
    selectComparatorsByChapter,
    selectHasErrors as selectLocationHasErrors,
    selectIsLoading as selectLocationIsLoading,
    selectPinnedLocation,
    selectTarget
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { AverageDailyFootfall, loadAverageDailyFootfall } from "./averageDailyFootfall";
import { OutputAreaFootfall, loadOutputAreaFootfall } from "./outputAreaFootfall";
import { DailyFootfall, loadDailyFootfall } from "./dailyFootfall";
import { RetailCentreFootfall, loadRetailCentreFootfall } from "./retailCentreFootfall";

interface LoadFootfallResponse {
    dailyFootfall: DailyFootfall[],
    outputAreaFootfall: OutputAreaFootfall[],
    averageDailyFootfall: AverageDailyFootfall[],
    retailCentreFootfall: RetailCentreFootfall[]
}

interface FootfallState {
    isLoading: boolean,
    hasErrors: boolean,
    outputAreaFootfall: OutputAreaFootfall[],
    dailyFootfall: DailyFootfall[],
    averageDailyFootfall: AverageDailyFootfall[],
    retailCentreFootfall: RetailCentreFootfall[]
}

const initialState: FootfallState = {
    isLoading: false,
    hasErrors: false,
    outputAreaFootfall: [],
    dailyFootfall: [],
    averageDailyFootfall: [],
    retailCentreFootfall: []
};

const footfallSlice = createSlice({
    name: "customer/tools/location/footfall",
    initialState,
    reducers: {
        clearOutputAreaFootfall: (state) => {
            state.outputAreaFootfall = [];
        },
        clearDailyFootfall: (state) => {
            state.dailyFootfall = [];
        },
        clearAverageDailyFootfall: (state) => {
            state.averageDailyFootfall = [];
        },
        clearRetailCentreFootfall: (state) => {
            state.retailCentreFootfall = [];
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadFootfall.pending, (state: FootfallState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadFootfall.rejected, (state: FootfallState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.outputAreaFootfall = initialState.outputAreaFootfall;
            state.dailyFootfall = initialState.dailyFootfall;
            state.averageDailyFootfall = initialState.averageDailyFootfall;
            state.retailCentreFootfall = initialState.retailCentreFootfall;
        });
        builder.addCase(loadFootfall.fulfilled, (state: FootfallState, action: PayloadAction<LoadFootfallResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.outputAreaFootfall = action.payload.outputAreaFootfall;
            state.dailyFootfall = action.payload.dailyFootfall;
            state.averageDailyFootfall = action.payload.averageDailyFootfall;
            state.retailCentreFootfall = action.payload.retailCentreFootfall;
        });
    }
});

export const loadFootfall = createAppAsyncThunk(
    "customer/tools/location/footfall/loadFootfall",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const retailCentreId = selectPinnedLocation(state)?.retailCentre.id;
            const comparatorRetailCentreId = selectComparatorsByChapter(state)?.footfall?.retailCentreId;

            const retailCentreIds = [];
            if (retailCentreId) {
                retailCentreIds.push(retailCentreId);
            }
            if (comparatorRetailCentreId) {
                retailCentreIds.push(comparatorRetailCentreId);
            }

            const dailyFootfallPromise = thunkAPI.dispatch(loadDailyFootfall(retailCentreIds));
            const outputAreaFootfallPromise = thunkAPI.dispatch(loadOutputAreaFootfall(retailCentreId));
            const averageDailyFootfallPromise = thunkAPI.dispatch(loadAverageDailyFootfall(retailCentreId));
            const retailCentreFootfallPromise = thunkAPI.dispatch(loadRetailCentreFootfall(retailCentreIds));
            const result = await Promise.all([
                dailyFootfallPromise,
                outputAreaFootfallPromise,
                averageDailyFootfallPromise,
                retailCentreFootfallPromise
            ]);
            const dailyFootfall = result[0];
            const outputAreaFootfall = result[1];
            const averageDailyFootfall = result[2];
            const retailCentreFootfall = result[3];
            const loadFootfallResponse: LoadFootfallResponse = {
                dailyFootfall,
                outputAreaFootfall,
                averageDailyFootfall,
                retailCentreFootfall
            };
            return loadFootfallResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Footfall.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearFootfall = (): AppThunk => (dispatch) => {
    dispatch(footfallSlice.actions.clearOutputAreaFootfall());
    dispatch(footfallSlice.actions.clearDailyFootfall());
    dispatch(footfallSlice.actions.clearAverageDailyFootfall());
    dispatch(footfallSlice.actions.clearRetailCentreFootfall());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.footfall.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.footfall.hasErrors;
};

export const selectOutputAreaFootfall = (state: RootState) => {
    return state.customer.tools.location.footfall.outputAreaFootfall;
};

export const selectDailyFootfall = (state: RootState) => {
    return state.customer.tools.location.footfall.dailyFootfall;
};

export const selectAverageDailyFootfall = (state: RootState) => {
    return state.customer.tools.location.footfall.averageDailyFootfall;
};

export const selectRetailCentreFootfall = (state: RootState) => {
    return state.customer.tools.location.footfall.retailCentreFootfall;
};

export const selectFootfallLevelAlignment = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectTarget(state),
    (isLoading, hasErrors, pinnedLocation, target) => {
        const id = "footfall-level";
        let label = "Footfall level";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        if (!pinnedLocation) {
            return new RagIndicator(id, status, label, "No location selected.");
        }
        if (!target?.useFootfall) {
            return new RagIndicator(id, RagIndicatorStatus.NoData, "No target set for Footfall", "");
        }
        const score = pinnedLocation.retailCentre.getRagScore(ScoreField.Footfall);
        switch (score) {
            case 5:
            case 4:
                status = RagIndicatorStatus.Green;
                label = "The footfall for the selected location aligns strongly with your target level of footfall.";
                break;
            case 3:
            case 2:
                status = RagIndicatorStatus.Amber;
                label = "The footfall for the selected location aligns averagely with your target level of footfall.";
                break;
            default:
                status = RagIndicatorStatus.Red;
                label = "The footfall for the selected location aligns weakly with your target level of footfall.";
        }
        return new RagIndicator(id, status, label, "");
    }
);

export const selectFootfallAlignmentScore = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (pinnedLocation) => {
        return pinnedLocation?.retailCentre?.footfallScore ?? 0;
    }
);

export const selectFootfallLevelOverTime = createSelector(
    selectDailyFootfall,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (dailyFootfall, pinnedLocation, comparatorsByChapter) => {
        if (dailyFootfall.length === 0) {
            return [];
        }

        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.footfall.retailCentreId;

        return _(dailyFootfall)
            .groupBy(footfall => footfall.date.toISO())
            .map((footfallByDate, date) => ({
                date,
                retailCentre: footfallByDate.find(footfall => footfall.retailCentreId === retailCentreId)?.footfall ?? 0,
                benchmark: footfallByDate.find(footfall => footfall.retailCentreId === comparatorRetailCentreId)?.footfall ?? 0
            }))
            .value();
    }
);

export const selectFootfallLevel = createSelector(
    selectRetailCentreFootfall,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (retailCentreFootfall, pinnedLocation, comparatorsByChapter) => {
        const footfallLevel = {
            selectedLocation: 0,
            comparator: 0
        };
        if (retailCentreFootfall.length === 0) {
            return footfallLevel;
        }

        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.footfall.retailCentreId;

        footfallLevel.selectedLocation =
            retailCentreFootfall.find(footfall => footfall.retailCentreId === retailCentreId)?.currentYearFootfall ?? 0;
        footfallLevel.comparator =
            retailCentreFootfall.find(footfall => footfall.retailCentreId === comparatorRetailCentreId)?.currentYearFootfall ?? 0;

        return footfallLevel;
    }
);

export const selectYoYFootfall = createSelector(
    selectRetailCentreFootfall,
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectComparatorsByChapter(state),
    (retailCentreFootfall, pinnedLocation, comparatorsByChapter) => {
        const yearOnYearFootfall = {
            retailCentre: 0,
            benchmark: 0
        };
        if (retailCentreFootfall.length === 0) {
            return yearOnYearFootfall;
        }

        const retailCentreId = pinnedLocation?.retailCentre.id;
        const comparatorRetailCentreId = comparatorsByChapter?.footfall.retailCentreId;

        const footfallByRetailCentre = retailCentreFootfall.map(footfallByRetailCentre => ({
            retailCentreId: footfallByRetailCentre.retailCentreId,
            variance: mathUtils.safePercentageChange(footfallByRetailCentre.currentYearFootfall, footfallByRetailCentre.previousYearFootfall)
        }));

        const retailCentreVariance = footfallByRetailCentre.find(footfall => footfall.retailCentreId === retailCentreId)?.variance ?? 0;
        const benchmarkVariance = footfallByRetailCentre.find(footfall => footfall.retailCentreId === comparatorRetailCentreId)?.variance ?? 0;
        yearOnYearFootfall.retailCentre = retailCentreVariance;
        yearOnYearFootfall.benchmark = benchmarkVariance;
        return yearOnYearFootfall;
    }
);

export const selectAvgDailyFootfall = createSelector(
    selectAverageDailyFootfall,
    (averageDailyFootfall) => {
        if (averageDailyFootfall.length === 0) {
            return [];
        }

        return averageDailyFootfall.map(footfall => ({
            timeRangeIndex: footfall.timeBucketIndex,
            averageFootfall: footfall.averageFootfall,
            weekdayIndex: footfall.dayIndex - 1
        }));
    }
);

export default footfallSlice;
