import React from "react";

import { useTheme, withStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@material-ui/core";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";

import BarChart from "./BarChart";

import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        height: "100%"
    }
}))(Card);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardActions);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%",
        minWidth: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(CardContent);

interface StoreCardProps {
    store: CostReductionOpportunityByStore,
    selected: boolean,
    onSelect: (store: CostReductionOpportunityByStore) => void,
    onDeselect: (store: CostReductionOpportunityByStore) => void
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
    const theme = useTheme();
    const { store, selected, onSelect, onDeselect } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(store);
        } else {
            onSelect(store);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardActions style={{ height: theme.spacing(20) }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {store.storeName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Opportunity (%)
                            </Typography>
                            <br/>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toPercentage((store.opportunityValueAsPercentageOfRevenue * 100), true, 1)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Opportunity (&pound;)
                            </Typography>
                            <br />
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toGBP(store.opportunityValue, 1)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Average similarity score
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toPercentage(store.averageSimilarityScore, true, 1)}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>
                <StyledCardContent>
                    <BarChart store={store} />
                </StyledCardContent>
                <StyledCardActions>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            align="center"
                            style={{ color: theme.palette.text.primary }}
                        >
                            {store.costName}
                        </Typography>
                    </Grid>
                </StyledCardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default StoreCard;
