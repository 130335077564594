import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectPastTwelveMonthsPercentageOfRevenue
} from "modules/customer/insights/cost/costOverview/costOverviewSlice";

import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";

const PercentageOfRevenue = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const data = useAppSelector(selectPastTwelveMonthsPercentageOfRevenue);

    return (
        <Box width="100%" height="100%" data-cy="percentage-of-revenue">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toPercentage(data.data.pastTwelveMonthsPercentage, true, 1)}
                    </Typography>
                }
                targetLabel={"Past 12 months costs as % of revenue"}
                comparisonValue={
                    <PercentageIndicator loading={false} error={false} value={data.data.difference} switchColor={true} noDataMessage={""} />
                }
                comparisonLabel={"Change from previous year"}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toPercentage(data.data.previousYearPercentage, true, 1)}
                    </Typography>
                }
                differenceLabel="Previous year"
            />
        </Box>
    );
};

export default PercentageOfRevenue;
