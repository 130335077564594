import React from "react";

import { Grid } from "@material-ui/core";

import Deflections from "./Deflections";
import GenerateRevenuePrediction from "./generateRevenuePrediction/GenerateRevenuePrediction";
import PeakWeekRevenuePrediction from "./PeakWeekRevenuePrediction";
import ProposedStoreParameters from "./ProposedStoreParameters";
import RevenuePredictionDrivers from "./RevenuePredictionDrivers";
import TypicalWeekRevenuePrediction from "./TypicalWeekRevenuePrediction";
import TypicalYearRevenuePrediction from "./TypicalYearRevenuePrediction";

import { selectClientRegistration } from "modules/customer/tools/location/locationSlice";
import { selectHasPrediction } from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";

const RevenuePrediction: React.FC = () => {
    const hasPrediction = useAppSelector(selectHasPrediction);
    const clientRegistration = useAppSelector(selectClientRegistration);
    const hasCustomCatchments = clientRegistration?.hasCustomCatchments ?? false;

    return (
        <Grid container spacing={2}>
            {hasPrediction && (
                <>
                    <Grid item xs={12}>
                        <TypicalYearRevenuePrediction />
                    </Grid>
                    <Grid item xs={12}>
                        <TypicalWeekRevenuePrediction />
                    </Grid>
                    <Grid item xs={12}>
                        <PeakWeekRevenuePrediction />
                    </Grid>
                    <Grid item xs={12}>
                        <RevenuePredictionDrivers />
                    </Grid>
                    {hasCustomCatchments && (
                        <Grid item xs={12}>
                            <Deflections />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ProposedStoreParameters />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <GenerateRevenuePrediction />
            </Grid>
        </Grid>
    );
};

export default RevenuePrediction;
