import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import Page from "components/landing/Page";
import PageCallToAction from "components/landing/PageCallToAction";

import DashProvidedUsWithDetailedInsight from "./DashProvidedUsWithDetailedInsight";
import UnlockThePowerOfRetailAnalytics from "./UnlockThePowerOfRetailAnalytics";

const BookRetailAnalyticsDemo: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const section = searchParams.get("section");

    React.useEffect(() => {
        if (section === "top") {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            history.push("/book-retail-analytics-demo");
        }
    }, [section, history]);

    return (
        <Page>
            <UnlockThePowerOfRetailAnalytics />
            <DashProvidedUsWithDetailedInsight />
            <PageCallToAction
                color={theme.palette.common.black}
                // @ts-ignore
                bgcolor={theme.palette.tertiary.main}
                title="KPMG Dash"
                subtitle="Built by experts with over 20 years of subject matter expertise in retail, data and technology"
                actionLabel="Book a demo"
                actionUrl="/book-retail-analytics-demo?section=top"
            />
        </Page>
    );
};

export default BookRetailAnalyticsDemo;
