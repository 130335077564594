import React from "react";

import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RadarChart from "./RadarChart";

import { Store } from "modules/customer/tools/location/store";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        height: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent"
    }
}))(Card);

const StyledCardActionArea = withStyles(() => ({
    root: {
        height: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardContent);

const StyledTypography = withStyles(theme => ({
    root: {
        color: theme.palette.text.disabled
    }
}))(Typography);

interface StoreCardProps {
    store: Store,
    selected: boolean,
    onSelect: (store: Store) => void,
    onDeselect: (store: Store) => void,
    enableSpend: boolean
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
    const { store, selected, onSelect, onDeselect, enableSpend } = props;

    const handleClick = () => {
        if (selected) {
            onDeselect(store);
        } else {
            onSelect(store);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardContent>
                    <RadarChart store={store} enableSpend={enableSpend} />
                </StyledCardContent>
                <CardActions>
                    <Box>
                        <Typography variant="subtitle1" component="div">
                            {store.name}
                        </Typography>
                        {/* @ts-ignore */}
                        <StyledTypography variant="body1" component="div">
                            {store.categoryName}
                        </StyledTypography>
                    </Box>
                </CardActions>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default StoreCard;
