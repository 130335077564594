import React from "react";
import { NavLink } from "react-router-dom";

import { AppBar, BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledAppBar = withStyles(() => ({
    root: {
        top: "auto",
        bottom: 0
    }
}))(AppBar);

const StyledBottomNavigation = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.drawer
    }
}))(BottomNavigation);

const StyledBottomNavigationAction = withStyles(theme => ({
    root: {
        "&.active": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        maxWidth: "100%"
    }
}))(BottomNavigationAction);

const MobileNavigation: React.FC = () => {
    return (
        <StyledAppBar component="nav">
            <StyledBottomNavigation showLabels>
                <StyledBottomNavigationAction
                    label="Home"
                    icon={<HomeRoundedIcon />}
                    // @ts-ignore
                    component={NavLink}
                    to="/home"
                />
                <StyledBottomNavigationAction
                    label="Leads"
                    icon={<PersonRoundedIcon />}
                    // @ts-ignore
                    component={NavLink}
                    to="/leads"
                />
            </StyledBottomNavigation>
        </StyledAppBar>
    );
};

export default MobileNavigation;
