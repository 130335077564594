import { Box } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { Marker } from "react-map-gl";

const StoreMarker = (props) => {
    const { mapCenter } = props;

    return (
        <Marker
            latitude={mapCenter.latitude}
            longitude={mapCenter.longitude}
            offsetTop={-20}
            offsetLeft={-10}>
            <Box style={{ cursor: "pointer" }}>
                <LocationOnOutlinedIcon />
            </Box>
        </Marker>
    );
};

export default StoreMarker;
