import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectFiscalYearStartDate, selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";
import actions from "./actions";

const getSalesPerformanceVsBudgetOverTime = () => async (dispatch, getState) => {
    dispatch(actions.getSalesPerformanceVsBudgetOverTimeRequest());
    try {
        const state = getState();
        const currentDate = selectReferenceDate(state);
        const fyStart = selectFiscalYearStartDate(state);
        const financialYearStartDate = dateUtils.financialYearStartDate(currentDate, fyStart);
        const financialYearEndDate = dateUtils.financialYearEndDate(currentDate, fyStart);
        const query = {
            measures: ["F_SalesForecast.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate]
            }],
            order: [
                ["D_Date.QuarterName", "asc"]
            ],
            dimensions: ["D_Date.QuarterName", "F_SalesForecast.ForecastFlag"]
        };
        const resultSet = await dispatch(cubeLoad(query));

        const quarterlySales = resultSet.loadResponses[0].data.map(item => ({
            sales: item["F_SalesForecast.SumLineValue"],
            forecastFlag: item["F_SalesForecast.ForecastFlag"],
            quarter: item["D_Date.QuarterName"]
        }));

        const budgetQuery = {
            measures: ["F_Budget.SumBudgetRevenue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [financialYearStartDate, financialYearEndDate]
            }],
            order: [
                ["D_Date.QuarterName", "asc"]
            ],
            dimensions: ["D_Date.QuarterName", "D_Date.FY"]
        };
        const budgetResultSet = await dispatch(cubeLoad(budgetQuery));

        const quarterlyBudget = budgetResultSet.loadResponses[0].data.map(item => ({
            budget: item["F_Budget.SumBudgetRevenue"],
            quarter: item["D_Date.QuarterName"],
            fy: item["D_Date.FY"]
        }));
        dispatch(actions.getSalesPerformanceVsBudgetOverTimeSuccess(quarterlySales, quarterlyBudget));
    }
    catch (error) {
        dispatch(actions.getSalesPerformanceVsBudgetOverTimeFailure());
        dispatch(logError("Error loading SalesPerformanceVsBudgetOverTime.", error));
    }
};

const operations = {
    getSalesPerformanceVsBudgetOverTime
};

export default operations;
