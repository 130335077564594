const GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_REQUEST = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_REQUEST";
const GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_SUCCESS = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_SUCCESS";
const GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_FAILURE = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_FAILURE";
const GET_SALES_BY_REGION_GROWTH_FORECAST_REQUEST = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_SALES_BY_REGION_GROWTH_FORECAST_REQUEST";
const GET_SALES_BY_REGION_GROWTH_FORECAST_SUCCESS = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_SALES_BY_REGION_GROWTH_FORECAST_SUCCESS";
const GET_SALES_BY_REGION_GROWTH_FORECAST_FAILURE = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_SALES_BY_REGION_GROWTH_FORECAST_FAILURE";
const GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST";
const GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS";
const GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE = "spark/customer/insights/performance/forecast/regionalBreakdown/GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE";
const SET_SELECTED_REGION = "spark/customer/insights/performance/forecast/regionalBreakdown/SET_SELECTED_REGION";

const types = {
    GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_REQUEST,
    GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_SUCCESS,
    GET_TOTAL_SALES_GROWTH_BY_REGION_FORECAST_FAILURE,
    GET_SALES_BY_REGION_GROWTH_FORECAST_REQUEST,
    GET_SALES_BY_REGION_GROWTH_FORECAST_SUCCESS,
    GET_SALES_BY_REGION_GROWTH_FORECAST_FAILURE,
    GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_REQUEST,
    GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_SUCCESS,
    GET_FORECAST_DEPENDENCY_ON_SINGULAR_REGION_OR_STORE_FAILURE,
    SET_SELECTED_REGION
};

export default types;
