import React from "react";

import { selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const store = useAppSelector(selectStore);

    return (
        <>
            Identify peak footfall periods for your {store?.name} store.
            Leverage this data to fine-tune staffing schedules, optimise store opening hours, and plan impactful marketing activities aligned with high footfall times.
            Utilise quieter moments for essential maintenance and security, ensuring a seamless customer experience.
            These insights allow for precise, data-driven decisions, enhancing profitability and customer satisfaction.
        </>
    );
};

export default Subtitle;
