import React from "react";

import RAGIndicator from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectPayrollCostsPerSqftCategorisation } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const PayrollCostPerSquareFootCategorisation = () => {
    const categorisation = useAppSelector(selectPayrollCostsPerSqftCategorisation);

    return (
        <RAGIndicator ragIndicator={categorisation} />
    );
};

export default PayrollCostPerSquareFootCategorisation;
