import React from "react";

import { Box, Button, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import { withStyles } from "@material-ui/core/styles";

const StyledCard = withStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    }
}))(Card);

const StyledCardContent = withStyles(() => ({
    root: {
        flexGrow: 1
    }
}))(CardContent);

const StyledCardActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(7),
        flexDirection: "column",
        alignItems: "flex-start"
    }
}))(CardActions);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main,
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const EmailUs: React.FC = () => {
    return (
        <Box height="100%" display="flex" flexDirection="column" data-cy="pages-customer-help-email-us">
            <Box color="text.disabled">
                <Typography variant="subtitle1" component="div" gutterBottom>
                    Email us
                </Typography>
            </Box>
            <StyledCard>
                <StyledCardContent>
                    <Box height="100%" display="flex">
                        <EmailRoundedIcon />
                        <Box height="100%" paddingLeft={2} display="flex" flexDirection="column">
                            <Typography variant="subtitle1" component="div">
                                Email us with your queries and we will aim to get back to you ASAP.
                            </Typography>
                        </Box>
                    </Box>
                    <br />
                </StyledCardContent>
                <StyledCardActions disableSpacing>
                    <Typography variant="h6" component="div" color="textSecondary">
                        Email support
                    </Typography>
                    <StyledButton
                        variant="text"
                        size="medium"
                        color="default"
                        disableElevation
                        href="mailto:dashsupport@kpmg.co.uk?subject=KPMG Dash Support"
                    >
                        dashsupport@kpmg.co.uk
                    </StyledButton>
                </StyledCardActions>
            </StyledCard>
        </Box>
    );
};

export default EmailUs;
