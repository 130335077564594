import React from "react";

import { Box } from "@material-ui/core";

import KPIAlignment from "components/visuals/KPIAlignment";

import { useAppSelector } from "store";
import { selectDemographicsAlignmentScore } from "modules/customer/tools/location/demographics/demographicsSlice";
import { selectIsLoading, selectHasErrors, selectTarget } from "modules/customer/tools/location/locationSlice";

const OverallDemographicAlignmentScore: React.FC = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const demographicsAlignmentScore = useAppSelector(selectDemographicsAlignmentScore);
    const target = useAppSelector(selectTarget);

    const disabled = !(target?.useDemographics);
    const label = "Demographic alignment score";

    return (
        <Box width="100%" height="100%" data-cy="overall-demographic-alignment-score">
            <KPIAlignment
                isLoading={isLoading}
                hasErrors={hasErrors}
                label={label}
                score={demographicsAlignmentScore}
                disabled={disabled}
            />
        </Box>
    );
};

export default OverallDemographicAlignmentScore;
