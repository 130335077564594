import React from "react";
import ReactMapGL, {
    FlyToInterpolator,
    MapRef,
    MapEvent,
    NavigationControl,
    ViewportProps,
    MapLoadEvent
} from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppSelector } from "store";

import Progress from "components/visuals/Progress";
import Error from "components/visuals/Error";

import Recenter from "./Recenter";
import FullscreenButton from "./FullscreenButton";
import DownloadButton from "./DownloadButton";

import "assets/styles/mapbox-overrides.css";
import "assets/styles/mapbox-geocoder-overrides.css";
import "assets/styles/mapbox-navigation-control-overrides.css";
import MapTitle from "./MapTitle";


interface MapBaseProps {
    loading: boolean,
    error: boolean,
    title?: string,
    mapboxAccessToken: string,
    mapboxBaseMapStyle: string,
    mapRef: React.Ref<MapRef>,
    height?: string | number,
    initialViewport: ViewportProps,
    currentViewport?: ViewportProps,
    interactiveLayerIds?: string[],
    onLoad?: (event: MapLoadEvent) => void,
    onViewportChange?: (viewport: ViewportProps) => void
    onHover?: (event: MapEvent) => void,
    onClick?: (event: MapEvent, centerMap: (latitude: number, longitude: number, zoom?: number) => void) => void,
    onMouseOut?: (event: MapEvent) => void,
    addGeocoder: boolean,
    geocoderContainerRef?: React.RefObject<HTMLDivElement>,
    addNavigationControl: boolean,
    addRecenterButton: boolean,
    addFullscreenButton?: boolean,
    downloadData?: object[],
    dataCy: string,
    children?: React.ReactNode,
    displayPOIs?: boolean
}

const MapBase: React.FC<MapBaseProps> = (props) => {
    const theme = useTheme();
    const featureFlags = useAppSelector(selectFeatureFlags);
    const height = props.height ?? theme.spacing(60);
    const {
        loading, error,
        title, dataCy,
        mapboxAccessToken, mapboxBaseMapStyle, mapRef, initialViewport, currentViewport, interactiveLayerIds,
        onLoad, onViewportChange, onHover, onClick, onMouseOut,
        addGeocoder, geocoderContainerRef,
        addNavigationControl, addRecenterButton, addFullscreenButton, downloadData, displayPOIs
    } = props;
    const [viewport, setViewport] = React.useState(currentViewport ?? initialViewport);
    const [geocoderInputValue, setGeocoderInputValue] = React.useState("");

    const centerMap = React.useCallback((latitude: number, longitude: number, zoom?: number) => {
        setViewport(currentViewport => ({
            ...currentViewport,
            latitude,
            longitude,
            zoom: zoom || currentViewport.zoom,
            transitionInterpolator: new FlyToInterpolator(),
            transitionDuration: 1000
        }));
    }, [setViewport]);

    const handleMapLoad = React.useCallback((event: MapLoadEvent) => {
        onLoad && onLoad(event);
        if (!displayPOIs) {
            const map = event.target;
            map.setLayoutProperty("poi-label", "visibility", "none");
        }
    }, [onLoad, displayPOIs]);

    const handleViewportChange = React.useCallback((viewport: ViewportProps) => {
        setViewport(viewport);
        onViewportChange && onViewportChange(viewport);
    }, [setViewport, onViewportChange]);

    const handleHover = React.useCallback((event: MapEvent) => {
        onHover && onHover(event);
    }, [onHover]);

    const handleClick = React.useCallback((event: MapEvent) => {
        onClick && onClick(event, centerMap);
    }, [onClick, centerMap]);

    const handleMouseOut = React.useCallback((event: MapEvent) => {
        onMouseOut && onMouseOut(event);
    }, [onMouseOut]);

    const getGeocoderInput = React.useCallback(() => {
        return geocoderContainerRef?.current?.getElementsByClassName("mapboxgl-ctrl-geocoder--input")[0] as HTMLInputElement;
    }, [geocoderContainerRef]);

    const handleGeocoderInit = React.useCallback(() => {
        const input = getGeocoderInput();
        if (input) {
            input.value = geocoderInputValue;
        }
    }, [getGeocoderInput, geocoderInputValue]);

    const handleGeocoderResult = React.useCallback(() => {
        const input = getGeocoderInput();
        const inputValue = input?.value;
        setGeocoderInputValue(inputValue);
    }, [getGeocoderInput, setGeocoderInputValue]);

    const handleGeocoderClear = React.useCallback(() => {
        setGeocoderInputValue("");
    }, [setGeocoderInputValue]);

    React.useEffect(() => {
        setViewport(currentViewport ?? initialViewport);
    }, [initialViewport, currentViewport]);

    if (loading) {
        return (<Progress />);
    }

    if (error) {
        return (<Error />);
    }

    return (
        <>
            {title && (
                <MapTitle title={title} />
            )}
            <Box position="relative" height={height} data-cy={dataCy}>
                {addRecenterButton && (
                    <Recenter initialViewport={initialViewport} centerMap={centerMap} />
                )}
                <ReactMapGL
                    mapboxApiAccessToken={mapboxAccessToken}
                    ref={mapRef}
                    {...viewport}
                    interactiveLayerIds={interactiveLayerIds}
                    onLoad={handleMapLoad}
                    onViewportChange={handleViewportChange}
                    onHover={handleHover}
                    onClick={handleClick}
                    onMouseOut={handleMouseOut}
                    width="100%"
                    height="100%"
                    mapStyle={mapboxBaseMapStyle}
                >
                    {addGeocoder && (
                        <Geocoder
                            mapRef={mapRef}
                            mapboxApiAccessToken={mapboxAccessToken}
                            containerRef={geocoderContainerRef}
                            onViewportChange={setViewport}
                            onInit={handleGeocoderInit}
                            onResult={handleGeocoderResult}
                            onClear={handleGeocoderClear}
                            inputValue=""
                            marker={false}
                            countries="gb"
                            enableEventLogging={false}
                        />
                    )}
                    {addNavigationControl && (
                        <NavigationControl
                            showCompass={false}
                            style={{ right: 16, bottom: 40 }}
                        />
                    )}
                    {(addFullscreenButton && featureFlags.enableCustomerMapboxExporting) && (
                        <FullscreenButton />
                    )}
                    {(downloadData && featureFlags.enableCustomerMapboxExporting) && (
                        <DownloadButton data={downloadData} fileName={title}/>
                    )}
                    {props.children}
                </ReactMapGL>
            </Box>
        </>
    );
};

export default MapBase;
