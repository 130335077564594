import React from "react";

import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { withStyles } from "@material-ui/core/styles";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";
import SimpleTooltip from "components/SimpleTooltip";

import DeleteAccountConfirmation from "./DeleteAccountConfirmation";

import {
    clearDeleteAccountConfirmation,
    hideDeleteAccount,
    hideDeleteAccountConfirmation,
    selectDeleteAccountVisibility,
    showDeleteAccount,
    showDeleteAccountConfirmation
} from "modules/admin/accounts/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteAccountProps {
    accountId: string,
    companyName: string
}

const DeleteAccount: React.FC<DeleteAccountProps> = (props) => {
    const { accountId, companyName } = props;
    const dispatch = useAppDispatch();
    const deleteAccountVisibility = useAppSelector(selectDeleteAccountVisibility);
    const show = (deleteAccountVisibility.isVisible) && (accountId === deleteAccountVisibility.accountId);

    const handleDeleteAccountClick = () => {
        dispatch(showDeleteAccount(accountId));
    };

    const handleConfirmClick = () => {
        dispatch(showDeleteAccountConfirmation(accountId));
        dispatch(hideDeleteAccount());
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteAccount());
        dispatch(hideDeleteAccountConfirmation());
        dispatch(clearDeleteAccountConfirmation());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteAccountClick}>
                    <DeleteRoundedIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <ConfirmationDialog
                open={show}
                title="Are you sure you want to delete this account?"
                subtitle={companyName}
                type={ConfirmationDialogType.Error}
                confirmLabel="Yes, delete"
                cancelLabel="No, cancel"
                onConfirm={handleConfirmClick}
                onCancel={handleCancelClick}
            />
            <DeleteAccountConfirmation accountId={accountId} companyName={companyName} />
        </>
    );
};

export default DeleteAccount;
