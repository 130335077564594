import React from "react";

import { Box, Grid } from "@material-ui/core";

import SharedAndNewCustomersByCustomerProfile from "./SharedAndNewCustomersByCustomerProfile";

const CustomerProfilesOfSharedAndUniquePopulation: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SharedAndNewCustomersByCustomerProfile />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerProfilesOfSharedAndUniquePopulation;
