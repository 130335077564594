import React from "react";

import ConfirmationDialog, { ConfirmationDialogType } from "components/ConfirmationDialog";

import {
    selectOverrideRevenuePredictionConfirmationVisibility,
    generateRevenuePrediction,
    hideGenerateRevenuePrediction,
    hideOverrideRevenuePredictionConfirmation
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppDispatch, useAppSelector } from "store";

const OverrideRevenuePredictionConfirmation: React.FC = () => {
    const dispatch = useAppDispatch();
    const overrideRevenuePredictionConfirmationVisibility = useAppSelector(selectOverrideRevenuePredictionConfirmationVisibility);

    const handleConfirmClick = () => {
        dispatch(hideOverrideRevenuePredictionConfirmation());
        dispatch(hideGenerateRevenuePrediction());
        dispatch(generateRevenuePrediction());
    };

    const handleCancelClick = () => {
        dispatch(hideOverrideRevenuePredictionConfirmation());
    };

    return (
        <ConfirmationDialog
            open={overrideRevenuePredictionConfirmationVisibility.isVisible}
            title="You're about to generate a new revenue prediction"
            subtitle="Continuing this process will overwrite your current revenue prediction values. Do you want to proceed with a new revenue prediction?"
            type={ConfirmationDialogType.Warning}
            confirmLabel="Yes, generate"
            cancelLabel="No, cancel"
            onConfirm={handleConfirmClick}
            onCancel={handleCancelClick}
        />
    );
};

export default OverrideRevenuePredictionConfirmation;
