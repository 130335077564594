import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class RetailCentreFootfall {
    public readonly retailCentreId: number;
    public readonly currentYearFootfall: number;
    public readonly previousYearFootfall: number;

    constructor(
        retailCentreId: number,
        currentYearFootfall: number,
        previousYearFootfall: number,
    ) {
        this.retailCentreId = retailCentreId;
        this.currentYearFootfall = currentYearFootfall;
        this.previousYearFootfall = previousYearFootfall;
    }
}

export const loadRetailCentreFootfall = (retailcentresIds: number[]): AppThunk<Promise<RetailCentreFootfall[]>> => async (dispatch) => {
    try {
        const retailCentreIdStrings = retailcentresIds.map(String);
        const query = {
            dimensions: [
                "RetailCentreYearlyFootfall.RetailCentreID",
                "RetailCentreYearlyFootfall.Prev12MonthsFootfall",
                "RetailCentreYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall",
            ],
            filters: [{
                member: "RetailCentreYearlyFootfall.RetailCentreID",
                operator: "equals",
                values: retailCentreIdStrings
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return retailcentresIds.map(retailcentreId => {
            const row = rawData.find(row => row["RetailCentreYearlyFootfall.RetailCentreID"] === retailcentreId);

            return new RetailCentreFootfall(
                retailcentreId,
                row["RetailCentreYearlyFootfall.Prev12MonthsFootfall"] ?? 0,
                row["RetailCentreYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"] ?? 0
            );
        });
    } catch (error) {
        dispatch(logError("Error loading RetailCentreFootfall.", error));
        throw error;
    }
};
