import React from "react";

import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
    DemographicIndicatorMeasure as DemographicIndicatorMeasureEnum
} from "modules/customer/insights/portfolioNew/catchment/catchmentDemographics";
import {
    selectDemographicIndicatorMeasure,
    setDemographicIndicatorMeasure
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButtonGroup = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark,
        marginBottom: theme.spacing(2),
        borderRadius: "6px"
    },
}))(ButtonGroup);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
        color: theme.palette.common.white,
        borderRadius: "6px",
        "&:hover": {
            // @ts-ignore
            backgroundColor: (props: any) => props["data-is-selected"] ? theme.palette.quaternary.light : theme.palette.quaternary.dark,
            color: theme.palette.common.white,
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:firs-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:first-child)": {
            border: 0,
            borderRadius: "6px"
        },
        "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
            border: 0,
            borderRadius: "6px"
        }
    }
}))(Button);

const DemographicIndicatorMeasure: React.FC = () => {
    const dispatch = useAppDispatch();
    const demographicIndicatorMeasure = useAppSelector(selectDemographicIndicatorMeasure);

    const handlePercentageOfPopulationClick = () => {
        dispatch(setDemographicIndicatorMeasure(DemographicIndicatorMeasureEnum.PercentageOfPopulation));
    };

    const handlePopulationInThousandsClick = () => {
        dispatch(setDemographicIndicatorMeasure(DemographicIndicatorMeasureEnum.PopulationInThousands));
    };

    return (
        <StyledButtonGroup
            variant="contained"
            size="medium"
            color="default"
            disableElevation
        >
            <StyledButton
                data-is-selected={demographicIndicatorMeasure === DemographicIndicatorMeasureEnum.PercentageOfPopulation}
                onClick={handlePercentageOfPopulationClick}
            >
                <Typography variant="subtitle2" component="div">
                    % of population
                </Typography>
            </StyledButton>
            <StyledButton
                data-is-selected={demographicIndicatorMeasure === DemographicIndicatorMeasureEnum.PopulationInThousands}
                onClick={handlePopulationInThousandsClick}
            >
                <Typography variant="subtitle2" component="div">
                    Population
                </Typography>
            </StyledButton>
        </StyledButtonGroup>
    );
};

export default DemographicIndicatorMeasure;
