import React from "react";

import Chapter from "components/customer/insights/Chapter";
import Page from "components/customer/insights/Page";
import Subchapter from "components/customer/insights/Subchapter";

import OverviewSubtitle from "./overview/OverviewSubtitle";
import CompanyHealthSubchapter from "./overview/companyHealth/CompanyHealthSubchapter";
import CompanyHealthSubtitle from "./overview/companyHealth/CompanyHealthSubtitle";
import KeyPerformanceIndicators from "./overview/keyPerformanceIndicators/KeyPerformanceIndicators";
import Recommendations from "./overview/recommendations/Recommendations";

import SalesSubtitle from "./sales/SalesSubtitle";
import RevenueOverTime from "./sales/revenueOverTime/RevenueOverTime";
import RevenueOverTimeSubtitle from "./sales/revenueOverTime/RevenueOverTimeSubtitle";
import RevenueGrowthBreakdown from "./sales/revenueGrowthBreakdown/RevenueGrowthBreakdown";
import RevenueGrowthBreakdownSubtitle from "./sales/revenueGrowthBreakdown/RevenueGrowthBreakdownSubtitle";
import RevenueGrowth from "./sales/revenueGrowth/RevenueGrowth";
import RevenueGrowthSubtitle from "./sales/revenueGrowth/RevenueGrowthSubtitle";
import ContributionGrowth from "./sales/contributionGrowth/ContributionGrowth";
import ContributionGrowthSubtitle from "./sales/contributionGrowth/ContributionGrowthSubtitle";
import RegionalGrowthBreakdown from "./sales/regionalGrowthBreakdown/RegionalGrowthBreakdown";
import RegionalGrowthBreakdownSubtitle from "./sales/regionalGrowthBreakdown/RegionalGrowthBreakdownSubtitle";
import ProductCategoryBreakdown from "./sales/productCategoryBreakdown/ProductCategoryBreakdown";
import ProductCategoryBreakdownSubtitle from "./sales/productCategoryBreakdown/ProductCategoryBreakdownSubtitle";

import ForecastSubtitle from "./forecast/ForecastSubtitle";
import ForecastRevenue from "./forecast/forecastRevenue/ForecastRevenue";
import ForecastRevenueSubtitle from "./forecast/forecastRevenue/ForecastRevenueSubtitle";
import ForecastGrowthVsMarket from "./forecast/forecastGrowthVsMarket/ForecastGrowthVsMarket";
import ForecastGrowthVsMarketSubtitle from "./forecast/forecastGrowthVsMarket/ForecastGrowthVsMarketSubtitle";
import RegionalBreakdown from "./forecast/regionalBreakdown/RegionalBreakdown";
import RegionalBreakdownSubtitle from "./forecast/regionalBreakdown/RegionalBreakdownSubtitle";

import BudgetSubtitle from "./budget/BudgetSubtitle";
import RevenueVsBudget from "./budget/revenueVsBudget/RevenueVsBudget";
import RevenueVsBudgetSubtitle from "./budget/revenueVsBudget/RevenueVsBudgetSubtitle";
import RevenueVsBudgetOverTime from "./budget/revenueVsBudgetOverTime/RevenueVsBudgetOverTime";
import RevenueVsBudgetOverTimeSubtitle from "./budget/revenueVsBudgetOverTime/RevenueVsBudgetOverTimeSubtitle";
import ContributionVsBudget from "./budget/contributionVsBudget/ContributionVsBudget";
import ContributionVsBudgetSubtitle from "./budget/contributionVsBudget/ContributionVsBudgetSubtitle";
import StorePerformanceAgainstBudget from "./budget/storePerformanceAgainstBudget/StorePerformanceAgainstBudget";
import StorePerformanceAgainstBudgetSubtitle
    from "./budget/storePerformanceAgainstBudget/StorePerformanceAgainstBudgetSubtitle";
//import BudgetVsForecastRevenue from "./budget/budgetVsForecastRevenue/BudgetVsForecastRevenue";
//import BudgetVsForecastRevenueSubtitle from "./budget/budgetVsForecastRevenue/BudgetVsForecastRevenueSubtitle";
//import BudgetedVsForecastContribution from "./budget/budgetedVsForecastContribution/BudgetedVsForecastContribution";
//import BudgetedVsForecastContributionSubtitle
//    from "./budget/budgetedVsForecastContribution/BudgetedVsForecastContributionSubtitle";

import { useAppDispatch, useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import {
    clearPerformanceData,
    getPerformanceData,
    selectIsSetupComplete,
    setSubchaptersIds
} from "modules/customer/insights/performance/performanceSlice";

const OVERVIEW_CHAPTER_ID = "overview";
const COMPANY_HEALTH_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_company-health`;
const KEY_PERFORMANCE_INDICATORS_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_key-performance-indicators`;
const RECOMMENDATIONS_SUBCHAPTER_ID = `${OVERVIEW_CHAPTER_ID}_recommendations`;

const SALES_CHAPTER_ID = "sales";
const REVENUE_OVER_TIME_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_revenue-over-time`;
const REVENUE_GROWTH_BREAKDOWN_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_revenue-growth-breakdown`;
const REVENUE_GROWTH_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_revenue-growth`;
const CONTRIBUTION_GROWTH_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_contribution-growth`;
const REGIONAL_GROWTH_BREAKDOWN_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_regional-growth-breakdown`;
const PRODUCT_CATEGORY_BREAKDOWN_SUBCHAPTER_ID = `${SALES_CHAPTER_ID}_product-category-breakdown`;

const FORECAST_CHAPTER_ID = "forecast";
const FORECAST_REVENUE_SUBCHAPTER_ID = `${FORECAST_CHAPTER_ID}_forecast-revenue`;
const FORECAST_GROWTH_VS_MARKET_SUBCHAPTER_ID = `${FORECAST_CHAPTER_ID}_forecast-growth-vs-market`;
const REGIONAL_BREAKDOWN_SUBCHAPTER_ID = `${FORECAST_CHAPTER_ID}_regional-breakdown`;

const BUDGET_CHAPTER_ID = "budget";
const REVENUE_VS_BUDGET_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_revenue-vs-budget`;
const REVENUE_VS_BUDGET_OVER_TIME_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_revenue-vs-budget-over-time`;
const CONTRIBUTION_VS_BUDGET_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_contribution-vs-budget`;
const STORE_PERFORMANCE_AGAINST_BUDGET_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_store-performance-against-budget`;
const BUDGET_VS_FORECAST_REVENUE_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_budget-vs-forecast-revenue`;
const BUDGETED_VS_FORECAST_CONTRIBUTION_SUBCHAPTER_ID = `${BUDGET_CHAPTER_ID}_budgeted-vs-forecast-contribution`;

const Performance: React.FC = () => {
    const dispatch = useAppDispatch();
    const isSetupComplete = useAppSelector(selectIsSetupComplete);
    const userInfo = useAppSelector(selectUserInfo);

    const overviewChapterRef = React.useRef(null);
    const companyHealthSubchapterRef = React.useRef(null);
    const keyPerformanceIndicatorsSubchapterRef = React.useRef(null);
    const recommendationsSubchapterRef = React.useRef(null);

    const salesChapterRef = React.useRef(null);
    const revenueOverTimeSubchapterRef = React.useRef(null);
    const revenueGrowthBreakdownSubchapterRef = React.useRef(null);
    const revenueGrowthSubchapterRef = React.useRef(null);
    const contributionGrowthSubchapterRef = React.useRef(null);
    const regionalGrowthBreakdownSubchapterRef = React.useRef(null);
    const productCategoryBreakdownSubchapterRef = React.useRef(null);

    const forecastChapterRef = React.useRef(null);
    const forecastRevenueSubchapterRef = React.useRef(null);
    const forecastGrowthVsMarketSubchapterRef = React.useRef(null);
    const regionalBreakdownSubchapterRef = React.useRef(null);

    const budgetChapterRef = React.useRef(null);
    const revenueVsBudgetSubchapterRef = React.useRef(null);
    const revenueVsBudgetOverTimeSubchapterRef = React.useRef(null);
    const contributionVsBudgetSubchapterRef = React.useRef(null);
    const storePerformanceAgainstBudgetSubchapterRef = React.useRef(null);
    //const budgetVsForecastRevenueSubchapterRef = React.useRef(null);
    //const budgetedVsForecastContributionSubchapterRef = React.useRef(null);

    React.useEffect(() => {
        dispatch(getPerformanceData());
        dispatch(setSubchaptersIds({
            overview: {
                companyHealth: COMPANY_HEALTH_SUBCHAPTER_ID,
                KeyPerformanceIndicators: KEY_PERFORMANCE_INDICATORS_SUBCHAPTER_ID,
                recommendations: RECOMMENDATIONS_SUBCHAPTER_ID
            },
            sales: {
                revenueOverTime: REVENUE_OVER_TIME_SUBCHAPTER_ID,
                revenueGrowthBreakdown: REVENUE_GROWTH_BREAKDOWN_SUBCHAPTER_ID,
                revenueGrowth: REVENUE_GROWTH_SUBCHAPTER_ID,
                contributionGrowth: CONTRIBUTION_GROWTH_SUBCHAPTER_ID,
                regionalGrowthBreakdown: REGIONAL_GROWTH_BREAKDOWN_SUBCHAPTER_ID,
                productCategoryBreakdown: PRODUCT_CATEGORY_BREAKDOWN_SUBCHAPTER_ID
            },
            forecast: {
                forecastRevenue: FORECAST_REVENUE_SUBCHAPTER_ID,
                forecastGrowthVsMarket: FORECAST_GROWTH_VS_MARKET_SUBCHAPTER_ID,
                regionalBreakdown: REGIONAL_BREAKDOWN_SUBCHAPTER_ID
            },
            budget: {
                revenueVsBudget: REVENUE_VS_BUDGET_SUBCHAPTER_ID,
                revenueVsBudgetOverTime: REVENUE_VS_BUDGET_OVER_TIME_SUBCHAPTER_ID,
                contributionVsBudget: CONTRIBUTION_VS_BUDGET_SUBCHAPTER_ID,
                storePerformanceAgainstBudget: STORE_PERFORMANCE_AGAINST_BUDGET_SUBCHAPTER_ID,
                budgetVsForecastRevenue: BUDGET_VS_FORECAST_REVENUE_SUBCHAPTER_ID,
                budgetedVsForecastContribution: BUDGETED_VS_FORECAST_CONTRIBUTION_SUBCHAPTER_ID
            }
        }));
        return () => {
            dispatch(clearPerformanceData());
        };
    }, [dispatch]);

    return (
        <Page
            title="Performance"
            companyName={userInfo.companyDisplayName}
            isSetupComplete={isSetupComplete}
            dataCy="customer-insights-performance"
        >
            <Chapter
                id={OVERVIEW_CHAPTER_ID}
                number={1}
                title="Overview"
                subtitle={<OverviewSubtitle />}
                chapterRef={overviewChapterRef}
                dataCy="overview"
            >
                <CompanyHealthSubchapter
                    id={COMPANY_HEALTH_SUBCHAPTER_ID}
                    number={1.1}
                    title="Company health"
                    subtitle={<CompanyHealthSubtitle />}
                    subchapterRef={companyHealthSubchapterRef}
                    dataCy="company-health"
                />
                <Subchapter
                    id={KEY_PERFORMANCE_INDICATORS_SUBCHAPTER_ID}
                    number={1.2}
                    title="Key performance indicators"
                    subchapterRef={keyPerformanceIndicatorsSubchapterRef}
                    dataCy="key-performance-indicators"
                >
                    <KeyPerformanceIndicators />
                </Subchapter>
                <Subchapter
                    id={RECOMMENDATIONS_SUBCHAPTER_ID}
                    number={1.3}
                    title="Recommendations"
                    subchapterRef={recommendationsSubchapterRef}
                    dataCy="recommendations"
                >
                    <Recommendations />
                </Subchapter>
            </Chapter>
            <Chapter
                id={SALES_CHAPTER_ID}
                number={2}
                title="Sales"
                subtitle={<SalesSubtitle />}
                chapterRef={salesChapterRef}
                dataCy="sales"
            >
                <Subchapter
                    id={REVENUE_OVER_TIME_SUBCHAPTER_ID}
                    number={2.1}
                    title="Revenue over time"
                    subtitle={<RevenueOverTimeSubtitle />}
                    subchapterRef={revenueOverTimeSubchapterRef}
                    dataCy="revenue-over-time"
                >
                    <RevenueOverTime />
                </Subchapter>
                <Subchapter
                    id={REVENUE_GROWTH_BREAKDOWN_SUBCHAPTER_ID}
                    number={2.2}
                    title="Revenue growth breakdown"
                    subtitle={<RevenueGrowthBreakdownSubtitle />}
                    subchapterRef={revenueGrowthBreakdownSubchapterRef}
                    dataCy="revenue-growth-breakdown"
                >
                    <RevenueGrowthBreakdown />
                </Subchapter>
                <Subchapter
                    id={REVENUE_GROWTH_SUBCHAPTER_ID}
                    number={2.3}
                    title="Revenue growth"
                    subtitle={<RevenueGrowthSubtitle />}
                    subchapterRef={revenueGrowthSubchapterRef}
                    dataCy="revenue-growth"
                >
                    <RevenueGrowth />
                </Subchapter>
                <Subchapter
                    id={CONTRIBUTION_GROWTH_SUBCHAPTER_ID}
                    number={2.4}
                    title="Contribution growth"
                    subtitle={<ContributionGrowthSubtitle />}
                    subchapterRef={contributionGrowthSubchapterRef}
                    dataCy="contribution-growth"
                >
                    <ContributionGrowth />
                </Subchapter>
                <Subchapter
                    id={REGIONAL_GROWTH_BREAKDOWN_SUBCHAPTER_ID}
                    number={2.5}
                    title="Regional growth breakdown"
                    subtitle={<RegionalGrowthBreakdownSubtitle />}
                    subchapterRef={regionalGrowthBreakdownSubchapterRef}
                    dataCy="regional-growth-breakdown"
                >
                    <RegionalGrowthBreakdown />
                </Subchapter>
                <Subchapter
                    id={PRODUCT_CATEGORY_BREAKDOWN_SUBCHAPTER_ID}
                    number={2.6}
                    title="Product category breakdown"
                    subtitle={<ProductCategoryBreakdownSubtitle />}
                    subchapterRef={productCategoryBreakdownSubchapterRef}
                    dataCy="product-category-breakdown"
                >
                    <ProductCategoryBreakdown />
                </Subchapter>
            </Chapter>
            <Chapter
                id={FORECAST_CHAPTER_ID}
                number={3}
                title="Forecast"
                subtitle={<ForecastSubtitle />}
                chapterRef={forecastChapterRef}
                dataCy="forecast"
            >
                <Subchapter
                    id={FORECAST_REVENUE_SUBCHAPTER_ID}
                    number={3.1}
                    title="Forecast revenue"
                    subtitle={<ForecastRevenueSubtitle />}
                    subchapterRef={forecastRevenueSubchapterRef}
                    dataCy="forecast-revenue"
                >
                    <ForecastRevenue />
                </Subchapter>
                <Subchapter
                    id={FORECAST_GROWTH_VS_MARKET_SUBCHAPTER_ID}
                    number={3.2}
                    title="Forecast growth vs market"
                    subtitle={<ForecastGrowthVsMarketSubtitle />}
                    subchapterRef={forecastGrowthVsMarketSubchapterRef}
                    dataCy="forecast-growth-vs-market"
                >
                    <ForecastGrowthVsMarket />
                </Subchapter>
                <Subchapter
                    id={REGIONAL_BREAKDOWN_SUBCHAPTER_ID}
                    number={3.3}
                    title="Regional breakdown"
                    subtitle={<RegionalBreakdownSubtitle />}
                    subchapterRef={regionalBreakdownSubchapterRef}
                    dataCy="regional-breakdown"
                >
                    <RegionalBreakdown />
                </Subchapter>
            </Chapter>
            <Chapter
                id={BUDGET_CHAPTER_ID}
                number={4}
                title="Budget"
                subtitle={<BudgetSubtitle />}
                chapterRef={budgetChapterRef}
                dataCy="budget"
            >
                <Subchapter
                    id={REVENUE_VS_BUDGET_SUBCHAPTER_ID}
                    number={4.1}
                    title="Revenue vs budget"
                    subtitle={<RevenueVsBudgetSubtitle />}
                    subchapterRef={revenueVsBudgetSubchapterRef}
                    dataCy="revenue-vs-budget"
                >
                    <RevenueVsBudget />
                </Subchapter>
                <Subchapter
                    id={REVENUE_VS_BUDGET_OVER_TIME_SUBCHAPTER_ID}
                    number={4.2}
                    title="Revenue vs budget over time"
                    subtitle={<RevenueVsBudgetOverTimeSubtitle />}
                    subchapterRef={revenueVsBudgetOverTimeSubchapterRef}
                    dataCy="revenue-vs-budget-over-time"
                >
                    <RevenueVsBudgetOverTime />
                </Subchapter>
                <Subchapter
                    id={CONTRIBUTION_VS_BUDGET_SUBCHAPTER_ID}
                    number={4.3}
                    title="Contribution vs budget"
                    subtitle={<ContributionVsBudgetSubtitle />}
                    subchapterRef={contributionVsBudgetSubchapterRef}
                    dataCy="contribution-vs-budget"
                >
                    <ContributionVsBudget />
                </Subchapter>
                <Subchapter
                    id={STORE_PERFORMANCE_AGAINST_BUDGET_SUBCHAPTER_ID}
                    number={4.4}
                    title="Store performance against budget"
                    subtitle={<StorePerformanceAgainstBudgetSubtitle />}
                    subchapterRef={storePerformanceAgainstBudgetSubchapterRef}
                    dataCy="store-performance-against-budget"
                >
                    <StorePerformanceAgainstBudget />
                </Subchapter>
                {/*<Subchapter*/}
                {/*    id={BUDGET_VS_FORECAST_REVENUE_SUBCHAPTER_ID}*/}
                {/*    number={4.5}*/}
                {/*    title="Budgeted vs forecast revenue"*/}
                {/*    subtitle={<BudgetVsForecastRevenueSubtitle />}*/}
                {/*    subchapterRef={budgetVsForecastRevenueSubchapterRef}*/}
                {/*    dataCy="budget-vs-forecast-revenue"*/}
                {/*>*/}
                {/*    <BudgetVsForecastRevenue />*/}
                {/*</Subchapter>*/}
                {/*<Subchapter*/}
                {/*    id={BUDGETED_VS_FORECAST_CONTRIBUTION_SUBCHAPTER_ID}*/}
                {/*    number={4.6}*/}
                {/*    title="Budgeted vs forecast contribution"*/}
                {/*    subtitle={<BudgetedVsForecastContributionSubtitle />}*/}
                {/*    subchapterRef={budgetedVsForecastContributionSubchapterRef}*/}
                {/*    dataCy="budgeted-vs-forecast-contribution"*/}
                {/*>*/}
                {/*    <BudgetedVsForecastContribution />*/}
                {/*</Subchapter>*/}
            </Chapter>
        </Page>
    );
};

export default Performance;
