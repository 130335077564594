import React from "react";

import RAGIndicator from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectRevenuePerHeadCategorisation } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const RevenuePerHeadCategorisation = () => {
    const categorisation = useAppSelector(selectRevenuePerHeadCategorisation);

    return (
        <RAGIndicator ragIndicator={categorisation} />
    );
};

export default RevenuePerHeadCategorisation;
