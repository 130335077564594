import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { getUserInfo } from "modules/auth/authSlice";
import { apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

export enum OnboardingStep {
    Welcome,
    PlanDetails,
    TermsOfUse,
    Payment,
    PaymentResult
}

interface CheckoutSession {
    id: string,
    subscriptionId: string,
    isComplete: boolean
}

interface Subscription {
    companyName: string,
    companyRegistrationNumber: string,
    initialTermInYears: number,
    planName: string,
    priceInPence: number,
    planDescription: string,
    features: string[],
    footnotes: string[]
}

interface OnboardingState {
    activeStep: OnboardingStep,
    checkoutSession: CheckoutSession,
    subscription: Subscription,
    canAcceptTermsOfUse: boolean
}

const checkoutSessionEmpty: CheckoutSession = {
    id: "",
    subscriptionId: "",
    isComplete: false
};

const subscriptionEmpty: Subscription = {
    companyName: "",
    companyRegistrationNumber: "",
    initialTermInYears: 0,
    planName: "",
    priceInPence: 0,
    planDescription: "",
    features: [],
    footnotes: []
};

const initialState: OnboardingState = {
    activeStep: OnboardingStep.Welcome,
    checkoutSession: checkoutSessionEmpty,
    subscription: subscriptionEmpty,
    canAcceptTermsOfUse: false
};

const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        setActiveStep: (state, action: PayloadAction<OnboardingStep>) => {
            state.activeStep = action.payload;
        },
        setCheckoutSession: (state, action: PayloadAction<CheckoutSession>) => {
            state.checkoutSession = action.payload;
        },
        clearCheckoutSession: (state) => {
            state.checkoutSession = checkoutSessionEmpty;
        },
        setSubscription: (state, action: PayloadAction<Subscription>) => {
            state.subscription = action.payload;
        },
        clearSubscription: (state) => {
            state.subscription = subscriptionEmpty;
        },
        setCanAcceptTermsOfUse: (state, action: PayloadAction<boolean>) => {
            state.canAcceptTermsOfUse = action.payload;
        }
    }
});

export const {
    setActiveStep,
    setCanAcceptTermsOfUse
} = onboardingSlice.actions;

export const getCheckoutSession = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/onboarding/checkout-session"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const checkoutSession = response.data.checkoutSession;
            dispatch(onboardingSlice.actions.setCheckoutSession(checkoutSession));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        default: {
            dispatch(onboardingSlice.actions.clearCheckoutSession());
            break;
        }
    }
};

export const getSubscription = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/onboarding/subscription"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const subscription = response.data.subscription;
            dispatch(onboardingSlice.actions.setSubscription(subscription));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
        default: {
            dispatch(onboardingSlice.actions.clearSubscription());
            break;
        }
    }
};

export const downloadTermsOfUse = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/onboarding/terms-of-use"));
    if (response.status === ApiResponseStatus.Ok) {
        const url = response.data.url;
        window.open(url, "_blank");
    }
};

const provisionInfrastructure = (): AppThunk => (dispatch) => {
    dispatch(apiPut("/customer/onboarding/infrastructure", {}));
};

export const acceptTermsOfUse = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPut("/customer/onboarding/terms-of-use", {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(provisionInfrastructure());
            dispatch(onboardingSlice.actions.setActiveStep(OnboardingStep.Payment));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const setupPayment = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost("/customer/onboarding/checkout-session", {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const checkoutSession = response.data.checkoutSession;
            window.location = checkoutSession.url;
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const completeOnboarding = (): AppThunk => async (dispatch) => {
    const response = await dispatch(apiPost("/customer/onboarding/complete", {}));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(getUserInfo());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            break;
        }
    }
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.onboarding.activeStep;
};

export const selectCheckoutSession = (state: RootState) => {
    return state.customer.onboarding.checkoutSession;
};

export const selectSubscription = (state: RootState) => {
    return state.customer.onboarding.subscription;
};

export const selectCanAcceptTermsOfUse = (state: RootState) => {
    return state.customer.onboarding.canAcceptTermsOfUse;
};

export default onboardingSlice;
