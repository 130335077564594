import React from "react";
import { useAppSelector } from "store";

import RAGIndicatorNew from "components/visuals/RAGIndicatorNew";

import { selectDistanceToHotspot } from "modules/customer/insights/portfolioNew/competition/competitionSlice";

const DistanceToHotspot = () => {
    const distanceToHotspot = useAppSelector(selectDistanceToHotspot);

    return (
        <RAGIndicatorNew ragIndicator={distanceToHotspot} />
    );
};

export default DistanceToHotspot;
