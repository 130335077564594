import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    orderedList: {
        listStyleType: "none",
        counterReset: "section",
        padding: theme.spacing(0),
        margin: theme.spacing(0)
    },
    title: {
        fontSize: theme.spacing(4),
        fontWeight: 600,
        display: "table",
        counterIncrement: "section",
        paddingBottom: theme.spacing(7),
        "&::before": {
            content: "counters(section, '.')",
            paddingRight: theme.spacing(5.5),
            paddingBottom: "8px"
        }
    },
    subtitle: {
        fontSize: "16px",
        fontWeight: 400,
        display: "table",
        counterIncrement: "section",
        paddingTop: theme.spacing(2),
        "&::before": {
            content: "counters(section, '.')",
            display: "table-cell",
            paddingRight: theme.spacing(5)
        }
    },
    indentedSubtitle: {
        fontSize: theme.spacing(2),
        fontWeight: 400,
        display: "table",
        counterIncrement: "section",
        paddingTop: theme.spacing(1),
        "&::before": {
            content: "counters(section, '.')",
            display: "table-cell",
            paddingRight: theme.spacing(3)
        }
    },
    titleTwo: {
        fontSize: theme.spacing(4),
        fontWeight: 600,
        display: "table",
        counterIncrement: "section",
        paddingBottom: theme.spacing(7),
        "&::before": {
            content: "counters(section, '.')",
            paddingRight: theme.spacing(3)
        }
    },
    subtitleTwo: {
        fontSize: theme.spacing(2),
        fontWeight: 400,
        display: "table",
        counterIncrement: "section",
        paddingTop: theme.spacing(2),
        "&::before": {
            content: "counters(section, '.')",
            display: "table-cell",
            paddingRight: theme.spacing(4)
        }
    }
}));

interface TermsOfUseContentsProps {
    linkColor: string
}

const TermsOfUseContents: React.FC<TermsOfUseContentsProps> = (props) => {
    const classes = useStyles();
    const { linkColor } = props;
    return (
        <ol className={classes.orderedList}>
            <li className={classes.title}>Introduction
                <ol className={classes.orderedList} >
                    <li className={classes.subtitle}><Typography variant="subtitle1">These Terms of Use apply to the provision of Dash, the Documentation and the Support Services.</Typography></li>
                    <li className={classes.subtitle}>
                        <Typography variant="h6" gutterBottom><span style={{ fontWeight: "bold" }}>By clicking on the "Accept" button below the Licensee agrees to the terms of this Agreement. A copy of these Terms of Use is available by clicking on the "Download" button below.</span></Typography>
                        <Typography variant="h6"><span style={{ fontWeight: "bold" }}>You agree to the provisions of this Agreement on the Licensee's behalf and confirm by accepting the terms of this Agreement that you have the authority to enter into this Agreement on the Licensee's behalf.</span></Typography>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">If there is any inconsistency between the documents that form this Agreement, such inconsistency shall be resolved as follows:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">The Plan Details, which shall take precedence over</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">these Terms of Use.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">KPMG may revise these Terms of Use from time to time by a variation to these Terms of Use which shall be notified to the Licensee at the email address provided by the Licensee to KPMG to receive these notifications. If the Licensee objects to the variation, the Licensee shall notify KPMG within 10 Business Days of the date the variation is sent by KPMG to the Licensee and KPMG has the right to terminate this Agreement on 5 Business Days written notice to the Licensee.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Definitions
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}>
                        <Typography variant="subtitle1" gutterBottom style={{ lineHeight: "22px" }}>The definitions in this clause 2.1 apply to this Agreement.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Acceptance Date"</span> means the date the Licensee accepts Dash under clause 3.3.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Affiliates"</span> means any legal entity that, directly or indirectly, Controls, or is Controlled by, or is under common Control with the Licensee.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Agreement"</span> means the contract formed by the Plan Details and these Terms of Use.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Authorised Purpose"</span> means the following purpose: Dash may be used to support business activities and decision-making specific to the Licensee's business.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Authorised User"</span> means users of the Licensee and its Affiliates who are given access to Dash and the Documentation.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Business Days"</span> means Monday to Friday, excluding UK bank holidays and public holidays.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Commencement Date"</span> means the date this Agreement is accepted by the Licensee.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Confidential Information"</span> means any information that has been or will be made available directly or indirectly by one Party to the other in connection with Dash or the Support Services that is marked or communicated as confidential or whose nature is such that a recipient would reasonably consider it confidential, including, without limitation, all information embodied in Dash and Documentation, business plans, proposals, product development details, methodologies, software specifications and code and financial information. Confidential Information excludes Excluded Information.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Control"</span> means, in relation to a body corporate, the power to secure that its affairs are conducted in accordance with the wishes of the holding company, as defined in Section 1159 of the Companies Act 2006, and <span style={{ fontWeight: "bold" }}>Controlled</span> shall be construed accordingly.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Dash"</span> means the Dash analytics web platform that allows Authorised Users to analyse their uploaded business information in accordance with the functionality as set out in the Plan Details. It includes any changes, additions or modifications to the Dash analytics web platform including Updates.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Documentation"</span> means the user instructions and all other written or electronic information that KPMG provides to the Licensee, from time to time, for Dash and the Support Services.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Excluded Information"</span> means information that: (a) is or becomes generally available in the public domain through no fault of either the receiving Party or those to whom the receiving Party has disclosed the Confidential Information; or (b) was or becomes known to the receiving Party free of any obligation of confidence from a third party entitled to make such disclosure; or (c) was developed by a Party independently of the other Party's Confidential Information.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Fees"</span> means the fees set out in the Plan Details.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Helpdesk"</span> means an email and/or telephone help desk support service as specified in the Plan Details, for consultation and support between the hours of 0900 and 1700, UK time, Monday to Friday excluding bank holidays and public holidays in England.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Hosting Provider"</span> means a third party supplier who provides KPMG with the facilities and/or services to host Dash.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Initial Term"</span> means the initial term for which Dash is provided, as set out in the Plan Details.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"IPRs"</span> means all intellectual property rights including all rights in and to inventions, utility models, patents, copyright, trade marks, logos, trade and business names, rights in designs, rights in computer software, database rights, moral rights, rights in confidential information (including know-how and trade secrets) in every case whether registered or unregistered and all similar or equivalent rights or forms of protection (whether now or in the future) anywhere and references to <span style={{ fontWeight: "bold" }}>IPR</span> means any of them.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"KPMG" or "our"</span> means KPMG LLP, a limited liability partnership registered in England and Wales (under registration number OC301540) having its registered office at 15 Canada Square, London E14 5GL.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"KPMG Persons"</span> means KPMG and all of KPMG's members, employees, suppliers and agents together with any entity associated with KPMG, and all of its partners, directors, employees and agents, and <span style={{ fontWeight: "bold" }}>KPMG Person</span> means any of them.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Licensee", "you"</span> or <span style={{ fontWeight: "bold" }}>"your"</span> means the "Licensee" named in the Plan Details.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Licensee Data"</span> means all information and data: (a) that the Licensee or any Affiliate provides to KPMG for the purposes of the provision of Dash and the Support Services; or (b) that is generated by Dash using information or data referred to in (a), except to the extent that the information or data generated consists of KPMG's IPR or that of KPMG's third party licensors.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Party"</span> means either the Licensee or KPMG (as appropriate) and Parties means both the Licensee and KPMG.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Plan Details"</span> means the order for the licence for Dash and the Support Services that is issued by KPMG to the Licensee.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Renewal Term"</span> means as defined in clause 3.2 of these Terms of Use.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Support Services"</span> means any support services set out in clause 7 of these Terms of Use.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Technical Requirements"</span> means the technical or other system requirements set out in clause 6.5 of these Terms of Use, and as KPMG may update and notify the Licensee from time to time.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Term"</span> means the period from the Commencement Date until termination or expiry of this Agreement.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Terms of Use"</span> means these terms and conditions.</Typography>
                        <Typography variant="subtitle1" gutterBottom><span style={{ fontWeight: "bold" }}>"Third Party Suppliers"</span> means suppliers of services to the Licensee who are not Affiliates.</Typography>
                        <Typography variant="subtitle1"><span style={{ fontWeight: "bold" }}>"Update"</span> means any error correction, patch fix, new releases or data update to Dash that KPMG provides at KPMG's discretion from time to time.</Typography>
                    </li>
                </ol>
            </li>
            <li className={classes.title}>Commencement and Duration
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">This Agreement shall come into force on the Commencement Date.</Typography></li>
                    <li className={classes.subtitle}>
                        <Typography variant="subtitle1">Unless either Party terminates earlier in accordance with these Terms of Use, this Agreement is for the Initial Term and shall then automatically renew for successive 12 months period (each a Renewal Term).</Typography>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee may not use Dash other than for acceptance and implementation until the Licensee either:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">complies with any acceptance requirements for the implementation services; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">where there are no acceptance provisions for the implementation services, the Licensee is notified in writing by KPMG that implementation has been completed.</Typography></li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li className={classes.title}>Licence
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Subject to clause 3.3 and payment of the Fees, KPMG grants to the Licensee a non-exclusive, non-transferable licence to use Dash and the Documentation for the Term. This licence shall only extend to use of Dash and the Documentation that is:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">for the Licensee's own internal business purposes; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">as described in the Authorised Purpose. The Licensee shall not access, use, sub-license or allow any third party to use or access Dash or the Documentation except as expressly permitted by this Agreement.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Subject to clauses 4.3 and 4.5, and the Licensee's compliance with clause 18.4, the Licensee may make Dash and the Documentation available to the Licensee's Affiliates where necessary for the Licensee's receipt of the services that such Affiliates provide to the Licensee, provided always and in each case, that the Licensee has obtained KPMG's prior written consent. Any such use must be solely for the Authorised Purpose and for the internal business purposes of the Licensee and such Affiliates may only use Dash and the Documentation in connection with the services that they provide to the Licensee, and is permitted subject to the restrictions, exclusions and limits on KPMG's liability contained in this Agreement. The Licensee shall procure the agreement of any such Affiliate to these Terms of Use as if it were the Licensee. If the Licensee fails to obtain such agreement the Licensee shall hold KPMG harmless from the consequences of such failure.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">If any Affiliate ceases to be a supplier of the services in respect of which they have been using Dash or the Documentation to the Licensee, then with immediate effect, the Licensee shall not be entitled to make available Dash or the Documentation to such former Affiliate, and the Licensee must immediately ensure that the former Affiliate ceases use of Dash and the Documentation and that all copies of the Documentation in the possession or control of the relevant Affiliate are destroyed.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">If:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">any entity to which the Licensee made Dash and the Documentation available too pursuant to clause 4.2 is proposed to cease or ceases to be an Affiliate; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1" gutterBottom>there is a proposed or actual change in Control of the Licensee,</Typography></li>
                        </ol>
                        <Typography variant="subtitle1">then to the extent the Licensee is aware of and permitted to do so, the Licensee shall promptly notify KPMG in writing and in the case of clause 4.4.2, provide details of who has assumed Control of the Licensee to KPMG.</Typography>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee must ensure that all Affiliates comply with the provisions of this Agreement and the Licensee is liable for any acts or omissions of all Affiliates as if those were the Licensee's own acts or omissions.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee may not remove, obscure or (where permitted copying takes place) omit KPMG's proprietary notices or those of KPMG's third party licensors from Dash or the Documentation.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Authorised Users
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Use of Dash is restricted to Authorised Users. No Third Party Supplier is permitted to access Dash or the Documentation.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee is allowed the maximum number of Authorised Users as set out in the Plan Details at any one time.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee is responsible for:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">initiating access for Authorised Users and for setting the permission level that each Authorised User shall have;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">deleting the account for any Authorised User who no longer needs access to Dash; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">changing an Authorised User's password if the Licensee believes any event or action has taken place which might reasonably impair Dash's security, such as lost passwords or improper access to the account of an Authorised User.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Authorised Users are responsible for maintaining the security of their username and password.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">If the Licensee exceeds the maximum number of Authorised Users set out in the Plan Details, the Licensee shall promptly notify KPMG and KPMG shall have the right to charge the Licensee an additional Fee for these additional Authorised Users.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Authorised Users use of Dash will be tracked and assessed for the purpose of tailoring and improving Dash to help unlock better insights.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Hosting and Technical Requirements
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">KPMG shall host Dash and any Licensee Data using Microsoft Azure and using data centres controlled by Microsoft in the UK. KPMG may, upon prior written notice to the Licensee, host Dash using servers operated by KPMG or by a different third party on KPMG's behalf.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">KPMG shall perform all maintenance and support necessary to operate the environment in which it hosts Dash.  KPMG may modify the environment in which it hosts (or procures the hosting of) Dash as KPMG reasonably considers necessary provided that such modifications shall not diminish the capacity, quality or security of such environment or the quality or performance of Dash.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee shall comply with Microsoft's current acceptable use policy, as provided to the Licensee and as may be updated from time to time. As at the Commencement Date, Microsoft's acceptable use policy is as set out in clause 6.4. KPMG may suspend the Licensee's access to Dash if clause 6.4 is violated.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Licensee may not use Dash:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">in a way prohibited by law, regulation, governmental order or decree;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">to violate the rights of others;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">to try to gain unauthorised access to or disrupt any service, device, data, account or network;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">to spam or distribute malware;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">in a way that could harm the hosting environment or impair anyone's use of it;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">in any application or situation where failure of the hosting environment could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">to assist or encourage anyone to do any of the above.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The latest versions available for Chrome, Microsoft Edge and Safari internet browsers are currently supported for Dash. The list of supported browsers may be amended by KPMG from time to time as newer browsers are released and any changes shall be notified to the Licensee.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Support Services
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">KPMG shall provide a Helpdesk to assist with technical issues relating to the operation of Dash.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">The Support Services do not include training on the use of Dash, on-site attendance by KPMG, installation of any software or hardware, system design, consulting, software development or the modification, deletion or recovery of data.  If KPMG provides the Licensee with any such services they shall be the subject of a separate written agreement between the Parties.</Typography></li>
                    <li className={classes.subtitle}><Typography variant="subtitle1">If further support is provided to the Licensee by KPMG, these shall be set out in the Licensee's Plan Details.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Updates
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">KPMG may periodically provide the Licensee with Updates. Such Update shall be made available to the Licensee in accordance with this Agreement at no additional charge. KPMG may release an Update at any time.</Typography></li>
                </ol>
            </li>
            <li className={classes.title}>Third Party Data Sources
                <ol className={classes.orderedList}>
                    <li className={classes.subtitle}><Typography variant="subtitle1">Dash contains a number of third party data sources ("<span style={{ fontWeight: "bold" }}>Data Sources</span>"). KPMG shall use reasonable skill and care when selecting Data Sources, and shall carry out appropriate due diligence on a Data Source's background and credentials before using their data in Dash. KPMG shall not independently verify their data, and KPMG makes no warranties about the accuracy or completeness of such data. To the extent permissible by law, KPMG and its associated entities shall not be liable for any errors, omissions, defects or misrepresentations in the information from a Data Source or for any loss or damage suffered by persons who use or rely on such information (including for reasons of negligence, negligent misstatement or otherwise).</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Charges
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to clause 10.8, the Fees shall be payable by the Licensee by a reoccurring monthly or annual (as chosen by the Licensee) payment via KPMG's third party payment provider, Stripe.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee acknowledges that Stripe is used solely in connection with the acceptance and receipt of payments by KPMG in connection with the licence of Dash. The Licensee shall not perform or attempt to perform any actions that interfere with the normal operation of Stripe or affect other users' use of Stripe.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">When using Stripe, the Licensee confirms that they are not, nor are they submitting a transaction on behalf of, a Restricted Business. For the purpose of this clause 10.3, "<span style={{ fontWeight: "bold" }}>Restricted Business</span>" means any business practice for which Stripe cannot be used as identified on the Stripe Restricted Business List (which can be found here: <Link href="https://stripe.com/gb/legal/restricted-businesses" target="_blank" underline="always" style={{ color: linkColor }}>https://stripe.com/gb/legal/restricted-businesses</Link>).</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall be entitled to payment of the Fees (on a pro-rata basis, if applicable) up to the date of termination if this Agreement is terminated.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If any sums payable to KPMG under this Agreement are in arrears for more than thirty (30) days after the due date, KPMG reserves the right without prejudice to any other right or remedy to suspend the Licensee's right to use Dash and the provision of the Support Services without further notice.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Unless otherwise stated in the Plan Details, the Fees are exclusive of any applicable VAT.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">An annual price increase may be applied to the Fees by KPMG. KPMG shall provide at least 60 days written notice before the end of the Initial Term or Renewal Term, as applicable, to the Licensee of the annual price increase.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may, at its discretion, allow the Licensee to pay the Fees via invoice instead of via KPMG's third party payment provider, Stripe. In such cases, the Fees shall be payable by the Licensee by a reoccurring monthly or annual payment (as notified by the Licensee to KPMG) via invoice and the Licensee shall pay KPMG's invoice within 30 days of the date of KPMG's invoice, without any set-off.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>The Licensee's Obligations
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}>
                        <Typography variant="subtitle1">The Licensee must put in place and maintain adequate security measures to safeguard Dash and the Documentation from unauthorised access, use or copying. The Licensee must notify KPMG promptly of any potential or actual misuse of:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">any user accounts or authentication credentials; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">any other security incident related to, or that may affect, Dash, the Documentation or the Support Services.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee is responsible for ensuring that the Licensee's use of Dash complies with any applicable legislation or other legal requirements. To facilitate the provision of Dash, the Licensee shall, where applicable, provide (and procure that the Licensee's personnel and Affiliates provide), in a timely manner, cooperation, information, documents and access to personnel, systems and other facilities as KPMG reasonably need or request and the Licensee shall obtain any necessary licences and permissions to permit KPMG to do so.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee is solely responsible for:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">providing the means by which the Licensee accesses and uses Dash;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">uploading their business information into Dash; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">ensuring that any required access devices, networks and other items are in accordance with the Technical Requirements.</Typography></li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Proprietary Rights and Restrictions
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The IPRs in Dash, the Documentation and any Updates, fixes and other and all related IPRs are KPMG's property or that of KPMG's third party licensors. Except for the licence granted to the Licensee under clause 4, neither the Licensee nor any Affiliate acquires any right or interest of any kind in or to the aforementioned IPRs.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee retains ownership of any Licensee Data. The Licensee grants to KPMG a non-exclusive, non-transferable, worldwide licence to access and use any Licensee Data to the extent necessary to comply with KPMG's obligations under this Agreement (including, where applicable, any of KPMG's obligations which continue after termination of this Agreement). KPMG may sub-license the rights granted under this clause 12.2 to any KPMG Person or to third parties who facilitate the administration of KPMG's business or support KPMG's infrastructure (including, where relevant, Hosting Providers and third parties who assist with the provision of the Support Services).</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee shall not, and the Licensee shall procure that the Licensee's Affiliates do not:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">other than as permitted by clause 4, license, sub-license, sell, resell, make available to third parties, profit from or commercially exploit, or make available for the benefit of any third party, all or any part of Dash or the Documentation;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">except as permitted by law, alter, modify, adapt, disassemble, reverse engineer, decompile, make derivative works of or based on, or access Dash for any reason;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">copy any features, functions or graphics of Dash or the Documentation;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">attempt to access areas of any portal or systems or network on which Dash and the Documentation are hosted or via which Dash is accessed, to which the Licensee has not been given access;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">modify, disable, or compromise the integrity or performance of Dash or any related systems, networks or data, including but not limited to probing, scanning or testing the vulnerability of any system or network that hosts Dash;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">overwhelm or attempt to overwhelm any infrastructure over which Dash is provided; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">infringe any IPR owned or licensed by KPMG or KPMG' third party licensors.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee shall not use KPMG's name, logo or trade marks in any marketing, promotional material or other publication except with KPMG's specific prior written consent.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee agrees that KPMG can use the Licensee's name, logo or trade marks in any marketing, promotional material or other publication, unless the Licensee has opted out of such use via email to KPMG at <Link href="mailto:dash@kpmg.co.uk" underline="always" style={{ color: linkColor }}>dash@kpmg.co.uk</Link> within 30 days of the Commencement Date.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee agrees that KPMG can prepare, publish and distribute, information about the Licensee's use of Dash for KPMG's marketing and advertising purposes, with the prior written approval of the Licensee (such approval not to be unreasonably withheld or delayed).</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>IPR Indemnity
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to clause 13.2, KPMG shall indemnify the Licensee against any loss the Licensee and any Affiliate incurs by reason of any claim that the Licensee's use or possession of Dash and the  Documentation in accordance with this Agreement infringes the IPR in the United Kingdom of any third party not connected to the Licensee, except to the extent that:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">the Licensee or any Affiliate prejudice KPMG's defence of such a claim;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">such infringement is caused by or contributed to by the Licensee or any Affiliate's use of Dash or the Documentation other than in accordance with this Agreement; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">such infringement is caused by or contributed to by the Licensee or any Affiliate's acts or omissions (other than the use of Dash and the Documentation in accordance with this Agreement).</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If the Licensee becomes aware of any claim or likely claim of infringement as contemplated by clause 13.1 the Licensee shall:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">promptly notify KPMG in writing of the details of the claim;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">give KPMG all reasonable assistance with such claim (at KPMG's reasonable cost); and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">give KPMG sole conduct and control of the claim and its settlement or resolution.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may, at KPMG's expense, modify or replace all or part of Dash or the Documentation to avoid infringement or claims of infringement, or, if KPMG, in KPMG's sole discretion, determine that no modification or replacement is appropriate, then KPMG may terminate this Agreement and refund to the Licensee a pro-rata proportion of the Fees that the Licensee has paid for the number of days remaining of the period for which the Licensee has paid at the time of such termination.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">This clause 13 states KPMG's entire liability for infringement or alleged infringement of any third party IPR by Dash or the Documentation.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Confidentiality
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Parties shall each keep the other's Confidential Information confidential and use it only for the purposes of using Dash and the Documentation, performing or receiving the Support Services or for exercising their rights or performing their obligations under this Agreement and for corporate governance purposes. Each Party shall protect the Confidential Information it receives as it would protect its own Confidential Information, and exercise at least a reasonable standard of care.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG complies with the confidentiality standards of the Institute of Chartered Accountants in England and Wales ("ICAEW") and with any obligations imposed by law or applicable regulators.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Parties may disclose Confidential Information if required by law or regulator but only to the extent required, and the Party intending to make such disclosure shall provide the other with notice of the disclosure if permitted by law or regulation. The Parties may also disclose Confidential Information to their legal advisors and insurers in relation to any dispute arising under this Agreement.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall retain the Licensee's Confidential Information in accordance with KPMG's record retention policy. KPMG may share information relating to the Licensee and Dash and the Support Services (including Confidential Information) with other KPMG Persons, KPMG's subcontractors and Hosting Providers, and it may be accessed by parties who facilitate or support KPMG's business who shall be under obligations of confidentiality at least equivalent to this Agreement. KPMG remain responsible to the Licensee if Confidential Information is shared with such parties.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may use information KPMG has obtained in providing Dash, anonymised and/or aggregated so that no personal data or commercially sensitive information is disclosed, for development of know-how, product development, benchmarking, analytics, quality assurance and other purposes related to KPMG's business.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Warranties
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG warrants for the Term that:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">KPMG has the right to grant to the Licensee the licences to use Dash and the Documentation as provided in this Agreement;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">KPMG shall perform the Support Services with reasonable skill and care and in accordance with good industry practice within the computer industry; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">KPMG shall have in place industry-standard firewalls and periodically test Dash's vulnerability using industry standard methods. However the Licensee is responsible for ensuring that any computer equipment used to access Dash has appropriate anti-malware and anti-virus software in place.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to clauses 15.4 and 15.6, KPMG warrants for the Term that Dash shall perform in accordance with any specification set out in this Agreement and, as applicable, the Documentation.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If the Licensee notifies KPMG that Dash does not operate in accordance with the warranty set out in clause 15.2, KPMG shall, at KPMG's discretion, either:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">provide a remedy (which may consist, at KPMG's sole discretion, of a permanent error fix or a workaround) for the failure to meet the warranty stated in clause 15.2 within a reasonable time and at no additional charge to the Licensee; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1" gutterBottom>if, in KPMG's reasonable opinion, a remedy is not available, terminate this Agreement, and, if the Licensee has paid any advance fees in respect of the use of Dash, KPMG shall refund to the Licensee a pro-rata proportion of the Fees that the Licensee has paid for the number of days remaining of the period for which the Licensee has paid at the time of such termination.</Typography></li>
                        </ol>
                        <Typography variant="subtitle1">This clause 15.3 shall be the Licensee's sole remedy in respect of the warranty in clause 15.2.</Typography>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG does not warrant that the operation of Dash shall be uninterrupted or error free.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">This Agreement sets out the full extent of KPMG's obligations and liabilities (and those of any of KPMG's subcontractors or other suppliers) concerning its subject matter. All other warranties, conditions, terms, undertakings and obligations that might otherwise be implied into this Agreement including any implied terms of satisfactory quality, ability to achieve a particular result and fitness for purpose are excluded to the fullest extent permitted by law.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The warranty in clause 15.2 does not apply to the extent any purported breach of that warranty is caused by:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">use of Dash other than as permitted by this Agreement;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">the Licensee's failure to meet any Technical Requirements; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">any failure of the Licensee's internal or external telecommunications systems or networks, any internet connection, hardware, mobile devices or other software.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If, upon KPMG's investigation, a problem is found to be caused by the Licensee, KPMG may charge the Licensee for KPMG's reasonable costs and expenses incurred in the course of any investigation or remediation of the problem.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Legal, Regulatory, Other Compliance and Advice
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Each Party is responsible for making any notifications, registrations and disclosures required of it by applicable law or regulation.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Notwithstanding any other provision in this Agreement, each Party agrees that the other may make any notifications, registrations and disclosures required by law or regulation.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee is responsible for:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">the management, conduct and operation of the Licensee's business, interests and affairs;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">compliance with the Licensee's regulatory obligations;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">deciding how the Licensee's uses Dash; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">the delivery, achievement or realisation of any benefits directly or indirectly related to Dash.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Any opinion, statement of expectation, forecast or recommendation that the Licensee derives or concludes by the use of Dash does not amount to any statement or implication by KPMG that KPMG has determined or predicted future events or circumstances.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Any projections provided in Dash are made based on a probabilistic and statistical assessment and are for guidance only. The Licensee should not use them as a sole reference point and the Licensee is responsible for decisions made about the Licensee's business.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG is a regulated professional service provider which adheres to sanctions laws applicable to its business activities. The Licensee confirms that neither:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">the Licensee; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1" gutterBottom>the Licensee's owners, persons that control the Licensee's business or other affiliated persons responsible for the business' operations,</Typography></li>
                        </ol>
                        <Typography variant="subtitle1">have been sanctioned in the past nor are currently sanctioned by the United Kingdom, European Union, United States of America nor the United Nations.</Typography>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee shall inform KPMG immediately should any of the persons specified in clause 16.6 become sanctioned by the United Kingdom, European Union, United States of America or the United Nations during the Term.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Data Protection
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">"<span style={{ fontWeight: "bold" }}>DP Legislation</span>" means the General Data Protection Regulation (EU 2016/679), the Data Protection Act 2018 and legislation that amends, re-enacts or replaces it in England and Wales. The definitions and interpretations in the DP Legislation apply to this clause.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">This clause 17 applies to personal data provided to KPMG by the Licensee or on the Licensee's behalf in connection with the Support Services and Dash as set out in the data processing particulars set out in clause 17.8 ("<span style={{ fontWeight: "bold" }}>Personal Data</span>").</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee's warrants and represents that the Licensee has any necessary consent, provided any necessary notice and done all other things required under the DP Legislation to disclose the Personal Data to KPMG in connection with the Support Services and Dash. KPMG shall act as a Processor on the Licensee's behalf and will process the Personal Data in accordance with this Agreement and KPMG's obligations under DP Legislation.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">only process the Personal Data: (a) in accordance with the Licensee's specific instructions (save to the extent KPMG reasonably consider such instructions infringe DP Legislation, in which case KPMG shall notify the Licensee); (b) to provide the Support Services and Dash to the Licensee; (c) for KPMG's reasonable business purposes including facilitation and support of KPMG's business and quality control; and (d) to meet KPMG's legal and regulatory obligations;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">implement appropriate technical and organisational measures to maintain the security of the Personal Data and prevent unauthorised or unlawful access to, or processing of, or any accidental loss, destruction or damage to that Personal Data;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">keep, and procure that KPMG Persons keep, Personal Data confidential in accordance with KPMG's confidentiality obligations;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">notify the Licensee in writing without undue delay of discovery of, and provide reasonable cooperation in the event of, any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, Personal Data in KPMG's possession or control;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">provide full cooperation and assistance to the Licensee in relation to any request by a data subject to have access to Personal Data held about them or in relation to a reasonable request, allegation or complaint by a competent authority or data subject and, unless prevented from doing so by applicable law, KPMG will notify the Licensee in writing without undue delay of receipt of any request and in any event within 5 days of receipt of any request; and</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">delete or return all Personal Data to the Licensee upon request on termination or expiry of KPMG's engagement and destroy all copies of the Personal Data (save to the extent that retention of copies is required by applicable law or professional regulation).</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall maintain a record of KPMG's processing activities and provide such cooperation and information to the Licensee as is necessary for the Licensee to demonstrate compliance with the Licensee's obligations under DP Legislation, including permitting the Licensee, or a third party acting on the Licensee's behalf, to audit KPMG's compliance with this clause 17.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall not use any subcontractor to process Personal Data on KPMG's behalf ("<span style={{ fontWeight: "bold" }}>Sub-processors</span>") without the Licensee's prior written consent other than KPMG Persons (for whom consent is hereby given) and shall ensure that where a Sub-processor is duly engaged to carry out processing activities of the Personal Data on KPMG's behalf (a) such processing is subject to a written contract with that Sub-processor containing data protection obligations no less onerous than those set out in this clause 17; and (b) we shall remain liable for the acts and omission of any such Sub-processor with respect to the processing of Personal Data.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG shall not transfer Personal Data to any other jurisdiction outside the European Economic Area, other than to a country deemed to provide an adequate level of protection for personal data by the EU Commission or to the extent permissible by DP Legislation.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Data processing particulars:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">Purposes of processing. If the personal data set out in clause 17.8.3 is processed by KPMG, such personal data will be processed for the purposes of providing Dash and the Support Services.</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">Data subjects. If the personal data set out in clause 17.8.3 is processed by KPMG, such personal data will relate to the Licensee's customers.</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">Categories of data. The personal data that may be processed by KPMG concerns the following categories of personal data: the Licensee's customers purchase data and address.</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">Duration and retention of data. If the personal data set out in clause 17.8.3 is processed by KPMG, such personal data will be processed for the Term and may be retained by KPMG for a period up to 7 years following termination or expiry of this Agreement.</Typography></li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Liability
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to clauses 18.2, 18.6 and 18.7, the respective aggregate liability (whether in contract, tort (including negligence), statutory liability or otherwise) of either Party to the other Party arising from or in connection with this Agreement in respect of all events occurring in each year from and including the Commencement Date and each anniversary thereof shall not exceed one hundred and fifty per cent (150%) of the Fees paid or payable under this Agreement in respect of that year (annualised in respect of any part of a year).</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to clause 18.7, KPMG shall not be liable for any loss or damage arising out of or in connection with this Agreement, whether in contract, tort (including negligence) statutory liability or otherwise, to the extent that such loss or damage:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">is indirect or consequential;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">arises from any inability to access or retrieve data; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1" gutterBottom>consists of loss of profits, goodwill, anticipated savings or wasted time;</Typography></li>
                        </ol>
                        <Typography variant="subtitle1">in each case, whether or not KPMG has been advised of the possibility of such loss or damage.</Typography>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee shall not bring any claim against any KPMG Person other than KPMG in respect of any loss, damage, liability or expenses arising out of or in connection with this Agreement. This clause is enforceable by any KPMG Person.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee shall procure that no Affiliate or other third party may assert any claim against KPMG or any KPMG Person or subcontractor arising out of or in connection with this Agreement (including any claim resulting from use of Dash and the Documentation and related output). This clause 18.4 shall not prevent the Licensee from claiming against KPMG for any loss, damage, liability or expense suffered by any Affiliate.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Licensee accepts sole responsibility for the accuracy and completeness of all data processed using Dash and for the results obtained from it to the extent that the results depend upon the accuracy or completeness of any Licensee Data.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Nothing in this Agreement shall exclude or limit the Licensee's liability to KPMG for any loss, damage, liability or reasonable expense that KPMG suffer as a result of the Licensee's breach of clauses 4.1 and 18.4 of this Agreement.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Nothing in this Agreement shall exclude or limit the liability of either Party for:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">death or personal injury caused by the negligence of the other Party;</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">fraud or fraudulent misrepresentation; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">any other liability that cannot be excluded or limited by law.</Typography></li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Termination
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Either Party may terminate this Agreement:</Typography>
                        <ol className={classes.orderedList}>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">at the end of the Initial Term or any Renewal Term by serving not less than thirty (30) days' prior notice to the other Party; or</Typography></li>
                            <li className={classes.indentedSubtitle}><Typography variant="subtitle1">immediately if the other Party is in material breach of any of its obligations under this Agreement and, (where the breach is capable of remedy), such breach is not remedied within thirty (30) days of that Party's receipt of notice of such breach from the terminating Party.</Typography></li>
                        </ol>
                    </li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may terminate this Agreement immediately if there is a change of law, rule, regulation or professional standard, or circumstances arise that KPMG reasonably believes would cause the relationship between the Parties to violate such law, rule, regulation or professional standard or would prejudice any KPMG Person's ability to comply with applicable auditor independence requirements including without limitation if KPMG or any KPMG Person provides or is later engaged to provide audit services to the Licensee or any of its Affiliates.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If the Licensee enters into a new agreement for Dash for a different Dash plan, this Agreement shall automatically terminate on the date the new agreement for Dash is entered into by the Licensee and this Agreement shall be replaced with the new agreement entered into for Dash by the Licensee for the licensing and support of Dash.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">On termination of this Agreement the Licensee and all Affiliates must immediately cease to use Dash and the Documentation and KPMG shall deactivate or delete related user accounts (unless KPMG are required by applicable law or professional standards to maintain such accounts) and the Licensee shall immediately take all necessary steps to cease the Licensee's and any Affiliates' use of Dash.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Termination of this Agreement shall not affect the rights of either Party under this Agreement which may have accrued up to the date of termination, including in particular the Licensee's obligation to make any payments due to KPMG. The provisions of clauses 1.3, 2.1, 9.1, 10.1, 10.8, 12.2, 12.4, 12.5, 12.6, 14, 15.5, 18, 19.4, 19.5, 19.6, 19.7, 21.1, 22, 23, 24.1, 24.3 and 25 shall survive termination of this Agreement together with any other provision either expressly stated to survive termination or which, by implication, is intended to so survive.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">All amounts due but unpaid to KPMG under this Agreement shall become payable immediately on its termination, and this provision is without prejudice to any right to claim for interest.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If KPMG terminate this Agreement under clauses 1.4 or 19.2, or the Licensee's terminates this Agreement under clause 19.1.2, KPMG shall refund to the Licensee via KPMG's third party payment provider, a pro-rata proportion of the Fees to account for the number of days of the agreed Term remaining at the time of such termination.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Termination by either Party shall automatically terminate any use of Dash, the Documentation or Support Services by any Affiliates.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Force Majeure
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">No Party shall be in breach of its obligations under this Agreement or incur any liability to the other due to causes beyond their reasonable control.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If such an event occurs, the affected Party shall, as soon as reasonably practicable, notify the other, who may suspend or terminate this Agreement by giving 7 days' written notice, taking effect if the affected Party has not recommenced the performance of its obligations in that period.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Waiver and Assignment
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Failure by either Party to exercise or enforce any rights under this Agreement does not amount to a waiver of such rights.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may assign all or any of KPMG's rights or transfer all or any of KPMG's obligations under this Agreement. Except as expressly permitted by this Agreement, the Licensee shall not assign any of the Licensee's rights or transfer any of the Licensee's obligations under this Agreement without KPMG's prior written consent.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG may use subcontractors to assist in the performance of KPMG's obligations under this Agreement but KPMG remain responsible to the Licensee for fulfilling KPMG's obligations under this Agreement.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Entire Agreement
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">This Agreement sets out the entire agreement between the Parties in connection with Dash, the Documentation and the Support Services and extinguishes all previous agreements, promises, representations and understandings between the Parties to the extent that they relate to the licensing and support of Dash.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Parties acknowledge that, in entering into this Agreement, neither relies on any statement, representation, assurance or warranty (made innocently or negligently) unless it is set out in this  Agreement.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Notices
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Any notice given to KPMG under this Agreement shall be sent via email to the following email address <Link href="mailto:dash@kpmg.co.uk" underline="always" style={{ color: linkColor }}>dash@kpmg.co.uk</Link>, as updated and provided to the Licensee from time to time.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Any notice given to the Licensee under this Agreement shall be sent first class registered post (or overseas equivalent) to the Licensee's registered address, or left (and signed for) at the Licensee's registered address with a copy of such notice sent via email to the email address which accepted this Agreement, unless an updated email address has been provided by the Licensee to KPMG.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Rights of Third Parties
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">KPMG Persons (who are not the KPMG contracting Party) are entitled to the benefit of the rights given them under this Agreement and may exercise and enforce such rights pursuant to the Contracts (Rights of Third Parties) Act 1999.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">The Parties may agree to rescind or vary this Agreement without anyone else's consent, including KPMG Persons.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Except where this Agreement confers rights or benefits on KPMG Persons other than KPMG, no one other than the Parties shall have any rights under the Contracts (Rights of Third Parties) Act 1999 or otherwise to enforce any part of this Agreement.</Typography></li>
                </ol>
            </li>
            <li className={classes.titleTwo}>Disputes, Law and Jurisdiction
                <ol className={classes.orderedList}>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If a dispute between the Parties cannot be resolved by commercial negotiation, the Parties shall attempt to resolve it in accordance with the Centre for Effective Dispute Resolution's (CEDR) model mediation procedure.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If the Parties cannot agree on the joint appointment of a mediator within 10 Business Days of initiating the mediation procedure (or any longer agreed period, they shall make a joint request to CEDR to nominate the mediator.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">If the Parties resolve the dispute, their agreement shall be put in writing and shall be binding once it is signed by or on behalf of the Parties.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Failing agreement, a Party may invite the mediator to provide a non-binding opinion in writing.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Each Party shall bear its own costs of mediation, and the fees and all costs of the mediator shall be borne in equal proportions unless the mediator directs otherwise.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Subject to the above process, this Agreement and all disputes arising on any basis from, under or in connection to it shall be governed by the laws of England and Wales and subject to the exclusive jurisdiction of the English courts.</Typography></li>
                    <li className={classes.subtitleTwo}><Typography variant="subtitle1">Referral of a dispute to mediation shall not prevent a Party from seeking an injunction or other equitable relief.</Typography></li>
                </ol>
            </li>
        </ol>
    );
};

export default TermsOfUseContents;
