import React from "react";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { chooseStoreAndComparator } from "modules/customer/insights/portfolio/portfolioSlice";
import { ComparatorType } from "modules/customer/insights/portfolio/comparator";
import {
    FilterStep,
    hideFilters,
    selectActiveStep,
    selectCandidateComparator,
    selectCandidateStore,
    setActiveStep
} from "modules/customer/insights/portfolio/filters/filtersSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateStore = useAppSelector(selectCandidateStore);
    const candidateComparator = useAppSelector(selectCandidateComparator);

    const handleBackClick = () => {
        let previousStep: FilterStep;
        switch (activeStep) {
            case FilterStep.CustomSelection:
                previousStep = FilterStep.SelectComparator;
                break;
            case FilterStep.SelectComparator:
                previousStep = FilterStep.SelectStore;
                break;
            case FilterStep.SelectStore:
            default:
                previousStep = FilterStep.SelectStore;
                break;
        }
        dispatch(setActiveStep(previousStep));
    };

    const handleNextClick = () => {
        let nextStep: FilterStep;
        switch (activeStep) {
            case FilterStep.SelectStore:
                nextStep = FilterStep.SelectComparator;
                break;
            case FilterStep.SelectComparator:
                nextStep = FilterStep.CustomSelection;
                break;
            case FilterStep.CustomSelection:
            default:
                nextStep = FilterStep.CustomSelection;
                break;
        }
        dispatch(setActiveStep(nextStep));
    };

    const handleFinishClick = () => {
        if (!candidateStore || !candidateComparator) {
            return;
        }
        const payload = {
            store: candidateStore,
            comparator: candidateComparator
        };
        dispatch(chooseStoreAndComparator(payload));
        dispatch(hideFilters());
    };

    return (
        <>
            <StyledButton
                variant="text"
                size="medium"
                color="default"
                disableElevation
                onClick={handleBackClick}
                disabled={activeStep === FilterStep.SelectStore}
                data-cy="btn-back"
            >
                Back
            </StyledButton>
            {((activeStep === FilterStep.SelectStore) || (activeStep === FilterStep.SelectComparator && candidateComparator?.type === ComparatorType.Custom)) && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleNextClick}
                    disabled={!candidateStore}
                    data-cy="btn-next"
                >
                    Next
                </Button>
            )}
            {((activeStep === FilterStep.SelectComparator && candidateComparator?.type !== ComparatorType.Custom) || activeStep === FilterStep.CustomSelection) && (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    disableElevation
                    onClick={handleFinishClick}
                    disabled={!candidateComparator || candidateComparator.getStores().length === 0}
                    data-cy="btn-finish"
                >
                    Finish
                </Button>
            )}
        </>
    );
};

export default Actions;
