import React from "react";

import { IconButton } from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { withStyles } from "@material-ui/core/styles";

import SimpleTooltip from "components/SimpleTooltip";

import { moveQuestionDown } from "modules/admin/frequentlyAskedQuestions/frequentlyAskedQuestionsSlice";
import { useAppDispatch } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}))(IconButton);

interface MoveQuestionDownProps {
    categoryId: string,
    questionId: string
}

const MoveQuestionDown: React.FC<MoveQuestionDownProps> = (props) => {
    const { categoryId, questionId } = props;
    const dispatch = useAppDispatch();

    const handleMoveQuestionDownClick = () => {
        dispatch(moveQuestionDown(categoryId, questionId));
    };

    return (
        // @ts-ignore 
        <SimpleTooltip title="Move down">
            <StyledIconButton size="small" onClick={handleMoveQuestionDownClick}>
                <ArrowDownwardRoundedIcon />
            </StyledIconButton>
        </SimpleTooltip>
    );
};

export default MoveQuestionDown;
