import React from "react";
import { useDropzone } from "react-dropzone";

import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "store";
import { Dataset, DatasetType, selectUploadInfo, upload } from "modules/customer/data/dataSlice";
import { notifyError } from "modules/notifications/notificationsSlice";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        "&:last-child": {
            padding: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
        borderStyle: "dashed",
        borderColor: (props: any) => props["data-is-drag-active"] ? theme.palette.primary.main : theme.palette.text.disabled,
        borderRadius: theme.spacing(0.5),
        backgroundColor: (props: any) => props["data-is-drag-active"]
            ? theme.palette.primary.main + "33" //33 is hex code for 20% opacity on the alpha channel
            // @ts-ignore
            : theme.palette.quaternary.dark,

    }
}))(CardContent);

const StyledButton = withStyles(theme => ({
    root: {
        // @ts-ignore
        color: theme.palette.tertiary.main
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    uploadIcon: {
        fontSize: theme.spacing(8),
        color: theme.palette.text.disabled
    },
    uploadInput: {
        display: "none"
    }
}));

interface DragAndDropProps {
    dataset: Dataset
}

const DragAndDrop: React.FC<DragAndDropProps> = (props) => {
    const { dataset } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const uploadInfo = useAppSelector(selectUploadInfo);
    const uploadInProgress = uploadInfo.fileName !== "";

    const handleDrop = (acceptedFiles: File[]) => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
        if (acceptedFiles.length === 0) {
            dispatch(notifyError("File not accepted."));
            return;
        }
        dispatch(upload(acceptedFiles[0]));
    };

    const {
        inputRef,
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop: handleDrop,
        accept: dataset.type === DatasetType.Csv ? ".csv" : ".xlsx",
        multiple: false,
        noClick: true,
        disabled: uploadInProgress
    });

    const getDropzoneRootProps = () => {
        if (uploadInProgress) {
            return {};
        }
        return { ...getRootProps() };
    };

    const getDropzoneInputProps = () => {
        if (uploadInProgress) {
            return {};
        }
        return { ...getInputProps() };
    };

    return (
        <StyledCard elevation={0}>
            <StyledCardContent
                data-is-drag-active={isDragActive}
                align="center"
                {...getDropzoneRootProps()}
            >
                <CloudUploadRoundedIcon className={classes.uploadIcon} />
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="subtitle1" component="div">
                        Drag and drop or
                    </Typography>
                    <input
                        id="upload-input"
                        type="file"
                        disabled={uploadInProgress}
                        className={classes.uploadInput}
                        {...getDropzoneInputProps()}
                    />
                    <label htmlFor="upload-input">
                        {/* @ts-ignore */}
                        <StyledButton component="span"
                            variant="text"
                            size="small"
                            color="default"
                            disabled={uploadInProgress}
                        >
                            <Typography variant="subtitle1" component="div">
                                browse files
                            </Typography>
                        </StyledButton>
                    </label>
                </Box>
            </StyledCardContent>
        </StyledCard>
    );
};

export default DragAndDrop;
