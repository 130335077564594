import { immerable } from "immer";

export class SpendCategory {
    [immerable] = true;

    public readonly name: string;
    private _isSelected: boolean;

    constructor(
        name: string,
        isSelected: boolean
    ) {
        this.name = name;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}
