import React from "react";
import PropTypes from "prop-types";

import { Box, Hidden } from "@material-ui/core";

import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";

const Navigation = (props) => {
    const { appBarRef } = props;

    return (
        <Box data-cy="landing-navigation">
            <Hidden smDown>
                <NavigationDesktop />
            </Hidden>
            <Hidden mdUp>
                <NavigationMobile appBarRef={appBarRef} />
            </Hidden>
        </Box>
    );
};

Navigation.propTypes = {
    appBarRef: PropTypes.object.isRequired
};

export default Navigation;
