import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import actions from "./actions";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const getFootfall = () => async (dispatch, getState) => {
    dispatch(actions.getFootfallRequest());
    try {
        const state = getState();
        const selectedStoreSelector = selectStore(state);
        const selectedStoreRC = selectedStoreSelector.retailCentreID.toString();
        const selectedStoreCategory = selectedStoreSelector.kpmgStoreCategory;
        const selectedStoreOAID = selectedStoreSelector.outputAreaCode;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStoreOAIDs = comparatorStoresSelector.getStores().map(store => store.outputAreaCode);
        const mapCenter = {
            latitude: selectedStoreSelector.latitude,
            longitude: selectedStoreSelector.longitude
        };

        const queryNewFootfallSelected = {
            dimensions: [
                "F_CatchmentYearlyFootfall.RetailCentreID",
                "F_CatchmentYearlyFootfall.OAID",
                "F_CatchmentYearlyFootfall.Prev12MonthsFootfall",
                "F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall",
            ],
            filters: [{
                member: "F_CatchmentYearlyFootfall.RetailCentreID",
                operator: "equals",
                values: [selectedStoreRC]
            },
            {
                member: "F_CatchmentYearlyFootfall.RetailCategory",
                operator: "equals",
                values: [selectedStoreCategory]
            }],
            order: [
                ["F_CatchmentYearlyFootfall.OAID", "asc"]
            ]
        };

        const resultSetFootfallSelected = await dispatch(cubeLoadExtended(queryNewFootfallSelected));

        const selectedData = resultSetFootfallSelected.loadResponses[0].data.map(item => ({
            name: selectedStoreSelector.name,
            retailCentreID: item["F_CatchmentYearlyFootfall.RetailCentreID"],
            OAID: item["F_CatchmentYearlyFootfall.OAID"],
            normalisedLastYear: item["F_CatchmentYearlyFootfall.Prev12MonthsFootfall"],
            normalisedLastLastYear: item["F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"],
            percentageChangeYOY: item["F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"] === 0 ? 100 :
                100 * (item["F_CatchmentYearlyFootfall.Prev12MonthsFootfall"] - item["F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"]) /
                    item["F_CatchmentYearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"]

        }));

        const relevantOAIDs = comparatorStoreOAIDs;
        if (!selectedData.find(item => item.OAID === selectedStoreOAID)) {
            relevantOAIDs.push(selectedStoreOAID);
        }

        const queryNewFootfallComparator = {
            dimensions: [
                "F_YearlyFootfall.OAID",
                "F_YearlyFootfall.Prev12MonthsFootfall",
                "F_YearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall",

            ],
            filters: [{
                member: "F_YearlyFootfall.OAID",
                operator: "equals",
                values: relevantOAIDs
            }]
        };

        const resultSetFootfallComparator = await dispatch(cubeLoadExtended(queryNewFootfallComparator));
        
        const comparatorData = resultSetFootfallComparator.loadResponses[0].data.map(item => ({
            OAID: item["F_YearlyFootfall.OAID"],
            normalisedLastYear: item["F_YearlyFootfall.Prev12MonthsFootfall"],
            normalisedLastLastYear: item["F_YearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"],
            percentageChangeYOY: item["F_YearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"] === 0 ? 100 :
            100 * (item["F_YearlyFootfall.Prev12MonthsFootfall"] - item["F_YearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"]) /
                item["F_YearlyFootfall.TwelveMonthsPriorToPrev12MonthsFootfall"]
        }));

        // if selected store OAID not in selectedData, push it in there from comparitor data
        if (!selectedData.find(item => item.OAID === selectedStoreOAID)) {
            const selectedStoreOaData = comparatorData.find(item => item.OAID === selectedStoreOAID);
            if (selectedData) {
                selectedData.push({
                    name: selectedStoreSelector.name,
                    mainOAID: selectedStoreOaData.OAID,
                    OAID: selectedStoreOaData.OAID,
                    normalisedLastYear: selectedStoreOaData.normalisedLastYear,
                    normalisedLastLastYear: selectedStoreOaData.normalisedLastLastYear,
                    percentageChangeYOY: selectedStoreOaData.percentageChangeYOY
                });
            } else {
                selectedData.push({
                    name: selectedStoreSelector.name,
                    mainOAID: selectedStoreOAID,
                    OAID: selectedStoreOAID,
                    normalisedLastYear: 0,
                    normalisedLastLastYear: 0,
                    percentageChangeYOY: 0
                });
            }
        }

        const filteredComparatorData = comparatorData.filter(item => comparatorStoreOAIDs.includes(item.OAID));
        
        dispatch(actions.getFootfallSuccess(selectedData, filteredComparatorData, mapCenter));
    }
    catch (error) {
        dispatch(actions.getFootfallFailure());
        dispatch(logError("Error loading Footfall.", error));
    }
};

const operations = {
    getFootfall
};

export default operations;
