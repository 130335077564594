import React from "react";
import Highcharts from "highcharts";

import { useTheme } from "@material-ui/core/styles";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { Store } from "modules/customer/tools/product/store";
import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

interface BarChartProps {
    store: Store
}

const BarChart: React.FC<BarChartProps> = (props) => {
    const {
        store
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const categories = ["Optimised sales", "Estimated sales", `${clientName} sourced sales`];
    const values = [store.optimisedSales, store.estimatedSales, store.clientSourcedSales];

    const options: Highcharts.Options = {
        chart: {
            height: theme.spacing(20),
            backgroundColor: 'transparent'
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories,
            labels: {
                enabled: true
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        plotOptions: {
            bar: {
                pointPadding: 0.01,
                groupPadding: 0.01
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            footerFormat: ``,
            // @ts-ignore
            formatter: function () {
                const header = store.name;

                const categoriesArr = [...categories, "Headroom"];
                const categoriesFormatArr = categories.map((category, index) => {
                    return `color:${colourPalette.categorical[index]};font-weight:bold`;
                });
                categoriesFormatArr.push(`color:${theme.palette.text.primary};font-weight:bold`);
                const valuesArr = [...values, store.salesHeadroom].map(value => numberFormatter.toGBP(value));

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr,
                        valueFormatting: ""
                    }
                )}</table>`;
            },
            useHTML: true
        },
        series: [{
            data: values.map((value, index) => ({
                y: value,
                color: colourPalette.categorical[index]
            })),
            type: "bar",
            dataLabels: {
                enabled: false
            }
        }],
        exporting: {
            enabled: false
        }
    };

    return (
        <Bar loading={false} error={false} options={options} />
    );
};

export default BarChart;
