import React from "react";
import { Box, Card, CardContent, Chip, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StopIcon from "@material-ui/icons/Stop";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        width: theme.spacing(22)
    }
}));

const MapLegend = () => {
    const classes = useStyles();
    const theme = useTheme();
    const colourPalette = useColourPalette();
    return (
        <Card elevation={0} className={classes.card}>
            <CardContent>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.concentricCircles[0]
                        }} />
                    &nbsp;1 Km
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.concentricCircles[1]
                        }} />
                    &nbsp;2 Km
                </Typography>
                <Typography variant="body2" component="div">
                    <Chip
                        size="small"
                        style={{
                            width: theme.spacing(3),
                            height: theme.spacing(1.5),
                            backgroundColor: colourPalette.concentricCircles[2]
                        }} />
                    &nbsp;5 Km
                </Typography>
                <Box display="flex">
                    <LocationOnOutlinedIcon fontSize="small" style={{ color: colourPalette.comparators[0] }} />
                    <Typography variant="body2">&nbsp;&nbsp;Selected store</Typography>
                </Box>
                <Box display="flex">
                    <LocationOnOutlinedIcon fontSize="small" />
                    <Typography variant="body2">&nbsp;&nbsp;Your store</Typography>
                </Box>
                <Box display="flex">
                    <StopIcon fontSize="small" style={{ transform: "rotate(45deg)", color: colourPalette.categorical[2] }} />
                    <Typography variant="body2">&nbsp;&nbsp;Direct competitor</Typography>
                </Box>
                <Box display="flex">
                    <FiberManualRecordIcon fontSize="small" style={{ color: colourPalette.categorical[0] }} />
                    <Typography variant="body2">&nbsp;&nbsp;Competitor</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default MapLegend;
