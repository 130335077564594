import React from "react";

import { selectComparator, selectStore } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const comparator = useAppSelector(selectComparator);
    const store = useAppSelector(selectStore);

    return (
        <>
            Understand your {store?.name} store's gross profit margin (%).
            Dive into historical trends, identifying pivotal months and dissecting the driving factors behind margin shifts.
            Gauge your store's performance relative to {comparator?.name} to determine its operational sustainability.
        </>
    );
};

export default Subtitle;
