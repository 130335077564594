import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const CompetitionSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Having looked at the local area of your {store?.name} store through a retail health lens, let us now
            look at it through the lens of competition. Here we will look at the level of competition in the area
            surrounding your {store?.name} store and see how far you and your competitors are from the
            retail-centre hotspot. We will also assess your store's ability to attract and retain customers and evaluate
            its market share compared to {comparator?.name}.
        </>
    );
};

export default CompetitionSubtitle;
