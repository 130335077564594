import React from "react";

import { Box, Grid } from "@material-ui/core";

import MarketCategoriesFilter from "./ExistingStoresFilter";
import LikelihoodOfVisitingSlider from "./LikelihoodOfVisitingSlider";

const Filter: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MarketCategoriesFilter />
                </Grid>
            </Grid>
            <>Percentage likelihood of visiting selected location</>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <LikelihoodOfVisitingSlider />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Filter;
