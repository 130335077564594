import React from 'react';
import { Box, Grid } from '@material-ui/core';

import Range from './rangePlaceholder/Range';

const BasicPerformanceReporting = () => {
    return (
        <Box display="flex" flexDirection="column" data-cy="feature-customer-products-basic-performance-reporting">
            <Grid container>
                <Grid item xs={9}>
                    <Range />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BasicPerformanceReporting;
