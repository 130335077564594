import React from "react";

import { Box, Container } from "@material-ui/core";

import Navigation from "pages/customer/navigation/Navigation";
import Onboarding from "pages/customer/onboarding/Onboarding";

import Idle from "./Idle";
import LoggedOut from "./LoggedOut";
import Router from "./Router";

const Customer: React.FC = () => {
    return (
        <Box display="flex" flexGrow={1} data-cy="pages-customer">
            <Navigation />
            <Container component="main" maxWidth={false} disableGutters>
                <Router />
                <Onboarding />
                <Idle />
                <LoggedOut />
            </Container>
        </Box>
    );
};

export default Customer;
