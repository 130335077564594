import { AppThunk } from "appThunk";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Comparator } from "modules/customer/tools/location/comparator";
import { SpendCategory } from "./spendCategory";
import { CatchmentAccountIds } from "modules/customer/tools/location/locationSlice";

export class SpendPerDetailedCategory {
    public readonly retailCentreId: number;
    public readonly isComparator: boolean;
    public readonly spendCategoryName: string;
    public readonly detailedCategoryName: string;
    public readonly weightedSpend: number;
    public readonly unweightedSpend: number;

    constructor(
        retailCentreId: number,
        isComparator: boolean,
        spendCategoryName: string,
        detailedCategoryName: string,
        weightedSpend: number,
        unweightedSpend: number
    ) {
        this.retailCentreId = retailCentreId;
        this.isComparator = isComparator;
        this.spendCategoryName = spendCategoryName;
        this.detailedCategoryName = detailedCategoryName;
        this.weightedSpend = weightedSpend;
        this.unweightedSpend = unweightedSpend;
    }
}

export const loadSpendPerDetailedCategory = (selectedRetailCentreId: number | undefined, comparator: Comparator | undefined, targetStoreCategoryId: number | undefined, spendCategories: SpendCategory[], catchmentAccountIds: CatchmentAccountIds): AppThunk<Promise<SpendPerDetailedCategory[]>> => async (dispatch) => {
    if (!selectedRetailCentreId || !comparator)
    {
        return [];
    }

    try {
        const spendCategoryIds = spendCategories.map(category => String(category.id));
        
        const query = {
            dimensions: [
                "CatchmentDetailedCategorySpend.RetailCentreID",
                "CatchmentDetailedCategorySpend.IsScenario",
                "CatchmentDetailedCategorySpend.SpendCategory_ID",
                "CatchmentDetailedCategorySpend.DetailedSpendCategory",
                "CatchmentDetailedCategorySpend.WeightedSpend",
                "CatchmentDetailedCategorySpend.UnweightedSpend"
            ],
            filters: [{
                member: "CatchmentDetailedCategorySpend.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "CatchmentDetailedCategorySpend.SpendCategory_ID",
                operator: "equals",
                values: spendCategoryIds
            }, {
                or: [{
                    and: [{
                        member: "CatchmentDetailedCategorySpend.RetailCentreID",
                        operator: "equals",
                        values: [String(selectedRetailCentreId)]
                    }, {
                        member: "CatchmentDetailedCategorySpend.IsScenario",
                        operator: "equals",
                        values: ["1"]
                    }, {
                        member: "CatchmentDetailedCategorySpend.Client_ID",
                        operator: "equals",
                        values: [catchmentAccountIds.scenario]
                    }]
                }, {
                    and: [{
                        member: "CatchmentDetailedCategorySpend.RetailCentreID",
                        operator: "equals",
                        values: [String(comparator.retailCentreId)]
                    }, {
                        member: "CatchmentDetailedCategorySpend.IsScenario",
                        operator: "equals",
                        values: [String(Number(comparator.scenarioCatchment))]
                    }, {
                        member: "CatchmentDetailedCategorySpend.Client_ID",
                        operator: "equals",
                        values: [catchmentAccountIds.scenario]
                    }]
                }]
            }]
        };
        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<any>;
        
        const rawData = resultSet.loadResponses[0].data;
        return rawData.map(row => {
            const retailCentreId = row["CatchmentDetailedCategorySpend.RetailCentreID"];
            const categoryId = row["CatchmentDetailedCategorySpend.SpendCategory_ID"];
            const categoryName = spendCategories.find(item => item.id === categoryId)?.name ?? "";
            const detailedCategoryName = (row["CatchmentDetailedCategorySpend.DetailedSpendCategory"]);
            const weightedSpend = Number(row["CatchmentDetailedCategorySpend.WeightedSpend"]);
            const unweightedSpend = Number(row["CatchmentDetailedCategorySpend.UnweightedSpend"]);
            const isComparator = (
                row["CatchmentDetailedCategorySpend.RetailCentreID"] === comparator.retailCentreId
                && Boolean(Number(row["CatchmentDetailedCategorySpend.IsScenario"])) === comparator.scenarioCatchment
            );
            return new SpendPerDetailedCategory(retailCentreId, isComparator, categoryName, detailedCategoryName, weightedSpend, unweightedSpend);
        });
    } catch (error) {
        dispatch(logError("Error loading SpendPerOutputArea.", error));
        throw error;
    }
};
