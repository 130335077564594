import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectStore, selectComparator, selectPrimaryCategory, selectProductCategories, selectReferenceDate, cubeLoadStoreCatchments, selectReferenceDateNew } from "modules/customer/insights/portfolio/portfolioSlice";

import actions from "./actions";

const getNumberOfHouseholds = () => async (dispatch, getState) => {
    dispatch(actions.getNumberOfHouseholdsRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStores = comparatorStoresSelector.getStores();

        const relevantStores = [selectedStoreSelector].concat(...comparatorStores);

        //Selected store Number of households query:
        const householdsQuery = {
            dimensions: [
                "CatchmentAreasPopulation.RetailCentreID",
                "CatchmentAreasPopulation.RetailCategory",
                "CatchmentAreasPopulation.Households"
            ],
            filters: [{
                member: "CatchmentAreasPopulation.RetailCentreID",
                operator: "equals"
            }, {
                member: "CatchmentAreasPopulation.RetailCategory",
                operator: "equals"
            }],
            segments: [
                "CatchmentAreasPopulation.Baseline"
            ]
        };

        const resultSetHouseholds = await dispatch(cubeLoadStoreCatchments(householdsQuery, relevantStores, "RetailCategory", "RetailCentreID"));

        let selectedStoreHouseholds = {};
        const comparatorHouseholds = [];

        for (let i in relevantStores) {
            const relevantData = resultSetHouseholds.loadResponses[0].data.find(item =>
                item["CatchmentAreasPopulation.RetailCentreID"] === relevantStores[i].retailCentreID
                && item["CatchmentAreasPopulation.RetailCategory"] === relevantStores[i].kpmgStoreCategory);
            
            const householdsData = {
                retailCentreID: relevantStores[i].retailCentreID,
                storeCategory: relevantStores[i].kpmgStoreCategory,
                households: relevantData ? relevantData["CatchmentAreasPopulation.Households"] : 0
            };
            
            if (relevantStores[i].id === selectedStoreSelector.id) {
                selectedStoreHouseholds = householdsData.households;
            } else {
                comparatorHouseholds.push(householdsData);
            }
            
        }

        dispatch(actions.getNumberOfHouseholdsSuccess(selectedStoreHouseholds, comparatorHouseholds));
    }
    catch (error) {
        dispatch(actions.getNumberOfHouseholdsFailure());
        dispatch(logError("Error loading NumberOfHouseholds.", error));
    }
};

const getMarketShareOverTime = () => async (dispatch, getState) => {
    dispatch(actions.getMarketShareOverTimeRequest());
    try {
        const state = getState();

        const selectedStoreSelector = selectStore(state);
        const selectedStoreID = selectedStoreSelector.id;
        const comparatorStoresSelector = selectComparator(state);
        const comparatorStores = comparatorStoresSelector.getStores();
        const comparatorStoresIDs = comparatorStores.map(store => store.id);

        const selectedAndComparatorIDs = comparatorStoresIDs.concat([selectedStoreID]);
        const relevantStores = [selectedStoreSelector].concat(...comparatorStores);

        const clientProductCategories = selectProductCategories(state);

        const referenceDate = selectReferenceDateNew(state);

        //End of last full month
        const currentDate = referenceDate === referenceDate.endOf("month") ? referenceDate : referenceDate.minus({ months: 1 }).endOf("month");
        const priorTwelveMonths = currentDate.minus({ months: 12 }).plus({ days: 1 });

        const storeSalesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                "D_ProductCategory.ProductCategory3",
                "D_Date.MonthEndDate",
                "D_Date.Year",
                "D_Date.MonthNo"
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [priorTwelveMonths, currentDate]
            }],
            order: [
                ["D_Date.Year", "asc"],
                ["D_Date.MonthNo", "asc"]
            ],
            measures: [
                "F_Sales.SumLineValue"
            ],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: selectedAndComparatorIDs
            },{
                member: "D_ProductCategory.ProductCategory3",
                operator: "set"
            }]
        };

        const resultSetStoreSales = await dispatch(cubeLoadExtended(storeSalesQuery));

        const storeSalesData = resultSetStoreSales.loadResponses[0].data.map(item => ({
            storeID: item["D_Store.StoreNaturalID"],
            retailCentreID: item["D_Store.RetailCentreID"],
            storeCategory: item["D_Store.KPMGStoreCategory"],
            spendCategory: item["D_ProductCategory.ProductCategory3"],
            salesValue: item["F_Sales.SumLineValue"] || 0,
            date: item["D_Date.MonthEndDate"],
            year: item["D_Date.Year"],
            month: item["D_Date.MonthNo"],
            seasonalityValue: 0
        }));

        const queryMarketSeasonality = {
            dimensions: [
                "F_MarketSeasonality.KPMGSpendCategory",
                "F_MarketSeasonality.Seasonality",
                "F_MarketSeasonality.MonthEndDate",
                "F_MarketSeasonality.Year",
                "F_MarketSeasonality.MonthNo"
            ],
            timeDimensions: [{
                dimension: "F_MarketSeasonality.MonthEndDate",
                dateRange: [priorTwelveMonths, currentDate]
            }],
            filters: [{
                member: "F_MarketSeasonality.KPMGSpendCategory",
                operator: "equals",
                values: clientProductCategories
            }],
            order: [[
                "F_MarketSeasonality.MonthEndDate", "asc"
            ]]
        };

        const resultSetMarketSeasonality = await dispatch(cubeLoadExtended(queryMarketSeasonality));

        const seasonalityData = resultSetMarketSeasonality.loadResponses[0].data.map(item => ({
            spendCategory: item["F_MarketSeasonality.KPMGSpendCategory"],
            monthEndDate: item["F_MarketSeasonality.MonthEndDate"],
            seasonalityValue: item["F_MarketSeasonality.Seasonality"],
            year: item["F_MarketSeasonality.Year"],
            month: item["F_MarketSeasonality.MonthNo"]
        }));

        const queryMarketCategorySpend = {
            dimensions: [
                "CatchmentMarketCategorySpend.RetailCentreID",
                "CatchmentMarketCategorySpend.KpmgStoreCategory",
                "CatchmentMarketCategorySpend.SpendKpmgCategory"
            ],
            measures: [
                "CatchmentMarketCategorySpend.sumBaselineWeightedSpend"
            ],
            filters: [{
                member: "CatchmentMarketCategorySpend.RetailCentreID",
                operator: "equals"
            },
            {
                member: "CatchmentMarketCategorySpend.KpmgStoreCategory",
                operator: "equals"
            },
            {
                member: "CatchmentMarketCategorySpend.SpendKpmgCategory",
                operator: "equals",
                values: clientProductCategories
            }],
            segments: [
                "CatchmentMarketCategorySpend.Baseline"
            ]
        };

        const resultSetMarketCategorySpend = await dispatch(cubeLoadStoreCatchments(queryMarketCategorySpend, relevantStores, "KpmgStoreCategory", "RetailCentreID"));

        const marketSpendData = resultSetMarketCategorySpend.loadResponses[0].data.map(item => ({
            retailCentreID: item["CatchmentMarketCategorySpend.RetailCentreID"],
            storeCategory: item["CatchmentMarketCategorySpend.KpmgStoreCategory"],
            spendCategory: item["CatchmentMarketCategorySpend.SpendKpmgCategory"],
            marketCatSpend: item["CatchmentMarketCategorySpend.sumBaselineWeightedSpend"]
        }));

        for (let i in storeSalesData) {
            const relevantSeasonalityData = seasonalityData.find(item =>
                item.month === storeSalesData[i].month
                && item.spendCategory.toLowerCase() === storeSalesData[i].spendCategory.toLowerCase());

            const relevantMarketCatSpendData = marketSpendData.find(item =>
                item.retailCentreID === storeSalesData[i].retailCentreID
                && item.storeCategory.toLowerCase() === storeSalesData[i].storeCategory.toLowerCase()
                && item.spendCategory.toLowerCase() === storeSalesData[i].spendCategory.toLowerCase());
            const relevantMarketCatSpendValue = relevantMarketCatSpendData?.marketCatSpend ?? 0;

            storeSalesData[i].seasonalityValue = relevantSeasonalityData?.seasonalityValue ?? 0;
            storeSalesData[i].monthlyMarketSpend = storeSalesData[i].seasonalityValue * relevantMarketCatSpendValue;
        }

        //Calculate % of sales each category makes up, then calculate weighted market share values
        for (const i in relevantStores) {
            const relevantSalesData = storeSalesData.filter(item => item.storeID === relevantStores[i].id);
            //Need to group and sum sales by spend category
            const groupedSales = relevantSalesData.reduce((acc, obj) => {
                const key = obj["spendCategory"];
                if (!acc[key]) {
                   acc[key] = 0;
                }
                // Add object to list for given key's value
                acc[key] += obj.salesValue;
                return acc;
             }, {});

            let totalStoreSales = 0;
            for (const value in groupedSales) {
                totalStoreSales += groupedSales[value];
            }
            for (const value in groupedSales) {
                groupedSales[value] /= totalStoreSales;
            }
            relevantStores[i].groupedSales = groupedSales;

        }

        for (const i in storeSalesData) {
            const relevantCategoryWeighting = relevantStores.find(item => item.id === storeSalesData[i].storeID).groupedSales[storeSalesData[i].spendCategory];
            storeSalesData[i].weightedSalesValue = relevantCategoryWeighting * storeSalesData[i].salesValue;
            storeSalesData[i].monthlyMarketShare = (storeSalesData[i].monthlyMarketSpend === 0)
                ? 1 : relevantCategoryWeighting * (storeSalesData[i].salesValue / storeSalesData[i].monthlyMarketSpend);
        }

        const groupedMarketShare = [];

        for (const i in storeSalesData) {
            const currentStore = storeSalesData[i];
            const relevantData = storeSalesData.filter(item => item.storeID === currentStore.storeID && item.date === currentStore.date);

            if (!groupedMarketShare.find(item => currentStore.storeID === item.storeID && currentStore.date === item.date)) {
                const sumSales = relevantData.map(item => item.salesValue).reduce((a, b) => a + b);
                const sumWeightedSales = relevantData.map(item => item.weightedSalesValue).reduce((a, b) => a + b);
                const sumSpend = relevantData.map(item => item.monthlyMarketSpend).reduce((a, b) => a + b);
                const sumMarketShare = sumSales / sumSpend;
    
                groupedMarketShare.push({
                    storeID: currentStore.storeID,
                    date: currentStore.date,
                    year: currentStore.date,
                    month: currentStore.month,
                    monthlyMarketShare: sumMarketShare,
                    salesValue: sumSales,
                    weightedSalesValue: sumWeightedSales,
                    marketSpend: sumSpend,
                });
            }
        }

        const selectedStore = groupedMarketShare.filter(item => item.storeID === selectedStoreID);

        const comparator = groupedMarketShare.filter(item => comparatorStoresIDs.includes(item.storeID));

        dispatch(actions.getMarketShareOverTimeSuccess(selectedStore, comparator));
    }
    catch (error) {
        dispatch(actions.getMarketShareOverTimeFailure());
        dispatch(logError("Error loading MarketShareOverTime.", error));
    }
};

const getOpeningsAndClosures = () => async (dispatch, getState) => {
    dispatch(actions.getOpeningsAndClosuresRequest());
    try {
        const state = getState();
        const primaryProductCategory = selectPrimaryCategory(state);

        const selectedStoreSelector = selectStore(state);
        const selectedStoreRCID = String(selectedStoreSelector.retailCentreID);
        const currentDate = selectReferenceDate(state);
        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);

        const queryAllCompetitors = {
            dimensions: [
                "LocalCompetitors.OpeningDate",
                "LocalCompetitors.Fascia",
                "FasciaMapping.RevisedFascia",
                "LocalCompetitors.DateDeleted",
                "LocalCompetitors.GrossInternalAreaSqFt"
            ],
            filters: [
                {
                    "member": "LocalCompetitors.RetailCategory",
                    "operator": "equals",
                    "values": [primaryProductCategory]
                },
                {
                    "member": "LocalCompetitors.RetailCentreID",
                    "operator": "equals",
                    "values": [selectedStoreRCID]
                },
                {
                    or: [{
                        "member": "LocalCompetitors.OpeningDate",
                        "operator": "inDateRange",
                        "values": [priorTwelveMonthsStartDate, currentDate]
                    },
                    {
                        "member": "LocalCompetitors.DateDeleted",
                        "operator": "inDateRange",
                        "values": [priorTwelveMonthsStartDate, currentDate]
                    }]
                }

            ]
        };

        const resultSetCompetitors = await dispatch(cubeLoadExtended(queryAllCompetitors));

        const queryDirectCompetitorNames = {
            dimensions: [
                "F_DirectCompetitors.CompetitorName"
            ]
        };

        const resultSetDirectCompetitorsNames = await dispatch(cubeLoadExtended(queryDirectCompetitorNames));
        const directCompetitorNames = resultSetDirectCompetitorsNames.loadResponses[0].data.map(item => item["F_DirectCompetitors.CompetitorName"]);

        const competitorStores = resultSetCompetitors.loadResponses[0].data.map(item => ({
            type: ((directCompetitorNames.includes(item["LocalCompetitors.Fascia"]) || directCompetitorNames.includes(item["FasciaMapping.RevisedFascia"]))
                ? "Direct competitor" : "Competitor"),
            name: item["LocalCompetitors.Fascia"],
            openedAt: (item["LocalCompetitors.OpeningDate"]),
            closedAt: (item["LocalCompetitors.DateDeleted"]),
            size: (item["LocalCompetitors.GrossInternalAreaSqFt"]),
        }));

        let directCompetitors = [];
        let otherCompetitors = [];

        for (let i in competitorStores) {
            if (competitorStores[i].type === "Direct competitor") {
                directCompetitors.push(competitorStores[i]);
            } else {
                otherCompetitors.push(competitorStores[i]);
            }
        }

        let openDirect = [];
        let closeDirect = [];
        let openOther = [];
        let closeOther = [];

        const unixPriorTwelveMonthsStartDate = dateUtils.dateUTC(priorTwelveMonthsStartDate).getTime();

        let row = {};
        for (let i in directCompetitors) {
            row = directCompetitors[i];
            if ((dateUtils.dateUTC(row.openedAt).getTime()) >= unixPriorTwelveMonthsStartDate) {
                openDirect.push({ 
                    date: (Math.floor(dateUtils.dateUTC(row.openedAt).getTime())), 
                    size: row.size,
                    name: row.name
                });
            }
            if (row.closedAt) {
                closeDirect.push({ 
                    date: (Math.floor(dateUtils.dateUTC(row.closedAt).getTime())), 
                    size: row.size,
                    name: row.name
                });
            }
        }

        for (let i in otherCompetitors) {
            row = otherCompetitors[i];
            if ((dateUtils.dateUTC(row.openedAt).getTime()) >= unixPriorTwelveMonthsStartDate) {
                openOther.push({ 
                    date: (Math.floor(dateUtils.dateUTC(row.openedAt).getTime())), 
                    size: row.size,
                    name: row.name
                });
            }
            if (otherCompetitors[i].closedAt) {
                closeOther.push({ 
                    date: (Math.floor(dateUtils.dateUTC(row.closedAt).getTime())), 
                    size: row.size,
                    name: row.name
                });
            }
        }

        dispatch(actions.getOpeningsAndClosuresSuccess(openDirect, closeDirect, openOther, closeOther));
    }
    catch (error) {
        dispatch(actions.getOpeningsAndClosuresFailure());
        dispatch(logError("Error loading OpeningsAndClosures.", error));
    }
};

const operations = {
    getNumberOfHouseholds,
    getMarketShareOverTime,
    getOpeningsAndClosures,
};

export default operations;
