const revenueLastYear = (state) => {
    return state.customer.insights.range.sales.historicSales.revenueLastYear;
};

const unitSalesLastYear = (state) => {
    return state.customer.insights.range.sales.historicSales.unitSalesLastYear;
};

const medianWeeklyRevenue = (state) => {
    return state.customer.insights.range.sales.historicSales.medianWeeklyRevenue;
};

const medianWeeklyUnitSales = (state) => {
    return state.customer.insights.range.sales.historicSales.medianWeeklyUnitSales;
};

const revenueUnitSalesOverTime = (state) => {
    return state.customer.insights.range.sales.historicSales.revenueUnitSalesOverTime;
};

const revenueTrend = (state) => {
    return state.customer.insights.range.sales.historicSales.revenueTrend;
};

const unitSalesTrend = (state) => {
    return state.customer.insights.range.sales.historicSales.unitSalesTrend;
};

const selectors = {
    revenueLastYear,
    unitSalesLastYear,
    medianWeeklyRevenue,
    medianWeeklyUnitSales,
    revenueUnitSalesOverTime,
    revenueTrend,
    unitSalesTrend
};

export default selectors;
