import React from "react";

import DumbbellAlignment from "components/visuals/DumbellAlignment";

import { selectCatchmentAreaDemographic } from "modules/customer/tools/location/demographics/demographicsSlice";
import { useAppSelector } from "store";
import { selectTarget } from "modules/customer/tools/location/locationSlice";

const CatchmentAreaDemographicCharacteristics: React.FC = () => {

    const catchmentAreaDemographic = useAppSelector(selectCatchmentAreaDemographic);
    const targetIsSet = Boolean(useAppSelector(selectTarget)?.useDemographics);

    const chartValues = [{
        lowCategory: "Low affluence",
        highCategory: "High affluence",
        label: "Affluence",
        selectedValue: catchmentAreaDemographic.retailCentre.affluence,
        targetValue: catchmentAreaDemographic.target.affluence
    }, {
        lowCategory: "Young",
        highCategory: "Old",
        label: "Age",
        selectedValue: catchmentAreaDemographic.retailCentre.age,
        targetValue: catchmentAreaDemographic.target.age
    }, {
        lowCategory: "Adult-only households",
        highCategory: "Households with young children",
        label: "Children",
        selectedValue: catchmentAreaDemographic.retailCentre.children,
        targetValue: catchmentAreaDemographic.target.children
    }, {
        lowCategory: "Less diverse",
        highCategory: "Very diverse",
        label: "Diversity",
        selectedValue: catchmentAreaDemographic.retailCentre.diversity,
        targetValue: catchmentAreaDemographic.target.diversity
    }, {
        lowCategory: "Very rural",
        highCategory: "Very urban",
        label: "Urbanicity",
        selectedValue: catchmentAreaDemographic.retailCentre.urbanicity,
        targetValue: catchmentAreaDemographic.target.urbanicity
    }];

    return (
        <DumbbellAlignment chartValues={chartValues} targetIsSet={targetIsSet} dataCy="demographics-alignment" />
    );
};

export default CatchmentAreaDemographicCharacteristics;
