import React from "react";

import { useAppSelector } from "store";
import { selectStore, selectComparator } from "modules/customer/insights/portfolio/portfolioSlice";

const PerformanceDriversSubtitle: React.FC = () => {
    const store = useAppSelector(selectStore);
    const comparator = useAppSelector(selectComparator);

    return (
        <>
            Analyse what is driving the performance of your {store?.name} store, and how this varies
            from {comparator?.name}. Find out whether your store is appropriately sized and staffed.
        </>
    );
};

export default PerformanceDriversSubtitle;
