import React from "react";
import { Marker as MapGLMarker } from "react-map-gl";

import { Typography } from "@material-ui/core";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

import SimpleTooltip, { SimpleTooltipBackgroundColour, SimpleTooltipPlacement } from "components/SimpleTooltip";
import useColourPalette from "components/visuals/useColourPalette";

import { Competitor } from "modules/customer/tools/location/competition/competitors";

interface MarkerProps {
    competitor: Competitor,
    onTooltipOpen: () => void,
    onTooltipClose: () => void
}

const Marker: React.FC<MarkerProps> = (props) => {
    const { competitor, onTooltipOpen, onTooltipClose } = props;
    const colourPalette = useColourPalette();

    return (
        <MapGLMarker
            latitude={competitor.latitude}
            longitude={competitor.longitude}
            offsetTop={-10}
            offsetLeft={-12}
        >
            <SimpleTooltip
                placement={SimpleTooltipPlacement.Bottom}
                backgroundColour={SimpleTooltipBackgroundColour.Quaternary}
                onOpen={onTooltipOpen}
                onClose={onTooltipClose}
                title={
                    <Typography variant="body2" component="div" align="center" style={{ fontWeight: "bold" }}>
                        {competitor.fascia}
                    </Typography>
                }
            >
                {competitor.directCompetitor ? (
                    <PlayArrowRoundedIcon fontSize="small" cursor="pointer" style={{ color: colourPalette.categorical[2], transform: "rotate(90deg)" }} />
                ) : (
                    <FiberManualRecordRoundedIcon fontSize="small" cursor="pointer" style={{ color: colourPalette.categorical[0] }} />
                )}
            </SimpleTooltip>
        </MapGLMarker>
    );
};

export default Marker;
