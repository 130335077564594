const GET_BUDGET_VS_FORECAST_CONTRIBUTION_REQUEST = "spark/customer/insights/performance/budget/budgetedVsForecastContribution/GET_BUDGET_VS_FORECAST_CONTRIBUTION_REQUEST";
const GET_BUDGET_VS_FORECAST_CONTRIBUTION_SUCCESS = "spark/customer/insights/performance/budget/budgetedVsForecastContribution/GET_BUDGET_VS_FORECAST_CONTRIBUTION_SUCCESS";
const GET_BUDGET_VS_FORECAST_CONTRIBUTION_FAILURE = "spark/customer/insights/performance/budget/budgetedVsForecastContribution/GET_BUDGET_VS_FORECAST_CONTRIBUTION_FAILURE";

const types = {
    GET_BUDGET_VS_FORECAST_CONTRIBUTION_REQUEST,
    GET_BUDGET_VS_FORECAST_CONTRIBUTION_SUCCESS,
    GET_BUDGET_VS_FORECAST_CONTRIBUTION_FAILURE
};

export default types;
