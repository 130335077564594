import React from "react";

import { Box, Typography } from "@material-ui/core";

const Title: React.FC = () => {
    return (
        <>
            <Typography variant="h5" component="div">
                Comparator
            </Typography>
            <Box paddingTop={2}>
                <Typography variant="subtitle1" component="div">
                    Comparators provide a helpful reference point to compare locations against. For any location you're
                    analysing, Dash will also provide data points for the comparator to help you understand how the
                    locations differ. Select up to 5 of your own store locations.
                </Typography>
            </Box>
        </>
    );
};

export default Title;
