import { combineReducers } from "redux";

import openingsAndClosures from "./openingsAndClosures";
import marketCategoryBreakdown from "./marketCategoryBreakdown";
import supplyAndDemand from "./supplyAndDemand";
import retailDensity from "./retailDensity";
import marketCategorySpend from "./marketCategorySpend";


const reducer = combineReducers({
    openingsAndClosures,
    marketCategoryBreakdown,
    supplyAndDemand,
    retailDensity,
    marketCategorySpend
});

export default reducer;
