import { combineReducers } from "redux";

import types from "./types";

const supplyAndDemandEmpty = {
    loading: false,
    error: false,
    store: {},
    comparator: []
};

const supplyAndDemand = (state = supplyAndDemandEmpty, action) => {
    switch (action.type) {
        case types.GET_SUPPLY_AND_DEMAND_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                store: {},
                comparator: []
            };
        case types.GET_SUPPLY_AND_DEMAND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                store: action.payload.store,
                comparator: action.payload.comparator
            };
        case types.GET_SUPPLY_AND_DEMAND_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                store: {},
                comparator: []
            };
        default:
            return state;
    }
};

const reducer = combineReducers({
    supplyAndDemand
});

export default reducer;
