import React from "react";

import DemographicIndicatorChart from "./DemographicIndicatorChart";

import {
    selectCatchmentDemographicIndicators
} from "modules/customer/insights/portfolioNew/catchment/catchmentSlice";
import { useAppSelector } from "store";

const HouseholdIncome: React.FC = () => {
    const title = "Household income (Affluence)";
    const categories = [
        "<£20,000",
        "£20,000-£29,999",
        "£30,000-£39,999",
        "£40,000-£49,999",
        "£50,000-£59,999",
        "£60,000-£69,999",
        "£70,000-£79,999",
        "£80,000-£89,999",
        "£90,000-£99,999",
        ">£100,000"
    ];
    const demographicIndicatorsInCatchmentArea = useAppSelector(selectCatchmentDemographicIndicators);

    const selectedStore = demographicIndicatorsInCatchmentArea.data.selectedStore?.householdIncome;
    const selectedStoreValues = selectedStore ? [
        selectedStore.countIncomeSub20k,
        selectedStore.countIncome20to30k,
        selectedStore.countIncome30to40k,
        selectedStore.countIncome40to50k,
        selectedStore.countIncome50to60k,
        selectedStore.countIncome60to70k,
        selectedStore.countIncome70to80k,
        selectedStore.countIncome80to90k,
        selectedStore.countIncome90to100k,
        selectedStore.countIncome100kPlus
    ] : [];
    const selectedStoreTotal = selectedStoreValues.reduce((sum, val) => sum + val, 0);

    const comparator = demographicIndicatorsInCatchmentArea.data.comparator?.householdIncome;
    const comparatorValues = comparator ? [
        comparator.countIncomeSub20k,
        comparator.countIncome20to30k,
        comparator.countIncome30to40k,
        comparator.countIncome40to50k,
        comparator.countIncome50to60k,
        comparator.countIncome60to70k,
        comparator.countIncome70to80k,
        comparator.countIncome80to90k,
        comparator.countIncome90to100k,
        comparator.countIncome100kPlus
    ] : [];
    const comparatorTotal = comparatorValues.reduce((sum, val) => sum + val, 0);

    return (
        <DemographicIndicatorChart
            title={title}
            categories={categories}
            selectedStoreValues={selectedStoreValues}
            comparatorValues={comparatorValues}
            selectedStoreTotal={selectedStoreTotal}
            comparatorTotal={comparatorTotal}
        />
    );
};

export default HouseholdIncome;
