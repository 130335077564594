import React from "react";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import Actions from "./Actions";
import Content from "./Content";
import Open from "./Open";
import Stepper from "./Stepper";
import Title from "./Title";

import { hideFilters, selectFiltersVisibility } from "modules/customer/insights/portfolioNew/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "store";

const Filters: React.FC = () => {
    const dispatch = useAppDispatch();
    const filtersVisibility = useAppSelector(selectFiltersVisibility);

    const handleClose = () => {
        dispatch(hideFilters());
    };

    return (
        <>
            <Open />
            <TwoPanelDialog
                open={filtersVisibility.isVisible}
                onClose={handleClose}
                leftPanelTitle={<Title />}
                leftPanelContent={<Stepper />}
                leftPanelActions={<Actions />}
                leftPanelActionsJustifyContent={JustifyContent.SpaceBetween}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default Filters;
