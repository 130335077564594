import React from "react";

import RAGIndicator from "components/visuals/RAGIndicatorNew";

import { useAppSelector } from "store";
import { selectSalesPerPoundPropCostCategorisation } from "modules/customer/insights/portfolioNew/drivers/driversSlice";

const RevenuePerPoundOfPropertyCostCategorisation = () => {
    const salesPerPoundPropCostCategorisation = useAppSelector(selectSalesPerPoundPropCostCategorisation);

    return (
        <RAGIndicator ragIndicator={salesPerPoundPropCostCategorisation} />
    );
};

export default RevenuePerPoundOfPropertyCostCategorisation;
