import React from "react";
import { Grid } from "@material-ui/core";

import PayrollCostPerSqftGauge from "./PayrollCostPerSqftGauge";
import PayrollCostPerSqftTitle from "./PayrollCostPerSqftTitle";
import PayrollCostsPerSqftVariance from "./PayrollCostsPerSqftVariance";
import PayrollCostsPerSqftRank from "./PayrollCostsPerSqftRank";
import SalesValuePerHeadGauge from "./SalesValuePerHeadGauge";
import SalesValuePerHeadTitle from "./SalesValuePerHeadTitle";
import SalesValuePerHeadVariance from "./SalesValuePerHeadVariance";
import SalesValuePerHeadRank from "./SalesValuePerHeadRank";
import SalesPerPoundOfStaffCostGauge from "./SalesPerPoundOfStaffCostGauge";
import SalesPerPoundOfStaffCostTitle from "./SalesPerPoundOfStaffCostTitle";
import SalesPerPoundOfStaffCostVariance from "./SalesPerPoundOfStaffCostVariance";
import SalesPerPoundOfStaffCostRank from "./SalesPerPoundOfStaffCostRank";
import PayrollCostsPerSqftCategorisation from "./PayrollCostsPerSqftCategorisation";
import SalesPerHeadCategorisation from "./SalesPerHeadCategorisation";
import SalesPerPoundStaffCostCategorisation from "./SalesPerPoundStaffCostCategorisation";

const Staffing = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={4}>
                        <PayrollCostPerSqftGauge />
                        <PayrollCostPerSqftTitle />
                        <PayrollCostsPerSqftVariance />
                        <PayrollCostsPerSqftRank />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SalesValuePerHeadGauge />
                        <SalesValuePerHeadTitle />
                        <SalesValuePerHeadVariance />
                        <SalesValuePerHeadRank />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SalesPerPoundOfStaffCostGauge />
                        <SalesPerPoundOfStaffCostTitle />
                        <SalesPerPoundOfStaffCostVariance />
                        <SalesPerPoundOfStaffCostRank />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <PayrollCostsPerSqftCategorisation />
            </Grid>
            <Grid item xs={12}>
                <SalesPerHeadCategorisation />
            </Grid>
            <Grid item xs={12}>
                <SalesPerPoundStaffCostCategorisation />
            </Grid>
        </Grid>
    );
};

export default Staffing;
