import React from "react";
import { DateTime } from "luxon";

import { Badge, Box, Button, Card, CardActionArea, CardContent, MenuItem, Popover, TextField, Typography } from "@material-ui/core";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import {
    clearSavedLocationsFilter,
    selectCreatedAtRange,
    selectIsSavedLocationsFilterModified,
    selectSavedLocationsFilter,
    selectUsers,
    setSavedLocationsFilter
} from "modules/customer/tools/location/savedLocations/savedLocationsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButtonCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: (props: any) => props["data-is-open"] ? theme.palette.background.paper : theme.palette.quaternary.dark,
        borderRadius: (props: any) => props["data-is-open"] ? "6px 6px 0px 0px" : "6px"
    }
}))(Card);

const StyledButtonCardActionArea = withStyles(theme => ({
    root: {
        padding: "0px 8px"
    }
}))(CardActionArea);

const StyledMenuCard = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "6px 0px 6px 6px",
    }
}))(Card);

const StyledMenuCardContent = withStyles(theme => ({
    root: {
        minWidth: theme.spacing(40),
        "&:last-child": {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    }
}))(TextField);

const StyledKeyboardDatePicker = withStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main,
            color: theme.palette.common.white,
            marginTop: theme.spacing(-2)
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: theme.spacing(0)
        },
        "& .MuiIconButton-root:hover": {
            backgroundColor: "transparent"
        },
        "& .MuiFormHelperText-contained": {
            margin: theme.spacing(0)
        }
    },
}))(KeyboardDatePicker);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const savedLocationsFilter = useAppSelector(selectSavedLocationsFilter);
    const users = useAppSelector(selectUsers);
    const createdAtRange = useAppSelector(selectCreatedAtRange);
    const isSavedLocationsFilterModified = useAppSelector(selectIsSavedLocationsFilterModified);
    const [anchorElement, setAnchorElement] = React.useState<HTMLButtonElement | undefined>(undefined);
    const isOpen = Boolean(anchorElement);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(undefined);
    };

    const handleResetAllClick = () => {
        dispatch(clearSavedLocationsFilter());
    };

    const handleSavedByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const userId = event.target.value;
        const newSavedLocationsFilter = { ...savedLocationsFilter, userId };
        dispatch(setSavedLocationsFilter(newSavedLocationsFilter));
    };

    const handlePrivacyStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isPublic = value === "" ? undefined : Boolean(Number(value));
        const newSavedLocationsFilter = { ...savedLocationsFilter, isPublic };
        dispatch(setSavedLocationsFilter(newSavedLocationsFilter));
    };

    const handleDateSavedFromChange = (date: any) => {
        const createdAtFrom = DateTime.fromISO(date.toISOString(), { zone: "utc" });
        const newSavedLocationsFilter = { ...savedLocationsFilter, createdAtFrom };
        dispatch(setSavedLocationsFilter(newSavedLocationsFilter));
    };

    const handleDateSavedToChange = (date: any) => {
        const createdAtTo = DateTime.fromISO(date.toISOString(), { zone: "utc" });
        const newSavedLocationsFilter = { ...savedLocationsFilter, createdAtTo };
        dispatch(setSavedLocationsFilter(newSavedLocationsFilter));
    };

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isSavedLocationsFilterModified}>
                <StyledButtonCard elevation={0} data-is-open={isOpen}>
                    <StyledButtonCardActionArea onClick={handleClick}>
                        <Box display="flex" alignItems="center">
                            <FilterListRoundedIcon fontSize="small" />
                            &nbsp;&nbsp;
                            <Typography variant="body1" component="div">
                                Filter
                            </Typography>
                        </Box>
                    </StyledButtonCardActionArea>
                </StyledButtonCard>
            </Badge>
            <Popover
                elevation={0}
                open={isOpen}
                anchorEl={anchorElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                PaperProps={{
                    style: { backgroundColor: "transparent", borderRadius: "0px", maxHeight: "70vh" }
                }}
            >
                <StyledMenuCard>
                    <StyledMenuCardContent>
                        <Box display="flex">
                            <Spacer />
                            <Button
                                variant="text"
                                size="small"
                                color="default"
                                disableElevation
                                onClick={handleResetAllClick}
                            >
                                Reset all
                            </Button>
                        </Box>
                        <Box padding={1}>
                            <Typography variant="body1" component="div" gutterBottom>
                                Saved by
                            </Typography>
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                select
                                value={savedLocationsFilter.userId}
                                onChange={handleSavedByChange}
                                SelectProps={{
                                    displayEmpty: true,
                                    MenuProps: {
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        }
                                    }
                                }}
                                fullWidth
                            >
                                <MenuItem value="">
                                    Select user
                                </MenuItem>
                                {users.map(user =>
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                )}
                            </StyledTextField>
                        </Box>
                        <Box padding={1}>
                            <Typography variant="body1" component="div" gutterBottom>
                                Privacy status
                            </Typography>
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                select
                                value={savedLocationsFilter.isPublic === undefined ? "" : Number(savedLocationsFilter.isPublic)}
                                onChange={handlePrivacyStatusChange}
                                SelectProps={{
                                    displayEmpty: true,
                                    MenuProps: {
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        }
                                    }
                                }}
                                fullWidth
                            >
                                <MenuItem value="">
                                    Select privacy status
                                </MenuItem>
                                <MenuItem value={0}>
                                    Private
                                </MenuItem>
                                <MenuItem value={1}>
                                    Public
                                </MenuItem>
                            </StyledTextField>
                        </Box>
                        <Box padding={1}>
                            <Typography variant="body1" component="div" gutterBottom>
                                Date saved between
                            </Typography>
                            <StyledKeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                size="small"
                                color="primary"
                                margin="normal"
                                format="dd/MM/yyyy"
                                minDate={createdAtRange.from}
                                minDateMessage={`Date should not be before ${createdAtRange.from.toLocaleString()}`}
                                maxDate={createdAtRange.to}
                                maxDateMessage={`Date should not be after ${createdAtRange.to.toLocaleString()}`}
                                value={(savedLocationsFilter.createdAtFrom === undefined ? createdAtRange.from : savedLocationsFilter.createdAtFrom)}
                                onChange={handleDateSavedFromChange}
                                fullWidth
                                disableToolbar
                                disableFuture
                                autoOk
                                KeyboardButtonProps={{
                                    disableRipple: true
                                }}
                            />
                        </Box>
                        <Box padding={1}>
                            <StyledKeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                size="small"
                                color="primary"
                                margin="normal"
                                format="dd/MM/yyyy"
                                minDate={(savedLocationsFilter.createdAtFrom === undefined ? createdAtRange.from : savedLocationsFilter.createdAtFrom)}
                                minDateMessage={`Date should not be before ${(savedLocationsFilter.createdAtFrom === undefined
                                    ? createdAtRange.from.toLocaleString()
                                    : savedLocationsFilter.createdAtFrom.toLocaleString())}`
                                }
                                maxDate={createdAtRange.to}
                                maxDateMessage={`Date should not be after ${createdAtRange.to.toLocaleString()}`}
                                value={(savedLocationsFilter.createdAtTo === undefined ? createdAtRange.to : savedLocationsFilter.createdAtTo)}
                                onChange={handleDateSavedToChange}
                                fullWidth
                                disableToolbar
                                disableFuture
                                autoOk
                                KeyboardButtonProps={{
                                    disableRipple: true
                                }}
                            />
                        </Box>
                    </StyledMenuCardContent>
                </StyledMenuCard>
            </Popover>
        </>
    );
};

export default Filter;
