import React from "react";
import { Box, Typography } from "@material-ui/core";

const PropertyCostsPerSquareFootTitle = () => {
    const label = "Property costs per square foot";

    return (
        <Box display="flex" data-cy="property-costs-per-square-foot-title" pb={1}>
            <Typography variant="h6">{label}</Typography>
        </Box>

    );
};

export default PropertyCostsPerSquareFootTitle;
