import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Spot the stores with the greatest headroom for growth across the estate.
            Compare estimated and optimised sales over the past year to identify locations where aligning the
            product range more closely with store demand can unlock additional revenue.
        </>
    );
};

export default Subtitle;
