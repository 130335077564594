import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const Progress = () => {
    return (
        <Box align="center">
            <CircularProgress color="primary" />
        </Box>
    );
};

export default Progress;
