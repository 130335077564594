const GET_TYPES_OF_SALES_GROWTH_REQUEST = "spark/customer/insights/performance/sales/revenueGrowth/GET_TYPES_OF_SALES_GROWTH_REQUEST";
const GET_TYPES_OF_SALES_GROWTH_SUCCESS = "spark/customer/insights/performance/sales/revenueGrowth/GET_TYPES_OF_SALES_GROWTH_SUCCESS";
const GET_TYPES_OF_SALES_GROWTH_FAILURE = "spark/customer/insights/performance/sales/revenueGrowth/GET_TYPES_OF_SALES_GROWTH_FAILURE";

const types = {
    GET_TYPES_OF_SALES_GROWTH_REQUEST,
    GET_TYPES_OF_SALES_GROWTH_SUCCESS,
    GET_TYPES_OF_SALES_GROWTH_FAILURE
};

export default types;
