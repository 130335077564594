import React from "react";

import { Box, Typography } from "@material-ui/core";

import useColourPalette from "components/visuals/useColourPalette";
import KPIComparison from "components/visuals/KPIComparison";

import { useAppSelector } from "store";
import { selectYoYFootfall } from "modules/customer/tools/location/footfall/footfallSlice";
import { selectIsLoading, selectHasErrors } from "modules/customer/tools/location/footfall/footfallSlice";
import { selectComparatorsByChapter } from "modules/customer/tools/location/locationSlice";
import numberFormatter from "utils/numberFormatter";

const YoYChangeInFootfall: React.FC = () => {
    const colourPalette = useColourPalette();
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const comparator = useAppSelector(selectComparatorsByChapter)?.footfall;
    const yoyChangeInFootfall = useAppSelector(selectYoYFootfall);
    const benchmarkValue = yoyChangeInFootfall.benchmark;
    const benchmarkPositiveSign = benchmarkValue > 0 ? "+" : "";
    const retailCentreValue = yoyChangeInFootfall.retailCentre;
    const differenceValue = retailCentreValue - benchmarkValue;

    return (
        <Box width="100%" height="100%" data-cy="yoy-change-in-footfall">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: colourPalette.comparators[0] }}>
                        {numberFormatter.toPercentage(retailCentreValue)}
                    </Typography>
                }
                targetLabel="Year-on-year % change in footfall level in the selected location"
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[1] }}>
                        {benchmarkPositiveSign}{numberFormatter.toPercentage(benchmarkValue)}
                    </Typography>
                }
                comparisonLabel={`Comparator: ${comparator?.storeName}`}
                differenceValue={
                    <Typography variant="h6" component="div">
                        {numberFormatter.toPercentage(differenceValue)}
                    </Typography>
                }
                differenceLabel="Difference"
            />
        </Box>
    );
};

export default YoYChangeInFootfall;
