import React from "react";

import { Button, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "components/SimpleDialog";
import SimpleTooltip from "components/SimpleTooltip";

import { deleteDatasetFile, hideDeleteDatasetFile, selectDeleteDatasetFileVisibility, showDeleteDatasetFile } from "modules/admin/accounts/datasetFiles/datasetFilesSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledIconButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1)
    }
}))(IconButton);

interface DeleteDatasetFileProps {
    accountId: string,
    datasetFileId: string
}

const DeleteDatasetFile: React.FC<DeleteDatasetFileProps> = (props) => {
    const { accountId, datasetFileId } = props;
    const dispatch = useAppDispatch();
    const deleteDatasetFileVisibility = useAppSelector(selectDeleteDatasetFileVisibility);
    const show = (deleteDatasetFileVisibility.isVisible) && (datasetFileId === deleteDatasetFileVisibility.datasetFileId);

    const handleDeleteDatasetFileClick = () => {
        dispatch(showDeleteDatasetFile(datasetFileId));
    };

    const handleConfirmClick = () => {
        dispatch(deleteDatasetFile(accountId, datasetFileId));
    };

    const handleCancelClick = () => {
        dispatch(hideDeleteDatasetFile());
    };

    return (
        <>
            <SimpleTooltip title="Delete">
                <StyledIconButton size="small" onClick={handleDeleteDatasetFileClick}>
                    <DeleteIcon />
                </StyledIconButton>
            </SimpleTooltip>
            <SimpleDialog
                open={show}
                onClose={handleCancelClick}
                title={<></>}
                titleAlign="center"
                content={
                    <Typography variant="h5" component="div">
                        Are you sure you want to delete this dataset file?
                    </Typography>
                }
                contentAlign="center"
                actions={
                    <>
                        <Button variant="outlined" onClick={handleCancelClick}>
                            No, cancel
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={handleConfirmClick}>
                            Yes, delete
                        </Button>
                    </>
                }
                actionsJustifyContent="center"
            />
        </>
    );
};

export default DeleteDatasetFile;
